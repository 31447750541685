import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Container, Button, Typography, Tooltip, IconButton, Fab, Divider, Dialog} from '@material-ui/core';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {connect} from 'react-redux';
import { trackGAEvent, consoleToLog, shouldDisable, AccessLevel } from '../util/AppUtil';
import { SelectedDrawerState, setSelectedDrawerState, setSelectedOrgEmailObj, setCreateAndEditTemplate } from '../actions/selectedOrganization';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClearIcon from '@material-ui/icons/Clear';
import { updateSelectedOrg} from '../actions/selectedOrganization';
import { updateOrganization } from '../actions/organization';
import { useSnackbar } from 'notistack';
import {orgOnboardingUpdateApi} from '../services/authService';
import ListAltIcon from '@material-ui/icons/ListAlt';

const useStyles = makeStyles((theme) => ({
    mainContent : {
        width: (showDrawer) => {
            return showDrawer ? "83%" : "100%"; 
        },
        backgroundColor: theme.palette.primary.light, 
        transition: ".5s", 
        flexGrow: 1,
        //overflow:'hidden',
        overflow:'auto',
        overflowX:'hidden'
    },
    container: {
        marginTop: "8px"
    },
    root: {
        display: 'flex',
        position:'relative',
    },
    customColumn1: {
        maxWidth:'11%',
        flexBasis:'11%'
    },
    customColumn1A: {
        maxWidth:'20%',
        flexBasis:'20%'
    },
    customColumn2: {
        maxWidth:'89%',
        flexBasis:'89%'
    },
    customColumn2A: {
        maxWidth:'80%',
        flexBasis:'80%'
    },
    buttonStyle: {
        position:'absolute',
        top:'4px',
        right:'4px',
        background:'#222', 
        maxWidth:'28px', 
        borderRadius:'4px', 
        paddingTop:'2px', 
        paddingBottom:'2px', 
        color:'white', 
        '&:hover': {
            background:'#222'
        }
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    imageContainer: {
        padding:'16px 0px 0px 16px'
    },
    imageStyle1: {
        width: '100px',
        height:'111px'
    },
    imageStyle2: {
        width: '220px',
        height:'241px'
    },
    headingStyle1: {
        fontSize: '25px',
        fontWeight: 600,
        marginTop:'16px',
        [theme.breakpoints.down(1400)] : {
            fontSize: '23.5px'
        }
    },
    subtitleStyle1: {
        fontSize: '15px',
        fontWeight: 400,
        color:'#333',
        [theme.breakpoints.down(1400)] : {
            fontSize: '14px'
        }
    },
    buttonBorderStyle: {
        position:'relative',
        border:'2px dashed #ccc',
        width:'62px',
        height: '62px',
        background:'white',
        padding:'15px',
        gap:"10px",
        '&:hover': {
            zIndex: 10,
            background: 'rgba(0, 0, 0, 0.2)',
        }
    },
    labelStyle: {
        textAlign:'center',
        marginTop:'16px',
        fontSize:'12px',
        fontWeight: 500
    },
    columnContainer: {
        textAlign:'center',
        position:'relative'
    },
    lineStyle: {
        position:'absolute', 
        width:'108px', 
        top:'31px',
        borderBottom:'2px dashed rgba(0,0,0,0.2)',
        color:'rgba(0, 0, 0, 0.1)',
        textAlign:'left',
        '&:hover': {
            color: 'transparent'
        }
        
    },
    MuiDialogpaper: {
        scrollbarWidth: 'none',
        overflow:'visible',
        width:'900px',
        height:'380px',
        "&::-webkit-scrollbar": {
            display: "none"
        }   
    },
    dialogTitle : {
        fontSize:'17px',
        fontWeight:'600',
        marginTop:'24px'
    },
}
));

const OutsideOnboardingComponent = (props) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();

    const [expanded, setExpanded] = React.useState(true);
    const [openDialog, setOpenDialog] = useState(false);

    const { access_level } = props.selectedOrganization || {};
    const accessLevelOwner = shouldDisable(AccessLevel.getUserAccessLevelValue(access_level), AccessLevel.OWNER);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const onAddOrganizationInfoClick = () => {
        trackGAEvent(props.selectedOrganization.organization.name, 'Open Settings clicked from onboarding', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
        //props.setSelectedTemplate('');
        props.setSelectedDrawerState(SelectedDrawerState.SHOW_ORG_SETTINGS);
    }

    const onAddSupportEmailClick = () => {
        trackGAEvent(props.selectedOrganization.organization.name, 'Open Settings clicked from onboarding', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
        //props.setSelectedTemplate('');
        const emailObj = {
            email_type: 'gmail',
        }
        props.setSelectedOrgEmailObj(emailObj);
        props.setSelectedDrawerState(SelectedDrawerState.SHOW_ORG_SETTINGS);
    }

    const onInviteMemberClick = () => {
        trackGAEvent(props.selectedOrganization.organization.name, 'Open Member clicked from onboarding', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
        //props.setSelectedTemplate('');
        props.setSelectedDrawerState(SelectedDrawerState.SHOW_ORG_MEMBERS);
    }

    const onImportOrCreateWorkflowClick = () => {
        trackGAEvent(props.selectedOrganization.organization.name, 'Open Settings clicked from onboarding', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
        props.setCreateAndEditTemplate(true);
        props.setSelectedDrawerState(SelectedDrawerState.SHOW_ORG_SETTINGS);
    }

    const onAddClientsClick = () => {
        trackGAEvent(props.selectedOrganization.organization.name, 'Open Companies clicked from onboarding', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
        //props.setSelectedTemplate('');
        props.setSelectedDrawerState(SelectedDrawerState.SHOW_ORG_COMPANIES);
    }

    const onCloseButtonClick = () => {
        const organization_id = props.selectedOrganization.organization.id;

        orgOnboardingUpdateApi(organization_id, false)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response orgOnboardingUpdateApi: ", res);
                enqueueSnackbar(res.message, {variant:'success'});

                props.updateOrganization(res.organization);//update organization list in redux
                props.updateSelectedOrg(res.organization);//update selected_org in redux 
                props.setSelectedDrawerState(SelectedDrawerState.SHOW_WORKPLACE);
            })
            .catch((e) => {
                consoleToLog("orgOnboardingUpdateApi error", e.response);
                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant:'error'
                    });
                    return false;
                }
            });

    }

    const handleDialogOpen = () => {
        setOpenDialog(true);
    }

    const handleDialogClose = () => {
        setOpenDialog(false);
    }

    return (
        <div className={classes.mainContent}
            style={{height: props.height}}>
                <Container className={classes.container} maxWidth="xl">
                <Card className={classes.root} style={{paddingBottom:expanded && '24px'}}>
                    <Grid item className={expanded ? classes.customColumn1A : classes.customColumn1}>
                        <CardMedia className={classes.imageContainer}>
                            <img src={'/images/onboarding_welcome.svg'} className={expanded ? classes.imageStyle2 : classes.imageStyle1}/>
                        </CardMedia>
                    </Grid>
                    <Grid item style={{maxWidth:'3%', flexBasis:'3%', marginLeft:'auto'}}>
                        <IconButton
                            onClick={handleDialogOpen}
                            aria-expanded={expanded}
                            aria-label="show more"
                            className={classes.buttonStyle}
                        >
                            <ClearIcon style={{color:'#fff'}}/>
                        </IconButton>
                    </Grid>
                    <Grid item className={expanded ? classes.customColumn2A : classes.customColumn2}>
                        <Grid item container>
                            <Grid item md={12}>
                                <Typography className={classes.headingStyle1}>
                                    {`Greetings ${props.auth.user.firstname}`}
                                </Typography>
                                <Typography className={classes.subtitleStyle1}>
                                    We appreciate your decision to become a part of eProcessify. To initiate your journey, we suggest you complete your profile.
                                </Typography>
                            </Grid>
                        </Grid>
                        <Collapse in={expanded} timeout="auto" unmountOnExit>
                            <CardContent style={{position:'relative'}}>
                                <Grid item style={{marginTop:'16px', display:'flex', gap:'90px', padding:0, alignItems:'flex-start'}}>
                                    {!accessLevelOwner &&
                                    <Grid item className={classes.columnContainer}>
                                        <IconButton 
                                            onClick={onAddOrganizationInfoClick}
                                            className={classes.buttonBorderStyle}>
                                            <img src="/images/onboarding_add_org.svg"/>
                                        </IconButton>
                                        <Typography variant='subtitle1' className={classes.labelStyle}>
                                            Add<br /> Organization Info
                                        </Typography>
                                        <div style={{left:'80px'}} className={classes.lineStyle}/>
                                    </Grid>} 
                                    {!accessLevelOwner &&
                                    <Grid item className={classes.columnContainer}>
                                        <IconButton 
                                            onClick={onAddSupportEmailClick}
                                            className={classes.buttonBorderStyle}>
                                            <img src="/images/onboarding_add_support_email.svg"/>
                                        </IconButton>
                                        <Typography variant='subtitle1' className={classes.labelStyle}>
                                            Add <br />Support Email
                                        </Typography>
                                        <div  style={{left:'70px'}} className={classes.lineStyle}/>
                                    </Grid>}
                                    <Grid item className={classes.columnContainer}> 
                                        <IconButton 
                                            onClick={onInviteMemberClick}
                                            className={classes.buttonBorderStyle}>
                                            <img src="/images/onboarding_add_member.svg"/>
                                        </IconButton>
                                        <Typography variant='subtitle1' className={classes.labelStyle}>
                                            Invite Members
                                        </Typography>
                                        <div  style={{left:'75px', width:'118px'}} className={classes.lineStyle}/>
                                    </Grid>
                                    <Grid item className={classes.columnContainer}>
                                        <IconButton 
                                            onClick={onImportOrCreateWorkflowClick}
                                            className={classes.buttonBorderStyle}>
                                            <img src="/images/onboarding_add_templates.svg"/>
                                        </IconButton>
                                        <Typography variant='subtitle1' className={classes.labelStyle}>
                                            Import Workflow or<br />
                                            Create Workflow
                                        </Typography>
                                        <div  style={{left:'82px', width:'122px'}} className={classes.lineStyle}/>
                                    </Grid>
                                    <Grid item className={classes.columnContainer}>
                                        <IconButton 
                                            onClick={onAddClientsClick}
                                            className={classes.buttonBorderStyle}>
                                            <img src="/images/onboarding_add_clients.svg"/>
                                        </IconButton>
                                        <Typography variant='subtitle1' className={classes.labelStyle}>
                                            Add Client or<br />
                                            Add Bulk Clients
                                        </Typography>
                                    </Grid>
                                </Grid>
                                {/* <Grid item style={{marginTop:'16px', display:'flex'}}>
                                    <Grid item className={classes.columnContainer}>
                                        <div className={classes.lineStyle}/>
                                    </Grid> 
                                    <Grid item className={classes.columnContainer}>
                                        <div className={classes.lineStyle}/>
                                    </Grid>
                                    <Grid item className={classes.columnContainer}> 
                                        <div className={classes.lineStyle}/>
                                    </Grid>
                                    <Grid item className={classes.columnContainer}>
                                        <div className={classes.lineStyle}/>
                                    </Grid>
                                </Grid> */}
                            </CardContent>
                        </Collapse>
                    </Grid>
                </Card>
                </Container>

                {
                    openDialog && 
                        <Dialog open={openDialog}
                        classes={{
                            paper: classes.MuiDialogpaper}}
                        fullWidth
                        onClose={handleDialogClose}>
                            <div style={{textAlign:"right", marginTop:'16px', marginRight:'16px'}}>
                                <Button onClick={handleDialogClose} style={{minWidth:'20px'}}>
                                    <ClearIcon style={{color:"#ccc"}}/>
                                </Button>
                            </div>
                            
                            <div style={{width:'100%', textAlign:'center', marginTop:'40px', position:'relative'}}>
                                <div>
                                    <ListAltIcon style={{fontSize:"36px", color:'red'}}/>
                                </div>

                                <div style={{marginTop:'24px'}}>
                                    <Typography variant='h4'>
                                        Close Onboarding?
                                    </Typography>
                                </div>

                                <div>
                                    <Typography className={classes.dialogTitle}>
                                        {'You can always bring back onboarding from Settings => Preferences'}
                                    </Typography>
                                </div>

                                <div style={{display:'flex', justifyContent:'center', alignItems:'center', marginTop:'54px'}}>
                                    <Grid item md={5}
                                        onClick={handleDialogClose}>
                                        <Button fullWidth variant='outlined' color='primary'>
                                            Cancel
                                        </Button>
                                    </Grid>

                                    <Grid item md={5} style={{marginLeft:'16px'}}>
                                        <Button 
                                            onClick={onCloseButtonClick}
                                            fullWidth variant='contained' color='primary'>
                                            Close
                                        </Button>
                                    </Grid>
                                </div>
                            </div>

                        </Dialog>
                }
        </div>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization
});

const mapDispatchToProps = (dispatch) => ({
    setSelectedDrawerState: (drawerState) => dispatch(setSelectedDrawerState(drawerState)),
    setSelectedOrgEmailObj: (emailObj) => dispatch(setSelectedOrgEmailObj(emailObj)),
    setCreateAndEditTemplate: (createEditTemplate) => dispatch(setCreateAndEditTemplate(createEditTemplate)),
    updateOrganization: (organization) => dispatch(updateOrganization(organization)),
    updateSelectedOrg: (organization) => dispatch(updateSelectedOrg(organization)),
    setSelectedDrawerState: (drawerState) => dispatch(setSelectedDrawerState(drawerState))
})

export default connect(mapStateToProps, mapDispatchToProps)(OutsideOnboardingComponent);