import React, {useEffect} from 'react';
const OutsideClick = (ref, onClickOutside) => {
    useEffect(() => {
      /**
       * Invoke Function onClick outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          onClickOutside();
         // consoleToLog(ref.current)
        }
      }
      // Bind
      document.addEventListener("click", handleClickOutside);
      return () => {
        // dispose
        document.removeEventListener("click", handleClickOutside);
      };
    }, [ref, onClickOutside]);
  }

export default OutsideClick;
