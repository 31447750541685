import ReactGA from "react-ga4";
import {countries} from "../data/country.json";
import {states} from "../data/state.json";
import MenuItem from '@material-ui/core/MenuItem';
import { authenticateApi } from "../services/authService";

export const isDebug = () => {
    return process.env.REACT_APP_DEBUG !== 'false';
};


//input: 'abc' output: 'Abc'
export const capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
}


//input: ' a b ' output: 'ab'
export const removeSpace = (str) => {
    return str.replace(/\s/g, '')
}


//get checklist status string
export const getStatusNameByValue = (statusValue) => {
    switch (statusValue) {
        case 'pending':
            return 'Pending';

        case 'in_process':
            return 'In Process';

        case 'awaiting_reply':
            return 'Awaiting Reply';

        case 'completed':
            return 'Completed';

        case 'not_applicable':
            return 'Not Applicable';

        case 'over_due':
            return 'Over Due';
        
        case 'pending_approval':
            return 'Pending Approval';

    }
}


//input: 'abc' output: 'Abc'
export const capitalizeFirstLetterOfEachWord = (s) => {
    if (typeof s !== 'string') return '';
    return s.replace(/\b\w/g, l => l.toUpperCase());
}

export const consoleToLog = (msg, value) => {
    if (isDebug()) console.log(msg, value);
};

export const AccessLevel = {
    OWNER: 4,
    MANAGER: 3,
    MEMBER: 2,
    GUEST: 1,
    getUserAccessLevelValue: function (access_level) {
        switch (access_level) {
            case "owner": return 4;
            case "manager": return 3;
            case "member": return 2;
            case "guest": return 1;
        }
    }
};

export const shouldDisable = (user_access, actual_permission) => {
    return !(user_access >= actual_permission);
}


export const getStatusColor = (status) => {
    /**
    Completed       #72BB53(green)
    Awaiting Reply  #DC4C18(orange)
    In Progress     #FFA834(yellow)
    Pending         #929292(gray)       //default also
    Not Applicable  #929292(gray) with opacity
    */
    if (!status) return;
    var color = '#929292';
    switch (status) {
        case 'pending':
        case 'Pending':
        case 'not_applicable':
        case 'Not Applicable':
            color = '#929292';
            break;
        case 'in_process':
        case 'In Process':
            color = '#FFA834';
            break;
        case 'awaiting_reply':
        case 'Awaiting Reply':
            color = '#DC4C18';
            break;
        case 'completed':
        case 'Completed':
            color = '#72BB53';
            break;
        case 'pending_approval':
            color = '#000000';
            break;
        case 'over_due':
            color = 'red';
            break;    
        default:
            color = '#929292';
    }
    return color;
}

//activity action types (usage: activity click)
export const activityActionTypes = {
    CHECKLIST: 'checklist',
    TASK: 'task',
    NOTES: 'notes',
    COMMENTS: 'comments',
    ATTACHMENTS: 'attachments'
};

//input: html format activity with mentions :: output: display proper mentions
export const getReadableActivityInHTML = (activity_text) => {
    var readableActivity = activity_text;
    // consoleToLog("readableActivity---:",readableActivity);
    readableActivity = readableActivity.replace(/\n\r?/g, "<br />");//add <br>
    readableActivity = readableActivity.split('@[').join('<b>');
    readableActivity = readableActivity.split(']').join("</b>");
    readableActivity = readableActivity.split(/\(.+?\)/).join('');
    return readableActivity;
}

// Get comment count from task activity list.
// input: activity array :: output: count/undefined
export const getCommentCount = (activityList) => {
    var comment_count = undefined;
    for (var i = 0; i < activityList.length; i++) {
        if (activityList[i].action_type === activityActionTypes.COMMENTS) {
            comment_count = (activityList[i].comment && activityList[i].comment.comment_count) ? activityList[i].comment.comment_count : undefined;
            //if found return
            if (comment_count) {
                return comment_count;
            }
        }
    }
    return comment_count;//if not found
}

//Check if approver is logged in user on not in Checklist Page and SideMenu 
export const checkWheatherLoggedInUserIsApprover = (approvers_list, logged_in_user_id) => {
    let approverUsers = approvers_list && approvers_list.length > 0 && approvers_list?.filter((approver) => (approver.user.id === logged_in_user_id));
    if (approverUsers && approverUsers.length > 0) {
        return false;
    }
    else {
        return true;
    }
}

//Check wheater approvers has approved the checklist in Checklist Page and SideMenu 
// export const isAnyOneApproved = (approvers_list) => {
//     let approverUsers = approvers_list.filter((approver) => approver.is_approve === true);
//     if (approverUsers && approverUsers.length > 0) {
//         return true;
//     }
//     else {
//         return false;
//     }
// }

//Check if aprrover is logged in user and is_approve is true or false 
// export const checkIsApproveStatus = (approvers_list, logged_in_user_id) => {
//     const loggedInUser = approvers_list.filter((approver) => approver.user.id === logged_in_user_id);
//     const is_approve = loggedInUser && loggedInUser.length > 0 && loggedInUser[0].is_approve === false //checklistItem.checklist_approvers_list.filter((approver) => approver.is_approve === false);
//     if (is_approve) {
//         return true;
//     } else {
//         return false;
//     }
// }

// //Check if checklist send for review in Checklist Page and SideMenu 
// export const isChecklistSentForReviewByMember = (approvers_list) => {
//     const loggedInUser = approvers_list.filter((approver) => approver.is_send_to_approve === true);
//     if (loggedInUser && loggedInUser.length > 0) {
//         return true;
//     } else {
//         return false;
//     }
// }

//Check if all approver approved checklist and if true display complete checklist
// export const checkAllAppoverApprovedChecklist = (approvers_list) => {
//     const checklistApproved = approvers_list.filter((approver) => approver.is_approve !== true);
//     if (approvers_list && approvers_list.length > 0 && checklistApproved && checklistApproved.length > 0) {
//         return false;
//     }
//     else {
//         return true;
//     }
// }

export const getAssigneesFromChecklistItem = (checklistItem) => {
    return (checklistItem.checklist_members_list && checklistItem.checklist_members_list.length > 0) ? checklistItem.checklist_members_list : [];
}

export const getAssigneesOfApprover = (checklistItem) => {
    return (checklistItem.checklist_approvers_list && checklistItem.checklist_approvers_list.length > 0) ? checklistItem.checklist_approvers_list : [];
}

export const getLabelsFromChecklistItem = (checklistItem) => {
    return (checklistItem.checklist_labels_list && checklistItem.checklist_labels_list.length > 0) ? checklistItem.checklist_labels_list : [];
}

//Check approve status for
export const sendChecklistToApprover = (approvers_list) => {
    const loggedInUser = approvers_list.filter((approver) => approver.approval_status === "NONE" || approver.approval_status === "REJECTED");
    if (loggedInUser && loggedInUser.length > 0) {
        return true;
    } else {
        return false;
    }
}

//Check if checklist send for review in Checklist Page and SideMenu 
export const isChecklistSentForReview = (approvers_list) => {
    const loggedInUser = approvers_list.filter((approver) => approver.approval_status === 'PENDING_APPROVAL');
    if (loggedInUser && loggedInUser.length > 0) {
        return true;
    } else {
        return false;
    }
}


export const checkApprovalStatus = (approvers_list, logged_in_user_id) => {
    const loggedInUser = approvers_list.filter((approver) => approver.user.id === logged_in_user_id);
    const is_approve = loggedInUser && loggedInUser.length > 0 && loggedInUser[0].approval_status !== 'APPROVED' //checklistItem.checklist_approvers_list.filter((approver) => approver.is_approve === false);
    if (is_approve) {
        return true;
    } else {
        return false;
    }
}

export const checkAllAppoverApprovedChecklist = (approvers_list) => {
    const checklistApproved = approvers_list && approvers_list.length > 0 && approvers_list.filter((approver) => approver.approval_status !== 'APPROVED');
    if (approvers_list && approvers_list.length > 0 && checklistApproved && checklistApproved.length > 0) {
        return false;
    }
    else {
        return true;
    }
}

/* export const getBGAndTextColorFromArray = () => {
    const lightColorArray = ["#e1f5e7", "#dff5ff", "#fff2d3", "#ffff94", "#ebffbb", "#dba8ff", "#ffffaa"];
    const darkColorArray = ["#63b267", "#31a9ea", "#d4ac4d","#c6bc08", "#5f902f", "#4f1c96", "#f7751e"];

    var index = Math.floor(Math.random()*darkColorArray.length);
    //consoleToLog("index: ", index);
    consoleToLog("object", {dark: darkColorArray[index], light: lightColorArray[index]})
    return {dark: darkColorArray[index], light: lightColorArray[index]}
} */

/**bulk apis */
export const checklistMembers = (checkList, updatedChecklistArr) => {
   let updateChecklistMembers = checkList.map((checklistItem) => {
        updatedChecklistArr.map((checklist) => {
          if(checklist.id === checklistItem.id) {
             checklistItem.checklist_members_list = checklist.checklist_members_list
          }
          return checklistItem;
      })
      return checklistItem;
   });
   return updateChecklistMembers;
}

// export const checklistLabels = (checkList, updatedChecklistArr) => {
//     let updateChecklistLabels = checkList.map((checklistItem) => {
//          updatedChecklistArr.map((checklist) => {
//            if(checklist.id === checklistItem.id) {
//             checklistItem.checklist_labels_list = checklist.checklist_labels_list
//            }
//            return checklistItem;
//        })
//        return checklistItem;
//     });
//     return updateChecklistLabels;
//  }

 export const checklistLabels = (checkList, updatedChecklistArr) => {
    let updateChecklistLabels = checkList.map((checklistItem) => {
         updatedChecklistArr.map((updatedChecklistItem) => {
           if(updatedChecklistItem.id === checklistItem.id) {
            checklistItem = updatedChecklistItem;
           }
           return checklistItem;
       })
       return checklistItem;
    });
    return updateChecklistLabels;
 }

 export const updateTemplateArr = (templates, updatedtemplate, selectedTemplate) => {
    let updateTemplateStatus = templates.map((template) => {
        if(updatedtemplate && updatedtemplate.length === 0) {
            if(template.template_id === selectedTemplate.template_id) {
                template.data = [];
            }
            return template; 
        } else {
            updatedtemplate.map((temp) => {
                if(template.template_id === temp.template_id) {
                    template.data = temp.data;
                    template.checklist_count = temp.checklist_count
                } 
                return template;
            }) 
            return template;
        }
    });
    return updateTemplateStatus;
 }

 export const bulkUpdateChecklistDueDate = (checkList, updatedChecklistArr) => {
    let updateChecklistDueDate = checkList.map((checklistItem) => {
        updatedChecklistArr.map((checklist) => {
            if (checklistItem.id === checklist.id) {
                checklistItem.due_date = checklist.due_date;
            }
            return checklistItem;
        })
        return checklistItem;
    });
    return updateChecklistDueDate;
 }

 export const bulkChecklistMoveToFolder = (checklistGroup, checklist_group) => {
    let updateMoveToFodlerArr =  checklistGroup.map((folder) => {
        if(folder?.id === checklist_group?.id) {
           return {
               ...folder,
               checklist_group_status : checklist_group.checklist_group_status
           }
        } else {
            return folder;
        }
    });
    return updateMoveToFodlerArr;
 }


// export const bulkChecklistMoveToFolder = (checklistGroup, checklist_group) => {
//     let updateMoveToFodlerArr =  checklistGroup.map((folder) => {
//         if(folder?.id === checklist_group?.id) {
//            return checklist_group;
//         } else {
//             return folder;
//         }
//     });
//     return updateMoveToFodlerArr;
//  }

//display activity icons
 export const displayIcons = (activityObj) => {
    let flaticon = '';
    if(activityObj.activity_type === "complete_checklist") {
         flaticon = 'flaticon-clipboards-3'
    }
    if(activityObj.activity_type === "task_status_changed" || 
    activityObj.activity_type === 'task_status_changed_by_rule') {
         flaticon = 'flaticon-clipboards'
    }
    if(activityObj.activity_type === "set_checklist_due_date" || 
    activityObj.activity_type === "set_task_due_date") {
        flaticon = 'flaticon-calendar-1 customMargin2' 
    }
    if(activityObj.activity_type === "apply_label" 
    || activityObj.activity_type === "remove_label" 
    || activityObj.activity_type === "apply_label_by_rule" 
    || activityObj.activity_type === 'remove_label_by_rule') {
        flaticon = 'flaticon-price-tag customMargin';
    }
    if(activityObj.activity_type === "add_member_added" || activityObj.activity_type === "add_member_joined" ||
      activityObj.activity_type === "remove_member_removed" || activityObj.activity_type === "remove_member_left") {
        flaticon = 'flaticon-user' 
    }
    if(activityObj.activity_type === "add_approver_added" || activityObj.activity_type === "add_approver_joined") {
        flaticon = 'flaticon-approval';
    }
    if(activityObj.activity_type === "remove_approver_removed" || activityObj.activity_type === "remove_approver_left") {
        flaticon = 'flaticon-customer-service';
    }
    if(activityObj.activity_type === "move_checklist" || activityObj.activity_type === "create_checklist") {
        flaticon = 'flaticon-clipboards-2';
    }
    if(activityObj.activity_type === "notes_on_checklist" || activityObj.activity_type === "comment_on_task") {
        flaticon = 'flaticon-comment customMargin';
    }
    if(activityObj.activity_type === "upload_attachment" || activityObj.activity_type === "upload_widget_attachment") {
        flaticon = 'flaticon-attachment-1';
    }
    if(activityObj.activity_type === "checklist_send_to_approver") {
        flaticon = 'flaticon-clipboards-1'
    }
    if(activityObj.activity_type === "approver_approve_checklist") {
        flaticon = 'flaticon-badge customMargin2'
    }
    if(activityObj.activity_type === "approver_reject_checklist") {
        flaticon = 'flaticon-label customMargin2'
    }
    if(activityObj.activity_type === "scheduler_disabled" || 
    activityObj.activity_type === "scheduler_enabled" || 
    activityObj.activity_type === "scheduler_disabled_by_rule" || 
    activityObj.activity_type === "scheduler_enabled_by_rule") {
        flaticon = 'flaticon-clock-2 customMargin2'
    }
    return flaticon;
}
 
export const getExtension = (path) => {
    const regex = new RegExp("[^.]+$");
    const extension = path.match(regex)[0];

    switch (extension) {
      case "pdf":
        return "flaticon-pdf";
      case "xlsx":
        return "flaticon-xls";
      case "xls":
        return "flaticon-xls";
      case "xlsm":
        return "flaticon-xls";
      case "jpeg":
        return "flaticon-jpg";
      case "jpg":
        return "flaticon-jpg";
      case "docx":
        return "flaticon-doc";
      case "doc":
        return "flaticon-doc";
      case "txt":
        return "flaticon-txt";
      case "pptx":
        return "flaticon-ppt";
      case "zip":
        return "flaticon-zip"
      default:
        return "flaticon-file"
    }
}

export const trackGAPageView= (page) => {
    //ReactGA.pageview(page);
    if(!isDebug()) ReactGA.send({ hitType: "pageview", page: page/* , title: "Custom Title" */ });
}

export const trackGAEvent= (category, action, label) => {
    if(!isDebug()) {
        ReactGA.event({
            category: category,
            action: action,
            label: label
        });
    } 
}

export const updateTaskWidgets = (templateTask, templateTaskWidgets) => {
    let updateTaskWidgets = templateTask.map((task) => {
        templateTaskWidgets.map((widget) => {
            if(task.id === widget.template_task_id) {
                task.task_widget_list = templateTaskWidgets
            }
            return task
        })
        return task
    })
    return updateTaskWidgets
 }


export const ruleType = {
    CHECKLIST_CREATION: 'checklist_creation',
    TASK: 'task',
    LABEL: 'label',
    EMAIL_FOLLOWUP: 'email_followup',
    AND:'and',
    OR:'or',
    NONE: 'none',
    DUE_DATE: 'due_date'
    //AND_OR :'and/or'
};


export const labelType ={
    APPLIED :'apply',
    REMOVED :'remove'
}


export const schedulerType = {
    ENABLED :"enable",
    DISABLED :"disable"
}

export const actionType = {
    TASK: 'task',
    LABEL: 'label',
    EMAIL_FOLLOWUP: 'email_followup',
    DUE_DATE: 'due_date'
}

export const updatePredefinedTaskWidgets = (templateTask, templateTaskWidgets) => {
    let updateTaskWidgets = templateTask.map((task) => {
        templateTaskWidgets.map((widget) => {
            if(task.id === widget.predefined_template_task_id) {
                task.predefined_task_widget_list = templateTaskWidgets
            }
            return task
        })
        return task
    })
    return updateTaskWidgets
 }
// export const getReadableActivityNotesText = (activityNotesText, currentAuthUser, notesOrActivity) => {
//     var readableNotesOrActivity =  activityNotesText;
//     let removehtmltags = '';
//     if(notesOrActivity === 'activity_text') {
//        return removehtmltags = activityNotesText.replace(/<[^>]*>?/gm, '');
//     }
//     let  numberPattern = /\d+/g;
//     let user_id = removehtmltags ? removehtmltags.match(numberPattern) : readableNotesOrActivity.match(numberPattern);
//     let num = user_id?.join()
//     let mention_user_id = Number(num);

//     readableNotesOrActivity = readableNotesOrActivity.replace(/\n\r?/g, "<br />");//add <br>
//     readableNotesOrActivity = readableNotesOrActivity.split('@[').join(`<b style=color:${mention_user_id === currentAuthUser ? '#0B4C8C' :'black'};background:#E8F5FB;padding:4px;border-radius:4px>`);
//     readableNotesOrActivity = readableNotesOrActivity.split(']').join("</b>");
//     readableNotesOrActivity = readableNotesOrActivity.split(/\(.+?\)/).join('');
//     return readableNotesOrActivity;
// }

export const OverlayMessage = {
    IMPORT_MSG:"Please wait. We are setting up clients.",
    EXPORT_MSG: "Please wait. We are exporting into spreadsheet!",
    BULK_ADD_LABEL: "Please wait. We are applying labels to checklists!",
    BULK_REMOVE_LABEL: "Please wait. We are removing labels from checklists!",
    BULK_ADD_MEMBERS: "Please wait. We are adding members to checklists!",
    BULK_REMOVE_MEMBERS: "Please wait. We are removing members from checklists!",
    BULK_COMPLETE_CHECKLIST :"Please wait. We are completing checklists!",
    BULK_SET_DUE_DATE :"Please wait. We are updating due date in checklists!",
    BULK_MOVE_TO_FOLDER:"Please wait. We are moving checklists to folder!",
    SEND_EMAIL: "Please wait. We are sending the email!",
    DOWNLOAD_MESSAGE: "Please wait. We are exporting into zip!",
}

export const getInvoicePeriodicityPlaceholder= (periodictyType) => {
    if(periodictyType === "monthly"){
        return "per month";
    }
    else if(periodictyType === "quarterly"){
        return "per quarter";
    }
    else if(periodictyType === "half_yearly"){
        return "per half year";
    }
    else if(periodictyType === "yearly"){
        return "per year";
    }
    else return "";
}


export const showAllCountries = () => (
    countries.map((country)=>{
        return(
            <MenuItem value={country.code}>{country.name}</MenuItem>
        )
    })
)

export const showAllStates = () => (
    states.map((state)=>{
        return(
            <MenuItem value={state.code}>{state.name}</MenuItem>
        )
    })
)

export const statusColor = (status) => {   
    if(status === 'paid') {
        return 'green'
    } else if(status === 'viewed') {
        return '#cca20e'
    } else if(status === 'partial') {
        return '#006644'
    } else if(status === 'draft') {
        return '#828c98'
    } else if(status === 'sent') {
        return '#4c51bf'
    } else if(status === 'downloaded') {
        return '#007bc3'
    } else {
        return '#FF0000'
    }
}

export const parseString = (str) => {
    //console.log('str', str);
    return str.replace(/<br ?\/?>/ig,"\n")
};

export const showDefaultClientValues = (option, clientObj) => {
    const itemObj = option.default_value;
    //console.log('*******************', itemObj)
    switch (itemObj) {
        case '{{client.name}}':
            return clientObj?.name;

        case '{{client.address}}':
            return clientObj?.address;

        case '{{client.country}}':
            return clientObj?.country;
        
        case '{{client.state}}':
            return clientObj?.state;
            
        case '{{client.tax_id}}':
            return clientObj?.tax_id
    
        default:
            break;
    }
}

export const phoneNumberValidate = (e) => {
    const re = /[0-9 ()+]+$/g;
    if (!re.test(e.key) || e.target.value.length > 13) {
        e?.preventDefault();
    }
}

export const showAllUsers = (usersList) => (
    usersList.map((userObj)=>{
        const fullName = userObj.user.firstname + ' ' + userObj.user.lastname;
        return(
            <MenuItem value={userObj.user.id}>{fullName}</MenuItem>
        )
    })
)

export const stringToSlug = (str) => {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to   = "aaaaeeeeiiiioooouuuunc------";
    for (var i=0, l=from.length ; i<l ; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes

    return str;
}

export const sendEmailString = {
    TO : 'To',
    CC : 'Cc',
    BCC : 'Bcc'
}

export const setEmailObj = (checklist, followups) => {
    const clientEmailObj = checklist?.company.contacts && checklist?.company.contacts.length > 0 ? 
                            checklist?.company.contacts.map((contact) => contact.email) : []

    const updateFollowupArray = followups && followups.length > 0 ? followups.map((followup) => {
        return { 
            id: followup.id, 
            subject: followup.subject
        }
    }) : [];


    const checklistObj = {
        checklist_id: checklist?.id,
        end_date: checklist?.end_date,
        end_month: checklist?.end_month,
        next_month: checklist?.next_month,
        period: checklist?.period,
        start_date: checklist?.start_date,
        start_month: checklist?.start_month,
        subtitle: checklist?.subtitle,
        template_id: checklist?.template_data.template_id,
        template_name: checklist?.template_data.template_name,
        title: checklist?.title,
        company_id: checklist?.company.id,
        followups: updateFollowupArray
    }
    
    let data = {
        emails:   clientEmailObj,
        selectedTasks: [checklistObj],
        selectedChecklistId: checklist?.id
    }

    return data;
}

export const showEmailIcons = (image_type) => {
    switch (image_type) {
        case 'in_coming':
            return '/images/in-coming-mail.svg'

        case 'out_going':
            return '/images/out-going-mail.svg'

        case 'attachment':
            return '/images/attachment-img-icon.svg'

        case 'comment':
            return '/images/comment-icon.svg'

        case 'triage':
            return '/images/clear-triage-icon.svg'

        case 'read':
            return '/images/mark-as-read-large.svg'

        case 'unread':
            return '/images/mark-as-unread-large.svg'

        case 'reply':
            return '/images/reply-icon.svg'

        case 'replyAll':
            return '/images/reply-all-icon.svg'

        case 'forward':
            return '/images/forward-icon.svg'

        case 'create-checklist':
            return '/images/create-checklist-icon.svg'

        case 'search':
            return '/images/search-icon.svg'

        case 'assign-checklist':
            return '/images/assign-checklist-icon.svg'

        case 'assign-client':
            return '/images/add-client-icon.svg'

        case 'sent-email':
            return '/images/sent-email.svg'
            
        default:
            break;
    }
}

export const displayEmailSubject = (emailSubject) => {
    return emailSubject ? emailSubject.replaceAll(/^Re:|Fwd:+/ig, '') : '';
}

export const sendApiPath = (reply, replyAll, forward) => {
    if(reply) return 'reply';
    if(replyAll) return 'replyAll';
    if(forward) return 'forward';
}

export const inputNumberValidate = (e) => {
    const re = /[0-9]+/g;
    if (!re.test(e.key)) {
        e?.preventDefault();
    }
}

export const getStatusColor1 = (status) => {
    /**
    Active  #72BB53(green)
    Inactive  #DC4C18(orange)
    Max Limit Reached  #FFA834(yellow)
    */
    if (!status) return;
    var color = '#929292';
    switch (status) {
        case 'MAX_LIMIT_REACHED':
        case 'Max Limit Reached':
            color = '#FFA834';
            break;
        case 'INACTIVE':
        case 'Inactive':
            color = '#DC4C18';
            break;
        case 'ACTIVE':
        case 'Active':
            color = '#72BB53';
            break;
        default:
            color = '#929292';
    }
    return color;
}

export const EmailFollowupStatus = {
    ACTIVE: "ACTIVE",
    INACTIVE: "INACTIVE",
    MAX_LIMIT_REACHED: "MAX_LIMIT_REACHED"
}

//Check if approver is logged in user on not in Checklist Page and SideMenu 
export const checkWheatherLoggedInUserIsAssignees = (members_list, logged_in_user_id) => {
    let assignees = members_list && members_list.length > 0 && members_list?.filter((approver) => (approver.user.id === logged_in_user_id));
    if (assignees && assignees.length > 0) {
        return false;
    }
    else {
        return true;
    }
}

export const generateRandomId = () => {
    return Math.random().toString(36).substr(2, 9);
}

export const restructureNotes = (notes) => {

    let currentUserId = null;
    const groupedNotes = [];
    for (let i = 0; i < notes.length; i++) {
        const note = notes[i];
        const nextNote = notes[i + 1];
        const userId = note?.comment_user?.id;
        const nextUserId = nextNote?.comment_user?.id;

        if (currentUserId === null || userId !== currentUserId) {
            currentUserId = userId;
            const newGroup = { group_id: generateRandomId(), items: [] };
            groupedNotes.push(newGroup);
        }

        groupedNotes[groupedNotes.length - 1].items.push(note);

            if (nextNote && nextUserId !== currentUserId) {
                currentUserId = null; // Change the group ID for the next iteration
            }
    }
    return groupedNotes;
}

export const showImg = (attachmentObj) => {
    const extn = attachmentObj.name ? attachmentObj.name.split('.').pop() : attachmentObj.title  ? attachmentObj.title.split('.').pop() : '';
    
    if(extn === 'pdf') {
        return '/images/pdf_icon1.png'
    } else if(extn === 'xlsx') {
        return '/images/excel_icon1.png'
    } else if(extn === 'jpg' || extn === 'jpeg' || extn === 'png' || extn === 'svg') {
        return '/images/image_icon.png'
    } else if(extn === 'doc' || extn === 'docx') {
        return '/images/docx_icon.png'
    } else {
        return '/images/other_icon.png'
    }
}

export const generateRandomString = (length, chars) => {
    var mask = '';
    if (chars.indexOf('a') > -1) mask += 'abcdefghijklmnopqrstuvwxyz';
    //if (chars.indexOf('A') > -1) mask += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    if (chars.indexOf('#') > -1) mask += '0123456789';
    //if (chars.indexOf('!') > -1) mask += '~`!@#$%^&*()_+-={}[]:";\'<>?,./|\\';
    var result = '';
    for (var i = length; i > 0; --i) result += mask[Math.floor(Math.random() * mask.length)];
    return result;
}

export const ApprovalStatus = {
    PENDING: 'NONE',
    APPROVED: 'APPROVED',
    REJECTED: 'REJECTED',
    CANCELLED: 'CANCELLED'
}

export const ApprovalTypes = {
    CHECKLIST: 'checklist',
    TASK: 'task'
}


export const TaskStatusName = {
    PENDING: "Pending",
    PENDING_APPROVAL : "Pending Approval",
    AWAITING_REPLY: "Awaiting Reply",
    COMPLETED: "Completed"
}

export const approvalStatus = (status) => {
    switch (status) {
        case 'NONE':
            return {name: 'Pending', color: '#888888'};
        case 'APPROVED':
            return {name: 'Approved', color: '#3B961B'};
        case 'REJECTED':
            return {name: 'Rejected', color: '#DB4C3F'};
        case 'CANCELLED':
            return {name: 'Cancelled', color: '#333333'};
        default:
            return '';
    }

}

const findItemsNotInAaarray = (arr1, arr2) => {
    return arr1.filter(obj1 => !arr2.some(obj2 => obj2.checklist_ids?.includes(obj1.id)));
}

const findCommonItems = (arr1, arr2) => {
    return arr1.filter(obj1 => arr2.some(obj2 => obj1.template_data?.template_id === obj2.template_id));
}

// Function to find objects with different template_id
const findUncommonItems = (arr1, arr2) => {
    return arr1.filter(obj1 => !arr2.some(obj2 => obj1?.template_data?.template_id === obj2.template_id));
}

export const restructureChecklistArray = (checklistArr, lineItems) => {

    // let lineItemArr = [];
    const itemNotinArray = findItemsNotInAaarray(checklistArr, lineItems);
    //console.log('itemNotInArray', itemNotinArray);

    const itemHavingSameTemplateIds = findCommonItems(itemNotinArray, lineItems)  
    //console.log('itemHavingSameTemplateIds', itemHavingSameTemplateIds);

    const itemNotHavingSameTemplateIds = findUncommonItems(itemNotinArray, lineItems) 
    //console.log('itemNotHavingSameTemplateIds', itemNotHavingSameTemplateIds);

    if(itemHavingSameTemplateIds && itemHavingSameTemplateIds.length > 0) {
        const updatedArray = lineItems.map((item) => {
            itemHavingSameTemplateIds.map((checklistItem) => {
                if(checklistItem.template_data.template_id === item.template_id) {
                    item.item_description += `, ${checklistItem.subtitle}`;
                    item.checklist_ids.push(checklistItem.id);
                }
            });
            return item
        });
            return updatedArray;
        } else {
            let updatedArray = itemNotHavingSameTemplateIds.map(item => {
                return {
                    item_name: item.template_data?.template_name,
                    item_description: item.subtitle,
                    cost: 0,
                    quantity: 1,
                    checklist_ids: [item.id],
                    template_id: item.template_data?.scheduled ? item.template_data?.template_id : undefined,
                    template_data: item.template_data,
                    client_id: item.company.id,
                    edit: false,
                    loading: true
                };
            });
            return [...lineItems, ...updatedArray];
    }
}

export const getUnCommonItems = (checklistArr, lineItems) => {

    // let lineItemArr = [];
    const itemNotinArray = findItemsNotInAaarray(checklistArr, lineItems);
    //console.log('itemNotInArray', itemNotinArray);

    const itemNotHavingSameTemplateIds = findUncommonItems(itemNotinArray, lineItems) 
    //console.log('itemNotHavingSameTemplateIds', itemNotHavingSameTemplateIds);
    return itemNotHavingSameTemplateIds;
}

export const fetchAccessToken = async (service) => {
    try {
        const response = await authenticateApi(service);
        const data = await response.data;
        return data;
    } catch (error) {
        console.error('Error fetching access token:', error);
        throw error;
    }
};