import React, {useEffect, useState, Fragment, useRef, useMemo} from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { TextField, Dialog, Grid, Button, Typography, InputBase, MenuItem, Tooltip, Slide, DialogContent, Select,
    InputAdornment, Avatar, Chip, Paper, CircularProgress, Checkbox, FormControlLabel} from '@material-ui/core';
import {connect} from 'react-redux';
//import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState,  RichUtils, Modifier } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import ClearIcon from '@material-ui/icons/Clear';
import {sendEmailApi, replyForwardEmailApi} from '../services/genieService';
import { consoleToLog, sendEmailString, OverlayMessage, capitalizeFirstLetterOfEachWord } from '../util/AppUtil';
import "react-multi-email/style.css";
import { useSnackbar } from 'notistack';
import { Autocomplete } from '@material-ui/lab';
import { getClientEmailListAPi, getClientsPendingTasksApi } from '../services/authService';
import { useIsMount } from './useIsMount';
import { emailValidator } from '../util/validator';
import ReactDOMServer from "react-dom/server";
import {getMentionOptionsForBody} from '../services/mentionService';
import { ClickAwayListener } from '@material-ui/core';
import { OverlayView,  setOverlayToken, setOverlayView, setIsEmailSend, setOvelayMessage, setEmailItemId } from '../actions/overlay';
import {uploadAttachmentApi} from '../services/uploadService';
import {setCurrentEmailState, setIsEmailSendError, setEmailFollowupObject, setChecklistArray, setFromChecklistDetailsPage} from '../actions/selectedOrganization';
import SendEmailErrorModal from './SendEmailErrorModal';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import {createEmailFollowupApi} from '../services/authService';
import {displayEmailSubject, sendApiPath} from '../util/AppUtil';
import moment from 'moment';
import { Editor } from "@tinymce/tinymce-react";
import {fullTextSearchApi} from '../services/clientService';
import {inputNumberValidate} from '../util/AppUtil';
import EmailAttachmentErrorModal from './EmailAttachmentErrorModal';
import JoditEditor from 'jodit-react';


const useStyles = makeStyles((theme) => ({
    backIcon: {
        color: "#202020",
        border: " 1px solid #202020",
        padding: "4px 0px",
        fontSize: "18px",
        minWidth: "40px",
        lineHeight: "18px",
        marginTop: "15px",
        marginBottom: "10px",
        marginLeft: "15px",
    },
    appBar: {
        position: 'relative',
        background:'#f2f6fc'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    customColumn: {
        flexBasis:'5.5%',
        maxWidth:'5.5%'
    },
    attachLink : {
        color:'#444',
        fontWeight:'600',
        cursor:'pointer',
        textDecoration:"none !important",
        fontSize:'15px',
        display:'flex',
        alignItems:'flex-start',
        minWidth:'25px',
        padding:'4px 8px',
        marginLeft:'12px',
        marginRight:'12px',
    },
    attachmentListStyle: {
        display:'flex',
        alignItems:'center',
        padding:'8px',
        backgroundColor:'#f9f9f9',
        border:'1px solid #ececec',
        borderRadius:'5px',
        marginRight:'12px',
        marginBottom:'12px'
    },
    attachmentFilename: {
        marginTop:'-2px',
        marginRight:'6px',
        maxWidth:'20ch',
        overflow:'hidden',
        whiteSpace:'nowrap',
        textOverflow:'ellipsis'
    },
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        borderBottom:'1px solid #ccc',
        borderRadius:'0px'
    },
    MuiDialogpaper: {
        scrollbarWidth: 'none !important',
    
    },
    selectIcon: {
        left: 80,
        color:'#555555'
    },
    selectIcon1: {
        position: 'relative',
        color:'#555555'
    },
    textStyles: {
        color: '#747775',
        fontSize: '14px'
    },
    selectIcon2: {
        right: 0,
    },
    selectIcon3: {
        position: 'relative',
    },
    MuiTextFieldroot : {
        '& .MuiInput-underline' : {
            border:'0 !important'
        }
    },
    customBorder: {
        borderBottom:'1px solid rgba(0,0,0,0.1)'
    },
    wrapperClass : { 
        display: 'flex', 
        flexDirection: 'column' 
    },
    toolbarClass: { 
        order: 2,
        border:'0',
        paddingLeft:'0px',
        paddingLeft: '0px',
        boxShadow: '0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%), 0px 2px 4px -1px rgb(0 0 0 / 20%)'
    },
    sendButtonStyle: {
        background:'#0b57d0',
        borderRadius:'50px',
        fontSize:'14px',
        fontWeight:500,
        color:'#fff !important',
        width:'135px',
        '&:hover' : {
            color:'#fff !important',
            backgroundColor: '#1b61d1 !important'
        }
    },
    avatar : {
        width: '23px !important',
        height: '23px !important',
        fontSize: '12px !important',
        background: 'none !important',
        color: 'rgba(0, 0, 0, 0.87) !important',
        border: '1px solid rgba(0, 0, 0, 0.26) !important'
        },
    
    chip : {
        borderRadius: '16px !important',
        backgroundColor: '#fff !important',
        border: '1px solid #ccc !important',
        color:'#5f6368',
        '&:hover' : {
            backgroundColor: '#f7f7f7 !important',
            fontWeight:500
        }
        },
        
    avatarStyle: {
        width: 20,
        height: 20,
        fontSize: 12
    },
    autoStyle: {
        "&::-webkit-scrollbar": {
            display: "none"
        }
    },
    tooltipText: {
        color: '#5f6368',
        fontSize:'14px'
    },
    tooltipText1: {
        color: 'grey',
        fontSize:'13px',
        marginTop:'-2px',
        textAlign:'left'
    },
    avatar1: {
        width: '35px !important',
        height: '35px !important',
        fontSize: '16px !important',
        background: '#f7f7f7 !important',
        color: '#5f6368 !important',
        border: '1px solid #5f6368 !important'
    },
    popover: {
        pointerEvents: 'none',
        marginTop:'8px',
        marginLeft:'100px',
    },
    paper: {
        padding: theme.spacing(2),
    },
    lineText: {
        color: '#747775',
        fontSize: '14px',
        '&:hover' : {
            textDecoration:'underline'
        }
    },
    subtitleStyle: {
        marginLeft:'8px', 
        color:'grey', 
        fontSize:'13px'
    },
    templateName: {
        color:'#0071FF'
    },
    errorTextStyles: {
        fontSize: '17px',
        fontWeight: '600',
        textAlign: 'center'
    },
    emailTextStyle: {
        fontSize:'16px',
        fontWeight:600,
        display:'flex',
        alignItems:'center'
    },
    emailListContainer: {
        padding:'0px 24px',
        '& .more_actionButton' : {
            visibility:'visible',
            width:'120px',
            borderRadius:'5px',
            fontSize:'14px',
            padding:'1px 0px',
            background:'white',
            border:'1px solid #ccc',
        },

    },
    connectIcons: {
        width:'22px',
        marginRight: '8px'
    },
    checkIcon: {
        color:"green",
        fontSize:'20px',
        marginLeft:'8px'
    },
    customPadding:{
        margin:'0px 16px'
    },
    root: {
        width: 200,
        "& .MuiInputLabel-root": {
            color: "#041E49"
        },
    },
    connectLinkStyle: {
        fontSize:'13px',
        cursor:'pointer',
        marginLeft:'8px',
        color:'#0b57d0',
        '&:hover': {
            textDecoration:'underline'
        }
    },
    daysLabel : {
        background:'#C4C4C4', 
        padding:'14px', 
        borderRadius:'4px',
    },
    MuiTextFieldroot : {
        '& .MuiOutlinedInput-adornedEnd': {
            paddingRight: '8px'
        }
    },
    MuiFormControlLabelroot : {
        '& .MuiFormControlLabel-label' : {
            fontSize: '14px',
            color: '#555555',
            marginLeft:'-4px'
        }
    },
    emailCloseButton: {
        minWidth:'25px',
        padding:'6px 8px',
    },
    clientListStyle: {
        fontSize:'13px',
        maxWidth:'450px',
        whiteSpace:"nowrap",
        overflow: 'hidden',
        textOverflow:'ellipsis',
        marginLeft:'6px'
    }
}))

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[4],
        fontSize: 11,
        marginLeft:'100px !important',
        padding: theme.spacing(2),
        width: 'max-content', 
        maxWidth: '550px'
    },
    }))(Tooltip);

const SendEmailDialog = (props) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();

    const [selectedEmailId, setSelectedEmailId] = useState(props.orgEmailList && props.orgEmailList.length === 1 ? props.orgEmailList[0] : '-1');
    const [emailSubject, setEmailSubject] = useState('');
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [attachment, setAttachment] = useState([]);
    const [selectedEmailDraft, setSelectedEmailDraft] = useState(props.currentEmailState?.selectedEmailDraft ? props.currentEmailState?.selectedEmailDraft : '-1');
    const [emails, setEmails] = useState([]);
    const [emailCcs, setEmailCcs] = useState([]);
    const [emailBccs, setEmailBccs] = useState([]);
    const [textFieldClicked, setTextFieldClicked] = useState(true);
    const [emailCcClicked, setEmailCcClicked] = useState(false);
    const [emailBccClicked, setEmailBccClicked] = useState(false);
    const [clientEmailList, setClientEmailList] = useState([]);
    const [inputText, setInputText] = useState('');
    const [openDropdown, setOpenDropdown] = useState(false);
    const [openDropdown1, setOpenDropdown1] = useState(false);
    const [openDropdown2, setOpenDropdown2] = useState(false);
    const [selectedEmailString, setSelectedEmailString] = useState(sendEmailString.TO);
    const [emailError, setEmailError] = useState(true);
    const [loading, setLoading] = useState(false);
    const [selectedChecklistId, setSelectedChecklistId] = useState(false);
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [errorAttachments, setErrorAttachments] = useState([]);
    
    const [clientPendingCheckList, setClientPendingCheckList] = useState([]);
    const [selectedTasks, setSelectedTasks] = useState([]);
    const [inputText1, setInputText1] = useState('');
    const [showTasks, setShowTasks] = useState(false);
    const [mentionOptionsForBody, setMentionOptionsForBody] = useState([]);

    const [interval, setInterval] = useState(false);
    const [intervalDays, setIntervalDays] = useState(2);
    const [maxCount, setMaxCount] = useState(3);
    //const [editorValue, setEditorValue] = useState('');
    const [draftAttachments, setDraftAttachments] = useState([]);
    
    const isMount = useIsMount();

    const allEmailsArr = [...emails, ...emailCcs, ...emailBccs];
    const editor = useRef(null);
	const [editorValue, setEditorValue] = useState('');

    const buttons = [
        "undo",
        "redo",
        "|",
        "font",
        "fontsize",
        "|",
        "bold",
        "italic",
        "strikethrough",
        "underline",
        "|",
        "align",
        "ul",
        "ol",
        "|",
        "outdent",
        "indent",
        "|",
        "table",
        ];

	const config = useMemo(
		() => ({
			readonly: false,
            toolbar: true,
            spellcheck: true,
            language: "en",
            toolbarAdaptive: true,
            askBeforePasteHTML: false,
            askBeforePasteFromWord: true,
            defaultActionOnPaste: "insert_clear_html",
            buttons: buttons,
			placeholder: 'Type here',
            disablePlugins: 'add-new-line',
            cleanHTML: {
                fillEmptyParagraph: false,
                replaceNBSP:false
            },
            enter: 'BR', // Use <br> instead of <p> on Enter key press
            removeButtons: ['source', 'fullsize', 'about'],
            minHeight:300
		}),
        [],
    );

    const displayEditorBodyData = () => {

        return `<br/><br/>
                <div>On ${moment(props.stringDate).format('ddd, DD MMM YYYY')} at ${moment(props.stringDate).format('HH:mm')}, ${props.emailObj?.from.name} &lt;${props.emailObj?.from.email}&gt; wrote: </div>
                <blockquote style="margin:0px 0px 0px 0.8ex;border-left:1px solid rgb(204,204,204);padding-left:1ex">
                    ${props.initialEmailObj?.html}
                </blockquote>`
    }


    const displayForwardMessage = () => {

        return `<br/><br/>
                <span style="font-size:13px">---------------------- Forwarded message -----------------------<br/>
                From: ${props.emailObj?.from.name} &lt;${props.emailObj?.from.email}&gt<br/>
                Date: ${moment(props.stringDate).format('ddd, DD MMM YYYY')} at ${moment(props.stringDate).format('HH:mm')}<br/>
                Subject: ${props.emailObj?.subject}<br/>
                
                ${props.ccEmails && props.ccEmails.length > 0 ? 
                    `Cc: ${props.ccEmails.map((email) => `&lt;${email}`).join(', ')}&gt;</span>` : ``}
                <br/><br/>
                    ${props.initialEmailObj?.html}`
    }

    const storeAttachmentArray = () => {
        let attachmnentArr = [];
        for(let i = 0; i < attachment.length; i++) {
            let obj = {
                lastModified: attachment[i].lastModified,
                lastModifiedDate: attachment[i].lastModifiedDate,
                name: attachment[i].name,
                size: attachment[i].size,
                type: attachment[i].type,
                webkitRelativePath: attachment[i].webkitRelativePath
            }
            attachmnentArr.push(obj);
        }
        return attachmnentArr
    }

    
    let data = { selectedEmailDraft, 
                selectedEmailId, 
                emails, 
                emailCcs, 
                emailBccs, 
                emailSubject, 
                //body_text: editorValue,
                body_text: editorValue, 
                selectedTasks, 
                selectedEmailString, 
                emailCcClicked, 
                emailBccClicked, 
                attachment: attachment && attachment.length > 0 ? storeAttachmentArray() : [],
                interval,
                intervalDays,
                maxCount,
                selectedChecklistId
    }


    const handleChange = (event) => {
        setSelectedEmailId(event.target.value);
        const obj = event.target.value;
        if(!obj?.connected) {
            onConnectClick(obj);
        }
    };

    //open error modal
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const subjectAndBodyVariable = {
        "{{company.name}}": selectedTasks[0]?.title ? selectedTasks[0]?.title : "{{company.name}}",
        "{{checklist.start_date}}": selectedTasks[0]?.start_date ? selectedTasks[0]?.start_date : "{{checklist.start_date}}",
        "{{checklist.end_date}}": selectedTasks[0]?.end_date ? selectedTasks[0]?.end_date : "{{checklist.end_date}}",
        "{{company.pan}}" : selectedTasks[0]?.pan ? selectedTasks[0]?.pan : "{{checklist.pan}}",
        "{{checklist.period}}": selectedTasks[0]?.subtitle ? selectedTasks[0]?.subtitle : "{{checklist.period}}",
        "{{checklist.next_month}}" : selectedTasks[0]?.next_month ? selectedTasks[0]?.next_month :"{{checklist.next_month}}",
        "{{checklist.start_month}}" : selectedTasks[0]?.start_month ? selectedTasks[0]?.start_month :"{{checklist.start_month}}",
        "{{checklist.end_month}}" : selectedTasks[0]?.end_month ? selectedTasks[0]?.end_month : "{{checklist.end_month}}",
        "{{checklist.subtitle}}" : selectedTasks[0]?.subtitle ? selectedTasks[0]?.subtitle : "{{checklist.subtitle}}",
        "*{{company.name}}": selectedTasks[0]?.title ? selectedTasks[0]?.title : "*{{company.name}}",
        "*{{checklist.start_date}}": selectedTasks[0]?.start_date ? selectedTasks[0]?.start_date : "*{{checklist.start_date}}",
        "*{{checklist.end_date}}": selectedTasks[0]?.end_date ? selectedTasks[0]?.end_date : "*{{checklist.end_date}}",
        "*{{company.pan}}" : selectedTasks[0]?.pan ? selectedTasks[0]?.pan : "*{{checklist.pan}}",
        "*{{checklist.period}}": selectedTasks[0]?.subtitle ? selectedTasks[0]?.subtitle : "*{{checklist.period}}",
        "*{{checklist.next_month}}" : selectedTasks[0]?.next_month ? selectedTasks[0]?.next_month : "*{{checklist.next_month}}",
        "*{{checklist.start_month}}" : selectedTasks[0]?.start_month ? selectedTasks[0]?.start_month : "*{{checklist.start_month}}",
        "*{{checklist.end_month}}" : selectedTasks[0]?.end_month ? selectedTasks[0]?.end_month : "*{{checklist.end_month}}",
        "*{{checklist.subtitle}}" : selectedTasks[0]?.subtitle ? selectedTasks[0]?.subtitle : "*{{checklist.subtitle}}"
    };

    let emailSchedulerMentionData = {
        company_name: selectedTasks[0]?.title,
        start_date: selectedTasks[0]?.start_date,
        end_date: selectedTasks[0]?.end_date,
        subtitle: selectedTasks[0]?.subtitle,
        pan: selectedTasks[0]?.pan,
        next_month: selectedTasks[0]?.next_month,
        start_month: selectedTasks[0]?.start_month,
        end_month: selectedTasks[0]?.end_month
    }

    const renderContentForSubjectAndBody = (emailString) => {
        let result = emailString;
        for (let dynamicVar in subjectAndBodyVariable) {
            const compAsHtml = ReactDOMServer?.renderToStaticMarkup(
                subjectAndBodyVariable[dynamicVar]
            );
            result = result?.replaceAll(dynamicVar, compAsHtml);
            //console.log('result', result);
        }
            return result;
    };

    useEffect(() => {
        if(!isMount) {
            generateDynamicMentionOptions();
        }
    }, [selectedEmailDraft]);

    useEffect(() => {
        if(!isMount) {
            if(!props.currentEmailState && selectedEmailDraft !== '-1') {
                setEmailDraftObj(selectedEmailDraft);
            }
        }
    }, [selectedTasks]);

    useEffect(() => {
        if(props.isEmailSendError) {
            props.setIsEmailSendError(false);
        }
    }, []);

    useEffect(() => {
        if(props.fromEmailDetails) {
            setEmailSubject(displayEmailSubject(props.emailObj?.subject));
            //props.forwardClick ? setEditorValue(displayForwardMessage()) : setEditorValue(displayEditorBodyData());
            props.forwardClick ? setEditorValue(displayForwardMessage()) : setEditorValue(displayEditorBodyData());
            setSelectedChecklistId(props.checklist_id);
        }
    }, []);


    useEffect(() => {
        if(props.currentEmailState) {
            setSelectedEmailDraft(props.currentEmailState?.selectedEmailDraft ? props.currentEmailState?.selectedEmailDraft : '-1');
            setSelectedEmailId(props.orgEmailList && props.orgEmailList.length === 1 ? props.orgEmailList[0] : props.currentEmailState?.selectedEmailId ? props.currentEmailState?.selectedEmailId : '-1');
            setEmails(props.currentEmailState?.emails ? props.currentEmailState?.emails : []);
            setEmailCcs(props.currentEmailState?.emailCcs ? props.currentEmailState?.emailCcs : []);
            setEmailBccs(props.currentEmailState?.emailBccs ? props.currentEmailState?.emailBccs : []);
            setSelectedTasks(props.currentEmailState?.selectedTasks ? props.currentEmailState?.selectedTasks : []);
            setSelectedEmailString(props.currentEmailState?.selectedEmailString ? props.currentEmailState?.selectedEmailString : sendEmailString.TO);
            setEmailCcClicked(props.currentEmailState?.emailCcClicked ? props.currentEmailState?.emailCcClicked : false);
            setEmailBccClicked(props.currentEmailState?.emailBccClicked ? props.currentEmailState?.emailBccClicked : false); 
            setAttachment(props.currentEmailState?.attachment && props.currentEmailState?.attachment.length > 0 ? props.currentEmailState?.attachment : []);
            setInterval(props.currentEmailState?.interval ? props.currentEmailState?.interval : false);
            setIntervalDays(props.currentEmailState?.intervalDays ? props.currentEmailState?.intervalDays : 2);
            setMaxCount(props.currentEmailState?.maxCount ? props.currentEmailState?.maxCount : 3);
            props.currentEmailState?.selectedChecklistId && setSelectedChecklistId(props.currentEmailState?.selectedChecklistId);

            //props.currentEmailState?.body_text ? setEditorValue(props.currentEmailState?.body_text) : setEditorValue('');
            props.currentEmailState?.body_text ? setEditorValue(props.currentEmailState?.body_text) : setEditorValue('');
            setEmailSubject(renderContentForSubjectAndBody(props.currentEmailState?.emailSubject ? props.currentEmailState?.emailSubject : ''));
            props.setIsEmailSendError(false);
        }
    }, []);

    useEffect(() => {
        setTextFieldClicked(false);
        if(props.replyClick) {
            let emailArray = props.emailObj?.from  ? [props.emailObj?.from.email] : [];
            setEmails(emailArray);
            setSelectedEmailString(sendEmailString.TO);
        }
        if(props.replyAllClick) {
            let emailArray = props.emailObj?.from  ? [props.emailObj?.from.email] : [];
            if(props.toEmails && props.toEmails.length > 0) {
                const emailArr1 = props.toEmails && props.toEmails.length > 0 ? props.toEmails.map((email) => email) : [];
                const finalArr = [...emailArray, ...emailArr1];
                setEmails(finalArr);
                setSelectedEmailString(sendEmailString.TO);
            }

            if(props.ccEmails && props.ccEmails.length > 0) {
                setEmailCcs(props.ccEmails && props.ccEmails.length > 0 ? props.ccEmails.map((email) => email) : [])
                setEmailCcClicked(true);
                setSelectedEmailString(sendEmailString.CC);
            }
        }
    }, []);

    useEffect(() => {
        if(!isMount) {
            if(props.orgEmailList && props.orgEmailList.length > 0) {
                if(props.orgEmailList && props.orgEmailList.length === 1) {
                    setSelectedEmailId(props.orgEmailList[0]);
                } else {
                    const filterEmailList = props.orgEmailList.filter((orgEmail) => orgEmail.id === props.currentEmailState?.selectedEmailId?.id);
                    if(!filterEmailList[0]?.connected) onConnectClick(filterEmailList[0]);
                    setSelectedEmailId(filterEmailList[0]);
                }
            }
        }
    }, [props.orgEmailList]);

    useEffect(() => {
            if(props.emailAttachments && props.emailAttachments.length > 0 && props.forwardClick) {
                setAttachment(props.emailAttachments);
            }

    }, [props.emailAttachments]);


    const setEmailBodyText = (bodyText) => {
        const blocksFromHtml = htmlToDraft(renderContentForSubjectAndBody(bodyText));
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const editorState = EditorState.createWithContent(contentState);
        setEditorState(editorState);
    }

    // useEffect(() => {
    //     if(!isMount) {
    //         if(selectedEmailId !== '-1' && !selectedEmailId.connected) {
    //             onConnectClick();
    //         }   
    //     }
    // }, [selectedEmailId])

    const generateDynamicMentionOptions = () => {
        //we need to generate dynamic array of options for subject and body dropdown seperatly 
        var mentionOptionsForBody = getMentionOptionsForBody(emailSchedulerMentionData);

        setMentionOptionsForBody(mentionOptionsForBody)
        
    }

    //consoleToLog('log******', allEmailsArr);

    // useEffect(() => {
    //     if(!isMount) {
    //         if(!textFieldClicked && emailCcs.length < 1) {
    //             setEmailCcClicked(false);
                
    //         }

    //         if(!textFieldClicked && emailBccs.length < 1) {
    //             setEmailBccClicked(false);
                
    //         }
            
    //         if(!textFieldClicked && emailCcs.length < 1 && emailBccs.length < 1) {
    //             setSelectedEmailString(sendEmailString.TO);
    //         }
    //     }

    // }, [textFieldClicked])

    const getEmailClientList = (query) => {
        const organization_id = props.selectedOrganization.organization?.id;

        if(!query) {
            setClientEmailList([]);
            return;
        }

        fullTextSearchApi(organization_id, query)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response fullTextSearchApi', res);
                setClientEmailList(res.contacts);
            })
            .catch((e) => {
                consoleToLog('Error fullTextSearchApi', e.response);
                if(e.response.data && e.response.data.message) {
                    return;
                }
            })
    }

    let toolbarConfig = {
        inline: {
            options: ['bold', 'italic', 'underline', 'strikethrough']
        },
        /* blockType: {
            options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code']
        }, */
        list: {
            options: ['unordered', 'ordered']
        },
        textAlign: {
            options: ['left', 'center', 'right', 'justify']
        },
        link: { options: ['link'] },
        options: ['inline', 'link',/* 'blockType', */ 'fontFamily', 'fontSize', 'colorPicker', 'list', 'textAlign'],
        order: 2
    };

    const handleReturn = (e) =>  {
        //console.log("eeeeeee:",e);
        if (e.keyCode===13) {
          ///this.setState({ editorState: insertSoftNewline(editorState) });
            setEditorState(RichUtils.insertSoftNewline(editorState));
            return 'handled';
        }
        return 'not-handled';
    }

    const handlePastedText = (text, html, editorState) => {
        var editorValue = editorState.getCurrentContent();
        var selection = editorState.getSelection();

        var newContent = Modifier.insertText(
            editorValue,
            selection,
            text
        )

        var newEditorState = EditorState.push(editorState, newContent, "insert-characters")
        //Then send this to onChange()
        setEditorState(newEditorState);
        return 'handled'
    }

    const onEditorStateChange = (editorState) => {
        setEditorState(editorState)
    }

    const onAttachmentSelected = (e) => {
        const { target: { files } } = e;
        //consoleToLog('attachment array: ', Array.from(files));
            const fileArr = Array.from(files);
            
            let totalSize = fileArr.reduce((sum, obj) => sum + obj.size, 0);
            if(totalSize > 20e6) {
                fileArr.map((fileObj) => setErrorAttachments(oldErrorAttachment => [...oldErrorAttachment, fileObj])); 
            } else {
                fileArr.map((fileObj) => setAttachment(oldAttachment => [...oldAttachment, fileObj]));
            }
    }

    const setBodyText = (emailDraft) => {
        if(props.forwardClick) {
            return `${renderContentForSubjectAndBody(emailDraft.body)}
                    ${displayForwardMessage()}`;
        } 
        else if(props.replyAllClick || props.replyClick) {
            return `${renderContentForSubjectAndBody(emailDraft.body)}
            ${displayEditorBodyData()}`;
        } else {
            return renderContentForSubjectAndBody(emailDraft.body);
        }


    }

    const setEmailDraftObj = (emailDraft) => {

        if(emailDraft !== '-1') {
            const emailBodyText = setBodyText(emailDraft);
            setEmailSubject(renderContentForSubjectAndBody(emailDraft.subject.replaceAll('*', '')));
            //setEditorValue(emailBodyText.replaceAll('*', ''));
            setEditorValue(emailBodyText.replaceAll('*', ''));
            let updatedAttachments = emailDraft.attachments && emailDraft.attachments.length > 0 ?
            emailDraft.attachments.map((attahmentObj) => {
                attahmentObj.bucket = process.env.REACT_APP_S3_EMAIL_DRAFT_BUCKET_NAME;
                attahmentObj.filename =  attahmentObj.path.replace(/^.*[\\\/]/, '');
                delete attahmentObj['attachment_id'];
                return attahmentObj
            }) : [];
            setAttachment(updatedAttachments);
        }
    }

    const handleEmailDraftChange = (e) => {
        setSelectedEmailDraft(e.target.value)
        const emailDraft = e.target.value;

        setEmailDraftObj(emailDraft);
    
    }

    const showAttachment = (file, index) => {
        return (
            <div className={classes.attachmentListStyle} key={index}>
                    <i className="flaticon-attachment" style={{marginRight:'3px'}}></i>
                    <Tooltip title={file.path ? file.path.replace(/^.*[\\\/]/, '') : file.name} arrow>
                        <Typography  variant="body1" className={classes.attachmentFilename} style={{marginTop:'-2px', marginRight:'3px'}}>
                            {file.path ? file.path.replace(/^.*[\\\/]/, '') : file.name}
                        </Typography>
                    </Tooltip>
                    <ClearIcon fontSize="small"
                            onClick={(e) => onRemoveAttachment(e, file)}
                            style={{cursor:'pointer', fontSize:'16px'}}/>
            </div>
            );
    }

    const onRemoveAttachment = (e, file) => {
        e.stopPropagation();
        let updateAttachment= attachment.filter((fileItem) => fileItem.name ? fileItem.name !== file.name : fileItem.filename !== file.filename);
        setAttachment(updateAttachment);
    }

    const onEmailCcClicked = () => {
        setEmailCcClicked(true);
        setSelectedEmailString(sendEmailString.CC);
    }

    const onEmailBccClicked = () => {
        setEmailBccClicked(true);
        setSelectedEmailString(sendEmailString.BCC);
    }


    const endAdornmentText = (params) => {
        return (
            <>
                <InputAdornment position="end" 
                    style={{display:'flex', alignItems:'center', marginLeft:'auto', opacity:textFieldClicked ? 1 : 0}}>
                    {!emailCcClicked &&
                    <Tooltip title='Add Cc recipients (Ctrl-Shift-C)'>
                        <Typography className={classes.lineText}
                            onClick={onEmailCcClicked}
                            style={{marginRight:'6px', cursor:"pointer"}}>
                            Cc
                        </Typography>
                    </Tooltip>
                    }
                    
                    {!emailBccClicked &&
                    <Tooltip title='Add Bcc recipients (Ctrl-Shift-B)'>
                        <Typography className={classes.lineText}
                            onClick={onEmailBccClicked}
                            style={{cursor:"pointer"}}>
                            Bcc
                        </Typography>
                    </Tooltip>}
                </InputAdornment>
                {params.InputProps.endAdornment}
            </>
        )
    }

    const onToEmailDivClicked = () => {
        setTextFieldClicked(true);
        if(emailCcs && emailCcs.length > 0) {
            setEmailCcClicked(true);
        }

        if(emailBccs && emailBccs.length > 0) {
            setEmailBccClicked(true);
        }
    }

    const handleInputChange = (e, newValue, setOpen) => {
        getEmailClientList(newValue);
            
        setInputText(newValue);
        if (!newValue) {
            setOpen(false);
        }
    }

    // const onAddCompany = (option) => {
    //     setAllEmailsArr(oldClients => [...oldClients, option]);
    // }
    const boldString = (string) => {
        let text = string ? string : '';
        let boldStringText = new RegExp(inputText, 'igm');
        let result =  text ? text.replace(boldStringText, '<strong>' + inputText + '</strong>') : '';
        return result;
    }

    const showStyledOptionsNames = (option) => {
        const fullName = option.first_name ? capitalizeFirstLetterOfEachWord(option.first_name) + " " + capitalizeFirstLetterOfEachWord(option.last_name) : option.email;
        if(option.first_name || option.email) {
            return (
                <span dangerouslySetInnerHTML={{ __html: boldString(fullName) }}>
                </span>
            )
        }
    }

    const showStyledOptionsEmail = (option) => {
            return (<span dangerouslySetInnerHTML={{ __html: boldString(option.email) }}></span>
            )
    }

    const showOptionClientList = (option) => {
        const clientList = option.clients && option.clients.length > 0 ? `(${option.clients.map((client) => client.name).join(', ')})` : '';
            return (
                <span dangerouslySetInnerHTML={{ __html: boldString(clientList) }}>
                </span>
            )
    }

    const renderOptions = (option) => {
        return (
            <div style={{display: "flex", alignItems: 'center', width:"100%"}} 
                className={classes.autoStyle}
                //onClick={() => onAddCompany(option)}     
            >
                <Avatar style={{marginRight: "6px", marginTop: "4px"}}  
                    classes={{
                        root: classes.avatar
                    }}
                >
                    {showAvatarInitialsForOptions(option)}
                </Avatar>
                <div style={{marginLeft:'8px'}}>
                    <Typography variant='subtitle1' style={{marginBottom:'0px', display:'flex', alignItems:'center'}}>
                        {showStyledOptionsNames(option)}
                        <Tooltip title={showClientAssociatedWithContact(option)} arrow>
                            <span className={classes.clientListStyle}>
                                {showOptionClientList(option)}
                            </span>
                        </Tooltip>
                    </Typography>
                    <Typography variant='subtitle1' style={{color:'grey'}}>
                        {showStyledOptionsEmail(option)}
                    </Typography>
                </div>
            </div>
        );
    }

    const onDeleteClient = (option, emailArr, setEmailArr) => {
        setEmailArr(emailArr.filter(entry => entry !== option));
    }

    const showAvatarInitials = (option) => {
        if(option.first_name || option.email) {
            return option.first_name ? option.first_name.charAt(0).toUpperCase() + option.last_name.charAt(0).toUpperCase() : option.email.charAt(0).toUpperCase();
        } else {
                return option.charAt(0).toUpperCase();
        }
    }

    const showAvatarInitialsForOptions = (option) => {
        if(option.first_name || option.email) {
            return option.first_name ? option.first_name.charAt(0).toUpperCase() + option.last_name.charAt(0).toUpperCase() : option.email.charAt(0).toUpperCase();
        } 
    }

    const showSelectedValueText = (option) => {
        if(option.first_name || option.email) {
            return  option.first_name ? capitalizeFirstLetterOfEachWord(option.first_name) + ' ' + capitalizeFirstLetterOfEachWord(option.last_name) : option.email;
        } else {
            return option;
        }
    }

    const showClientAssociatedWithContact = (option) => {
        return option.clients && option.clients.length > 0 ? `(${option.clients.map((client) => client.name).join(', ')})`: '';
    }

    const showTooltip = (option) => {
        return (
                <Paper elevation={0}>
                    <div style={{display:'flex', alignItems:'center'}}>
                        <Grid item>
                            <Avatar className={classes.avatar1}>
                                {showAvatarInitials(option)}
                            </Avatar>
                        </Grid>

                        <Grid item style={{marginLeft:'12px'}}>
                            <Typography className={classes.tooltipText}>
                                <b>{showSelectedValueText(option)}</b>
                                <span style={{marginLeft:'6px', fontSize:'12px', marginTop:'-4px'}}>
                                    {showClientAssociatedWithContact(option)}
                                </span>
                            </Typography>

                            {option.email &&
                                <Typography className={classes.tooltipText1}>
                                    {option.email}
                                </Typography>

                            }
                        </Grid>
                    </div>
                </Paper>

        )
    }

    const showTooltip1 = (option) => {
        return (
            <Paper elevation={0}>
                <div style={{display:'flex', alignItems:'center'}}>
                    <Grid item>
                        <Avatar className={classes.avatar1}>
                            {option?.title?.charAt(0)}
                        </Avatar>
                    </Grid>

                    <Grid item style={{marginLeft:'12px'}}>
                        <Typography variant='subtitle1' style={{marginBottom:'0px'}}>
                            {option.title} 
                            <span className={classes.subtitleStyle}>
                                {option.subtitle}
                            </span>
                        </Typography>

                        <Typography variant='body2' className={classes.templateName}>
                            {option.template_name}
                        </Typography>
                    </Grid>
                </div>
            </Paper>
        )
    }

    const renderTags = (options, getTagProps, emailArr, setEmailArr) => {
        return (
            options.map((option, index) =>
                <Fragment key={option?.id ? option?.id : option}>
                    <LightTooltip title={showTooltip(option)}>
                            <Chip
                                classes={{
                                    root: classes.chip
                                }}
                                avatar={<Avatar style={{position:'relative', top:'1px'}} classes={{
                                    root: classes.avatar
                                }}>{showAvatarInitials(option)}</Avatar>}
                                onDelete={() => onDeleteClient(option, emailArr, setEmailArr)}
                                variant="outlined"
                                style={{margin: "2px 2px", overflow:'hidden'}}
                                deleteIcon={<ClearIcon  style={{width: "18px"}}/>}
                                label={showSelectedValueText(option)} {...getTagProps({ index })}
                            />
                    </LightTooltip>
                </Fragment>
            ))
    }

    const handleKeyDown = (e, emailArr, setEmailArr) => {
        if(e.ctrlKey && e.shiftKey && e.keyCode === 67) {
            setEmailCcClicked(true);
            e.preventDefault();
            return;
        }

        if(e.ctrlKey && e.shiftKey && e.keyCode === 66) {
            setEmailBccClicked(true);
            e.preventDefault();
            return;
        }

        if(e.keyCode === 9 && !e.target.value) {
            e.stopPropagation();
            return;
        }

        if (e.keyCode === 13 && e.target.value && clientEmailList.length === 0) {
            addEmailToArr(e, emailArr, setEmailArr);
        }

        if (e.keyCode === 9 && e.target.value && clientEmailList.length === 0) {
            addEmailToArr(e, emailArr, setEmailArr);
        }

        if (e.keyCode === 9 && clientEmailList.length > 0) {
            setEmailArr(emailArr.concat(clientEmailList[0]));
            e.preventDefault();
        }

    }

    const onChange = (e, newValue, emailArr, setEmailArr) =>{
        if(newValue.length === 0) {
            setEmailArr(newValue);
        } else {
            let arrLength = newValue.length - 1;
            const errorEmail = emailValidator(newValue[arrLength].email ? newValue[arrLength].email : newValue[arrLength]);
            //const emailError1 = emailArr.filter((emailObj) => emailObj.email.includes(newValue[arrLength].email ? newValue[arrLength].email : newValue[arrLength]));
            if(!errorEmail) {
                return;
            } else {
                setEmailArr(newValue);
            }
        }
    }

    const addEmailToArr = (e, emailArr, setEmailArr) => {
        const emailError = emailValidator(e.target.value);
        //const emailError1 = emailArr.filter((emailObj) => emailObj.email.includes(e.target.value));
        if(!emailError) return;
        setEmailArr(emailArr.concat(e.target.value));
        e.preventDefault();
    }

    const showAutoCompleteTextField = (emailArr, setEmailArr, toString, open, setOpen) => {
        return (
            <Autocomplete
                multiple
                style={{width: "100%"}}
                id="size-small-outlined-multi"
                size="small"
                options={clientEmailList}
                open={open}
                onOpen={() =>  {
                    if(inputText) {
                        setOpen(true)
                    }
                }}
                onClose={() => setOpen(false)}
                onChange={(e, newValue) => onChange(e, newValue, emailArr, setEmailArr)}
                disableClearable
                onInputChange={(e, newValue) => handleInputChange(e, newValue, setOpen)}
                getOptionSelected={(option, value) => option?.email ? option.email === value.email : option === value}
                getOptionLabel={(option) => option?.email ? option.email : option}
                value={emailArr}
                renderOption={(option) => renderOptions(option)}
                renderTags={(options, getTagProps) => renderTags(options, getTagProps, emailArr, setEmailArr)}
                filterOptions={(options) => options}
                autoHighlight
                freeSolo
                onFocus={onToEmailDivClicked}
                onBlur={(e) => addEmailToArr(e, emailArr, setEmailArr)}
                renderInput={(params) => (
                <TextField
                    {...params}
                    margin='dense'
                    autoFocus
                    onKeyDown={(e) => handleKeyDown(e, emailArr, setEmailArr)}
                    InputProps={{ ...params.InputProps,
                        startAdornment: (
                            <>
                            <InputAdornment position="start">
                                <div style={{fontSize:'14px', color:'#747775'}}>
                                    {toString}
                                </div>
                            </InputAdornment>
                            {params.InputProps.startAdornment}
                            </>
                        ),
                        endAdornment: textFieldClicked && toString === selectedEmailString &&  endAdornmentText(params),
                        disableUnderline: true,
                        }}
                />
                )}
            />
        )
    }

    const renderOptions1 = (option) => {
        return (
            <div style={{display: "flex", alignItems: 'center', width:"100%"}} 
                className={classes.autoStyle}
            >
                <Avatar style={{marginRight: "6px", marginTop: "4px"}}  
                    classes={{
                        root: classes.avatar
                    }}
                >
                    {option.title.charAt(0)}
                </Avatar>
                <div style={{marginLeft:'8px'}}>
                    <Typography variant='subtitle1' style={{marginBottom:'0px'}}>
                        {option.title} 
                        <span className={classes.subtitleStyle}>
                            {option.subtitle}
                        </span>
                    </Typography>

                    <Typography variant='body2' className={classes.templateName}>
                        {option.template_name}
                    </Typography>
                </div>
            </div>
        );
    }

    const onDeleteChecklist = (option) => {
        setSelectedTasks(selectedTasks.filter(entry => entry !== option));
    }

    const renderTags1 = (options, getTagProps) => {
        return (
            options.map((option, index) =>
                <Fragment key={option.checklist_id}>
                        <LightTooltip title={showTooltip1(option)}>
                            <Chip
                                classes={{
                                    root: classes.chip
                                }}
                                avatar={<Avatar classes={{
                                    root: classes.avatar
                                }}>{option.title.charAt(0).toUpperCase()}</Avatar>}
                                onDelete={() => onDeleteChecklist(option)}
                                deleteIcon={<ClearIcon  style={{width: "18px"}}/>}
                                label={option.title} {...getTagProps({ index })}
                            />
                        </LightTooltip>
                </Fragment>
            ))
    }

    const getPendingChecklist = (text) => {
        const organization_id = props.selectedOrganization.organization.id;
        const clientsArr = allEmailsArr?.map((contact) => contact.clients);
        const clientsIdArr = clientsArr.flat().filter(client => client && client.id);
        const clients = clientsIdArr.map(client => client && client.id).join(',');

        if(!text) {
            setClientPendingCheckList([]);
            return;
        }

        getClientsPendingTasksApi(organization_id, clients, text)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response getClientsPendingTasksApi', res);
                
                setClientPendingCheckList(res);

            })
            .catch((e) => {
                consoleToLog('Error getClientsPendingTasksApi', e.response);
                if(e.response.data && e.response.data.message) {
                    return;
                }
            })
    }

    const handleInputChange1 = (e, newValue) => {
        getPendingChecklist(newValue);
            
        setInputText1(newValue);
        if (!newValue) {
            setShowTasks(false);
        }
    }

    const showAutoCompleteTextField1 = () => {
        return (
            <Autocomplete
                multiple
                style={{width: "100%"}}
                size="small"
                open={showTasks}
                onOpen={() =>  {
                    if(inputText1) {
                        setShowTasks(true)
                    }
                }}
                onClose={() => setShowTasks(false)}
                options={clientPendingCheckList}
                getOptionSelected={(option, value) => option.checklist_id === value.checklist_id}
                getOptionLabel={(option) => option.title + option.subtitle + option.template_name}
                renderOption={(option) => renderOptions1(option)}
                disableClearable
                value={selectedTasks}
                onChange={(e, newValue) => {
                    setSelectedTasks(newValue);
                }}
                onInputChange={(e, newValue) => handleInputChange1(e, newValue)}
                //onInputChange={(e, newValue) => handleInputChange1(e, newValue)}
                renderTags={(options, getTagProps) => renderTags1(options, getTagProps)}
                renderInput={(params) => (
                    <TextField {...params} 
                        placeholder="Tasks"
                        InputProps={{ ...params.InputProps, disableUnderline: true}}
                        />
                )}
                disabled={emails.length === 0 && emailCcs.length === 0 && emailBccs.length === 0}
            />
        )
    }

    // const handleInputChange1 = (newValue) => {
    //     getPendingChecklist();

    //     setInputText1(newValue);
    //     if (!newValue) {
    //         setOpen(false);
    //     }
    // }


    const onSendEmailClick = () => {
        
        const from_email = selectedEmailId?.email;
        if(selectedEmailId === '-1') {
            enqueueSnackbar('Please select the email to send from', {variant:'error'});
            return;
        }
        
        const toEmails = emails && emails.length > 0 ? emails.map((email) => email.email ? email.email : email) : [];
        const CCEmails = emailCcs && emailCcs.length > 0 ? emailCcs.map((email) => email.email ? email.email : email) : [];
        const BCCEmails = emailBccs && emailBccs.length > 0 ? emailBccs.map((email) => email.email ? email.email : email) : [];
        if(toEmails.length === 0 && CCEmails.length === 0 && BCCEmails.length === 0) {
            enqueueSnackbar('Please specify at least one recipient.', {variant:'error'});
            return;
        }


        const subject = emailSubject;
        if(subject === '') {
            enqueueSnackbar('Please enter email subject.', {variant:'error'});
            return;
        }

        // if (!editorState.getCurrentContent().hasText()) {
        //     enqueueSnackbar('Email body can not be empty!', {
        //         variant:'error'
        //     });
        //     return;
        // }
        // const body_text = draftToHtml(convertToRaw(editorState.getCurrentContent()));

        const body_text = editorValue;
        if (!body_text) {
            enqueueSnackbar('Email body can not be empty!', {
                variant:'error'
            });
            return;
        }

        if(interval) {
            if(intervalDays === '' || parseInt(intervalDays) === 0) {
                enqueueSnackbar('Please enter interval days', {variant:'error'})
                return;
            }

            if(maxCount === '' || parseInt(maxCount) === 0) {
                enqueueSnackbar('Please enter max count', {variant:'error'});
                return;
            }

        }

        const emailChecklistIds = props.fromReplyForwardEmailDetails ? props.selectedEmailItem?.metadata?.checklist_ids : [];
        const checklist_ids = selectedTasks && selectedTasks.length > 0 ? selectedTasks.map((checklist) => checklist.checklist_id) : [];
        const followups = selectedTasks && selectedTasks.length > 0 ? selectedTasks.map((checklist) => checklist.followups && checklist.followups.length > 0 ? checklist.followups : []) : [];
        const followupsArr = followups && followups.length > 0 ? followups.flat() : [];

        const redirect_uri = selectedEmailId?.email_type === 'gmail' ? process.env.REACT_APP_GMAIL_REDIRECT_URL : process.env.REACT_APP_OUTLOOK_REDIRECT_URL;
        const metadata = (emailChecklistIds && emailChecklistIds.length > 0 || checklist_ids && checklist_ids.length > 0) ? {checklist_ids: [...checklist_ids, ...emailChecklistIds], followups: followupsArr, organization_id: props.selectedOrganization.organization.id} : undefined;
        const from_name = props.selectedOrganization.organization.name;
        const companyIds = selectedTasks && selectedTasks.length > 0 ? selectedTasks.map((item) => item.company_id) : [];
        const company_ids = companyIds.filter((item, index) => companyIds.indexOf(item) === index);
        const email_uuid = props.fromEmailDetails && props.selectedEmailItem?.id; 
        const apiPathStr = props.fromEmailDetails && sendApiPath(props.replyClick, props.replyAllClick, props.forwardClick);
        const onlyEmailAttachments = attachment && attachment.length > 0 && attachment.filter((fileObj) => fileObj.lastModified);
        const is_repeat = interval;

        props.setCurrentEmailState(data);
        props.setOvelayMessage(OverlayMessage.SEND_EMAIL);
        props.setOverlayView(OverlayView.SHOW_OVERLAY_LOADING);
        props.setIsEmailSend(true);
        closeSendEmailDialog();

        setLoading(true);
        if(onlyEmailAttachments && onlyEmailAttachments.length > 0) {
            callUploadUrlApi(from_email, toEmails, CCEmails, BCCEmails, subject, body_text, checklist_ids, metadata, redirect_uri, from_name, company_ids, followupsArr, email_uuid, apiPathStr, onlyEmailAttachments, is_repeat);
        } else {
            if(interval) {
                callCreateFollowupApi(from_email, toEmails, CCEmails, BCCEmails, subject, body_text, checklist_ids, attachment, redirect_uri, from_name, company_ids, followupsArr, email_uuid, apiPathStr, is_repeat);
            } else {
                if(props.fromEmailDetails) {
                    callReplyForwardEmailApi(email_uuid, from_email, toEmails, CCEmails, BCCEmails, subject, body_text, attachment, redirect_uri, metadata, from_name, apiPathStr, is_repeat)
                } else {
                    callSendEmailApi(from_email, toEmails, CCEmails, BCCEmails, subject, body_text, metadata, attachment, redirect_uri, from_name, company_ids, is_repeat);
                }
            }
        }

    }

    const callUploadUrlApi = async (from_email, toEmails, CCEmails, BCCEmails, subject, body_text, checklist_ids, metadata, redirect_uri, from_name, company_ids, followupsArr, email_uuid, apiPathStr, onlyEmailAttachments, is_repeat) => {
        let fileAttachments = [];
        let draftAttachments = attachment.filter((obj) => obj.path);
        for(let i = 0; i < onlyEmailAttachments.length; i++) {
                const file_name = onlyEmailAttachments[i].name;
                const file_type = onlyEmailAttachments[i]?.type;
                const file = onlyEmailAttachments[i];
                const bucket = process.env.REACT_APP_S3_EMAIL_SEND_BUCKET_NAME;

                const file_path = `mails/sent/${file_name}`;

                uploadAttachmentApi(file_path, file_type, bucket, file)
                .then((data) => {
                    consoleToLog('upload success', data);

                    let obj = {bucket: bucket, path: file_path, name: file_name, type: file_type};
                    fileAttachments.push(obj);
                    consoleToLog('fileAttachments', fileAttachments, fileAttachments.length === onlyEmailAttachments.length)
                    if(fileAttachments.length === onlyEmailAttachments.length) {
                        const finalAttachmentArray = draftAttachments && draftAttachments.length > 0 ? fileAttachments.concat(draftAttachments) : fileAttachments;
                        if(interval) {
                            callCreateFollowupApi(from_email, toEmails, CCEmails, BCCEmails, subject, body_text, checklist_ids, finalAttachmentArray, redirect_uri, from_name, company_ids, followupsArr, email_uuid, apiPathStr, is_repeat);
                        } else {
                            if(props.fromEmailDetails) {
                                callReplyForwardEmailApi(email_uuid, from_email, toEmails, CCEmails, BCCEmails, subject, body_text, finalAttachmentArray, redirect_uri, metadata, from_name, apiPathStr, is_repeat);
                            } else {
                                callSendEmailApi(from_email, toEmails, CCEmails, BCCEmails, subject, body_text, metadata, finalAttachmentArray, redirect_uri, from_name, company_ids, is_repeat);
                            }
                        }
                    }
                })
                .catch((e) => {
                    consoleToLog('upload error', e);
                    props.setOvelayMessage('');
                    props.setOverlayView(OverlayView.NO_VIEW);
                    props.setIsEmailSend(false);
                    props.handleSendEmailDialogOpen();
                })
        }

    }

    const callCreateFollowupApi = (from_email,  toEmails, CCEmails, BCCEmails, subject, body_text, checklist_ids, attachment, redirect_uri, from_name, company_ids, followupsArr, email_uuid, apiPathStr, is_repeat) => {

        const organization_id = props.selectedOrganization?.organization.id;

        createEmailFollowupApi(organization_id, subject, body_text, attachment, selectedEmailId?.id, toEmails, CCEmails, BCCEmails, checklist_ids, parseInt(maxCount), parseInt(intervalDays), selectedEmailDraft?.id)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response createEmailFollowupApi', res);
                props.selectedTemplate && props.setChecklistArray(res.checklists);
                if(props.fromChecklistDetails) { 
                    let obj = {
                        email_followup : res.email_followup,
                        checklists : res.checklists
                    }
                    props.setEmailFollowupObject(obj)
                };

                const metadata = {checklist_ids: checklist_ids && checklist_ids.length > 0 ? checklist_ids : [], followups: [{id: res.email_followup?.id, subject: res.email_followup?.subject}, ...followupsArr], organization_id: props.selectedOrganization.organization.id}
                if(props.fromEmailDetails) {
                    callReplyForwardEmailApi(email_uuid, from_email, toEmails, CCEmails, BCCEmails, subject, body_text, attachment, redirect_uri, metadata, from_name, apiPathStr, is_repeat);
                } else {
                    callSendEmailApi(from_email, toEmails, CCEmails, BCCEmails, subject, body_text, metadata, attachment, redirect_uri, from_name, company_ids, is_repeat)
                }
            })
            .catch((e) => {
                consoleToLog('Error createEmailFollowupApi', e);
                props.setOvelayMessage('');
                props.setOverlayView(OverlayView.NO_VIEW);
                props.setIsEmailSend(false);
                props.handleSendEmailDialogOpen();
                if(e.response.data && e.response.data.message) {
                    return;
                }
            })

    }

    const callSendEmailApi = (from_email, toEmails, CCEmails, BCCEmails, subject, body_text, metadata, attachments, redirect_uri, from_name, company_ids, is_repeat) => {
        data.uploadedAttachment = attachments;
        props.setCurrentEmailState(data);

        sendEmailApi(selectedEmailId?.id, from_email, toEmails, CCEmails, BCCEmails, subject, body_text, attachments, metadata, redirect_uri, from_name, company_ids, is_repeat)
            .then((response) => {
                const res = response.data
                consoleToLog('Response sendEmailApi, ', res);
                props.setEmailItemId(res.emaild_id);
                setLoading(false);
                
            })
            .catch((e) => {
                consoleToLog("Error sendEmailApi, ", e);
                setLoading(false);
                enqueueSnackbar(e.response.data.message, {variant: 'error'});
                props.setOvelayMessage('');
                props.setOverlayView(OverlayView.NO_VIEW);
                props.setIsEmailSend(false);
                props.handleSendEmailDialogOpen();
                props.setChecklistArray([]);
                props.setFromChecklistDetailsPage(false);
                props.setEmailFollowupObject(undefined);
                if(e.response.status === 401 && e.response.data && e.response.data.message) {
                    props.setIsEmailSendError(true);
                    return;
                }
            })
    }

    const callReplyForwardEmailApi = (email_uuid, from_email, toEmails, CCEmails, BCCEmails, subject, body_text, attachments, redirect_uri, metadata, from_name, apiPathStr, is_repeat) => {
        data.uploadedAttachment = attachments;
        props.setCurrentEmailState(data);

        const company_ids =  props.fromReplyForwardEmailDetails && props.selectedEmailItem?.company_ids;


        replyForwardEmailApi(selectedEmailId?.id, email_uuid, from_email, toEmails, CCEmails, BCCEmails, subject, body_text, attachments, redirect_uri, metadata, from_name, apiPathStr, company_ids, is_repeat)
            .then((response) => {
                const res = response.data
                consoleToLog('Response sendEmailApi, ', res);
                props.setEmailItemId(res.emaild_id);
                setLoading(false);
                
            })
            .catch((e) => {
                consoleToLog("Error sendEmailApi, ", e.response);
                setLoading(false);
                enqueueSnackbar(e.response.data.message, {variant: 'error'});
                props.setOvelayMessage('');
                props.setOverlayView(OverlayView.NO_VIEW);
                props.setIsEmailSend(false);
                props.handleClose();
                if(e.response.status === 401 && e.response.data && e.response.data.message) {
                    props.setIsEmailSendError(true);
                    return;
                }
            })
    }

    const onConnectClick = (obj) => {
            data.selectedEmailId = selectedEmailId === '-1' ? obj : selectedEmailId;
            props.setCurrentEmailState(data);
            props.setIsEmailSendError(true);
            handleOpen();
    }

    const daysLabel = (val) => {
        return {
            inputProps: {min: 1},
            endAdornment: 
                        <InputAdornment  position="end" className={classes.daysLabel}>
                            <Typography style={{color:'#555555'}}>
                                {(val === 'days') ? 'days' : 'times'}
                            </Typography>
                        </InputAdornment>
        }
    }

    const closeSendEmailDialog = () => {
        props.fromEmailDetails ? props.handleClose() : props.handleSendEmailDialogClose();
        setEmails([]);
        setEmailCcs([]);
        setEmailBccs([]);
        setEmailCcClicked(false);
        setEmailBccClicked(false);
        setTextFieldClicked(false);
        setClientEmailList([]);
        setClientPendingCheckList([]);
        setSelectedTasks([]);
        setSelectedEmailDraft('-1');
        setSelectedEmailId('-1');
        setEditorState(EditorState.createEmpty());
        setInterval(false);
        setIntervalDays(2);
        setMaxCount(3);
    }

    const onManualClose = () => {
        closeSendEmailDialog();
        props.setCurrentEmailState(undefined);
        props.fromChecklistDetails && props.setFromChecklistDetailsPage(false);
        props.isEmailSendError && props.setIsEmailSendError(false);
    }

    const handleCloseErrorModal = () => {
        setErrorAttachments([]);
    }

    return (
        <Dialog
            fullScreen={false}
            open={props.openSendEmailDialog}
            onClose={onManualClose}
            scroll="paper"
            classes={{
                paper: classes.MuiDialogpaper}}
            maxWidth="md"
            fullWidth
            >

                <DialogContent dividers style={{padding: '0px 0px 16px 0px'}}>
                    {//selectedEmailId !== '-1' && emailError ?
                    
                    <Grid item container direction='column'>
                        <Grid item sm={12}
                            style={{marginBottom:'6px', padding:'4px 16px', background:'#f2f6fc'}}>

                            <Grid item container justifyContent='space-between'>
                                <Grid item sm={11}>
                                <Select
                                    variant='standard'
                                    fullWidth
                                    select
                                    style={{fontSize:'14px', color: '#041E49', fontWeight: 500, width:"50%"}}
                                    onChange={handleEmailDraftChange}
                                    value={selectedEmailDraft}
                                    disableUnderline
                                    classes={{
                                        icon: selectedEmailDraft !== '-1' ? classes.selectIcon1 : classes.selectIcon
                                        }}
                                    renderValue={() => {
                                        const item = props.orgEmailDraftList.find((email) => email.title === selectedEmailDraft.title); 
                                        return item ? item.title : 'New Email'
                                        }}
                                >
                                    <MenuItem value='-1'>New Email</MenuItem>
                                    {
                                        props.orgEmailDraftList && props.orgEmailDraftList.length &&
                                        props.orgEmailDraftList.map((obj) => {
                                            return  <MenuItem key={obj.id} value={obj}>
                                                        {obj.title}
                                                    </MenuItem>
                                        })
                                    }
                                </Select>
                                </Grid>

                                <Grid item sm={1} style={{flexBasis:'2.8%', maxWidth:'2.8%'}}>
                                    <Button onClick={onManualClose}
                                        className={classes.emailCloseButton}> 
                                            <ClearIcon fontSize='small'/>
                                    </Button>
                                </Grid>
                            </Grid>

                        </Grid>
                        <Grid item sm={12} className={classes.customPadding}>
                            <Grid item container alignItems='center'>
                                <Grid item md={1}  className={classes.customColumn}>
                                    <Typography className={classes.textStyles}>
                                        From
                                    </Typography>
                                </Grid>

                                {<Grid item>
                                    <Select
                                        fullWidth
                                        className={classes.textStyles}
                                        style={{width:"100%", lineHeight:'16px'}}
                                        onChange={handleChange}
                                        value={selectedEmailId}
                                        disableUnderline
                                        classes={{
                                            icon: selectedEmailId !== '-1' ? classes.selectIcon3 : classes.selectIcon2
                                            }}
                                        renderValue={() => {
                                            const item = props.orgEmailList.find((email) => email?.email === selectedEmailId.email); 
                                            return item ? item.email : 'Please select email'
                                            }}
                                        
                                    >
                                        <MenuItem value='-1'> Please select email</MenuItem>
                                        {
                                            props.orgEmailList && props.orgEmailList.length &&
                                            props.orgEmailList.map((obj) => {
                                                
                                                return   obj.verified &&
                                                        <MenuItem key={obj.id} value={obj}>
                                                            {obj.email}
                                                        </MenuItem>
                                            })
                                        }
                                    </Select>
                                </Grid>
                                }
                                {
                                    selectedEmailId !== '-1' && !selectedEmailId?.connected &&
                                    <Grid item>
                                        <Typography onClick={onConnectClick} 
                                            className={classes.connectLinkStyle}>
                                            Connect
                                        </Typography>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                        
                        <ClickAwayListener onClickAway={(e) => {
                                    setTextFieldClicked(false);
                                    if(allEmailsArr.length > 0) {
                                        getPendingChecklist();
                                    }
                        }}>
                            <Grid item sm={12}
                                className={`${classes.customBorder} ${classes.customPadding}`}>
                                
                                    {showAutoCompleteTextField(emails, setEmails, 'To', openDropdown, setOpenDropdown)}

                                    {emailCcClicked && showAutoCompleteTextField(emailCcs, setEmailCcs, 'Cc', openDropdown1, setOpenDropdown1)}

                                    {emailBccClicked && showAutoCompleteTextField(emailBccs, setEmailBccs, 'Bcc', openDropdown2, setOpenDropdown2)}
                                    
                            </Grid>
                        </ClickAwayListener>

                        {!props.fromReplyForwardEmailDetails &&
                        <Grid item sm={12}
                            style={{padding:'8px 0px'}}
                            className={`${classes.customBorder} ${classes.customPadding}`}>

                                {showAutoCompleteTextField1()}
                            
                        </Grid>}

                        <Grid item sm={12} className={`${classes.customBorder} ${classes.customPadding}`}>
                            <TextField variant='standard'
                                fullWidth
                                margin='dense'
                                className={classes.textStyles}
                                style={{padding:'4px 0px 4px 0px', background:'#fff', color:'#041E49', border:'none'}}
                                value={emailSubject}
                                onChange={(e) => setEmailSubject(e.target.value)}
                                placeholder='Subject'
                                inputProps={{style:{fontSize: '14px', color:'#747775'}}}
                                InputProps={{disableUnderline: true}}
                            />
                        </Grid>

                        <Grid item sm={12} 
                            className={classes.customPadding}
                            style={{marginTop:'8px', position:'relative'}}>
                            
                            <div>
                                <JoditEditor
                                    ref={editor}
                                    value={editorValue}
                                    config={config}
                                    tabIndex={1}
                                    onBlur={newContent => setEditorValue(newContent)} // preferred to use only this option to update the editorValue for performance reasons
                                    //onChange={(newContent) => setEditorValue(newContent)}
                                />
                                {/* <Editor
                                    apiKey={process.env.REACT_APP_TINY_CLOUD_MCE_API_KEY}
                                    value={editorValue}
                                    init={{
                                        height:350,
                                        menubar: false,
                                        auto_focus:props.fromEmailDetails && true ,
                                        selector: 'textarea',
                                        plugins: "link lists table",
                                        //images_upload_url: 'postAcceptor.php',
                                        automatic_uploads: true,
                                        content_style:
                                            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }",
                                        images_file_types: "jpg,svg,png",
                                        toolbar: 'undo redo | styleselect | fontsize forecolor | bold italic | align bullist numlist | outdent indent | table',
                                        fontsize_formats:
                                        "8pt 9pt 10pt 11pt 12pt 14pt 18pt 24pt 30pt 36pt 48pt 60pt 72pt 96pt",
                                        //paste_data_images: true
                                    }}
                                    onClick={() => setTextFieldClicked(false)}
                                    onEditorChange={(data) => setEditorValue(data)}
                                    />  */}
                                </div>
                        </Grid>

                        <Grid item sm={12} className={classes.customPadding}>
                                <Grid item container alignItems='center' style={{marginTop:'16px'}}>
                                {
                                    attachment && attachment.length > 0 &&
                                    attachment.map((file, index) => {
                                        return showAttachment(file, index);
                                    })
                                }
                                </Grid>
                        </Grid>

                        <Grid item sm={12}
                            className={classes.customPadding}
                            style={{marginTop:'8px'}}>
                            <Grid item container
                                alignItems='center'>

                                <Grid item >
                                    <Button onClick={onSendEmailClick}
                                        className={classes.sendButtonStyle}
                                        disabled={selectedEmailId !== '-1' && selectedEmailId?.connected === false}
                                        variant='outlined'
                                        color='primary'>
                                        {loading && <CircularProgress size={18} style={{marginRight:'8px', color:"#fff"}}/>}
                                        Send Email
                                    </Button>
                                </Grid>

                                <Grid item>
                                    <Tooltip title='Attach files'>
                                        <Button  component="label" size='small'
                                            disableTouchRipple
                                            className={classes.attachLink}>
                                                <AttachFileIcon fontSize='small'/>
                                        <input style={{display:'none'}}
                                            type="file"
                                            multiple
                                            id="emailSchedulerAttachment"
                                            onChange={onAttachmentSelected}
                                            accept='.xlsx, .xls, .doc, .pdf, .docx, .jpg, .jpeg, .png, .svg, .fvu, .zip, .001'
                                        />
                                        </Button>
                                    </Tooltip>
                                </Grid>

                                {!props.forwardClick &&
                                <Grid item md={3} style={{flexBasis:'10%', maxWidth:'10%'}}>
                                    <FormControlLabel
                                        classes={{
                                            root: classes.MuiFormControlLabelroot
                                        }}
                                        control={
                                        <Checkbox
                                            name="checkedB"
                                            color="primary"
                                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                                            checked={interval}
                                            onChange={(event) => setInterval(event.currentTarget.checked)}
                                            onClick={(e) => e.stopPropagation()}
                                        />
                                        }
                                        label="Repeat"
                                    />
                                </Grid>}
                                
                                <Grid item md={3} style={{marginRight:'16px', marginTop:'-4px', visibility:interval ? 'visible' : 'hidden'}}>
                                    <Grid item container alignItems="center">
                                        <Grid item md={2}>
                                            <span style={{color:'#555', fontSize:'14px', position:'relative', top:'1px'}}>
                                                Every
                                            </span>  
                                        </Grid> 
                                        <Grid item md={8} style={{marginLeft:'6px'}}>
                                            <TextField 
                                                fullWidth
                                                type="number"
                                                autoComplete='off'
                                                id="interval_days"
                                                margin="dense"
                                                variant="outlined"
                                                disabled={!interval}
                                                value={intervalDays}
                                                onChange={(e) => setIntervalDays(e.target.value)}
                                                InputProps={daysLabel('days')}
                                                classes={{
                                                    root: classes.MuiTextFieldroot
                                                }}
                                                onKeyPress={inputNumberValidate}
                                            />   
                                        </Grid>   
                                    </Grid>  
                                </Grid>

                                <Grid item md={3} style={{marginTop:'-4px', visibility:interval ? 'visible' : 'hidden'}}>
                                    <Grid item container alignItems="center">
                                        <Grid item md={3}>
                                            <span style={{color:'#555', fontSize:'14px'}}>
                                                Maximum
                                            </span>  
                                        </Grid> 

                                        <Grid item md={8} style={{marginLeft:'16px'}}>
                                            <TextField 
                                                fullWidth
                                                type="number"
                                                autoComplete='off'
                                                id="max_count"
                                                margin="dense"
                                                variant="outlined"
                                                value={maxCount}
                                                disabled={!interval}
                                                onChange={(e) => setMaxCount(e.target.value)}
                                                InputProps={daysLabel('times')}
                                                classes={{
                                                    root: classes.MuiTextFieldroot
                                                }}
                                                onKeyPress={inputNumberValidate}
                                                
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>  

                            </Grid>
                        </Grid>
                    </Grid>}
                </DialogContent>
                {
                    open &&
                    <SendEmailErrorModal selectedEmailId={selectedEmailId}
                        open={open}
                        handleClose={handleClose}
                    />
                }
                {
                    errorAttachments && errorAttachments.length > 0 && 
                    <EmailAttachmentErrorModal openErrorModal={errorAttachments && errorAttachments.length > 0} 
                        handleCloseErrorModal={handleCloseErrorModal}
                        errorAttachments={errorAttachments}
                        />
                }
        </Dialog>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    orgEmailList: state.organizationInfo.orgEmailList,
    orgEmailDraftList: state.organizationInfo.orgEmailDraftList,
    currentEmailState: state.organizationInfo.currentEmailState,
    isEmailSendError: state.organizationInfo.isEmailSendError,
    fromChecklistDetails: state.organizationInfo.fromChecklistDetails,
    selectedTemplate: state.organizationInfo.selectedTemplate,
    isLoading: state.loading.isLoading
});

const mapDispatchToProps = (dispatch) => ({
    setOverlayView: (exportView) => dispatch(setOverlayView(exportView)),
    setOverlayToken: (randomToken) => dispatch(setOverlayToken(randomToken)),
    setEmailItemId: (emailItemId) => dispatch(setEmailItemId(emailItemId)),
    setOvelayMessage: (overlayMessage) => dispatch(setOvelayMessage(overlayMessage)),
    setIsEmailSend: (isEmailSend) => dispatch(setIsEmailSend(isEmailSend)),
    setCurrentEmailState: (emailState) => dispatch(setCurrentEmailState(emailState)),
    setIsEmailSendError: (emailSendError) => dispatch(setIsEmailSendError(emailSendError)),
    setEmailFollowupObject: (emailFollowup) => dispatch(setEmailFollowupObject(emailFollowup)),
    setChecklistArray: (checklistArray) => dispatch(setChecklistArray(checklistArray)),
    setFromChecklistDetailsPage: (fromChecklistDetails) => dispatch(setFromChecklistDetailsPage(fromChecklistDetails))
})

export default connect(mapStateToProps, mapDispatchToProps)(SendEmailDialog);