import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles'; 
import { Grid, Container, Typography,  Button, Popover, Tooltip, withStyles, CircularProgress } from '@material-ui/core';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import { addOrgMember } from '../actions/selectedOrganization';
import { DateRangePicker } from "materialui-daterange-picker";
import moment from 'moment';
import { useIsMount } from './useIsMount';
import { consoleToLog, trackGAEvent } from '../util/AppUtil';
import RightToLeftSideDrawer from './RightToLeftSideDrawer';
import ChecklistCompaniesFilter from './filters/ChecklistCompaniesFilter';
import Chart from 'react-apexcharts';

import Paper from '@material-ui/core/Paper';
import { getClientWorkReportApi } from '../services/reportService';
import ChecklistReportsDrawer from './ChecklistReportsDrawer';
import { getChecklistForClientWorkReport } from '../services/reportService';
import { red } from '@material-ui/core/colors';



const useStyles = makeStyles((theme) => ({
    mainContent : {
        width: '100%',
        // backgroundColor: theme.palette.primary.light, 
        transition: ".5s", 
        flexGrow: 1,
        //overflow:'hidden',
        overflow:'auto',
        overflowX:'hidden'
        },
    
    container: {
        marginTop: "8px"
    },
    graphMain: {
        padding: "1rem !important",
        fontSize: "11px !important",
        marginTop: "1rem !important",
        boxShadow: "0 .5rem 1rem rgba(0,0,0,.15) !important",
        width: "32rem !important",
        height: "21rem !important",
        marginLeft: "40px !important",
        marginBottom: "50px"
    },

    icons : {
        color: "black",
        fontSize: "18px",
        minWidth:'30px',
        marginLeft: "8px",
        marginTop:'-3px'
    } ,
    notAttachmentsImage: {
        height: "13rem",
        width: "15rem",
        marginTop: "3rem",
      },
      notAttachmentsText: {
        fontSize: "20px",
        fontWeight: "600",
      },
      notAttachmentsButton: {
        fontSize: "15px",
        width: "19rem",
        marginTop: "20px",
      },
      
    })
    
)

const ClientWorkReport = (props) => {
    const {showDrawer} = props;
    const classes = useStyles(showDrawer);
    const { enqueueSnackbar } = useSnackbar();
    const [anchorEl, setAnchorEl] = useState(false);
 
    const [clearFilter, setClearFilter] = useState(false);
    const [selectedCompanies, setSelectedCompanies] = useState([]);
    const [completionDataForClient, setCompletionDataForClient] = useState([]);
    
    const [openDrawer, setOpenDrawer] = useState(false);
    const [openFilters, setOpenFilters] = useState(false);

    const [page, setPage] = useState(1);
    const [nextPage, setNextPage] = useState(false);
    const [isMoreLoading, setIsMoreLoading] = useState(false);
    const [clientDataLoading,setClientDataLoading] = useState(false);
    const isMount = useIsMount(); 
    const [tempObj, setTempObj] = useState(undefined);
    const [tempObj1, setTempObj1] = useState(undefined);
    const [statusValue, setStatusValue] = useState(undefined);
    const [openChecklistDrawer, setOpenChecklistDrawer] = useState(false);
    const [checklistLoading, setChecklistLoading] = useState(false);
    const [checkList, setCheckList] = useState([]);
    const [reportObj, setReportObj] = useState(undefined);
    
  
    const checklistDetails = window.location.toString().includes("checklist");

    useEffect(() => {
        if(!props.fromClientDetails) {
            document.title = 'Completion Reports'
        }
    }, []);

    useEffect(() => {
        if(!isMount) {
            if(!checklistDetails) {
                document.title = 'Completion Reports'
            }
        }
    }, [checklistDetails]);

    useEffect(() => {
        if(!isMount) {
            if(props.clientTab) {
                toggleDrawer();
                setOpenFilters(!openFilters);
        }
        }
    }, [props.clientTab]);


    const open = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onUpdateClearFilterValue = () => {
        setClearFilter(false);
        if(!props.fromClientDetails)
        props.setFilterCountForClient(0);
    }

    const onSelectedCompanies = (sts) => {
        setSelectedCompanies(sts);
        setTempObj(undefined);
        setTempObj1(undefined);
    }

    const handleChecklistDrawerOpen = () => {
        setOpenChecklistDrawer(true);
    }

    const handleChecklistDrawerClose = () => {
        setOpenChecklistDrawer(false);
    }

    //api
    const getCompletionReportOfClient = () => {
        consoleToLog("Api: selectedCompanies", selectedCompanies);
        

        const organization_id = props.selectedOrganization.organization.id;

         if(!props.fromClientDetails)
         {
            if(!selectedCompanies || selectedCompanies.length === 0)
            {
                setCompletionDataForClient([]); 
                return
            }
         }
      

        const ids = props.fromClientDetails ? props.clientId : selectedCompanies.map((l) => l.id).join(',');
        setClientDataLoading(true);
        
        getClientWorkReportApi(organization_id, ids )
            .then((response) => {
                const res = response.data;
                consoleToLog("Response getClientWorkReportApi: ", res);
                setCompletionDataForClient(res);
                if(!props.fromClientDetails)
                props.setFilterCountForClient(1);

                if (isMoreLoading) {
                    const newItems = completionDataForClient.concat(res);
                    setCompletionDataForClient(newItems);
                    setNextPage(res.next_page);
                    setPage(page + 1);
                    setIsMoreLoading(!isMoreLoading);

                } else {
                    trackGAEvent(props.selectedOrganization.organization.name, 'Filter ClientWork Report', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
                    setNextPage(res.next_page)
                    if (res.next_page) setPage(page + 1);
                    setCompletionDataForClient(res);
                }
                setClientDataLoading(false);
            })
             .catch((e) => {
                 consoleToLog("getClientWorkReportApi error", e.response);
                 if (e.response.data && e.response.data.message) {
                     enqueueSnackbar(e.response.data.message, {
                         variant:'error'
                     });
                    return false;
                 }
                 setClientDataLoading(false);
             });
    }

    useEffect(() => {
        if(!isMount) {
            consoleToLog("***On filter change  in useeffect");
            pageReset();
        }
    }, [selectedCompanies])
    
    useEffect(() => {
        if(!isMount) {
            if (page === 1) {
                consoleToLog("***On page change  in useeffect");
                getCompletionReportOfClient();
            }
        }    
    }, [page]);

    useEffect(() => {
        //consoleToLog("hi", isMoreLoading);
        if(!isMount) {
            if (isMoreLoading) {
                consoleToLog("***On more loading  in useeffect");
                getCompletionReportOfClient();
            }
        }
    }, [isMoreLoading])


    useEffect(() => {
                getCompletionReportOfClient();
             },[selectedCompanies]);

    useEffect(() => {
               getCompletionReportOfClient();
        
             },[props.fromClientDetails,props.clientId]);
    
        
    useEffect(() => {
        if(!isMount) {
            if(page === 1 && tempObj1 && reportObj) {
                callgetChecklistForClientWorkReport();
            }
        }
    }, [tempObj1, statusValue, page, reportObj]);

    useEffect(() => {
        if(!isMount) {
            if(isMoreLoading) {
                callgetChecklistForClientWorkReport();
            }
        }
    }, [isMoreLoading]);

    const toggleDrawer = () => {
        setOpenDrawer(open => !open)
        setAnchorEl(null);
    }

    const setDrawerOpen = () => {
        setOpenDrawer(false);
        setOpenFilters(false);
        props.setClientTab(false);
    }


    const ReportsNotFound = () => {
        return (
        <div>
            {/* Not Found*/}
            <Grid container direction="row">
            <Grid
                item
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
                sm={12}
            >
                <img
                alt="No Reports Found"
                src="/images/no_attachment_found.svg"
                className={classes.notAttachmentsImage}
                />
            </Grid>
            <Grid
                item
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
            >
                <Typography className={classes.notAttachmentsText}>
                No Reports Found By Clients, You need to select all filters
                </Typography>
            </Grid>
            <Grid
                item
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
            >
                <Button
                variant="contained"
                color="primary"
                //endIcon={<PublishIcon />}
                size="large"
                className={classes.notAttachmentsButton}
                onClick={()=>{
                    toggleDrawer();
                    setOpenFilters(!openFilters);
                }}
                >
                Add Filters
                </Button>
            </Grid>
            </Grid>
            {/* Not Found*/}
        </div>
        );
    };

    
      const loadMoreLogic = () => {
        return (
            <Grid item container>
                <Grid item lg={12}>
                {nextPage && !isMoreLoading &&
                    <div style={{textAlign: "center", margin:'16px 0px 32px 0px'}}>
                        <Button type="button"
                            color="secondary"
                            variant="contained"
                            onClick={() => {
                                setIsMoreLoading(!isMoreLoading);
                            }}>Load More
                        </Button>
                    </div>}

                { isMoreLoading &&
                    <CircularProgress size={35} style={{position:'relative', left:'50%', right:'50%', margin:'16px 0px'}}/>
                }
                </Grid>
            </Grid>
        )
    }

    const pageReset = () => {
        //on page change useeffect will be called and getEmail api called
        setPage(1);
        setNextPage(false);
        if(page===1 && tempObj1) callgetChecklistForClientWorkReport();
    }

    const callgetChecklistForClientWorkReport = () => {
        const organization_id = props.selectedOrganization.organization.id;
        const client_ids = props.fromClientDetails ? props.clientId : reportObj.client_id;
        const status = statusValue?.toLowerCase(); 
        const template_id = tempObj1 ? tempObj1.template_id : undefined; 

        if(!isMoreLoading) setChecklistLoading(true);
        getChecklistForClientWorkReport(organization_id,client_ids, page, status, template_id)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response getCompleteIncompleteChecklistApi: ', res);
                setChecklistLoading(false);

                if(isMoreLoading) {
                    const newItems = checkList.concat(res.checklist);
                    setCheckList(newItems);
                    setPage(page + 1);
                    setNextPage(res.next_page);
                    setIsMoreLoading(!isMoreLoading);
                } else {
                    setNextPage(res.next_page);
                    if(res.next_page) setPage(page + 1);
                    setCheckList(res.checklist);
                }

            })
            .catch((e) => {
                consoleToLog("Error getCompleteIncompleteChecklistApi: ", e.response);
                setChecklistLoading(false);
                if(e.response.data && e.response.data.message) {
                    return;
                }
            })
    }


    return (
        <div className={classes.mainContent}
            style={{height: props.height}}>
            <Container className={classes.container} maxWidth="xl">
                { clientDataLoading ?
                    <Grid item container>
                        <Grid item lg={12}>
                            <CircularProgress size={35} style={{position:'relative', left:'50%', right:'50%', margin:'16px 0px'}}/>
                        </Grid>
                    </Grid>
                    :
                    (completionDataForClient && completionDataForClient.length > 0) ? 
                    completionDataForClient.map((templateData, index)=>{
                        return(
                            <div key={templateData.templates.template_id+"_"+index}
                                style={{padding: "12px 0", marginTop: "10px", fontWeight: "bold", background: "white", marginBottom: "50px"}}>
                                <Typography variant="body" style={{marginLeft: "20px", display:'flex', flexDirection:"column"}}>
                                    {props.fromClientDetails ? "" : templateData.client_name}  
                                    <Typography variant="body" style={{marginTop:'16px', textAlign:props.fromClientDetails ? 'center' : 'left'}}>
                                        {templateData.templates.length > 0 ? "" : "No Reports Found"}  
                                    </Typography>
                                </Typography>
                                <Grid container>
                                    {templateData.templates && templateData.templates.length>0 && templateData.templates.map((td, index) => {
                                    const seriesArr=[td.complete, td.incomplete];
                                    // consoleToLog("series: ", seriesArr);
                                        return(
                                            <Grid key={index} item lg={4}>
                                                <Chart options={{
                                                        series:[3,5],
                                                        chart:{
                                                            width:300,
                                                            type: "pie"
                                                        },
                                                        colors:[
                                                            "#00cf95",
                                                            "#0291ff",
                                                            "#FF5733",
                                                            "#1B4F72",
                                                            "#DC7633",
                                                            "#F7DC6F",
                                                            "#52BE80",
                                                            "#A93226",
                                                            "#273746",
                                                            "#B7950B"
                                                        ],
                                                        plotOptions:{
                                                            pie:{
                                                                customScale:0.7
                                                            }
                                                        },
                                                        legend:{
                                                            show:false
                                                        },
                                                        labels:["Complete", "Incomplete" ],
                                                        responsive:[
                                                        {
                                                            breakpoint:480,
                                                            options:{
                                                            chart:{
                                                                width:200
                                                            },
                                                            legend:{
                                                                position:"bottom"
                                                            }
                                                            }
                                                        }
                                                        ],
                                                        chart: {
                                                            events: {
                                                                    dataPointSelection: (event, chartContext, config) => {
                                                                        setReportObj(templateData);
                                                                        setTempObj(templateData.templates);
                                                                        setTempObj1(td);
                                                                        let status = config.w.config.labels[config.dataPointIndex];
                                                                        setStatusValue(status);
                                                                        pageReset();
                                                                        handleChecklistDrawerOpen();
                                                                    },
                                                                }
                                                            }
                                                        }} 
                                                    series={seriesArr} 
                                                    type="pie" 
                                                    height={300}
                                                    />
                                                <Typography variant="subtitle1" style={{fontWeight: "bold", textAlign: "center", marginTop: "-35px"}}>
                                                    {td.template_name}
                                                </Typography>
                                            </Grid>
                                            )
                                })}
                                </Grid>
                        </div>
                        )
                    }) 
                    :!props.fromClientDetails ? <ReportsNotFound /> : <div></div>
                    
                }
            </Container>  
            {!props.fromClientDetails &&
            <RightToLeftSideDrawer openDrawer={openDrawer} 
                closeDrawer={setDrawerOpen}
                title="Report Filters"
                createChecklist={false}>
                
                <Grid item style={{marginLeft: "12px"}}>
                    <Grid item container>
                        <Grid item >
                            <Typography
                                variant="body1"
                                style={{lineHeight:'42px', color: '#888888'}}
                            >
                            Filter By Client
                            </Typography>
                        </Grid>
                    </Grid>
                    
                    <Grid item container style={{marginTop:'-16px', width: "500px"}}>
                        <Grid item lg={6}>
                            <ChecklistCompaniesFilter isFromReport = {true}
                                clearFilter={clearFilter}
                                onUpdateClearFilterValue={onUpdateClearFilterValue} 
                                onSelectedCompanies={onSelectedCompanies}
                                selectedCompanies={selectedCompanies}
                                />
                        </Grid>
                    </Grid>   
                </Grid>

                <Grid item style={{marginLeft: "12px", marginTop: "18px"}}>
                <Button variant="contained" 
                    color="primary" 
                    style={{fontWeight:"300 !important"}}
                    className="modal__button"
                    onClick={()=>{
                        setClearFilter(true);
                        setSelectedCompanies([]);
                        setCompletionDataForClient([]); 
                        props.setFilterCountForClient(0);
                        //pageReset();
                    }}>
                    Clear Filters
                </Button>
                </Grid>
            </RightToLeftSideDrawer>
            }

            <ChecklistReportsDrawer 
                openChecklistDrawer={openChecklistDrawer}
                handleChecklistDrawerClose={handleChecklistDrawerClose}
                checklistLoading={checklistLoading}
                checkList={checkList}
                tempObj={tempObj1}
                nextPage={nextPage}
                isMoreLoading={isMoreLoading}
                setIsMoreLoading={setIsMoreLoading}
                fromClientDetails={props.fromClientDetails}
            />
    </div>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    members: state.organizationInfo.members
});

const mapDispatchToProps = (dispatch) => ({
    addOrgMember: (member) => dispatch(addOrgMember(member))
})

export default connect(mapStateToProps, mapDispatchToProps)(ClientWorkReport);