import React, {useState, useEffect} from 'react';
import { AppBar, Toolbar, Typography, IconButton, Button, Grid, Divider, CircularProgress, Avatar, Tooltip } from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import { connect } from 'react-redux';
import ClearIcon from '@material-ui/icons/Clear';
import {getNotificationListApi} from '../services/authService';
import {consoleToLog, getReadableActivityInHTML} from '../util/AppUtil';
import { useSnackbar } from 'notistack';
import {useIsMount} from './useIsMount'; 
import moment from 'moment';
import { Link } from 'react-router-dom';
import {setSelectedTemplate, setSelectedDrawerState, SelectedDrawerState} from '../actions/selectedOrganization';
import {database} from '../firebase/firebase';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
 
const useStyles = makeStyles((theme) => ({
    popOverStyles: {
        position:'relative !important',
        padding:'10px',
        //marginTop:"-32px !important"
        //width:'550px !important',
        //height:'calc(100vh - 58px)',
        //borderRadius:'0px !important',
    },
    appBar: {
        width:"550px",
        background:'#F9F9F9',
        height:'45px',
        color:'#202020',
        position:'sticky',
        top:'0px'
    },
    loading: {
        position:'relative',
        left:'50%',
        right:'50%',
        top:'30px'
    },
    // avatarStyle: {
    //     width:"38px !important",
    //     height:"38px !important",
    //     fontSize:'16px !important',
    //     fontWeight:'600 !important',
    //     boxSizing: 'border-box !important',
    // },
    MuiAvatarroot : {
        width:"38px !important",
        height:"38px !important",
        fontSize:'16px !important',
        fontWeight:'600 !important',
        boxSizing: 'border-box !important',
            '& .MuiAvatar-img' : {
                border:'none'
            }
        },
    newPostContainer : {
        position: '-webkit-fixed',
        position: 'fixed',
        top: '18%',
        ["@media (min-width:1400px)"]: {
            top: '15%',
          },
        zIndex: '999'
    },
    showNewPostsButton : {
        backgroundColor: '#20a8e0',
        color: 'white',
        fontSize: '11px',
        fontWeight: 500,
        border: 'none',
        borderRadius: '25px !important',
        //z-index: 9;
        margin: '0px auto !important',
        padding: '2px 16px',
        boxShadow: '0 0 0 1px lighten(#20a8e0, 50%)',
        transition: 'all 0.3s',
        left: '100% !important'
    },
    doubleArrowIcon: {
        transform: 'rotate(-90deg) !important',
        fontSize:'14px', 
        marginRight:'4px'
    },
    textContainer: {
        whiteSpace:'nowrap', 
        overflow:'hidden', 
        textOverflow:'ellipsis'
    }    
})); 

const Notifications = (props) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    const isMount = useIsMount();

    const [page, setPage] = useState(1);
    const [nextPage, setNextPage] = useState(false);
    const [notificationList, setNotification] = useState([]);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [isMoreLoading, setIsMoreLoading] = useState(false);

    let notifiCount = (props.selectedOrganization &&
        props.selectedOrganization.organization &&
        props.selectedOrganization.organization.id &&
        props.notifications &&
        props.notifications[props.selectedOrganization.organization.id] &&
        props.notifications[props.selectedOrganization.organization.id] !== 0
    ) ? props.notifications[props.selectedOrganization.organization.id] :  0;


    useEffect(() => {
        if(page === 1) {
        getNotificationList();
        }
    }, []);

    useEffect(() => {
        if(!isMount) {
            if(isMoreLoading) {
                consoleToLog('Notifications IsMoreLoading Clicked');
                getNotificationList();
            }
        }

    }, [isMoreLoading])

    useEffect(() => {
        if(!isMount) {
            if(page === 1) {
                getNotificationList();
            }
        }
    }, [page]);

   const getNotificationList = () => {
        const access_token = props.auth.access_token;
        const organization_id = props.selectedOrganization.organization.id;

        if(!isMoreLoading) setLoading(true);
        getNotificationListApi(access_token, organization_id, page)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response getNotificationListApi: ", res);
                setLoading(false);
                //set State 
                if (isMoreLoading) { // next pages
                    const notificationItems = notificationList.concat(res.notifications)
                    setNotification(notificationItems);
                    setNextPage(res.next_page);
                    setPage(page + 1);
                    setIsMoreLoading(!isMoreLoading);
                } else { //first time 
                    setNextPage(res.next_page)
                    if (res.next_page) setPage(page + 1);
                    setNotification(res.notifications);
                    setTotal(res.total);
                    setTimeout(() => {resetFirebaseCount(organization_id)}, 400);
                    
                }
            })
            .catch((e) => {
                consoleToLog(" getNotificationListApi error", e.response);
                setLoading(false);
                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant:'error'
                    });
                    return false;
                }
            });
    }

    const resetFirebaseCount = async (organization_id) => {
        const user_id = props.auth.user.id;
        var docRef = database.collection("users").doc(`${user_id}`)

        docRef.get().then(function (doc) {
            if (doc.exists) {
                let allData = doc.data();
                var orgs = allData.organizations;
                orgs[`${organization_id}`] = 0;
                docRef.set({ organizations: orgs }).then(() => {
                    consoleToLog('Firebase count reset successfully');
                })
            } else {
                consoleToLog('To reset count: doc not exists');
            }
        }).catch(function (error) {
            consoleToLog("FirebaseService: Error getting document:", error);
        });
    }

    const onRefreshClick = () => {
        setPage(1);
        setNextPage(false)
    }

    const showNewPostsButton = () => {
        return (
            <Grid item className={classes.newPostContainer} style={{display:(notifiCount !== 0) ? 'block' : 'none'}}>
                { (notifiCount !== 0) ? 
                     <Button variant="contained" 
                            className={classes.showNewPostsButton} onClick={onRefreshClick}>
                            <DoubleArrowIcon className={classes.doubleArrowIcon}/>
                           New Notifications Arrived
                        </Button> : <Grid item></Grid>
                }
            </Grid>
        );
    }


    const showNotificationList = () => {
        return (<Grid item md={12} lg={12} className={classes.popOverStyles}>
                    {
                        notificationList && notificationList.length > 0 && 
                        notificationList.map((notificationObj) => {
                        const time = moment(notificationObj.created_at).fromNow();
                        const innerHtmlContent = getReadableActivityInHTML(notificationObj.notification_text);
                        const image = notificationObj.source_user.image ? notificationObj.source_user.image : undefined;
                        const fullName = notificationObj.source_user.firstname.charAt(0).toUpperCase() + notificationObj.source_user.lastname.charAt(0).toUpperCase();
                        const templateObj = notificationObj.template;
                        var stripedHtml = notificationObj.notification_text.replace(/<[^>]+>/g, '');
                        let textInfo = notificationObj.template.name + ' ' + '-' + ' ' + notificationObj.checklist.title + ' ' + (notificationObj.checklist.subtitle);
                        
                        return <Grid item key={notificationObj.id}> 
                                    <Link 
                                        style={{
                                            textDecoration: 'none', 
                                            width:'auto', 
                                            display:'inline-block', 
                                            padding:"8px 0px",
                                            cursor: `${notificationObj.notification_type === "setup_completed" ? 'default' : 'pointer'}`
                                        }}
                                        to={notificationObj.notification_type === "setup_completed" ?
                                            {} : {
                                                pathname: `/checklist/${notificationObj.checklist.slug_code}`,
                                                notificationObj: {
                                                    ...notificationObj,
                                                    user: notificationObj.source_user
                                                }
                                            }}
                                        //onClick={() => setFromChecklistItemPage(true)}
                                        onClick={(e) => {
                                                props.setSelectedDrawerState(SelectedDrawerState.NOT_SELECTED);
                                                props.setSelectedTemplate(templateObj);
                                                //props.handleNotificationClose();
                                            }
                                            }>
                                        <Grid item container  alignItems='center'>
                                            <Grid item xs={1} style={{flexBasis:'4%', maxWidth:'4%', 
                                            marginRight:notificationObj.notification_type === "setup_completed" ? "38px" : '32px'}}>  
                                                <Avatar 
                                                    classes={{
                                                        root: classes.MuiAvatarroot
                                                    }}
                                                    size={40}
                                                    src={image}
                                                    >
                                                {fullName}       
                                                </Avatar>        
                                            </Grid>

                                            <Grid item xs={notificationObj.notification_type === "setup_completed" ? 9 : 10} style={{color:'#212121', width:'560px'}}>
                                                    <Grid item >
                                                        <Tooltip title={stripedHtml} arrow>
                                                            <Typography variant='body1' 
                                                                style={{whiteSpace: "nowrap", maxWidth: "490px", overflow: "hidden", textOverflow: "ellipsis" }}
                                                                dangerouslySetInnerHTML={{ __html: innerHtmlContent }}
                                                            ></Typography>
                                                        </Tooltip>
                                                    </Grid>
                                                    {notificationObj.notification_type !== 'setup_completed' &&    
                                                    <Grid item container alignItems="center" >
                                                            <Tooltip title={textInfo} arrow>
                                                            <Typography className={classes.textContainer}>
                                                                
                                                                    <span style={{color:'#0071FF', fontSize:'14px'}}>{notificationObj.template.name}</span>  

                                                                    <span style={{padding:'0px 4px'}}>-</span> 

                                                                    <span style={{fontSize:'13px'}}>
                                                                        { `${notificationObj.checklist.title} ${notificationObj.checklist.subtitle ? 
                                                                            '(' + notificationObj.checklist.subtitle + ')' : ''}`}
                                                                    </span>
                                                                
                                                            </Typography>
                                                            </Tooltip>
                                                        </Grid>}
                                                <Typography variant='subtitle1' style={{color:'#555555'}}> {time} </Typography> 
                                                
                                            </Grid>   
                                        </Grid>
                                    </Link>
                                    <Divider light style={{width:'100%'}}/> 
                                </Grid> 

                        })
                    }

                </Grid>
        )
    }

    const loadMoreLogic = () => {
        return (
            <Grid item container>
                <Grid item lg={12}>
                {nextPage && !isMoreLoading &&
                    <div style={{textAlign: "center", margin:'16px 0px 32px 0px'}}>
                        <Button type="button"
                            color="secondary"
                            variant="contained"
                            onClick={() => {
                                setIsMoreLoading(!isMoreLoading);
                            }}>Load More
                        </Button>
                    </div>}

                { isMoreLoading &&
                    <CircularProgress size={35} style={{position:'relative', left:'50%', right:'50%', margin:'16px 0px'}}/>
                }
                </Grid>
            </Grid>
        )
    }

    return (
        <div style={{position:'relative'}}>
            <AppBar position="static" className={classes.appBar} elevation={0}>
                <Toolbar style={{minHeight:'40px', padding:'0px 16px', }}>
                    <Typography variant="body1" 
                        style={{fontWeight:'600', marginRight:'auto'}}>
                        Notifications
                    </Typography>
                    <IconButton 
                        onClick={props.handleNotificationClose}
                        style={{color:'#202020', marginRight:'-16px'}} >
                        <ClearIcon/>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <div>
                {
                    loading ? <CircularProgress size={35} className={classes.loading}/> 
                            : <div>
                                {showNewPostsButton()}
                                {showNotificationList()}
                                {loadMoreLogic()}
                              </div>  
                }
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    members: state.organizationInfo.members,
    notifications: state.notifications,
});

const mapDispatchToProps = (dispatch) => ({
    setSelectedTemplate: (template) => dispatch(setSelectedTemplate(template)),
    setSelectedDrawerState: (drawerState) => dispatch(setSelectedDrawerState(drawerState))
})

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);