import React, {useState, useEffect} from 'react';
import { CircularProgress, makeStyles } from '@material-ui/core';
import {Grid, Typography, Dialog, Avatar, Button, FormControlLabel, Checkbox, Radio  } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import {getNotConnectInvoiceAccountsApi} from '../../services/authService';
import {connect} from 'react-redux';
import { AccessLevel, consoleToLog } from '../../util/AppUtil';
import { useSnackbar } from 'notistack';
import {getUnConnectInvoiceAccountsApi, connectedInvoiceAccountsApi} from '../../services/invoiceService'; 
import {connectInvoiceAccountToOrgApi, getInvoiceAccountsListApi} from '../../services/clientService';
import {setOrgInvoiceAccounts} from '../../actions/selectedOrganization';
import {getInvoiceAccountsEntityListApi} from '../../services/invoiceService';
import { markAsDefaultApi } from '../../services/invoiceService';
import {setDefaultEntityApi} from '../../services/authService';

const useStyles = makeStyles((theme) => ({
    MuiDialogpaper: {
        scrollbarWidth: 'none',
        "&::-webkit-scrollbar": {
            display: "none"
        }   
    },
    dialogTitle : {
        borderBottom:'1px solid #ccc', 
        padding:'14px 16px', 
        fontSize:'16px',
        fontWeight:'600',
    },
    backIconContainer : {
        [theme.breakpoints.down(1450)] : {
            flexBasis:'50%',
            maxWidth:'50%'
        },
    },
    backIcon: {
        padding:'6px',
        border:'1px solid rgba(0,0,0, .1)',
        borderRadius:'5px',
        cursor:'pointer',
    },
    invoiceItemContainer: {
        backgroundColor: "#F9F9F9",
        padding:"10px",
        borderRadius: '4px',
        marginBottom:'8px',
        '&:last-child' : {
            marginBottom:'0px',
        }
    },
    listItemsStyle: {
        color:'#555555', 
        display:'flex', 
        alignItems:'center'
    }
}));

const InvoiceListModal = (props) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();

        const [invoiceAccountsList, setInvoiceAccountsList] = useState([]);
        const [selectedAccountOrEntity, setSelectedAccountOrEntity] = useState([]);
        const [connectedInvoiceAccountId, setConnectedInvoiceAccountId] = useState(undefined);
        const [loading, setLoading] = useState(false);
        const [submitLoading, setSubmitLoading] = useState(false);
        const [entityList, setEntityList] = useState(false);
        const ownerMemberList = props.members.filter((member) => member.access_level === 'owner');

        useEffect(() => {
            getUnConnectInvoiceAccountsList();
        }, [])

        const getUnConnectInvoiceAccountsList = () => {
            const owner_ids_list = ownerMemberList && ownerMemberList.length > 0 && ownerMemberList.map((member) => member.user.id).join(',');

            setLoading(true);
            getUnConnectInvoiceAccountsApi(owner_ids_list)
                .then((response) => {
                    const res = response.data;
                    consoleToLog('Response getUnConnectInvoiceAccountsApi', res);
                    setLoading(false);

                    setInvoiceAccountsList(res);
                })
                .catch((e) => {
                    consoleToLog('Error getUnConnectInvoiceAccountsApi:', e);
                    setLoading(false);
                    if(e.response.data && e.response.data.message) {
                        return;
                    }
                })
        }   

        const onSelectedChecklist = (acc_id) => {
            const tempArr =  selectedAccountOrEntity.filter((id) => id === acc_id)
            return tempArr.length > 0 ? true : false;
        }

        const handleChange = (e, acc) => {
                setSelectedAccountOrEntity([acc.id]);
        }


        const onConnectClick = () => {
            const organization_id = props.selectedOrganization.organization.id;

            setSubmitLoading(true);
            connectInvoiceAccountToOrgApi(organization_id, selectedAccountOrEntity)
                .then((response) => {
                    const res = response.data;
                    consoleToLog('Response connectInvoiceAccountToOrgApi: ', res);
                    setSubmitLoading(false);

                    enqueueSnackbar('Account connected successfully', {variant:'success'});
                    //props.getConnectedAccountList();

                    callConnectedInvoiceAccountsApi(organization_id, selectedAccountOrEntity)
                })
                .catch((e) => {
                    consoleToLog('Error connectInvoiceAccountToOrgApi: ', e.response);
                    setSubmitLoading(false);
                    if(e.response.data && e.response.data.message) {
                        enqueueSnackbar(e.response.data.message, {variant:'error'});
                        return;
                    }
                })

        }

        const callConnectedInvoiceAccountsApi = (organization_id, selectedAccountOrEntity) => {
            connectedInvoiceAccountsApi(organization_id, selectedAccountOrEntity)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response connectedInvoiceAccountsApi: ', res);

                setConnectedInvoiceAccountId(res[0].id);
                callGetConnectedInvoiceAccountsApi(organization_id, selectedAccountOrEntity)

            })
            .catch((e) => {
                consoleToLog('Error connectedInvoiceAccountsApi: ', e.response);
                if(e.response.data && e.response.data.message) {
                    //enqueueSnackbar(e.response.data.message, {variant:'error'});
                    return;
                }
            })
        }

        const callGetConnectedInvoiceAccountsApi = (organization_id, selectedAccountOrEntity) => {
            getInvoiceAccountsListApi(organization_id)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response getInvoiceAccountsListApi: ', res);

                fetchInvoiceAccountEntityList(selectedAccountOrEntity, res.invoice_accounts);
            })
            .catch((e) => {
                consoleToLog("getInvoiceAccountsListApi error", e.response);
                if (e.response.data && e.response.data.message) {
                    return false;
                }
            });
        }

        const fetchInvoiceAccountEntityList = (invoice_account_id, accountList) => {
            getInvoiceAccountsEntityListApi(invoice_account_id)
                .then((response) => {
                    const res = response.data;
                    consoleToLog('Response getInvoiceAccountsEntityListApi: ', res);
    
                    const invoiceAccountList = accountList.map((account) => {
                        if(account.id === Number(invoice_account_id)) {
                            return {
                                ...account,
                                entities: res
                            }
                        }
                        return account
                    });
                    setEntityList(true);
                    setInvoiceAccountsList(res);
                    props.setOrgInvoiceAccounts(invoiceAccountList);
    
                })
                .catch((e) => {
                    consoleToLog("getInvoiceAccountsEntityListApi error", e.response);
                    if (e.response.data && e.response.data.message) {
                        return false;
                    }
                });
        }

        const onSetAsDefaultClick = () => {
            const entity_id = selectedAccountOrEntity[0];
        
            setSubmitLoading(true);
            markAsDefaultApi(connectedInvoiceAccountId, entity_id)
                .then((response) => {
                    const res = response.data;
                    consoleToLog('Response markAsDefaultApi: ', res);
                    enqueueSnackbar('Account successfully marked as default', {variant:'success'});
                    setSubmitLoading(false);
                    
                    const updatedInvoiceAccountList = props.accountList.map((account) => {
                        if(account.id === connectedInvoiceAccountId) {
                            account.entities = res;
                        }
                        return account;
                    })
        
                    props.setOrgInvoiceAccounts(updatedInvoiceAccountList);
                    callSetDefaultEntityApi(connectedInvoiceAccountId, entity_id);
                    props.handleClickDialogClose();
                })
                .catch((e) => {
                    consoleToLog('Error markAsDefaultApi: ', e);
                    setSubmitLoading(false);
                    if(e.response.data && e.response.data.message) {
                        enqueueSnackbar(e.response.data.message ,{
                            vairant:'error'
                        });
                        return;
                    }
                })
        }

        const callSetDefaultEntityApi = (connectedInvoiceAccountId, entity_id) => {

            const organization_id = props.selectedOrganization.organization.id;

            setDefaultEntityApi(organization_id, connectedInvoiceAccountId, entity_id)
                .then((response) => {
                    const res = response.data;
                    consoleToLog('Response setDefaultEntityApi: ', res);
                    
                })
                .catch((e) => {
                    consoleToLog('Error setDefaultEntityApi: ', e);
                    if(e.response.data && e.response.data.message) {
                        return;
                    }
                })
        }

    return (
        <div>
            <Dialog
                open={props.openDialog}
                //TransitionComponent={Transition}
                scroll="paper"
                classes={{
                    paper: classes.MuiDialogpaper}}
                maxWidth="md"
                fullWidth
                style={{scrollbarWidth:'none !important'}}
                onClose={props.handleClickDialogClose}>

                    <Grid item container   
                        justifyContent='space-between'
                        alignItems='center'className={classes.dialogTitle}>

                        <Grid item>        
                            <Typography style={{fontSize:'16px', fontWeight:500}}>
                                {!entityList ? 'Invoice Accounts' : 'Entities'}
                            </Typography>
                        </Grid>

                        <Grid item>
                            <Grid item>
                                <ClearIcon fontSize='small' onClick={props.handleClickDialogClose}
                                style={{cursor:'pointer', verticalAlign:'inherit'}}/>
                            </Grid>
                        </Grid>

                    </Grid>

                    {loading ?
                        <Grid item container justifyContent='center'>
                            <CircularProgress size={28} style={{marginTop:'16px'}}/>
                        </Grid>
                    :
                    <Grid item sm={12} style={{padding:'32px 16px'}}>
                        {invoiceAccountsList && invoiceAccountsList.length > 0 ?
                        invoiceAccountsList.map((account) => {
                            return <Grid item container direction="row"
                                        key={account.id}
                                        className={classes.invoiceItemContainer}
                                        >
                                        <Grid item container direction="column" sm={9}>
                                            <Grid item container>
                                                <Grid item>
                                                    <Radio
                                                        checked={selectedAccountOrEntity?.includes(account.id) || entityList && selectedAccountOrEntity?.includes(account.default) }
                                                        onChange={(e) => handleChange(e, account)}
                                                        value={account.id}
                                                    />
                                                </Grid>

                                                <Grid item style={{marginLeft:'8px'}}>
                                                    <Typography variant="subtitle1" className={classes.listItemsStyle}>
                                                        Name : {account.name}
                                                    </Typography>

                                                    <Typography variant="subtitle1" className={classes.listItemsStyle}>
                                                        Email : {account.email}
                                                    </Typography>
                                                </Grid>

                                                
                                            </Grid>
                                        </Grid>
                                    </Grid>
                        }) 
                        : 
                        <Grid item container justifyContent='center'>
                            <Typography variant='body1'>
                                No Account List Found
                            </Typography>
                        </Grid>
                        }
                    </Grid>}

                    {
                        invoiceAccountsList && invoiceAccountsList.length > 0 &&
                        <Grid item container justifyContent='center' style={{marginBottom:'32px'}}>
                            <Button variant='contained' color='primary' 
                                onClick={() => {
                                    if(!entityList) {
                                        onConnectClick();
                                    } else {
                                        onSetAsDefaultClick();
                                    }
                                    
                                }
                            }
                            >
                                {submitLoading && <CircularProgress size={25} style={{marginRight:'8px', color:'white'}}/>}
                                {!entityList ? 'Connect' : 'Set as Default'}
                            </Button>
                        </Grid>
                    }
                
            </Dialog>
        </div>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    members: state.organizationInfo.members,
    accountList: state.organizationInfo.accountList
});

const mapDispatchToProps = (dispatch) => ({
    setOrgInvoiceAccounts: (accountList) => dispatch(setOrgInvoiceAccounts(accountList))
})

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceListModal);