import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import { login } from '../actions/auth';
import {CircularProgress, Grid} from '@material-ui/core';
import { firebase } from '../firebase/firebase';
import {history} from '../routers/AppRouter';
import { consoleToLog } from '../util/AppUtil';
import {getUserDetailsApi, createFirebaseTokenApi} from '../services/authService';
import {logout} from '../actions/auth';
import {logoutOrgs} from '../actions/organization';
import {logoutFilters} from '../actions/filters';
import {logoutSelectedOrg} from '../actions/selectedOrganization';
import {logoutApi} from '../services/authService';
import { fetchAccessToken } from '../util/AppUtil';

const RootPage = (props) => {

    useEffect(() => {
        let url_string = window.location.href;
        let url = new URL(url_string);
        let service_name = url.searchParams.get("service");
        let first_login = url.searchParams.get("first_login");
        const back_url = url.searchParams.get("back_url");
        
        if(props.location.state?.logOut) {
            clearAllReduxAndRedirectToAuthApp(back_url);
            // logoutApi()
            //     .then((response) => {
            //         const res = response.data;
            //         consoleToLog('Response logoutApi: ', res);
            //         clearAllReduxAndRedirectToAuthApp(back_url);
            //     })
            //     .catch((e) => {
            //         consoleToLog('Error logoutApi: ', e.response);
            //         if(e.response.status === 401) {
            //             clearAllReduxAndRedirectToAuthApp(back_url);
            //         }
            //         if(e.response.data && e.response.data.message) {
            //             return;
            //         }
            //     })

        } else {

        const fetchToken = async () => {
            try {
            const data = await fetchAccessToken(service_name);
            if(data) {
                consoleToLog('Response authenticateApi', data);
                const {access_token, redirect_url, invalid_service, scope} = data;
                if(access_token) {
                    if(scope?.includes('workplace')) {
                        createFirebaseTokenApi()
                        .then((response) => {
                            const res = response.data;
                            consoleToLog('Response createFirebaseTokenApi: ', res);

                            loginToFirebase(res.firebase_access_token);
                            let userObj = {
                                ...res.user,
                                first_login: first_login === "true",
                                scope: scope
                            }
                            const obj = {
                                firebase_access_token: res.firebase_access_token,
                                service_name: service_name,
                                user: userObj
                            }
                            props.login(obj);
                            if(userObj.first_login) {
                                history.replace('/onboarding');
                            } else {
                                if(back_url && back_url !== '') {
                                    history.replace(`${back_url}`);
                                } else {
                                    history.replace('/dashboard');
                                }
                            }

                            
                        })
                        .catch((e) => {
                            consoleToLog('Error createFirebaseTokenApi: ', e);
                            if(e.response.status === 401) {
                                window.open(`${process.env.REACT_APP_AUTH_APP_DOMAIN_NAME}/login?service=workplace`, '_self');
                                return;
                            }
                            if(e.response.data && e.reponse.data.message) {
                                return;
                            }
                        })
                } else {
                    window.open(`${process.env.REACT_APP_AUTH_APP_DOMAIN_NAME}/not-registered?service=workplace`, '_self');
                }
                }  else {
                    window.open(`${process.env.REACT_APP_AUTH_APP_DOMAIN_NAME}/login?service=workplace`, '_self');
                }

            } 
            } catch (error) {
                consoleToLog("error fetchAccessToken", error);
            }
        };
    
        fetchToken();
    }
    }, []);

    const clearAllReduxAndRedirectToAuthApp = (back_url) => {
        props.logout();
        props.logoutOrgs();
        props.logoutSelectedOrg();
        props.logoutFilters();
        logoutFirebase();
        return window.open(`${process.env.REACT_APP_AUTH_APP_DOMAIN_NAME}/login?service=workplace${back_url && back_url !== '' ? `&back_url=${back_url}` : ''}`, '_self');
        
    }

    const loginToFirebase = (token) => {
        firebase.auth().signInWithCustomToken(token)
            .catch(function (error) {
                consoleToLog("Firebase login api error: ", error);
            });
        }

    const logoutFirebase = () => {
        firebase.auth().signOut();
    }


    return (
        <div>
            <Grid item container justifyContent='center'>
                <CircularProgress size={30} style={{marginTop:'32px'}}/>
            </Grid>
        </div>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth
});

const mapDispatchToProps = (dispatch) => ({
    login: (auth) => dispatch(login(auth)),

    logout: () => dispatch(logout()),
    logoutOrgs: () => dispatch(logoutOrgs()),
    logoutSelectedOrg: () => dispatch(logoutSelectedOrg()),
    logoutFilters: () => dispatch(logoutFilters())
});

export default connect(mapStateToProps, mapDispatchToProps)(RootPage);