import React, {useState, useEffect, Component} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Typography, TextField, Button, Drawer, AppBar, Toolbar, IconButton, CircularProgress,MenuItem, Checkbox} from '@material-ui/core';
import { connect } from 'react-redux';
import ClearIcon from '@material-ui/icons/Clear';
import {useIsMount} from '../useIsMount';
import { consoleToLog ,trackGAEvent, capitalizeFirstLetterOfEachWord} from '../../util/AppUtil';
import { useSnackbar } from 'notistack';
import RightToLeftSideDrawer from '../RightToLeftSideDrawer';
import { addContactApi } from '../../services/clientService';
import { editContactApi } from '../../services/clientService';
import ContactComponent from './ContactComponent';
import {phoneNumberValidate} from "../../util/AppUtil";
import { emailValidator } from '../../util/validator';
import ChecklistCompaniesFilter from '../filters/ChecklistCompaniesFilter';

const useStyles = makeStyles((theme) => ({
    drawer: {
    //    width: drawerWidth,
        flexShrink: 0
    },
    drawerPaper: {
        width: '30%', 
        height: 'calc(100vh - 51px)',
        flexGrow:1,
        top:'51px',
        position:"relative",
        overflow:'auto'
    },
    appBar: {
        background:'#F9F9F9',
        height:'45px',
        color:'#202020'
    },
    customMarginTop : {
        marginTop:"16px"
    },
    MuiCheckboxColorPrimary : {
        color:"#d3d3d3 !important",
      },
          
    Muichecked: {
        color:'#202020 !important'
    },
    customMarginTop: {
        marginTop:'8px'
    },
    customMarginBottom: {
        marginBottom:'-8px'
    },
    dialogboxFooter: {
        display: "flex",
        position: "absolute",
        bottom: "0px",
        left: "0px",
        right: "0px",
        padding: "12px 0px 12px 12px",
        zIndex: "15",
        background: "#fff",
        borderTop: "1px solid #ccc",
    },
}));

const ContactDrawer = (props) => {

    const {fromAddEditContact} = props;
  
    const classes = useStyles();
    const isMount = useIsMount();
    const {enqueueSnackbar} = useSnackbar();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [Title, setTitle] = useState('');
    const [Email, setEmail] = useState('');
    const [phoneNo, setPhoneNo] = useState('');
    const [errorNo, setErrorNo] = useState(undefined);
    const [errorMsg, setErrorMsg] = useState('');
    const [loading, setLoading] = useState(false);
    const [selectedCompanies, setSelectedCompanies] = useState([]);

    
    useEffect(() => {
        if(Object.keys(props.contactObj).length !== 0) {
            
            setFirstName(props.contactObj?.first_name);
            setLastName(props.contactObj?.last_name);
            setTitle(props.contactObj?.title);
            setEmail(props.contactObj?.email);
            setPhoneNo(props.contactObj?.phone_number);
            setSelectedCompanies(props.contactObj?.clients);
        } 

    }, [props.contactObj]);

    const closeDrawer = () => {
        props.handleContactDrawerClose();
    }

    const onCancelClick = () => {
        setFirstName('');
        setLastName('');
        setTitle('');
        setEmail('');
        setPhoneNo('');
        setErrorNo(undefined);
        setErrorMsg('');
        setSelectedCompanies([]);
        closeDrawer();
    }

    const onCreateOrUpdateContact = () => {
        const first_name = capitalizeFirstLetterOfEachWord(firstName);
        if(first_name === '') {
            setErrorNo(0);
            setErrorMsg('* Please enter first name');
            return;
        }

        const last_name = capitalizeFirstLetterOfEachWord(lastName);
        if(last_name === '') {
            setErrorNo(1);
            setErrorMsg('* Please enter last name');
            return;
        }

        const title = Title;
        
        const email = Email;

        if (!email) {
            enqueueSnackbar('Email can not be empty!', {
                variant:'error'
            });
            return;
        }
        const isEmailValid = emailValidator(email);
        if (!isEmailValid) {
            enqueueSnackbar('Email is not valid!', {
                variant:'error'
            });
            return;
        }
       
       
        const phone_number = phoneNo;
       

        const organization_id = props.selectedOrganization.organization.id;
        const id = props.contactObj.id;
        // const client_id = props.fromClientDetails && props.companyObj.id;
        // const client_name = props.fromClientDetails && props.companyObj.name;
        const clients = selectedCompanies && selectedCompanies.length > 0 ? selectedCompanies.map((client) => { return {id: client.id, name: client.name, invoice_account_id: client.invoice_account_id} }) : [];


        setLoading(true);


        if(Object.keys(props.contactObj).length !== 0){
            
                callEditContactApi(organization_id, id, first_name, last_name, title, email, phone_number, clients);
        
        } else  {
                callAddContactApi(organization_id, first_name, last_name, title, email, phone_number, clients);
            }
    }

    const callAddContactApi = (organization_id, first_name, last_name, title, email, phone_number, clients) => {
        addContactApi(organization_id, first_name, last_name, title, email, phone_number, clients)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response addContactApi: ', res);
                setLoading(false);

                const checkifNewClientPresentInArr = props.contactList.filter((contact) => contact?.id === res.contact?.id);

                if(checkifNewClientPresentInArr && checkifNewClientPresentInArr.length > 0) {
                    const updatedArray = props.contactList.map((contact) => {
                        if(contact.id === res.contact.id) {
                            return {
                                ...contact,
                                clients: [...contact?.clients, ...res.contact?.clients]
                            }
                        }
                        return contact;
                    });
                    props.setContactList(updatedArray);

                } else {
                    props.setContactList([res.contact, ...props.contactList]);
                }
                //props.setContactDeletedFromOutside(true);
                // if(props.fromClientDetails){
                //     const contactsArr = props.companyObj.contacts && props.companyObj.contacts.length > 0 ? props.companyObj.contacts : [];
                //     const newObj = {
                //         ...props.companyObj,
                //         contacts: [res, ...contactsArr]
                //     }
                //     props.setCompanyObj(newObj);
                //     props.companyUpdated(newObj);
                // }
                // else{
                    
                //     props.setContactList([res, ...props.contactList]);
                // }

                enqueueSnackbar('Contact Added Successfully', {variant: 'success'});
                trackGAEvent(props.selectedOrganization.organization.name, 'Contact Added', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
                onCancelClick();

            })
            .catch((e) => {
                consoleToLog("Error addContactApi", e);
                setLoading(false);
                if(e.response) {
                    enqueueSnackbar(e.response.data.message, {variant: 'error'});
                    return;
                }
            })
    }


    const updateContactObj = (contactArray, id, first_name, last_name, phone_number, title, email, clients) => {
        return  contactArray.map((contact) => {
                    if(contact.id === id) {
                        contact.first_name = first_name;
                        contact.last_name = last_name;
                        contact.phone_number = phone_number;
                        contact.title = title;
                        contact.email = email;
                        contact.clients = clients;
                    }
                    return contact;
                })
    }

    const callEditContactApi = (organization_id, id, first_name, last_name, title, email, phone_number, clients) => {
        editContactApi(organization_id, id, first_name, last_name, title, email, phone_number, clients)
                .then((response) => {
                    const res = response.data;
                    consoleToLog('Response editContactApi: ', res);
                    setLoading(false);

                    const updatedArray = updateContactObj(props.contactList, id, first_name, last_name, phone_number, title, email, clients);
                    props.setContactList(updatedArray);
                    //props.setContactDeletedFromOutside(true);

                    enqueueSnackbar('Contact Updated Successfully', {variant: 'success'});
                    trackGAEvent(props.selectedOrganization.organization.name, 'Contact Updated', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
                    onCancelClick();

                })
                .catch((e) => {
                    consoleToLog("Error editContactApi", e);
                    setLoading(false);
                    if (e.response.data && e.response.data.message) {
                        enqueueSnackbar(e.response.data.message, {
                            variant:'error'
                        });
                    }
                })
    }


    const onSelectedCompanies = (sts) => {
        setSelectedCompanies(sts);
    }
    
    
    return (
        <RightToLeftSideDrawer
            title={Object.keys(props.contactObj).length !== 0  ? 'Update Contact' : "Add Contact"} 
            openDrawer={props.openContactDrawer}
            closeDrawer={onCancelClick}
        >
                <div className='modal__main' style={{overflow:'auto'}}>
                    <Grid item container>
                        <Grid item md={12} lg={12}>
                            <Typography variant="subtitle1" 
                                className={classes.customMarginBottom}>
                                First Name
                            </Typography>

                            <TextField fullWidth 
                                id="first_name" 
                                variant="outlined"
                                type="text"
                                margin="dense"
                                value={firstName}
                                autoComplete="off"
                                placeholder="Enter First Name"
                                InputLabelProps={{style: {fontSize: 13, color: "#ccc"}}}
                                onChange={(e) => {
                                    setFirstName(e.target.value);
                                    setErrorNo(undefined);
                                    setErrorMsg('');
                                }}
                                error={errorNo === 0}
                                helperText={errorNo === 0 ? errorMsg : ''}
                            />
                        </Grid>

                        <Grid item md={12} lg={12} className={classes.customMarginTop}>
                            <Typography variant="subtitle1" 
                                className={classes.customMarginBottom}>
                                Last Name
                            </Typography>

                            <TextField fullWidth 
                                id="last_name" 
                                variant="outlined"
                                type="text"
                                margin="dense"
                                value={lastName}
                                autoComplete="off"
                                placeholder="Enter Last Name"
                                InputLabelProps={{style: {fontSize: 13, color: "#ccc"}}}
                                onChange={(e) => {
                                    setLastName(e.target.value);
                                    setErrorNo(undefined);
                                    setErrorMsg('');
                                }}
                                error={errorNo === 1}
                                helperText={errorNo === 1 ? errorMsg : ''}
                            />
                        </Grid>

                        <Grid item md={12} lg={12} className={classes.customMarginTop}>
                            <Typography variant="subtitle1" 
                                className={classes.customMarginBottom}>
                                Title
                            </Typography>

                            <TextField fullWidth 
                                id="title" 
                                variant="outlined"
                                type="text"
                                margin="dense"
                                value={Title}
                                autoComplete="off"
                                placeholder="Enter Title"
                                InputLabelProps={{style: {fontSize: 13, color: "#ccc"}}}
                                onChange={(e) => {
                                    setTitle(e.target.value);
                                    setErrorNo(undefined);
                                    setErrorMsg('');
                                }}
                                error={errorNo === 2}
                                helperText={errorNo === 2 ? errorMsg : ''}
                            />
                        </Grid>

                        <Grid item md={12} lg={12} className={classes.customMarginTop}>
                            <Typography variant="subtitle1" 
                                className={classes.customMarginBottom}>
                                Email
                            </Typography>

                            <TextField fullWidth 
                                id="email" 
                                variant="outlined"
                                type="text"
                                margin="dense"
                                value={Email}
                                autoComplete="off"
                                placeholder="Enter Email Id"
                                InputLabelProps={{style: {fontSize: 13, color: "#ccc"}}}
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                    setErrorNo(undefined);
                                    setErrorMsg('');
                                }}
                                error={errorNo === 3}
                                helperText={errorNo === 3 ? errorMsg : ''}
                            />
                        </Grid>

                        <Grid item md={12} lg={12} className={classes.customMarginTop}>
                            <Typography variant="subtitle1" 
                                className={classes.customMarginBottom}>
                                Mobile
                            </Typography>

                            <TextField fullWidth 
                                id="mobile" 
                                variant="outlined"
                                type="text"
                                margin="dense"
                                value={phoneNo}
                                autoComplete="off"
                                placeholder="Enter Mobile No"
                                InputLabelProps={{style: {fontSize: 13, color: "#ccc"}}}
                                onKeyPress={(e)=>phoneNumberValidate(e)} 
                                onChange={(e) => {
                                    setPhoneNo(e.target.value);
                                    setErrorNo(undefined);
                                    setErrorMsg('');
                                }}
                            
                                
                            
                                error={errorNo === 4}
                                helperText={errorNo === 4 ? errorMsg : ''}
                            />
                        </Grid>

                        <Grid item md={12} lg={12} className={classes.customMarginTop}>
                                <ChecklistCompaniesFilter fromContactComponent={true}
                                    onSelectedCompanies={onSelectedCompanies}
                                    selectedCompanies={selectedCompanies}
                                    isFromContactDrawer={true}
                                    contactObj={props.contactObj}
                                    callDeleteContactApiFromClientDetails={props.callDeleteContactApiFromClientDetails}/>
                        </Grid>
                        <div className='modal__footer'>
                            <Button variant="contained" color="primary" 
                                onClick={onCreateOrUpdateContact}
                                disabled={loading}
                                className="modal__button">
                                {loading && <CircularProgress size={15} className='loading__style'/>}
                                {Object?.keys(props.contactObj)?.length !== 0 ? 'Update' : 'Save'}       
                            </Button>
                            <Button variant="outlined" style={{
                                minWidth: '130px',
                            }} onClick={onCancelClick}>
                                Cancel
                            </Button>
                        </div>
                    </Grid> 
                </div>
                
            </RightToLeftSideDrawer>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
});

export default connect(mapStateToProps)(ContactDrawer);