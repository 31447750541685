
/**logout filters */
export const logoutFilters = () => ({
    type: 'LOGOUT_FILTERS'
});


/**Checklist Filters (start_date, end_date, members, labels, companies) */
export const setStartDateFilter = (start_date) => ({
    type: 'SET_START_DATE_FILTER',
    start_date
});

export const setEndDateFilter = (end_date) => ({
    type: 'SET_END_DATE_FILTER',
    end_date
});

//status
export const addSelectedStatusToFilter = (status) => ({
    type: 'ADD_SELECTED_STATUS_TO_FILTER',
    status
});

export const removeSelectedStatusFromFilter = (status) => ({
    type: 'REMOVE_SELECTED_STATUS_FROM_FILTER',
    status
});

//members filter
export const addSelectedMemberToFilter = (member) => ({
    type: 'ADD_SELECTED_MEMBER_TO_FILTER',
    member
});

export const removeSelectedMemberFromFilter = (member) => ({
    type: 'REMOVE_SELECTED_MEMBER_FROM_FILTER',
    member
});

//labels filter
export const addSelectedLabelToFilter = (label) => ({
    type: 'ADD_SELECTED_LABEL_TO_FILTER',
    label
});

export const removeSelectedLabelFromFilter = (label) => ({
    type: 'REMOVE_SELECTED_LABEL_FROM_FILTER',
    label
});

//companies filter
export const addSelectedCompanyToFilter = (company) => ({
    type: 'ADD_SELECTED_COMPANY_FILTER',
    company
});

export const deleteSelectedCompanyFromFilter = (company) => ({
    type: 'DELETE_SELECTED_COMPANY_FILTER',
    company
});

//Clear Filders
export const clearSelectedFilters = () => ({
    type: 'CLEAR_SELECTED_FILTERS'
});

//clear selected member
export const clearSelectedMembersFilter = () => ({
    type: 'CLEAR_SELECTED_MEMBERS'
});


/**SET_SHOW_UNASSIGNED_CHECKLIST */
export const setShowUnassignedChecklist = (showUnassignedChecklist) => ({
    type: 'SET_SHOW_UNASSIGNED_CHECKLIST',
    showUnassignedChecklist
})

/**
 * Search Member 
 */

export const setSearchMemberFilter = (name) => ({
    type: 'SET_SEARCH_MEMBER_FILTER',
    name
});

/**Search label */
export const setSearchLabelFilter = (name) => ({
    type: 'SET_SEARCH_LABEL_FILTER',
    name
});

//search by checklist name
export const setSearchChecklistFilter = (search_text) => ({
    type: 'SET_SEARCH_CHECKLIST_FILTER',
    search_text
});

/**SET_SHOW_APPROVED_CHECKLIST */
export const setShowApprovedChecklist = (showApprovedChecklist) => ({
    type: 'SET_SHOW_APPROVED_CHECKLIST',
    showApprovedChecklist
});

/**Sort by Filter */
export const setSortByFilter = (sortBy) => ({
    type: 'SET_SORTBY',
    sortBy
})


//billable status
export const addSelectedBillableStatusToFilter = (status) => ({
    type: 'ADD_SELECTED_BILLABLE_STATUS_TO_FILTER',
    status
});

export const removeSelectedBillableStatusFromFilter = (status) => ({
    type: 'REMOVE_SELECTED_BILLABLE_STATUS_FROM_FILTER',
    status
});


export const clearSelectedBillableStatus = () => ({
    type: 'CLEAR_BILLABLE_STATUS_FILTER'
});

 