import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Typography, Button, Tabs, Tab, Box, AppBar} from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import MobileBasicInfo from './MobileBasicInfo';
import MobileChangePassword from './MobileChangePassword';
import MobileMyOrganizations from './MobileMyOrganizations';

const useStyles = makeStyles((theme) => ({
    mainContent : {
        width: '100%',
        height:'calc(100vh - 100px)',
        backgroundColor: theme.palette.primary.light,
        transition: ".5s",
        flexGrow: 1,
        //overflow:'hidden',
        overflow:'auto',
        overflowX:'hidden'
        },
    container: {
        marginTop: "8px",
    },
    tabsAppbar: {
        boxShadow: "none",
        padding:'0px 12px'
    },    
    container: {
        marginTop: "8px"
        },
    tab: {
        flexGrow: 1,
        },  
    tabPanelContainer: {
        flexGrow: 1,
        padding:'15px',
        //height:'100vh',
    },       
}))


const MobileMyProfileComponent = (props) => {
    const classes = useStyles();

    const [tabValue, setTabValue] = useState(0);

    const TabPanel = (props) => {
        const { children, value, index, ...rest } = props;
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`full-width-tabpanel-${index}`}
                aria-labelledby={`full-width-tab-${index}`}
                {...rest}
            >
                {value === index && (
                    <Box p={0} height='100vh'>
                        <Typography component={'span'}>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    };

    const a11yProps = (index) => {
        return {
            id: `full-width-tab-${index}`,
            'aria-controls': `full-width-tabpanel-${index}`,
        };
    }

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };
    
    const handleChangeIndex = (index) => {
        setTabValue(index);
    };

    return (
        <div className={classes.mainContent}>
            <Grid item container direction="column">
                <AppBar position="static" color="default" className={classes.tabsAppbar}>
                    <Tabs
                        value={tabValue}
                        onChange={handleTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                        aria-label="full width tabs example"
                    >
                        <Tab label="Basic Info" {...a11yProps(0)} />
                        <Tab label="Change Password" {...a11yProps(1)} />
                        <Tab label="Organizations" {...a11yProps(2)} />
                    </Tabs>
                </AppBar>
                <Grid item style={{marginBottom:"1rem"}}>
                        {tabValue === 0 && <MobileBasicInfo/>}
                        {tabValue === 1 && <MobileChangePassword/>}
                        {tabValue === 2 && <MobileMyOrganizations />}
                </Grid>
            </Grid>
        </div>
    );
}

export default MobileMyProfileComponent;