import React, {useState} from 'react';
import RightToLeftSideDrawer from '../RightToLeftSideDrawer';
import { Grid, Typography, Button, TextField, CircularProgress } from "@material-ui/core";
import {connect} from 'react-redux';
import {setOrganizations, addOrganization} from '../../actions/organization';
import {useSnackbar} from 'notistack';
import {createNewOrganizationApi} from '../../services/authService';
import { consoleToLog, capitalizeFirstLetterOfEachWord, trackGAEvent } from '../../util/AppUtil';
import { nameValidator, emailValidator } from '../../util/validator';

const CreateOrganizationModal = (props) => {

    const {enqueueSnackbar} = useSnackbar();
    const [orgName, setOrgName] = useState('');
    const [orgEmail, setOrgEmail] = useState('');
    const [loading, setLoading] = useState(false);

    const onCreateOrgClick = (e) => {
        e.preventDefault();

        const organization_name = orgName;
        if (!organization_name) {
            enqueueSnackbar('Organization name can not be empty!', {
                variant:'error'
            });
            return;
        }
        const isOrganizationNameValid = nameValidator(organization_name);
        if (!isOrganizationNameValid) {
            enqueueSnackbar('Organization name is not valid! Please enter Alphabets only', {
                variant:'error'
            });
            return;
        }

        // const organization_email = orgEmail;
        // if (!organization_email) {
        //     enqueueSnackbar('Organization email can not be empty!', {
        //         variant:'error'
        //     });
        //     return;
        // }
        // const isOrganizationEmailValid = emailValidator(organization_email);
        // if (!isOrganizationEmailValid) {
        //     enqueueSnackbar('Email is not valid!', {
        //         variant:'error'
        //     });
        //     return;
        // }

        setLoading(true);
        //Create New Organization API
        createNewOrganizationApi(capitalizeFirstLetterOfEachWord(organization_name))
            .then((response) => {
                const res = response.data;
                consoleToLog("Response createNewOrganizationApi: ", res);

                enqueueSnackbar('Organization created!', {
                    variant:'success'
                });

                //update redux so that list will get updated
                props.addOrganization(res);

                //set text field value
                setOrgName('');
                //setOrgEmail('');
                setLoading(false);
                props.handleDrawerClose();
                trackGAEvent(props.selectedOrganization.organization.name, 'Create Organization', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
            })
            .catch((e) => {
                consoleToLog("createNewOrganizationApi error", e.response);
                setLoading(false);
                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant:'error'
                    });
                    return false;
                }
            })
    }

    return (
        <RightToLeftSideDrawer title="Create Organization"
            openDrawer={props.openDrawer}
            closeDrawer={props.handleDrawerClose}
        >
            <div className="modal__main">
                <Grid item container direction="column" spacing={2}>
                    <Grid item md={12} lg={12}>
                        <Typography variant="subtitle1" style={{marginBottom:'-4px'}}>
                            Organization Name
                        </Typography>
  
                        <TextField 
                            fullWidth
                            variant="outlined"
                            margin="dense"
                            type="text"
                            value={orgName}
                            placeholder="Enter Organization Name"
                            InputLabelProps={{style: {fontSize: 13, marginTop:'3px'}}}
                            onChange={(e) => setOrgName(e.target.value)}
                        />
                    </Grid> 

                    {/* <Grid item md={12} lg={12}>
                        <Typography  variant="subtitle1" style={{marginBottom:'-4px'}}>
                            Enter Organization Email
                        </Typography>

                        <TextField 
                            fullWidth
                            variant="outlined"
                            margin="dense"
                            type="text"
                            value={orgEmail}
                            placeholder="Enter Organization Email"
                            InputLabelProps={{style: {fontSize: 13, marginTop:'3px'}}}
                            onChange={(e) => setOrgEmail(e.target.value)}  
                        />
                    </Grid>   */}

                    <div className="modal__footer">
                        <Button variant="contained" color="primary" 
                            className="modal__button"  
                            onClick={onCreateOrgClick}>
                                {loading && <CircularProgress size={24} 
                                                style={{marginLeft: 0,
                                                marginRight: '16px',
                                                color: 'white'}}/>}
                                Create
                        </Button>
                        <Button variant="outlined" style={{
                            minWidth: '130px',
                        }} onClick={props.handleDrawerClose}>
                            Cancel
                        </Button>
                    </div>
                </Grid>    
            </div>
            
        </RightToLeftSideDrawer>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    organizations: state.organizations,
    selectedOrganization: state.organizationInfo.selectedOrganization,
});

const mapDispatchToProps = (dispatch) => ({
    setOrganizations: (organizations) => dispatch(setOrganizations(organizations)),
    addOrganization: (organization) => dispatch(addOrganization(organization))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateOrganizationModal);