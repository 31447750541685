import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

export const PublicRoute = ({
    auth,
    component: Component,
    ...rest
}) => (
    <Route
        {...rest}
        component={(props) => (
            (auth && auth.access_token) ? (
                <Redirect to='/dashboard' />
            ) : (
                    // <Component {...props}/>
                    props.location.state?.logOut 
                    ?
                    window.open(`${process.env.REACT_APP_AUTH_APP_DOMAIN_NAME}/logout?service=workplace`, '_self')
                    :
                    window.open(`${process.env.REACT_APP_AUTH_APP_DOMAIN_NAME}/login?service=workplace`, '_self')
                )
        )}
    />
);

const mapStateToProps = (state) => ({
    auth: state.auth
});

export default connect(mapStateToProps)(PublicRoute);