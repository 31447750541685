import React, {useState, useEffect, useRef} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import { Checkbox, Grid, Typography,Tooltip, Button, Divider, CircularProgress, ClickAwayListener } from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import { getChecklistApi, exportChecklistApi, exportChecklistTaskApi, archiveChecklistApi, moveChecklistToFolderApi,
    modifyMembersFromChecklistApi, modifyApproversFromChecklistApi, updateCheckListDueDateApi, modifyLabelsFromChecklistApi,
    completeChecklistApi, sendChecklistToApproversApi, bulkAssignRemoveMembersApi, bulkApplyRemoveLabelsApi,
     bulkCompleteChecklistApi, bulkSetChecklistDueDateApi, bulkMoveToFolderApi, editTaskWidgetApi, getEmailFollowupApi} from '../services/authService';
import {connect} from 'react-redux';
import { consoleToLog, shouldDisable, AccessLevel, getStatusColor, getAssigneesFromChecklistItem, getAssigneesOfApprover,
    getLabelsFromChecklistItem, checkWheatherLoggedInUserIsApprover, sendChecklistToApprover, checkAllAppoverApprovedChecklist,
    checklistMembers, checklistLabels, updateTemplateArr, bulkUpdateChecklistDueDate, bulkChecklistMoveToFolder, trackGAEvent, OverlayMessage, statusColor} from '../util/AppUtil';
import {useIsMount} from './useIsMount';
import {setLoading} from '../actions/loading';
import Popover from '@material-ui/core/Popover';
import Calender from './Calender';
import ChecklistFoldersComponent from './ChecklistFoldersComponent';
import {history} from '../routers/AppRouter';
import { useParams } from 'react-router-dom';
import CreateEditFolderModal from './modals/CreateEditFolderModal';
import CreateChecklistModal from './modals/CreateChecklistModal';
import { useSnackbar } from 'notistack';
import ChecklistFiltersModal from './modals/ChecklistFiltersModal';
import SelectFromMemberList from './checklist/SelectFromMemberList';
import SelectFromApproverList from './checklist/SelectFromApproverList';
import SelectFromLabelList from './checklist/SelectFromLabelList';
import CircularProgressWithLabel from './CircularProgressWithLabel';
import moment from 'moment';
import Confetti from 'react-confetti';
import CheckListDetailPage from "../pages/modal/CheckListDetailPage";
import {addSelectedLabelToFilter} from '../actions/filters';
import { OverlayView,  setOverlayToken, setOverlayView, setBulkObj, setIsBulkMoveToFolder, setIsBulkCompleteChecklist, setOvelayMessage  } from '../actions/overlay';
import { CSSTransition, TransitionGroup} from 'react-transition-group';
import { v4 as uuidv4 } from 'uuid';
import {setOrgTemplates, setSelectedTemplate, setSelectedDrawerState, SelectedDrawerState, fetchTemplateList, setChecklistArray} from '../actions/selectedOrganization';
import RightToLeftSideDrawer from './RightToLeftSideDrawer';
import { useLocation } from 'react-router-dom';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import {setCurrentEmailState} from '../actions/selectedOrganization';
import CheckIcon from '@material-ui/icons/Check';
import {disableEmailFollowupApi} from '../services/authService';
import {setEmailObj, showEmailIcons} from '../util/AppUtil';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ImportTemplateModal from './modals/ImportTemplateModal';
import {setEmailSentFrom} from '../actions/selectedOrganization';
import {checkWheatherLoggedInUserIsAssignees} from '../util/AppUtil';
import SendForReviewDialog from './SendForReviewDialog';
import UndoIcon from '@material-ui/icons/Undo';
import {undoCompleteApi} from '../services/authService';

const useStyles = makeStyles((theme) => ({
    mainbg: {
        //backgroundColor:'white',
        marginTop:"20px",
        borderRadius:'4px',
        marginBottom:'32px'
    },
    checklisthover :{
        padding: '0px 10px 0px 0px',
        '& .onChecklist__hover' : {
            visibility: 'hidden',
        },
        '&:hover .onChecklist__hover' : {
            visibility: 'visible'
        },
        '& .more_actionButton' : {
            display:'none',
            width:'90px',
            background:'white',
            border:'1px solid #ccc',
            borderRadius:'5px',
            fontSize:'13px',
            padding:'1px 1px',
            marginRight:'-1px',
            margin:'6px 0px'
        },
        '& .more_actionButton1' : {
            display:'none',
            width:'120px',
            background:'white',
            border:'1px solid #ccc',
            borderRadius:'5px',
            fontSize:'13px',
            padding:'1px 0px',
            margin:'6px 0px'
        },
        '& .more_actionButton2' : {
            display:'none',
            width:'140px',
            background:'white',
            border:'1px solid #ccc',
            borderRadius:'5px',
            fontSize:'13px',
            padding:'1px 0px',
            dispatch:'flex',
            alignItems:'center',
            margin:'6px 0px'
        },
        '& .action__icon' : {
            display:'none',
            color: "black",
            fontSize: "15px",
            padding: '5px 5px 5px 5px',
            border:'1px solid #ccc',
            borderRadius:'5px',
            cursor:'pointer',
            margin:'6px 0px'
        },
        '&:hover .action__icon' : {
            display:"flex", 
            alignItems:'center'
        },
        '&:hover .more_actionButton' : {
            display:'flex'
        },
        '&:hover .more_actionButton1' : {
            display:'flex'
        },
        '&:hover .more_actionButton2' : {
            display:'flex'
        },
        '& .hideOnHover' : {
            display:'flex',
        },
        '&:hover .hideOnHover' : {
            display:'none'
        }
    },
    topbtns : {
        color: "black",
        fontSize: "15px",
        minWidth:'20px',
        padding:'5px 6px', 
    },
    sidemenubtns: {
        color: "#808080",
        fontSize: "15px",
        minWidth:'20px',
        padding: '0px 5px'
    },
    labels : {
        display:'flex',
        marginTop:'3px',
        flexGrow:'1',
        '& .labellist:last-child' : {
            '& .bullet' : {
            display: 'none'
            }
        }
    },
    status : {
        color: '#DB4C3F',
        padding:'1px 12px',
        border: '1px solid #DB4C3F',
        borderRadius:'5px'
    },
    containermargin: {
        marginBottom:'-10px'
    },
    icontext : {
        color:'#808080',
        padding:'0 0px 0 5px',
    },
    loading: {
        position:'relative',
        left:'50%',
        right:'50%',
        top:'10%'
    },
    customColumn1: {
        flexBasis:'12.5%',
        maxWidth:'12.5%'
    },
    customColumn2: {
        flexBasis:'12.7%',
        maxWidth:'12.7%'
    },
    drawer: {
    //    width: drawerWidth,
        flexShrink: 0
    },
    drawerPaper: {
        width: (createChecklist) => {
           return createChecklist ? '550px' : '36%';
        },
        height: 'calc(100vh-51px)',
        flexGrow:1,
        top:'51px'
    },
  appBar: {
      background:'#F9F9F9',
      height:'45px',
      color:'#202020'
  },
  popover: {
    pointerEvents: 'none',
    marginLeft: "-150px"
    },
  paper: {
    padding: theme.spacing(1),
    boxShadow: "none",
    border: "1px solid rgba(0, 0, 0, 0.12)"
    },
  moveToFolder : {
    '&:hover' : {
        background:'#d3d3d3'
    },
    '& .displayList' : {
        display: 'none',
        position: 'absolute',
        top:0,
        left: 0,
        padding: 0,
    },
    '&:hover .displayList' : {
        display: 'block',
        position: 'absolute',
        left:'-140px',
        backgroundColor: '#ffffff',
        border: '1px solid #ccc',
        zIndex: '15',
        borderRadius: '4px',
        width: '180px',
        },
    },
    moreActionDropdown : {
        position: 'absolute',
        top: '0',
        left:'-50px',
        zIndex: '10',
    },
    folderList: {
        display: 'block',
        padding:'7px 10px 7px 16px',
        cursor:'pointer',
        '&:hover' : {
            background:'#d3d3d3'
        }
    },
    displayFolderIcon:{
        fontSize:'16px',
        marginRight:"8px"
    },
    movetoRoot1 : {
        display: 'block',
        width: '100%',
        position: 'relative',
        padding:'7px 10px 7px 16px',
    },
    list : {
        cursor:'pointer',
        '&:hover' : {
            background:'#d3d3d3'
        }
    },
    calendarStyle: {
        position:'absolute', 
        right:'0px', 
        zIndex:'999', 
        background:'#fff', 
        border:'1px solid #ccc'
    },
    moreActionsIcons: {
        marginRight: '4px', 
        color: '#555'
    },
    actionsIcons: {
        color: '#b5bbc2',
        fontSize: '18px',
    },
    moreActionsContainer: {
        marginBottom:'3px',
        position:"relative", 
        display:'flex',
        alignItems:'center',
        flexDirection:'row-reverse',
        justifyContent:'flex-start'
    },
    followupTextStyle: {
        width:'auto',
        overflow:'hidden',
        whiteSpace:'nowrap',
        textOverflow:'ellipsis'
    },
    noActivityImage: {
        height: "14rem",
        width: "16rem",
        marginTop: "3rem",
    },
    noActivityText: {
        fontSize: "20px",
        fontWeight: "600",
    },
    noActivityButton: {
        fontSize: "15px",
        width: "190px",
        marginTop: "20px",
    },
    buttonPlusIcon: {
        marginRight:'4px', 
        fontSize:'1.2rem', 
        display:'flex',
        alignItems:'center',
        marginTop:'-2px'
    },
    emailFollowupTag: {
        height:'20px',
        borderRadius:'14px',
        width:'70px',
        color:'#747775',
        textAlign:'center',
        fontSize:'12px',
        background:'#ededed',
        marginLeft:'8px',
        marginTop:'-3px',
        border:'1px solid #ccc'
    },
    emailIcons: {
        filter: 'invert(46%) sepia(96%) saturate(5%) hue-rotate(346deg) brightness(96%) contrast(96%)'
    },
    templateNameStyle: {
        lineHeight:'42px', 
        color: '#888888', 
        fontStyle:'italic',
        maxWidth:'120px',
        overflow:'hidden',
        whiteSpace:'nowrap',
        textOverflow:'ellipsis',
    }
}));

const ChecklistComponent = (props) => {
    const createChecklist = props.createChecklist
    const classes = useStyles(createChecklist);

    /* ****************For Due Date calender********************** */
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorEl1, setAnchorEl1] = useState(null)
/* ******************************* */
    const [page, setPage] = useState(1);
    const [nextPage, setNextPage] = useState(false);
    const [checkList, setCheckList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isMoreLoading, setIsMoreLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const [selectedChecklist, setSelectedChecklist] = useState([]);
    const [checklistGroup, setChecklistGroup] = useState([]);
    const [isActive, setIsActive] = useState(false);
    const [checklistId, setChecklistId] = useState(undefined);
    const [folderObj, setFolderObj] = useState({});
    const [selectedChecklistItem, setSelectedChecklistItem] = useState(undefined);
    const [confetti, setConfetti] = useState({run: false, recycle: false});
    const [animationActive, setAnimationActive] = useState(false);
    const [allowUncheck,setAllowUncheck]= useState(true);
    const [mode, setMode] = useState("out-in");
    const { enqueueSnackbar } = useSnackbar();
    const [isShiftDown, setIsShiftDown] = useState(false);
    const [lastSelectedItem, setLastSelectedItem] = useState(false);
    const [showDueDatePopover, setShowDueDatePopover] = useState(false);
    const [followupList, setFollowupList] = useState([]);
    const [isActive1, setIsActive1] = useState(false);
    const [openImportTemplateModal, setOpenImportTemplateModal] = useState(false);
    const [openFromDialog, setOpenFromDialog] = useState(false);
    const [openSendForReviewDialog, setOpenSendForReviewDialog] = useState(false);
    const [dontChangeSelectedChecklist, setDontChangeSelectedChecklist] = useState(false);

    const upcomingChecklist = props.setDrawerState === SelectedDrawerState.SHOW_UPCOMING_CHECKLIST;

    let listEl = null;


    // setvalues for modal =>tabs
    const [tab,setTab]=useState(0);

    /**Access Levels */
    const { access_level } = props.selectedOrganization || {};
    const accessLevelManager = shouldDisable(AccessLevel.getUserAccessLevelValue(access_level), AccessLevel.MANAGER);
    const accessLevelMember = shouldDisable(AccessLevel.getUserAccessLevelValue(access_level), AccessLevel.MEMBER);
    const accessLevelGuest = (AccessLevel.getUserAccessLevelValue(access_level) === AccessLevel.GUEST) ? true : false;

    const isMount = useIsMount();
    const logged_in_user_id = props.auth.user ? props.auth.user?.id : null;

    let {slug} = useParams();
    const location = useLocation();
    // console.log('location in checklist Obj', location);

    useEffect(() => {
        if(!isMount) {
                document.title = 'Work'
        }
    }, [slug]);

    /**Right menu names */
    const CREATE_FOLDER = 'Create Folder';
    const EDIT_FOLDER = 'Update Folder';
    const CREATE_CHECKLIST = 'Create Checklist';
    const FILTERS = 'Filters';

    const rightToLeftSideDrawerTitle = () => {
        if(props.createFolder) {
            return CREATE_FOLDER;
        }
        if(props.editFolder) {
            return EDIT_FOLDER;
        }
        if(props.createChecklist) {
            return CREATE_CHECKLIST;
        }
        if(props.openFilters) {
            return FILTERS;
        }

    }

    const onClick = (e, checklist_id) => {
    setIsActive(!isActive);
    setChecklistId(checklist_id);
    }

    const handleClickNew = (event, checklistItem) => {
        setAnchorEl(event.currentTarget);
        setSelectedChecklistItem(checklistItem);
      };

      const handleClose = () => {
        setAnchorEl(null);
      };

      const handleClose1 = () => {
        setAnchorEl1(null);
      };

        //open popover
        const open = Boolean(anchorEl);
        const id = open ? 'simple-popover' : undefined;

        const open1 = Boolean(anchorEl1);
        const id1 = open1 ? 'simple-popover1' : undefined;
    
      const clickedDate = (str) => {
        
        const checklist_id = selectedChecklistItem.id;
        const checklistDueDate = !str ? str : moment(str).format("YYYY-MM-DD");
        
        updateCheckListDueDateApi(checklist_id, checklistDueDate)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response updateCheckListDueDateApi: ", res);
                const updatedChecklist = checkList.map((checklistItem) => {
                    if (checklistItem.id === selectedChecklistItem.id) {
                        checklistItem.due_date = checklistDueDate ;
                        
                    }
                    return checklistItem;
                });

                trackGAEvent(props.selectedOrganization.organization.name, 'Checklist Set DueDate', `${props.auth.user.firstname} ${props.auth.user.lastname}`);

                setCheckList(updatedChecklist);
                setSelectedChecklistItem(undefined);
                anchorEl1 && handleClose1();
            })
            .catch((e) => {
                consoleToLog("updateCheckListDueDateApi error", e.response);
                if (e.response.data && e.response.data.message) {
                    //toast.error(e.response.data.message);
                    return false;
                }
            });

        handleClose(null);
      }

      const isDueDateOverDue = (checklistItem) => {
        var dueDate, currentDate;
        //currentDate
        currentDate = moment(new Date()).format('YYYY-MM-DD');


        //Due Date
        if (checklistItem.due_date)
            dueDate = moment(checklistItem.due_date, "YYYY-MM-DD").format("YYYY-MM-DD")

        //consoleToLog("Now: ", currentDate, "  formatedDueDate: ", dueDate);

        //if Due date available then compare else return false
        if (dueDate) {
            //consoleToLog("is same: ", moment(currentDate).isSame(dueDate));
            //If dates are same its not overdue
            if (moment(currentDate).isSame(dueDate)) return false;

            //check current date is greater/after than provided date if greater return true
            if (moment(currentDate).isAfter(dueDate)) return true;
            else return false;
        }
        else return false;
    }

    useEffect(() => {
         //consoleToLog('First Useffect'); 
        //consoleToLog("bgObj in Checklist Component Length",Object.keys(props.overlay.bgObj));
        modalStatus();
        const template_id = props.selectedTemplate ? props.selectedTemplate.template_id : undefined;
            if(template_id) {
                fetchChecklist();
            }
    }, []);

    useEffect(()=>{
         if(!isMount){
             //consoleToLog("bgObj in Checklist Component Length",Object.keys(props.overlay.bgObj));

            if(props.overlay.isBulkMoveToFolder === false && (props.overlay.bgObj.checklists !== null && props.overlay.bgObj.checklists?.length > 0) ){
                var newChecklist = checklistLabels(checkList, props.overlay.bgObj.checklists);
                setCheckList(newChecklist);
            }
            }

            if (props.overlay.bgObj.template && props.overlay.bgObj.template !== null && props.overlay.bgObj.template.length > 0){
                let templatesArr = updateTemplateArr(props.templates, props.overlay.bgObj.template, props.selectedTemplate);
                props.setOrgTemplates(templatesArr);
            } 

            if(props.overlay.isBulkMoveToFolder === true){
                const updatedChecklistGroup = bulkChecklistMoveToFolder(checklistGroup, props.overlay.bgObj.checklist_groups);
                setChecklistGroup(updatedChecklistGroup);

                let totalChecklist = props.overlay.bgObj.checklists.length;
                const updatedChecklist = checkList.filter((checklistItem) => {
                   return  !props.overlay.bgObj.checklists.find((checklist) => {
                            return checklistItem.id === checklist.id
                        });
                });

                if(updatedChecklist.length === 0) {
                    pageReset();
                } else {
                    setCheckList(updatedChecklist);
                    setTotal(total - totalChecklist);
                };
                setAnimationActive(true);
            }

            if(props.overlay.isBulkCompleteChecklist === true){
                setConfetti({run: true, confetti: true});
                setAnimationActive(true);
                setTimeout(() => {
                    setConfetti({run: false, confetti: false});
                    const totalChecklist = props.overlay.bgObj.checklists.length;
                    const newCheckListArr = checkList.map((checklistItem) => {
                        props.overlay.bgObj.checklists.map((checklist) => {
                            if (checklistItem.id === checklist.id) {
                                checklistItem.status = checklist.status;
                            }
                            return checklistItem;
                        })
                        return checklistItem;
                    }).filter((checklistItem) => checklistItem.status !== 'Completed');

                    if (props.overlay.bgObj.template && props.overlay.bgObj.template !== null && props.overlay.bgObj.template.length > 0){
                        let templatesArr = updateTemplateArr(props.templates, props.overlay.bgObj.template, props.selectedTemplate);
                        props.setOrgTemplates(templatesArr);
                    }
                    if(newCheckListArr.length === 0) {
                        pageReset();
                    } else {
                        setCheckList(newCheckListArr);
                        setTotal(total - totalChecklist);
                    }
                    setAnimationActive(false);
                }, 3000);
            }
            props.setIsBulkMoveToFolder(false);
            props.setIsBulkCompleteChecklist(false);
            props.setCheckAll(false);
            //props.setOvelayMessage("");

    },[props.overlay.bgObj]);

    var urlPath = window.location.pathname.split("/");
    var curr_slug = urlPath[urlPath.length-1];

    useEffect(() => {
        if(!isMount) {
            if(curr_slug) {
                modalStatus();
            }
        }

    }, [curr_slug]);

    useEffect(() => {
        if (isMount) {
           // consoleToLog('Second  Useffect');
        }
        else {
            if (isMoreLoading) {
                //consoleToLog('More Loading Useffect');
                consoleToLog('isMore loading api called')
                fetchChecklist();
            }
        }
    }, [isMoreLoading]);

    useEffect(() => {
        if(isMount) {
           // consoleToLog('Third  Useffect');
        } else {
           // consoleToLog('If any of the filter is changed');
            pageReset();
        }
    }, [props.selectedTemplate?.template_id, slug, props.startDateFilter, props.endDateFilter, props.selectedStatusFilter,
        props.selectedMembersFilter, props.selectedLabelsFilter, props.companiesFilter, 
        props.searchChecklistFilter, props.sortByFilter, 
        props.showUnassignedChecklist, props.selectedBillStatusFilter]);

    useEffect(() => {
        if(isMount) {
        //    consoleToLog('Fourth  Useffect');
        } else {
            if(page === 1) {
                fetchChecklist();
            }
        }
    }, [page]);

    useEffect(() => {
        if(isMount) {
        } else {
            // send SelectedChecklistCount
            props.getSelectedChecklistCount(selectedChecklist);
            if(selectedChecklist.length === 0) {
                consoleToLog("USeEffect: Inside if condition selectedChecklist",selectedChecklist.length);
                // setAllowUncheck(false);
                props.setCheckAll(false);
            } else {
                    if(!props.addToCart) {
                        props.setCheckAll(true);
                    }
                // setAllowUncheck(true);
            }
        }
    }, [selectedChecklist]);

    useEffect(() => {
        if(isMount) {
        } else {
            if(props.checkAll && selectedChecklist.length === 0) {
                setSelectedChecklist(checkList.map(checklistItem => checklistItem))
            } else {
                // if(!props.checkAll && allowUncheck) {
                //     setSelectedChecklist([])
                // }
                if(!props.checkAll) {
                    setSelectedChecklist([]);
                }
            }
        }
    }, [props.checkAll]);

    useEffect(() => {
        if(!isMount) {
            if(dontChangeSelectedChecklist) {
                setDontChangeSelectedChecklist(false);
            } else {
                setSelectedChecklist(props.selectedChecklistArr);
                if(props.selectedChecklistArr && props.selectedChecklistArr.length === 0) {
                    setIsShiftDown(false);
                    setLastSelectedItem(false);
                }
            }
        }
    }, [props.addToCart, props.selectedChecklistArr])

    useEffect(() => {
        if(isMount) {

        } else {
            props.setCheckAll(false);
        }
    }, [slug])

    /* useEffect(() => {
        consoleToLog("allowUncheck in useEffect",allowUncheck);
    }, [allowUncheck])
 */
    useEffect(() => {
        if(isMount) {
        } else {
            setChecklistGroup(checklistGroup)
        }
    }, [checklistGroup]);

    useEffect(() => {//Bulk Add Members
        if(isMount) {
        } else {
            if(props.addBulkMembers) {
                onBulkAssignMember();
            }
        }
    }, [props.addBulkMembers]);

    useEffect(() => {//Bulk Remove Members
        if(isMount) {
        } else {
            if(props.removeBulkMembers) {
                onBulkRemoveMember();
            }
        }
    }, [props.removeBulkMembers]);

    useEffect(() => {//Bulk Add Labels
        if(isMount) {
        } else {
            if(props.applyBulkLabels) {
                onBulkApplyLabel();
            }
        }
    }, [props.applyBulkLabels]);

    useEffect(() => {//Bulk Remove Labels
        if(isMount) {
        } else {
            if(props.removeBulkLabels) {
                onBulkRemoveLabel();
            }
        }
    }, [props.removeBulkLabels]);

    useEffect(() => {//Bulk Complete Checklist
        if(isMount) {
        } else {
            if(props.bulkComplete) {
            onBulkCompleteChecklist();
            }
        }
    }, [props.bulkComplete]);

    useEffect(() => {//Bulk Set Due Date
        if(isMount) {

        } else {
                if(props.bulkDueDate !== undefined) {
               onBulkSetChecklistDueDate();
                }
        }
    }, [props.bulkDueDate]);

    useEffect(() => {//Bulk Move to folder
        if(isMount) {

        } else {
            if(props.bulkMoveToFolder !== undefined) {
               onBulkMoveToFolder();
            }
        }
    }, [props.bulkMoveToFolder]);

    useEffect(() => {//Set Checklist Group
        if(isMount) {

        } else {
            props.getChecklistFolders(checklistGroup);
        }
    }, [checklistGroup]);

    useEffect(() => {//Set Checklist Group
        if(isMount) {

        } else {
            if(props.refreshChecklist) {
                pageReset();
            }
        }
    }, [props.refreshChecklist]);

    useEffect(() => {
        if(!isMount) {
            callTemplateApi();
        }
    }, [ props.startDateFilter, props.endDateFilter, props.companiesFilter, props.selectedMembersFilter]);

    // useEffect(() => {
    //     if(!isMount) {
    //         if(props.checklistArray && props.checklistArray.length === 0 && props.currentEmailState?.selectedChecklistId && !props.overlay.isSendEmail) {
    //             const updatedCheckListArr = checkList.map((checklistItem) => {
    //                 if(checklistItem.id === props.currentEmailState?.selectedChecklistId) {
    //                     checklistItem.out_email_count = checklistItem.out_email_count + 1;
    //                 }
    //                 return checklistItem;
    //             })
                
    //             setCheckList(updatedCheckListArr);
    //             props.fetchChecklistFromChild(updatedCheckListArr);
    //             props.setCurrentEmailState(undefined);
    //         }
    //     }
    // }, [props.overlay.isSendEmail]);

    // useEffect(() => {
    //     if(!isMount) {
    //         if(props.checklistArray && props.checklistArray.length > 0 && !props.overlay.isSendEmail) {
                
                
    //             setCheckList(updatedCheckListArr);
    //             props.fetchChecklistFromChild(updatedCheckListArr);
    //             props.setChecklistArray([]);
    //         }
    //     }
    // }, [props.checklistArray])

    // useEffect(() => {
    //     if(isMount) {

    //     } else {
    //            if(selectedChecklist.length > 50) {
    //                 console.log('useEffect called');
    //                 console.log('selected checklist', selectedChecklist.length - 50);
    //                 let newArray = selectedChecklist.splice(-50,  -selectedChecklist.length - 1)
    //                 setSelectedChecklist(newArray);
    //            }
    //      }
    // }, [selectedChecklist])

    useEffect(() => {
        if(!isMount) {
            document.addEventListener("keyup", handleKeyUp, false);
            document.addEventListener("keydown", handleKeyDown, false);
            listEl?.addEventListener("selectstart", handleSelectStart, false);
            // return () => {
            //     listEl.current.removeEventListener("selectstart", handleSelectStart, false);
            // };

            return () => {
                document.removeEventListener("keyup", handleKeyUp);
                document.removeEventListener("keydown", handleKeyDown);
                listEl?.removeEventListener("selectstart", handleSelectStart);
            }
        }
    }, [selectedChecklist, lastSelectedItem]);

    useEffect(() => {
        if(!isMount) {
            if(selectedChecklist && selectedChecklist.length > 0) {
                selectedChecklist.splice(50, selectedChecklist.length-1);
                consoleToLog('selected checklist**** ', selectedChecklist.length)

                props.getSelectedChecklistCount(selectedChecklist)
            }
        }
    }, [selectedChecklist]);

    useEffect(() => {
        if(!isMount) {
                setCheckList(props.checkListFromParent);
        }
    }, [props.checkListFromParent]);

    useEffect(() => {
        if(!isMount) {
            if(props.setDrawerState === SelectedDrawerState.SHOW_ALL_CHECKLIST) {
                setLoading(true);
                pageReset();
            }

            if(props.setDrawerState === SelectedDrawerState.SHOW_OVERDUE_CHECKLIST) {
                setLoading(true);
                pageReset();
            }

            if(props.setDrawerState === SelectedDrawerState.SHOW_UPCOMING_CHECKLIST) {
                setLoading(true);
                pageReset();
            }
        }
    }, [props.setDrawerState]);

    const handleKeyUp = (e) => {
        if (e.key === "Shift" && isShiftDown) {
          setIsShiftDown(false);
        }
      }

    const handleKeyDown = (e) => {
        if (e.key === "Shift" && !isShiftDown) {
            setIsShiftDown(true);
        }
      }

    const handleSelectStart = (e) => {
        // if we're clicking the labels it'll select the text if holding shift
        if (isShiftDown) {
          e.preventDefault();
        }
      }


      const handleClick = (e, checklist) => {
        consoleToLog("****handleClick array in onSelectedChecklist** ",selectedChecklist.length);
        setDontChangeSelectedChecklist(true);
        if(selectedChecklist.length > 50 || selectedChecklist.length === 50){
            if(onSelectedChecklist(checklist)) {
                const array = selectedChecklist.filter((item) => item.id !== checklist.id);
                setSelectedChecklist(array);
            } else {    
                enqueueSnackbar('Cannot select more than 50 checklists !', {
                    variant: 'error'
                });
            }
        } else {
            const {value} = e.target;
            const nextValue = getNextValue(checklist);

            setSelectedChecklist(nextValue);
            setLastSelectedItem(checklist.id);
        }
    }

    const getNextValue = (value) => {
        const hasBeenSelected = !selectedChecklist.includes(value.id);
        consoleToLog('hasBeenSelected', hasBeenSelected);


        if (isShiftDown) {
            const newSelectedItems = getNewSelectedItems(value);
            // de-dupe the array using a Set
            const selections = [...new Set([...selectedChecklist, ...newSelectedItems])];
            consoleToLog('selections', selections);

            if (!hasBeenSelected) {
            consoleToLog('new selected', selections.filter(item => !selectedChecklist.includes(item.id)))
            return selections.filter(item => !selectedChecklist.includes(item.id));
            }

            return selections;
        }

        // if it's already in there, remove it, otherwise append it
        return selectedChecklist.includes(value)
            ? selectedChecklist.filter(item => item.id !== value.id)
            : [...selectedChecklist, value];
    }

    const getNewSelectedItems = (value) => {
    let currentSelectedIndex = checkList.findIndex(checklistItem => checklistItem.id === value.id);
    const lastSelectedIndex = checkList.findIndex(
        checklistItem => checklistItem.id === lastSelectedItem
    );
    //currentSelectedIndex = currentSelectedIndex > 49 ? 49 : currentSelectedIndex;


    consoleToLog('currentSelectedIndex:', currentSelectedIndex);
    consoleToLog('lastSelectedIndex:', lastSelectedIndex);

    return checkList
        .slice(
        Math.min(lastSelectedIndex, currentSelectedIndex),
        Math.max(lastSelectedIndex, currentSelectedIndex) + 1
        )
        .map(checklistItem => checklistItem);
    }


    const pageReset = () => {
        setPage(1);
        setNextPage(false);
        if(page === 1) {
            fetchChecklist();
        }
    }

    const callTemplateApi = () => {
        const organization_id = props.selectedOrganization.organization.id;
        
        props.fetchTemplateList(organization_id, props.startDateFilter, props.endDateFilter, 
            props.companiesFilter, props.selectedMembersFilter, props.selectedTemplate)
    }

    const onBulkAssignMember = () => {//Bulk Add Members
        const organization_id = props.selectedOrganization.organization.id;
        const checklists = selectedChecklist.map(item => item.id).join();
        const assignees = props.addBulkMembers ? `${props.addBulkMembers}` : '';
        const bulkStr = 'bulk_assign_member';
        var randomtoken = uuidv4();

        // props.setBulkLoading(true);
        props.setOverlayView(OverlayView.SHOW_OVERLAY_LOADING);
        props.setOverlayToken(randomtoken);

        bulkAssignRemoveMembersApi(organization_id, checklists, assignees, bulkStr, randomtoken)
                .then((response) => {
                    const res = response.data;
                    consoleToLog('Repsonse bulkAssignRemoveMembersApi', res);
                    // props.setBulkLoading(false);
                    // const newChecklistArr = checklistMembers(checkList, res.checklist);
                    // setCheckList(newChecklistArr);
                    trackGAEvent(props.selectedOrganization.organization.name, ' Bulk add Member', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
                })
                .catch((e) => {
                    consoleToLog("bulkAssignRemoveMembersApi error", e);
                    // props.setBulkLoading(false);
                    props.setOverlayView(OverlayView.NO_VIEW);
                    props.setOverlayToken("");
                    if (e.response.data && e.response.data.message) {
                         enqueueSnackbar(e.response.data.message, {
                            variant: 'error'
                        });
                        return false;
                    }
                });

    }

    const onBulkRemoveMember = () => {//Bulk Remove Members
        const organization_id = props.selectedOrganization.organization.id;
        const checklists = selectedChecklist.map(item => item.id).join();
        const assignees = props.removeBulkMembers ? `${props.removeBulkMembers}` : '';
        const bulkStr = 'bulk_remove_member';
        var randomtoken = uuidv4();

        props.setOverlayView(OverlayView.SHOW_OVERLAY_LOADING);
        props.setOverlayToken(randomtoken);

        // props.setBulkLoading(true);
        bulkAssignRemoveMembersApi(organization_id, checklists, assignees, bulkStr, randomtoken)
                .then((response) => {
                    const res = response.data;
                    consoleToLog('Repsonse bulkAssignRemoveMembersApi', res);
                    // props.setBulkLoading(false);

                    // const newChecklistArr = checklistMembers(checkList, res.checklist);
                    // setCheckList(newChecklistArr);
                    trackGAEvent(props.selectedOrganization.organization.name, 'Bulk remove Member', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
                })
                .catch((e) => {
                    consoleToLog("bulkAssignRemoveMembersApi error", e);
                    // props.setBulkLoading(false);
                    props.setOverlayView(OverlayView.NO_VIEW);
                    props.setOverlayToken("");
                    if (e.response.data && e.response.data.message) {
                        enqueueSnackbar(e.response.data.message, {
                            variant: 'error'
                        });
                        return false;
                    }
                });

    }

    const onBulkApplyLabel = () => {//Bulk Add Labels
        const organization_id = props.selectedOrganization.organization.id;
        const checklists = selectedChecklist.map(item => item.id).join();
        const assignees = props.applyBulkLabels ? `${props.applyBulkLabels}` : '';
        const start_date = props.startDateFilter;
        const end_date = props.endDateFilter;
        const bulkStr = 'bulk_apply_labels';
        var randomtoken = uuidv4();

        props.setOverlayView(OverlayView.SHOW_OVERLAY_LOADING);
        props.setOverlayToken(randomtoken);

        // props.setBulkLoading(true);
        bulkApplyRemoveLabelsApi(organization_id, checklists, assignees, start_date, end_date, bulkStr, randomtoken)
                .then((response) => {
                    const res = response.data;
                    consoleToLog('Repsonse bulkApplyRemoveLabelsApi', res);
                    // props.setBulkLoading(false);
                    // setCheckList(res.checklist);

                    // let templatesArr = updateTemplateArr(props.templates, res.template, props.selectedTemplate);
                    // props.setOrgTemplates(templatesArr);
                    trackGAEvent(props.selectedOrganization.organization.name, 'Bulk add label', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
                })
                .catch((e) => {
                    consoleToLog("bulkApplyRemoveLabelsApi error", e);
                    // props.setBulkLoading(false);
                    props.setOverlayView(OverlayView.NO_VIEW);
                    props.setOverlayToken("");
                    if (e.response.data && e.response.data.message) {
                        enqueueSnackbar(e.response.data.message, {variant: "error"});
                        return false;
                      }
                });

    }

    const onBulkRemoveLabel = () => {//Bulk Remove Labels
        const organization_id = props.selectedOrganization.organization.id;
        const checklists = selectedChecklist.map(item => item.id).join();
        const assignees = props.removeBulkLabels ? `${props.removeBulkLabels}` : '';
        const start_date = props.startDateFilter;
        const end_date = props.endDateFilter;
        const bulkStr = 'bulk_remove_labels';
        var randomtoken = uuidv4();

        props.setOverlayView(OverlayView.SHOW_OVERLAY_LOADING);
        props.setOverlayToken(randomtoken);
        // props.setBulkLoading(true);

        bulkApplyRemoveLabelsApi(organization_id, checklists, assignees, start_date, end_date, bulkStr, randomtoken)
                .then((response) => {
                    const res = response.data;
                    consoleToLog('Repsonse bulkApplyRemoveLabelsApi', res);
                    // props.setBulkLoading(false);

                    // const newChecklistArr = checklistLabels(checkList, res.checklist);
                    // setCheckList(newChecklistArr);

                    // let templatesArr = updateTemplateArr(props.templates, res.template, props.selectedTemplate);
                    // props.setOrgTemplates(templatesArr);
                    trackGAEvent(props.selectedOrganization.organization.name, 'Bulk remove label', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
                })
                .catch((e) => {
                    consoleToLog("bulkApplyRemoveLabelsApi error", e);
                    // props.setBulkLoading(false);
                    props.setOverlayView(OverlayView.NO_VIEW);
                    props.setOverlayToken("");
                    if (e.response.data && e.response.data.message) {
                        enqueueSnackbar(e.response.data.message, {
                            variant: 'error'
                        });
                        return false;
                    }
                });

    }

    const onBulkCompleteChecklist = () => {//Bulk Complete Checklist
        const organization_id = props.selectedOrganization.organization.id;
        const checklists = selectedChecklist.map(item => item.id).join();
        const start_date = props.startDateFilter;
        const end_date = props.endDateFilter;
        const user_ids = props.selectedMembersFilter && props.selectedMembersFilter.length > 0 ? props.selectedMembersFilter.map((member) => member.user.id).join() : '';
        const company_ids = props.companiesFilter && props.companiesFilter.length > 0 ? props.companiesFilter.map((company) => company.id).join() : '';
        var randomtoken = uuidv4();

        props.setOverlayView(OverlayView.SHOW_OVERLAY_LOADING);
        props.setOverlayToken(randomtoken);
        props.setIsBulkCompleteChecklist(true);
        // we wont be showing loader
        props.setBulkComplete(false);

        bulkCompleteChecklistApi(organization_id, checklists, start_date, end_date, randomtoken, user_ids, company_ids)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response bulkCompleteChecklistApi", res);
                // props.setBulkComplete(false)

                // setConfetti({run: true, confetti: true});
                // setAnimationActive(true);
                // setTimeout(() => {
                //     setConfetti({run: false, confetti: false});
                //     const totalChecklist = res.checklist.length;
                //     const newCheckListArr = checkList.map((checklistItem) => {
                //         res.checklist.map((checklist) => {
                //             if (checklistItem.id === checklist.id) {
                //                 checklistItem.status = checklist.status;
                //             }
                //         })
                //         return checklistItem;
                //     }).filter((checklistItem) => checklistItem.status !== 'Completed');

                //     let templatesArr = updateTemplateArr(props.templates, res.template);
                //     props.setOrgTemplates(templatesArr);

                //     if(newCheckListArr.length === 0) {
                //         pageReset();
                //     } else {
                //         setCheckList(newCheckListArr);
                //         setTotal(total - totalChecklist);
                //     }
                //     setAnimationActive(false);
                //     props.setCheckAll(false);
                // }, 3000);

                trackGAEvent(props.selectedOrganization.organization.name, ' Bulk complete checklist', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
            })
            .catch((e) => {
                consoleToLog(" bulkCompleteChecklistApi error", e);
                props.setIsBulkCompleteChecklist(false);
                // props.setBulkLoading(false);
                props.setOverlayView(OverlayView.NO_VIEW);
                props.setOverlayToken("");
                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant: 'error'
                    });
                    return false;
                }
            });
    }

    const onBulkSetChecklistDueDate = () => {//Bulk Set due Date
        const organization_id = props.selectedOrganization.organization.id;
        const checklists = selectedChecklist.map(item => item.id).join();
        const due_date = !props.bulkDueDate ? '' : moment(props.bulkDueDate).format("YYYY-MM-DD");
        props.setAnchorEl2(null);
        var randomtoken = uuidv4();
        // props.setBulkLoading(true);

        props.setOverlayView(OverlayView.SHOW_OVERLAY_LOADING);
        props.setOverlayToken(randomtoken);
        // we wont be showing loader
        props.setBulkDueDate(undefined);

        bulkSetChecklistDueDateApi(organization_id, checklists, due_date, randomtoken)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response bulkSetChecklistDueDateApi', res);
                // props.setBulkLoading(false);

                // const updatedChecklist = bulkUpdateChecklistDueDate(checkList, res.checklist);
                // setCheckList(updatedChecklist);

                // props.setBulkDueDate(undefined);
                trackGAEvent(props.selectedOrganization.organization.name, 'Bulk set due date', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
            })
            .catch((e) => {
                consoleToLog(" bulkSetChecklistDueDateApi error", e);
                // props.setBulkLoading(false);
                props.setOverlayView(OverlayView.NO_VIEW);
                props.setOverlayToken("");

                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant: 'error'
                    });
                    return false;
                }
            });

    }

    const onBulkMoveToFolder = () => {//Bulk Move to folder
        const organization_id = props.selectedOrganization.organization.id;
        const checklists = selectedChecklist.map(item => item.id).join();
        const start_date = props.startDateFilter;
        const end_date = props.endDateFilter;
        const group_id =  `${props.bulkMoveToFolder}`;
        var randomtoken = uuidv4();

        // consoleToLog('123456', props.bulkMoveToFolder)
        setAnimationActive(true);
        // props.setBulkLoading(true);
        props.setOverlayView(OverlayView.SHOW_OVERLAY_LOADING);
        props.setOverlayToken(randomtoken);
        props.setIsBulkMoveToFolder(true);

        // use a redux variable  true
        bulkMoveToFolderApi(organization_id, checklists, start_date, end_date, group_id, randomtoken)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response bulkMoveToFolderApi', res);
                // props.setBulkLoading(false);
                // const updatedChecklistGroup = bulkChecklistMoveToFolder(checklistGroup, res.checklist_group);
                // setChecklistGroup(updatedChecklistGroup);
                // let totalChecklist = res.checklist.length;

                // const updatedChecklist = checkList.filter((checklistItem) => {
                //    return  !res.checklist.find((checklist) => {
                //             return checklistItem.id === checklist.id
                //         });
                // });

                // if(updatedChecklist.length === 0) {
                //     pageReset();
                // } else {
                //     setCheckList(updatedChecklist);
                //     setTotal(total - totalChecklist);
                // }
                // props.setCheckAll(false);
                // setAnimationActive(false);
                props.setBulkMoveToFolder(undefined);
                trackGAEvent(props.selectedOrganization.organization.name, 'Bulk move to folder', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
            })
            .catch((e) => {
                consoleToLog(" bulkMoveToFolderApi error", e);
                // props.setBulkLoading(false);
                // use a redux variable  false
                props.setOverlayView(OverlayView.NO_VIEW);
                props.setOverlayToken("");
                props.setIsBulkMoveToFolder(false);
                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant: 'error'
                    });
                    return false;
                }
            });
    }

    const fetchChecklist = () => {
        let template_id = props.selectedTemplate?.template_id;
        // if(!template_id) {
        //     toast.error('Please select a template to view checklist');
        //     return;
        // }

        var user_ids;
        /* if(!user_ids) {
            user_ids = undefined;
        } */

        if(props.showUnassignedChecklist){
            user_ids = ',0'; //no need of user id'
        }
        else{
            user_ids = props.selectedMembersFilter.map((member) => member.user.id).join();
        }

        if (!user_ids) {
            user_ids = undefined;
        }

        let labels = props.selectedLabelsFilter.map((label) => label.id).join();
        if(!labels) {
            labels = undefined;
        }

        //let waiting_for_my_approval = false;
        // if(props.showWaitingForApprovalChecklist) {
        //     waiting_for_my_approval = true;
        // }

        const status = props.selectedStatusFilter.map(status => status).join();
        const search_text = props.searchChecklistFilter ? props.searchChecklistFilter : undefined;
        const organization_id = props.selectedOrganization.organization.id;
        const start_date = props.startDateFilter;
        const end_date = props.endDateFilter;
        const companiesFilter = props.companiesFilter;
        const sortBy = props.sortByFilter;
        const group_slug_code = slug;
        const billable_status = props.selectedBillStatusFilter?.map(status => status).join();
        const apiStr = props.selectedTemplate?.name === "All" ? '/' : `/template/${template_id}/`;
        const overdue = props.setDrawerState === SelectedDrawerState.SHOW_OVERDUE_CHECKLIST ? true : false;
        const upcoming = props.setDrawerState === SelectedDrawerState.SHOW_UPCOMING_CHECKLIST ? true : false;

        if(!isMoreLoading)  setLoading(true);
        if(props.refreshChecklist) {
            setLoading(false);
        }
        getChecklistApi(organization_id, template_id, page, start_date, end_date, user_ids, labels,
            status, search_text, companiesFilter, sortBy, group_slug_code, billable_status, overdue, upcoming, apiStr)
                .then((response) => {
                    const res = response.data;
                    consoleToLog("Response getChecklistApi: ", res);
                    setLoading(false);
                    setChecklistGroup(res.checklist_group);
                    props.setRefreshChecklist(false);
                    props.setSpin(false);
                    props.returnChecklistCount(res.checklist)

                    if(isMoreLoading) {
                        const newItems = checkList.concat(res.checklist);
                        let uniqueArray = newItems.reduce((unique, o) => {
                            if(!unique.some(obj => obj.id === o.id)) {
                                unique.push(o);
                            }
                            return unique;
                        },[]);

                        setCheckList(uniqueArray);
                        setPage(page + 1);
                        setNextPage(res.next_page);
                        setIsMoreLoading(!isMoreLoading);
                         // to parent
                        props.getChecklistCount(newItems.length);
                        props.fetchChecklistFromChild(newItems);
                    } else {
                        setCheckList(res.checklist);
                        setNextPage(res.next_page);
                        if(res.next_page) setPage(page + 1);
                        setTotal(res.total);
                        // to parent
                        props.getChecklistCount(res.checklist.length);
                        props.fetchChecklistFromChild(res.checklist);
                        }
                    })
                    .catch((e) => {
                        consoleToLog(" getChecklistApi error", e);
                        setLoading(false);
                        if (e.response.data && e.response.data.message) {
                            return false;
                        }
                    });

        }

    const onSelectedChecklist = (checklist) => {
        const tempArr =  selectedChecklist.filter((item) => item.id === checklist.id);
        return tempArr.length > 0 ? true : false;
    }

    const checklistLabelList = (checklistItem) => {
        const labels = checklistItem.checklist_labels_list.slice(2);
        const titles = labels.map((l) => l.name).join(', ');
        return <Tooltip title={titles} arrow >
                    <Typography variant='subtitle1'  style={{
                    marginLeft:'12px',
                    color:'#333',
                    textDecoration:'underline',
                    marginTop:'1px',
                    cursor:'pointer'
                    }}>+{labels.length} more</Typography>
                </Tooltip>
    }

    //on export task click
    const exportChecklistTaskClick  = (checklist_id) => {

        var randomtoken = uuidv4();
        consoleToLog("token for export: ", randomtoken);

        props.setOvelayMessage(OverlayMessage.EXPORT_MSG);
        props.setOverlayView(OverlayView.SHOW_OVERLAY_LOADING);
        props.setOverlayToken(randomtoken);
        setIsActive(false);

        exportChecklistTaskApi(checklist_id, randomtoken)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response exportChecklistTaskApi: ", res);
                trackGAEvent(props.selectedOrganization.organization.name, 'Checklist Export Task', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
            })
            .catch((e) => {
                consoleToLog("exportChecklistTaskApi error", e.response);

                props.setOverlayView(OverlayView.NO_VIEW);
                props.setOverlayToken("");

                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant:'error'
                    });
                    return false;
                }
            });
    }

    /**On Archive Checklist Click */
    const onArchiveChecklistClick = (checklist_id) => {
        setIsActive(false);
        
        setAnimationActive(true);
        archiveChecklistApi(checklist_id)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response archiveChecklistApi: ", res);
                
                const updatedChecklist = checkList.filter((checklistItem) => checklistItem.id !== checklist_id);
                setCheckList(updatedChecklist);
                setTotal(total - 1);
                setAnimationActive(false);

                trackGAEvent(props.selectedOrganization.organization.name, 'Checklist Archived', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
            })
            .catch((e) => {
                consoleToLog(" archiveChecklistApi error", e.response);
                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant:'error'
                    });
                    return false;
                }
            });
    }

    /**on move to folder click */
    const onFolderNameClick = (e, checklist_id, folder_id) => {
        e.preventDefault();
        const start_date = props.startDateFilter;
        const end_date = props.endDateFilter;
        setIsActive(false);

        setAnimationActive(true);
        moveChecklistToFolderApi(checklist_id, folder_id, start_date, end_date)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response moveChecklistToGroupApi', res);
                setFolderObj(res.checklist_group);
                enqueueSnackbar(res.message, {
                    variant:'success'
                });

                const updatedChecklistGroup = checklistGroup.map((folder) => {
                    if(folder.id === res.checklist_group?.id) {
                       return {
                           ...folder,
                           checklist_group_status : res.checklist_group.checklist_group_status
                       }
                    } else {
                        return folder
                    }
                });
                setChecklistGroup(updatedChecklistGroup);

                const updatedChecklist = checkList.filter((checklistItem) => checklistItem.id !== checklist_id);
                setCheckList(updatedChecklist);
                setTotal(total - 1);
                setAnimationActive(false);
                trackGAEvent(props.selectedOrganization.organization.name, 'Checklist Move To Folder', `${props.auth.user.firstname} ${props.auth.user.lastname}`);

            })
            .catch((e) => {
                consoleToLog('Error moveChecklistToGroupApi', e);
                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant:'error'
                    });
                    return false;
                }
            })
    }

    const setAssignedMemberList = (assignedMemberList, checklist_id) => {
        var assignees = assignedMemberList.map((member) => member.user.id).join();
        var member_names = assignedMemberList.map((member) => member.user.firstname + ' ' + member.user.lastname).join();

        //Modify Members From Checklist Api
        modifyMembersFromChecklistApi(checklist_id, assignees)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response modifyMembersFromChecklistApi: ", res);

                trackGAEvent(props.selectedOrganization.organization.name, 'Checklist Add Member', `${props.auth.user.firstname} ${props.auth.user.lastname}`);

                //change local state
                const newCheckListArr = checkList.map((checklistItem) => {
                    if (checklistItem.id === checklist_id) {
                        checklistItem.checklist_members_list = res.members;
                        return checklistItem;
                    }
                    return checklistItem;
                });
                setCheckList(newCheckListArr);
            })
            .catch((e) => {
                consoleToLog("modifyMembersFromChecklistApi error", e.response);

                //if failed, we still display last added member in SelectFromMemberList,
                //to remove that member just refresh state,
                //so that original checklist data passed to SelectFromMemberList, and members gets updated.
                setCheckList(checkList);

                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant:'error'
                    });
                    return false;
                }
            });
    }

    const setAssignedAproverList = (assignedApproverList, checklist_id) => {
        var approvers = assignedApproverList.map((member) => member.user.id).join();
        var approver_names = assignedApproverList.map((member) => member.user.firstname + ' ' + member.user.lastname).join();

        //Modify Approvers From Checklist Api
        modifyApproversFromChecklistApi(checklist_id, approvers)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response modifyApprovers FromChecklistApi: ", res);

                trackGAEvent(props.selectedOrganization.organization.name, 'Checklist Add Approver', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
                //change local state
                const newCheckListArr = checkList.map((checklistItem) => {
                    if (checklistItem.id === checklist_id) {
                        checklistItem.checklist_approvers_list = res.approvers;
                        return checklistItem;
                    }
                    return checklistItem;
                });
                setCheckList(newCheckListArr);
            })
            .catch((e) => {
                consoleToLog("modifyApproversFromChecklistApi error", e.response);

                //if failed, we still display last added member in SelectFromMemberList,
                //to remove that member just refresh state,
                //so that original checklist data passed to SelectFromMemberList, and members gets updated.
                setCheckList(checkList);

                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant:'error'
                    });
                    return false;
                }
            });
    }

     /**Modify Lables */
     const setAssignedLabelList = (assignedLabelList, checklist_id) => {
        const labels = assignedLabelList.map((label) => label.id).join();
        const labels_names = assignedLabelList.map((label) => label.name).join();

        //Modify Labels From Checklist Api
        modifyLabelsFromChecklistApi(checklist_id, labels)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response modifyLabelsFromChecklistApi: ", res);

                trackGAEvent(props.selectedOrganization.organization.name, 'Checklist Add Label', `${props.auth.user.firstname} ${props.auth.user.lastname}`);

                //change local state
                const newCheckListArr = checkList.map((checklistItem) => {
                    if (checklistItem.id === checklist_id) {
                        //checklistItem.checklist_labels_list = res.labels;
                        //return checklistItem;
                        return res.checklist;
                    }
                    return checklistItem;
                });
                setCheckList(newCheckListArr);
            })
            .catch((e) => {
                consoleToLog(" modifyLabelsFromChecklistApi error", e.response);

                //if failed, we still display last added label in SelectFromLabelList,
                //to remove that label just refresh state,
                //so that original checklist data passed to SelectFromLabelList, and labels gets updated.
                setCheckList(checkList)
                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant:'error'
                    });
                    return false;
                }
            });
    }

    const onCompleteChecklistClick = (checklist_id) => {
        const start_date = props.startDateFilter;
        const end_date = props.endDateFilter;
        const user_ids = props.selectedMembersFilter && props.selectedMembersFilter.length > 0 ? props.selectedMembersFilter.map((member) => member.user.id).join() : undefined;
        const company_ids = props.companiesFilter && props.companiesFilter.length > 0 ? props.companiesFilter.map((company) => company.id).join() : undefined;

        props.setCompleteChecklist(true);
        setAnimationActive(true);
        completeChecklistApi(checklist_id, start_date, end_date, user_ids, company_ids)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response completeChecklistApi: ", res);
                props.setCompleteChecklist(false);

                setConfetti({run: true, confetti: true});
                setTimeout(function () {
                    setConfetti({run: false, confetti: false});
                    const newCheckListArr = checkList.map((checklistItem) => {
                        if (checklistItem.id === checklist_id) {
                            checklistItem = res.checklist;
                            return checklistItem;
                        }
                        return checklistItem;
                    }).filter((checklistItem) => checklistItem.status !== 'Completed');
                    setCheckList(newCheckListArr);
                    setTotal(total - 1);
                    setAnimationActive(false);
                    }, 4000);

                    let templatesArr = updateTemplateArr(props.templates, res.template, props.selectedTemplate);
                    props.setOrgTemplates(templatesArr);
                    
                    trackGAEvent(props.selectedOrganization.organization.name, 'Checklist Complete', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
            })
            .catch((e) => {
                consoleToLog("completeChecklistApi error", e.response);
                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant:'error'
                    });
                    return false;
                }
            });
    }

    const handleDueDatePopOver = () => {
        return (<Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
            vertical: 'bottom',
            horizontal:'center'
            }}
            transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
            }}
        >
            <Calender clickedDate={clickedDate} />
        </Popover>)
    }

    const onLabelsClick = (selectedLabel) => {
        const selected_label = props.selectedLabelsFilter.filter((label) => label.id === selectedLabel.id);
        consoleToLog('checklist label clicked', selected_label);
        if(selected_label.length > 0) {
            // enqueueSnackbar('Label already added to filter', ({
            //     variant: 'error'
            // }));
            return;
        } else {
            props.addSelectedLabelToFilter(selectedLabel);
        }
        trackGAEvent(props.selectedOrganization.organization.name, 'Checklist Filtered By Label', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
    }

    const onSendForReviewClick = (checklist_id) => {
        setOpenSendForReviewDialog(true);
        // sendChecklistToApproversApi(checklist_id)
        //     .then((response) => {
        //         const res = response.data;
        //         consoleToLog("Response sendChecklistToApproversApi", res);
        //         enqueueSnackbar(res.message, ({
        //             variant: 'success'
        //         }));
        //         const newCheckListArr = checkList.map((checklistItem) => {
        //             if (checklistItem.id === checklist_id) {
        //                 checklistItem.status = "Pending Approval";
        //                 return checklistItem;
        //             }
        //             return checklistItem;
        //         });
        //         setCheckList(newCheckListArr);
        //         trackGAEvent(props.selectedOrganization.organization.name, 'Checklist Send To Review', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
        //     })
        //     .catch((e) => {
        //         consoleToLog("Response sendChecklistToApproversApi", e.response);
        //         if (e.response.data && e.response.data.message) {
        //             enqueueSnackbar(e.response.data.message, ({
        //                 variant: 'error'
        //             }));
        //             return false;
        //         }
        //     });
    }

    const displayStatus = (status) => {
        if(status === "None") return "Not Generated".charAt(0).toUpperCase() + "Not Generated".slice(1).toLowerCase();
        else return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase()
    }

    const onSendEmailClick = (checklist) => {
        trackGAEvent(props.selectedOrganization.organization.name, 'Sent Email Clicked from checklist', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
        getEmailFollowups(checklist, true);
        props.setEmailSentFrom('Checklist');
    }

    const setEmailObject = (checklist, followups) => {
        const data = setEmailObj(checklist, followups);
        props.setCurrentEmailState(data);
        props.handleSendEmailDialogOpen();
        setSelectedChecklistItem(checklist);
    }

    const getEmailFollowups = (checklist, fromSendEmail) => {
        const checklist_id = checklist.id;

        getEmailFollowupApi(checklist_id)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response getEmailFollowupApi: ', res);

                if(fromSendEmail){ 
                    setEmailObject(checklist, res.email_followups);
                } else {
                    setFollowupList(res.email_followups);
                    setIsActive1(!isActive1);
                    setChecklistId(checklist_id);
                }
            })
            .catch((e) => {
                consoleToLog('Error getEmailFollowupApi: ', e);
                if(e.response.data && e.response.data.message) {
                    return;
                }
            })
    }

    const onEmailFollowupClick = (followup) => {
        const organization_id = props.selectedOrganization.organization.id;
        const followup_id = followup.id;

        disableEmailFollowupApi(organization_id, followup_id)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response disableEmailFollowupApi', res);
                setFollowupList(followupList.filter((followup) => followup.id !== followup_id));
                updateFollowups(res.checklists);
                enqueueSnackbar(res.message, {variant:'success'});
            })
            .catch((e) => {
                consoleToLog('Error disableEmailFollowupApi', e.response);
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant:'error'
                    })
                    return;
                }
            })
    }

    const showEmailFollowupsPopover = (checklist) => {
        return (
            checklist.id === checklistId && isActive1 &&
            <ClickAwayListener onClickAway={onClickAway}>
                <Grid item className={`menu ${isActive1 ? "active" : "inactive"}`} style={{width:'230px', textAlign:'left'}}>
                    <ul >
                        {followupList && followupList.length > 0 && followupList.map((followup) => {
                            return <li className={classes.list} key={followup.id}
                                    onClick={() => onEmailFollowupClick(followup)}
                                    style={{borderBottom:'1px solid rgba(0,0,0,0.1)'}}>
                                    <Tooltip title={followup.subject} arrow>
                                    <a className={classes.followupTextStyle}>{followup.subject}</a>
                                    </Tooltip>
                            </li>
                        })
                            
                        }

                        
                    </ul>
                </Grid>
            </ClickAwayListener>
        )
    }

    const onClickAway = () => {
        setChecklistId(undefined);
        isActive1 && setIsActive1(false);
        isActive && setIsActive(false);
        isActive1 && setFollowupList([]);
    }

    const returnFontWeight = (checklistItem, checklistItemEmailRead, approverOrAssigneePresent) => {
        const readEmailUsersArr = checklistItem?.email_read_users;
        if(readEmailUsersArr === null || checklistItemEmailRead || (readEmailUsersArr && readEmailUsersArr.length === 0 && !approverOrAssigneePresent)) {
            return '400' // returning normal font weight
        } else {
            return '500' // returning bold font
        }
    }

    const returnBackground = (checklistItem, checklistItemEmailRead, approverOrAssigneePresent) => {
        const readEmailUsersArr = checklistItem?.email_read_users;
        if(readEmailUsersArr === null || checklistItemEmailRead  || (readEmailUsersArr && readEmailUsersArr.length === 0 && !approverOrAssigneePresent)) {
            return '#f3f3f3'
        } else {
            return '#fff'
        }
    }

    const checkChecklistEmailRead = (checklistItem, checklistItemEmailRead, approverOrAssigneePresent) => {
        const readEmailUsersArr = checklistItem?.email_read_users;
        if(readEmailUsersArr === null || !approverOrAssigneePresent || checklistItemEmailRead || (readEmailUsersArr && readEmailUsersArr.length === 0 && !approverOrAssigneePresent)) {
            return true;
        } else {
            return false;
        }
    }

    const checklistItemReadUnread = (checklistItem, checklistItemEmailRead, approverOrAssigneePresent, noteReadCheck) => {
        if(checkChecklistEmailRead(checklistItem, checklistItemEmailRead, approverOrAssigneePresent)) {
                if((checklistItem.notes_count === 0 || checklistItem.notes_count === "0") || (checklistItem.notes_count > 0 && noteReadCheck) || (checklistItem.notes_count > 0 && !noteReadCheck && !approverOrAssigneePresent)) {
                    return true;
                } else {
                    return false;
                }
        } else {
            return false;
        }
    
    }

    const updateChecklistNoteReadUsers = (currentchecklistItem) => {
        const newCheckListArr = checkList.map((checklistItem) => {
            if (checklistItem.id === currentchecklistItem.id) {
                checklistItem.note_read_users = [...checklistItem.note_read_users, logged_in_user_id]
                return checklistItem;
            }else{
                return checklistItem;
            }
        });
        setCheckList(newCheckListArr);
    }

    const updateFollowups = (checlistArr) => {
        const updatedCheckListArr = checkList.map((checklistItem) => {
            checlistArr.map((checklist) => {
                if(checklistItem.id === checklist.id) {
                    checklistItem = checklist;
                }
                return checklistItem;
            })
            return checklistItem;
        });
        setCheckList(updatedCheckListArr);
    }


    const handleOpenSendForReviewDialog = (checklist) => {
        setOpenSendForReviewDialog(true);
        setSelectedChecklistItem(checklist);
    }

    const handleCloseSendForReviewDialog = () => {
        setOpenSendForReviewDialog(false);
        setSelectedChecklistItem(undefined);
    }

    const onUndoCompleteChecklistClick = (checklist_id) => {
        const apiStr = `checklist/${checklist_id}`;

        undoCompleteApi(apiStr)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response completeChecklistApi: ", res);

                enqueueSnackbar(res.message, {variant:"success"});

                const newCheckListArr = checkList.map((checklistItem) => {
                    if (checklistItem.id === checklist_id) {
                        checklistItem = res.checklist;
                        return checklistItem;
                    }
                    return checklistItem;
                });
                setCheckList(newCheckListArr);
                

                let templatesArr = updateTemplateArr(props.templates, res.template, props.selectedTemplate);
                props.setOrgTemplates(templatesArr);
                
                trackGAEvent(props.selectedOrganization.organization.name, 'Checklist Complete', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
            })
            .catch((e) => {
                consoleToLog("completeChecklistApi error", e.response);
                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant:'error'
                    });
                    return false;
                }
            });
    }

    const updateChecklistStatusAndIsCompletable = (checklistObj) => {
        const newCheckListArr = checkList.map((checklistItem) => {
            if (checklistItem.id === checklistObj.id) {
                checklistItem.status = checklistObj.status;
                checklistItem.is_completable = checklistObj.is_completable
            }
            return checklistItem
        });
        setCheckList(newCheckListArr);
    }

    const showChecklistItems = (checklistArray) => {
        return (
            <div>
                <TransitionGroup mode={mode}>
                {(checklistArray && checklistArray.length > 0)  &&
                        checklistArray.map((checklistItem, i) => {
                        //const percentage = checklistItem.total_task > 0 ? Math.round((checklistItem.completed_task / checklistItem.total_task) * 100) : 0;
                        const approvers_list = checklistItem.checklist_approvers_list && checklistItem.checklist_approvers_list.length > 0 ? checklistItem.checklist_approvers_list : [];
                        const members_list = checklistItem.checklist_members_list && checklistItem.checklist_members_list.length > 0 ? checklistItem.checklist_members_list : [];
                        const checklist_id = checklistItem.id;
                        let checklistItemEmailRead = checklistItem.email_read_users && checklistItem.email_read_users.length > 0 && checklistItem.email_read_users.includes(logged_in_user_id);
                        let loggedInUserInChecklistApprovers = checkWheatherLoggedInUserIsApprover(approvers_list, logged_in_user_id)
                        let loggedInUserInChecklistAssignees = checkWheatherLoggedInUserIsAssignees(members_list, logged_in_user_id)
                        let approverOrAssigneePresent = (!loggedInUserInChecklistApprovers || !loggedInUserInChecklistAssignees) ? true : false
                        let readNoteUsersArr = checklistItem?.note_read_users;
                        let noteReadCheck = readNoteUsersArr && readNoteUsersArr.length > 0 ? readNoteUsersArr.includes(logged_in_user_id) : false;
                        return <CSSTransition
                                    key={checklistItem.id}
                                    timeout={1000}
                                    exit={animationActive}
                                    classNames="item">
                                <Grid container direction="column"
                                    className={classes.checklisthover}
                                    onMouseLeave={onClickAway}
                                    style={{background: checklistItemReadUnread(checklistItem, checklistItemEmailRead, approverOrAssigneePresent, noteReadCheck) ? '#f3f3f3' : '#fff'}}
                                    key={checklistItem.id}>
                                    <Grid container>
                                        <Grid item md={6} lg={6} style={{flexBasis: "52%", maxWidth: "52%"}}>{/**1st column checklist title subtitle */}
                                            <div style={{display:'flex'}}>
                                                {!accessLevelGuest &&
                                                <Grid item lg={1} md={1} ref={node => (listEl = node)}>
                                                    <Checkbox
                                                        onChange={(e) =>  handleClick(e, checklistItem)}
                                                        checked= {onSelectedChecklist(checklistItem) || false}
                                                        id={`item-${checklistItem.id}`}
                                                        value={checklistItem}
                                                        disabled={onSelectedChecklist(checklistItem) && props.addToCart}
                                                    />
                                                </Grid>}
                                                <Grid item md={11} lg={11} style={{marginLeft: accessLevelGuest ? '20px' :'-4px', marginTop:'-2px'}}>
                                                    <Grid item container alignItems='center'>
                                                        <Grid item>
                                                            <Typography
                                                                variant="body1"
                                                                style={{cursor:"pointer", fontWeight: checklistItemReadUnread(checklistItem, checklistItemEmailRead, approverOrAssigneePresent, noteReadCheck) ? '400' : '500'}}>
                                                                    <Tooltip title={checklistItem.title} arrow>
                                                                    <div
                                                                        className="checklistItem_link"
                                                                        style={{textDecoration:"none", marginLeft: '4px'}}
                                                                        onClick={(e)=>{
                                                                            trackGAEvent(props.selectedOrganization.organization.name, 'Checklist Opened', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
                                                                            handleDetailsDialogOpen(checklistItem.slug_code,checklistItem);
                                                                            getCurrentchecklistItem(checklistItem)
                                                                        }}>
                                                                        {checklistItem.title}
                                                                    </div>
                                                                    </Tooltip>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item style={{marginLeft:'8px'}}>
                                                            <Tooltip title={props.selectedTemplate?.name === 'All' ? checklistItem?.template_data?.template_name : props.selectedTemplate?.name} arrow>
                                                                <Typography
                                                                    variant="body1"
                                                                    className={classes.templateNameStyle}
                                                                    style={{}}
                                                                >
                                                                {checklistItem?.template_data?.template_name}
                                                                </Typography>
                                                            </Tooltip>
                                                        </Grid>
                                                        <Grid item style={{marginLeft:'8px'}}>
                                                            <Typography
                                                                variant="body1"
                                                                style={{color:'#888888'}}
                                                                dangerouslySetInnerHTML={{ __html: '&#8226' }}
                                                            >
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item style={{marginLeft:'8px'}}>
                                                            <Typography
                                                                variant="body1"
                                                                style={{lineHeight:'42px', color: '#888888'}}
                                                            >
                                                            {checklistItem.subtitle}
                                                            </Typography>
                                                        </Grid>
                                                        {checklistItem.followups_count > 0 &&
                                                        <Grid item>
                                                            <Typography
                                                                className={classes.emailFollowupTag}>
                                                                Followup
                                                            </Typography>
                                                        </Grid>}
                                                    </Grid>
                                                    <Grid item container style={{marginTop:'-6px'}}>
                                                        <Grid item>
                                                            <Tooltip title="Notes" arrow >
                                                                <Button aria-label="delete"
                                                                    className={classes.sidemenubtns}
                                                                    onClick={(e)=>{
                                                                        trackGAEvent(props.selectedOrganization.organization.name, 'Checklist Notes Icon', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
                                                                        getCurrentchecklistItem(checklistItem);
                                                                        handleTabOpen(checklistItem.slug_code, "Notes");}}
                                                                >
                                                                    <i className="flaticon-comment" style={{lineHeight:'5px'}}></i>
                                                                    <Typography variant="subtitle1" className={classes.icontext}>
                                                                    {checklistItem.notes_count ? checklistItem.notes_count : '0'}
                                                                    </Typography>
                                                                </Button>
                                                            </Tooltip>
                                                        </Grid>
                                                        <Grid item>
                                                            <Tooltip title="Attachments" arrow >
                                                                <Button aria-label="delete"
                                                                className={classes.sidemenubtns}
                                                                onClick={(e)=>{
                                                                    trackGAEvent(props.selectedOrganization.organization.name, 'Checklist Attachment Icon', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
                                                                    getCurrentchecklistItem(checklistItem);
                                                                    handleTabOpen(checklistItem.slug_code, "Attachments");}}
                                                                >
                                                                    <i className="flaticon-attachment-1" style={{lineHeight:'5px'}}></i>
                                                                    <Typography variant="subtitle1" className={classes.icontext}>
                                                                    {checklistItem.attachment_count ? checklistItem.attachment_count : '0'}
                                                                    </Typography>
                                                                </Button>
                                                            </Tooltip>
                                                        </Grid>
                                                        <Grid item>
                                                            <Tooltip title="Sent Mails" arrow >
                                                                <Button aria-label="delete"
                                                                className={classes.sidemenubtns}
                                                                onClick={(e)=>{
                                                                    trackGAEvent(props.selectedOrganization.organization.name, 'Checklist Attachment Icon', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
                                                                    getCurrentchecklistItem(checklistItem);
                                                                    handleTabOpen(checklistItem.slug_code, "Audit Trail");}}
                                                                >
                                                                    <img src={showEmailIcons('out_going')} className={classes.emailIcons}/>
                                                                    <Typography variant="subtitle1" className={classes.icontext}>
                                                                    {checklistItem.out_email_count ? checklistItem.out_email_count : '0'}
                                                                    </Typography>
                                                                </Button>
                                                            </Tooltip>
                                                        </Grid>
                                                        <Grid item>
                                                            <Tooltip title="Received Mails" arrow >
                                                                <Button aria-label="delete"
                                                                className={classes.sidemenubtns}
                                                                onClick={(e)=>{
                                                                    trackGAEvent(props.selectedOrganization.organization.name, 'Checklist Attachment Icon', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
                                                                    getCurrentchecklistItem(checklistItem);
                                                                    handleTabOpen(checklistItem.slug_code, "Audit Trail");}}
                                                                >
                                                                    <img src={showEmailIcons('in_coming')} className={classes.emailIcons}/>
                                                                    <Typography variant="subtitle1" className={classes.icontext}>
                                                                    {checklistItem.in_email_count ? checklistItem.in_email_count : '0'}
                                                                    </Typography>
                                                                </Button>
                                                            </Tooltip>
                                                        </Grid>
                                                        {checklistItem.due_date &&
                                                            <Grid item >
                                                            <Tooltip title="Due Date" arrow >
                                                                <Button aria-label="delete"
                                                                onClick={(e) =>handleClickNew(e, checklistItem)}
                                                                className={classes.sidemenubtns}
                                                                style={{color: checklistItem.billable_status !== 'paid' && isDueDateOverDue(checklistItem) &&'red'}}
                                                                >
                                                                    <i className="flaticon-calendar-1" style={{lineHeight:'5px'}}></i>
                                                                    <Typography variant="subtitle1"
                                                                    style={{color:checklistItem.billable_status !== 'paid' && isDueDateOverDue(checklistItem) &&'red'}}
                                                                    className={classes.icontext}>{`${moment(checklistItem.due_date).format('Do MMM')}`}</Typography>
                                                                </Button>
                                                            </Tooltip>
                                                            {!accessLevelManager && handleDueDatePopOver()}
                                                        </Grid> }
                                                        <Grid item>
                                                            {(checklistItem.checklist_labels_list && checklistItem.checklist_labels_list.length > 0) &&
                                                                <div style={{display:'flex'}}>
                                                                    <div className={classes.labels}>
                                                                        {checklistItem.checklist_labels_list.slice(0,2).map((label) => {
                                                                            return <div key={label.id} style={{display:'flex', alignItems:'flex-start'}} className='labellist'>
                                                                                        <Typography
                                                                                            onClick={() => onLabelsClick(label)}
                                                                                            style={{color: `${label.color}`,
                                                                                            paddingLeft:'3px',
                                                                                            cursor:'pointer',
                                                                                            paddingTop:'0',
                                                                                            fontSize:'12px'}}>{label.name}</Typography>
                                                                                        <FiberManualRecordIcon fontSize="small"
                                                                                            className="bullet"
                                                                                            style={{width:'6px', height:'6px', color:"#ccc", paddingLeft:'3px', paddingTop:'0', marginTop:'7px'}}/>
                                                                                    </div>
                                                                                })
                                                                        }
                                                                    </div>
                                                                    <span>
                                                                    {(checklistItem.checklist_labels_list && checklistItem.checklist_labels_list.length > 2) &&
                                                                        checklistLabelList(checklistItem) }
                                                                    </span>
                                                                </div>
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </div>

                                        </Grid> {/**1st column checklist title subtitle */}

                                        {/**Assignees column*/}
                                        <Grid item md={1} lg={1} className={classes.customColumn2}>
                                            <Typography
                                                variant="subtitle1"
                                                style={{lineHeight:'35px', color:'#202020', marginBottom:'-5px'}}
                                                className="onChecklist__hover"
                                            >
                                                Assignee
                                            </Typography>
                                            <SelectFromMemberList
                                                isFromChecklistPage={true}
                                                setAssignedMemberList={(selectedAssignees) => setAssignedMemberList(selectedAssignees, checklistItem.id)}
                                                assignees={getAssigneesFromChecklistItem(checklistItem)}
                                                disabledAssignChecklistMembers={accessLevelManager}/>
                                        </Grid>

                                        {/**Approver column*/}
                                        <Grid item md={1} lg={1} className={classes.customColumn2}>
                                            <Typography
                                                variant="subtitle1"
                                                style={{lineHeight:'35px', color:'#202020', marginBottom:'-5px'}}
                                                className="onChecklist__hover"
                                            >
                                                Approver
                                            </Typography>
                                            <SelectFromApproverList
                                                setAssignedApproverList={(selectedAssignees) => setAssignedAproverList(selectedAssignees, checklistItem.id)}
                                                assignees={getAssigneesOfApprover(checklistItem)}
                                                disabledAssignChecklistApprovers={accessLevelManager}
                                                approvalList={checklistItem?.checklist_approvals_list}
                                                />
                                        </Grid>

                                        {/**Progress column*/}
                                        <Grid item md={1} lg={1} style={{flexBasis: "10%", maxWidth: "10%", }}>
                                            {/* <Grid item container justifyContent="center">
                                                <Grid item className='hideOnHover'>
                                                    <Typography
                                                        variant="body1"
                                                        style={{lineHeight:'35px', color:'#202020',}}
                                                        className="onChecklist__hover"
                                                    >
                                                        {(checklistItem.status === 'Completed')? `Invoice` : `Progress`}
                                                    </Typography>
                                                </Grid>
                                            </Grid> */}
                                            <Grid item container justifyContent="center" className='hideOnHover'>
                                                {(checklistItem.status === 'Completed') ?
                                                    
                                                        <Grid item style={{display:'flex', alignItems:'center', marginTop:'24px'}}>
                                                            <div 
                                                                style={{width: '4px', 
                                                                            height: '20px', 
                                                                            marginRight: '4px',
                                                                            borderTopLeftRadius: '2px',
                                                                            borderBottomLeftRadius: '2px',
                                                                            background: statusColor(checklistItem.billable_status.toLowerCase())}}>
                                                                </div>
                                                                <Typography variant="subtitle1" 
                                                                    style={{color:statusColor(checklistItem.billable_status.toLowerCase()), fontSize: "12px"}}>
                                                                    {displayStatus(checklistItem.billable_status)}
                                                                </Typography>
                                                        </Grid>
                                                    
                                                :
                                                <Tooltip title={"Tasks: " + checklistItem.completed_task + "/" + checklistItem.total_task} arrow >
                                                    <Grid item style={{marginTop:'19px'}}>
                                                        <CircularProgressWithLabel
                                                            checklistItemEmailRead={checklistItemEmailRead}
                                                            style={{display:'flex', alignItems:"center"}}
                                                            value={checklistItem.total_task > 0 ? Math.round((checklistItem.completed_task / checklistItem.total_task) * 100) : 0}
                                                    />
                                                    </Grid>
                                                </Tooltip>
                                            }
                                            </Grid>
                                        </Grid>

                                        {/**MoreAction icons and status column*/}
                                        <Grid item md={2} lg={2} className={classes.customColumn1} style={{display:'flex', alignItems:'center', flexDirection:'column', justifyContent:'center'}}>
                                            <Grid item container direction="column">
                                                <Grid item style={{display:'flex', justifyContent:'flex-start', flexDirection:'row-reverse', marginTop:'3px'}}>
                                                    <div style={{position:"relative"}}>
                                                        <Tooltip title="More Actions" arrow >
                                                                <i className="flaticon-more action__icon" 
                                                                    onClick={(e) => onClick(e, checklistItem.id)}
                                                                    style={{position:"relative", left:'1px'}}>
                                                                </i>
                                                        </Tooltip>
                                                        {(checklistId === checklistItem.id) && isActive &&
                                                        <ClickAwayListener onClickAway={onClickAway}>
                                                        <Grid item className={`menu ${isActive ? "active" : "inactive"}`}>
                                                            <ul>
                                                                {checklistItem.followups_count > 0 && checklistItem.status !== 'Completed' && checklistItem.is_completable && 
                                                                    <li className={classes.list} onClick={(e) => {
                                                                        if (window.confirm("You cannot undo the completed checklist. Would you like to continue to complete the checklist?")) {
                                                                            onCompleteChecklistClick(checklistItem.id);
                                                                            }
                                                                    }}>
                                                                            <a>Complete Checklist</a>
                                                                    </li>
                                                                }

                                                                {(checklistItem.followups_count > 0 || checklistItem.is_completable) && 
                                                                <SelectFromLabelList setAssignedLabelList = {
                                                                    (selectedLabels) => setAssignedLabelList(selectedLabels, checklistItem.id)
                                                                }
                                                                    assignees={getLabelsFromChecklistItem(checklistItem)}
                                                                    disabledAssignChecklistLabels={accessLevelMember}
                                                                    fromActionsDropdown={true}
                                                                />}

                                                                {checklistItem.status !== 'Pending Approval' &&
                                                                <li className={classes.list} onClick={(e) => {
                                                                    handleOpenSendForReviewDialog(checklistItem);
                                                                }}>
                                                                    <a>Send For Review</a>
                                                                </li>}

                                                                {!accessLevelMember &&
                                                                    <li className={classes.list} 
                                                                    onClick={(e) =>{
                                                                        setAnchorEl1(e.currentTarget);
                                                                        setSelectedChecklistItem(checklistItem);
                                                                    }}>
                                                                        <a>Set Due Date</a>
                                                                    </li>}

                                                                {/* { approvers_list && approvers_list.length > 0 && (checkWheatherLoggedInUserIsApprover(approvers_list, logged_in_user_id)
                                                                    && sendChecklistToApprover(approvers_list)) && checklistItem.status !== 'Pending Approval' &&
                                                                    <li className={classes.list} onClick={(e) => {
                                                                        if (window.confirm("Do you want to send checklist to approver")) {
                                                                            onSendForReviewClick(checklistItem.id)
                                                                            }
                                                                        }}>
                                                                        <a>Send For Review</a>
                                                                    </li>
                                                                } */}

                                                                {!accessLevelManager &&
                                                                    <li className={classes.list} onClick={(e) => {
                                                                        if (window.confirm("Are you sure, You want to archive this checklist? Once archived you won't be able see this checklist!")) {
                                                                            onArchiveChecklistClick(checklistItem.id);
                                                                            }
                                                                    }}>
                                                                        <a>Archive Checklist</a>
                                                                    </li>
                                                                }

                                                                {!accessLevelGuest &&
                                                                    <li className={classes.list}
                                                                    onClick={(e) => {
                                                                        if(props.overlay && props.overlay.showOverlayView === OverlayView.SHOW_OVERLAY_LOADING){
                                                                            enqueueSnackbar("Another export request is already in progress. Please be patient.", ({
                                                                                variant: 'error'
                                                                            }));
                                                                        }
                                                                        else exportChecklistTaskClick(checklistItem.id);
                                                                    }}>
                                                                        <a>Export Checklist Task</a>
                                                                    </li>
                                                                }

                                                                {((checklistGroup && checklistGroup.length > 0) || slug) &&
                                                                    <li className={classes.moveToFolder} style={{position:'relative'}}>
                                                                        <a> Move To Folder
                                                                            <i className="flaticon-right-arrow"
                                                                                style={{width: '1.5em',
                                                                                height: '1.5em',
                                                                                fontSize: '12px',
                                                                                position:'relative',
                                                                                top:0,
                                                                                left:'28%',
                                                                                flexShrink: 0,
                                                                                userSelect: 'none',}}></i>
                                                                        </a>
                                                                        <div className="displayList mr-1">
                                                                        {slug &&
                                                                                <Typography variant="body2"  className={classes.folderList}
                                                                                onClick={(e) => onFolderNameClick(e,checklistItem.id, 0)}
                                                                                >Move To Root
                                                                                </Typography>
                                                                        }
                                                                    {(checklistGroup && checklistGroup.length > 0) ? checklistGroup.map((folder) => {
                                                                    return <div key={folder.id}
                                                                                onClick={(e) => {
                                                                                    onFolderNameClick(e,checklistItem.id, folder.id);
                                                                                }}>
                                                                                <Tooltip title={folder.group_name} arrow>
                                                                                    <Typography variant="body2" className={classes.folderList}
                                                                                    style={{ whiteSpace: "nowrap", maxWidth: "auto", overflow: "hidden", textOverflow: "ellipsis" }}
                                                                                    >
                                                                                        <i className={`flaticon-folder-1 ${classes.displayFolderIcon}` } style={{marginTop:'5px'}}></i>
                                                                                            <span style={{marginTop:'-5px'}}>{folder.group_name}</span>
                                                                                    </Typography>
                                                                                </Tooltip>
                                                                            </div>

                                                                    }):
                                                                    <div></div>
                                                                    }
                                                                    </div>
                                                                    </li>
                                                                }

                                                            </ul>
                                                        </Grid>
                                                        </ClickAwayListener>
                                                        }
                                                        </div>
                                                        {checklistId === checklistItem?.id &&
                                                            <Popover
                                                                id={id1}
                                                                open={open1}
                                                                anchorEl={anchorEl1 && checklistId === checklistItem?.id}
                                                                onClose={handleClose1}
                                                                anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal:'right'
                                                                }}
                                                                transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'right',
                                                                }}
                                                            >
                                                                <Calender clickedDate={clickedDate} />
                                                            </Popover>
                                                            }
                                                        <div style={{display:"flex", justifyContent:'center', position:'absolute', right:'50px'}}>
                                                            {/* <SelectFromLabelList setAssignedLabelList = {
                                                                    (selectedLabels) => setAssignedLabelList(selectedLabels, checklistItem.id)
                                                                }
                                                                    assignees={getLabelsFromChecklistItem(checklistItem)}
                                                                    disabledAssignChecklistLabels={accessLevelMember}
                                                                /> */}
                                                            
                                                            <Button item className="more_actionButton1" 
                                                                onClick={() => onSendEmailClick(checklistItem)}> 
                                                                    <EmailOutlinedIcon 
                                                                    className={classes.moreActionsIcons}
                                                                    style={{fontSize:'15px', verticalAlign:'baseline', color:'#0071ff'}}   
                                                                    />
                                                                <span> Send Email </span>
                                                            
                                                            </Button> 
                                                            {checklistItem.followups_count > 0
                                                            ?
                                                            <Button item className="more_actionButton2"
                                                                onClick={() => getEmailFollowups(checklistItem, false)}
                                                            > 
                                                                    <HighlightOffOutlinedIcon 
                                                                    className={classes.moreActionsIcons}
                                                                    style={{fontSize:'15px', verticalAlign:'baseline', color:'#dc143c'}}   
                                                                    />
                                                                <span> Stop Followup </span>
                                                                
                                                                {showEmailFollowupsPopover(checklistItem)}
                                                            </Button>
                                                            :
                                                            checklistItem.is_completable && checklistItem.status !== "Completed" &&
                                                            <Button item className="more_actionButton" onClick={(e) => {
                                                                if (window.confirm("You cannot undo the completed checklist. Would you like to continue to complete the checklist?")) {
                                                                    onCompleteChecklistClick(checklistItem.id);
                                                                    }
                                                                }}> 
                                                                <CheckIcon 
                                                                    className={classes.moreActionsIcons}
                                                                    style={{fontSize:'15px', verticalAlign:'baseline', color:'#228B22'}}   
                                                                />
                                                                <span> Complete </span>
                                                    
                                                            </Button>
                                                            }
                                                            {
                                                                checklistItem.followups_count === 0 && !checklistItem.is_completable &&
                                                                <SelectFromLabelList setAssignedLabelList = {
                                                                    (selectedLabels) => setAssignedLabelList(selectedLabels, checklistItem.id)
                                                                }
                                                                    assignees={getLabelsFromChecklistItem(checklistItem)}
                                                                    disabledAssignChecklistLabels={accessLevelMember}
                                                                />
                                                            }
                                                            {
                                                                checklistItem.followups_count === 0 && checklistItem.status === 'Completed' &&
                                                                <Button item className="more_actionButton2" onClick={(e) => {
                                                                    if (window.confirm("Are you sure you want to undo the completed checklist?")) {
                                                                        onUndoCompleteChecklistClick(checklistItem.id);
                                                                        }
                                                                    }}> 
                                                                    <UndoIcon 
                                                                        className={classes.moreActionsIcons}
                                                                        style={{fontSize:'15px', verticalAlign:'baseline', color:'grey'}}   
                                                                    />
                                                                    <span> Undo Complete </span>
                                                        
                                                                </Button>
                                                            }
                                                        </div>
                                                    </Grid>
                                                    <Grid item container justifyContent="center">{/**Status */}
                                                        <Tooltip title={checklistItem.status} arrow>
                                                            <Typography variant="subtitle1" className='hideOnHover statusStyle' style={{
                                                                    color: `${getStatusColor(checklistItem.status)}`,
                                                                    border: `1px solid ${getStatusColor(checklistItem.status)}`,
                                                                }}>{checklistItem.status}
                                                            </Typography>
                                                        </Tooltip>
                                                    </Grid> 
                                                </Grid>
                                            </Grid>
                                    </Grid>
                                    <Divider light style={{marginLeft: accessLevelGuest ? '24px' :'48px',
                                                        marginRight: accessLevelGuest ? '24px' :'10px',
                                                        marginTop: '5px'}}/>
                            </Grid>
                            </CSSTransition>
                                    })
                            }
                            </TransitionGroup>
                            {(checklistArray && checklistArray.length === 0) &&
                                <TemplatesNotFound src="/images/no_checklist.svg"
                                    message="No Checklist Found"
                                    fromChecklist={true}
                                    toggleDrawer={props.toggleDrawer}
                                    setCreateChecklist={props.setCreateChecklist}
                                    />
                            }
            </div>
        )
    }

    const restrutureChrecklistArr = (checklistArray) => {
        const groupedChecklist = checklistArray.reduce((acc, item) => {
            const dueDate = moment(item.due_date, 'YYYY-MM-DD');
            const today = moment().startOf('day');
            const difference = dueDate.diff(today, 'days');
        
            let label;
            if (difference === 0) {
                label = 'Today';
            } else if (difference === 1) {
                label = 'Tomorrow';
            } else if (difference === 2 || difference === 3 || difference === 4 || difference === 5) {
                label = dueDate.format('DD MMM YYYY');
            }
        
            if (label) {
                const existingGroup = acc.find(group => group.label === label);
                if (existingGroup) {
                    existingGroup.items.push(item);
                } else {
                    acc.push({ label, items: [item] });
                }
            }

            return acc;
        }, []);
        return groupedChecklist;
    }

    const showUpcomingChecklistItems = () => {
        let checklistLocalArray =  restrutureChrecklistArr(checkList);
        return (
            <div>
                {
                    checklistLocalArray && checklistLocalArray.length > 0
                    && checklistLocalArray.map((checklistItem) => {
                        return <div key={checklistItem.label} style={{marginBottom:'16px', background:'#f7f7f7'}}> 
                                    <Typography variant='body1' style={{fontWeight:500}}>
                                        {checklistItem.label}
                                    </Typography>
                                        {showChecklistItems(checklistItem.items)}
                                </div>
                    })
                }
                {(checklistLocalArray && checklistLocalArray.length === 0) &&
                    <TemplatesNotFound src="/images/no_checklist.svg"
                        message="No Checklist Found"
                        fromChecklist={true}
                        toggleDrawer={props.toggleDrawer}
                        setCreateChecklist={props.setCreateChecklist}
                        />
                }
            </div>
        )
    }

    const onFolderClick = (slug_code) => {
        consoleToLog('Folder Slug Code: ', slug_code);
        history.push(`/group/${slug_code}`);

    }

    const loadMoreLogic = () => {
        return (
            <Grid item container>
                <Grid item lg={12}>
                {nextPage && !isMoreLoading &&
                    <div style={{textAlign: "center", margin:'16px 0px'}}>
                        <Button type="button"
                            color="secondary"
                            variant="contained"
                            onClick={() => {
                                setIsMoreLoading(!isMoreLoading);
                            }}>Load More
                        </Button>
                    </div>}

                { isMoreLoading &&
                    <CircularProgress size={35} style={{position:'relative', left:'50%', right:'50%', margin:'16px 0px'}}/>
                }
                </Grid>
            </Grid>
        )
    }

    const addFolderToList = (newFolder) => {
        props.setDrawerOpen();
       //consoleToLog('1234566', newFolder);
     //   var new_folder = checklistGroup.concat(newFolder);
        setChecklistGroup(oldFolders => [newFolder, ...oldFolders]);
    }

    const onDeleteFolder = (group_id) => {
        let new_folder_array = checklistGroup.filter((folder) => folder.id !== group_id);
        setChecklistGroup(new_folder_array);
        props.setDrawerOpen();
    }

    const editedFolder = (editedFolder) => {
        props.onEditFolderClick(undefined);
        const new_folder_array = checklistGroup.map((folder) => {

            if (folder.id === editedFolder.id) {
                consoleToLog(folder.id, editedFolder.id)
                return {
                    ...folder,//old
                    ...editedFolder//new
                }
            } else {
                return folder;
            }
        })

        consoleToLog(new_folder_array);
        setChecklistGroup(new_folder_array)
    }

    const onChecklistCreation = (newChecklistArr) => {
        consoleToLog('1234', newChecklistArr);
        const newItems = newChecklistArr.concat(checkList);
        props.fetchChecklistFromChild(newItems);
        setCheckList(newItems);
    }

    // checklistItem title onClick
    const [openDialog, setOpenDialog] = useState(false);
    const [currentchecklistItem,setCurrentchecklistItem]=useState(undefined);

    const handleDetailsDialogOpen = (current_slug,checklistItem) => {
        setTab(0);
        setOpenDialog(true);
        props.setChecklistDetailsOpen(true);
        history.push(`/checklist/${current_slug}`);
        getCurrentchecklistItem(checklistItem);
    }

    const handleDetailsDialogClose = () => {
        props.setChecklistDetailsOpen(false);
        if (checklistGroup.length > 0 && (checklistGroup[0] && checklistGroup[0].slug_code !== undefined)){
            history.goBack();
        }else{
            history.push("/dashboard");
        }

        if(location.activityObj) {
            props.setSelectedTemplate('');
            props.setSelectedDrawerState(SelectedDrawerState.SHOW_ACTIVITY_REPORT);
            history.push('/dashboard',
                {activityArr: location.activityList,
                isMoreLoading: location.isMoreLoading,
                nextPage: location.nextPage,
                currPage: location.page,
                scrollTop: location.scrollTop
            })
        }

    }

    // var urlPath = window.location.pathname.split("/");
    // var curr_slug = urlPath[urlPath.length-1];

    // get current checklistitem to pass in CheckListDetailsPage
    const getCurrentchecklistItem = (checklistItem) => {
        setCurrentchecklistItem(checklistItem);
      };

     // onCheckListStatusCompleted from details
     const onCheckListStatusCompleted = (checklistStatus,toggle ) => {
          const newCheckListArr1 = checkList.map((checklistItem) => {
            if (checklistItem.id === currentchecklistItem.id) {
                checklistItem.status = checklistStatus;
                return checklistItem;
            }
            else return checklistItem;
        }).filter((checklistItem) => checklistItem.status !== 'Completed');
        setCheckList(newCheckListArr1);
    }

    // onTaskStatusChanged from details
    const onTaskStatusChanged = (selectedChecklistItemId, updatedChecklist) => {
        const newCheckListStatus = checkList.map((checklistItem) => {
            if (checklistItem.id === selectedChecklistItemId) {
                return updatedChecklist;
            }
            else return checklistItem;
        })
        // consoleToLog("newCheckListStatus in Checklist Component ",newCheckListStatus)
        setCheckList(newCheckListStatus);
    }

     // onLabelModified from details
    const onLabelModified = (updatedChecklist, selectedChecklistItemId) => {
        const newCheckListArr = checkList.map((checklistItem) => {
            if (checklistItem.id === selectedChecklistItemId) {
                //  checklistItem.checklist_labels_list = selectedLabels;
                return updatedChecklist;
            }
            else return checklistItem;
        });
        setCheckList(newCheckListArr);
    }

      // onDueDateModified from details
    const onDueDateModified = (newChecklistArray) => {
        const newCheckListArr = checkList.map((checklistItem) => {
            if (checklistItem.id === newChecklistArray.id) {
                checklistItem.due_date = newChecklistArray.due_date;
                return checklistItem;
            }
            else return checklistItem;
        });
        setCheckList(newCheckListArr);
    }

    // onChecklistArchived from details
    const onChecklistArchived = (checklist_id) => {
        const updatedChecklist = checkList.filter((checklistItem) => checklistItem.id !== checklist_id);
        setCheckList(updatedChecklist);
        setTotal(total - 1);
    }

    const onAttachmentDelete = (updatedDeleteAttachmentArray) =>{
        const newCheckListArr = checkList.map((checklistItem) => {
            if (checklistItem.id === currentchecklistItem.id) {
                checklistItem.attachment_count = updatedDeleteAttachmentArray.length;
                return checklistItem;
            }else{
                return checklistItem;
            }
        });
        setCheckList(newCheckListArr);
    }

    const onAttachmentAdded = (checklistObj) => {
        const newCheckListArr = checkList.map((checklistItem) => {
            if (checklistItem.id === checklistObj.id) {
                checklistItem = checklistObj;
                return checklistItem;
            }else{
                return checklistItem;
            }
        });
        setCheckList(newCheckListArr);
    }

    const onWidgetAttachmentAdded = (newArray) => {
        // consoleToLog("newArray is checklist component",newArray);
        const newCheckListArr = checkList.map((checklistItem) => {
            if (checklistItem.id === currentchecklistItem.id) {
                checklistItem.attachments = newArray;
                return checklistItem;
            }else{
                return checklistItem;
            }
        });
        setCheckList(newCheckListArr);
    }

    const onNoteAdded = (newArray,checklistId) =>{
        const newNotesArray = checkList.map((checklistItem) => {
            if (checklistItem.id === checklistId) {
                checklistItem.notes_count = newArray.length;
                return checklistItem;
            }else{
                return checklistItem;
            }
        });
        // consoleToLog("onNoteDeleted",newNotesArray);
        setCheckList(newNotesArray);
    }

    const onNoteDeleted = (newArray,checklistId) =>{
        const newNotesArray = checkList.map((checklistItem) => {
            if (checklistItem.id === checklistId) {
                checklistItem.notes_count = newArray.length;
                return checklistItem;
            }else{
                return checklistItem;
            }
        });
        setCheckList(newNotesArray);
    }

    const modalStatus = ()=> {
        if(window.location.toString().includes("checklist")){
            setOpenDialog(true)
        }
        else{
          setOpenDialog(false)
        }
      }

    const handleTabOpen = (current_slug, tabName) => {
          if (tabName === "Attachments") {
            setTab(1);
          }
          if (tabName === "Notes") {
            setTab(3);
          }

          if (tabName === "Audit Trail") {
            setTab(4);
          }

        // consoleToLog("******* tabName",tabName);
        setOpenDialog(true);
        history.push(`/checklist/${current_slug}`);
    };

    const handleImportTemplateModalOpen = () => {
        setOpenImportTemplateModal(true);
    }

    const handleImportTemplateModalClose = () => {
        setOpenImportTemplateModal(false);
    }

    const TemplatesNotFound = (props) => {
        const classes = useStyles();
        return (
            <div>
                <Grid container direction="row" alignItems="center" justifyContent="center">
                    <Grid item container direction="column"
                        alignItems="center"
                        justifyContent="center"
                        sm={6}>
                            <img alt="No Attachments Found" src={props.src}
                                className={classes.noActivityImage}/>
                    </Grid>
                    <Grid item container direction="column"
                        alignItems="center"
                        justifyContent="center">
                        <Typography className={classes.noActivityText}>
                            {props.message}
                        </Typography>
                    </Grid>
                    <Grid item container  alignItems="center" spacing={2}
                        justifyContent="center">
                            {props.fromChecklist ?
                                !accessLevelManager &&
                                <Grid item>
                                    <Button variant="contained" color="primary" size="large"
                                        onClick={() =>  {
                                            props.toggleDrawer();
                                            props.setCreateChecklist(true);
                                        }}
                                        className={classes.noActivityButton}>
                                            <AddCircleOutlineIcon fontSize='small' className={classes.buttonPlusIcon}/>
                                            Create Checklist
                                    </Button>
                                </Grid>
                            :
                            <>
                                <Grid item>
                                    <Button variant="contained" color="primary" size="large"
                                        onClick={() =>  history.push('/template/create')}
                                        className={classes.noActivityButton}>
                                            <AddCircleOutlineIcon fontSize='small' className={classes.buttonPlusIcon}/>
                                            Create Template
                                    </Button>
                                </Grid>

                                <Grid item>
                                    <Button variant="contained" color="primary" size="large"
                                        onClick={handleImportTemplateModalOpen}
                                        className={classes.noActivityButton}>
                                            <AddCircleOutlineIcon fontSize='small' className={classes.buttonPlusIcon}/>
                                            Import Template
                                    </Button>
                                </Grid>
                            </>
                            }
                    </Grid>
                </Grid>
            </div>
        );
    };

    const updateChecklistFromReview = (checklistObj) => {
        const updateChecklistArray = checkList.map((checklistItem) => {
                if(checklistItem.id === checklistObj.id) {
                    checklistItem.checklist_approvals_list = checklistObj.checklist_approvals_list;
                    checklistItem.checklist_approvers_list = checklistObj.checklist_approvers_list;
                    checklistItem.status = checklistObj.status;
                    checklistItem.is_completable = checklistObj.is_completable;
                }
                return checklistItem;
        });
        setCheckList(updateChecklistArray);

    }

    return (
            <div>
                {!loading && ((checklistGroup && checklistGroup.length > 0) || (slug && slug.length > 0)) &&
                <ChecklistFoldersComponent checklistGroup={checklistGroup}
                    onFolderClick={onFolderClick}
                    fetchChecklist={fetchChecklist}
                    slug={slug}
                    onEditFolderClick={props.onEditFolderClick}
                    onDeleteFolder={onDeleteFolder}
                    folderObj={folderObj}
                />
                }
                <div className={props.templates && props.templates.length > 0 && checkList.length > 0 && classes.mainbg}>
                { loading ?
                    <CircularProgress size={35} className={classes.loading} />
                    :
                        props.templates && props.templates.length > 0 ?
                        <div>
                            {upcomingChecklist ? showUpcomingChecklistItems()  : showChecklistItems(checkList)}
                            {loadMoreLogic()}
                        </div>
                        :
                        <TemplatesNotFound src="/images/no_template.svg"
                            message="No Templates Found"
                            />
                }
            </div>
            <RightToLeftSideDrawer openDrawer={props.openDrawer}
                closeDrawer={props.setDrawerOpen}
                title={rightToLeftSideDrawerTitle()}
                createChecklist={props.createChecklist}
                >
                    {(props.createFolder || props.editFolder) &&
                        <CreateEditFolderModal addFolderToList={addFolderToList}
                            setDrawerOpen={props.setDrawerOpen}
                            editFolder={props.editFolder}
                            editedFolder = {editedFolder}
                        />}

                    {props.createChecklist &&
                        <CreateChecklistModal setDrawerOpen={props.setDrawerOpen}
                            checklistGroup={checklistGroup}
                            onChecklistCreation={onChecklistCreation}
                            fetchChecklist={pageReset}/>}

                    {props.openFilters &&
                        <ChecklistFiltersModal setDrawerOpen={props.setDrawerOpen}/>}

            </RightToLeftSideDrawer>

            {confetti.run &&
                    <Confetti
                        width={window.innerWidth}
                        height={window.innerHeight}
                        run={confetti.run}
                        recycle={confetti.recycle}
                        gravity={0.3}
                        numberOfPieces={400}
                    />
            }
        {/*Checklist Item Details Dialog*/}
            {openDialog  && <CheckListDetailPage
                currentchecklistItem = {currentchecklistItem}
                isDueDateOverDue={isDueDateOverDue}
                slug = {curr_slug}
                openDialog = {openDialog}
                setOpenDialog = {setOpenDialog}
                handleDetailsDialogClose = {handleDetailsDialogClose}
                handleDetailsDialogOpen = {handleDetailsDialogOpen}
                onCheckListStatusCompleted = {onCheckListStatusCompleted}
                onTaskStatusChanged = {onTaskStatusChanged}
                onLabelModified = {onLabelModified}
                modalStatus = {modalStatus}
                onDueDateModified = {onDueDateModified}
                onChecklistArchived = {onChecklistArchived}
                onAttachmentDelete = {onAttachmentDelete}
                onAttachmentAdded = {onAttachmentAdded}
                onWidgetAttachmentAdded = {onWidgetAttachmentAdded}
                onNoteAdded = {onNoteAdded}
                onNoteDeleted = {onNoteDeleted}
                tabValue={tab}
                handleSendEmailDialogOpen={props.handleSendEmailDialogOpen}
                checklistGroup={checklistGroup}
                onChecklistCreation={onChecklistCreation}
                fetchChecklist={pageReset}
                updateChecklistNoteReadUsers={updateChecklistNoteReadUsers}
                updateFollowups={updateFollowups}
                fullName={props.fullName}
                updateChecklistFromReview={updateChecklistFromReview}
                updateChecklistStatusAndIsCompletable={updateChecklistStatusAndIsCompletable}
                />
            }

            <ImportTemplateModal openDrawer={openImportTemplateModal} 
                handleDrawerClose={handleImportTemplateModalClose}/>

            {openSendForReviewDialog && 
                <SendForReviewDialog openSendForReviewDialog={openSendForReviewDialog}
                    handleCloseSendForReviewDialog={handleCloseSendForReviewDialog}
                    selectedChecklistItem={selectedChecklistItem}
                    fromChecklistComponent={true}
                    updateChecklistFromReview={updateChecklistFromReview}
                />
            }
        </div>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedTemplate: state.organizationInfo.selectedTemplate,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    isLoading: state.loading.isLoading,
    startDateFilter: state.filters.startDateFilter,
    endDateFilter: state.filters.endDateFilter,
    selectedStatusFilter: state.filters.selectedStatusFilter,
    selectedMembersFilter: state.filters.selectedMembersFilter,
    selectedLabelsFilter: state.filters.selectedLabelsFilter,
    companiesFilter: state.filters.companiesFilter,

    searchChecklistFilter: state.filters.searchChecklistFilter,
    sortByFilter: state.filters.sortByFilter,
    overlay: state.overlay,
    templates: state.organizationInfo.templates,
    showUnassignedChecklist: state.filters.showUnassignedChecklist,
    selectedBillStatusFilter: state.filters.selectedBillStatusFilter,
    checklistArray: state.organizationInfo.checklistArray,
    overlay: state.overlay,
    currentEmailState: state.organizationInfo.currentEmailState,
    setDrawerState: state.organizationInfo.setDrawerState
});

const mapDispatchToProps = (dispatch) => ({
    setLoading: (isLoading) => dispatch(setLoading(isLoading)),
    addSelectedLabelToFilter: (item) => dispatch(addSelectedLabelToFilter(item)),
    setOverlayView: (exportView) => dispatch(setOverlayView(exportView)),
    setOverlayToken: (randomToken) => dispatch(setOverlayToken(randomToken)),
    setOrgTemplates: (templates) => dispatch(setOrgTemplates(templates)),
    setSelectedTemplate: (template) => dispatch(setSelectedTemplate(template)),
    setSelectedDrawerState: (drawerState) => dispatch(setSelectedDrawerState(drawerState)),
    setBulkObj: (bgObj) => dispatch(setBulkObj(bgObj)),
    setIsBulkMoveToFolder: (isBulkMoveToFolder) => dispatch(setIsBulkMoveToFolder(isBulkMoveToFolder)),
    setIsBulkCompleteChecklist: (isBulkCompleteChecklist) => dispatch(setIsBulkCompleteChecklist(isBulkCompleteChecklist)),
    setOvelayMessage: (overlayMessage) => dispatch(setOvelayMessage(overlayMessage)),
    fetchTemplateList: (organization_id, start_date, end_date, companiesFilter, selectedMembersFilter, selectedTemplate) => 
        dispatch(fetchTemplateList(organization_id, start_date, end_date, companiesFilter, selectedMembersFilter, selectedTemplate)),
    setCurrentEmailState: (emailState) => dispatch(setCurrentEmailState(emailState)),
    setChecklistArray: (checklistArray) => dispatch(setChecklistArray(checklistArray)),
    setEmailSentFrom: (str) => dispatch(setEmailSentFrom(str))
})

export default connect(mapStateToProps, mapDispatchToProps)(ChecklistComponent)