import React, {useState, useEffect} from 'react';
import { Accordion, 
        AccordionSummary,
        AccordionDetails , 
        Typography, 
        Popover,
        Tooltip, 
        List, ListItem, Button, MenuItem, Link, Divider } from "@material-ui/core";

import { makeStyles } from '@material-ui/core/styles'; 
import {connect} from 'react-redux';  
import {setSelectedTemplate, setSelectedDrawerState, SelectedDrawerState, setOrgTemplates, fetchTemplateList} from '../actions/selectedOrganization';
import ImportTemplateModal from './modals/ImportTemplateModal';
import {shouldDisable, AccessLevel, trackGAEvent, consoleToLog, getStatusColor, getStatusNameByValue} from '../util/AppUtil';
import {history} from '../routers/AppRouter';
import MoreVertOutlinedIcon from '@material-ui/icons/MoreVertOutlined';
import {templatePublishUnpublishApi, archiveTemplateApi} from '../services/authService';
import {useSnackbar} from 'notistack';
import { useParams } from 'react-router-dom';
import { useIsMount } from './useIsMount';

const useStyles = makeStyles((theme) => ({
    leftmenu : {
        width: (showDrawer) => { 
           return showDrawer ? "19%" : "0%";
        },     
        height: 'calc(100vh - 48px)',
        flexGrow: 1,
        backgroundColor: "white", 
        transition: ".5s", 
        overflow: "auto", 
        scrollbarWidth: 'none',
        "&::-webkit-scrollbar": {
            display: "none"
        }   
    },
    summary : {
        flexDirection: "row-reverse", 
        display: "inline-flex", 
        padding: 0,  
        minHeight: "0",
    },
    detail : {
        padding: '0px 5px 0px 28px',
        display: "block"
    },
    text : {
        color:'#202020',
        fontWeight: 600,
    },
    MuiAccordionSummaryroot : {
        '&.Mui-expanded' : {
            marginBottom: '-3px'
        }
    },
    MuiAccordionroot: {
        "&.MuiAccordion-root:before": {
          backgroundColor: "white"
        }
    },
    icons : {
        marginRight:'8px',
        marginLeft: '2px', 
        fontSize: '16px',
        lineHeight: 0
    },
    templatehover :{
        '& .ellipsisIcon' : {
            display: 'none',
        },
        '&:hover .ellipsisIcon' : {
                display: 'block',
                width:'28px',
                height: '28px',
                padding:0
            }
    },
    listStyle: {
       marginLeft:'auto !important',
       color: '#ccc',
       padding:'0 4px 0px 8px',
    },
    MuiAccordionSummaryexpandIcon : {
        transform: 'rotate(-90deg)',
        '&.Mui-expanded' : {
        transform: 'rotate(0deg)'
        }
    },
    rightdownicon : {
        marginRight: '7px',
        marginTop:'8px',
        width: '1.5em',
        height: '1.5em',
        fontSize: '1rem',
        transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        flexShrink: 0,
        userSelect: 'none',
    },
    labels : {
        padding:'10px 18px',
        marginTop: '5px',
        marginBottom: '10px'
    },
    listitem : {
        fontSize: '15px',
        fontWeight: 400,
        color:'#202020',
        '&:first-child' : {
            marginTop:'-8px'
        },
        '&:selected' : {
            background:'transparent'
        }    
    },
    popover: {
        pointerEvents: 'none',
        marginLeft: "-100px"
    },
    paper: {
        padding: theme.spacing(1),
        boxShadow: "none",
        border: "1px solid rgba(0, 0, 0, 0.12)",
    },
    listmainstyle1 : {
        width: 'fit-content',
        height: 'auto',
        overflow: 'hidden',
        listStyle: 'none',
        padding:'0',
        margin: "0"
    },
    liststyle1: {
        display: 'block',
        color:'#202020',
        textAlign: 'left',
        fontSize: "14px",
        width:'180px',
        height: 'auto',
        padding: '8px 10px',
        },
    statuslist : {
        float:'right', 
        color:'#888888',
        marginTop:'-20px'
    },
    templatePopover: {
        marginTop:'7px',
        marginLeft: "-35px",
    },
    templateActionIcon: {
        padding:'0 8px 0px 4px', 
        marginRight:'4px',
        fontSize:'19px',
        color: '#ccc',
        position:"relative",
        top:'2px'
    },
    countAndEllipsisIcon: {
        display: 'flex', 
        marginLeft: 'auto', 
        alignItems: 'center !important', 
        justifyContent:'space-around !important'
    },
    templateList : {
        //paddingRight: '0px',
        padding:'0px 0px 0px 16px',
        height:'40px',
        '& .tempActionIcon' : {
            visibility: 'hidden'
        },
        '&:hover .tempActionIcon' : {
            visibility: 'visible'
        }
    },
    templateName : {
        width: '145px',
        overflow: 'hidden',
        whiteSpace: 'noWrap',
        textOverflow: 'ellipsis',
        fontSize:'15px',
    },
    activityLabelStyle : {
        padding:'10px 18px',
        marginTop: '5px',
    },
    invoicesLinkStyle: {
        display:'flex !important', 
        alignItems:'center !important',
        width:'100% !important', 
        textDecoration:'none !important'
    },
    externalImg: {
        marginLeft:'8px !important',
        filter: "invert(28%) sepia(0%) saturate(18%) hue-rotate(259deg) brightness(96%) contrast(83%)",
    }
}));

const LeftMenuComponent = (props) => {
    const {showDrawer} = props;
    const classes = useStyles(showDrawer);
    const [anchorEl, setAnchorEl] = useState(null);
    const [templateId, setTemplateId] = useState('');
    const [anchorEl2, setAnchorEl2] = useState(null);
    const [openDrawer, setDrawerOpen] = useState(false);
    const [anchorEl3, setAnchorEl3] = useState(null);
    const [tempId, setTempId] = useState(undefined);
    const {enqueueSnackbar} = useSnackbar();

    const { access_level } = props.selectedOrganization || {};
    const accessLevelManager = shouldDisable(AccessLevel.getUserAccessLevelValue(access_level), AccessLevel.MANAGER);
    const accessLevelGuest = (AccessLevel.getUserAccessLevelValue(access_level) === AccessLevel.GUEST) ? true : false;
    const onboarding = props.selectedOrganization.organization?.onboarding;

    const {slug} = useParams();
    const isMount = useIsMount();
    
    const handlePopoverOpen = (event, template) => {
        setTemplateId(template.template_id)
        /* let template_status = template.data;
        template_status.length > 0 && */
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
        setTemplateId('')
    };

    const handleTemplatePopoverOpen = (event) => {
        setAnchorEl2(event.currentTarget);
    } 

    const handleTemplatePopoverClose = () => {
        setAnchorEl2(null);
    }

    const openTemplateActionPopover = (event, template) => {
        setTemplateId(template.template_id)
        setAnchorEl3(event.currentTarget);
    } 

    const closeTemplateActionPopover = () => {
        setAnchorEl3(null);
        setTemplateId('')
    }

    const handleDrawerOpen = () => {
        setAnchorEl2(null);
        setDrawerOpen(true);
    }

    const handleDrawerClose = () => {
        setDrawerOpen(false);  
    }

    useEffect(() => {
        if(!isMount) {
            if(slug && props.setSelectedTemplate.template_id !== tempId) {
                history.push('/dashboard')
            }
        }
    }, [tempId]);

    const showTemplateLabelStatus = (template) => {
        let overDueData=[], allStatusDataExceptOverdue=[];
        if(template.status_data && template.status_data.length>0){
            overDueData =  template.status_data.filter(x => x.status === "over_due");
            allStatusDataExceptOverdue =  template.status_data.filter(x => x.status !== "over_due");
        }
        return <Popover
                    id="mouse-over-popover"
                    className={classes.popover}
                    classes={{
                        paper: classes.paper,
                    }}
                    open={Boolean(anchorEl) && (templateId === template.template_id)}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                    }}
                    transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                    }}
                    onClose={handlePopoverClose}
                    >
                    {labelStatus(template)}
                    {template.data && template.data.length > 0 && <Divider style={{margin: "8px 0px"}}/>}
                    {getTemplateChecklistStatus(allStatusDataExceptOverdue)}
                    {overDueData && overDueData.length>0 && <Divider style={{margin: "8px 0px"}}/>}
                    {overDueData && overDueData.length>0 && getTemplateChecklistStatus(overDueData)}
                </Popover>
    }

    const callTemplateListApi = (organization_id) => {
        props.fetchTemplateList(organization_id, props.startDateFilter, props.endDateFilter, 
            props.companiesFilter, props.selectedMembersFilter, props.selectedTemplate)
    }

    const onTemplatePublishUnPublishClick = (template, pubString) => {
        const organization_id = props.selectedOrganization.organization.id;
        const template_id = template.template_id;

        templatePublishUnpublishApi(organization_id, 'template' ,template_id, pubString)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response templatePublishUnpublishApi ', res);
                setAnchorEl3(null);

                enqueueSnackbar(res.message, {
                    variant:'success'
                })

                callTemplateListApi(organization_id);
                
            })
            .catch((e) => {
                consoleToLog("Erro templatePublishUnpublishApi ", e);
                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant:'error'
                    })
                    return false;
                }
            });

     }

    

    const onDeleteTemplateClick = (e, template) => {
        e.stopPropagation();
        closeTemplateActionPopover();
        if(window.confirm('Are you sure you want to delete this template?')) {
            const organization_id = props.selectedOrganization.organization.id;
            const template_id = template.template_id;

            archiveTemplateApi(template_id)
                .then((response) => {
                    const res = response.data;
                    consoleToLog("Response archiveTemplateApi", res);

                    callTemplateListApi(organization_id);
                })
                .catch((e) => {
                    consoleToLog('Error archiveTemplateApi', e.response);
                    if(e.response.data && e.response.data.message){
                        enqueueSnackbar(e.response.data.message, {variant:'error'});
                        return;
                    }
                })
        }

    }

    const showTemplateActionPopover = (template) => {
        return <Popover
                    id="template-action-popover"
                    className={classes.templatePopover}
                    keepMounted
                    open={(Boolean(anchorEl3) && template.template_id === templateId)}
                    anchorEl={anchorEl3}
                    onClose={closeTemplateActionPopover}
                    >
                    {!accessLevelManager &&
                    <MenuItem onClick={(e) => {
                        e.stopPropagation();
                        history.push(`/template/edit/${template.slug}`);
                    }}>
                        Edit Template
                    </MenuItem>
                    }
                    {
                        template?.published ?
                        <MenuItem onClick={(e) => {
                                e.stopPropagation();
                                if(window.confirm('Are you sure you want to unpublish this template?')) {
                                    onTemplatePublishUnPublishClick(template, 'unpublish');
                                }
                            }}>
                            Unpublish
                        </MenuItem>
                        :
                        <MenuItem onClick={(e) => {
                                e.stopPropagation();
                                onTemplatePublishUnPublishClick(template, 'publish');
                            }}>
                            Publish
                        </MenuItem>

                    }
                    {!accessLevelManager &&
                    <MenuItem onClick={(e) => onDeleteTemplateClick(e, template)}>
                        Delete Template
                    </MenuItem>
                    }
                </Popover>
    }

    // const onTemplateClick = (template) => {
    //     trackGAEvent(props.selectedOrganization.organization.name, 'Open Template', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
    //     props.setSelectedDrawerState(SelectedDrawerState.NOT_SELECTED);
    //     props.setSelectedTemplate(template);
    //     history.push('/dashboard')
    // }

    const onOrganizationMemberClick = () => {
        trackGAEvent(props.selectedOrganization.organization.name, 'Open Member', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
        //props.setSelectedTemplate('');
        props.setSelectedDrawerState(SelectedDrawerState.SHOW_ORG_MEMBERS);
    }

    const onCompletionReportClick = () => {
        trackGAEvent(props.selectedOrganization.organization.name, 'Completion Report', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
        //props.setSelectedTemplate('');
        props.setSelectedDrawerState(SelectedDrawerState.SHOW_COMPLETION_REPORT);
    }

    const onWorkLoadReportClick = () => {
        trackGAEvent(props.selectedOrganization.organization.name, 'Workload Report', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
        //props.setSelectedTemplate('');
        props.setSelectedDrawerState(SelectedDrawerState.SHOW_WORKLOAD_REPORT);
    }

    const onFollowupReportClick = () => {
        trackGAEvent(props.selectedOrganization.organization.name, 'Followup Report', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
        //props.setSelectedTemplate('');
        props.setSelectedDrawerState(SelectedDrawerState.SHOW_FOLLOWUP_REPORT);
    }
    
    const onOrganizationLabelsClick = () => {
        trackGAEvent(props.selectedOrganization.organization.name, 'Open Labels', `${props.auth.user.firstname} ${props.auth.user.lastname} `);
        //props.setSelectedTemplate('');
        props.setSelectedDrawerState(SelectedDrawerState.SHOW_ORG_LABELS);
    }

    const onOrganizationCompaniesClick = () => {
        trackGAEvent(props.selectedOrganization.organization.name, 'Open Companies', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
        //props.setSelectedTemplate('');
        props.setSelectedDrawerState(SelectedDrawerState.SHOW_ORG_COMPANIES);
    }

    const onOrganizationSettingsClick = () => {
        trackGAEvent(props.selectedOrganization.organization.name, 'Open Settings', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
        //props.setSelectedTemplate('');
        props.setSelectedDrawerState(SelectedDrawerState.SHOW_ORG_SETTINGS);
    }

    /* const onActivityReportClick = () => {
        trackGAEvent(props.selectedOrganization.organization.name, 'Open Activity', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
        //props.setSelectedTemplate('');
        props.setSelectedDrawerState(SelectedDrawerState.SHOW_ACTIVITY_REPORT);
    } */

    const onEmailReportClick = () => {
        trackGAEvent(props.selectedOrganization.organization.name, 'Email Report', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
        //props.setSelectedTemplate('');
        props.setSelectedDrawerState(SelectedDrawerState.SHOW_EMAIL_REPORT);
    }

    const onCreateTemplateClick = () => {
        history.push('/template/create');
        setAnchorEl2(null);
    }

    const onPredefinedTemplateClick = () => {
        trackGAEvent(props.selectedOrganization.organization.name, 'Clicked Predefined Template', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
        //props.setSelectedTemplate('');
        props.setSelectedDrawerState(SelectedDrawerState.CREATE_PREDEFINED_TEMPLATE);

    }

    const onOnboardingClick = () => {
        trackGAEvent(props.selectedOrganization.organization.name, 'Clicked Onboarding Tab', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
        //props.setSelectedTemplate('');
        props.setSelectedDrawerState(SelectedDrawerState.SHOW_ONBOARDING);

    }

    const setTemplate = () => {
        !props.selectedTemplate && props.setSelectedTemplate(props.templates[0]);
        history.push('/dashboard');
    }

    const onOverdueTabClick = () => {
        trackGAEvent(props.selectedOrganization.organization.name, 'Opened Work', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
        props.setSelectedDrawerState(SelectedDrawerState.SHOW_OVERDUE_CHECKLIST);
        setTemplate();
    }

    const onUpcomingTabClick = () => {
        trackGAEvent(props.selectedOrganization.organization.name, 'Opened Work', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
        props.setSelectedDrawerState(SelectedDrawerState.SHOW_UPCOMING_CHECKLIST);
        setTemplate();
    }

    const onAllTabClick = () => {
        trackGAEvent(props.selectedOrganization.organization.name, 'Opened Work', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
        props.setSelectedDrawerState(SelectedDrawerState.SHOW_ALL_CHECKLIST);
        setTemplate();
    }

    const labelStatus = (template) => {
        let label_count =  template.data;
        return (
            <ul className={classes.listmainstyle1}>
                { label_count && label_count.length > 0 ? label_count.map((label) => {
                            return <li className={classes.liststyle1} key={label.name}>
                                    <span> <Typography style={{whiteSpace: "nowrap",
                                                    maxWidth: "150px", 
                                                    overflow: "hidden", 
                                                    fontSize:'14px',
                                                    color:`${label.label_color}`,
                                                    textOverflow: "ellipsis"}}>{label.label_name}
                                            </Typography>
                                        <span className={classes.statuslist}>{label.label_count}</span> 
                                    </span>
                                </li>
                        }): <div></div>}
            </ul>
        )
    }

    const getTemplateChecklistStatus = (status_data) => {
        return (
            <ul className={classes.listmainstyle1}>
                { status_data && status_data.length > 0 ? status_data.map((status) => {
                            return <li className={classes.liststyle1} key={status.name}>
                                    <span> <Typography style={{whiteSpace: "nowrap",
                                                    maxWidth: "150px", 
                                                    overflow: "hidden", 
                                                    fontSize:'14px',
                                                    color:`${getStatusColor(status.status)}`,
                                                    textOverflow: "ellipsis"}}>{getStatusNameByValue(status.status)}
                                            </Typography>
                                        <span className={classes.statuslist}>{status.count}</span> 
                                    </span>
                                </li>
                        }): <div></div>}
            </ul>
        )
    }

    const showTemplatePopover = () => {
        return <Popover
                    id="template-option-popover"
                    keepMounted
                    open={Boolean(anchorEl2)}
                    anchorEl={anchorEl2}
                    className={classes.templatePopover}
                    onClose={handleTemplatePopoverClose}
                    >
                    <MenuItem onClick={onCreateTemplateClick}>Create Template</MenuItem>
                    <MenuItem onClick={handleDrawerOpen}>Import Template</MenuItem>
                </Popover>
    }

    return (
        <div className={classes.leftmenu}>
            {/* {!accessLevelGuest && <ListItem button className={classes.activityLabelStyle}
                selected={props.setDrawerState === SelectedDrawerState.SHOW_ACTIVITY_REPORT} 
                onClick={onActivityReportClick}>
                <i className={`flaticon-chat-1 ${classes.icons}`}></i>
                <Typography variant="body1" className={classes.text}>Activity</Typography>
            </ListItem>} */}
            {!accessLevelManager && onboarding &&
            <ListItem button className={classes.labels}
                    selected={props.setDrawerState === SelectedDrawerState.SHOW_ONBOARDING}
                    onClick={onOnboardingClick}
            >
                <i className={`flaticon-settings-1 ${classes.icons}`}></i>
                <Typography variant="body1" className={classes.text}>Onboarding</Typography>
            </ListItem>}

            {/* <ListItem button className={classes.activityLabelStyle}
                selected={props.setDrawerState === SelectedDrawerState.SHOW_WORKPLACE} 
                onClick={onWorkTabClick}>
                <i className={`flaticon-folder ${classes.icons}`}></i>
                <Typography variant="body1" className={classes.text}>Work</Typography>
            </ListItem> */}

                    {<Accordion elevation={0} defaultExpanded="true" 
                        classes={{
                            root: classes.MuiAccordionroot
                        }}
                    >
                        <div>
                            <AccordionSummary
                                style={{flexDirection: 'row-reverse', display: "inline-flex", padding: 0,  minHeight: "0"}}
                                //className={classes.summary}
                                expandIcon={<i className={`flaticon-down-arrow ${classes.rightdownicon}`}></i>}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                classes = {{
                                    root: classes.MuiAccordionSummaryroot,
                                    expandIcon: classes.MuiAccordionSummaryexpandIcon
                                }}
                            >
                                <Typography className={classes.text} variant="body1">Work</Typography>
                            </AccordionSummary>
                        </div>
                        <AccordionDetails  className={classes.detail}>
                            <List component="div" disablePadding>
                                <ListItem button className={classes.listitem}
                                    selected={props.setDrawerState === SelectedDrawerState.SHOW_OVERDUE_CHECKLIST}   
                                    onClick={onOverdueTabClick}>
                                        Overdue
                                </ListItem>
                                <ListItem button className={classes.listitem}
                                    selected={props.setDrawerState === SelectedDrawerState.SHOW_UPCOMING_CHECKLIST}   
                                    onClick={onUpcomingTabClick}>
                                        Upcoming
                                </ListItem>
                                <ListItem button className={classes.listitem}
                                    selected={props.setDrawerState === SelectedDrawerState.SHOW_ALL_CHECKLIST}   
                                    onClick={onAllTabClick}>
                                        All
                                </ListItem>
                            </List>
                        </AccordionDetails>
                    </Accordion>}

                    {/* <Accordion elevation={0} defaultExpanded="true" 
                        className={classes.templatehover}
                        classes={{
                            root: classes.MuiAccordionroot,
                        }}
                    >
                        <div>
                            <AccordionSummary
                                style={{flexDirection: 'row-reverse', display: "inline-flex", padding: 0,  minHeight: "0"}}
                                //className={classes.summary}
                                //expandIcon={<ExpandMoreIcon className={classes.rightdownicon}/>}
                                expandIcon={<i className={`flaticon-down-arrow ${classes.rightdownicon}`}></i>}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                classes = {{
                                    root: classes.MuiAccordionSummaryroot,
                                    expandIcon: classes.MuiAccordionSummaryexpandIcon
                                }}
                            >
                                <Typography className={classes.text} variant="body1">Templates</Typography>
                            </AccordionSummary>
                            {/* <IconButton aria-label="delete" style={{float: "right"}}>
                                <i className="flaticon-more ellipsisIcon" style={{fontSize: "1.3rem", color: "#202020"}}></i>
                            </IconButton> }
                            {!accessLevelManager &&
                                <Button aria-label="delete"
                                    onClick={handleTemplatePopoverOpen}
                                    style={{float: "right", marginTop: "8px", minWidth: "50px", padding: "2px 4px"}}
                                >
                                    <i className="flaticon-more ellipsisIcon" style={{fontSize: "1.3rem", color: "#202020",marginTop:"2px"}}></i>
                                </Button>}
                            {showTemplatePopover()}
                        </div>
                        <AccordionDetails  className={classes.detail}>
                            <List component="div" disablePadding>
                                {
                                    (props.templates && props.templates.length > 0) ?
                                    props.templates.map((template) => {
                                        let template_id = props.selectedTemplate ? props.selectedTemplate.template_id : undefined
                                        return <div key={template.template_id} className={classes.listitem}>
                                                    <ListItem button 
                                                        selected={props.setDrawerState === SelectedDrawerState.NOT_SELECTED && template_id === template.template_id}
                                                    onClick={() => {
                                                        onTemplateClick(template);
                                                        setTempId(template);
                                                    }}
                                                    className={classes.templateList}
                                                    >
                                                        <Tooltip title={template.name} arrow>
                                                            <span className={classes.templateName}>
                                                                {template.name}  
                                                            </span>
                                                        </Tooltip>
                                                        <div className={classes.countAndEllipsisIcon}>
                                                            <span className={classes.listStyle}
                                                                onMouseEnter={(e)=>handlePopoverOpen(e, template)}
                                                                onMouseLeave={(e)=>handlePopoverClose(e)}
                                                            >{template.checklist_count}</span>
                                                            <span onClick={(e) => {
                                                                e.stopPropagation();
                                                            }}>
                                                               <MoreVertOutlinedIcon
                                                                onClick={(e) => {
                                                                    // e.stopPropagation();
                                                                    openTemplateActionPopover(e, template);
                                                                }}
                                                                className={`${classes.templateActionIcon} tempActionIcon`}/>
                                                                {showTemplateActionPopover(template)}
                                                            </span>
                                                        </div>
                                                    </ListItem>
                                                    {template.checklist_count > 0 && showTemplateLabelStatus(template)}
                                                </div>
                                    }) :
                                    <ListItem button className={classes.listitem} >No Templates Found</ListItem>
                                }
                            </List>
                        </AccordionDetails>
                    </Accordion> */}
                    {props.superAdminUser &&
                    <ListItem button className={classes.activityLabelStyle}
                        selected={props.setDrawerState === SelectedDrawerState.CREATE_PREDEFINED_TEMPLATE} 
                        onClick={onPredefinedTemplateClick}>
                        <i className={`flaticon-clipboards-2 ${classes.icons}`}></i>
                        <Typography variant="body1" className={classes.text}>Predefined Templates</Typography>
                    </ListItem>}

                    {!accessLevelGuest && <Accordion elevation={0} defaultExpanded="true" 
                        classes={{
                            root: classes.MuiAccordionroot
                        }}
                    >
                        <div>
                            <AccordionSummary
                                style={{flexDirection: 'row-reverse', display: "inline-flex", padding: 0,  minHeight: "0"}}
                                //className={classes.summary}
                                expandIcon={<i className={`flaticon-down-arrow ${classes.rightdownicon}`}></i>}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                classes = {{
                                    root: classes.MuiAccordionSummaryroot,
                                    expandIcon: classes.MuiAccordionSummaryexpandIcon
                                }}
                            >
                                <Typography className={classes.text} variant="body1">Reports</Typography>
                            </AccordionSummary>
                        </div>
                        <AccordionDetails  className={classes.detail}>
                            <List component="div" disablePadding>
                                <ListItem button className={classes.listitem}
                                    selected={props.setDrawerState === SelectedDrawerState.SHOW_COMPLETION_REPORT}   
                                    onClick={onCompletionReportClick}>
                                    Completion Report
                                </ListItem>
                                <ListItem button className={classes.listitem}
                                    selected={props.setDrawerState === SelectedDrawerState.SHOW_WORKLOAD_REPORT}   
                                    onClick={onWorkLoadReportClick}>
                                   Workload Report
                                </ListItem>
                                <ListItem button className={classes.listitem}
                                    selected={props.setDrawerState === SelectedDrawerState.SHOW_FOLLOWUP_REPORT}   
                                    onClick={onFollowupReportClick}>
                                    Follow Up Report
                                </ListItem>
                                <ListItem button className={classes.listitem}
                                    selected={props.setDrawerState === SelectedDrawerState.SHOW_EMAIL_REPORT}   
                                    onClick={onEmailReportClick}>
                                    Email Report
                                </ListItem>
                                {/* <ListItem button className={classes.listitem}>
                                    Activity Report
                                </ListItem> */}
                            </List>
                        </AccordionDetails>
                    </Accordion>}
                    {!accessLevelGuest && <List component="div" disablePadding>
                        <ListItem button className={classes.labels}
                                selected={props.setDrawerState === SelectedDrawerState.SHOW_ORG_MEMBERS}   
                                onClick={onOrganizationMemberClick}>
                            <i className={`flaticon-group ${classes.icons}`}></i>
                            <Typography variant="body1" className={classes.text}>Members</Typography>
                        </ListItem>
                        {/* <ListItem button className={classes.labels}
                                selected={props.setDrawerState === SelectedDrawerState.SHOW_ORG_LABELS}   
                                onClick={onOrganizationLabelsClick}>
                            <i className={`flaticon-price-tag ${classes.icons}`}></i>
                            <Typography variant="body1" className={classes.text}>Labels</Typography>
                        </ListItem> */}
                        <ListItem button className={classes.labels}
                                selected={props.setDrawerState === SelectedDrawerState.SHOW_ORG_COMPANIES}
                                onClick={onOrganizationCompaniesClick}
                        >
                        <i className={`flaticon-business-and-trade ${classes.icons}`}></i>
                            <Typography variant="body1" className={classes.text}>Clients</Typography>  
                        </ListItem>
                        
                        {!accessLevelManager &&
                        <ListItem button className={classes.labels}
                                selected={props.setDrawerState === SelectedDrawerState.SHOW_ORG_SETTINGS}
                                onClick={onOrganizationSettingsClick}
                        >
                            <i className={`flaticon-settings-1 ${classes.icons}`}></i>
                            <Typography variant="body1" className={classes.text}>Settings</Typography>
                        </ListItem>}
                    </List>}
                    <ImportTemplateModal openDrawer={openDrawer} 
                        handleDrawerClose={handleDrawerClose}/>
                </div>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    selectedTemplate: state.organizationInfo.selectedTemplate,
    templates: state.organizationInfo.templates,
    setDrawerState: state.organizationInfo.setDrawerState,
    startDateFilter: state.filters.startDateFilter,
    endDateFilter: state.filters.endDateFilter,
    selectedMembersFilter: state.filters.selectedMembersFilter,
    companiesFilter: state.filters.companiesFilter,
    superAdminUser: state.organizationInfo.superAdminUser,
})

const mapDispatchToProps = (dispatch) => ({
    setSelectedTemplate: (template) => dispatch(setSelectedTemplate(template)),
    setSelectedDrawerState: (drawerState) => dispatch(setSelectedDrawerState(drawerState)),
    setOrgTemplates: (templates) => dispatch(setOrgTemplates(templates)),
    fetchTemplateList: (organization_id, start_date, end_date, companiesFilter, selectedMembersFilter, selectedTemplate) => 
        dispatch(fetchTemplateList(organization_id, start_date, end_date, companiesFilter, selectedMembersFilter, selectedTemplate)),
})

export default connect(mapStateToProps, mapDispatchToProps)(LeftMenuComponent);