
//set selected organization
export default (state = {}, action) => {
    switch (action.type) {
        case 'LOGOUT_ORGS':
            return {};

        case 'SET_ORGANIZATIONS_WITH_COUNT':
            return action.organizations;

        default:
            return state;
    }
};
