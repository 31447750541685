import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Drawer, Grid, Typography, Button, TextField, MenuItem, CircularProgress} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {connect} from 'react-redux';
import { addOrgMember } from '../../actions/selectedOrganization';
import { useSnackbar } from 'notistack';
import { emailValidator } from '../../util/validator';
import { consoleToLog, capitalize, trackGAEvent } from '../../util/AppUtil';
import {addMemberToOrgApi, createPendingInvitationApi, pendingInvitationExistApi} from '../../services/authService';
import MobileRightToLeftSideDrawer from './MobileRightToLeftSideDrawer';

const useStyles = makeStyles((theme) => ({
    drawerPaper: {
        backgroundColor: theme.palette.primary.light,
        width: '100%', 
        height: '100vh',
        flexGrow:1,
        overflowY:'auto',
        overflowX:'hidden'
    },
    backIcon: {
        color: "#202020",
        border: " 1px solid #202020",
        padding: "4px 0px",
        fontSize: "18px",
        minWidth: "40px",
        lineHeight: "18px",
        marginBottom: "10px",
        marginRight: "16px",
    },
    mobileHeading: {
        fontSize: '18px',
        fontWeight: 500,
        marginTop:'-8px'
    },
    modalFooter: {
        position: 'absolute',
        bottom: '8px',
        left: 0,
        right:0,
        zIndex:15,
        background: '#fff',
        borderTop: '1px solid #ccc',
        padding:'6px 8px 6px 8px',
    },
    labelStyle: {
        fontSize:'14px',
        marginBottom:'-4px'
    }
    
}))

const MobileInviteMemberDrawer = (props) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();

    const [selectedRowId, setSelectedRowId] = useState(-1);
    const [email, setEmail] = useState('');
    const [buttonLoading, setButtonLoading] = useState(false);

    const onChangeSelectedRoleType = (e) => {
        const value = e.target.value;
        consoleToLog('********value', value);
        setSelectedRowId(value);
    }

    const onInviteMemberClick = () => {
        if (!email) {
            enqueueSnackbar('Email cannot be empty', {
                variant: 'error'
            });
            return;
        }
        const isEmailValid = emailValidator(email);
            if (!isEmailValid) {
            enqueueSnackbar('Email is not valid', {
                variant: 'error'
            });
                return;
        }

        const role_id = selectedRowId;
        consoleToLog("*************role_id", role_id);
        if(role_id === -1 || role_id === '-1') {
            enqueueSnackbar('Please Select a Type', {
                variant: 'error'
            });
            return;
        }

        const service = 'workplace';
        const organization_id = props.selectedOrganization.organization.id;
        const entity_name = props.selectedOrganization.organization.name;
        const entity_email = props.selectedOrganization.organization.email;

        if(!props.is_orgEmail_verified) {
            enqueueSnackbar('Verfiy your email to send invitation', {variant:'error'});
            return;
        } else {
            setButtonLoading(true);
            pendingInvitationExistApi(organization_id, email)
                .then((response) => {
                    const res = response.data;
                    consoleToLog('Response pendingInvitationExistApi: ', res);

                    if(res.message === 'No record in pending inviation') {
                        addMemberToOrg(service, email, role_id, organization_id, entity_name, entity_email)
                    }
                })
                .catch((e) => {
                    consoleToLog("pendingInvitationExistApi error", e.response);
                    setButtonLoading(false);
                    if (e.response.data && e.response.data.message) {
                        return false;
                    }
                });
        }
    }

    const addMemberToOrg = (service, email, role_id, organization_id, entity_name, entity_email) => {

        addMemberToOrgApi(service, email, role_id, organization_id, entity_name, entity_email)
        .then((response) => {
            const res = response.data;
            consoleToLog('Response addMemberToOrgApi: ', res);
            setButtonLoading(false);
            enqueueSnackbar(res.message, {
                variant: 'success'
            });

            //const is_shadow = res.member.user.is_shadow;
            trackGAEvent(props.selectedOrganization.organization.name, 'Invite Member', `${props.auth.user.firstname} ${props.auth.user.lastname} invited member ${email}`);
            
            setEmail('');
            setSelectedRowId(undefined);
            props.handleDrawerClose();
            createPendingInvitation(organization_id, role_id, email)
        })
        .catch((e) => {
            consoleToLog("addMemberToOrgApi error", e.response);
            setButtonLoading(false);
            if (e.response.data && e.response.data.message) {
                enqueueSnackbar(e.response.data.message, {
                    variant:'error'
                });
                return false;
            }
        });
    }

    const createPendingInvitation = (organization_id, role_id, email) => {
            
        createPendingInvitationApi(organization_id, role_id, email)
            .then((response) => {
                    const res = response.data;
                    consoleToLog('Response createPendingInvitationApi', res);

            })
            .catch((e) => {
                consoleToLog("createPendingInvitationApi error", e.response);
                setButtonLoading(false);
                if (e.response.data && e.response.data.message) {
                    return false;
                }
            });
    }

    return (
        <MobileRightToLeftSideDrawer openDrawer={props.openDrawer} 
            drawerClose={props.handleDrawerClose}
            title='Invite Member'>
                <div className="modal__scheduler" style={{overflow:'auto'}}>
                    <Grid item xs={12}> 
                        <Typography className={classes.labelStyle}>
                            Email
                        </Typography>
                        <TextField fullWidth 
                            id="email" 
                            variant="outlined"
                            type="text"
                            margin="dense"
                            value={email}
                            style={{background:'#fff'}}
                            placeholder="Enter Email Address"
                            InputLabelProps={{style: {fontSize: 13, color: "#ccc"}}}
                            onChange={e => { 
                                setEmail(e.target.value);
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} style={{marginTop:'8px'}}> 
                        <Typography className={classes.labelStyle}>
                            Type
                        </Typography>
                        <TextField fullWidth
                            margin="dense"
                            variant="outlined"
                            select
                            //label= "Select Type"
                            value={selectedRowId ? selectedRowId : ''}
                            style={{background: '#fff'}}
                            InputLabelProps={{style: {fontSize: 14, paddingRight:'31px !important'}}}
                            onChange={onChangeSelectedRoleType}
                        >
                                <MenuItem value={-1}>Select Type</MenuItem>
                                {   
                                    props.roles && props.roles.map((role) => {
                                    return  <MenuItem key={role} value={role}>
                                                {capitalize(role)}
                                            </MenuItem>
                                    })
                                }
                        </TextField>
                    </Grid>
                </div>

                <div className={classes.modalFooter}>
                    <Grid item container alignItems='center' spacing={1}>
                        <Grid item xs={6} sm={6}>
                            <Button variant="contained" color="primary" 
                                fullWidth
                                onClick={onInviteMemberClick}
                                >
                                {buttonLoading && <CircularProgress size={25} style={{marginRight:'8px'}}/>}
                                Invite
                            </Button>
                        </Grid>

                        <Grid item xs={6} sm={6}>
                            <Button variant="outlined" 
                                fullWidth
                                onClick={props.handleDrawerClose}>
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            
        </MobileRightToLeftSideDrawer>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    members: state.organizationInfo.members
});

const mapDispatchToProps = (dispatch) => ({
    addOrgMember: (member) => dispatch(addOrgMember(member))
})

export default connect(mapStateToProps, mapDispatchToProps)(MobileInviteMemberDrawer);