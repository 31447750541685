import React,{useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Grid, Typography, Button, Avatar, Drawer, CircularProgress, TextField, Paper} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { FileUploader } from "react-drag-drop-files";
import S3FileUpload from "react-s3";
import { uploadChecklistAttachmentApi, getUploadUrlForChecklistAttachmentApi } from "../../../services/ChecklistService";
import {connect} from 'react-redux';
import { useSnackbar } from 'notistack';
import { isStringSpaces } from "../../../util/validator";
import {consoleToLog, capitalizeFirstLetterOfEachWord,trackGAEvent,} from "../../../util/AppUtil";
import { useIsMount } from '../../../components/useIsMount';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
    drawerPaper: {
        backgroundColor: theme.palette.primary.light,
        width: '100%', 
        height: '100vh !important',
        flexGrow:1,
        overflowY:'auto',
        overflowX:'hidden',
    },
    backIcon: {
        color: "#202020",
        border: " 1px solid #202020",
        padding: "4px 0px",
        fontSize: "18px",
        minWidth: "40px",
        lineHeight: "18px",
        marginBottom: "10px",
        marginRight: "16px",
    },
    mobileHeading: {
        fontSize: '18px',
        fontWeight: 500,
        marginTop:'-8px'
    },
    modalFooter: {
        position: 'absolute',
        bottom: '8px',
        left: 0,
        right:0,
        zIndex:15,
        background: '#fff',
        borderTop: '1px solid #ccc',
        padding:'6px 8px 6px 8px',
    },
    circularProgress: {
        marginLeft: 0,
        marginRight: theme.spacing(2),
        color: 'white'
    },
    labelStyle: {
        marginBottom: '-7px'
    },
    fileUploaderComponent: {
        width: "auto",
        backgroundColor: "red",
        border: "none",
        outline: "none",
    },
    fileUploaderText: {
        fontSize: "13px",
        color: "black",
        fontWeight: "400",
    },
    dragcontainerFooter: {
        fontSize: "12px",
        color: "gray",
        marginLeft: "0px",
        marginTop: "-14px",
        background: theme.palette.primary.light
    },
    dragcontainer: {
        width: '100% !important',
        border: 'none !important',
        outline: 'none !important',
    },
    dragcontainer:  {
        background: theme.palette.primary.light,
        '& .label': {
        display: 'block !important',
        height: '8rem !important',
        alignItems: 'center',
        border: 'dashed 2px #c5bebe !important',
        cursor: 'pointer',
        marginBottom: '-2px !important',
        fontSize: '10px !important',
        borderRadius:'4px',
        
        }
    }
}))


const MobileUploadAttachmentDrawer = (props) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    const isMount = useIsMount();

    const [loading, setLoading] = useState(false);
    const [attachmentTitle, setAttachmentTitle] = useState('');
    const [file, setFile] = useState(null);
    
    const handleFileChange = (file) => {
        setFile(file);
    };

    const onTypeError = (err) => {
        // consoleToLog("Error", err);
    };

    const onUploadFileClick = (e) => {
        e.preventDefault();
        if (!attachmentTitle) {
        enqueueSnackbar("Title can not be empty!", {
            variant: "error",
        });
        return;
        }

        const isTitleValid = !isStringSpaces(attachmentTitle);
        if (!isTitleValid) {
        enqueueSnackbar("Title can not be empty!", {
            variant: "error",
        });
        return;
        }
        if (!file) {
        enqueueSnackbar("Attachment can not be empty!", {
            variant: "error",
        });
        return;
        }
        if (isTitleValid && file) {
        // consoleToLog("Submit details", newTitle, file.name);
        }
        const checklist_id = props.checklistId;
        if (!checklist_id) {
        // consoleToLog("UploadFileModal: Checklist_id not found!");
        return;
        }

        const type = 'checklist';

        getUploadUrlForChecklistAttachmentApi(checklist_id, file.name, type)
        .then((response) => {
            const res = response.data;
            consoleToLog('Response getUploadUrlForChecklistAttachmentApi: ', res);

            // Put the fileType in the headers for the upload
            const options = {
            headers: {
                "Content-Type": file.type,
            }
            };
            axios.defaults.headers.common = {};
            axios.put(res.upload_signed_url, file, options)
            .then(result => {
                consoleToLog('signed Request s3', result);
                console.log("Response from s3");
                updateCheckListFileUploadUrl(checklist_id, type, attachmentTitle, res.url);
            })
            .catch(error => {
                consoleToLog('error: ', error.response);
            })

        })
        .catch((err) => {
            consoleToLog("File Upload Error: ", err);
        });
    }

    const updateCheckListFileUploadUrl = (checklist_id, type, title, path) => {

        uploadChecklistAttachmentApi(checklist_id, type, capitalizeFirstLetterOfEachWord(title), path)
            .then((response) => {
            const res = response.data;
            consoleToLog("Response uploadChecklistAttachmentApi: ", res);
            setAttachmentTitle("");

            var updatedAttachmentsArray = [
                response.data.checklist_attachment,
                ...props.attachmentList,
            ];

            // updated array locally
            props.setAttachmentList(updatedAttachmentsArray);
            if (!isMount) {
                props.setAttachmentList(updatedAttachmentsArray);
            }
            enqueueSnackbar(res.message, {
                variant: "success",
            });
            
            props.handleDrawerClose();
            trackGAEvent(props.selectedOrganization.organization.name, 'Mobile Checklist Upload Attachment', `${props.auth.user.firstname} ${props.auth.user.lastname}`);

            // to parent
            props.onAttachmentAdded(updatedAttachmentsArray);
            })
            .catch((e) => {
            consoleToLog("uploadChecklistAttachmentApi error", e.response);
            });
    };


    return (
        <Drawer open={props.openDrawer}
        variant="persistent"
        anchor="right"
        onClose={props.handleDrawerClose}
        classes={{
            paper: classes.drawerPaper
        }}>
            <Grid item xs={12} sm={12} style={{padding:'16px', marginTop:'4px'}}>
                <Grid item container alignItems='center'>
                    <Grid item>
                        <Button
                            variant="outlined"
                            className={classes.backIcon}
                            onClick={props.handleDrawerClose}
                        > 
                            <ArrowBackIcon /> 
                        </Button>
                    </Grid>

                    <Grid item>
                        <Typography variant="body1" 
                            className={classes.mobileHeading}>
                            Upload Attachment
                        </Typography>
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={12} style={{marginTop:'16px'}}>
                    <Typography variant="subtitle1" className={classes.labelStyle}>
                        Title
                    </Typography>
                    <TextField fullWidth
                        variant='outlined'
                        margin='dense'
                        placeholder='Enter Attachment Title'
                        onChange={(e) => setAttachmentTitle(e.target.value)}
                        style={{background:'#fff'}}
                    
                    />
                </Grid>

                <Grid item xs={12} sm={12} style={{marginTop:'16px'}}> 
                    <Paper elevation={0} className={classes.dragcontainer}>
                        <FileUploader
                            handleChange={handleFileChange}
                            onDrop={handleFileChange}
                            onSelect={handleFileChange}
                            onTypeError={onTypeError}
                            name="file"
                            className={classes.fileUploaderComponent}
                        >
                            <Grid item className='label'> 
                                <Grid item container direction="row"
                                    alignItems="center"
                                    justifyContent="center"
                                    style={{ marginTop: "2rem" }}
                                >
                                    <Button variant="outlined" color="secondary">
                                        Browse File
                                    </Button>
                                </Grid>
                                <Grid item container direction="row"
                                    alignItems="center"
                                    justifyContent="center"
                                    style={{ marginBottom: "10px", marginTop: "5px" }}
                                >
                                    {file ? (
                                    <Typography
                                        className={classes.fileUploaderText}
                                        style={{ color: " #888888" }}
                                    >
                                        {file.name}
                                    </Typography>
                                    ) : (
                                    <Typography className={classes.fileUploaderText}>
                                        Drag and Drop File
                                    </Typography>
                                    )}
                                </Grid>
                            </Grid>
                        </FileUploader>
                        <span className={classes.dragcontainerFooter}>
                            jpg, png, pdf, docx ,xls files allowed only
                        </span>
                    </Paper>
                </Grid>
            
                <Grid item container spacing={2} className={classes.modalFooter}>
                    <Grid item xs={6} sm={6}> 
                        <Button variant="contained" color="primary" 
                            fullWidth
                            onClick={onUploadFileClick}
                            >
                            {loading && <CircularProgress size={24} className={classes.circularProgress}/>}
                            Upload
                        </Button>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <Button variant="outlined"
                            fullWidth 
                            onClick={props.handleDrawerClose}>
                            Cancel
                        </Button>    
                    </Grid>
                </Grid>
            </Grid>
            
        </Drawer>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization
  });

export default connect(mapStateToProps)(MobileUploadAttachmentDrawer);