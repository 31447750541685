import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { Drawer } from "@material-ui/core";
import { Divider } from "@material-ui/core";
import { Typography, AppBar, Toolbar, IconButton } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import {
  ActionChange,
  ActionEnable,
  ActionLabel,
  Actions,
  ActionDueDate
} from "./RulesOptions";
import RuleItem from "./RuleItem";
import { connect } from "react-redux";
import TriggerComponent from "./TriggerComponent";
import { useIsMount } from "./useIsMount";
import { ruleType, actionType } from "./../util/AppUtil";
import { v4 as uuidv4 } from "uuid";
import {
  addRuleApi,
  getRulesApi,
  deleteRulesApi,
  editRuleApi,
} from "../services/ChecklistService";
import { useSnackbar } from "notistack";
import { CircularProgress } from "@material-ui/core";
import {
  getPredefinedTemplateRulesApi,
  addPredefinedTemplateRuleApi,
  editPredefinedTemplateRuleApi,
  deletepredefinedTemplateRuleApi,
} from '../services/authService';
import { consoleToLog } from '../util/AppUtil';
import { getEmailDraftListApi, getPredefinedTemplateEmailDraftListApi} from "../services/genieService";
import { schedulerType } from "../util/AppUtil";
import { checkTemplateRules } from "../actions/selectedOrganization";
import {getChecklistRulesApi, addChecklistRuleApi, editChecklistRuleApi, deleteChecklistRulesApi, updateChecklistRulesApi} from '../services/ChecklistService';


const useStyles = makeStyles((theme) => ({
  actionBox: {
    padding: "1rem",
    paddingLeft: "0rem",
    paddingRight: "2rem",
  },
  circularProgress: {
    marginLeft: "3px",
    marginRight: theme.spacing(2),
    color: 'white'
  },
  actionCircleContainer: {
    marginTop: "10px",
    height: "6rem",
  },
  actionsContainer: {
    margin: "0rem 3rem 0rem 3rem",
  },
  loaderContainer: {
    margin: "1rem",
  },
  rulesFound: {
    width: "95vw",
  },
  rulesNotFound: {
    width: "94vw",
  },
  notFoundMain: {
    width: "88vw",
  },
  notFoundMainText: {
    marginLeft: "6rem",
    width: "88vw",
  },
  ruleListContainer: {
    marginTop: "1rem",
    maxHeight: "60vh",
    overflow: "hidden",
    overflowY: "scroll",
    width: "100%",
  },
  notAttachmentsImage: {
    height: "13rem",
    width: "15rem",
    marginTop: "3rem",
    marginLeft: "10rem",
  },
  notAttachmentsText: {
    fontSize: "20px",
    fontWeight: "600",
  },
  notAttachmentsButton: {
    fontSize: "15px",
    width: "19rem",
    marginTop: "20px",
  },
  uploadAttachmentTypo: {
    fontSize: "15px",
    fontWeight: "600",
    margin: "5px 10px",
  },
  uploadAttachmentLabel: {
    fontSize: "13px",
    marginTop: "10px",
    marginBottom: "10px",
    color: "black",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  appBar: {
    background: "#F9F9F9",
    height: "45px",
    color: "#202020",
  },
  radioParent: {
    padding: "1rem",
    border: "1px solid black",
  },
  leftContainer: {
    marginTop: "10px",
    height: "6rem",
  },
  rightContainer: {
    padding: "1rem",
    paddingLeft: "0rem",
  },
  addOrContainer: {
    marginTop: "12px",
    marginBottom: "12px",
  },
  selectParent: {
    // border: "1px solid black",
    // padding: "10px",
    padding: "4px 10px",
    marginTop: "10px",
    borderRadius: "3px",
    backgroundColor: "#f7f6f6",
  },
  selectText: {
    color: "black",
    fontSize: "15px",
  },
  selectText2: {
    color: "black",
    marginLeft: "8px",
  },
  selectContainer: {
    marginLeft: "8px",
    minWidth: "10px",
  },
  selectdisabled: {
    color: "grey",
  },
  selectdisabled1: {
    color: "grey",
    backgroundColor: "black",
  },
  circleText: {
    borderRadius: "100%",
    padding: "40px 30px",
    border: "1px solid black",
    fontSize: "14px",
    fontWeight: "500",
  },
  circleText2: {
    borderRadius: "100%",
    padding: "44px 28px",
    border: "1px solid black",
    fontSize: "14px",
    fontWeight: "500",
  },
  whenContainer: {
    margin: "0rem 3rem 00rem 3rem",
  },
  whenContainer1: {
    margin: "0rem 3rem 0rem 3rem",
  },
  schedulerDivider: {
    height: "1.5px",
    backgroundColor: "black",
    marginTop: "3.2rem",
    marginLeft: "20px",
    marginRight: "-1px",
  },
  schedulerDivider1: {
    height: "1.5px",
    backgroundColor: "black",
    marginTop: "3.4rem",
    marginLeft: "25px",
    marginRight: "-1px",
  },
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
    fontSize: "3px",
  },
  icon: {
    borderRadius: "50%",
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto black",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: "black",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "black",
    },
  },
  addButtonStyle: {
    flexBasis: '9%', 
    maxWidth: '9%,',
    [theme.breakpoints.down(1470)] : {
      flexBasis: '11.5%', 
      maxWidth: '11.5%',
      marginRight:'-8px'
    }
  },
}));

const Rules = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [taskList, setTaskList] = useState(props.taskList);
  const [labelList, setLabelList] = useState(props.labelsList);
  const isMount = useIsMount();
  const { enqueueSnackbar } = useSnackbar();
  const [triggerArray, setTriggerArray] = useState([]);
  const [deleteIndex, setDeleteIndex] = useState();
  const [addTriggerDisabled, setAddTriggerDisable] = useState(true);
  const [action, setAction] = useState();
  const [rulesArray, setRulesArray] = useState();
  const [rulesLoading, setRulesLoading] = useState(false);
  const [actionArray, setActionArray] = useState([]);
  const [selectedRule, setSelectedRule] = useState(undefined);
  const templateId = props.templateId;
  const checklistId = props.checklistId;
  const organizationId = props.selectedOrganization.organization.id;
  const [loading,setLoading] = useState(false);
  const [emailDraftList, setEmailDraftList] = useState([]);
  const [predefinedEmailDraftList, setPredefinedEmailDraftList] = useState([]);
  
  const emailDraftListArr = props.fromPredefinedTemplate ? predefinedEmailDraftList : emailDraftList;


  //default first object
  const firstObj = {
    id: uuidv4(),
    type: ruleType.CHECKLIST_CREATION,
    creation: { immediate: true, duration: 0 },
    task: undefined,
    label: undefined,
    email_followup: undefined,
  };

  useEffect(() => {
    const localTriggerArray = [...triggerArray];
    localTriggerArray.push(firstObj);
    setTriggerArray(localTriggerArray);
    if(props.fromChecklistDetails) {
      consoleToLog('api called');
      getChecklistRulesApiCall();
    } else {
      if (props.templateId !== "" && !props.fromPredefinedTemplate) {
        getRulesApiCall();
      } else {
        getPredefinedTemplateRulesApiCall();
      }
    }
  }, []);

  useEffect(()=>{
    consoleToLog("triggerArray in useEffect",triggerArray)
  },[triggerArray])

  useEffect(() => {
    if(!isMount && selectedRule !== undefined && triggerArray.length > 1 ){
      if(triggerArray[0].type !== ruleType.CHECKLIST_CREATION){
        setAddTriggerDisable(false);
      }
    }
  }, [selectedRule,open,triggerArray]);

  useEffect(() => {
    if (!isMount) {
      var firstObj = {
        id: uuidv4(),
        type: ruleType.CHECKLIST_CREATION,
        creation: { immediate: true, duration: 0 },
        task: undefined,
        label: undefined,
        email_followup: undefined,
        //checklist: undefined
      };
      if (addTriggerDisabled && open && triggerArray.length > 1) {
        if (
          window.confirm(
            "Warning! All triggers will be lost. Would you like to continue to choose checklist creation ?"
          )
        ) {
          setTriggerArray([firstObj]);
        }
      }
    }
  }, [addTriggerDisabled]);

  //when checklist creation radio button is clicked, clear all triggers keep one Trigger obj
  const handleTriggerButtonAndClearTriggers = (flag) => {
    setAddTriggerDisable(flag);
    if (flag && open && triggerArray.length > 1) {
      if (
        window.confirm(
          "Warning! All triggers will be lost. Would you like to continue to choose checklist creation ?"
        )
      ) {
        setTriggerArray([firstObj]);
      }
    }
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setTriggerArray([firstObj]);
    setActionArray([]);
    setSelectedRule(undefined);
  };

  const getRulesApiCall = () => {
    const template_id = templateId;
    setRulesLoading(true);
    getRulesApi(template_id)
      .then((response) => {
        consoleToLog("****getRulesApiCall response: ", response.data.template_rules);
        setRulesArray(response.data.template_rules);
        setRulesLoading(false);

        getEmailDraftList(organizationId);
      })
      .catch((e) => {
        consoleToLog("getRulesApiCall error", e);
      });
  };

  const getPredefinedTemplateRulesApiCall = () => {
    const organization_id = organizationId;
    const template_id = templateId;
  
    setRulesLoading(true);
    getPredefinedTemplateRulesApi(organization_id, template_id)
      .then((response) => {
        consoleToLog("****getPredefinedTemplateRulesApi response: ", response.data.template_rules);
        setRulesArray(response.data.template_rules);
        setRulesLoading(false);
        getPredefinedEmailDraftList(organization_id);
      })
      .catch((e) => {
        consoleToLog("getPredefinedTemplateRulesApi error", e);
      });
  };

  const getChecklistRulesApiCall = () => {
    const checklist_id = checklistId;
    
    setRulesLoading(true);
    getChecklistRulesApi(checklist_id)
      .then((response) => {
        consoleToLog("****getChecklistRulesApi response: ", response.data.checklist_rules);
        setRulesArray(response.data.checklist_rules);
        setRulesLoading(false);

        getEmailDraftList(organizationId);
      })
      .catch((e) => {
        consoleToLog("getChecklistRulesApi error", e);
      });
  };

  const getEmailDraftList = (organinzation_id) => {
    getEmailDraftListApi(organinzation_id)
        .then((response) => {
            const res = response.data;
            consoleToLog('Response getEmailDraftListApi: ', res);

            setEmailDraftList(res.email_drafts);
        })
        .catch((e) => {
            consoleToLog("Error getEmailDraftListApi: ", e.response);

        })
  }

  const getPredefinedEmailDraftList = (organinzation_id) => {
    getPredefinedTemplateEmailDraftListApi(organinzation_id)
        .then((response) => {
            const res = response.data;
            consoleToLog('Response getPredefinedTemplateEmailDraftListApi: ', res);
            setPredefinedEmailDraftList(res.predefined_email_drafts);
        })
        .catch((e) => {
            consoleToLog("Error getPredefinedTemplateEmailDraftListApi: ", e.response);

        })
}


  const addRuleApiCall = (rules_json) => {
    const template_id = templateId;
    consoleToLog("******addRuleApiCall rules_json", rules_json);
    setLoading(true);
    addRuleApi(template_id, rules_json)
      .then((response) => {
        const res = response;
        consoleToLog("addRuleApiCall response: ", res);
        enqueueSnackbar("Automator Added Successfully", {
          variant: "success",
        });
        setRulesArray(response.data.template_rules);
        setOpen(false);
        setTriggerArray([firstObj]);
        setActionArray([]);
        setLoading(false);

        props.checkTemplateRules(organizationId);
      })
      .catch((e) => {
        consoleToLog("addRuleApiCall error", e);
        setOpen(false);
        setTriggerArray([firstObj]);
        setActionArray([]);
        setLoading(false)
      });
  };

  const addPredefinedTemplateRuleApiCall = (rules_json) => {
    const template_id = templateId;
    const organization_id = organizationId
    consoleToLog("******addPredefinedTemplateRuleApiCall rules_json", rules_json);
    setLoading(true);
    addPredefinedTemplateRuleApi(organization_id, template_id, rules_json)
      .then((response) => {
        const res = response;
        consoleToLog("addPredefinedTemplateRuleApi response: ", res);
        enqueueSnackbar("Automator Added Successfully", {
          variant: "success",
        });
          setRulesArray(response.data.predefined_template_rules);
          setOpen(false);
          setTriggerArray([firstObj]);
          setActionArray([]);
          setLoading(false)
      })
      .catch((e) => {
        consoleToLog("addPredefinedTemplateRuleApi error", e);
          setOpen(false);
          setTriggerArray([firstObj]);
          setActionArray([]);
          setLoading(false)
        });
  };

  const addChecklistRule = (rules_json) => {
    const checklist_id = checklistId;

    consoleToLog("******addChecklistRuleApi rules_json", rules_json);
    setLoading(true);
    addChecklistRuleApi(checklist_id, rules_json)
      .then((response) => {
        const res = response;
        consoleToLog("addChecklistRuleApi response: ", res);
        enqueueSnackbar("Automator Added Successfully", {
          variant: "success",
        });
        setRulesArray(response.data.checklist_rules);
        setOpen(false);
        setTriggerArray([firstObj]);
        setActionArray([]);
        setLoading(false);

        props.checkTemplateRules(organizationId);
      })
      .catch((e) => {
        consoleToLog("addChecklistRuleApi error", e);
        setOpen(false);
        setTriggerArray([firstObj]);
        setActionArray([]);
        setLoading(false)
      });
  }


  const editRuleApiCall = (rules_json) =>{
    const template_id = templateId;
    const id =selectedRule.id;

    setLoading(true);
    editRuleApi(template_id, rules_json, id)
    .then((response)=>{
      const res = response;
      consoleToLog("editRuleApiCall response: ", res);
      enqueueSnackbar("Automator Updated Successfully", {
        variant: "success",
      });
      setRulesArray(response.data.template_rules);
      setOpen(false);
      setLoading(false);
      
      setTriggerArray([firstObj]);
      setActionArray([]);
      setSelectedRule(undefined);
      props.checkTemplateRules(organizationId);
    })
    .catch((e) => {
      consoleToLog("editRuleApiCall error", e);
      setLoading(false);
    });
  }

  const editPredefinedTemplateRuleApiCall = (rules_json) =>{
    const organinzation_id = organizationId;
    const template_id = templateId;
    const id =selectedRule.id;

    setLoading(true);
    editPredefinedTemplateRuleApi(organinzation_id, template_id, rules_json, id)
    .then((response)=>{
      const res = response;
      consoleToLog("editPredefinedTemplateRuleApi response: ", res);
      enqueueSnackbar("Automator Updated Successfully", {
        variant: "success",
      });
      setRulesArray(response.data.predefined_template_rules);
      setOpen(false);
      setLoading(false);
    })
    .catch((e) => {
      consoleToLog("editPredefinedTemplateRuleApi error", e);
    });
  }

  const editChecklistRuleApiCall = (rules_json) =>{
    const checklist_id = checklistId;
    const id =selectedRule.id;

    setLoading(true);
    editChecklistRuleApi(checklist_id, rules_json, id)
    .then((response)=>{
      const res = response;
      consoleToLog("editChecklistRuleApi response: ", res);
      enqueueSnackbar("Automator Updated Successfully", {
        variant: "success",
      });
      setRulesArray(response.data.checklist_rules);
      setOpen(false);
      setLoading(false);
      
      setTriggerArray([firstObj]);
      setActionArray([]);
      setSelectedRule(undefined);
      props.checkTemplateRules(organizationId);
    })
    .catch((e) => {
      consoleToLog("editChecklistRuleApi error", e);
      setLoading(false);
    });
  }


  const validateTriggers = () => {
    for (var i = 0; i < triggerArray.length; i++) {
      var trigger = triggerArray[i];
      if (
        trigger.type === ruleType.TASK &&
        (Object.keys(trigger.task).length === 0 ||
          trigger.task.template_task_id === undefined ||
          trigger.task.status === undefined ||
          trigger.task.template_task_id === "none" ||
          trigger.task.status === "none")
      ) {
        enqueueSnackbar("Please select all fields", { variant: "error" });
        return;
      }

      if (
        trigger.type === ruleType.CHECKLIST_CREATION &&
        trigger.creation.immediate === false &&
        trigger.creation.duration === 0
      ) {
        enqueueSnackbar("Please select all fields", { variant: "error" });
        return;
      }

      if (
        trigger.type === ruleType.EMAIL_FOLLOWUP &&
        (Object.keys(trigger.email_followup).length === 0 ||
          trigger.email_followup.email_draft_id === undefined ||
          trigger.email_followup.email_draft_id === "none")
      ) {
        enqueueSnackbar("Please select all fields", { variant: "error" });
        return;
      }

      if (
        trigger.type === ruleType.LABEL &&
        (Object.keys(trigger.label).length === 0 ||
          trigger.label.label_id === undefined ||
          trigger.label.label_id === "none")
      ) {
        enqueueSnackbar("Please select all fields", { variant: "error" });
        return;
      }

      if (trigger.type === ruleType.NONE) {
        enqueueSnackbar("Please select all fields", { variant: "error" });
        return;
      }

      if(actionArray.length === 0){
        enqueueSnackbar("Please add an action", { variant: "error" });
        return;
      }
    }
    
    modifyArray();
  };

  const modifyArray = () => {
    if (triggerArray) {
      var modifiedTriggerArray = triggerArray.map((triggerObj) => {
        if (triggerObj.type === ruleType.TASK) {
          return triggerObj.task;
        } else if (triggerObj.type === ruleType.EMAIL_FOLLOWUP) {
          return triggerObj.email_followup;
        } else if (triggerObj.type === ruleType.LABEL) {
          return triggerObj.label;
        } else if (
          triggerObj.type === ruleType.AND ||
          triggerObj.type === ruleType.OR
        ) {
          return { type: triggerObj.type };
        } else {
          return {
            type: triggerObj.type,
            days: triggerObj.creation.duration,
          };
        }
      });
    }

    var modifiedActionsArray = actionArray.map((action) => {
      if (action.typeOfAction === actionType.TASK) {
        var taskObj = {
          type: action.typeOfAction,
          template_task_id: action.data.template_task_id,
          status: action.data.status,
        };
        return taskObj;
      } else if (action.typeOfAction === actionType.EMAIL_FOLLOWUP) {
        var schedulerObj = {
          type: action.typeOfAction,
          email_draft_id: action.data.email_draft_id,
          status: action.data.status,
          interval: action.data.interval,
          max_count: action.data.max_count,
          organization_email_id: props.fromPredefinedTemplate ? undefined : action.data.organization_email_id
        };
        consoleToLog("schedulerObj in modify",schedulerObj)
        return schedulerObj;
      } else if (action.typeOfAction === actionType.LABEL) {
        var labelObj = {
          type: action.typeOfAction,
          status: action.data.status,
          label_id: action.data.label_id,
        };
        return labelObj;
      } else {
        var dueDateObj = {
          type: action.typeOfAction,
          interval: action.data.interval,
        };
        return dueDateObj;
      }
    });

    var rules_json = [
      { triggers: modifiedTriggerArray, actions: modifiedActionsArray },
    ];
    consoleToLog("Modified Array", rules_json);

    if (rules_json.length !== 0) {
      if(selectedRule  !== undefined){
        consoleToLog("Edit Rule Api");

          if(props.fromChecklistDetails) {
            editChecklistRuleApiCall(rules_json);
          } else {
            if(props.fromPredefinedTemplate) {
              editPredefinedTemplateRuleApiCall(rules_json);
            } else {
              editRuleApiCall(rules_json);
            }
        }
      }else{
        consoleToLog("Add Rule Api");
        if(props.fromChecklistDetails) {
          addChecklistRule(rules_json);
        } else {
          if(props.fromPredefinedTemplate) {
            addPredefinedTemplateRuleApiCall(rules_json);
          } else {
            addRuleApiCall(rules_json);
          }
        }
      }
    }
  };

  // flow : handleAddRule click => validateTriggers => (if valid)modifyArray => Add Rule Api or Edit Rule Api
  const handleAddRule = () => {
    validateTriggers();
  };

  const addTrigger = () => {
    var operationObject = {
      id: uuidv4(),
      type: ruleType.NONE,
      creation: undefined,
      task: undefined,
      label: undefined,
      email_followup: undefined
    };
    var newObject = {
      id: uuidv4(),
      type: ruleType.TASK,
      creation: undefined,
      task: {},
      label: undefined,
      email_followup: undefined,
      //checklist: undefined
    };

    const localTriggerArray = triggerArray;
    localTriggerArray.push(operationObject);
    localTriggerArray.push(newObject);
    setTriggerArray([...localTriggerArray]);
  };

  const deleteTrigger = (triggerId) => {
    var localUpdatedArray = [];
    var deleteNext = false;
    var deletePrev = false;

    for (var i = 0; i < triggerArray.length; i++) {
      if (triggerId === triggerArray[i].id) {
        if (i === 0) {
          deleteNext = true;
          continue;
        } else if (i === triggerArray.length - 1) {
          deletePrev = true;
          continue;
        } else {
          //if other
          deleteNext = true;
          continue;
        }
      } else {
        if (deleteNext) {
          deleteNext = false;
          continue;
        } else {
          localUpdatedArray.push(triggerArray[i]);
          setTriggerArray([...localUpdatedArray]);
        }
      }
    }

    if (deletePrev) {
      localUpdatedArray.pop(triggerArray[i]);
      setTriggerArray([...localUpdatedArray]);
    }
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const updateTrigger = (updatedTriggerObj, errorValue) => {
    var localUpdatedArray = triggerArray.map((t) => {
      if (t.id === updatedTriggerObj.id) {
        return updatedTriggerObj;
      } else {
        return t;
      }
    });
    setTriggerArray([...localUpdatedArray]);
  };

  const editRule = (rule) => {
    setSelectedRule(rule);
    //  modify
    convertRuleToTriggersAndActions(rule);
    setOpen(true);
  };

  const convertRuleToTriggersAndActions = (rule) => {
    var localTriggerArray = [];
    var localActionsArray = [];

    for (var i = 0; i < rule.triggers.length; i++) {
      var trigger = rule.triggers[i];
      if (trigger.type === ruleType.TASK) {
        var taskObj = {
          id: uuidv4(),
          type: ruleType.TASK,
          creation: undefined,
          task: {
            type: trigger.type,
            template_task_id: trigger.template_task_id,
            status: trigger.status,
          },
          label: undefined,
          email_followup: undefined,
        };

        localTriggerArray.push(taskObj);
      } else if (trigger.type === ruleType.EMAIL_FOLLOWUP) {
        var schedulerObj = {
          id: uuidv4(),
          type: ruleType.EMAIL_FOLLOWUP,
          creation: undefined,
          task: undefined,
          label: undefined,
          email_followup: {
            type: trigger.type,
            email_draft_id: trigger.email_draft_id,
            status: trigger.status,
          },
        };

        localTriggerArray.push(schedulerObj);
      } else if (trigger.type === ruleType.LABEL) {
        var labelObj = {
          id: uuidv4(),
          type: ruleType.LABEL,
          creation: undefined,
          task: undefined,
          label: {
            type: trigger.type,
            label_id: trigger.label_id,
            active: trigger.active,
          },
          email_followup: undefined,
        };

        localTriggerArray.push(labelObj);
      } else if (trigger.type === ruleType.CHECKLIST_CREATION) {
        var checklistData;
        if (trigger && trigger.days === 0) {
          checklistData = { immediate: true, duration: 0 };
        } else {
          checklistData = { immediate: false, duration: trigger.days };
        }
        var checklistObj = {
          id: uuidv4(),
          type: ruleType.CHECKLIST_CREATION,
          creation: checklistData,
          task: undefined,
          label: undefined,
          email_followup: undefined,
        };

        localTriggerArray.push(checklistObj);
      } else if (
        trigger.type === ruleType.AND ||
        trigger.type === ruleType.OR
      ) {
        var operationObj = {
          id: uuidv4(),
          type: trigger.type,
          creation: undefined,
          task: undefined,
          label: undefined,
          email_followup: undefined
        };

        localTriggerArray.push(operationObj);
      }
    }
    consoleToLog("**LOCALTRIGGERARRAY**", localTriggerArray);

    for (var a = 0; a < rule.actions.length; a++) {
      var action = rule.actions[a];
      if (action.type === actionType.TASK) {
        var taskAction = {type: action.type, template_task_id: action.template_task_id, status: action.status};
        localActionsArray.push({id: uuidv4(),typeOfAction: action.type, data: taskAction});

      } else if (action.type === actionType.EMAIL_FOLLOWUP) {
        var emailFollowupAction = {type: action.type, email_draft_id: action.email_draft_id, status: action.status, 
          interval: action.interval, max_count: action.max_count, 
          organization_email_id: props.fromPredefinedTemplate ? undefined : action.organization_email_id};
        localActionsArray.push({id: uuidv4(),typeOfAction: action.type, data: emailFollowupAction});

      } else if (action.type === actionType.LABEL) {
        var labelAction = {type: action.type, label_id: action.label_id, status: action.status};
        localActionsArray.push({id: uuidv4(),typeOfAction: action.type, data: labelAction});
      } else if(action.type === actionType.DUE_DATE) {
        var dueDateAction = {type: action.type, interval: action.interval};
        localActionsArray.push({id: uuidv4(),typeOfAction: action.type, data: dueDateAction});
      }
    }
    consoleToLog("localActionsArray",localActionsArray);

    if(localTriggerArray.length > 0){
      setTriggerArray([...localTriggerArray]);
    }
    if(localActionsArray.length > 0){
      setActionArray(localActionsArray);
    }
  };
  
  const addTaskAction = (typeOfAction, taskId, taskStatus, taskName) => {
    var taskData = { type: typeOfAction, template_task_id: taskId, status: taskStatus, taskName: taskName};
    actionArray.push({id: actionArray.length, typeOfAction: typeOfAction, data: taskData });
    setActionArray([...actionArray]);
  };

  const getEmailFollowupActionData = (typeOfAction, email_draft_id, schedulerStatus, emailDraftName, intervalDays, maxCount, organizatioEmailID) => {
    var emailFollowupData = {type: typeOfAction,
        email_draft_id: email_draft_id,
        status: schedulerStatus,
        emailDraftName: emailDraftName,
        interval: schedulerStatus === schedulerType.DISABLED ? 0 : intervalDays,
        max_count: schedulerStatus === schedulerType.DISABLED ? 0 : maxCount,
        organization_email_id: props.fromPredefinedTemplate ? undefined :  organizatioEmailID ? organizatioEmailID : 0
    };
    actionArray.push({id: actionArray.length, typeOfAction: typeOfAction, data: emailFollowupData});
    setActionArray([...actionArray]);
  };

  const getLabelActionData = (typeOfAction, labelId, labelStatus, labelName) => {
    var labelData = {type: typeOfAction, status: labelStatus, label_id: labelId, labelName: labelName};
    actionArray.push({ id: actionArray.length, typeOfAction: typeOfAction, data: labelData});
    setActionArray([...actionArray]);
  };

  const getDueDateActionData = (typeOfAction, intervalDays) => {
    console.log('intervalDAys', intervalDays);
    var dueDateData = {type: typeOfAction, interval: Number(intervalDays)};
    actionArray.push({ id: actionArray.length, typeOfAction: typeOfAction, data: dueDateData});
    setActionArray([...actionArray]);
  };

  const deleteActions = (deleteId) => {
    var localDeleteArray = actionArray.filter(
      (action) => action.id !== deleteId
    );
    setActionArray(localDeleteArray);
  };

  const RulesNotFound = () => {
    const classes = useStyles();
    return (
      <Grid container direction="row" sm={12}
        justifyContent={props.fromChecklistDetails && "center"}
        className={classes.notFoundMain}>
        <Grid
          item
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          sm={12}
          className={classes.notFoundMain}
        >
          <img
            alt="No Attachments Found"
            src="/images/no_attachment_found.svg"
            className={classes.notAttachmentsImage}
          />
        </Grid>
        <Grid
          item
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          sm={12}
          className={classes.notFoundMainText}
        >
          <Typography className={classes.notAttachmentsText}>
            No Automation Found
          </Typography>
        </Grid>
        <Grid
          item
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          className={classes.notFoundMainText}
        >
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.notAttachmentsButton}
            onClick={handleDrawerOpen}
          >
            Add Automator
          </Button>
        </Grid>
      </Grid>
    );
  };

  const RulesList = () => {
    const handleDeleteRule = (id) => {
      var template_id = templateId;
      consoleToLog("handleDeleteRule", template_id, id);
      if (window.confirm("Are you sure you want to delete Automator? ")) {
        deleteRulesApi(template_id, id)
        .then((response) => {
          consoleToLog("deleteRulesApi response: ", response);
          enqueueSnackbar("Automator Deleted Successfully", {
            variant: "success",
          });
          setRulesArray(response.data.template_rules);
        })
        .catch((e) => {
          consoleToLog("deleteRulesApi error", e);
        });
        } 
    };

    const handlepredefinedDeleteRule = (id) => {
      const organization_id = organizationId;
      const template_id = templateId;
      consoleToLog("handlepredefinedDeleteRule", template_id, id);
      if (window.confirm("Are you sure you want to delete Rule? ")) {
        deletepredefinedTemplateRuleApi(organization_id, template_id, id)
        .then((response) => {
          consoleToLog("deletepredefinedTemplateRuleApi response: ", response);
          enqueueSnackbar("Automator Deleted Successfully", {
            variant: "success",
          });
          setRulesArray(response.data.predefined_template_rules);
        })
        .catch((e) => {
          consoleToLog("deletepredefinedTemplateRuleApi error", e);
        });
        } 
    };

    const handleChecklistDeleteRule = (id) => {
      const checklist_id = checklistId;
      if (window.confirm("Are you sure you want to delete Rule? ")) {
        deleteChecklistRulesApi(checklist_id, id)
        .then((response) => {
          consoleToLog("deleteChecklistRulesApi response: ", response);
          enqueueSnackbar("Automator Deleted Successfully", {
            variant: "success",
          });
          setRulesArray(response.data.checklist_rules);
        })
        .catch((e) => {
          consoleToLog("deleteChecklistRulesApi error", e);
        });
        } 
    }

    const onPublishForAllClick = () => {

      updateChecklistRulesApi(templateId)
        .then((response) => {
          const res = response.data;
          consoleToLog("updateChecklistRulesApi response: ", res);
          enqueueSnackbar(res.message, {
            variant: "success",
          });
        })
        .catch((e) => {
          consoleToLog("deleteChecklistRulesApi error", e);
          if(e.response.data && e.response.data.message) {
            enqueueSnackbar(e.response.data.message, {
              variant: "error",
            });
            return;

          }
        });
    }

    return (
      <Grid sm={12} className={classes.rulesFound}>
        <Grid
          container
          style={{ marginTop: "1rem", marginLeft: !props.fromChecklistDetails ? '-30px' : '-8px'}}
          justifyContent="flex-end"
          alignItems="flex-end"
        >
          {!props.fromChecklistDetails &&
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              size="medium"
              onClick={onPublishForAllClick}
              style={{marginRight:'12px'}}
            >
              Publish For All
            </Button>
          </Grid>}
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              onClick={handleDrawerOpen}
            >
              Add Automator
            </Button>
          </Grid>
        </Grid>

        <Grid
          item
          container
          sm={12}
          direction="row"
          className={!props.fromChecklistDetails && "ruleListContainer"}
        >
          <Grid item container sm={12} style={{ marginBottom: "1rem"}}>
            {rulesArray &&
              rulesArray.length > 0 &&
              rulesArray.map((rule) => {
                return (
                  <RuleItem
                    rule={rule}
                    handleDeleteRule={handleDeleteRule}
                    editRule={editRule}
                    handlepredefinedDeleteRule={handlepredefinedDeleteRule}
                    fromPredefinedTemplate={props.fromPredefinedTemplate}
                    fromChecklistDetails={props.fromChecklistDetails}
                    handleChecklistDeleteRule={handleChecklistDeleteRule}
                  />
                );
              })}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid item container sm={12}>
      {rulesLoading ? (
        <Grid sm={12} className={classes.rulesNotFound}>
          <Grid
            container
            className={classes.loaderContainer}
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress />
          </Grid>
        </Grid>
      ) : rulesArray && rulesArray.length > 0 ? (
        <RulesList />
      ) : (
        <RulesNotFound />
      )}

      <Grid item container justifyContent="flex-end" style={{ margin: "10px" }}>
        <Drawer
          open={open}
          onClose={handleDrawerClose}
          anchor="right"
          className="drawerContainerSchedular"
        >
          <AppBar position="static" className={classes.appBar} elevation={0}>
            <Toolbar style={{ minHeight: "40px", padding: "0px 16px" }}>
              <Typography
                variant="body1"
                style={{ fontWeight: "600", marginRight: "auto" }}
              >
                {selectedRule === undefined ? "Add Automator" : "Update Automator"}
              </Typography>
              <IconButton
                style={{ color: "#202020", marginRight: "-16px" }}
                onClick={handleDrawerClose}
              >
                <ClearIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <div>
            <Grid
              container
              sm={11}
              className={classes.whenContainer}
              justifyContent="center"
              direction="row"
            >
              <Grid
                item
                direction="column"
                sm={1}
                className={classes.leftContainer}
              >
                <Grid
                  item
                  container
                  alignItems="center"
                  justifyContent="center"
                >
                  <span className={classes.circleText}>WHEN</span>
                </Grid>
              </Grid>

              <Grid item direction="column" sm={1}>
                <Divider className={classes.schedulerDivider} />
              </Grid>
              <Grid
                item
                direction="column"
                sm={10}
                className={classes.rightContainer}
              >
                <Grid item container sm={12} className={classes.rightContainer}>
                  <Grid item className={classes.radioParent} sm={12}>
                    <div>
                      <Grid item container sm={12} direction="row">
                      {/* {consoleToLog("TriggerComponent render : ", triggerArray, props.fromPredefinedTemplate)} */}
                        {triggerArray &&
                          triggerArray.length > 0 &&
                          triggerArray.map((trigger, index) => {
                            return (
                              <TriggerComponent
                                key={index}
                                trigger={trigger}
                                triggerIndex={index}
                                deleteTrigger={deleteTrigger}
                                taskList={taskList}
                                labelList={labelList}
                                updateTrigger={updateTrigger}
                                triggerArray={triggerArray}
                                deleteIndex={deleteIndex}
                                //handleTriggerButton={handleTriggerButton}
                                handleTriggerButtonAndClearTriggers={
                                  handleTriggerButtonAndClearTriggers
                                }
                                fromPredefinedTemplate={props.fromPredefinedTemplate}
                                emailDraftList={emailDraftListArr}
                                fromChecklistDetails={props.fromChecklistDetails}
                              />
                            );
                          })}
                      </Grid>
                      <Grid
                        item
                        container
                        justifyContent="flex-end"
                        alignItems="flex-end"
                        style={{ marginTop: "1rem" }}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          size="medium"
                          onClick={addTrigger}
                          disabled={addTriggerDisabled}
                        >
                          Add Trigger
                        </Button>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
          {/*Second*/}
          <div>
            <Grid
              container
              sm={11}
              className={classes.actionsContainer}
              justifyContent="center"
              direction="row"
            >
              <Grid
                item
                direction="column"
                sm={1}
                className={classes.actionCircleContainer}
              >
                <Grid
                  item
                  container
                  alignItems="center"
                  justifyContent="center"
                >
                  <span className={classes.circleText2}>ACTION</span>
                </Grid>
              </Grid>
              <Grid item direction="column" sm={1}>
                <Divider className={classes.schedulerDivider1} />
              </Grid>
              <Grid item direction="column" sm={10}>
                <Grid item container sm={12} className={classes.actionBox}>
                  <Grid item className={classes.radioParent} sm={12}>
                    <ActionChange
                      taskList={taskList}
                      addTaskAction={addTaskAction}
                      action={action}
                      fromChecklistDetails={props.fromChecklistDetails}
                    />
                    <ActionEnable
                      getEmailFollowupActionData={getEmailFollowupActionData}
                      action={action}
                      emailDraftList={emailDraftListArr}
                      fromPredefinedTemplate={props.fromPredefinedTemplate}
                      orgEmailList={props.orgEmailList}
                    />
                    <ActionLabel
                      labelList={labelList}
                      getLabelActionData={getLabelActionData}
                      action={action}
                    />
                    <ActionDueDate 
                      getDueDateActionData={getDueDateActionData} 
                      action={action}
                      fromPredefinedTemplate={props.fromPredefinedTemplate}/>
                    <Actions
                      actionArray={actionArray}
                      deleteActions={deleteActions}
                      taskList={taskList}
                      labelList={labelList}
                      emailDraftList={emailDraftListArr}
                      orgEmailList={props.orgEmailList}
                      fromChecklistDetails={props.fromChecklistDetails}
                      
                    />
                  </Grid>
                  <Grid
                    item
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    style={{ marginTop: "1rem" }}
                  >
                    <Button
                      onClick={handleAddRule}
                      variant="contained"
                      color="primary"
                      size="medium"
                    >
                     {loading && <CircularProgress size={24} className={classes.circularProgress}/>} 
                     Save Automator
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      size="medium"
                      style={{ marginLeft: "1rem" }}
                      onClick={handleDrawerClose}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
          {/*Second*/}
        </Drawer>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  labelsList: state.organizationInfo.labels,
  selectedOrganization: state.organizationInfo.selectedOrganization,
  orgEmailList: state.organizationInfo.orgEmailList,
});

const mapDispatchToProps = (dispatch) => ({
  checkTemplateRules: (organinzation_id) => dispatch(checkTemplateRules(organinzation_id))
})

export default connect(mapStateToProps, mapDispatchToProps)(Rules);
