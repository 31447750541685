import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

export const PrivateRoute = ({
    auth,
    component: Component, //renaming it
    ...rest //this will include all props which are not destructured (if we use ...props, it will update above destructured props)
}) => (
    <Route 
        {...rest}  
        render={(props) => (
            (auth && auth.access_token) ? (
                <div>
                    <Component {...props} />
                </div>
            ) : (
                window.open(`${process.env.REACT_APP_AUTH_APP_DOMAIN_NAME}/login?service=workplace`, '_self')
            )
        )} 
    />
);

const mapStateToProps = (state) => ({
    auth: state.auth
}); 

export default connect(mapStateToProps)(PrivateRoute);