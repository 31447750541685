import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {Grid, Container, Typography, Tabs, Tab, Box, AppBar} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import BasicInfo from './BasicInfo';
import ChangePassword from './ChangePassword';
import MyOrganizations from './MyOrganizations';
import CreateOrganizationModal from './modals/CreateOrganizationModal';
import {SelectedDrawerState} from '../actions/selectedOrganization';

const useStyles = makeStyles((theme) => ({
    mainContent : {
        width: (showDrawer) => {
           return showDrawer ? "83%" : "100%"; 
        },
        backgroundColor: theme.palette.primary.light, 
        transition: ".5s", 
        flexGrow: 1,
        //overflow:'hidden',
        overflow:'auto',
        overflowX:'hidden'
        },
    root: {
        marginTop:'24px'
        },    
    tabsAppbar: {
        boxShadow: "none",
        backgroundColor: "white",
        borderBottom: "1px solid #c5b9b9",
        zIndex:10
        },    
    container: {
        marginTop: "8px"
        },
    tab: {
        flexGrow: 1,
        },  
    tabPanelContainer: {
        flexGrow: 1,
        padding:'15px',
        background: 'white',
        //height:'100vh',
        marginBottom:'25px',
    },       

}))

const MyProfile = (props) => {
    const {showDrawer} = props;
    const classes = useStyles(showDrawer);

    const [tabValue, setTabValue] = useState(0);
    const [openDrawer, setOpenDrawer] = useState(false);

    const handleDrawerOpen = () => {
        setOpenDrawer(true);
    }

    const handleDrawerClose = () => {
        setOpenDrawer(false);
    }

    useEffect(() => {
        document.title = `Profile - ${props.fullName}`
    }, []);

    useEffect(() => {
        if(props.setDrawerState === SelectedDrawerState.SHOW_MY_PROFILE) {
            setTabValue(0);
        }

        if(props.setDrawerState === SelectedDrawerState.SHOW_MY_ORGS) {
            setTabValue(2);
        }

    }, [props.setDrawerState]);

    const TabPanel = (props) => {
        const { children, value, index, ...rest } = props;
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...rest}
          >
            {value === index && (
              <Box p={0}>
                <Typography component={'span'}>{children}</Typography>
              </Box>
            )}
          </div>
        );
      };

    const a11yProps = (index) => {
        return {
          id: `full-width-tab-${index}`,
          'aria-controls': `full-width-tabpanel-${index}`,
        };
      }

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
      };
    
    const handleChangeIndex = (index) => {
    setTabValue(index);
    };

    return (
        <div className={classes.mainContent} 
            style={{height: props.height}}>
            <Container className={classes.container} maxWidth="xl">
                <Typography component={'span'} style={{fontSize:'20px', fontWeight:'600'}} color="primary">
                    Profile
                </Typography>

                <Grid item container direction="column" className={classes.root}>
                        <AppBar position="static" color="default" className={classes.tabsAppbar}>
                            <Tabs
                                value={tabValue}
                                onChange={handleTabChange}
                                indicatorColor="primary"
                                textColor="primary"
                                aria-label="full width tabs example"
                            >
                                <Tab label="Basic Info" {...a11yProps(0)} />
                                <Tab label="Change Password" {...a11yProps(1)} />
                                <Tab label="Organizations" {...a11yProps(2)} />
                            </Tabs>
                        </AppBar>
                        <SwipeableViews
                            index={tabValue}
                            onChangeIndex={handleChangeIndex}
                        >
                            <TabPanel value={tabValue} index={0}>
                                <BasicInfo/>
                            </TabPanel>
                            <TabPanel value={tabValue} index={1}>
                                <ChangePassword />
                            </TabPanel>
                            <TabPanel value={tabValue} index={2}>
                                <MyOrganizations handleDrawerOpen={handleDrawerOpen}/>
                            </TabPanel>
                        </SwipeableViews>
                 </Grid>
            </Container>  

            <CreateOrganizationModal openDrawer={openDrawer}
                handleDrawerClose={handleDrawerClose}
            />  
        </div>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    setDrawerState: state.organizationInfo.setDrawerState
})

export default connect(mapStateToProps)(MyProfile);