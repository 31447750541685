import React, {useState} from 'react';
import {Dialog, Grid, Button, Typography, DialogContent, TextField, FormControlLabel, Checkbox, Tooltip, CircularProgress} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import { connect } from 'react-redux';
import AddIcon from '@material-ui/icons/Add';
import ChecklistMembersFilter from './filters/ChecklistMembersFilter';
import { uploadChecklistAttachmentApi, getUploadUrlForChecklistAttachmentApi } from "../services/ChecklistService";
import axios from 'axios';
import { useSnackbar } from 'notistack';
import {sendForApprovalApi} from '../services/ChecklistService';
import { ApprovalTypes, consoleToLog } from '../util/AppUtil';
import SelectFromApproverList from './checklist/SelectFromApproverList';
import { useEffect } from 'react';
import { useIsMount } from './useIsMount';

const useStyles = makeStyles((theme) => ({
    dialogContainer: {
        padding:'16px'
    },
    closeDialogButton: {
        minWidth:'25px',
        padding:'6px 8px',
    },
    dialogHeading: {
        fontSize:'16px',
        fontWeight: 600
    },
    customMarginBottom: {
        marginBottom:'-6px'
    },
    customMarginTop: {
        marginTop:'16px'
    },
    attachLink : {
        color:'#3eb6e7',
        fontWeight:'600',
        cursor:'pointer',
        textDecoration:"none !important",
        fontSize:'18px',
        display:'flex',
        alignItems:'flex-start',
        '&:hover': {
            color:'#20a8e0'
        }
    },
    attachmentFilename: {
        marginTop:'-2px', 
        marginRight:'6px',
        maxWidth:'20ch',
        overflow:'hidden',
        whiteSpace:'nowrap',
        textOverflow:'ellipsis'
    },
    attachmentListStyle: {
        display:'flex',
        alignItems:'center', 
        padding:'8px', 
        backgroundColor:'#f9f9f9', 
        border:'1px solid #ececec', 
        borderRadius:'5px', 
        marginRight:'12px',
        marginBottom:'12px'
    },
    customMarginTop1: {
        marginTop:'20px'
    },
}))

const SendForReviewDialog = (props) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    const isMount = useIsMount();

    const [reasonText, setReasonText] = useState('');
    const [approverList, setApprovalList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [attachment, setAttachment] = useState([]);
    const checklistId = props.approvalObj ? props.approvalObj.target_id : props.selectedChecklistItem?.id;
    const taskId = props.approvalObj ? props.approvalObj.target_id : props.taskObj?.id;

    useEffect(() => {
        if(props.selectedChecklistItem && !props.taskObj){ 
            setApprovalList(props.selectedChecklistItem?.checklist_approvers_list)
        }
    }, []);

    const onAttachmentSelected = (e) => {
        const { target: { files } } = e;
        //consoleToLog('attachment array: ', Array.from(files));
        const fileArr = Array.from(files);
        fileArr && fileArr.length > 0 &&
        fileArr.map((fileObj) => setAttachment(oldAttachment => [...oldAttachment, fileObj]))
    }

    const getAssigneesOfApprover = () => {
        if(props.approvalObj) {
            let obj = {
                user: props.approvalObj && props.approvalObj.approver_user && props.approvalObj.approver_user
            }
            return (props.approvalObj && props.approvalObj.approver_user) ? [obj] : [];
        } 
        if(props.selectedChecklistItem && !props.fromChecklistDetailsComponent) {
            return props.selectedChecklistItem?.checklist_approvers_list
        }
    }

    const showAttachment = (file, index) => {
        return (
            <div className={classes.attachmentListStyle} key={index}>
                    <i className="flaticon-attachment" style={{marginRight:'3px'}}></i>
                    <Tooltip title={file.name} arrow>
                        <Typography  variant="body1" className={classes.attachmentFilename} style={{marginTop:'-2px', marginRight:'3px'}}>
                            {file.name}
                        </Typography>
                    </Tooltip>
                    <ClearIcon fontSize="small"
                            onClick={(e) => onRemoveAttachment(e, file)} 
                            style={{cursor:'pointer', fontSize:'16px'}}/>
            </div>
            );
    }

    const onRemoveAttachment = (e, file) => {
        e.stopPropagation();
        let updateAttachment= attachment.filter((fileItem) => fileItem.name !== file.name);
        setAttachment(updateAttachment);
    }


    const onSaveClick = () => {
        const organization_id = props.selectedOrganization.organization.id;
        const template_slug = props.selectedTemplate?.name !== 'All' ? props.selectedTemplate?.slug : '';
        const subtitle = props.selectedChecklistItem?.subtitle;
        const organization_slug = props.selectedOrganization.organization?.slug;
        const auto_generated_id = props.selectedChecklistItem?.company.auto_generated_id;
        
        const type = 'approval';
        const approval_id = props.approvalObj ? props.approvalObj.target_id : props.selectedChecklistItem && !props.taskObj ? checklistId : taskId;
        const text = reasonText;

        const approvers = props.approvalObj ? getAssigneesOfApprover().map((approver) => approver.user.id).join(',') :
        approverList && approverList.length > 0 ? approverList.map((approver) => approver.user.id).join(',') : [];
        if(!props.approvalObj && approverList && approverList.length === 0) {
            enqueueSnackbar('Please select approvers', {variant:'error'});
            return;
        }

        const fileObj = attachment && attachment.length > 0 ? attachment[0] : undefined;

        setLoading(true);
        if(attachment && attachment.length > 0) {
            callUploadApi(organization_id, fileObj, type, auto_generated_id, template_slug, subtitle, organization_slug, approval_id, text, approvers)
        } else {
            callSendForApprovalApi(organization_id, text, approvers);
        }
    }

    const callUploadApi = (organization_id, fileObj, type, auto_generated_id, template_slug, subtitle, organization_slug, approval_id, text, approvers) => {
        getUploadUrlForChecklistAttachmentApi(organization_id, fileObj.name, type, auto_generated_id, template_slug, subtitle, organization_slug, approval_id)
        .then((response) => {
            const res = response.data;
            consoleToLog('Response getUploadUrlForChecklistAttachmentApi: ', res);

            // Put the fileType in the headers for the upload
            const options = {
            headers: {
                "Content-Type": fileObj.type,
            }
            };
            axios.defaults.headers.common = {};
            axios.put(res.upload_signed_url, fileObj, options)
            .then(result => {
                consoleToLog('signed Request s3', result);
                console.log("Response from s3");
                updateCheckListFileUploadUrl(organization_id, type, '', res.url, text, approvers, approval_id);
            })
            .catch(error => {
                setLoading(false);
                consoleToLog('error: ', error.response);
            })

        })
        .catch((err) => {
            consoleToLog("File Upload Error: ", err);
            setLoading(false);
        });
    }

    const updateCheckListFileUploadUrl = (organization_id, type, title, path, text, approvers, approval_id) => {
        const user_id = props.auth.user.id;
        const client_id = props.selectedChecklistItem?.company.id;
    
        uploadChecklistAttachmentApi(organization_id, type, title, path, approval_id, user_id, client_id)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response uploadChecklistAttachmentApi: ", res);
        
                callSendForApprovalApi(organization_id, text, approvers);
            })
            .catch((e) => {
                consoleToLog("uploadChecklistAttachmentApi error", e);
                setLoading(false);
            });
    };

    const callSendForApprovalApi = (organization_id, text, approvers) => {
        let apiStr = props.selectedChecklistItem && !props.taskObj || props.approvalObj?.target_type === ApprovalTypes.CHECKLIST ? `checklist/${checklistId}` : `task/${taskId}`;
        const client_id = props.selectedChecklistItem?.company?.id;

        sendForApprovalApi(organization_id, client_id, text, approvers, apiStr)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response sendForApprovalApi ', res);
                setLoading(false);

                enqueueSnackbar(res.message, {variant:'success'});

                props.fromChecklistDetailsComponent ?
                props.checklistTaskUpdateFromApproval(res?.checklist_task, res?.checklist, props.callGetApiFromReviewModal ? true : false)
                :
                props.updateChecklistFromReview(res.checklist);
                props.approvalObj && props.setUpdateApprovalList(res.approval_object);
                props.handleCloseSendForReviewDialog();
            })
            .catch((e) => {
                consoleToLog('Error sendForApprovalApi: ', e);
                setLoading(false);
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {variant:'error'});
                    return;
                }
            })


    }

    return (
        <Dialog 
            fullScreen={false}
            scroll="paper"
            open={props.openSendForReviewDialog}
            onClose={props.handleCloseSendForReviewDialog}
            maxWidth="md"
            fullWidth>
                <DialogContent dividers style={{padding: '0px 0px 16px 0px', overflowX:'hidden'}}>
                        <Grid item sm={12}
                            style={{padding:'4px 16px', background:'#f2f6fc'}}>

                            <Grid item container justifyContent='space-between'>
                                <Grid item sm={11}>
                                    <Typography className={classes.dialogHeading}>
                                        Send For Review
                                    </Typography>
                                </Grid>

                                <Grid item sm={1} style={{flexBasis:'2.8%', maxWidth:'2.8%'}}>
                                    <Button onClick={props.handleCloseSendForReviewDialog}
                                        className={classes.closeDialogButton}> 
                                            <ClearIcon fontSize='small'/>
                                    </Button>
                                </Grid>
                            </Grid>

                        </Grid>

                        <Grid item container direction='column' className={classes.dialogContainer}>


                            <Grid item md={12} style={{margin:'0px 0px 16px 0px'}}>
                                <Typography variant='subtitle1' style={{marginBottom:'6px'}}>
                                    Approvers
                                </Typography>

                                <SelectFromApproverList fromSendForReviewModal={true}
                                    setApprovalList={setApprovalList}
                                    isShowOnly={true}
                                    approvalObj={props.approvalObj}
                                    assignees={getAssigneesOfApprover()}
                                    disabledAssignChecklistApprovers={typeof(props.approvalObj) === 'object'}
                                    />
                            </Grid>

                            <Grid item md={12}>
                                <Typography variant='subtitle1' className={classes.customMarginBottom}>
                                    Reason (Optional)
                                </Typography>
                                <TextField fullWidth
                                    variant='outlined'
                                    margin='dense'
                                    placeholder='Enter Reason'
                                    value={reasonText}
                                    onChange={(e) => setReasonText(e.target.value)}
                                    multiline
                                    maxRows={3}/>
                            </Grid>

                            <Grid item md={12} className={classes.customMarginTop1}>
                                {attachment && attachment.length === 0 &&
                                <label style={{display:'flex', alignItems:'center'}}>
                                    <Typography className={classes.attachLink}>
                                        <AddIcon style={{marginRight:'4px'}}/> 
                                        Attach File 
                                    </Typography>
                                    <Typography variant='subtitle1' style={{marginLeft:'6px'}}>
                                        (Optional)
                                    </Typography>
                                    <input style={{display:'none'}}
                                        type="file"
                                        id="approval_attachment"
                                        onChange={onAttachmentSelected}
                                /> 
                                </label>}

                                <Grid item container alignItems='center' style={{marginTop:'8px'}}>
                                {
                                    attachment && attachment.length > 0 &&
                                    attachment.map((file, index) => {
                                        return showAttachment(file, index);
                                    })
                                }
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item md={12} className={classes.customMarginTop}>
                            <Grid item container justifyContent='center' spacing={1}>
                                <Grid item>
                                    <Button 
                                        onClick={onSaveClick}
                                        variant='contained' color='primary'>
                                        {loading && <CircularProgress size={20} style={{marginRight:'8px', color:'#fff'}}/>}
                                        Send
                                    </Button>
                                </Grid>

                                <Grid item>
                                    <Button 
                                        onClick={props.handleCloseSendForReviewDialog}
                                        variant='outlined' color='primary'>
                                        Cancel
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        
                </DialogContent>
        </Dialog>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    selectedTemplate: state.organizationInfo.selectedTemplate
})

export default connect(mapStateToProps)(SendForReviewDialog);