import React, {useEffect, useState} from 'react';
import {Grid, Typography, Button, Avatar, Popover, MenuItem} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import AddNewCompanyModal from '../modals/AddNewCompanyModal';
import CreateNewCompanyModal from '../modals/CreateNewCompanyModal';
import {capitalize, consoleToLog, trackGAEvent, shouldDisable, AccessLevel} from '../../util/AppUtil';
import {getClientsListApi} from '../../services/clientService';
import {connect} from 'react-redux';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import SetupCompanyModal from '../modals/SetupCompanyModal';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { useIsMount } from '../useIsMount';
import { useSnackbar } from 'notistack';
import OnboardingCreateNewCompany from './OnboardingCreateNewCompany';

const useStyles = makeStyles((theme) => ({
    connectIcons: {
        width:'20px',
        marginLeft:'auto'
    },
    avatarStyle: {
        width:"38px !important",
        height:"38px !important",
        fontSize:'16px !important',
        fontWeight:'600 !important',
        transform: 'translateY(3px)'
    },
    clientText: {
        transform: 'translateY(1.5px)'
    },
    clientsContainerStyle: {
        height:'170px', 
        overflowY:'auto', 
        overflowX:'hidden',
        "&::-webkit-scrollbar": {
            display: "none"
        },
        [theme.breakpoints.down(1430)] : {
            height:'70px', 
        }
    },
    clientColumn : {
        background: '#F9F9F9',
        borderRadius: '5px',
        marginBottom: '8px',
        padding:'8px 16px 8px 8px',
        '&:last-child' : {
            marginBottom: '0px'
        },
        "& .deleteIcon" : {
            visibility: 'hidden'
        },
        "&:hover .deleteIcon" : {
            visibility: 'visible'
        }
    },
    iconAttach: {
        marginLeft:'6px', 
        fontSize:'0.8rem', 
        height:'20px', 
        color:'#222'
    },
}));

const OnboardingClients = (props) => {
    const classes = useStyles();
    const isMount = useIsMount();
    const {enqueueSnackbar} = useSnackbar();

    const [companyName, setCompanyName] = useState('');
    
    const [openAddCompanyDrawer, setOpenAddCompanyDrawer] = useState(false);
    const [openCreateCompanyDrawer, setOpenCreateCompanyDrawer] = useState(false);
    const [clearCompanyDrawer, setClearCompanyDrawer] = useState(false);
    const [openCompanySetupDrawer, setOpenCompanySetupDrawer] = useState(false);

    const [clientList, setClientList] = useState([]);
    const [page, setPage] = useState(0);
    const [nextPage, setNextPage] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isMoreLoading, setIsMoreLoading] = useState(false);

    useEffect(() => {
        fetchClientList();
    }, []);


    const handleDrawerOpen = () => { //Add New Company Drawer Open
        if(props.templates && props.templates.length === 0) {
            enqueueSnackbar('Please add work Templates to add client',{variant:'error'});
            props.setTabValue(3);
        } else {
            setOpenAddCompanyDrawer(true);
        }
    }

    const handleDrawerClose = () => { //Add New Company Drawer Close
        setOpenAddCompanyDrawer(false);
    }

    const handleOpenCreateCompanyDrawer = () => { //Create Company Drawer Open
        setOpenCreateCompanyDrawer(true);
    }

    const handleCloseCreateCompanyDrawer = () => { //Create Company Drawer Close
        setOpenCreateCompanyDrawer(false);
    }

    const handleOpenCompanySetupDrawer = () => {// open company setup
        setOpenCompanySetupDrawer(true);
    }

    const handleCloseCompanySetupDrawer = () => {// close company setup
        setOpenCompanySetupDrawer(false);
    }

    const addCompanyWithoutChecklists = (company) => {
        console.log('company', company);
        setClientList([company, ...clientList]);
    }

    const fetchClientList = (query) => {
        const organization_id = props.selectedOrganization.organization.id;

        if(!isMoreLoading)  setLoading(true);
        getClientsListApi(organization_id, page, query)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response getClientsListApi: ', res);
                setLoading(false);

                if(isMoreLoading) {
                    const newItems = clientList.concat(res.companies);
                    setClientList(newItems);
                    setPage(page + 1);
                    setNextPage(res.next_page);
                    setIsMoreLoading(!isMoreLoading);
                } else {
                    setNextPage(res.next_page);
                    if (res.next_page) setPage(page + 1);
                    setClientList(res.companies);
                }
            })
            .catch((e) => {
                setLoading(false);
                consoleToLog('Error getClientsListApi: ', e.response);
                //show401ErrorAndClearRedux(props, e, enqueueSnackbar);
            })
    }

    const showClientsList = () => {
        return (
                <Grid item container className={classes.clientsContainerStyle}>
                    <Grid item container direction="column">
                        {clientList.map((client) => {
                            const compArr = client.name.split(" ");
                            let compStr1 = compArr[0];
                            let compStr2 = compArr[1] ? compArr[1] : '';
                            const finalStr = compStr1?.charAt(0).toUpperCase() + compStr2?.charAt(0).toUpperCase();
                            const clientEmails = client.email && client.email.length > 0 ? client.email.split(',') : [];
                            return <Grid item container 
                                        key={client.id} 
                                        justifyContent='space-between'
                                        className={classes.clientColumn}>

                                        <Grid item md={12} lg={12}>
                                            <Grid item container alignItems="center">
                                                <Grid item>
                                                    <Avatar className={classes.avatarStyle}>
                                                        {finalStr}   
                                                    </Avatar>
                                                </Grid>
                                                <Grid item className={classes.clientText}>
                                                    <div style={{marginLeft: "16px"}}>
                                                        <Typography variant='body1'>
                                                            {client.name} 
                                                        </Typography>
                                                        <Typography variant="subtitle1" style={{color:'#555555'}}>
                                                            {clientEmails}
                                                        </Typography>
                                                    </div>
                                                </Grid>
                                            </Grid>                                                
                                        </Grid>
                                        {/* {member.user.id === memberId && showMoreActionsPopover(member, fullName)} */}
                                    </Grid>
                            })
                        }   
                    </Grid>
                </Grid>
        )
    }

    return (
        <Grid item md={12}>
            {clientList && clientList.length > 0 && showClientsList()}
            <Grid item container justifyContent='center' alignItems='center' spacing={1} 
                style={{marginTop:"16px"}}>
                <Grid item md={5}>
                    <Button variant='outlined' color='primary' fullWidth
                        onClick={handleDrawerOpen}>
                        Add Client
                        <AddCircleOutlineIcon className={classes.connectIcons} />
                    </Button>
                </Grid>
                <Grid item md={5}>
                    <Button variant='outlined' color='primary' fullWidth
                        onClick={handleOpenCompanySetupDrawer}>
                        Add Bulk Clients
                        <AttachFileIcon className={classes.connectIcons} />
                    </Button>
                </Grid>
            </Grid>
            
            <AddNewCompanyModal openAddCompanyDrawer={openAddCompanyDrawer}
                handleDrawerClose={handleDrawerClose}
                handleOpenCreateCompanyDrawer={handleOpenCreateCompanyDrawer}
                setOpenAddCompanyDrawer={setOpenAddCompanyDrawer}
                setCompanyName={setCompanyName}
                fromOnboardingComponent={true}
            />

            <OnboardingCreateNewCompany companyName={companyName} 
                openCreateCompanyDrawer={openCreateCompanyDrawer}
                handleCloseCreateCompanyDrawer={handleCloseCreateCompanyDrawer}
                addCompany={addCompanyWithoutChecklists}
                clearCompanyDrawer={clearCompanyDrawer}
                setClearCompanyDrawer={setClearCompanyDrawer}
                fromOnboardingComponent={true}

            />

            <SetupCompanyModal openCompanySetupDrawer={openCompanySetupDrawer}
                handleCloseCompanySetupDrawer={handleCloseCompanySetupDrawer}
                clientList={clientList}
                fromOnboardingComponent={true}
            />


        </Grid>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    overlay: state.overlay,
    templates: state.organizationInfo.templates
});

export default connect(mapStateToProps)(OnboardingClients);