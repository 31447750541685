import React, {useState, Fragment, useEffect} from 'react';
import {TextField, Grid, Avatar, Chip} from '@material-ui/core'; 
import {connect} from 'react-redux';
import {consoleToLog} from '../util/AppUtil';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ClearIcon from '@material-ui/icons/Clear';
import { useIsMount } from './useIsMount';

const useStyles = makeStyles((theme) => ({
    avatar : {
        width: '15px !important',
        height: '15px !important',
        fontSize: '12px !important',
        background: 'none !important',
        color: 'rgba(0, 0, 0, 0.87) !important',
        border: '1px solid rgba(0, 0, 0, 0.26) !important'
        },

    chip : {
        borderRadius: '4px !important',
        backgroundColor: '#f9f9f9 !important',
        border: '1px solid #ececec !important',
        },

    avatarStyle: {
        width: 20,
        height: 20,
        fontSize: 12
        },
        autoStyle: {
        "&::-webkit-scrollbar": {
            display: "none"
        }
    },
    MuiAutocompleteinputRoot : {
        '& .MuiOutlinedInput-root' : {
        padding:'1px',
        borderRadius: '5px',
        }
    },
    customTextField: {
        "& input::placeholder": {
          fontSize: "13px",
        }
      }
    }
    
));

const AutocompleteTemplate = (props) => {
    const classes = useStyles();
    const isMount = useIsMount(); 
    const [assignedTemplates, setAssignedTemplates] = useState([]);
    const [templates, setTemplates] = useState([])
    useEffect(() => {
        var localArray = [];
        // if(!props.singleTemplateSelect) localArray.push({template_id: -1, name: "All"});
        // localArray.push(...props.templates);
        // setTemplates(localArray);

        // if(!props.singleTemplateSelect) {
        //     let updatedArray = props.templates.filter((template) => template.template_id !== -1);
        //     console.log('updaet********', updatedArray);
        //     setTemplates(updatedArray);
        // }

        props.selectedTemplates && props.selectedTemplates.length > 0 && !props.singleTemplateSelect && setAssignedTemplates(props.selectedTemplates);
        props.singleTemplateSelect && props.selectedTemplates && setAssignedTemplates(props.selectedTemplates);
    }, []);

    useEffect(() => {
        if(!isMount) {
            consoleToLog("Autocomplete Templates- On Clear click: in useeffect");
            props.clearFilter && setAssignedTemplates([]);
        }
    }, [props.clearFilter])

    useEffect(() => {
        if(!isMount) {
            consoleToLog("Autocomplete Templates- on template change: in useeffect", assignedTemplates, props.clearFilter);
            if(props.clearFilter) {
                props.onSelectedTemplates(props.singleTemplateSelect ? undefined : assignedTemplates);
            } else {
                props.onSelectedTemplates(assignedTemplates);
            }
        }
    }, [assignedTemplates])

    const onDeleteMember = (option) => {
        if(assignedTemplates){ 
            setAssignedTemplates(assignedTemplates.filter(entry => entry.template_id !== option.template_id))
            props.onUpdateClearFilterValue();
        }
    }

    const renderOptions = (option) => {
        return (
            <div style={{display: "flex", width: "100%"}} 
                className={classes.autoStyle} 
            >
                <Avatar style={{marginRight: "6px", marginTop: "4px"}}
                    classes={{
                        root: classes.avatar
                    }}
                >
                    {option.name.charAt(0)}
                </Avatar>
                {option.name}
            </div>
            );
    }
    
    const renderTags = (options) => {
        return (
            options.map(option =>
                <Fragment key={option.template_id}>
                <Chip
                    classes={{
                        root: classes.chip
                    }}
                    avatar={<Avatar classes={{
                        root: classes.avatar
                    }}>{option.name.charAt(0)}</Avatar>}
                    label={option.name}
                    onDelete={() => onDeleteMember(option)}
                    variant="outlined"
                    style={{margin: "2px 2px"}}
                    deleteIcon={<ClearIcon  style={{width: "18px"}}/>}
                />
                </Fragment>))
        }

    return (
            <Autocomplete
                multiple={!props.singleTemplateSelect ? true : false}
                style={{ width: '100%', marginTop:'8px', background: props.fromMobileComponent && '#fff' }}
                id="size-small-outlined-multi"
                size="small"
                options={!props.singleTemplateSelect ? props.templates?.filter((template) => template.name !== 'All') : props.templates}
                getOptionLabel={(option) => option ? option.name : ""}
                disableClearable
                renderOption={(option) => renderOptions(option)}
                value={assignedTemplates}
                getOptionSelected={(option, value) => option.template_id === value.template_id}
                onChange={(e, newValue) => {
                    props.onUpdateClearFilterValue();
                    setAssignedTemplates(newValue);  
                }}
                classes={{
                    root: classes.MuiAutocompleteinputRoot
                }}
                renderTags={(options) => renderTags(options)}
                renderInput={(params) => (
                    <TextField {...params} variant="outlined" 
                        placeholder="Select Templates" 
                        fullWidth
                        classes={{
                            root: classes.customTextField
                        }}
                    />
                )}
            />
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    templates: state.organizationInfo.templates
});

export default connect(mapStateToProps)(AutocompleteTemplate);