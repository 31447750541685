export const logoutOrgs = () => ({
    type: 'LOGOUT_ORGS'
})

//set organizations list 
export const setOrganizations = (organizations) => ({
    type: 'SET_ORGANIZATIONS',
    organizations
});

//add new organization
export const addOrganization = (organization) => ({
    type: 'ADD_ORGANIZATION',
    organization
});

//update a organization
export const updateOrganization = (organization) => ({
    type: 'UPDATE_ORGANIZATION',
    organization
});
