import React, {useState, useRef, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import { Grid, Typography, Button, Popover , Menu, Divider} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {history} from '../routers/AppRouter';
import {deleteFolderApi} from '../services/authService';
import {connect} from 'react-redux';
import { consoleToLog, getStatusColor, getStatusNameByValue, trackGAEvent } from '../util/AppUtil';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';
import { useSnackbar } from 'notistack';
import {useIsMount} from './useIsMount';
import OutsideClick from './OutsideClick';

const useStyles = makeStyles((theme) => ({
    folderborder : {
        border: '1px solid #202020',
        borderRadius: '5px',
        padding: '6px 10px',
        cursor: 'pointer'
    },
    folderhover : {
        position:'relative',
        cursor:'pointer',
        '& .folderstatus' :{
            display: 'none',
            position: 'absolute',
            top:0,
            left: 0,
            padding: 0,
            },
        '&:hover .folderstatus' : {
            display: 'block',
            position:'absolute',
            top:'1.4rem',
            left:'5px',
            zIndex:10,
            overflow:'visible'
            },
                
        '& .listmainstyle ': {
            boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.05)',
            backgroundColor: '#fff',
            border: '1px solid rgba(0, 0, 0, 0.12)',
            borderRadius: '5px',
            width: 'fit-content',
            height: 'auto',
            overflow: 'hidden',
            listStyle: 'none',
            padding:'0'
            },
        '& .liststyle' : {
            display: 'block',
            color:'#202020',
            textAlign: 'left',
            fontSize: "14px",
            width:'180px',
            height: 'auto',
            padding: '8px 10px',
            }      
        },
        foldericon : {
            fontSize: "18px",
            minWidth:"40px",
            lineHeight: "18px",
            padding:"4px 0px",
            border:"1px solid #202020",
            color: '#202020'
        },
        statuslist : {
            float:'right', 
            color:'#888888',
            marginTop:'-20px'
        },
        folderName : {
            marginLeft: '4px',
        }, 
        popover: {
            pointerEvents: 'none',
            marginLeft: "-150px"
        },
        paper: {
            padding: theme.spacing(1),
            boxShadow: "none",
            border: "1px solid rgba(0, 0, 0, 0.12)",
        },
        listmainstyle1 : {
            width: 'fit-content',
            height: 'auto',
            overflow: 'hidden',
            listStyle: 'none',
            padding:'0',
            margin: "0"
        },
        liststyle1: {
            display: 'block',
            color:'#202020',
            textAlign: 'left',
            fontSize: "14px",
            width:'180px',
            height: 'auto',
            padding: '8px 10px',
            }
    })
);

const ChecklistFoldersComponent = (props) => {
    const [anchorEl, setAnchorEl] = useState(false);
    const [anchorE2, setAnchorE2] = useState(null);
    const { enqueueSnackbar } = useSnackbar();
    
    const [renderArr, setRenderArray] = useState(props.checklistGroup);
    const [dropdownArray, setDropdownArray] = useState([]);
    const [dummyRenderArr, setDummyRenderArr] = useState([]);
    const [folderId, setFolderId] = useState();

    const isMount = useIsMount();
    const handleClose = () => {
        setAnchorEl(false);
    };

    const handlePopoverOpen = (event, folder) => {
        setFolderId(folder.id)
        /* let folder_status = folder.checklist_group_status ? folder.checklist_group_status : [];

        folder_status.length > 0 &&  */setAnchorE2(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorE2(null);
    };

    const wrapperRef = useRef();
    OutsideClick(wrapperRef, () => {
        setAnchorEl(false);
      });

    /* ************************* */
    useEffect(()=>{
        //consoleToLog("in useeffect", renderArr);
        //consoleToLog("screenwidth: ", document.getElementById('vikas') && document.getElementById('vikas').clientWidth);
        calculateFolderWidthAndDisplay(renderArr);
    }, []);


    useEffect(()=>{
        if(isMount) {

        } else {
            consoleToLog('In useEffect when checklist group updated', props.checklistGroup);
            setRenderArray(props.checklistGroup);
            setDummyRenderArr(props.checklistGroup);
            //calculateFolderWidthAndDisplay(props.checklistGroup);
        }
    }, [props.checklistGroup]);

    useEffect(() => {
        if(isMount) {

        } else {
            consoleToLog('In useEffect dummyArr', dummyRenderArr);
            calculateFolderWidthAndDisplay(dummyRenderArr);
        }
    }, [dummyRenderArr]);

    useEffect(() => {
        if(isMount) {

        } else {
            if(dropdownArray.length === 0) {
                setAnchorEl(false)
            }
        }
    }, [dropdownArray]);


    const calculateFolderWidthAndDisplay = (updatedArr) => {
        //consoleToLog('After props.checklistGroup change', updatedArr)
        const screenwidth = document.getElementById('vikas') && document.getElementById('vikas').clientWidth;
        var calcWidth = 0;
        var localArray = [];
        var localArray2 = [];
        for (var i=0; i< updatedArr.length;i++){
        //consoleToLog("element width: "+renderArr[i].group_name+" =>", document.getElementById(i+"_"+renderArr[i].group_name) && document.getElementById(i+"_"+renderArr[i].group_name).clientWidth);
        //consoleToLog("updated arra =>  ", updatedArr[i].group_name);
        calcWidth = calcWidth + parseInt(document.getElementById(i+"_"+updatedArr[i].group_name).clientWidth);
        //consoleToLog("after calc wi: ", calcWidth);
        if(calcWidth < (screenwidth-100)){
           localArray.push(updatedArr[i]);
        }
        else {
            localArray2.push(updatedArr[i])
        }
        }
         setRenderArray(localArray);
         setDropdownArray(localArray2);

        //consoleToLog("***1: ", localArray)
        //consoleToLog("***2: ", localArray2)
    }

    /* *********************** */
    const onDeleteOptionClick = (e, groupId) => {
        e.preventDefault();
        consoleToLog(e, groupId);
        const organization_id = props.selectedOrganization.organization.id;
        const template_id = props.selectedTemplate.template_id;
        const group_id = groupId;

        deleteFolderApi(group_id, organization_id, template_id) 
            .then((response) => {
                const res = response.data;
                consoleToLog('Response deleteFolderApi', res);
                enqueueSnackbar(res.message, {
                    variant: 'success'
                });
                props.onDeleteFolder(groupId);
                trackGAEvent(props.selectedOrganization.organization.name, 'Checklist delete Folder', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
            })
            .catch((e) => {
                consoleToLog('Error deleteChecklistGroupApi', e);
                if (e.response.data && e.response.data.message) {
                    return false;
                }
            })
      }

    const open = Boolean(anchorE2);

     const folderStatus = (group) => {
           let group_status =  group.checklist_group_status ? group.checklist_group_status : [];
           let checklist_group_status_data =  group.checklist_group_status_data ? group.checklist_group_status_data.filter(x => x.status !== "over_due") : [];
           let checklist_group_overdue_status_data =  group.checklist_group_status_data ? group.checklist_group_status_data.filter(x => x.status === "over_due") : [];
          
           // consoleToLog("group status", group_status);
         return (
            <div className="folderstatus">
                    <ul className="listmainstyle">
                     {(group_status && group_status.length > 0)  && group_status.map((option) => {
                         return <li className="liststyle" key={option.label_name}>
                                    <span> <Typography style={{whiteSpace: "nowrap",
                                                    maxWidth: "150px", 
                                                    overflow: "hidden", 
                                                    fontSize:'14px',
                                                    color:`${option.label_color}`,
                                                    textOverflow: "ellipsis"}}>{option.label_name}
                                            </Typography>
                                        <span className={classes.statuslist}>{option.label_count}</span> 
                                    </span>
                                </li>
                     })}
                     {group_status && group_status.length > 0  && <Divider style={{margin: "8px 0px"}}></Divider>}
                     {checklist_group_status_data.map((option) => {
                        return <li className="liststyle" key={option.status}>
                                   <span> <Typography style={{whiteSpace: "nowrap",
                                                   maxWidth: "150px", 
                                                   overflow: "hidden", 
                                                   fontSize:'14px',
                                                   color:`${getStatusColor(option.status)}`,
                                                   textOverflow: "ellipsis"}}>{getStatusNameByValue(option.status)}
                                           </Typography>
                                       <span className={classes.statuslist}>{option.count}</span> 
                                   </span>
                               </li>
                    })}
                    {group_status && group_status.length >0 && <Divider style={{margin: "8px 0px"}}></Divider>}
                    {checklist_group_overdue_status_data && checklist_group_overdue_status_data.length >0 && checklist_group_overdue_status_data.map((option) => {
                        return <li className="liststyle" key={option.status} style={{margin: group_status && group_status.length > 0 ? "0px 0px 8px 0px" : "8px 0px" }}>
                                   <span> <Typography style={{whiteSpace: "nowrap",
                                                   maxWidth: "150px", 
                                                   overflow: "hidden", 
                                                   fontSize:'14px',
                                                   color:`${getStatusColor(option.status)}`,
                                                   textOverflow: "ellipsis"}}>{getStatusNameByValue(option.status)}
                                           </Typography>
                                       <span className={classes.statuslist}>{option.count}</span> 
                                   </span>
                               </li>
                    })}
                    </ul>
            </div>
         )
     } 

     const folderStatus2 = (group) => {
        let group_status =  group.checklist_group_status ? group.checklist_group_status : [];
        return (
            (group_status && group_status.length > 0)  && 
           <ul className={classes.listmainstyle1}>
               { group_status.map((option) => {
                         return <li className={classes.liststyle1} key={option.label_name}>
                                    <span> <Typography style={{whiteSpace: "nowrap",
                                                    maxWidth: "150px", 
                                                    overflow: "hidden", 
                                                    fontSize:'14px',
                                                    color:`${option.label_color}`,
                                                    textOverflow: "ellipsis"}}>{option.label_name}
                                            </Typography>
                                        <span className={classes.statuslist}>{option.label_count}</span> 
                                    </span>
                                </li>
                     })}
                     hiii
           </ul>
        )
    }
    const classes = useStyles();
    return (
        <div style={{padding:'20px 10px 0 0px'}} id="vikas">
            {
                <Grid item container direction="row" spacing={1}>
               {props.slug &&
                <Grid item>
                    <Button variant="outlined" className={classes.foldericon} onClick={()=>{history.goBack()}}>
                    <ArrowBackIcon style={{padding:'1px 0'}}/> 
                    </Button>
                </Grid>
                }
                {renderArr && renderArr.length > 0 && renderArr.map((group, index) => {  
                    /* const attributes = {
                        className: 'custom-root',
                        disabledclassname: 'custom-disabled',
                        dividerclassname: 'custom-divider',
                        selectedclassname: 'custom-selected'
                      } */
                    return <Grid item className={classes.folderhover}
                                style={{width:'auto'}}
                                key={group.slug_code}
                                id={index+"_"+group.group_name}
                                onClick={() => {
                                    trackGAEvent(props.selectedOrganization.organization.name, 'Checklist Folder Clicked', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
                                    props.onFolderClick(group.slug_code);
                                }}
                                //ref={itemRef.current[index]}
                                >
                                <ContextMenuTrigger id={`${group.id}`}>    
                                    <div style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}} 
                                            className={classes.folderborder} 
                                           
                                    >
                                        <i className="flaticon-folder-1" style={{color: "#202020"}}></i>
                                        <Typography variant="subtitle1" 
                                            className={classes.folderName}
                                            >
                                            {group.group_name}
                                        </Typography>
                                    </div>
                                    <ContextMenu id={`${group.id}`}>
                                        <MenuItem
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                props.onEditFolderClick(group)
                                            }}
                                            >
                                            Edit
                                        </MenuItem>
                                        <MenuItem divider />
                                        <MenuItem
                                            onClick={(e) => {
                                                    e.stopPropagation();
                                                    if (window.confirm("Are you sure you want to delete folder? After deleting checklist will be moved to root folder!?")) {
                                                    onDeleteOptionClick(e, group.id);
                                                    } 
                                                }
                                            }
                                            >
                                            Delete
                                        </MenuItem>
                                    </ContextMenu>
                                {folderStatus(group)}
                                </ContextMenuTrigger>
                            </Grid>
                    })
                }        
                    <Grid item style={{position:'relative'}}>
                        {dropdownArray && dropdownArray.length>0 && 
                        <Button variant="outlined" 
                            ref={wrapperRef}
                            onClick={() =>setAnchorEl(!anchorEl)} 
                            className={classes.foldericon}>
                            {/* <MoreHorizIcon/> */}
                            {<i className="flaticon-more" style={{fontSize: "1.5rem"}}></i>}
                        </Button>}
                        {anchorEl && <Grid item className='dropdowncontainer'>
                            {dropdownArray && dropdownArray.length > 0 &&
                            <ul className="folderdropdown" >
                                {
                                    dropdownArray && dropdownArray.length > 0 && dropdownArray.map((folder, index)=>{
                                        return(
                                        <div key={folder.group_name+index}>
                                            <ContextMenuTrigger id={`${folder.id}`}>
                                            <li className='folders' 
                                                //onClick={handleClose}
                                                onClick={() => {
                                                    trackGAEvent(props.selectedOrganization.organization.name, 'Checklist Folder Clicked', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
                                                    props.onFolderClick(folder.slug_code)
                                                }}
                                                key={folder.group_name+index}>
                                                <i className="flaticon-folder"></i>
                                                <Typography variant="subtitle1" 
                                                    className={classes.folderName}
                                                    aria-owns={open ? 'mouse-over-popover' : undefined}
                                                    aria-haspopup="true"
                                                    onMouseEnter={(e)=>handlePopoverOpen(e, folder)}
                                                    onMouseLeave={(e)=>handlePopoverClose(e)}>
                                                {folder.group_name}
                                                </Typography>
                                                <ContextMenu id={`${folder.id}`}>
                                                    <MenuItem
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            props.onEditFolderClick(folder)
                                                        }}
                                                        >
                                                        Edit
                                                    </MenuItem>
                                                    <MenuItem divider />
                                                    <MenuItem
                                                        onClick={(e) => {
                                                                e.stopPropagation();
                                                                if (window.confirm("Are you sure you want to delete folder? After deleting checklist will be moved to root folder!?")) {
                                                                onDeleteOptionClick(e, folder.id);
                                                                } 
                                                            }
                                                        }
                                                        >
                                                        Delete
                                                    </MenuItem>
                                                </ContextMenu> 
                                                    <Popover
                                                    id="mouse-over-popover"
                                                    className={classes.popover}
                                                    classes={{
                                                        paper: classes.paper,
                                                    }}
                                                    open={open && (folderId === folder.id)}
                                                    anchorEl={anchorE2}
                                                    anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'left',
                                                    }}
                                                    transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'left',
                                                    }}
                                                    onClose={handlePopoverClose}
                                                    >
                                                    {folderStatus2(folder)}
                                                    </Popover>
                                                </li>
                                            </ContextMenuTrigger>
                                        </div>
                                        )
                                    })
                                }
                            </ul>
                            }
                        </Grid>
                        }
                </Grid>
            </Grid> }
        </div>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    selectedTemplate: state.organizationInfo.selectedTemplate
});

export default connect(mapStateToProps)(ChecklistFoldersComponent);



