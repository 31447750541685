import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Grid, Typography, Button, IconButton, Tooltip, Dialog, TextField, CircularProgress, Popover, MenuItem} from '@material-ui/core';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import ClearIcon from '@material-ui/icons/Clear';
import AddEmailDialogModal from './modals/AddEmailDialogModal';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import {deleteSupportEmailApi, getLoginRedirectUrlApi} from '../services/genieService';
import { consoleToLog } from '../util/AppUtil';
import { useSnackbar } from 'notistack';
import {connect} from 'react-redux';
import {deleteOrgEmail, setSelectedOrgEmailObj, updateOrgEmail} from '../actions/selectedOrganization';
import SettingsEthernetOutlinedIcon from '@material-ui/icons/SettingsEthernetOutlined';

const useStyles = makeStyles((theme) => ({
    settingsContainer: {
        width:'100%',
        background:'#fff',
        borderRadius:'4px',
        marginBottom:'32px'
    },
    emailListContainer: {
        padding:'0px 24px',
        borderBottom:'1px solid rgba(0,0,0,0.1)',
        '&:last-child' : {
            border:'none',
        },
        '&:first-child' : {
            borderBottom:'1px solid rgba(0,0,0,0.1)',
        },
        '& .onEmailListHover' : {
            visibility: 'hidden'
        },
        '&:hover .onEmailListHover': {
            visibility: 'visible'
        },
        '& .more_actionButton' : {
            visibility:'hidden',
            width:'120px',
            borderRadius:'5px',
            fontSize:'14px',
            padding:'1px 0px',
            background:'white',
            border:'1px solid #ccc',
        },
        '&:hover .more_actionButton' : {
            visibility:'visible'
        },

    },
    emailTextStyle: {
        fontSize:'18px',
        fontWeight:600,
        display:'flex',
        alignItems:'center'
    },
    checkIcon: {
        color:"green",
        fontSize:'20px',
        marginLeft:'8px'
    },
    dialogTitle : {
        padding:'16px 24px', 
        display:'flex',  
        justifyContent:'start', 
        alignItems:'center',
        fontSize:'18px',
        fontWeight:'600'
    },
    MuiDialogpaper: {
        scrollbarWidth: 'none',
        overflow:'visible',
        paddingBottom:'5px',
        "&::-webkit-scrollbar": {
            display: "none"
        }   
    },
    iconStyles: {
        fontSize:'16px', 
        color:'#555', 
        marginRight:'2px', 
        marginTop:'-2.5px'
    },
    connectIcons: {
        width:'20px',
        marginRight:'8px'
    }
}))

const UserEmails = (props) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar()

    const [loading, setLoading] = useState(false);
    const [anchorEl, setAnchorEl] = useState(false);

    useEffect(() => {
        props.setSelectedOrgEmailObj();
    }, []);

    const showVerifyEmailText = () => {
        return (
            <Tooltip title={"Verify Email"} arrow>
                <IconButton disableRipple style={{color: 'red', padding:'0', background:"none", marginLeft:'8px', marginTop:'2px'}}>
                        <>
                            <ClearIcon fontSize="small" style={{marginRight:'4px', fontWeight:'bold'}}/>
                            <Typography style={{color:"#0071FF"}}>Verify </Typography>
                        </>
                </IconButton>
            </Tooltip>
        )
    }

    const deleteSupportEmail = (e, email) => {
    
        if(window.confirm('Are you sure you want to delete this email?')) {
            const support_email_id = email.id;

            deleteSupportEmailApi(support_email_id) 
                .then((response) => {
                    const res = response.data;
                    consoleToLog('Response deleteSupportEmailApi ', res);
                    enqueueSnackbar(res.message, {
                        variant:'success'
                    });

                    props.deleteOrgEmail(support_email_id);
                })
                .catch((err) => {
                    consoleToLog("deleteSupportEmailApi error", e.response);
                    if(e.response.data && e.response.data.message) {
                        enqueueSnackbar(e.response.data.message, {
                            variant:'error'
                        })
                        return;
                    }
                });
        }
    }

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const getLoginRedirectUrl=()=>{
        getLoginRedirectUrlApi()
        .then((response) => {
            const res = response.data;
            consoleToLog("frontend: ", res);
            window.open(res.url, "_self");
        })
        .catch((e) => {
            consoleToLog("error", e);
        });
    }

    const loginWithoutLook = () => {
        window.open(`https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${process.env.REACT_APP_OUTLOOK_CLIENT_ID}&scope=offline_access%20https://graph.microsoft.com/mail.send&response_type=code&redirect_uri=${process.env.REACT_APP_OUTLOOK_REDIRECT_URL}`, '_self');
    }

    const showOptionsPopover = (email) => {
        return <Popover
                    id="connect-option-popover"
                    keepMounted
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={handlePopoverClose}
                    >
                    <MenuItem onClick={(e) => onConnectEmailClick(e, email, 'gmail')}>
                        <img src='images/gmail_icon.png' className={classes.connectIcons}/>
                        Gmail
                    </MenuItem>
                    <MenuItem onClick={(e) => onConnectEmailClick(e, email, 'outlook')}>
                        <img src='images/outlook_icon.png' className={classes.connectIcons} />
                        Outlook
                    </MenuItem>
                </Popover>
    }

    const onConnectEmailClick = (e, email, emailType) => {
        const emailObj = {
            support: email.support,
            access_users: email.access_users,
            organization_email_id: email.id,
            email_type: emailType
        }
        props.setSelectedOrgEmailObj(emailObj);

        if(emailType === 'gmail') {
            getLoginRedirectUrl();
        } else {
            loginWithoutLook();
        }


        handlePopoverClose();
    } 

    return (
        <Grid item >
            <Grid item sm={12} className={classes.settingsContainer}>
                {loading ?
                <CircularProgress size={28} style={{position:'relative', left:'50%', right:'50%', margin:'16px 0px'}}/>
                :
                <Grid item>
                    {
                        props.userEmails && props.userEmails.length > 0 ?
                        props.userEmails.map((email) => {
                            return <Grid item container
                                    alignItems='center'
                                    key={email.id}
                                    className={classes.emailListContainer}
                                    style={{height: '70px'}}
                                    justifyContent='space-between'>
                                        <Grid item sm={9}>
                                            <Typography className={classes.emailTextStyle}>
                                                {email.email}
                                                {email.verified ? 
                                                    <CheckCircleRoundedIcon className={classes.checkIcon}/>
                                                :
                                                showVerifyEmailText()  }
                                            </Typography>
                                        </Grid>

                                        <Grid item sm={3}>
                                            <div style={{display:'flex', alignItems:'center'}}>
                                                { email.verified && !email.connected &&
                                                <Button className="more_actionButton" 
                                                        onClick={handlePopoverOpen}
                                                        style={{paddingLeft:'4px', paddingRight:'4px'}}
                                                        >
                                                        <SettingsEthernetOutlinedIcon 
                                                            style={{marginRight:'4px'}}
                                                            className={classes.iconStyles}
                                                            fontSize='small'/>
                                                        <span> Connect </span>
                                                </Button>}
                                                {showOptionsPopover(email)}

                                                <Button className="more_actionButton"
                                                        onClick={(e) => deleteSupportEmail(e, email)}
                                                        > 
                                                    <DeleteOutlinedIcon 
                                                        className={classes.iconStyles}
                                                        fontSize='small'/>
                                                    <span> Delete </span>
                                                    
                                                </Button>
                                                
                                            </div>
                                        </Grid>

                                    </Grid>
                        }) : 
                        <Grid item container justifyContent='center'>
                            <Typography  variant='body' 
                                style={{padding:'16px', fontSize:'16px'}}>
                                No User Email Found 
                            </Typography>
                        </Grid>
                    }

                </Grid>}
            </Grid>
            {props.openDialog2 && <AddEmailDialogModal openDialog={props.openDialog2}
                                        handleDialogClose={props.handleUserDialogClose}
                                        userEmails={props.userEmails}
                                        setUserEmails={props.setUserEmails}
                                        fromSupportEmail={false}
                                        />}
        </Grid>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
})

const mapDispatchToProps = (dispatch) => ({
    deleteOrgEmail: (email_id) => dispatch(deleteOrgEmail(email_id)),
    setSelectedOrgEmailObj: (emailObj) => dispatch(setSelectedOrgEmailObj(emailObj)),
    updateOrgEmail: (email) => dispatch(updateOrgEmail(email))
})

export default connect(mapStateToProps, mapDispatchToProps)(UserEmails);