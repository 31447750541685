import React, {useState, useEffect} from 'react';
import {makeStyles, Tooltip} from '@material-ui/core';
import {Grid, Typography, TextField, Button, MenuItem, CircularProgress, Paper, Tabs, Tab, Avatar} from '@material-ui/core';
import {connect} from 'react-redux';
import { useSnackbar } from 'notistack';
import {useIsMount} from '../../components/useIsMount';
import {capitalize, consoleToLog, trackGAEvent, shouldDisable, AccessLevel} from '../../util/AppUtil';
import {organizationMemberListApi, editOrgMemberApi, removeOrgMemberApi, removeScopeApi,
    getPendingInvitationsListApi, resendInvitationApi} from '../../services/authService';
import { setOrgMembers, updateMemberRole, removeOrgMember } from '../../actions/selectedOrganization';
import MobileInviteMemberDrawer from './MobileInviteMemberDrawer';

const useStyles = makeStyles((theme) => ({
    mainContent : {
        width: '100%',
        height:'calc(100vh - 100px)',
        backgroundColor: theme.palette.primary.light,
        transition: ".5s",
        flexGrow: 1,
        //overflow:'hidden',
        overflow:'auto',
        overflowX:'hidden'
        },
    container: {
        marginTop: "8px",
        marginBottom:'16px'
    },
    tab: {
        flexGrow: 1,
        borderRadius:0,
        borderBottom: "1px solid #c5b9b9",
        },
        
    memberContainer: {
        flexGrow: 1,
        background: 'white',
        padding:'15px',
        //height:'100vh',
        marginBottom:'25px'
    },   
    memberColumn : {
        background: '#FFFFFF',
        padding:'8px 8px',
        margin:'0px 0px 8px 0px',
        borderTop:'1px solid rgba(0,0,0,0.1)',
        borderBottom:'1px solid rgba(0,0,0,0.1)',
        '&:last-child' : {
            marginBottom: '0px'
        },
    },
    avatarStyle: {
        width:"38px !important",
        height:"38px !important",
        fontSize:'16px !important',
        fontWeight:'600 !important',
        transform: 'translateY(3px)'
    },
    memberText: {
        transform: 'translateY(1.5px)'
    },
    pendingLoading: {
        position:'relative',
        left:'50%',
        right:'50%',
        top:'5%'
    },
    pendingMemberColumn : {
        background: '#FFFFFF',
        padding:'8px 8px',
        margin:'0px 0px 8px 0px',
        borderTop:'1px solid rgba(0,0,0,0.1)',
        borderBottom:'1px solid rgba(0,0,0,0.1)',
        '&:last-child' : {
            marginBottom: '0px'
        },
    },
    inviteButton: {
        color:'#0071FF',
        border:'1px solid #0071FF'
    },
    nameStyle: {
        fontSize:'16px', 
        fontWeight:'600', 
        maxWidth:'250px', 
        overflow:'hidden', 
        whiteSpace:'nowrap', 
        textOverflow:'ellipsis'
    }
}))

const MobileOrganizationMembers = (props) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const isMount = useIsMount();

    const [tabValue, setTabValue] = useState(0);
    const [pendingMembersList, setPendingMembersList] = useState([]);
    const [pendingMembersLoading, setPendingMembersLoading] = useState(false);

    const [openDrawer, setOpenDrawer] = useState(false);

    const roles = ["owner", "manager", "member", "guest"];
    const { access_level } = props.selectedOrganization || {};
    const accessLevelManager = shouldDisable(AccessLevel.getUserAccessLevelValue(access_level), AccessLevel.MANAGER);
    const loggedInUserAccessLevel = AccessLevel.getUserAccessLevelValue(access_level);

    useEffect(() => {
        getOrganizationMemberList();
    }, []);

    useEffect(() => {
        if(isMount) {

        } else {
            if(tabValue) {
                getPendingInvitations();
            }
        }

    }, [tabValue])

    const getOrganizationMemberList = () => {
        const organization_id = props.selectedOrganization.organization.id;
        //get members list api on first render
        organizationMemberListApi(organization_id)
        .then((response) => {
            const res = response.data;
            consoleToLog("Response Org-MembersPage: organizationMemberListApi: ", res);

            //set members of selected oraganization in redux
            props.setOrgMembers(res.member_list);
        })
        .catch((e) => {
            consoleToLog("Response Org-MembersPage: organizationMemberListApi: ", e.response);
            if (e.response.data && e.response.data.message) {
                enqueueSnackbar(e.response.data.message, {
                    variant:'error'
                });
                return false;
            }
        });
    }

    const getPendingInvitations = () => {
        const organization_id = props.selectedOrganization.organization.id;
        setPendingMembersLoading(true);

        getPendingInvitationsListApi(organization_id)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response getPendingInvitationsListApi: ", res);
                setPendingMembersLoading(false);
                setPendingMembersList(res.pending_invitation);
            })
            .catch((e) => {
                setPendingMembersLoading(false);
                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant:'error'
                    });
                    return false;
                }
            });
    }

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    }

    const checkDisabled = (isCurrentlyLoggedIn) => {
        if(isCurrentlyLoggedIn) {
            return true;
        } else {
            return false;
        }

    }

    const handleDrawerOpen = () => {
        setOpenDrawer(true);
    }

    const handleDrawerClose = () => {
        setOpenDrawer(false);
    }

    const onMemberTypeChanged = (e, organization_member_id) => {
        const changeTypeToRoleId = e.target.value;

        editOrgMemberApi(organization_member_id, changeTypeToRoleId)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response editOrgMemberApi: ", res);
                enqueueSnackbar(res.message, {
                    variant: 'success'
                });

                props.updateMemberRole(organization_member_id, changeTypeToRoleId);
                trackGAEvent(props.selectedOrganization.organization.name, 'Change Role', `${props.auth.user.firstname} ${props.auth.user.lastname} changed member role to ${changeTypeToRoleId}`);
            })
            .catch((e) => {
                consoleToLog("Response editOrgMemberApi: ", e.response);
                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant:'error'
                    });
                    return false;
                }
            });
    }

    const deleteMemberFromOrg = (organization_member_id, member_user_id, fullName) => {
        //dont allow if owner deleting itself
        if (props.auth.user.id === member_user_id) {
            enqueueSnackbar('You can not delete yourself!', {
                variant:'error'
            });
            return;
        }

        if(window.confirm('Are you sure you want to remove member?')) {
            
            removeOrgMemberApi(organization_member_id) 
                .then((response) => {
                    const res = response.data;
                    consoleToLog("Response removeOrgMemberApi: ", res);
                    enqueueSnackbar(res.message, {
                        variant: 'success'
                    });
                            
                    trackGAEvent(props.selectedOrganization.organization.name, 'Delete Member', `${props.auth.user.firstname} ${props.auth.user.lastname} deleted member  ${fullName}`);
                    //remove from members-redux 
                    props.removeOrgMember(organization_member_id);
                        if(res.orphaned) {
                            removeRegisterScope(member_user_id)
                        }
                })
                .catch((e) => {
                    consoleToLog("Response removeOrgMemberApi: ", e.response);
                    if (e.response.data && e.response.data.message) {
                        enqueueSnackbar(e.response.data.message, {
                            variant:'error'
                        });
                        return false;
                    }
                });    
            }

    }

    const removeRegisterScope = (member_user_id) => {
        const service = 'workplace';
        const user_id = member_user_id

        removeScopeApi(service, user_id)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response removeScopeApi: ', res);
            })
            .catch((e) => {
                consoleToLog("Response removeScopeApi: ", e.response);
                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant:'error'
                    });
                    return false;
                }
            });   
    }

    const showMembersList = () => {
        return (
                <Grid item container style={{marginTop:'16px'}}>
                        <Grid item container direction="column">
                            {props.members.map((member) => {
                                let fullName = member.user.firstname + " " + member.user.lastname;
                                const isDisabled = checkDisabled(props.auth.user.id === member.user.id);
                                const memberAccessLevel = AccessLevel.getUserAccessLevelValue(member.access_level);
                                return <Grid item container 
                                            key={member.user.id} 
                                            className={classes.memberColumn}>

                                            <Grid item md={10} lg={10}>
                                                <Grid item container>
                                                    <Grid item>
                                                        <Avatar className={classes.avatarStyle}>
                                                        {member.user.firstname.charAt(0).toUpperCase() +
                                                            member.user.lastname.charAt(0).toUpperCase()}    
                                                        </Avatar>
                                                    </Grid>
                                                    <Grid item className={classes.memberText}>
                                                        <Grid item container direction='column' style={{marginLeft: "16px"}}>
                                                            <Grid item>
                                                                <Typography className={classes.nameStyle}>
                                                                    {fullName}
                                                                </Typography>
                                                                <Typography variant="subtitle1" style={{color:'#555555'}}>
                                                                    {member.user.email}
                                                                </Typography>
                                                            </Grid>

                                                            <Grid item>
                                                                <TextField 
                                                                    margin="dense"
                                                                    style={{transform:'translateY(-1.5px)', width:'160px'}}
                                                                    variant="outlined"
                                                                    select
                                                                    disabled={isDisabled || accessLevelManager || (loggedInUserAccessLevel < memberAccessLevel)}
                                                                    value={member.access_level}
                                                                    onChange={(e) => onMemberTypeChanged(e, member.id)}
                                                                >
                                                                    {
                                                                    roles && roles.map((role) => {
                                                                    return  <MenuItem key={role} value={role}>
                                                                                {capitalize(role)}
                                                                            </MenuItem>
                                                                    })
                                                                    }
                                                                </TextField>               
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                </Grid>                                                
                                            </Grid>


                                            {!accessLevelManager && !(loggedInUserAccessLevel < memberAccessLevel) &&
                                            <div style={{display:'flex', alignItems:'center', marginLeft:'auto'}}>
                                                <Grid item xs={2} className="deleteIcon"> 
                                                    <Button  onClick={() => deleteMemberFromOrg(member.id, member.user.id, fullName)} 
                                                        style={{
                                                            fontSize: "18px",
                                                            minWidth: '20px'
                                                        }}
                                                        title="Delete member">
                                                        <i className="flaticon-delete-1" style={{height:'25px'}}></i>
                                                    </Button> 
                                                </Grid>              
                                            </div> }      

                                        </Grid>
                                })
                            }   
                        </Grid>
                </Grid>
        )
    }

    const resendInvitation = (member) => {
        const service = 'workplace';
        const email = member.email;
        const role = member.role;
        const entity_id = props.selectedOrganization.organization.id;
        const entity_name = props.selectedOrganization.organization.name;
        const entity_email = props.selectedOrganization.organization.email;
        

        resendInvitationApi(service, email, role, entity_id, entity_name, entity_email)
            .then((response) => {
                const res = response.data;
                enqueueSnackbar(res.message, {
                    variant: 'success'
                });
                consoleToLog("Response resendInvitationApi: ", res);
            })
            .catch((e) => {
                consoleToLog("Response resendInvitationApi error", e.response);
                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant:'error'
                    });
                    return false;
                }
            });

    }

    const showPendingMemberList = () => {
        return (
            <Grid item container style={{marginTop:'16px'}}>
                    { pendingMembersLoading ? <CircularProgress size={25} className={classes.pendingLoading}/> : 
                    <Grid item container direction="column">
                    {pendingMembersList && pendingMembersList.length > 0 ? pendingMembersList.map((member) => {
                            return <Grid item container 
                                        alignItems='center'
                                        key={member.id} 
                                        className={classes.pendingMemberColumn}>

                                        <Grid item xs={10} sm={10}>
                                            <Grid item container>
                                                <Grid item>
                                                    <Avatar className={classes.avatarStyle} style={{transform:'translateY(0px)'}}>
                                                    {member.email.charAt(0).toUpperCase()}    
                                                    </Avatar>
                                                </Grid>
                                                <Grid item className={classes.memberText}>
                                                    <Grid item container direction='column' style={{marginLeft: "16px"}}>
                                                        <Grid item style={{marginTop:"-4px"}}> 
                                                            <Typography style={{fontSize:"15px", fontWeight:"600"}}>
                                                                {member.email}
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item>
                                                            <TextField fullWidth
                                                                style={{transform:'translateY(-1.5px)', width:'160px'}}
                                                                margin="dense"
                                                                variant="outlined"
                                                                select
                                                                disabled={true}
                                                                value={member.role}>
                                                                    {
                                                                    roles && roles.map((role) => {
                                                                    return  <MenuItem key={role} value={role}>
                                                                                {capitalize(role)}
                                                                            </MenuItem>
                                                                    })
                                                                    }
                                                            </TextField>               
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <div style={{display:'flex', alignItems:'center', marginLeft:'auto'}}>
                                            <Grid item xs={2} sm={2} className="refreshIcon">
                                                <Button  onClick={() => resendInvitation(member)}
                                                    style={{
                                                    fontSize: "18px",
                                                    minWidth: '20px'
                                                }}>
                                                    <i className="flaticon-reload" style={{height:'25px'}}></i>
                                                </Button> 
                                            </Grid>              
                                        </div>       

                                    </Grid>
                            }) : <Grid item container justifyContent="center">
                                    <Typography variant="body1">No Pending Invitations</Typography>
                                </Grid>
                        }   
                    </Grid>}
            </Grid>
        )
    }

    return (
        <div className={classes.mainContent}>
            <Grid item container direction='column'
                className={classes.container}>
                <Grid item xs={12} style={{marginTop:'8px', padding:'0 12px'}}>
                    <Button fullWidth 
                        onClick={handleDrawerOpen}
                        className='add_button_style'>
                        Invite Members
                    </Button>
                </Grid>

                <Grid item xs={12} style={{marginTop:'8px'}}>
                    <Grid item container direction="column" style={{marginTop:'30px'}}>
                        <Grid item container  style={{padding:'0 12px'}}>
                                <Tabs
                                    value={tabValue}
                                    onChange={handleTabChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                >
                                    <Tab label="Members" />
                                    {!accessLevelManager &&
                                        <Tab label="Pending Invitation" />
                                    }
                                </Tabs>
                        </Grid>

                        {!tabValue ? showMembersList() : showPendingMemberList()}
                    </Grid>

                </Grid>
            </Grid>
            <MobileInviteMemberDrawer openDrawer={openDrawer}
                handleDrawerClose={handleDrawerClose}
                roles={roles}
                is_orgEmail_verified={props.is_orgEmail_verified}
                />
        </div>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    members: state.organizationInfo.members
});

const mapDispatchToProps = (dispatch) => ({
    setOrgMembers: (members) => dispatch(setOrgMembers(members)),
    updateMemberRole: (id, access_level) => dispatch(updateMemberRole(id, access_level)),
    removeOrgMember: (id) => dispatch(removeOrgMember(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(MobileOrganizationMembers);