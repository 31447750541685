import React, {useEffect, useState, createRef} from 'react';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Dialog, Button, Typography, Avatar, Tooltip, Popover, Paper, CircularProgress, 
    Divider, Slide, Drawer, AppBar, Toolbar, IconButton, Link, ClickAwayListener} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import * as PostalMime from "postal-mime";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { useIsMount } from '../useIsMount';
import moment from 'moment';
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';
import {consoleToLog, displayEmailSubject, showEmailIcons, trackGAEvent} from '../../util/AppUtil';
import {connect} from 'react-redux';
import {decryptEmailPathApi} from '../../services/authService';
import EmailDetailsNotesComponent from './EmailDetailsNotesComponent';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { useSnackbar } from 'notistack';
import SendEmailDialog from '../SendEmailDialog';
import CreateChecklistModal from '../modals/CreateChecklistModal';
import ClearIcon from '@material-ui/icons/Clear';
import {setEmailSentFrom} from '../../actions/selectedOrganization';
import {getEmailDetailsApi} from '../../services/genieService';
import {downloadAttachmentApi} from '../../services/authService';
import {readEmailItemApi} from '../../services/genieService';
import {disableEmailFollowupApi} from '../../services/authService';
import { showImg } from '../../util/AppUtil';

const useStyles = makeStyles((theme) =>({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        position: 'absolute',
        backgroundColor: theme.palette.background.paper,
        width:'100vw',
        height:'100vh',
        zIndex:1000
    },
    backIcon: {
        color:'#666',
        padding: "4px 0px",
        fontSize: "18px",
        minWidth: "40px",
        lineHeight: "18px",
        marginTop: "15px",
        marginBottom: "10px",
        marginLeft: "8px",
    },
    collapsibleBtn : {
        cursor: 'pointer',
        border: '1px solid rgba(0, 0, 0, .125)',
        outline: 'none',
        fontSize: '20px',
        marginTop: '8px', 
        display: 'block',
        '&:hover':{
            border: '1px solid #ccc'
        }
    },
    emailFrom : {
        fontSize:'14px',
        fontWeight: 'bold'
    },
    detailsContainer: {
        padding:'0px 16px 24px 16px',
        backgroundColor: theme.palette.primary.light,
        height:'100vh !important',
        overflow:'auto'
    },
    customColumn1: {
        flexBasis:'6.8%', 
        maxWidth:'6.8%'
    },
    avatarStyles: {
        background:'#fff !important',
        height:'40px !important',
        width:'40px !important',
        color:'#666 !important',
        border:'1px solid rgba(0,0,0, 0.125) !important',
        fontSize:'16px !important'
    },
    fromHeadingStyle: {
        fontSize: '14px',
        fontWeight: '600'
    },
    emailDate : {
        fontSize:'13px',
        color:'#666',
        marginRight:'6px'
    },
    emailActionIcons: {
        minWidth: '25px !important'
    },
    emailActionIcons1: {
        width:'120px',
        border: '1px solid #444746',
        borderRadius: '6px'
        
    },
    emailSubjectStyle: {
        fontSize:'1.1rem',
        fontWeight: 600
    },
    allrecipientStyle: {
        fontSize:'12px', 
        color:'#5E5E5E', 
        display:'flex', 
        alignItems:'center',
        width:'auto',
        maxWidth:'600px',
        overflow: 'hidden',
        whiteSpace:'nowrap',
        textOverflow:'ellipsis',
        [theme.breakpoints.down(1450)] : {
            maxWidth:'510px',
        }   
    },
    toText: {
        fontSize:'12px',
        marginRight:'4px',
        color:'#5E5E5E',
        marginTop:'-1.5px'
    },
    dropdownArrowIconStyle: {
        marginLeft:'4px', 
        cursor:'pointer'
    },
    popoverLabelStyle: {
        color: '#999',
        textAlign:'right' 
    },
    paper: {
        position:'absolute',
        padding: theme.spacing(1),
        border: "1px solid rgba(0, 0, 0, 0.12)",
        width: '100% !important',
        maxWidth:"550px !important",
        marginLeft:'180px'
    },
    customMarginLeft: {
        marginLeft:'16px'
    },
    customMarginTop: {
        marginTop:'4px'
    },
    MuiButtonlabel: {
        justifyContent:'flex-start !important',
        marginLeft:'8px',
        color:'#172B4D'
    },
    MuiButtonlabel1: {
        justifyContent:'flex-start !important',
        marginLeft:'8px',
        color:'#dc4f42'
    },
    actionButtonStyle: {
        background:'white', 
        color:'#202020', 
        marginTop:'16px',
        border: '1px solid #202020'
    },
    actionButtonStyle1: {
        background:'white', 
        color:'#202020', 
        marginTop:'16px',
        border:'1px solid #dc4f42',
        color:'#dc4f42'
    },
    clientListStyle: {
        color:'#172B4D',
        marginTop:'4px'
    },
    subtitleStyle: {
        fontSize:'13px',
        marginLeft:'8px', 
        color:'grey', 
    },
    avatarColumn: {
        flexBasis:'6.5%',
        maxWidth:'6.5%'
    },
    emailDetailContainer: {
        padding:'16px 16px', 
        background:'white', 
        borderRadius:'4px',
        flexBasis: '71%',
        maxWidth:'71%',
        marginLeft:'16px'
    },
    actionsContainer: {
        marginLeft:'32px',
        background:'#fff',
        borderRadius:'4px',
        padding:'16px 16px'
    },
    supportEmailStyle: {
        padding:'2px 4px',
        borderRadius:'4px',
        color:'#fff',
        marginLeft:'8px'
    },
    customMarginTop1: {
        marginTop:'16px'
    },
    list : {
        cursor:'pointer',
        '&:hover' : {
            background:'#d3d3d3'
        }
    },
    followupTextStyle: {
        width:'auto',
        overflow:'hidden',
        whiteSpace:'nowrap',
        textOverflow:'ellipsis'
    },
    drawer: {
        //    width: drawerWidth,
            flexShrink: 0
    },
    drawerPaper: {
        width: '550px', 
        flexGrow:1,
    },
    appBar: {
        background:'#F9F9F9',
        height:'45px',
        color:'#202020'
    },
    MuiButtonlabel1: {
        justifyContent:'flex-start !important',
        marginLeft:'8px',
        color:'#dc4f42'
    },
    actionButtonStyle1: {
        background:'white', 
        color:'#202020', 
        marginTop:'16px',
        border:'1px solid #dc4f42',
        color:'#dc4f42'
    }
}))

const EmailDetailsModal = (props) => {
    const classes = useStyles();
    const isMount = useIsMount();
    const {enqueueSnackbar} = useSnackbar();


    const [emailObj, setEmailObj] = useState(undefined);
    const [showEmailBody, setShowEmailBody] = useState(false);
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [initialEmailObj, setInitialEmailObj] = useState(undefined);
    const [replyClick, setReplyClick] = useState(false);
    const [replyAllClick, setReplyAllClick] = useState(false);
    const [forwardClick, setForwardClick] = useState(false);
    const [loading, setLoading] = useState(true);
    const [parsedEmailObj, setParsedEmailObj] = useState(undefined);
    const [canScroll, setCanScroll] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [attachmentName, setAttachmentName] = useState(undefined);
    const [attachmentLoading, setAttachmentLoading] = useState(false);
    const [emailAttachments, setEmailAttachments] = useState([]);

    const [isActive, setIsActive] = useState(false);

    const emailMetadata = props.selectedEmailItem?.metadata;
    const {user} = props.selectedOrganization;

    const myDivToFocus = createRef();
    const mentionRef = createRef();


    let fromName = emailObj?.from ? emailObj?.from.name : '';
    const nameArr = fromName.split(" ");
    let nameStr1 = nameArr[0];
    let nameStr2 = nameArr[1] ? nameArr[1] : '';
    const finalStr = nameStr1.charAt(0).toUpperCase() + nameStr2.charAt(0).toUpperCase();

    let email_id = emailObj?.from ? emailObj?.from.email : '';
    let stringDate = moment(`${emailObj?.date}`).toLocaleString();
    //console.log('strng date', stringDate);

    //if there is no text property in email object
    let htmlString = emailObj?.html;
    var div = document.createElement('div');
    div.innerHTML = htmlString;

    div.querySelectorAll('div').forEach(span => {
      div.innerHTML = div.innerHTML.replace(span.outerHTML, '⠀'+span.outerHTML+'⠀'); // Invisible character U+2800
    });

    htmlString = div.innerHTML.split('⠀'); 
    //console.log('html String', htmlString[1].replace(/<[^>]*>?/gm, ''))

    //find if html string has blockquote or not
    var div1 = document.createElement('div');
    div1.innerHTML = htmlString;
    let link = document.getElementsByTagName('blockquote');

    let toEmails = emailObj?.to_email && emailObj?.to_email.length > 0 ? emailObj?.to_email : [];
    let ccEmails = emailObj?.cc && emailObj?.cc.length > 0 ? emailObj?.cc : [];

    let arrList = [...toEmails, ...ccEmails];
    const emailReadUsersArr = props.selectedEmailItem?.read_users;
    const emailRead = emailReadUsersArr?.includes(user);

    useEffect(() => {
        getEmailDetails();
        props.selectedEmailItem?.raw_s3_path &&
        descryptEmailPath(undefined, false);
    }, [])

    useEffect(() => {
        if(props.isEmailSendError) {
            handleOpen();
        }
    }, []);

    // useEffect(() => { 
    //     if(!isMount) {
    //         async function fetchData() {
    //             const parser = new PostalMime.default();
    //             const email = await parser.parse(atob(rawEmailData));
    //             setEmailObj(email);
    //             setInitialEmailObj(email);
    //             setLoading(false);
    //         }
    //         fetchData();
    //     }
    // }, [rawEmailData]);


    useEffect(() => { 
        if(!isMount) {
            if(!emailObj.part1 && !emailObj.part2) {
                updateDetailsView();
            }
        }
    }, [emailObj]);

    useEffect(() => {
        if(!isMount) {
            if(myDivToFocus.current && canScroll) {
                myDivToFocus.current.scrollIntoView({
                    behavior: 'smooth'
                })
            }
        }
    }, [myDivToFocus])

    useEffect(() => {
        if(!isMount) {
            if(myDivToFocus.current && canScroll) {
                mentionRef.current?.focus();
                setCanScroll(false);
            }
        }
    }, [canScroll, mentionRef])

    const getEmailDetails = () => {
        const email_uuid = props.selectedEmailItem?.id;

        getEmailDetailsApi(email_uuid)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response getEmailDetailsApi: ', res);
                
                setEmailObj(res);
                setInitialEmailObj(res);
                setLoading(false); 
                
                if(!emailRead) {
                    callReadTriageItemsApi(email_uuid);
                }
 
                trackGAEvent(props.selectedOrganization.organization.name, 'Email Details Opened', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
            })
            .catch(e => {
                consoleToLog('Error getEmailDetailsApi: ', e);
                setLoading(false);
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {variant:'success'});
                    props.handleEmailDialogClose();
                    return;
                }
            }) 
    }

    const callReadTriageItemsApi = (selectedTriageEmailUuid) => {
        //const emailUUIDArr = selectedTriage && selectedTriage.length > 0 ? selectedTriage.map((triageItem) => triageItem.data.email_uuid) : [];
        const email_uuid = [selectedTriageEmailUuid];

        readEmailItemApi(email_uuid)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response readTriageItemApi: ", res);

                    trackGAEvent(props.selectedOrganization.organization.name, 'Read Email Item', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
                    props.emailListReadUnRead(true, email_uuid);
            })
            .catch((e) => {
                consoleToLog("Error readTriageItemApi: ", e);
                if(e.response.data && e.response.data.message) {
                    return;
                }
            })
    }

    const descryptEmailPath = (attachmentObj, downloadAttachment) => {
        const organinzation_id = props.selectedOrganization.organization.id;
        const raw_s3_path = props.selectedEmailItem?.raw_s3_path;

        setAttachmentLoading(true);
        decryptEmailPathApi(organinzation_id, raw_s3_path)
            .then(async (response) => {
                const res = response.data;
                consoleToLog('Response decryptEmailPathApi: ', res);

                const parser = new PostalMime.default();
                const email = await parser.parse(atob(res.raw_email_data));
                if(attachmentObj && downloadAttachment) {
                    setParsedEmailObj(email);
                    downloadClickedAttachment(email, attachmentObj);
                } else {
                    let attachmentWithTypes = email?.attachments && email?.attachments && email?.attachments.map((attachment) => {
                        let fileObj =  new File([new Uint8Array(attachment.content)], attachment.filename, {type: attachment.mimeType})
                        return fileObj;
                    })
                    setEmailAttachments(attachmentWithTypes);
                }
            })
            .catch((e) => {
                consoleToLog('Error decryptEmailPathApi: ', e.response);
                setAttachmentLoading(false);
                if(e.response.data && e.response.data.message) {
                    //enqueueSnackbar(e.response.data.message, {variant:'error'});
                    return;
                }
            })
    }

    const downloadClickedAttachment = (emailObject, clickedAttachment) => {
        emailObject.attachments.forEach((attachment) => {
            if(attachment.filename === clickedAttachment.name) {
                let downloadableURL = URL.createObjectURL(
                    new Blob([attachment.content], { type: attachment.mimeType})
                );
                //initiate download
                setAttachmentLoading(false);
                setAttachmentName(undefined);
                document.getElementById(`${emailObj?.email_uuid}_attachments_container`).href = downloadableURL;
                document.getElementById(`${emailObj?.email_uuid}_attachments_container`).setAttribute('download', attachment.filename);
                document.getElementById(`${emailObj?.email_uuid}_attachments_container`).click();
                
            }
        })
    }

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        replyClick && setReplyClick(false);
        replyAllClick && setReplyAllClick(false);
        forwardClick && setForwardClick(false);
    };

    const onReplyClick = () => {
        setReplyClick(true);
        handleOpen();
        trackGAEvent(props.selectedOrganization.organization.name, 'Reply Clicked', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
        props.setEmailSentFrom('Email Details Reply');
    }

    const onReplyAllClick = () => {
        setReplyAllClick(true);
        handleOpen();
        trackGAEvent(props.selectedOrganization.organization.name, 'Reply All Clicked', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
        props.setEmailSentFrom('Email Details Reply All');
    }

    const onForwardClick = () => {
        setForwardClick(true);
        handleOpen();
        trackGAEvent(props.selectedOrganization.organization.name, 'Forward Clicked', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
        props.setEmailSentFrom('Email Details Forward');
    }

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const handleDrawerOpen = () => {
        setOpenDrawer(true);
    }

    const handleDrawerClose = () => {
        setOpenDrawer(false);
    }

    const showRecipientPopover = () => {
        return (<Popover
            id='email-recipient-popover'
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
            anchorOrigin={{
            vertical: 'bottom',
            horizontal:'center'
            }}
            transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
            }}
            classes={{
                paper: classes.paper,
            }}
        >   
            <Paper elevation={0}>
            <Grid item container alignItems='center'>
                <Grid item md={2}>
                    <Typography variant='body1' className={classes.popoverLabelStyle}>
                        from: 
                    </Typography>
                </Grid>
                
                <Grid item md={10}>
                    <div style={{display:'flex', alignItems:'center', maxWidth:'auto', whiteSpace:'nowwrap', overflow:'hidden', textOverflow:'ellipsis'}}>
                        {fromName &&
                        <Typography className={classes.customMarginLeft} 
                            style={{fontSize:'14px', fontWeight:600}}>
                            {fromName}
                        </Typography>}

                        <Typography variant='body1' className={!fromName && classes.customMarginLeft} 
                            style={{marginLeft: fromName && '8px'}}>
                            {`${emailObj?.from.email}`}
                        </Typography>
                    </div>
                </Grid>
            </Grid>

            {toEmails && toEmails.length > 0 &&
            <Grid item container className={classes.customMarginTop}>
                <Grid item md={2}>
                    <Typography variant='body1' className={classes.popoverLabelStyle} >
                        to: 
                    </Typography>
                </Grid>
                
                <Grid item md={10} >
                    
                    {toEmails.map((email) => {
                        return <Typography key={email} variant='body1' className={classes.customMarginLeft}>
                                    {email}
                                </Typography>
                    })
                    }
                    
                </Grid>
            </Grid>}
            
            {ccEmails && ccEmails.length > 0 &&
            <Grid item container className={classes.customMarginTop}>
                <Grid item md={2}>
                    <Typography variant='body1' className={classes.popoverLabelStyle}>
                        cc: 
                    </Typography>
                </Grid>
                
                <Grid item md={10}>
                    {ccEmails.map((email) => {
                        return <Typography key={email} variant='body1' className={classes.customMarginLeft}>
                                    {email}
                                </Typography>
                    })
                    }
                </Grid>

            </Grid>
            }

            <Grid item container className={classes.customMarginTop}>
                <Grid item md={2}>
                    <Typography variant='body1' className={classes.popoverLabelStyle}>
                        date: 
                    </Typography>
                </Grid>
                
                <Grid item md={10}>
                    <Typography variant='body1' className={classes.customMarginLeft}>
                        {moment(`${stringDate}`).format('DD MMM h:mm A') + ' ' + `(${moment(`${stringDate}`)?.fromNow()})`}
                    </Typography>
                </Grid>

            </Grid>

            <Grid item container className={classes.customMarginTop}>
                <Grid item md={2}>
                    <Typography variant='body1' className={classes.popoverLabelStyle}>
                        subject: 
                    </Typography>
                </Grid>
                
                <Grid item md={10}>
                    <Typography variant='body1' className={classes.customMarginLeft}>
                        {displayEmailSubject(emailObj?.subject)}
                    </Typography>
                </Grid>

            </Grid>
            </Paper>
        </Popover>)
    }

    const updateDetailsView = () => {
        if (emailObj !== undefined) {
            let htmlString = `${emailObj.html}`;
            var div = document.createElement('div');
            div.innerHTML = htmlString;

            const inlineImages = emailObj.attachments?.filter((emailItem) =>  emailItem.disposition === 'inline' || emailItem.contentId);
            
            if(inlineImages && inlineImages.length > 0) {
                let imgTags = div.getElementsByTagName("img");
                for(let i = 0; i < inlineImages.length; i++) {
                    for (let j = 0; j < imgTags.length; j++) { 
                        let urlValue = imgTags[j].getAttribute("src"); 
                        if (urlValue.includes('cid')) { 
                            if(urlValue.replace('cid:', '') === inlineImages[i].contentId.replace(/[<>]/g, '')) {
                                imgTags[j].setAttribute("src", 'data:image/png;base64,' + inlineImages[i].content); 
                            }
                        }
                    }  
                }

            }

            initialEmailObj.html = div.innerHTML;

            //console.log('div initial string', div.innerHTML);
            div.querySelectorAll('blockquote').forEach(blockquote => {
                div.innerHTML = div.innerHTML.replace(blockquote.outerHTML, '⠀'+blockquote.outerHTML+'⠀'); // Invisible character U+2800
            });
            //console.log('div final string', div.innerHTML);

            htmlString = div.innerHTML.split('⠀'); // U+2800
            //console.log('html final string', htmlString);

            const part1 = htmlString.slice(0, 1);
            let finalStr1 = part1.join('');
            const top_content = document.getElementById(`${emailObj.email_uuid}_top_content`);
            top_content.innerHTML = finalStr1;

            const part2 = htmlString.slice(1);
            let finalStr2 = part2.join('');
            const body_content = document.getElementById(`${emailObj.email_uuid}_content`);
            body_content.innerHTML = finalStr2;
            
            const obj = {...emailObj};
            obj.part1 =  part1;
            obj.part2 =  part2;
            //emailObj['part1'] = part1;
            //emailObj['part2'] = part2;
            //console.log('parts1', part1);
            //console.log('parts2', part2);

            //attachment
            // obj.attachments.forEach((attachment) => {
            //     if(attachment.disposition !== 'inline') {
            //         let attachemnt_content = document.getElementById(`${emailObj?.messageId}_attachments_container`);
            //         let image_content = document.getElementById(`${emailObj?.messageId}_icon`);
                    
            //         const attachmentLink = document.createElement("a");
            //         const icon = document.createElement('i');
                    
            //         // get file extension
            //         const extension = attachment.filename.split('.').pop();

            //         const showImg = (extn) => {
            //             if(extn === 'pdf') {
            //                 return '/images/pdf_icon1.png'
            //             } else if(extn === 'xlsx') {
            //                 return '/images/excel_icon1.png'
            //             } else if(extn === 'jpg' || extn === 'jpeg' || extn === 'png' || extn === 'svg') {
            //                 return '/images/image_icon.png'
            //             } else if(extn === 'doc' || extn === 'docx') {
            //                 return '/images/docx_icon.png'
            //             } else {
            //                 return '/images/other_icon.png'
            //             }
            //         } 

            //         attachmentLink.href = URL.createObjectURL(
            //         new Blob([attachment.content], { type: attachment.mimeType })
            //         );
            //         attachmentLink.download = attachment.filename || "attachment";
            //         attachmentLink.innerHTML = `<img src=${showImg(extension)} style="width:18px; height:18px; margin-right:7px"/>` + ' ' + attachment.filename
            //         || `attachment (${attachment.mimeType})`;
            //         attachmentLink.classList.add("attachment-link");
            //         attachemnt_content.appendChild(attachmentLink)
            //     }
            // });
            setEmailObj(obj);
            }
    } 

    const onClickAway = () => {
        setIsActive(false);
    }

    const onStopEmailFollowupClick = () => {
        setIsActive(!isActive);
    }

    const getEmailColor = () => {
        const emailArrList = arrList?.map((email) => email.address);
        const supportEmail = props.orgEmailList.filter((orgEmail) => emailArrList.includes(orgEmail.email));
        return supportEmail
    }

    const onMarkAsUnRead = () => {
        props.callUnReadTriageItemsApi(props.selectedEmailItem?.id);
        props.handleDetailsDialogClose()
    }

    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    const downloadAttachmentThroughPath = (attachment) => {
        const organization_id = props.selectedOrganization.organization.id;
        const bucket = attachment.bucket;
        const path = attachment.path;
        const uuid = emailObj?.email_uuid;

        downloadAttachmentApi(organization_id, path, bucket)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response downloadAttachmentApi: ', res);
                const downloadableURL = res.document_signed_url;

                //initiate download
                document.getElementById(`${uuid}_attachments_container`).href = downloadableURL;
                document.getElementById(`${uuid}_attachments_container`).click();
            })
            .catch((e) => {
                consoleToLog('Error downloadAttachmentApi: ', e);
                if(e.response.data && e.response.data.message) {
                    return;
                }
            })
    }

    const onAttachmentClick = (attachment) => {
        if(attachment.path) {
            downloadAttachmentThroughPath(attachment);
        } else {
            if(parsedEmailObj) {
                downloadClickedAttachment(parsedEmailObj, attachment);
                return;
            } else {
                descryptEmailPath(attachment, true);
            }
        }
    }

    const getReadableNotesText = (htmlText) => {
        var readableMailText = htmlText;
        
        readableMailText = readableMailText?.replace(/\n\r?/g, "<br />");//add <br>
        readableMailText = readableMailText?.replace( /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig, function(url) {
            return '<a href="' + url + '" target="_blank">' + url + '</a>';
        });
        readableMailText = readableMailText?.split(/\(.+?\)/).join('');
        return readableMailText;
        
    }

    const onEmailFollowupClick = (followup) => {
        const organization_id = props.selectedOrganization.organization.id;
        const followup_id = followup.id;
    
        setIsActive(false);
        
        disableEmailFollowupApi(organization_id, followup_id)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response disableEmailFollowupApi', res);
    
                trackGAEvent(props.selectedOrganization.organization.name, 'Stop Followup from Checklist Email Details', `${props.auth.user.firstname} ${props.auth.user.lastname} `);
                const followupArray = emailMetadata?.followups.filter((followup) => followup.id !== res.email_followup?.id);

                const obj = {
                    ...props.selectedEmailItem,
                    metadata: {
                        ...props.selectedEmailItem?.metadata,
                        followups: followupArray
                    }
                }
                props.setSelectedEmailItem(obj);
                props.updateFollowups(res.checklists)


                enqueueSnackbar(res.message, {variant:'success'});
            })
            .catch((e) => {
                consoleToLog('Error disableEmailFollowupApi', e.response);
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant:'error'
                    })
                    return;
                }
            })
    }

    const showEmailFollowupsPopover = () => {
        return (
            <ClickAwayListener onClickAway={onClickAway}>
                <Grid item className={`menu ${isActive ? "active" : "inactive"}`} style={{width:'100%', textAlign:'left', top:'60px'}}>
                    <ul >
                        {emailMetadata?.followups && emailMetadata?.followups.length > 0 && emailMetadata?.followups.map((followup) => {
                            
                            return  followup &&
                                    <li className={classes.list} key={followup.id}
                                            onClick={() => {
                                                onClickAway();
                                                onEmailFollowupClick(followup);
                                                
                                            }}
                                            style={{borderBottom:'1px solid rgba(0,0,0,0.1)'}}>
                                            <Tooltip title={followup ? followup.subject : ''} arrow>
                                            <a className={classes.followupTextStyle}>{followup.subject}</a>
                                            </Tooltip>
                                    </li>
                        })
                            
                        }

                        
                    </ul>
                </Grid>
            </ClickAwayListener>
        )
    }

    return (
        <Dialog fullScreen 
            open={props.openEmailDetailsModal}
            onClose={props.handleEmailDialogClose}>
                <Grid item>
                    {loading ?
                        <Grid item container justifyContent='center' > 
                            <CircularProgress size={30} style={{marginTop:'32px'}}/>
                        </Grid>
                        :
                        <div className={classes.detailsContainer}>
                            <Grid item container alignItems='center' style={{marginTop:'16px', paddingLeft:'8px'}}>
                                <Grid item>
                                    <Button onClick={() => props.handleEmailDialogClose()} 
                                        className='header_back_icon'>
                                        <ArrowBackIcon />
                                    </Button>
                                </Grid>
                            </Grid>

                            <Grid item md={12} style={{marginTop:'8px', padding:'0px'}}>

                            <Grid item container>
                                <Grid item md={9} className={classes.emailDetailContainer}>
                                    <Grid item container style={{padding:'12px 0px'}}>
                                        <Grid item md={1} className={classes.avatarColumn}>

                                        </Grid>

                                        <Grid item md={11}>
                                            <Typography className={classes.emailSubjectStyle}>
                                                {emailObj?.subject}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item container>
                                        <Grid item md={1} className={classes.avatarColumn}>
                                            <Avatar className={classes.avatarStyles} style={{marginLeft:'8px'}}>
                                                {fromName ? finalStr : 'CN'}
                                            </Avatar>
                                        </Grid>

                                        <Grid item md={11}>
                                            <Grid item container style={{marginBottom:'16px'}} justifyContent='space-between'>
                                                <Grid item>

                                                    <div style={{display:'flex', alignItems:'center', marginLeft:'auto'}}>
                                                        <Typography className={classes.fromHeadingStyle}>
                                                            {emailObj?.from.name}
                                                        </Typography>

                                                        <Typography variant='body2' style={{marginLeft:'4px'}}>
                                                            {`<${emailObj?.from.email}>`}
                                                        </Typography>
                                                    </div>
                                                    
                                                    <Typography style={{display:'flex', alignItems:'center'}}>
                                                        <span className={classes.toText}> 
                                                            to
                                                        </span>
                                                        <span className={classes.allrecipientStyle}>
                                                            {arrList?.map((email) => email).join(', ')} 
                                                        </span>
                                                        <ArrowDropDownOutlinedIcon fontSize='small' 
                                                            onClick={handlePopoverOpen}
                                                            className={classes.dropdownArrowIconStyle}/>
                                                    </Typography>
                                                    {showRecipientPopover()}
                                                </Grid>

                                                <Grid item style={{display:'flex', alignItems:'center', marginTop:'-24px'}}>
                                                    <Typography className={classes.emailDate}>
                                                        {moment(`${stringDate}`).format('h:mm A') + ' ' + `(${moment(`${stringDate}`)?.fromNow()})`}
                                                    </Typography>

                                                    <Tooltip title='Reply' arrow>
                                                        <Button onClick={onReplyClick} 
                                                            className={classes.emailActionIcons}>
                                                            <img src={showEmailIcons('reply')} />
                                                        </Button>
                                                    </Tooltip>
                                                    
                                                    {arrList && arrList.length > 1 &&
                                                    <Tooltip title='Reply All' arrow>
                                                        <Button onClick={onReplyAllClick} 
                                                            className={classes.emailActionIcons}>
                                                            <img src={showEmailIcons('replyAll')} />
                                                        </Button>
                                                    </Tooltip>
                                                    }
                                                    
                                                    <Tooltip title='Forward' arrow>
                                                        <Button  onClick={onForwardClick}
                                                            className={classes.emailActionIcons}>
                                                            <img src={showEmailIcons('forward')} />
                                                        </Button>
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>

                                            <div>
                                                <Typography variant='subtitle1' id={`${emailObj?.email_uuid}_top_content`}>
                                                </Typography>

                                                {emailObj?.part2 && emailObj?.part2.length !== 0 &&
                                                <MoreHorizIcon className={classes.collapsibleBtn}
                                                style={{color: showEmailBody ? 'white' : 'black',
                                                        backgroundColor: showEmailBody ? 'black' : 'white'}}
                                                onClick={() => setShowEmailBody(!showEmailBody)}/>}

                                                <Typography variant='subtitle1' style={{display: showEmailBody ? 'block' : 'none'}} 
                                                    id={`${emailObj?.email_uuid}_content`}>
                                                </Typography>

                                                    
                                                {emailObj && emailObj.attachments && emailObj.attachments.length > 0 &&
                                                    <Grid item container alignItems='center' spacing={1} style={{marginTop:'24px'}}>
                                                        {
                                                            emailObj.attachments.map((attachmentObj) => {
                                                                return  attachmentObj.disposition !== 'inline' && attachmentObj?.name &&
                                                                        <Grid item>
                                                                            <Typography className='attachment-link' 
                                                                                onClick={() => {
                                                                                    setAttachmentName(attachmentObj.name);
                                                                                    onAttachmentClick(attachmentObj);
                                                                                }}>
                                                                                <img src={showImg(attachmentObj)}
                                                                                    style={{width:'16px', height:'16px', marginRight:'7px'}}/>
                                                                                {attachmentObj.name}

                                                                                {attachmentLoading && attachmentObj.name === attachmentName && <CircularProgress size={20} style={{marginLeft:'12px'}}/>} 
                                                                            </Typography>
                                                                            <Link
                                                                                style={{ display: "none" }}
                                                                                id={`${emailObj?.email_uuid}_attachments_container`}
                                                                                target="_blank"
                                                                                onClick={(e) => e.stopPropagation()}
                                                                                download
                                                                            ></Link>
                                                                        </Grid>
                                                            })
                                                        }
                                                    </Grid>
                                                }
                                            </div>
                                            {/* <Typography variant='subtitle1' dangerouslySetInnerHTML={{ __html: getReadableNotesText(emailObj?.text) }}>
                                            </Typography> */}
                                            
                                            <Grid item container alignItems='center' spacing={1} style={{marginTop:'16px'}}>
                                                <Grid item>
                                                    <Button
                                                        onClick={onReplyClick} 
                                                        className={classes.emailActionIcons1} 
                                                        startIcon={<img src={showEmailIcons('reply')} />}>
                                                        Reply
                                                    </Button>
                                                </Grid>

                                                {arrList && arrList.length > 1 &&
                                                <Grid item>
                                                    <Button 
                                                        onClick={onReplyAllClick}
                                                        className={classes.emailActionIcons1} 
                                                        startIcon={<img src={showEmailIcons('replyAll')} />}>
                                                        Reply All
                                                    </Button>
                                                </Grid>}

                                                <Grid item>
                                                    <Button 
                                                        onClick={onForwardClick}
                                                        className={classes.emailActionIcons1}
                                                        startIcon={<img src={showEmailIcons('forward')} />}>
                                                        Forward
                                                    </Button>
                                                </Grid>
                                            </Grid>

                                            <Divider style={{margin:'24px 0px'}}/>
                                            
                                            {props.selectedEmailItem.received &&
                                            <Grid item md={12} 
                                                ref={myDivToFocus}>
                                                <EmailDetailsNotesComponent canScroll={canScroll} 
                                                    setCanScroll={setCanScroll}
                                                    mentionRef={mentionRef}
                                                    selectedEmailItem={props.selectedEmailItem}
                                                    updateEmailArr={props.updateEmailArr}
                                                />
                                            </Grid>
                                            }

                                        </Grid>
                                    </Grid>
                                </Grid>
                                
                                <Grid item md={3} className={classes.actionsContainer}>
                                    <Grid item container direction='column'>
                                        <Typography variant='body1' style={{fontWeight:600}}>
                                            Actions
                                        </Typography>

                                        {emailMetadata.followups && emailMetadata.followups.length > 0 && 
                                        <div style={{position:'relative'}}>
                                            <Button fullWidth 
                                                onClick={onStopEmailFollowupClick}
                                                classes={{
                                                    label: classes.MuiButtonlabel1
                                                }}
                                                startIcon={<HighlightOffOutlinedIcon style={{color:'#dc4f42'}} fontSize='small'/>}
                                                className={classes.actionButtonStyle1}>
                                                Stop Followup
                                            </Button>
                                            {isActive && showEmailFollowupsPopover()}
                                        </div>}

                                        {props.selectedEmailItem.received &&
                                        <Button fullWidth 
                                            classes={{
                                                label: classes.MuiButtonlabel
                                            }}
                                            className={classes.actionButtonStyle}
                                            startIcon={<img src={showEmailIcons('create-checklist')}/>}
                                            onClick={handleDrawerOpen}
                                            >
                                            Create Checklist
                                        </Button>}

                                        <Button fullWidth 
                                            classes={{
                                                label: classes.MuiButtonlabel
                                            }}
                                            className={classes.actionButtonStyle}
                                            startIcon={<AddCircleOutlineIcon fontSize='small'/>}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setCanScroll(true);
                                            }}
                                            >
                                            Add Note
                                        </Button>
                                    </Grid>
                                </Grid>
                                
                                </Grid>
                            </Grid>

                        </div>}
                    </Grid>

                        {
                            open && 
                            <SendEmailDialog
                                openSendEmailDialog={open}
                                handleClose={handleClose}
                                emailObj={emailObj}
                                showEmailBody={showEmailBody}
                                setShowEmailBody={setShowEmailBody}
                                initialEmailObj={initialEmailObj}
                                replyClick={replyClick}
                                stringDate={stringDate}
                                replyAllClick={replyAllClick}
                                toEmails={toEmails}
                                ccEmails={ccEmails}
                                forwardClick={forwardClick}
                                selectedEmailItem={props.selectedEmailItem}
                                fromEmailDetails={true}
                                checklist_id={props.checklist_id}
                                emailAttachments={emailAttachments}
                                setEmailAttachments={setEmailAttachments}
                                fromReplyForwardEmailDetails={true}
                            />
                        }

                        {
                            <Drawer variant="temporary"
                                anchor="right"
                                open={openDrawer}
                                onClose={handleDrawerClose}
                                classes={{
                                    paper: classes.drawerPaper
                                    }}>

                                    <AppBar position="static" className={classes.appBar} elevation={0}>
                                        <Toolbar style={{minHeight:'40px', padding:'0px 16px', }}>
                                            <Typography variant="body1" 
                                                style={{fontWeight:'600', marginRight:'auto'}}>
                                                Create Checklist
                                            </Typography>
                                            <IconButton style={{color:'#202020', marginRight:'-16px'}} 
                                                onClick={handleDrawerClose}
                                            >
                                                <ClearIcon/>
                                            </IconButton>
                                        </Toolbar>
                                    </AppBar>
                                
                                {props.selectedEmailItem.received && 
                                <CreateChecklistModal checklistGroup={props.checklistGroup}
                                    onChecklistCreation={props.onChecklistCreation}
                                    fromEmailDetails={true}
                                    selectedEmailItem={props.selectedEmailItem}
                                    handleDrawerClose={handleDrawerClose}
                                />}
                            </Drawer>
                        }

        </Dialog>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    orgEmailList: state.organizationInfo.orgEmailList,
});

const mapDispatchToProps = (dispatch) => ({
    setEmailSentFrom: (str) => dispatch(setEmailSentFrom(str))
})

export default connect(mapStateToProps, mapDispatchToProps)(EmailDetailsModal);