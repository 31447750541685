import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Typography, Button, Fab, Divider} from '@material-ui/core';
import { MentionsInput, Mention } from "react-mentions";
import {connect} from 'react-redux';
import { useSnackbar } from 'notistack';
import moment from "moment";
import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
import {addNewCommentApi,editChecklistTaskCommentApi,deleteChecklistTaskCommentApi,} from "../../../services/authService";
import { consoleToLog, trackGAEvent } from '../../../util/AppUtil';
import { useIsMount } from '../../../components/useIsMount';

const useStyles = makeStyles((theme) => ({
    checklistDetailBtn1: {
        borderRadius: "5px",
        padding: "2px 10px 2px 10px",
        color: "#656565",
        border: `1px solid #656565`,
        margin: "10px 10px 10px 0px",
    },
    checklistDetailBtn2: {
        borderRadius: "5px",
        padding: "2px 10px 2px 10px",
        color: "#dd584d",
        border: `1px solid #dd584d`,
    },
    checklistDetailActHeading: {
        fontWeight: "600",
        fontSize: "16px",
        marginTop: "10px",
        // marginBottom: "10px",
    },
    activtyIconContainer: {
        backgroundColor: '#3d92ff',
        minHeight: "28px",
        height: "28px",
        width: "28px",
        boxShadow: "none",
    },
    activtycommentIcon: {
        color: "white !important",
        fontSize: "1rem",
        lineHeight:'0 !important',
    },
    activtyhorizontalDivider: {
        display:'flex',
        width: "2px",
        //height: "3.8rem !important",
        backgroundColor: "#cacaca",
        marginTop: "0px",
        marginLeft:'15px',
        marginBottom: "0px",
        border:'none',
        '& last-child' : {
            height: "0",
        }
    },
    activityTime : {
        color:'rgba(0,0,0,0.5)', 
        fontSize:'12px',
    },
}))

const MobileTaskActivities = (props) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    const isMount = useIsMount();

    const [text, setText] = useState("");
    const [mentions, setMentions] = useState(undefined);
    const [commentsArray, setCommentsArray] = useState(props.selectedTask.activity_feed);
    const [selectedCommentForEditing, setSelectedCommentForEditing] = useState(undefined);

    useEffect(() => {
        setCommentsArray(props.selectedTask.activity_feed);
      }, []);
    
      useEffect(() => {
        if (!isMount) {
          setCommentsArray(props.selectedTask.activity_feed);
        }
      }, [props.selectedTask]);
    
    
      useEffect(() => {
        if (!isMount) {
          setCommentsArray(props.selectedTask.activity_feed);
        }
      }, [props.selectedTask.activity_feed]);

    const clearCurrentCommentData = () => {
        setText("");
        setMentions([]);
        setSelectedCommentForEditing(undefined);
    };

    // for edit and delete button
    const currentAuthUser = props.auth.user.id;

    let usersData = props.members.map((member) => ({
        id: member.user.id,
        display: `${member.user.firstname} ${member.user.lastname}`,
    }));

    const getReadableNotesText = (activity) => {
        var readableActivity =  activity.activity_text;
        let removehtmltags =  readableActivity.replace(/<[^>]*>?/gm, '');
        let  numberPattern = /\d+/g;
        let user_id = removehtmltags.match(numberPattern);
        let num = user_id?.join()
        let mention_user_id = Number(num);
        
        readableActivity = readableActivity.replace(/\n\r?/g, "<br />");//add <br>
        readableActivity = readableActivity.split('@[').join(`<b style=color:${mention_user_id === currentAuthUser ? '#0B4C8C' :'black'};background:#E8F5FB;padding:4px;border-radius:4px>`);
        readableActivity = readableActivity.split(']').join("</b>");
        readableActivity = readableActivity.split(/\(.+?\)/).join('');
        return readableActivity;
    }

    const handleSaveComment = (e) => {
        e.preventDefault();
    
        const textValue = text.trim();
    
        if (!textValue) {
          enqueueSnackbar("Comment can not be empty!", {
            variant: "error",
          });
        } else {
          if (selectedCommentForEditing) {
            //edit note
            //if note is not changed do nothing
            if (selectedCommentForEditing === text) {
              consoleToLog("Comment is not changed!");
              return;
            } else {
              onCommentEditApiCall(text);
            }
          } else {
            //add comment
            addNewCommentApiCall(text);
          }
          setText("");
        }
    };

    const addNewCommentApiCall = () => {
        const checklist_task_id = props.selectedTask.id;
        const organization_id = props.selectedOrganization.organization.id;
        const mention_users = mentions.map((mentionObj) => mentionObj.id);
    
        addNewCommentApi(checklist_task_id, mention_users,  text, organization_id)
          .then((response) => {
            consoleToLog("addNewCommentApi res", response);
            //var updatedCommentArray = [response.data.activity_feed, ...commentsArray];
            enqueueSnackbar("Comment Added Successfully", {
              variant: "success",
            });
            //setCommentsArray(updatedCommentArray);
            clearCurrentCommentData();
            
            //trackGAEvent(props.selectedOrganization.organization.name, 'Add Comment', `${props.auth.user.firstname} ${props.auth.user.lastname} added comment to task`);
            
            const comment_count = response.data.activity_feed.comment.comment_count;
            // to parent
            props.onCommentsUpdated(response.data.activity_feed, comment_count);
          })
          .catch((e) => {
            consoleToLog("addNewCommentApi err", e);
          });
      };

      const onEditClick = (e, activityObj) => {
        const commentToBeUpdated = activityObj.comment;
        setText(commentToBeUpdated.text);
        setSelectedCommentForEditing(commentToBeUpdated);
        setMentions([]);
        props.onScroll();
      };
    
    const onCommentEditApiCall = () => {
        const organization_id = props.selectedOrganization.organization.id;
        const checklist_task_id = props.selectedTask.id;
        const mention_users = mentions.map((mentionObj) => mentionObj.id);
        const comment_id = selectedCommentForEditing.id;
    
        editChecklistTaskCommentApi(checklist_task_id,  mention_users,  text, comment_id,  organization_id)
          .then((response) => {
            const res = response.data;
            consoleToLog("Response editChecklistTaskCommentApi: ", res);
            /* const updatedEditCommentArray = commentsArray.map((activityObj) => {
              return activityObj.id === res.activity_feed.id
                ? res.activity_feed
                : activityObj;
            }); */
            enqueueSnackbar("Comment Updated Successfully", {
              variant: "success",
            });
            //setCommentsArray(updatedEditCommentArray);
            clearCurrentCommentData();
            trackGAEvent(props.selectedOrganization.organization.name, 'Edit Comment', `${props.auth.user.firstname} ${props.auth.user.lastname} task comment edited`);
            // to parent
            props.onCommentsEdited(res.activity_feed);
          })
          .catch((e) => {
            consoleToLog("editChecklistTaskCommentApi error", e.response);
          });
    };

    const onCommentDeleteApiCall = (activity) => {
        const comment_id = activity.comment.id;
        const checklist_task_id = activity.task_id;
        const organization_id = props.selectedOrganization.organization.id;
    
        if (window.confirm("Are you sure to delete the comment??")) {
          deleteChecklistTaskCommentApi(comment_id, checklist_task_id, organization_id)
            .then((res) => {
              consoleToLog("deleteChecklistTaskCommentApi res", res);
              var updatedDeleteCommentArray = commentsArray.filter((activityObject) =>
                  activityObject.id !== res.data.activity_feed.id
              );
              enqueueSnackbar("Comment Deleted Successfully", {
                variant: "success",
              });
              setCommentsArray(updatedDeleteCommentArray);
              props.onCommentsdeleted(updatedDeleteCommentArray);
            })
            .catch((err) => {
              consoleToLog("deleteChecklistTaskCommentApi err", err);
            });
        }
    };

    const displayActivity = () => {
        return(
            <div className = "commentsParent">
            {commentsArray && commentsArray.length > 0 ? (
                commentsArray.map((activity) => {
                const innerHtmlContent = getReadableNotesText(activity);
                const time = moment(activity.created_at).fromNow();
        
                return(
                    <div style={{display:'flex', marginTop:'16px'}} key={activity.id}> 
                        <Grid item style={{ marginTop: "-3px"}}>
                            <Fab className={classes.activtyIconContainer}>
                                <ChatBubbleOutlineIcon className={classes.activtycommentIcon} />
                            </Fab>
                            <Divider orientation="vertical" className={classes.activtyhorizontalDivider} />
                        </Grid>
                        <Grid item xs={12}  style={{marginLeft:'8px', color:'#212121'}}>
                            <Grid item style={{display:'flex', width:'100%', position:'relative'}}>
                                <Typography style={{fontSize:'14px'}}
                                    dangerouslySetInnerHTML={{ __html: innerHtmlContent }}
                                ></Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} style={{display:'flex', justifyContent:'space-between'}}>
                                {((activity.action_type === "comments") &&
                                (currentAuthUser === activity.user.id)) ?
                                <div style={{display: "inline-flex", marginLeft:'-18px'}}>
                                    <Button size="small"
                                        style={{padding:'4px'}}
                                        onClick={(e) => onEditClick(e, activity)}
                                    >
                                        Edit
                                    </Button>
                                    <Button size="small"
                                        style={{padding:'4px'}}
                                        onClick={()=>onCommentDeleteApiCall(activity)}
                                    >
                                        Delete
                                    </Button>
                                </div> : <></>}

                                <div className={classes.activityTime}> 
                                    {time} 
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                )
            })) : (
            <Grid item container style={{ marginTop: "1rem" }}>
                <Grid item sm={12} xs={12} style={{ textAlign: "center", padding:'14px 0px' }}>
                    <Typography variant="body1">No Activities Found</Typography>
                </Grid>
            </Grid>
            )
          }
          </div>
        )
    }

    return (
        <div>
            <Grid item xs={12} sm={12}>
                <div className="mentionWrapper">
                    <MentionsInput
                        markup="@[__display__](__id__)"
                        className="mentions"
                        placeholder="Use @ to notify a team member"
                        value={text}
                        onChange={(e, newValue, newPlainTextValue, mentions) => {
                            setText(e.target.value);
                            setMentions(mentions);
                        }}
                    >
                        <Mention className="mentions__mention"  data={usersData}/>
                    </MentionsInput>
                </div>
                    <Button variant="outlined"
                        className={classes.checklistDetailBtn1}
                        onClick={handleSaveComment}
                        name="send"
                    >
                        Send
                    </Button>
                    <Button variant="outlined"
                        className={classes.checklistDetailBtn2}
                        onClick={() => {
                            setText("");
                        }}
                        >
                        Discard
                    </Button>
            </Grid>
            <Grid item xs={12} sm={12}>
                <Typography className={classes.checklistDetailActHeading}>
                    Activities
                </Typography>
                {displayActivity()}
            </Grid>
        </div>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    members: state.organizationInfo.members,
  });

export default connect(mapStateToProps)(MobileTaskActivities);