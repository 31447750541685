import React, {useState} from 'react';
import RightToLeftSideDrawer from '../RightToLeftSideDrawer';
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Button, Link, Divider, CircularProgress } from "@material-ui/core";
import ClearIcon from '@material-ui/icons/Clear';
import {downloadInvoiceTemplateApi, downloadTemplateApi, importCompanyListApi, onImportInvoiceApi} from '../../services/authService';
import { useSnackbar } from 'notistack';
import {connect} from 'react-redux'
import {consoleToLog, trackGAEvent, OverlayMessage} from '../../util/AppUtil'; 
import { v4 as uuidv4 } from 'uuid';
import { OverlayView, setOverlayToken, setOverlayView, setOvelayMessage } from '../../actions/overlay';

const useStyles = makeStyles((theme) => ({
    fileUploaderContainer: {
        display: 'flex !important',
        width:'100%',
        height: '6rem !important',
        justifyContent:'center',
        alignItems: 'center',
        cursor: 'pointer',
        borderRadius: '4px !important',
      },
     attachIcon : {
        fontSize : '16px',
        marginRight: '8px',
        marginTop: '2px'
     }, 
    linkText:{
        color: "#20a8e0",
        fontSize: "14px",
        fontWeight: "600",
        marginLeft: "2px",
        cursor: "pointer",
        textDecoration:'none'
    },
    clearIcon: {
        minWidth:"20px", 
        padding:"0", 
        marginLeft:'8px'
    },
    circularProgress: {
        marginLeft: 0,
        marginRight: theme.spacing(2),
        color: 'white'
      },
    errorHeading : {
        fontWeight: "600", 
        fontSize:'24px', 
        color:'#ccc', 
        marginTop:'16px', 
        marginBottom:'4px'
    }  
 
}))

const SetupCompanyModal = (props) => {
    const [attachment, setAttachment] = useState([]);
    const [attachment1, setAttachment1] = useState([]);
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();

    const [errorReport, setErrorReport] = useState([]);
    const [companySetupLoading, setCompanySetupLoading] = useState(false);
    const [invoiceSetupLoading, setInvoiceSetupLoading] = useState(false);

    const dragOver = (e) => {
        e.preventDefault();
    }
    
    const dragEnter = (e) => {
        e.preventDefault();
    }
    
    const dragLeave = (e) => {
        e.preventDefault();
    }
    
    const fileDrop = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files[0];
        consoleToLog(files);
        setAttachment([...attachment, files]);
    }

    const fileDrop1 = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files[0];
        consoleToLog(files);
        setAttachment1([...attachment1, files]);
    }

    const onAttachmentSelected = (e) => {
        const { target: { files } } = e;
        setAttachment([...attachment, e.target.files[0]]);
    }

    const onAttachmentSelected1 = (e) => {
        const { target: { files } } = e;
        setAttachment1([...attachment1, e.target.files[0]]);
    }

    const onRemoveAttachment = (e, isInvoice) => {
        e.stopPropagation();
        if(isInvoice) setAttachment1([]);
        else setAttachment([]);
    }

    const showAttachment = (file, index, isInvoice) => {
        return (
            <Grid item container>
                 <Typography key={index} variant="body2" color="primary">
                    {file.name}
                </Typography>
                <Button onClick={(e)=>onRemoveAttachment(e, isInvoice)} 
                    className={classes.clearIcon}>
                    <ClearIcon fontSize="small"/>
                </Button>
            </Grid>
        );
    }

    const onImportInvoiceClick= (e) => {
        e.preventDefault();

        const file = attachment1[0];
        if (!file) {
            enqueueSnackbar('Please attach a file', {
                variant:'error'
            });
            return;
        }
        const organization_id = props.selectedOrganization.organization.id;
        //const setup_type = this.state.isGSTImport ? 'gst_setup': 'it_setup';

        setInvoiceSetupLoading(true);

        var randomtoken = uuidv4();

        onImportInvoiceApi(file, organization_id, randomtoken)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response onImportInvoiceClick: ", res);
                setInvoiceSetupLoading(false);

                //show msg
                    enqueueSnackbar(res.message, {
                        variant:'success'
                    });
                    props.setOvelayMessage(OverlayMessage.IMPORT_MSG);
                    props.setOverlayView(OverlayView.SHOW_OVERLAY_LOADING);
                    props.setOverlayToken(randomtoken);
                    props.handleCloseCompanySetupDrawer();

                    trackGAEvent(props.selectedOrganization.organization.name, 'Import Invoice Setup Template', `${props.auth.user.firstname} ${props.auth.user.lastname}`);

            })
            .catch((e) => {
                consoleToLog("onImportInvoiceClick error", e.response);
                setInvoiceSetupLoading(false);
                props.setOverlayView(OverlayView.NO_VIEW);
                props.setOverlayToken("");
                if(e.response.status === 400 && e.response.data.error_report && e.response.data.error_report.length > 0){
                    setErrorReport(e.response.data.error_report);
                    setAttachment1([]);
                }
                if (e.response.data && e.response.data.message ) {
                    enqueueSnackbar(e.response.data.message, {
                        variant:'error'
                    });
                    return false;
                }
            });
            
    }

    const onImportCompanyClick = (e) => {
        e.preventDefault();

        const file = attachment[0];
        if (!file) {
            enqueueSnackbar('Please attach a file', {
                variant:'error'
            });
            return;
        }
        const organization_id = props.selectedOrganization.organization.id;
        //const setup_type = this.state.isGSTImport ? 'gst_setup': 'it_setup';

        setCompanySetupLoading(true);

        var randomtoken = uuidv4();
        importCompanyListApi(file, organization_id, randomtoken)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response importCompanyListApi: ", res);
                setCompanySetupLoading(false);

                //show msg
                if (res.error_report && res.error_report.length > 0) {
                    props.setOverlayView(OverlayView.NO_VIEW);
                    props.setOverlayToken("");
                    enqueueSnackbar(res.message, {
                        variant:'error'
                    })
                }
                else {
                    enqueueSnackbar(res.message, {
                        variant:'success'
                    });
                    props.setOvelayMessage(OverlayMessage.IMPORT_MSG);
                    props.setOverlayView(OverlayView.SHOW_OVERLAY_LOADING);
                    props.setOverlayToken(randomtoken);
                    props.handleCloseCompanySetupDrawer();

                    trackGAEvent(props.selectedOrganization.organization.name, 'Import Setup Template', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
                }

                setErrorReport(res.error_report);
                setAttachment([]);
            })
            .catch((e) => {
                consoleToLog("importCompanyListApi error", e.response);
                setCompanySetupLoading(false);
                props.setOverlayView(OverlayView.NO_VIEW);
                props.setOverlayToken("");
                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant:'error'
                    });
                    return false;
                }
            });
            
    }

    const downloadTemplateFile = () =>{
        const organization_id = props.selectedOrganization.organization.id;
        var randomtoken = uuidv4();

        props.setOverlayView(OverlayView.SHOW_OVERLAY_LOADING);
        props.setOverlayToken(randomtoken);
        props.handleCloseCompanySetupDrawer();
        props.setOvelayMessage(OverlayMessage.EXPORT_MSG);
        
        downloadTemplateApi(organization_id, randomtoken) 
            .then((response) => {
                const res = response.data;
                consoleToLog('Response downloadTemplateApi ', res);
            })
            .catch((e) => {
                consoleToLog("downloadTemplateApi error", e.response);
                props.setOverlayView(OverlayView.NO_VIEW);
                props.setOverlayToken("");

                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant:'error'
                    });
                    return false;
                }
            });
    }

    const downloadInvoiceTemplateFile = () =>{
        const organization_id = props.selectedOrganization.organization.id;
        var randomtoken = uuidv4();

        props.setOverlayView(OverlayView.SHOW_OVERLAY_LOADING);
        props.setOverlayToken(randomtoken);
        props.handleCloseCompanySetupDrawer();
        props.setOvelayMessage(OverlayMessage.EXPORT_MSG);
        
        downloadInvoiceTemplateApi(organization_id, randomtoken) 
            .then((response) => {
                const res = response.data;
                consoleToLog('Response downloadInvoiceTemplateApi ', res);
            })
            .catch((e) => {
                consoleToLog("downloadInvoiceTemplateApi error", e.response);
                props.setOverlayView(OverlayView.NO_VIEW);
                props.setOverlayToken("");

                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant:'error'
                    });
                    return false;
                }
            });
    }

    const closeDrawer = () => {
        setErrorReport([]);
        setAttachment([]);
        setAttachment1([]);
        props.handleCloseCompanySetupDrawer()
    }

    return (
        <RightToLeftSideDrawer title="Import Client Data"
            openDrawer={props.openCompanySetupDrawer}
            closeDrawer={closeDrawer}
            fromOnboardingComponent={props.fromOnboardingComponent}
        >
            <div className="modal__main">
                <Grid item>
                    <div onDragOver={dragOver}
                        onDragEnter={dragEnter}
                        onDragLeave={dragLeave}
                        onDrop={fileDrop}
                        className={classes.fileUploaderContainer}
                        style={{border: (attachment && attachment.length > 0) ? 'none' : '2px dashed #c5bebe'}}>

                    {attachment && attachment.length > 0 ?
                    <div>
                            {(attachment && attachment.length > 0) &&
                                    attachment.map((file, index) => {
                                        return showAttachment(file, index, false);
                            })}
                    </div>
                    :
                    <label>   
                        <input style={{display:'none'}}
                            type="file"
                            id="companyListFile"
                            accept=".xlsx"
                            onChange={onAttachmentSelected}
                        />

                        <Typography variant="body2" style={{cursor:'pointer'}}>
                            <i className={`flaticon-attachment ${classes.attachIcon}`}></i>
                            Attach File
                        </Typography>
                    </label>  
                    }  
                    </div>    

                    <Grid item style={{marginTop:'8px'}}>
                        <Typography variant="body2">
                            Use  <Link className={classes.linkText} 
                                    onClick={()=>{
                                        if(props.overlay && props.overlay.showOverlayView === OverlayView.SHOW_OVERLAY_LOADING){
                                            enqueueSnackbar("Another download request is already in progress. Please be patient.", ({
                                                variant: 'error'
                                            }));
                                        }
                                        else downloadTemplateFile();

                                    }}>this template </Link> 
                                to import your client data
                        </Typography> 
                    </Grid>

                    <Grid item style={{marginTop:'24px'}}>
                        <Button onClick={onImportCompanyClick} 
                            variant="contained" color="primary" 
                            style={{width:'130px'}}>
                            {companySetupLoading && <CircularProgress size={20} className={classes.circularProgress}/>}
                            Import
                        </Button> 
                    </Grid>
                </Grid>
                                    
                {(props.clientList && props.clientList.length > 0 && 
                props.accountList && props.accountList.length > 0) && !props.fromOnboardingComponent &&
                <Grid item sm={12}>
                    <Typography variant="body1" 
                        style={{ marginBottom:"0px", marginTop: "50px", fontWeight:'600'}}> 
                        Invoice Setup
                    </Typography>

                    <Grid item style={{marginTop: "8px"}}>
                        <div onDragOver={dragOver}
                            onDragEnter={dragEnter}
                            onDragLeave={dragLeave}
                            onDrop={fileDrop1}
                            className={classes.fileUploaderContainer}
                            style={{border:(attachment1 && attachment1.length > 0) ? 'none' : '2px dashed #c5bebe'}}
                        >

                        {attachment1 && attachment1.length > 0 ?
                        <div>
                                {(attachment1 && attachment1.length > 0) &&
                                        attachment1.map((file, index) => {
                                            return showAttachment(file, index, true);
                                })}
                        </div>
                        :
                        <label>   
                            <input style={{display:'none'}}
                                type="file"
                                id="companyListFile"
                                accept=".xlsx"
                                onChange={onAttachmentSelected1}
                            />

                            <Typography variant="body2" style={{cursor:'pointer'}}>
                                <i className={`flaticon-attachment ${classes.attachIcon}`}></i>
                                Attach File
                            </Typography>
                        </label>  
                        }  
                        </div>    

                        <Grid item style={{marginTop:'8px'}}>
                            <Typography variant="body2">
                                Use  <Link className={classes.linkText} 
                                        onClick={()=>{
                                            if(props.overlay && props.overlay.showOverlayView === OverlayView.SHOW_OVERLAY_LOADING){
                                                enqueueSnackbar("Another download request is already in progress. Please be patient.", ({
                                                    variant: 'error'
                                                }));
                                            }
                                            else downloadInvoiceTemplateFile();

                                        }}>this template </Link> 
                                to import your invoice data
                            </Typography> 
                        </Grid>

                        <Grid item style={{marginTop:'24px'}}>
                            <Button onClick={onImportInvoiceClick} 
                                variant="contained" color="primary" 
                                style={{width:'130px'}}>
                                {invoiceSetupLoading && <CircularProgress size={20} className={classes.circularProgress}/>}
                            Import
                            </Button> 
                        </Grid>
                    </Grid>
                </Grid>}


                <Grid item>
                    {errorReport && errorReport.length > 0 &&
                        <div>
                            <Typography className={classes.errorHeading}
                            >Error Report</Typography>
                            <Divider light style={{padding:'0', margin:'0'}}/>
                        </div>
                    }
                    {errorReport && errorReport.length > 0 &&
                        errorReport.map((error, index) => {
                            return <div key={index}>
                                    <Typography style={{color:'red', padding:'8px 0px'}}>{index + 1}. {error}</Typography>
                                    <Divider light/>
                                    </div>
                        })
                    }
                </Grid>
            </div>    
            
        </RightToLeftSideDrawer>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    overlay: state.overlay,
    accountList: state.organizationInfo.accountList
});

const mapDispatchToProps = (dispatch) => ({
    setOverlayView: (exportView) => dispatch(setOverlayView(exportView)),
    setOverlayToken: (randomToken) => dispatch(setOverlayToken(randomToken)),
    setOvelayMessage: (overlayMessage) => dispatch(setOvelayMessage(overlayMessage)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SetupCompanyModal);