import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Typography, TextField, Button, Drawer, AppBar, Toolbar, IconButton, CircularProgress,
    MenuItem, Checkbox} from '@material-ui/core';
import { connect } from 'react-redux';
import ClearIcon from '@material-ui/icons/Clear';
import {useIsMount} from './useIsMount';
import {addCustomFieldApi, editCustomFieldApi} from '../services/clientService';
import { consoleToLog, trackGAEvent } from '../util/AppUtil';
import { useSnackbar } from 'notistack';
import {addOrgCustomField, updateOrgCustomField} from '../actions/selectedOrganization';


const useStyles = makeStyles((theme) => ({
    drawer: {
    //    width: drawerWidth,
        flexShrink: 0
    },
    drawerPaper: {
        width: '30%', 
        //height: 'calc(100vh - 51px)',
        flexGrow:1,
        top:'51px'
    },
    appBar: {
        background:'#F9F9F9',
        height:'45px',
        color:'#202020'
    },
    customMarginTop : {
        marginTop:"16px"
    },
    MuiCheckboxColorPrimary : {
        color:"#d3d3d3 !important",
      },
          
    Muichecked: {
        color:'#202020 !important'
    },
    customMarginBottom: {
        marginBottom:'-4px'
    },
    dialogboxFooter: {
        display: "flex",
        position: "absolute",
        bottom: "0px",
        left: "0",
        right: "0",
        padding: "12px 0px 12px 12px",
        zIndex: "15",
        background: "#fff",
        borderTop: "1px solid #ccc",
    },
}));

const CustomFieldDrawer = (props) => {
    const {fromEditCustomFields} = props;
    const classes = useStyles(fromEditCustomFields);
    const isMount = useIsMount();
    const {enqueueSnackbar} = useSnackbar();

    const [customFieldName, setCustomFieldName] = useState('');
    const [defaultValue, setDefaultValue] = useState('none');
    const [showInvoice, setShowInvoice] = useState(false);
    const [errorNo, setErrorNo] = useState(undefined);
    const [errorMsg, setErrorMsg] = useState('');
    const [loading, setLoading] = useState(false);
    const [defaultValueArr, setDefaultValueArr] = useState([
        {key: "Tax Id", value: "{{client.tax_id}}"},
        {key: "Address", value: "{{client.address}}"},
        {key: "State", value: "{{client.state}}"},
        {key: "Country", value: "{{client.country}}"},
        {key: "Name", value: "{{client.name}}"},
    ]);
    const [defaultType, setDefaultType] = useState('none');
    const [defaultTypeArr, setDefaultTypeArr] = useState([
        {key:'Text', value:'text'},
        {key:'Password', value:'password'},
        // {key:'Image / Document', value:'file'}
    ]);

    useEffect(() => {
        if(!isMount) {
            if(props.selectedCustomField) {
                setCustomFieldName(props.selectedCustomField.name);
                setShowInvoice(props.selectedCustomField.show_invoice);
                setDefaultValue(props.selectedCustomField.default_value);
                setDefaultType(props.selectedCustomField.type)
            }
        }
    }, [props.selectedCustomField]);

    const closeDrawer = () => {
        props.setOpenCustomFieldDrawer(false);
        if(props.selectedCustomField) props.setSelectedCustomField(undefined);
    }

    const onCancelClick = () => {
        setCustomFieldName('');
        setShowInvoice(false);
        setDefaultValue('none');
        setDefaultType('none');
        setErrorNo(undefined);
        setErrorMsg('');
        closeDrawer();
        
    }

    const onShowInvoiceClicked = () =>{
        setShowInvoice(!showInvoice);
    }

    const onDefaultValueChanged = (e) => {
        setDefaultValue(e.target.value);
    }

    const onDefaultTypeChanged = (e) => {
        setDefaultType(e.target.value);
        setErrorNo(undefined);
        setErrorMsg('');
    }

    const onCreateOrUpdateCustomField = () => {
        const name = customFieldName;
        if(name === '') {
            setErrorNo(0);
            setErrorMsg('* Please enter custom field name');
            return;
        }

        if(defaultType === 'none') {
            setErrorNo(1);
            setErrorMsg('* Please select a type');
            return;
        }

        const default_value = defaultValue === 'none' ? '' : defaultValue;

        const organization_id = props.selectedOrganization.organization.id;
        setLoading(true);

        if(props.selectedCustomField) {
            editCustomFieldApi(props.selectedCustomField.id, name, default_value, defaultType)
                .then((response) => {
                    const res = response.data;
                    consoleToLog('Response editCustomFieldApi: ', res);
                    setLoading(false);

                    enqueueSnackbar('Custom Field Updated Successfully', {variant: 'success'});

                    trackGAEvent(props.selectedOrganization.organization.name, 'Custom Field Updated', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
                    props.updateOrgCustomField(res);
                    onCancelClick();

                })
                .catch((e) => {
                    consoleToLog("Error editCustomFieldApi", e);
                    setLoading(false);
                    if(e.response.status && e.response.status.message) {
                        enqueueSnackbar(e.response.status.message, {variant: 'error'});
                        return;
                    }
                })
        
        } else {
            addCustomFieldApi(organization_id, name, default_value, defaultType)
                .then((response) => {
                    const res = response.data;
                    consoleToLog('Response addCustomFieldApi: ', res);
                    setLoading(false);

                    enqueueSnackbar('Custom Field Added Successfully', {variant: 'success'});
                    trackGAEvent(props.selectedOrganization.organization.name, 'Custom Field Added', `${props.auth.user.firstname} ${props.auth.user.lastname}`);

                    props.addOrgCustomField(res);
                    if(props.fromEditCustomFields) {
                        props.onItemClick(res, props.currentIndex);
                        props.setSwitchFocus(true);
                    }
                    onCancelClick();

                })
                .catch((e) => {
                    consoleToLog("Error addCustomFieldApi", e);
                    setLoading(false);
                    if(e.response) {
                        enqueueSnackbar(e.response.data.message, {variant: 'error'});
                        return;
                    }
                })
        }
    }

    return (
        <Drawer 
            anchor="right"
            open={props.openCustomFieldDrawer}
            onClose={closeDrawer}
            className={props.fromEditCustomFields && 'drawerContainer'}
            classes={{
                paper: !props.fromEditCustomFields && classes.drawerPaper
            }}
            >
            <AppBar position="static" className={classes.appBar} elevation={0}>
                <Toolbar style={{minHeight:'40px', padding:'0px 16px', }}>
                    <Typography variant="body1" 
                        style={{fontWeight:'600', marginRight:'auto'}}>
                        Custom Field
                    </Typography>
                    <IconButton style={{color:'#202020', marginRight:'-16px'}} 
                        onClick={onCancelClick}>
                            <ClearIcon/>
                    </IconButton>
                </Toolbar>
            </AppBar>
                <Grid item container style={{padding:'16px'}}>
                    <Grid item md={12} lg={12}>
                        <Typography variant="subtitle1" 
                            className={classes.customMarginBottom}>
                            Custom Field Name
                        </Typography>

                        <TextField fullWidth 
                            id="tax_name" 
                            variant="outlined"
                            type="text"
                            margin="dense"
                            value={customFieldName}
                            autoComplete="off"
                            placeholder="Enter custom field name"
                            InputLabelProps={{style: {fontSize: 13, color: "#ccc"}}}
                            onChange={(e) => {
                                setCustomFieldName(e.target.value)
                                setErrorNo(undefined);
                                setErrorMsg('');
                            }}
                            error={errorNo === 0}
                            helperText={errorNo === 0 ? errorMsg : ''}
                        />
                    </Grid>
                    
                    <Typography variant="subtitle1" style={{marginTop:"8px"}}
                            className={classes.customMarginBottom}>
                            Type
                    </Typography>
                    <Grid item md={12} lg={12}>
                        <TextField
                            style={{ width: "100%"}} 
                            id="def_type"
                            margin="dense"
                            select
                            //label="Select Type"
                            variant="outlined"
                            value={defaultType}
                            onChange={onDefaultTypeChanged}
                            InputLabelProps={{style: {fontSize: 13}}}
                            error={errorNo === 1}
                            helperText={errorNo === 1 ? errorMsg : ''}
                        >
                            <MenuItem value='none'>None</MenuItem>
                            {
                                defaultTypeArr && defaultTypeArr.map((op) => {
                                return  <MenuItem key={op.key} value={op.value}>
                                            {op.key}
                                        </MenuItem>
                                })
                            }
                        </TextField>    
                    </Grid>

                            
                    {defaultType === 'text' &&
                    <Grid item md={12} lg={12}>        
                        <Typography variant="subtitle1" style={{marginTop:"8px"}}
                                className={classes.customMarginBottom}>
                                Default value
                        </Typography>
                    
                        <TextField
                            style={{ width: "100%"}} 
                            id="def_value"
                            margin="dense"
                            select
                            //label="Default Value"
                            variant="outlined"
                            value={defaultValue}
                            onChange={onDefaultValueChanged}
                            InputLabelProps={{style: {fontSize: 13}}}
                        >
                            <MenuItem value='none'>None</MenuItem>
                            {
                                defaultValueArr && defaultValueArr.map((op) => {
                                return  <MenuItem key={op.key} value={op.value}>
                                            {op.key}
                                        </MenuItem>
                                })
                            }
                        </TextField>    
                    </Grid>}

                    {/* <Grid item md={12} lg={12}
                        style={{display:'flex', alignItems:'center', margin:"8px 0px 2px -10px"}}>
                        <Checkbox
                            onChange={(e) => onShowInvoiceClicked(e)}
                            checked={showInvoice}
                            name="invoiceCheck"
                            color="primary"
                            style={{color:"#ccc !important"}}
                            classes={{
                                colorPrimary: classes.MuiCheckboxColorPrimary,
                                checked: classes.Muichecked
                            }}
                        />
                        <Typography variant='subtitle1' 
                            style={{marginLeft:'-4px'}}
                            className={classes.labelStyles}>
                            Show on invoice
                        </Typography>
                        
                    </Grid> */}

                    <div className={props.fromEditCustomFields ? classes.dialogboxFooter : "modal__footer"}>
                        <Button variant="contained" color="primary" 
                            onClick={onCreateOrUpdateCustomField}
                            disabled={loading}
                            className="modal__button">
                            {loading && <CircularProgress size={15} className='loading__style'/>}
                            Save    
                        </Button>
                        <Button variant="outlined" style={{
                            minWidth: '130px',
                        }} onClick={()=>onCancelClick()}>
                            Cancel
                        </Button>
                    </div> 
                </Grid>
            </Drawer>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    customFieldList: state.organizationInfo.customFieldList,
});

const mapDispatchToProps = (dispatch) => ({
    addOrgCustomField: (custom_field) => dispatch(addOrgCustomField(custom_field)),
    updateOrgCustomField: (custom_field) => dispatch(updateOrgCustomField(custom_field))
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomFieldDrawer);