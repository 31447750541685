import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Container, Button, Typography, TextField, InputAdornment, Avatar, 
    CircularProgress, Tooltip, Paper, Tabs, Tab} from '@material-ui/core';
import {connect} from 'react-redux';
import { consoleToLog, trackGAEvent, OverlayMessage, shouldDisable, AccessLevel } from '../util/AppUtil';
import { useSnackbar } from 'notistack';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import {orgCompaniesListApi, deleteCompanyApi, exportCompaniesApi} from '../services/authService';
import { useIsMount } from './useIsMount';
import AddNewCompanyModal from './modals/AddNewCompanyModal';
import CreateNewCompanyModal from './modals/CreateNewCompanyModal';
import CreateCompanyChecklistModal from './modals/CreateCompanyChecklistModal';
import SetupCompanyModal from './modals/SetupCompanyModal';
import { OverlayView, setOverlayToken, setOverlayView, setOvelayMessage} from '../actions/overlay';
import { v4 as uuidv4 } from 'uuid';
import ClientDetailsModal from "../components/modals/ClientDetailsModal";
import ClientListComponent from './ClientListComponent';
import ContactComponent from './client_details/ContactComponent';
import ContactDrawer from './client_details/ContactDrawer';

import CustomFieldsComponent from './CustomFieldsComponent';
import AddTemplateModal from './modals/AddTemplateModal';
import ImportTemplateModal from './modals/ImportTemplateModal';
import CreateChecklistMessageModal from './modals/CreateChecklistMessageModal';
import AddClientContactModal from './modals/AddClientContactModal';
import {getClientsListApi} from '../services/clientService';
import BackupModal from './modals/BackupModal';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';

const useStyles = makeStyles((theme) => ({
    mainContent : {
        width: (showDrawer) => {
            return showDrawer ? "83%" : "100%"; 
        },
        backgroundColor: theme.palette.primary.light, 
        transition: ".5s", 
        flexGrow: 1,
        //overflow:'hidden',
        overflow:'auto',
        overflowX:'hidden'
    },
    container: {
        marginTop: "8px",
        marginBottom:'32px'
    },
    MuiButtonlabel : {
        fontSize: "12px"
    }, 
    buttonPlusIcon: {
        marginLeft:'8px', 
        fontSize:'1.2rem', 
        alignItems:'center'
    },    
    buttonStyle : {
        justifyContent:'center', 
        padding:'8px 15px',
        marginRight:'8px'
    },
    exportButtonStyle: {
        justifyContent:'center', 
        marginRight:'8px',
        border:'1px solid rgba(0,0,0,0.1)',
        padding:'7px 13px',
        height: "30px",
        marginTop: "9px",
        marginLeft: "auto"
    },
    iconExport: {
        marginLeft:'6px', 
        fontSize:'0.8rem', 
        height:'20px', 
        color:'black'
    }, 
    attachButtonStyle: {
        justifyContent:'center', 
        padding:'7px 18px',
        marginLeft:'auto !important'
    },
    iconAttach: {
        marginLeft:'6px', 
        fontSize:'0.8rem', 
        height:'20px', 
        color:'white'
    },    
    MuiFormControlroot: {
        '& .MuiInputBase-input' :{
            padding: '7px 4px 7px 0px',
            color:'grey',
            background:'#fff'
        },
        '& .MuiOutlinedInput-root' :{
            position: 'relative',
            borderRadius: '6px',
        },   
        '& .MuiOutlinedInput-adornedStart' : {
            background:'#fff',
            paddingLeft: '7px',
        }
    },
    headings: {
        fontWeight: '600'
    },
    avatarStyle: {
        width:"38px !important",
        height:"38px !important",
        fontSize:'16px !important',
        fontWeight:'600 !important'
    },
    loading: {
        position:'relative',
        left:'50%',
        right:'50%',
        top:'30px'
    },
    companyListContainer : {
        background:'white', 
        minWidth:"100%", 
        borderRadius:'4px', 
        padding:'10px', 
        marginBottom:'6px',
        '& .action__icons' : {
            visibility: 'hidden',
        },
        '&:hover .action__icons' : {
            visibility: 'visible',
        },
        '&:hover':{
          backgroundColor:'rgba(0, 0, 0, 0.04)',
          cursor:"pointer"
        }
    },
    companyName: {
        fontSize:'14px', 
        fontWeight:'600',
        whiteSpace: "nowrap", 
        maxWidth: "320px", 
        overflow: "hidden", 
        textOverflow: "ellipsis",
        cursor: 'pointer'
    },
    addressHeading: {
        display:'flex', 
        justifyContent: 'center', 
        marginLeft:'-78px'
    },
    addressText : {
        display:'flex', 
        justifyContent: 'center'
    },
    customColumn1 : {
        flexWidth: '40%',
        maxWidth: '40%'
    },
    customColumn2 : {
        flexWidth: '18%',
        maxWidth: '18%'
    },
    emailEllipsis : {
        fontSize:'14px', 
        fontWeight:'600',
        whiteSpace: "nowrap", 
        maxWidth: "83% !important", 
        overflow: "hidden", 
        textOverflow: "ellipsis",
        cursor: 'pointer'
    },
    tab: {
        flexGrow: 1,
        borderRadius:0,
        borderBottom: "1px solid #c5b9b9",
    },
    buttonPlusIcon: {
        //marginLeft:'8px', 
        marginRight: "8px",
        fontSize:'1.2rem', 
        alignItems:'center'
    },    
    buttonStyle : {
        justifyContent:'center', 
        padding:'8px 15px',
        marginRight:'8px'
    },
    addButton: {
        color: "white",
        backgroundColor: "#3d3d3d",
        marginLeft: "auto",
        height: "30px",
        marginTop: "9px",
        "&:hover": {
            //you want this to be the same as the backgroundColor above
            backgroundColor: "#3d3d3d"
        },
        marginRight: "8px"
    },
    backupButton: {
        marginRight:'8px',
        border:'1px solid rgba(0,0,0,0.1)',
        padding:'7px 13px',
        height: "30px",
        marginTop: "9px",
        color:"#3d3d3d"
    }

    }
));          

const OrganizationCompanies = (props) => {
    const {showDrawer} = props;
    const classes = useStyles(showDrawer);
    const {enqueueSnackbar} = useSnackbar();
    const isMount = useIsMount();

    const [tempCompanyData, setTempCompanyData] = useState(undefined); 
    const [companyName, setCompanyName] = useState('');
    const [editCompany, setEditCompany] = useState(undefined);
    const [showCompanyDetails, setShowCompanyDetails] = useState(false);

    const [openAddCompanyDrawer, setOpenAddCompanyDrawer] = useState(false);
    const [openCreateCompanyDrawer, setOpenCreateCompanyDrawer] = useState(false);
    const [openCreateChecklistDrawer, setOpenCreateChecklistDrawer] = useState(false);
    const [openEditCompanyDrawer, setOpenEditCompanyDrawer] = useState(false);
    const [clearCompanyDrawer, setClearCompanyDrawer] = useState(false);
    const [openCompanySetupDrawer, setOpenCompanySetupDrawer] = useState(false);
    const [newClient, setNewClient] = useState(undefined);
    const [openClientContactDrawer, setOpenClientContactDrawer] = useState(false);
    
    const [value, setValue] = useState(0);
    const [openDetailsModal, setOpenDetailsModal] = useState(false);
    const [clientObj, setClientObj] = useState(undefined);
    const [openCustomFieldDrawer, setOpenCustomFieldDrawer] = useState(false);
    const [openContactDrawer, setOpenContactDrawer] = useState(false);
    const [contactObj, setContactObj] = useState({});

    const [clientList, setClientList] = useState([]);
    const [page, setPage] = useState(0);
    const [nextPage, setNextPage] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isMoreLoading, setIsMoreLoading] = useState(false);
    const [contactDeletedFromOutside, setContactDeletedFromOutside] = useState(false);

    const [openDialog, setOpenDialog] = useState(false);
    const [openTemplateDrawer, setOpenTemplateDrawer] = useState(false);

    const [searchClientText, setSearchClientText] = useState('');
    const [showBackupDrawer, setShowBackupDrawer] = useState(false);

    const isActive = (props.selectedOrganization.organization) ? (props.selectedOrganization.organization.deactivation_date) : null;
    const { access_level } = props.selectedOrganization || {};
    const accessLevelManager = shouldDisable(AccessLevel.getUserAccessLevelValue(access_level), AccessLevel.MANAGER);

    useEffect(() => {
        document.title = `Clients - ${props.fullName}`;
        fetchClientList(searchClientText);
    }, []);

    const checklistDetails = window.location.toString().includes("checklist");

    useEffect(() => {
        if(!isMount) {
            if(!checklistDetails) {
                document.title = 'Clients'
            }
        }
    }, [checklistDetails]);

    // useEffect(() => {
    //     if(!isMount) {
    //         if(contactDeletedFromOutside) {
    //             setPage(0);
    //         }
    //     }
    // }, [contactDeletedFromOutside]);

    useEffect(() => {
        if(!isMount) {
            fetchClientList(searchClientText);
        }
    }, [searchClientText]);

    useEffect(() => {
        if(!isMount) {
            if(value===0 && page===0) {
                fetchClientList(searchClientText);
                //setContactDeletedFromOutside(false);
            }
        }
    }, [page]);


    useEffect(() => {
        if(isMount) {

        } else {
            if (tempCompanyData) {
                handleOpenClientContactDrawer();
            }
        }    
    }, [tempCompanyData]);

    useEffect(() => {
        if(isMount) {

        } else {
            if (isMoreLoading) {
                fetchClientList(searchClientText);
            }
        }    
    }, [isMoreLoading]);

    useEffect(() => {
        if(!isMount) {
            if(value === 0) {
                setPage(0);
            }
        }
    }, [value]);

    const fetchClientList = (query) => {
        const organization_id = props.selectedOrganization.organization.id;

        if(!isMoreLoading)  setLoading(true);
        getClientsListApi(organization_id, page, query)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response getClientsListApi: ', res);
                setLoading(false);

                if(isMoreLoading) {
                    const newItems = clientList.concat(res.companies);
                    setClientList(newItems);
                    setPage(page + 1);
                    setNextPage(res.next_page);
                    setIsMoreLoading(!isMoreLoading);
                } else {
                    setNextPage(res.next_page);
                    if (res.next_page) setPage(page + 1);
                    setClientList(res.companies);
                }
            })
            .catch((e) => {
                setLoading(false);
                consoleToLog('Error getClientsListApi: ', e.response);
                //show401ErrorAndClearRedux(props, e, enqueueSnackbar);
            })
    }

    const pageReset = (query) => {
        setNextPage(false);
        if(page === 0) {
            fetchClientList(query);
        }
    }

    // const onSearch = (e) => {
    //     const query = e.target.value;
    //     if (!query || query.length === 0) {
    //         setPage(0);
    //         //setSearchCompany('');
    //         pageReset(query);
    //     } else {
    //         //dont allow spaces before string, allow in between
    //         const regex = /^[^-\s][a-zA-Z0-9_\s-]+$/;
    //         if (query && query.length > 1) {
    //             setPage(0);
    //             //setSearchCompany(query);
    //             pageReset(query);
    //         }
    //     }
    // }

    const onSearch = (e) => {
        const query = e.target.value;
        if (query === '') {
            setPage(0);
            setNextPage(false);
            setSearchClientText('');
        } else {
            //dont allow spaces before string, allow in between
            const regex = /^[^-\s][a-zA-Z0-9_\s-]+$/;
            if (query && query.length > 0) {
                setPage(0);
                setNextPage(false);
                setSearchClientText(query);
            }
        }
    }

    const handleDrawerOpen = () => { //Add New Company Drawer Open
        setOpenAddCompanyDrawer(true);
    }

    const handleDrawerClose = () => { //Add New Company Drawer Close
        setOpenAddCompanyDrawer(false);
    }

    const handleOpenCreateCompanyDrawer = () => { //Create Company Drawer Open
        setOpenCreateCompanyDrawer(true);
    }

    const handleCloseCreateCompanyDrawer = () => { //Create Company Drawer Close
        setOpenCreateCompanyDrawer(false);
    }

    const handleOpenCreateChecklistDrawer = () => { //Create Checklist Drawer Open
        setOpenCreateChecklistDrawer(true);
    }

    const handleCloseCreateChecklistDrawer = () => { //Create Checklist Drawer Close 
        setOpenCreateChecklistDrawer(false);
    }

    const handleOpenClientContactDrawer = () => { //open add client contact drawer
        setOpenClientContactDrawer(true);
    }

    const handleCloseClientContactDrawer = () => { //Create Checklist Drawer Close 
        setOpenClientContactDrawer(false);
    }

    const storeTempCompanyInfo = (tempCompanyData) => { // store company info for create Checklist
        setTempCompanyData(tempCompanyData);  
    }

    const handleOpenEditCompanyDrawer = (company) => { // edit company drawer Open
        setOpenEditCompanyDrawer(true);
        setEditCompany(company);
        handleDrawerClose();
    }

    const handleCloseEditCompanyDrawer = () => { // edit company drawer close
        setOpenEditCompanyDrawer(false);
        setEditCompany(undefined);
    }

   /*  const onShowCompanyDetailsClick = (company) => { // open show company details
        trackGAEvent(props.selectedOrganization.organization.name, 'View Company', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
        setEditCompany(company);
        setShowCompanyDetails(true);
    } */

    const handleOpenCompanySetupDrawer = () => {// open company setup
        setOpenCompanySetupDrawer(true);
    }

    const handleCloseCompanySetupDrawer = () => {// close company setup
        setOpenCompanySetupDrawer(false);
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setSearchClientText('');
    };

    const addCompany = (company) => {
        setClientList([company, ...clientList]);
    }

    const addCompanyWithoutChecklists = (company) => {
        setClientList([company, ...clientList]);
    }

    const handleOpenDetailsModal = (client) =>{
        setOpenDetailsModal(true);
        setClientObj(client)
    }

    //on export task click
    const exportCompanies  = () => {
        const organization_id = props.selectedOrganization.organization.id;

        var randomtoken = uuidv4();
        consoleToLog("token for export: ", randomtoken);

        props.setOverlayView(OverlayView.SHOW_OVERLAY_LOADING);
        props.setOverlayToken(randomtoken);
        props.setOvelayMessage(OverlayMessage.EXPORT_MSG);
        
        exportCompaniesApi(organization_id, randomtoken)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response exportCompaniesApi: ", res);
                trackGAEvent(props.selectedOrganization.organization.name, 'Export Companies', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
            })
            .catch((e) => {
                consoleToLog("exportCompaniesApi error", e.response);

                props.setOverlayView(OverlayView.NO_VIEW);
                props.setOverlayToken("");

                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant:'error'
                    });
                    return false;
                }
            });
    }

    const handleCustomFieldDrawerOpen = () => {
        trackGAEvent(props.selectedOrganization.organization.name, 'Add Custom Field Clicked', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
        setOpenCustomFieldDrawer(true);
    }

   


    const ButtonInTabs = ({ className, onClick, children, title }) => {
        return <Button 
                className={className} 
                onClick={onClick} 
                children={children}
                //disabled = {(title === 'Export') && props.templates.length === 0}
                style={{color:title === 'Export' || title === 'Backup' ? '#555555': 'white', 
                    cursor: props.templates.length === 0 && 'pointer !important'}}
                />;
    };

    const handleDialogOpen = () => {
        setOpenDialog(true);
    }

    const handleDialogClose = () => {
        setOpenDialog(false);
    }

    const onAddClientClick = () => {
        if(props.templates.length === 0) {
            handleDialogOpen();
        } else {
            trackGAEvent(props.selectedOrganization.organization.name, 'Add Client Clicked', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
            handleDrawerOpen();
        }
    }

    const onCompanySetupClick = () => {
        if(props.templates.length === 0) {
            handleDialogOpen();
        } else {
            trackGAEvent(props.selectedOrganization.organization.name, 'Setup Clicked', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
            handleOpenCompanySetupDrawer();
        }
    }

    const handleTemplateDrawerOpen = () => {
        setOpenTemplateDrawer(true);
        handleDialogClose();
    }

    const handleTemplateDrawerClose = () => {
        setOpenTemplateDrawer(false);
    }

    const handleContactDrawerOpen = () => {
        consoleToLog("entered")
        trackGAEvent(props.selectedOrganization.organization.name, 'Add Contact Clicked', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
        setOpenContactDrawer(true);
    }

    const handleContactDrawerClose=()=>{
        setOpenContactDrawer(false);
    }

    const handleOpenBackupDrawer = () => {
        setShowBackupDrawer(true);
    }

    const handleCloseBackupDrawer = () => {
        setShowBackupDrawer(false);
    }

    return (
        <div className={classes.mainContent} 
            style={{height: props.height}}>
            <Container className={classes.container} maxWidth="xl">
                <Grid item container alignItems="center">
                    <Grid item>
                        <Typography style={{fontSize:'20px', fontWeight:'600'}} color="primary">
                            Clients
                        </Typography>
                    </Grid>
                    {/* <Grid item style={{marginLeft:'18px'}}>
                        {!isActive && !accessLevelManager && 
                        <Button variant="contained" color="primary" 
                            classes={{
                                label: classes.MuiButtonlabel
                            }}
                            onClick={handleDrawerOpen}
                            className={classes.buttonStyle}>
                            Add
                            <AddCircleOutlineIcon className={classes.buttonPlusIcon}/>
                        </Button>}
                        <Button variant="outlined" 
                            color="primary" 
                            classes={{
                                label: classes.MuiButtonlabel
                            }}
                            className={classes.exportButtonStyle}
                            onClick={(e) => {
                                if(props.overlay && props.overlay.showOverlayView === OverlayView.SHOW_OVERLAY_LOADING){
                                    enqueueSnackbar("Another export request is already in progress. Please be patient.", ({
                                        variant: 'error'
                                    }));
                                }
                                else exportCompanies();
                            }}>
                            Export 
                            <i className={`flaticon-export ${classes.iconExport}`}></i>
                        </Button>
                    </Grid>
                    <Grid item style={{marginLeft:'auto'}}>
                    {!isActive &&  !accessLevelManager && 
                        <Button variant="contained" color="primary" 
                            onClick={handleOpenCompanySetupDrawer}
                            classes={{
                                label: classes.MuiButtonlabel
                            }}
                            className={classes.attachButtonStyle}>
                            Setup 
                            <i className={`flaticon-attachment ${classes.iconAttach}`}></i>
                        </Button>    
                    } 
                    </Grid>      */}
                </Grid>

                <Grid item container direction="column" style={{marginTop:'16px'}}>
                        <Grid item container>
                            <Paper className={classes.tab} elevation={1} style={{marginTop:"10px"}}>
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                >
                                    <Tab label="Clients" />
                                    <Tab label="Custom Fields" />
                                    <Tab label="Contacts"/>
                                    <div style={{marginLeft:'auto', marginRight:'8px'}}>
                                    {
                                        value === 0 && !accessLevelManager && 
                                        <ButtonInTabs
                                            onClick={handleOpenBackupDrawer}
                                            title='Backup'
                                            className={classes.backupButton}
                                        >
                                            Backup
                                            <SettingsBackupRestoreIcon ontSize='small' 
                                            style={{fontSize:'15px', marginLeft:'4px'}}/>
                                        </ButtonInTabs>
                                    }


                                    {
                                        value === 0 && !isActive && !accessLevelManager && 
                                        <ButtonInTabs
                                            onClick={onAddClientClick}
                                            className={classes.addButton}
                                            title='Add'
                                        >
                                            Add
                                        <AddCircleOutlineIcon fontSize='small' 
                                            style={{fontSize:'15px', marginLeft:'4px'}}
                                            className={classes.buttonPlusIcon}/>
                                        </ButtonInTabs>
                                    }

                                    {
                                        value === 0 &&
                                        <ButtonInTabs
                                            onClick={(e) => {
                                                if(props.overlay && props.overlay.showOverlayView === OverlayView.SHOW_OVERLAY_LOADING){
                                                    enqueueSnackbar("Another export request is already in progress. Please be patient.", ({
                                                        variant: 'error'
                                                    }));
                                                }
                                            else { 
                                                exportCompanies();
                                                trackGAEvent(props.selectedOrganization.organization.name, 'Export Client Clicked', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
                                            }
                                        }}
                                            className={classes.exportButtonStyle}
                                            title='Export'
                                        >
                                            Export 
                                            <i className={`flaticon-export ${classes.iconExport}`}></i>
                                        </ButtonInTabs>
                                    }

                                    
                                    {value === 0 && !isActive &&  !accessLevelManager && 
                                        <ButtonInTabs
                                            onClick={onCompanySetupClick}
                                            className={classes.addButton}
                                            title='Setup'
                                        >
                                            Setup 
                                            <i className={`flaticon-attachment ${classes.iconAttach}`}></i>
                                        </ButtonInTabs>
                                    }
                                    </div>

                                    {value === 1 &&
                                    <ButtonInTabs
                                        onClick={handleCustomFieldDrawerOpen}
                                        className={classes.addButton}
                                    >
                                    <AddCircleOutlineIcon className={classes.buttonPlusIcon}/>
                                        Add Custom Field
                                    </ButtonInTabs>}
                                    
                                    {value === 2 &&
                                    <ButtonInTabs
                                    onClick={handleContactDrawerOpen}
                                        className={classes.addButton}
                                    >
                                    <AddCircleOutlineIcon className={classes.buttonPlusIcon}/>
                                        Add Contact
                                    </ButtonInTabs>}
                                </Tabs>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}
                            style={{backgroundColor:"white", paddingBottom:'24px', display:'flex', marginBottom:"1rem"}}>
                            {value === 0 && 
                            <ClientListComponent openAddCompanyDrawer={openAddCompanyDrawer}
                                handleDrawerClose={handleDrawerClose}
                                openDetailsModal={openDetailsModal}
                                setOpenDetailsModal={setOpenDetailsModal}
                                clientObj={clientObj}
                                setClientObj={setClientObj}
                                clientList={clientList}
                                setClientList={setClientList}
                                handleSendEmailDialogOpen={props.handleSendEmailDialogOpen}
                                nextPage={nextPage}
                                isMoreLoading={isMoreLoading}
                                setIsMoreLoading={setIsMoreLoading}
                                loading={loading}
                                onSearch={onSearch}
                            />}
                            {
                                value === 1 && 
                                <CustomFieldsComponent openCustomFieldDrawer={openCustomFieldDrawer} 
                                    setOpenCustomFieldDrawer={setOpenCustomFieldDrawer}
                                />
                            }
                            {
                                value === 2 && 
                                <ContactComponent openContactDrawer={openContactDrawer} 
                                    setOpenContactDrawer={setOpenContactDrawer}
                                    handleContactDrawerClose={handleContactDrawerClose}
                                    handleContactDrawerOpen={handleContactDrawerOpen}
                                    setContactObj={setContactObj}
                                    fromOutsideClientDetails={true}
                                    setContactDeletedFromOutside={setContactDeletedFromOutside}
                                />
                            }
                        </Grid>
                </Grid>
            </Container>

            <AddNewCompanyModal openAddCompanyDrawer={openAddCompanyDrawer}
                handleDrawerClose={handleDrawerClose}
                handleOpenCreateCompanyDrawer={handleOpenCreateCompanyDrawer}
                setOpenAddCompanyDrawer={setOpenAddCompanyDrawer}
                setCompanyName={setCompanyName}
                setEditCompany={handleOpenEditCompanyDrawer}
                handleOpenDetailsModal={handleOpenDetailsModal}
            />

            <CreateNewCompanyModal companyName={companyName} 
                openCreateCompanyDrawer={openCreateCompanyDrawer}
                handleCloseCreateCompanyDrawer={handleCloseCreateCompanyDrawer}
                addCompany={addCompanyWithoutChecklists}
                storeTempCompanyInfo={(tempCompanyData) => storeTempCompanyInfo(tempCompanyData)}
                clearCompanyDrawer={clearCompanyDrawer}
                setClearCompanyDrawer={setClearCompanyDrawer}
                tempCompanyData={tempCompanyData}
                handleOpenCreateChecklistDrawer={handleOpenCreateChecklistDrawer}

            />    

            <CreateCompanyChecklistModal openCreateChecklistDrawer={openCreateChecklistDrawer}
                handleCloseCreateChecklistDrawer={handleCloseCreateChecklistDrawer}
                handleCloseCreateCompanyDrawer={handleCloseCreateCompanyDrawer}
                tempCompanyData={tempCompanyData}
                addCompany={addCompany}
                setClearCompanyDrawer={setClearCompanyDrawer}
                setTempCompanyData={setTempCompanyData}
                handleCloseClientContactDrawer={handleCloseClientContactDrawer}
            />  

            <SetupCompanyModal openCompanySetupDrawer={openCompanySetupDrawer}
                handleCloseCompanySetupDrawer={handleCloseCompanySetupDrawer}
                clientList={clientList}
            />

            {openDialog &&
            <AddTemplateModal openDialog={openDialog}
                handleDialogClose={handleDialogClose} 
                handleTemplateDrawerOpen={handleTemplateDrawerOpen}/>}

            <ImportTemplateModal openDrawer={openTemplateDrawer} 
                handleDrawerClose={handleTemplateDrawerClose}/>

            <AddClientContactModal openDrawer={openClientContactDrawer}
                closeDrawer={handleCloseClientContactDrawer}
                tempCompanyData={tempCompanyData}
                setClearCompanyDrawer={setClearCompanyDrawer}
                handleCloseCreateCompanyDrawer={handleCloseCreateCompanyDrawer}
                storeTempCompanyInfo={(tempCompanyData) => storeTempCompanyInfo(tempCompanyData)}
                handleOpenCreateChecklistDrawer={handleOpenCreateChecklistDrawer}
                clientList={clientList}
                setClientList={setClientList}
                />

            <BackupModal showBackupDrawer={showBackupDrawer}
                handleCloseBackupDrawer={handleCloseBackupDrawer}
                />
            {/* <ContactDrawer openContactDrawer={openContactDrawer} 
                handleContactDrawerClose={handleContactDrawerClose}
                fromAddEditContact={true}
                contactObj={contactObj}
            />       */}

        </div>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    overlay: state.overlay,
    templates: state.organizationInfo.templates,
});

const mapDispatchToProps = (dispatch) => ({
    setOverlayView: (exportView) => dispatch(setOverlayView(exportView)),
    setOverlayToken: (randomToken) => dispatch(setOverlayToken(randomToken)),
    setOvelayMessage: (overlayMessage) => dispatch(setOvelayMessage(overlayMessage)),
})

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationCompanies);