import {Grid, Tooltip, TextField, Typography, FormControlLabel, ListItem,
    Checkbox, Button, Drawer} from "@material-ui/core";
  import { useEffect, useState } from "react";
  import { useIsMount } from "../../../components/useIsMount";
  import { consoleToLog, trackGAEvent } from "../../../util/AppUtil";
  import {setSearchLabelFilter} from '../../../actions/filters';
  import {connect} from 'react-redux';
  import ArrowBackIcon from '@material-ui/icons/ArrowBack';
  import {makeStyles} from '@material-ui/core/styles';
  
  const useStyles = makeStyles((theme) => ({
    drawerPaper: {
      backgroundColor: theme.palette.primary.light,
      width: '100%', 
      height: '100vh',
      flexGrow:1,
      overflowY:'auto',
      overflowX:'hidden',
    },
    backIcon: {
      color: "#202020",
      border: " 1px solid #202020",
      padding: "4px 0px",
      fontSize: "18px",
      minWidth: "40px",
      lineHeight: "18px",
      marginBottom: "10px",
      marginRight: "16px",
    },
    mobileHeading: {
      fontSize: '18px',
      fontWeight: 500,
      marginTop:'-8px'
    },
    customColumn2: {
      flexBasis: "13%",
      maxWidth: "13%",
      flexWrap: "nowrap !important",
    },
    listContainer: {
        width:'100% !important'
    },
    popoverIcons: {
        marginRight:'7px',
        marginTop:'1px'
    }
  }));
  
  const MobileSelectFromLabelList = (props) => {
    const classes = useStyles();
  
    const [selectedLabelsList, setSelectedLabelsList] = useState(
      props.assignees && props.assignees.length > 0 ? props.assignees : []
    );
    const [openDrawer, setOpenDrawer] = useState(false);
    const isMount = useIsMount();
  
    const handleDrawerOpen = () => {
      setOpenDrawer(true);
    }
  
    const handleDrawerClose = () => {
      setOpenDrawer(false);
    }
  
    useEffect(() => {
        props.setSearchLabelFilter("");
    }, []);
  
    useEffect(() => {
        if (!isMount) {
            setSelectedLabelsList(props.assignees);
        }
        
    }, [props.assignees]);

    const isSelectedLabel = (checkLabel) => {
        const tempArr = selectedLabelsList.filter(
            (label) => label.id === checkLabel.id
        );
        return tempArr.length > 0 ? true : false;
    };
  
    const onLabelChecked = (e, checkedLabel) => {
        //create checklist page
        if (isSelectedLabel(checkedLabel)) {
          //remove
          const array = selectedLabelsList.filter(
            (label) => label.id !== checkedLabel.id
          );
          setSelectedLabelsList(array);
          onApplyChanges(array);
        } else {
          //add
          const array = selectedLabelsList.concat(checkedLabel);
          setSelectedLabelsList(array);
          onApplyChanges(array);
        }
    };

    const onApplyChanges = (list) => {
        props.setAssignedLabelList(list);
    };
  
    const handleTextFieldChange = (e) => {
        const name = e.target.value;
        props.setSearchLabelFilter(name);
    };
  
    return (
      <Grid item xs={12}>
        <Grid item xs={12}>
            <Drawer 
                open={props.openDrawer}
                variant="persistent"
                anchor="right"
                onClose={props.handleDrawerClose}
                classes={{
                    paper: classes.drawerPaper
                }}
              >
                <Grid item xs={12} style={{padding:'16px', marginTop:'4px'}}>
                    <Grid item container alignItems='center'>
                        <Grid item>
                            <Button
                                variant="outlined"
                                className={classes.backIcon}
                                onClick={props.handleDrawerClose}
                            > 
                                <ArrowBackIcon /> 
                            </Button>
                        </Grid>
  
                        <Grid item>
                            <Typography variant="body1" 
                                className={classes.mobileHeading}>
                                Labels
                            </Typography>
                        </Grid>
                    </Grid>
                    <TextField
                      id="member_input"
                      fullWidth
                      variant="outlined"
                      margin="dense"
                      label="Search Labels"
                      style={{ marginBottom: "8px", background:'white'}}
                      //value={textFieldValue}
                      onChange={handleTextFieldChange}
                    />
                    
                    {props.labels &&
                        props.labels.length > 0 &&
                        props.labels.map((label, index) => {
                            return (
                                <FormControlLabel
                                key={label.id + "_" + index}
                                control={
                                    <Checkbox
                                    checked={isSelectedLabel(label) || false}
                                    onChange={(e) => onLabelChecked(e, label)}
                                    name="checkedB"
                                    color="primary"
                                    />
                                }
                                style={{
                                    display: "block",
                                    whiteSpace: "nowrap",
                                    maxWidth: "auto",
                                    overflow: "hidden",
                                    verticalAlign: "middle",
                                    marginRight: "0px",
                                    color: `${label.color}`,
                                    textOverflow: "ellipsis",
                                }}
                                label={label.name}
                                />
                            );
                        })}
                    </Grid>
                </Drawer>
        </Grid>
      </Grid>
    );
  };
  
  const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    labels: state.organizationInfo.labels.filter((label) => {
        return label.name
          .toLowerCase()
          .includes(state.filters.searchLabelFilter.toLowerCase());
    }),
  });
  
  const mapDispatchToProps = (dispatch) => ({
    setSearchLabelFilter: (name) => dispatch(setSearchLabelFilter(name)),
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(MobileSelectFromLabelList);
  