import {panNoValidator, gstinValidator} from './validator';
//UDF: user defined fields enum
export const defaultUserDefinedNames = {
    GSTUsername: "GST username",
    GSTPassword: "GST password",
    ITPassword: "IT password",
    PAN: "PAN", 
    GSTIN: "GSTIN",
    Custom: "Custom",
    RERAUsername: "RERA username",
    RERAPassword: "RERA password",
};

export const isPredefinedName = (name) => {
    if (name === defaultUserDefinedNames.Custom) {
        return false;
    }
    if (Object.values(defaultUserDefinedNames).includes(name)) {
        return true;
    }
}

export const udfTypes = {
    Text: { displayName: "Text", value: "text" },
    Password: { displayName: "Password", value: 'password' },
    ImageOrDocument: { displayName: "Image / Document", value: "file" }
};

//return if valid, return error if not valid
export const isValidUserDefinedFields = (udfArray, orgEmailList) => {
    for (var i = 0; i < udfArray.length; i++) {
        //name validations
        if (!udfArray[i].name) {//empty
            return `User defined field name can not be empty at row:${i + 1}`;
        }
        for (var j = i + 1; j < udfArray.length; j++) {//repeat
            if (udfArray[i].name === udfArray[j].name) {
                return `User defined field name can not be repeated: ${udfArray[i].name}`;
            }
        }
        //value validations
        if (!udfArray[i].value) {//empty
            return `Value can not be empty at row:${i + 1}`;
        }
        if(udfArray[i].name === 'PAN' && udfArray[i].value) {
            const isPANNoValid = panNoValidator(udfArray[i].value)
                if(!isPANNoValid) {
                    //return `${isPANNoValid.toast.error()}`
                    return;
                }
            }
        if(udfArray[i].name === 'GSTIN') {
            const isGSTINValid = gstinValidator(udfArray[i].value);
            if(!isGSTINValid) {
                //return `${isGSTINValid.toast.error()}`;
                return;
            }
            
        }
        if(udfArray[i].type === udfTypes.Password.value && orgEmailList && orgEmailList.length === 0) {
            return `You cannot add any password, Please add support email first.`;
            
        }
        
    }
}



// var crypto = require('crypto');
// //encrypt
// export const encrypt_token = (key, iv, data) => {
//     var cipher = crypto.createCipheriv('aes-256-cbc', key, iv);
//     cipher.update(data, 'binary', 'base64');
//     return cipher.final('base64');
// }
// //decrypt
// export const decrypt_token = (key, iv, data) => {
//     var decipher = crypto.createDecipheriv('aes-256-cbc', key, iv);
//     decipher.update(data, 'base64', 'binary');
//     return decipher.final('binary');
// }


// //input array of udf's / output: array of udf's with passwords encrypted
// export const encryptUDFPasswords = (key, iv, udfArray) => {
//     for (var i = 0; i < udfArray.length; i++) {
//         if (udfArray[i].type === udfTypes.Password.value) {
//             const password = udfArray[i].value;
//             const ePassword = encrypt_token(key, iv, password);
//             udfArray[i].value = ePassword;
//             udfArray[i].isDecrypted = false;
//         }
//     }
//     return udfArray;
// }

// //input: array of udf's with encrypted passwords / output: array with passwords decrypted.
// export const decryptUDFPasswords = (key, iv, udfArray) => {
//     for (var i = 0; i < udfArray.length; i++) {
//         if (udfArray[i].type === udfTypes.Password.value && !udfArray[i].isDecrypted) {
//             const password = udfArray[i].value;
//             const dPassword = decrypt_token(key, iv, password);
//             udfArray[i].value = dPassword;
//             udfArray[i].isDecrypted = true;
//         }
//     }
//     return udfArray;
// }