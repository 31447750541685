import React from 'react';
import {Grid, Typography} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    customFieldContainer: {
        background:'#fff',
        padding:'8px 16px'
    },
    textStyle: {
        fontWeight: 600,
        maxWidth:'98%', 
        wordBreak:'break-word'
    }
}))

const MobileCustomFields = (props) => {
    const classes = useStyles();

    const customFields = props.clientObj?.user_defined_field && props.clientObj?.user_defined_field.length > 0 ? 
                        props.clientObj?.user_defined_field : [];
    return (
        <div style={{marginTop:'16px'}}>
            {
                customFields && customFields.length > 0 ?
                customFields.map((cf) => {
                    return   (cf.type !== 'password') &&
                            <Grid item xs={12} key={cf.value} className={classes.customFieldContainer}>
                                <Grid item container alignItems='center'>
                                    <span>
                                        {cf.name}
                                    </span>

                                    <span style={{margin:'0 8px'}}>
                                        -
                                    </span>

                                    <span className={classes.textStyle}>
                                        {cf.type === 'file' ? cf.value.replace(/^.*[\\\/]/, '') : cf.value}
                                    </span>
                                </Grid>
                            </Grid>
                    }) : <Grid item container justifyContent='center' style={{padding:'8px 0px', background:"#fff"}}>
                            <Typography>
                                No Custom Fields Found
                            </Typography>
                        </Grid>
            }
        </div>
    );
}

export default MobileCustomFields;