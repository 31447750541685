import React from 'react';
import Modal from '@material-ui/core/Modal';
import { Grid, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        position: 'absolute',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: '16px',
        width:'530px'
    },
    modalHeading: {
        fontSize:'18px',
        fontWeight:600,
        color:"#212121"
    },
    modalAttachmentHeading: {
        fontSize:'14px',
        color:'#5f6368'
    },
    attachmentList: {
        fontSize:'14px',
        color:'#212121',
        marginBottom:'16px',
    }
}));

const EmailAttachmentErrorModal = (props) => {
    const classes = useStyles();
    return (
        <Modal
            open={props.openErrorModal}
            onClose={props.handleCloseErrorModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={classes.modal}
        >
            <div className={classes.paper}>
                <Grid item sm={12}>
                    
                    <Grid item sm={12}>
                        <Grid item container justifyContent='space-between' alignItems='center'>
                            <Grid item>
                                <Typography variant='body1' className={classes.modalHeading}>
                                    Attachments
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Button 
                                    onClick={props.handleCloseErrorModal}
                                    style={{minWidth:"20px"}}>
                                    <ClearIcon style={{color:"#212121"}}/>
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid item sm={12} style={{margin:"16px 0px"}}>
                            <Typography className={classes.modalAttachmentHeading}>
                                The following files are larger than 20 MB. Please Select files less than 20 MB.
                            </Typography>
                        </Grid>
                        <Grid item sm={12}>
                            {props.errorAttachments && props.errorAttachments.length > 0 &&
                                props.errorAttachments.map((fileObj) => {
                                return <Typography  key={fileObj.name} className={classes.attachmentList}>
                                            {fileObj.name}
                                        </Typography>
                            })
                            }
                        </Grid>
                        <Grid item container justifyContent='center' alignItems='center' style={{marginTop:"32px"}}>
                            <Button variant='contained' 
                                onClick={props.handleCloseErrorModal}
                                color='primary'>
                                OK
                            </Button>
                        </Grid>
                                
                    </Grid>
                </Grid>
            </div>
        </Modal>
    );
}

export default EmailAttachmentErrorModal;