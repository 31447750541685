export default (state = {}, action) => {
    switch (action.type) {
        case 'LOGIN':
            return action.auth;
        
        case 'LOGOUT':
            return {};  
            
        case 'UPDATE_USER':
            return {
                ...state,
                user: action.user
            }    

        case 'SET_DELTA_TIME':
            return {
                ...state,
                deltaTime: action.delta
            }    

        default:
            return state;
    }
}