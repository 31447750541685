
const defaultState = {
    selectedOrganization: {},
    members: [],
    labels: [],
    templates: [],
    selectedTemplate: undefined,
    waiting_for_my_approval_checklist_count: 0,
    setDrawerState: "",
    cd: {},
    superAdminUser: false,
    accountList: [],
    isInvoiceAccessor: false,
    customFieldList: [],
    selectedOrgEmailObj: undefined,
    orgEmailList: [],
    orgEmailDraftList: [],
    currentEmailState: undefined,
    isEmailSendError: false,
    checklistArray:[],
    emailFollowupObj: undefined,
    fromChecklistDetails: false,
    isCreateEditTemplate: false,
    templateRuleErrorMessage: '',
    emailSentFrom: ''
}

//set selected organization
export default (state = defaultState, action) => {
    switch (action.type) {
        case 'LOGOUT_SELECTED_ORG':
            return defaultState;

        /*** selected organization*/
        case 'SET_SELECTED_ORG':
            return {
                ...state,
                selectedOrganization: action.organization
            }

        case 'UPDATE_SELECTED_ORG':
            return {
                ...state,
                selectedOrganization: {
                    ...state.selectedOrganization,
                    organization: action.organization
                }
            }    

        /*** Org members */
         case 'SET_ORG_MEMBERS':
            return {
                ...state,
                members: action.members
            } 
         
         /**add member */   
         case 'ADD_ORG_MEMBER':
            return {
                ...state,
                members: [
                    ...state.members,
                    action.member
                ]
            }   

         /**change member role */
         case 'UPDATE_ORG_MEMBER_ROLE': 
            return {
                ...state,
                members: state.members.map((member) => {
                    if(member.id === action.organization_member_id) {
                        return {
                            ...member,
                            access_level: action.access_level
                        }
                    } else {
                        return member;
                    }
                })
            }  
            
         /**remove member from org */
         case 'REMOVE_ORG_MEMBER':
             return {
                 ...state,
                 members: state.members.filter(({id}) => id !== action.organization_member_id)
             }   
            
        /*** Org labels */
        case 'SET_ORG_LABELS':
            return {
                ...state,
                labels: action.labels
            }

        /*** Add Org labels */    
        case 'ADD_ORG_LABEL': 
            return {
                ...state,
                labels: [
                    ...state.labels,
                    action.label
                ]
            } 
            
        /*** Update Org labels */      
        case 'UPDATE_ORG_LABEL': 
            return {
                ...state,
                labels: state.labels.map((label) => {
                    if(label.id === action.label.id) {
                        return action.label;
                    } else {
                        return label;
                    }
                })
            }

        /**Delete org label */
        case 'DELETE_ORG_LABEL':
            return {
                ...state,
                labels: state.labels.filter((label) => label.id !== action.label_id)
            }    

            
        /***Templates */
        case 'SET_ORG_TEMPLATES':
            return {
                ...state,
                templates: action.templates
            }  
            
        case 'SET_SELECTED_TEMPLATE':
            return {
                ...state,
                selectedTemplate: action.template
            } 
            
        case 'SET_WAITING_FOR_MY_APPROVAL_CHECKLIST_COUNT':
            return {
                ...state,
                waiting_for_my_approval_checklist_count: action.waiting_for_my_approval_checklist_count
            }  
            
        case 'SET_SELECTED_DRAWER_STATE':
            return {
                ...state,
                setDrawerState: action.drawerState
            }  
            
        // Decryption Key
         case 'SET_CD_SALT':
            return {
                ...state,
                cd: {
                    salt: action.salt
                }
            }
        
        case 'SET_SUPER_ADMIN_USER':
            return {
                ...state,
                superAdminUser: action.super_admin
            }    
        /*** Org invoice accounts */
        case 'SET_ORG_INVOICE_ACCOUNTS':
            return {
                ...state,
                accountList: action.accountList
            }
        /**set invoice accessor */    
        case 'SET_IS_INVOICE_ACCESSOR':
            return {
                ...state,
                isInvoiceAccessor: action.invoice_accessor
            }

        /*** Org Custom Fields */
        case 'SET_ORG_CUSTOM_FIELDS':
            return {
                ...state,
                customFieldList: action.custom_fields
            }

        /*** Add Org Custom Field */    
        case 'ADD_ORG_CUSTOM_FIELD': 
        return {
            ...state,
            customFieldList: [
                ...state.customFieldList,
                action.custom_field
            ]
        } 
        
        /*** Update Org Custom Field */      
        case 'UPDATE_ORG_CUSTOM_FIELD': 
            return {
                ...state,
                customFieldList: state.customFieldList.map((custom_field) => {
                    if(custom_field.id === action.custom_field.id) {
                        return action.custom_field;
                    } else {
                        return custom_field;
                    }
                })
            }  
            
        /**Delete Org Custom Field */
        case 'DELETE_ORG_CUSTOM_FIELD':
            return {
                ...state,
                customFieldList: state.customFieldList.filter((custom_field) => custom_field.id !== action.custom_field_id)
            }

        case 'SET_SELECTED_ORG_EMAIL_OBJ':
            return {
                ...state,
                selectedOrgEmailObj: action.emailObj
            }

        case 'SET_ORG_EMAILS':
            return {
                ...state,
                orgEmailList: action.emails
            }

        case 'ADD_EMAIL_TO_ORG': 
        return {
            ...state,
            orgEmailList: [
                ...state.orgEmailList,
                action.email
            ]
        } 
        
        case 'UPDATE_ORG_EMAIL': 
            return {
                ...state,
                orgEmailList: state.orgEmailList.map((email) => {
                    if(email.id === action.email.id) {
                        return action.email;
                    } else {
                        return email;
                    }
                })
            }  
            
        case 'DELETE_ORG_EMAIL':
            return {
                ...state,
                orgEmailList: state.orgEmailList.filter((email) => email.id !== action.email_id)
            }

        case 'SET_ORG_EMAIL_DRAFTS':
            return {
                ...state,
                orgEmailDraftList: action.draftsList
            }
        
        case 'SET_CURRENT_EMAIL_STATE': 
            return {
                ...state,
                currentEmailState: action.emailState
            }

        case 'SET_IS_SEND_EMAIL_ERROR': 
            return {
                ...state,
                isEmailSendError: action.emailSendError
            }

        case 'SET_CHECKLIST_ARRAY': 
            return {
                ...state,
                checklistArray: action.checklistArray
            }

        case 'SET_FROM_CHECKLIST_DETAILS_PAGE': 
            return {
                ...state,
                fromChecklistDetails: action.fromChecklistDetails
            }

        case 'SET_EMAIL_FOLLOWUP_OBJECT': 
            return {
                ...state,
                emailFollowupObj: action.emailFollowup
            }

        case 'SET_CREATE_AND_EDIT_TEMPLATE': 
            return {
                ...state,
                isCreateEditTemplate: action.createEditTemplate
            }

        case 'SET_TEMPLATE_RULE_ERROR_MESSAGE': 
            return {
                ...state,
                templateRuleErrorMessage: action.errorMessage
            }

             /*** Add Org Email Draft */    
        case 'ADD_ORG_EMAIL_DRAFT': 
            return {
                ...state,
                orgEmailDraftList: [
                    action.email_draft,
                    ...state.orgEmailDraftList
                ]
            } 
        
        /*** Update Org Email Draft */      
        case 'UPDATE_ORG_EMAIL_DRAFT': 
            return {
                ...state,
                orgEmailDraftList: state.orgEmailDraftList.map((email_draft) => {
                    if(email_draft.id === action.email_draft.id) {
                        return action.email_draft;
                    } else {
                        return email_draft;
                    }
                })
            }  
            
        /**Delete Org Email Draft */
        case 'DELETE_ORG_EMAIL_DRAFT':
            return {
                ...state,
                orgEmailDraftList: state.orgEmailDraftList.filter((email_draft) => email_draft.id !== action.email_draft_id)
            }

        case 'SET_EMAIL_SENT_FROM':
            return {
                ...state,
                emailSentFrom: action.str
            }


            default:
            return state;
    }
};