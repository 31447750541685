import { getAxios } from './axiosUtil';

//get checklist task widget attachment url API(upload widget)
export const getTaskAttachmentUrlApi = (task_id, widget_id, path) => {
    let data = {
        path
    }
    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/checklist_task/${task_id}/widget/${widget_id}/download`, data);
}


export const updateTaskStatusApi = (checklist_task_id, status) => {
    let data = {
        status
    }
    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/checklist_task/${checklist_task_id}/status`, data);
}

//Complete Checklist API
export const completeChecklistApi = (checklist_id) => {
    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/checklist/${checklist_id}/complete`);
}

//get checklist attachments list API
// export const getCheckListAttachmentListApi = (access_token, checklist_id) => {
//     getAxios().defaults.headers.common['Authorization'] = access_token;
//     return getAxios().get(`${process.env.REACT_APP_BASE_URL}/checklist/${checklist_id}/attachments`);
// }

export const uploadChecklistAttachmentApi = (organization_id, type, title, path, target_id, user_id, client_id) => {
    let data = {
        type,
        title,
        path,
        target_id, 
        user_id, 
        client_id
    }
    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/organization/${organization_id}/attachment/upload_record`, data);
}

//delete checklist attachment (sidemenu => attachments)
export const deleteChecklistAttachmentApi = (organization_id, attachment_id) => {
    return getAxios().delete(`${process.env.REACT_APP_BASE_URL}/organization/${organization_id}/attachment/${attachment_id}/delete`);
}

//checklist attachments download API
export const getChecklistAttachmentUrlApi = (checklist_id, checklist_attachments_id, path) => {
    let data = {
        path
    }
    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/checklist/${checklist_id}/attachment/${checklist_attachments_id}/download`, data);
}


// Notes

//get Checklist comment/notes list api
// export const getChecklistNotesApi = (access_token, checklist_id, organization_id) => {
//     getAxios().defaults.headers.common['Authorization'] = access_token;
//     return getAxios().get(`${process.env.REACT_APP_BASE_URL}/comments/${organization_id}/checklist/${checklist_id}`);
// }

//add Checklist comment/notes api
export const addChecklistNoteApi = (checklist_id, mention_users, text, organization_id) => {
    let data = {
        mention_users,
        text
    };

    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/comments/${organization_id}/checklist/${checklist_id}`, data);
}

//delete Checklist comment/notes api
export const deleteChecklistNoteApi = (comment_id, checklist_id, organization_id) => {
    const param = {
        params: {
            comment_id
        }
    }

    return getAxios().delete(`${process.env.REACT_APP_BASE_URL}/comments/${organization_id}/checklist/${checklist_id}`, param);
}

//edit Checklist comment/notes api
export const editChecklistNoteApi = (checklist_id, mention_users, text, comment_id, organization_id) => {
    let data = {
        mention_users,
        text,
        comment_id
    };

    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/comments/${organization_id}/checklist/${checklist_id}`, data);
}

//get all activities depend on user role (view_type)
// export const getActivityApi = (access_token, organization_id, page, view_type) => {
//     const param = {
//         params: {
//             page,
//             view_type
//         }
//     }
//     getAxios().defaults.headers.common['Authorization'] = access_token;
//     return getAxios().get(`${process.env.REACT_APP_BASE_URL}/activity_feed/${organization_id}`, param);
// }

//get checklist activity
export const getChecklistActivityApi = (organization_id, page, checklist_slug) => {
    const param = {
        params: {
            page
        }
    }
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/activity_feed/${organization_id}/checklist/${checklist_slug}`, param);
}

// export const getSignedUrl = (access_token, task_id, widget_id, path) => {
//     const param = {
//         path
//     }
//     getAxios().defaults.headers.common['Authorization'] = access_token;
//     return getAxios().put(`${process.env.REACT_APP_BASE_URL}/checklist_task/${task_id}/widget/${widget_id}/download_widget`, param);
// }

export const getRulesApi = (template_id) => {
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/template/${template_id}/rules`);
}

export const addRuleApi = (template_id, rules_json) => {
    let data = { rules_json };
    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/template/${template_id}/rules`, data);
}
 
export const editRuleApi = (template_id, rules_json, id) => {
    let data = {id, rules_json };
    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/template/${template_id}/rules`, data);
}

export const deleteRulesApi = (template_id, id) => {
    const param = {
        params: {
            id
        }
    }
    return getAxios().delete(`${process.env.REACT_APP_BASE_URL}/template/${template_id}/rules`, param);
}
 
export const getUploadUrlForChecklistAttachmentApi = (organization_id, filename, type, auto_generated_id, template_slug, subtitle, organization_slug, approval_id) => {
    let data = {
                    filename, 
                    type,
                    auto_generated_id, 
                    template_slug, 
                    subtitle, 
                    organization_slug,
                    approval_id
                }
    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/organization/${organization_id}/attachment/signed_upload_url`, data);
}

export const editChecklistTaskApi = (organization_id, checklist_id, id, title, heading, widget_json) => {
    let data = {
        id,
        title,
        heading,
        widget_json
    };
    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/organization/${organization_id}/checklist/${checklist_id}/task`, data);
}

export const deleteChecklistTaskApi = (organization_id, checklist_id, id) => {
    const param = {
        params: {
            id
        }
    }
    return getAxios().delete(`${process.env.REACT_APP_BASE_URL}/organization/${organization_id}/checklist/${checklist_id}/task`, param);
}

export const addChecklistTaskApi = (organization_id, checklist_id, title, heading, widget_json) => {
    let data = {
        title, 
        heading, 
        widget_json
    }
    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/organization/${organization_id}/checklist/${checklist_id}/task`, data);
}

export const getChecklistRulesApi = (checklist_id) => {
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/checklist/${checklist_id}/rules`);
}

export const addChecklistRuleApi = (checklist_id, rules_json) => {
    let data = { rules_json };
    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/checklist/${checklist_id}/rules`, data);
}

export const editChecklistRuleApi = (checklist_id, rules_json, id) => {
    let data = {id, rules_json };
    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/checklist/${checklist_id}/rules`, data);
}

export const deleteChecklistRulesApi = (checklist_id, id) => {
    const param = {
        params: {
            id
        }
    }
    return getAxios().delete(`${process.env.REACT_APP_BASE_URL}/checklist/${checklist_id}/rules`, param);
}

export const changeChecklistTaskOrderApi = (organization_id, checklist_id, id, order_number) => {
    const data = {
        order_number
    }

    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/organization/${organization_id}/checklist/${checklist_id}/task/${id}/change_order`, data);
}

export const approvalRulesApi = (business_logic_json, apiStr) => {
    const data = {
        business_logic_json
    }

    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/${apiStr}/business_logic`, data);
}

export const getApprovalListApi = (checklist_id) => {

    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/checklist/${checklist_id}/approvals`);
}

export const sendForApprovalApi = (organization_id, client_id, text, approvers, apiStr) => {

    let data = {
        text, 
        approvers, 
        //path
    }

    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/organization/${organization_id}/${client_id}/send_for_approval/${apiStr}`, data);
}

export const approveRejectCancelApi = (organization_id, apiStr) => {
    let data = {
        request_origin: 'workplace'
    }

    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/organization/${organization_id}/approve_reject_cancel/${apiStr}`, data);
}

export const undoApproveRejectApi = (organization_id, approval_id) => {

    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/organization/${organization_id}/undo_approve_reject/${approval_id}`);
}

export const updateChecklistTaskDueDateApi = (task_id, due_date) => {
    let data = {
        due_date
    }
    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/checklist_task/${task_id}/due_date`,data);
}

export const updateChecklistRulesApi = (template_id) => {

    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/template/${template_id}/update_checklist_rules`);
}

export const updateChecklistBusinessLoginApi = (template_id) => {

    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/template/${template_id}/update_checklist_business_logic`);
}