import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button, TextField } from '@material-ui/core';
import { emailValidator } from '../../util/validator';
import { useState } from 'react';
import {forgetPasswordApi} from '../../services/authService';
import { consoleToLog } from '../../util/AppUtil';
import { useSnackbar } from 'notistack';

const ForgotPassword = (props) => {
    const [femail, setFEmail] = useState('');
    const [femailInValid, setFEmailInValid] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const onSubmit = () => {
        if (!femail) {
            setFEmailInValid(true);
            return;
        }
        const isEmailValid = emailValidator(femail);
        if (!isEmailValid) {
            setFEmailInValid(true);
            return;
        }

        forgetPasswordApi(femail)
        .then((response) => {
          const res = response.data;
          consoleToLog("Response forgetPasswordApi: ", res);
          enqueueSnackbar(res.message, { 
            variant: 'success',
          });
          props.handleClose();

        })
        .catch((e) => {
          consoleToLog("forgetPasswordApi error", e.response);
          if (e.response.data && e.response.data.message) {
            enqueueSnackbar(e.response.data.message, { 
              variant: 'error',
            });
            return false;
        }
        })
        
    }
    return(
        <Dialog open={props.isOpen} 
          onClose={props.handleClose} 
          aria-labelledby="form-dialog-title">

          <DialogTitle id="form-dialog-title" 
            style={{paddingBottom: "0px", paddingTop: "30px", paddingLeft: "30px", paddingRight: "30px"}}>Forgot Your Password?</DialogTitle>
          
          <DialogContent style={{paddingTop: "10px", paddingLeft: "30px", paddingRight: "30px"}}>
            <DialogContentText style={{fontSize: props.fromMobile ? "14px" : "12px"}}>
              Enter the email you used to create your eProcessify account and we'll send you an email to reset your password.
            </DialogContentText>
            <TextField
              style={{marginTop: "20px"}}
              autoFocus
              variant="outlined"
              margin="dense"
              id="name"
              label="Email Address"
              type="email"
              fullWidth
              error={femailInValid}
              helperText={femailInValid ? "* Email is invalid!" : ''}
              onChange={e => { 
                  setFEmail(e.target.value);
                  setFEmailInValid(false);
              }}
              onKeyPress={(ev) => {
                consoleToLog(`Pressed keyCode ${ev.key}`);
                if (ev.key === 'Enter') {
                  onSubmit();
                  ev.preventDefault();
                }
              }}
            />
          </DialogContent>

          <DialogActions style={{marginTop: "15px", paddingBottom: "30px", paddingRight: "30px"}}>
            <Button onClick={props.handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={onSubmit} color="primary" variant="contained">
              Send Email
            </Button>
          </DialogActions>

      </Dialog>
    );
}

export default ForgotPassword;