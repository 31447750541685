import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Grid, Typography, Button, Drawer, AppBar, Toolbar, IconButton} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import ApprovalItem from './ApprovalItem';
import { useIsMount } from '../useIsMount';
import { useEffect } from 'react';

const useStyles = makeStyles((theme) => ({
    appBar: {
        background: "#F9F9F9",
        height: "45px",
        color: "#202020",
    },
    drawerPaper: {
        width: '970px',
        //height: 'calc(100vh - 51px)',
        flexGrow:1,
    }
}))

const TaskApprovalModal = (props) => {
    const classes = useStyles();
    const isMount = useIsMount();

    const [approvalList, setApprovalList] = useState([]);

    useEffect(() => {
        if(props.taskObj) {
            setApprovalList(props.taskObj?.task_approval_list);
        }
    }, [props.taskObj])


    return (
        <Drawer open={props.openApprovalsModal}
        onClose={props.handleCloseApprovalsModal}
        anchor="right"
        classes={{
            paper: classes.drawerPaper
            }}>
            <AppBar position="static" className={classes.appBar} elevation={0}>
                <Toolbar style={{ minHeight: "40px", padding: "0px 16px" }}>
                    <Typography
                    variant="body1"
                    style={{ fontWeight: "600", marginRight: "auto" }}>
                        Approval Details
                    </Typography>
                    <IconButton
                        style={{ color: "#202020", marginRight: "-16px" }}
                        onClick={() => {
                            props.handleCloseApprovalsModal();
                        }}
                    >
                    <ClearIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Grid item md={12} style={{marginTop:'16px', padding:'0px 16px'}}>
                {
                    approvalList && approvalList.length > 0 ?
                    approvalList.map((item) => {
                        return <ApprovalItem key={item.id} 
                                    item={item}
                                    approvalList={approvalList}
                                    setApprovalList={setApprovalList}
                                    updateChecklistFromReview={props.updateChecklistFromReview}
                                    checklistTaskUpdateFromApproval={props.checklistTaskUpdateFromApproval}
                                    fromChecklistDetailsDrawer={props.fromChecklistDetailsDrawer}
                                    handleOpenSendForReviewDialog={props.handleOpenSendForReviewDialog}
                                    setApprovalObj={props.setApprovalObj}
                                    taskObj={props.taskObj}
                                />
                    }):
                    <Grid item container justifyContent='center' style={{background:"#f6f6f6", padding:'8px'}}>
                        <Typography variant='body1'>
                            No Approvals Found
                        </Typography>
                    </Grid>
                }
            </Grid>
        </Drawer>
    );
}

export default TaskApprovalModal;