import React, {useState, useEffect} from 'react';
import {getChecklistFoldersApi} from '../services/authService';
import { consoleToLog } from '../util/AppUtil';
import {connect} from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {TextField} from '@material-ui/core';
import { useIsMount } from './useIsMount';
import  Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option) => option.group_name,
});


const useStyles = makeStyles((theme) => ({
    MuiAutocompleteinputRoot : {
        '& .MuiOutlinedInput-root' : {
        padding:'1px 1px 1px 4px',
        borderRadius: '5px',
        }
    },
    customTextField: {
        "& input::placeholder": {
          fontSize: "13px",
        }
      },
    
    avatar : {
        width: '15px !important',
        height: '15px !important',
        fontSize: '12px !important',
        background: 'none !important',
        color: 'rgba(0, 0, 0, 0.87) !important',
        border: '1px solid rgba(0, 0, 0, 0.26) !important'
    },  
        chip : {
        borderRadius: '4px !important',
        backgroundColor: '#f9f9f9 !important',
        border: '1px solid #ececec !important',
    },  
    autoStyle: {
        "&::-webkit-scrollbar": {
            display: "none"
        }
    }

    }
));

const AutocompleteFolder = (props) => {
    const classes = useStyles();
    const isMount = useIsMount();

    const [folderList, setFolderList] = useState([]);
    const [selectedFolder, setSelectedFolder] = useState(null);

    // useEffect(() => {
    //     setFolderList(props.checklistGroup);
    // }, []);

    useEffect(() => {
        if(isMount) {

        } else {
            props.assignedFolder(selectedFolder)
        }

    }, [selectedFolder])

    useEffect(() => {
        if(!isMount) {
            if(props.selectedTemplate) {
                fetchChecklistFolders();
            }
        } 
    }, [props.selectedTemplate])

    const fetchChecklistFolders = () => {
        const organization_id = props.selectedOrganization.organization.id;
        const template_id = props.selectedTemplate.template_id;
        
        getChecklistFoldersApi(organization_id, template_id)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response getChecklistFoldersApi', res);
                setFolderList(res.checklist_grouping) 
            })
            .catch((e) => {
                consoleToLog("getChecklistFoldersApi error", e.response);
                if (e.response.data && e.response.data.message) {
                    return false;
                }
            });
    }

    const handleChange = (event, newValue) => {
        if (typeof newValue === 'string') {
            setSelectedFolder({
                title: newValue,
            });
            } else if (newValue && newValue.inputValue) {
            // Create a new selectedFolder from the user input
            setSelectedFolder({
                title: newValue.inputValue,
            });
            } else {
            setSelectedFolder(newValue);
            }
    }

    const getOptionLabel = (option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
            return option;
            }
            // Add "xxx" option created dynamically
            if (option.inputValue) {
            return option.inputValue;
            }
            // Regular option
            return option.group_name;
    }

    const renderOptions = (option) => {
        return (
            <div style={{display: "flex", width: "100%", marginLeft: option.parent_group_id && '16px'}} 
                className={classes.autoStyle} 
                //onClick={() => onMemberClick(option)}
            >
                {option.group_name}
            </div>
            );
    }

    return (
        <Autocomplete
            value={selectedFolder}
            onChange={(event, newValue) => handleChange(event, newValue)}
            //onInputChange={(e, newValue) => fetchChecklistFolders(newValue)}
            filterOptions={filterOptions}
            clearOnBlur
            handleHomeEndKeys
            id="folder-autocomplete-input"
            options={folderList.reverse()}
            disabled={folderList.length === 0}
            getOptionLabel={(option) => getOptionLabel(option)}
            style={{ width: '100%', marginTop:'8px', background: props.fromMobileComponent && '#fff' }}
            renderOption={(option) => renderOptions(option)}
            freeSolo
            classes={{
                root: classes.MuiAutocompleteinputRoot
            }}
            renderInput={(params) => (
                <TextField {...params} 
                    placeholder="Search Folders" 
                    variant="outlined" 
                    classes={{
                        root: classes.customTextField
                    }}    
                />
            )}
        />        
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
   // selectedTemplate: state.organizationInfo.selectedTemplate
});

export default connect(mapStateToProps)(AutocompleteFolder);