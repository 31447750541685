import React, { useEffect } from 'react';
import {Grid, Typography} from '@material-ui/core';
import {connect} from 'react-redux'; 
import {trackGAEvent} from '../../util/AppUtil';
import {addSelectedBillableStatusToFilter, removeSelectedBillableStatusFromFilter, clearSelectedBillableStatus} from '../../actions/filters';
import { useIsMount } from '../useIsMount';

const InvoiceStatusFilters = (props) => {
    const statusNameList = ["Not Generated", "Draft", "Viewed", "Sent", "Downloaded", "Partial", "Paid"];
    const statusValueList = ["not_generated", "draft", "viewed", "sent", "downloaded", "partial", "paid"];
    const statusClassNames = ["not_generated__status", "draft__status", "viewed__status","sent__status","downloaded__status", "partial__status", "paid__status"];
    const statusClassNames1 = ["not_generated__background", "draft__background", "viewed__background", "sent__background", "downloaded__background", "partial__background", "paid__background"]
    const isMount = useIsMount();

    useEffect(()=>{
        if(!isMount) {
            if(props.selectedStatusFilter 
                && props.selectedStatusFilter.length>0 &&
                 props.selectedStatusFilter.indexOf("completed") > -1){
                    console.log("we have status completed")
            }
            else{ 
                console.log("we dont have status completed");
                props.clearSelectedBillableStatus();
            }
        }
    },[props.selectedStatusFilter])

    const statusClassName = (index) => {
        const STATUS_CLASS = `status__filterList ${statusClassNames1[index]}`;
        return props.selectedBillStatusFilter.includes(statusValueList[index]) ? `${STATUS_CLASS} active` : STATUS_CLASS;
    }

    const onBilStatusClick = (selectedValue) => {
        const selectedStatus = props.selectedBillStatusFilter.filter((status) => status === selectedValue);
        trackGAEvent(props.selectedOrganization.organization.name, 'Billable Status Selection Changed', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
        if(selectedStatus && selectedStatus.length > 0) {
            props.removeSelectedBillableStatusFromFilter(selectedValue);
        } else {
            props.addSelectedBillableStatusToFilter(selectedValue);
        }
    }

    return (
        (props.selectedStatusFilter.indexOf("completed") > -1) ? <div style={{marginTop:'16px'}}>
           <span style={{fontSize:'14px'}}>
                Invoice Status            
            </span> 
            <Grid container lg={12} style={{marginTop:'8px'}} >
                {statusNameList.map((status, index) => {
                        return <Grid item
                                    className={statusClassName(index)}
                                    style={{display:'flex', alignItems:'center'}}
                                    key={status}
                                    onClick={() => onBilStatusClick(statusValueList[index])}
                                >
                            <span className={`circle ${statusClassNames[index]}`}></span> 
                            <Typography  style={{fontSize:'12px'}}>{status}</Typography>
                        </Grid>
                    })
                }
            </Grid> 
        </div> : <div></div>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedStatusFilter: state.filters.selectedStatusFilter,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    selectedBillStatusFilter: state.filters.selectedBillStatusFilter
});

const mapDispatchToProps = (dispatch) => ({
    addSelectedBillableStatusToFilter: (item) => dispatch(addSelectedBillableStatusToFilter(item)),
    removeSelectedBillableStatusFromFilter: (item) => dispatch(removeSelectedBillableStatusFromFilter(item)),
    clearSelectedBillableStatus: (item) => dispatch(clearSelectedBillableStatus(item))
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceStatusFilters)