import React, {useState, Fragment, useEffect} from 'react';
import {TextField, Grid, Avatar, Chip} from '@material-ui/core'; 
import {connect} from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ClearIcon from '@material-ui/icons/Clear';
import { useIsMount } from './useIsMount';

const useStyles = makeStyles((theme) => ({
    avatar : {
        width: '15px !important',
        height: '15px !important',
        fontSize: '12px !important',
        background: 'none !important',
        color: 'rgba(0, 0, 0, 0.87) !important',
        border: '1px solid rgba(0, 0, 0, 0.26) !important'
        },

    chip : {
        borderRadius: '4px !important',
        backgroundColor: '#f9f9f9 !important',
        border: '1px solid #ececec !important',
        },

    avatarStyle: {
        width: 20,
        height: 20,
        fontSize: 12
        },
        autoStyle: {
        "&::-webkit-scrollbar": {
            display: "none"
        }
    },
    MuiAutocompleteinputRoot : {
        '& .MuiOutlinedInput-root' : {
        padding:'1px',
        borderRadius: '5px',
        }
    },
    customTextField: {
        "& input::placeholder": {
          fontSize: "13px",
        }
      }
    }
));

const AutocompleteMemberApprover = (props) => {
    const classes = useStyles();
    const isMount = useIsMount(); 
    const [assignedMember, setAssignedMember] = useState([]);
    const [assignedApprover, setAssignedApprover] = useState([]);

    useEffect(() => {
        if(isMount) {

        } else {
            if(props.assignMembers) {
                props.onAssignedMemberList(assignedMember);
            } else {
                props.onAssignedApproverList(assignedApprover);
            }
        }

    }, [assignedMember, assignedApprover])

    const onDeleteMember = (option) => {
        props.assignMembers && setAssignedMember(assignedMember.filter(entry => entry !== option))
        props.assignApprovers && setAssignedApprover(assignedApprover.filter(entry => entry !== option));
        
    }

   /*  const onMemberClick = (option) => {
        !props.isFromCreateChecklistModal && props.addSelectedMemberToFilter(option);
    } */

    const renderOptions = (option) => {
        return (
            <div style={{display: "flex", width: "100%"}} 
                className={classes.autoStyle} 
                //onClick={() => onMemberClick(option)}
            >
                <Avatar style={{marginRight: "6px", marginTop: "4px"}}
                    classes={{
                        root: classes.avatar
                    }}
                >
                    {option.user.firstname.charAt(0)}
                </Avatar>
                {option.user.firstname + ' ' + option.user.lastname}
            </div>
            );
    }
    
    const renderTags = (options) => {
        return (
            options.map(option =>
                <Fragment key={option.user.id}>
                <Chip
                    classes={{
                        root: classes.chip
                    }}
                    avatar={<Avatar classes={{
                        root: classes.avatar
                    }}>{option.user.firstname.charAt(0)}</Avatar>}
                    label={option.user.firstname + ' ' + option.user.lastname}
                    onDelete={() => onDeleteMember(option)}
                    variant="outlined"
                    style={{margin: "2px 2px"}}
                    deleteIcon={<ClearIcon  style={{width: "18px"}}/>}
                />
                </Fragment>))
        }

    return (
        <div style={{marginTop:'6px'}}>
            <Grid item style={{marginTop:'8px'}}>
                <Autocomplete
                    multiple
                    style={{width: "100%", background: props.fromMobileComponent && '#fff'}}
                    id="size-small-outlined-multi"
                    size="small"
                    options={props.members}
                    getOptionLabel={(option) => option.user.firstname + ' ' + option.user.lastname}
                    disableClearable
                    renderOption={(option) => renderOptions(option)}
                    value={(props.assignMembers && assignedMember) || (props.assignApprovers && assignedApprover)}
                    onChange={(e, newValue) => {
                        props.assignMembers ? setAssignedMember(newValue) :  
                        setAssignedApprover(newValue);
                    }}
                    classes={{
                        root: classes.MuiAutocompleteinputRoot
                    }}
                    renderTags={(options) => renderTags(options)}
                    renderInput={(params) => (
                        <TextField {...params} variant="outlined" 
                            placeholder={props.assignMembers ? "Search Member" : "Search Approver"} 
                            fullWidth
                            classes={{
                                root: classes.customTextField
                            }}
                        />
                    )}
                />
            </Grid>    
        </div>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    members: state.organizationInfo.members,
});

export default connect(mapStateToProps)(AutocompleteMemberApprover);