import React, {useState, useEffect, Fragment} from 'react';
import {Drawer, Grid, Typography, Button, IconButton, TextField, Chip, Avatar, Link, Tooltip} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import RightToLeftSideDrawer from '../RightToLeftSideDrawer';
import { MentionsInput, Mention } from 'react-mentions';
import { getMentionOptionsForSubject, getMentionOptionsForBody } from '../../services/mentionService';
import { EditorState, convertToRaw, ContentState,  RichUtils, Modifier } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import htmlToDraft from 'html-to-draftjs';
import ClearIcon from '@material-ui/icons/Clear';
import AddIcon from '@material-ui/icons/Add';
import { useSnackbar } from 'notistack';
import {connect} from 'react-redux';
import { consoleToLog, stringToSlug, trackGAEvent } from '../../util/AppUtil';
import { EmailFollowupStatus } from '../../util/AppUtil';
import axios from 'axios';
import {addOrgEmailDraft, updateOrgEmailDraft} from '../../actions/selectedOrganization';
import { Editor } from "@tinymce/tinymce-react";
import { Autocomplete } from '@material-ui/lab';
import { disableEmailFollowupApi } from '../../services/authService';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';

const useStyles = makeStyles((theme) => ({
    dialogboxFooter: {
        display: "flex",
        position: "absolute",
        bottom: "0px",
        left: "0",
        right: "0",
        padding: "12px 0px 12px 12px",
        zIndex: "15",
        background: "#fff",
        borderTop: "1px solid #ccc",
    }, 
    customMarginTop : {
        margin:'8px 0px 0px 0px'
    },   
    customMarginBottom : {
        marginBottom:'-8px'
    },  
    MuiTextFieldroot : {
        '& .MuiOutlinedInput-adornedEnd': {
            paddingRight: '8px'
        }
    },
    MuiFormControlLabelroot : {
        '& .MuiFormControlLabel-label' : {
            fontSize: '14px',
            color: '#555555',
            marginLeft:'-4px'
        }
    },
    companyEmailLabelStyle : {
        color:'#0071ff', 
        cursor:'pointer'
    },
    circularProgress: {
        marginLeft: 0,
        marginRight: theme.spacing(2),
        color: 'white'
    },
    attachLink : {
        color:'#3eb6e7',
        fontWeight:'600',
        cursor:'pointer',
        textDecoration:"none !important",
        fontSize:'18px',
        display:'flex',
        alignItems:'flex-start',
        '&:hover': {
            color:'#20a8e0'
        }
    },
    downloadLink: {
        color: "#20a8e0",
        fontSize: "15px",
        fontWeight: "600",
        marginLeft: "2px",
        cursor: "pointer",
        maxWidth:'20ch',
        overflow:'hidden',
        whiteSpace:'nowrap',
        textOverflow:'ellipsis',
    },
    attachmentListStyle: {
        display:'flex',
        alignItems:'center', 
        padding:'8px', 
        backgroundColor:'#f9f9f9', 
        border:'1px solid #ececec', 
        borderRadius:'5px', 
        marginRight:'12px',
        marginBottom:'12px'
    },
    downloadStyle: {
        display:'flex',
        alignItems:'center',
        marginRight:'12px',
        marginBottom:'8px',
        backgroundColor:'#f9f9f9',
        border:'1px solid #ececec', 
        padding:'8px',
        borderRadius:'5px'
    },
    attachmentFilename: {
        marginTop:'-2px', 
        marginRight:'6px',
        maxWidth:'20ch',
        overflow:'hidden',
        whiteSpace:'nowrap',
        textOverflow:'ellipsis'
    },
    MuiAutocompleteinputRoot : {
        '& .MuiOutlinedInput-root' : {
        padding:'1px 1px 1px 4px',
        borderRadius: '5px',
        }
    },
    customTextField: {
        "& input::placeholder": {
            fontSize: "13px",
        }
    },
    avatar : {
        width: '15px !important',
        height: '15px !important',
        fontSize: '12px !important',
        background: 'none !important',
        color: 'rgba(0, 0, 0, 0.87) !important',
        border: '1px solid rgba(0, 0, 0, 0.26) !important'
    },
    chip : {
        borderRadius: '4px !important',
        backgroundColor: '#f9f9f9 !important',
        border: '1px solid #ececec !important',
    },
    moreActionsIcons: {
        marginRight: '4px', 
        color: '#555'
    },
    stopFollowupButton: {
        width:'140px',
        background:'white',
        border:'1px solid #ccc',
        borderRadius:'5px',
        fontSize:'13px',
        padding:'1px 0px',
        dispatch:'flex',
        alignItems:'left',
      },
}))

const EmailFollowupDetailsModal = (props) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    
    const [value, setValue] = useState("");
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [attachment, setAttachment] = useState([]);
    const [path, setPath] = useState([]);
   


    useEffect(() => {
        if(props.followupObj) {
            preFillFormData();
        }
    }, [props.followupObj])

    const preFillFormData = () => {
        const { to_emails, cc_emails, bcc_emails,  subject, body, attachments } = props.followupObj;

        const blocksFromHtml = htmlToDraft(body);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const editorState = EditorState.createWithContent(contentState);

        setEditorState(editorState);
        setValue(subject);
        setPath(attachments && attachments.length > 0 ? attachments : []);

    }

    const onDeleteEmail = (option) => {
    
    }


    const renderTags = (options) => {
        return (
            options.map(option =>
                <Fragment>
                <Chip
                    classes={{
                        root: classes.chip
                    }}
                    avatar={<Avatar classes={{
                        root: classes.avatar
                    }}>{option?.charAt(0)}</Avatar>}
                    label={option}
                    // onDelete={() => onDeleteEmail(option)}
                    variant="outlined"
                    style={{margin: "2px 2px"}}
                    // deleteIcon={<ClearIcon  style={{width: "18px"}}/>}
                />
                </Fragment>))
        }

        const showAttachmentFromServer = (filepath, index) => {
    
            return (
                    <Tooltip 
                        key={filepath.attachment_id}
                        title={filepath.path ? filepath.path.replace(/^.*[\\\/]/, '') : filepath.replace(/^.*[\\\/]/, '')}
                        arrow>
                        <div  className={classes.downloadStyle}>    
                        <i className="flaticon-attachment" style={{marginRight:'3px'}}></i>
                            <Typography className={classes.downloadLink}>
                                {filepath.path ? filepath.path.replace(/^.*[\\\/]/, '') : filepath.replace(/^.*[\\\/]/, '')}
                            </Typography>
                            <Link
                                style={{ display: "none" }}
                                id="draft-attachment"
                                target="_blank"
                                onClick={(e) => e.stopPropagation()}
                                download
                            ></Link>
                        </div>
                    </Tooltip>
            )
        }

        const onStopFollowupClick = (followup) => {
           
            const organization_id = props.selectedOrganization.organization.id;
            const followup_id = followup.id;

            disableEmailFollowupApi(organization_id, followup_id)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response disableEmailFollowupApi', res);
                enqueueSnackbar(res.message, {variant:'success'});
                const obj = {
                    ...followup,
                    status: EmailFollowupStatus.INACTIVE
                }
                const updatedArray = props.emailFollowupData.map((followup) => {
                    if(followup.id === obj.id) {
                        followup = obj
                    }
                    return followup;
                });
                props.setEmailFollowupData(updatedArray);
                props.handleFollowupDetailsDrawerClose();
            
            })   

            .catch((e) => {
                consoleToLog('Error disableEmailFollowupApi', e.response);
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant:'error'
                    })
                    return;
                }
            })
         }

    return (
        <RightToLeftSideDrawer 
            title='Email Followup Details'
            openDrawer={props.openFollowupDetailsDrawer}
            closeDrawer={props.handleFollowupDetailsDrawerClose}>
                {props.followupObj &&
                <div className="modal__main3" style={{overflow:'auto'}}>
                    <Grid item container md={12} lg={12} direction="column">
                        
                        <Grid item lg={12}> 
                            <Typography variant="subtitle1" color="primary" className={classes.customMarginBottom}>
                                To
                            </Typography> 
                            <Autocomplete
                                multiple
                                value={props.followupObj?.to_emails}
                                getOptionSelected={(option, value) => option === value}
                                getOptionLabel={(option) => option}
                                id="folder-autocomplete-input"
                                options={props.followupObj?.to_emails}
                                style={{ width: '100%', marginTop:'8px'}}
                                classes={{
                                    root: classes.MuiAutocompleteinputRoot
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} 
                                        variant="outlined" 
                                        classes={{
                                            root: classes.customTextField
                                        }}    
                                    />
                                )}
                                renderTags={(options) => renderTags(options)}
                                disabled
                            />
                        </Grid>

                        {props.followupObj?.cc_emails && props.followupObj?.cc_emails.length > 0 &&
                        <Grid item lg={12}> 
                            <Typography variant="subtitle1" color="primary" className={classes.customMarginBottom}>
                                Cc
                            </Typography> 
                            <Autocomplete
                                multiple
                                value={props.followupObj?.cc_emails}
                                getOptionSelected={(option, value) => option === value}
                                getOptionLabel={(option) => option}
                                id="folder-autocomplete-input"
                                options={props.followupObj?.cc_emails}
                                style={{ width: '100%', marginTop:'8px'}}
                                classes={{
                                    root: classes.MuiAutocompleteinputRoot
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} 
                                        variant="outlined" 
                                        classes={{
                                            root: classes.customTextField
                                        }}    
                                    />
                                )}
                                renderTags={(options) => renderTags(options)}
                                disabled
                            />
                        </Grid>}

                        {props.followupObj?.bcc_emails && props.followupObj?.bcc_emails.length > 0 &&
                        <Grid item lg={12}> 
                            <Typography variant="subtitle1" color="primary" className={classes.customMarginBottom}>
                                Bcc
                            </Typography> 
                            <Autocomplete
                                multiple
                                value={props.followupObj?.bcc_emails}
                                getOptionSelected={(option, value) => option === value}
                                getOptionLabel={(option) => option}
                                id="folder-autocomplete-input"
                                options={props.followupObj?.bcc_emails}
                                style={{ width: '100%', marginTop:'8px'}}
                                classes={{
                                    root: classes.MuiAutocompleteinputRoot
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} 
                                        variant="outlined" 
                                        classes={{
                                            root: classes.customTextField
                                        }}    
                                    />
                                )}
                                renderTags={(options) => renderTags(options)}
                                disabled
                            />
                        </Grid>}

                        <Grid item md={12} lg={12} 
                            className={classes.customMarginTop}>
                            <Typography variant="subtitle1" color="primary" style={{marginBottom:'-8px'}}>
                                Subject
                            </Typography> 

                            <TextField fullWidth
                                variant='outlined'
                                margin='dense'
                                value={props.followupObj?.subject}
                                disabled
                                />
                        </Grid>

                        <Grid item md={12} lg={12} 
                            style={{marginTop:'24px'}}>
                            <Typography variant="subtitle1" color="primary">
                                Message Body
                            </Typography>
                            
                            <div style={{border:'1px solid #ccc'}}>
                            <Editor
                                apiKey={process.env.REACT_APP_TINY_CLOUD_MCE_API_KEY}
                                value={props.followupObj?.body}
                                init={{
                                    height:350,
                                    menubar: false,
                                    selector: 'textarea',
                                    plugins: "link lists",
                                    //images_upload_url: 'postAcceptor.php',
                                    automatic_uploads: true,
                                    content_style:
                                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px; padding:0; }",
                                    images_file_types: "jpg,svg,png",
                                    toolbar: false,
                                    fontsize_formats:
                                    "8pt 9pt 10pt 11pt 12pt 14pt 18pt 24pt 30pt 36pt 48pt 60pt 72pt 96pt",
                                    //paste_data_images: true
                                    background:'#ccc'
                                }}
                                
                                disabled={true}
                            />  
                            </div>
                        </Grid>

                        <Grid item container alignItems='center' style={{marginTop:'8px'}}>
                            {
                                path && path.length > 0 && path.map((filePath, index) => {
                                    return showAttachmentFromServer(filePath, index);
                                    })
                            }
                        </Grid>

                        <Grid item container alignItems='left' style={{marginTop:'25px'}}>   
                             <Grid item>
                                <Button className={classes.stopFollowupButton}
                                    style={{padding:'1px 0px'}}
                                    onClick={() => onStopFollowupClick(props.followupObj)}>
                                    <HighlightOffOutlinedIcon 
                                        className={classes.moreActionsIcons}
                                        style={{fontSize:'15px', verticalAlign:'baseline', color:'#dc143c'}}   
                                        />
                                    <span> Stop Followup </span>
                                </Button>
                             </Grid>
                        </Grid>

                    </Grid>
                </div>}
        </RightToLeftSideDrawer>
            
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
});

const mapDispatchToProps = (dispatch) => ({
    addOrgEmailDraft: (email_draft) => dispatch(addOrgEmailDraft(email_draft)),
    updateOrgEmailDraft: (email_draft) => dispatch(updateOrgEmailDraft(email_draft))
})

export default connect(mapStateToProps, mapDispatchToProps)(EmailFollowupDetailsModal);