import React from 'react';
import Modal from '@material-ui/core/Modal';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import { Grid, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {getLoginRedirectUrlApi} from '../services/genieService';
import {connect} from 'react-redux';
import { consoleToLog } from '../util/AppUtil';
import {setSelectedOrgEmailObj} from '../actions/selectedOrganization';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        position: 'absolute',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(3, 3),
    },
    errorTextStyles: {
        fontSize: '17px',
        fontWeight: '600',
        textAlign: 'center'
    },
    emailTextStyle: {
        fontSize:'16px',
        fontWeight:600,
        display:'flex',
        alignItems:'center'
    },
    emailListContainer: {
        marginTop: '16px',
        '& .more_actionButton' : {
            visibility:'visible',
            width:'120px',
            borderRadius:'5px',
            fontSize:'14px',
            padding:'1px 0px',
            background:'white',
            border:'1px solid #ccc',
        },

    },
    connectIcons: {
        width:'22px',
        marginRight: '8px'
    },
    checkIcon: {
        color:"green",
        fontSize:'20px',
        marginLeft:'8px'
    },
}));

const SendEmailErrorModal = (props) => {
    const classes = useStyles();

    const onConnectEmailClick = (e, emailType) => {
        const emailObj = {
            support: props.selectedEmailId?.support,
            access_users: props.selectedEmailId?.access_users,
            organization_email_id: props.selectedEmailId?.id,
            email_type: emailType
        }
        props.setSelectedOrgEmailObj(emailObj);

        if(emailType === 'gmail') {
            getLoginRedirectUrl();
        } else {
            loginWithoutLook();
        }
    } 

    const getLoginRedirectUrl=()=>{
        const redirect_uri = process.env.REACT_APP_GMAIL_REDIRECT_URL;
        getLoginRedirectUrlApi(redirect_uri)
        .then((response) => {
            const res = response.data;
            consoleToLog("frontend: ", res);
            window.open(res.url, "_self");
        })
        .catch((e) => {
            consoleToLog("error", e);
        });
    }

    const loginWithoutLook = () => {
        window.open(`https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${process.env.REACT_APP_OUTLOOK_CLIENT_ID}&scope=offline_access%20https://graph.microsoft.com/mail.send%20https://graph.microsoft.com/User.Read%20https://graph.microsoft.com/Mail.Read%20https://graph.microsoft.com/Mail.ReadWrite%20https://graph.microsoft.com/Mail.ReadBasic&response_type=code&redirect_uri=${process.env.REACT_APP_OUTLOOK_REDIRECT_URL}`, '_self');
    }

    return (
        <Modal
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={classes.modal}
        >
            <div className={classes.paper}>
                <Grid item sm={12}>
                    <Grid item sm={12}>
                        <Typography className={classes.errorTextStyles}>
                            {props.isEmailSendError?
                            'Email not send, Please try connecting your email.'
                            :
                            'Email is not connected, Please connect your email'
                            }
                            </Typography>
                    </Grid>
                    <Grid item sm={12}>
                        <Grid item container
                            alignItems='center'
                            className={classes.emailListContainer}
                            justifyContent='space-between'>
                                <Grid item sm={5}>
                                    <Typography className={classes.emailTextStyle}>
                                        {props.selectedEmailId?.email}
                                        {props.selectedEmailId?.verified && 
                                            <CheckCircleRoundedIcon className={classes.checkIcon}/>
                                        }
                                    </Typography>
                                </Grid>

                                

                                <Grid item sm={6} style={{marginLeft:'32px'}}>
                                    {!props.selectedEmailId?.email_type 
                                    ?
                                    <div style={{display:'flex',  justifyContent: 'flex-end', alignItems:'center'}}>
                                        <Button className="more_actionButton" 
                                                onClick={(e) => onConnectEmailClick(e, 'gmail')}
                                                style={{paddingLeft:'4px', paddingRight:'4px'}}
                                                >
                                                <img src='images/gmail_icon.png' className={classes.connectIcons}/>
                                                <span> Gmail </span>
                                        </Button>

                                        <Button className="more_actionButton" onClick={(e) => onConnectEmailClick(e, 'outlook')}
                                                > 
                                                <img src='images/outlook_icon.png' className={classes.connectIcons} />
                                            <span> Outlook </span>
                                            
                                        </Button>
                                        
                                    </div>
                                    :
                                    <div style={{display:'flex',  justifyContent: 'flex-end', alignItems:'center'}}>
                                        {props.selectedEmailId?.email_type === 'gmail' &&
                                        <Button className="more_actionButton" 
                                                onClick={(e) => onConnectEmailClick(e, 'gmail')}
                                                style={{paddingLeft:'4px', paddingRight:'4px'}}
                                                >
                                                <img src='images/gmail_icon.png' className={classes.connectIcons}/>
                                                <span> Gmail </span>
                                        </Button>}

                                        {props.selectedEmailId?.email_type === 'outlook' &&
                                        <Button className="more_actionButton" onClick={(e) => onConnectEmailClick(e, 'outlook')}
                                                > 
                                                <img src='images/outlook_icon.png' className={classes.connectIcons} />
                                            <span> Outlook </span>
                                            
                                        </Button>}
                                        
                                    </div>}
                                </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </Modal>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    isEmailSendError: state.organizationInfo.isEmailSendError
})

const mapDispatchToProps = (dispatch) => ({
    setSelectedOrgEmailObj: (emailObj) => dispatch(setSelectedOrgEmailObj(emailObj)),
})


export default connect(mapStateToProps, mapDispatchToProps)(SendEmailErrorModal);