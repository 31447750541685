import React, { useState, useEffect} from "react";
import {TextField, Grid, Typography, MenuItem, CircularProgress, Divider, Tooltip} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import "react-multi-email/style.css";
import { useSnackbar } from 'notistack';
import {connect} from 'react-redux';
import { getOptionsAndValuesForMonthlyInvoicePeriod, 
  getOptionsAndValuesForQuartelyInvoicePeriod, 
  getOptionsAndValuesForHalfYearlyInvoicePeriod, 
  getOptionsAndValuesForYearlyInvoicePeriod } from "../../util/checklistUtil";
import { consoleToLog, getInvoicePeriodicityPlaceholder, trackGAEvent } from "../../util/AppUtil";
import { updateInvoiceAccountForClientApi } from "../../services/invoiceService";
import { useIsMount } from "../useIsMount";
import Checkbox from '@material-ui/core/Checkbox';
import UpdatePriceConfigDrawer from "./UpdatePriceConfigDrawer";

const useStyles = makeStyles((theme) => ({
  MuiFormControlroot: {
    "& .MuiOutlinedInput-input": {
      paddingLeft: "16px",
    },
    "& .MuiSelect-select.Mui-disabled": {
      cursor: "default !important",
      background: "#EEEEEE !important",
    },
    "& .MuiInputBase-input.Mui-disabled": {
      background: "#EEEEEE !important",
      color: "#555555 !important",
    },
  },
  listContainer: {
    "&:last-child .dropdownDivider": {
      display: "none",
    },
  },
  dividerStyle: {
    background: "#000",
    opacity: "0.1",
  },
  customMarginTop: {
    margin: "16px 0px 0px 0px",
  },
  customMarginBottom: {
    marginBottom: "-8px",
  },
  udfButton: {
    position: "relative",
    width: "27px",
    height: "27px",
    minWidth: "0",
    margin: "-5px 0px 0px 10px",
    fontSize: "14px",
    color: "#555555",
    borderRadius: "50%",
    border: "1px solid #555555",
    paddingTop: "5px",
    //display: props.isFromWaitingForMyApproval ? "none" : "flex",
  },
  customColumn: {
    maxWidth: "31.5%",
    flexBasis: "31.5%",
  },
  customColumn2: {
    maxWidth: "5.5%",
    flexBasis: "5.5%",
  },
  circularProgress: {
    marginLeft: 0,
    marginRight: theme.spacing(2),
    color: "white",
  },
  downloadLink: {
    color: "#20a8e0",
    fontSize: "15px",
    fontWeight: "600",
    marginLeft: "2px",
    cursor: "pointer",
  },
//   customColumn1 : {
//     flexWidth: '40%',
//     maxWidth: '40%'
// ``},
  switchBase: {
    color: 'white',
    '&$checked': {
      color: 'white',
    },
    '&$checked + $track': {
      backgroundColor: '#0071ff',
    },
  },
  checked: {},
  track: {},
  periodLabel: {
      color:'#ccc',
      marginLeft:"8px"
  },
  rowContainer: {
    '& .edit_btn' : {
      visibility:'hidden'
    },
    '&:hover .edit_btn': {
      visibility:'visible',
      color:'#0071ff', 
      cursor:'pointer',
    }
  }

}));

const ClientInvoiceSettings = (props) => {
  const classes = useStyles();
  const {enqueueSnackbar} = useSnackbar();
  const[accountList, setAccountList]=useState([]);
  const[selectedAccountId, setSelectedAccountId]=useState("-1");
  const[invoiceConfigList, setInvoiceConfigList]=useState([]);
  const isMount = useIsMount();

  const [checked, setChecked] = useState(true);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [invoiceObj, setInvoiceObj] = useState(undefined);
  const [invoicePeriodRangeArr, setInvoicePeriodRangeArr] = useState([]);

  const entityList = props.accountList.map((account) => account.entities).flat();

  const handleOpenDrawer = (invoiceObj, invoicePeriodRange) => {
    setOpenDrawer(true);
    setInvoiceObj(invoiceObj);
    setInvoicePeriodRangeArr(invoicePeriodRange);
  }

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
    setInvoiceObj(undefined);
  }

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  useEffect(()=>{
    //console.log("hi",props.selectedAccountId);
    setAccountList(props.accountList);
    setSelectedAccountId(props.selectedAccountId===null ? "-1" : props.selectedAccountId);
    setInvoiceConfigList(props.invoiceConfigs);
  },[])


  useEffect(()=>{
   if(!isMount){ 
     //console.log("hi",props.selectedAccountId);
    setSelectedAccountId(props.selectedAccountId===null ? "-1" : props.selectedAccountId);}
  },[props.selectedAccountId])

  useEffect(()=>{
    if(!isMount){ 
      setInvoiceConfigList(props.invoiceConfigs);
    }
   },[props.invoiceConfigs])

  const onChangeAcc = (e) => {
    const value = e.target.value;
    //console.log(value);
    if(value === "-1") return;
    
    const client_id = props.clientId;
    const old_invoice_acct_id = props.selectedAccountId;
    const new_invoice_acct_id = value;
    const client_organization_id = props.companyObj?.organization_id

    setSelectedAccountId(value);

    updateInvoiceAccountForClientApi(client_id, old_invoice_acct_id, new_invoice_acct_id, client_organization_id)
    .then((response) => {
        const res = response.data;
        consoleToLog("Response updateInvoiceAccountForClientApi: ", res);
        enqueueSnackbar('Invoice account updated successfully', {
          variant:'success'
        });
        trackGAEvent(props.selectedOrganization.organization.name, 'Invoice Account Selected', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
        props.companyUpdated(res.client);
        
      })
      .catch((e) => {
        consoleToLog("updateInvoiceAccountForClientApi res", e);
        
        setSelectedAccountId(props.selectedAccountId===null ? "-1" : props.selectedAccountId);
        
        if (e.response.data && e.response.data.message) {
        enqueueSnackbar(e.response.data.message, {
          variant:'error'
          });
        }
      });
  }

  const showView = () => {
    return(
      <Grid item md={12} lg={12}>
      <Grid item container sm={8} style={{marginTop: "10px"}}>
          <Typography variant="subtitle1" 
            className={classes.customMarginBottom}
            style={{marginTop: "10px", fontWeight: "bold"}}>
            Price Configuration:
          </Typography>
      </Grid>
      <Grid item container style={{marginTop:'10px', marginBottom:"-8px"}}>
          <Grid item md={2} lg={2}>
              <Typography variant='body1' className={classes.headings}>
              Billable status     
              </Typography>
          </Grid>
          <Grid item md={2} lg={2}>
              <Typography variant='body1' className={classes.headings}>
                Service    
              </Typography>   
          </Grid> 
          <Grid item md={2} lg={2}>
              <Typography variant='body1' className={classes.headings}>
                  Cost     
              </Typography>
          </Grid> 
          <Grid item md={3} lg={3}>
              <Typography variant='body1' className={classes.headings}>
                  Entity Name     
              </Typography>
          </Grid>   

      </Grid>
      <Grid item md={12} lg={12} style={{marginTop:"12px"}}>
      {
        invoiceConfigList && invoiceConfigList.length > 0 && invoiceConfigList.map((ic, index)=>{
          let invoicePeriodRange;
          if(ic.checklist_periodicity === "monthly"){
              invoicePeriodRange= getOptionsAndValuesForMonthlyInvoicePeriod();
          }
          else if(ic.checklist_periodicity === "quarterly"){
              invoicePeriodRange= getOptionsAndValuesForQuartelyInvoicePeriod();
          }
          else if(ic.checklist_periodicity === "half_yearly"){
              invoicePeriodRange= getOptionsAndValuesForHalfYearlyInvoicePeriod();
          }
          else if(ic.checklist_periodicity === "yearly"){
              invoicePeriodRange= getOptionsAndValuesForYearlyInvoicePeriod();
          }
          else invoicePeriodRange= getOptionsAndValuesForMonthlyInvoicePeriod();

          let entityObj = entityList.find((entityItem) => entityItem.id === ic.entity_id);
        
          return(
            <Grid item container direction='column' 
              key={ic.id} 
              justifyContent='center'
              className={classes.rowContainer}> 

              <Grid item container alignItems="center">
                  <Grid item md={2} lg={2}>
                    <Checkbox
                      checked={ic.billable}
                      onChange={handleChange}
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                      disabled
                    />
                  </Grid>
                  
                  <Grid item md={2} lg={2}>
                      <Tooltip title={ic.service_name} arrow>
                        <Typography variant='body1' 
                          className={classes.headings} 
                          style={{maxWidth:"200px", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}>
                          {ic.service_name}    
                        </Typography>   
                      </Tooltip>
                  </Grid>

                  <Grid item md={2} lg={2} 
                        style={{display:'flex', alignItems:'center'}}>
                        <Typography variant='body1' style={{color:'#555'}}>
                            {ic.cost}
                        </Typography>  
                        
                        <Typography variant='subtitle1'  
                            className={classes.periodLabel}>
                              {getInvoicePeriodicityPlaceholder(ic.periodicity)}
                        </Typography>
                  </Grid>

                  <Grid item md={3} lg={3} 
                        style={{display:'flex', alignItems:'center'}}>
                        <Typography variant='body1' style={{color:'#333'}}>
                            {entityObj.name}
                        </Typography> 
                  </Grid>

                  <Grid item md={1} lg={1}>
                    <Typography variant="subtitle1" onClick={() => handleOpenDrawer(ic, invoicePeriodRange)}
                      className='edit_btn'>
                      Edit
                    </Typography>
                  </Grid>
                
              </Grid>
              <Grid item sm={9} style={{flexBasis:'77%', maxWidth:'77%'}}>
                <Divider light/>
              </Grid>
            </Grid>
          )
        })
      }
      </Grid>
      <UpdatePriceConfigDrawer openDrawer={openDrawer}
        invoiceObj={invoiceObj}
        handleCloseDrawer={handleCloseDrawer}
        invoicePeriodRangeArr={invoicePeriodRangeArr}
        clientId={props.clientId}
        invoiceConfigList={invoiceConfigList}
        setInvoiceConfigList={setInvoiceConfigList}
        accountList={accountList}
      />
    </Grid>
    )
  }

  return (
    props.invoiceSettingLoading ? 
    (<Grid
          container
          sm={12}
          alignItems="center"
          justifyContent="center"
          style={{ marginTop: "2rem", overflow: "hidden" }}
        >
          <CircularProgress color="secondary" size={40} />
        </Grid>)
     : showView()
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  selectedOrganization: state.organizationInfo.selectedOrganization,
  accountList: state.organizationInfo.accountList
});

export default connect(mapStateToProps, null)(ClientInvoiceSettings);

