import React, {useState, useEffect} from 'react';
import {Grid, Dialog, Button, Typography, Avatar, Tooltip, Popover, Paper, CircularProgress, 
    Divider, TextField, InputAdornment, ClickAwayListener, Slide, Drawer, AppBar, Toolbar, IconButton, Link} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import * as PostalMime from "postal-mime";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { useIsMount } from '../../components/useIsMount';
import moment from 'moment';
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';
import {consoleToLog, displayEmailSubject, showEmailIcons, trackGAEvent} from '../../util/AppUtil';
import {connect} from 'react-redux';
import {decryptEmailPathApi} from '../../services/authService';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { useSnackbar } from 'notistack';
import ClearIcon from '@material-ui/icons/Clear';
import {setEmailSentFrom} from '../../actions/selectedOrganization';
import {getEmailDetailsApi} from '../../services/genieService';

const useStyles = makeStyles((theme) =>({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        position: 'absolute',
        backgroundColor: theme.palette.background.paper,
        width:'100vw',
        height:'100vh',
        zIndex:1000
    },
    backIcon: {
        color:'#666',
        padding: "4px 0px",
        fontSize: "18px",
        minWidth: "40px",
        lineHeight: "18px",
        marginTop: "15px",
        marginBottom: "10px",
    },
    collapsibleBtn : {
        cursor: 'pointer',
        border: '1px solid rgba(0, 0, 0, .125)',
        outline: 'none',
        fontSize: '20px',
        marginTop: '8px', 
        display: 'block',
        '&:hover':{
            border: '1px solid #ccc'
        }
    },
    emailFrom : {
        fontSize:'14px',
        fontWeight: 'bold'
    },
    detailsContainer: {
        padding:'0px 16px 24px 16px',
        backgroundColor: theme.palette.primary.light,
        height:'100vh !important',
        overflow:'auto'
    },
    customColumn1: {
        flexBasis:'6.8%', 
        maxWidth:'6.8%'
    },
    avatarStyles: {
        background:'#fff !important',
        height:'40px !important',
        width:'40px !important',
        color:'#666 !important',
        border:'1px solid rgba(0,0,0, 0.125) !important',
        fontSize:'16px !important'
    },
    fromHeadingStyle: {
        fontSize: '14px',
        fontWeight: '600'
    },
    emailDate : {
        fontSize:'13px',
        color:'#666',
        marginRight:'6px'
    },
    emailActionIcons: {
        minWidth: '25px !important'
    },
    emailActionIcons1: {
        width:'120px',
        border: '1px solid #444746',
        borderRadius: '6px'
        
    },
    emailSubjectStyle: {
        fontSize:'15px',
        fontWeight: 600
    },
    allrecipientStyle: {
        fontSize:'12px', 
        color:'#5E5E5E', 
        display:'flex', 
        alignItems:'center',
        width:'auto',
        maxWidth:'600px',
        overflow: 'hidden',
        whiteSpace:'nowrap',
        textOverflow:'ellipsis',
        [theme.breakpoints.down(1450)] : {
            maxWidth:'510px',
        }   
    },
    toText: {
        fontSize:'12px',
        marginRight:'4px',
        color:'#5E5E5E',
        marginTop:'-1.5px'
    },
    dropdownArrowIconStyle: {
        marginLeft:'4px', 
        cursor:'pointer'
    },
    popoverLabelStyle: {
        color: '#999',
        textAlign:'right' 
    },
    paper: {
        position:'absolute',
        padding: theme.spacing(1),
        border: "1px solid rgba(0, 0, 0, 0.12)",
    },
    customMarginLeft: {
        marginLeft:'16px'
    },
    customMarginTop: {
        marginTop:'4px'
    },
    MuiButtonlabel: {
        justifyContent:'flex-start !important',
        marginLeft:'8px',
        color:'#172B4D'
    },
    MuiButtonlabel1: {
        justifyContent:'flex-start !important',
        marginLeft:'8px',
        color:'#dc4f42'
    },
    actionButtonStyle: {
        background:'white', 
        color:'#202020', 
        marginTop:'16px',
        border: '1px solid #202020'
    },
    actionButtonStyle1: {
        background:'white', 
        color:'#202020', 
        marginTop:'16px',
        border:'1px solid #dc4f42',
        color:'#dc4f42'
    },
    clientListStyle: {
        color:'#172B4D',
        marginTop:'4px'
    },
    subtitleStyle: {
        fontSize:'13px',
        marginLeft:'8px', 
        color:'grey', 
    },
    avatarColumn: {
        flexBasis:'6.5%',
        maxWidth:'6.5%'
    },
    emailDetailContainer: {
        background:'#fff',
        padding:'16px',
        marginTop:'8px'
    },
    actionsContainer: {
        marginLeft:'32px',
        background:'#fff',
        borderRadius:'4px',
        padding:'16px 16px'
    },
    supportEmailStyle: {
        padding:'2px 4px',
        borderRadius:'4px',
        color:'#fff',
        marginLeft:'8px'
    },
    customMarginTop1: {
        marginTop:'16px'
    },
    list : {
        cursor:'pointer',
        '&:hover' : {
            background:'#d3d3d3'
        }
    },
    followupTextStyle: {
        width:'auto',
        overflow:'hidden',
        whiteSpace:'nowrap',
        textOverflow:'ellipsis'
    },
    drawer: {
        //    width: drawerWidth,
            flexShrink: 0
    },
    drawerPaper: {
        width: '550px', 
        flexGrow:1,
    },
    appBar: {
        background:'#F9F9F9',
        height:'45px',
        color:'#202020'
    }
}))

const MobileEmailDetails = (props) => {
    const classes = useStyles();
    const isMount = useIsMount();
    const {enqueueSnackbar} = useSnackbar();


    const [emailObj, setEmailObj] = useState(undefined);
    const [showEmailBody, setShowEmailBody] = useState(false);
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [loading, setLoading] = useState(true);
    const [parsedEmailObj, setParsedEmailObj] = useState(undefined);
    const [canScroll, setCanScroll] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [attachmentName, setAttachmentName] = useState(undefined);
    const [attachmentLoading, setAttachmentLoading] = useState(false);

    let fromName = emailObj?.from ? emailObj?.from.name : '';
    const nameArr = fromName.split(" ");
    let nameStr1 = nameArr[0];
    let nameStr2 = nameArr[1] ? nameArr[1] : '';
    const finalStr = nameStr1.charAt(0).toUpperCase() + nameStr2.charAt(0).toUpperCase();

    let email_id = emailObj?.from ? emailObj?.from.email : '';
    let stringDate = moment(`${emailObj?.date}`).toLocaleString();
    //console.log('strng date', stringDate);

    //if there is no text property in email object
    let htmlString = emailObj?.html;
    var div = document.createElement('div');
    div.innerHTML = htmlString;

    div.querySelectorAll('div').forEach(span => {
      div.innerHTML = div.innerHTML.replace(span.outerHTML, '⠀'+span.outerHTML+'⠀'); // Invisible character U+2800
    });

    htmlString = div.innerHTML.split('⠀'); 
    //console.log('html String', htmlString[1].replace(/<[^>]*>?/gm, ''))

    //find if html string has blockquote or not
    var div1 = document.createElement('div');
    div1.innerHTML = htmlString;
    let link = document.getElementsByTagName('blockquote');

    let toEmails = emailObj?.to_email && emailObj?.to_email.length > 0 ? emailObj?.to_email : [];
    let ccEmails = emailObj?.cc && emailObj?.cc.length > 0 ? emailObj?.cc : [];

    let arrList = [...toEmails, ...ccEmails];

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        getEmailDetails();
    }, []);

    useEffect(() => { 
        if(!isMount) {
            if(!emailObj.part1 && !emailObj.part2) {
                updateDetailsView();
            }
        }
    }, [emailObj]);


    const getEmailDetails = () => {
        const email_uuid = props.selectedEmailItem?.id;

        getEmailDetailsApi(email_uuid)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response getEmailDetailsApi: ', res);
                
                setEmailObj(res);
                setLoading(false); 

                trackGAEvent(props.selectedOrganization.organization.name, 'Mobile Email Details Opened', `${props.auth.user.firstname} ${props.auth.user.lastname} `);
            })
            .catch(e => {
                consoleToLog('Error getEmailDetailsApi: ', e);
                setLoading(false);
                if(e.response.data && e.response.data.message) {
                    props.handleDialogClose();
                    return;
                }
            }) 
    }

    const descryptEmailPath = (attachmentObj) => {
        const organinzation_id = props.selectedOrganization.organization.id;
        const raw_s3_path = props.selectedEmailItem?.raw_s3_path;

        setAttachmentLoading(true);
        decryptEmailPathApi(organinzation_id, raw_s3_path)
            .then(async (response) => {
                const res = response.data;
                consoleToLog('Response decryptEmailPathApi: ', res);

                const parser = new PostalMime.default();
                const email = await parser.parse(atob(res.raw_email_data));
                setParsedEmailObj(email);
                downloadClickedAttachment(email, attachmentObj);
            })
            .catch((e) => {
                consoleToLog('Error decryptEmailPathApi: ', e.response);
                setAttachmentLoading(false);
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar('Error opening email details!!!!', {variant:'error'});
                    props.handleEmailDialogClose();
                    return;
                }
            })
    }

    const downloadClickedAttachment = (emailObject, clickedAttachment) => {
        emailObject.attachments.forEach((attachment) => {
            if(attachment.filename === clickedAttachment.name) {
                let downloadableURL = URL.createObjectURL(
                    new Blob([attachment.content], { type: attachment.mimeType})
                );
                //initiate download
                setAttachmentLoading(false);
                setAttachmentName(undefined);
                document.getElementById(`${emailObj?.email_uuid}_attachments_container`).href = downloadableURL;
                document.getElementById(`${emailObj?.email_uuid}_attachments_container`).setAttribute('download', attachment.filename);
                document.getElementById(`${emailObj?.email_uuid}_attachments_container`).click();
                
            }
        })
    }

    const showRecipientPopover = () => {
        return (<Popover
            id='email-recipient-popover'
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
            anchorOrigin={{
            vertical: 'bottom',
            horizontal:'center'
            }}
            transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
            }}
            classes={{
                paper: classes.paper,
            }}
        >   
            <Paper elevation={0} style={{width:'100%'}}>
            <Grid item container alignItems='center'>
                <Grid item md={2}>
                    <Typography variant='body1' className={classes.popoverLabelStyle}>
                        from: 
                    </Typography>
                </Grid>
                
                <Grid item xs={10}>
                {fromName &&
                <Typography className={classes.customMarginLeft} 
                    style={{fontSize:'15px', fontWeight:600}}>
                    {fromName}
                </Typography>}

                <Typography variant='body1' className={!fromName &&classes.customMarginLeft} style={{marginLeft: fromName && '16px'}}>
                    {`${emailObj?.from.email}`}
                </Typography>
                </Grid>
            </Grid>

            {toEmails && toEmails.length > 0 &&
            <Grid item container className={classes.customMarginTop}>
                <Grid item md={2}>
                    <Typography variant='body1' className={classes.popoverLabelStyle} >
                        to: 
                    </Typography>
                </Grid>
                
                <Grid item md={10} >
                    
                    {toEmails.map((email) => {
                        return <Typography key={email} variant='body1' className={classes.customMarginLeft}>
                                    {email}
                                </Typography>
                    })
                    }
                    
                </Grid>
            </Grid>}
            
            {ccEmails && ccEmails.length > 0 &&
            <Grid item container className={classes.customMarginTop}>
                <Grid item md={2}>
                    <Typography variant='body1' className={classes.popoverLabelStyle}>
                        cc: 
                    </Typography>
                </Grid>
                
                <Grid item md={10}>
                    {ccEmails.map((email) => {
                        return <Typography key={email} variant='body1' className={classes.customMarginLeft}>
                                    {email}
                                </Typography>
                    })
                    }
                </Grid>

            </Grid>
            }

            <Grid item container className={classes.customMarginTop}>
                <Grid item md={2}>
                    <Typography variant='body1' className={classes.popoverLabelStyle}>
                        date: 
                    </Typography>
                </Grid>
                
                <Grid item md={10}>
                    <Typography variant='body1' className={classes.customMarginLeft}>
                        {moment(`${stringDate}`).format('DD MMM h:mm A') + ' ' + `(${moment(`${stringDate}`)?.fromNow()})`}
                    </Typography>
                </Grid>

            </Grid>

            <Grid item container className={classes.customMarginTop}>
                <Grid item md={2}>
                    <Typography variant='body1' className={classes.popoverLabelStyle}>
                        subject: 
                    </Typography>
                </Grid>
                
                <Grid item md={10}>
                    <Typography variant='body1' className={classes.customMarginLeft}>
                        {displayEmailSubject(emailObj?.subject)}
                    </Typography>
                </Grid>

            </Grid>
            </Paper>
        </Popover>)
    }

    const showImg = (attachmentObj) => {
        const extn = attachmentObj.name.split('.').pop();
        
        if(extn === 'pdf') {
            return '/images/pdf_icon1.png'
        } else if(extn === 'xlsx') {
            return '/images/excel_icon1.png'
        } else if(extn === 'jpg' || extn === 'jpeg' || extn === 'png' || extn === 'svg') {
            return '/images/image_icon.png'
        } else if(extn === 'doc' || extn === 'docx') {
                console.log('docx image', extn);
            return '/images/docx_icon.png'
        } else {
            return '/images/other_icon.png'
        }
    }

    const onAttachmentClick = (attachment) => {
        if(parsedEmailObj) {
            downloadClickedAttachment(parsedEmailObj, attachment);
            return;
        } else {
            descryptEmailPath(attachment);
        }
    }

    const updateDetailsView = () => {
        if (emailObj !== undefined) {
            let htmlString = `${emailObj.html}`;
            var div = document.createElement('div');
            div.innerHTML = htmlString;

            const inlineImages = emailObj.attachments?.filter((emailItem) =>  emailItem.disposition === 'inline' || emailItem.contentId);
            
            if(inlineImages && inlineImages.length > 0) {
                let imgTags = div.getElementsByTagName("img");
                for(let i = 0; i < inlineImages.length; i++) {
                    for (let j = 0; j < imgTags.length; j++) { 
                        let urlValue = imgTags[j].getAttribute("src"); 
                        if (urlValue.includes('cid')) { 
                            if(urlValue.replace('cid:', '') === inlineImages[i].contentId.replace(/[<>]/g, '')) {
                                imgTags[j].setAttribute("src", 'data:image/png;base64,' + inlineImages[i].content); 
                            }
                        }
                    }  
                }

            }

            //console.log('div initial string', div.innerHTML);
            div.querySelectorAll('blockquote').forEach(blockquote => {
                div.innerHTML = div.innerHTML.replace(blockquote.outerHTML, '⠀'+blockquote.outerHTML+'⠀'); // Invisible character U+2800
            });
            //console.log('div final string', div.innerHTML);

            htmlString = div.innerHTML.split('⠀'); // U+2800
            //console.log('html final string', htmlString);

            const part1 = htmlString.slice(0, 1);
            let finalStr1 = part1.join('');
            const top_content = document.getElementById(`${emailObj.email_uuid}_top_content`);
            top_content.innerHTML = finalStr1;

            const part2 = htmlString.slice(1);
            let finalStr2 = part2.join('');
            const body_content = document.getElementById(`${emailObj.email_uuid}_content`);
            body_content.innerHTML = finalStr2;
            
            const obj = {...emailObj};
            obj.part1 =  part1;
            obj.part2 =  part2;
            
            setEmailObj(obj);
            }
    }
    
    return (
        <Dialog fullScreen 
            open={props.openDialog}
            onClose={props.handleDialogClose}>
                <Grid item>
                    {loading ?
                        <Grid item container justifyContent='center' > 
                            <CircularProgress size={30} style={{marginTop:'32px'}}/>
                        </Grid>
                        :
                        <div className={classes.detailsContainer}>
                            <Grid item container alignItems='center' style={{marginTop:'16px'}}>
                                <Grid item>
                                    <Button onClick={() => props.handleDialogClose()} 
                                        className='header_back_icon'>
                                        <ArrowBackIcon />
                                    </Button>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} className={classes.emailDetailContainer}>
                                
                                <Grid item xs={12}  style={{padding:'12px 0px'}}>
                                    <Typography className={classes.emailSubjectStyle}>
                                        {emailObj?.subject}
                                    </Typography>
                                </Grid>

                                <Grid item container>
                                    <Grid item xs={2}>
                                        <Avatar className={classes.avatarStyles}>
                                            {fromName ? finalStr : 'CN'}
                                        </Avatar>
                                    </Grid>

                                    <Grid item xs={10}>
                                        <Grid item container style={{marginBottom:'16px'}} justifyContent='space-between'>
                                            <Grid item xs={12}>

                                                <Typography className={classes.fromHeadingStyle}>
                                                    {emailObj?.from.name}
                                                </Typography>

                                                <Typography variant='subtitle1' style={{marginLeft:'4px'}}>
                                                    {`<${emailObj?.from.email}>`}
                                                </Typography>
                                                
                                                <Typography style={{display:'flex', alignItems:'center'}}>
                                                    <span className={classes.toText}> 
                                                        To
                                                    </span>
                                                    <span className={classes.allrecipientStyle}>
                                                        {arrList?.map((email) => email).join(', ')} 
                                                    </span>
                                                    <ArrowDropDownOutlinedIcon fontSize='small' 
                                                        onClick={handlePopoverOpen}
                                                        className={classes.dropdownArrowIconStyle}/>
                                                </Typography>
                                                {showRecipientPopover()}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={11}>
                                    <div>
                                        <Typography variant='subtitle1' id={`${emailObj?.email_uuid}_top_content`}>
                                        </Typography>

                                        {emailObj?.part2 && emailObj?.part2.length !== 0 &&
                                        <MoreHorizIcon className={classes.collapsibleBtn}
                                        style={{color: showEmailBody ? 'white' : 'black',
                                                backgroundColor: showEmailBody ? 'black' : 'white'}}
                                        onClick={() => setShowEmailBody(!showEmailBody)}/>}

                                        <Typography variant='subtitle1' style={{display: showEmailBody ? 'block' : 'none'}} 
                                            id={`${emailObj?.email_uuid}_content`}>
                                        </Typography>

                                            
                                        {emailObj && emailObj.attachments && emailObj.attachments.length > 0 &&
                                            <Grid item container alignItems='center' spacing={1} style={{marginTop:'24px'}}>
                                                {
                                                    emailObj.attachments.map((attachmentObj) => {
                                                        return  attachmentObj.disposition !== 'inline' &&
                                                                <Grid item>
                                                                    <Typography className='attachment-link' 
                                                                        onClick={() => {
                                                                            setAttachmentName(attachmentObj.name);
                                                                            onAttachmentClick(attachmentObj);
                                                                        }}>
                                                                        <img src={showImg(attachmentObj)}
                                                                            style={{width:'16px', height:'16px', marginRight:'7px'}}/>
                                                                        {attachmentObj.name}

                                                                        {attachmentLoading && attachmentObj.name === attachmentName && <CircularProgress size={20} style={{marginLeft:'12px'}}/>} 
                                                                    </Typography>
                                                                    <Link
                                                                        style={{ display: "none" }}
                                                                        id={`${emailObj?.email_uuid}_attachments_container`}
                                                                        target="_blank"
                                                                        onClick={(e) => e.stopPropagation()}
                                                                        download
                                                                    ></Link>
                                                                </Grid>
                                                    })
                                                }
                                            </Grid>
                                        }
                                    </div>
                                </Grid>
                                    {/* <Typography variant='subtitle1' dangerouslySetInnerHTML={{ __html: getReadableNotesText(emailObj?.text) }}>
                                    </Typography> */}

                        </Grid>

                        </div>}
                    </Grid>
        </Dialog>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization
})

export default connect(mapStateToProps)(MobileEmailDetails);