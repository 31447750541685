import React, {useState, useEffect, createRef} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Typography, Button, CircularProgress} from '@material-ui/core';
import {connect} from 'react-redux';
import { useSnackbar } from 'notistack';
import {useIsMount} from '../../components/useIsMount';
import MobileAddEditLabelDrawer from './MobileAddEditLabelDrawer';
import {deleteOrgLabelApi} from '../../services/authService';
import { deleteOrgLabel} from '../../actions/selectedOrganization';
import { consoleToLog, trackGAEvent } from '../../util/AppUtil';

const useStyles = makeStyles((theme) => ({
    mainContent : {
        width: '100%',
        height:'calc(100vh - 100px)',
        backgroundColor: theme.palette.primary.light,
        transition: ".5s",
        flexGrow: 1,
        //overflow:'hidden',
        overflow:'auto',
        overflowX:'hidden'
        },
    container: {
        marginTop: "8px",
        marginBottom:'16px'
    },
    labelContainer : {
        marginTop: '30px'
    },
    labelList : {
        background: '#fff',
        padding:'8px',
        marginBottom:'8px',
        borderTop:'1px solid rgba(0,0,0,0.1)',
        borderBottom:'1px solid rgba(0,0,0,0.1)',
    },
    actionIconContainer : {
        display: 'flex', 
        justifyContent: 'flex-end',
        marginLeft:'8px'
    },
    actionIcons : {
        minWidth:'20px',
        justifyContent:'flex-end !important',
        verticalAlign: 'end !important'
    },
    flatIcons : {
        fontSize:'1.2rem', 
        height:'28px'
    },
    labelNameStyle: {
        color: 'white',
        padding: '0 10px',
        maxWidth:'auto',
        overflow:'hidden',
        whiteSpace:'nowrap',
        textOverflow:'ellipsis'
    }
}))

const MobileOrganizationLabels = (props) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    const isMount = useIsMount();

    const myDivToFocus = createRef();

    const [openDrawer, setOpenDrawer] = useState(false);
    const [editLabel, setEditLabel] = useState(undefined);
    const [scrollDown, setScrollDown] = useState(false);

    const handleDrawerOpen = () => {
        setOpenDrawer(true);
    }

    const handleDrawerClose = () => {
        setOpenDrawer(false);
    }

    const onEditLabelClicked = (label) => {
        setEditLabel(label);
    }

    useEffect(() => {
        if(!isMount) {
            if(myDivToFocus && myDivToFocus.current && scrollDown) {
                myDivToFocus.current.scrollIntoView({
                    block: 'nearest'
                })
                setScrollDown(false);
            }
        }

    }, [scrollDown]);

    const onDeleteLabelClick = (e, labelId, labelName) => {
        //e.preventDefault();
        const organization_id = props.selectedOrganization.organization.id;
        const label_id = labelId;
        if(window.confirm("Are you sure you want to delete this label?")) {
            deleteOrgLabelApi(label_id, organization_id)
                .then((response) => {
                    const res = response.data;
                    consoleToLog('Response deleteOrgLabelApi', res);
                    enqueueSnackbar(res.message, {
                        variant: 'success'
                    });
                    props.deleteOrgLabel(label_id);

                    trackGAEvent(props.selectedOrganization.organization.name, 'Delete Label', `${props.auth.user.firstname} ${props.auth.user.lastname} deleted label ${labelName}`);
                })
                .catch((e) => {
                    consoleToLog('Error deleteOrgLabelApi', e.response);
                    if (e.response.data && e.response.data.message) {
                        enqueueSnackbar(e.response.data.message, {
                            variant: 'error'
                        });
                        return false;
                    }
                });
            }
    }

    return (
        <div className={classes.mainContent}>
            <Grid item container direction='column'
                className={classes.container}>
                <Grid item xs={12} style={{marginTop:'8px', padding:'0 12px'}}>
                    <Button fullWidth 
                        onClick={handleDrawerOpen}
                        className='add_button_style'>
                        Add Label
                    </Button>
                </Grid>

                <Grid item xs={12} style={{marginTop:'8px'}} >
                    <Grid item container direction="column" style={{marginTop:'30px'}}>
                    {
                        props.auth && props.labels && props.labels.length > 0 ? 
                        props.labels.map((label) => {
                            return <Grid item container className={classes.labelList}
                                        ref={myDivToFocus}
                                        key={label.id}
                                        justifyContent="space-between"
                                        alignItems="center">
                                        <Grid item xs={10} sm={10}
                                            className={classes.customColumn}
                                            style={{background: `${label.color}`,
                                                    borderRadius:'4px',
                                                    padding:'5px' }}
                                        >
                                            <Typography variant="body2"
                                            className={classes.labelNameStyle}>
                                                {label.name}
                                            </Typography>

                                        </Grid>  

                                        <Grid item xs={1} sm={1} 
                                            className={classes.actionIconContainer}>
                                            <Button className={`${classes.actionIcons}`}
                                                style={{marginRight:'6px'}}
                                                onClick={() => {
                                                    onEditLabelClicked(label);
                                                    handleDrawerOpen();
                                                }}
                                                > 
                                                    <i className={`flaticon-edit ${classes.flatIcons}`}></i>
                                            </Button>

                                            <Button className={`${classes.actionIcons}`}
                                                onClick = {(e) => onDeleteLabelClick(e, label.id, label.name)}
                                            > 
                                                <i className={`flaticon-delete-1 ${classes.flatIcons}`}></i>
                                            </Button> 
                                        </Grid> 
                                    </Grid>

                        }) : <Grid item container className={classes.labelList}>
                                <Grid item> No Labels Found </Grid>
                            </Grid>  

                    }
                    </Grid>

                </Grid>
            </Grid>
            <MobileAddEditLabelDrawer openDrawer={openDrawer}
                handleDrawerClose={handleDrawerClose}
                editLabel={editLabel}
                setEditLabel={setEditLabel}
                setScrollDown={setScrollDown}
            />
        </div>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    labels: state.organizationInfo.labels
});

const mapDispatchToProps = (dispatch) => ({
    deleteOrgLabel: (label_id) => dispatch(deleteOrgLabel(label_id))
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileOrganizationLabels);