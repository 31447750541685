import React, {useState, useEffect} from 'react';
import {Grid, Typography, Button, Box, Container, Paper, Tabs, Tab, Tooltip} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import { useIsMount } from './useIsMount';
import { consoleToLog } from '../util/AppUtil';
import { useSnackbar } from 'notistack';
import TaskWidgets from './TaskWidgets';
import TemplateTasks from './TemplateTasks';
import Rules from './Rules';
import CloseIcon from '@material-ui/icons/Close';
import {templatePublishUnpublishApi} from '../services/authService';
import { history } from '../routers/AppRouter';
import {getOptionsAndValuesForQuartelyInvoicePeriod} from '../util/checklistUtil';
import TemplateApprovalRules from './TemplateApprovalRules';

const useStyles = makeStyles((theme) => ({
    innerbg: {
        //border: "1px solid #dbdbdb",
        borderRadius: "10px",
        padding: "32px 48px",
        background: "#ffffff",
        // [theme.breakpoints.down("md")]: {
        //     padding: "45px 20px",
        //     }
    },
    templateHeading: {
        fontSize : "24px",
        fontWeight: "600",
        margin:'0px auto'
    },
    labelStyles: {
        lineHeight:1, 
        color:'#555555'
      },  
    MuiFormControlLabellabel :{
        marginLeft:'-4px'
    },
    circularProgress: {
        marginLeft: 0,
        marginRight: theme.spacing(2),
        color: 'white'
    },   
    container: {
        marginTop: "16px"
    },
    editIcon: {
        fontSize:'1.1rem',
        lineHeight: 0,
    },
    scheduleType : {
        // padding:'4px 16px',
        // background: theme.palette.primary.main,
        // color:'#fff',
        // borderRadius:'40px'
        padding:'4px 6px',
        background: "#EAEAEA",
        color:'#92929D ',
        borderRadius:'5px',
        fontSize:"10px",
    },
    tab: {
        flexGrow: 1,
        borderRadius:0,
        borderBottom: "1px solid #c5b9b9",
        height:'90%'
    },
    tasksWidgetContainer: {
        width:'100%',
        //height:'70vh',
        background:'#fff',
        overflowX:'hidden',
        overflowY:'auto',
        "&::-webkit-scrollbar": {
            display: "none"
        }
    },
    schedulerStyle : {
        height: '70vh',
        padding: '24px 16px',
        overflow: 'auto',
        scrollbarWidth: 'none',
        "&::-webkit-scrollbar": {
            display: "none"
        }
    }, 
}))

const TemplateTasksAndWidget = (props) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    const isMount = useIsMount();
    
    const template_task_list = props.templateObj ? props.templateObj?.template_task_list : 
    props.predefinedTemplateObj?.predefined_template_tasks;

   // const [templateObj, setTemplateObj] = useState(props.templateObj);
    const [templateTask, setTemplateTask] = useState(template_task_list);
    const [tabValue, setTabValue] = useState(0);
    const [taskObj, setTaskObj] = useState(undefined);
    //const [taskWidgets, setTaskWidgets] = useState([]);

    const template_name = props.templateObj ? props.templateObj.name : props.predefinedTemplateObj.name; 
    const schedule_type = props.templateObj ? props.templateObj.schedule_type : props.predefinedTemplateObj.schedule_type;
    const template_publish = props.templateObj ? props.templateObj.published : props.predefinedTemplateObj.published;
    const business_logic = props.templateObj && props.templateObj.business_logic;
    // consoleToLog('template_publish', template_publish)

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    }

    const showScheduleValues = (value) => {
        switch (value) {
            case 'monthly':
                return 'Monthly';
            case 'quaterly':
                return 'Quaterly';
            case 'half_yearly':
                return 'Half Yearly';
            case 'yearly':
                return 'Yearly';   
            default:
                return '';
        }
    }


    const tasksAndWidget = () => {
        return (
            <Grid item container>
                {/* Tasks  */}
                <TemplateTasks templateObj={props.templateObj}
                    setTaskObj={setTaskObj}
                    taskObj={taskObj}
                    templateTask={templateTask}
                    setTemplateTask={setTemplateTask}
                    predefinedTemplateObj={props.predefinedTemplateObj}
                    fromPredefinedTemplate={props.fromPredefinedTemplate}
                    templateId={props.templateId}
                    is_orgEmail_verified={props.is_orgEmail_verified}
                />

                {/* Widget  */}
                <TaskWidgets taskObj={taskObj} 
                    templateId={props.templateId}
                   // taskWidgets={taskWidgets}
                    templateTask={templateTask}
                    setTemplateTask={setTemplateTask}
                    setTaskObj={setTaskObj}
                    fromPredefinedTemplate={props.fromPredefinedTemplate}
                    is_orgEmail_verified={props.is_orgEmail_verified}
                />
            </Grid>
        )
    }


    const rules = () =>{
        return (
            <div>
                <Rules taskList = {templateTask} 
                    templateId={props.templateId} 
                    fromPredefinedTemplate={props.fromPredefinedTemplate}
                    />
            </div>
        )
    }

    const templateApprovalRules = () =>{
        return (
            <div>
                <TemplateApprovalRules 
                    taskList = {templateTask} 
                    templateId={props.templateId}
                    setTemplateObj={props.setTemplateObj}
                    templateObj={props.templateObj}/>
            </div>
        )
    }

    const onEditTemplateClick = () => {
        if(props.fromPredefinedTemplate) {
            props.setEditPredefinedTemplate(props.predefinedTemplateObj);
            props.setPredefinedTemplateObj(undefined);

        } else {
            props.setEditTemplate(props.templateObj);
            props.setTemplateObj(undefined);
        }
    }

    const onTemplatePublishClick = () => {
            if(template_publish) {
                publishUnpublishApiCall("unpublish");
            } else {
                publishUnpublishApiCall("publish");
            }
    }

    const publishUnpublishApiCall = (pubString) => {
        const organization_id = props.selectedOrganization.organization.id;
        const template_id = props.templateId;
        const tempString = props.fromPredefinedTemplate ? 'predefined_template' : 'template'

        templatePublishUnpublishApi(organization_id, tempString, template_id, pubString)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response templatePublishUnpublishApi ', res);

                enqueueSnackbar(res.message, {
                    variant:'success'
                });

                if(props.fromPredefinedTemplate) {
                   props.setPredefinedTemplateObj(res.predefined_template); 
                } else {
                 props.setTemplateObj(res.template);
                }
            })
            .catch((e) => {
                consoleToLog("Erro templatePublishUnpublishApi ", e);
                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant:'error'
                    })
                    return false;
                }
            });

    }

    const setHeightOfComponent = () => {
        if(props.verifiedEmailArr && props.verifiedEmailArr.length > 0 && props.templateRuleErrorMessage !== null) {
            return 'calc(70vh - 80px)';
        }
        else if(props.no_support_email) {
            return 'calc(70vh - 40px)';
        }
        else if(props.verifiedEmailArr && props.verifiedEmailArr.length > 0) {
            return 'calc(70vh - 40px)';
        } 
        else if(props.templateRuleErrorMessage) {
            return 'calc(70vh - 40px)';
        }  else {
            return '70vh'
        }
    }

    return (
        <div style={{marginBottom:'32px'}}>
            <Container className={classes.container} maxWidth="xl">
                <Grid item container direction='column'>
                    <Grid item container spacing={1}> 
                        <Grid item>
                            <Typography style={{fontSize:'20px', fontWeight:'600'}} color="primary">
                                 {template_name}
                            </Typography>
                        </Grid> 

                        <Grid item>
                            <Button
                                onClick={onEditTemplateClick}
                                style={{
                                minWidth:'20px'
                            }}>
                                <i className={`flaticon-edit ${classes.editIcon}`} ></i>
                            </Button>

                        </Grid> 

                        <Grid item style={{display:'flex', marginLeft:'auto', justifyContent:'space-evenly'}}>
                            <Button
                                variant="contained"
                                disableElevation
                                onClick={onTemplatePublishClick}
                                style={{
                                minWidth:'20px',
                                background: '#0071ff',
                                color:'white',
                                marginRight:'8px'
                            }}>
                                {template_publish ? "Unpublish" : "Publish"}
                            </Button>

                            <Tooltip title="Close" arrow>
                                <Button
                                    onClick={() => history.push('/dashboard')}
                                    style={{
                                    minWidth:'20px'
                                }}>
                                    <CloseIcon/>
                                </Button>
                            </Tooltip>
                        </Grid>
                    </Grid> 

                    <Grid item container style={{marginTop:'-8px', marginLeft:'-8px'}} spacing={1}>
                        {
                            schedule_type && schedule_type.length > 0 ?
                            schedule_type.map((value) => {
                                return  <Grid item key={value}>
                                            <Typography className={classes.scheduleType}>{showScheduleValues(value)}</Typography>   
                                        </Grid>
                                
                            }) : <Grid item></Grid>
                        }   
                    </Grid> 
                </Grid>

                <Grid item container direction="column" style={{marginTop:'24px'}}>
                    <Grid item container>
                        <Paper className={classes.tab} elevation={0}>
                            <Tabs
                                value={tabValue}
                                onChange={handleTabChange}
                                indicatorColor="primary"
                                textColor="primary"
                            >
                                <Tab label="Tasks" />
                                <Tab label="Automation" />
                                <Tab label="Rules" />
                            </Tabs>
                        </Paper>
                    </Grid>

                    <Grid item container 
                        style={{height: setHeightOfComponent()}}
                        className={classes.tasksWidgetContainer}>

                        {tabValue === 0 && tasksAndWidget()}
                        {tabValue === 1 && rules()}
                        {tabValue === 2 && templateApprovalRules()}

                    </Grid>    

                 </Grid>

            </Container>
        </div>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    templateRuleErrorMessage: state.organizationInfo.templateRuleErrorMessage
});

export default connect(mapStateToProps)(TemplateTasksAndWidget);