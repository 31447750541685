import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography} from '@material-ui/core';
import moment from 'moment';
import {connect} from 'react-redux';
import ErrorIcon from '@material-ui/icons/Error';

const useStyles = makeStyles((theme) => ({
    contactLink : {
        color:'#c75877',
        cursor:'pointer',
        marginLeft:'3px'
        
    },
    MuiTypographyroot : {
        background:'#fdd4cd',
        padding:'8px 10px',
        color:'#c75877',
        display:'flex',
        [theme.breakpoints.down(1440)] : {
            fontSize:'12px !important',
            padding:'6px 0px 6px 6px',
        },
        [theme.breakpoints.down(1300)] : {
            fontSize:'11px !important',
            padding:'6px 0px 6px 6px',
        },
    },
    errorIcon : {
        fontSize:'15px', 
        verticalAlign:'text-top',
        marginTop:'3px', 
        marginRight:'4px',
        [theme.breakpoints.down(1400)] : {
            marginTop:'2px',
            marginRight:'2px',
            fontSize:'14px' 
        },
        [theme.breakpoints.down(1300)] : {
            fontSize:'13px', 
            marginTop:'2px',
            marginRight:'2px',
        }
    } 
}))

const OrganizationError = (props) => {
    const classes = useStyles();
    const isActive = (props.selectedOrganization.organization) ? (props.selectedOrganization.organization.deactivation_date) : null;
    
    return (
        <>
        {isActive ?
            <Grid item xs={12} className={classes.errorContainer}>
                <Typography variant="subtitle1" classes={{
                    root:classes.MuiTypographyroot
                }}>
                    <ErrorIcon fontSize="small"  className={classes.errorIcon}/>
                    Your organization has been deactivated on <strong style={{padding:'0 2px'}}>{moment(isActive).format("DD MMMM YYYY")}</strong> because of inactivity or no payment.
                    All recurring checklist will no more be created and SMS and email automation will not work. 
                    <a className={classes.contactLink} 
                        href="https://www.eprocessify.com/contact" 
                        target="_blank">Contact Us</a>
                </Typography>
            </Grid> 
            :
            <></>
        }
        </>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
})

export default connect(mapStateToProps)(OrganizationError);