import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import {Grid, Typography, Dialog, Slide, DialogContent, DialogContentText} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    dialogTitle : {
        padding:'16px 24px', 
        display:'flex',  
        justifyContent:'start', 
        alignItems:'center',
        fontSize:'18px',
        fontWeight:'600'
    },
    avatarStyle: {
        width:"28px",
        height:"28px",
        fontSize:'12px',
        fontWeight:'600',
        color:'#2B2B2B',
        marginRight:'-8px'
    },
    accountListContainer : {
        padding:'8px',
        borderRadius: '4px',
        cursor:'pointer',
        '&:hover' : {
            background: '#eef2f7'
        }
    },
    dividerStyle : {
        background:'#000', 
        opacity:'0.1'
    },
    MuiDialogpaper: {
        scrollbarWidth: 'none',
        overflow:'visible',
        paddingBottom:'5px',
        "&::-webkit-scrollbar": {
            display: "none"
        }   
    },
}))

const AddTemplateModal = (props) => {
    const classes = useStyles();
    
    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="down" ref={ref} {...props} />;
    });

    return (
        <div>
            <Dialog
                    open={props.openDialog}
                    //TransitionComponent={Transition}
                    classes={{
                        paper: classes.MuiDialogpaper}}
                    fullWidth
                    onClose={props.handleDialogClose}>
                    <Typography
                        className={classes.dialogTitle}>
                        <span>Please add work Templates to add client. Click Add now to import Template</span>

                    </Typography>

                    <Grid item container justifyContent='flex-end' spacing={1}
                        style={{padding:'16px 24px'}}>   
                        <Grid item>
                            <Button variant='contained' color='primary' 
                                onClick={props.handleTemplateDrawerOpen}>
                                Add Now
                            </Button>
                        </Grid>

                        <Grid item>
                            <Button variant='outlined' color='primary' 
                                onClick={props.handleDialogClose}>
                                Cancel
                            </Button>
                        </Grid>
                    </Grid> 
                </Dialog>
        </div>
    );
}

export default AddTemplateModal;