import { OverlayView } from "../actions/overlay";

//manage dashboard page loading
const defaultState = {
    overlayRandomToken: "",
    showOverlayView: OverlayView.NO_VIEW,
    bgObj: {},
    isBulkMoveToFolder:false,
    isBulkCompleteChecklist:false,
    overlayMessage:"",
    emailItemId: "",
    isSendEmail:false
}

const overlay= (state = defaultState, action) => {
    switch (action.type) {
        case 'SET_OVERLAY_VIEW':
            return {
                ...state,
                showOverlayView: action.exportView
            };
        case 'SET_OVERLAY_TOKEN':
            return {
                ...state,
                overlayRandomToken: action.randomToken
            };
        case 'SET_BULK_OBJ':
            return{
                ...state,
                bgObj : action.bgObj   
            }
        case 'SET_IS_BULK_MOVETO_FOLDER':
            return{
                ...state,
                isBulkMoveToFolder : action.isBulkMoveToFolder 
            }
        case 'SET_IS_BULK_COMPLETE_CHECKLIST':
            return{
                ...state,
                isBulkCompleteChecklist : action.isBulkCompleteChecklist 
            }  
        case 'SET_OVERLAY_MESSAGE':
            return{
                ...state,
                overlayMessage : action.overlayMessage 
            } 
        case 'SET_EMAIL_ITEM_ID':
            return{
                ...state,
                emailItemId : action.emailItemId 
            }
        case 'SET_IS_EMAIL_SEND':
            return{
                ...state,
                isSendEmail : action.isEmailSend 
            }
            
        default:
            return state;
    }
};

export default overlay;