import React, {useState} from 'react';
import {connect} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles'; 
import {Grid, Typography, TextField, Button, CircularProgress, IconButton, InputAdornment, Tooltip, MenuItem} from '@material-ui/core';
import { consoleToLog, capitalizeFirstLetterOfEachWord, shouldDisable, AccessLevel, trackGAEvent } from '../util/AppUtil';
import { useSnackbar } from 'notistack';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import CheckSharpIcon from '@material-ui/icons/CheckSharp';
import ClearIcon from '@material-ui/icons/Clear';
import {verifyOrgEmailApi, organizationUpdateApi} from '../services/authService';
import RequestCreditsModal from './modals/RequestCreditsModal';
import { orgNameValidator, emailValidator } from '../util/validator';
import { updateSelectedOrg } from '../actions/selectedOrganization';
import { updateOrganization } from '../actions/organization';
import {countries} from "../data/country.json";
import Select from '@material-ui/core/Select';


const useStyles = makeStyles((theme) => ({
    mainContent : {
        width: (showDrawer) => {
            return showDrawer ? "83%" : "100%"; 
        },
        backgroundColor: theme.palette.primary.light, 
        transition: ".5s", 
        flexGrow: 1,
        //overflow:'hidden',
        height:'calc(100vh - 48px)',
        overflow:'hidden',
        overflowX:'hidden', 
    },
    container: {
        marginTop: "8px"
    },
    settingsContainer: {
        width:'100%',
        background:'#fff',
        borderRadius:'4px',
        padding:'16px 24px',
        marginTop:'16px',
        marginBottom:'32px'
    },
    imgContainer :{
        marginTop:"8px",
        marginLeft:'12rem',
        width:'110px',
        height:'105px',
        borderRadius:'50%',
        border: '1px solid rgba(0, 113, 255, 0.19)',
        boxSizing:'border-box',
        display:'flex',
        justifyContent:'center',
        alignItems:'center'
    },
    imgContainer1 :{
        width:'110px',
        height:'105px',
        borderRadius:'50%',
    },
    uploadImg: {
        position:'relative',
        top:'85px',
        right:'30px',
        minWidth:'25px', 
        height:'25px', 
        borderRadius:'50%',
        background:'#3d3d3d', 
        color:'white',
        cursor:'pointer'
    },
    customColumn : {
        flexBasis:'23%',
        maxWidth:'23%'
    },
    circularProgress: {
        marginLeft: 0,
        marginRight: theme.spacing(2),
        color: 'white'
    },  
    formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    },
    selectCountry:{
        width:"100%",
        height:"2.5rem",
        padding:"2px",
    },
    selectCountry2:{
        width:"100%",
        height:"2.5rem",
        padding:"2px",
        color:"grey"
    }
}))

const OrgInfo = (props) => {

    const {showDrawer} = props;
    const classes = useStyles(showDrawer);
    const {enqueueSnackbar} = useSnackbar();
    

    let org_name = props.selectedOrganization.organization.name ? props.selectedOrganization.organization.name : '';
    let org_email = props.selectedOrganization.organization.email ? props.selectedOrganization.organization.email : '';
    let org_country = props.selectedOrganization.organization.country ? props.selectedOrganization.organization.country : 'none';
    let start_month = props.selectedOrganization.organization?.financial_year_start_month;

    const [attachment, setAttachment] = useState(undefined);
    const [organizationName, setOrganizationName] = useState(org_name);
    const [organizationEmail, setOrganizationEmail] = useState(org_email);
    const [selectedcountry, setSelectedCountry] = useState(org_country);
    const [loading, setLoading] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [monthValue, setMonthValue] = useState(start_month);

    const { image } = props.selectedOrganization.organization || {};
    const { access_level } = props.selectedOrganization || {};
    const disabled = shouldDisable(AccessLevel.getUserAccessLevelValue(access_level), AccessLevel.OWNER);
    const isEmailVerified = props.selectedOrganization.organization.is_email_verified;

    const startMonthOptions = [{name: "January", value:1}, {name: "February", value: 2}, {name: "March", value:3}, 
            {name: "April", value: 4}, {name: "May", value: 5}, {name: "June", value: 6},
            {name: "July", value: 7}, {name: "August", value:8}, {name: "September", value: '9'}, 
            {name: "October", value: 10}, {name: "November", value: 11}, {name: "December", value: 12}
        ];

    const onOrgPhotoSelected = (e) => {
        consoleToLog("onOrgPhotoSelected",e.target.files[0]);
        setAttachment (e.target.files[0])
        var oFReader = new FileReader();
        oFReader.readAsDataURL(e.target.files[0]);
        oFReader.onload = function (oFREvent) {
            document.getElementById("organizationPhoto").src = oFREvent.target.result;
        };
    }

    const handleCountryChange = (event) => {
        //console.log("Country: ", event.target.value);
        setSelectedCountry(event.target.value);
      };

    const handleOpenDrawer = () => {
        setOpenDrawer(true);
    }

    const handleCloseDrawer = () => {
        setOpenDrawer(false);
    }

    const onVerifyOrgEmailClick = () => {
        const organization_id = props.selectedOrganization.organization.id;

        verifyOrgEmailApi(organization_id)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response verifyOrgEmailApi: ", res)
                enqueueSnackbar(res.message, {
                    variant:'success'
                });
                trackGAEvent(props.selectedOrganization.organization.name, 'Verify Email From Settings', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
            })
            .catch((e) => {
                consoleToLog("verifyOrgEmailApi error", e);

                if (e && e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant:'success'
                    });
                    return false;
                }
            });
    }

    const endAdornmentIcon = () => {
        return (
            <InputAdornment position="end" onClick={() => {
                if (!disabled && !isEmailVerified) onVerifyOrgEmailClick()
            }}>
                <Tooltip title={isEmailVerified ? "Email Verified" : "Verify Email"} arrow>
                    <IconButton disableRipple style={{color: isEmailVerified ? 'green' : 'red', padding:'0', background:"none"}}>
                        {isEmailVerified ? 
                            <CheckSharpIcon fontSize="medium" /> 
                            : 
                            <>
                                <ClearIcon fontSize="small" style={{marginRight:'4px', fontWeight:'bold'}}/>
                                <Typography style={{color:"#0071FF"}}>Verify </Typography>
                            </>
                        }
                    </IconButton>
                </Tooltip>
                
            </InputAdornment>
        )
    }

    // const endAdornmentLabel = () => {
    //     return (
    //         <InputAdornment position="end" onClick={handleOpenDrawer}
    //             style={{cursor:'pointer'}}>
    //             <Typography variant='body2' style={{color:"#0071FF", fontWeight:'600'}}>
    //                     Request Credits
    //             </Typography>
    //         </InputAdornment>
    //     )
    // }

    /* const SmsCreditsValue = () => {
        return(<Typography variant="body2" style={{padding: "8px", borderRadius: "4px", background: "#CDCDCD"}}>
                                     {`SMS Credit: ${props.selectedOrganization.organization.sms_credit}`}
                                    </Typography>)
    } */

    const handleMonthChange = (e) => {
        setMonthValue(e.target.value);
    }

    const onUpdateClick = (e) => {
        e.preventDefault();

        var orgName = organizationName;
        orgName = orgName.trim();//remove spaces from both side

        if (!orgName) {//check empty
            enqueueSnackbar('Organization name can not be empty!', {
                variant:'error'
            });
            return;
        }

        const isOrgNameValid = orgNameValidator(orgName);

        if (!isOrgNameValid) {
            enqueueSnackbar('Organization name is invalid. It should contain atleast one character.', {
                variant:'error'
            });
            return;
        }

        var orgEmail = organizationEmail;
        orgEmail = orgEmail.trim();//remove spaces from both side

        // if (!orgEmail) {
        //     enqueueSnackbar('Organization email can not be empty!', {
        //         variant:'error'
        //     });
        //     return;
        // }
        // const isOrgEmailValid = emailValidator(orgEmail);
        // if (!isOrgEmailValid) {
        //     return;
        // }
        if(selectedcountry===undefined){
            enqueueSnackbar('Country field can not be entry!', {
                variant:'error'
            });
            return;
        }

        const financial_year_start_month = monthValue;
        if(financial_year_start_month === -1) {
            enqueueSnackbar('Please select ffinancial year start month', {variant:'error'});
            return
        }

        const id = props.selectedOrganization.organization.id
        const image = attachment

        setLoading(true);
        //update organization api call
        organizationUpdateApi(id, capitalizeFirstLetterOfEachWord(orgName), image, selectedcountry, financial_year_start_month)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response organizationUpdateApi: ", res);
                setLoading(false);
                enqueueSnackbar(res.message, {
                    variant:'success'
                });

                //redux updation
                props.updateOrganization(res.organization);//update organization list in redux
                props.updateSelectedOrg(res.organization);//update selected_org in redux 

                trackGAEvent(props.selectedOrganization.organization.name, ' Save Organization', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
            })
            .catch((e) => {
                consoleToLog("organizationUpdateApi error", e.response);
                setLoading(false);
                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant:'error'
                    });
                    return false;
                }
            });
    }

    const onResetClick = () => {
        setOrganizationName(org_name);
        setSelectedCountry(org_country);
        setAttachment(undefined);
    }

    return (
        <Grid container direction="column">
                <Grid item sm={12} className={classes.settingsContainer}>
                    <Grid item md={3} lg={3} >
                        <div style={{display:'flex', justifyContent:'center', position:'relative'}}>
                            <div className={classes.imgContainer}>
                                <img  className={classes.imgContainer1}
                                    src={image ? image : "/images/eprocessify-icon.png"}
                                    id="organizationPhoto"
                                    alt="OrganizationPIc"
                                />
                            </div>
                            <label className={classes.uploadImg}>
                                <Typography style={{marginLeft:'4px', marginTop:'3px'}}>
                                    <EditRoundedIcon fontSize="small" style={{fontSize:'18px'}}/>
                                </Typography>
                                <input style={{display:'none'}}
                                    type="file"
                                    id="input_orgPhoto"
                                    accept="image/x-png,image/gif,image/jpeg"
                                    onChange={onOrgPhotoSelected}
                                    disabled={disabled}
                                />
                            </label>
                        </div>
                    </Grid>  

                    <Grid item container direction="column" spacing={2} style={{marginTop:'2px'}}>
                        <Grid item md={5} lg={5}>
                            <Typography variant="subtitle1">
                                Organization Name
                            </Typography>

                            <TextField 
                                fullWidth
                                variant="outlined"
                                margin="dense"
                                value={organizationName}
                                placeholder="Enter Organization Name"
                                InputLabelProps={{style: {fontSize: 13, marginTop:'3px'}}}
                                disabled={disabled}
                                onChange={(e) => setOrganizationName(e.target.value)}
                            />
                        </Grid> 

                        {/* <Grid item md={5} lg={5}>
                            <Typography variant="body2">
                                Organization Email    
                            </Typography>

                            <TextField 
                                fullWidth
                                variant="outlined"
                                margin="dense"
                                value={organizationEmail}
                                placeholder="Enter Organization Email"
                                InputLabelProps={{style: {fontSize: 13, marginTop:'3px'}}}
                                disabled={disabled}
                                InputProps={{
                                    endAdornment: endAdornmentIcon()
                                    }}
                                onChange={(e) => setOrganizationEmail(e.target.value)}  
                            />
                        </Grid>   */}

                        <Grid item md={5} lg={5}>
                            <Typography variant="subtitle1" style={{marginBottom:'6px'}}>
                                Country  
                            </Typography>
                                <Select 
                                    className={selectedcountry !== undefined ? classes.selectCountry : classes.selectCountry2}
                                    value={selectedcountry}
                                    onChange={handleCountryChange}
                                    variant="outlined"
                                    placeholder='Select Country'
                                    autoWidth
                                    disabled={disabled}
                                >
                                <MenuItem disabled value="none">Select Country</MenuItem>
                                {countries.map((country)=>{
                                    return(
                                        <MenuItem value={country.code}>{country.name}</MenuItem>
                                    )
                                })}
                                </Select>

                        </Grid>

                        <Grid item  md={5} lg={5}>
                            <Typography variant='subtitle1' style={{marginBottom:'6px'}}>
                                Financial Year Start Month
                            </Typography>
                            <Select 
                                placeholder='Select Financial Year Start Month'
                                variant='outlined'
                                margin='dense'
                                value={monthValue}
                                onChange={handleMonthChange}
                                select
                                fullWidth
                                disabled={disabled}
                            >
                                {
                                    <MenuItem value={-1}>Please Select Start Month</MenuItem>
                                }
                                {
                                    startMonthOptions.map((val) => {
                                        return <MenuItem key={val.name} value={val.value}>
                                                    {val.name}
                                                </MenuItem> 
                                    })
                                }
                                
                            </Select>
                        </Grid>

                        {/*  <Grid item md={5} lg={5}>
                            <Typography variant="body2">
                                Organization Credits
                            </Typography>

                            <TextField 
                                fullWidth
                                variant="outlined"
                                margin="dense"
                                value={`SMS Credit: ${props.selectedOrganization.organization.sms_credit}` }
                                placeholder="Request Credits"
                                InputLabelProps={{style: {fontSize: 13, marginTop:'3px'}}}
                                InputProps={{
                                    endAdornment: endAdornmentLabel()
                                }}
                            />
                        </Grid>  */} 

                        <Grid item md={5} lg={5} style={{marginTop:'8px'}}>
                            <Grid item container>
                                <Button variant="contained" 
                                    color="primary" 
                                    style={{minWidth:'107px'}}
                                    disabled={disabled}
                                    onClick={onUpdateClick}
                                    >
                                    {loading && <CircularProgress size={24} className={classes.circularProgress}/>}
                                    Save
                                </Button>    

                                <Button variant="outlined" color="primary" 
                                    onClick={onResetClick}
                                    style={{marginLeft:'8px', minWidth:'107px'}}>
                                    Reset
                                </Button>
                            </Grid>   
                        </Grid> 

                    </Grid> 
                
                </Grid>
            <RequestCreditsModal openDrawer={openDrawer}
                handleCloseDrawer={handleCloseDrawer}
            />
        </Grid>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization
});

const mapDispatchToProps = (dispatch) => ({
    updateOrganization: (organization) => dispatch(updateOrganization(organization)),
    updateSelectedOrg: (organization) => dispatch(updateSelectedOrg(organization))
});

export default connect(mapStateToProps, mapDispatchToProps)(OrgInfo);