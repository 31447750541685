import { getAxios } from "./axiosUtil";

export const getOrgSupportEmailsApi = (organization_id) => {

    return getAxios().get(`${process.env.REACT_APP_GENIE_SERVICE_BASE_URL}/organization/${organization_id}/emails`);
}

export const addOrgUserSupportEmailApi = (organization_id, email, support, emailType) => {
    let data = {email, support}
    return getAxios().post(`${process.env.REACT_APP_GENIE_SERVICE_BASE_URL}/organization/${organization_id}/${emailType}`, data);
}

export const updateOrgSupportEmailApi = (support_email_id, access_user, refresh_token, email_type) => {
    var data = new FormData();
    if(refresh_token) {
        data.append('refresh_token', refresh_token);
    }
    if(email_type) {
        data.append('email_type', email_type);
    }
    if(access_user && access_user.length > 0) {
        for(var i = 0; i < access_user.length; i++){
            data.append('access_user[]', access_user[i].user ? access_user[i].user.id : access_user[i]);
        }
    } else {
        data.append('access_user[]', '');
    }
    
    const header = {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Content-Type': 'multipart/form-data'
        }
    };
    return getAxios().put(`${process.env.REACT_APP_GENIE_SERVICE_BASE_URL}/organization_email/${support_email_id}/edit`, data, header);
}

export const deleteSupportEmailApi = (support_email_id) => {

    return getAxios().delete(`${process.env.REACT_APP_GENIE_SERVICE_BASE_URL}/organization_email/${support_email_id}/delete`);
}

export const createEmailDraftApi = (organization_id, title, subject, body, attachments, apiPath) => {
    let data = {
        organization_id,
        title,
        subject,
        body,
        attachments
    }

    return getAxios().post(`${process.env.REACT_APP_GENIE_SERVICE_BASE_URL}/${apiPath}`, data);
}

export const getEmailDraftListApi = (organization_id) => {

    return getAxios().get(`${process.env.REACT_APP_GENIE_SERVICE_BASE_URL}/organization/${organization_id}/email_drafts`);
}

export const updateEmailDraftApi = (title, subject, body, attachments, apiPath) => {
    let data = {
        title,
        subject,
        body,
        attachments
    }

    return getAxios().put(`${process.env.REACT_APP_GENIE_SERVICE_BASE_URL}/${apiPath}`, data);
}

export const deleteEmailDraftApi = (id) => {

    return getAxios().delete(`${process.env.REACT_APP_GENIE_SERVICE_BASE_URL}/${id}/delete`);
}

export const downloadEmailDraftAttachmentApi = (access_token, filepath, bucket, organinzation_id) => {
    let data = {filepath, bucket, organinzation_id}
    getAxios().defaults.headers.common['Authorization'] = access_token;
    return getAxios().post(`${process.env.REACT_APP_GENIE_SERVICE_BASE_URL}/getsigns3url `, data);
}

export const deleteEmailDraftAttachmentApi = (attachment, apiPath) => {
    const data = {
        attachment
    }
    return getAxios().put(`${process.env.REACT_APP_GENIE_SERVICE_BASE_URL}/${apiPath}/attachment/delete`, data);
}

export const sendSupportEmailVerificationApi = (organization_id, email) => {
    const data = {
        email
    }
    return getAxios().put(`${process.env.REACT_APP_GENIE_SERVICE_BASE_URL}/organization/${organization_id}/send_email_verification_link`, data);
}

//for backend use only
export const updateAllUnverifiedEmailStatusApi = (access_token) => {

    getAxios().defaults.headers.common['Authorization'] = access_token;
    return getAxios().post(`${process.env.REACT_APP_GENIE_SERVICE_BASE_URL}/organization/update_all_unverified_email_status`);
}

export const getLoginRedirectUrlApi = (redirect_uri) => {   
    const param = {
        params: {
            redirect_uri
        }
    }

    return getAxios().get(`${process.env.REACT_APP_GENIE_SERVICE_BASE_URL}/login`, param)
}

export const getGmailOutlookRefeshTokenApi = (code, apiPathStr, organization_id, organization_email_id, redirect_uri)=>{
    const data = {
        code,
        organization_id,
        organization_email_id,
        redirect_uri
    }

    return getAxios().post(`${process.env.REACT_APP_GENIE_SERVICE_BASE_URL}/${apiPathStr}`, data)
}

export const getPredefinedTemplateEmailDraftListApi = (organization_id) => {
    return getAxios().get(`${process.env.REACT_APP_GENIE_SERVICE_BASE_URL}/organization/${organization_id}/predfined_drafts`);
}

export const deletePredefinedEmailDraftApi = (organization_id, id) => {

    return getAxios().delete(`${process.env.REACT_APP_GENIE_SERVICE_BASE_URL}/organization/${organization_id}/predefined_draft/${id}/delete`);
}

export const sendEmailApi = (organization_email_id, from_email, to_email, cc_email, bcc_email, subject, body_text, attachments, metadata, redirect_uri, from_name, company_ids, is_repeat) => {
        let data = {
            from_email,
            to_email,
            cc_email,
            bcc_email,
            subject,
            body_text,
            attachments,
            metadata,
            redirect_uri,
            from_name,
            company_ids,
            is_repeat
        }
    
    const header = {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Content-Type': 'multipart/form-data'
        }
    };

    return getAxios().post(`${process.env.REACT_APP_GENIE_SERVICE_BASE_URL}/email/${organization_email_id}/send`, data);
}

export const checkEmailBackgroundTaskCompleted = (email_item_id) => {
    return getAxios().get(`${process.env.REACT_APP_GENIE_SERVICE_BASE_URL}/bg_job_request/${email_item_id}/check`);
}

export const getAttachmentUploadURLApi = (name, type, bucket, organinzation_id) => {
    let data = {name, type, bucket, organinzation_id}
    return getAxios().post(`${process.env.REACT_APP_GENIE_SERVICE_BASE_URL}/presigns3url`, data);
}

export const addCommentToEmailApi = (email_uuid, comment, mentioned_users) => {
    let data = {
        comment,
        mentioned_users,
    }

    return getAxios().post(`${process.env.REACT_APP_GENIE_SERVICE_BASE_URL}/email/${email_uuid}/add_comment`, data);
}

export const replyForwardEmailApi = (organization_email_id, email_uuid, from_email, to_email, cc_email, bcc_email, subject, body_text, attachments, redirect_uri, metadata, from_name, apiPathStr, company_ids, is_repeat) => {
    let data = {
        from_email,
        to_email,
        cc_email,
        bcc_email,
        subject,
        body_text,
        attachments,
        redirect_uri,
        metadata,
        from_name,
        company_ids,
        is_repeat
    }

return getAxios().post(`${process.env.REACT_APP_GENIE_SERVICE_BASE_URL}/email/${organization_email_id}/message/${email_uuid}/${apiPathStr}`, data);
}

export const createChecklistInGenieApi = (email_uuid, checklists) => {
    let data = {
        checklists
    }

    return getAxios().put(`${process.env.REACT_APP_GENIE_SERVICE_BASE_URL}/email/${email_uuid}/update`, data);
}

export const getIncomingOutgoingEmailListApi = (checklist_id) => {
    return getAxios().get(`${process.env.REACT_APP_GENIE_SERVICE_BASE_URL}/checklist/${checklist_id}/emails`);

}

export const getClientAuditTrailListApi = (client_id, page) => {
    const param = {
        params: {
            page
        }
    }

    return getAxios().get(`${process.env.REACT_APP_GENIE_SERVICE_BASE_URL}/client/${client_id}/emails`, param);
}

export const getEmailDetailsApi = (email_uuid, ) => {
    return getAxios().get(`${process.env.REACT_APP_GENIE_SERVICE_BASE_URL}/email/${email_uuid}/email_details/decrypt`);
}

export const readEmailItemApi = (email_uuids) => {
    let data = {
        email_uuids
    }

    return getAxios().put(`${process.env.REACT_APP_GENIE_SERVICE_BASE_URL}/email/read`, data);
}

export const unreadEmailItemApi = (access_token, email_uuids) => {
    let data = {
        email_uuids
    }

    getAxios().defaults.headers.common['Authorization'] = access_token;
    return getAxios().put(`${process.env.REACT_APP_GENIE_SERVICE_BASE_URL}/email/unread`, data);
}