import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Typography, TextField, Button, CircularProgress} from '@material-ui/core';
import {useSnackbar} from 'notistack';
import { passwordValidator } from '../../util/validator';
import {changePasswordApi} from '../../services/authService';
import { consoleToLog, trackGAEvent } from '../../util/AppUtil';
import {connect} from 'react-redux';

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        marginTop:'16px',
        overFlowY: 'auto',
        overflowX:'hidden',
    },
    tabPanelContainer: {
        flexGrow: 1,
        padding:'15px',
        //height:'100vh',
        marginBottom:'25px',
    },      
    footer: {
        position:'absolute',
        width:'100%',
        bottom:'0px',
        padding:'16px',
        background:'white',
        borderTop:'1px solid rgba(0,0,0,0.1)'
    }

}))

const MobileChangePassword = (props) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();

    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [oldPassInvalid, setOldPassInvalid] = useState(false);
    const [oldPassErrMessage, setOldPassErrMessage] = useState('')
    const [newPassInvalid, setNewPassInvalid] = useState(false);
    const [newPassErrMessage, setNewPassErrMessage] = useState('');
    const [confirmPassIvalid, setConfirmPassInvalid] = useState(false)  
    const [loading, setLoading] = useState(false);

    const onUpdatePasswordClick = (e) => {
        e.preventDefault();

        const old_password = oldPassword
        const new_password = newPassword;

        if (!old_password) {//check empty
            setOldPassInvalid(true);
            setOldPassErrMessage('* Old password can not be empty!');
            return;
        } else {
            setOldPassInvalid(false);
        }

        const isOldPasswordValid = passwordValidator(old_password);
        if (!isOldPasswordValid) {
            setOldPassInvalid(true);
            setOldPassErrMessage('* Password length should be 5 or more!')
            return;
        } else {
            setOldPassInvalid(false);
        }

        if (!new_password) {//check empty
            setNewPassInvalid(true);
            setNewPassErrMessage('* New password can not be empty!');
            return;
        }else {
            setNewPassInvalid(false);
        }

        const isNewPasswordValid = passwordValidator(new_password);
        if (!isNewPasswordValid) {
            setNewPassInvalid(true);
            setNewPassErrMessage('* Password length should be 5 or more!');
            return;
        }else {
            setNewPassInvalid(false);
        }

        if(newPassword !== confirmPassword) {
            setConfirmPassInvalid(true);
            return;
        }else {
            setConfirmPassInvalid(false);
        }


        setLoading(true);
        //call change password api
        changePasswordApi(old_password, new_password)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response changePasswordApi: ", res);
                enqueueSnackbar(res.message, {
                    variant:'success'
                });
                setLoading(false);
                setOldPassword('');
                setNewPassword('');
                setConfirmPassword('');
                trackGAEvent(props.selectedOrganization.organization.name, 'Change Password', `${props.auth.user.firstname} ${props.auth.user.lastname} changed password`);
            })
            .catch((e) => {
                consoleToLog("changePasswordApi error", e);
                setLoading(false);
                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant:'error'
                    });
                    return false;
                }
            });
    } 

    return (
        <div className={classes.mainContainer}>
                <Grid item container direction="column"  
                    spacing={2}
                    style={{padding:'0px 12px', overflow:'hidden'}}>
                    <Grid item xs={12} sm={12}>
                        <Typography variant="body2">
                            Old Password
                        </Typography>

                        <TextField 
                            fullWidth
                            variant="outlined"
                            margin="dense"
                            type="password"
                            placeholder="Enter Old Password"
                            InputLabelProps={{style: {fontSize: 13, marginTop:'3px'}}}
                            onChange={(e) => setOldPassword(e.target.value)}
                            error={oldPassInvalid}
                            helperText={oldPassInvalid ? oldPassErrMessage : ''}
                        />
                    </Grid> 

                    <Grid item xs={12} sm={12}>
                        <Typography variant="body2">
                            New Password
                        </Typography>

                        <TextField 
                            fullWidth
                            variant="outlined"
                            margin="dense"
                            type="password"
                            placeholder="Enter New Password"
                            InputLabelProps={{style: {fontSize: 13, marginTop:'3px'}}}
                            onChange={(e) => setNewPassword(e.target.value)}  
                            error={newPassInvalid}
                            helperText={newPassInvalid ? newPassErrMessage : ''}
                        />
                    </Grid>  

                    <Grid item xs={12} sm={12}>
                        <Typography variant="body2">
                            Confirm New Password
                        </Typography>

                        <TextField 
                            fullWidth
                            variant="outlined"
                            margin="dense"
                            type="password"
                            placeholder="Re-enter New Password"
                            InputLabelProps={{style: {fontSize: 13, marginTop:'3px'}}}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            error={confirmPassIvalid}
                            helperText={confirmPassIvalid ? '* Passwords are not matching!' : ''}
                        />
                    </Grid>  

                </Grid>  

                <Grid item xs={12} sm={12} className={classes.footer}>
                    <Grid item container spacing={1}>
                        <Grid item xs={12} sm={12}>
                            <Button variant="contained" 
                                color="primary"
                                fullWidth 
                                style={{padding:'8px'}}
                                onClick={onUpdatePasswordClick}
                                >
                                {loading && <CircularProgress size={24} className={classes.circularProgress}/>} 
                                Change Password
                            </Button>
                        </Grid>

                        {/* <Grid item xs={6} sm={6}>
                            <Button variant="outlined" 
                                //onClick={onResetClick}
                                fullWidth
                                style={{padding:'8px'}}
                                color="primary"> 
                                Reset
                            </Button>
                        </Grid> */}
                    </Grid>   
                </Grid>      
        </div>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
});

export default connect(mapStateToProps)(MobileChangePassword);