import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Grid, Typography, Button, Tooltip, IconButton, Popover, MenuItem} from '@material-ui/core';
import {connect} from 'react-redux';
import {shouldDisable, AccessLevel, consoleToLog, trackGAEvent} from "../../util/AppUtil";
import {getLoginRedirectUrlApi, getOrgSupportEmailsApi} from '../../services/genieService';
import {setSelectedOrgEmailObj, deleteOrgEmail, checkTemplateRules} from '../../actions/selectedOrganization';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import { sendSupportEmailVerificationApi, deleteSupportEmailApi } from '../../services/genieService';
import { useSnackbar } from 'notistack';
import InfoIcon from '@material-ui/icons/Info';
import SelectFromMemberList from '../checklist/SelectFromMemberList';
import FilterNoneOutlinedIcon from '@material-ui/icons/FilterNoneOutlined';
import ClearIcon from '@material-ui/icons/Clear';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import ForwardingInfoDrawer from '../modals/ForwardingInfoDrawer';


const useStyles = makeStyles((theme) => ({
    connectIcons: {
        width:'20px',
        marginRight:'8px'
    },
    emailListContainer: {
        padding:'0px 0px 8px 24px',
        background: '#F9F9F9',
        marginBottom:'8px',
        borderRadius:'4px',
        '&:last-child' : {
            border:'none',
            marginBottom:'0px'
        },
        
        '& .onEmailListHover' : {
            visibility: 'hidden'
        },
        '&:hover .onEmailListHover': {
            visibility: 'visible'
        },
        '& .more_actionButton' : {
            visibility:'hidden',
            width:'120px',
            borderRadius:'5px',
            fontSize:'14px',
            padding:'1px 0px',
            background:'white',
            border:'1px solid #ccc',
        },
        '&:hover .more_actionButton' : {
            visibility:'visible'
        },

    },
    emailTextStyle: {
        fontSize:'15px',
        display:'flex',
        alignItems:'center'
    },
    checkIcon: {
        color:"green",
        fontSize:'20px',
        marginLeft:'8px'
    },
    dialogTitle : {
        padding:'16px 24px', 
        display:'flex',  
        justifyContent:'start', 
        alignItems:'center',
        fontSize:'18px',
        fontWeight:'600'
    },
    MuiDialogpaper: {
        scrollbarWidth: 'none',
        overflow:'visible',
        paddingBottom:'5px',
        "&::-webkit-scrollbar": {
            display: "none"
        }   
    },
    iconStyles: {
        fontSize:'16px', 
        color:'#555', 
        marginRight:'2px', 
    },
    infoButtonStyle: {
        minWidth:'10px'
    },
    infoStyleIcon: {
        color:'#007bff',
        fontSize:'21px'
    },
    copyIcon: {
        color:'#555555',
        fontSize:'18px'
    },
    emailForwardStyle: {
        color:'grey',
        fontSize:'12px'
    }
}))

const OnboardingSupportEmails = (props) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();

    const [emailId, setEmailId] = useState(undefined);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const organization_id = props.selectedOrganization.organization.id;

    const onConnectEmailClick = (e, emailType) => {
        e.preventDefault();
        const emailObj = {
            email_type: emailType,
            fromOnboardingComponent: true
        }
        props.setSelectedOrgEmailObj(emailObj);

        if(emailType === 'gmail') {
            getLoginRedirectUrl();
        } else {
            loginWithoutLook();
        }
    } 

    const getLoginRedirectUrl=()=>{
        const redirect_uri = process.env.REACT_APP_GMAIL_REDIRECT_URL;
        getLoginRedirectUrlApi(redirect_uri)
        .then((response) => {
            const res = response.data;
            consoleToLog("frontend: ", res);
            window.open(res.url, "_self");
        })
        .catch((e) => {
            consoleToLog("error", e);
        });
    }

    const loginWithoutLook = () => {
        window.open(`https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${process.env.REACT_APP_OUTLOOK_CLIENT_ID}&scope=offline_access%20https://graph.microsoft.com/mail.send%20https://graph.microsoft.com/User.Read%20https://graph.microsoft.com/Mail.Read%20https://graph.microsoft.com/Mail.ReadWrite%20https://graph.microsoft.com/Mail.ReadBasic&response_type=code&redirect_uri=${process.env.REACT_APP_OUTLOOK_REDIRECT_URL}`, '_self');
    }

    const showVerifyEmailText = (email) => {
        return (
            <Tooltip title={"Verify Email"} arrow>
                <IconButton disableRipple
                    onClick={(e) => sendVerificationEmail(e, email)}
                    style={{color: 'red', padding:'0', background:"none", marginLeft:'6px', marginTop:'3px'}}>
                        <>
                            <ClearIcon fontSize="small" style={{marginRight:'2px', fontWeight:'600'}}/>
                            <Typography style={{color:"#0071FF"}}>Verify </Typography>
                        </>
                </IconButton>
            </Tooltip>
        )
    }

    const sendVerificationEmail = (e, email) => {

        sendSupportEmailVerificationApi(organization_id, email.email)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response sendSupportEmailVerificationApi", res);

                enqueueSnackbar(res.message, {variant:'success'});
            })
            .catch((e) => {
                consoleToLog('Error sendSupportEmailVerificationApi:', e.response);
                if(e.response.data && e.response.data.message){
                    enqueueSnackbar(e.response.data.message, {variant:'error'});
                    return;
                }
            })
    }

    const handleDrawerOpen = () => {
        setOpenDrawer(true);
    }

    const handleDrawerClose = () => {
        setOpenDrawer(false);
    }

    const handlePopoverOpen = (event, email) => {
        setAnchorEl(event.currentTarget)
        setEmailId(email.id);
    }

    const handlePopoverClose = () => {
        setAnchorEl(null);
        setEmailId(undefined);
    }

    const deleteSupportEmail = (e, email) => {
        const support_email_id = email.id;
        if(window.confirm('Are you sure you want to delete this email?')) {
            deleteSupportEmailApi(support_email_id) 
                .then((response) => {
                    const res = response.data;
                    consoleToLog('Response deleteSupportEmailApi ', res);
                    enqueueSnackbar(res.message, {
                        variant:'success'
                    })

                    // const updatedSupportEmailArr = props.supportEmails.filter((email) => email.id !== support_email_id);
                    // props.setSupportEmails(updatedSupportEmailArr);
                    props.deleteOrgEmail(support_email_id);
                    props.checkTemplateRules(organization_id);
                    trackGAEvent(props.selectedOrganization.organization.name, 'Deleted Support Email', `${props.auth.user.firstname} ${props.auth.user.lastname}`);

                })
                .catch((err) => {
                    consoleToLog("deleteSupportEmailApi error", e.response);
                    if(e.response.data && e.response.data.message) {
                        enqueueSnackbar(e.response.data.message, {
                            variant:'error'
                        })
                        return;
                    }
                });
        }
    }

    const showMoreActionsPopover = (email) => {
        return <Popover
                    id="more-option-popover"
                    keepMounted
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    className={classes.templatePopover}
                    onClose={handlePopoverClose}
                    style={{marginRight:"8px"}}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    >
                    <MenuItem>
                        Add Accessor
                    </MenuItem>
                    <MenuItem onClick={(e) => deleteSupportEmail(e, email)}>
                        Delete
                    </MenuItem>
                </Popover>
    }

    return (
        <Grid item md={12} style={{margin: '8px 0px 8px 0px'}}>
            <Grid item md={12}>
                {
                    props.orgEmailList && props.orgEmailList.length > 0 ?
                    props.orgEmailList.filter((email) => email.support).map((email) => {
                        return email.support &&
                            <Grid item container
                                alignItems='center'
                                key={email.id}
                                className={classes.emailListContainer}
                                justifyContent='space-between'>
                                    <Grid item sm={12} className={classes.customColumn1}>
                                        <Grid item container alignItems='center' justifyContent='space-between'>
                                            <Typography className={classes.emailTextStyle}>
                                                {email.email}
                                                {email.verified ? 
                                                    <CheckCircleRoundedIcon className={classes.checkIcon}/>
                                                :
                                                showVerifyEmailText(email)  }
                                            </Typography>
{/* 
                                            <Button onClick={(event) => handlePopoverOpen(event, email)}>
                                                <MoreHorizIcon fontSize='small'/>
                                            </Button> */}
                                            {/* {emailId === email.id && showMoreActionsPopover(email)} */}
                                        </Grid>
                                        <Grid item md={12}>
                                            <Grid item container alignItems='center'>
                                                <Grid item md={10}>
                                                    <Typography variant='subtitle1' className={classes.emailForwardStyle}>
                                                        {`Start receiving email by adding forwarding address to ${email.alias_email}@${process.env.REACT_APP_STG_EPROCESSIFY_MAIL}`}
                                                    </Typography>
                                                </Grid>

                                                <Grid item md={1} style={{marginTop:'-12px'}}>
                                                    <Tooltip title='Copy Alias Email' arrow>
                                                        <Button style={{marginTop:'-5px'}} 
                                                            className={classes.infoButtonStyle}
                                                            onClick={() => {
                                                                    console.log('onClick')
                                                                    navigator.clipboard.writeText(`${email.alias_email}@${process.env.REACT_APP_STG_EPROCESSIFY_MAIL}`);
                                                                    trackGAEvent(props.selectedOrganization.organization.name, 'Copied Support email', `${props.auth.user.firstname} ${props.auth.user.lastname}`);

                                                                    enqueueSnackbar('Alias email copied successfully!!!', {
                                                                        variant:'success',
                                                                        anchorOrigin: {
                                                                            vertical:'top',
                                                                            horizontal:'center'
                                                                        }
                                                                    })
                                                                }
                                                            }>
                                                            <FilterNoneOutlinedIcon className={classes.copyIcon}/>
                                                        </Button>
                                                    </Tooltip>
                                                </Grid>

                                                <Grid item md={1} style={{marginTop:'-12px'}}>
                                                    <Tooltip title='Info' arrow>
                                                        <Button style={{marginTop:'-5px'}} 
                                                            className={classes.infoButtonStyle}
                                                            onClick={handleDrawerOpen}>
                                                            <InfoIcon className={classes.infoStyleIcon}/>
                                                        </Button>
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    {/* <Grid item sm={3} className={classes.customColumn2}>
                                        <Typography
                                            variant="body1"
                                            style={{marginTop:'-8px', marginBottom:'4px', marginLeft:'2px', color:'#202020'}}
                                            className="onEmailListHover"
                                        >
                                            Accessors
                                        </Typography>
                                        {/* <SelectFromMemberList assignees={getFilteredMemberArr(email)} 
                                            setAssignedMemberList={(selectedAssignees) =>
                                                setAssignedMemberList(selectedAssignees, email.id)
                                            }
                                            fromSupportEmailComponent={true}
                                            isShowOnly={true}
                                        />}
                                    </Grid> */}
                                </Grid>
                    }) : 
                    <Grid item container justifyContent='center'>
                        <Typography  variant='body' 
                            style={{padding:'16px', fontSize:'16px'}}>
                            No Support Email Found 
                        </Typography>
                    </Grid>
                }
            </Grid>
            <Grid item container justifyContent='center' alignItems='center' spacing={1} 
                style={{marginTop:"16px"}}>
                <Grid item md={5}>
                    <Button variant='outlined' color='primary' fullWidth
                        onClick={(e) => onConnectEmailClick(e, 'gmail')}>
                        <img src='images/gmail_icon.png' className={classes.connectIcons}/>
                        Gmail
                    </Button>
                </Grid>

                <Grid item md={5}>
                    <Button variant='outlined' color='primary' fullWidth
                        onClick={(e) => onConnectEmailClick(e, 'outlook')}>
                        <img src='images/outlook_icon.png' className={classes.connectIcons} />
                        Outlook
                    </Button>
                </Grid>
            </Grid>

            <ForwardingInfoDrawer openDrawer={openDrawer} 
                handleDrawerClose={handleDrawerClose}
                fromOnboardingComponent={true}
                />
        </Grid>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    orgEmailList: state.organizationInfo.orgEmailList
});

const mapDispatchToProps = (dispatch) => ({
    setSelectedOrgEmailObj: (emailObj) => dispatch(setSelectedOrgEmailObj(emailObj)),
    deleteOrgEmail: (email_id) => dispatch(deleteOrgEmail(email_id)),
    checkTemplateRules: (organization_id) => dispatch(checkTemplateRules(organization_id))
});

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingSupportEmails);