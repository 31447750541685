import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { consoleToLog, trackGAEvent } from "../../util/AppUtil";
import { updateFileUploadUrlApi } from "../../services/authService";
import { connect } from "react-redux";
import S3FileUpload from "react-s3";
import { Link } from "react-router-dom";
import { getTaskAttachmentUrlApi, getUploadUrlForChecklistAttachmentApi } from "../../services/ChecklistService";
import { Grid, Button } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useSnackbar } from 'notistack';
import { useIsMount } from "../useIsMount";
import axios from 'axios';
import {downloadAttachmentApi} from '../../services/authService';
import {uploadChecklistAttachmentApi} from '../../services/ChecklistService';
import { showImg } from "../../util/AppUtil";
import {deleteChecklistAttachmentApi} from '../../services/ChecklistService';

const useStyles = makeStyles((theme) => ({
  customFileUploadTypo: {
    fontWeight: 600,
    fontSize: "1rem",
    marginBottom: "1rem",
  },
  customFileUploadMain: {
    marginTop: "15px",
    marginBottom: "10px",
  },
  selectedFileName: {
    paddingLeft: "10px",
    fontSize: "13px",
    color: "888888",
  },
  downloadLink: {
    color: "#20a8e0",
    fontSize: "15px",
    fontWeight: "600",
    marginLeft: "15px",
    marginTop: "-10px",
    cursor: "pointer",
  },
  uploadLoader:{
    height:"1.5rem",
    width:"1.5rem",
    marginLeft:"1rem",
    marginTop:"-1px"
  },
  mobileDisplay: {
    color: "#20a8e0",
    fontSize: "15px",
    fontWeight: "600",
    marginLeft: "15px",
    cursor: "pointer",
    maxWidth:'30ch',
    overflow:"hidden",
    whiteSpace:'nowrap',
    textOverflow:'ellipsis',
    display:'block'
  },
  uploadBtn: {
    marginRight:'8px',
    '&:hover' : {
      textDecoration: "none !important",
      backgroundColor: '#222 !important'
    }
  }
}));

const UploaderWidget = (props) => {
  const classes = useStyles();
  const [widget, setWidget] = useState(props.widget);
  const [downloadableURL, setDownloadableURL] = useState(undefined);
  const [uploadLoading, setUploadLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const isMount = useIsMount();
  const [taskAttachments, setTaskAttachments] = useState(props.selectedTask.task_attachments);
  const [attachmentLoading, setAttachmentLoading] = useState(false);
  const [attachmentId, setAttachmentId] = useState(undefined);

  useEffect(() => {
    setWidget(props.widget);
  }, []);

  useEffect(() => {
    if(!isMount){
      setTaskAttachments(props.selectedTask.task_attachments);
    }
  }, [props.selectedTask.task_attachments]);

  useEffect(() => {
    if(downloadableURL !== undefined){
      document.getElementById("initiateDownload").href = downloadableURL;
      document.getElementById("initiateDownload").click();
    }
  }, [downloadableURL])

  const onAttachmentSelected = (e) => {
    const {target: { files }} = e;
    const user_id = props.auth.user.id;
    const taskId = props.selectedTask.id;
    const organization_id = props.selectedOrganization.organization.id;
    const type = 'task';
    const auto_generated_id = props.checklistItem?.company.auto_generated_id;
    const template_slug = props.selectedTemplate?.slug;
    const subtitle = props.checklistItem?.subtitle;
    const organization_slug = props.selectedOrganization.organization?.slug;
    const fileArr = e.target.files;
    let totalAttachmentArray = [];
    setUploadLoading(true);

    for(let i = 0; i < fileArr.length; i++) {
      getUploadUrlForChecklistAttachmentApi(organization_id, fileArr[i].name, type, auto_generated_id, template_slug, subtitle, organization_slug, '')
      .then((response) => {
        const res = response.data;
        consoleToLog('Response getUploadUrlForChecklistAttachmentApi: ', res);

        // Put the fileType in the headers for the upload
        const options = {
          headers: {
            "Content-Type": fileArr[i].type,
          }
        };

        axios.defaults.headers.common = {};
          axios.put(res.upload_signed_url, fileArr[i], options)
          .then(response => {
            consoleToLog('signed Request s3', response);
            callUploadAttachmentSuccessApi(organization_id, type, res.url, taskId, fileArr, totalAttachmentArray)
          })
          .catch(error => {
      setUploadLoading(false);
              consoleToLog('error: ', error);
            })
  
      })
      .catch((err) => {
        consoleToLog("File Upload Error: ", err);
      });

    }
  };

  const callUploadAttachmentSuccessApi = (organization_id, type, path, target_id, fileArr, totalAttachmentArray) => {
    const user_id = props.auth.user.id;
    const client_id = props.checklistItem?.company.id;

    uploadChecklistAttachmentApi(organization_id, type, '', path, target_id, user_id, client_id)
      .then((response) => {
        const res = response.data;
        consoleToLog("***Response updateFileUploadUrlApi: ", res);;
        //const updatedWidget = res.checklist_task_widget;
        trackGAEvent(props.selectedOrganization.organization.name, 'Upload File', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
        totalAttachmentArray.push(res.attachment); 
        if(fileArr.length === totalAttachmentArray.length) {
          console.log('multiple files uploading');
          let finalAttachmentArray = [...taskAttachments, ...totalAttachmentArray];
          //setTaskAttachments(finalAttachmentArray);
          props.updateTaskAttachmentArray(res.checklist_task);
        }

        enqueueSnackbar('File uploaded successfully!', { 
          variant: 'success',
      });
        //update parent state
        //props.onWidgetUpdated(updatedWidget); 
        setUploadLoading(false);
      })
      .catch((e) => {
        consoleToLog("updateFileUploadUrlApi error", e);
        setUploadLoading(false);
      });
  };

  const onAttachmentClick = (e, fileObj) => {
    e.stopPropagation();
    const path = fileObj.path;
    const organization_id = props.selectedOrganization.organization.id;
    setAttachmentLoading(true);

    // get downloadable url from backend using url
    downloadAttachmentApi(organization_id, path)
      .then((response) => {
        const res = response.data;
        consoleToLog("Response downloadAttachmentApi: ", res);
        const downloadableURL = res.document_signed_url;
        consoleToLog("downloadableURL in function", downloadableURL);
        setDownloadableURL(downloadableURL);
        setAttachmentLoading(false);
        setAttachmentId(undefined);
      })
      .catch((e) => {
        consoleToLog("downloadAttachmentApi error", e.response);
        setAttachmentLoading(false);
      });
  };

  const deleteChecklistAttachment = (e, fileObj) => {
    e.stopPropagation();
    if(window.confirm('Are you sure you want to delete this attachment')) {
      const organization_id = props.selectedOrganization.organization.id;
      const attachment_id = fileObj.id;
      setAttachmentLoading(true);

      deleteChecklistAttachmentApi(organization_id, attachment_id)
        .then((response) => {
          const res = response.data;
          consoleToLog("Response deleteChecklistAttachmentApi ", res);

          setAttachmentLoading(false);
          
          enqueueSnackbar(res.message, {
            variant: "success",
          });

          trackGAEvent(props.selectedOrganization.organization.name, 'Checklist Delete Attachment', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
          // to parent
          let updatedTaskAttachmentArray = taskAttachments.filter((attachment) => attachment.id !== attachment_id);
          props.updateTaskAttachmentArray(res.checklist_task);
        })
        .catch((e) => {
          setAttachmentLoading(false);
          consoleToLog("Response deleteChecklistAttachmentApi error", e.response);
        });
    }
  };
  return (
    <>
      <div className={classes.customFileUploadMain}>
          <Grid container>
            <Grid item container>
              <Typography className={classes.customFileUploadTypo}>
                Attachments
              </Typography>
            </Grid>
            <Grid item container direction="row" alignItems="center">
                <Button variant="contained" 
                  color="primary"
                  component="label"
                  disableTouchRipple
                  classes={{
                    root: classes.uploadBtn
                  }}>
                  <input type="file" 
                    style={{ display: "none" }}
                    onChange={onAttachmentSelected} 
                    multiple/>
                    Upload
                    {uploadLoading && <CircularProgress style={{height:"1.5rem", width:"1.5rem",marginLeft:"1rem"}}/>}  
                </Button>
              {/*File downloader*/}
              {taskAttachments && taskAttachments.length > 0 ? 
                taskAttachments.map((attachmentObj) => {
                  return <Grid item style={{marginRight:'6px'}}>
                            <Typography className='attachment-link' 
                                onClick={(e) => {
                                    onAttachmentClick(e, attachmentObj);
                                    setAttachmentId(attachmentObj.id);
                                  }}
                                >
                                <img src={showImg(attachmentObj)}
                                    style={{width:'16px', height:'16px', marginRight:'7px'}}/>
                                {attachmentObj.title}

                                {(attachmentLoading && attachmentId === attachmentObj.id) ? <CircularProgress size={20} style={{marginLeft:'12px'}}/> : 
                                  <i className="flaticon-delete-1" 
                                    onClick={(e) => deleteChecklistAttachment(e, attachmentObj)}
                                    style={{marginLeft:'8px', fontSize:'17px', position:'relative', top:'2px', cursor:'pointer'}}></i>}
                            </Typography>
                            <Link
                                style={{ display: "none" }}
                                id={`initiateDownload`}
                                target="_blank"
                                onClick={(e) => e.stopPropagation()}
                                download
                            ></Link>
                        </Grid>
                })
                :<Typography>No Task Attachment Found</Typography>
                
              }
            </Grid>
          </Grid>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  selectedOrganization: state.organizationInfo.selectedOrganization,
  selectedTemplate: state.organizationInfo.selectedTemplate
});

export default connect(mapStateToProps, null)(UploaderWidget);
