import React,{useEffect, useState} from "react";
import { Grid ,TextField, CircularProgress, Select, MenuItem, Tooltip, IconButton, Link, Divider } from "@material-ui/core";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Typography} from "@material-ui/core";
import RightToLeftSideDrawer from './RightToLeftSideDrawer';
import SelectFromMemberList  from "../components/checklist/SelectFromMemberList";
import { consoleToLog, shouldDisable, AccessLevel, showAllCountries, showAllStates, trackGAEvent, parseString } from "../util/AppUtil";
import { useSnackbar } from "notistack";
import { emailValidator } from "../util/validator";
import {setOrgInvoiceAccounts} from '../actions/selectedOrganization';
import { useIsMount } from "./useIsMount";
import CheckSharpIcon from '@material-ui/icons/CheckSharp';
import ClearIcon from '@material-ui/icons/Clear';
import InvoiceListModal from './modals/InvoiceListModal';
import {disconnectInvoiceAccountApi} from '../services/clientService';
import {markAsDefaultApi, disconnectedInvoiceAccountsApi} from '../services/invoiceService';
import {unsetEntityApi} from '../services/authService';

const useStyles = makeStyles((theme) => ({
  mainContent : {
    width: (showDrawer) => {
       return showDrawer ? "83%" : "100%"; 
    },
    backgroundColor: theme.palette.primary.light, 
    transition: ".5s", 
    flexGrow: 1,
    //overflow:'hidden',
    height:'calc(100vh - 48px)',
    overflow:'auto',
    overflowX:'hidden'
    },
    
  container: {
    // width: "88vw",
    width:"76vw",
    backgroundColor: "white",
    height: "70vh",
    overflow: "hidden",
    padding: "1rem",
    paddingTop: "2rem",
  },
  heading: {
    fontSize: "20px",
    fontWeight: "600",
  },
  btn: {
    background: "#383838",
    // width: "107px",
    // padding: "7px 0",
    marginTop: "-4px",
    color: "white",
    padding:"3px 7px",
    maxWidth:"7rem"
  },
  invoiceItemContainer: {
    marginTop: "1rem",
    backgroundColor: "#F9F9F9",
    padding:"10px"
  },
  attachmentsIcon: {
    padding: "6px 4px",
    // marginLeft:"15px",
    // paddingRight:"0px"
    paddingLeft: "15px",
  },
  attachmentEndIcons: {
    marginRight: "15px",
    cursor: "pointer",
  },
  attachmentEndIcons1: {
    marginRight: "15px",
    cursor: "pointer",
  },
  customMarginBottom : {
    marginBottom:'2px',
    marginTop:'12px',
  },
  circularProgress: {
        marginLeft: 0,
        marginRight: theme.spacing(2),
        color: 'white'
    },
    enableStyle:{
      width:"100%",
      height:"2.5rem",
      padding:"2px",
  },
    disableStyle:{
      width:"100%",
      height:"2.5rem",
      padding:"2px",
      color:"#bababa"
  },
  linkStyle: {
    fontSize:'14px',
    color:'#007bff',
    cursor:'pointer',
    display:'flex',
    alignItems:'center'
  }  
}));

const showEmailVerificationEmail = (isEmailVerified, onVerifyEmailClick, account_id) => {
  return (
      <Grid item style={{marginLeft:'8px'}}>
          <Tooltip title={isEmailVerified ? "Email Verified" : "Verify Email"} arrow>
              <IconButton disableRipple 
                  onClick={() => !isEmailVerified && onVerifyEmailClick(account_id)}
                  style={{color: isEmailVerified ? 'green' : 'red', padding:'0', background:"none", cursor:'pointer'}}>
                  {isEmailVerified ? 
                      <CheckSharpIcon fontSize="small" style={{fontSize:"18px", marginTop:'-2px'}}/> 
                      : 
                      <>
                          <ClearIcon fontSize="small" style={{marginRight:'4px', fontWeight:'bold', fontSize:"15px"}}/>
                          <Typography variant='subtitle1' style={{color:"#0071FF", marginTop:'-1px'}}>Verify </Typography>
                      </>
                  }
              </IconButton>
          </Tooltip>
          
      </Grid>
  )
}


const InvoiceItem = ({ account, key, onMarkasDefaultClick, loading, organizationId, enqueueSnackbar, accountList, setOrgInvoiceAccount}) => {
  const classes = useStyles(useStyles);
  const is_email_verified = account?.is_email_verified;
  let account_id = account.id;

  const [accountId, setAccountId] = useState('');

  const onDisconnectInvoiceClick = (account) => {

      if(window.confirm('Are you sure you want to disconnect this invoice account?')) {

        disconnectInvoiceAccountApi(organizationId, account_id)
          .then((response) => {
              const res = response.data;
              consoleToLog('Response disconnectInvoiceAccountApi: ', res);
              enqueueSnackbar(res.message, {variant:"success"});

              setOrgInvoiceAccount(accountList.filter(account => account.id !== account_id));
              disconnectInvoiceAccountFromWorkplace(organizationId, account_id);
          })
          .catch((e) => {
              consoleToLog('Error disconnectInvoiceAccountApi: ', e.response);
              if(e.response.data && e.response.data.message) {
                return;
              }
          })
      }

  }

  const disconnectInvoiceAccountFromWorkplace = (organizationId, account_id) => {

    disconnectedInvoiceAccountsApi(organizationId, [account_id])
      .then((response) => {
          const res = response.data;
          consoleToLog('Response disconnectedInvoiceAccountsApi: ', res);

          callUnsetEntityApi(organizationId);
      })
      .catch((e) => {
          consoleToLog('Error disconnectedInvoiceAccountsApi: ', e.response);
          if(e.response.data && e.response.data.message) {
            return;
          }
      })
  } 

  const callUnsetEntityApi = (organizationId) => {
    unsetEntityApi(organizationId)
      .then((response) => {
        const res = response.data;
        consoleToLog('Response unsetEntityApi: ', res);

      })
      .catch((e) => {
          consoleToLog('Error unsetEntityApi: ', e.response);
          if(e.response.data && e.response.data.message) {
            return;
          }
      })
  }

  return (
    <Grid
      item
      container
      direction="column"
      key={key}
      className={classes.invoiceItemContainer}
    >
      <Grid item md={12}>
        <Grid item container style={{marginTop:'8px'}}>
          <Grid item md={9}>
            <Typography style={{fontSize:'16px', fontWeight:'600', marginLeft:'8px'}}>
              {account.name} 
            </Typography>
          </Grid>

          <Grid item md={2}>
            <Link onClick={(e) => onDisconnectInvoiceClick(e, account)}
              style={{ marginLeft:'-8px', position:'relative', top:'4px'}}
              className={classes.linkStyle}>
              Disconnect
            </Link>
          </Grid>
        </Grid>

        {/* <Grid item md={10}>
          <Divider style={{marginTop:"16px"}}/>
        </Grid> */}

        <Grid item md={10} style={{marginLeft:'32px'}}>
          <Typography style={{margin:'8px 0px 0px 0px', fontSize:"16px", color:'grey', fontWeight:500, textDecoration:'underline'}}>
            Entities
          </Typography>
          {account.entities && account.entities.length > 0 &&
            account.entities.map((entity) => {
              return <Grid item container alignItems="center" justifyContent="center" key={entity?.id}
                      style={{marginTop:'8px'}}>
                        <Grid item sm={10}>
                          <Typography style={{fontSize:'16px', fontWeight:'600'}}>
                              Name : {entity.name} 
                          </Typography>
                          <Typography variant="subtitle1" style={{color:'#555555'}}>
                              Address : {parseString(entity?.address !== null ? entity.address : "")}
                          </Typography>
                          {entity.tax_id &&
                          <Typography variant="subtitle1" style={{color:'#555555'}}>
                              Tax Id : {entity.tax_id}
                          </Typography>}
                        </Grid>

                        <Grid item sm={2}>
                          {
                            entity.default ?
                            <Typography variant='subtitle1' style={{textAlign:'center', marginLeft:'-16px'}}>
                              Default
                            </Typography>
                            :
                            <Link onClick={() => {
                                onMarkasDefaultClick(account_id, entity?.id, setAccountId);
                                setAccountId(entity?.id);
                              }}
                              className={classes.linkStyle}>
                              Mark as default  {loading && entity.id === accountId && <CircularProgress size={18} style={{marginLeft:'8px'}}/>}
                            </Link>
                          }
                        </Grid>

                      </Grid>
            })
          }
        </Grid>
      </Grid>
    </Grid>
  );
};

const InvoiceSettings = (props) => {
  const classes = useStyles();
  const isMount = useIsMount();
  const { enqueueSnackbar } = useSnackbar();
  
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [connectedAccountList, setConnectedAccountList] = useState([]);

  const [openDialog, setOpenDialog] = useState(false);

  const { access_level } = props.selectedOrganization || {};
  const accessLeveOwner = shouldDisable(AccessLevel.getUserAccessLevelValue(access_level), AccessLevel.OWNER);
  
  useEffect(() => {
    if(!isMount) {
      if(props.openDialog) {
        handleClickDialogOpen()
      }
    }
  }, [props.openDialog])

  const handleClickDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleClickDialogClose = () => {
      setOpenDialog(false);
      props.setOpenDialog(false);
  };

  const onMarkasDefaultClick = (invoice_account_id, entity_id, setAccountId) => {

      setLoading(true);
      markAsDefaultApi(invoice_account_id, entity_id)
        .then((response) => {
          const res = response.data;
          consoleToLog('Response markAsDefaultApi: ', res);
          enqueueSnackbar('Account successfully marked as default', {variant:'success'});
          setLoading(false);
          setAccountId('');
          
          const entityList = props.accountList.map((account) => account.entities).flat();

          const updatedInvoiceAccountList = props.accountList.map((account) => {
              if(account.id === invoice_account_id) {
                account.entities = res;
              }
              return account;
          })

          props.setOrgInvoiceAccounts(updatedInvoiceAccountList);
        })
        .catch((e) => {
          consoleToLog('Error markAsDefaultApi: ', e);
          setLoading(false);
          if(e.response.data && e.response.data.message) {
            enqueueSnackbar(e.response.data.message ,{
              vairant:'error'
            });
            return;
          }
        })
  }

  return (
    <Grid item sm={12} 
      style={{margin:"1rem", height: props.accountList && props.accountList.length > 0 ? '100%' : '40vh'}}>
      
      {props.accountList && props.accountList.length > 0  ?
        <Grid container direction='column'>
        <Grid item>
          <Typography className={classes.heading} color="primary">
            Accounts
          </Typography>
        </Grid>
      
        <Grid item xs={12}>
          {props.loading ? (
            <CircularProgress size={35} style={{position:'relative', left:'50%', right:'50%', margin:'16px 0px'}}/>
          ) :
          props.accountList.map((acc)=>{
              return  <InvoiceItem account={acc} 
                          key={acc.id}
                          onMarkasDefaultClick={onMarkasDefaultClick}
                          loading={loading}
                          setOrgInvoiceAccount={props.setOrgInvoiceAccounts}
                          organizationId={props.selectedOrganization.organization.id}
                          enqueueSnackbar={enqueueSnackbar}
                          accountList={props.accountList}
                        />
            })
          }
        </Grid> 
      </Grid>
        :
      <Grid item container justifyContent="center" style={{marginTop:'200px'}}>
          <Button variant="contained" color="primary" 
            onClick={handleClickDialogOpen}>
              Connect
          </Button>  
      </Grid>}
      
      {openDialog && 
        <InvoiceListModal openDialog={openDialog}
            handleClickDialogClose={handleClickDialogClose}
            //
          />}
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  selectedOrganization: state.organizationInfo.selectedOrganization,
  accountList: state.organizationInfo.accountList
});

const mapDispatchToProps = (dispatch) => ({
  setOrgInvoiceAccounts: (accountList) => dispatch(setOrgInvoiceAccounts(accountList))
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceSettings);