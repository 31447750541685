import React, {useState, useEffect, createRef} from 'react';
import {TextField, Grid, Button, Typography, CircularProgress, Tooltip, Popover, MenuItem, IconButton, Select, Paper, Link} from '@material-ui/core'; 
import {makeStyles} from '@material-ui/core/styles';
import RightToLeftSideDrawer from '../RightToLeftSideDrawer';
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import 'react-multi-email/style.css';
import {defaultUserDefinedNames, isPredefinedName, udfTypes, isValidUserDefinedFields} from '../../util/userDefinedFieldsUtil';
import { useSnackbar } from 'notistack';
import { useIsMount } from '../useIsMount';
import ClearIcon from '@material-ui/icons/Clear';
import {connect} from 'react-redux';
import {companyNameValidator, mobileNoValidator} from '../../util/validator';
import { removeSpace, consoleToLog, capitalizeFirstLetterOfEachWord, trackGAEvent, phoneNumberValidate } from '../../util/AppUtil';
import {addCompanyToOrgApi} from '../../services/authService';
import S3FileUpload from "react-s3";
import { showAllCountries, showAllStates, showAllUsers } from '../../util/AppUtil';
import {Autocomplete} from '@material-ui/lab';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CloseIcon from '@material-ui/icons/Close';
import CustomFieldDrawer from "../CustomFieldDrawer";
import {addClientApi} from "../../services/clientService";
import {getUploadURLApi, uploadCustomFieldSuccessApi} from "../../services/authService";
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
    MuiFormControlroot: {
        '& .MuiOutlinedInput-input' :{
            paddingLeft: '16px',
        },   
    },
    listContainer : {
        '&:last-child .dropdownDivider' : {
            display: 'none'
        }
    },
    dividerStyle : {
        background:'#000', 
        opacity:'0.1'
    },
    customMarginTop : {
        margin:'16px 0px 0px 0px'
    },
    customMarginBottom : {
        marginBottom:'-8px'
    },
    udfButton : {
        position: "relative",
        width: "27px",
        height: "27px",
        minWidth: "0",
        margin: "-5px 0px 0px 10px",
        fontSize: "14px",
        color: "#555555",
        borderRadius: "50%",
        border: "1px solid #555555",
        paddingTop: "5px",
        //display: props.isFromWaitingForMyApproval ? "none" : "flex",
    },
    customColumn: {
        maxWidth: '31.5%',
        flexBasis: '31.5%',
    },
    customColumn2: {
        maxWidth: '5.5%',
        flexBasis: '5.5%',
    },
    circularProgress: {
        marginLeft: 0,
        marginRight: theme.spacing(2),
        color: 'white'
      },
      enableStyle:{
        width:"100%",
        height:"2.5rem",
        padding:"2px",
    },
      disableStyle:{
        width:"100%",
        height:"2.5rem",
        padding:"2px",
        color:"#bababa"
    },
    MuiAutocompleteinputRoot : {
        '& .MuiOutlinedInput-root' : {
        padding:'0.5px 1px 0.5px 1px',
        borderRadius: '4px',
        background:'#fff',
        marginTop:'4px'
        }
    },
    customTextField: {
        "& input::placeholder": {
            paddingLeft: "8px",
        }
    },  
    customColumn: {
        maxWidth: '31.5%',
        flexBasis: '31.5%',
    },
    customColumn2: {
        maxWidth: '5.5%',
        flexBasis: '5.5%',
    },
    addCustomFieldStyle: {
        margin:'8px auto 4px 4px', 
        width:'95%', 
        color:'#2196f3', 
        borderColor:'#d4d8de'
    },
    addItemIcon: {
        margin:'0px 6px 0px 0px', 
        fontSize:'16px'
    },
})); 


const CreateNewCompanyModal = (props) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    const isMount = useIsMount();
    const focusInputRef = createRef();

    const [anchorEl, setAnchorEl] = useState(null);

    const [companyName, setCompanyName] = useState('');
    const [emails, setEmails] = useState([]);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [compAddress, setCompAddress] = useState('');
    const [selectedCountry, setSelectedCountry] = useState('IN');
    const [selectedState, setSelectedState] = useState('none');
    const [taxId, setTaxId] = useState('');
    const [openDrawer, setOpenDrawer] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(undefined);
    const [selectedManager, setSelectedManager] = useState('none')

    const [userDefinedFields, setUserDefinedFields] = useState([]);
    const [loading, setLoading] = useState(false);
    const customFieldList = props.customFieldList;
    const [switchFocus, setSwitchFocus] = useState(false);

    const comp_name = props.companyName ? props.companyName : undefined
    useEffect(() => {
        setCompanyName(comp_name);
    }, [comp_name]);

    const handleOpenUDFPopOver = (event) => {
        setAnchorEl(event.currentTarget);
    }
    
    const handleCloseUDFPopOver = () => {
        setAnchorEl(null);
    }

    const onUserDefinedFieldAdded = (udf) => {
        //allow repeatation give warning about error
        // if (userDefinedFields && userDefinedFields.length > 0) {
        //     const names = userDefinedFields.map((udf) => udf.name).join();
        //     if (names.includes(udf) && udf !== defaultUserDefinedNames.Custom) {
        //         enqueueSnackbar('Name Already used! Try Custom to add more.', {
        //             variant:'error'
        //         });
        //         return;
        //     }
        // }

        // const typeValue = (udf === defaultUserDefinedNames.GSTPassword || udf === defaultUserDefinedNames.ITPassword || udf === defaultUserDefinedNames.RERAPassword) ?
        //     udfTypes.Password.value : udfTypes.Text.value;

        // var newUDFObj = {
        //     name: (udf !== defaultUserDefinedNames.Custom) ? udf : '',
        //     type: typeValue,
        //     value: undefined,
        //     isPredefinedName: isPredefinedName(udf),
        //     shouldEncrypt: true //for api
        // };
        // setUserDefinedFields([...userDefinedFields, newUDFObj]);
        // setAnchorEl(null);

        var newUDFObj = {
            name: undefined,
            type: undefined,
            value: undefined,
            show_invoice: false,
            shouldEncrypt: true
          };
          trackGAEvent(props.selectedOrganization.organization.name, 'Add User Defined Field', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
        setUserDefinedFields([...userDefinedFields, newUDFObj]);
    }

    useEffect(() => {
        if(props.clearCompanyDrawer) {
            setCompanyName('');
            setEmails([]);
            setPhoneNumber('');
            setCompAddress('');
            setUserDefinedFields([]);
            setSelectedState('none');
            props.setClearCompanyDrawer(true);
        }
    }, [props.clearCompanyDrawer]);

    useEffect(() => {
        if(!isMount) {
            if(switchFocus) {
              focusInputRef.current?.focus();
            }
        }
    
      }, [focusInputRef]);

    const onUpdateUDFName = (e, index) => {
        const newNameValue = e.target.value;
        const allObjs = userDefinedFields;
        allObjs[index].name = newNameValue;
        setUserDefinedFields([...allObjs]);
    }

    const onUpdateUDFType = (e, index) => {
        const allObjs = userDefinedFields;
        allObjs[index].type = e.target.value;
        allObjs[index].value = undefined;
        setUserDefinedFields([...allObjs]);
    }

    const onUpdateUDFValue = (e, index, type) => {
        var newValue = undefined;
        if (type === udfTypes.ImageOrDocument.value) {//file
            newValue = e.target.files[0];

        } else {//text password
            newValue = e.target.value;
        }
        const allObjs = userDefinedFields;
        allObjs[index].value = newValue;
        setUserDefinedFields([...allObjs]);
    }

    const onRemoveUDFObj = (name) => {
        trackGAEvent(props.selectedOrganization.organization.name, 'Delete User Defined Field', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
        const newArr = userDefinedFields.filter((udf) => udf.name !== name);
        setUserDefinedFields(newArr);
    }

    const filterCustomFeilds = () => {
        const customFieldArr = customFieldList.filter((cf) => userDefinedFields.find(filter => filter.name === cf.name) === undefined).filter((option) => option.type !== udfTypes.ImageOrDocument.value);
        return customFieldArr;
    }

    const customNameField = (udf) => {
        const udfName = userDefinedFields.find((udfField) => udfField.name === udf.name);
        //console.log('udf name*****************************', udfName);
        return udfName?.name ? true : false;
    }

    const handleOpenCustomFieldDrawer = () => {
        setOpenDrawer(true);
    }

    const handleCloseCustomFieldDrawer = () => {
        setOpenDrawer(false);
    }

    const onItemClearClick = (index) => {
        const updatedArray = userDefinedFields;
        
        updatedArray[index].name = undefined;
        updatedArray[index].type = undefined;
        updatedArray[index].value = undefined;
    
        setUserDefinedFields([...updatedArray]);
    }

    const showDefaultClientValues = (option) => {
        const itemObj = option.default_value;
        //console.log('*******************', itemObj)
        switch (itemObj) {
            case '{{client.name}}':
                return companyName;

    
            case '{{client.address}}':
                return compAddress;
    
            case '{{client.country}}':
                return selectedCountry;
            
            case '{{client.state}}':
                return selectedState;
                
            case '{{client.tax_id}}':
                return taxId
        
            default:
                break;
        }
    }

    const onItemClick = (option, index) => {
        const updatedArray = userDefinedFields;

        updatedArray[index].name = option.name;
        updatedArray[index].type = option.type;
        updatedArray[index].value = showDefaultClientValues(option);
        updatedArray[index].show_invoice = option.show_invoice;
        if(option.type === udfTypes.Password.value) {
            updatedArray[index].shouldEncrypt = true;
        } else {
            updatedArray[index].shouldEncrypt = false;
        }
        focusInputRef.current?.focus();
        
        setUserDefinedFields([...updatedArray]);
        if(switchFocus) setSwitchFocus(false);
    }

    const AddCustomFieldButton = ({ children, ...other }) => (
        <Paper {...other} 
            style={{marginTop:'6px',
                    boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px' 
                    }}>
            <Button fullWidth
                className={classes.addCustomFieldStyle}
                variant="outlined"
                color="primary"
                style={{padding:'6px 2px'}}
                onMouseDown={handleOpenCustomFieldDrawer}
                classes={{
                    label: classes.MuiButtonlabel
                }}
            >
            <AddCircleOutlineIcon fontSize='small' className={classes.addItemIcon}/>  
                Add Custom Field
            </Button>
            {children}
        </Paper>
        );
    
    const  getSelectedItem = (index) => {
        const itemObj = customFieldList.find((cf) => {
            if(cf.name === userDefinedFields[index].name) {
                return cf
            } 
        });
        //console.log('**********item', itemObj);

        return itemObj || {};
    }


    const renderOptions = (option, index) => {
        return (
            <div className={classes.itemOptionStyle}>
                <Grid item container 
                    onClick={() => onItemClick(option, index)}
                    justifyContent="space-between">
                    <Typography variant='body2'>
                        {option.name}
                    </Typography> 
                </Grid>    

            </div>
            );
    }

    const showCustomField = () => {
        return (
            userDefinedFields && userDefinedFields.map((udf, index) => {
            const typeOfValueInputField =
            udf.type === udfTypes.Text.value
                ? "text"
                : udf.type === udfTypes.Password.value
                ? "password"
                : "";
            return (
            <Grid item container spacing={1}
                key={index}
                onClick={() => setCurrentIndex(index)}
                alignItems="center">

                <Grid item sm={3} className={classes.customColumn}>
                    <Autocomplete
                        id="combo-box-demo"
                        options={filterCustomFeilds()}
                        classes={{
                            root: classes.MuiAutocompleteinputRoot
                        }}
                        value={getSelectedItem(index)}
                        //value={udf}
                        disabled={customNameField(udf)}
                        getOptionLabel={(option) => option.name}
                        //inputValue={invoiceItem.item_name}
                        //onInputChange={(e, newInputvalue) => onInvoiceItemNameChanged(e, newInputvalue, index)}
                        renderOption={(option) => renderOptions(option, index)}
                        onChange={(e, newValue) => {
                            //onUDFNameChanged(e, newValue, index);
                            onItemClick(newValue, index)
                        }}
                        renderInput={params => (
                            <TextField {...params} variant="outlined" 
                            placeholder='Search Custom Field' 
                            fullWidth
                            classes={{
                                root: classes.customTextField
                            }}
                                />
                        )}
                        closeIcon={<CloseIcon fontSize='small' onClick={() => onItemClearClick(index)}/>}
                        PaperComponent={AddCustomFieldButton}
                    />
                </Grid>
    
                <Grid item sm={3} className={classes.customColumn}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        disabled
                        value={udf.type ? udf.type : -1}
                        select
                        classes={{root: classes.MuiFormControlroot,}}>
                            {Object.values(udfTypes).map((typeObj) => {
                            return (
                                <MenuItem key={typeObj.value} value={typeObj.value}>
                                {typeObj.displayName}
                                </MenuItem>
                            );
                            })}
                    </TextField>
                </Grid>

                <Grid item sm={3} className={classes.customColumn}>
                    {udf.type === udfTypes.ImageOrDocument.value ? (
                    <Grid item container sm={12}>
                        {!udf.value  && (
                        <label>
                            <input
                                style={{ display: "none" }}
                                type="file"
                                onChange={(e) => onUpdateUDFValue(e, index, udf.type)}
                            />
    
                            <Typography variant="body2"
                                style={{
                                    marginTop: "2px",
                                    marginBottom: "none",
                                    padding: "8px 38px 8px 32px",
                                    border: "1px dashed #555",
                                    cursor: "pointer",
                                    color: "#555",
                                    fontSize:'13px'
                            }}>
                            <i className="flaticon-attachment"  style={{ marginRight: "6px" }}></i>
                                Attach File
                            </Typography>
                        </label>
                        )}
                        {(udf.value && (udf.value.name && udf.value.name.indexOf("/") === -1)) ? (
                        <Tooltip
                            title={udf.value.name
                                ? udf.value.name
                                : udf.value ? udf.value.replace(/^.*[\\\/]/, "") : ""}
                            arrow
                        >
                            <Typography
                            style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                cursor: "pointer",
                            }}
                            >
                            {udf.value.name
                            ? udf.value.name
                            :udf.value ? udf.value.replace(/^.*[\\\/]/, "") : ""}
                            </Typography>
                        </Tooltip>
                        )
                                :
                        (udf.value && (udf.value !== '' && udf.value?.indexOf("/") > -1)) && (
                        <Tooltip title={udf.value?.replace(/^.*[\\\/]/, "")} arrow>
                        <div  >
                            <Typography className={classes.downloadLink}>
                                {udf.value?.replace(/^.*[\\\/]/, "")}
                            </Typography>
                            <Link
                                style={{ display: "none" }}
                                id="udfFileDownloader"
                                target="_blank"
                                onClick={(e) => e.stopPropagation()}
                                download
                            ></Link>
                        </div>
                        </Tooltip>
                        )}  
                    </Grid>
                ) : (
                    <TextField
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        type={typeOfValueInputField}
                        value={udf.value ? udf.value : ""}
                        // disabled={showCompanyDetails}
                        placeholder="Value"
                        classes={{
                            root: classes.MuiFormControlroot,
                        
                        }}
                        inputRef={focusInputRef}
                        onChange={(e) => onUpdateUDFValue(e, index, udf.type)}
                    />
                )}
                </Grid>
    
                <Grid item md={1} className={classes.customColumn2}>
                    <IconButton
                        onClick={() => onRemoveUDFObj(udf.name, index)}
                        style={{
                        color: "#555",
                        padding: "0px",
                        // marginRight: "-16px",
                        marginTop: "3px",
                        }}
                    >
                        <ClearIcon />
                    </IconButton>
                </Grid>
            </Grid>
            );
        })
        );
    };

    const onCreateCompanyModal = () => {
        const company_name = companyName;
        
        //const companyEmail = emails;
        
        let mobile = phoneNumber;
        
        //var pan_no = document.getElementById('pan_no').value;
        //var GSTIN = document.getElementById('GSTIN').value;
        
        const address = compAddress;

        //company name validation
        if (!company_name) {
            enqueueSnackbar('Client name can not be empty!', {
                variant: 'error'
            });
            return;
        }


        const isCompanyNameValid = companyNameValidator(company_name);
        if (!isCompanyNameValid) {
            enqueueSnackbar('Enter valid client name', {
                variant: 'error'
            });
            return;
        }

        mobile = removeSpace(mobile);
        //user defined fields validations

        if(selectedCountry === 'none' || selectedCountry === '' || selectedCountry === undefined) {
            enqueueSnackbar('Please Select the Country', {
                variant: 'error'
            });
            return;
        } 
  
        // if(selectedCountry !== 'IN') {
        //     enqueueSnackbar('Please Select India as Country', {
        //         variant: 'error'
        //     });
        //     return;
        // }
    
        if(selectedCountry === 'IN' && selectedState === 'none') {
            enqueueSnackbar('Please Select the State', {
                variant: 'error'
            });
            return;
        }

        const state = selectedCountry === 'IN' ? selectedState : undefined;  

        const tax_id = taxId;
        
        if (userDefinedFields.length > 0) {
            const errors = isValidUserDefinedFields(userDefinedFields, props.orgEmailList);
            if (errors) {
                enqueueSnackbar(errors, {
                    variant:'error'
                });
                return;
            }
        } 

        const relationship_manager_id = selectedManager !== 'none' ? [selectedManager] : [];

        setLoading(true);
        const companyObj = {company_name, mobile, address, selectedCountry, state, tax_id, relationship_manager_id }
        
        callNextFileUploadOrCompanyApi(0, companyObj);
    }

    const callNextFileUploadOrCompanyApi = (index, companyObj) => {
        if (userDefinedFields && userDefinedFields.length > 0) {
            for (var i = index; i < userDefinedFields.length; i++) {
                if (userDefinedFields[i].type === udfTypes.ImageOrDocument.value) {
                    uploadFileIfExists(i, companyObj);
                    break;
                } else if (i === userDefinedFields.length - 1) {//call company api directly as this is last iteration
                    callCompanyApi(companyObj);
                }
            }
        } else {
            callCompanyApi(companyObj);
        }
    }

    const uploadFileIfExists = (i, companyObj) => {
        const udfObj = userDefinedFields[i];

        //upload file logic
        const auto_generated_id = companyObj.auto_generated_id;
        const file_name = udfObj.value?.name;
        const file_type = udfObj.value?.type; 
        const type = 'custom'

    getUploadURLApi(undefined, file_name, type)
        .then((response) => {
            const res = response.data;
            consoleToLog('Response getUploadURLApi', res);
            
            var signedRequest = res.upload_signed_url;
            var url = res.url;

        // Put the fileType in the headers for the upload
            const options = {
            headers: {
                "Content-Type": file_type,
            }
            };
                axios.defaults.headers.common = {};
                axios.put(signedRequest, udfObj.value, options)
                    .then(result => {
                        consoleToLog('signed Request s3', result);

                        callUploadSuccessApi(url, udfObj, i);
                    })
                    .catch(error => {
                        consoleToLog('error: ', error.response);
                    })
        })
        .catch((e) => {
            consoleToLog('Error getUploadURLApi', e);

        })

    }

    const callUploadSuccessApi = (path, udfObj, i) => {
        const client_id = props.tempCompanyData?.id;

        uploadCustomFieldSuccessApi(client_id, path)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response uploadCustomFieldSuccessApi: ', res);

                //update state obj with new path
                const newUDFObj = {
                    ...udfObj,
                    value: path
                }

                let tempArr = userDefinedFields.filter((obj, index) => index !== i);
                tempArr.splice(i,0, newUDFObj)
                setUserDefinedFields(tempArr);
                callapi (i, props.tempCompanyData, tempArr);
                consoleToLog('123456 arr', userDefinedFields, tempArr);
            })
            .catch((e) => {
                consoleToLog("Error uploadCustomFieldSuccessApi: ", e);
            })
    }

    const callapi = (i, companyObj, tempArr) => {
        let userDefinedArr = userDefinedFields.map(a => {
            const exists = tempArr.find(b => a.name == b.name);
            return exists ? (a.value = exists.value, a) : a;
        });
        setUserDefinedFields(userDefinedArr); 

        if (i === userDefinedFields.length - 1) {//call company api
            callCompanyApi(companyObj);
        } else {//recursive call if file exists
            callNextFileUploadOrCompanyApi(i + 1, companyObj);
        }
    }

    const callCompanyApi = ({company_name, mobile, address, selectedCountry, state, tax_id, relationship_manager_id }) => {
        
            const organization_id = props.selectedOrganization.organization.id;
            const user_defined_field = userDefinedFields;
            //const client_email = companyEmail && companyEmail.length > 0 ? companyEmail.join() : '';
            //consoleToLog('company1234567', companyEmail)
            //create company api call
            addClientApi(organization_id, capitalizeFirstLetterOfEachWord(company_name),mobile, address, state,
                selectedCountry, tax_id, user_defined_field, relationship_manager_id)
                .then((response) => {
                    const res = response.data;
                    consoleToLog("Response addClientApi: ", res);
                    setLoading(false);
                    enqueueSnackbar('Client created!', {
                        variant:'success'
                    });

                    //update local state companies
                    props.addCompany(res);
                    props.storeTempCompanyInfo(res);
                    setLoading(false);
                    trackGAEvent(props.selectedOrganization.organization.name, 'Add Company', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
                })
                .catch((e) => {
                    consoleToLog("addClientApi error", e);
                    setLoading(false);
                    if (e.response.data && e.response.data.message) {
                        enqueueSnackbar(e.response.data.message, {
                            variant : 'error'
                        });
                        return false;
                    }
                });
    }

    const validateEmail = (email) => {
        if(!isEmail(email)) {
            enqueueSnackbar('Please enter a valid email.', {variant:'error'});
            return false;
        } else {
            return true;
        }
    }

    const onYesButtonClick = () => {
        // const validCompanyDetails = {
        //     companyInfo: { company_name, address, companyEmail, mobile, selectedCountry, selectedState, tax_id},
        //     user_defined_field: userDefinedFields
        // }
        // consoleToLog('udf_data******', validCompanyDetails.user_defined_field);
        props.handleOpenCreateChecklistDrawer();
    }

    const onNoButtonClick = () => {
        createCompanyWithoutChecklist();
    }

    const createCompanyWithoutChecklist = () => {
        const organization_id = props.selectedOrganization.organization.id;


        const user_defined_field = props.tempCompanyData?.user_defined_field;
        const { name, phone_number, address, country, state, tax_id, id } = props.tempCompanyData;
        //const emailArr = email && email !== '' ? email.split(',') : [];
        addCompanyToOrgApi(capitalizeFirstLetterOfEachWord(name),phone_number, address, 
        organization_id, user_defined_field, [], country, state, tax_id, id)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response addCompanyToOrgApi: ", res);

                props.setClearCompanyDrawer(true);
                props.handleCloseCreateCompanyDrawer();
                props.storeTempCompanyInfo(undefined);
            })
            .catch((e) => {
                consoleToLog("addCompanyToOrgApi error", e.response);
                setLoading(false);
                if (e.response.data && e.response.data.message) {
                    // enqueueSnackbar(e.response.data.message, {
                    //     variant:'error'
                    // });
                    return;
                }
            });
    } 

    return (
        <RightToLeftSideDrawer title="Add Client"
            openDrawer={props.openCreateCompanyDrawer}
            closeDrawer={props.handleCloseCreateCompanyDrawer}
            createCompany={props.openCreateCompanyDrawer}
        >
            <div className={"modal__main"} style={{overflow:'auto'}}>
                <Grid item container>
                    <Grid item md={12} lg={12}>
                        <Typography variant="subtitle1" 
                            className={classes.customMarginBottom}>
                            Client Name
                        </Typography>

                        <TextField fullWidth 
                            id="company_name" 
                            variant="outlined"
                            type="text"
                            margin="dense"
                            value={companyName}
                            autoComplete="off"
                            placeholder="Enter Client Name"
                            InputLabelProps={{style: {fontSize: 13, color: "#ccc"}}}
                            onChange={(e) => setCompanyName(e.target.value)}
                        />
                    </Grid>    


                    {/* <Grid item md={12} lg={12}
                        className={classes.customMarginTop}>
                        <Typography variant="subtitle1" >
                            Email Addresses (Optional)
                        </Typography>

                        <ReactMultiEmail
                            className={emails && emails.length > 0 ? "org__email" : ""}
                            style={{
                                borderRadius: "5px",
                                borderStyle: "solid",
                                borderWidth: "1px",
                                borderColor: "#DBDBDB",
                                fontSize:"14px"
                            }}
                            placeholder="Type Email Address And Press Enter"
                            emails={emails}
                            onChange={(emails) => setEmails(emails)}
                            validateEmail={email => validateEmail(email)}
                            getLabel={(
                                email,
                                index,
                                removeEmail = (index) => {
                                    const arr = emails.splice(index, 1);
                                    setEmails(arr);
                                }
                            ) => {
                                return (
                                    <div data-tag key={index} className="return__email">
                                        {email}
                                        {<span data-tag-handle onClick={() => removeEmail(index)}>
                                            x
                                        </span>}
                                    </div>
                                );
                            }}
                        />
                        </Grid>  */}

                    <Grid item md={12} lg={12} 
                        className={classes.customMarginTop}>
                        <Typography variant="subtitle1" 
                            className={classes.customMarginBottom}>
                            Mobile Number (Optional)
                        </Typography>   

                        <TextField fullWidth 
                            id="mobile_number" 
                            variant="outlined"
                            type="tel"
                            margin="dense"
                            autoComplete="off"
                            value={phoneNumber}
                            placeholder="Mobile Number"
                            InputLabelProps={{style: {fontSize: 13, color: "#ccc"}}}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            onKeyPress={(e) => phoneNumberValidate(e)}
                        />
                    </Grid>  

                    <Grid item md={12} lg={12} 
                        className={classes.customMarginTop}>
                        <Typography variant="subtitle1" 
                            className={classes.customMarginBottom}>
                            Tax Id (Optional)
                        </Typography>   

                        <TextField fullWidth 
                            id="tax_id" 
                            variant="outlined"
                            type="text"
                            margin="dense"
                            autoComplete="off"
                            placeholder="Tax Id"
                            value={taxId}
                            InputLabelProps={{style: {fontSize: 13, color: "#ccc"}}}
                            onChange={(e) => setTaxId(e.target.value)}
                        />
                    </Grid>

                    <Grid item md={12} lg={12} 
                        className={classes.customMarginTop}>
                        <Typography variant="subtitle1" 
                            className={classes.customMarginBottom}>
                            Address (Optional)
                        </Typography>   

                        <TextField fullWidth 
                            id="address" 
                            variant="outlined"
                            type="text"
                            margin="dense"
                            autoComplete="off"
                            placeholder="Address"
                            value={compAddress}
                            InputLabelProps={{style: {fontSize: 13, color: "#ccc"}}}
                            onChange={(e) => setCompAddress(e.target.value)}
                        />
                    </Grid>

                    <Grid item md={12} lg={12} 
                        className={classes.customMarginTop}>
                        <Typography variant="subtitle1">
                            Country
                        </Typography>   

                        <Select 
                            className={selectedCountry === 'none' ? classes.disableStyle : classes.enableStyle}
                            value={selectedCountry}
                            onChange={(e) =>  setSelectedCountry(e.target.value)}
                            variant="outlined"
                            placeholder='Select Country'
                            autoWidth
                            //disabled={disabled}
                            >
                            {
                                selectedCountry === 'none' && <MenuItem value='none'>Select Country</MenuItem>
                            } 
                            {showAllCountries()}
                        </Select>
                    </Grid>

                    {selectedCountry === 'none' || selectedCountry === 'IN' &&
                    <Grid item md={12} lg={12} 
                        className={classes.customMarginTop}>
                        <Typography variant="subtitle1">
                            State
                        </Typography>   

                        <Select 
                            className={selectedState === 'none' ? classes.disableStyle : classes.enableStyle}
                            value={selectedState}
                            onChange={(e) => setSelectedState(e.target.value)}
                            variant="outlined"
                            placeholder='Select State'
                            autoWidth
                            disabled={selectedCountry === 'none' || selectedCountry !== 'IN'}
                        >
                        {
                            selectedState === 'none' && <MenuItem value='none'>Select State</MenuItem>
                        } 
                        {showAllStates()}
                        </Select>
                    </Grid>}

                    <Grid item md={12} lg={12} 
                        className={classes.customMarginTop}>
                        <Typography variant="subtitle1">
                            Relationship Manager (Optional)
                        </Typography>   

                        <Select 
                            className={selectedManager === 'none' ? classes.disableStyle : classes.enableStyle}
                            value={selectedManager}
                            onChange={(e) => setSelectedManager(e.target.value)}
                            variant="outlined"
                            placeholder='Select Relationship Manager'
                            autoWidth
                        >
                        {
                            selectedManager === 'none' && <MenuItem value='none'>Select Relationship Manager</MenuItem>
                        } 
                        {props.members && props.members.length > 0 && showAllUsers(props.members)}
                        </Select>
                    </Grid>

                    <Grid item  md={12} lg={12} 
                        className={classes.customMarginTop}>
                            <Grid item container>
                                <Grid item>
                                    <Typography variant="subtitle1" 
                                        className={classes.customMarginBottom}>
                                        User Defined Fields
                                    </Typography>   
                                </Grid>

                                <Grid item>
                                    <Tooltip title="Add New Entry" arrow>
                                        <Button
                                            className={classes.udfButton}
                                            onClick={(e) => onUserDefinedFieldAdded(e)}
                                        >
                                        <i className="flaticon-add-2" style={{ paddingTop: "6px" }}></i>
                                        </Button>
                                    </Tooltip>
                                </Grid>       
                            </Grid>
                    </Grid> 

                    {showCustomField()}        
                        
                    <div className="modal__footer">
                        <Button variant="contained" color="primary" 
                            onClick={onCreateCompanyModal}
                            className="modal__button">
                            {loading && <CircularProgress size={24} className={classes.circularProgress}/>}
                            Next    
                        </Button>
                        <Button variant="outlined" style={{
                            minWidth: '130px',
                        }} onClick={props.handleCloseCreateCompanyDrawer}>
                            Cancel
                        </Button>
                    </div> 
                </Grid>
            </div>

            <CustomFieldDrawer openCustomFieldDrawer={openDrawer} 
                setOpenCustomFieldDrawer={handleCloseCustomFieldDrawer}
                fromEditCustomFields={true}
                onItemClick={onItemClick}
                currentIndex={currentIndex}
                setSwitchFocus={setSwitchFocus}
                />
        </RightToLeftSideDrawer>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    templates: state.organizationInfo.templates,
    customFieldList: state.organizationInfo.customFieldList,
    members: state.organizationInfo.members,
    orgEmailList: state.organizationInfo.orgEmailList
});

export default connect(mapStateToProps)(CreateNewCompanyModal);
