import React, {useState, useEffect} from 'react';
import {TextField, Grid, Button} from '@material-ui/core'; 
import { connect } from 'react-redux';
import {consoleToLog, trackGAEvent} from '../../util/AppUtil';
import {createFolderApi, editFolderApi} from '../../services/authService';
import {useParams} from 'react-router-dom';
import { useSnackbar } from 'notistack';

const CreateEditFolderModal = (props) => {
    const {slug} = useParams();
    const folder_name = props.editFolder ? props.editFolder.group_name : ''
    const [folderName, setFolderName] = useState('');

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        setFolderName(folder_name);
    }, [folder_name]);

    const onCreateEditFolderClick = (e) => {
        if(!folderName) {
            enqueueSnackbar('Folder Name Cannot be empty', {
                variant:'error'
            });
            return;
        }
    
        const organization_id = props.selectedOrganization.organization.id;
        const template_id = props.selectedTemplate.template_id ? props.selectedTemplate.template_id : null;
        const parent_group_slug_code = slug ? slug : '' ;
        const id = props.editFolder ? props.editFolder.id : undefined;
        
        !props.editFolder &&
        createFolderApi(organization_id, template_id, folderName, parent_group_slug_code)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response createFolderApi ', res);
                props.addFolderToList(res.checklist_grouping);
                trackGAEvent(props.selectedOrganization.organization.name, 'Checklist Create Folder', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
            })
            .catch((e) => {
                consoleToLog('Error createFolderApi ', e);
                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant:'error'
                    });
                    return false;
                }
            });
        
        props.editFolder &&
        editFolderApi(id, folderName, organization_id, template_id)
        .then((response) => {
            const res = response.data;
            consoleToLog('Response editFolderApi', res);
            props.editedFolder(res.checklist_grouping);
            props.setDrawerOpen();
            trackGAEvent(props.selectedOrganization.organization.name, 'Checklist edit Folder', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
        })
        .catch((e) => {
            consoleToLog('Error editFolderApi ', e);
            if (e.response.data && e.response.data.message) {
                enqueueSnackbar(e.response.data.message, {
                    variant:'error'
                });
                return false;
            }
        })    
    } 

    return (
        <div className="modal__main">
            <Grid container md={12} lg={12}>
                <Grid item lg={12}> 
                        <span style={{fontSize:'14px'}}>
                            Folder Name
                        </span>
                        <TextField fullWidth 
                            id="folder_name" 
                            variant="outlined"
                            type="text"
                            margin="dense"
                            value={folderName}
                            placeholder="Enter Folder Name"
                            InputLabelProps={{style: {fontSize: 13, color: "#ccc"}}}
                            onKeyPress={(ev) => {
                                //consoleToLog(`Pressed keyCode ${ev.key}`);
                                if (ev.key === 'Enter') {
                                    onCreateEditFolderClick();
                                    ev.preventDefault();
                                }
                            }}
                            onChange={e => { 
                                setFolderName(e.target.value);
                            }}
                        />
                        <div className="modal__footer">
                            <Button variant="contained" color="primary" 
                                className="modal__button"  
                                onClick={onCreateEditFolderClick}>
                                {props.editFolder ? 'Update' : 'Create'}
                            </Button>
                            <Button variant="outlined" style={{
                                minWidth: '130px',
                            }} onClick={props.setDrawerOpen}>
                                Cancel
                            </Button>
                        </div>
                </Grid>
            </Grid>
            
        </div>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    selectedTemplate: state.organizationInfo.selectedTemplate,
});

export default connect(mapStateToProps)(CreateEditFolderModal);