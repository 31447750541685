import React, {useState, useEffect} from 'react';
import { useIsMount } from "../../../components/useIsMount";
import {getChecklistActivityApi} from "../../../services/ChecklistService";
import { connect } from "react-redux";
import {makeStyles} from '@material-ui/core';
import {Grid, Typography, Button, CircularProgress, Container, Fab, Divider, Link} from '@material-ui/core';
import RefreshIcon from "@material-ui/icons/Refresh";
import { consoleToLog, getReadableActivityInHTML, displayIcons } from '../../../util/AppUtil';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: "8px",
        padding:'0 12px'
    },
    loading: {
        position:'relative',
        left:'50%',
        right:'50%',
        top:'30px'
    },
    activtyIconContainer: {
        backgroundColor: '#3d92ff',
        minHeight: "30px",
        height: "30px",
        width: "30px",
        boxShadow: "none",
    },
    activtycommentIcon: {
        width: "15px",
        color: "white !important",
        marginLeft:'-3px',
        marginTop:'-2px',
        fontSize: "1.1rem",
        lineHeight:'0 !important',
    },
    activtyhorizontalDivider: {
        display:'flex',
        width: "2px",
        //height: "3.8rem !important",
        backgroundColor: "#cacaca",
        marginTop: "0px",
        marginLeft:'15px',
        marginBottom: "0px",
        border:'none',
        '& last-child' : {
            height: "0",
        }
    },
    activityTime : {
        color:'rgba(0,0,0,0.5)', 
        fontSize:'13px',
    },
    bulletIcons: {
        width: '9px',
        height: '9px', 
        color: '#ccc', 
        paddingLeft: '3px', 
        paddingTop: '0', 
        margin: '-3px 8px 0px 6px'
    },
    filterIcon: {
        position:'fixed',
        right:'24px',
        bottom:'16px'
    }

}))

const MobileChecklistActivities = (props) => {
    const classes = useStyles();
    const isMount = useIsMount();

    const [activity, setActivity] = useState();
    const [isAPICallInProgress, setIsAPICallInProgress] = useState(false);

    useEffect(() => {
        getActivity();
    }, []);

    const getActivity = () => {
        const organizationId = props.selectedOrganization.organization.id;
        const checklist_slug = props.checklistSlug;
        const page = 1; 
        setIsAPICallInProgress(true);
    
        getChecklistActivityApi(organizationId, page, checklist_slug)
        .then((response) => {
            consoleToLog("getChecklistActivityApi response", response.data);
            const res = response.data;
            setActivity(res.activity_feed);
            setIsAPICallInProgress(false);
        })
        .catch((e) => {
            consoleToLog("Activity api error(checklist activity)", e);
            setIsAPICallInProgress(false);
        });
    };

    const showActivityList = () => {
        return (
        <Grid item className={classes.mainBg}>
            {
            activity && activity.length > 0 &&
            activity.map((activityObj, index) => {
                const time = moment(activityObj.created_at).fromNow();
                const innerHtmlContent = getReadableActivityInHTML(activityObj.activity_text);
                    return<Grid item key={activityObj.id} xs={12} id={activityObj.id.toString()}>
                        <Link
                            style={{textDecoration: 'none', display:'inline-block', fontSize:'13px', marginBottom:'20px'}}
                            to={(activityObj.activity_type === 'move_checklist' && activityObj.action_type === 'checklist_grouping') 
                                    ? {
                                        pathname:activityObj.group_slug_code ? `/group/${activityObj.group_slug_code}` : `/dashboard`,
                                        activityObj
                                    } 
                                    : {
                                        pathname: `/checklist/${activityObj.checklist.slug_code}`,
                                        activityObj
                                    } 
                                }
                            onClick={(e)=>{props.onChecklistActivityClick(e, activityObj)}}
                            // onClick={(e) => {
                            //         //trackGAEvent(props.selectedOrganization.organization.name, 'Clicked Activity', `${props.auth.user.firstname} ${props.auth.user.lastname} clicked activity feed row`);
                            //         props.setSelectedDrawerState(SelectedDrawerState.NOT_SELECTED);
                            //         props.setSelectedTemplate(templateObj);
                            //         setActivityId(activityObj.id);
                            //     }
                            // }
                        > 
                        <div style={{display:'flex'}}>
                            <Grid item style={{ marginTop: "-3px"}}>
                                <Fab className={classes.activtyIconContainer}>
                                    { <i className={`${displayIcons(activityObj)} ${classes.activtycommentIcon}`}></i> }
                                    {/* <PublishIcon className={classes.activtycommentIcon} />  */}
                                </Fab>
                                <Divider orientation="vertical" className={classes.activtyhorizontalDivider} />
                            </Grid>

                            <Grid item style={{marginLeft:'8px', color:'#212121'}}>
                                <Grid item container direction="column">
                                    <Grid item style={{display:'flex', width:'100%', position:'relative'}}>
                                        <Typography variant='body1'
                                            dangerouslySetInnerHTML={{ __html: innerHtmlContent }}
                                        ></Typography>
                                    </Grid>

                                    <Grid item>
                                        <div className={classes.activityTime}> 
                                            {time} 
                                        </div>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </div>
                        </Link> 
                    </Grid>
            }) 
        }
        </Grid>

        )
    }

    return (
        <div className={classes.container}>
            <Grid item container justifyContent="flex-end" alignItems="center" 
                style={{marginTop:'16px'}}>
                    <Button
                        variant="contained"
                        color="primary"
                        endIcon={<RefreshIcon />}
                        onClick={getActivity}
                    >
                        Refresh
                    </Button>
            </Grid>
            
            {isAPICallInProgress ? 
                    <CircularProgress size={25} className={classes.loading}/> 
                        :
                    <Grid item xs={12} style={{marginTop:'24px'}}>
                        {showActivityList()}
                    </Grid>
            }

        </div>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
  });

export default connect(mapStateToProps)(MobileChecklistActivities);