import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Grid, Typography,  TextField } from '@material-ui/core';
import { useState } from 'react';
import { passwordValidator } from '../util/validator';
import { consoleToLog } from '../util/AppUtil';
import {updatePasswordApi} from '../services/authService';
import { getQueryParams } from '../util/urlUtil';
import { useSnackbar } from 'notistack';
import {connect} from 'react-redux';
import {history} from '../routers/AppRouter';

const useStyles = makeStyles((theme) => ({
    innerbg: {
        border: "1px solid #dbdbdb",
        borderRadius: "10px",
        padding: "45px 100px",
        background: "#ffffff",
        [theme.breakpoints.down("md")]: {
            padding: "45px 20px",
          }
    },
    mainbg: {
        background: theme.palette.primary.light
    },
    logo: {
        maxWidth: "200px",
        marginBottom: "10px",
        [theme.breakpoints.down("md")]: {
            maxWidth: "180px",
          }
    },
    title: {
        marginTop: "20px",
        fontSize: "1.1rem"
    }
  }));

const UpdatePasswordPage = (props) => {
    //consoleToLog("Theme vikas: ", props);
      const classes = useStyles(props);
      const { enqueueSnackbar } = useSnackbar();

      const [password, setPassword] = useState('');
      const [cpassword, setCPassword] = useState('');
      const [passwordInValid, setPasswordInValid] = useState(false);
      const [cpasswordInValid, setCPasswordInValid] = useState(false);
      
      const [passwordError, setPasswordError] = useState("");

      const onSubmitClick = () => {
        if (!password) {
            setPasswordInValid(true);
            setPasswordError('* Password Required');
            return;
        }

        const isPasswordValid = passwordValidator(password);
        if (!isPasswordValid) {
            setPasswordInValid(true);
            setPasswordError('* Password length should be 5 or more!');
            return;
        }

        if (password !== cpassword) {
            setCPasswordInValid(true);
            return;
        }

        //get url parameter
        let queryParams = getQueryParams(document.location.search);
        //consoleToLog('Query Params: ', queryParams.jwt);

        updatePasswordApi(password, queryParams.jwt)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response updatePasswordApi: ", res);
                enqueueSnackbar(res.message, {
                    variant: 'success'
                });

                if(props.auth && props.auth.access_token)  {
                    history.push('/dashboard');
                } else {
                    history.push('/login');
                }
            })
            .catch((e) => {
                consoleToLog("updatePasswordApi error", e);
                /* if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant: 'error'
                    });
                    return false;
                } */
            });
      }

    return (
            <div className={classes.mainbg}>
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    minHeight="100vh"
                    >
                    <Grid container direction="column" xs={10} md={6} lg={5} 
                        className={classes.innerbg}
                    >
                        <img src="/images/eprocessify-logo-svg.svg" 
                            alt="logo" 
                            className={classes.logo} />
                        <Typography variant="body1"
                            className={classes.title}>Update Password</Typography>
                        <Typography variant="subtitle1">Enter new password to access your account</Typography>

                        <Grid container item xs={12} style={{marginTop: "15px"}}>
                            <TextField
                                fullWidth
                                id="password"
                                label="Password"
                                type="password"
                                variant="outlined"
                                margin="dense"
                                error={passwordInValid}
                                helperText={passwordInValid ? passwordError : ''}
                                onChange={e => { 
                                    setPassword(e.target.value);
                                    setPasswordInValid(false);
                                }}
                                InputLabelProps={{style: {fontSize: 14}}}
                            />
                        </Grid>
                        <Grid container item xs={12} style={{marginTop: "15px"}}>
                            <TextField
                                fullWidth
                                id="cpassword"
                                label="Confirm Password"
                                type="password"
                                margin="dense"
                                variant="outlined"
                                onChange={e => { 
                                    setCPassword(e.target.value);
                                    setCPasswordInValid(false);
                                }}
                                error={cpasswordInValid}
                                helperText={cpasswordInValid ? "* Passwords are not matching!"  : ''}
                                InputLabelProps={{style: {fontSize: 14}}}
                                onKeyPress={(ev) => {
                                    consoleToLog(`Pressed keyCode ${ev.key}`);
                                    if (ev.key === 'Enter') {
                                        onSubmitClick();
                                      ev.preventDefault();
                                    }
                                  }}
                            />
                        </Grid>
                        <Button variant="contained" 
                            color="primary" 
                            style={{marginTop: "25px"}}
                            onClick={()=>onSubmitClick()}>
                            Update Password
                        </Button>
                    </Grid>
                </Box>
            </div>
    )
}

const mapStateToProps = (state) => ({
    auth: state.auth
});

export default connect(mapStateToProps)(UpdatePasswordPage);