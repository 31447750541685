import React, {useState, useEffect } from 'react';
import {Grid, Typography, Button, CircularProgress} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {getClientChecklistApi} from '../../../services/authService';
import { consoleToLog, AccessLevel, shouldDisable } from '../../../util/AppUtil';
import { useIsMount } from '../../../components/useIsMount';
import MobileClientChecklistItem from './MobileClientChecklistItem';
import {connect} from 'react-redux';
import MobileChecklistDetailsModal from '../../pages/modal/MobileChecklistDetailsModal';
import { history } from '../../routers/MobileAppRouter';

const useStyles = makeStyles((theme) => ({
    
}))

const MobileClientChecklist = (props) => {
    const classes = useStyles();
    const isMount = useIsMount();

    const [checklists, setChecklists] = useState([]);
    const [checklistLoading, setChecklistLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [nextPage, setNextPage] = useState(false);
    const [isMoreLoading, setIsMoreLoading] = useState(false);

    //checklist details
    const [openDialog, setOpenDialog] = useState(false);
    const [currentChecklistItem, setCurrentChecklistItem] = useState(undefined);
    const [tabValue, setTabValue] = useState(0); 

    var urlPath = window.location.pathname.split("/");
    var curr_slug = urlPath[urlPath.length-1];

    /**Access Levels */
    const { access_level } = props.selectedOrganization || {};
    const accessLevelManager = shouldDisable(AccessLevel.getUserAccessLevelValue(access_level), AccessLevel.MANAGER);


    useEffect(() => {
        getClientChecklistApiCall();
    }, []);

    useEffect(() => {
        if(!isMount) {
            if(curr_slug) {
                modalStatus();
            }
        }

    }, [curr_slug]);

    const modalStatus = ()=> {
        if(window.location.toString().includes("checklist")){
            setOpenDialog(true)
        }
        else{
            setOpenDialog(false)
        }
    }

    const getClientChecklistApiCall = () => {
        const company_id = props.clientObj?.id;
        const organization_id = props.selectedOrganization.organization.id;
        
        if(!isMoreLoading) setChecklistLoading(true);
            getClientChecklistApi(company_id, organization_id,page)
            .then((response) => {
            const res = response.data;
            consoleToLog("Response getClientChecklistApi: ", res);
            setChecklistLoading(false);
            if(isMoreLoading) {
                const newItems = checklists.concat(res.checklists);
                setChecklists(newItems);
                setPage(page + 1);
                setNextPage(res.next_page);
                setIsMoreLoading(!isMoreLoading);
            } else {
                setNextPage(res.next_page);
                if(res.next_page) setPage(page + 1);
                setChecklists(res.checklists);
                // setTotal(res.total);
                }
            })
            .catch((err) => {
                consoleToLog("getClientChecklistApi err", err);
                setChecklistLoading(false);
            });
    };

    const handleDetailsDialogOpen = (current_slug, checklistItem) => {
        setTabValue(0);
        setOpenDialog(true);
        history.push(`/checklist/${current_slug}`);
        setCurrentChecklistItem(checklistItem);
    }

    const handleDetailsDialogClose = () => {
        setOpenDialog(false);
        history.push("/dashboard");
    }

    // get current checklistitem to pass in CheckListDetailsPage
    const getCurrentchecklistItem = (checklistItem) => {
        setCurrentChecklistItem(checklistItem);
    };

    const handleTabOpen = (current_slug, tabName) => {
        if (tabName === "Attachments") {
            setTabValue(1);
        }
        if (tabName === "Notes") {
            setTabValue(3);
        }
        if (tabName === "Audit Trail") {
            setTabValue(4);
        }

        // consoleToLog("******* tabName",tabName);
        setOpenDialog(true);
        history.push(`/checklist/${current_slug}`);
    };

    const loadMoreLogic = () => {
        return (
            <Grid item container>
                <Grid item lg={12}> 
                {nextPage  && (checklistLoading === false && isMoreLoading === false) &&
                    <div style={{textAlign: "center", margin:'16px 0px'}}>
                        <Button type="button"
                            color="secondary"
                            variant="contained"
                            onClick={() => setIsMoreLoading(true)}>
                            Load More
                        </Button>
                    </div>}
                    { isMoreLoading &&
                        <CircularProgress size={35} style={{position:'relative', left:'50%', right:'50%', margin:'16px 0px'}}/>
                    }
                </Grid>
            </Grid>
        )
    }

    // onTaskStatusChanged from details
    const onTaskStatusChanged = (selectedChecklistItemId, updatedChecklist) => {
        const newCheckListStatus = checklists.map((checklistItem) => {
            if (checklistItem.id === selectedChecklistItemId) {
                return updatedChecklist;
            }
            else return checklistItem;
        })
        // consoleToLog("newCheckListStatus in Checklist Component ",newCheckListStatus)
        setChecklists(newCheckListStatus);
    }

    const onAttachmentDelete = (updatedDeleteAttachmentArray) =>{
        const newCheckListArr = checklists.map((checklistItem) => {
            if (checklistItem.id === currentChecklistItem.id) {
                checklistItem.attachment_count = updatedDeleteAttachmentArray.length;
                return checklistItem;
            }else{
                return checklistItem;
            }
        });
        setChecklists(newCheckListArr);
    }

    const onAttachmentAdded = (updatedAttachmentsArray) => {
        const newCheckListArr = checklists.map((checklistItem) => {
            if (checklistItem.id === currentChecklistItem.id) {
                checklistItem.attachment_count = updatedAttachmentsArray.length;
                return checklistItem;
            }else{
                return checklistItem;
            }
        });
        setChecklists(newCheckListArr);
    }

    const onNoteAdded = (newArray,checklistId) =>{
        const newNotesArray = checklists.map((checklistItem) => {
            if (checklistItem.id === checklistId) {
                checklistItem.notes_count = newArray.length;
                return checklistItem;
            }else{
                return checklistItem;
            }
        });
        // consoleToLog("onNoteDeleted",newNotesArray);
        setChecklists(newNotesArray);
    }

    const onNoteDeleted = (newArray,checklistId) =>{
        const newNotesArray = checklists.map((checklistItem) => {
            if (checklistItem.id === checklistId) {
                checklistItem.notes_count = newArray.length;
                return checklistItem;
            }else{
                return checklistItem;
            }
        });
        setChecklists(newNotesArray);
    }

    return (
        <Grid item md={12}>
            {
                checklistLoading ? 
                    <Grid item container justifyContent='center'>
                        <CircularProgress size={20} />
                    </Grid>
                    :
                    <Grid item md={12}>
                        {
                            checklists && checklists.length > 0 ? 
                            checklists.map((checklistItem) => {
                                return <MobileClientChecklistItem checklistItem={checklistItem}
                                            key={props.checklistItem?.id}
                                            handleDetailsDialogOpen={handleDetailsDialogOpen}
                                            getCurrentchecklistItem={getCurrentchecklistItem}
                                            handleTabOpen={handleTabOpen}
                                            />
                            })
                            :
                            <Grid item style={{
                                display:'flex', 
                                justifyContent:'center', 
                                background:'#fff',
                                marginTop:'32px', 
                                padding:'8px'}}>
                                
                                No Checklist Found
                            </Grid>
                        }
                        {loadMoreLogic()}
                    </Grid>
                
            }

            {openDialog &&
                <MobileChecklistDetailsModal openDialog={openDialog} 
                    handleDetailsDialogClose={handleDetailsDialogClose}
                    currentChecklistItem={currentChecklistItem}
                    checkList={checklists}
                    setCheckList={setChecklists}
                    accessLevelManager={accessLevelManager}
                    tabValue={tabValue}
                    modalStatus={modalStatus}
                    slug = {curr_slug}
                    onTaskStatusChanged={onTaskStatusChanged}
                    onAttachmentDelete={onAttachmentDelete}
                    onAttachmentAdded={onAttachmentAdded}
                    onNoteAdded={onNoteAdded}
                    onNoteDeleted={onNoteDeleted}
            />}
        </Grid>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedTemplate: state.organizationInfo.selectedTemplate,
    selectedOrganization: state.organizationInfo.selectedOrganization,
});

export default connect(mapStateToProps)(MobileClientChecklist);