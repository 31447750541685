import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles'; 
import {Grid, Container, Typography, Button, TextField, Dialog, Popover, MenuItem, ClickAwayListener } from '@material-ui/core';
import { setSelectedOrgEmailObj, updateSelectedOrg } from '../actions/selectedOrganization';
import { updateOrganization } from '../actions/organization';
import SwipeableViews from "react-swipeable-views";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import OrgInfo from './OrgInfo';
import InvoiceSettings from "./InvoiceSettings";
import Paper from '@material-ui/core/Paper';
import {shouldDisable, AccessLevel, consoleToLog, trackGAEvent} from "../util/AppUtil";
import { useIsMount } from './useIsMount';
import { useSnackbar } from "notistack";
import SupportEmails from './SupportEmails';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import UserEmails from './UserEmails';
import {getLoginRedirectUrlApi, getOrgSupportEmailsApi} from '../services/genieService';
import EmailDrafts from './EmailDrafts';
import { useLocation } from 'react-router-dom';
import MuiAlert from '@material-ui/lab/Alert';
import ErrorIcon from '@material-ui/icons/Error';
import OrganizationTemplates from './OrganizationTemplates';
import {history} from '../routers/AppRouter';
import ImportTemplateModal from './modals/ImportTemplateModal';
import {setCreateAndEditTemplate} from '../actions/selectedOrganization';
import PreferencesSettings from './PreferencesSettings';
import OrganizationLabels from './OrganizationLabels';
import {getConnectedInvoiceAccountsListApi} from '../services/invoiceService';

const useStyles = makeStyles((theme) => ({
    mainContent : {
        width: (showDrawer) => {
            return showDrawer ? "83%" : "100%"; 
        },
        backgroundColor: theme.palette.primary.light, 
        transition: ".5s", 
        flexGrow: 1,
        //overflow:'hidden',
        overflow:'auto',
        overflowX:'hidden'
        },
    container: {
        marginTop: "8px"
        },
    tab: {
        flexGrow: 1,
        borderRadius:0,
        borderBottom: "1px solid #c5b9b9",
        },
    settingsContainer: {
        width:'100%',
        background:'#fff',
        borderRadius:'4px',
        padding:'16px 24px',
        marginTop:'16px',
        marginBottom:'32px'
    },
    buttonStyle : {
        justifyContent:'center', 
        padding:'8px 15px',
        marginRight:'8px'
    },
    addButton: {
        color: "white",
        backgroundColor: "#3d3d3d",
        marginLeft: "auto",
        height: "30px",
        marginTop: "9px",
        "&:hover": {
            //you want this to be the same as the backgroundColor above
            backgroundColor: "#3d3d3d"
        },
        marginRight: "8px"
    },
    buttonPlusIcon: {
        marginLeft:'8px', 
        fontSize:'1.2rem', 
        alignItems:'center'
    },
    MuiAlertroot: {
        borderRadius:'0px',
        paddingTop:'2px',
        paddingBottom:'2px',
        fontSize:'13px'
    },
    MuiAlertfilledError: {
        color: '#c75877',
        fontWeight: 500,
        backgroundColor: '#fdd4cd',
    },
    verifyLink: {
        color: '#20a8e0',
        cursor: 'pointer',
        fontSize:'13px',
        margin:"0px 1px 0px 8px",
    } ,
    connectIcons: {
        width:'20px',
        marginRight:'8px'
    },
    list : {
        '&:hover' : {
            background:'#d3d3d3'
        }
    },
    muiTab: {
        minWidth:"120px",
        [theme.breakpoints.down(1400)] : {
            minWidth: '100px'
        }
    }
}));

function Alert(props) {
    return <MuiAlert elevation={0} variant="filled" {...props}  />;
}


const OrganizationSettings = (props) =>{
    const {showDrawer} = props;
    const classes = useStyles(showDrawer);
    const { enqueueSnackbar } = useSnackbar();
    const location = useLocation();

    const [value, setValue] = useState('0');
    const [openDialog, setOpenDialog] = useState(false);
    const [connectedAccountList, setConnectedAccountList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [openDialog1, setOpenDialog1] = useState(false);
    const [openDialog2, setOpenDialog2] = useState(false);
    const [supportEmails, setSupportEmails] = useState(props.orgEmailList.filter((email) => email.support));
    const [userEmails, setUserEmails] = useState(props.orgEmailList.filter((email) => !email.support));
    const [anchorEl, setAnchorEl] = useState(null);
    const [isActive, setIsActive] = useState(false);
    const [openLabelDrawer, setOpenLabelDrawer] = useState(false);

    const verifiedEmailArr = supportEmails.length > 0 && supportEmails.filter((email) => email.verified);
    const userInvoiceAppScope = props.auth.user.scope && props.auth.user.scope.length > 0 ? props.auth.user.scope.includes('invoices') : false;

    const [openDrawer, setOpenDrawer] = useState(false);
    const [openDrawer1, setOpenDrawer1] = useState(false);

    const isMount = useIsMount();

    useEffect(() => {
        document.title = `Settings - ${props.fullName}`;
    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleDrawerOpen = () => {
        setOpenDrawer(true);
    }

    const handleDrawerClose = () => {
        setOpenDrawer(false);
    }

    const handleImportTemplateDrawerOpen = () => {
        trackGAEvent(props.selectedOrganization.organization.name, 'Import template clicked', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
        setOpenDrawer1(true);
    }

    const handleImportTemplateDrawerClose = () => {
        setOpenDrawer1(false);
    }

    // useEffect(() => {
    //     getSupportEmailList();
    // }, []);

    useEffect(() => {
        setSupportEmails(props.orgEmailList.filter((email) => email.support));
        setUserEmails(props.orgEmailList.filter((email) => !email.support));
    }, [])

    useEffect(() => {
        if(!isMount) {
            setSupportEmails(props.orgEmailList.filter((email) => email.support));
            setUserEmails(props.orgEmailList.filter((email) => !email.support));
        }
    }, [props.orgEmailList])

    useEffect(() => {
            if(props.selectedOrgEmailObj && props.selectedOrgEmailObj?.email_type) {
                setValue('3');
            }  

    }, []);

    useEffect(() => {
        if(!props.selectedOrgEmailObj && props.isCreateEditTemplate) {
            setValue('5');
            props.isCreateEditTemplate && props.setCreateAndEditTemplate(false);
        }
    }, []);

    useEffect(() => {
        if(!isMount) {
            if(value === '4') {
                props.setCreateAndEditTemplate(false);
            }
        }
    }, [value])

    const getSupportEmailList = () => {
        const organization_id = props.selectedOrganization.organization.id;

        getOrgSupportEmailsApi(organization_id)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response getOrgSupportEmailsApi: ', res);

                setSupportEmails(res.organization_emails.filter((email) => email.support));
                setUserEmails(res.organization_emails.filter((email) => !email.support));

            })
            .catch((e) => {
                consoleToLog('Error getOrgSupportEmailsApi: ', e.response);
                if(e.response.data && e.response.data.message) {
                    return;
                }
            })
    }

    useEffect(() => {
        if(!isMount) {
            if(value === 1) {
                consoleToLog('Invoice Settings Clicked');
                trackGAEvent(props.selectedOrganization.organization.name, 'Invoice Settings', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
            }
        }
    }, [value]);

    useEffect(() => {
        if(!isMount) {
                consoleToLog('Org Email Changed');
                setSupportEmails(props.orgEmailList.filter((email) => email.support));
                setUserEmails(props.orgEmailList.filter((email) => !email.support))
        }
    }, [props.orgEmailList]);
    
    const { access_level } = props.selectedOrganization || {};
    const accessLeveOwner = shouldDisable(AccessLevel.getUserAccessLevelValue(access_level), AccessLevel.OWNER);
    const accessLevelManager = shouldDisable(AccessLevel.getUserAccessLevelValue(access_level), AccessLevel.MANAGER);

    const ButtonInTabs = ({ className, onClick, children }) => {
        return <Button 
                style={{position:'relative', marginLeft:'auto'}}
                className={className} 
                onClick={onClick} 
                children={children}
                />;
    };

    const onConnectEmailClick = (e, emailType) => {
        e.preventDefault();
        const emailObj = {
            email_type: emailType
        }
        props.setSelectedOrgEmailObj(emailObj);
        setIsActive(false);

        if(emailType === 'gmail') {
            getLoginRedirectUrl();
        } else {
            loginWithoutLook();
        }
    } 

    const getLoginRedirectUrl=()=>{
        const redirect_uri = process.env.REACT_APP_GMAIL_REDIRECT_URL;
        getLoginRedirectUrlApi(redirect_uri)
        .then((response) => {
            const res = response.data;
            consoleToLog("frontend: ", res);
            window.open(res.url, "_self");
        })
        .catch((e) => {
            consoleToLog("error", e);
        });
    }

    const loginWithoutLook = () => {
        window.open(`https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${process.env.REACT_APP_OUTLOOK_CLIENT_ID}&scope=offline_access%20https://graph.microsoft.com/mail.send%20https://graph.microsoft.com/User.Read%20https://graph.microsoft.com/Mail.Read%20https://graph.microsoft.com/Mail.ReadWrite%20https://graph.microsoft.com/Mail.ReadBasic&response_type=code&redirect_uri=${process.env.REACT_APP_OUTLOOK_REDIRECT_URL}`, '_self');
    }

    const handleDialogOpen = () => {
        setOpenDialog1(true);
    }

    const handleDialogClose = () => {
        setOpenDialog1(false);
    }

    const handleUserDialogOpen = () => {
        setOpenDialog2(true);
    }

    const handleUserDialogClose = () => {
        setOpenDialog2(false);
    }

    const onConnectClick = () => {
        trackGAEvent(props.selectedOrganization.organization.name, 'Invoice Accounts Connect Clicked', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
        setOpenDialog(true);
    }

    const onAddLabelClick = () => {
        setOpenLabelDrawer(true);
    }

    return(
        <div className={classes.mainContent} 
            style={{height: props.height}}>
            {
                supportEmails && supportEmails.length > 0 && verifiedEmailArr.length === 0 && value === 2 &&
                <Alert severity="error" icon={<ErrorIcon fontSize='small' style={{marginRight:'-6px', fontSize:'18px', marginTop:'1px'}}/>} classes={{
                    root: classes.MuiAlertroot,
                    filledError: classes.MuiAlertfilledError
                }}>Your one of the support email is not verified. Follow up emails will be disabled if support emails are not verified.
                </Alert>
            }
        <Container className={classes.container} maxWidth="xl">
            <Typography style={{fontSize:'20px', fontWeight:'600'}} color="primary">
                Settings
            </Typography>

            <Grid item container direction="column" style={{marginTop:'3px'}}>
                <Grid item container>
                    <Paper className={classes.tab} elevation={1} style={{marginTop:"10px"}}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                        >
                            <Tab label="Info" value={'0'} 
                                classes={{
                                    root: classes.muiTab
                                }}/>
                            {!accessLevelManager && <Tab label="Preferences" 
                                                        value={'1'}
                                                        classes={{
                                                            root: classes.muiTab
                                                        }}/>}
                            {!accessLeveOwner && userInvoiceAppScope && <Tab label="Invoice Accounts" 
                                                    value={'2'} classes={{
                                                    root: classes.muiTab
                                                }}/>}
                            {!accessLeveOwner && <Tab label="Support Emails" 
                                                    value={'3'}
                                                    classes={{
                                                        root: classes.muiTab
                                                    }}/>}
                            <Tab label="Email Drafts" value={'4'}
                                classes={{
                                    root: classes.muiTab
                                }}/>
                            {!accessLevelManager &&<Tab label="Templates" 
                                                        value={'5'}
                                                        classes={{
                                                            root: classes.muiTab
                                                        }}/>}
                            <Tab label="Labels" value={'6'}
                                classes={{
                                    root: classes.muiTab
                                }}/>

                            {!accessLevelManager &&
                            <div style={{marginLeft:'auto', marginRight:'8px'}}>
                            {
                                value === '5' &&
                                <ButtonInTabs
                                    onClick={() => {
                                        trackGAEvent(props.selectedOrganization.organization.name, 'Add Template Clicked', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
                                        props.setCreateAndEditTemplate(true);
                                        history.push('/template/create')
                                    }}
                                    className={classes.addButton}
                                >
                                    Add Template
                                    <AddCircleOutlineIcon fontSize='small' 
                                        style={{fontSize:'15px', marginLeft:'4px'}}
                                        className={classes.buttonPlusIcon}/>
                                </ButtonInTabs>
                            }

                            {
                                value === '5' &&
                                <ButtonInTabs
                                    onClick={handleImportTemplateDrawerOpen}
                                    className={classes.addButton}
                                >
                                    Import Template
                                    <AddCircleOutlineIcon fontSize='small' 
                                        style={{fontSize:'15px', marginLeft:'4px'}}
                                        className={classes.buttonPlusIcon}/>
                                </ButtonInTabs>
                            }
                            </div>}

                            {
                                value === '4' &&
                                <ButtonInTabs
                                    onClick={handleDrawerOpen}
                                    className={classes.addButton}
                                >
                                    Add Email Draft 
                                    <AddCircleOutlineIcon fontSize='small' 
                                        style={{fontSize:'15px', marginLeft:'4px'}}
                                        className={classes.buttonPlusIcon}/>
                                </ButtonInTabs>
                            }

                            {
                                value === '3' && !accessLeveOwner &&
                                <ButtonInTabs
                                    className={classes.addButton}
                                    onClick={() => setIsActive(!isActive)}
                                >
                                    Add
                                    <AddCircleOutlineIcon fontSize='small' 
                                            style={{fontSize:'15px', marginLeft:'4px'}}
                                            className={classes.buttonPlusIcon}/>
                                </ButtonInTabs>
                            }
                            
                            {
                                // value === '2' && !accessLeveOwner && props.accountList.length > 0 &&
                                // <ButtonInTabs
                                //     onClick={onConnectClick}
                                //     className={classes.addButton}
                                // >
                                //     Connect
                                // </ButtonInTabs>
                            }

                            {
                                value === '6'  &&
                                <ButtonInTabs
                                    className={classes.addButton}
                                    onClick={onAddLabelClick}
                                >
                                    Add Label
                                    <AddCircleOutlineIcon fontSize='small' 
                                            style={{fontSize:'15px', marginLeft:'4px'}}
                                            className={classes.buttonPlusIcon}/>
                                </ButtonInTabs>
                            }

                        </Tabs>
                    </Paper>
                    <Grid item style={{position:"relative"}}>
                        
                        {isActive &&
                            <ClickAwayListener onClickAway={() => setIsActive(false)}>
                            <Grid item
                                style={{marginTop:'24px', marginRight:'6px'}}
                                className={`menu ${isActive ? "active" : "inactive"}`}>
                                <ul>
                                    <li className={classes.list} onClick={(e) => onConnectEmailClick(e, 'gmail')}>
                                        <a href="" style={{display:'flex', alignItems:'center'}}>
                                            <img src='images/gmail_icon.png' className={classes.connectIcons}/>
                                            Gmail
                                        </a>
                                    </li>

                                    <li className={classes.list} onClick={(e) => onConnectEmailClick(e, 'outlook')}>
                                        <a href="" style={{display:'flex', alignItems:'center'}}>
                                            <img src='images/outlook_icon.png' className={classes.connectIcons} />
                                            Outlook
                                        </a>
                                    </li>

                                </ul>
                                
                            </Grid>
                            </ClickAwayListener>}
                    </Grid>
                </Grid>
                <Grid item md={12} style={{backgroundColor:"white",marginBottom:"1rem"}}>
                    {value === '0' && <OrgInfo/>}
                    {value === '1' && !accessLevelManager && <PreferencesSettings />}
                    {value === '2' && !accessLeveOwner && userInvoiceAppScope &&
                    <InvoiceSettings openDialog={openDialog}
                            loading={loading}
                            setOpenDialog={setOpenDialog}
                            setConnectedAccountList={setConnectedAccountList}
                    />}
                    {value === '3' && !accessLeveOwner && 
                        <SupportEmails openDialog1={openDialog1}
                            handleDialogClose={handleDialogClose}
                            supportEmails={supportEmails}
                            setSupportEmails={setSupportEmails}
                        />}
                    {value === '4' && 
                    <EmailDrafts openDrawer={openDrawer}
                        setOpenDrawer={setOpenDrawer}
                        handleDrawerClose={handleDrawerClose}/>} 
                    {value === '5' && !accessLevelManager &&
                    <OrganizationTemplates setCreateAndEditTemplate={props.setCreateAndEditTemplate}/>}

                    {value === '6' && <OrganizationLabels openLabelDrawer={openLabelDrawer}
                                            setOpenLabelDrawer={setOpenLabelDrawer}/>}
                </Grid>
            </Grid>
        </Container>
        <ImportTemplateModal openDrawer={openDrawer1} 
            handleDrawerClose={handleImportTemplateDrawerClose}/>
    </div>

    )
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    accountList: state.organizationInfo.accountList,
    orgEmailList: state.organizationInfo.orgEmailList,
    selectedOrgEmailObj: state.organizationInfo.selectedOrgEmailObj,
    isCreateEditTemplate: state.organizationInfo.isCreateEditTemplate
});

const mapDispatchToProps = (dispatch) => ({
    setSelectedOrgEmailObj: (emailObj) => dispatch(setSelectedOrgEmailObj(emailObj)),
    updateOrganization: (organization) => dispatch(updateOrganization(organization)),
    updateSelectedOrg: (organization) => dispatch(updateSelectedOrg(organization)),
    setCreateAndEditTemplate: (createEditTemplate) => dispatch(setCreateAndEditTemplate(createEditTemplate))
});

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationSettings);