import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core';

// Inspired by the former Facebook spinners.
const useStylesFacebook = makeStyles((theme) => ({
    root: {
      position: 'relative',
    },
    bottom: {
      color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    top: {
      color: theme.palette.primary.main/* '#1a90ff' */,
      
      position: 'absolute',
      left: 0,
    },
    circle: {
      strokeLinecap: 'round',
    },
  }));
  
  function CircularProgressWithLabel(props) {
    const classes = useStylesFacebook();
  
    return (
      <Box position="relative" display="inline-flex">
        <CircularProgress
          variant="determinate"
          className={classes.bottom}
          size={28}
          thickness={4}
          {...props}
          value={100}
        />
        <CircularProgress
          variant="determinate"
          disableShrink
          className={classes.top}
          classes={{
            circle: classes.circle,
          }}
          size={28}
          thickness={4}
          {...props}
        />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="caption" 
            component="div" 
            color="textSecondary"
            style={{fontSize: "0.50rem", background: props.checklistItemRead && '#fff', borderRadius:'50%', display:'flex', 
            justifyContent:'center', alignItems:'center', width:'22px', height:'22px'}}>
            {`${Math.round(props.value,)}%`}</Typography>
        </Box>
        </Box>
    );
  }
export default CircularProgressWithLabel;