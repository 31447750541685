import React, { useState, useEffect, createRef } from "react";
import {TextField, Grid, Button, Typography, Tooltip, Popover, MenuItem, Link,IconButton, CircularProgress, Paper} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {defaultUserDefinedNames, isPredefinedName, udfTypes} from "../../util/userDefinedFieldsUtil";
import { useSnackbar } from "notistack";
import { connect } from "react-redux";
import {consoleToLog, trackGAEvent, showDefaultClientValues,} from "../../util/AppUtil";
import ClearIcon from "@material-ui/icons/Clear";
import { useIsMount } from "../useIsMount";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import {downloadAttachmentApi} from "../../services/authService";
import { setCDSalt } from "../../actions/selectedOrganization";
import {Autocomplete } from '@material-ui/lab';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CloseIcon from '@material-ui/icons/Close';
import CustomFieldDrawer from "../CustomFieldDrawer";
import {getDownloadURLForClientAttachmentApi} from '../../services/clientService'
import { isValidUserDefinedFields } from "../../util/userDefinedFieldsUtil";
import NodeRSA from "node-rsa";
import {deleteCustomFieldAttachmentApi} from '../../services/authService';

const useStyles = makeStyles((theme) => ({
  MuiFormControlroot: {
    "& .MuiOutlinedInput-input": {
      paddingLeft: "16px",
    },
  },
  listContainer: {
    "&:last-child .dropdownDivider": {
      display: "none",
    },
  },
  dividerStyle: {
    background: "#000",
    opacity: "0.1",
  },
  customMarginTop: {
    margin: "16px 0px 0px 0px",
  },
  customMarginBottom: {
    marginBottom: "-8px",
  },
  udfButton: {
    position: "relative",
    width: "27px",
    height: "27px",
    minWidth: "0",
    margin: "-5px 0px 0px 10px",
    fontSize: "14px",
    color: "#555555",
    borderRadius: "50%",
    border: "1px solid #555555",
    paddingTop: "5px",
    //display: props.isFromWaitingForMyApproval ? "none" : "flex",
  },
  customColumn: {
    maxWidth: "31.5%",
    flexBasis: "31.5%",
  },
  customColumn2: {
    maxWidth: "5.5%",
    flexBasis: "5.5%",
  },
  circularProgress: {
    marginLeft: 0,
    marginRight: theme.spacing(2),
    color: "white",
  },
  downloadLink: {
    color: "#20a8e0",
    fontSize: "15px",
    fontWeight: "600",
    marginLeft: "2px",
    cursor: "pointer",
  },
  circularProgress: {
    marginLeft: 0,
    marginRight: theme.spacing(2),
    color: 'white'
  },
  addCustomFieldStyle: {
    margin:'8px auto 4px 4px', 
    width:'95%', 
    //color:'#2196f3', 
    //borderColor:'#d4d8de'
    color:'#555555', 
    borderColor:'#d4d8de'
  },
  addItemIcon: {
      margin:'0px 6px 0px 0px', 
      fontSize:'16px'
  },
  MuiAutocompleteinputRoot : {
    '& .MuiOutlinedInput-root' : {
    padding:'0.5px 1px 0.5px 1px',
    borderRadius: '4px',
    background:'#fff',
    marginTop:'4px'
    }
  },
  customTextField: {
    "& input::placeholder": {
        paddingLeft: "8px",
    }
  },
  itemOptionStyle: {
    width: "100%", 
    height:'100%',
    color:'#777f8e',
    margin:'5px 0px'
  },
  MuiFormControlroot: {
    '& .MuiOutlinedInput-input' :{
        paddingLeft: '16px',
    },   
  },
  addCustomFieldStyle: {
    margin:'8px auto 4px 8px', 
    width:'95%', 
    color:'#2196f3', 
    borderColor:'#d4d8de'
  },
  addItemIcon: {
      margin:'0px 6px 0px 0px', 
      fontSize:'16px'
  },
}));

const CustomFields = (props) => {
  const classes = useStyles();
  const isMount = useIsMount();
  const { enqueueSnackbar } = useSnackbar();
  const user_defined_field = props.companyObj.user_defined_field ? props.companyObj.user_defined_field : [];

  const [anchorEl, setAnchorEl] = useState(null);
  const [userDefinedFields, setUserDefinedFields] = useState(user_defined_field);
  const [deletedFiles, setDeletedFiles] = useState([]);
  const [openCustomFieldDrawer, setOpenCustomFieldDrawer] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(undefined);
  const focusInputRef = createRef();
  const customFieldList = props.customFieldList;

  const [switchFocus, setSwitchFocus] = useState(false);

  useEffect(() => {
      if(user_defined_field && user_defined_field.length > 0) {
        const updatedArray = user_defined_field.filter((udf) => udf.type !== udfTypes.ImageOrDocument.value);
        setUserDefinedFields(updatedArray);
      }
  }, []);

  useEffect(() => {
    if(!isMount) {
      const updatedArray = user_defined_field.filter((udf) => udf.type !== udfTypes.ImageOrDocument.value);
      setUserDefinedFields(updatedArray);
    }
  }, [props.companyObj]);

  useEffect(() => {
    if(!isMount) {
        if(switchFocus) {
          focusInputRef.current?.focus();
        }
    }

  }, [focusInputRef]);

  useEffect(() => {
    if(!isMount) {
      if(props.addCustomField) {
        onUserDefinedFieldAdded();
        props.setAddCustomField(false);
      }
    }
  }, [props.addCustomField])

  const handleSave = () =>{
    // to parent
    //props.editCustomFields(userDefinedFields, 0);
    if(userDefinedFields.length > 0) {
      const errors = isValidUserDefinedFields(userDefinedFields, props.orgEmailList);
      if (errors) {
          enqueueSnackbar(errors, {
              variant:'error'
          });
          return;
      } else {
        props.callNextFileUploadOrEditApi(0, userDefinedFields);
      }
    } 
  }

  const handleOpenUDFPopOver = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseUDFPopOver = () => {
    setAnchorEl(null);
  };

  const userDefinedFieldPopover = () => {
    return (
      <Popover
        id="udf_popover"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleCloseUDFPopOver}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {Object.values(defaultUserDefinedNames) &&
          Object.values(defaultUserDefinedNames).map((udf) => {
            return (
              <MenuItem key={udf} onClick={() => onUserDefinedFieldAdded(udf)}>
                {udf}
              </MenuItem>
            );
          })}
      </Popover>
    );
  };

  const onUpdateUDFName = (e, index) => {
    const newNameValue = e.target.value;
    const allObjs = userDefinedFields;
    allObjs[index].name = newNameValue;
    setUserDefinedFields([...allObjs]);
  };

  const onUDFNameChanged = (e, udf, index) => {
    const updatedArray = userDefinedFields;
    updatedArray[index].name = udf.name;
    setUserDefinedFields([...updatedArray]);
}

  const onUpdateUDFType = (e, index) => {
    const allObjs = userDefinedFields;
    allObjs[index].type = e.target.value;
    allObjs[index].value = undefined;
    setUserDefinedFields([...allObjs]);
  };

  const onUpdateUDFValue = (e, index, type) => {
    var newValue = undefined;
    if (type === udfTypes.ImageOrDocument.value) {
      //file
      newValue = e.target.files[0];
      //console.log('newFile', newValue);
      //uploadFileIfExists(newValue, index)
    } else {
      //text password
      newValue = e.target.value;
    }
    const allObjs = userDefinedFields;
    allObjs[index].value = newValue;
    setUserDefinedFields([...allObjs]);
  };

  const onRemoveUDFObj = (removeUDFObj) => {
    consoleToLog("removeUDFObj", removeUDFObj);
    //if udf.type=file & not newly added udf,
    //save it to state, send to backend later for deletion from aws
    trackGAEvent(props.selectedOrganization.organization.name, "Delete User Defined Field On Edit Company", `${props.auth.user.firstname} ${props.auth.user.lastname}`);

    const remainingObjs = userDefinedFields.filter((udf) => udf.name !== removeUDFObj.name);
    if (removeUDFObj.type === udfTypes.ImageOrDocument.value && removeUDFObj.value && typeof(removeUDFObj.value) !== 'object') {
      callRemoveAttachmentApi(removeUDFObj);
    }

    if(remainingObjs && remainingObjs.length === 0 && removeUDFObj.value) {
      props.callNextFileUploadOrEditApi(0, remainingObjs);
    } else {
      setUserDefinedArrayInState(remainingObjs);
      setUserDefinedFields(remainingObjs);
    }
  };

  const callRemoveAttachmentApi = (removeUDFObj) => {
    const client_id = props.companyObj.id
      deleteCustomFieldAttachmentApi(client_id, removeUDFObj.value) 
      .then((response) => {
        const res = response.data;
        consoleToLog('Response deleteCustomFieldAttachmentApi: ', res);
      })
      .catch((e) => {
        consoleToLog('Error deleteCustomFieldAttachmentApi', e.response);
      })
  }

  const onRemoveCustomFieldClick = (udf, index) => {
    const isFromParentCustomField = props.companyObj?.user_defined_field?.includes(udf);
    console.log('is from parent', isFromParentCustomField);

    if(isFromParentCustomField) {
        if(window.confirm('Are you sure you want to delete this Custom Field?')) {
            onRemoveUDFObj(udf, index);
        }
    } else {
        onRemoveUDFObj(udf, index);
    }

}

    consoleToLog('userDefinedFields', userDefinedFields);

  const downloadAttachmentUrl = (value) => {
    const organization_id = props.selectedOrganization.organization.id;

    //get downloadable url from backend using url
    downloadAttachmentApi(organization_id, value)
      .then((response) => {
        const res = response.data;
        consoleToLog("Response downloadAttachmentApi: ", res);

        const downloadableURL = res.document_signed_url;

        //initiate download
        document.getElementById("udfFileDownloader").href = downloadableURL;
        document.getElementById("udfFileDownloader").click();
      })
      .catch((e) => {
        consoleToLog(" downloadAttachmentApi error", e.response);
        if (e.response.data && e.response.data.message) {
          enqueueSnackbar(e.response.data.message, {
            variant: "error",
          });
          return false;
        }
      });
  };

  const onUserDefinedFieldAdded = (udf) => {
    //allow repeatation give warning about error
    // if (userDefinedFields && userDefinedFields.length > 0) {
    //   const names = userDefinedFields.map((udf) => udf.name).join();
    //   if (names.includes(udf) && udf !== defaultUserDefinedNames.Custom) {
    //     enqueueSnackbar("Name Already used! Try Custom to add more.", {
    //       variant: "error",
    //     });
    //     return;
    //   }
    // }

    // const typeValue =
    //   udf === defaultUserDefinedNames.GSTPassword ||
    //   udf === defaultUserDefinedNames.ITPassword ||
    //   udf === defaultUserDefinedNames.RERAPassword
    //     ? udfTypes.Password.value
    //     : udfTypes.Text.value;

    // var newUDFObj = {
    //   name: udf !== defaultUserDefinedNames.Custom ? udf : "",
    //   type: typeValue,
    //   value: undefined,
    //   isPredefinedName: isPredefinedName(udf),
    //   shouldEncrypt: true, //for api
    // };
    
    // setUserDefinedFields([...userDefinedFields, newUDFObj]);
    // setAnchorEl(null);

      //allow repeatation give warning about error
      var newUDFObj = {
        name: undefined,
        type: undefined,
        value: undefined,
        show_invoice: false,
        shouldEncrypt: true 
      };
      trackGAEvent(props.selectedOrganization.organization.name, "Add User Defined Field", `${props.auth.user.firstname} ${props.auth.user.lastname}`);
    setUserDefinedFields([...userDefinedFields, newUDFObj]);
  };

  const setUserDefinedArrayInState = (newUDFArr) => {
    setUserDefinedFields(newUDFArr);
  };

  const decryptPasswordAndShow = (salt, udf, index) => {
    //const organization_id = props.selectedOrganization.organization.id;

    try {
      const key = new NodeRSA(salt);
      const decryptedPassword = key.decrypt(udf.value, 'utf8');
      consoleToLog('decrypted key*******************', decryptedPassword);
      if(decryptedPassword) {
        const allObjs = userDefinedFields;
        for (var i = 0; i < allObjs.length; i++) {
            if (allObjs[i].name === udf.name) {
              allObjs[i].showPasswordField = decryptedPassword;
              setUserDefinedArrayInState(allObjs);
              consoleToLog("After decryptionApi ", allObjs);
              setUserDefinedFields(allObjs);
              // to parent
              props.OnShowPassword(allObjs);
              break;
            }
          }
      } 

    } catch (e) {
      consoleToLog('Error', e);
      enqueueSnackbar('Invalid private key', {variant: 'error'})
      props.setCDSalt("");
      document.getElementById(`userKeyAttachment${index}`).value = null;
      return;
    }
    // consoleToLog('salt', salt)
    // decryptionApi(organization_id, salt, udf.value)
    //   .then((response) => {
    //     const res = response.data;
    //     consoleToLog("Response decryptionApi: ", res);

    //     const password = res.plainText;

    //     const allObjs = userDefinedFields;
    //     for (var i = 0; i < allObjs.length; i++) {
    //       if (allObjs[i].name === udf.name) {
    //         allObjs[i].showPasswordField = password;
    //         setUserDefinedArrayInState(allObjs);
    //         consoleToLog("After decryptionApi ", allObjs);
    //         setUserDefinedFields(allObjs);
    //         // to parent
    //         props.OnShowPassword(allObjs);
    //         break;
    //       }
    //     }

    //     // ReactGA.event({
    //     //     category: 'Company',
    //     //     action: 'Password Viewed',
    //     //     label: `Viewed by ${this.props.auth.user.firstname + ' ' + this.props.auth.user.lastname}`
    //     // });
    //   })
    //   .catch((e) => {
    //     consoleToLog("decryptionApi error", e);

    //     //clear previous cd.salt if any
    //     props.setCDSalt("");
    //     document.getElementById(`userKeyAttachment${index}`).value = null;

    //     if (e.response.data && e.response.data.message) {
    //       enqueueSnackbar(e.response.data.message, {
    //         variant: "error",
    //       });
    //       return false;
    //     }
    //   });
  };

  const onShowPasswordClick = (udf, index) => {
    //hide if password already shown
    if (udf.showPasswordField) {
      //password already shown to user, hide it
      const allObjs = userDefinedFields;
      for (var i = 0; i < allObjs.length; i++) {
        if (allObjs[i].name === udf.name) {
          allObjs[i].showPasswordField = undefined;
          setUserDefinedArrayInState(allObjs);
          setUserDefinedFields(allObjs);
          // to parents
          props.OnShowPassword(allObjs);
          break;
        }
      }
      return;
    }
    const salt = props.cd && props.cd.salt ? props.cd.salt : undefined;
    if (!salt) {
      const message = "Private key for decrypting the password not found! Please select the file.";
      if (window.confirm(message)) {
        document.getElementById(`userKeyAttachment${index}`).click();
        // console.log('123456', clicked)
      }
    } else {
      consoleToLog("Else ShowPassword");
      decryptPasswordAndShow(salt, udf, index);
    }
  };

  const onPasswordFieldClick = (e, udf) => {
    //clear text field //mark shouldEncrypt for API
    if (udf.type === udfTypes.Password.value) {
      const newUDFObj = {
        ...udf,
        value: "",
        shouldEncrypt: true, //for api
      };
      const newUDFArr = userDefinedFields.map((udfObj) => {
        if (udfObj.name === udf.name) {
          return newUDFObj;
        } else {
          return udfObj;
        }
      });
      setUserDefinedArrayInState(newUDFArr);
    }
  };

  const onKeyFileSelected = (e, udf, index) => {
    const file = e.target.files[0];

    if (!file) {
      enqueueSnackbar("File Not Selected", {
        variant: "error",
      });
      return;
    }

    //read file contents and store in redux
    const fileReader = new FileReader();
    fileReader.readAsText(file);
    fileReader.onloadend = () => {
      const fileContents = fileReader.result;
      //const base64Contents = window.btoa(fileContents); //convert to base64 format
      //store in redux
      props.setCDSalt(fileContents);

      decryptPasswordAndShow(fileContents, udf, index);
    };
  };

  const onItemClearClick = (index) => {
    const updatedArray = userDefinedFields;
    
    updatedArray[index].name = undefined;
    updatedArray[index].type = undefined;
    updatedArray[index].value = undefined;

    setUserDefinedFields([...updatedArray]);
  }

  const onItemClick = (option, index) => {
    const updatedArray = userDefinedFields;
    
    updatedArray[index].name = option.name;
    updatedArray[index].type = option.type;
    updatedArray[index].value = showDefaultClientValues(option, props.companyObj);
    updatedArray[index].show_invoice = option.show_invoice;
    if(option.type === udfTypes.Password.value) {
      updatedArray[index].shouldEncrypt = true;
  } else {
      updatedArray[index].shouldEncrypt = false;
  }
    focusInputRef.current?.focus();
    
    setUserDefinedFields([...updatedArray]);
    if(switchFocus) setSwitchFocus(false);
  }

  const filterCustomFeilds = () => {
    const customFieldArr = customFieldList?.filter((cf) => userDefinedFields?.find(filter => filter.name === cf.name) === undefined)
    return customFieldArr;
  }

  const customNameField = (udf) => {
    const udfName = props.companyObj.user_defined_field?.find((udfField) => udfField.name === udf.name);
    //console.log('udf name*****************************', udfName);
    return udfName?.name ? true : false;
  }

  const handleOpenCustomFieldDrawer = () => {
    setOpenCustomFieldDrawer(true);
  }

  const handleCloseCustomFieldDrawer = () => {
    setOpenCustomFieldDrawer(false);
  }

  const AddCustomFieldButton = ({ children, ...other }) => (
    <Paper {...other} 
        style={{marginTop:'6px',
                boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px' 
                }}>
        <Button fullWidth
            className={classes.addCustomFieldStyle}
            variant="outlined"
            color="primary"
            style={{padding:'6px 2px'}}
            onMouseDown={handleOpenCustomFieldDrawer}
            classes={{
                label: classes.MuiButtonlabel
            }}
        >
        <AddCircleOutlineIcon fontSize='small' className={classes.addItemIcon}/>  
            Add Custom Field
        </Button>
        {children}
    </Paper>
    );

  const  getSelectedItem = (index) => {
      const itemObj = customFieldList.find((cf) => {
          if(cf.name === userDefinedFields[index].name) {
              return cf
          } 
      });
      //console.log('**********item', itemObj);

      return itemObj || {};
  }

  const renderOptions = (option, index) => {
    return (
        <div className={classes.itemOptionStyle}>
            <Grid item container 
                onClick={() => onItemClick(option, index)}
                justifyContent="space-between">
                <Typography variant='body2'>
                    {option.name}
                </Typography> 
            </Grid>    

        </div>
        );
  }

  

  const showCustomField = () => {
    return (
        userDefinedFields && userDefinedFields.map((udf, index) => {
        const typeOfValueInputField =
        udf.type === udfTypes.Text.value
            ? "text"
            : udf.type === udfTypes.Password.value
            ? "password"
            : "";
        return (
        <Grid item container spacing={1}
            key={index}
            onClick={() => setCurrentIndex(index)}
            alignItems="center">

            <Grid item sm={3}>
                <Autocomplete
                    id="combo-box-demo"
                    options={filterCustomFeilds()}
                    classes={{
                        root: classes.MuiAutocompleteinputRoot
                    }}
                    //value={getSelectedItem(index)}
                    value={userDefinedFields[index]}
                    disabled={customNameField(udf)}
                    getOptionLabel={(option) => option.name}
                    //inputValue={invoiceItem.item_name}
                    //onInputChange={(e, newInputvalue) => onInvoiceItemNameChanged(e, newInputvalue, index)}
                    renderOption={(option) => renderOptions(option, index)}
                    onChange={(e, newValue) => {
                        onUDFNameChanged(e, newValue, index);
                        onItemClick(newValue, index)
                    }}
                    renderInput={params => (
                        <TextField {...params} variant="outlined" 
                        placeholder='Search Custom Field' 
                        fullWidth
                        classes={{
                            root: classes.customTextField
                        }}
                            />
                    )}
                    closeIcon={<CloseIcon fontSize='small' onClick={() => onItemClearClick(index)}/>}
                    PaperComponent={AddCustomFieldButton}
                />
            </Grid>

            <Grid item sm={3}>
                <TextField
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    disabled
                    value={udf.type ? udf.type : -1}
                    select
                    classes={{root: classes.MuiFormControlroot,}}>
                        {Object.values(udfTypes).map((typeObj) => {
                        return (
                            <MenuItem key={typeObj.value} value={typeObj.value}>
                            {typeObj.displayName}
                            </MenuItem>
                        );
                        })}
                </TextField>
            </Grid>

            <Grid item sm={3}>
            {udf.type === udfTypes.ImageOrDocument.value ? (
                <Grid item container sm={11}>
                  {/* {!udf.value && (
                    <label>
                      <input
                        style={{ display: "none" }}
                        type="file"
                        onChange={(e) => onUpdateUDFValue(e, index, udf.type)}
                      />

                      <Typography
                        variant="body2"
                        style={{
                          marginTop: "2px",
                          marginBottom: "none",
                          padding: "8px 38px 8px 32px",
                          border: "1px dashed #555",
                          cursor: "pointer",
                          color: "#555",
                        }}
                      >
                        <i
                          className="flaticon-attachment"
                          style={{ marginRight: "6px" }}
                        ></i>
                        Attach File
                      </Typography>
                    </label>
                  )} */}
                  {/* {(udf.value && (udf.value.name && udf.value.name.indexOf("/") === -1)) ? (
                    <Tooltip
                      title={
                        udf.value.name
                          ? udf.value.name
                          : udf.value
                          ? udf.value.replace(/^.*[\\\/]/, "")
                          : ""
                      }
                      arrow
                    >
                      <Typography
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          cursor: "pointer",
                        }}
                      >
                        {udf.value.name
                          ? udf.value.name
                          : udf.value
                          ? udf.value.replace(/^.*[\\\/]/, "")
                          : ""}
                      </Typography>
                    </Tooltip>
                  )
                  : 
                  (udf.value && (udf.value !== '' && udf.value?.indexOf("/") > -1)) && (
                    <Tooltip title={udf.value?.replace(/^.*[\\\/]/, "")} arrow>
                      <div
                        onClick={() =>
                          downloadAttachmentUrl(udf.value)
                        }
                      >
                        <Typography className={classes.downloadLink}>
                          {udf.value.replace(/^.*[\\\/]/, "")}
                        </Typography>
                        <Link
                          style={{ display: "none" }}
                          id="udfFileDownloader"
                          target="_blank"
                          onClick={(e) => e.stopPropagation()}
                          download
                        ></Link>
                      </div>
                    </Tooltip>
                      )}   */}
                </Grid>
              ) : udf.showPasswordField ? (
                <TextField
                  fullWidth
                  disabled
                  variant="outlined"
                  type="text"
                  margin="dense"
                  value={udf.showPasswordField ? udf.showPasswordField : ""}
                  classes={{  root: classes.MuiFormControlroot,
                  }}
                />
              ) : (
                <TextField
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  type={typeOfValueInputField}
                  value={udf.value ? udf.value : ""}
                  inputRef={focusInputRef}
                  // disabled={showCompanyDetails}
                  onClick={(e) => onPasswordFieldClick(e, udf)}
                  onChange={(e) => onUpdateUDFValue(e, index, udf.type)}
                  placeholder="Value"
                  classes={{
                    root: classes.MuiFormControlroot,
                  }}
                />
              )}
            </Grid>

            <Grid item md={3}>
              <Grid item container>
                <Grid item md={1}>
                    <IconButton
                        onClick={() => onRemoveCustomFieldClick(udf, index)}
                        style={{
                        color: "#555",
                        padding: "0px",
                        // marginRight: "-16px",
                        marginTop: "3px",
                        }}
                    >
                        <ClearIcon />
                    </IconButton>
                </Grid>

              {udf.type === udfTypes.Password.value && 
                props.companyObj?.user_defined_field && 
                props.companyObj?.user_defined_field.includes(udf) &&  ( //show details show password button
                  <Grid item md={1}>
                    <VisibilityOutlinedIcon
                      fontSize="small"
                      style={{
                        marginTop: "6px",
                        cursor: "pointer",
                        color: udf.showPasswordField ? "red" : "green",
                      }}
                      onClick={(e) => onShowPasswordClick(udf, index)}
                    />
                    <input
                      style={{ display: "none" }}
                      type="file"
                      id={`userKeyAttachment${index}`}
                      onChange={(e) => onKeyFileSelected(e, udf, index)}
                      accept=".pem"
                    ></input>
                  </Grid>
                )}
                </Grid>
              </Grid>
        </Grid>
        );
    })
    );
  };

  const userDefinedFieldsNotFound = () =>{
    return(
      <Grid item container>
        <Grid item lg={12} style={{textAlign:'center'}}>
            <Typography variant="body1" >Custom Fields Not Found</Typography>
        </Grid>    
      </Grid>  
    )
  }

  return (
    <Grid item md={12} lg={12} className={classes.customMarginTop}>
      <Grid item container>
        <Grid container style={{ marginBottom: "14px" }}>
          {/* <Grid item container direction="row" justifyContent="flex-end">
            <Button
              variant="contained"
              className={classes.uploadAttachmentLargeBtn}
              color="primary"
              size="medium"
              onClick={(e) => onUserDefinedFieldAdded(e)}
            >
              Add Custom Field
            </Button>
          </Grid> */}
          {userDefinedFieldPopover()}


        </Grid>
        <Grid item container style={{ overflow: "hidden" }}>
          {userDefinedFields && userDefinedFields.length > 0 
            ? showCustomField() 
            : userDefinedFieldsNotFound()}
        </Grid>
      </Grid>
      {userDefinedFields && userDefinedFields.length > 0 
       ?  
          <Grid container sm={12} style={{ marginTop: "1rem" }}>
            <Button
              variant="contained"
              color="primary"
              className="modal__button"
              onClick={() => {handleSave()}}
            >
               {props.apiRunning && <CircularProgress size={20} className={classes.circularProgress}/>}
              Save
            </Button>
          </Grid>
        : 
          <div></div>
      }

      <CustomFieldDrawer openCustomFieldDrawer={openCustomFieldDrawer} 
        setOpenCustomFieldDrawer={handleCloseCustomFieldDrawer}
        fromEditCustomFields={true}
        onItemClick={onItemClick}
        currentIndex={currentIndex}
        setSwitchFocus={setSwitchFocus}
        />
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  selectedOrganization: state.organizationInfo.selectedOrganization,
  templates: state.organizationInfo.templates,
  cd: state.organizationInfo.cd,
  customFieldList: state.organizationInfo.customFieldList,
  orgEmailList: state.organizationInfo.orgEmailList
});

const mapDispatchToProps = (dispatch) => ({
  setCDSalt: (salt) => dispatch(setCDSalt(salt)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomFields);
