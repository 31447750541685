import React, {useState, useEffect} from 'react';
import {TextField, Grid, Button, Typography, CircularProgress} from '@material-ui/core'; 
import {makeStyles} from '@material-ui/core/styles';
import RightToLeftSideDrawer from '../RightToLeftSideDrawer';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import {connect} from 'react-redux';
import { consoleToLog, capitalizeFirstLetterOfEachWord, trackGAEvent } from '../../util/AppUtil';
import { addOrgLabel, updateOrgLabel } from '../../actions/selectedOrganization';
import { addLabelToOrgApi, editOrgLabelApi } from '../../services/authService';
import {labelNameValidator} from '../../util/validator';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
    labelColors: {
        width:'45px',
        height:'45px',
        borderRadius: '50%',
        margin:'8px 9px 8px 0px',
        cursor:'pointer',
        '&:last-child' : {
            marginRight: '0px'
        }
    },
    
    checkIcon : {
        color: 'white', 
        position:'absolute', 
        left:'25%', 
        right:'25%', 
        top:'20%',
        fontWeight:'bold',
    },

    circularProgress: {
        marginLeft: 0,
        marginRight: theme.spacing(2),
        color: 'white'
      },

}))


const CreateEditLabelModal = (props) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const colors = [
        '#61be4e',
        '#f2d600',
        '#fc9f1b',
        '#eb5a46',
        '#c377e0',
        '#1379bf',
        '#2ec2e0',
        '#51e898',
        '#fa78CC',
        '#344563'
    ];

    const [selectedColor, setSelectedColor] = useState('');
    
    const [labelName, setLabelName] = useState('');
    const [loading, setLoading] = useState(false);

    const title = props.editLabel ? "Update Label" : "Create Label";
    const label_name = props.editLabel ? props.editLabel.name : ''
    const label_color = props.editLabel ? props.editLabel.color : '#fc9f1b'



    useEffect(() => {
        setLabelName(label_name);
    }, [label_name]);

    useEffect(() => {
        setSelectedColor(label_color);
    }, [label_color]);


    const setSelectedLabelColor = (e, color) => {
        setSelectedColor(color);
    }

    const onCreateEditLabelClick = (e) => {
        e.preventDefault();
        const name = labelName;
        if (!name) {
            enqueueSnackbar('Label name can not be empty!', {
                variant: 'error'
            });
            return;
        }

        const isLabelNameValid = labelNameValidator(name);
        if(!isLabelNameValid){ 
            return;
        }

        const id = props.editLabel ? props.editLabel.id : undefined; 
        const color = selectedColor;
        const organization_id = props.selectedOrganization.organization.id

        setLoading(true);

        props.editLabel ?
        editOrgLabelApi(id, color, capitalizeFirstLetterOfEachWord(name), organization_id)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response editOrgLabelApi: ", res);
                setLoading(false);
                enqueueSnackbar('Label updated!', {
                    variant: 'success'
                });
               
                props.updateOrgLabel(res);
                props.setDrawerOpen();

                trackGAEvent(props.selectedOrganization.organization.name, 'Edit Label', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
            })
            .catch((e) => {
                consoleToLog("editOrgLabelApi error", e.response);
                setLoading(false);
                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant: 'error'
                    });
                    return false;
                }
            })
            :
        addLabelToOrgApi(color, capitalizeFirstLetterOfEachWord(name), organization_id)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response addLabelToOrgApi: ", res);
                setLoading(false);
                enqueueSnackbar('Label created!', {
                    variant: 'success'
                });
                
                props.addOrgLabel(res);
                props.setDrawerOpen();

                setSelectedColor('#fc9f1b');
                setLabelName('');

                trackGAEvent(props.selectedOrganization.organization.name, 'Add Label', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
            })
            .catch((e) => {
                consoleToLog("addLabelToOrgApi error", e.response);
                setLoading(false);
                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant: 'error'
                    });
                    return false;
                }
            });
    }

    return (
        <RightToLeftSideDrawer title={title} 
            openDrawer={props.openDrawer}
            closeDrawer={props.setDrawerOpen}
            
        >
            <div className="modal__main">
                <Grid container md={12} lg={12}>
                    <Grid item lg={12}> 
                        <Typography variant="subtitle1">
                            Label Name
                        </Typography>
                        <TextField fullWidth 
                            id="label_name" 
                            variant="outlined"
                            type="text"
                            margin="dense"
                            value={labelName}
                            placeholder="Enter Label Name"
                            InputLabelProps={{style: {fontSize: 13, color: "#ccc"}}}
                            onChange={(e) => setLabelName(e.target.value)}
                        />
                        

                        <Typography variant="subtitle1" 
                            style={{marginTop:'18px'}}>
                            Select Color
                        </Typography>
                        <Grid item container>
                            { colors.map((color) => {
                                return <Grid item md={1} lg={1} key={color}
                                            className={classes.labelColors}
                                            onClick={(e) => setSelectedLabelColor(e, color)}
                                            style={{backgroundColor: `${color}`, position:'relative'}}
                                        >

                                            {
                                                selectedColor && selectedColor === color &&
                                                <CheckRoundedIcon className={classes.checkIcon}/>
                                            }

                                        </Grid> 
                                })
                            }       
                        </Grid>   

                        <div className="modal__footer">
                            <Button variant="contained" color="primary" 
                                onClick={onCreateEditLabelClick}
                                className="modal__button" >
                                {loading && <CircularProgress size={24} className={classes.circularProgress}/>}     
                                 {props.editLabel ? "Update" : "Create"}
                            </Button>
                            <Button variant="outlined" style={{
                                minWidth: '130px',
                            }} onClick={props.setDrawerOpen}>
                                Cancel
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </RightToLeftSideDrawer>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization
});

const mapDispatchToProps = (dispatch) => ({
    addOrgLabel : (label) => dispatch(addOrgLabel(label)),
    updateOrgLabel : (label) => dispatch(updateOrgLabel(label)) 
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateEditLabelModal);