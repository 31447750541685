import React, {useState, useEffect} from "react";
import ChecklistItem from "./ChecklistItem";
import { connect } from "react-redux";
import { Grid, Typography, Button, CircularProgress } from '@material-ui/core';
import CheckListDetailPage from '../../pages/modal/CheckListDetailPage';
import { consoleToLog } from "../../util/AppUtil";
import {history} from '../../routers/AppRouter';
import { useIsMount } from "../useIsMount";
import { setChecklistArray, setCurrentEmailState } from "../../actions/selectedOrganization";

const Checklist = (props) => {
  const isMount = useIsMount();

  const [openDialog, setOpenDialog] = useState(false);
  const [currentchecklistItem, setCurrentchecklistItem] = useState(undefined);
  const [tab, setTab] = useState(0);

  useEffect(() => {
    if(!isMount) {
        if(props.checklistArray && props.checklistArray.length === 0 && props.currentEmailState?.selectedChecklistId && !props.overlay.isSendEmail) {
            const updatedCheckListArr = props.checklists.map((checklistItem) => {
                if(checklistItem.id === props.currentEmailState?.selectedChecklistId) {
                    checklistItem.out_email_count = checklistItem.out_email_count + 1;
                }
                return checklistItem;
            })
            
            props.setChecklists(updatedCheckListArr);
            props.setCurrentEmailState(undefined);
        }
    }
}, [props.overlay.isSendEmail]);

useEffect(() => {
    if(!isMount) {
        if(props.checklistArray && props.checklistArray.length > 0 && !props.overlay.isSendEmail) {
            const updatedCheckListArr = props.checklists.map((checklistItem) => {
                props.checklistArray.map((checklist) => {
                    if(checklistItem.id === checklist.id) {
                        checklistItem = checklist;
                    }
                    if(checklistItem.id === props.currentEmailState?.selectedChecklistId) {
                        checklistItem.out_email_count = checklistItem.out_email_count + 1;
                    }
                    return checklistItem;
                })
                return checklistItem;
            })
            
            props.setChecklists(updatedCheckListArr);
            props.setChecklistArray([]);
        }
    }
}, [props.checklistArray, props.overlay.isSendEmail])

  var urlPath = window.location.pathname.split("/");
  var curr_slug = urlPath[urlPath.length-1];

  const handleDetailsDialogOpen=(current_slug, checklistItem)=>{
    setOpenDialog(true);
    history.push(`/checklist/${current_slug}`)
    consoleToLog("opening dialog box");
    getCurrentchecklistItem(checklistItem);
  }

  const handleDetailsDialogClose=()=>{
      setOpenDialog(false);
      history.push("/dashboard")
      consoleToLog("closing dialog box");
  }

  const getCurrentchecklistItem = (checklistItem) => {
    setCurrentchecklistItem(checklistItem);
  };

  const handleTabOpen = (current_slug, tabName) => {
    if (tabName === "Attachments") {
      setTab(1);
    }
    if (tabName === "Notes") {
      setTab(3);
    }

    if (tabName === "Audit Trail") {
      setTab(4);
    }

  // consoleToLog("******* tabName",tabName);
    setOpenDialog(true);
    history.push(`/checklist/${current_slug}`);
  };

  // onTaskStatusChanged from details
  const onTaskStatusChanged = (selectedChecklistItemId, updatedChecklist) => {
    const newCheckListStatus = props.checklists.map((checklistItem) => {
        if (checklistItem.id === selectedChecklistItemId) {
            return updatedChecklist;
        }
        else return checklistItem;
    })
    props.setChecklists(newCheckListStatus);
  }

  const onNoteAdded = (newArray, checklistId) =>{
    const newNotesArray = props.checklists.map((checklistItem) => {
        if (checklistItem.id === checklistId) {
            checklistItem.notes_count = newArray.length;
            return checklistItem;
        }else{
            return checklistItem;
        }
    });
    props.setChecklists(newNotesArray);
  }

  const onNoteDeleted = (newArray, checklistId) =>{
    const newNotesArray = props.checklists.map((checklistItem) => {
        if (checklistItem.id === checklistId) {
            checklistItem.notes_count = newArray.length;
            return checklistItem;
        }else{
            return checklistItem;
        }
    });
    props.setChecklists(newNotesArray);
  }

  const onAttachmentAdded = (updatedAttachmentsArray) => {
    const newCheckListArr = props.checklists.map((checklistItem) => {
        if (checklistItem.id === currentchecklistItem.id) {
            checklistItem.attachment_count = updatedAttachmentsArray.length;
            return checklistItem;
        }else{
            return checklistItem;
        }
    });
    props.setChecklists(newCheckListArr);
  }

  const onAttachmentDelete = (updatedDeleteAttachmentArray) =>{
    const newCheckListArr = props.checklists.map((checklistItem) => {
        if (checklistItem.id === currentchecklistItem.id) {
            checklistItem.attachment_count = updatedDeleteAttachmentArray.length;
            return checklistItem;
        }else{
            return checklistItem;
        }
    });
    props.setChecklists(newCheckListArr);
  }

  const onDueDateModified = (newChecklistArray) => {
    const newCheckListArr = props.checklists.map((checklistItem) => {
        if (checklistItem.id === newChecklistArray.id) {
            checklistItem.due_date = newChecklistArray.due_date;
            return checklistItem;
        }
        else return checklistItem;
    });
    props.setChecklists(newCheckListArr);
  }

  const onLabelModified = (updatedChecklist, selectedChecklistItemId) => {
    const newCheckListArr = props.checklists.map((checklistItem) => {
        if (checklistItem.id === selectedChecklistItemId) {
            //  checklistItem.checklist_labels_list = selectedLabels;
            return updatedChecklist;
        }
        else return checklistItem;
    });
    props.setChecklists(newCheckListArr);
}

  const CheckListNotFound = () => {
    return (
      <Grid item container>
        <Grid item lg={12} style={{ textAlign: "center" }}>
          <Typography variant="body1">No Checklist Found</Typography>
        </Grid>
      </Grid>
    );
  };

  const loadMoreLogic = () => {
    return (
        <Grid item container>
            <Grid item lg={12}> 
            {props.nextPage  && (props.checklistLoading === false && props.isMoreLoading === false) &&
                <div style={{textAlign: "center", margin:'16px 0px'}}>
                    <Button type="button"
                        color="secondary"
                        variant="contained"
                        onClick={() => {props.handleMoreLoading() }}>
                        Load More
                    </Button>
                </div>}
                { props.isMoreLoading &&
                  <CircularProgress size={35} style={{position:'relative', left:'50%', right:'50%', margin:'16px 0px'}}/>
                }
            </Grid>
        </Grid>
    )
}

  return (
    <div style={{ margin: "1rem" }}>
      {props.checklistLoading ? (
        <CircularProgress size={35} style={{position:'relative', left:'50%', right:'50%', margin:'16px 0px'}}/>
      ) : props.checklists !== undefined && props.checklists.length > 0 ? (props.checklists.map((checklist) => {
          return <ChecklistItem checklist={checklist}  
                    key={checklist.id} 
                    handleDetailsDialogOpen={handleDetailsDialogOpen}
                    getCurrentchecklistItem={getCurrentchecklistItem}
                    handleTabOpen={handleTabOpen}
                    />;
        })
      ) : (
        <CheckListNotFound />
      )}
      {loadMoreLogic()}

      {openDialog && <CheckListDetailPage  openDialog={openDialog}
                        slug={curr_slug}
                        currentchecklistItem={currentchecklistItem}
                        handleDetailsDialogClose={handleDetailsDialogClose}
                        tabValue={tab}
                        onTaskStatusChanged={onTaskStatusChanged}
                        onNoteAdded = {onNoteAdded}
                        onNoteDeleted = {onNoteDeleted}
                        onAttachmentAdded = {onAttachmentAdded}
                        onAttachmentDelete = {onAttachmentDelete}
                        onDueDateModified={onDueDateModified}
                        onLabelModified={onLabelModified}
                        handleSendEmailDialogOpen={props.handleSendEmailDialogOpen}
                        />}
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  selectedOrganization: state.organizationInfo.selectedOrganization,
  cd: state.organizationInfo.cd,

  checklistArray: state.organizationInfo.checklistArray,
  overlay: state.overlay,
  currentEmailState: state.organizationInfo.currentEmailState
});

const mapDispatchToProps = (dispatch) => ({
    setCurrentEmailState: (emailState) => dispatch(setCurrentEmailState(emailState)),
    setChecklistArray: (checklistArray) => dispatch(setChecklistArray(checklistArray)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Checklist);
