import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {Grid, Button, Typography} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  richtextContainer: {
    padding:'16px',
    '&:hover': {
      backgroundColor: '#e2e4e9',
      borderRadius:'4px'
    },
    '& .actionButtons': {
      visibility:"hidden"
    },
    '&:hover .actionButtons': {
      visibility:"visible"
    }
  },
  flatIcons : {
    fontSize:'18px', 
    display:'flex',
    alignItems:'center'
  },
  customColumn1: {
    flexBasis: "92%",
    maxWidth: "92%"
  },
  customColumn2: {
    flexBasis: "8%",
    maxWidth: "8%"
  },
  buttonClass: {
    minWidth:"23px"
  }
}))

const RichTextWidget = (props) => {
  const classes = useStyles();
  return (
    <>
      {props.text ? (
        <Grid item container alignItems="center" justifyContent="space-between"
          className={`container mt-3 ${props.editChecklist && classes.richtextContainer}`}>
        <Grid item md={props.editChecklist ? 10 : 12} className={props.editChecklist && classes.customColumn1}>
          <Typography
            dangerouslySetInnerHTML={{
              __html: props.text ? props.text : "",
            }}
          >
            
          </Typography>
        </Grid>
        
        {props.editChecklist &&
        <Grid item md={2} className={classes.customColumn2}>
          <Grid item container alignItems="center" className={props.editChecklist && 'actionButtons'}>
              <Grid item>
                  <Button className={classes.buttonClass}
                    onClick={(e) => props.handleOpenUpdateTaskWidgetDrawer(e, props.task1, props.widget)}>
                    <i className={`flaticon-edit ${classes.flatIcons}`}></i>
                  </Button>
              </Grid>

              <Grid item>
                  <Button className={classes.buttonClass}
                    onClick={() => props.deleteWidgetClick(props.task1, props.widget)}>
                    <i className={`flaticon-delete-1 ${classes.flatIcons}`}></i>
                  </Button>
              </Grid>

          </Grid>
        </Grid>}
        </Grid>
      ) : (
        <Grid item container alignItems="center" justifyContent="space-between"
          className={`container mt-3 ${props.editChecklist && classes.richtextContainer}`}>
        <Grid item md={props.editChecklist ? 10 : 12} className={props.editChecklist && classes.customColumn1}>
          <Typography variant="body2">
            Add More Detailed description...
          </Typography>
        </Grid>
        
        {props.editChecklist &&
        <Grid item md={2} className={classes.customColumn2}>
          <Grid item container alignItems="center" className={props.editChecklist && 'actionButtons'}>
              <Grid item>
                  <Button className={classes.buttonClass}
                    onClick={(e) => props.handleOpenUpdateTaskWidgetDrawer(e, props.task1, props.widget)}>
                    <i className={`flaticon-edit ${classes.flatIcons}`}></i>
                  </Button>
              </Grid>

              <Grid item>
                  <Button className={classes.buttonClass}
                    onClick={() => props.deleteWidgetClick(props.task1, props.widget)}>
                    <i className={`flaticon-delete-1 ${classes.flatIcons}`}></i>
                  </Button>
              </Grid>

          </Grid>
        </Grid>}
        </Grid>
      )}
    </>
  );
};

export default RichTextWidget;
