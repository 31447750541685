import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import { createBrowserHistory } from "history";
import MobilePrivateRoute from './MobilePrivateRoute';
import MobilePublicRoute from './MobilePublicRoute';
import MobileLoginPage from '../pages/MobileLoginPage';
import MobileDashboardPage from '../pages/MobileDashboardPage';

export const history = createBrowserHistory();

const MobileAppRouter = (props) => (
    <Router history={history}>
        <div>
            <Switch>
                <MobilePublicRoute path="/" component={MobileLoginPage} exact={true} />
                <MobilePublicRoute path="/login" component={MobileLoginPage} /> 
                <MobilePrivateRoute path="/dashboard" component={MobileDashboardPage} />
                <MobilePrivateRoute path="/group/:slug?" component={MobileDashboardPage} exact/>
                <MobilePrivateRoute path="/checklist" component={MobileDashboardPage}/>
            </Switch>
        </div>
    </Router>
)

export default MobileAppRouter;