import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {
    StyledRadio,
    StatusOption,
    ChecklistOption,
    LabelOptions,
    SchedulerOptions,
    Selectoperation
} from "./RulesOptions";
import FormHelperText from "@material-ui/core/FormHelperText";
import { ruleType , consoleToLog } from "../util/AppUtil";
import { useIsMount } from './useIsMount';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    appBar: {
        background: "#F9F9F9",
        height: "45px",
        color: "#202020",
    },
    radioParent: {
        padding: "1rem",
        border: "1px solid black",
    },
    leftContainer: {
        marginTop: "10px",
        height: "6rem",
    },
    rightContainer: {
        padding: "1rem",
        paddingLeft: "0rem",
    },
    addOrContainer: {
        marginTop: "12px",
        marginBottom: "12px",
    },
    selectParent: {
        // border: "1px solid black",
        // padding: "10px",
        padding: "4px 10px",
        marginTop: "10px",
        borderRadius: "3px",
        backgroundColor: "#f7f6f6",
    },
    selectText: {
        color: "black",
        fontSize: "15px",
    },
    selectText2: {
        color: "black",
        marginLeft: "8px",
    },
    selectContainer: {
        marginLeft: "8px",
        minWidth: "10px",
    },
    selectdisabled: {
        color: "grey",
    },
    selectdisabled1: {
        color: "grey",
        backgroundColor: "black",
    },
    circleText: {
        borderRadius: "100%",
        padding: "40px 30px",
        border: "1px solid black",
        fontSize: "14px",
        fontWeight: "500",
    },
    circleText2: {
        borderRadius: "100%",
        padding: "44px 28px",
        border: "1px solid black",
        fontSize: "14px",
        fontWeight: "500",
    },
    whenContainer: {
        margin: "0rem 3rem 00rem 3rem",
    },
    whenContainer1: {
        margin: "0rem 3rem 0rem 3rem",
    },
    schedulerDivider: {
        height: "1.5px",
        backgroundColor: "black",
        marginTop: "3.2rem",
        marginLeft: "20px",
        marginRight: "-1px",
    },
    schedulerDivider1: {
        height: "1.5px",
        backgroundColor: "black",
        marginTop: "3.4rem",
        marginLeft: "25px",
        marginRight: "-1px",
    },
    root: {
        "&:hover": {
            backgroundColor: "transparent",
        },
        fontSize: "3px",
    },
    icon: {
        borderRadius: "50%",
        width: 16,
        height: 16,
        boxShadow:
            "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
        backgroundColor: "#f5f8fa",
        backgroundImage:
            "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
        "$root.Mui-focusVisible &": {
            outline: "2px auto black",
            outlineOffset: 2,
        },
        "input:hover ~ &": {
            backgroundColor: "#ebf1f5",
        },
        "input:disabled ~ &": {
            boxShadow: "none",
            background: "rgba(206,217,224,.5)",
        },
    },
    checkedIcon: {
        backgroundColor: "black",
        backgroundImage:
            "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
        "&:before": {
            display: "block",
            width: 16,
            height: 16,
            backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
            content: '""',
        },
        "input:hover ~ &": {
            backgroundColor: "black",
        },
    },
}));

const TriggerComponent = (props) => {
    const classes = useStyles();
    const [triggerValid1, setTriggerValid1] = useState(undefined);
    const [triggerError1, setTriggerError1] = useState("");
    // const [operationValid, setOperationValid] = useState(undefined);
    const [operationError1, setOperationError1] = useState("");
    const [isAnOperation, setIsAnOperation] = useState(undefined);
    const [currentTriggerObj, setCurrentTriggerObj] = useState(props.trigger);
    const isMount = useIsMount();

    useEffect(() => {
        if (!isMount) {
            setCurrentTriggerObj(props.trigger);
        }
    }, [props.trigger])

    const getTaskStatusData = (typeOfOperation, taskId, taskStatus) => {
        var data = { type: typeOfOperation, template_task_id: taskId, status: taskStatus };
        updateTrigger({ ...currentTriggerObj, task: data });
    }

    const getLabelsData = (typeOfOperation, label_id, active) => {
        var data = { type: typeOfOperation, label_id: label_id, active: active };
        updateTrigger({ ...currentTriggerObj, label: data });
    }

    const getOperationData = (typeOfOperation) => {
        updateTrigger({ ...currentTriggerObj, type: typeOfOperation });
    }

    const getSchedulerData = (typeOfOperation, email_draft_id, status) => {
        var data = { type: typeOfOperation, email_draft_id: email_draft_id, status: status };
        updateTrigger({ ...currentTriggerObj, email_followup: data });
    }

    const getChecklistData = (duration, immediate) => {
        updateTrigger({ ...currentTriggerObj, creation: { ...currentTriggerObj.creation, immediate: immediate, duration: (duration === undefined) ? 0 : duration } })
    }

    const deleteTriggerComponent = () => {
        props.deleteTrigger(currentTriggerObj.id);
    };

    const updateTrigger = (updatedTriggerObj) => {
        props.updateTrigger(updatedTriggerObj);
    }

    const handleCurrentOption = (event) => {
        if (event.target.value === ruleType.CHECKLIST_CREATION) {
            setCurrentTriggerObj({ ...currentTriggerObj, type: event.target.value, creation: { ...currentTriggerObj.creation, immediate: true, duration: 0 }, task: undefined, label: undefined, email_followup: undefined });
            updateTrigger({ ...currentTriggerObj, type: event.target.value, creation: { ...currentTriggerObj.creation, immediate: true, duration: 0 }, task: undefined, label: undefined, email_followup: undefined });
            props.handleTriggerButtonAndClearTriggers(true);
        } else if (event.target.value === ruleType.TASK) {
            setCurrentTriggerObj({ ...currentTriggerObj, type: event.target.value, creation: undefined, task: {}, label: undefined, email_followup: undefined });
            updateTrigger({ ...currentTriggerObj, type: event.target.value, creation: undefined, task: {}, label: undefined, email_followup: undefined });
            props.handleTriggerButtonAndClearTriggers(false);
        } else if (event.target.value === ruleType.LABEL) {
            setCurrentTriggerObj({ ...currentTriggerObj, type: event.target.value, creation: undefined, task: undefined, label: {}, email_followup: undefined });
            updateTrigger({ ...currentTriggerObj, type: event.target.value, creation: undefined, task: undefined, label: {}, email_followup: undefined });
            props.handleTriggerButtonAndClearTriggers(false);
        } else if (event.target.value === ruleType.EMAIL_FOLLOWUP) {
            consoleToLog("CO", currentTriggerObj);
            setCurrentTriggerObj({ ...currentTriggerObj, type: event.target.value, creation: undefined, task: undefined, label: undefined, email_followup: {} });
            updateTrigger({ ...currentTriggerObj, type: event.target.value, creation: undefined, task: undefined, label: undefined, email_followup: {} });
            props.handleTriggerButtonAndClearTriggers(false);
        }
    };

    const validateOperation = (errorValue, isAnOperation) => {
        // setOperationValid(isValidFlag);
        setOperationError1(errorValue);
        setIsAnOperation(isAnOperation);
    }

    // Validation for triggerComponent
    const validateBeforeTrigger2 = (isValidFlag, errorValue) => {
        setTriggerError1(errorValue);
        setTriggerValid1(isValidFlag);
    }

    return (
        <Grid sm={12}>
            <Grid container sm={12}
                direction="row"
                className="triggerComponentParent"
                style={{ marginTop: "12px", marginBottom: "12px" }}>
                {currentTriggerObj.type !== ruleType.NONE &&
                    currentTriggerObj.type !== ruleType.OR &&
                    currentTriggerObj.type !== ruleType.AND &&
                    <Grid item sm={12}>
                        <RadioGroup
                            row
                            defaultValue="female"
                            aria-label="gender"
                            name="customized-radios"
                            className="radioGroup"
                            onChange={handleCurrentOption}
                            value={currentTriggerObj.type}
                        >
                            {
                                props.triggerIndex === 0 &&
                                <FormControlLabel
                                    value={ruleType.CHECKLIST_CREATION}
                                    control={<StyledRadio />}
                                    label="Checklist Creation"
                                />
                            }

                            <FormControlLabel
                                value={ruleType.TASK}
                                control={<StyledRadio />}
                                label="On Task Status"
                                disabled = {props.taskList.length === 0}
                            />
                            <FormControlLabel
                                value={ruleType.LABEL}
                                control={<StyledRadio />}
                                label="On Label"
                            />
                            <FormControlLabel
                                value={ruleType.EMAIL_FOLLOWUP}
                                control={<StyledRadio />}
                                label="On Email Followup"
                                disabled = {props.emailDraftList.length === 0}
                            />
                        </RadioGroup>
                    </Grid>
                }
                <Grid item container sm={12} direction="row">
                    {/*Condtionally render*/}
                    {/* add checklist option on condition trigger creation */}
                    {/* {consoleToLog("Trigger array in triggerComponents", props.triggerArray)} */}
                    <Grid item container sm={11}>
                        {currentTriggerObj.type === ruleType.CHECKLIST_CREATION &&
                            <ChecklistOption
                                taskList={props.taskList}
                                getChecklistData={getChecklistData}
                                currentTriggerObj={currentTriggerObj}
                                updateTrigger={updateTrigger}
                                validateBeforeTrigger={validateBeforeTrigger2}
                            />}
                        {currentTriggerObj.type === ruleType.TASK &&
                            <StatusOption validateBeforeTrigger={validateBeforeTrigger2}
                                taskList={props.taskList}
                                getTaskStatusData={getTaskStatusData}
                                currentTriggerObj={currentTriggerObj}
                                updateTrigger={updateTrigger}
                                fromChecklistDetails={props.fromChecklistDetails}
                            />}
                        {currentTriggerObj.type === ruleType.LABEL &&
                            <LabelOptions validateBeforeTrigger={validateBeforeTrigger2}
                                labelList={props.labelList}
                                getLabelsData={getLabelsData}
                                currentTriggerObj={currentTriggerObj}
                                updateTrigger={updateTrigger}
                            />}
                        {currentTriggerObj.type === ruleType.EMAIL_FOLLOWUP &&
                            <SchedulerOptions validateBeforeTrigger={validateBeforeTrigger2}
                                getSchedulerData={getSchedulerData}
                                currentTriggerObj={currentTriggerObj}
                                updateTrigger={updateTrigger}
                                emailDraftList={props.emailDraftList}
                            />}
                    </Grid>
                    <Grid item container sm={1} justifyContent="center" alignItems="center">
                        {props.triggerArray.length > 1 &&
                            <i
                                className="flaticon-delete-1"
                                style={{
                                    fontSize: "18px",
                                    margin: "0 15px",
                                    cursor: "pointer",
                                    marginTop: "10px"
                                }}
                                title="Delete"
                                onClick={deleteTriggerComponent}
                            ></i>
                        }
                    </Grid>
                </Grid>
                {triggerValid1 === false && <FormHelperText style={{ color: "red" }}>{triggerError1}</FormHelperText>}
            </Grid>
            <Grid container sm={12}
                direction="row"
                style={{ marginTop: "-8px" }}
                alignItems="center"
                justifyContent="flex-end"
            >
                {(currentTriggerObj.type === ruleType.NONE ||
                    currentTriggerObj.type === ruleType.AND ||
                    currentTriggerObj.type === ruleType.OR) &&
                    <Grid item
                        sm={8}>
                        <Selectoperation validateOperation={validateOperation}
                            getOperationData={getOperationData}
                            currentTriggerObj={currentTriggerObj} />
                        {isAnOperation && <FormHelperText style={{ color: "red", marginLeft: "-1rem" }}> {operationError1} </FormHelperText>}
                    </Grid>
                }
            </Grid>
        </Grid>
    );
};


export default TriggerComponent