import React, {useState, useEffect} from 'react';
import { Grid, Tooltip, TextField, Typography, FormControlLabel, Checkbox, Button, CircularProgress  } from '@material-ui/core';
import { setSearchMemberFilter } from '../../actions/filters';
import {connect} from 'react-redux';
import useOuterClick from '../checklist/useOuterClick';
import {useIsMount} from '../useIsMount';

const BulkAssignMembers = (props) => {

    const [selectedMember, setSelectedMember] = useState(undefined);
    const [isActiveAdd, setIsActiveAdd] = useState(false);
    const isMount = useIsMount();

    useEffect(() => {
        props.setSearchMemberFilter('');
    }, []);

    useEffect(() => {
        if(isMount) {

        } else {
                if(!props.bulkLoading) {
                    setSelectedMember(undefined);
                }
        }

    }, [isActiveAdd])



    const handleTextFieldChange = (e) => {
        const name = e.target.value;
        props.setSearchMemberFilter(name);
    }


    const onMemberChecked = (e, checkedMember) => {
        setSelectedMember(checkedMember)
        props.onAddBulkMembers(checkedMember);
    }

    const onClickAdd = (e) => {
        setIsActiveAdd(!isActiveAdd);
    }

    const innerRef = useOuterClick(ev => {  
        //consoleToLog("outside click11"); 
        setIsActiveAdd(false);
    });

    return (
        <Grid item container lg={2}
            ref={innerRef}
        >
            <Grid item style={{position:"relative", marginRight: "1px"}}>
                {(!props.disabledAssignChecklistLabels) && <Tooltip title="Add Member" arrow >
                    <Button aria-label="delete"
                        disabled={props.bulkLoading && selectedMember === undefined}
                        className="checklistBulk__icons"
                        onClick={(e) => onClickAdd(e)}
                    >
                        <i className="flaticon-add-user"></i>
                    </Button>
                </Tooltip>}  
                {isActiveAdd &&
                    <Grid item  
                        className={`menu2 ${isActiveAdd ? "active bulkmenuStyles" : "inactive"}`}
                        //style={{padding: "4px 10px"}}
                    >
                        <TextField id="member_input" 
                            label="Search Members" 
                            style={{ marginBottom: "8px"}}
                            //value={textFieldValue} 
                            onChange={handleTextFieldChange}
                            />
                        <Typography variant="button" gutterBottom >
                            Members {props.bulkLoading && <CircularProgress size={15} 
                                                                color="primary" 
                                                                style={{marginLeft:'10px'}}/> }
                        </Typography>
                        {props.members && props.members.length > 0 && props.members.map((member, index)=>{
                            const fullName = member.user.firstname + ' ' + member.user.lastname;
                            return(
                                <FormControlLabel
                                    key={member.id+"_"+index}
                                    control={
                                    <Checkbox
                                        disabled={props.bulkLoading && selectedMember !== member.user.id}
                                        checked={selectedMember === member.user.id}
                                        onChange={(e) => onMemberChecked(e, member.user.id)}
                                        name="checkedB"
                                        color="primary"
                                    />
                                    }
                                    style={{ display:"block", whiteSpace: "nowrap", maxWidth: "auto", overflow: "hidden", textOverflow: "ellipsis" }}
                                    label={fullName}
                              />
                            )
                        })}
                    </Grid>
                }   
            </Grid>
            
        </Grid>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    members: state.organizationInfo.members.filter((member) => {
        const fullName = member.user.firstname + ' ' + member.user.lastname;
        //consoleToLog("****fullnam: ", fullName);
        return fullName.toLowerCase().includes(
            state.filters.searchMemberFilter.toLowerCase()
        )
    }),
});

const mapDispatchToProps = (dispatch) => ({
    setSearchMemberFilter: (name) => dispatch(setSearchMemberFilter(name))
})

export default connect(mapStateToProps, mapDispatchToProps)(BulkAssignMembers);