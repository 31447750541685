import React, {useState, useEffect, createRef, useRef} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Typography,TextField, Button, ClickAwayListener, InputAdornment, CircularProgress, Tooltip} from '@material-ui/core';
import TextFormatIcon from '@material-ui/icons/TextFormat';
import GetAppIcon from '@material-ui/icons/GetApp';
import PublishIcon from '@material-ui/icons/Publish';
import {addTaskWidgetApi, editTaskWidgetApi, changeTaskWidgetOrderApi, deleteTaskWidgetApi, editTemplateTaskApi, getTemplateAttachmentUploadURLApi} from '../services/authService';
import {connect} from 'react-redux';
import {consoleToLog, updateTaskWidgets, updatePredefinedTaskWidgets, updateTemplateTaskList, updatePredefinedTemplateTaskList} from '../util/AppUtil';
import {useSnackbar} from 'notistack';
import {useIsMount} from './useIsMount';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import draftToHtml from 'draftjs-to-html';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import S3FileUpload from "react-s3";
import OpenWithOutlinedIcon from '@material-ui/icons/OpenWithOutlined';
import htmlToDraft from 'html-to-draftjs';
import SubdirectoryArrowLeftRoundedIcon from '@material-ui/icons/SubdirectoryArrowLeftRounded';
import axios from 'axios';
import {generateRandomString} from '../util/AppUtil';

const useStyles = makeStyles((theme) => ({
    widgetStyle : {
        height: '70vh',
        borderRight: '1px solid rgba(0,0,0,0.1)',
        padding: '24px 16px',
        overflow: 'auto',
        scrollbarWidth: 'none',
        "&::-webkit-scrollbar": {
            display: "none"
        }
    },
    buttonStyles : {
        marginTop:'24px'
    },
    buttonItems : {
        display:'block',
        width: '140px',
        padding: '15px 0px',
        background: '#E5EFFF'
    },
    buttonIconStyles : {
        display:'flex', 
        flexDirection:'column', 
        alignItems:'center'
    },
    noBorder: {
        border: "none",
      },
    richWidgetStyle : {
        height:'auto', 
        borderRadius:'5px',
        background:'#eaecf0',
        color: '#455571',
        padding:'8px',
        cursor: 'pointer',
        minHeight:'70px',
        '&:hover' : {
            background:'#e2e4e9',
        }
    },
    widgetIconStyles: {
        display:'flex', 
        alignItems:'center',
        margin:'4px auto',
        position:'absolute',
        left:'92%',
        transform: 'translateY(-40%)',
    },
    hoverIcons : {
        marginTop:'1px',
        color:'#ccc', 
        fontSize:'16px',
        padding:'0px 8px',
    },
    widgetList : {
        position:'relative',
        marginBottom: '24px',
        '& .taskIcons' : {
            visibility: 'hidden'
        },
        '&:hover .taskIcons' : {
            visibility: 'visible',
            cursor:'pointer'
        }
    },

}))

const TaskWidgets = (props) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    const isMount = useIsMount();

    const myDivToFocus = createRef(null);
    const editorReference = useRef();

    const [taskwidgets, setTaskWidgets] = useState([]);
    const [widgetId, setWidgetId] = useState(undefined);
    const [widgetObj, setWidgetObj] = useState(undefined);
    const [uploadDownloadWidgetName, setUploadDownloadWidgetName] = useState('');
    //const [filePath, setFilePath] = useState(undefined);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [canScrollToNewlyCreatedWidget, setCanScrollToNewlyCreatedWidget] = useState(false);
    const [loading, setLoading] = useState(false);

    const taskId = props.taskObj ? props.taskObj.id : undefined;
    const templateString = props.fromPredefinedTemplate ? 'predefined_template' : 'template'
    consoleToLog('123456', taskId);

    let toolbarConfig = {
        inline: {
            options: ['bold', 'italic', 'underline', 'strikethrough']
        },
        /* blockType: {
            options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code']
        }, */
        list: {
            options: ['unordered', 'ordered']
        },
        textAlign: {
            options: ['left', 'center', 'right', 'justify']
        },
        link: { options: ['link'] },
        options: ['inline', 'link',/* 'blockType', */ 'fontFamily', 'fontSize', 'colorPicker', 'list', 'textAlign']
    };

    let task_widget = props.taskObj?.widget_json;

    consoleToLog('Newly Selected Task', taskwidgets);

    useEffect(() => {
            setTaskWidgets(task_widget);
    }, [props.taskObj]);

    // const outerRef = useOuterClick((ev) => {
    //     //consoleToLog("outside click11");
    //     if(widgetObj.id === widgetId) {
    //      onEditWidgetApi(widgetObj);
    //     }
    //   });

    useEffect(() => {
        if(!isMount) {
            if (myDivToFocus && myDivToFocus.current && canScrollToNewlyCreatedWidget) {
                onScrollToNewlyCreatedTask();
            }   
        }  
    }, [myDivToFocus]);

    const onScrollToNewlyCreatedTask = () => {
        setCanScrollToNewlyCreatedWidget(false);
            myDivToFocus.current.scrollIntoView({
                behavior: 'smooth', 
            })
    }

    useEffect(() => {
        if(!isMount) {
            if(widgetObj?.id === widgetId) {
                if(widgetObj?.type === 'upload') {
                    if(!widgetObj.json_data.desc) {
                        setUploadDownloadWidgetName('Upload Document')
                    } else  {
                        setUploadDownloadWidgetName(widgetObj.json_data.desc);
                    }
                } else if(widgetObj?.type === 'download') {
                    if(!widgetObj.json_data.desc) {
                        setUploadDownloadWidgetName('Download File')
                    } else  {
                        setUploadDownloadWidgetName(widgetObj.json_data.desc);
                    } 
                } else if(widgetObj?.type === 'rich_text') {
                    editorReference.current.focusEditor();
                    prefillRichTextEditor();
                }
            }  
        }
    }, [widgetObj, editorReference]);


    const onEditorStateChange = (editorState) => {
        setEditorState(editorState)
    }

    const prefillRichTextEditor = () => {
        const blocksFromHtml = htmlToDraft(widgetObj.json_data.desc || '<p></p>');
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const editorState = EditorState.createWithContent(contentState);
        const setFocusOnEnd = EditorState.moveFocusToEnd(editorState);

        setEditorState(setFocusOnEnd);

    }

    const getOrderNumber = () => {
        const orderNumber = taskwidgets.length > 0 ? Math.max(...taskwidgets?.map((widget) => widget.order_number)) : 0;
        //console.log('order number******', orderNumber);
        return orderNumber  + 1
    }
    // useEffect(() => {
    //     if(!isMount) {
    //     getOrderNumber();
    //     }
    // }, [taskwidgets])

    const createObject = (widget_type) => {
        let obj = {};
        if(widget_type === 'download') {
            return obj = {id: generateRandomString(8, 'a#'), type:widget_type, is_editable: false, order_number: getOrderNumber(), json_data:{desc: '', file_path: ''}}
        } else if(widget_type === 'upload') {
            return obj = {id: generateRandomString(8, 'a#'), type:widget_type, is_editable: true, order_number: getOrderNumber(), json_data:{desc: ''}}

        } else {
            return obj = {id: generateRandomString(8, 'a#'), type:widget_type, is_editable: false, order_number: getOrderNumber(), json_data:{desc: ''}}
        }

    }

    const callAddWigetApi = (widget_type, updateTaskWidget) => {
        const organization_id = props.selectedOrganization.organization.id;
        const template_id = props.templateId;
        const task_id = taskId;
        const type = widget_type;
        const title = props.taskObj?.title;
        const heading = props.taskObj?.heading;
        
        const new_obj = updateTaskWidget && updateTaskWidget.length === 0 && createObject(widget_type);
        const widget_json = updateTaskWidget && updateTaskWidget.length === 0 ? [...taskwidgets, new_obj] : updateTaskWidget;
        //console.log('new widget json***********', widget_json);

        editTemplateTaskApi(organization_id, templateString, template_id, task_id, title, heading, widget_json)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response editTemplateTaskApi: ", res);

                enqueueSnackbar(res.message, {
                    variant:'success'
                });

                let updateTaskObj = {...props.taskObj, 
                    widget_json: widget_json
                }
                //console.log('newObject*************', updateTaskObj);
                props.setTaskObj(updateTaskObj);
                let updateTasks = props.fromPredefinedTemplate ? res.predefined_template_tasks : res.template_tasks;
                //console.log('updated tasks', updateTasks);
                props.setTemplateTask(updateTasks);

                if(updateTaskWidget && updateTaskWidget.length === 0) {
                    setCanScrollToNewlyCreatedWidget(true);
                } else {
                    setTaskWidgets(updateTaskWidget);
                    setWidgetId(undefined);
                    setWidgetObj(undefined);
                    setUploadDownloadWidgetName('');
                    setLoading(false);
                }
            })
            .catch((e) => {
                consoleToLog('Error editTemplateTaskApi: ', e);
                setLoading(false);
                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, ({
                        variant: 'error'
                    }));
                    return false;
                }
            })
    }

    const sendAppropriateTitletoEditWidgetApi = (widget) => {
        let title = '';
        if(widget.type === 'rich_text') {
            if(editorState.getCurrentContent().hasText()) {
               return title = draftToHtml(convertToRaw(editorState.getCurrentContent()));
            } else {
                enqueueSnackbar('Text body can not be empty!', {
                    variant:'error'
                });
                return;
            }
        } else if(widget.type === 'upload' || widget.type === 'download') {
            return title = uploadDownloadWidgetName
        }

        consoleToLog('updated Title', title)
        
    }

    const onFileSelected = (e, widget) => {
        const {target: { files }} = e;
        consoleToLog('selectedFile widget', widget);
        //const task_id = props.fromPredefinedTemplate ? widget.predefined_template_task_id :widget.template_task_id;
        const task_id = taskId;
        const tempString =  props.fromPredefinedTemplate ? 'predefined_template' : 'template';
        const type = 'template_widget'; 
        const organization_id = props.selectedOrganization.organization.id;


    const file_name = e.target.files[0].name;
    const file_type = e.target.files[0].type;
    const file = e.target.files[0];

    setLoading(true);
    getTemplateAttachmentUploadURLApi(props.templateId, file_name, type, tempString, organization_id)
    .then((response) => {
        const res = response.data;
        consoleToLog('Response getUploadUrlForChecklistAttachmentApi: ', res);

        // Put the fileType in the headers for the upload
            const options = {
                headers: {
                "Content-Type": file_type,
                }
            };
            axios.defaults.headers.common = {};
            axios.put(res.upload_signed_url, file, options)
            .then(result => {
                consoleToLog('signed Request s3', result);
                //console.log("Response from s3");
                onEditWidgetApi(widget, res.url);
                setLoading(false);
            })
            .catch(error => {
                consoleToLog('error: ', error.response);
                setLoading(false);
            })

        })
        .catch((err) => {
            consoleToLog("File Upload Error: ", err);
            setLoading(false);
        });
    // S3FileUpload.uploadFile(e.target.files[0], config)
    // .then((data) => {
    //     consoleToLog("uploadFile", data);
    //     onEditWidgetApi(widget, data.key)
    //   })
    //   .catch((err) => consoleToLog("uploadFile error", err));
    }

    const onEditWidgetApi = (widget, filePath) => {
        const file_path = filePath ? filePath : widget.json_data.file_path?.path;
        consoleToLog('File Path ', filePath);
        let title = sendAppropriateTitletoEditWidgetApi(widget)
        consoleToLog('title', title);

        const updateTaskWidget = taskwidgets.map((widgetObj) => {
                if(widgetObj.id === widget.id) {
                    const obj = {attachment_id : widget.json_data?.attachment_id ? widget.json_data?.attachment_id : '', path: file_path ? file_path : ''}
                    widgetObj = widget.type === 'download' ? {...widget, json_data:{desc : title, file_path: obj }}  : {...widget, json_data:{desc : title}}
                }
                //console.log('*******************', widgetObj);
                return widgetObj;
        });
        //console.log('update widget Array***********', updateTaskWidget);
        callAddWigetApi(widget.type, updateTaskWidget)
    }

    const showUploadOrDownloadFileLabel = (widget) => {
        if(widget.type === 'upload') {
            return 'No File Chosen'
        } 

        if(widget.type === 'download') {
                return <div>
                        {
                            (loading && widget.id === widgetObj?.id)  ?
                            <CircularProgress size={24} style={{marginLeft:'20px'}}/>
                            :   
                            widget.json_data.file_path.path ? widget.json_data.file_path.path.replace(/^.*[\\\/]/, "") : ''
                        } 
                    </div>
        }
    }

    const showUploadDownloadTitle = (widget) => {
        if(widget.id === widgetId) {
            if(widget.type === 'upload' || widget.type === 'download') {
                return uploadDownloadWidgetName;
            }
        } else {
            if(widget.type === 'upload' && !widget.json_data.desc) {
                return 'Upload Documents'
            } else if (widget.type === 'download' && !widget.json_data.desc) {
                return 'Download File'
            } else {
                return widget.json_data.desc
            }
        }
    }

    const reorder = (list, startIndex, endIndex) => {
        const result = list;
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
      
        return result;
      };
    

    const onDragEnd = (result) => {
        let orderNumber = result.destination.index;
        let widget_id = result.draggableId;

        const old_order_number = result.source.index + 1;
        const new_order_number = result.destination.index + 1;

        //console.log('new order & old order', old_order_number, new_order_number);

        // dropped outside the list
        if (!result.destination) {
            return;
          }
      
          const items = reorder(
            taskwidgets,
            result.source.index,
            result.destination.index
          );
          //console.log('items*********', items);  

          const updatedArray = items;

          let arr1, arr2 = [];
          if(old_order_number < new_order_number) {
            arr1 = updatedArray.slice(result.source.index, result.destination.index);
            //console.log('new array1*******', items.slice(new_order_number, old_order_number));
          } 

          if(old_order_number > new_order_number) {
            arr2 = updatedArray.slice(new_order_number, old_order_number);
            //console.log('new array2*******', arr2);
          } 
          
          

          const updatedTaskWidgetArray = updatedArray.map((item) => {
                if(old_order_number < new_order_number) {
                    arr1.map((itemObj) => {
                        if(itemObj.id === item.id) {
                            item.order_number = item.order_number - 1
                        }
                        return item;
                    })
                }
                if(old_order_number > new_order_number) {
                    arr2.map((itemObj) => {
                        if(itemObj.id === item.id) {
                            item.order_number = item.order_number + 1
                        }
                        return item;
                    })
                }
                if(item.id === widget_id) {
                    item.order_number = orderNumber + 1;
                }
                return item;
            })

          console.log('updated Array**********', updatedTaskWidgetArray);

          callAddWigetApi('', updatedTaskWidgetArray);
    }

    const onDeleteTaskWidgetClick = (e, widget) => {
        if(window.confirm("Are you sure you want to delete this widget?")) {

            const organization_id = props.selectedOrganization.organization.id;
            const template_id = props.templateId;
            const task_id = taskId;
            const widget_id = widget.id;
            const title = props.taskObj?.title;
            const heading = props.taskObj?.heading;

            const updateArray = taskwidgets;
            const arr =  updateArray.slice(widget.order_number);

            const updateTaskWidgets = taskwidgets.map((widget) => {
                arr.map((widgetObj) => {
                    if(widget.id === widgetObj.id) {
                        widget.order_number = widget.order_number - 1; 
                    }
                    return widget;
                })
                return widget;
            }).filter((widget) => widget.id !== widget_id);

            const widget_json = updateTaskWidgets;

            editTemplateTaskApi(organization_id, templateString, template_id, task_id, title, heading, widget_json)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response editTemplateTaskApi: ", res);

                enqueueSnackbar(res.message, {
                    variant:'success'
                });

                let updateTaskObj = {...props.taskObj, 
                    widget_json: widget_json
                }
                props.setTaskObj(updateTaskObj);
                let updateTasks = props.fromPredefinedTemplate ? res.predefined_template_tasks : res.template_tasks;
                props.setTemplateTask(updateTasks);

                setTaskWidgets(updateTaskWidgets);
            })
            .catch((e) => {
                consoleToLog('Error editTemplateTaskApi: ', e.response);
                setLoading(false);
                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, ({
                        variant: 'error'
                    }));
                    return false;
                }
            })
        } 
    }    

    const showTaskWidgets = (widget, provided) => {
            if (props.taskObj?.id === taskId) {
            return (<Grid item md={12} lg={12}
                        className={classes.widgetList} 
                        ref={myDivToFocus}>
                    {     
                        (widget.type === 'rich_text') &&
                        <ClickAwayListener onClickAway={(e) => {
                            if(widget.id === widgetId) {
                                onEditWidgetApi(widget);
                            }
                        }}>
                        <Grid item md={12} lg={12} >
                            {(widget.id !== widgetId) ?
                                <Grid item
                                    onClick={(e) => {
                                        setWidgetId(widget.id);
                                        setWidgetObj(widget);
                                    }} 
                                    className={classes.richWidgetStyle} >
                                    {         
                                        (widget.json_data.desc !== '') ?
                                            <Typography variant='body1'  
                                                dangerouslySetInnerHTML={{
                                                __html: widget.json_data.desc,
                                              }}>
                                            </Typography>
                                            :
                                            <Typography variant='body1' >
                                                Add more detailed description...
                                            </Typography>
                                    }        
                                </Grid>
                                :
                                <>
                                    <Editor
                                        ref={editorReference}
                                        editorStyle={{ 
                                            border: '1px solid lightgray',
                                            minHeight: '200px',
                                            scrollbarWidth: 'none',
                                            lineHeight:1.5,
                                            padding: '12px',
                                            "&::-webkit-scrollbar": {
                                                display: "none"
                                            }
                                        }}
                                        editorState={editorState}
                                        onEditorStateChange={onEditorStateChange}
                                        toolbar={toolbarConfig}
                                        handlePastedText={() => false}
                                        //readOnly={}
                                    /> 
                                    <Grid item container style={{padding:'8px 0px'}}>
                                        <Button variant="contained" color="primary" 
                                            onClick={() => onEditWidgetApi(widget)}
                                            style={{minWidth:'30px', marginRight:'8px'}}>
                                            Save  
                                        </Button>
                                    
                                        <Button color="primary"
                                            onClick={() => {
                                                //setEditorState(EditorState.createEmpty());
                                                setWidgetId(undefined);
                                            }} 
                                            style={{minWidth:'30px'}}>
                                            <ClearOutlinedIcon />  
                                        </Button>
                                    </Grid>   
                                </>     

                                
                            }   
                        </Grid> 
                        </ClickAwayListener>  
                    } 

                    {
                        (widget.type === 'upload' || widget.type === 'download') && 
                        
                        <Grid item md={12} lg={12} style={{background:"#f9f9f9", padding:'12px', borderRadius:'4px'}}>
                                <Grid item container direction="column">
                                     <ClickAwayListener onClickAway={(e) => {
                                            consoleToLog('outside click detected');
                                            if(widget.id === widgetId) {
                                                onEditWidgetApi(widget);
                                            }
                                    }}> 
                                        <Grid item md={12} lg={12} style={{display:'block'}}> 
                                            {(widget.id === widgetId) ? 
                                                (<TextField
                                                fullWidth
                                                autoFocus
                                                margin="dense"
                                                style={{marginBottom:'8px', background:'#fff'}}
                                                variant="outlined"
                                                type="text"
                                                value={uploadDownloadWidgetName}
                                                inputProps={{ style: { 
                                                    cursor:'text',
                                                    color:'#212121',
                                                    lineHeight:'18px', 
                                                    padding:'8px 4px' } }} 
                                                inputRef={(input) => {
                                                    if (input !== null) {
                                                    input.focus();
                                                    }
                                                }}
                                                onKeyPress={(ev) => {
                                                    //consoleToLog(`Pressed keyCode ${ev.key}`);
                                                    if (ev.key === 'Enter') {
                                                        //setIsTextFieldFocused(false);
                                                        ev.preventDefault();
                                                        onEditWidgetApi(widget)
                                                    }
                                                }}
                                                onChange={(e) => setUploadDownloadWidgetName(e.target.value)}  
                                                InputProps={{
                                                    endAdornment: (
                                                      <InputAdornment position="end" style={{color:"#909090"}}>
                                                          press 
                                                        <SubdirectoryArrowLeftRoundedIcon fontSize="small"/>
                                                        enter to save
                                                      </InputAdornment>
                                                    )
                                                  }} 
                                            />) : (
                                                <TextField
                                                fullWidth
                                                autoFocus
                                                margin="dense"
                                                variant="outlined"
                                                disabled={widgetId !== widget.id}
                                                style={{marginBottom:'-1px', marginTop:'-6px'}}
                                                type="text"
                                                InputProps={{
                                                    classes:{notchedOutline: classes.noBorder}
                                                    }}
                                                defaultValue={showUploadDownloadTitle(widget)}
                                                inputProps={{ style: { 
                                                    cursor:'text',
                                                    color:'#212121',
                                                    lineHeight:'18px', 
                                                    padding:'8px 4px' } }} 
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setWidgetId(widget.id);
                                                    setWidgetObj(widget);
                                                }}   
                                            />
                                            )}
                                        </Grid>
                                    </ClickAwayListener>  
                                        
                                    <Grid item container spacing={1} alignItems="center">
                                        <Grid item>
                                            <label>
                                                    <>
                                                        <input type="file" style={{display: "none"}} 
                                                        id={widget.id.toString()} 
                                                        onChange={(e) => { 
                                                                onFileSelected(e, widget)
                                                                setWidgetObj(widget)
                                                        }}/>
                                                        <Button variant="contained" color="primary" 
                                                            onClick={() => document.getElementById(`${widget.id.toString()}`).click()}
                                                            style={{minWidth:'20px', display:'block'}}>
                                                            Select file to download
                                                        </Button>  
                                                    </>
                                                    {/* <Button variant="contained" color="primary" 
                                                        style={{minWidth:'20px', display:'block'}}>
                                                        Upload
                                                    </Button> */}
                                            </label>
                                        </Grid>  

                                        <Grid item>
                                            <Typography variant="body2" color="primary">
                                            {showUploadOrDownloadFileLabel(widget)}
                                            </Typography>  
                                        </Grid>  
                                    </Grid>        
                            </Grid>   
                        </Grid>
                    } 

                    {(widgetId !== widget.id) &&   
                    <div className={classes.widgetIconStyles}
                     style={{ top: widget.type === 'rich_text' ? '10%' : '40%'}} >
                        {(widget.type === 'upload' || widget.type === 'download') &&
                        <Grid item className="taskIcons" {...provided.dragHandleProps}>
                            <Tooltip title="Edit" arrow>
                                <i className={`flaticon-edit ${classes.hoverIcons}`}
                                    style={{padding:0, color:'grey'}}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setWidgetId(widget.id);
                                        setWidgetObj(widget);
                                    }} 
                                />
                            </Tooltip>    
                        </Grid>}

                        <Grid item className="taskIcons" {...provided.dragHandleProps}>
                            <Tooltip title="Move" arrow>
                                <OpenWithOutlinedIcon
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                                className={classes.hoverIcons}      
                                />
                            </Tooltip>    
                        </Grid>

                        <Grid item className="taskIcons">
                            <Tooltip title="Delete" arrow>
                                <i className="flaticon-delete-1" 
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        onDeleteTaskWidgetClick(e, widget);
                                    }}
                                    style={{fontSize:'14px'}}/>
                            </Tooltip>        
                        </Grid>
                    </div>}

                    </Grid>)
            }

    }

    return (
        <Grid item md={9} lg={9}>
            <Grid item container>
                <Grid item md={10} lg={10} className={classes.widgetStyle} 
                    style={{height: !props.is_orgEmail_verified ? 'calc(70vh - 40px)' : '70vh'}}>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable" direction="vertical">
                            {(provided) => (
                                <div item container direction="column" 
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}>
                                    {   
                                        taskwidgets && taskwidgets.length > 0 && 
                                        taskwidgets.map((widget, index) => {
                                            const widget_id = widget?.template_task_id || widget?.predefined_template_task_id
                                           return <Draggable key={widget.id} 
                                                draggableId={widget.id.toString()} 
                                                index={index}>
                                                    {(provided) => (
                                                        <div {...provided.draggableProps}
                                                       // {...provided.dragHandleProps}
                                                            onClick={(e) => e.stopPropagation()}
                                                            ref={provided.innerRef}>
                                                            {showTaskWidgets(widget, provided)}
                                                        </div>    
                                                    )
                                                        
                                                    }
                                             </Draggable>
                                        })
                                    }
                                    {provided.placeholder}
                                </div>
                                )
                            }
                        </Droppable>

                    </DragDropContext>    
                </Grid>

                <Grid item md={2} lg={2} className={classes.buttonStyles}>
                    <Grid item container
                        justifyContent='center' 
                        alignItems="center"
                        direction="column" 
                        spacing={2}>
                        <Grid item>
                            <Button variant="contained"
                                disabled={(props.taskObj && props.taskObj.heading)}
                                disableElevation
                                onClick={(e) => {
                                    e.stopPropagation();
                                    callAddWigetApi('rich_text', []);
                                }}
                                className={classes.buttonItems}>
                                <div className={classes.buttonIconStyles}>
                                    <TextFormatIcon />
                                    Rich Text
                                </div>
                            </Button>
                            
                        </Grid>

                        {/* <Grid item>
                            <Button variant="contained"
                                disableElevation  
                                disabled={(props.taskObj && props.taskObj.heading)}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    callAddWigetApi('upload', []);
                                    }
                                }
                                className={classes.buttonItems}>
                                <div className={classes.buttonIconStyles}>
                                    <PublishIcon />
                                    Upload
                                </div>
                            </Button>
                        </Grid> */}

                        <Grid item>
                            <Button variant="contained" 
                                disableElevation 
                                onClick={(e) => {
                                    e.stopPropagation();
                                    callAddWigetApi('download', []);
                                    }
                                }
                                disabled={(props.taskObj && props.taskObj.heading)}
                                className={classes.buttonItems}>
                                    <div className={classes.buttonIconStyles}>
                                        <GetAppIcon />
                                        Download
                                    </div>
                            </Button>
                        </Grid>    

                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization
})

export default connect(mapStateToProps)(TaskWidgets);