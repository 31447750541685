import { organizationListApi, organizationDetailsApi, organizationTemplateListApi, checkTemplateRulesApi } from '../services/authService';
import { logoutOrgs, setOrganizations } from './organization';
import { consoleToLog } from '../util/AppUtil';
import {setLoading} from '../actions/loading';
import { logout } from './auth';
import { logoutFilters } from './filters';
import { history } from '../routers/AppRouter';
import { firebase } from '../firebase/firebase';
import {getInvoiceAccountsListApi} from '../services/clientService';
import {getOrgSupportEmailsApi, getEmailDraftListApi} from '../services/genieService';
import { udfTypes } from '../util/userDefinedFieldsUtil';
import {shouldDisable, AccessLevel} from "../util/AppUtil";
import {getInvoiceAccountsEntityListApi} from '../services/invoiceService';

/**logout */
export const logoutSelectedOrg = () => ({
    type: 'LOGOUT_SELECTED_ORG'
});

 
/** Selected Organization **/
//set selected organization 
export const setSelectedOrg = (organization) => ({
    type: 'SET_SELECTED_ORG',
    organization
});

//update selected organization
export const updateSelectedOrg = (organization) => ({
    type: 'UPDATE_SELECTED_ORG',
    organization
});


export const getOrganizationList = (selectedOrganization, start_date, end_date, companiesFilter, selectedMembersFilter, selectedTemplate, scope) => {
    return (dispatch) => {
        organizationListApi()
            .then((response) => {
                const res = response.data;
                consoleToLog("Response OrganizationListApi ", res);

                dispatch(setOrganizations(res));
                if (Object.keys(selectedOrganization).length === 0) {
                    dispatch(setSelectedOrg(res[0]));
                    dispatch(setSelectedDrawerState(SelectedDrawerState.SHOW_ALL_CHECKLIST));
                }    

                let organization_id;
                if (Object.keys(selectedOrganization).length === 0) {
                    organization_id = res[0].organization.id
                } else {//if selected org found, get its id
                    organization_id = selectedOrganization.organization.id;
                         //if found update its data
                    const updateSelectedOrg = res.find((org) => org.organization.id === organization_id);
                        if (updateSelectedOrg) {
                            dispatch(setSelectedOrg(updateSelectedOrg));
                    }
                }
                //fetch members & labels and store in redux-> orgInfo-> members
                dispatch(fetchOrgDetails(organization_id, start_date, end_date, companiesFilter, selectedMembersFilter, selectedTemplate, scope));
            })
            .catch((e) => {
                consoleToLog('Error OrganizationListApi1', e);
                dispatch(setLoading(false));
                // const { status } = e.response;
                // consoleToLog('Error code', status);
                // //if unauthorized error comes we will clear all data from redux and redirect to login
                // if (status === 401) {
                //     dispatch(logout());
                //     dispatch(logoutOrgs());
                //     dispatch(logoutSelectedOrg());
                //     dispatch(logoutFilters());
                //     history.push('/login');
                //     firebase.auth().signOut();
                // }

                if (e.response.data && e.response.data.message) {
                  //  toast.error(e.response.data.message);
                    return false;
                }
            });
    };
}


//set members list of selected org
export const setOrgMembers = (members) => ({
    type: 'SET_ORG_MEMBERS',
    members
});

//set members list of selected org
export const setOrgLabels = (labels) => ({
    type: 'SET_ORG_LABELS',
    labels
});

//set custom fields of selected org
export const setOrgCustomFields = (custom_fields) => ({
    type: 'SET_ORG_CUSTOM_FIELDS',
    custom_fields
});

export const setSuperAdminUser = (super_admin) => ({
    type: 'SET_SUPER_ADMIN_USER',
    super_admin
})

export const setIsInvoiceAccessor = (invoice_accessor) => ({
    type: 'SET_IS_INVOICE_ACCESSOR',
    invoice_accessor
})

export const setOrgInvoiceAccounts = (accountList) => ({
    type: 'SET_ORG_INVOICE_ACCOUNTS',
    accountList
})

export const fetchOrgDetails = (organization_id, start_date, end_date, companiesFilter, selectedMembersFilter, selectedTemplate, scope) => {
    return(dispatch) => {
        organizationDetailsApi(organization_id)
                .then((response) => {
                    const res = response.data;
                    consoleToLog('Response OrganizationDetailsApi: ', res);

                    //set members & labels of selected organization in redux
                    dispatch(setOrgMembers(res.members));
                    dispatch(setOrgLabels(res.labels));
                    dispatch(setSuperAdminUser(res.loggedin_user.is_super_admin))
                    dispatch(setIsInvoiceAccessor(res.loggedin_user.is_invoice_accessor))
                    let updatedArray = res.custom_fields && res.custom_fields.length > 0 ? res.custom_fields.filter(cf => cf.type !== udfTypes.ImageOrDocument.value) : [];
                    dispatch(setOrgCustomFields(updatedArray))

                    dispatch(fetchEmailDraftsList(organization_id));
                    if(scope && scope.length > 0 && scope?.includes('invoices')) {
                        dispatch(fetchInvoiceAccounts(organization_id, start_date, end_date, companiesFilter, selectedMembersFilter, selectedTemplate));
                    } else {
                        dispatch(fetchTemplateList(organization_id, start_date, end_date, companiesFilter, selectedMembersFilter, selectedTemplate));
                    }
                })
                .catch((e) => {
                    consoleToLog("OrganizationDetailsApi error", e.response);
                    dispatch(setLoading(false));
                    if (e.response.data && e.response.data.message) {
                        return false;
                    }
                });
    }
}

export const fetchInvoiceAccounts = (organization_id, start_date, end_date, companiesFilter, selectedMembersFilter, selectedTemplate) => {
    return (dispatch) => {
        getInvoiceAccountsListApi(organization_id)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response getInvoiceAccountsListApi: ', res);

                if(res.invoice_accounts && res.invoice_accounts.length > 0) {
                    dispatch(fetchInvoiceAccountEntityList(res.invoice_accounts[0]?.id, res.invoice_accounts))
                } else {
                    dispatch(setOrgInvoiceAccounts([]));
                }
                dispatch(fetchTemplateList(organization_id, start_date, end_date, companiesFilter, selectedMembersFilter, selectedTemplate))

            })
            .catch((e) => {
                consoleToLog("getInvoiceAccountsListApi error", e);
                dispatch(setLoading(false));
                if (e.response.data && e.response.data.message) {
                    return false;
                }
            });
    }
}

export const fetchInvoiceAccountEntityList = (invoice_account_id, accountList) => {
    return (dispatch) => {
        getInvoiceAccountsEntityListApi(invoice_account_id)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response getInvoiceAccountsEntityListApi: ', res);

                const invoiceAccountList = accountList.map((account) => {
                    if(account.id === invoice_account_id) {
                        return {
                            ...account,
                            entities: res
                        }
                    }
                    return account
                });

                dispatch(setOrgInvoiceAccounts(invoiceAccountList));

            })
            .catch((e) => {
                consoleToLog("getInvoiceAccountsEntityListApi error", e.response);
                dispatch(setLoading(false));
                if (e.response.data && e.response.data.message) {
                    return false;
                }
            });
    }
}

//set templates
export const setOrgTemplates = (templates) => ({
    type: 'SET_ORG_TEMPLATES',
    templates
});

//set waiting for my approval count
export const setWaitingForMyApprovalChecklistCount = (waiting_for_my_approval_checklist_count) => ({
    type: 'SET_WAITING_FOR_MY_APPROVAL_CHECKLIST_COUNT',
    waiting_for_my_approval_checklist_count
});

export const fetchTemplateList = (organization_id, start_date, end_date, companiesFilter, selectedMembersFilter, selectedTemplate) => {
    return (dispatch) => {
        organizationTemplateListApi(organization_id, start_date, end_date, companiesFilter, selectedMembersFilter, selectedTemplate)
                .then((response) => {
                    const res = response.data;
                    consoleToLog('Response OrganizationTemplateListApi', res);
                    dispatch(setLoading(false));

                    let allTemplateArr = res.templates && res.templates.length > 0 ? [{name: 'All', template_id: -1}, ...res.templates] : [];
                    //set templates of selected organization in redux
                    dispatch(setOrgTemplates(allTemplateArr));
                    //dispatch(setSelectedDrawerState(SelectedDrawerState.SHOW_ACTIVITY_REPORT));
                    let template_id;
                    if(selectedTemplate === undefined) {
                        dispatch(setSelectedTemplate(allTemplateArr[0]));
                        //dispatch(setSelectedDrawerState(SelectedDrawerState.SHOW_ACTIVITY_REPORT));
                    }  else {//if selected org found, get its id
                        template_id = selectedTemplate.template_id;
                        //if found update its data
                        const updateSelectedTemplate = allTemplateArr.find((template) => template.template_id === template_id);
                        if (updateSelectedTemplate) {
                            dispatch(setSelectedTemplate(updateSelectedTemplate));
                        } else {
                            dispatch(setSelectedTemplate(allTemplateArr[0]));
                        }
                    }    
                    dispatch(setWaitingForMyApprovalChecklistCount(res.waiting_for_my_approval_checklist_count));
                })
                .catch((e) => {
                    consoleToLog("OrganizationTemplateListApi error", e);
                    dispatch(setLoading(false));
                    if (e.response.data && e.response.data.message) {
                        return false;
                    }
                });

    }
}

export const fetchEmailDraftsList = (organization_id) => {
    return (dispatch) => {
        getEmailDraftListApi(organization_id)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response getEmailDraftListApi: ', res);

                dispatch(setOrgEmailDrafts(res.email_drafts));
                dispatch(fetchOrgEmails(organization_id));
            })
            .catch((e) => {
                consoleToLog("getEmailDraftListApi error", e.response);
                dispatch(setLoading(false));
                if (e.response.data && e.response.data.message) {
                    return false;
                }
            });
    }
}

export const fetchOrgEmails = (organization_id) => {
    return(dispatch) => {
        getOrgSupportEmailsApi(organization_id)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response getOrgSupportEmailsApi: ', res);

                dispatch(setOrgEmails(res.organization_emails));

            })
            .catch((e) => {
                consoleToLog('Error getOrgSupportEmailsApi: ', e.response);
                dispatch(setLoading(false));
                if(e.response.data && e.response.data.message) {
                    return;
                }
            })
    }
}

export const setSelectedTemplate = (template) => ({
    type: 'SET_SELECTED_TEMPLATE',
    template
});

export const SelectedDrawerState  = {
    SHOW_ORG_MEMBERS  : 'org_members',
    SHOW_ORG_LABELS : 'org_labels',
    SHOW_ORG_COMPANIES : 'org_companies',
    SHOW_ORG_SETTINGS : 'org_settings',
    SHOW_COMPLETION_REPORT: 'completion_report',
    SHOW_WORKLOAD_REPORT: 'workload_report',
    SHOW_FOLLOWUP_REPORT: 'followup_report',
    SHOW_EMAIL_REPORT: 'email_report',
    SHOW_ACTIVITY_REPORT: 'activity_report',
    SHOW_MY_PROFILE: 'my_profile',
    SHOW_MY_ORGS: 'my_orgs',
    NOT_SELECTED : 'not_selected',
    CREATE_TEMPLATE: 'create_template',
    CREATE_PREDEFINED_TEMPLATE: 'create_predefined_template',
    SHOW_ONBOARDING: 'onboarding',
    SHOW_OVERDUE_CHECKLIST: 'overdue_checklist',
    SHOW_UPCOMING_CHECKLIST: 'upcoming_checklist',
    SHOW_ALL_CHECKLIST: 'all_checklist',
};

export const setSelectedDrawerState = (drawerState) =>  ({
    type: 'SET_SELECTED_DRAWER_STATE',
    drawerState
})

//add member to org
export const addOrgMember = (member) => ({
    type: 'ADD_ORG_MEMBER',
    member
});

//update member (role changed)
export const updateMemberRole = (organization_member_id, access_level) => ({
    type: 'UPDATE_ORG_MEMBER_ROLE',
    organization_member_id,
    access_level
});

//Remove Organization Member
export const removeOrgMember = (organization_member_id) => ({
    type: 'REMOVE_ORG_MEMBER',
    organization_member_id
});

//Add label to org
export const addOrgLabel = (label) => ({
    type: 'ADD_ORG_LABEL',
    label
});

//Update Org Label
export const updateOrgLabel = (label) => ({
    type: 'UPDATE_ORG_LABEL',
    label
});

//delete org Label
export const deleteOrgLabel = (label_id) => ({
    type: 'DELETE_ORG_LABEL',
    label_id
})

// Decryption Key
 export const setCDSalt = (salt) => ({//company details: salt
    type: 'SET_CD_SALT',
    salt
})

//Add To Org Custom Field 
export const addOrgCustomField = (custom_field) => ({
    type: 'ADD_ORG_CUSTOM_FIELD',
    custom_field
});

//Update Org Custom Field 
export const updateOrgCustomField = (custom_field) => ({
    type: 'UPDATE_ORG_CUSTOM_FIELD',
    custom_field
});

//delete Org Custom Field 
export const deleteOrgCustomField = (custom_field_id) => ({
    type: 'DELETE_ORG_CUSTOM_FIELD',
    custom_field_id
})

export const setSelectedOrgEmailObj = (emailObj) => ({
    type: "SET_SELECTED_ORG_EMAIL_OBJ",
    emailObj
})

export const setOrgEmails = (emails) => ({
    type: "SET_ORG_EMAILS",
    emails
})

export const addEmailToOrg = (email) => ({
    type: "ADD_EMAIL_TO_ORG",
    email
})

export const updateOrgEmail = (email) => ({
    type: "UPDATE_ORG_EMAIL",
    email
})

export const deleteOrgEmail = (email_id) => ({
    type: "DELETE_ORG_EMAIL",
    email_id
})

export const setOrgEmailDrafts = (draftsList) => ({
    type: "SET_ORG_EMAIL_DRAFTS",
    draftsList
})

export const setCurrentEmailState = (emailState) => ({
    type: 'SET_CURRENT_EMAIL_STATE',
    emailState
})

export const setIsEmailSendError = (emailSendError) => ({
    type: 'SET_IS_SEND_EMAIL_ERROR',
    emailSendError
})

export const setChecklistArray = (checklistArray) => ({
    type: 'SET_CHECKLIST_ARRAY',
    checklistArray
})

export const setEmailFollowupObject = (emailFollowup) => ({
    type: 'SET_EMAIL_FOLLOWUP_OBJECT',
    emailFollowup
})

export const setFromChecklistDetailsPage = (fromChecklistDetails) => ({
    type: 'SET_FROM_CHECKLIST_DETAILS_PAGE',
    fromChecklistDetails
})

export const setCreateAndEditTemplate = (createEditTemplate) => ({
    type: 'SET_CREATE_AND_EDIT_TEMPLATE',
    createEditTemplate
})

export const setTemplateRulesErrorMessage = (errorMessage) => ({
    type: 'SET_TEMPLATE_RULE_ERROR_MESSAGE',
    errorMessage
})

export const checkTemplateRules = (organization_id) => {
    return(dispatch) => {
        checkTemplateRulesApi(organization_id)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response checkTemplateRulesApi: ', res);

                dispatch(setTemplateRulesErrorMessage(res.message));

            })
            .catch((e) => {
                consoleToLog('Error getOrgSupportEmailsApi: ', e.response);
                if(e.response.data && e.response.data.message) {
                    return;
                }
            })
    }
}

//Add To Org Email Draft 
export const addOrgEmailDraft = (email_draft) => ({
    type: 'ADD_ORG_EMAIL_DRAFT',
    email_draft
});

//Update Org Email Draft 
export const updateOrgEmailDraft = (email_draft) => ({
    type: 'UPDATE_ORG_EMAIL_DRAFT',
    email_draft
});

//delete Org Email Draft
export const deleteOrgEmailDraft = (email_draft_id) => ({
    type: 'DELETE_ORG_EMAIL_DRAFT',
    email_draft_id
})

export const setEmailSentFrom = (str) => ({
    type: 'SET_EMAIL_SENT_FROM',
    str
});