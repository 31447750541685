import React, {useState} from 'react';
import {Drawer, Grid, Typography, Button, TextField, CircularProgress} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {connect} from 'react-redux';
import {createNewOrganizationApi} from '../../services/authService';
import {setOrganizations, addOrganization} from '../../actions/organization';
import { consoleToLog, capitalizeFirstLetterOfEachWord, trackGAEvent} from '../../util/AppUtil';
import { nameValidator, emailValidator } from '../../util/validator';
import { useSnackbar } from 'notistack';
import MobileRightToLeftSideDrawer from './MobileRightToLeftSideDrawer';

const useStyles = makeStyles((theme) => ({
    drawerPaper: {
        backgroundColor: theme.palette.primary.light,
        width: '100%', 
        height: '100vh',
        flexGrow:1,
        overflowY:'auto',
        overflowX:'hidden'
    },
    backIcon: {
        color: "#202020",
        border: " 1px solid #202020",
        padding: "4px 0px",
        fontSize: "18px",
        minWidth: "40px",
        lineHeight: "18px",
        marginBottom: "10px",
        marginRight: "16px",
    },
    mobileHeading: {
        fontSize: '18px',
        fontWeight: 500,
        marginTop:'-8px'
    },
    modalFooter: {
        position: 'absolute',
        bottom: '8px',
        left: 0,
        right:0,
        zIndex:15,
        background: '#fff',
        borderTop: '1px solid #ccc',
        padding:'6px 8px 6px 8px',
    },
    circularProgress: {
        marginLeft: 0,
        marginRight: theme.spacing(2),
        color: 'white'
    },
}))

const MobileCreateOrganizationDrawer = (props) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();

    const [orgName, setOrgName] = useState('');
    const [orgEmail, setOrgEmail] = useState('');
    const [loading, setLoading] = useState(false);

    const onCreateOrgClick = (e) => {
        e.preventDefault();

        const organization_name = orgName;
        if (!organization_name) {
            enqueueSnackbar('Organization name can not be empty!', {
                variant:'error'
            });
            return;
        }
        const isOrganizationNameValid = nameValidator(organization_name);
        if (!isOrganizationNameValid) {
            enqueueSnackbar('Organization name is not valid! Please enter Alphabets only', {
                variant:'error'
            });
            return;
        }

        // const organization_email = orgEmail;
        // if (!organization_email) {
        //     enqueueSnackbar('Organization email can not be empty!', {
        //         variant:'error'
        //     });
        //     return;
        // }
        // const isOrganizationEmailValid = emailValidator(organization_email);
        // if (!isOrganizationEmailValid) {
        //     enqueueSnackbar('Email is not valid!', {
        //         variant:'error'
        //     });
        //     return;
        // }

        setLoading(true);
        //Create New Organization API
        createNewOrganizationApi(capitalizeFirstLetterOfEachWord(organization_name))
            .then((response) => {
                const res = response.data;
                consoleToLog("Response createNewOrganizationApi: ", res);

                enqueueSnackbar('Organization created!', {
                    variant:'success'
                });

                //update redux so that list will get updated
                props.setScrollDown(true);
                props.addOrganization(res);

                //set text field value
                setOrgName('');
                setLoading(false);
                props.handleDrawerClose();
                trackGAEvent(props.selectedOrganization.organization.name, 'Create Organization', `${props.auth.user.firstname} ${props.auth.user.lastname} created organization ${organization_name}`);
            })
            .catch((e) => {
                consoleToLog("createNewOrganizationApi error", e.response);
                setLoading(false);
                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant:'error'
                    });
                    return false;
                }
            })
    }

    return (
        <MobileRightToLeftSideDrawer openDrawer={props.openDrawer} 
            drawerClose={props.handleDrawerClose}
            title='Create Organization'>
                <div className="modal__scheduler" style={{overflow:'auto'}}>
                    <Grid item xs={12} sm={12}>
                        <Typography variant="subtitle1" style={{marginBottom:'-4px'}}>
                            Organization Name
                        </Typography>

                        <TextField 
                            fullWidth
                            variant="outlined"
                            margin="dense"
                            type="text"
                            value={orgName}
                            placeholder="Enter Organization Name"
                            InputLabelProps={{style: {fontSize: 13, marginTop:'3px'}}}
                            onChange={(e) => setOrgName(e.target.value)}
                            style={{background:"#fff"}}
                        />
                    </Grid>
                            
                    {/* <Grid item xs={12} sm={12} style={{marginTop:'16px'}}>
                        <Typography  variant="subtitle1" style={{marginBottom:'-4px'}}>
                            Enter Organization Email
                        </Typography>

                        <TextField 
                            fullWidth
                            variant="outlined"
                            margin="dense"
                            type="text"
                            value={orgEmail}
                            placeholder="Enter Organization Email"
                            InputLabelProps={{style: {fontSize: 13, marginTop:'3px'}}}
                            onChange={(e) => setOrgEmail(e.target.value)}  
                            style={{background:"#fff"}}
                        />
                    </Grid> */}
                </div>

                <div className={classes.modalFooter}>
                    <Grid item container alignItems='center' spacing={1}>
                        <Grid item xs={6} sm={6}>
                            <Button variant="contained" color="primary" 
                                fullWidth
                                onClick={onCreateOrgClick}
                                >
                                {loading && <CircularProgress size={24} className={classes.circularProgress}/>}
                                Create
                            </Button>
                        </Grid>

                        <Grid item xs={6} sm={6}>
                            <Button variant="outlined" 
                                fullWidth
                                onClick={props.handleDrawerClose}>
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            
        </MobileRightToLeftSideDrawer>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    organizations: state.organizations,
    selectedOrganization: state.organizationInfo.selectedOrganization,
});

const mapDispatchToProps = (dispatch) => ({
    setOrganizations: (organizations) => dispatch(setOrganizations(organizations)),
    addOrganization: (organization) => dispatch(addOrganization(organization))
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileCreateOrganizationDrawer);