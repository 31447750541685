import React, {useState, useEffect} from 'react';
import RightToLeftSideDrawer from '../RightToLeftSideDrawer';
import {Grid, Typography, TextField, CircularProgress, Button, MenuItem} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {getPredefinedTemplateListApi, importPredefinedTemplateApi} from '../../services/authService';
import {consoleToLog} from '../../util/AppUtil';
import {setDeltaTime} from '../../actions/auth';
import { useSnackbar } from 'notistack';
import {fetchTemplateList} from '../../actions/selectedOrganization';
import { isStringSpaces } from '../../util/validator';

const useStyles = makeStyles((theme) => ({
    circularProgress: {
        marginLeft: 0,
        marginRight: theme.spacing(2),
        color: 'white'
      },
    customMarginTop : {
        margin:'16px 0px 0px 0px'
    },
    customMarginBottom : {
        marginBottom:'-8px'
    },  
    MuiFormControlroot: {
        '& .MuiOutlinedInput-input' :{
            paddingLeft: '16px',
        },   
    },

}))


const ImportTemplateModal = (props) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();

    const [templates, setTemplates] = useState([]);
    const [selectedTemplateCode, setSelectedTemplateCode] =  useState(-1);
    const [selectedTemplateTitle, setSelectedTemplateTitle] = useState('');
    const [loading, setLoading] = useState(false);

    
    useEffect(() => {
        if(props.openDrawer) {
            getPredefinedTemplateList()
        }
    }, [props.openDrawer])

    const getPredefinedTemplateList = () => {

        getPredefinedTemplateListApi()
            .then((response) => {
                const res = response.data;
                consoleToLog("Response getPredefinedTemplateListApi: ", res);
                props.setDeltaTime(res.time_delta);
                setTemplates(res.templates);
            })
            .catch((e) => {
                consoleToLog("Response getPredefinedTemplateListApi: ", e);
                if (e.response.data && e.response.data.message) {
                    return false;
                }
            });
    }

    const onTemplateSelected = (e) => {
        const slug_code = e.target.value;
        if(slug_code === -1 || slug_code === '-1') {
            setSelectedTemplateCode(-1);
            setSelectedTemplateTitle('');
            return;
        } else {
            const title = templates.find((template) => template.slug === slug_code).name;
            setSelectedTemplateCode(slug_code);
            setSelectedTemplateTitle(title);
        }
    }

    const onImportTemplateClick = (e) => {
        let slug_code = selectedTemplateCode;
        if (slug_code === -1 || slug_code === '-1') {
            consoleToLog('slug_code: ', slug_code)
            enqueueSnackbar('Template Cannot be empty', {
                variant: 'error'
            });
            return;
        }

        let title = selectedTemplateTitle;
        if(!title || isStringSpaces(title)) {
            enqueueSnackbar('Title Cannot be empty', {
                variant: 'error'
            });
            return;
        }

        const organization_id = props.selectedOrganization.organization.id;

        setLoading(true);
        importPredefinedTemplateApi(organization_id, slug_code, title)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response importPredefinedTemplateApi: ", res);
                setLoading(false);
                enqueueSnackbar(res.message, {
                    variant: 'success'
                });

                setSelectedTemplateCode(-1);
                setSelectedTemplateTitle('');

                // ReactGA.event({
                //     category: 'Predefined Template',
                //     action: 'Imported Predefined Template',
                //     label: `${props.auth.user.firstname} ${props.auth.user.lastname}`
                // });
                
                //props.setLoading(true);
                props.fetchTemplateList(organization_id, props.startDateFilter, props.endDateFilter,
                props.companiesFilter, props.selectedMembersFilter, props.selectedTemplate);
                props.handleDrawerClose();
            })
            .catch((e) => {
                consoleToLog("Response importPredefinedTemplateApi error: ", e.error);
                setLoading(false);
                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant: 'error'
                    });
                    return false;
                }
            });

    }

    return (
        <RightToLeftSideDrawer title="Import Template"
            openDrawer={props.openDrawer}
            closeDrawer={props.handleDrawerClose}
            fromOnboardingComponent={props.fromOnboardingComponent}
        >
            <div className="modal__main">
                <Grid item container md={12} lg={12} direction="column">
                    <Grid item lg={12}>
                        <Typography variant="subtitle1" className={classes.customMarginBottom}>
                            Select Predefined Template
                        </Typography> 
                        <TextField
                            style={{ width: "100%"}} 
                            id="templates"
                            margin="dense"
                            select
                            //label="Select Template"
                            value={selectedTemplateCode}
                            defaultValue={selectedTemplateCode}
                            variant="outlined"
                            onChange={onTemplateSelected}
                            InputLabelProps={{style: {fontSize: 13}}}
                           //disabled={!isScheduleActive}
                        >
                            <MenuItem value={-1} style={{opacity:'.5'}}>Select Template</MenuItem>
                            {
                                templates && templates.map((template) => {
                                return  <MenuItem key={template.slug}
                                            value={template.slug}>
                                            {template.name}
                                        </MenuItem>
                                })
                            }
                        </TextField>  
                    </Grid>

                    <Grid item md={12} lg={12} 
                        className={classes.customMarginTop}>
                        <Typography variant="subtitle1" 
                            className={classes.customMarginBottom}>
                            Title
                        </Typography>   

                        <TextField fullWidth 
                            id="template_title" 
                            variant="outlined"
                            type="text"
                            margin="dense"
                            autoComplete="off"
                            value={selectedTemplateTitle}
                            placeholder="Enter Template Title"
                            InputLabelProps={{style: {fontSize: 13, color: "#ccc"}}}
                            onChange={(e) => setSelectedTemplateTitle(e.target.value)}
                            classes={{
                                root: classes.MuiFormControlroot
                            }}
                        />
                    </Grid>


                    <div className={props.fromOnboardingComponent ? "modal__footer1" : "modal__footer"}>
                        <Button variant="contained" color="primary" 
                            className="modal__button"
                            onClick={onImportTemplateClick}
                            >
                            {loading && <CircularProgress size={24} className={classes.circularProgress}/>}
                            Import
                        </Button>
                        <Button variant="outlined" style={{
                            minWidth: '130px',
                        }} onClick={props.handleDrawerClose}>
                            Cancel
                        </Button>
                    </div>
                </Grid>    
            </div>
            
        </RightToLeftSideDrawer>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    startDateFilter: state.filters.startDateFilter,
    endDateFilter: state.filters.endDateFilter,
    companiesFilter: state.filters.companiesFilter,
    selectedMembersFilter: state.filters.selectedMembersFilter,
    selectedTemplate: state.organizationInfo.selectedTemplate,
});

const mapDispatchToProps = (dispatch) => ({
    fetchTemplateList: (organization_id, start_date, end_date, companiesFilter, selectedMembersFilter, selectedTemplate) => 
    dispatch(fetchTemplateList(organization_id, start_date, end_date, companiesFilter, selectedMembersFilter, selectedTemplate)),
   // setLoading: (isLoading) => dispatch(setLoading(isLoading)),
    setDeltaTime: (delta) => dispatch(setDeltaTime(delta)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImportTemplateModal);