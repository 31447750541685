import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import { Typography, Drawer, AppBar, Toolbar,IconButton, Grid } from '@material-ui/core';

import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles((theme) => ({
    drawer: {
    //    width: drawerWidth,
        flexShrink: 0
    },
    drawerPaper: {
        width: '33%', 
        height: (fromOnboardingComponent) => {
            return fromOnboardingComponent ? '100vh' : 'calc(100vh - 51px)'
        } ,
        flexGrow:1,
        top: (fromOnboardingComponent) => {
            return fromOnboardingComponent ? '0px' : '51px'
        }
    },
    appBar: {
        background:'#F9F9F9',
        height:'45px',
        color:'#202020'
    },
    headingStyle: {
        fontSize: '18px',
        fontWeight:'600',
        marginBottom:'-6px'
    }
}));

const ForwardingInfoDrawer = (props) => {
    let {fromOnboardingComponent} = props;
    const classes = useStyles(fromOnboardingComponent);
    //const [openDrawer, setOpenDrawer] = useState(props.openDrawer);

    return(
        <Drawer variant="temprorary"
            anchor="right"
            open={props.openDrawer}
            onClose={props.handleDrawerClose}
            classes={{
                paper: classes.drawerPaper
                }}
            >
            <AppBar position="static" className={classes.appBar} elevation={0}>
                <Toolbar style={{minHeight:'40px', padding:'0px 16px', }}>
                    <Typography variant="body1" 
                        style={{fontWeight:'600', marginRight:'auto'}}>
                            Forwarding Info
                    </Typography>
                    <IconButton style={{color:'#202020', marginRight:'-16px'}} 
                        onClick={props.handleDrawerClose}
                    >
                        <ClearIcon/>
                    </IconButton>
                </Toolbar>
            </AppBar>

            <Grid item style={{padding:'16px'}}>
                <Typography className={classes.headingStyle}>Turn on automatic forwarding</Typography>

                <p><img src="//storage.googleapis.com/support-kms-prod/Cm6cYtX7pQvTaMzx3ADskquczoegpK3vShee" width="465px" height="Auto" alt="Forwarding email to another account." class="Shadow" data-mime-type="image/gif"/></p>
                <ol style={{paddingLeft:'21px'}}>
                    <Typography variant='body2'>
                        <li>On your computer, open <a href="https://mail.google.com/" target="_blank" rel="noopener">Gmail</a> using the account you want to forward messages from. You can only forward messages for a single Gmail address, and not an email group or alias.</li>
                        <li>In the top right, click Settings <img src="//lh3.googleusercontent.com/p3J-ZSPOLtuBBR_ofWTFDfdgAYQgi8mR5c76ie8XQ2wjegk7-yyU5zdRVHKybQgUlQ=w36-h36" width="18" height="18" alt="Settings" data-mime-type="image/png" data-alt-src="//lh3.googleusercontent.com/p3J-ZSPOLtuBBR_ofWTFDfdgAYQgi8mR5c76ie8XQ2wjegk7-yyU5zdRVHKybQgUlQ"/>&nbsp;<img src="//lh3.googleusercontent.com/3_l97rr0GvhSP2XV5OoCkV2ZDTIisAOczrSdzNCBxhIKWrjXjHucxNwocghoUa39gw=w36-h36" width="18" height="18" alt="and then" data-mime-type="image/png" data-alt-src="//lh3.googleusercontent.com/3_l97rr0GvhSP2XV5OoCkV2ZDTIisAOczrSdzNCBxhIKWrjXjHucxNwocghoUa39gw"/> &nbsp;<strong>See all settings.&nbsp;</strong></li>
                        <li>Click the <strong>Forwarding and POP/IMAP</strong> tab.</li>
                        <li>In the "Forwarding" section, click <strong>Add a forwarding address</strong>.</li>
                        <li>Enter the email address you want to forward messages to.</li>
                        <li>Click <strong>Next</strong> <img src="//lh3.googleusercontent.com/3_l97rr0GvhSP2XV5OoCkV2ZDTIisAOczrSdzNCBxhIKWrjXjHucxNwocghoUa39gw=w36-h36" width="18" height="18" alt="and then" data-mime-type="image/png" data-alt-src="//lh3.googleusercontent.com/3_l97rr0GvhSP2XV5OoCkV2ZDTIisAOczrSdzNCBxhIKWrjXjHucxNwocghoUa39gw" /> <strong>Proceed</strong> <img src="//lh3.googleusercontent.com/3_l97rr0GvhSP2XV5OoCkV2ZDTIisAOczrSdzNCBxhIKWrjXjHucxNwocghoUa39gw=w36-h36" width="18" height="18" alt="and then" data-mime-type="image/png" data-alt-src="//lh3.googleusercontent.com/3_l97rr0GvhSP2XV5OoCkV2ZDTIisAOczrSdzNCBxhIKWrjXjHucxNwocghoUa39gw" /> <strong>OK</strong>.</li>
                        <li>A verification message will be sent to that address. Click the verification link in that message.</li>
                        <li>Go back to the settings page for the Gmail account you want to forward messages from, and refresh your browser.</li>
                        <li>Click the&nbsp;<strong>Forwarding and POP/IMAP</strong>&nbsp;tab.</li>
                        <li>In the "Forwarding" section, select <strong>Forward a copy of incoming mail to</strong>.</li>
                        <li>Choose what you want to happen with the Gmail copy of your emails. We recommend Keep Gmail's copy in the Inbox.</li>
                        <li>At the bottom of the page, click <strong>Save Changes</strong>.</li>
                    </Typography>
                </ol>
            </Grid>
            </Drawer>
        );
}

export default ForwardingInfoDrawer;