import React, { useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Button, Tooltip, Badge, Typography, Grid, TextField, InputAdornment, IconButton } from '@material-ui/core';
import {connect} from 'react-redux';
import {consoleToLog, trackGAEvent} from '../../util/AppUtil';
import { SelectedDrawerState } from '../../actions/selectedOrganization';
import ClearIcon from '@material-ui/icons/Clear';
import {setSearchChecklistFilter} from '../../actions/filters';

const useStyles = makeStyles((theme) => ({
    logo: {
        maxWidth: 120,
        marginLeft: "15px",
        cursor:'pointer'
    },
    login: {
        marginLeft: 'auto',
    },
    selectEmpty: {
        color: "white",
        padding: "0 14px",
        backgroundColor: "transparent"
    },
    icons : {
        backgroundColor: "transparent",
        color: "white",
        fontSize: "18px",
        marginTop: "8px",
        minWidth: "40px"
    },
    colorSecondary : {
        color: theme.palette.primary.main,
        background: theme.palette.secondary.contrastText
    },
    MuiTextFieldroot : {
        border:'none !important',
        '& .MuiOutlinedInput-root' : {
        borderRadius: '0px',
        border:'none !important'
        }
    }
  }));

const MobileHeader = (props) => {
    const classes = useStyles();

    const [showQuickSearch, setShowQuickSearch] = useState(false);
    const [checklistSearch, setChecklistSearch] = useState();

    const onSearchChecklistChange = (e) => {
        const search_text = e.target.value;
        setChecklistSearch(e.target.value);
        if (search_text.length % 3 === 0 || search_text.length > 3) {
            props.setSearchChecklistFilter(search_text);
            props.pageReset();
        }
    }

    const onQuickSearchClick = () => {
        setShowQuickSearch(true);
    }

    const onHideQuickSearch = () => {
        setShowQuickSearch(false);
        setChecklistSearch(undefined);
        props.setSearchChecklistFilter('');
        props.pageReset();
    }

    const displaySelectedComponent = () => {
        if(props.setDrawerState === SelectedDrawerState.SHOW_ORG_MEMBERS) {
            return  'Members'
        }
        if(props.setDrawerState === SelectedDrawerState.SHOW_COMPLETION_REPORT) {
            return  'Completion Report'
        }
        if(props.setDrawerState === SelectedDrawerState.SHOW_WORKLOAD_REPORT) {
            return  'Workload Report'
        }
        if(props.setDrawerState === SelectedDrawerState.SHOW_FOLLOWUP_REPORT) {
            return  'Followup Report'
        } 
        if(props.setDrawerState === SelectedDrawerState.SHOW_ACTIVITY_REPORT) {
            return 'Activity'
        }
        if(props.setDrawerState === SelectedDrawerState.SHOW_ORG_LABELS) {
            return 'Labels'
        }
        if(props.setDrawerState === SelectedDrawerState.SHOW_ORG_COMPANIES) {
            return 'Clients'
        }
        if(props.setDrawerState === SelectedDrawerState.SHOW_ORG_SETTINGS) {
            return 'Settings'
        }
        if(props.setDrawerState === SelectedDrawerState.SHOW_EMAIL_REPORT) {
            return 'Email Report'
        }
        else if(props.selectedTemplate !== '') {
            return 'Checklist'
        }
    }

    let notifiCount = (props.selectedOrganization &&
        props.selectedOrganization.organization &&
        props.selectedOrganization.organization.id &&
        props.notifications &&
        props.notifications[props.selectedOrganization.organization.id] &&
        props.notifications[props.selectedOrganization.organization.id] !== 0
    ) ? props.notifications[props.selectedOrganization.organization.id] :  0;

    return (
        <>
        {showQuickSearch ?
        <Grid item xs={12} sm={12}>
            <TextField  fullWidth
                value={checklistSearch}
                type='text'
                variant='outlined'
                placeholder='Quick Search Checklist' 
                InputProps={{
                    startAdornment: (
                    <InputAdornment position="start">
                        <i className="flaticon-search"></i>
                    </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end">
                                <ClearIcon onClick={onHideQuickSearch} 
                                    fontSize='small' style={{color:'#ccc'}}/>
                        </InputAdornment>
                    )
                }}
                classes={{
                    root: classes.MuiTextFieldroot
                }}
                onChange={onSearchChecklistChange}
            />
        </Grid>
        :
        <AppBar position="static" elevation={0}>
            <Toolbar variant="dense" style={{padding: '0px 8px'}}>
                <Tooltip title="Drawer Open/Close" arrow>
                    <Button
                        onClick={props.onShowDrawerClick}
                        className={classes.icons}
                        >
                        <i className="flaticon-menu"></i>
                    </Button>
                </Tooltip>

                <Grid item>
                <Typography variant="body1" style={{color:'#fff', fontSize:'18px', marginLeft:'4px'}}>
                        {displaySelectedComponent()}
                </Typography>
                </Grid>

                <div className={classes.login}>
                    {/* <Tooltip title="Waiting for my Approval" arrow>
                        <Button
                            className={classes.icons}>
                            <i className="flaticon-checked"></i>
                        </Button>
                    </Tooltip> */}
                    <Tooltip title="Notifications" arrow>
                        <Button
                            onClick={props.handleNotificationDrawerOpen}
                            className={classes.icons}>
                            <Badge  badgeContent={notifiCount}
                                    classes={{
                                        colorSecondary: classes.colorSecondary
                                    }}
                                    max={9}
                                    color='secondary'
                                    invisible={(notifiCount === 0)}>
                                <i className="flaticon-ring"></i>
                            </Badge> 
                        </Button>
                    </Tooltip>
                    {props.setDrawerState === SelectedDrawerState.NOT_SELECTED &&
                    <Tooltip title="Profile" arrow>
                        <Button aria-label="delete" 
                            onClick={onQuickSearchClick} 
                            className={classes.icons}>
                            <i className="flaticon-search"></i>
                        </Button>
                    </Tooltip>}
                </div>
                {/* <Button color="inherit" className={classes.login}>Login</Button> */}
            </Toolbar>
        </AppBar>
        }
        </>
    );
}

const mapStateToProps = (state) => ({
    setDrawerState: state.organizationInfo.setDrawerState,
    selectedTemplate: state.organizationInfo.selectedTemplate,
    notifications: state.notifications,
    selectedOrganization: state.organizationInfo.selectedOrganization
});

const mapDispatchToProps = (dispatch) => ({
    setSearchChecklistFilter: (search_text) => dispatch(setSearchChecklistFilter(search_text)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MobileHeader);