import React, {useState} from 'react';
import RightToLeftSideDrawer from '../RightToLeftSideDrawer';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Button, Popover, Typography} from '@material-ui/core';
import { DateRangePicker} from "materialui-daterange-picker";
import moment from 'moment';
import { consoleToLog, OverlayMessage, trackGAEvent } from '../../util/AppUtil';
import {connect} from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { OverlayView, setOverlayToken, setOverlayView, setOvelayMessage} from '../../actions/overlay';
import { useSnackbar } from 'notistack';
import { allAttachmentsZipApi } from '../../services/authService';

const useStyles = makeStyles((theme) => ({
    mainStyles: {
        marginTop: '16px',
        padding:'0px 16px 8px 16px',
        overflow: "auto !important", 
        height:'calc(100vh - 180px)',
        scrollbarWidth: 'none',
        position:'relative',
        "&::-webkit-scrollbar": {
            display: "none"
            }
        }
    })
);

const BackupModal = (props) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();

    const [dateRange, setDateRange] = useState('');
    const [anchorEl, setAnchorEl] = useState(false);

    const openDateRangePicker = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const open = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onDownloadDocsClick = () => {
        
        const organization_id = props.selectedOrganization.organization.id;
        const start_date = moment(dateRange.startDate).format('YYYY-MM-DD');
        const end_date = moment(dateRange.endDate).format('YYYY-MM-DD');
        const diff = moment(dateRange.endDate).diff(moment(dateRange.startDate), 'months');
        if(diff > 6) {
            enqueueSnackbar('Duration can be greater then six months', {variant:'error'});
            return;
        }

        const randomtoken = uuidv4();

        props.setOverlayView(OverlayView.SHOW_OVERLAY_LOADING);
        props.setOverlayToken(randomtoken);
        props.setOvelayMessage(OverlayMessage.DOWNLOAD_MESSAGE);
        closeDrawer();
        
        allAttachmentsZipApi(organization_id, randomtoken, start_date, end_date)
        .then((response) => {
            const res = response.data;
            consoleToLog("Response allAttachmentsZipApi: ", res);
            trackGAEvent(props.selectedOrganization.organization.name, 'Download Zip', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
        })
        .catch((e) => {
            consoleToLog("exportCompaniesApi error", e.response);

            props.setOverlayView(OverlayView.NO_VIEW);
            props.setOverlayToken("");

            if (e.response.data && e.response.data.message) {
                enqueueSnackbar(e.response.data.message, {
                    variant:'error'
                });
                return false;
            }
        });
    }

    const closeDrawer = () => {
        setDateRange('');
        props.handleCloseBackupDrawer();
    }

    return (
        <RightToLeftSideDrawer openDrawer={props.showBackupDrawer}
        closeDrawer={closeDrawer}
        title='Client Documents Backup'>

                <div className={classes.mainStyles}>
                    <Grid item lg={12}> 
                        <Button variant='outlined' 
                            color='primary' 
                            className={'border_style'}
                            style={{fontWeight:"300 !important", border: dateRange && '2px solid #0076ff', borderTopLeftRadius:'0px', borderBottomLeftRadius:'0px'}}
                            onClick={openDateRangePicker}>
                            {(dateRange.startDate && dateRange.endDate) ? `${moment(dateRange.startDate).format('DD-MM-YYYY')} - 
                                ${moment(dateRange.endDate).format('DD-MM-YYYY')}` :'Select Duration'}
                        </Button>
                        <Popover
                            id='daterange-popover'
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center"
                            }}
                            transformOrigin={{
                            vertical: "top",
                            horizontal: "center"
                            }}
                        >
                            <DateRangePicker
                                open={true}
                                toggle={handleClose}
                                onChange={(range) => {
                                consoleToLog("range", range, dateRange);
                                    setDateRange(range);
                                    handleClose();
                                }}
                                style={{
                                width:'50%',
                                }}

                                initialDateRange={{
                                    startDate: dateRange.startDate,
                                    endDate: dateRange.endDate
                                }}
                                definedRanges={[]}
                            />
                        </Popover>
                                
                        {dateRange && 
                        <Typography style={{margin:'16px 0px 0px 0px'}}>
                            Documents will be downloaded for duration {`${moment(dateRange.startDate).format('DD-MMM-YYYY')} - ${moment(dateRange.endDate).format('DD-MMM-YYYY')}`} 
                        </Typography>}

                        <div style={{marginTop:'16px'}}>
                            <Button variant="contained" color="primary" 
                                    className="modal__button"
                                    onClick={onDownloadDocsClick}
                                    >
                                Download
                            </Button>
                        </div>
                    </Grid>
                </div>

        </RightToLeftSideDrawer>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization
});

const mapDispatchToProps = (dispatch) => ({
    setOverlayView: (exportView) => dispatch(setOverlayView(exportView)),
    setOverlayToken: (randomToken) => dispatch(setOverlayToken(randomToken)),
    setOvelayMessage: (overlayMessage) => dispatch(setOvelayMessage(overlayMessage)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BackupModal);