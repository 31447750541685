import React, {useState} from 'react';
import { CircularProgress, Grid, Tooltip, Typography, Button, LinearProgress } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { consoleToLog} from '../../util/AppUtil';
import MobileRightToLeftSideDrawer from './MobileRightToLeftSideDrawer';
import { showChecklistStatusColor, checklistLabelList } from '../util/MobileAppUtil';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { history } from '../routers/MobileAppRouter';
import MobileChecklistDetailsModal from '../pages/modal/MobileChecklistDetailsModal';

const useStyles = makeStyles((theme) => ({
    checklistStyles: {
        background:'#fff',
        padding:'8px 14px'
    },
    companyNameStyle: {
        color:"#333333",
        maxWidth:'280px',
        whiteSpace:'nowrap',
        overflow:'hidden',
        textOverflow:'ellipsis',
        [theme.breakpoints.down(385)] : {
            maxWidth:'255px',
        }
    },
    subtitleStyle: {
        fontSize:'13px',
        color:'#888888',
        marginLeft:'8px'
    },
    templateNameStyle: {
        color:'#0071FF'
    },
}))

const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 4,
        borderRadius: 0,
    },
    colorPrimary: {
        backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
        borderRadius: 5,
        backgroundColor: '#525252',
    },
}))(LinearProgress);


const MobileChecklistReportsDrawer = (props) => {
    const classes = useStyles();

    const [selectedChecklistItem, setSelectedChecklistItem] = useState(undefined);
    const [openDialog, setOpenDialog] = useState(false);
    const [currentchecklistItem, setCurrentchecklistItem] = useState(undefined);
    const [tab, setTab] = useState(0);

    var urlPath = window.location.pathname.split("/");
    var curr_slug = urlPath[urlPath.length-1];

    const handleDetailsDialogOpen=(current_slug)=>{
        setOpenDialog(true);
        history.push(`/checklist/${current_slug}`)
        consoleToLog("opening dialog box");
    }

    const handleDetailsDialogClose=()=>{
        setOpenDialog(false);
        history.push("/dashboard")
        consoleToLog("closing dialog box");
    }

    const getCurrentchecklistItem = (checklistItem) => {
        setCurrentchecklistItem(checklistItem);
        // consoleToLog("******curr checklist************",checklistItem);
    };

    const modalStatus =()=>{
        if(window.location.toString().includes("checklist_details")){
            setOpenDialog(true)
        }
        else{
            setOpenDialog(false)
        }
    }

    const loadMoreLogic = () => {
        return (
            <Grid item container>
                <Grid item lg={12}>
                {props.nextPage && !props.isMoreLoading &&
                    <div style={{textAlign: "center", margin:'16px 0px'}}>
                        <Button type="button"
                            color="secondary"
                            variant="contained"
                            onClick={() => {
                                props.setIsMoreLoading(!props.isMoreLoading);
                            }}>Load More
                        </Button>
                    </div>}

                { props.isMoreLoading &&
                    <CircularProgress size={35} style={{position:'relative', left:'50%', right:'50%', margin:'16px 0px'}}/>
                }
                </Grid>
            </Grid>
        )
    }

    return (
        <MobileRightToLeftSideDrawer openDrawer={props.openChecklistDrawer}
            drawerClose={props.handleChecklistDrawerClose}
            title="Checklists"
            fromChecklistReports={true}
            >
                <div style={{marginTop:"16px"}}>
                    <Grid item container direction="column">
                        {
                            props.checklistLoading ?
                            <CircularProgress size={30} style={{position:'relative', top:'24px', left:'50%', right:'50%'}}/>
                            :
                            <Grid item md={12} lg={12}>
                            {
                                props.checkList && props.checkList.length > 0 ?
                                props.checkList.map((checklistItem) => {
                                    return <Grid item key={checklistItem.id} style={{marginBottom:'16px'}}>
                                    <Grid item className={classes.checklistStyles}
                                        style={{borderLeft: showChecklistStatusColor(checklistItem)}}>  
                                        <Grid item>
                                            <Typography variant="subtitle1" style={{display:'flex'}}>
                                                <span className={classes.companyNameStyle} 
                                                    onClick={(e)=>{
                                                        //trackGAEvent(props.selectedOrganization.organization.name, 'Checklist Opened', `${props.auth.user.firstname} ${props.auth.user.lastname} opened checklist`);
                                                        handleDetailsDialogOpen(checklistItem.slug_code,checklistItem);
                                                    }}
                                                >
                                                    {checklistItem.title}
                                                </span>

                                                <span className={classes.subtitleStyle}>
                                                    {checklistItem.subtitle}
                                                </span>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} style={{marginTop:'3px'}}>
                                            <Typography variant='body2' className={classes.templateNameStyle}>
                                                {checklistItem.template?.name}
                                            </Typography>
                                        </Grid>
                                        <Grid item container alignItems='center'
                                            justifyContent='space-between' 
                                            style={{marginTop:'3px'}}>
                                            <Grid item style={{marginBottom:'6px'}}>
                                                {(checklistItem.checklist_labels_list && checklistItem.checklist_labels_list.length > 0) &&
                                                    <div style={{display:'flex'}}>
                                                        <div className={classes.labels}>
                                                            {checklistItem.checklist_labels_list.slice(0,1).map((label) => {
                                                                return <div key={label.id} style={{display:'flex', alignItems:'flex-start'}} className='labellist'>
                                                                            <Typography
                                                                                //onClick={() => onLabelsClick(label)}
                                                                                style={{color: `${label.color}`,
                                                                                paddingLeft:'3px',
                                                                                cursor:'pointer',
                                                                                paddingTop:'0',
                                                                                fontSize:'12px'}}>{label.name}</Typography>
                                                                            <FiberManualRecordIcon fontSize="small"
                                                                                className="bullet"
                                                                                style={{width:'6px', height:'6px', color:"#ccc", paddingLeft:'3px', paddingTop:'0', marginTop:'7px'}}/>
                                                                        </div>
                                                                    })
                                                            }
                                                        </div>
                                                        <span>
                                                        {(checklistItem.checklist_labels_list && checklistItem.checklist_labels_list.length > 1) &&
                                                            checklistLabelList(checklistItem) }
                                                        </span>
                                                    </div>
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                <BorderLinearProgress variant="determinate" 
                                    value={checklistItem.total_task > 0 ? Math.round((checklistItem.completed_task / checklistItem.total_task) * 100) : 0} />        
                            </Grid>
                            }) :
                                <Grid item container justifyContent='center' alignItems='center'>
                                    No Checklist Found
                                </Grid>
                            }
                            </Grid>
                        }
                        {loadMoreLogic()}
                    </Grid>

                    {openDialog && <MobileChecklistDetailsModal  openDialog={openDialog}
                        setOpenDialog={setOpenDialog}
                        slug={curr_slug}
                        currentchecklistItem={currentchecklistItem}
                        handleDetailsDialogOpen={handleDetailsDialogOpen} 
                        handleDetailsDialogClose={handleDetailsDialogClose}
                        tabValue={tab}
                        disableCallbacks={true}
                        />}
                </div>
            
        </MobileRightToLeftSideDrawer>
    );
}

export default MobileChecklistReportsDrawer;