import React, {useState, useEffect, useRef} from 'react';
import RightToLeftSideDrawer from '../RightToLeftSideDrawer';
import {Grid, Typography, Button, TextField, CircularProgress} from '@material-ui/core';
import { useIsMount } from '../useIsMount';
import { useSnackbar } from 'notistack';
import {connect} from 'react-redux';
import {editChecklistTaskApi} from '../../services/ChecklistService';
import { consoleToLog } from '../../util/AppUtil';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { getTemplateAttachmentUploadURLApi } from '../../services/authService';
import axios from 'axios';


const UpdateTaskWidgetsDrawer = (props) => {
    const isMount = useIsMount();

    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [loading, setLoading] = useState(false);
    const [downloadWidgetName, setDownloadWidgetName] = useState('');
    const [fileLoading, setFileLoading] = useState(false);
    const [filePath, setFilePath] = useState(undefined);

    const {enqueueSnackbar} = useSnackbar();
    const editorReference = useRef();

    let toolbarConfig = {
        inline: {
            options: ['bold', 'italic', 'underline', 'strikethrough']
        },
        /* blockType: {
            options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code']
        }, */
        list: {
            options: ['unordered', 'ordered']
        },
        textAlign: {
            options: ['left', 'center', 'right', 'justify']
        },
        link: { options: ['link'] },
        options: ['inline', 'link',/* 'blockType', */ 'fontFamily', 'fontSize', 'colorPicker', 'list', 'textAlign']
    };

    useEffect(() => {
        if(!isMount) {
                if(props.taskObj && props.openUpdateTaskWidgetDrawer) {
                    if(props.widgetObj?.type === 'download') {
                        if(!props.widgetObj.json_data.desc) {
                            setDownloadWidgetName('Download File')
                        } else  {
                            setDownloadWidgetName(props.widgetObj?.json_data.desc);
                            setFilePath(props.widgetObj?.json_data.file_path?.path);
                        } 
                    } else if(props.widgetObj?.type === 'rich_text') {
                        editorReference.current?.focusEditor();
                        prefillRichTextEditor();
                    }
                }
        }
    }, [props.widgetObj, editorReference]);

    const closeDrawer = () => {
        props.handleCloseUpdateTaskWidgetDrawer();
        
    }

    const onEditorStateChange = (editorState) => {
        setEditorState(editorState)
    }

    const prefillRichTextEditor = () => {
        const blocksFromHtml = htmlToDraft(props.widgetObj.json_data.desc || '<p></p>');
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const editorState = EditorState.createWithContent(contentState);
        const setFocusOnEnd = EditorState.moveFocusToEnd(editorState);

        setEditorState(setFocusOnEnd);

    }

    const sendAppropriateTitletoEditWidgetApi = (widget) => {
        let title = '';
        if(widget.type === 'rich_text') {
            if(editorState.getCurrentContent().hasText()) {
                return title = draftToHtml(convertToRaw(editorState.getCurrentContent()));
            } else {
                enqueueSnackbar('Text body can not be empty!', {
                    variant:'error'
                });
                return;
            }
        } else if(widget.type === 'download') {
            return title = downloadWidgetName
        }

        consoleToLog('updated Title', title);
        
    }

    const onSaveClick = () => {
        const file_path = filePath ? filePath : props.widgetObj.json_data.file_path?.path;
        consoleToLog('File Path ', filePath);
        let widgetTitle = sendAppropriateTitletoEditWidgetApi(props.widgetObj)
        consoleToLog('title', widgetTitle);

        const updateTaskWidget = props.taskObj?.checklist_task_widget.map((widgetObj) => {
                if(widgetObj.id === props.widgetObj.id) {
                    const obj = {attachment_id : props.widgetObj.json_data?.attachment_id ? props.widgetObj.json_data?.attachment_id : '', path: file_path}
                    widgetObj = props.widgetObj.type === 'download' ? {...props.widgetObj, json_data:{desc : widgetTitle, file_path: obj }}  : {...props.widgetObj, json_data:{desc : widgetTitle}}
                }
                return widgetObj;
        });

        const organization_id = props.selectedOrganization.organization.id;
        const checklist_id = props.checklistId;
        const task_id = props.taskObj?.id;
        const heading = props.taskObj ? props.taskObj?.heading : false;
        const widget_json = updateTaskWidget && updateTaskWidget.length > 0 ? updateTaskWidget : [];
        const title = props.taskObj?.title;

        setLoading(true);

        if(props.taskObj) {
            editChecklistTaskApi(organization_id, checklist_id, task_id, title, heading, widget_json)
                .then((response) => {
                    const res = response.data;
                    consoleToLog("Response editChecklistTaskApi: ", res);
                    setLoading(false);

                    enqueueSnackbar(res.message, {
                        variant:'success'
                    });

                    let obj = {
                        ...props.taskObj,
                        checklist_task_widget: widget_json
                    }

                    props.onEditDeleteWidget(obj);
                    closeDrawer();
                })
                .catch((e) => {
                    consoleToLog('Error editChecklistTaskApi: ', e);
                    setLoading(false);
                    if (e.response.data && e.response.data.message) {
                        enqueueSnackbar(e.response.data.message, ({
                            variant: 'error'
                        }));
                        return false;
                    }
                })

        }

    }   

    const onFileSelected = (e) => {
        const {target: { files }} = e;
        const tempString = 'template';
        const type = 'template_widget'; 
        const organization_id = props.selectedOrganization.organization.id;
        const template_id = props.checklistItem?.template;


        const file_name = e.target.files[0].name;
        const file_type = e.target.files[0].type;
        const file = e.target.files[0];

        setFileLoading(true);
        getTemplateAttachmentUploadURLApi(template_id, file_name, type, tempString, organization_id)
        .then((response) => {
            const res = response.data;
            consoleToLog('Response getUploadUrlForChecklistAttachmentApi: ', res);

            // Put the fileType in the headers for the upload
                const options = {
                    headers: {
                    "Content-Type": file_type,
                    }
                };
                axios.defaults.headers.common = {};
                axios.put(res.upload_signed_url, file, options)
                .then(result => {
                    consoleToLog('signed Request s3', result);
                    setFilePath(res.url);
                    setFileLoading(false);
                })
                .catch(error => {
                    consoleToLog('error: ', error.response);
                    setFileLoading(false);
                })

            })
            .catch((err) => {
                consoleToLog("File Upload Error: ", err);
                setFileLoading(false);
            });
    }

    return (
        <RightToLeftSideDrawer title='Update Task Widgets'
            fromChecklistDetails={props.fromChecklistDetails}
            openDrawer={props.openUpdateTaskWidgetDrawer}
            closeDrawer={props.handleCloseUpdateTaskWidgetDrawer}
            >
            <div className="modal__main">
                <Grid item container md={12} lg={12} direction="column">
                    {props.widgetObj?.type === 'rich_text' ?
                    <Grid item lg={12} style={{margin:"0px 0px 8px 0px"}}>
                        <Editor
                            ref={editorReference}
                            editorStyle={{ 
                                border: '1px solid lightgray',
                                minHeight: '200px',
                                scrollbarWidth: 'none',
                                lineHeight:1.5,
                                padding: '12px',
                                "&::-webkit-scrollbar": {
                                    display: "none"
                                }
                            }}
                            editorState={editorState}
                            onEditorStateChange={onEditorStateChange}
                            toolbar={toolbarConfig}
                            handlePastedText={() => false}
                            //readOnly={}
                        /> 
                    </Grid>
                    :
                    <Grid item lg={12} style={{margin:"0px 0px 8px 0px"}}>
                        <TextField
                            fullWidth
                            autoFocus
                            margin="dense"
                            style={{marginBottom:'8px', background:'#fff'}}
                            variant="outlined"
                            type="text"
                            value={downloadWidgetName}
                            inputProps={{ style: { 
                                cursor:'text',
                                color:'#212121',
                                lineHeight:'18px', 
                                padding:'8px 4px' } }} 
                            inputRef={(input) => {
                                if (input !== null) {
                                input.focus();
                                }
                            }}
                            onChange={(e) => setDownloadWidgetName(e.target.value)}  
                        />
                        <Grid item container alignItems='center'>
                            <Grid item>
                                <label>
                                    <input type="file" style={{display: "none"}} 
                                    id={props.widgetObj?.id.toString()} 
                                    onChange={(e) => { 
                                            onFileSelected(e)
                                    }}/>
                                    <Button variant="contained" color="primary" 
                                        onClick={() => document.getElementById(`${props.widgetObj?.id.toString()}`).click()}
                                        style={{minWidth:'20px', display:'block'}}>
                                        Select file to download
                                    </Button>  
                                        
                                </label>
                            </Grid>

                            <Grid item>
                                <Typography variant='body2' style={{marginLeft:'8px'}}>
                                    {
                                        fileLoading  ?
                                        <CircularProgress size={24} style={{marginLeft:'20px'}}/>
                                        :   
                                        props.widgetObj?.json_data.file_path.path ? props.widgetObj?.json_data.file_path.path.replace(/^.*[\\\/]/, "") : ''
                                    } 
                                </Typography>    
                            </Grid>
                        </Grid>
                    </Grid>
                    }

                    <div className="modal__footer1" >
                        <Button variant="contained" color="primary" 
                            className="modal__button"
                            onClick={onSaveClick}
                            >
                            {loading && <CircularProgress size={24} style={{marginRight:'8px'}}/>}
                            Save
                        </Button>
                        <Button variant="outlined" 
                            onClick={props.handleCloseUpdateTaskWidgetDrawer}
                            style={{
                                minWidth: '130px',
                            }}>
                            Cancel
                        </Button>
                    </div>
                </Grid>
            </div>
        </RightToLeftSideDrawer>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization
})

export default connect(mapStateToProps)(UpdateTaskWidgetsDrawer);