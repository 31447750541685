import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Grid, Typography, Button, IconButton, Tooltip, Dialog, TextField, CircularProgress} from '@material-ui/core';
import {addOrgUserSupportEmailApi} from '../../services/genieService';
import {connect} from 'react-redux';
import { useSnackbar } from 'notistack';
import { emailValidator } from '../../util/validator';
import { consoleToLog } from '../../util/AppUtil';
import {addEmailToOrg} from '../../actions/selectedOrganization';

const useStyles = makeStyles((theme) => ({
    dialogTitle : {
        padding:'16px 24px', 
        display:'flex',  
        justifyContent:'start', 
        alignItems:'center',
        fontSize:'18px',
        fontWeight:'600'
    },
    MuiDialogpaper: {
        scrollbarWidth: 'none',
        overflow:'visible',
        paddingBottom:'5px',
        "&::-webkit-scrollbar": {
            display: "none"
        }   
    },
    iconStyles: {
        fontSize:'16px', 
        color:'#555', 
        marginRight:'2px', 
        marginTop:'-2.5px'
    }
}))

const AddEmailDialogModal = (props) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();

    const [email, setEmail] = useState('');

    const callAddEmailApi = ( ) => {
        if(!email) {
            enqueueSnackbar('Please enter email you want to add', {variant: 'error'});
            return;
        } else {
            let emailIsValid = emailValidator(email);
            if(!emailIsValid) {
                enqueueSnackbar('Please enter a valid email', {variant: 'error'});
                return;
            }
        }

        const duplicateEmail = props.orgEmailList.filter((emailObj) => emailObj.email === email);
        if(duplicateEmail && duplicateEmail.length > 0) {
            enqueueSnackbar('Email already present!!', {variant:"error"});
            return;
        }

        const organization_id = props.selectedOrganization.organization.id;
        const support = props.fromSupportEmail ? true : false;
        const emailType = props.fromSupportEmail ? 'support_email' : 'user_email';

        addOrgUserSupportEmailApi(organization_id, email, support, emailType)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response addOrgUserSupportEmailApi: ', res);
                enqueueSnackbar('Email added successfully!!', {variant:'success'});
                // props.fromSupportEmail ?
                // props.setSupportEmails([...props.supportEmails, res])
                // :
                // props.setUserEmails([...props.userEmails, res])
                props.addEmailToOrg(res);
                closeAddEmailModal();
            })
            .catch((e) => {
                consoleToLog('Error addOrgUserSupportEmailApi: ', e.response);
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant:'error'
                    });
                    return;
                }
            })
        
    }

    const closeAddEmailModal = () => {
        props.handleDialogClose();
        setEmail('');
    }

    return (
        <Dialog
                open={props.openDialog}
                //TransitionComponent={Transition}
                classes={{
                    paper: classes.MuiDialogpaper}}
                fullWidth
                onClose={closeAddEmailModal}>
                <Typography
                    className={classes.dialogTitle}>
                    <span>Add Email</span>

                </Typography>

                    
                <Grid item container 
                    alignItems='center'
                    justifyContent='center'>

                        <Grid item md={1}>
                            <Typography variant='body2'>
                                Email:
                            </Typography>
                        </Grid>

                        <Grid item md={8}>
                            <TextField fullWidth
                                variant='outlined'
                                margin='dense'
                                placeholder='Enter you email'
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Grid>

                </Grid>

                <Grid item container justifyContent='center' spacing={1}
                    style={{padding:'16px 24px'}}>   
                    <Grid item>
                        <Button variant='contained' color='primary' 
                            onClick={callAddEmailApi}>
                            OK
                        </Button>
                    </Grid>

                    <Grid item>
                        <Button variant='outlined' color='primary' 
                            onClick={closeAddEmailModal}>
                            Cancel
                        </Button>
                    </Grid>
                </Grid> 
            </Dialog>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    orgEmailList: state.organizationInfo.orgEmailList,
});

const mapDispatchToProps = (dispatch) => ({
    addEmailToOrg: (email) => dispatch(addEmailToOrg(email))
})

export default connect(mapStateToProps, mapDispatchToProps)(AddEmailDialogModal);