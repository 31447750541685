import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import authReducer from '../reducers/auth';
import organizationReducer from '../reducers/organization';
import selectedOrganizationReducer from '../reducers/selectedOrganization';
import loadingReducer from '../reducers/loading';
import filtersReducer from '../reducers/filters';
import overlayReducer from '../reducers/overlayReducer';
import notificationsReducer from '../reducers/notifications';
import activityReportsReducer from '../reducers/activityReports';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

//Store creation:
const storeObj =  (persistedState) => {
    const store = createStore(
        combineReducers({
            auth: authReducer,
            organizations: organizationReducer,
            organizationInfo: selectedOrganizationReducer,
            loading: loadingReducer,
            filters: filtersReducer,
            overlay: overlayReducer,
            notifications: notificationsReducer,
            activityReports: activityReportsReducer
        }),
        persistedState,
        composeEnhancers(applyMiddleware(thunk))
    );

    return store;
};

export default storeObj


