import React, {useState, useEffect, createRef} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Typography, Button, Avatar} from '@material-ui/core';
import {connect} from 'react-redux';
import MobileCreateOrganizationDrawer from './MobileCreateOrganizationDrawer';
import { useIsMount } from '../../components/useIsMount';

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        marginTop:'16px',
        overFlowY: 'auto',
        overflowX:'hidden',
    },
    tabPanelContainer: {
        flexGrow: 1,
        padding:'15px',
        //height:'100vh',
        marginBottom:'25px',
    },      
    circularProgress: {
        marginLeft: 0,
        marginRight: theme.spacing(2),
        color: 'white'
    },
    orgColumn : {
        background: '#FFFFFF',
        padding:'8px 8px',
        margin:'0px 0px 8px 0px',
        borderTop:'1px solid rgba(0,0,0,0.1)',
        borderBottom:'1px solid rgba(0,0,0,0.1)',
        '&:last-child' : {
            marginBottom: '0px'
        },
    },
    avatarStyle: {
        width:"38px !important",
        height:"38px !important",
        fontSize:'16px !important',
        fontWeight:'600 !important',
        transform: 'translateY(3px)'
    }, 
    nameStyle: {
        fontSize:'16px', 
        fontWeight:'600', 
        maxWidth:'250px', 
        overflow:'hidden', 
        whiteSpace:'nowrap', 
        textOverflow:'ellipsis'
    },
    footer: {
        position:'absolute',
        width:'100%',
        bottom:'0px',
        padding:'16px',
        background:'white',
        borderTop:'1px solid rgba(0,0,0,0.1)'
    }

}))

const MobileMyOrganizations = (props) => {
    const classes = useStyles();
    const isMount = useIsMount();

    const [openDrawer, setOpenDrawer] = useState(false);
    const [scrollDown, setScrollDown] = useState(false);

    const myDivToFocus = createRef();

    const handleDrawerOpen = () => {
        setOpenDrawer(true);
    }

    const handleDrawerClose = () => {
        setOpenDrawer(false);
    }

    useEffect(() => {
        if(!isMount) {
            if(myDivToFocus && myDivToFocus.current && scrollDown) {
                myDivToFocus.current.scrollIntoView({
                    block: 'nearest'
                })
                setScrollDown(false);
            }
        }

    }, [scrollDown]);

    return (
        <div className={classes.mainContainer}>
            <Grid item container direction='column'
                className={classes.container}>
                <Grid item xs={12} style={{marginTop:'8px', padding:'0 12px'}}>
                    <Button fullWidth 
                        onClick={handleDrawerOpen}
                        className='add_button_style'>
                        Create Organization
                    </Button>
                </Grid>

                <Grid item xs={12} style={{marginTop:'16px'}}>
                    {props.organizations && props.organizations.length > 0 &&
                        props.organizations.map((org) => {
                            const orgArr = org.organization.name.split(" ");
                            let orgStr1 = orgArr[0];
                            let orgStr2 = ((orgArr[1] && orgArr[1] !== '-') && orgArr[1]) || ((orgArr[1] && orgArr[1] === '-') && orgArr[2]) || '';
                            const finalStr = orgStr1.charAt(0).toUpperCase() + orgStr2.charAt(0).toUpperCase();
                        return <Grid item container 
                                    className={classes.orgColumn}>

                                    <Grid item xs={12} sm={12} ref={myDivToFocus}>
                                        <Grid item container>
                                            <Grid item>
                                                <Avatar className={classes.avatarStyle}>
                                                    {finalStr}    
                                                </Avatar>
                                            </Grid>
                                            <Grid item className={classes.memberText}>
                                                <Grid item container direction='column' style={{marginLeft: "16px"}}>
                                                    <Grid item>
                                                        <Typography className={classes.nameStyle}>
                                                            {org.organization.name}
                                                        </Typography>
                                                        <Typography variant="subtitle1" style={{color:'#555555'}}>
                                                            {org.organization.email}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                        </Grid>                                                
                                    </Grid>
                                </Grid>
                        })
                    }   
                </Grid>
            </Grid>

            <MobileCreateOrganizationDrawer openDrawer={openDrawer}
                handleDrawerClose={handleDrawerClose}
                setScrollDown={setScrollDown}
                />
        </div>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    organizations: state.organizations
});

export default connect(mapStateToProps)(MobileMyOrganizations);