import React, { useState, useEffect, createRef} from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Divider, Button } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
import { useIsMount } from "../../../components/useIsMount";
import moment from "moment";
import Fab from "@material-ui/core/Fab";
import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
import { MentionsInput, Mention } from "react-mentions";
import CircularProgress from "@material-ui/core/CircularProgress";
import {addChecklistNoteApi, editChecklistNoteApi, deleteChecklistNoteApi} from "../../../services/ChecklistService";
import { consoleToLog, trackGAEvent } from "../../../util/AppUtil";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
    menuListItems: {
        paddingRight: "26px",
    },
    commentSection: {
        padding: "5px 5px",
        fontSize: "12px",
        marginLeft: "10px",
        marginTop: "10px",
        marginBottom: "10px",
        boxShadow: "0px 0px 6px 0px #140d0d26",
        minWidth: "50rem",
        maxWidth: "57rem",
        width: "auto",
    },
    checklistDetailHeader: {
        fontWeight: 500,
        fontSize: "20px",
    },
    checklistDetailStatus: {
        borderRadius: "5px",
        padding: "2px 10px 2px 10px",
        color: "#656565",
        border: `1px solid #656565`,
        maxWidth: "12rem",
        width: "auto",
    },
    checklistDetailStatusIcon: {
        marginLeft: "2rem",
    },
    checklistDetailContent: {
        fontSize: "15px",
        padding: "18px 0px",
        color: "black",
        lineHeight: "25px",
    },
    checklistDetailBtn1: {
        borderRadius: "5px",
        padding: "2px 10px 2px 10px",
        color: "#656565",
        border: `1px solid #656565`,
        margin: "10px 10px 10px 0px",
    },
    checklistDetailBtn2: {
        borderRadius: "5px",
        padding: "2px 10px 2px 10px",
        color: "#dd584d",
        border: `1px solid #dd584d`,
    },
    checklistDetailAct: {
        fontWeight: "500",
        fontSize: "20px",
        marginTop: "10px",
        // marginBottom: "10px",
    },
    commentIconContainer: {
        backgroundColor: "#3d92ff",
        minHeight: "30px",
        height: "30px",
        width: "30px",
        boxShadow: "none",
    },
    commentIcon: {
        height: "15px",
        width: "15px",
        color: "white",
        transform: "scaleX(-1)",
        fontSize: "1rem",
    },
    commentedBy: {
        fontSize: "14px",
        marginLeft: "10px",
        color: "black",
    },
    commentedOn: {
        fontSize: "13.5px",
        color: "#5e6265",
        margin: "0px 5px",
    },
    commentTime: {
        fontSize: "14px",
        marginLeft: "10px",
        color: "#000",
    },
}));

const MobileChecklistNotes = (props) => {
    const isMount = useIsMount();
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();

    const [notes, setNotes] = useState(props.checkListItem.notes);

    const [mentions, setMentions] = useState();
    const [selectedNoteForEditing, setSelectedNoteForEditing] = useState(undefined);
    const [text, setText] = useState("");
    const [notesLoading,setNotesLoading] = useState(false);
    const myDivToFocus = createRef();  //for comment scrolling

    let usersData = props.members.map((member) => ({
        id: member.user.id,
        display: `${member.user.firstname} ${member.user.lastname}`,
    }));

    //for edit delete user
    const currentAuthUser = props.auth.user.id;
    
    const organizationId = props.selectedOrganization.organization.id;
    const checklistId = props.checkListItem.id;

    useEffect(() => {
        if(!isMount){
            setNotes(props.checkListItem.notes)
        }
    }, [props.checkListItem.notes]);

    const onScrollToCommentSection = () => {
        if(myDivToFocus.current){
            myDivToFocus.current.scrollIntoView({
                inline: "nearest",
                behavior: "smooth",
                block: "start",
            });
        }
    } 


const NotesItem = () => {
    const classes = useStyles();

    const handleDeleteNote = (newNotesArray) =>{
        props.onNoteDeleted(newNotesArray);
    }

    const onNoteDelete = (noteObj) =>{
        const organization_id = organizationId;
        const checklist_id = checklistId;
        const comment_id = noteObj.id;
            
        if (window.confirm('Are you sure to delete the note?')) {
    
            deleteChecklistNoteApi(comment_id, checklist_id, organization_id)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response deleteChecklistNoteApi: ", res);
                enqueueSnackbar(res.message, {
                    variant: "success",
                });
                const newNotesArray = notes.filter((note) => note.id !== res.comment.id);
                setNotes(newNotesArray);
    
                // to parent
                handleDeleteNote(newNotesArray);
                trackGAEvent(props.selectedOrganization.organization.name, 'Mobile Checklist Delete note', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
            })
            .catch((e) => {
                consoleToLog("deleteChecklistNoteApi error", e);
            });
            }
        }

    const getReadableNotesText = (note) => {
        var readableNoteText = note.text;
        let  numberPattern = /\d+/g;
        let num = readableNoteText.match(numberPattern);
        num?.join();
        let user_id = Number(num)
        
        readableNoteText = readableNoteText.replace(/\n\r?/g, "<br />");//add <br>
        readableNoteText = readableNoteText.split('@[').join(`<b style=color:${user_id === currentAuthUser ? '#0B4C8C' :'black'};background:#E8F5FB;padding:4px;border-radius:4px>`);
        readableNoteText = readableNoteText.split(']').join("</b>");
        readableNoteText = readableNoteText.split(/\(.+?\)/).join('');
        return readableNoteText;
        
    }

    return (
        <div className="notesMain">
            {notes && notes.map((note)=>{
                return (
                <Grid item container xs={12}
                    key={note.id}
                    style={{ marginTop: "0px", padding: "10px 0px" }}
                    className="notesParent">

                    <Grid item direction="column">
                        <Fab className={classes.commentIconContainer}>
                            <ChatBubbleOutlineIcon className={classes.commentIcon} />
                        </Fab>
                        <Divider orientation="vertical" className="horizontalDivider" />
                    </Grid>
            
                    <Grid item direction="column" xs={10}>
                        <Grid item container direction="row">
                            <Grid item className="noteFullName">
                                {note.comment_user!==null ? note.comment_user.firstname + " " + note.comment_user.lastname : note.name}
                            </Grid>
                            
                            <Grid item className="noteTime">
                                {moment(note.created_at).fromNow()}
                            </Grid>
                        </Grid>

                        <Grid item container direction="row" className="notesContainer" sm={12}>
                            <Grid item style={{marginLeft:'8px'}}
                                className="noteText"
                                xs={11}
                                dangerouslySetInnerHTML={{ __html: getReadableNotesText(note) }}>
                            </Grid>
                        </Grid>
                        {(note.comment_user!==null && currentAuthUser === note.comment_user.id) &&
                            <div style={{display: "flex", marginLeft:'-16px'}}>
                                <Button size="small"
                                    style={{padding:'4px'}}
                                    onClick={(e) => onNoteEdit(note)}
                                >
                                    Edit
                                </Button>
                                <Button size="small"
                                    style={{padding:'4px'}}
                                    onClick={() => onNoteDelete(note)}
                                >
                                    Delete
                                </Button>
                            </div> }
                    </Grid>
                </Grid>
                )
            })}
        </div>
    );
}

const addNewNote = (text) =>{
    const checklist_id = checklistId;
    const organization_id = organizationId;
    const mention_users = mentions.map(mentionObj => mentionObj.id);
    
    setNotesLoading(true);
    addChecklistNoteApi(checklist_id, mention_users, text, organization_id)
    .then((response) => {
        const res = response.data;
        consoleToLog ("Response addChecklistNoteApi: ", res);
        var newNote = res.comment
        var newNotesArray = [...notes,newNote];
        setNotes(newNotesArray);
        setNotesLoading(false);
        enqueueSnackbar(res.message, {
            variant: "success",
        });
        setText("");
        // to parent
        props.onNoteAdded(newNotesArray);
        trackGAEvent(props.selectedOrganization.organization.name, 'Mobile Checklist Add note', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
    })
    .catch((e) => {
        consoleToLog("addChecklistNoteApi error", e);
    });
}

const editNote = (text) =>{
    const organization_id = organizationId;
    const checklist_id = checklistId;
    const mention_users = mentions.map(mentionObj => mentionObj.id);
    const comment_id = selectedNoteForEditing.id;

    editChecklistNoteApi(checklist_id, mention_users, text, comment_id, organization_id)
    .then((response) => {
        const res = response.data;
        consoleToLog("Response editChecklistNoteApi: ", res);
        enqueueSnackbar(res.message, {
            variant: "success",
        });
        const newNotesArray = notes.map((note) => {
            return (note.id === res.comment.id) ? res.comment : note;
        })
        setNotes(newNotesArray);
        setText("");
        setSelectedNoteForEditing(undefined);
        trackGAEvent(props.selectedOrganization.organization.name, 'Mobile Checklist Edit note', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
    })
    .catch((e) => {
        consoleToLog("editChecklistNoteApi error", e);
    });
}

const clearCurrentCommentData = () => {
    setText("");
    setMentions([]);
    setSelectedNoteForEditing(undefined);
};

const onNoteEdit = (noteObj) =>{
    setSelectedNoteForEditing(noteObj);
    setMentions([]);
    setText(noteObj.text);  
    onScrollToCommentSection();
}

const onSaveClick = (e) => {
    e.preventDefault();

    const textValue = text.trim();
    if (!text) {
        consoleToLog('Note can not be empty!');
        return;
    };

    //add or edit
    if (selectedNoteForEditing) {//edit note
        //if note is not changed do nothing
        if (selectedNoteForEditing.text === text) {
            enqueueSnackbar("Note is not changed!", {
                variant: "error",
            });
            return;
        } else {
            editNote(text);
        }
    } else {//add note
        addNewNote(text);
    }
}

return (
    <>
        <div style={{marginTop:'16px', padding:'0px 12px'}}>
            <Grid item sm={12} style={{marginTop: selectedNoteForEditing ? '2rem' : ''}}>
                <div ref={myDivToFocus} className="mentionWrapper">
                    <MentionsInput
                        markup="@[__display__](__id__)"
                        className="mentions"
                        placeholder="Use @ to notify a team member"
                        value={text}
                        onChange={(e, newValue, newPlainTextValue, mentions) => {
                        setText(e.target.value);
                        setMentions(mentions);
                        }}
                    >
                        <Mention className="mentions__mention" data={usersData}/>
                    </MentionsInput>
                </div>
                <Button
                    variant="outlined"
                    className={classes.checklistDetailBtn1}
                    onClick={onSaveClick}
                    name="send"
                >
                    Send 
                    {notesLoading && (
                        <CircularProgress
                        style={{ color: "black",height:"15px",width:"15px",marginLeft:"10px"}}
                        size={10}
                        />
                    )}
                </Button>
                <Button
                    variant="outlined"
                    className={classes.checklistDetailBtn2}
                    onClick={clearCurrentCommentData}
                    >
                    Discard
                </Button>
            </Grid>
            <Grid item container>
                <Typography className={classes.checklistDetailAct}>Notes</Typography>
            </Grid>
                {NotesItem()}
        </div>
    </>
);
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    members: state.organizationInfo.members,
});

export default connect(mapStateToProps, null)(MobileChecklistNotes);
