import React,{useEffect, useState} from 'react';
import {Grid, Typography, Dialog, Button, Tabs, Tab, Paper, AppBar, Slide} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import HomeWorkOutlinedIcon from '@material-ui/icons/HomeWorkOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import LocalPhoneOutlinedIcon from '@material-ui/icons/LocalPhoneOutlined';
import MapOutlinedIcon from '@material-ui/icons/MapOutlined';
import FolderOutlinedIcon from '@material-ui/icons/FolderOutlined';
import MobileClientChecklist from './client/MobileClientChecklist';
import {getClientAuditTrailListApi} from "../../services/genieService";
import MobileAuditTrailComponent from './checklist_details/MobileAuditTrailComponent';
import { consoleToLog } from '../../util/AppUtil';
import { useIsMount } from '../../components/useIsMount';
import {connect} from 'react-redux';
import {getClientAttachmentListApi} from '../../services/authService';
import MobileClientAttachments from './client/MobileClientAttachments';
import MobileClientContacts from './client/MobileClientContacts';
import MobileCustomFields from './client/MobileCustomFields';
import MobileClientInvoiceSettings from './client/MobileClientInvoiceSettings';
import { getInvoiceConfigApi } from "../../services/invoiceService";

const useStyles = makeStyles((theme) => ({
    detailsBackground: {
        background: theme.palette.primary.light,
        overflowY:'auto',
        overflowX:'hidden'
    },
    detailsTopBarStatus: {
        padding:'4px 4px',
        textAlign: 'center',
        fontSize:'13px'
    },
    backIcon: {
        color: "#202020",
        border: " 1px solid #202020",
        padding: "4px 0px",
        fontSize: "18px",
        minWidth: "40px",
        lineHeight: "18px",
        margin: "15px 16px 10px 16px",
    },
    detailsTopBarTitle: {
        marginTop:'10px',
        fontSize:'16px',
        fontWeight:'500'
    },
    detailsTopBarOptionIcon: {
        marginTop:'6px'
    },
    tabsAppbar: {
        boxShadow: "none",
        backgroundColor: "transparent",
        marginTop:'8px',
        //marginLeft:'16px'
    },
    mobileHeading: {
        fontSize: '16px',
        fontWeight: 600,
        marginLeft:'16px',
    },
    displayFlex:{
        display: 'flex'
    },
    iconStyles: {
        fontSize:'25px',
        color:'#555'
    },
    customMarginTop: {
        marginTop:'14px',
        display: 'flex'
    },
    customMarginLeft: {
        marginLeft:'16px'
    },
    tabsAppbar: {
        boxShadow: "none",
        backgroundColor: "transparent",
        marginTop:'8px',
        //marginLeft:'16px'
    },
    tabsMain: {
        minWidth: "100px",
        color: "#5e6265",
    }
}))

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const MobileClientDetailsModal = (props) => {
    const classes = useStyles();
    const isMount = useIsMount();

    const [value, setValue] = useState('0');
    const [clientObj, setClientObj] = useState(props.clientInfo);

    const clientEmails = props.clientInfo?.email && props.clientInfo?.email.length > 0 ? props.clientInfo?.email.split(',') : [];

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    // pagnation logic for  attachments
    const [pageD, setPageD] = useState(1);
    const [nextPageD, setNextPageD] = useState(false);
    const [isMoreLoadingD, setIsMoreLoadingD] = useState(false);
    const [attachmentList, setAttachmentList] = useState(undefined);
    const [documentsLoading,setDocumentsLoading]=useState(false);
    const [invoiceConfigs, setInvoiceConfigs] = useState([]);
    const [selectedAccountId, setSelectedAccountId]=useState(props.clientInfo?.invoice_account_id);
    const [invoiceSettingLoading, setInvoiceSettingLoading]=useState(false);


    //pagination for client audit trail
    const [emailLoading, setEmailLoading] = useState(false);
    const [emailsList, setEmailsList] = useState([]);
    const [pageEmail, setPageEmail] = useState(0);
    const [nextPageEmail, setNextPageEmail] = useState(false);
    const [isMoreLoadingEmail, setIsMoreLoadingEmail] = useState(false);


    useEffect(()=>{
        if (!isMount) {
            setPageEmail(0);

            if (value === '2') {
                getClientAttachmentListApiCall();
            }

            if (value === '4') {
                getInvoiceSettings();
            }
            
            if (value === '5') {
                getClientAuditTrailListCall();
            }
        }
    },[value]);

    const handleMoreLoadingEmail = () => {
        setIsMoreLoadingEmail(!isMoreLoadingEmail);
    }

    const handleMoreLoadingD = () => {
        setIsMoreLoadingD(!isMoreLoadingD);
    }

    const onAttachmentDelete = (attachmentId) => {
        var updatedDeleteAttachmentArray = attachmentList.filter((attachment) => attachment.id !== attachmentId);
        // to parent
        setAttachmentList(updatedDeleteAttachmentArray);
    };

    const getClientAttachmentListApiCall = () => {
        const company_id = clientObj.id;
        const organization_id = props.selectedOrganization.organization.id;
    
        if(!isMoreLoadingD) setDocumentsLoading(true);
        getClientAttachmentListApi(company_id, organization_id, pageD)
        .then((response) => {
        const res = response.data;
        consoleToLog("Response getClientAttachmentListApi: ", res);
        setDocumentsLoading(false);
        if(isMoreLoadingD) {
            const newAttachments = attachmentList.concat(res.company_attachments);
            setAttachmentList(newAttachments);
            setPageD(pageD + 1);
            setNextPageD(res.next_page);
            setIsMoreLoadingD(!isMoreLoadingD);
        } else {
            setNextPageD(res.next_page);
            if(res.next_page) setPageD(pageD + 1);
            setAttachmentList(res.company_attachments);
            // setTotal(res.total);
            }
        
        })
        .catch((error) => {
            consoleToLog("getClientAttachmentListApi res", error);
        });
    };

    const getClientAuditTrailListCall = () => {
        const company_id = clientObj.id;
    
        if(!isMoreLoadingEmail) setEmailLoading(true);
            getClientAuditTrailListApi(company_id, pageEmail)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response getClientAuditTrailListApi: ", res);
                setEmailLoading(false);
                if(isMoreLoadingEmail) {
                    const newItems = emailsList.concat(res.emails);
                    setEmailsList(newItems);
                    setPageEmail(pageEmail + 1);
                    setNextPageEmail(res.next_page);
                    setIsMoreLoadingEmail(!isMoreLoadingEmail);
                } else {
                    setNextPageEmail(res.next_page);
                    if(res.next_page) setPageEmail(pageEmail + 1);
                    setEmailsList(res.emails);
                    // setTotal(res.total);
                    }
                })
                .catch((err) => {
                    consoleToLog("getClientAuditTrailListApi err", err);
                    setEmailLoading(false);
                });
    };

    const getInvoiceSettings = () => {
        const organization_id = props.selectedOrganization.organization.id;
        const company_id = clientObj.id;

        setInvoiceSettingLoading(true);
        getInvoiceConfigApi(organization_id, company_id)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response getInvoiceConfigApi: ", res);
                setInvoiceConfigs(res.invoice_configs);
                //setSelectedAccountId(res.invoice_account);
                setInvoiceSettingLoading(false);
            })
            .catch((error) => {
                consoleToLog("getInvoiceConfigApi res", error);
                setInvoiceSettingLoading(false);
            });
    }

    return (
        <Dialog fullScreen
            open={props.openDialog}
            onClose={props.handleDetailsDialogClose}
            TransitionComponent={Transition}
            classes={{
                paper: classes.detailsBackground
            }}
            >
                <Grid item md={12}>
                    <Grid item container alignItems='center'>
                        <Grid item xs={2}>
                            <Button
                                variant="outlined"
                                className={classes.backIcon}
                                onClick={props.handleDetailsDialogClose}
                            > 
                                <ArrowBackIcon /> 
                            </Button>
                        </Grid>

                        <Grid item xs={10}>
                            <Typography variant="body1" 
                                className={classes.mobileHeading}>
                                    {props.clientInfo?.name}
                            </Typography>
                        </Grid>
                    </Grid>
                
                <div>
                    <AppBar
                    position="static"
                    color="default"
                    className={classes.tabsAppbar}
                    >
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="secondary"
                            textColor="secondary"
                            aria-label="full width tabs example"
                            variant="scrollable"
                        >
                            <Tab
                                label="Client Info"
                                value={'0'}
                                className={classes.tabsMain}
                            />
                            <Tab
                                label="Custom fields"
                                value={'1'}
                                className={classes.tabsMain}
                            />
                            <Tab
                                label="Documents"
                                value={'2'}
                                className={classes.tabsMain}
                            />
                            <Tab
                                label="Checklists"
                                value={'3'}
                                className={classes.tabsMain}
                            />
                            {props.accountList && props.accountList.length > 0 && 
                            <Tab
                                label="Invoice Settings"
                                value={'4'}
                                className={classes.tabsMain}
                            />
                            }
                            <Tab
                                label="Audit Trail"
                                value={'5'}
                                className={classes.tabsMain}
                            />
                            <Tab
                                label="Contacts"
                                value={'6'}
                                className={classes.tabsMain}
                            />
                            
                        </Tabs>
                    </AppBar>
                    <div style={{ display: value === '0' ? 'block': 'none', marginTop:'16px', padding: "0px 16px 0px 16px"}}>
                        <Grid item xs={12} style={{marginTop:'16px'}}>
                            <Grid item className={classes.displayFlex}>
                                <Grid item xs={1}>
                                    <HomeWorkOutlinedIcon className={classes.iconStyles}/>
                                </Grid>

                                <Grid item xs={11} 
                                    className={classes.customMarginLeft}>
                                    <Typography variant='body2'>
                                        Client Name
                                    </Typography>
                                    <Typography variant='body1' 
                                        className={classes.textStyle}>
                                        {props.clientInfo?.name}
                                    </Typography>
                                </Grid>
                            </Grid>

                            {clientEmails && clientEmails.length > 0 &&
                            <Grid item className={classes.customMarginTop}>
                                <Grid item xs={1}>
                                    <EmailOutlinedIcon className={classes.iconStyles}/>
                                </Grid>

                                <Grid item xs={11}
                                    className={classes.customMarginLeft}>
                                    <Typography variant='body2'>
                                        Email Adresses
                                    </Typography>
                                {
                                    clientEmails && clientEmails.length > 0 &&
                                    clientEmails.map((email) => {
                                        return <Typography key={email} variant='subtitle1' 
                                                    className={classes.textStyle}>
                                                        {email}
                                                </Typography>
                                        })
                                }
                                </Grid>
                            </Grid>}

                            {props.clientInfo?.phone_number && 
                            <Grid item className={classes.customMarginTop}>
                                <Grid item xs={1}>
                                    <LocalPhoneOutlinedIcon className={classes.iconStyles}/>
                                </Grid>

                                <Grid item xs={11}
                                    className={classes.customMarginLeft}>
                                    <Typography variant='body2'>
                                        Mobile Number
                                    </Typography>
                                    <Typography variant='body1' 
                                        className={classes.textStyle}>
                                        {props.clientInfo?.phone_number}
                                    </Typography>
                                </Grid>
                            </Grid>}

                            {props.clientInfo?.address &&
                            <Grid item className={classes.customMarginTop}>
                                <Grid item xs={1}>
                                    <MapOutlinedIcon className={classes.iconStyles}/>
                                </Grid>

                                <Grid item xs={11}
                                    className={classes.customMarginLeft}>
                                    <Typography variant='body2'>
                                        Address
                                    </Typography>
                                    <Typography variant='body1' 
                                        className={classes.textStyle}>
                                        {props.clientInfo?.address}
                                    </Typography>
                                </Grid>
                            </Grid>}
                        </Grid>
                    </div>

                    <div style={{display: value === '1' ? 'block' : 'none'}}>
                        <MobileCustomFields clientObj={clientObj}
                            />
                    </div>

                    <div style={{display: value === '2' ? 'block' : 'none'}}>
                        <MobileClientAttachments clientObj={clientObj}
                            onAttachmentDelete={onAttachmentDelete}
                            attachmentList={attachmentList}
                            handleMoreLoadingD={handleMoreLoadingD}
                            documentsLoading={documentsLoading}
                            isMoreLoadingD={isMoreLoadingD}
                            nextPageD={nextPageD}
                            pageD={pageD}
                            tabValue={value}/>
                    </div>

                    <div style={{display: value === '3' ? 'block' : 'none'}}>
                        <MobileClientChecklist clientObj={clientObj}/>
                    </div>

                    <div style={{display: value === '4' ? 'block' : 'none'}}>
                        <MobileClientInvoiceSettings clientObj={clientObj}
                            invoiceConfigs={invoiceConfigs}
                            selectedAccountId={selectedAccountId}
                            invoiceSettingLoading={invoiceSettingLoading}
                            companyUpdated={props.companyUpdated}
                        />
                    </div>

                    <div style={{display: value === '5' ? 'block' : 'none'}}>
                        <MobileAuditTrailComponent clientObj={clientObj}
                            emailsList={emailsList} 
                            emailLoading={emailLoading}
                            handleMoreLoadingEmail={handleMoreLoadingEmail}
                            isMoreLoadingEmail={isMoreLoadingEmail}
                            nextPageEmail={nextPageEmail}
                            tabValue={value}
                            fromClientDetails={true}
                        />
                    </div>

                    <div style={{display: value === '6' ? 'block' : 'none'}}>
                        <MobileClientContacts clientObj={clientObj}
                        />
                    </div>
                </div>
                </Grid>
            </Dialog>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    accountList: state.organizationInfo.accountList
})

export default connect(mapStateToProps)(MobileClientDetailsModal);