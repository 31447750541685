import React, {useState, useEffect} from 'react';
import {Grid, Typography, Button, Avatar, Divider, Fab, CircularProgress} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import { consoleToLog, showEmailIcons } from '../../../util/AppUtil';
import {getIncomingOutgoingEmailListApi} from '../../../services/genieService';
import moment from 'moment'
import { useIsMount } from '../../../components/useIsMount';
import MobileEmailDetails from '../MobileEmailDetails';

const useStyles = makeStyles((theme) => ({
    allEmailsContainer: {
        minHeight: "35px",
        height: "35px",
        width: "35px",
        background: '#fff',
        boxShadow: "none",
        display:'flex',
        justifyContent:'center',
        aligItems:'center',
        position:'relative'
    },
    horizontalDivider: {
        display:'flex',
        width: "1.5px",
        backgroundColor: "#cacaca",
        marginTop: "0px",
        marginLeft:'17px',
        marginBottom: "0px",
        border:'none',
        '& last-child' : {
            height: "0",
        },
    },
    textBodyContainer: {
        paddingLeft:'16px', 
        cursor:'pointer',
        [theme.breakpoints.down(355)] : {
            paddingLeft:'28px', 
        }
    },
    fromToStyle: {
        fontSize:'14px',
        color:'#898989',
        marginRight:'4px'
    },
    emailRecipientStyle: {
        color:'#555555',
        fontSize:'14px',
        fontWeight:'500'
    },
    sentByUserStyle: {
        fontSize:'14px',
        fontWeight:'500',
        color:"#202020"
    },
    displayFlex: {
        display:'flex',
        aligItems:'center',
        marginLeft:'4px',
    },
    iconStyles: {
        fontSize:'13px',
        marginRight:'4px',
        position:'relative', 
        top:'4px'
    },
    subjectStyle: {
        fontSize:'14px',
        width:'auto',
        maxWidth:'auto',
        overflow:'hidden',
        whiteSpace:'nowrap',
        textOverflow:'ellipsis',
        margin:'4px 0px'
    },
    bodyStyle: {
        color:'#898989',
        width:'100%',
        flexWrap:'wrap',
    },
    outgoingIncomingStyle: {
        width: '15px',
        height:'15px',
        borderRadius:'50px',
        background:'#fff',
        position:'absolute',
        left:'24px',
        top:'0px',
        display:'flex',
        justifyContent:'center',
        aligItems:'center',
    }
}))

const MobileAuditTrailComponent = (props) => {
    const classes = useStyles();
    const isMount = useIsMount();

    const [emailList, setEmailList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [openEmailDetailsModal, setOpenDetailsModal] = useState(false);
    const [selectedEmailItem, setSelectedEmailItem] = useState(undefined);
    const [openDialog, setOpenDialog] = useState(false);

    useEffect(() => {
        if(!props.fromClientDetails) {
            fetchAllEmailList();
        }
    }, []);

    useEffect(() => {
        if(!isMount) {
            if(props.fromClientDetails) {
                setEmailList(props.emailsList);
            }
        }
    }, [!props.fromClientDetails, props.emailsList]);

    const fetchAllEmailList = () => {
        const checklist_id = props.checkListItem?.id;

        !props.fromChecklistDetails && setLoading(true);
        getIncomingOutgoingEmailListApi(checklist_id)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response getIncomingOutgoingEmailListApi: ', res);
                setLoading(false);

                setEmailList(res.emails);
            })
            .catch((e) => {
                consoleToLog('Error getIncomingOutgoingEmailListApi: ', e.response);
                setLoading(false);
                if(e.response.data && e.response.data.message) {
                    return;
                }
            })
    }

    const fromOutgoingMail = (emailObj) => {
        let toEmail = emailObj.to_email && emailObj.to_email.length > 0 ? emailObj.to_email : [];
        let ccEmail = emailObj.cc && emailObj.cc.length > 0 ? emailObj.cc : [];
        let bccEmail = emailObj.bcc && emailObj.bcc.length > 0 ? emailObj.bcc : [];
        let allEmailRecipientArr = [...toEmail, ...ccEmail, ...bccEmail];
        return (
            <Typography >
                <span className={classes.fromToStyle}>
                    To:
                </span> 
                <span className={classes.emailRecipientStyle}>
                    {allEmailRecipientArr.map((email) => email).join(', ')}
                </span>
            </Typography>
        )
    }

    const fromIncomingMail = (emailObj) => {
        return (
            <Typography>
                <span className={classes.fromToStyle}>
                    From:
                </span> 
                <span className={classes.emailRecipientStyle}>
                    {emailObj.from_name ? emailObj.from_name : emailObj.from_email}
                </span>
            </Typography>
        )
    }

    const showOutogingEmailAvatar = (emailObj) => {
        const firstname = emailObj?.user ? emailObj?.user.firstname : '';
        const lastname = emailObj?.user ? emailObj?.user.lastname : '';
        const fullName = firstname?.charAt(0).toUpperCase() + lastname?.charAt(0).toUpperCase();
        return fullName ? fullName : 'ME';
    }

    const showUserWhoSentEmail = (emailObj) => {
        const firstname = emailObj?.user ? emailObj?.user.firstname : '';
        const lastname = emailObj?.user ? emailObj?.user.lastname : '';
        const fullName = firstname + " " + lastname;
        return emailObj?.user ? fullName : 'Me';
    
    }

    const showInComingEmailAvatar = (emailObj) => {
        const fromNameArr = emailObj.from_name.split(" ");
        let nameStr1 = fromNameArr[0];
        let nameStr2 = fromNameArr[1] ? fromNameArr[1] : '';
        return nameStr1?.charAt(0).toUpperCase() + nameStr2?.charAt(0).toUpperCase();
    }

    const updateEmailArr = (triageObj) => {
        let updatedArray = emailList.map((email) => {
            if(email.id === triageObj.data.email_uuid) {
                email.comments = triageObj.data.comments;
            }
            return email;
        });
        setEmailList(updatedArray);
    }

    const loadMoreLogic = () => {
        return (
            <Grid item container>
                <Grid item lg={12}> 
                {props.nextPageEmail && !props.isMoreLoadingEmail &&
                    <div style={{textAlign: "center", margin:'16px 0px'}}>
                        <Button type="button"
                            color="secondary"
                            variant="contained"
                            onClick={() => {props.handleMoreLoadingEmail() }}>
                            Load More
                        </Button>
                    </div>}
                    { props.isMoreLoadingEmail &&
                        <CircularProgress size={35} style={{position:'relative', left:'50%', right:'50%', margin:'16px 0px'}}/>
                    }
                </Grid>
            </Grid>
        )
    }

    const handleDialogOpen = (emailObj) => {
        setSelectedEmailItem(emailObj);
        setOpenDialog(true);
    }

    const handleDialogClose = () => {
        setOpenDialog(false);
    }

    return (
        props.emailLoading ?
        <CircularProgress size={35} style={{position:'relative', left:'50%', right:'50%', margin:'16px 0px'}}/>
        :
        <div style={{marginTop:'16px', padding:'0px 8px'}}>
            {emailList && emailList.length > 0 ?
            emailList.map((emailObj) => {
            return <Grid item container alignItems='center' key={emailObj.id} style={{marginBottom:'16px'}}>
                        <Grid item xs={12}>
                            <Grid item container onClick={() => handleDialogOpen(emailObj)}>
                                <Grid item xs={1}>
                                    <Fab className={classes.allEmailsContainer} 
                                        style={{
                                            border: emailObj.received ? '1px solid #0473ff'  : '1px solid #4ea132',
                                            color: emailObj.received ? '#0473ff' : '#4ea132'
                                        }}>
                                        
                                        <div className={classes.outgoingIncomingStyle}
                                            style={{border: emailObj.received ? '1px solid #0473ff'  : '1px solid #4ea132'}}>
                                            <img src={showEmailIcons(emailObj.received ? 'in_coming' : 'out_going')} 
                                                style={{width:'7px'}}/>
                                        </div>
                                        <Typography style={{fontSize:'12px', marginTop:'2px'}}>
                                            {!emailObj.received ? showOutogingEmailAvatar(emailObj)  : showInComingEmailAvatar(emailObj)}
                                        </Typography>

                                    </Fab>
                                    <Divider orientation="vertical" className={classes.horizontalDivider} />
                                </Grid>

                                <Grid item xs={11} className={classes.textBodyContainer} 
                                    //onClick={() => handleEmailDialogOpen(emailObj)}
                                    >
                                    {emailObj.received ? fromIncomingMail(emailObj)  : fromOutgoingMail(emailObj)}

                                    <Grid item container alignItems='center' style={{marginTop:"4px"}}>
                                        {!emailObj.received && 
                                        <Grid item>
                                            <Typography className={classes.sentByUserStyle}>
                                                {showUserWhoSentEmail(emailObj)}
                                            </Typography>
                                        </Grid>}
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Typography className={classes.subjectStyle}>
                                            {/* {emailObj.subject ? emailObj.subject.replaceAll(/^Re:|Fwd:+/ig, '') : ''} */}
                                            {emailObj.subject ? emailObj.subject : ''}
                                        </Typography>
                                    </Grid>

                                    <Grid item container alignItems='center' style={{margin:'3px 0px'}}>
                                        <Grid item className={classes.displayFlex}>
                                            <i className={`flaticon-comment ${classes.iconStyles}`}></i>
                                            <Typography variant='subtitle1'>
                                                {emailObj.comments || emailObj.comments.length > 0 ? emailObj.comments.length : 0}
                                            </Typography>
                                        </Grid>

                                        <Grid item className={classes.displayFlex} style={{marginLeft:'16px'}}>
                                            <i className={`flaticon-attachment-1 ${classes.iconStyles}`}></i>
                                            <Typography variant='subtitle1'>
                                                {emailObj.attachments || emailObj.attachments.length > 0 ? emailObj.attachments.length : 0}
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Typography variant='subtitle1'
                                        dangerouslySetInnerHTML={{ __html: emailObj?.body_text?.replace(/<[^>]*>?/gm, '') }}
                                        className={classes.bodyStyle}>
                                    </Typography>

                                    <Typography variant='subtitle1' style={{color:'#333'}}>
                                        {moment(emailObj.created_at).format('DD MMM YYYY hh:mm a')}
                                    </Typography>

                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                })
                :
                <Grid item container>

                </Grid>
            }

            {(emailList && emailList.length > 0 && !props.emailLoading) && loadMoreLogic()}

            {openDialog && <MobileEmailDetails openDialog={openDialog}
                                selectedEmailItem={selectedEmailItem}
                                handleDialogClose={handleDialogClose}/>}
        </div>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization
})

export default connect(mapStateToProps)(MobileAuditTrailComponent);