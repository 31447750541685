export const OverlayView  = {
    SHOW_OVERLAY_LOADING  : 'overlay_loading',
    SHOW_OVERLAY_DOWNLOAD : 'overlay_download',
    NO_VIEW : 'no_view',
    ERROR_VIEW : 'error_view',
    SHOW_OVERLAY_ZIP_DOWNLOAD: 'overlay_zip_dpwnload'
};

export const setOverlayView = (exportView) => ({
    type: 'SET_OVERLAY_VIEW',
    exportView
})

export const setOverlayToken = (randomToken) => ({
    type: 'SET_OVERLAY_TOKEN',
    randomToken
})

export const setBulkObj = (bgObj) => ({
    type : 'SET_BULK_OBJ',
    bgObj
})

export const setIsBulkMoveToFolder = (isBulkMoveToFolder) =>({
    type:"SET_IS_BULK_MOVETO_FOLDER",
    isBulkMoveToFolder
})


export const setIsBulkCompleteChecklist = (isBulkCompleteChecklist) =>({
    type:"SET_IS_BULK_COMPLETE_CHECKLIST",
    isBulkCompleteChecklist
})

export const setOvelayMessage = (overlayMessage) => ({
    type :'SET_OVERLAY_MESSAGE',
    overlayMessage
})

export const setEmailItemId = (emailItemId) =>({
    type:"SET_EMAIL_ITEM_ID",
    emailItemId
})


export const setIsEmailSend = (isEmailSend) =>({
    type:"SET_IS_EMAIL_SEND",
    isEmailSend
})