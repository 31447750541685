import React, {useState} from 'react';
import RightToLeftSideDrawer from '../RightToLeftSideDrawer';
import {Grid, Typography, TextField, Button, CircularProgress} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import {consoleToLog} from '../../util/AppUtil';
import {onRequestApiCall} from '../../services/authService';
import {connect} from 'react-redux';

const useStyles = makeStyles((theme) => ({
    circularProgress: {
        marginLeft: 0,
        marginRight: theme.spacing(2),
        color: 'white'
      },
}))

const RequestCreditsModal = (props) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();

    const [smsCredits, setSmsCredits] = useState(0);
    const [loading, setLoading] = useState(false);

    const calculateSmsCredits = (e) => {
        const val = e.target.value;
        var credit = 0.30*val ;
        setSmsCredits(credit);
    }

    const onRequestCreditClick = (e) => {
        e.preventDefault();
        const organization_id = props.selectedOrganization.organization.id;

        const smsCredit = smsCredits;
        consoleToLog("smsCredit: ", smsCredit);

        if(!smsCredit){
            enqueueSnackbar("Please enter any one of credit!", {
                variant:'error'
            });
            return;
        }

        if(smsCredit && smsCredit<=0){
           enqueueSnackbar("Credit should be grater than 0!", {
               variant:'error'
           });
            return;
        }

         setLoading(true);
         //call api
         onRequestApiCall(organization_id, smsCredit)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response onRequestApiCall: ", res);
                setLoading(false);
                enqueueSnackbar(res.message, {
                    variant:'success'
                });
                setSmsCredits(0);
                props.handleCloseDrawer();
            })
            .catch((e) => {
                consoleToLog("onRequestApiCall error", e.response);
                setLoading(false);
                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant:'error'
                    });
                    return false;
                }
            });
    }

    return (
        <RightToLeftSideDrawer title="Request Credits" 
            openDrawer={props.openDrawer}
            closeDrawer={props.handleCloseDrawer}
        >
            <div className="modal__main">
                <Grid container md={12} lg={12}>
                    <Grid item lg={12}>
                        <Grid item container justifyContent="space-between">
                            <Typography variant="subtitle1" style={{marginBottom:'-8px'}}>
                                SMS Credits(0.30paisa/credit)
                            </Typography>

                            <Typography variant="body2" style={{marginBottom:'-8px'}}>
                                {(smsCredits && smsCredits !== 0) ? smsCredits + " Rs" : smsCredits}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid item lg={12}>
                        <TextField 
                            id="smsCredit"
                            fullWidth
                            margin="dense"
                            variant="outlined"
                            type="number"
                            placeholder="Enter SMS Credits" 
                            defaultValue={0}
                            InputLabelProps={{style: {fontSize: 13, marginTop:'3px'}}}
                            onChange={calculateSmsCredits}
                        
                        />
                    </Grid>   

                    <div className="modal__footer">
                        <Button variant="contained" color="primary" 
                            onClick={onRequestCreditClick}
                            className="modal__button">
                             {loading && <CircularProgress size={24} className={classes.circularProgress}/>}    
                            Request    
                        </Button>
                        <Button variant="outlined" style={{
                            minWidth: '130px',
                        }} onClick={props.handleCloseDrawer}>
                            Cancel
                        </Button>
                    </div> 
                </Grid>            
            </div>   
        </RightToLeftSideDrawer>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
});

export default connect(mapStateToProps)(RequestCreditsModal);