import React, { useState, useEffect } from "react";
import { Grid, Tooltip, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputBase from "@material-ui/core/InputBase";
import TextField from "@material-ui/core/TextField";
import { consoleToLog, getStatusNameByValue } from "./../util/AppUtil";
import { useIsMount } from "./useIsMount";
import {
  ruleType,
  labelType,
  schedulerType,
  actionType,
} from "./../util/AppUtil";
import IconButton from "@material-ui/core/IconButton";
import FormHelperText from "@material-ui/core/FormHelperText";
import {history} from "../routers/AppRouter";
import {Popover, InputAdornment} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectdisabled1: {
    color: "grey",
    backgroundColor: "black",
  },
  appBar: {
    background: "#F9F9F9",
    height: "45px",
    color: "#202020",
  },
  ruleText: {
    padding: "4px",
    fontSize: "14px",
  },
  radioParent: {
    padding: "1rem",
    border: "1px solid black",
  },
  selectParent: {
    // border: "1px solid black",
    // padding: "10px",
    padding: "4px 10px",
    marginTop: "10px",
    borderRadius: "3px",
    backgroundColor: "#f7f6f6",
  },
  selectText: {
    color: "black",
    fontSize: "15px",
    marginRight: "6px",
  },
  boldText: {
    fontWeight: "500",
    paddingLeft: "3px",
    paddingRight: "3px",
  },
  selectText2: {
    color: "black",
    marginLeft: "6px",
    fontSize: "15px",
  },
  selectContainer: {
    marginLeft: "8px",
    minWidth: "10px",
  },
  selectdisabled: {
    color: "grey",
  },
  circleText: {
    borderRadius: "100%",
    padding: "40px 30px",
    border: "1px solid black",
    fontSize: "14px",
    fontWeight: "500",
  },
  // durationText: {
  //   padding: "5px 0px",
  //   minWidth: "15px",
  //   height: "10px",
  //   marginTop: "-9px",
  //   paddingBottom: "0px",
  //   marginLeft: "4px",
  //   marginRight: "4px",
  //   width: "2rem",
  // },
  menuitem: {
    color: "grey",
  },
  selectOptions: {
    marginLeft: "6px",
    marginRight: "6px",
    fontSize: "15px",
    display: "inline-flex",
  },
  selectOptions2: {
    marginLeft: "4px",
    marginRight: "6px",
    fontSize: "15px",
    display: "inline-flex",
  },
  menuitem2: {
    color: "red",
  },
  whenContainer: {
    margin: "0rem 3rem -10rem 3rem",
  },
  whenContainer1: {
    margin: "0rem 3rem 0rem 3rem",
  },
  schedulerDivider: {
    height: "1.5px",
    backgroundColor: "black",
    marginTop: "3.2rem",
    marginLeft: "20px",
    marginRight: "-1px",
  },
  schedulerDivider1: {
    height: "1.5px",
    backgroundColor: "black",
    marginTop: "3.4rem",
    marginLeft: "25px",
    marginRight: "-1px",
  },
  actions: {
    textTransform: "uppercase",
    fontWeight: "500",
    fontSize: "14px",
    marginLeft: "2px",
  },
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
    fontSize: "3px",
  },
  icon: {
    borderRadius: "50%",
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto black",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: "black",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "black",
    },
  },
  MuiTextFieldroot : {
    '& .MuiFilledInput-input' :{
      padding:'6px 6px',
      background:'#f7f6f6',
      marginTop: (actionLabel) => {
        return actionLabel ? '-12px' :'-16px'
      }
    }
  },
  predefinedLabelClasses: {
    display:'flex',
    alignItems:'center',
  },
  paper: {
    padding: theme.spacing(1),
    boxShadow: "none",
    border: "1px solid rgba(0, 0, 0, 0.12)",
  },
  daysLabel : {
    background:'#C4C4C4', 
    padding:'14px', 
    borderRadius:'4px',
  },
  draftNameStyle: {
    width:'80px'
  },
  dueDateStyles: {
    marginBottom:'0px', 
    fontSize:'#333'
  }
}));

const StyledRadio = (props) => {
  const classes = useStyles();
  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
};

const Selectoperation = (props) => {
  const [operator, setOperator] = useState("none");
  const [showPlaceholder1, setShowPlaceholder1] = useState(operator === "none");
  const classes = useStyles();
  const isMount = useIsMount();

  useEffect(() => {
    selectOperationValidation();
    updateViews();
  }, []);

  useEffect(() => {
    if (!isMount) {
      selectOperationValidation();
      props.getOperationData(operator);
    }
  }, [operator]);

  const updateViews = () => {
    if (props.currentTriggerObj) {
      updateViewsWithValues();
    }
  };

  const updateViewsWithValues = () => {
    consoleToLog("Select Operation trigger: ", props.currentTriggerObj);
    if (Object.keys(props.currentTriggerObj).length === 0) {
      setOperator("none");
    } else {
      setOperator(props.currentTriggerObj.type);
    }
  };

  const handleOperatorChange = (event) => {
    setOperator(event.target.value);
  };

  const selectOperationValidation = () => {
    if (props.validateOperation) {
      if (operator === "none") {
        props.validateOperation("Select An operation!", true);
      } else {
        props.validateOperation("", true);
      }
    }
  };

  return (
    <Select
      labelId="demo-simple-select-label"
      displayEmpty
      id="demo-simple-select"
      disableUnderline
      value={operator}
      onChange={handleOperatorChange}
      style={{ color: "black" }}
    >
      <MenuItem disabled key="0" value={ruleType.NONE}>
        Select
      </MenuItem>
      <MenuItem value={ruleType.AND}>AND</MenuItem>
      <MenuItem value={ruleType.OR}>OR</MenuItem>
    </Select>
  );
};

const StatusOption = (props) => {
  const [value1, setValue1] = useState("none");
  const [value2, setValue2] = useState("none");
  const [showPlaceholder1, setShowPlaceholder1] = useState(value1 === "none");
  const [showPlaceholder2, setShowPlaceholder2] = useState(value2 === "none");
  const classes = useStyles();
  const statusArray = [
    "pending",
    "in_process",
    "awaiting_reply",
    "completed",
    "not_applicable",
  ];
  const isMount = useIsMount();

  useEffect(() => {
    statusOptionValidation();
    updateViews();
  }, []);

  useEffect(() => {
    if (!isMount) {
      statusOptionValidation();
    }
  }, [value1, value2]);

  useEffect(() => {
    if (!isMount) {
      if (props.currentTriggerObj.task) {
        updateViewsWithValues();
      }
    }
  }, [props.currentTriggerObj]);

  const updateViews = () => {
    if (props.currentTriggerObj) {
      updateViewsWithValues();
    }
  };

  const updateViewsWithValues = () => {
    if (Object.keys(props.currentTriggerObj.task).length === 0) {
      setValue1(value1);
      setValue2(value2);
    } else {
      setValue1(props.currentTriggerObj.task.template_task_id);
      setValue2(props.currentTriggerObj.task.status);
    }
  };

  const handleChange1 = (event) => {
    setValue1(event.target.value);
    props.getTaskStatusData(ruleType.TASK, event.target.value, value2);
  };

  const statusOptionValidation = () => {
    if (value1 === "none" && value2 === "none") {
      props.validateBeforeTrigger(
        false,
        "Task Name & Task Status Cannot Be empty !"
      );
    } else {
      if (value1 === "none") {
        props.validateBeforeTrigger(false, "Select Task Name!");
      }
      if (value2 === "none") {
        props.validateBeforeTrigger(false, "Select Task Status!");
      }
    }

    if (value1 !== "none" && value2 !== "none") {
      props.validateBeforeTrigger(true);
    }
  };

  const handleChange2 = (event) => {
    setValue2(event.target.value);
    props.getTaskStatusData(ruleType.TASK, value1, event.target.value);
  };

  return (
    <Grid
      item
      container
      className={classes.selectParent}
      direction="row"
      sm={11}
      style={{ display: "inline-block" }}
    >
      <span className={classes.selectOptions}>When</span>
      <Select
        value={value1}
        input={<InputBase />}
        onChange={handleChange1}
        onFocus={(e) => setShowPlaceholder1(false)}
        onClose={(e) => setShowPlaceholder1(e.target.value === undefined)}
        displayEmpty
        className={clsx(
          classes.selectOptions2,
          value1 === "none" ? classes.selectdisabled : null
        )}
      >
        <MenuItem
          className={clsx(
            classes.selectOptions2,
            !showPlaceholder1 ? classes.menuitemhidden : null
          )}
          key="0"
          disabled
          value="none"
        >
          Select Task Title
        </MenuItem>
        {props.taskList.map((task) => {
          return (
            <MenuItem key={props.fromChecklistDetails ? task.template_task : task.id} 
              value={props.fromChecklistDetails ? task.template_task : task.id}>
              {task.title} 
            </MenuItem>
          );
        })}
      </Select>
      <span className={classes.selectOptions}>Status changes to</span>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        onChange={handleChange2}
        disableUnderline
        value={value2}
        defaultValue={value2}
        input={<InputBase />}
        onFocus={(e) => setShowPlaceholder2(false)}
        onClose={(e) => setShowPlaceholder2(e.target.value === undefined)}
        className={clsx(
          classes.selectOptions2,
          value2 === "none" ? classes.selectdisabled : null
        )}
        displayEmpty
      >
        <MenuItem
          className={clsx(
            classes.menuitem,
            !showPlaceholder2 ? classes.menuitemhidden : null
          )}
          key="0"
          disabled
          value="none"
        >
          Select Status
        </MenuItem>
        {statusArray.map((status) => {
          return (
            <MenuItem value={status}>{getStatusNameByValue(status)}</MenuItem>
          );
        })}
      </Select>
    </Grid>
  );
};

const ChecklistOption = (props) => {
  const classes = useStyles();
  const [currentOption, setCurrentOption] = useState("Immediately");
  const [duration, setDuration] = useState(undefined);
  const isMount = useIsMount();

  useEffect(() => {
    checklistOptionValidation();
    updateViews();
  }, []);

  useEffect(() => {
    if (!isMount) {
      if (props.currentTriggerObj.creation) {
        updateViewsWithValues();
      }
    }
  }, [props.currentTriggerObj]);

  useEffect(() => {
    if (!isMount) {
      checklistOptionValidation();
      if (currentOption === "Immediately") {
        props.getChecklistData(0, true);
      }
      if (currentOption === "Duration") {
        props.getChecklistData(duration, false);
      }
    }
  }, [currentOption, duration]);

  const updateViews = () => {
    if (props.currentTriggerObj) {
      updateViewsWithValues();
    }
  };

  const updateViewsWithValues = () => {
    if (
      props.currentTriggerObj.creation &&
      Object.keys(props.currentTriggerObj.creation).length === 0
    ) {
      consoleToLog(
        "inside if condition of updateViewsWithValues",
        props.currentTriggerObj.creation
      );
    } else {
      if (props.currentTriggerObj.creation.immediate === true) {
        setCurrentOption("Immediately");
        setDuration(0);
        props.validateBeforeTrigger(true, "");
      } else {
        setCurrentOption("Duration");
        setDuration(props.currentTriggerObj.creation.duration);
        props.validateBeforeTrigger(true, "");
      }
    }
  };

  const handleChange = (event) => {
    setCurrentOption(event.target.value);
  };

  const handleTextChange = (e) => {
    setDuration(e.target.value.trim());
  };

  const checklistOptionValidation = () => {
    if (currentOption === "Duration") {
      props.validateBeforeTrigger(false, "Enter Duration");
      if (duration !== undefined && duration.length > 0) {
        props.validateBeforeTrigger(false, "");
      }
    }
    if (currentOption === "Immediately") {
      props.validateBeforeTrigger(true, "");
    }
  };

  return (
    <Grid item className={classes.selectParent} sm={11}>
      <Grid container>
        <RadioGroup
          aria-label="gender"
          name="customized-radios"
          className="radioGroup"
          onChange={handleChange}
          value={currentOption}
        >
          <FormControlLabel
            value="Immediately"
            control={<StyledRadio />}
            label="Immediately"
          />
          <FormControlLabel
            value="Duration"
            control={<StyledRadio />}
            label={
              <Grid>
                <span>After</span>
                <TextField
                  className="durationText"
                  value={duration}
                  onChange={handleTextChange}
                  disabled={currentOption === "Immediately"}
                />
                <span>days</span>
              </Grid>
            }
          />
        </RadioGroup>
      </Grid>
    </Grid>
  );
};

const LabelOptions = (props) => {
  const fromPredefinedTemplate = history.location.state?.fromPredefinedTemplate;

  const val = fromPredefinedTemplate ? '' : 'none'
  const [value1, setValue1] = useState(val);
  const [value2, setValue2] = useState(val);
  const [labelValue, setLabelValue] = useState(labelType.APPLIED);
  const [showPlaceholder1, setShowPlaceholder1] = useState(value1 === val);
  const [showPlaceholder2, setShowPlaceholder2] = useState(value2 === val);
  const classes = useStyles();
  const isMount = useIsMount();
  

  useEffect(() => {
    updateViews();
    labelOptionsValidation();
  }, []);

  useEffect(() => {
    if (!isMount) {
      updateViewsWithValues();
    }
  }, [props.currentTriggerObj]);

  useEffect(() => {
    if (!isMount) {
      labelOptionsValidation();
    }
  }, [value1, value2, labelValue]);

  const updateViews = () => {
    if (
      props.currentTriggerObj &&
      props.currentTriggerObj.label !== undefined
    ) {
      updateViewsWithValues();
    }
  };

  const updateViewsWithValues = () => {
    if (
      props.currentTriggerObj.label &&
      Object.keys(props.currentTriggerObj.label).length === 0
    ) {
      setLabelValue(labelType.APPLIED);
      setValue1(value1);
    } else {
      if (props.currentTriggerObj.label.active === true) {
        setLabelValue(labelType.APPLIED);
        setValue1(props.currentTriggerObj.label.label_id);
        // if applied clear removed data
        setValue2(val);
      } else {
        consoleToLog("Inside Remove");
        setLabelValue(labelType.REMOVED);
        setValue2(props.currentTriggerObj.label.label_id);
        // if applied clear applied data
        setValue1(val);
      }
    }
  };

  const handleChangeLabel1 = (event) => {
    consoleToLog("handleChangeLabel1",event.target.value);
    setValue1(event.target.value);
  };

  const handleChangeLabel2 = (event) => {
    consoleToLog("handleChangeLabel2",event.target.value);
    setValue2(event.target.value);
  };

  const labelValueChange = (event) => {
    setLabelValue(event.target.value);
  };

  const errmessage1 = fromPredefinedTemplate ? 'Enter Label Name To Apply' : 'Select Label Name to Apply'
  const errmessage2 = fromPredefinedTemplate ? 'Enter Label Name To Remove' : 'Select Label Name to Remove' 

  const labelOptionsValidation = () => {
    if (labelValue === labelType.APPLIED) {

      if (value1 === val) {
        props.validateBeforeTrigger(false, errmessage1);
      } else {
        props.validateBeforeTrigger(true);
        props.getLabelsData(ruleType.LABEL, value1, true);
      }
    }

    if (labelValue === labelType.REMOVED) {
      if (value2 === val) {
        props.validateBeforeTrigger(false, errmessage2);
      } else {
        props.validateBeforeTrigger(true);
        props.getLabelsData(ruleType.LABEL, value2, false);
      }
    }
  };

  const LabelOption1 = (type) => {
    return (
      <div style={{ 
            display: fromPredefinedTemplate ? "flex" : "inline-block" ,
            alignItems: 'center'
          }}>
        <span className={classes.selectOptions}>When label</span>
        {fromPredefinedTemplate ?
        <TextField 
          value={value1}
          type="text"
          onChange={(e) => setValue1(e.target.value)}
          variant="filled"
          margin="dense"
          classes={{
            root : classes.MuiTextFieldroot,
          }}
          disabled={labelValue === labelType.REMOVED}
          autoFocus
          inputRef={(input) => {
            if (input !== null) {
            input.focus();
            }
          }}
        />
        :
        <Select
          value={value1}
          input={<InputBase />}
          onChange={handleChangeLabel1}
          onFocus={(e) => setShowPlaceholder1(false)}
          onClose={(e) => setShowPlaceholder1(e.target.value === undefined)}
          className={clsx(
            classes.selectOptions2,
            value1 === "none" ? classes.selectdisabled : null
          )}
          disabled={labelValue === labelType.REMOVED}
          displayEmpty
        >
          <MenuItem
            className={clsx(
              classes.menuitem,
              !showPlaceholder1 ? classes.menuitemhidden : null
            )}
            key="0"
            disabled
            value="none"
          >
            Select Label
          </MenuItem>
          {props.labelList.map((label) => {
            return (
              <MenuItem key={label.id} value={label.id}>
                {label.name}
              </MenuItem>
            );
          })}
        </Select>}
        <span className={classes.selectOptions} style={{ marginLeft:fromPredefinedTemplate ? "4px" :"0px" }}>
          {/* is {props.type} */}
          is {type}
        </span>
      </div>
    );
  };

  const LabelOption2 = (type) => {
    return (
      <div style={{ 
            display: fromPredefinedTemplate ? "flex" : "inline-block" ,
            alignItems: 'center' 
        }}>
        <span className={classes.selectOptions}>When label</span>
        {fromPredefinedTemplate ?
        <TextField 
          value={value2}
          type="text"
          onChange={(e) => setValue2(e.target.value)}
          variant="filled"
          margin="dense"
          classes={{
            root : classes.MuiTextFieldroot
          }}
         disabled={labelValue === labelType.APPLIED}
         autoFocus
         inputRef={(input) => {
          if (input !== null) {
          input.focus();
          }
        }}
        />
        :
        <Select
          value={value2}
          input={<InputBase />}
          onChange={handleChangeLabel2}
          onFocus={(e) => setShowPlaceholder2(false)}
          onClose={(e) => setShowPlaceholder2(e.target.value === undefined)}
          className={clsx(
            classes.selectOptions2,
            value2 === "none" ? classes.selectdisabled : null
          )}
          disabled={labelValue === labelType.APPLIED}
          displayEmpty
        >
          <MenuItem
            className={clsx(
              classes.menuitem,
              !showPlaceholder2 ? classes.menuitemhidden : null
            )}
            key="0"
            disabled
            value="none"
          >
            Select Label
          </MenuItem>
          {props.labelList.map((label) => {
            return (
              <MenuItem key={label.id} value={label.id}>
                {label.name}
              </MenuItem>
            );
          })}
        </Select>}
        <span className={classes.selectOptions} style={{ marginLeft:fromPredefinedTemplate ? "4px" :"0px" }}>
          {/* is {props.type} */}
          is {type}
        </span>
      </div>
    );
  };

  return (
    <Grid item className={classes.selectParent} sm={11}>
      <RadioGroup
        aria-label="gender"
        name="customized-radios"
        className="radioGroup"
        onChange={labelValueChange}
        value={labelValue}
      >
        <FormControlLabel
          value={labelType.APPLIED}
          control={<StyledRadio />}
          //label={<LabelOption1 type="applied" labelList={props.labelList} />}
          label={LabelOption1('applied')}
        />
        <FormControlLabel
          value={labelType.REMOVED}
          control={<StyledRadio />}
          //label={<LabelOption2 type="removed" labelList={props.labelList} />}
          label={LabelOption2('removed')}
        />
      </RadioGroup>
    </Grid>
  );
};

const SchedulerOptions = (props) => {
  const [value1, setValue1] = useState("none");
  const [value2, setValue2] = useState("none");
  const [schedularValue, setSchedularValue] = useState(schedulerType.ENABLED);
  const [showPlaceholder1, setShowPlaceholder1] = useState(value1 === "none");
  const [showPlaceholder2, setShowPlaceholder2] = useState(value2 === "none");
  const classes = useStyles();
  const isMount = useIsMount();
  const fromPredefinedTemplate = history.location.state?.fromPredefinedTemplate;

  const errorMessageVal = fromPredefinedTemplate ? 'Select Predefined Email Draft Name' : 'Select Email Draft Name'

  useEffect(() => {
    consoleToLog("CureentObj",props.currentTriggerObj)
    updateViews();
    schedulerOptionValidation();
  }, []);

  useEffect(() => {
    if (!isMount) {
      updateViewsWithValues();
    }
  }, [props.currentTriggerObj]);

  useEffect(() => {
    if (!isMount) {
      schedulerOptionValidation();
    }
  }, [value1,value2,schedularValue]);

  const updateViews = () => {
    if (props.currentTriggerObj && props.currentTriggerObj.email_followup !== undefined) {
      updateViewsWithValues();
    }
  };

  const updateViewsWithValues = () => {
    if (props.currentTriggerObj.email_followup && Object.keys(props.currentTriggerObj.email_followup).length === 0) {
      setSchedularValue(schedulerType.ENABLED);
      setValue1(value1);
    } else {
      if (props.currentTriggerObj.email_followup.status === schedulerType.ENABLED) {
        setSchedularValue(schedulerType.ENABLED);
        setValue1(props.currentTriggerObj.email_followup.email_draft_id);
        // clear value 1
        setValue2("none");
      } else {
        setSchedularValue(schedulerType.DISABLED);
        setValue2(props.currentTriggerObj.email_followup.email_draft_id);
        // clear value 2
        setValue1("none");
      }
    }
  };

  const handleChange1 = (event) => {
    setValue1(event.target.value);
  };

  const handleChange2 = (event) => {
    setValue2(event.target.value);
  };

  const schedulerOptionValidation = () => {
    if (schedularValue === schedulerType.ENABLED) {
      if (value1 === "none") {
        props.validateBeforeTrigger(false, errorMessageVal);
      } else {
        props.validateBeforeTrigger(true);
        props.getSchedulerData(ruleType.EMAIL_FOLLOWUP, value1, schedulerType.ENABLED);
        
      }
    }

    if (schedularValue === schedulerType.DISABLED) {
      if (value2 === "none") {
        props.validateBeforeTrigger(false, errorMessageVal);
      } else {
        props.validateBeforeTrigger(true);
        props.getSchedulerData( ruleType.EMAIL_FOLLOWUP, value2, schedulerType.DISABLED);
      }
    }
  };

  const schedularValueChange = (event) => {
    setSchedularValue(event.target.value);
  };

  const SchedularOption1 = (props) => {
    return (
      <div style={{ display: "inline-block" }}>
        <span className={classes.selectOptions}>When email followup </span>
        <Select
          value={value1}
          input={<InputBase />}
          onChange={handleChange1}
          onFocus={(e) => setShowPlaceholder1(false)}
          onClose={(e) => setShowPlaceholder1(e.target.value === undefined)}
          className={clsx(
            classes.selectOptions2,
            value1 === "none" ? classes.selectdisabled : null
          )}
          disabled={schedularValue === schedulerType.DISABLED}
          style={{ maxWidth: "9rem" }}
        >
          <MenuItem
            className={clsx(
              classes.menuitem,
              !showPlaceholder1 ? classes.menuitemhidden : null
            )}
            key="0"
            disabled
            value="none"
          >
            {fromPredefinedTemplate ? 'Select Predefined Email Draft' : 'Select Email Draft'}
          </MenuItem>
          {props.emailDraftList.map((email_draft) => {
            return (
              <MenuItem key={email_draft.id} value={email_draft.id}>
                {email_draft.title}
              </MenuItem>
            );
          })}
        </Select>
        <span className={classes.selectOptions} style={{ marginLeft: "0px" }}>
          is {props.type}
        </span>
      </div>
    );
  };

  const SchedularOption2 = (props) => {
    return (
      <div style={{ display: "inline-block" }}>
        <span className={classes.selectOptions}>When email followup </span>
        <Select
          value={value2}
          input={<InputBase />}
          onChange={handleChange2}
          onFocus={(e) => setShowPlaceholder2(false)}
          onClose={(e) => setShowPlaceholder2(e.target.value === undefined)}
          className={clsx(
            classes.selectOptions2,
            value2 === "none" ? classes.selectdisabled : null
          )}
          disabled={schedularValue === schedulerType.ENABLED}
          style={{ maxWidth: "9rem" }}
        >
          <MenuItem
            className={clsx(
              classes.menuitem,
              !showPlaceholder2 ? classes.menuitemhidden : null
            )}
            key="0"
            disabled
            value="none"
          >
            {fromPredefinedTemplate ? 'Select Predefined Email Draft' : 'Select Email Draft'}
          </MenuItem>
          {props.emailDraftList.map((email_draft) => {
            return (
              <MenuItem key={email_draft.id} value={email_draft.id} >
                {email_draft.title}
              </MenuItem>
            );
          })}
        </Select>
        <span className={classes.selectOptions} style={{ marginLeft: "0px" }}>
          is {props.type}
        </span>
      </div>
    );
  };

  return (
    <Grid item className={classes.selectParent} sm={11}>
      <RadioGroup
        aria-label="gender"
        name="customized-radios"
        className="radioGroup"
        onChange={schedularValueChange}
        value={schedularValue}
        defaultValue={schedularValue}
        style={{ display: "inline-block" }}
      >
        <FormControlLabel
          value={schedulerType.ENABLED}
          control={<StyledRadio />}
          label={
            <SchedularOption1
              type="created"
              emailDraftList={props.emailDraftList}
            />
          }
        />
        <FormControlLabel
          value={schedulerType.DISABLED}
          control={<StyledRadio />}
          label={
            <SchedularOption2
              type="disabled"
              emailDraftList={props.emailDraftList}
            />
          }
        />
      </RadioGroup>
    </Grid>
  );
};

const ActionChange = (props) => {
  const [value1, setValue1] = useState("none");
  const [value2, setValue2] = useState("none");
  const [showPlaceholder1, setShowPlaceholder1] = useState(value1 === "none");
  const [showPlaceholder2, setShowPlaceholder2] = useState(value2 === "none");
  const [errorValue, setErrorValue] = useState("");
  const [addAction, setAddAction] = useState(false);
  const statusArray = [
    "pending",
    "in_process",
    "awaiting_reply",
    "completed",
    "not_applicable",
  ];
  const classes = useStyles();
  const [currentTaskName, setCurrentTaskName] = useState(undefined);
  const isMount = useIsMount();

  useEffect(() => {
    if (!isMount) {
      if (addAction) {
        validateAction();
      }
    }
  }, [value1, value2]);

  const handleChange1 = (event) => {
    setValue1(event.target.value);
    getTaskName(event.target.value);
  };

  const getTaskName = (taskId) => {
    var taskTitle = props.taskList.map((task) => {
      let task_id = props.fromChecklistDetails ? task.template_task : task.id; 
      if (task_id === taskId) {
        setCurrentTaskName(task.title);
      }
    });
  };

  const handleChange2 = (event) => {
    setValue2(event.target.value);
  };

  const validateAction = () => {
    if (value1 === "none" && value2 === "none") {
      setErrorValue("Cannot Add Action! Select Task Name & Status");
    }
    if (value1 !== "none" && value2 === "none") {
      setErrorValue("Cannot Add Action! Select Status");
    }
    if (value1 === "none" && value2 !== "none") {
      setErrorValue("Cannot Add Action! Select a Task Name");
    }
    if (value1 !== "none" && value2 !== "none") {
      setErrorValue("");
    }
  };

  const handleAddAction = () => {
    setAddAction(true);
    validateAction();
    if (value1 !== "none" && value2 !== "none") {
      props.addTaskAction(ruleType.TASK, value1, value2, currentTaskName);
      setValue1("none");
      setValue2("none");
      setAddAction(false);
    }
  };

  return (
    <div>
      <Grid item container className={classes.selectParent} sm={11}>
        <Grid item direction="row" sm={11}>
          <span className={classes.selectOptions}>Change</span>
          <Select
            value={value1}
            defaultValue={value1}
            input={<InputBase />}
            onChange={handleChange1}
            onFocus={(e) => setShowPlaceholder1(false)}
            onClose={(e) => setShowPlaceholder1(e.target.value === undefined)}
            className={clsx(
              classes.selectOptions2,
              value1 === "none" ? classes.selectdisabled : null
            )}
            style={{ maxWidth: "15rem" }}
            disabled ={props.taskList.length === 0}
          >
            <MenuItem
              className={clsx(
                classes.selectOptions2,
                !showPlaceholder1 ? classes.menuitemhidden : null
              )}
              key="0"
              disabled
              value="none"
            >
              Select Task Title
            </MenuItem>
            {props.taskList.map((task) => {
              return <MenuItem value={props.fromChecklistDetails ? task.template_task : task.id}>{task.title}</MenuItem>;
            })}
          </Select>
          <span className={classes.selectOptions}>Status to</span>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            onChange={handleChange2}
            disableUnderline
            value={value2}
            defaultValue={value2}
            input={<InputBase />}
            onFocus={(e) => setShowPlaceholder2(false)}
            onClose={(e) => setShowPlaceholder2(e.target.value === undefined)}
            className={clsx(
              classes.selectOptions,
              value2 === "none" ? classes.selectdisabled : null
            )}
            disabled ={props.taskList.length === 0}
          >
            <MenuItem
              className={clsx(
                classes.menuitem,
                !showPlaceholder2 ? classes.menuitemhidden : null
              )}
              key="0"
              disabled
              value="none"
            >
              Select Status
            </MenuItem>
            {statusArray.map((status) => {
              return (
                <MenuItem value={status}>
                  {getStatusNameByValue(status)}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
        <Grid
          item
          container
          direction="row"
          sm={1}
          alignItems="center"
          justifyContent="center"
        >
          <IconButton
            aria-label="delete"
            size="small"
            onClick={handleAddAction}
          >
            <i
              className="flaticon-add-2"
              style={{ fontSize: "15px", cursor: "pointer", color: "black" }}
              title="Edit"
            ></i>
          </IconButton>
        </Grid>
      </Grid>
      <FormHelperText style={{ color: "red" }}>{errorValue}</FormHelperText>
    </div>
  );
};

const ActionEnable = (props) => {
  const [value1, setValue1] = useState("none");
  const [value2, setValue2] = useState("none");
  const [showPlaceholder1, setShowPlaceholder1] = useState(value1 === "none");
  const [showPlaceholder2, setShowPlaceholder2] = useState(value2 === "none");
  const [errorValue, setErrorValue] = useState("");
  const [addAction, setAddAction] = useState(false);
  const classes = useStyles();
  const [currentSchedulerTitle, setCurrentSchedulerTitle] = useState(undefined);
  const [intervalDays, setIntervalDays] = useState(3);
  const [maxCount, setMaxCount] = useState(3);
  const [currentEmailDraftTitle, setCurrentEmailDrafTitle] = useState(undefined);
  const isMount = useIsMount();
  const [acnchorEl, setAnchorEl] = useState();
  const [selectedEmailId, setSelectedEmailId] = useState(props.fromPredefinedTemplate ? undefined : 'none');

  const errorMessage1 = props.fromPredefinedTemplate ? 'Select Predefined Email Draft' : 'Select Email Draft';

  useEffect(() => {
    if (!isMount) {
      if (addAction) {
        validateAction();
      }
    }
  }, [value1, value2, selectedEmailId, intervalDays, maxCount]);

  useEffect(() => {
    if (!isMount) {
      if (props.action === actionType.EMAIL_FOLLOWUP) {
        setValue1("none");
        setValue2("none");
        setSelectedEmailId(props.fromPredefinedTemplate ? undefined : "none");
        setIntervalDays(3);
        setMaxCount(3);
        setCurrentEmailDrafTitle(undefined)
      }
    }
  }, [props.action]);


  useEffect(() => {
    if (!isMount) {
      if (value2 === schedulerType.DISABLED) {
        setSelectedEmailId(undefined);
        setIntervalDays(undefined);
        setMaxCount(undefined);
      } else {
        if(!props.fromPredefinedTemplate) {
          setSelectedEmailId('none');
          setIntervalDays(3);
          setMaxCount(3);
        }
      }
    }
  }, [value2]);

  const handleChange1 = (event) => {
    setValue1(event.target.value);
    getEmailDraftName(event.target.value);
  };

  const getEmailDraftName = (emailDraftId) => {
    var emailDraftTitle = props.emailDraftList.map((email_draft) => {
      if (email_draft.id === emailDraftId) {
        setCurrentEmailDrafTitle(email_draft.title);
      }
    });
  };
  const handleChange2 = (event) => {
    setValue2(event.target.value);
  };

  const validateAction = () => {
    if (value1 === "none" && value2 === "none") {
      setErrorValue(`Cannot Add Action! ${errorMessage1} Name & Status`);
    }

    if (value1 !== "none" && value2 === "none") {
      setErrorValue("Cannot Add Action! Select Status");
    }

    if (value1 === "none" && value2 !== "none") {
      setErrorValue(`Cannot Add Action! ${errorMessage1} Name`);
    }

    if (value1 === "none" && value2 !== "none") {
      setErrorValue(`Cannot Add Action! ${errorMessage1} Name`);
    }

    if (value1 === "none" && value2 !== "none") {
      setErrorValue(`Cannot Add Action! ${errorMessage1} Name`);
    }

    if (selectedEmailId === "none" && value1 !== "none" && value2 !== "none") {
      setErrorValue(`Cannot Add Action! Please select support email`);
    }

    if (intervalDays === 0 || intervalDays === '') {
      setErrorValue(`Cannot Add Action! Please enter interval days`);
    }

    if (maxCount === 0 || maxCount === '') {
      setErrorValue(`Cannot Add Action! Please enter max count`);
    }

    if (value1 !== "none" && value2 !== "none" && selectedEmailId !== "none" && intervalDays !== '' && maxCount !== 0 && maxCount !== '') {
      setErrorValue("");
    }
  };

  const handleAddAction = () => {
    setAddAction(true);
    validateAction();
    if (value1 !== "none" && value2 !== "none" && selectedEmailId !== "none"  && intervalDays !== '' && intervalDays !== 0 && maxCount !== '' && maxCount !== 0) {
      props.getEmailFollowupActionData(
        ruleType.EMAIL_FOLLOWUP,
        value1,
        value2,
        currentEmailDraftTitle,
        intervalDays,
        maxCount, 
        selectedEmailId
      );
      setValue1("none");
      setValue2("none");
      setSelectedEmailId(props.fromPredefinedTemplate ? undefined : "none");
      setIntervalDays(3);
      setMaxCount(3);
      setCurrentEmailDrafTitle(undefined);
      setAddAction(false);
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
      setAnchorEl(null);
  };

  const handleChange = (event) => {
    setSelectedEmailId(event.target.value);
  };

  const daysLabel = (val) => {
    return {
        endAdornment: <InputAdornment  position="end" className={classes.daysLabel}>
                          <Typography style={{color:'#555555'}}>
                              {(val === 'days') ? 'days' : 'times'}
                          </Typography>
                      </InputAdornment>
    }
  }

  const showSettingsPopover = () => {
    return (
      <Popover
          id="sort-menu"
          anchorEl={acnchorEl}
          keepMounted
          open={Boolean(acnchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
            }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
            }}
          classes={{
              paper: classes.paper,
          }}
      >

            {!props.fromPredefinedTemplate &&
            <Grid item>
              <Typography variant='subtitle1'> 
                From Email
              </Typography>
              <Select
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  style={{width:"100%", lineHeight:'16px'}}
                  onChange={handleChange}
                  value={selectedEmailId}
                  renderValue={() => {
                      const item = props.orgEmailList.find((email) => email?.id === selectedEmailId); 
                      return item ? item.email : 'Please select email'
                      }}
                  
              >
                  <MenuItem value='none'> Please select support email</MenuItem>
                  {
                      props.orgEmailList && props.orgEmailList.length &&
                      props.orgEmailList.map((obj) => {
                          
                          return   obj.verified &&
                                  <MenuItem key={obj.id} value={obj.id}>
                                      {obj.email}
                                  </MenuItem>
                      })
                  }
              </Select>
            </Grid>}

          <div>
            <Typography variant='subtitle1' style={{marginBottom:'-8px', marginTop:'8px'}}> 
                Interval
            </Typography>
            <TextField  fullWidth
                margin="dense"
                variant='outlined'
                placeholder="Interval"
                onChange={(e) => setIntervalDays(e.target.value)}
                value={intervalDays}
                InputProps={daysLabel('days')}
            />
          </div>

          <div style={{marginTop:'8px'}}>
            <Typography variant='subtitle1' style={{marginBottom:'-8px'}}> 
                Max Count
            </Typography>
            <TextField  fullWidth
                margin="dense"
                variant='outlined'
                placeholder="Max Count"
                onChange={(e) => setMaxCount(e.target.value)}
                value={maxCount}
                InputProps={daysLabel('times')}
            />
          </div>
      </Popover>
    )
  }

  return (
    <div>
      <Grid item container className={classes.selectParent} sm={11}>
        <Grid item direction="row" sm={9} style={{ display: "inline-block" }}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            onChange={handleChange2}
            disableUnderline
            value={value2}
            input={<InputBase />}
            onFocus={(e) => setShowPlaceholder2(false)}
            onClose={(e) => setShowPlaceholder2(e.target.value === undefined)}
            className={clsx(
              classes.selectOptions2,
              value2 === "none" ? classes.selectdisabled : null
            )}
            disabled ={props.emailDraftList.length === 0}
          >
            <MenuItem
              className={clsx(
                classes.menuitem,
                !showPlaceholder2 ? classes.menuitemhidden : null
              )}
              key="0"
              disabled
              value="none"
            >
              Select
            </MenuItem>
            <MenuItem value={schedulerType.ENABLED}>Create</MenuItem>
            <MenuItem value={schedulerType.DISABLED}>Disable</MenuItem>
          </Select>
          <span className={classes.selectOptions} style={{ marginLeft: 0 }}>
            Email Followup
          </span>
          <Select
            value={value1}
            input={<InputBase />}
            onChange={handleChange1}
            onFocus={(e) => setShowPlaceholder1(false)}
            onClose={(e) => setShowPlaceholder1(e.target.value === undefined)}
            className={clsx(
              classes.selectOptions2,
              value1 === "none" ? classes.selectdisabled : null
            )}
            disabled ={props.emailDraftList.length === 0}
            style={{width:'145px'}}
          >
            <MenuItem
              className={clsx(
                classes.menuitem,
                !showPlaceholder1 ? classes.menuitemhidden : null
              )}
              key="0"
              disabled
              value="none"
            >
            {props.fromPredefinedTemplate ? "Select Predefined Email Draft" :  "Select Email Draft"}
            </MenuItem>
            {props.emailDraftList.map((email_draft) => {
              return (
                <MenuItem 
                  key={email_draft.id} 
                  value={email_draft.id}>
                  {email_draft.title}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
        <Grid item sm={2} style={{display:'flex', alignItems:'center', justifyContent:'center', visibility:value2 !== schedulerType.DISABLED ? 'visible': 'hidden' }}>
            <Tooltip title="Settings" arrow>
              <IconButton  size="small"
                style={{marginTop:'4px'}}
                onClick={handleClick}
                >
                <i
                  className="flaticon-settings"
                  style={{ fontSize: "15px", cursor: "pointer", color: "black", lineHeight:'18px' }}
                ></i>
              </IconButton>
              </Tooltip>
            {showSettingsPopover()}
        </Grid>
        <Grid
          item
          container
          direction="row"
          sm={1}
          alignItems="center"
          justifyContent="center"
        >
          <IconButton
            aria-label="delete"
            size="small"
            onClick={handleAddAction}
          >
            <i
              className="flaticon-add-2"
              style={{ fontSize: "15px", cursor: "pointer", color: "black" }}
              title="Edit"
            ></i>
          </IconButton>
        </Grid>
      </Grid>
      <FormHelperText style={{ color: "red" }}>{errorValue}</FormHelperText>
    </div>
  );
};

const ActionLabel = (props) => {
  const fromPredefinedTemplate = history.location.state?.fromPredefinedTemplate;
  const val = fromPredefinedTemplate ? '' : 'none';
  const [value1, setValue1] = useState(val);
  const [value2, setValue2] = useState("none");
  const [showPlaceholder1, setShowPlaceholder1] = useState(val);
  const [showPlaceholder2, setShowPlaceholder2] = useState(value2 === "none");
  const [errorValue, setErrorValue] = useState("");
  const [addAction, setAddAction] = useState(false);
  const [currentLabelTitle, setCurrentLabelTitle] = useState(undefined);
  let actionLabel = true;
  const classes = useStyles(actionLabel);
  const isMount = useIsMount();

  useEffect(() => {
    if (!isMount) {
      if (addAction) {
        validateAction();
      }
    }
  }, [value1, value2]);

  useEffect(() => {
    if (!isMount) {
      if (props.action === actionType.LABEL) {
        setValue1(val);
        setValue2("none");
      }
    }
  }, [props.action]);

  const handleChange1 = (event) => {
    setValue1(event.target.value);
    getLabelName(event.target.value);
  };

  const getLabelName = (labelId) => {
    if(fromPredefinedTemplate) {
      setCurrentLabelTitle(labelId);
    } else {
    var labelTitle = props.labelList.map((label) => {
      if (label.id === labelId) {
        setCurrentLabelTitle(label.name);
      }
    });
    }
  };

  const handleChange2 = (event) => {
    setValue2(event.target.value);
  };

  const errMeassage1 = fromPredefinedTemplate ? "Cannot Add Action! Enter Label Name & Status" :
  "Cannot Add Action! Select Label Name & Status"

  const errMessage2 = fromPredefinedTemplate ? "Cannot Add Action! Enter Label Name":
  "Cannot Add Action! Select Label Name"

  const validateAction = () => {
    if (value1 === val && value2 === "none") {
      setErrorValue(errMeassage1);
    }

    if (value1 !== val && value2 === "none") {
      setErrorValue("Cannot Add Action! Select Status");
    }

    if (value1 === val && value2 !== "none") {
      setErrorValue(errMessage2);
    }

    if (value1 !== val && value2 !== "none") {
      setErrorValue("");
    }
  };

  const handleAddAction = () => {
    setAddAction(true);
    validateAction();
    if (value1 !== val && value2 !== "none") {
      props.getLabelActionData(
        ruleType.LABEL,
        value1,
        value2,
        currentLabelTitle
      );
      setValue1(val);
      setValue2("none");
      setAddAction(false);
    }
  };

  return (
    <div>
      <Grid item container className={classes.selectParent} sm={11}>
        <Grid item direction="row" sm={11} style={{ display: "inline-block" }}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            onChange={handleChange2}
            disableUnderline
            value={value2}
            input={<InputBase />}
            onFocus={(e) => setShowPlaceholder2(false)}
            onClose={(e) => setShowPlaceholder2(e.target.value === undefined)}
            className={clsx(
              classes.selectOptions2,
              value2 === "none" ? classes.selectdisabled : null
            )}
          >
            <MenuItem
              className={clsx(
                classes.menuitem,
                !showPlaceholder2 ? classes.menuitemhidden : null
              )}
              key="0"
              disabled
              value="none"
            >
              Select
            </MenuItem>
            <MenuItem value={labelType.APPLIED}>Apply</MenuItem>
            <MenuItem value={labelType.REMOVED}>Remove</MenuItem>
          </Select>
          <span className={classes.selectOptions} style={{ marginLeft: 0 }}>
            Label
          </span>
          {fromPredefinedTemplate ?
          <TextField 
            value={value1}
            type="text"
            onChange={handleChange1}
            variant="filled"
            margin="dense"
            classes={{
              root : classes.MuiTextFieldroot,
            }}
            className={clsx(
              classes.selectOptions2,
              value1 === val ? classes.selectdisabled : null
            )}
          />
          :
          <Select
            value={value1}
            input={<InputBase />}
            onChange={handleChange1}
            onFocus={(e) => setShowPlaceholder1(false)}
            onClose={(e) => setShowPlaceholder1(e.target.value === undefined)}
            className={clsx(
              classes.selectOptions2,
              value1 === val ? classes.selectdisabled : null
            )}
          >
            <MenuItem
              className={clsx(
                classes.menuitem,
                !showPlaceholder1 ? classes.menuitemhidden : null
              )}
              key="0"
              disabled
              value="none"
            >
              Select Label
            </MenuItem>
            {props.labelList.map((label) => {
              return (
                <MenuItem key={label.id} value={label.id}>
                  {label.name}
                </MenuItem>
              );
            })}
          </Select>}
        </Grid>
        <Grid
          item
          container
          direction="row"
          sm={1}
          alignItems="center"
          justifyContent="center"
        >
          <IconButton
            aria-label="delete"
            size="small"
            onClick={handleAddAction}
          >
            <i
              className="flaticon-add-2"
              style={{ fontSize: "15px", cursor: "pointer", color: "black" }}
              title="Edit"
            ></i>
          </IconButton>
        </Grid>
      </Grid>
      <FormHelperText style={{ color: "red" }}>{errorValue}</FormHelperText>
    </div>
  );
};


const ActionDueDate = (props) => {
  const fromPredefinedTemplate = history.location.state?.fromPredefinedTemplate;
  
  const [dueDateValue, setDueDateValue] = useState('');
  const [errorValue, setErrorValue] = useState("");
  const [addAction, setAddAction] = useState(false);

  const classes = useStyles();
  const isMount = useIsMount();

  const validateAction = () => {
    if (dueDateValue === '') {
      setErrorValue('Please enter due date to continue');
    }
  };

  const handleAddAction = () => {
    setAddAction(true);
    validateAction();
    if (dueDateValue !== '') {
      props.getDueDateActionData(
        ruleType.DUE_DATE,
        dueDateValue,
      );
      setAddAction(false);
    }
  };

  const handleTextChange = (e) => {
    setDueDateValue(e.target.value);
    if(errorValue) setErrorValue('');
  } 

  return (
    <div>
      <Grid item container className={classes.selectParent} sm={11}>
        <Grid item direction="row" sm={11} style={{ display: "inline-block", position:"relative", top:"3px" }}>
            <Grid item container>
              <Typography variant="body2" style={{marginLeft:"6px"}} className={classes.dueDateStyles}>Set Due Date </Typography>
                <TextField
                  className="dueDateText"
                  value={dueDateValue}
                  onChange={handleTextChange}
                />
              <Typography variant="body2" className={classes.dueDateStyles}>days</Typography>
            </Grid>
        </Grid>
        <Grid
          item
          container
          direction="row"
          sm={1}
          alignItems="center"
          justifyContent="center"
        >
          <IconButton
            aria-label="delete"
            size="small"
            onClick={handleAddAction}
          >
            <i
              className="flaticon-add-2"
              style={{ fontSize: "15px", cursor: "pointer", color: "black" }}
              title="Edit"
            ></i>
          </IconButton>
        </Grid>
      </Grid>
      <FormHelperText style={{ color: "red" }}>{errorValue}</FormHelperText>
    </div>
  );
};

const Actions = (props) => {
  const classes = useStyles();
 
  const handleDeleteAction = (Id) => {
    props.deleteActions(Id);
  };

  const TaskAction = (props) => {
    const getTaskName = (taskId) => {
      var taskTitle = props.taskList.map((task) => {
        let task_id = props.fromChecklistDetails ? task.template_task : task.id;
        if (task_id === taskId) {
          return task.title;
        }
         return null;
      });
      return taskTitle;
    };

    var taskName =
      props.taskData && props.taskData.data.taskName
        ? props.taskData.data.taskName
        : getTaskName(props.taskData.data.template_task_id);
    var taskStatus =
      props.taskData && props.taskData.data && props.taskData.data.status;

    return (
      <Grid item container sm={12} className="actionParent">
        <Grid item direction="row" sm={11}>
          <Grid item container direction="row">
            <p>
              Change 
              <span className={classes.boldText}>
                {taskName}
              </span> 
              status to
              <span className={classes.boldText}>{taskStatus}</span>
            </p>
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="column"
          sm={1}
          alignItems="center"
          justifyContent="center"
          onClick={() => handleDeleteAction(props.taskData.id)}
          className="deletebtn"
        >
          <i
            className="flaticon-delete-1"
            style={{ fontSize: "15px", cursor: "pointer", color: "black" }}
            title="Delete"
          ></i>
        </Grid>
      </Grid>
    );
  };

  const SchedulerAction = (props) => {
    const getEmailDraftName = (emailDraftId) => {
      var emailDraftTitle = props.emailDraftList.map((email_draft) => {
        if (email_draft.id === emailDraftId) {
          return email_draft.title;
        }
        return null;
      });
      return emailDraftTitle;
    };
    var emailDraftName =
      props.emailFollowupData && props.emailFollowupData.data.emailDraftName
        ? props.emailFollowupData.data.emailDraftName
        : getEmailDraftName(props.emailFollowupData.data.email_draft_id);
    var emailFollowupStatus =
      props.emailFollowupData.data.status && props.emailFollowupData.data.status;

    const filterArray = !props.fromPredefinedTemplate && props.orgEmailList.filter((email) => email.id === props.emailFollowupData.data.organization_email_id);
    const from_email = filterArray && filterArray.length > 0 ? filterArray[0]?.email : undefined;
    const showString =
    <>Email  will be sent maximum <span className={classes.boldText}>{props.emailFollowupData.data.max_count}</span>{!from_email ? 'times.' :
    `times from`}{from_email && <span className={classes.boldText}>{from_email}.</span>}</>

    return (
      <Grid item container sm={12} className="actionParent">
        <Grid item direction="row" sm={11}>
          <Grid item container direction="row">
            <p>
              <span
                className={classes.boldText}
                style={{ paddingLeft: "0px", textTransform: "capitalize" }}
              >
                {emailFollowupStatus === 'enable' ? 'create' : emailFollowupStatus}
              </span>
              email followup
              
              <span className={classes.boldText}>
                {emailDraftName}
              </span>
              {props.emailFollowupData.data.interval !== 0 && props.emailFollowupData.data.max_count !== 0 && 
              <>
              at the interval of 
              <span className={classes.boldText}>
                {props.emailFollowupData.data.interval} 
              </span>
              days. {showString}</>}
            </p>
          </Grid>
        </Grid>
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          direction="row"
          sm={1}
          onClick={() => handleDeleteAction(props.emailFollowupData.id)}
          className="deletebtn"
        >
          <i
            className="flaticon-delete-1"
            style={{ fontSize: "15px", cursor: "pointer", color: "black" }}
            title="Delete"
          ></i>
        </Grid>
      </Grid>
    );
  };

  const LabelAction = (props) => {
    const fromPredefinedTemplate = history.location.state?.fromPredefinedTemplate;

    const getLabelName = (labelId) => {
      if(fromPredefinedTemplate){
        return labelId;
      }
      else{
        var labelTitle = props.labelList.map((label) => {
          if (label.id === labelId) {
            return label.name;
          }
          return null;
        });
        return labelTitle;
      }
    }

    var labelStatus = props.labelData
      ? props.labelData.data.status
      : "No status";
    var labelName =
      props.labelData && props.labelData.data.labelName
        ? props.labelData.data.labelName
        : getLabelName(props.labelData.data.label_id);

    return (
      <Grid item container sm={12} className="actionParent">
        <Grid item direction="row" sm={11}>
          <Grid item container direction="row">
            <p>
              <span
                className={classes.boldText}
                style={{ textTransform: "capitalize" }}
              >
                {labelStatus} 
              </span>
              Label
              <span className={classes.boldText} style={{ paddingLeft: "6px" }}>
                {labelName}
              </span>
            </p>
          </Grid>
        </Grid>
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          direction="row"
          sm={1}
          onClick={() => handleDeleteAction(props.labelData.id)}
          className="deletebtn"
        >
          <i
            className="flaticon-delete-1"
            style={{ fontSize: "15px", cursor: "pointer", color: "black" }}
            title="Delete"
          ></i>
        </Grid>
      </Grid>
    );
  };

  const DueDateAction = (props) => {
    const fromPredefinedTemplate = history.location.state?.fromPredefinedTemplate;
    let dueDateData = props.dueDateAction?.data;

    return (
      <Grid item container sm={12} className="actionParent" style={{padding:'16px 0px 8px 0px'}}>
          <Grid item>
            <Typography variant="body2">
                Set/Update due date after <b>{dueDateData.interval}</b> days.
            </Typography>
          </Grid>
          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            direction="row"
            sm={1}
            onClick={() => handleDeleteAction(props.dueDateAction?.id)}
            className="deletebtn"
          >
            <i
              className="flaticon-delete-1"
              style={{ fontSize: "15px", cursor: "pointer", color: "black" }}
              title="Delete"
            ></i>
          </Grid>
      </Grid>
    );
  };


  return (
    <Grid style={{ marginTop: "1.5rem", marginBottom: "1rem" }} sm={11}>
      <Grid item container direction="row" sm={11}>
        <span className={classes.actions}>Actions</span>
      </Grid>
      <Grid
        item
        container
        direction="row"
        className={classes.selectParent}
        style={{ fontSize: "14px", paddingTop: "0px" }}
      >
        {props.actionArray.length > 0 ? (
          props.actionArray.map((action) => {
            return (
              <>
                {action.typeOfAction === ruleType.TASK && (
                  <TaskAction taskData={action} taskList={props.taskList} />
                )}
                {action.typeOfAction === ruleType.EMAIL_FOLLOWUP && (
                  <SchedulerAction
                    emailFollowupData={action}
                    emailDraftList={props.emailDraftList}
                    orgEmailList={props.orgEmailList}
                  />
                )}
                {action.typeOfAction === ruleType.LABEL && (
                  <LabelAction labelData={action} labelList={props.labelList} />
                )}
                {action.typeOfAction === ruleType.DUE_DATE && (
                  <DueDateAction dueDateAction={action} />
                )}
              </>
            );
          })
        ) :  
            <Grid
              item
              container
              direction="row"
              style={{ marginTop: "7px", marginBottom: "7px" }}
            >
              No Actions
            </Grid>
        }
      </Grid>
    </Grid>
  );
};

export {
  Actions,
  ActionChange,
  StatusOption,
  ChecklistOption,
  Selectoperation,
  LabelOptions,
  SchedulerOptions,
  StyledRadio,
  ActionEnable,
  ActionLabel,
  ActionDueDate
};
