import React, {useState, useEffect} from 'react';
import {Grid, TextField, Typography, MenuItem, Button, CircularProgress, FormControlLabel, Checkbox, InputAdornment} from '@material-ui/core';
import {connect} from 'react-redux'
import CompanyAutoComplete from '../CompanyAutoComplete';
import {makeStyles} from '@material-ui/core/styles';
import {
    getStartYearOptions, getRangeOptionsFromTemplate, getRangeValuesFromTemplate,
    getCreateAtOptionsFromTemplate, getCreateAtValuesFromTemplate
} from '../../util/checklistUtil';
import { useIsMount } from '../useIsMount';
import moment from 'moment';
import AutocompleteMemberApprover from '../AutocompleteMemberApprover';
import AutocompleteFolder from '../AutocompleteFolder';
import { createChecklistApi } from '../../services/authService';
import { useSnackbar } from 'notistack';
import { consoleToLog, getInvoicePeriodicityPlaceholder, trackGAEvent } from '../../util/AppUtil';
import { getOptionsAndValuesForMonthlyInvoicePeriod, 
    getOptionsAndValuesForQuartelyInvoicePeriod, 
    getOptionsAndValuesForHalfYearlyInvoicePeriod, 
    getOptionsAndValuesForYearlyInvoicePeriod
} from '../../util/checklistUtil';
import TemplateAutocomplete from '../TemplateAutocomplete';
import { setSelectedTemplate, SelectedDrawerState } from '../../actions/selectedOrganization';
import {createChecklistInGenieApi} from '../../services/genieService';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import Calender from '../Calender';
import Popover from '@material-ui/core/Popover';
import ChecklistCompaniesFilter from '../filters/ChecklistCompaniesFilter'

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    margin: {
        margin: theme.spacing(1),
    },
    labelStyles: {
        lineHeight:1, 
        color:'#555555'
    },
    circularProgress: {
        marginLeft: 0,
        marginRight: theme.spacing(2),
        color: 'white'
    },
    MuiCheckboxColorPrimary : {
        color:"#d3d3d3 !important",
    },
    Muichecked: {
        color:'#202020 !important'
    },
    icontext : {
        color:'#808080',
        padding:'0 0px 0 5px',
    },
    materialIconStyle: {
        fontSize:'16px',
        color:'grey'
    },
    setDueDateStyle: {
        border:'1px solid rgba(0,0,0,0.1)',
        color:"#333"
    }

}))

const CreateChecklistModal = (props) => {
    const classes = useStyles();
    const isMount = useIsMount();
    const { enqueueSnackbar } = useSnackbar();

    const [selectedCompany, setSelectedCompany] = useState([]);
    const [isScheduleActive, setIsScheduleActive] = useState(true);
    const [selectedSchedule, setSelectedSchedule] = useState({
        selectedMonth: undefined,
        selectedYear: undefined,
        selectedRange: undefined,
        selectedCreateAt: undefined,
    });
    const [startYearOptions, setStartYearOptions] = useState([]);
    const [rangeOptions, setRangeOptions] = useState([]);
    const [rangeValues, setRangeValues] = useState([]);
    const [createAtOptions, setCreateAtOptions] = useState([]);
    const [createAtValues, setCreateAtValues] = useState([]);
    const [assignedMemberList, setAssignedMemberList] = useState([]);
    const [assignedApproverList, setAssignedApproverList] = useState([]);
    const [assignedFolder, setAssignedFolder] = useState(undefined);
    const [title, setTitle] = useState('');
    const [loading, setLoading] = useState(false);
    const [isBillableChecked, setBillableChecked] = useState(false);
    const [cost, setCost] = useState("");
    const [periodicity, setPeriodicity] = useState(-1);
    const [selectedTemplate, setSelectedTemplate] = useState(undefined);
    const [dueDate, setDueDate] = useState('');

    const [anchorEl, setAnchorEl] = useState(null);

    var invoicePeriodRange = [];

    const startMonthOptions = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
    ];
    
    useEffect(() => {
        setBillableChecked(false);
        const yearOptions = getStartYearOptions();
        setStartYearOptions(yearOptions);
    },[]);

    // useEffect(() => {
    //     if(isMount) {

    //     } else {
    //         props.setDrawerOpen();
    //         setBillableChecked(false);
    //     }

    // }, [selectedTemplate?.template_id])

    useEffect(() => {
        if(isMount) {

        } else {
            if(!isScheduleActive) {
                let set_date = moment().format('DD-MM-YYYY')
                setTitle(set_date);
                return;
            }
        }

    }, [isScheduleActive]);

    useEffect(() => {
        if(isMount) {

        } else {
            if(selectedTemplate) {
                getSelectedTemplateSchedule();
            }
        }

    }, [selectedTemplate]);

    const getSelectedTemplateSchedule = () => {
        const templateObj = selectedTemplate;

        //check if chedule is Active
        const scheduleIsActive = templateObj.schedule;
        setIsScheduleActive(scheduleIsActive);

        //Periodicity / range options
        const rangeOptionsFromTemplate = getRangeOptionsFromTemplate(templateObj);

        // //Periodicity / range values
        const rangeValuesFromTemplate = getRangeValuesFromTemplate(templateObj);

        //createAtOptions
        const createAtOptionsFromTemplate = getCreateAtOptionsFromTemplate(templateObj);

        //createAtValues
        const createAtValuesFromTemplate = getCreateAtValuesFromTemplate(templateObj);

        //default selected if one option
        var selectedRange = undefined;
        if (rangeOptionsFromTemplate.length === 1) {
            selectedRange = rangeValuesFromTemplate[0];
            setSelectedSchedule({
                ...selectedSchedule,
                selectedRange: selectedRange
            })
        }
        var selectedCreateAt = undefined;
        if (createAtOptionsFromTemplate.length === 1) {
            selectedCreateAt = createAtValuesFromTemplate[0];
            setSelectedSchedule({
                ...selectedSchedule,
                selectedRange: selectedCreateAt
            })
        }

        setRangeOptions(rangeOptionsFromTemplate);
        setRangeValues(rangeValuesFromTemplate);
        setCreateAtOptions(createAtOptionsFromTemplate);
        setCreateAtValues(createAtValuesFromTemplate);

        setSelectedSchedule({
            ...selectedSchedule,
            selectedRange,
            selectedCreateAt
        })
    }

    const onSelectedCompanies = (company) => {
        if (company) {
            setSelectedCompany(company)
        } else {
            setSelectedCompany(undefined)
        }
    }

    const onStartMonthChanged = (e) => {
        const value = e.target.value;
        if (value !== -1 || value !== '-1') {
            setSelectedSchedule({
                ...selectedSchedule,
                selectedMonth: value
            })
        }
    }

    const onStartYearChanged = (e) => {
        const value = e.target.value;
        if (value !== -1 || value !== '-1') {
            setSelectedSchedule({
                ...selectedSchedule,
                selectedYear: value
            });
        }
    }

    const onRangeChanged = (e) => {
        const value = e.target.value;
        if (value !== -1 || value !== '-1') {
            setSelectedSchedule({
                ...selectedSchedule,
                selectedRange: value
            });
        }
    }

    const onCreateAtChanged = (e) => {
        const value = e.target.value;
        if (value !== -1 || value !== '-1') {
            setSelectedSchedule({
                ...selectedSchedule,
                selectedCreateAt: value
            });
        }
    }

    const onAssignedMemberList = (assignedMemberList) => {
       setAssignedMemberList(assignedMemberList);
    }

    const onAssignedApproverList = (assignedApproverList) => {
        setAssignedApproverList(assignedApproverList);
    }

    const onAssignedFolder = (assignedFolder) => {
        setAssignedFolder(assignedFolder)
    }
    
    const onSelectedTemplate = (template) => {
        if (template) {
            setSelectedTemplate(template)
        } else {
            setSelectedTemplate(undefined)
        }
    }

    const onCreateChecklistClick = () => {

        if (selectedCompany && selectedCompany.length === 0) {
            enqueueSnackbar('Company cannot be empty', {
                variant:'error'
            });
        }

        const assignees = assignedMemberList.map((member) => member.user.id).join() || '';
        const approvers = assignedApproverList.map((approver) => approver.user.id).join() || '';
        const company_ids = selectedCompany.map((company) => company.id);
        const organization_id = props.selectedOrganization.organization.id;
        const template_id = selectedTemplate.template_id;
        const schedule = isScheduleActive ? 1 : undefined;
        const group_id = assignedFolder ? assignedFolder.id : undefined;

        let start_date = moment().format('YYYY-MM-DD'); //start_date/ default today
        let month = undefined;
        let year = undefined;
        if(schedule === 1) { 
            //Month
            if (selectedSchedule.selectedMonth && selectedSchedule.selectedMonth !== -1) {
                month = selectedSchedule.selectedMonth;
            } else {
                enqueueSnackbar('Month can not be empty!', {
                    variant: 'error'
                });
                return;
            }

            //Year
            if (selectedSchedule.selectedYear && selectedSchedule.selectedYear !== -1) {
                year = selectedSchedule.selectedYear
            } else {
                enqueueSnackbar('Year can not be empty!', {
                    variant: 'error'
                });
                return;
            }

            start_date = moment(`${year}-${month}-01`, "YYYY-MM-DD").format("YYYY-MM-DD");
        }

        //get range
        let schedule_type = '';//'one_time';//range // default value
        if (schedule === 1) {
            if (selectedSchedule.selectedRange && selectedSchedule.selectedRange !== -1) {
                schedule_type = selectedSchedule.selectedRange;
            } else {
                enqueueSnackbar('Periodicity cannot be empty!', {
                    variant: 'error'
                });
                return;
            }
        }

        //get create_at
        let schedule_creation_type = 'start';//create_at//default value
        if (schedule === 1) {
            if (selectedSchedule.selectedCreateAt && selectedSchedule.selectedCreateAt !== -1) {
                schedule_creation_type = selectedSchedule.selectedCreateAt;
            } else {
                enqueueSnackbar('Create At cannot be empty!', {
                    variant: 'error'
                });
                return;
            }
        }

        const subtitle = !isScheduleActive ? title : '';
        //consoleToLog('All selected values', start_date, schedule_type, schedule_creation_type, month, year);
        var billable =isBillableChecked ? isBillableChecked : false; 
        var localCost = cost? cost : "";
        var localPeriodicity=(periodicity!=="-1" && periodicity !== -1)?periodicity : "";
        const due_date = dueDate;

        setLoading(true)
        //create checklist api call
        createChecklistApi(assignees, company_ids, approvers, schedule, organization_id, template_id, 
            start_date, schedule_type, schedule_creation_type, subtitle, billable, localCost, localPeriodicity, due_date)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response createChecklistApi: ", res);
                enqueueSnackbar(res.message, {
                    variant: 'success'
                });
                if(selectedTemplate?.template_id === props.selectedTemplate?.template_id) {
                    props.onChecklistCreation && props.onChecklistCreation(res.checklist);
                } else {
                    props.setSelectedTemplate(selectedTemplate);
                }
                trackGAEvent(props.selectedOrganization.organization.name, 'Checklist Create', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
                if(props.fromEmailDetails) {
                    createChecklistInGenieApp(res.checklist);
                } else {
                    setLoading(false);
                    props.setDrawerOpen();
                }
            })
            .catch((e) => {
                consoleToLog("createChecklistApi error", e.response);
                setLoading(false);
                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant: 'error'
                    });
                    return false;
                }
            });
    }

    const createChecklistInGenieApp = (checklistArr) => {
        
        const email_uuid = props.selectedEmailItem.id;
        const checklistObj = checklistArr[0];

        createChecklistInGenieApi(email_uuid, checklistArr)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response createChecklistInGenieApi: ', res);

                // let updatedArray = props.triageList.map((triage) => {
                //     if(triage.id === res.triage.id) {
                //         triage.data = res.triage.data;
                //     }
                //     return triage;
                // });
                // props.setTriageList(updatedArray);

                setLoading(false);
                props.handleDrawerClose();
            })
            .catch((e) => {
                consoleToLog('Error createChecklistInGenieApi: ', e.response);
                setLoading(false);
                if(e.response.data && e.response.data.message) {
                    return;
                }
            })
    }

    const onBillableClicked = (e) => {
        !isBillableChecked && 
        trackGAEvent(props.selectedOrganization.organization.name, 'Create Checklist', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
        setBillableChecked(!isBillableChecked);
    }

        const scheduleChecklist = () => {
        return (
                <Grid item container md={12}>
                    <Grid item style={{flexBasis:'25%', maxWidth:'25%', marginRight:'4px'}}>
                        <TextField
                            style={{ width: "100%"}} 
                            id="months"
                            margin="dense"
                            select
                            label="Month"
                            variant="outlined"
                            onChange={onStartMonthChanged}
                            InputLabelProps={{style: {fontSize: 13}}}
                            disabled={!isScheduleActive}
                        >
                            <MenuItem value={-1}>Month</MenuItem>
                            {
                                startMonthOptions && startMonthOptions.map((op, index) => {
                                return  <MenuItem key={op} value={index + 1}>
                                            {op}
                                        </MenuItem>
                                })
                            }
                        </TextField>    

                    </Grid>
                    <Grid item lg={2} style={{marginRight:'4px'}}>
                        <TextField
                            style={{ width: "100%"}} 
                            id="year"
                            margin="dense"
                            select
                            label="Year"
                            variant="outlined"
                            onChange={onStartYearChanged}
                            InputLabelProps={{style: {fontSize: 13, paddingRight:'31px !important'}}}
                            disabled={!isScheduleActive}
                        >
                            <MenuItem value={-1}>Year</MenuItem>
                            {
                                startYearOptions && startYearOptions.map((op) => {
                                    return <MenuItem key={op} value={op}>
                                                {op}
                                            </MenuItem>
                                }) 
                            }
                        </TextField>
                    </Grid>
                    <Grid item lg={3} style={{marginRight:'4px'}}>
                        <TextField
                            style={{ width: "100%"}} 
                            id="rangeSelector"
                            margin="dense"
                            select
                            label="Periodicity"
                            variant="outlined"
                            onChange={onRangeChanged}
                            InputLabelProps={{style: {fontSize: 13}}}
                            disabled={!isScheduleActive || rangeOptions.length === 1}
                            value={selectedSchedule.selectedRange ? selectedSchedule.selectedRange : ''}
                        >
                            <MenuItem value={-1}>Periodicity</MenuItem>
                            {
                                rangeOptions && rangeOptions.map((op, index) => {
                                    return  <MenuItem key={op} 
                                                value={rangeValues[index]}>
                                                    {op}
                                            </MenuItem>
                                })
                            }
                        </TextField>
                    </Grid>
                    <Grid item lg={4} style={{flexBasis:'31%', maxWidth:'31%'}}>
                        <TextField
                            style={{ width: "100%"}} 
                            id="createAtSelector"
                            margin="dense"
                            select
                            label="Create At"
                            variant="outlined"
                            onChange={onCreateAtChanged}
                            InputLabelProps={{style: {fontSize: 13}}}
                            disabled={!isScheduleActive || createAtOptions.length === 1}
                            value={selectedSchedule.selectedCreateAt ? selectedSchedule.selectedCreateAt : '' }
                        >
                            <MenuItem value={-1}>Create At</MenuItem>
                            {
                                createAtOptions && createAtOptions.map((op, index) => {
                                        return  <MenuItem key={op} 
                                                    value={createAtValues[index]}>
                                                        {op}
                                                </MenuItem>
                                })
                            }
                        </TextField>
                    </Grid>  
                </Grid>
            );
    }

    if(isScheduleActive){
        if(selectedSchedule.selectedRange!== '' && selectedSchedule.selectedRange === "monthly"){
            invoicePeriodRange= getOptionsAndValuesForMonthlyInvoicePeriod();
        }
        else if(selectedSchedule.selectedRange!== '' && selectedSchedule.selectedRange === "quarterly"){
            invoicePeriodRange= getOptionsAndValuesForQuartelyInvoicePeriod();
        }
        else if(selectedSchedule.selectedRange!== '' && selectedSchedule.selectedRange === "half_yearly"){
            invoicePeriodRange= getOptionsAndValuesForHalfYearlyInvoicePeriod();
        }
        else if(selectedSchedule.selectedRange!== '' && selectedSchedule.selectedRange === "yearly"){
            invoicePeriodRange= getOptionsAndValuesForYearlyInvoicePeriod();
        }
    }

    const onInvoicePeriodicityChanged= (e, index) => {
        const value = e.target.value;
        setPeriodicity(value);
    }

    const onInvoiceCostChanged = (e, index) => {
        const value = e.target.value;
        setCost(value)
    }  
    
    const handleClickNew = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseDueDatePopover = () => {
        setAnchorEl(null);
    }

    const clickedDate = (str) => {
        setDueDate(moment(str).format("YYYY-MM-DD"));
        handleCloseDueDatePopover();
        
    };

    const handleDueDatePopOver = () => {
        return (<Popover
                id='task-due-date-popover'
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleCloseDueDatePopover}
                anchorOrigin={{
                vertical: 'bottom',
                horizontal:'right'
                }}
                transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
                }}
            >
                <Calender clickedDate={clickedDate} />
            </Popover>
        );
    };

    return (
        <div className="modal__main">
            <Grid item container md={12} lg={12} direction="column">
                <Grid item lg={12} style={{margin:"0px 0px 8px 0px"}}>
                    <Typography variant="subtitle1" style={{lineHeight:1.9, color:'#555555'}}>
                        Template
                    </Typography>   
                    <TemplateAutocomplete selectedTemplate={onSelectedTemplate}/>
                </Grid>

                <Grid item lg={12}>
                    {/* <Typography variant="subtitle1" style={{lineHeight:1.9, color:'#555555'}}>
                        Company
                    </Typography>    */}
                    <ChecklistCompaniesFilter
                        onSelectedCompanies={onSelectedCompanies}
                        isFromCreateChecklist={true}/>
                </Grid>
                
                {isScheduleActive ?
                    <Grid item container lg={12} style={{margin:"18px 0px 2px 0px"}}>  
                        <Typography variant="subtitle1" className={classes.labelStyles}>
                            Schedule
                        </Typography>
                        {scheduleChecklist()}
                    </Grid>
                    :
                    <Grid item container lg={12} style={{margin:"18px 0px 2px 0px"}}>  
                        <Typography variant="subtitle1" className={classes.labelStyles}>
                            Subtitle
                        </Typography>
                        <TextField
                            style={{ width: "100%"}} 
                            id="subtitle"
                            margin="dense"
                            //label="Subtitle"
                            variant="outlined"
                            value={title}
                            onChange={(e)=>{
                                setTitle(e.target.value);
                            }}
                            InputLabelProps={{style: {fontSize: 13, marginTop:'3px'}}}
                        ></TextField>
                    </Grid>
                }

                <Grid item style={{margin:"18px 0px 2px 0px"}}>
                        <Typography variant='subtitle1' style={{marginBottom:'2px'}}>
                            Set Due Date
                        </Typography>
                        <Button aria-label="delete"
                            onClick={handleClickNew}
                            className={classes.setDueDateStyle}
                        >
                            <CalendarTodayOutlinedIcon fontSize="small" className={classes.materialIconStyle}/>
                            <Typography variant="subtitle1"
                                className={classes.icontext}>{
                                dueDate ? `${moment(dueDate).format('Do MMM YYYY')}` : 'Due Date'}
                            </Typography>
                        </Button>
                    {handleDueDatePopOver()}
                </Grid>
                {props.accountList && props.accountList.length > 0 &&
                <Grid item style={{display:'flex', alignItems:'center', margin:"8px 0px 2px -10px"}}>
                        <Checkbox
                            onChange={(e) => onBillableClicked(e)}
                            checked={isBillableChecked}
                            name="invoiceCheck"
                            color="primary"
                            style={{color:"#ccc !important"}}
                            classes={{
                                colorPrimary: classes.MuiCheckboxColorPrimary,
                                checked: classes.Muichecked
                            }}
                        />
                        <Typography variant='subtitle1' 
                            style={{marginLeft:'-4px'}}
                            className={classes.labelStyles}>
                            Billable
                        </Typography>
                    {/* <FormControlLabel
                        //key={template.id + "_" + index}
                        control={
                            <Checkbox
                                onChange={(e) => onBillableClicked(e)}
                                checked={isBillableChecked}
                                name="invoiceCheck"
                                color="primary"
                            />
                        }
                        style={{
                            display: "block",
                            verticalAlign:'middle'
                        }}
                        label="Billable" 
                        classes={{
                            label:classes.MuiFormControlLabellabel
                        }}
                    /> */}
                </Grid>}

                {isBillableChecked && <Grid item style={{marginTop:"-8px"}}> 
                    <Grid item container spacing={1}>
                        <Grid item md={6} lg={6}>
                            <TextField
                                fullWidth
                                margin="dense"
                                variant="outlined"
                                value={cost}
                                onChange={(e) => onInvoiceCostChanged(e)}
                                placeholder='Cost'
                                //disabled={!template.checked}
                                InputLabelProps={{style: {fontSize: 14, marginTop:'0px'}}}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">{`${getInvoicePeriodicityPlaceholder(periodicity)}`}</InputAdornment>,
                                }}
                            ></TextField>
                        </Grid>
                        
                        { isScheduleActive &&  <Grid item md={6} lg={6}>
                            <TextField
                                style={{ width: "100%"}} 
                                id="invoicerangeSelector"
                                margin="dense"
                                select
                                label="Invoice Periodicity"
                                variant="outlined"
                                onChange={(e) => onInvoicePeriodicityChanged(e)}
                                InputLabelProps={{style: {fontSize: 14}}}
                                //disabled={!template.isScheduleActive || !template.checked || template.rangeOptions.length === 1}
                            // value={template.scheduleValues.selectedRange ? template.scheduleValues.selectedRange : ''}
                                classes={{
                                    root: classes.MuiTextFieldroot
                                }}
                            >
                                <MenuItem value={-1}>Invoice Periodicity</MenuItem>
                                {
                                    invoicePeriodRange && invoicePeriodRange.map((op, index) => {
                                        return <MenuItem key={op+index} 
                                                    value={op.value}>
                                                        {op.display_name}
                                            </MenuItem>
                                    })
                                }
                            </TextField>
                        </Grid>
                        }
                    </Grid>                                                                            
                </Grid>}

                <Grid item lg={12}>
                    <Typography variant="subtitle1" 
                        className={classes.labelStyles} 
                        style={{marginTop:'12px'}}>
                        Assignees
                    </Typography>   
                    <AutocompleteMemberApprover assignMembers={true} 
                    onAssignedMemberList={onAssignedMemberList} 
                    assignedMemberList={assignedMemberList}
                />
                </Grid>

                <Grid item lg={12}>
                    <Typography variant="subtitle1" style={{lineHeight:1, color:'#555555', marginTop:'18px'}}>
                        Approvers
                    </Typography>   
                    <AutocompleteMemberApprover assignApprovers={true} 
                        onAssignedApproverList={onAssignedApproverList} 
                        assignedApproverList={assignedApproverList}
                    />
                </Grid>

                {/* <Grid item lg={12}>
                    <Typography variant="subtitle1" style={{lineHeight:1, color:'#555555', marginTop:'18px'}}>
                        Folder
                    </Typography>   
                    <AutocompleteFolder assignedFolder={onAssignedFolder}
                        checklistGroup={props.checklistGroup}
                        selectedTemplate={selectedTemplate}
                    />
                </Grid> */}
            
                <div className="modal__footer" style={{bottom: props.fromEmailDetails && '0px'}}>
                    <Button variant="contained" color="primary" 
                        className="modal__button"
                        onClick={onCreateChecklistClick}
                        >
                        {loading && <CircularProgress size={24} className={classes.circularProgress}/>}
                        Create
                    </Button>
                    <Button variant="outlined" style={{
                        minWidth: '130px',
                    }} onClick={props.setDrawerOpen}>
                        Cancel
                    </Button>
                </div>
            </Grid>
        </div>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    selectedTemplate: state.organizationInfo.selectedTemplate,
    accountList: state.organizationInfo.accountList,
    setDrawerState: state.organizationInfo.setDrawerState
})

const mapDispatchToProps = (dispatch) => ({
    setSelectedTemplate: (template) => dispatch(setSelectedTemplate(template)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateChecklistModal);