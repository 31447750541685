import React, {useState} from 'react';
import {connect} from 'react-redux';
import {getAttachmentUploadURLApi} from './genieService';
import { consoleToLog } from '../util/AppUtil';
import axios from 'axios';

export const uploadAttachmentApi = async (file_name, file_type, bucket, file, organization_id) => {
    const uploadURl = await getAttachmentUploadURLApi(file_name, file_type, bucket, file)
    const options = {
            headers: {
            "Content-Type": file_type,
            }
        };
        axios.defaults.headers.common = {};
    const data = await axios.put(uploadURl.data.data.returnData.signedRequest, file, options)
    return data;
        
}

// const callAxiosApi = (signedRequest, file, options) => {
//     const signedRequest = res.data.returnData.signedRequest;
//             const url = res.data.returnData.url;
    
//             // Put the fileType in the headers for the upload
//             const options = {
//                 headers: {
//                 "Content-Type": file_type,
//                 }
//             };

//                 axios.put(signedRequest, file, options)
//                 .then(response => {
//                     consoleToLog('signed Request s3', response);

//                 })
//                 .catch(error => {
//                     consoleToLog('error: ', error.response);
//                     return;
//                 })

// }