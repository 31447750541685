import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { emailValidator, passwordValidator, nameValidator } from '../util/validator';
import {connect} from 'react-redux';
import {signUpApi} from '../services/authService';
import {login} from '../actions/auth';
import { consoleToLog, capitalizeFirstLetterOfEachWord, trackGAEvent, trackGAPageView } from '../util/AppUtil';
import { useSnackbar } from 'notistack';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getQueryParams } from '../util/urlUtil';
import { useIsMount } from '../components/useIsMount';

const useStyles = makeStyles((theme) => ({
    innerbg: {
        border: "1px solid #dbdbdb",
        borderRadius: "10px",
        padding: "30px 100px",
        background: "#ffffff",
        [theme.breakpoints.down("md")]: {
            padding: "45px 20px",
          }
    },
    mainbg: {
        background: theme.palette.primary.light
    },
    logo: {
        maxWidth: "200px",
        marginBottom: "10px",
        [theme.breakpoints.down("md")]: {
            maxWidth: "180px",
          }
    },
    title: {
        marginTop: "15px",
        fontSize: "1.1rem"
    },
    circularProgress: {
        marginLeft: 0,
        marginRight: theme.spacing(2),
        color: 'white'
      },
  }));

const SignupPage = (props) => {
      const classes = useStyles(props);
      const { enqueueSnackbar } = useSnackbar();
      //const isMount = useIsMount();
      //consoleToLog("Theme vikas: ", props);
      const [firstname, setFirstName] = useState('');
      const [lastName, setLastName] = useState('');
      const [email, setEmail] = useState(undefined);
      const [password, setPassword] = useState('');
      const [confirmPassword, setConfirmPassword] = useState('');

      const [fnInvalid, setFnInvalid] = useState(false);
      const [lnInvalid, setLnInvalid] = useState(false);
      const [emailInvalid, setEmailInvalid] = useState(false);
      const [passwordInvalid, setPasswordInvalid] = useState(false);
      const [passwordError, setPasswordError] = useState("");
      const [confirmPasswordInvalid, setConfirmPasswordInvalid] = useState(false);
      
      const [loading, setLoading] = useState(false);
      const [nameError, setNameError] = useState('')
      const [registerEmail,setRegisterEmail]= useState(getQueryParams(document.location.search));
      
      useEffect(()=> {
        trackGAPageView('/register');
        let queryParams = getQueryParams(document.location.search);
        consoleToLog("auth user in sign up page",queryParams.email);
        if(queryParams.email !== undefined){
            setEmail(queryParams.email);
            setRegisterEmail(queryParams.email);
        }
      }, [])

      const onSubmitClick = () => {
        const isFirstNameValid = nameValidator(firstname);
        if (!firstname) {
            setFnInvalid(true);
            setNameError('* First Name Required');
            return;
        }

        if(!isFirstNameValid) {
            setFnInvalid(true);
            setNameError('* First name is not valid! Please enter Alphabets only');
            return
        }

        const isLastNameValid = nameValidator(lastName);
        if (!lastName) {
            setLnInvalid(true);
            setNameError('* Last Name Required');
            return;
        }

        if(!isLastNameValid) {
            setLnInvalid(true);
            setNameError('* Last name is not valid! Please enter Alphabets only');
            return
        }

        if (!email) {
            setEmailInvalid(true);
            return;
        }
        const isEmailValid = emailValidator(email);
        if (!isEmailValid) {
            setEmailInvalid(true);
            return;
        }

        if (!password) {
            setPasswordInvalid(true);
            setPasswordError('* Password Required');
            return;
        }

        const isPasswordValid = passwordValidator(password);
        if (!isPasswordValid) {
            setPasswordInvalid(true);
            setPasswordError('* Password length should be 5 or more!');
            return;
        }

        if (password !== confirmPassword) {
            setConfirmPasswordInvalid(true);
            return;
        }

        setLoading(true)
        signUpApi(capitalizeFirstLetterOfEachWord(firstname), 
            capitalizeFirstLetterOfEachWord(lastName),
            email, 
            password)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response signUpApi: ", res);
                setLoading(false);
                enqueueSnackbar(res.message, { 
                    variant: 'success',
                });
                trackGAEvent('Signup', 'Signup clicked', `${res.user.firstname} ${res.user.lastname}`);
                props.login(res);
            })
            .catch((e) => {
                consoleToLog("SignUpApi error", e.response);
                setLoading(false);
                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, { 
                        variant: 'error',
                    });
                    return false;
                }
            });
      }

    return (
            <div className={classes.mainbg}>
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    minHeight="100vh"
                    pt={{xs:2}}
                    pb={{xs:2}}
                    >
                    <Grid container direction="column" xs={10} md={6} lg={5} 
                        className={classes.innerbg} 
                    >
                        <img src="/images/eprocessify-logo-svg.svg" 
                            alt="logo" 
                            className={classes.logo} />
                        <Typography variant="body1"
                            className={classes.title}>Register to eProcessify</Typography>
                        <Typography variant="subtitle1">Enter your details to create your account</Typography>

                        <Grid container item xs={12} style={{marginTop: "15px"}}>
                            <TextField
                                fullWidth
                                id="firstname"
                                label="*First Name"
                                type="text"
                                variant="outlined"
                                margin="dense"
                                InputLabelProps={{style: {fontSize: 14}}}
                                error={fnInvalid}
                                helperText={fnInvalid ? nameError : ''}
                                onChange={e => { 
                                    setFirstName(e.target.value);
                                    setFnInvalid(false);
                                }}
                            />
                        </Grid>
                        <Grid container item xs={12} style={{marginTop: "15px"}}>
                            <TextField
                                fullWidth
                                id="lastname"
                                label="*Last Name"
                                type="text"
                                variant="outlined"
                                margin="dense"
                                InputLabelProps={{style: {fontSize: 14}}}
                                error={lnInvalid}
                                helperText={lnInvalid ? nameError : ''}
                                onChange={e => { 
                                    setLastName(e.target.value);
                                    setLnInvalid(false);
                                }}
                            />
                        </Grid>
                        <Grid container item xs={12} style={{marginTop: "15px"}}>
                            <TextField
                                fullWidth
                                id="email"
                                label="*Email"
                                type="text"
                                variant="outlined"
                                margin="dense"
                                InputLabelProps={{style: {fontSize: 14}}}
                                error={emailInvalid}
                                value={email}
                                helperText={emailInvalid ? "* Email is invalid!" : ''}
                                onChange={e => { 
                                    if(Object.keys(registerEmail).length === 0){
                                        setEmail(e.target.value);
                                        setEmailInvalid(false);
                                    }
                                }}
                            />
                        </Grid>
                        <Grid container item xs={12} style={{marginTop: "15px"}}>
                            <TextField
                                fullWidth
                                id="password"
                                label="*Password"
                                type="password"
                                margin="dense"
                                variant="outlined"
                                InputLabelProps={{style: {fontSize: 14}}}
                                onChange={e => { 
                                    setPassword(e.target.value);
                                    setPasswordInvalid(false);
                                }}
                                error={passwordInvalid}
                                helperText={passwordInvalid ? passwordError : ''}
                            />
                        </Grid>
                        <Grid container item xs={12} style={{marginTop: "15px"}}>
                            <TextField
                                fullWidth
                                id="confirm-password"
                                label="*Confirm Password"
                                type="password"
                                margin="dense"
                                variant="outlined"
                                InputLabelProps={{style: {fontSize: 14}}}
                                onChange={e => { 
                                    setConfirmPassword(e.target.value);
                                    setConfirmPasswordInvalid(false);
                                }}
                                error={confirmPasswordInvalid}
                                helperText={confirmPasswordInvalid ? "* Passwords are not matching!" : ''}
                                onKeyPress={(ev) => {
                                    consoleToLog(`Pressed keyCode ${ev.key}`);
                                    if (ev.key === 'Enter') {
                                        onSubmitClick();
                                      ev.preventDefault();
                                    }
                                  }}
                            />
                        </Grid>
                        {/* <Link style={{marginTop: "12px", fontSize: "14px"}} 
                            color="secondary"
                            >Forgot your password?</Link> */}
                        <Button variant="contained" 
                            color="primary" 
                            style={{marginTop: "25px"}}
                            onClick={()=>onSubmitClick()}
                        >
                            {loading && <CircularProgress size={24} className={classes.circularProgress}/>}
                            Register to create account
                        </Button>
                        <Typography variant="subtitle1" style={{marginTop: "12px"}}>By Signing in, you agree to the <span style={{color: "blue", cursor: "pointer"}} onClick={()=>{window.open("https://eprocessify.com/terms-of-service", "_blank")}}>Terms of Service</span> and <span style={{color: "blue", cursor: "pointer"}} onClick={()=>{window.open("https://eprocessify.com/privacy-policy", "_blank")}}>Privacy Policy</span></Typography>
                    </Grid>
                </Box>
            </div>
    )
}

const mapStateToProps = (state) => ({
    auth: state.auth
});

const mapDispatchToProps = (dispatch) => ({
    login: (auth) => dispatch(login(auth))
});

export default connect(mapStateToProps, mapDispatchToProps)(SignupPage);