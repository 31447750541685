import React, {useState,useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Drawer, Grid, Typography, Button, TextField, MenuItem, CircularProgress} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {connect} from 'react-redux';
import { addOrgLabel, updateOrgLabel } from '../../actions/selectedOrganization';
import { addLabelToOrgApi, editOrgLabelApi } from '../../services/authService';
import {labelNameValidator} from '../../util/validator';
import { useSnackbar } from 'notistack';
import {consoleToLog, capitalizeFirstLetterOfEachWord, trackGAEvent} from '../../util/AppUtil';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import MobileRightToLeftSideDrawer from './MobileRightToLeftSideDrawer';

const useStyles = makeStyles((theme) => ({
    drawerPaper: {
        backgroundColor: theme.palette.primary.light,
        width: '100%', 
        height: '100vh',
        flexGrow:1,
        overflowY:'auto',
        overflowX:'hidden'
    },
    backIcon: {
        color: "#202020",
        border: " 1px solid #202020",
        padding: "4px 0px",
        fontSize: "18px",
        minWidth: "40px",
        lineHeight: "18px",
        marginBottom: "10px",
        marginRight: "16px",
    },
    mobileHeading: {
        fontSize: '18px',
        fontWeight: 500,
        marginTop:'-8px'
    },
    modalFooter: {
        position: 'absolute',
        bottom: '8px',
        left: 0,
        right:0,
        zIndex:15,
        background: '#fff',
        borderTop: '1px solid #ccc',
        padding:'6px 8px 6px 8px',
    },
    labelStyle: {
        fontSize:'14px',
        marginBottom:'-4px'
    },
    circularProgress: {
        marginLeft: 0,
        marginRight: theme.spacing(2),
        color: 'white'
    },
    labelColors: {
        width:'27px',
        height:'27px',
        borderRadius: '50%',
        margin:'8px 9px 8px 0px',
        cursor:'pointer',
        '&:last-child' : {
            marginRight: '0px'
        }
    },
    checkIcon : {
        color: 'white', 
        position:'absolute', 
        left:'6%', 
        top:'6%',
        fontWeight:'600',
        fontSize:'22px'
    },
    
}))

const MobileAddEditLabelDrawer = (props) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const title = props.editLabel ? "Update Label" : "Create Label";
    const label_name = props.editLabel ? props.editLabel.name : ''
    const label_color = props.editLabel ? props.editLabel.color : '#fc9f1b'

    const colors = [
        '#61be4e',
        '#f2d600',
        '#fc9f1b',
        '#eb5a46',
        '#c377e0',
        '#1379bf',
        '#2ec2e0',
        '#51e898',
        '#fa78CC',
        '#344563'
    ];

    const [selectedColor, setSelectedColor] = useState('#fc9f1b');
    const [labelName, setLabelName] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLabelName(label_name);
    }, [label_name]);

    useEffect(() => {
        setSelectedColor(label_color);
    }, [label_color]);

    const setSelectedLabelColor = (e, color) => {
        setSelectedColor(color);
    }

    const onAddLabelClick = (e) => {
        e.preventDefault();
        const name = labelName;
        if (!name) {
            enqueueSnackbar('Label name can not be empty!', {
                variant: 'error'
            });
            return;
        }

        const isLabelNameValid = labelNameValidator(name);
        if(!isLabelNameValid){ 
            return;
        }

        const id = props.editLabel ? props.editLabel.id : undefined; 
        const color = selectedColor;
        const organization_id = props.selectedOrganization.organization.id

        setLoading(true);

        props.editLabel ?
        editOrgLabelApi(id, color, capitalizeFirstLetterOfEachWord(name), organization_id)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response editOrgLabelApi: ", res);
                setLoading(false);
                enqueueSnackbar('Label updated!', {
                    variant: 'success'
                });
                props.updateOrgLabel(res);
                props.handleDrawerClose();
                props.setEditLabel(undefined)

                trackGAEvent(props.selectedOrganization.organization.name, 'Edit Label', `${props.auth.user.firstname} ${props.auth.user.lastname} edited label ${labelName}`);
            })
            .catch((e) => {
                consoleToLog("editOrgLabelApi error", e.response);
                setLoading(false);
                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant: 'error'
                    });
                    return false;
                }
            })
        :
        addLabelToOrgApi(color, capitalizeFirstLetterOfEachWord(name), organization_id)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response addLabelToOrgApi: ", res);
                setLoading(false);
                enqueueSnackbar('Label created!', {
                    variant: 'success'
                });
                
                props.setScrollDown(true);
                props.addOrgLabel(res);
                props.handleDrawerClose();
                setLabelName('');
                setSelectedColor('#fc9f1b');

                trackGAEvent(props.selectedOrganization.organization.name, 'Add Label', `${props.auth.user.firstname} ${props.auth.user.lastname} added label ${labelName}`);
            })
            .catch((e) => {
                consoleToLog("addLabelToOrgApi error", e.response);
                setLoading(false);
                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant: 'error'
                    });
                    return false;
                }
            });
    }

    const onCancelClick = () => {
        props.handleDrawerClose();
        props.setEditLabel(undefined);
    }

    return (
        <MobileRightToLeftSideDrawer openDrawer={props.openDrawer} 
            drawerClose={onCancelClick}
            title={title}
            >
                <div className="modal__scheduler" style={{overflow:'auto'}}>
                    <Grid item xs={12}> 
                        <Typography className={classes.labelStyle}>
                            Label Name
                        </Typography>
                        <TextField fullWidth 
                            id="label_name" 
                            variant="outlined"
                            type="text"
                            margin="dense"
                            value={labelName}
                            style={{background:'#fff'}}
                            placeholder="Enter Label Name"
                            InputLabelProps={{style: {fontSize: 13, color: "#ccc"}}}
                            onChange={e => { 
                                setLabelName(e.target.value);
                            }}
                        />
                    </Grid>
                            
                    <Grid item xs={12} style={{marginTop:'18px'}}>
                        <Typography className={classes.labelStyle}>
                            Select Color
                        </Typography>
                        <Grid item container>
                            { colors.map((color) => {
                                return <Grid item md={1} lg={1} key={color}
                                            className={classes.labelColors}
                                            onClick={(e) => setSelectedLabelColor(e, color)}
                                            style={{backgroundColor: `${color}`, position:'relative'}}
                                        >

                                            {
                                                selectedColor && selectedColor === color &&
                                                <CheckRoundedIcon className={classes.checkIcon}/>
                                            }

                                        </Grid> 
                                })
                            }       
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.modalFooter}>
                    <Grid item container alignItems='center' spacing={1}>
                        <Grid item xs={6} sm={6}>
                            <Button variant="contained" color="primary" 
                                fullWidth
                                onClick={onAddLabelClick}
                                >
                                {loading && <CircularProgress size={24} className={classes.circularProgress}/>}
                                {title}
                            </Button>
                        </Grid>

                        <Grid item xs={6} sm={6}>
                            <Button variant="outlined" 
                                fullWidth
                                onClick={onCancelClick}>
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            
        </MobileRightToLeftSideDrawer>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization
});

const mapDispatchToProps = (dispatch) => ({
    addOrgLabel : (label) => dispatch(addOrgLabel(label)),
    updateOrgLabel : (label) => dispatch(updateOrgLabel(label))
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileAddEditLabelDrawer);