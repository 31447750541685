import { Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useEffect ,useState} from 'react';
import { connect } from 'react-redux';
import { OverlayView,  setBulkObj,  setOverlayView, setOverlayToken, setOvelayMessage, setEmailItemId, setIsEmailSend} from '../actions/overlay';
import { checkBackgroundTaskComplted, downloadExportedFile } from '../services/authService';
import { consoleToLog, trackGAEvent } from '../util/AppUtil';
import { useIsMount } from './useIsMount';
import { useSnackbar } from 'notistack'; 
import {checkEmailBackgroundTaskCompleted} from '../services/genieService';
import {setCurrentEmailState, setChecklistArray, setEmailFollowupObject, setFromChecklistDetailsPage} from '../actions/selectedOrganization';
import {createEmailFollowupApi} from '../services/authService';
import {setEmailSentFrom} from '../actions/selectedOrganization';
import {setIsEmailSendError} from '../actions/selectedOrganization';
import { OverlayMessage } from '../util/AppUtil';

const OverlayComponent = (props) => {
    var interval;
    const isMount = useIsMount();
    //const [shouldShowLoading, setShouldShowLoading] = [false];
    //const [shouldShowDownload, setShouldShowDownload] = [false];
    const[errorMessage, setErrorMessage] = useState("");
    const[setUpMessage,setSetupMessage] = useState("");
    const[errorMessageArr, setErrorMessageArr] = useState([]);

    const {enqueueSnackbar} = useSnackbar();

    useEffect(()=>{
        if(!isMount && props.overlay && props.overlay.showOverlayView === OverlayView.SHOW_OVERLAY_LOADING && !props.overlay.isSendEmail){
            consoleToLog("useEffect 2: ")
            //loadData();
            interval = setInterval(loadData, 15000);
        }

    }, [props.overlay && (props.overlay.showOverlayView === OverlayView.SHOW_OVERLAY_LOADING)])

    useEffect(()=>{
        if(!isMount && props.overlay && props.overlay.showOverlayView === OverlayView.SHOW_OVERLAY_LOADING && props.overlay.emailItemId){
            consoleToLog("useEffect 2: ")
            //loadData();
            interval = setInterval(callSendEmailCheckApi, 5000);
        }

    }, [props.overlay && (props.overlay.showOverlayView === OverlayView.SHOW_OVERLAY_LOADING) && props.overlay.emailItemId])

    useEffect(() => {
        if(!isMount){
            console.log("useEffect1")
            if(props.overlay.overlayRandomToken === ""){
                //console.log("errorMessage", errorMessage)
                if (errorMessageArr && errorMessageArr.length === 0){
                    props.setOverlayView(OverlayView.NO_VIEW);
                    // consoleToLog("NO view");
                }else{
                    //props.setOverlayView(OverlayView.ERROR_VIEW);
                     //consoleToLog("error view1");
                }    
            }
        }
        return () => clearInterval(interval);
    }, [props.overlay.overlayRandomToken, errorMessage])
    

    const loadData = ()=>{
        const randomToken = props.overlay.overlayRandomToken;
        consoleToLog("loadData OverlayComponent interval called");

        if(props.overlay.overlayRandomToken !== ""){
            checkBackgroundTaskComplted(randomToken)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response checkBackgroundTaskComplted: ", res); 
                consoleToLog("Load Data status ", res.status); 

                if(res.status === 'SUCCESS')  {
                    if(res.type === "file_uri"){
                        props.setOverlayView(OverlayView.SHOW_OVERLAY_DOWNLOAD);
                        clearInterval(interval);
                        setSetupMessage("");
                    } else if (res.type === "checklist"){
                        // consoleToLog(" ******Response type checklist******", response.data.response);
                        props.setBulkObj(response.data.response);
                        props.setOverlayView(OverlayView.NO_VIEW);
                        clearInterval(interval);
                        setSetupMessage("");
                    }else if(res.type === "setup"){
                        props.setOverlayView(OverlayView.SHOW_OVERLAY_DOWNLOAD);
                        setSetupMessage("Setup completed.");
                        clearInterval(interval);
                    }
                }
            })
            .catch((e) => {
                consoleToLog("checkBackgroundTaskComplted error", e.response);
                props.setOverlayToken("");
                props.setOvelayMessage("");
                props.setOverlayView(OverlayView.ERROR_VIEW);
                clearInterval(interval);
                setSetupMessage("");
                // if (e.response.data && e.response.data.message) {
                //     // enqueueSnackbar(e.response.data.message, {variant: "error"});
                //     // props.setOverlayView(OverlayView.ERROR_VIEW);
                //     setErrorMessage(e.response.data.message);
                // }

                if (e.response.data.response && e.response.data.response.errors && e.response.data.response.errors.length > 0) {
                    // enqueueSnackbar(e.response.data.message, {variant: "error"});
                    // props.setOverlayView(OverlayView.ERROR_VIEW);
                    setErrorMessageArr(e.response.data.response.errors);
                }
 
            }); 
        } 

    }

    const clearReduxState = () => {
        props.setOverlayView(OverlayView.NO_VIEW);
        clearInterval(interval);
        props.setIsEmailSend(false);
        props.setEmailItemId('');
        props.currentEmailState && props.setCurrentEmailState(undefined);
        props.setIsEmailSendError && props.setIsEmailSendError(false);
    }
    
    const callSendEmailCheckApi = () => {
        const randomToken = props.overlay.emailItemId;
        checkEmailBackgroundTaskCompleted(randomToken)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response checkEmailBackgroundTaskCompleted: ", res); 

                if(res.status === 'SUCCESS') {
                    enqueueSnackbar('Email sent successfully!', {variant:'success'});
                    trackGAEvent(props.selectedOrganization.organization.name, `Email Sent from ${props.emailSentFrom}` , `${props.auth.user.firstname} ${props.auth.user.lastname} `);
                    clearReduxState();
                    props.setEmailSentFrom('');
                }
            })
            .catch((e) => {
                consoleToLog("checkEmailBackgroundTaskCompleted error", e.response);
                props.setOvelayMessage("");
                props.setOverlayView(OverlayView.ERROR_VIEW);
                clearInterval(interval);
                setSetupMessage("");
                props.setIsEmailSend(false);
                props.setEmailItemId('');
                props.setChecklistArray([]);
                props.setFromChecklistDetailsPage(false);
                props.setEmailFollowupObject(undefined);

                if (e.response.data.status && e.response.data.status === 'FAIL') {
                    setErrorMessageArr([e.response.data.message])
                }

            }); 

    }


    const downloadLink = () => {
        const randomToken = props.overlay.overlayRandomToken;

        downloadExportedFile(randomToken)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response downloadExportedFile: ", res);    
                consoleToLog("Response downloadExportedFile dosnload url: : ", res.document_signed_url);    
                
                trackGAEvent(props.selectedOrganization.organization.name, 'Download Exported File', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
                
                document.getElementById('initiateDownloadAttachment').href = res.document_signed_url;
                document.getElementById('initiateDownloadAttachment').click();
                
                props.setOverlayView(OverlayView.NO_VIEW);
                props.setOvelayMessage("");
            })
            .catch((e) => {
                consoleToLog("downloadExportedFile error", e);
                if (e.response.data && e.response.data.message) {
                    return false;
                }
            });
    }

    const stopApiRequest = () => {
        props.setOverlayView(OverlayView.NO_VIEW);
        clearInterval(interval);

        if(props.overlay.isSendEmail) {
            props.setIsEmailSend(false);
            props.setEmailItemId('');
            props.setCurrentEmailState(undefined);
            props.setIsEmailSendError && props.setIsEmailSendError(false);
        }
    }

    return(
        <div style={{
            position: 'absolute',
            zIndex: 2000,
          }}>
             {props.overlay && (props.overlay.showOverlayView === OverlayView.SHOW_OVERLAY_LOADING && props.overlay.overlayMessage !== "")  
                && <div style={{borderRadius: "8px", color: 'white', background: "#0071ff",  position: "fixed", bottom: "20px", right: "50px", padding: "13px 15px 15px 10px"}}>
                <div>
                    <CircularProgress color="white" style={{width: "25px", height: "25px", marginBottom: "-10px"}}/>
                    <span style={{marginBottom: "50px", marginLeft: "8px"}}>{props.overlay.overlayMessage}</span>
                </div>
                <Button variant="outlined"  
                    style={{color: "white", border: "1px solid white", float: "right", marginTop: "10px"}}
                    onClick={stopApiRequest}>
                        Close
                </Button>
            </div>}

            {props.overlay && (props.overlay.showOverlayView === OverlayView.ERROR_VIEW && errorMessageArr && errorMessageArr.length > 0)  
                && <div style={{borderRadius: "8px", color: 'white', background: "red",  position: "fixed", bottom: "20px", right: "50px", padding: "13px 15px 15px 10px"}}>
                <ul style={{margin:'0px', paddingLeft:'22px'}}>
                    {   
                        errorMessageArr && errorMessageArr.length > 0 &&
                        errorMessageArr.map((error) => {
                            return <li key={error} style={{marginBottom: "8px", padding:"0px"}}>{error}</li> 
                        })                    }
                    {/* <span style={{marginBottom: "50px", marginLeft: "8px"}}>{errorMessage}</span> */}
                </ul>
                <Button variant="outlined"  
                    style={{color: "white", border: "1px solid white", float: "right", marginTop: "10px"}}
                    onClick={()=>{
                        props.setOverlayView(OverlayView.NO_VIEW);
                        clearInterval(interval);
                    }}>
                        Close
                </Button>
            </div>}

            {props.overlay && (props.overlay.showOverlayView === OverlayView.SHOW_OVERLAY_DOWNLOAD && setUpMessage === "") && <div style={{borderRadius: "8px", color: 'white', background: "#0071ff",  position: "fixed", bottom: "20px", right: "50px", padding: "13px 15px 15px 10px"}}>
                <span style={{marginBottom: "50px", marginLeft: "8px"}}>
                    {props.overlay.overlayMessage === OverlayMessage.DOWNLOAD_MESSAGE ? 'Zip file is ready for download' : 'Spreadsheet is ready for download.'}
                </span>
                <div style={{textAlign: "center", margin: "0 auto", marginTop: "20px"}}>
                    <Button variant="outlined" 
                        style={{color: "white", border: "1px solid white"}}
                        onClick={()=>{
                            downloadLink();
                        }}>
                        Download
                    </Button>
                    <a style={{visibility: "hidden"}}
                        id="initiateDownloadAttachment"
                        target="_blank"
                        onClick={(e) => e.stopPropagation()}
                        download
                    ></a>
                    <Button variant="outlined"  
                        style={{color: "white", border: "1px solid white", marginLeft: "8px"}}
                        onClick={()=>{
                            props.setOverlayView(OverlayView.NO_VIEW);
                            props.setOvelayMessage("");
                        }}>
                        Close
                    </Button>
                </div>
            </div>}

            {props.overlay && (props.overlay.showOverlayView === OverlayView.SHOW_OVERLAY_DOWNLOAD && setUpMessage !=="") && 
            <div style={{borderRadius: "8px", color: 'white',background: "#0071ff",  position: "fixed", bottom: "20px", right: "50px", padding: "13px 15px 15px 10px"}}>
                <div>
                    <span style={{marginBottom: "50px", marginLeft: "8px"}}>{setUpMessage}</span>
                </div>
                <Button variant="outlined"  
                    style={{color: "white", border: "1px solid white", float: "right", marginTop: "10px"}}
                    onClick={()=>{
                        props.setOverlayView(OverlayView.NO_VIEW);
                        clearInterval(interval);
                    }}>
                        Close
                </Button>
            </div>
        }
        </div>
    )
}



const mapStateToProps = (state) => ({
    auth: state.auth,
    overlay: state.overlay,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    currentEmailState: state.organizationInfo.currentEmailState,
    fromChecklistDetails: state.organizationInfo.fromChecklistDetails,
    selectedTemplate: state.organizationInfo.selectedTemplate,
    emailSentFrom: state.organizationInfo.emailSentFrom,
    isEmailSendError: state.organizationInfo.isEmailSendError
});

const mapDispatchToProps = (dispatch) => ({
    setOverlayView: (exportView) => dispatch(setOverlayView(exportView)),
    setBulkObj: (bgObj) => dispatch(setBulkObj(bgObj)),
    setOverlayToken: (randomToken) => dispatch(setOverlayToken(randomToken)),
    setOvelayMessage: (overlayMessage) => dispatch(setOvelayMessage(overlayMessage)),
    setEmailItemId: (emailItemId) => dispatch(setEmailItemId(emailItemId)),
    setIsEmailSend: (isEmailSend) => dispatch(setIsEmailSend(isEmailSend)),
    setCurrentEmailState: (emailState) => dispatch(setCurrentEmailState(emailState)),
    setChecklistArray: (checklistArray) => dispatch(setChecklistArray(checklistArray)),
    setEmailFollowupObject: (emailFollowup) => dispatch(setEmailFollowupObject(emailFollowup)),
    setFromChecklistDetailsPage: (fromChecklistDetails) => dispatch(setFromChecklistDetailsPage(fromChecklistDetails)),
    setEmailSentFrom: (str) => dispatch(setEmailSentFrom(str)),
    setIsEmailSendError: (emailSendError) => dispatch(setIsEmailSendError(emailSendError))

})

export default connect(mapStateToProps, mapDispatchToProps)(OverlayComponent)