import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Grid, Typography, Button, CircularProgress} from '@material-ui/core';
import {getPredefinedTemplateEmailDraftListApi, deletePredefinedEmailDraftApi} from '../services/genieService';
import {connect} from 'react-redux';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import AddEditEmailDraftModal from './modals/AddEditEmailDraftModal';
import { consoleToLog } from '../util/AppUtil';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
    containerStyles: {
        width:'100%',
        background:'#fff',
        borderRadius:'4px',
        padding:'0px 24px',
        marginBottom:'32px'
    },
    emailDraftListContainer : {
        position:'relative',
        background:'#F9F9F9',
        padding:'12px 16px',
        borderRadius:'8px',
        marginBottom:'12px',
        '& .hoverIcons' : {
            visibility:'hidden',
            minWidth:'20px',
            padding:'0 8px',
            fontSize:'18px'
        },
        '&:hover' : {
            background: 'rgba(0, 113, 255, 0.08)'
        },
        '&:hover .hoverIcons' : {
            visibility:'visible',
            minWidth:'20px',
            fontSize:'18px'
        },
    },
    mailIcon : {
        position:'absolute',
        marginRight:'16px',
        lineHeight:0
    },
    customClasses1 : {
        maxWidth:'13%',
        flexBasis:'13%',
    },
    emailSubject : {
        lineHeight:'18px'
    },
    emailBody : {
        lineHeight:'18px',
        color:'#6F7378',
        maxWidth:'auto !important',
        overflow:'hidden !important',
        whiteSpace:'nowrap !important',
        textOverflow:'ellipsis !important',
    },
}))

const PredefinedEmailDrafts = (props) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();

    const [predefinedEmailDraftList, setPredefinedEmailDraftList] = useState([]);
    const [predefinedEmailDraftObj, setPredefinedEmailDraftObj] = useState(undefined);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getPredefinedEmailDraftList()
    }, []);

    const getPredefinedEmailDraftList = () => {
        const organinzation_id = props.selectedOrganization.organization.id;

        setLoading(true);
        getPredefinedTemplateEmailDraftListApi(organinzation_id)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response getPredefinedTemplateEmailDraftListApi: ', res);
                setLoading(false);

                setPredefinedEmailDraftList(res.predefined_email_drafts);
            })
            .catch((e) => {
                consoleToLog("Error getPredefinedTemplateEmailDraftListApi: ", e.response);
                setLoading(false);

            })
    }

    const onDeletePredefinedEmailDraftClick = (e, draft) => {
        if(window.confirm("Are you sure? You want to delete this draft?")) {
            const organization_id = props.selectedOrganization.organization.id
            const id = draft.id;

            deletePredefinedEmailDraftApi(organization_id, id)
                .then((response) => {
                    const res = response.data;
                    consoleToLog('Response deletePredefinedEmailDraftApi ', res);
                    enqueueSnackbar(res.message, {
                        variant:'success'
                    });

                    const updatedEmailDraftArr = predefinedEmailDraftList.filter((draft) => draft.id !== id);
                    setPredefinedEmailDraftList(updatedEmailDraftArr);
                })
                .catch((e) => {
                    consoleToLog("deletePredefinedEmailDraftApi error", e);
                    if (e.response.data && e.response.data.message) {
                        enqueueSnackbar(e.response.data.message, {
                            variant:'error'
                        })
                        return false;
                    }
                });
        }  

    }

    const PredefinedEmailDraftListUI = () => {
        return (
            <Grid item md={12} lg={12}>
            {
            predefinedEmailDraftList && predefinedEmailDraftList.length > 0 && 
            predefinedEmailDraftList.map((draft) => {
                const emailBody = draft.body.replace(/<[^>]*>?/gm, '');
                return <Grid item container
                            key={draft.id}
                            className={classes.emailDraftListContainer}
                            alignItems='center'
                            justifyContent='space-between'>
                            <Grid item className={classes.mailIcon}>
                                <MailOutlineIcon />
                            </Grid>

                            <Grid item md={9} lg={9} style={{marginLeft:'40px'}}>
                                <Typography variant="body1" className='schedulerTitle'>
                                    {draft.title}  
                                </Typography>

                                <Typography variant="subtitle1" className={classes.emailSubject}>
                                    {draft.subject}
                                </Typography>
                                <Typography variant="subtitle1"  className={classes.emailBody}>
                                        {emailBody}
                                </Typography>
                            </Grid>

                            <Grid item md={2} className={classes.customClasses1}>
                                <Grid item container justifyContent="flex-end">
                                    <div style={{marginTop:'7px'}}>
                                        <Button className="hoverIcons" 
                                            onClick={(e) => onEditPredefinedEmailDraftClick(e, draft)}>
                                            <i className="flaticon-edit"></i>
                                        </Button >
                                        <Button className="hoverIcons"
                                            onClick={(e) => onDeletePredefinedEmailDraftClick(e, draft)}>
                                            <i className="flaticon-delete-1"></i>
                                        </Button>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    })
                }
                </Grid>
        )
    }

    const onEditPredefinedEmailDraftClick = (e, draft) => {
        setPredefinedEmailDraftObj(draft);
        props.setOpenDrawer(true);
    }

    return (
        <Grid item sm={12} className={classes.containerStyles}>
            {loading ?
                <CircularProgress size={30} style={{position:'relative', left:'50%', right:'50%', margin:'16px 0px'}}/>
                :
                <Grid item container direction="column" alignItems="center" style={{marginTop:'24px'}}>
                    {
                        <PredefinedEmailDraftListUI />
                    }
                </Grid>
            }
            
            <AddEditEmailDraftModal openDrawer={props.openDrawer}
                handleDrawerClose={props.handleDrawerClose} 
                predefinedEmailDraftObj={predefinedEmailDraftObj}
                setPredefinedEmailDraftObj={setPredefinedEmailDraftObj}
                setPredefinedEmailDraftList={setPredefinedEmailDraftList}
                predefinedEmailDraftList={predefinedEmailDraftList}
                fromPredefinedComponent={true}
                />
        </Grid>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization
});

export default connect(mapStateToProps)(PredefinedEmailDrafts);