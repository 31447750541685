import React, {useState, useEffect} from 'react';
import {Grid, Typography, TextField, Button, CircularProgress, FormControlLabel, Checkbox, Box, MenuItem} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import RangeOptionsAutocomplete from './RangeOptionsAutocomplete';
import {connect} from 'react-redux';
import { setSelectedDrawerState } from '../actions/selectedOrganization';
import {createTemplateApi, editTemplateApi} from '../services/authService';
import {useSnackbar} from 'notistack';
import { consoleToLog, trackGAEvent } from '../util/AppUtil';
import {setOrgTemplates} from '../actions/selectedOrganization';
import {history} from '../routers/AppRouter';
import ScheduleCreationTypeAutocomplete from './ScheduleCreationTypeAutocomplete';
import {useIsMount} from './useIsMount';

const useStyles = makeStyles((theme) => ({
    innerbg: {
        //border: "1px solid #dbdbdb",
        borderRadius: "10px",
        padding: "32px 48px",
        background: "#ffffff",
        // [theme.breakpoints.down("md")]: {
        //     padding: "45px 20px",
        //     }
    },
    templateHeading: {
        fontSize : "24px",
        fontWeight: "600",
        margin:'0px auto',
        textAlign:'center'
    },
    labelStyles: {
        lineHeight:1, 
        color:'#555555'
      },  
    MuiFormControlLabellabel :{
        marginLeft:'-4px'
    },
    circularProgress: {
        marginLeft: 0,
        marginRight: theme.spacing(2),
        color: 'white'
    },   

}))


const CreateEditTemplate = (props) => {
    const classes = useStyles();
    const isMount = useIsMount();
    const {enqueueSnackbar} = useSnackbar();

    let temp_name = props.fromPredefinedTemplate ? props.editPredefinedTemplate?.name : props.editTemplate?.name 
    let schedule_type = props.fromPredefinedTemplate ? props.editPredefinedTemplate?.schedule_type : props.editTemplate?.schedule_type; 
    let schedule_creation_type = props.fromPredefinedTemplate ? props.editPredefinedTemplate?.schedule_creation_type : 
    props.editTemplate?.schedule_creation_type; 
    let schedule_value = props.fromPredefinedTemplate ? props.editPredefinedTemplate?.schedule : props.editTemplate?.schedule;
    let default_due_date_value = props.fromPredefinedTemplate ? props.editPredefinedTemplate?.due_date_days : props.editTemplate?.due_date_days;

    const [loading, setLoading] = useState(false);
    const [recurring, setRecurring] = useState(true);
    const [templateName, setTemplateName] = useState('');
    const [selectedScheduleType, setSelectedScheduleType] = useState([]);
    const [selectedScheduleCreationType, setSelectedScheduleCreationType] = useState([]);
    const [dueDateDays, setDueDateDays] = useState('');


    const scheduleRangeOptions = [{name: "Monthly", value:"monthly"}, 
    {name: "Quarterly", value:"quarterly"}, 
    {name: "Half Yearly", value:"half_yearly" }, 
    {name: "Yearly", value:"yearly"}];

    const scheduleCreationTypeOptions = [
        {name: "Start of period", value:"start"}, 
        {name: "End of period", value:"end"}
    ];

    useEffect(() => {
        if(props.editTemplate || props.editPredefinedTemplate) {
            setTemplateName(temp_name);
            setRecurring(schedule_value);
            setDueDateDays(default_due_date_value);

            if(schedule_type) {
                let range = scheduleRangeOptions.filter((option) => schedule_type.includes(option.value));
                setSelectedScheduleType(range);
            }

            if(schedule_creation_type) {
                let range = scheduleCreationTypeOptions.filter((option) => schedule_creation_type.includes(option.value));
                setSelectedScheduleCreationType(range);
            }
        }
    }, [props.editTemplate, props.editPredefinedTemplate])

    useEffect(() => {
        if(!isMount) {
            if(!recurring) {
                setSelectedScheduleType([]);
                setSelectedScheduleCreationType([]);
            }
        }

    }, [recurring])

    const onCreateTemplateClick = () => {
        const name = templateName;
        if(!name){
            enqueueSnackbar("Template name cannot be Empty", {
                variant: 'error'
            });
            return;
        }

        const schedule = recurring;
        const schedule_type = selectedScheduleType.map((option) => option.value).join();
        if(recurring && schedule_type === '') {
            enqueueSnackbar('Periodicity cannot be empty', {
                variant: 'error'
            });
            return;
        }
        const schedule_creation_type = selectedScheduleCreationType.map((option) => option.value).join();
            if(recurring && schedule_creation_type === '') {
                enqueueSnackbar('Creation Type cannot be empty', {
                    variant: 'error'
                });
                return;
            }

        const organization_id = props.selectedOrganization.organization.id;
        const template_id = props.editTemplate ? props.editTemplate.template_id : props.editPredefinedTemplate?.predefined_template_id;
        const templateString = props.fromPredefinedTemplate ? 'predefined_template' : 'template';
        const due_dates_days = !dueDateDays ? 0 : dueDateDays;

        setLoading(true);

        props.editTemplate || (props.fromPredefinedTemplate && props.editPredefinedTemplate) ?
        editTemplateApi(organization_id, templateString, template_id, name, schedule, schedule_type, schedule_creation_type, due_dates_days)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response editTemplateApi: ', res);
                setLoading(false);

                enqueueSnackbar(res.message, {
                    variant:'success'
                });

                if(props.fromPredefinedTemplate) {
                    trackGAEvent(props.selectedOrganization.organization.name, 'Predefined Template Updated', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
                    props.setPredefinedTemplateObj(res.predefined_template);
                    props.setEditPredefinedTemplate(undefined);
                } else {
                    trackGAEvent(props.selectedOrganization.organization.name, 'Template Updated', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
                    props.setTemplateObj(res.template);
                    props.setEditTemplate(undefined);
                }    
                // let templateArr = props.templates.concat(res.template);
                // props.setOrgTemplates(templateArr);

            })
            .catch((e) => {
                consoleToLog('Error editTemplateApi: ', e.response);
                setLoading(false);
                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, ({
                        variant: 'error'
                    }));
                    return false;
                }
            })
        :
        createTemplateApi(organization_id, name, schedule, schedule_type, schedule_creation_type, templateString, due_dates_days)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response createTemplateApi: ', res);
                setLoading(false);

                enqueueSnackbar(res.message, {
                    variant:'success'
                });

                if(props.fromPredefinedTemplate) {
                    trackGAEvent(props.selectedOrganization.organization.name, 'Predefined Template Added', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
                    props.setPredefinedTemplateObj(res.predefined_template)
                } else {
                    trackGAEvent(props.selectedOrganization.organization.name, 'Template Added', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
                    props.setTemplateObj(res.template);
                }   
                //let templateArr = props.templates.concat(res.template);
               // props.setOrgTemplates(templateArr);

            })
            .catch((e) => {
                consoleToLog('Error createTemplateApi: ', e.response);
                setLoading(false);
                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, ({
                        variant: 'error'
                    }));
                    return false;
                }
            })

    }  

    return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="100%"
                >
                <Grid item xs={10} md={6} lg={5}  className={classes.innerbg}>
                {window.location.pathname.indexOf('/create')!== -1 ?
                    <Typography className={classes.templateHeading}>
                        {props.fromPredefinedTemplate ? 'Create Predefined Template' : 'Create Template'  }
                    </Typography>
                    :
                    <Typography className={classes.templateHeading}>
                        {props.fromPredefinedTemplate ? 'Update Predefined Template' : 'Update Template'  }
                    </Typography>
                }
                <Grid item xs={12} style={{marginTop:'16px'}}>
                        <Typography variant="body2" className={classes.labelStyles}>
                            Template Name
                        </Typography> 
                        <TextField 
                            fullWidth
                            id="template_name"
                            margin="dense"
                            variant="outlined"
                            autoComplete="off"
                            value={templateName}
                            placeholder="Enter Template Name"
                            //label="Template Name"
                            InputLabelProps={{style: {fontSize: 14}}}
                            onChange={(e) => setTemplateName(e.target.value)}
                    />
                </Grid>

                <Grid item xs={12}> 
                        <FormControlLabel
                            style={{marginBottom:'-8px'}}
                            classes={{
                                label: classes.MuiFormControlLabellabel
                            }}
                            control={
                            <Checkbox
                                name="checkedB"
                                color="primary"
                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                checked={recurring}
                                onChange={() => setRecurring(!recurring)}
                                onClick={(e) => e.stopPropagation()}
                            />
                            }
                            label="Recurring"
                        />
                    <RangeOptionsAutocomplete  disabled={!recurring} 
                            selectedScheduleType={selectedScheduleType}
                            setSelectedScheduleType={setSelectedScheduleType}
                            scheduleRangeOptions={scheduleRangeOptions}
                            editTemplate={props.editTemplate}
                    />
                </Grid> 


                <Grid item xs={12} style={{marginTop:'16px'}}>
                        <Typography variant="body2" className={classes.labelStyles}>
                            Creation Type
                        </Typography> 
                        <ScheduleCreationTypeAutocomplete disabled={!recurring} 
                            selectedScheduleCreationType={selectedScheduleCreationType}
                            setSelectedScheduleCreationType={setSelectedScheduleCreationType}
                            editTemplate={props.editTemplate}
                            scheduleCreationTypeOptions={scheduleCreationTypeOptions}
                        />    
                </Grid> 

                <Grid item xs={12} style={{marginTop:'16px'}}>
                        <Typography variant="body2" className={classes.labelStyles}>
                            Default Due Date
                        </Typography>
                        <TextField
                            type="number"
                            fullWidth
                            id="due_date"
                            margin="dense"
                            variant="outlined"
                            autoComplete="off"
                            value={dueDateDays}
                            placeholder="Days"
                            InputLabelProps={{style: {fontSize: 14}}}
                            onChange={(e) => setDueDateDays(e.target.value)}
                        />
                </Grid>

                <Grid item container spacing={1} justifyContent="center" style={{marginTop:'24px'}}>
                    <Grid item>  
                        <Button variant="outlined" style={{
                            minWidth: '130px',
                        }} onClick={() => history.push('/dashboard')}>
                            Cancel
                        </Button>
                    </Grid>

                    <Grid item>    
                        <Button variant="contained" color="primary" 
                            className="modal__button"
                            onClick={onCreateTemplateClick}
                            >
                            {loading && <CircularProgress size={24} className={classes.circularProgress}/>}
                            Next
                        </Button>
                    </Grid>
                    
                </Grid>   

                </Grid>
            </Box>        
            );
        }

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    setDrawerState: state.organizationInfo.setDrawerState,
    templates: state.organizationInfo.templates,
});

const mapDispatchToProps = (dispatch) => ({
    setSelectedDrawerState: (drawerState) => dispatch(setSelectedDrawerState(drawerState)),
    setOrgTemplates: (templates) => dispatch(setOrgTemplates(templates))
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateEditTemplate);