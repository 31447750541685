import React, {Fragment} from 'react';
import {TextField, Grid, Avatar, Chip} from '@material-ui/core'; 
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles((theme) => ({
    avatar : {
        width: '15px !important',
        height: '15px !important',
        fontSize: '12px !important',
        background: 'none !important',
        color: 'rgba(0, 0, 0, 0.87) !important',
        border: '1px solid rgba(0, 0, 0, 0.26) !important'
        },

    chip : {
        borderRadius: '4px !important',
        backgroundColor: '#f9f9f9 !important',
        border: '1px solid #ececec !important',
        },

    avatarStyle: {
        width: 20,
        height: 20,
        fontSize: 12
        },
        autoStyle: {
        "&::-webkit-scrollbar": {
            display: "none"
        }
            
    }
    }
));

const ScheduleCreationTypeAutocomplete = (props) => {
    const classes = useStyles();

    // const onRangeOptionClick = (option) => {
    //     //let range = props.selectedScheduleCreationType.concat(option);
    //     //props.setSelectedScheduleCreationType(range);
    // }

    const onDeleteRangeOption = (option) => {
        //setCreationTypeOptions(creationTypeOptions.filter(entry => entry !== option));
        props.setSelectedScheduleCreationType(props.selectedScheduleCreationType.filter(entry => entry !== option));
    }

    const renderOptions = (option) => {
        return (
            <div style={{display: "flex", width: "100%"}} 
                className={classes.autoStyle} 
                //onClick={() => onRangeOptionClick(option)}
            >
                <Avatar style={{marginRight: "6px", marginTop: "4px"}}
                    classes={{
                        root: classes.avatar
                    }}
                >
                    {option.name.charAt(0)}
                </Avatar>
                {option.name}
            </div>
            );
    }
    
    const renderTags = (options) => {
        return (
            options.map(option =>
                <Fragment>
                <Chip
                    classes={{
                        root: classes.chip
                    }}
                    key={option}
                    avatar={<Avatar classes={{
                        root: classes.avatar
                    }}>{option.name.charAt(0)}</Avatar>}
                    label={option.name}
                    onDelete={() => onDeleteRangeOption(option)}
                    variant="outlined"
                    style={{margin: "2px 2px"}}
                    deleteIcon={<ClearIcon  style={{width: "18px"}}/>}
                />
                </Fragment>))
        }

    return (
        <div style={{marginTop:'6px'}}>
           {/* <span style={{fontSize:'14px'}}>
                Members            
            </span> */}
            <Grid container md={12} lg={12} style={{marginTop:'8px'}}>
                <Grid item lg={12}>
                <Autocomplete
                    multiple
                    style={{width: "100%"}}
                    id="size-small-outlined-multi"
                    size="small"
                    options={props.scheduleCreationTypeOptions}
                    getOptionLabel={(option) => option.name}
                    disableClearable
                    renderOption={(option) => renderOptions(option)}
                    value={props.selectedScheduleCreationType}
                    onChange={(e, newValue) => {
                        //setCreationTypeOptions(newValue);
                        props.setSelectedScheduleCreationType(newValue);
                    }}
                    renderTags={(options) => renderTags(options)}
                    renderInput={(params) => (
                        <TextField {...params} variant="outlined" placeholder="Create At" />
                    )}
                    disabled={props.disabled}
                />
                </Grid>
            </Grid>    
        </div>
    );
}


export default ScheduleCreationTypeAutocomplete;