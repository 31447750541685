import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Typography, Button, CircularProgress, InputAdornment, IconButton, MenuItem, Select, TextField} from '@material-ui/core';
import {connect} from 'react-redux';
import { updateSelectedOrg } from '../../actions/selectedOrganization';
import { updateOrganization } from '../../actions/organization';
import { consoleToLog, capitalizeFirstLetterOfEachWord, shouldDisable, AccessLevel, trackGAEvent } from '../../util/AppUtil';
import { useSnackbar } from 'notistack';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import CheckSharpIcon from '@material-ui/icons/CheckSharp';
import ClearIcon from '@material-ui/icons/Clear';
import { orgNameValidator, emailValidator } from '../../util/validator';
import {countries} from "../../data/country.json";
import {organizationUpdateApi, verifyOrgEmailApi} from '../../services/authService';

const useStyles = makeStyles((theme) => ({
    mainContent : {
        width: '100%',
        height:'calc(100vh - 100px)',
        backgroundColor: theme.palette.primary.light,
        transition: ".5s",
        flexGrow: 1,
        //overflow:'hidden',
        overflowY:'auto',
        overflowX:'hidden'
        },
    container: {
        marginTop: "8px",
        marginBottom:'16px',
    },
    settingsContainer: {
        width:'100%',
        marginTop:'16px',
        padding:'0 12px'
    },
    imgContainer :{
        marginTop:"8px",
        padding:'10px',
        borderRadius:'50%',
        border: '1px solid rgba(0, 113, 255, 0.19)',
        boxSizing:'border-box',
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        width:'120px',
        height:'120px',
        marginBottom:'16px'
    },
    imgContainer1 :{
        borderRadius:'50%',
        display: 'block',
        width:'105px',
        height:'105px',
        position:'relative',
        top:'-2px',
        left:'-2.5px'
    },
    uploadImg: {
        position:'absolute',
        minWidth:'25px', 
        height:'25px', 
        borderRadius:'50%',
        background:'#3d3d3d', 
        color:'white',
        cursor:'pointer',
        display:'block',
        left:'80px',
        top:'95px'
    },
    customColumn : {
        flexBasis:'23%',
        maxWidth:'23%'
    },
    circularProgress: {
        marginLeft: 0,
        marginRight: theme.spacing(2),
        color: 'white'
    },  
    formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    },
    selectCountry:{
        width:"100%",
        height:"2.5rem",
        padding:"2px",
    },
    selectCountry2:{
        width:"100%",
        height:"2.5rem",
        padding:"2px",
        color:"grey"
    },
    footer: {
        position:'absolute',
        width:'100%',
        bottom:'0px',
        padding:'16px',
        background:'white',
        borderTop:'1px solid rgba(0,0,0,0.1)'
    }
}))

const MobileOrganizationSettings = (props) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();

    let org_name = props.selectedOrganization.organization.name ? props.selectedOrganization.organization.name : '';
    let org_email = props.selectedOrganization.organization.email ? props.selectedOrganization.organization.email : '';
    let org_country = props.selectedOrganization.organization.country ? props.selectedOrganization.organization.country : 'none';
    let start_month = props.selectedOrganization.organization?.financial_year_start_month;

    const [attachment, setAttachment] = useState(undefined);
    const [organizationName, setOrganizationName] = useState(org_name);
    const [organizationEmail, setOrganizationEmail] = useState(org_email);
    const [selectedcountry, setSelectedCountry] = useState(org_country);
    const [loading, setLoading] = useState(false);
    const [monthValue, setMonthValue] = useState(start_month);

    const [openDrawer, setOpenDrawer] = useState(false);

    const { image } = props.selectedOrganization.organization || {};
    const { access_level } = props.selectedOrganization || {};
    const disabled = shouldDisable(AccessLevel.getUserAccessLevelValue(access_level), AccessLevel.OWNER);
    const isEmailVerified = props.selectedOrganization.organization.is_email_verified;

    const startMonthOptions = [{name: "January", value:1}, {name: "February", value: 2}, {name: "March", value:3}, 
            {name: "April", value: 4}, {name: "May", value: 5}, {name: "June", value: 6},
            {name: "July", value: 7}, {name: "August", value:8}, {name: "September", value: '9'}, 
            {name: "October", value: 10}, {name: "November", value: 11}, {name: "December", value: 12}
        ];

    const onOrgPhotoSelected = (e) => {
        consoleToLog("onOrgPhotoSelected",e.target.files[0]);
        setAttachment (e.target.files[0])
        var oFReader = new FileReader();
        oFReader.readAsDataURL(e.target.files[0]);
        oFReader.onload = function (oFREvent) {
            document.getElementById("organizationPhoto").src = oFREvent.target.result;
        };
    }

    const handleCountryChange = (event) => {
        console.log("Country: ", event.target.value);
        setSelectedCountry(event.target.value);
    };

    const onVerifyOrgEmailClick = () => {
        const organization_id = props.selectedOrganization.organization.id;

        verifyOrgEmailApi(organization_id)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response verifyOrgEmailApi: ", res)
                enqueueSnackbar(res.message, {
                    variant:'success'
                });
                trackGAEvent(props.selectedOrganization.organization.name, 'Verify Email From Settings', `${props.auth.user.firstname} ${props.auth.user.lastname} clicked email verification link from settings`);
            })
            .catch((e) => {
                consoleToLog("verifyOrgEmailApi error", e);

                if (e && e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant:'success'
                    });
                    return false;
                }
            });
    }

    const endAdornmentIcon = () => {
        return (
            <InputAdornment position="end" onClick={() => {
                if (!disabled && !isEmailVerified) onVerifyOrgEmailClick()
            }}>
                <IconButton disableRipple style={{color: isEmailVerified ? 'green' : 'red', padding:'0', background:"none"}}>
                    {isEmailVerified ? 
                        <CheckSharpIcon fontSize="medium" /> 
                        : 
                        <>
                            <ClearIcon fontSize="small" style={{marginRight:'4px', fontWeight:'bold'}}/>
                            <Typography style={{color:"#0071FF"}}>Verify </Typography>
                        </>
                    }
                </IconButton>
                
            </InputAdornment>
        )
    }

    const onUpdateClick = (e) => {
        e.preventDefault();

        var orgName = organizationName;
        orgName = orgName.trim();//remove spaces from both side

        if (!orgName) {//check empty
            enqueueSnackbar('Organization name can not be empty!', {
                variant:'error'
            });
            return;
        }

        const isOrgNameValid = orgNameValidator(orgName);

        if (!isOrgNameValid) {
            enqueueSnackbar('Organization name is invalid. It should contain atleast one character.', {
                variant:'error'
            });
            return;
        }

        var orgEmail = organizationEmail;
        orgEmail = orgEmail.trim();//remove spaces from both side

        // if (!orgEmail) {
        //     enqueueSnackbar('Organization email can not be empty!', {
        //         variant:'error'
        //     });
        //     return;
        // }
        // const isOrgEmailValid = emailValidator(orgEmail);
        // if (!isOrgEmailValid) {
        //     return;
        // }
        if(selectedcountry===undefined){
            enqueueSnackbar('Country field can not be entry!', {
                variant:'error'
            });
            return;
        }

        const financial_year_start_month = monthValue;
        if(financial_year_start_month === -1) {
            enqueueSnackbar('Please select ffinancial year start month', {variant:'error'});
            return
        }

        const id = props.selectedOrganization.organization.id
        const image = attachment

        setLoading(true);
        //update organization api call
        organizationUpdateApi(id, capitalizeFirstLetterOfEachWord(orgName), image, selectedcountry, financial_year_start_month)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response organizationUpdateApi: ", res);
                setLoading(false);
                enqueueSnackbar(res.message, {
                    variant:'success'
                });

                //redux updation
                props.updateOrganization(res.organization);//update organization list in redux
                props.updateSelectedOrg(res.organization);//update selected_org in redux 

                trackGAEvent(props.selectedOrganization.organization.name, ' Save Organization', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
            })
            .catch((e) => {
                consoleToLog("organizationUpdateApi error", e.response);
                setLoading(false);
                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant:'error'
                    });
                    return false;
                }
            });
    }

    const onResetClick = () => {
        setOrganizationName(org_name);
        setOrganizationEmail(org_email);
        setSelectedCountry(org_country);
        setAttachment(undefined);
    }

    const handleMonthChange = (e) => {
        setMonthValue(e.target.value);
    }

    return (
        <div className={classes.mainContent}>
            <Grid item container direction="column" className={classes.settingsContainer}>
                <Grid item md={2} lg={2}>
                    <div style={{position:"relative"}} className={classes.imgContainer}>
                        <img className={classes.imgContainer1}
                            src={image ? image : "/images/eprocessify-icon.png"}
                            id="organizationPhoto"
                            alt="OrganizationPIc"
                        />
                        <label className={classes.uploadImg}>
                        <Typography style={{marginLeft:'4px', marginTop:'3px'}}>
                            <EditRoundedIcon fontSize="small" style={{fontSize:'18px'}}/>
                        </Typography>
                            <input style={{display:'none'}}
                                type="file"
                                id="input_orgPhoto"
                                accept="image/x-png,image/gif,image/jpeg"
                                onChange={onOrgPhotoSelected}
                                disabled={disabled}
                            />
                        </label>
                    </div>
                </Grid>  

                <Grid item container direction="column" spacing={2} style={{marginTop:'2px'}}>
                    <Grid item md={12} lg={12}>
                        <Typography variant="body2">
                            Organization Name
                        </Typography>

                        <TextField 
                            fullWidth
                            variant="outlined"
                            margin="dense"
                            value={organizationName}
                            placeholder="Enter Organization Name"
                            InputLabelProps={{style: {fontSize: 13, marginTop:'3px'}}}
                            disabled={disabled}
                            onChange={(e) => setOrganizationName(e.target.value)}
                            style={{background:'#fff'}}
                        />
                    </Grid> 

                    <Grid item md={12} lg={12}>
                        <Typography variant="body2" style={{marginBottom:'6px'}}>
                            Country  
                        </Typography>
                            <Select 
                                className={selectedcountry !== undefined ? classes.selectCountry : classes.selectCountry2}
                                value={selectedcountry !== undefined ? selectedcountry : "none"}
                                onChange={handleCountryChange}
                                variant="outlined"
                                placeholder='Select Country'
                                autoWidth
                                disabled={disabled}
                                style={{background:'#fff'}}
                            >
                            {selectedcountry === undefined && 
                                <MenuItem disabled value="none">Select Country</MenuItem>
                            }
                            {countries.map((country)=>{
                                return(
                                    <MenuItem value={country.code}>{country.name}</MenuItem>
                                )
                            })}
                            </Select>

                    </Grid>

                    <Grid item  md={12} lg={12}>
                        <Typography variant='subtitle1' style={{marginBottom:'6px', marginTop:'3px'}}>
                            Financial Year Start Month
                        </Typography>
                        <Select 
                            placeholder='Select Financial Year Start Month'
                            variant='outlined'
                            margin='dense'
                            value={monthValue}
                            onChange={handleMonthChange}
                            select
                            fullWidth
                            disabled={disabled}
                            style={{background:'#fff'}}
                        >
                            {
                                <MenuItem value={-1}>Please Select Start Month</MenuItem>
                            }
                            {
                                startMonthOptions.map((val) => {
                                    return <MenuItem key={val.name} value={val.value}>
                                                {val.name}
                                            </MenuItem> 
                                })
                            }
                            
                        </Select>
                    </Grid>

                </Grid> 
            </Grid>
            <Grid item xs={12} sm={12} 
                    className={classes.footer}>
                    <Grid item container spacing={1}>
                        <Grid item xs={6} sm={6}>
                            <Button variant="contained" 
                                color="primary" 
                                fullWidth
                                disabled={disabled}
                                style={{padding:'8px'}}
                                onClick={onUpdateClick}
                                >
                                {loading && <CircularProgress size={24} className={classes.circularProgress}/>}
                                Save
                            </Button>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <Button variant="outlined" 
                                fullWidth
                                color="primary" 
                                style={{padding:'8px'}}
                                onClick={onResetClick}>
                                Reset
                            </Button>
                        </Grid>

                    </Grid>   
            </Grid>
        </div>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    accountList: state.organizationInfo.accountList
});

const mapDispatchToProps = (dispatch) => ({
    updateOrganization: (organization) => dispatch(updateOrganization(organization)),
    updateSelectedOrg: (organization) => dispatch(updateSelectedOrg(organization))
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileOrganizationSettings);