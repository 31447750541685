import React, {useState, useEffect} from 'react';
import MobileRightToLeftSideDrawer from './MobileRightToLeftSideDrawer';
import {Grid, Typography, IconButton, CircularProgress, Button, FormControlLabel, 
    Checkbox, TextField, InputAdornment, MenuItem} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { useIsMount } from '../../components/useIsMount';
import {getInvoicePeriodicityPlaceholder, consoleToLog, trackGAEvent} from '../../util/AppUtil';
import { useSnackbar } from 'notistack';
import {updateCostAndPeriodicityForInvoiceConfigApi} from '../../services/invoiceService';
import {connect} from 'react-redux';

const useStyles = makeStyles((theme) => ({
    circularProgress: {
        marginLeft: 0,
        marginRight: theme.spacing(2),
        color: 'white'
    },
    MuiFormControlLabelroot : {
        '& .MuiFormControlLabel-label' : {
            fontSize: '14px',
            color: '#555555',
            marginLeft:'-4px'
        }
    },
    MuiTextFieldroot : {
        '& .MuiOutlinedInput-adornedEnd': {
            paddingRight: '8px'
        }
    },
    inputLabel: {
        marginBottom:'-7px'
    },
    modalFooter: {
        display: 'flex',
        position: 'absolute',
        bottom:0,
        left: 0,
        right:0,
        padding: '12px 16px 12px 16px',
        zIndex: '15',
        background: '#fff',
        borderTop: '1px solid #ccc'
    }
}))


const MobileUpdateInvoiceConfigDrawer = (props) => {
    const classes = useStyles();
    const isMount = useIsMount();
    const {enqueueSnackbar} = useSnackbar();

    const [loading, setLoading] = useState(false);
    const [billableStatus, setBillableStatus] = useState(false);
    const [invoiceCost, setInvoiceCost] = useState(0);
    const [invoicePeriodicity, setInvoicePeriodicity] = useState(-1);

    useEffect(() => {
        if(props.invoiceObj) {
            setBillableStatus(props.invoiceObj?.billable);
            setInvoiceCost(props.invoiceObj?.cost);
            setInvoicePeriodicity(props.invoiceObj?.periodicity);
        }
    }, [props.invoiceObj]);

    useEffect(() => {
        if(!isMount) {
            if(!props.openDrawer) {
                setBillableStatus(false);
                setInvoiceCost(0);
                setInvoicePeriodicity(-1);
            }
        }

    }, [props.openDrawer])

    const onUpdatePriceConfigClick = () => {
        const organization_id = props.selectedOrganization.organization.id;
        const company_id = props.clientId;
        const id = props.invoiceObj.id;
        const billable = billableStatus;
        const cost = invoiceCost;

        if(billableStatus && (!cost || cost == '' || cost == 0)) {
            enqueueSnackbar('Invoice cost cannot be empty', {variant: 'error'});
            return;
        }

        const periodicity = invoicePeriodicity;
        if(billableStatus && props.invoiceObj?.template.schedule && (!periodicity || periodicity == -1 || periodicity == '-1')) {
            enqueueSnackbar('Please select invoice periodicity', {variant: 'error'});
            return;
        }

        setLoading(true);
        updateCostAndPeriodicityForInvoiceConfigApi(organization_id, company_id, id, cost, periodicity, billable)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response updateCostAndPeriodicityForInvoiceConfigApi: ", res);
                setLoading(false);
                enqueueSnackbar(res.message, {variant:'success'});
                trackGAEvent(props.selectedOrganization.organization.name, 'Mobile Client Invoice Settings', `${props.auth.user.firstname} ${props.auth.user.lastname}`);

                const updateInvoiceConfigArr = props.invoiceConfigList.map((invoice, index) => {
                    if(invoice.id === id) {
                        invoice = res.invoice_config;
                    }
                    return invoice;
                });
                
                props.setInvoiceConfigList(updateInvoiceConfigArr);
                props.handleCloseDrawer();
            })
            .catch((e) => {
                consoleToLog("updateCostAndPeriodicityForInvoiceConfigApi res", e);
                setLoading(false);
                if (e.response.data && e.response.data.message) {
                enqueueSnackbar(e.response.data.message, {
                    variant:'error'
                });
                }
            }); 
    }

    const onInvoicePeriodicityChanged = (e) => {
        setInvoicePeriodicity(e.target.value);
    }

    return (
        <MobileRightToLeftSideDrawer openDrawer={props.openDrawer}
            drawerClose={props.handleCloseDrawer}
            title={props.invoiceObj?.service_name}>

            <div className="modal__scheduler" style={{overflow:'auto'}}>
                <Grid item sm={12}>
                    <FormControlLabel
                        classes={{
                            root: classes.MuiFormControlLabelroot
                        }}
                        control={
                        <Checkbox
                            name="checkedB"
                            color="primary"
                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                            checked={billableStatus}
                            onChange={(event) => setBillableStatus(event.currentTarget.checked)}
                            //onClick={(e) => e.stopPropagation()}
                        />
                        }
                        label="Billable"
                    />
                </Grid>

                {billableStatus &&
                    <>
                    <Grid item sm={12} style={{marginTop:'8px'}}>
                        <Typography variant='subtitle1' className={classes.inputLabel}>
                            Cost    
                        </Typography>
                        <TextField
                            fullWidth
                            margin="dense"
                            variant="outlined"
                            value={invoiceCost === 0 ? '' : invoiceCost}
                            onChange={(e) => setInvoiceCost(e.target.value)}
                            placeholder='Cost'
                            InputProps={{
                            endAdornment: <InputAdornment position="end">
                                            {`${getInvoicePeriodicityPlaceholder(invoicePeriodicity)}`}
                                        </InputAdornment>,
                            }}
                            disabled={!billableStatus}
                            InputLabelProps={{style: {fontSize: 13, marginTop:'3px'}}}
                            style={{paddingRight:"10px"}}
                        />
                    </Grid>

                    {props.invoiceObj?.template.schedule &&
                    <Grid item sm={12} style={{marginTop:'8px'}}>
                        <Typography variant='subtitle1' className={classes.inputLabel}>
                            Periodicity    
                        </Typography>
                        <TextField
                            style={{ width: "100%"}} 
                            id="invoicerangeSelector"
                            margin="dense"
                            select
                            placeholder="Invoice Periodicity"
                            variant="outlined"
                            onChange={(e) => onInvoicePeriodicityChanged(e)}
                            InputLabelProps={{style: {fontSize: 14}}}
                            value={invoicePeriodicity ? invoicePeriodicity : -1}
                            classes={{
                                root: classes.MuiTextFieldroot
                            }}
                            disabled={!billableStatus}
                        >
                            <MenuItem value={-1}>Invoice Periodicity</MenuItem>
                            {   props.invoicePeriodRangeArr && props.invoicePeriodRangeArr.length > 0 
                                && props.invoicePeriodRangeArr.map((op, index) => {
                                    return <MenuItem key={op} 
                                                value={op.value}>
                                                {op.display_name}
                                        </MenuItem>
                                })
                            }
                        </TextField>
                    </Grid>}
                    </>
                }
            </div>


            <div className={classes.modalFooter}>
                <Grid item container alignItems='center' spacing={1}>
                    <Grid item xs={6}>
                        <Button variant="contained" 
                            fullWidth
                            color="primary"
                            onClick={onUpdatePriceConfigClick}>
                            {loading && <CircularProgress size={24} className={classes.circularProgress}/>}
                                Update
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button variant="outlined" 
                            fullWidth
                            onClick={props.handleCloseDrawer}>
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </MobileRightToLeftSideDrawer>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    accountList: state.organizationInfo.accountList
});

export default connect(mapStateToProps)(MobileUpdateInvoiceConfigDrawer);