import React, { useState, useEffect,createRef} from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, IconButton, ClickAwayListener} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { MentionsInput, Mention } from "react-mentions";
import { connect } from "react-redux";
import { useIsMount } from "../useIsMount";
import {trackGAEvent } from "../../util/AppUtil";
import moment from "moment";
import Fab from "@material-ui/core/Fab";
import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
import { Divider } from "@material-ui/core";
import { useSnackbar } from "notistack";
import {
  addChecklistNoteApi,
  deleteChecklistNoteApi,
  editChecklistNoteApi,
} from "../../services/ChecklistService";
import CircularProgress from "@material-ui/core/CircularProgress";
import { consoleToLog, getStatusColor, getStatusNameByValue, restructureNotes } from "../../util/AppUtil";
import SendIcon from '@material-ui/icons/Send';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {checklistNoteReadApi} from '../../services/authService';

const useStyles = makeStyles((theme) => ({
  menuListItems: {
    paddingRight: "26px",
  },
  commentSection: {
    padding: "5px 5px",
    fontSize: "12px",
    marginLeft: "10px",
    marginTop: "10px",
    marginBottom: "10px",
    boxShadow: "0px 0px 6px 0px #140d0d26",
    minWidth: "50rem",
    maxWidth: "57rem",
    width: "auto",
  },
  checklistDetailHeader: {
    fontWeight: 500,
    fontSize: "20px",
  },
  checklistDetailStatus: {
    borderRadius: "5px",
    padding: "2px 10px 2px 10px",
    color: "#656565",
    border: `1px solid #656565`,
    maxWidth: "12rem",
    width: "auto",
  },
  checklistDetailStatusIcon: {
    marginLeft: "2rem",
  },
  checklistDetailContent: {
    fontSize: "15px",
    padding: "18px 0px",
    color: "black",
    lineHeight: "25px",
  },
  checklistDetailBtn1: {
    width:"100px",
    borderRadius: "5px",
    padding: "2px 10px 2px 10px",
    color: "#656565",
    border: `1px solid #656565`,
    margin: "10px 10px 10px 0px",
  },
  checklistDetailBtn2: {
    width:"100px",
    borderRadius: "5px",
    padding: "2px 10px 2px 10px",
    color: "#dd584d",
    border: `1px solid #dd584d`,
  },
  checklistDetailAct: {
    fontWeight: "500",
    fontSize: "20px",
    marginTop: "10px",
    // marginBottom: "10px",
  },
  commentIconContainer: {
    backgroundColor: "#3d92ff",
    minHeight: "30px",
    height: "30px",
    width: "30px",
    boxShadow: "none",
  },
  commentIcon: {
    height: "15px",
    width: "15px",
    color: "white",
    transform: "scaleX(-1)",
    fontSize: "1rem",
  },
  commentedBy: {
    fontSize: "14px",
    marginLeft: "10px",
    color: "black",
  },
  commentedOn: {
    fontSize: "13.5px",
    color: "#5e6265",
    margin: "0px 5px",
  },
  commentTime: {
    fontSize: "14px",
    marginLeft: "10px",
    color: "#000",
  },
  notesListContainer: {
    position:"relative",
    height: 'calc(100vh - 230px)',
    overflow: 'auto',
    background: '#f2f1f1',
    [theme.breakpoints.down(1450)]: {
      height: 'calc(100vh - 230px)',
    },
  },
  messageContainer: {
    padding: '6px 16px 16px 16px',
  },
  listContainer: {
    width:'max-content',
    '& .dropdownClass': {
      visiblity:'hidden',
      opacity:0,
      border:'1px solid #ccc',
      background:'#fff',
      minWidth:'14px',
      height:'4px',
      padding:"10px 6px",
      borderRadius:'16px',
    },
    '&:hover .dropdownClass': {
      visiblity:'visible',
      opacity:1,
      transition: "transform 0.4s ease"
    }
    
  },
  sendButtonStyle: {
      color: "#008a7c",
  },
  templateNameStyle: {
      color:'#0071FF'
  },
  actionIconStyle: {
      width:'21px',
      filter: 'invert(100%) sepia(90%) saturate(0%) hue-rotate(296deg) brightness(94%) contrast(101%)',
      position:'relative',
      top:'1px'
  },
  textFieldStyle: {
    position:'relative',
    display:"flex",
    height: "14vh",
    left:0,
    right:0,
    bottom:0,
    borderTop:'1px solid #ccc',
    background:"#fff",
    overflow:'visible',
    boxSizing:'border-box',
    [theme.breakpoints.down(1400)]: {
      height: "16vh",
    },
    [theme.breakpoints.down(1320)]: {
      height: "16vh",
    },
  },
  menuIconStyles: {
    marginRight:'12px',
    fontSize:'15px',
  },
  customColumn1: {
    flexBasis:'100%', 
    maxWidth:'100%',
    [theme.breakpoints.down(1450)]: {
      flexBasis:'91%', 
      maxWidth:'91%',
    },
  }
}));

const ChecklistNotes = (props) => {
  const isMount = useIsMount();
  const classes = useStyles();
  const [notes,setNotes] = useState(props.checkListItem.notes);
  const [mentions, setMentions] = useState();
  const [selectedNoteForEditing, setSelectedNoteForEditing] = useState(undefined);
  const [text, setText] = useState("");
  const [notesLoading,setNotesLoading] = useState(false);
  const myDivToFocus = createRef();  //for comment scrolling
  const [isActive, setIsActive] = useState(false);
  const [itemId, setItemId] = useState(undefined);

  const loggedInUser = props.auth.user.id;
  let groupedNotesList =  restructureNotes(notes);

  let usersData = props.members.map((member) => ({
    id: member.user.id,
    display: `${member.user.firstname} ${member.user.lastname}`,
  }));
  const { enqueueSnackbar } = useSnackbar();

  //for edit delete user
  const currentAuthUser = props.auth.user.id;

  // useEffect(() => {
  //   if(!isMount){
  //     setNotes(props.checkListItem.notes);
  //   }
  // }, [props.checkListItem]);

  useEffect(() => {
      if(props.tabValue === 3) {
        if(props.checkListItem.notes.length > 0 && props.checkListItem.notes.length > 0 && !props.checkListItem?.note_read_users?.includes(loggedInUser)) {
          callChecklistNoteReadApi();
        }
      }
  }, [props.tabValue])

  const onScrollToCommentSection = () => {
    if(myDivToFocus.current){
      myDivToFocus.current.scrollIntoView({
          inline: "nearest",
          behavior: "smooth",
          block: "start",
      });
    }
  } 

  const organizationId = props.selectedOrganization.organization.id;
  const checklistId = props.checkListItem.id;

  const callChecklistNoteReadApi = () => {

    checklistNoteReadApi(organizationId, checklistId)
        .then((response) => {
            const res = response.data;
            consoleToLog('Response checklistNoteReadApi', res);

        })
        .catch((e) => {
            consoleToLog('Error checklistNoteReadApi', e);
        })
  }

  const handleDeleteNote = (newNotesArray) =>{
    props.onNoteDeleted(newNotesArray);
  }


  const onNoteDelete = (noteObj) =>{
    const organization_id = organizationId;
    const checklist_id = checklistId;
    const comment_id = noteObj.id;

    if (window.confirm('Are you sure to delete the note?')) {

      deleteChecklistNoteApi(comment_id, checklist_id, organization_id)
      .then((response) => {
          const res = response.data;
          consoleToLog("Response deleteChecklistNoteApi: ", res);
          enqueueSnackbar(res.message, {
            variant: "success",
          });
          const newNotesArray = notes.filter((note) => note.id !== res.comment.id);
          setNotes(newNotesArray);
          groupedNotesList.map((groupItem) => {
            groupItem.items.filter((item) => item.id !== res.comment.id)
          });
          // to parent
          handleDeleteNote(newNotesArray);
          trackGAEvent(props.selectedOrganization.organization.name, 'Checklist Delete note', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
      })
      .catch((e) => {
        consoleToLog("deleteChecklistNoteApi error", e);
      });
    }
  }

  const getReadableNotesText = (note) => {
    var readableNoteText = note.text;
    let  numberPattern = /\d+/g;
    let num = readableNoteText.match(numberPattern);
    num?.join();
    let user_id = Number(num)
    
    readableNoteText = readableNoteText.replace(/\n\r?/g, "<br />");//add <br>
    readableNoteText = readableNoteText.split('@[').join(`<b>`);
    readableNoteText = readableNoteText.split(']').join("</b>");
    readableNoteText = readableNoteText.split(/\(.+?\)/).join('');
    return readableNoteText;
    
  }

  const calculateMessageTimeReceived = (time) => {
    let lastExecutionTime = moment(time).utc().format(); //your last execution time
    let nextDay = moment(lastExecutionTime).add(12,'hours').utc().format(); // calculate 24 hours next to last access
    let currentTime = moment().utc().format(); //current time
    return moment(currentTime).isSameOrAfter(nextDay);
  }

  const downArrowClick = (event, item) => {
      setIsActive(true);
      setItemId(item.id);
  }

  const onClickAway = () => {
    setIsActive(false);
    setItemId(undefined);
  }

  const showPopover = (item, userId) => {
    return (
        (itemId === item.id) && isActive &&
        <ClickAwayListener onClickAway={onClickAway}>
          <Grid item className={`notes_menu ${isActive ? loggedInUser === userId ? "active" : "active1" : "inactive"}`}>
              <ul>

                  <li className={classes.list} 
                  onClick={(e) => onNoteEdit(item)}>
                      <span>
                        <i className={`flaticon-edit ${classes.menuIconStyles}`}></i>
                        Edit
                      </span>
                  </li>

                  <li className={classes.list} 
                  onClick={(e) => onNoteDelete(item)}>
                      <span>
                        <i className={`flaticon-bin ${classes.menuIconStyles}`}></i>
                        Delete
                      </span>
                  </li>

              </ul>
          </Grid>
        </ClickAwayListener>
      )
  }

  const returnTaskStatusClassName = (status) => {
    if(status === "awaiting_reply") return 'awaiting-reply__background';
    if(status === "pending") return 'pending__background';
    if(status === "in_process") return 'in-process__background';
    if(status === "pending_approval") return 'pending-approval__background';
    if(status === "completed") return 'completed__status__background';
}

  const NotesItem = () =>{
    return (
      <div className={classes.notesListContainer}>
        <Grid item md={12} >
              {groupedNotesList && groupedNotesList.length > 0 && groupedNotesList.map((note, index)=>{
                  return <Grid item md={12} key={note.group_id} className={classes.messageContainer}>
                                  <Grid item md={12} ref={myDivToFocus}>
                                      {note.items && note.items.length > 0 &&
                                      note.items.slice(0,1).map((item) => {
                                          const createdAt = item?.created_at;
                                          const messageReceivedToday = calculateMessageTimeReceived(createdAt);
                                          const userId = item?.comment_user?.id;
                                          const userName = item?.comment_user?.firstname + ' ' +  item?.comment_user?.lastname;
                                          return  <Grid item container alignItems="center" 
                                                    style={{marginLeft:loggedInUser === userId && 'auto', marginRight: loggedInUser === userId && 0}}
                                                    className={classes.listContainer} key={item.id}>
                                                    <div 
                                                        style={{order: loggedInUser === userId ? 2 : 1}}
                                                        className={loggedInUser === userId ? 'loggedInUserMessage' : 'message'}>
                                                        {loggedInUser !== userId &&
                                                        <Typography variant='body2' style={{fontWeight:'600'}}>
                                                            {userName}
                                                        </Typography>}
                                                        <Grid item md={12} style={{marginTop:'2px'}}>
                                                            {
                                                                item.comment_type === "checklist_task" &&
                                                                <div className={returnTaskStatusClassName(item?.checklist_task_data?.default_status)}
                                                                    style={{
                                                                        borderLeft:`5px solid ${getStatusColor(item?.checklist_task_data?.default_status)}`, 
                                                                        margin:'4px 0px 8px 0px',
                                                                        fontSize: '13px',
                                                                        padding: '8px',
                                                                        borderRadius:'5px',
                                                                }}>
                                                                    <span style={{marginLeft:'4px'}}>
                                                                        {item.checklist_task_data.title}
                                                                    </span>
                                                                </div>
                                                            }
                                                            <Typography variant='body2' dangerouslySetInnerHTML={{ __html : getReadableNotesText(item, true)}}>
                                                            </Typography>
                                                            <span className='time'>
                                                                {messageReceivedToday ? moment(createdAt).format('MMM D, h:mm a') : moment(createdAt).format('h:mm a')}    
                                                            </span>
                                                        </Grid>
                                                    </div>
                                                    {loggedInUser === userId && item.comment_type === "checklist" &&
                                                    <Grid item
                                                        style={{order: loggedInUser === userId ? 1 : 2, position:'relative'}}>
                                                        <IconButton 
                                                          onClick={(e) => downArrowClick(e, item)}
                                                          className='dropdownClass'
                                                            style={{marginRight: loggedInUser === userId && '8px', marginLeft: loggedInUser !== userId && '8px'}}>
                                                            <ExpandMoreIcon />
                                                        </IconButton>
                                                        {showPopover(item, userId)}
                                                    </Grid>}
                                                  </Grid>
                                      })
                                      }
                              </Grid>
                              {
                                  note.items && note.items.length > 0 && 
                                  note.items.slice(1).map((item) => {
                                      const createdAt = item?.created_at;
                                      const messageReceivedToday = calculateMessageTimeReceived(createdAt);
                                      const userId = item?.comment_user?.id;
                                      return <Grid item container key={item.id} alignItems="center" 
                                                style={{marginLeft:loggedInUser === userId && 'auto', marginRight: loggedInUser === userId && 0}}
                                                className={classes.listContainer}>
                                              <div className='messageOne' 
                                                  style={{background: loggedInUser === userId ? '#e4f8d0' : '#fff',
                                                          marginLeft: loggedInUser === userId && 'auto',
                                                          marginRight: loggedInUser !== userId && 'auto',
                                                          order: loggedInUser === userId ? 2 : 1}}>
                                                  {
                                                      item.comment_type === "checklist_task" &&
                                                      <div className={returnTaskStatusClassName(item?.checklist_task_data?.default_status)}
                                                          style={{
                                                              borderLeft: loggedInUser !== item.note_user?.id && `5px solid ${getStatusColor(item?.checklist_task_data?.default_status)}`, 
                                                              margin:'4px 0px 8px 0px',
                                                              fontSize: '13px',
                                                              padding: '8px',
                                                              borderRadius:'5px',
                                                              borderRight: loggedInUser === item.note_user?.id && `5px solid ${getStatusColor(item?.checklist_task_data?.default_status)}`
                                                          
                                                      }}>
                                                          <span style={{marginLeft:'4px'}}>
                                                              {item.checklist_task_data.title}
                                                          </span>
                                                      </div>
                                                  }
                                                  <Typography variant='body2' dangerouslySetInnerHTML={{ __html : getReadableNotesText(item, true)}}>
                                                  </Typography>
                                                  <span class="time">
                                                      {messageReceivedToday ? moment(createdAt).format('MMM D, h:mm a') : moment(createdAt).format('h:mm a')}    
                                                  </span>
                                              </div>
                                              {loggedInUser === userId && item.comment_type === "checklist" &&
                                              <Grid item style={{order: loggedInUser === userId ? 1 : 2, position:'relative'}}>
                                                <IconButton 
                                                  onClick={(e) => downArrowClick(e, item)}
                                                  className='dropdownClass'
                                                  style={{marginRight: loggedInUser === userId && '8px', marginLeft: loggedInUser !== userId && '8px'}}>
                                                    <ExpandMoreIcon />
                                                </IconButton>
                                                {showPopover(item, userId)}
                                              </Grid>}
                                              </Grid>
                                  })
                              }
                          </Grid>
              })}
          </Grid>
      </div>
    );
  }

  const clearCurrentCommentData = () => {
    setText("");
    setMentions([]);
    setSelectedNoteForEditing(undefined);
  };

  const onNoteEdit = (noteObj) =>{
    setSelectedNoteForEditing(noteObj);
    setMentions([]);
    setText(noteObj.text);  
    onScrollToCommentSection();
  }

  const addNewNote = (text) =>{
    const checklist_id = checklistId;
    const organization_id = organizationId;
    const mention_users = mentions.map(mentionObj => mentionObj.id);
    
    setNotesLoading(true);
    addChecklistNoteApi(checklist_id, mention_users, text, organization_id)
    .then((response) => {
        const res = response.data;
        consoleToLog ("Response addChecklistNoteApi: ", res);
        var newNote = res.comment
        var newNotesArray = [...notes,newNote];
        groupedNotesList = restructureNotes(newNotesArray);
        setNotes(newNotesArray);
        setNotesLoading(false);
        enqueueSnackbar(res.message, {
          variant: "success",
        });
        setText("");
        // to parent
        props.onNoteAdded(newNotesArray);
        trackGAEvent(props.selectedOrganization.organization.name, 'Checklist Add note', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
    })
    .catch((e) => {
      consoleToLog("addChecklistNoteApi error", e);
    });
  }

  const editNote = (text) =>{
    const organization_id = organizationId;
    const checklist_id = checklistId;
    const mention_users = mentions.map(mentionObj => mentionObj.id);
    const comment_id = selectedNoteForEditing.id;

    editChecklistNoteApi(checklist_id, mention_users, text, comment_id, organization_id)
    .then((response) => {
        const res = response.data;
        consoleToLog("Response editChecklistNoteApi: ", res);
        enqueueSnackbar(res.message, {
          variant: "success",
        });
        const newNotesArray = notes.map((note) => {
            return (note.id === res.comment.id) ? res.comment : note;
        })
        setNotes(newNotesArray);

        groupedNotesList.map((groupItem) => {
          groupItem.items.map((item) => {
            if(item.id === res.comment.id) {
              item = res.comment
            }
            return item;
          })
          return groupItem
        });

        setText("");
        setSelectedNoteForEditing(undefined);
        trackGAEvent(props.selectedOrganization.organization.name, 'Checklist Edit note', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
    })
    .catch((e) => {
      consoleToLog("editChecklistNoteApi error", e);
    });
  }

  const onSaveClick = (e) => {
    e.preventDefault();

    const textValue = text.trim();
    if (!text) {
      consoleToLog('Note can not be empty!');
        return;
    };

    //add or edit
    if (selectedNoteForEditing) {//edit note
        //if note is not changed do nothing
        if (selectedNoteForEditing.text === text) {
          enqueueSnackbar("Note is not changed!", {
            variant: "error",
          });
            return;
        } else {
          editNote(text);
        }
    } else {//add note
        addNewNote(text);
    }
}

  const handleKeyDown = (e) => {
    if(e.ctrlKey && e.keyCode === 13) { 
      onSaveClick(e);
    }
  }

  return (
    <div ref={myDivToFocus} style={{position:'relative', boxSizing:'border-box'}}>
        {NotesItem()}
        <Grid item md={12} className={classes.textFieldStyle}>
            <Grid item md={11} className={classes.customColumn1}>
                <div className="mentionWrapper1">
                    <MentionsInput
                        markup="@[__display__](__id__)"
                        className="mentions1"
                        placeholder="Use @ to notify a team member and ctrl + enter↵ to send message"
                        value={text}
                        onChange={(e, newValue, newPlainTextValue, mentions) => {
                            setText(e.target.value);
                            setMentions(mentions);
                        }}
                        onKeyDown={handleKeyDown}
                    >
                        <Mention className="mentions1__mention" data={usersData}/>
                    </MentionsInput>
                </div>
            </Grid>
            <Grid item md={1}>
              <div style={{position:"relative", top:'6px'}}>
                <Button
                  variant="outlined"
                  className={classes.checklistDetailBtn1}
                  onClick={onSaveClick}
                  name="send"
                >
                  Send 
                  {notesLoading && (
                    <CircularProgress
                      style={{ color: "black",height:"15px",width:"15px",marginLeft:"10px"}}
                      size={10}
                    />
                  )}
                </Button>
                <Button
                  variant="outlined"
                  className={classes.checklistDetailBtn2}
                  onClick={clearCurrentCommentData}
                >
                  Discard
                </Button>
              </div>
            </Grid>
        </Grid>
      </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  selectedOrganization: state.organizationInfo.selectedOrganization,
  members: state.organizationInfo.members,
});

export default connect(mapStateToProps, null)(ChecklistNotes);
