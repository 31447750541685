import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import { Grid } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import Slide from "@material-ui/core/Slide";
import SwipeableViews from "react-swipeable-views";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Badge from "@material-ui/core/Badge";
import TasksContent from "../../components/checklist_details/TasksContent";
import ChecklistAttachments from "../../components/checklist_details/ChecklistAttachments";
import ChecklistNotes from "../../components/checklist_details/ChecklistNotes";
import ActivityPage from "../../components/checklist_details/ActivityPage";
import {
  getTaskList,
  modifyLabelsFromChecklistApi,
  updateCheckListDueDateApi,
  archiveChecklistApi,
  completeChecklistApi,
  disableEmailFollowupApi
} from "../../services/authService";
import { connect } from "react-redux";
import { consoleToLog, trackGAEvent } from "../../util/AppUtil";
import moment from "moment";
import {
  getStatusColor,
  getLabelsFromChecklistItem,
  checkAllAppoverApprovedChecklist,
  shouldDisable,
  AccessLevel,
} from "../../util/AppUtil";
import CircularProgress from "@material-ui/core/CircularProgress";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import Confetti from "react-confetti";
import { useIsMount } from "../../components/useIsMount";
import SelectFromLabelList from "../../components/checklist/SelectFromLabelList";
import Popover from "@material-ui/core/Popover";
import Calender from "../../components/Calender";
import { history } from "../../routers/AppRouter";
import { useSnackbar } from "notistack";
import {useLocation} from 'react-router-dom';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import {setChecklistArray} from '../../actions/selectedOrganization';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOff';
import CheckIcon from '@material-ui/icons/Check';
import {setEmailObj, updateTemplateArr} from '../../util/AppUtil';
import {setCurrentEmailState, setEmailFollowupObject, setFromChecklistDetailsPage} from '../../actions/selectedOrganization';
import EmailConversations from '../../components/checklist_details/EmailConversations';
import {setEmailSentFrom} from '../../actions/selectedOrganization';
import ChecklistClientDetailsModal from "../../components/modals/ChecklistClientDetailsModal";
import { setOrgTemplates } from "../../actions/selectedOrganization";
import { setSelectedTemplate } from "../../actions/selectedOrganization";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ClearIcon from '@material-ui/icons/Clear';
import Rules from '../../components/Rules';
import IconButton from '@material-ui/core/IconButton';
import Approvals from "../../components/checklist_details/Approvals";
import SendForReviewDialog from "../../components/SendForReviewDialog";
import TemplateApprovalRules from "../../components/TemplateApprovalRules";

const useStyles = makeStyles((theme) => ({
  backIcon: {
    color: "#202020",
    border: " 1px solid #202020",
    padding: "4px 0px",
    fontSize: "18px",
    minWidth: "40px",
    lineHeight: "18px",
    marginTop: "15px",
    marginBottom: "10px",
    marginLeft: "15px",
  },
  detailsIconBtns: {
    color: "black",
    fontSize: "15px",
    minWidth: "20px",
    padding: "5px 6px",
    marginTop: "15px",
  },
  detailsMain: {
    paddingTop: "10px",
    paddingBottom: "10px",
    backgroundColor: "#f2f6ff",
  },
  detailBadge: {
    backgroundColor: "#cccccc",
    fontSize: "12px",
    borderRadius: "3.5px",
    padding: "0px 6px",
    fontWeight: 500,
    color: "black",
    marginBottom: "3px",
    marginLeft: "5px",
  },
  tabsMain: {
    minWidth: "100px",
    color: "#5e6265",
  },
  itemList: {
    // backgroundColor:"#e5efff",
    backgroundColor: "#f9f9f9",
    marginBottom: "10px",
    borderRadius: "5px",
    // marginRight:"20px"
  },
  checklistYear: {
    // lineHeight: "18px",
    color: "#888888",
    fontSize: "14px",
    marginLeft: "10px",
    marginTop: "3px",
  },
  checklistDateBtn: {
    // marginTop: "-5px",
    marginTop: "-7px",
    marginLeft: "-6px",
    marginRight: "-6px",
  },
  checklistDate: {
    marginLeft: "4px",
    color: "#888888",
    fontSize: "12.6px",
    // marginTop: "0.6px",
    marginTop: "3px",
  },
  checklistStatus: {
    backgroundColor: "#72BB53",
    marginTop: "-15.7px",
    marginBottom: "10px",
    padding: "2px",
    fontWeight: "600",
    color: "white",
  },
  checkListStatusComponent: {
    borderRadius: "5px",
    padding: "2px 10px 2px 10px",
    color: "#dd584d",
    border: `1px solid #dd584d`,
  },
  tabsAppbar: {
    boxShadow: "none",
    backgroundColor: "white",
    borderBottom: "1px solid #c5b9b9",
  },
  checklistLabel: {
    marginLeft: "3px",
    marginRight: "3px",
  },
  topMessage:{
    color:"gray",
    fontSize: "13px",
    marginTop: "2px",
    marginBottom: "-22px",
  },
  moreOptionsContainer : {
  '& .more_actionButton' : {
    width:'90px',
    background:'white',
    border:'1px solid #ccc',
    borderRadius:'5px',
    fontSize:'13px',
    padding:'1px 1px',
    marginRight:'-1px'
  },
  '& .more_actionButton1' : {
    width:'120px',
    background:'white',
    border:'1px solid #ccc',
    borderRadius:'5px',
    fontSize:'13px',
    padding:'1px 0px',
  },
  '& .more_actionButton2' : {
    width:'140px',
    background:'white',
    border:'1px solid #ccc',
    borderRadius:'5px',
    fontSize:'13px',
    padding:'1px 0px',
    dispatch:'flex',
    alignItems:'center'
  },
  '& .action__icon' : {
    color: "black",
    fontSize: "15px",
    padding: '5px 5px 5px 5px',
    border:'1px solid #ccc',
    borderRadius:'5px',
    cursor:'pointer'
  },
  },
  followupTextStyle: {
    width:'auto',
    overflow:'hidden',
    whiteSpace:'nowrap',
    textOverflow:'ellipsis',
    cursor:'pointer'
  },
  list : {
    cursor:'pointer',
    '&:hover' : {
        background:'#d3d3d3'
    }
  },
  templateNameStyle: {
    color:'#0071FF',
    marginLeft:'8px',
    fontSize:'14px',
    marginTop: "3px"
  },
  addButton: {
    color: "white",
    backgroundColor: "#3d3d3d",
    marginLeft: "auto",
    height: "30px",
    marginTop: "9px",
    "&:hover": {
        //you want this to be the same as the backgroundColor above
        backgroundColor: "#3d3d3d"
    },
    marginRight: "8px"
  },
  buttonPlusIcon: {
    marginRight:'5px', 
    fontSize:'1.2rem', 
    alignItems:'center'
}, 
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CheckListDetailPage = (props) => {
  const classes = useStyles();
  const [value, setValue] = useState(props.tabValue);
  const [itemDetails, setItemDetails] = useState([]);
  const [confetti, setConfetti] = useState({ run: false, recycle: false });
  const [anchorEl, setAnchorEl] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const [checklistId, setChecklistId] = useState(undefined);
  const { enqueueSnackbar } = useSnackbar();
  const isMount = useIsMount();
  const [currentActivity,setCurrentActivity] = useState(undefined);
  const[disableFollowupLoading, setDisableFollowupLoading] = useState(false);

  const activeSchedulerCount = itemDetails?.active_scheduler_count;
  const totalSchedulerCount = itemDetails?.total_scheduler_count
  const [companyData, setCompanyData] = useState(props.currentchecklistItem ? props.currentchecklistItem : itemDetails);
  const [isActive1, setIsActive1] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [editChecklist, setEditChecklist] = useState(false);
  const [addTaskClicked, setAddTaskClicked] = useState(false);
  const [updateTaskFromParent, setUpdateTaskFromParent] = useState(false);
  
  const [openSendForReviewDialog, setOpenSendForReviewDialog] = useState(false);
  const [taskObj, setTaskObj] = useState(undefined);
  const [approvalObj, setApprovalObj] = useState(undefined);
  const [updateApporvalList, setUpdateApprovalList] = useState(undefined);
  const [callGetApiFromReviewModal, setCallGetApiFromReviewModal] = useState(false);
  const [callGetApi, setCallGetApi] = useState(false);

  const { access_level } = props.selectedOrganization || {};
  const accessLevelManager = shouldDisable(
    AccessLevel.getUserAccessLevelValue(access_level),
    AccessLevel.MANAGER
  );
  const accessLevelMember = shouldDisable(
    AccessLevel.getUserAccessLevelValue(access_level),
    AccessLevel.MEMBER
  );
  const accessLevelGuest =
    AccessLevel.getUserAccessLevelValue(access_level) === AccessLevel.GUEST
      ? true
      : false;

    const location  = useLocation();
    const activityObj = location.activityObj ? location.activityObj : location.notificationObj;

    const checkRef = useRef();

    const fullName = props.auth.user.firstname + ' ' + props.auth.user.lastname;

  useEffect(() => {
    document.title = `Work Details - ${fullName}`;
  }, []);

  useEffect(()=>{
    setCompanyData(itemDetails);
  },[itemDetails])

  useEffect(() => {
    if(activityObj) {
      if(activityObj.activity_type === 'upload_attachment') {
        setValue(1);
      }

      if(activityObj.activity_type === 'scheduler_enabled' || 
      activityObj.activity_type === 'scheduler_disabled' ||
      activityObj.activity_type === "scheduler_disabled_by_rule" || 
      activityObj.activity_type === "scheduler_enabled_by_rule") {
        setValue(3);
      }

      if(activityObj.activity_type === 'notes_on_checklist' || activityObj.action_type === 'notes') {
        setValue(4);
      }
    }

  }, [activityObj]);


  useEffect(() => {
    // to handle activity click from activity tab
    if(currentActivity) {
      switch(currentActivity.activity_type) {
        case 'upload_attachment':
          setValue(1);
          break;
        case 'notes_on_checklist':
          setValue(4);
          break;
        default:
          setValue(0);
      }
    }
  }, [currentActivity]);

  const checklistItemNotesArrayRev = (checklistObj, updateTaskListfromRule) => {
    let obj = {
      ...checklistObj,
      notes:  checklistObj.notes && checklistObj.notes.length > 0 ? checklistObj.notes.reverse() : []
      }  
      if(updateTaskListfromRule) setUpdateTaskFromParent(true);
      setItemDetails(obj);
  }

  useEffect(() => {
    var checklistItemSlug = props.slug;

    getTaskList(checklistItemSlug)
      .then((response) => {
        const res = response.data;
        consoleToLog("getTaskList res", res);
        
        checklistItemNotesArrayRev(res);
        //setItemDetails(res);
        const filteredTemplate = props.templates.filter((template) =>  template.template_id === res.template_data?.template_id);
        console.log('template', filteredTemplate);
        props.setSelectedTemplate(filteredTemplate[0]);

        // setGetWigets(true);
      })
      .catch((error) => {
        consoleToLog("getTaskList error", error);
      });

  }, []);

  useEffect(() => {
    if(!isMount) {
      if(props.emailFollowupObj && !props.overlay.isSendEmail) {
        const filteredArray = props.emailFollowupObj.checklists && props.emailFollowupObj.checklists.length > 0 && 
          props.emailFollowupObj.checklists.filter((checklist) => checklist.id === itemDetails.id);
        setItemDetails({ ...itemDetails, 
          followups: [...itemDetails.followups, props.emailFollowupObj.email_followup],
          checklist_labels_list: filteredArray[0].checklist_labels_list ? filteredArray[0].checklist_labels_list : [],
          status: filteredArray[0]?.status,
          followups_count: filteredArray[0]?.followups_count
        });
        props.setEmailFollowupObject(undefined);
        props.setFromChecklistDetailsPage(false);
      }
    }
  }, [props.emailFollowupObj, props.overlay.isSendEmail]);

  useEffect(() => {
    if(!isMount) {
        if(Object.keys(itemDetails).length > 0) {
          document.addEventListener("keydown", handleKeydown, false);
        }

        return () => {
          document.removeEventListener("keydown", handleKeydown);
        };
    }
  }, [itemDetails])
        
var handleKeydown = (e) => {
    if(e.ctrlKey && e.key === 'e') {
        e.preventDefault();
        onSendEmailClick();
    }
}

  const a11yProps = (index) => {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  };

  const onChecklistActivityClick = (currentActivity) =>{
    // to handle activity click from activity tab
    setCurrentActivity(currentActivity);
  }

  const onDeleteClick = (e, checklist_id) => {
    isActive1 && setIsActive1(false);
    setIsActive(!isActive);
    setChecklistId(checklist_id);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  // To change the checklit status on all tasks completed
  const onCheckListStatusCompleted = (status, toggle) => {
    // getWidgets(false);
    setConfetti({ run: true, confetti: true });
    //to parent
    props.onCheckListStatusCompleted && props.onCheckListStatusCompleted(status, toggle);

    setTimeout(() => {
      setConfetti({ run: false, confetti: false });
    }, 3000);
  };
 

  const onTaskStatusChanged = (activityObj ) => {
    //setItemDetails(activityObj.checklist);
    checklistItemNotesArrayRev(activityObj.checklist);
    // to parent
    props.onTaskStatusChanged && props.onTaskStatusChanged(itemDetails.id,activityObj.checklist)
  };

  const onTaskCommentUpdated = (checklistObj ) => {
    checklistItemNotesArrayRev(checklistObj);
    // to parent
  };

  // For Add labels
  const setAssignedLabelList = (assignedLabelList, checklist_id) => {
    const labels = assignedLabelList.map((label) => label.id).join();

    //Modify Labels From Checklist Api
    modifyLabelsFromChecklistApi(checklist_id, labels)
      .then((response) => {
        const res = response.data;
        consoleToLog(" ***Response modifyLabelsFromChecklistApi: ", res);
        // setItemDetails({ ...itemDetails, checklist_labels_list: res.labels });
        // var newLabelsArray = res.labels;
        //setItemDetails(res.checklist);
        checklistItemNotesArrayRev(res.checklist);
        // to parent
        props.onLabelModified && props.onLabelModified(res.checklist, itemDetails.id);
        trackGAEvent(props.selectedOrganization.organization.name, 'Checklist Add Label from Detail', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
      })
      .catch((e) => {
        consoleToLog(" modifyLabelsFromChecklistApi error", e.response);
        if (e.response.data && e.response.data.message) {
          enqueueSnackbar(e.response.data.message, {variant: "error"});
          return false;
        }
      });
  };

  // for more options
  const onArchiveChecklistClick = (checklist_id) => {
    setIsActive(false);
    archiveChecklistApi(checklist_id)
      .then((response) => {
        const res = response.data;
        consoleToLog("Response archiveChecklistApi: ", res);

        /* ReactGA.event({
          category: "Checklist",
          action: "Checklist Archieved",
          label: `${props.auth.user.firstname} ${props.auth.user.lastname}`,
        }); */

        
        history.push("/dashboard");
        props.modalStatus && props.modalStatus();
        props.onChecklistArchived && props.onChecklistArchived(checklist_id);
        trackGAEvent(props.selectedOrganization.organization.name, 'Checklist archived from Detail', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
      })
      .catch((e) => {
        consoleToLog(" archiveChecklistApi error", e.response);
        if (e.response.data && e.response.data.message) {
          enqueueSnackbar(e.response.data.message, {variant: "error"});
          return false;
        }
      });
  };

  const TabsWithBadage = (props) => {
    return (
      <span>
        {props.labelname}
        <Badge color="primary" className={classes.detailBadge}>
          {props.count}
        </Badge>
      </span>
    );
  };

  const clickedDate = (str) => {
    const checklist_id = itemDetails.id;
    const checklistDueDate = !str ? str : moment(str).format("YYYY-MM-DD");

    updateCheckListDueDateApi(checklist_id, checklistDueDate)
      .then((response) => {
        const res = response.data;
        consoleToLog("Response updateCheckListDueDateApi: ", res);
        // const newCheckListArray = (itemDetails.due_date = checklistDueDate);
        setItemDetails({ ...itemDetails, due_date: checklistDueDate });

        // to parent
        props.onDueDateModified && props.onDueDateModified({ ...itemDetails, due_date: checklistDueDate });
        trackGAEvent(props.selectedOrganization.organization.name, 'Checklist Set DueDate From Detail', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
        handleClose();
      })
      .catch((e) => {
        consoleToLog("updateCheckListDueDateApi error", e.response);
        if (e.response.data && e.response.data.message) {
          enqueueSnackbar(e.response.data.message, {variant: "error"});
          return false;
        }
      });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickNew = (event) => {
    setAnchorEl(event.currentTarget);
  };

  //open popover
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleDueDatePopOver = () => {
    return (<Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal:'right'
                }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
                }}
              style={{ left: "45%", top: "0%" }}
            >
              <Calender clickedDate={clickedDate} />
            </Popover>
    );
  };

  const completeChecklistApiFromHeader = () => {
    if (window.confirm("Checklist will be completed as all tasks are completed. You cannot undo the completed checklist. Would you like to continue to complete the checklist?")) {
      const checklist_id = itemDetails.id;
      const start_date = props.startDateFilter;
      const end_date = props.endDateFilter;
      const user_ids = props.selectedMembersFilter && props.selectedMembersFilter.length > 0 ? props.selectedMembersFilter.map((member) => member.user.id).join() : '';
      const company_ids = props.companiesFilter && props.companiesFilter.length > 0 ? props.companiesFilter.map((company) => company.id).join() : '';

      completeChecklistApi(checklist_id, start_date, end_date, user_ids, company_ids)
        .then((response) => {
          const res = response.data;
          enqueueSnackbar(res.message, {
            variant: "success",
          });

          /* ReactGA.event({
            category: "Checklist",
            action: "Checklist marked as complete from task details",
            label: `${props.auth.user.firstname} ${props.auth.user.lastname}`,
          }); */
          setConfetti({ run: true, confetti: true });
          setItemDetails({ ...itemDetails, status: "Completed" });

          //to parent
          props.onCheckListStatusCompleted && props.onCheckListStatusCompleted("Completed", true);
          trackGAEvent(props.selectedOrganization.organization.name, 'Checklist Complete from Detail', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
          setTimeout(() => {
            setConfetti({ run: false, confetti: false });
          }, 3000);
          setIsActive(false);

          let templatesArr = updateTemplateArr(props.templates, res.template, props.selectedTemplate);
          props.setOrgTemplates(templatesArr);
        })
        .catch((e) => {
          consoleToLog("completeChecklistApi error");
          enqueueSnackbar(e.message, {
            variant: "error",
          });
        });
    }
  };

  // if attachment is added from widegts(TaskContent)
  /* const onWidgetAttachmentAdded = (attachmentObj) => {
    consoleToLog("attachmentObj",attachmentObj);
    // push
    var itemDetailsAttachments = itemDetails.attachments;
    // adding widgetAttachment to attachemnts
    var updatedArray = itemDetailsAttachments.push(attachmentObj);

    setItemDetails({ ...itemDetails, attachments: itemDetailsAttachments });
    // to parent 
    props.onWidgetAttachmentAdded(itemDetailsAttachments);
  } */

  const onAttachmentDelete = (updatedDeleteAttachmentArray) =>{
    setItemDetails({ ...itemDetails, attachments: updatedDeleteAttachmentArray });
    // to parent
    props.onAttachmentDelete && props.onAttachmentDelete(updatedDeleteAttachmentArray);
  }
 
  const onAttachmentAdded = (updatedAttachmentsArray, checklistObj) => {
    setItemDetails({ ...itemDetails, attachments: updatedAttachmentsArray, status: checklistObj.status });
     // to parent
    props.onAttachmentAdded && props.onAttachmentAdded(checklistObj);
  }

  const onAttachmentUpdated = (updatedtaskArray) => {
    setItemDetails({ ...itemDetails, /*attachments: updatedAttachmentsArray,*/ checklist_task: updatedtaskArray});
  }

  const onNoteAdded = (newarray) =>{
    setItemDetails({ ...itemDetails, notes: newarray });
    // to parent
    props.onNoteAdded && props.onNoteAdded(newarray,itemDetails.id);

  }

  const onNoteDeleted = (newarray) =>{
    setItemDetails({ ...itemDetails, notes: newarray });
     // to parent
    props.onNoteDeleted && props.onNoteDeleted(newarray,itemDetails.id);
  }

  const checklistTaskUpdate = (task_array) => {
    setItemDetails({ ...itemDetails, checklist_task: task_array});
  }

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
    setIsActive(null);
    setChecklistId(undefined);
  }

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  }

  const getEmailFollowups = () => {
    isActive && setIsActive(false);
    setIsActive1(!isActive1);
    setChecklistId(itemDetails.id);
  }

  const onSendEmailClick = () => {
    trackGAEvent(props.selectedOrganization.organization.name, 'Sent Email Clicked from checklist details', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
    const data = setEmailObj(itemDetails, props.selectedTemplate);
    props.setCurrentEmailState(data);
    props.handleSendEmailDialogOpen();
    props.setFromChecklistDetailsPage(true);
    props.setEmailSentFrom('Checklist Details');
}

  const onEmailFollowupClick = (followup) => {
    const organization_id = props.selectedOrganization.organization.id;
    const followup_id = followup.id;

    setIsActive1(false);
    setChecklistId(undefined);
    
    setDisableFollowupLoading(true);
    disableEmailFollowupApi(organization_id, followup_id)
        .then((response) => {
            const res = response.data;
            consoleToLog('Response disableEmailFollowupApi', res);
            const filteredArray = res.checklists && res.checklists.length > 0 && res.checklists.filter((checklist) => checklist.id === itemDetails.id);

            setItemDetails({ ...itemDetails, 
              followups: itemDetails.followups.filter((followup) => followup.id !== followup_id),
              checklist_labels_list: filteredArray[0].checklist_labels_list,
              status: filteredArray[0].status,
              followups_count: filteredArray[0].followups_count
            });

            trackGAEvent(props.selectedOrganization.organization.name, 'Stop Followup from Checklist Details', `${props.auth.user.firstname} ${props.auth.user.lastname} `);
            
            props.updateFollowups(res.checklists);
            enqueueSnackbar(res.message, {variant:'success'});
            setDisableFollowupLoading(false);
        })
        .catch((e) => {
            consoleToLog('Error disableEmailFollowupApi', e.response);
            setDisableFollowupLoading(false);
            if(e.response.data && e.response.data.message) {
                enqueueSnackbar(e.response.data.message, {
                    variant:'error'
                })
                return;
            }
        })
  }

  const showEmailFollowupsPopover = () => {
    return (
        itemDetails.id === checklistId &&
        <ClickAwayListener onClickAway={() => {
                setIsActive1(false);
                setChecklistId(undefined);
            }}>
            <Grid item className={`menu ${isActive1 ? "active" : "inactive"}`} style={{width:'230px', textAlign:'left'}}>
                <ul>
                    {itemDetails.followups && itemDetails.followups.length > 0 && itemDetails.followups.map((followup) => {
                        return <li className={classes.list} key={followup.id}
                                onClick={() => onEmailFollowupClick(followup)}
                                style={{borderBottom:'1px solid rgba(0,0,0,0.1)'}}>
                                <Tooltip title={followup.subject} arrow>
                                <a className={classes.followupTextStyle}>{followup.subject}</a>
                                </Tooltip>
                        </li>
                    })
                        
                    }

                    
                </ul>
            </Grid>
        </ClickAwayListener>
    )
  }

  const onEditChecklistClick = () => {
    setEditChecklist(!editChecklist);
    setIsActive(false);
    setChecklistId(undefined);
  }

  // ChecklistPage Topbar
  const CheckListDetailsTopBar = (props) => {
  //  var companyData = currentItem ? currentItem : itemDetails;
    return (
      <Grid
        container
        className={classes.detailsMain}
        key={companyData.id}
      >
        <Grid item container alignItems="center" direction="row" sm={6}>
          <Grid container wrap="nowrap" spacing={2}>
            <Grid item>
              <Button
                variant="outlined"
                className={classes.backIcon}
                onClick={props.handleDetailsDialogClose}
              > 
                <ArrowBackIcon /> 
              </Button>
            </Grid>
            <Grid
              item
              container
              xs
              zeroMinWidth
              style={{ paddingLeft: "0px", paddingTop: "6px" }}
            >
              <Grid item container direction="row">
                <Grid
                  item
                  container
                  direction="row"
                  alignItems="center"
                  style={{ paddingTop: "5px"}}
                  className={
                      companyData.due_date === null &&
                      companyData.checklist_labels_list.length === 0
                      ? `companyTitle2`
                      : `companyTitle`
                  }
                >
                  <Grid item varient="body1" direction="column">
                    {companyData.title}  
                  </Grid>
                  <Grid
                    item
                    direction="column"
                    className={classes.checklistYear}
                  >
                    {companyData.subtitle}
                  </Grid>
                  <Grid
                    item
                    direction="column"
                    className={classes.templateNameStyle}
                  >
                    {props.templateName1}
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                container
                direction="row"
                style={{ marginTop: "-2px", marginLeft: "2px" }}
              >
                {/* DueDate*/}
                {companyData.due_date &&
                  companyData.due_date.length > 0 ? (
                  <Grid item>
                    <Tooltip title="Due Date" arrow>
                      <Button
                        className={classes.checklistDateBtn}
                        style={{color: props.isDueDateOverDue && props.isDueDateOverDue(companyData) && "red"}}
                      >
                        <i
                          className="flaticon-calendar-1"
                          style={{ lineHeight: "20px", height: "14px" }}
                        ></i>
                        <Typography
                          variant="subtitle1"
                          className={classes.checklistDate}
                          style={{color: props.isDueDateOverDue && props.isDueDateOverDue(companyData) && "red"}}
                        >
                          {`${moment(companyData.due_date).format(
                            "Do MMM"
                          )}`}
                        </Typography>
                      </Button>
                    </Tooltip>
                  </Grid>
                ) : (
                  <div></div>
                )}
                {/* Labels*/}
                <Grid
                  item
                  className={
                    companyData.due_date === null
                      ? "details_labels"
                      : "detailsLabels"
                  }
                >
                  {companyData.checklist_labels_list &&
                    companyData.checklist_labels_list.length > 0 ? (
                      companyData.checklist_labels_list.map((label) => {
                      return (
                        <div
                          key={label.id}
                          className="labellist"
                          style={{ display: "inline-flex" }}
                        >
                          <Typography
                            style={{
                              color: `${label.color}`,
                              paddingLeft: "3px",
                              cursor: "pointer",
                              paddingTop: "0",
                              fontSize: "12.5px",
                              marginTop: "12px",
                            }}
                          >
                            {label.name}  
                          </Typography>
                          <FiberManualRecordIcon
                            fontSize="small"
                            style={{
                              width: "6px",
                              height: "6px",
                              color: "#ccc",
                              paddingLeft: "3px",
                              paddingTop: "0",
                              marginTop: "20px",
                            }}
                            className="bullet"
                          />
                        </div>
                      );
                    })
                  ) : (
                    <div></div>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* Options */}
        {!editChecklist &&
        <Grid item sm={4}  style={{ marginTop: "16px"}}>
          <Grid item container className={classes.moreOptionsContainer}>
            <Grid item>
              <SelectFromLabelList
                setAssignedLabelList={(selectedLabels) =>
                  setAssignedLabelList(selectedLabels, itemDetails.id)
                }
                assignees={getLabelsFromChecklistItem(itemDetails)}
                disabledAssignChecklistLabels={accessLevelMember}
              />
            </Grid>
            <Grid item>
              <Button item className="more_actionButton1" 
                  onClick={onSendEmailClick}
                  > 
                      <EmailOutlinedIcon 
                      className={classes.moreActionsIcons}
                      style={{fontSize:'15px', verticalAlign:'baseline', color:'#0071ff', marginRight:'4px'}}   
                      />
                  <span> Send Email </span>
              
              </Button> 
            </Grid>
            {itemDetails.followups && itemDetails.followups.length > 0 ?
            <Grid item style={{position:'relative'}}>
              <Button item className="more_actionButton2"
                  onClick={getEmailFollowups}
              > 
                      {disableFollowupLoading 
                        ?
                      <CircularProgress size={20} style={{marginRight:'4px'}} />
                        :
                      <HighlightOffOutlinedIcon 
                      className={classes.moreActionsIcons}
                      style={{fontSize:'15px', verticalAlign:'baseline', color:'#dc143c', marginRight:'4px'}}   
                      />}
                  <span> Stop Followup </span>
                  
              </Button>
              {itemDetails.followups.length > 0 && showEmailFollowupsPopover()}
            </Grid>
              :
            itemDetails.status !== "Completed" && itemDetails.is_completable &&
            <Grid item>
              <Button item className="more_actionButton"  onClick={completeChecklistApiFromHeader}> 
                <CheckIcon 
                    className={classes.moreActionsIcons}
                    style={{fontSize:'15px', verticalAlign:'baseline', color:'#228B22', marginRight:'4px'}}   
                />
                <span> Complete </span>

              </Button>
            </Grid>
            }
            <Grid item style={{position:"relative"}}>
                <Tooltip title="More Actions" arrow >
                    <i className="flaticon-more action__icon" 
                        onClick={(e) => onDeleteClick(e, itemDetails.id)}
                        style={{position:"relative", left:'1px', display:"flex", alignItems:'center'}}>
                    </i>
                </Tooltip>
                {(checklistId === itemDetails.id) && isActive &&
                  <ClickAwayListener onClickAway={() => {
                      setIsActive(false);
                      setChecklistId(undefined);
                    }}>
                      <Grid item className={`menu ${isActive ? "active" : "inactive"}`}>
                        <ul>
                            {!accessLevelManager &&
                            <li className={classes.list}  
                                onClick={onEditChecklistClick}
                                > 
                                <a> Edit </a>
                            
                            </li>}
                            {itemDetails.status !== 'Pending Approval' &&
                              <li className={classes.list} onClick={(e) => {
                                  handleOpenSendForReviewDialog(undefined, value !== 5 ? true : false);
                              }}>
                                  <a>Send For Review</a>
                              </li>}
                            {!accessLevelManager &&
                                <li className={classes.list} onClick={(e) => {
                                    if (window.confirm("Are you sure, You want to archive this checklist? Once archived you won't be able see this checklist!")) {
                                        onArchiveChecklistClick(itemDetails.id);
                                        } 
                                }}>
                                    <a>Archive Checklist</a>
                                </li>
                            }
                            {itemDetails.status !== "Completed" && itemDetails.followups && itemDetails.followups.length > 0 && itemDetails.is_completable && (
                                <li className={classes.list} onClick={completeChecklistApiFromHeader}>
                                      <a>Complete Checklist</a>
                                </li>
                              )}
                              <li className={classes.list} disabled={accessLevelMember} 
                                    onClick={(event) => {
                                      // setGetWigets(false);
                                      handleClickNew(event);
                                    }}>
                                    <a>Set Due Date</a>
                              </li>
                              <li className={classes.list} 
                                    onClick={handleDrawerOpen}>
                                    <a>View Client Details</a>
                              </li>
                        </ul>
                      </Grid>
                  </ClickAwayListener>}
                  {itemDetails.id === checklistId && handleDueDatePopOver()}
                </Grid>
                      {/*  */}
            </Grid>
        </Grid>}

        <Grid item direction="row" sm={editChecklist ? 6 : 2}>
          <Grid item container justifyContent="flex-end">
            <Grid item style={{ marginTop: "15px", marginRight: "35.5px" }}>
              {editChecklist 
              ?
              <Tooltip title="Close Edit Mode" arrow>
                <IconButton onClick={() => {
                  setEditChecklist(false);
                  setValue(0);
                  }}>
                  <ClearIcon />
                </IconButton>
              </Tooltip>
              :
              <Typography
                disabled={accessLevelMember}
                variant="subtitle1"
                className={classes.checkListStatusComponent}
                style={{
                  borderRadius: "5px",
                  padding: "2px 10px 2px 10px",
                  marginTop: "-3px",
                  color: `${getStatusColor(companyData.status)}`,
                  border: `1px solid ${getStatusColor(
                    companyData.status
                  )}`,
                }}
              >
                {companyData.status} 
              </Typography>}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  // Checking the length of itemDetails object
  const itemDetailsObjectLength = Object.keys(itemDetails).length;
  // Assigning a avr to props.currentchecklistItem
  var currentItem = props.currentchecklistItem;

  const ButtonInTabs = ({ className, onClick, children, title }) => {
    return <Button 
            className={className} 
            onClick={onClick} 
            children={children}
            //disabled = {(title === 'Export') && props.templates.length === 0}
            style={{
              color:title === 'Export' ? '#555555': 'white', 
              cursor: 'pointer !important',
              marginRight:'24px'
            }}
            />;
  };

  const onAddTaskClick = () => {
    setAddTaskClicked(true);
  }

  const handleOpenSendForReviewDialog = (task, fromApprovalModal) => {
    setOpenSendForReviewDialog(true);
    setTaskObj(task);
    fromApprovalModal && setCallGetApiFromReviewModal(true);
  }

  const checklistTaskUpdateFromApproval = (taskObj, checklistObj, fromApprovalModal) => {
    if(taskObj) {
      const task_array = itemDetails.checklist_task.map((task) => {
          if(task.id === taskObj.id) {
            task = taskObj
          }
          return task
      });
      setTaskObj(taskObj);
      setItemDetails({ ...itemDetails, status: checklistObj.status, checklist_task: [...task_array]});
      setUpdateTaskFromParent(true);
    } else {
      setItemDetails({ ...itemDetails, status: checklistObj.status});
    }
    fromApprovalModal && setCallGetApi(true);
    props.updateChecklistFromReview(checklistObj);
  }

  const handleCloseSendForReviewDialog = () => {
    setOpenSendForReviewDialog(false);
    approvalObj && setApprovalObj(undefined);
    callGetApiFromReviewModal && setCallGetApiFromReviewModal(false);
  }

  const checklistDetailsRulesObjUpdate = (obj) => {
    setItemDetails({ ...itemDetails, business_logic: obj});
    setUpdateTaskFromParent(true);
  }

  return (
    <>
      <div style={{ overflow: "hidden"}}>
        <Dialog
          fullScreen
          open={props.openDialog}
          onClose={props.handleDetailsDialogClose}
          TransitionComponent={Transition}
        >
          {/* using currentItem i.e from dashboard || itemDetails i.e from tasklistApi */}
            {currentItem || itemDetails ? (
              <CheckListDetailsTopBar
                handleDetailsDialogClose={props.handleDetailsDialogClose}
                isDueDateOverDue={props.isDueDateOverDue}
                handleSendEmailDialogOpen={props.handleSendEmailDialogOpen}
                templateName={props.selectedTemplate?.name}
                templateName1={itemDetails?.template_data?.template_name}
              />
            ) : (
              <Grid container alignItems="center" justifyContent="center">
                <Grid
                  item
                  sm={12}
                  container
                  alignItems="center"
                  justifyContent="center"
                >
                  <CircularProgress color="secondary" sm={12} />
                </Grid>
              </Grid>
            )}
            {itemDetailsObjectLength === 0 ? (
              <Grid container alignItems="center" justifyContent="center">
                <Grid
                  item
                  sm={12}
                  container
                  alignItems="center"
                  justifyContent="center"
                >
                  <CircularProgress color="secondary" sm={12} />
                </Grid>
              </Grid>
            ) : (
              <div className={classes.root} style={{background: value === 3 && '#f2f1f1', height:'100%'}}>
                <AppBar
                  position="static"
                  color="default"
                  className={classes.tabsAppbar}
                >
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="secondary"
                    textColor="secondary"
                    style={{ paddingLeft: "15px" }}
                    aria-label="full width tabs example"
                  >
                    <Tab
                      label="Tasks"
                      className={classes.tabsMain}
                      {...a11yProps(0)}
                    />
                    <Tab
                      label={
                        <TabsWithBadage
                          count={itemDetails ? itemDetails.attachments.length: ""}
                          labelname="Attachments"
                          
                        />
                      }                    
                      className={classes.tabsMain}
                      {...a11yProps(1)}
                      disabled={editChecklist}
                    />
                    <Tab
                      label="Activity"
                      className={classes.tabsMain}
                      {...a11yProps(2)}
                      disabled={editChecklist}
                    />
                    <Tab
                      label={<TabsWithBadage 
                      count={itemDetails ? itemDetails.notes.length: ""}
                      labelname="Notes" />}
                      className={classes.tabsMain}
                      {...a11yProps(3)}
                      disabled={editChecklist}
                    />
                    <Tab
                      label="Audit Trail"
                      className={classes.tabsMain}
                      {...a11yProps(4)}
                      disabled={editChecklist}
                    />
                    <Tab
                      label="Approvals"
                      className={classes.tabsMain}
                      {...a11yProps(5)}
                      disabled={editChecklist}
                    />
                    {
                      editChecklist &&
                      <Tab
                        label="Automation"
                        className={classes.tabsMain}
                        {...a11yProps(6)}
                        />
                    }
                    {
                      editChecklist &&
                      <Tab
                        label="Rules"
                        className={classes.tabsMain}
                        {...a11yProps(7)}
                        />
                    }
                    {editChecklist && !accessLevelManager &&
                      <ButtonInTabs
                          onClick={onAddTaskClick}
                          className={classes.addButton}
                          title='Create Task'
                      >
                      <AddCircleOutlineIcon fontSize='small' 
                          style={{fontSize:'15px'}}
                          className={classes.buttonPlusIcon}/>
                            Add Task
                      </ButtonInTabs>
                    }
                  </Tabs>
                </AppBar>
                <Grid container direction="row" alignItems="center" justifyContent="center">
                  <span className={classes.topMessage}>*Press esc to exit</span>
                </Grid>
                {/* <SwipeableViews index={value} onChangeIndex={handleChangeIndex} 
                  style={{padding: value === 3 ? "0px" : "14px"}}> */}
                <div style={{padding: value === 3 ? "0px" : "14px"}}>
                  <div style={{ display: value === 0? 'block': 'none', paddingTop: "8px"}}>
                    {confetti.run && (
                      <div>
                        <Grid item container className={classes.checklistStatus}>
                          <Grid item lg={12} style={{ textAlign: "center" }}>
                            <Typography variant="body1">Completed</Typography>
                          </Grid>
                        </Grid>
                        <Confetti
                          width={window.innerWidth}
                          height={580}
                          run={confetti.run}
                          recycle={confetti.recycle}
                          gravity={0.3}
                          numberOfPieces={400}
                        />
                      </div>
                    )}
                    {itemDetails.checklist_task && <TasksContent
                      taskList={itemDetails.checklist_task}
                      checklistId={itemDetails.id}
                      checkList={itemDetails}
                      onCheckListStatusCompleted={onCheckListStatusCompleted}
                      //onTaskStatusCompleted={onTaskStatusCompleted}
                      onTaskStatusChanged={onTaskStatusChanged}
                      activityObj={activityObj}
                      // getWidgets={getWidgets}
                      // getActivities={getActivities}
                      //selectedTaskMain={selectedTaskMain}
                      //onWidgetAttachmentAdded = {onWidgetAttachmentAdded}
                      onTaskCommentUpdated={onTaskCommentUpdated}
                      onNoteAdded={onNoteAdded}
                      onNoteDeleted={onNoteDeleted}
                      onAttachmentUpdated={onAttachmentUpdated}
                      editChecklist={editChecklist}
                      addTaskClicked={addTaskClicked}
                      setAddTaskClicked={setAddTaskClicked}
                      checklistTaskUpdate={checklistTaskUpdate}
                      checklistTaskUpdateFromApproval={checklistTaskUpdateFromApproval}
                      updateTaskFromParent={updateTaskFromParent}
                      setUpdateTaskFromParent={setUpdateTaskFromParent}
                      setApprovalObj={setApprovalObj}
                      handleOpenSendForReviewDialog={handleOpenSendForReviewDialog}
                      taskObj={taskObj}
                      setTaskObj={setTaskObj}
                      isDueDateOverDue={props.isDueDateOverDue}
                      value={value}
                    />}
                  </div>
                  <div style={{ display: value === 1 ? 'block' : 'none'}}>
                    <ChecklistAttachments
                      attachmentList = {itemDetails.attachments}
                      checklistId = {itemDetails.id}
                      checkListItem = {itemDetails}
                      onAttachmentDelete = {onAttachmentDelete}
                      onAttachmentAdded = {onAttachmentAdded}
                    />
                  </div>
                  <div style={{ display: value === 2 ? 'block' : 'none'}}>
                    <ActivityPage checklistSlug = {props.slug} 
                      isFromCheckListItemPage={true} 
                      onChecklistActivityClick = {onChecklistActivityClick}
                    />
                  </div> 
                  <div style={{ display: value === 3 ? 'block' : 'none'}}>
                    <ChecklistNotes
                      checkListItem = {itemDetails}
                      onNoteAdded = {onNoteAdded}
                      onNoteDeleted = {onNoteDeleted}
                      tabValue = {value}
                      updateChecklistNoteReadUsers={props.updateChecklistNoteReadUsers}
                    />
                  </div>
                  <div style={{display: value === 4 ? 'block' : 'none'}}>
                      <EmailConversations
                          checkListItem = {itemDetails}
                          checklistGroup={props.checklistGroup}
                          onChecklistCreation={props.onChecklistCreation}
                          setChecklistArray={props.setChecklistArray}
                          updateFollowups={props.updateFollowups}
                      />
                  </div>
                  <div style={{display: value === 5 ? 'block' : 'none'}}>
                      <Approvals
                          checkListItem = {itemDetails}
                          taskList = {itemDetails?.checklist_task}
                          checklistTaskUpdateFromApproval={checklistTaskUpdateFromApproval}
                          setApprovalObj={setApprovalObj}
                          approvalObj={approvalObj}
                          handleOpenSendForReviewDialog={handleOpenSendForReviewDialog}
                          updateApporvalList={updateApporvalList}
                          setUpdateApprovalList={setUpdateApprovalList}
                          callGetApi={callGetApi}
                          setCallGetApi={setCallGetApi}
                      />
                  </div>
                  {editChecklist && !accessLevelManager &&
                  <div style={{display: value === 6 ? 'block' : 'none'}}>
                    <Rules 
                        taskList={itemDetails.checklist_task.filter((task) => task.template_task)}
                        fromChecklistDetails={true}
                        checklistId={itemDetails?.id}
                    />
                  </div>}
                  {editChecklist && !accessLevelManager &&
                  <div style={{display: value === 7 ? 'block' : 'none'}}>
                    <TemplateApprovalRules 
                        taskList={itemDetails.checklist_task.filter((task) => task.template_task)}
                        fromChecklistDetails={true}
                        checklistId={itemDetails?.id}
                        checklistDetailsObj={itemDetails}
                        checklistDetailsRulesObjUpdate={checklistDetailsRulesObjUpdate}
                        checklistItemNotesArrayRev={checklistItemNotesArrayRev}
                        updateChecklistStatusAndIsCompletable={props.updateChecklistStatusAndIsCompletable}
                    />
                  </div>}
                </div>
                {/* </SwipeableViews> */}
              </div>
            )}
        </Dialog>
        { openDrawer &&<ChecklistClientDetailsModal openDrawer={openDrawer}
            handleDrawerClose={handleDrawerClose}
            checkListItem={itemDetails}
            handleSendEmailDialogOpen={props.handleSendEmailDialogOpen}
            templateName={props.selectedTemplate?.name}
            />}

      {openSendForReviewDialog && 
      <SendForReviewDialog openSendForReviewDialog={openSendForReviewDialog}
          handleCloseSendForReviewDialog={handleCloseSendForReviewDialog}
          selectedChecklistItem={itemDetails}
          fromChecklistDetailsComponent={true}
          taskObj={taskObj}
          checklistTaskUpdateFromApproval={checklistTaskUpdateFromApproval}
          approvalObj={approvalObj}
          setApprovalObj={setApprovalObj}
          setUpdateApprovalList={setUpdateApprovalList}
          callGetApiFromReviewModal={callGetApiFromReviewModal}
      />
      }
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  selectedOrganization: state.organizationInfo.selectedOrganization,
  startDateFilter: state.filters.startDateFilter,
  endDateFilter: state.filters.endDateFilter,
  overlay: state.overlay,
  selectedTemplate: state.organizationInfo.selectedTemplate,
  emailFollowupObj: state.organizationInfo.emailFollowupObj,
  selectedMembersFilter: state.filters.selectedMembersFilter,
  companiesFilter: state.filters.companiesFilter,
  templates: state.organizationInfo.templates
});

const mapDispatchToProps = (dispatch) => ({
  setChecklistArray: (checklistArray) => dispatch(setChecklistArray(checklistArray)),
  setCurrentEmailState: (emailState) => dispatch(setCurrentEmailState(emailState)),
  setEmailFollowupObject: (emailFollowup) => dispatch(setEmailFollowupObject(emailFollowup)),
  setFromChecklistDetailsPage: (fromChecklistDetails) => dispatch(setFromChecklistDetailsPage(fromChecklistDetails)),
  setEmailSentFrom: (str) => dispatch(setEmailSentFrom(str)),
  setOrgTemplates: (templates) => dispatch(setOrgTemplates(templates)),
  setSelectedTemplate: (template) => dispatch(setSelectedTemplate(template))
})

export default connect(mapStateToProps, mapDispatchToProps)(CheckListDetailPage);