import React, {useState, Fragment, useEffect} from 'react';
import {TextField, Grid, Chip} from '@material-ui/core'; 
import {connect} from 'react-redux';
import {trackGAEvent} from '../util/AppUtil';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ClearIcon from '@material-ui/icons/Clear';
import { useIsMount } from './useIsMount';

const useStyles = makeStyles((theme) => ({
    avatar : {
        width: '15px !important',
        height: '15px !important',
        fontSize: '12px !important',
        background: 'none !important',
        color: 'rgba(0, 0, 0, 0.87) !important',
        border: '1px solid rgba(0, 0, 0, 0.26) !important'
        },
    
    chip : {
        borderRadius: '4px !important',
        backgroundColor: '#f9f9f9 !important',
        border: '1px solid #ececec !important',
        },
        
    avatarStyle: {
        width: 20,
        height: 20,
        fontSize: 12
    },
       
    autoStyle: {
        "&::-webkit-scrollbar": {
            display: "none"
        }
            
    }

    }
));

const AutocompleteTemplateTask = (props) => {
    const classes = useStyles();
    const isMount = useIsMount();

    const[selectedTasks, setSelectedTasks] = useState(props.forApproval ? props.taskListForApproval : props.taskListForAttachment);

    useEffect(() => {
        if(!isMount) {
            if(props.forApproval) {
                props.setTaskListForApproval(selectedTasks);
            }

            if(props.forAttachment) {
                props.setTaskListForAttachment(selectedTasks);

            }

        }
    }, [selectedTasks]);

    useEffect(() => {
        if(!isMount) {
            if(props.allTaskNeedsApproval && props.forApproval) {
                setSelectedTasks([]);
            }
        }
    }, [props.allTaskNeedsApproval]);

    const onAddTask = (option) => {
        //trackGAEvent(props.selectedOrganization.organization.name, 'Labels Added', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
    }

    const onDeleteTask = (option) => {
        //trackGAEvent(props.selectedOrganization.organization.name, 'Labels Removed', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
        setSelectedTasks(selectedTasks.filter(entry => entry !== option));
    }

    const renderOptions = (option) => {
        return (
            <div style={{display: "flex", width:'100%'}} 
                className={classes.autoStyle}
                onClick={() => {
                    onAddTask(option)
                }} 
            >
                {option.title}
            </div>
        );
    }
    
    const renderTags = (options) => {
        return (
            options.map(option =>
                <Fragment>
                <Chip
                    classes={{
                        root: classes.chip
                    }}
                    label={option.title}
                    onDelete={() => onDeleteTask(option)}
                    variant="outlined"
                    style={{margin: "2px 2px"}}
                    deleteIcon={<ClearIcon  style={{width: "18px"}}/>}
                />
                </Fragment>))
        }

    return (
        <Grid item md={5} style={{marginLeft:'18px'}}>
            <Autocomplete
                multiple
                id="size-small-outlined-multi"
                size="small"
                options={props.fromChecklistDetails ? props.taskList.filter((task) => task.template_task) : props.taskList}
                getOptionLabel={(option) => option.title}
                disableClearable
                renderOption={(option) => renderOptions(option)}
                value={selectedTasks}
                onChange={(e, newValue) => setSelectedTasks(newValue)}
                renderTags={(options) => renderTags(options)}
                renderInput={(params) => (
                    <TextField {...params} variant="outlined" placeholder="Type here" />
                )}
                disabled={(props.forApproval && props.allTaskNeedsApproval) || (props.forApproval && !props.specificTaskNeedsApproval) || (props.forAttachment && !props.specificTaskNeedsAttachment)}
            />
        </Grid>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
});

export default connect(mapStateToProps)(AutocompleteTemplateTask);