import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Tooltip, Button, Divider} from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import SelectFromApproverList from "../checklist/SelectFromApproverList";
import SelectFromMemberList from "../checklist/SelectFromMemberList";
import CircularProgressWithLabel from "../CircularProgressWithLabel";
import moment from "moment";
import { getStatusColor, getAssigneesFromChecklistItem, getAssigneesOfApprover} from "../../util/AppUtil";
import { showEmailIcons, trackGAEvent } from "../../util/AppUtil";
import {connect} from 'react-redux';

const useStyles = makeStyles((theme) => ({
  mainbg: {
    backgroundColor: "white",
    marginTop: "20px",
    borderRadius: "4px",
    padding: "10px 10px 10px 0px",
    marginBottom: "32px",
  },
  checklisthover: {
    "& .onChecklist__hover": {
      visibility: "hidden",
    },
    "&:hover .onChecklist__hover": {
      visibility: "visible",
    },
  },
  topbtns: {
    color: "black",
    fontSize: "15px",
    minWidth: "20px",
    padding: "5px 6px",
  },
  sidemenubtns: {
    color: "#808080",
    fontSize: "15px",
    minWidth: "20px",
    padding: "0px 5px",
  },
  labels: {
    display: "flex",
    marginTop: "3px",
    flexGrow: "1",
    "& .labellist:last-child": {
      "& .bullet": {
        display: "none",
      },
    },
  },
  status: {
    color: "#DB4C3F",
    padding: "1px 12px",
    border: "1px solid #DB4C3F",
    borderRadius: "5px",
  },
  containermargin: {
    marginBottom: "-10px",
  },
  icontext: {
    color: "#808080",
    padding: "0 0px 0 5px",
  },
  loading: {
    position: "relative",
    left: "50%",
    right: "50%",
    top: "10%",
  },
  customColumn1: {
    flexBasis: "13.5%",
    maxWidth: "13.5%",
  },
  customColumn2: {
    flexBasis: "13.7%",
    maxWidth: "13.7%",
  },
  drawer: {
    //    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: (createChecklist) => {
      return createChecklist ? "550px" : "36%";
    },
    height: "calc(100vh-51px)",
    flexGrow: 1,
    top: "51px",
  },
  appBar: {
    background: "#F9F9F9",
    height: "45px",
    color: "#202020",
  },
  popover: {
    pointerEvents: "none",
    marginLeft: "-150px",
  },
  paper: {
    padding: theme.spacing(1),
    boxShadow: "none",
    border: "1px solid rgba(0, 0, 0, 0.12)",
  },
  moveToFolder: {
    "&:hover": {
      background: "#d3d3d3",
    },
    "& .displayList": {
      display: "none",
      position: "absolute",
      top: 0,
      left: 0,
      padding: 0,
    },
    "&:hover .displayList": {
      display: "block",
      position: "absolute",
      left: "-80px",
      top: "40%",
      backgroundColor: "#ffffff",
      border: "1px solid #ccc",
      zIndex: "15",
      borderRadius: "4px",
      width: "180px",
    },
  },
  displayFolderIcon: {
    fontSize: "16px",
    marginRight: "8px",
  },
  movetoRoot1: {
    display: "block",
    width: "100%",
    position: "relative",
    padding: "7px 10px 7px 16px",
  },
  list: {
    "&:hover": {
      background: "#d3d3d3",
    },
  },
  folderNameStyle: {
    borderRadius: '5px',
    borderColor: 'rgb(219, 219, 219)',
    fontSize: '12px',
    backgroundColor: '#f3f3f3',
    padding: '2px 4px',
    color: 'rgba(0, 0, 0, 0.6)'
  },
  emailIcons: {
    filter: 'invert(46%) sepia(96%) saturate(5%) hue-rotate(346deg) brightness(96%) contrast(96%)'
  }
}));

const ChecklistItem = (props) => {
  const [mode, setMode] = useState("out-in");
  const classes = useStyles(useStyles);

  const checklistLabelList = (checklist) => {
    const labels = checklist.checklist_labels_list.slice(2);
    const titles = labels.map((l) => l.name).join(", ");
    return (
      <Tooltip title={titles} arrow>
        <Typography
          variant="subtitle1"
          style={{
            marginLeft: "12px",
            color: "#333",
            textDecoration: "underline",
            marginTop: "1px",
            cursor: "pointer",
          }}
        >
          +{labels.length} more
        </Typography>
      </Tooltip>
    );
  };

  const isDueDateOverDue = (checklist) => {
    var dueDate, currentDate;
    currentDate = moment(new Date()).format("YYYY-MM-DD");

    //Due Date
    if (checklist.due_date)
      dueDate = moment(checklist.due_date, "YYYY-MM-DD").format("YYYY-MM-DD");
    //if Due date available then compare else return false
    if (dueDate) {
      if (moment(currentDate).isSame(dueDate)) return false;
      //check current date is greater/after than provided date if greater return true
      if (moment(currentDate).isAfter(dueDate)) return true;
      else return false;
    } else return false;
  };

  return (
    <div>
      <TransitionGroup mode={mode}>
        <CSSTransition
          timeout={1000}
          // exit={animationActive}
          classNames="item"
        >
          <Grid
            container
            direction="column"
            className={classes.checklisthover}
          >
            <Grid container style={{marginBottom:"10px"}}>
              <Grid item md={6} lg={6}>
                {/**1st column checklist title subtitle */}
                <div style={{ display: "flex" }}>
                  <Grid item md={12} lg={12}>
                    <Grid item container alignItems="center" style={{cursor:'pointer'}}
                      onClick={() => props.handleDetailsDialogOpen(props.checklist.slug_code, props.checklist)}>
                      <Grid item>
                        <Typography
                          variant="body1"
                        >
                          <Tooltip title={props.checklist.title} arrow>
                            <div
                              className="checklistItem_link"
                              style={{
                                textDecoration: "none",
                                marginLeft: "4px",
                              }}
                            >
                              {props.checklist.template.name}
                            </div>
                          </Tooltip>
                        </Typography>
                      </Grid>
                      <Grid item style={{ marginLeft: "8px" }}>
                        <Typography
                          variant="body1"
                          style={{ lineHeight: "42px", color: "#888888" }}
                        >
                          {props.checklist.subtitle}
                        </Typography>
                      </Grid>
                      {props.checklist.group && props.checklist.group?.group_name &&
                      <Grid item style={{ marginLeft: "8px", marginTop: "2px"}}>
                        <Typography
                          variant="body1"
                          className={classes.folderNameStyle}
                        >
                          {props.checklist.group?.group_name}
                        </Typography>
                      </Grid>}
                    </Grid>
                    <Grid item container style={{ marginTop: "-6px" , cursor: "default" }}>
                      <Grid item>
                        <Tooltip title="Notes" arrow>
                          <Button
                            aria-label="delete"
                            className={classes.sidemenubtns}
                            style={{ cursor: "default" }}
                            onClick={(e)=>{
                              trackGAEvent(props.selectedOrganization.organization.name, 'Checklist Notes Icon clicked from client details', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
                              props.getCurrentchecklistItem(props.checklist);
                              props.handleTabOpen(props.checklist.slug_code, "Notes");}}
                          >
                            <i className="flaticon-comment" style={{ lineHeight: "5px" }}></i>
                            <Typography
                              variant="subtitle1"
                              className={classes.icontext}
                            >
                              {props.checklist.notes_count ? props.checklist.notes_count : 0}
                            </Typography>
                          </Button>
                        </Tooltip>
                      </Grid>
                      <Grid item>
                        <Tooltip title="Attachments" arrow>
                          <Button
                            aria-label="delete"
                            className={classes.sidemenubtns}
                            style={{ cursor: "default" }}
                            onClick={(e)=>{
                              trackGAEvent(props.selectedOrganization.organization.name, 'Checklist Attachment Icon clicked from client details', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
                              props.getCurrentchecklistItem(props.checklist);
                              props.handleTabOpen(props.checklist.slug_code, "Attachments");}}
                          >
                            <i className="flaticon-attachment-1" style={{ lineHeight: "5px" }}></i>
                            <Typography
                              variant="subtitle1"
                              className={classes.icontext}
                            >
                              {props.checklist.attachment_count ? props.checklist.attachment_count : 0}
                            </Typography>
                          </Button>
                        </Tooltip>
                      </Grid>
                      <Grid item>
                        <Tooltip title="Sent Mails" arrow >
                            <Button aria-label="delete"
                            className={classes.sidemenubtns}
                            onClick={(e)=>{
                                trackGAEvent(props.selectedOrganization.organization.name, 'Sent Email icon clicked from client details', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
                                props.getCurrentchecklistItem(props.checklist);
                                props.handleTabOpen(props.checklist.slug_code, "Audit Trail");}}
                            >
                                <img src={showEmailIcons('out_going')} className={classes.emailIcons}/>
                                <Typography variant="subtitle1" className={classes.icontext}>
                                {props.checklist.out_email_count ? props.checklist.out_email_count : '0'}
                                </Typography>
                            </Button>
                        </Tooltip>
                    </Grid>
                    <Grid item>
                        <Tooltip title="Received Mails" arrow >
                            <Button aria-label="delete"
                            className={classes.sidemenubtns}
                            onClick={(e)=>{
                                trackGAEvent(props.selectedOrganization.organization.name, 'Recieved Email icon clicked from client details', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
                                props.getCurrentchecklistItem(props.checklist);
                                props.handleTabOpen(props.checklist.slug_code, "Audit Trail");}}
                            >
                                <img src={showEmailIcons('in_coming')} className={classes.emailIcons}/>
                                <Typography variant="subtitle1" className={classes.icontext}>
                                {props.checklist.in_email_count ? props.checklist.in_email_count : '0'}
                                </Typography>
                            </Button>
                        </Tooltip>
                    </Grid>
                    {props.checklist && props.checklist.due_date &&           
                      <Grid item>
                        <Tooltip title="Due Date" arrow>
                          <Button
                            aria-label="delete"
                            className={classes.sidemenubtns}
                            style={{ color: isDueDateOverDue(props.checklist) && "red", cursor:"default" }}
                          >
                            <i className="flaticon-calendar-1" style={{ lineHeight: "5px" }}></i>
                            <Typography
                              variant="subtitle1"
                              style={{color: isDueDateOverDue(props.checklist) && "red"}}
                              className={classes.icontext}
                            >
                              {`${moment(props.checklist.due_date).format("Do MMM")}`}
                            </Typography>
                          </Button>
                        </Tooltip>
                      </Grid>
                      }
                      <Grid item>
                        {props.checklist.checklist_labels_list &&
                          props.checklist.checklist_labels_list.length > 0 && (
                            <div style={{ display: "flex" }}>
                              <div className={classes.labels}>
                                {props.checklist.checklist_labels_list
                                  .slice(0, 2)
                                  .map((label) => {
                                    return (
                                      <div
                                        key={label.id}
                                        style={{  display: "flex", alignItems: "flex-start",paddingTop:"2px" , cursor : "default"}}
                                        className="labellist"
                                      >
                                        <Typography
                                          style={{
                                            color: `${label.color}`,
                                            paddingLeft: "3px",
                                            // cursor: "pointer",
                                            paddingTop: "0",
                                            fontSize: "12px",
                                          }}
                                        >
                                          {label.name}
                                        </Typography>
                                        <FiberManualRecordIcon
                                          fontSize="small"
                                          className="bullet"
                                          style={{
                                            width: "6px",
                                            height: "6px",
                                            color: "#ccc",
                                            paddingLeft: "3px",
                                            paddingTop: "0",
                                            marginTop: "7px",
                                          }}
                                        />
                                      </div>
                                    );
                                  })}
                              </div>
                              <span>
                                {props.checklist.checklist_labels_list && props.checklist.checklist_labels_list.length > 2
                                  && checklistLabelList(props.checklist)}
                              </span>
                            </div>
                          )}
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              {/**1st column checklist title subtitle */}
              {/**Assignees column*/}
              <Grid item md={1} lg={1} className={classes.customColumn2} style={{cursor:"default"}}>
                <Typography
                  variant="body1"
                  style={{ lineHeight: "35px", color: "#202020" }}
                  className="onChecklist__hover"
                >
                  Assignee
                </Typography>
                <SelectFromMemberList
                  isFromChecklistPage={true}
                  assignees={getAssigneesFromChecklistItem(props.checklist)} 
                  isFromWaitingForMyApproval={true}
                  isFromClients={true}
                />
              </Grid>
              {/**Approver column*/}
              <Grid item md={1} lg={1} className={classes.customColumn2} style={{cursor:"default"}}>
                <Typography
                  variant="body1"
                  style={{ lineHeight: "35px", color: "#202020" }}
                  className="onChecklist__hover"
                >
                  Approver
                </Typography>
                <SelectFromApproverList
                  assignees={getAssigneesOfApprover(props.checklist)}
                  isFromWaitingForMyApproval={true}
                  isFromClients={true}
                />
              </Grid>
              {/**Progress column*/}
              <Grid item md={1} lg={1}>
                <Grid item container justifyContent="center">
                  <Grid item style={{marginTop:"2rem"}}>
                    <Typography
                      variant="body1"
                      style={{ lineHeight: "35px", color: "#202020" }}
                      //   className="onChecklist__hover"
                    >
                      <CircularProgressWithLabel
                        style={{ marginTop: "-2px" }}
                        value={ props.checklist.total_task > 0 
                          ? Math.round((props.checklist.completed_task / props.checklist.total_task) * 100) 
                          : 0 }
                      />
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item md={2} lg={2} className={classes.customColumn1}>
                <Grid
                  item
                  container
                  justifyContent="center"
                  style={{ marginTop: "2rem" }}
                >
                  {/**Status */}
                  <Typography
                    variant="subtitle1"
                    style={{
                      borderRadius: "5px",
                      padding: "2px 10px 2px 10px",
                      marginTop: "-3px",
                      color: `${getStatusColor(props.checklist.status)}`,
                      border: `1px solid ${getStatusColor(props.checklist.status)}`,
                    }}
                  >
                    {props.checklist.status}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Divider light style={{ marginLeft: "0px" }} />
          </Grid>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization
})

export default connect(mapStateToProps)(ChecklistItem);
