import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Grid, Typography, Button, Avatar, IconButton, Popover, ListItem, Link, CircularProgress} from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';
import { useIsMount } from '../../../components/useIsMount';
import { getExtension } from '../../../util/AppUtil';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { consoleToLog } from '../../../util/AppUtil';
import {
    deleteChecklistAttachmentApi,
    getChecklistAttachmentUrlApi,
    getTaskAttachmentUrlApi,
} from "../../../services/ChecklistService";
import {connect} from 'react-redux';
import { updateFileUploadUrlApi } from "../../../services/authService";
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
    MuiButtonoutlined : {
        borderColor:'#0071FF !important',
        color: '#0071FF !important'
    },
    attachmentItemContainer: {
        background:'#fff',
        borderRadius:'5px',
        padding:'10px',
        marginBottom:'16px'
    },
    attachmentTitle: {
        fontSize:'14px',
        fontWeight:'500',
        color:'#333333'
    },
    attachmentPathname: {
        fontSize:'12px',
        color:'#555555',
        maxWidth:'200px',
        overflow:'hidden',
        whiteSpace:'nowrap',
        textOverflow:'ellipsis'
    },
    actionsContainer: {
        marginLeft:'auto', 
        display:'flex', 
        flexDirection:"column", 
        alignItems:'center',
        marginTop:'-4px'
    }
}))

const MobileClientAttachments = (props) => {
    const classes = useStyles();
    const isMount = useIsMount();
    const {enqueueSnackbar} = useSnackbar();

    const [anchorEl, setAnchorEl] = useState(null);
    const [downloadableUrl, setDownloadableUrl] = useState(undefined);
    const [widgetDownloadableURL, setWidgetDownloadableURL] = useState(undefined);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (downloadableUrl !== undefined) {
            document.getElementById("initiateDownloadAttachment").href = downloadableUrl;
            document.getElementById("initiateDownloadAttachment").click();
        }
    }, [downloadableUrl]);
    
    useEffect(() => {
        if (widgetDownloadableURL !== undefined) {
            document.getElementById("initiateDownloadAttachment").href = widgetDownloadableURL;
            document.getElementById("initiateDownloadAttachment").click();
        }
    }, [widgetDownloadableURL]);

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handlePopoverClose = () => {
        setAnchorEl(null);
    }

    // Download Checklist Attachment
    const handleDownloadAttachment = (fileObj) => {
        const checklist_id = fileObj.checklist;
        const checklist_attachments_id = fileObj.id;
        const path = fileObj.path;

        getChecklistAttachmentUrlApi(
        checklist_id,
        checklist_attachments_id,
        path
        )
        .then((response) => {
            const res = response.data;
            consoleToLog("Response getChecklistAttachmentUrlApi: ", res);
            const downloadableURL = res.document_signed_url;
            setDownloadableUrl(downloadableURL);
            handlePopoverClose();
            // trackGAEvent(props.selectedOrganization.organization.name, 'Checklist Download Attachment', `${props.auth.user.firstname} ${props.auth.user.lastname} downloaded attachment`);
        })
        .catch((e) => {
            consoleToLog(" getChecklistAttachmentUrlApi error", e.response);
        });
    };

    // Download Widget Attachment
    const onDownloadWidgetAttachment = (fileObj) => {
        const checklist_task_widget_id = fileObj.id;
        const path = fileObj.path;

        getTaskAttachmentUrlApi(checklist_task_widget_id, path)
        .then((response) => {
            const res = response.data;
            consoleToLog("Response getTaskAttachmentUrlApi: ", res);
            handlePopoverClose();
            const widgetDownloadableURL = res.document_signed_url;
            setWidgetDownloadableURL(widgetDownloadableURL);
            // trackGAEvent(props.selectedOrganization.organization.name, 'Checklist Download Widget Attachment', `${props.auth.user.firstname} ${props.auth.user.lastname} downloaded widget attachment`);
        })
        .catch((e) => {
            consoleToLog(" getTaskAttachmentUrlApi error", e.response);
        });
    };

    const deleteAttachment = (e, fileObj, isChecklistAttachment) => {
        if (window.confirm("Are you sure you want to delete this attachment permanently?")) {
            if (isChecklistAttachment) {
                deleteChecklistAttachment(fileObj);
            } else {
                //widget attachment
                deleteWidgetAttachment(fileObj);
            }
        }
    };
    
      // Widget Attachment Delete
    const deleteWidgetAttachment = (fileObj) => {
        const path = ""; //empty for deletion
        const checklist_task_widget_id = fileObj.id;
    
        updateFileUploadUrlApi(checklist_task_widget_id, path)
        .then((response) => {
            const res = response.data;
            consoleToLog("Response deletion-updateFileUploadUrlApi: ", res);
            enqueueSnackbar("Checklist Attachment Deleted", {
                variant: "success",
            });
            var widgetId = res.checklist_task_widget.id;
            //  to parent
            props.onAttachmentDelete(widgetId);
            // trackGAEvent(props.selectedOrganization.organization.name, 'Checklist Delete widget Attachment', `${props.auth.user.firstname} ${props.auth.user.lastname} deleted widget attachment`);
        })
        .catch((e) => {
            consoleToLog(" deletion-updateFileUploadUrlApi error", e.response);
        });
    };
    
      // Widget Checklist Delete
    const deleteChecklistAttachment = (fileObj) => {
       // const checklist_id = fileObj.checklist;
        const organization_id = props.selectedOrganization.organization.id;
        const attachment_id = fileObj.id;
        setLoading(true);
    
        deleteChecklistAttachmentApi(organization_id, attachment_id)
        .then((response) => {
            const res = response.data;
            consoleToLog("Response deleteChecklistAttachmentApi ", res);
            setLoading(false);
            enqueueSnackbar(res.message, {
                variant: "success",
            });
            consoleToLog("attachment_id", attachment_id);
            //  to parent
            props.onAttachmentDelete(attachment_id);
            // trackGAEvent(props.selectedOrganization.organization.name, 'Checklist Delete Attachment', `${props.auth.user.firstname} ${props.auth.user.lastname} deleted attachment`);
        })
        .catch((e) => {
            consoleToLog("Response deleteChecklistAttachmentApi error", e.response);
        });
    };

    const showActionsPopover = (attachmentItem) => {
        return (
            <Popover id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
                }}
                transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
                }}>
                
                <ListItem onClick={(e) => {
                    attachmentItem.attachment_type === "attachment"
                    ? handleDownloadAttachment(attachmentItem)
                    : onDownloadWidgetAttachment(attachmentItem);
                }}
                >Download
                <Link
                    className="d-none"
                    id="initiateDownloadAttachment"
                    target="_blank"
                    onClick={(e) => e.stopPropagation()}
                    download
                    ></Link>    
                </ListItem>  

                <ListItem onClick={(e) => {
                    attachmentItem.attachment_type === "attachment"
                    ? deleteAttachment(e, attachmentItem, true)
                    : deleteAttachment(e, attachmentItem, false);
                }}
                >
                {loading && <CircularProgress size={20} style={{marginRight:'6px'}}/>}    Delete
                </ListItem>    
            </Popover>
        )
    }

    return (
        <div style={{marginTop:'16px', padding:'0px 12px'}}>
            
            
            <Grid item xs={12} style={{marginTop:'16px'}}>
            {props.attachmentList && props.attachmentList.length > 0 ?
                props.attachmentList.map((attachmentItem) => {

                    var AttachmentName = attachmentItem.type ? attachmentItem.json_data.path.replace(/^.*[\\\/]/, "")
                    : attachmentItem.path.replace(/^.*[\\\/]/, "");
                    
                    var uploadedBy = attachmentItem.attachment_user.firstname.charAt(0).toUpperCase() +
                    attachmentItem.attachment_user.lastname.charAt(0).toUpperCase();
                    
                    var uploadedByFullName = attachmentItem.attachment_user.firstname +
                    attachmentItem.attachment_user.lastname;

                    return <Grid item key={attachmentItem.id} 
                                className={classes.attachmentItemContainer}>
                                    <Grid item container alignItems='center'>
                                        <Grid item>
                                            <i className={getExtension(AttachmentName)}
                                                style={{ fontSize: "32px" }}
                                            ></i>
                                        </Grid>

                                        <Grid item style={{marginLeft:'12px'}}> 
                                            <Typography className={classes.attachmentTitle}>
                                                {attachmentItem.title} 
                                            </Typography>

                                            <Typography className={classes.attachmentPathname}>
                                                {AttachmentName}
                                            </Typography>
                                        </Grid>

                                        <Grid item className={classes.actionsContainer}> 
                                            <IconButton onClick={handlePopoverOpen}
                                                style={{padding:'0px'}}>
                                                <MoreHorizIcon fontSize='small'/>
                                            </IconButton>
                                            <Avatar>{uploadedBy}</Avatar>
                                            {showActionsPopover(attachmentItem)}
                                        </Grid>
                                    </Grid>
                            </Grid>
                }) : <Grid item container justifyContent='center' 
                        style={{padding:'8px 0px', background:'#fff'}}
                        alignItems='center'>
                        <Typography >
                            No Attachment Found
                        </Typography>
                    </Grid>
            
            
            }
            </Grid> 
        </div>
    );
}
const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
});

export default connect(mapStateToProps, null)(MobileClientAttachments);