import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {TextField, Grid, Button, FormControlLabel, Checkbox} from '@material-ui/core';
import DurationFilterComponent from '../filters/DurationFilterComponent';
import ChecklistStatusFilters from '../filters/ChecklistStatusFilters';
import ChecklistMembersFilter from '../filters/ChecklistMembersFilter';
import ChecklistLabelFilter from '../filters/ChecklistLabelsFilter';
import ChecklistCompaniesFilter from '../filters/ChecklistCompaniesFilter';
import {connect} from 'react-redux';
import {clearSelectedFilters, clearSelectedMembersFilter, setShowUnassignedChecklist} from '../../actions/filters';
import { trackGAEvent } from '../../util/AppUtil';
import InvoiceStatusFilters from '../filters/InvoiceStatusFilters';

const useStyles = makeStyles((theme) => ({
    mainStyles: {
        marginTop: '16px',
        padding:'0px 16px 8px 16px',
        overflow: "auto !important", 
        height:'calc(100vh - 180px)',
        scrollbarWidth: 'none',
        position:'relative',
        "&::-webkit-scrollbar": {
            display: "none"
            }
        }
    })
);

const ChecklistFiltersModal = (props) => {
    const classes = useStyles();

    const handleChange = (e) => {
        console.log("vikas");
        const value = !props.showUnassignedChecklist;
        if (value) props.clearSelectedMembersFilter();//clear member filter
        props.setShowUnassignedChecklist(value);//store filter
    }

    return (
        <div className={classes.mainStyles}>
            <Grid container md={12} lg={12}>
                <Grid item lg={12}> 

                        <DurationFilterComponent />
                        <ChecklistStatusFilters />
                        <FormControlLabel control={
                            <Checkbox checked={props.showUnassignedChecklist}
                                onChange={handleChange}/>
                            } 
                            label="Unassigned Checklist"/>
                        <ChecklistMembersFilter showUnassignedChecklist={props.showUnassignedChecklist}
                            isChecklistFilter={true}/>
                        <ChecklistLabelFilter />
                        <ChecklistCompaniesFilter />
                        <InvoiceStatusFilters />
                        <div className="modal__filter">
                            <Button variant="contained" color="primary" 
                                    className="modal__button"
                                    onClick={() =>{
                                            trackGAEvent(props.selectedOrganization.organization.name, 'Filter Reset', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
                                            props.clearSelectedFilters()
                                        }
                                    }
                                    >
                                Reset Filters
                            </Button>
                        </div>
                </Grid>
            </Grid>
        </div>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    showUnassignedChecklist: state.filters.showUnassignedChecklist,
})

const mapDispatchToProps = (dispatch) => ({
    clearSelectedFilters: () => dispatch(clearSelectedFilters()),
    clearSelectedMembersFilter: () => dispatch(clearSelectedMembersFilter()),
    setShowUnassignedChecklist: (showUnassignedChecklist) => dispatch(setShowUnassignedChecklist(showUnassignedChecklist)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChecklistFiltersModal);