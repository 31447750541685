import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { MentionsInput, Mention } from "react-mentions";
import { connect } from "react-redux";
import {
  addNewCommentApi,
  editChecklistTaskCommentApi,
  deleteChecklistTaskCommentApi,
} from "../../services/authService";
import { useIsMount } from "../useIsMount";
import { trackGAEvent } from "../../util/AppUtil";
import { consoleToLog } from "../../util/AppUtil";
import moment from "moment";
import Fab from "@material-ui/core/Fab";
import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
import { Divider } from "@material-ui/core";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  menuListItems: {
    paddingRight: "26px",
  },
  commentSection: {
    padding: "5px 5px",
    fontSize: "12px",
    marginLeft: "10px",
    marginTop: "10px",
    marginBottom: "10px",
    boxShadow: "0px 0px 6px 0px #140d0d26",
    minWidth: "50rem",
    maxWidth: "57rem",
    width: "auto",
  },
  checklistDetailHeader: {
    fontWeight: 500,
    fontSize: "20px",
  },
  checklistDetailStatus: {
    borderRadius: "5px",
    padding: "2px 10px 2px 10px",
    color: "#656565",
    border: `1px solid #656565`,
    maxWidth: "12rem",
    width: "auto",
  },
  checklistDetailStatusIcon: {
    marginLeft: "2rem",
  },
  checklistDetailContent: {
    fontSize: "15px",
    padding: "18px 0px",
    color: "black",
    lineHeight: "25px",
  },
  checklistDetailBtn1: {
    borderRadius: "5px",
    padding: "2px 10px 2px 10px",
    color: "#656565",
    border: `1px solid #656565`,
    margin: "10px 10px 10px 0px",
  },
  checklistDetailBtn2: {
    borderRadius: "5px",
    padding: "2px 10px 2px 10px",
    color: "#dd584d",
    border: `1px solid #dd584d`,
  },
  checklistDetailAct: {
    fontWeight: "500",
    fontSize: "20px",
    marginTop: "10px",
    // marginBottom: "10px",
  },
  commentIconContainer: {
    backgroundColor: "#3d92ff",
    minHeight: "30px",
    height: "30px",
    width: "30px",
    boxShadow: "none",
  },
  commentIcon: {
    height: "15px",
    width: "15px",
    color: "white",
    transform: "scaleX(-1)",
    fontSize: "1rem",
  },
  commentedBy: {
    fontSize: "14px",
    marginLeft: "10px",
    color: "black",
  },
  commentedOn: {
    fontSize: "13.5px",
    color: "#5e6265",
    margin: "0px 5px",
  },
  commentTime: {
    fontSize: "14px",
    marginLeft: "10px",
    color: "#000",
  },
}));

const TaskActivities = (props) => {
  const classes = useStyles();
  const [text, setText] = useState("");
  const [mentions, setMentions] = useState();
  const [commentsArray, setCommentsArray] = useState(props.currentTask.activity_feed);
  const [selectedCommentForEditing, setSelectedCommentForEditing] = useState(undefined);
  // for edit and delete button
  const currentAuthUser = props.auth.user.id;
  const { enqueueSnackbar } = useSnackbar();
  const isMount = useIsMount();

  useEffect(() => {
    setCommentsArray(props.currentTask.activity_feed);
  }, []);

  useEffect(() => {
    if (!isMount) {
      setCommentsArray(props.currentTask.activity_feed);
    }
  }, [props.currentTask]);


  useEffect(() => {
    if (!isMount) {
      setCommentsArray(props.currentTask.activity_feed);
    }
  }, [props.currentTask.activity_feed]);

  const clearCurrentCommentData = () => {
    setText("");
    setMentions([]);
    setSelectedCommentForEditing(undefined);
  };

  const addNewCommentApiCall = () => {
    const checklist_task_id = props.currentTask.id;
    const organization_id = props.selectedOrganization.organization.id;
    const mention_users = mentions.map((mentionObj) => mentionObj.id);

    addNewCommentApi(checklist_task_id, mention_users,  text, organization_id)
      .then((response) => {
        consoleToLog("addNewCommentApi res", response);
        //var updatedCommentArray = [response.data.activity_feed, ...commentsArray];
        enqueueSnackbar("Comment Added Successfully", {
          variant: "success",
        });
        //setCommentsArray(updatedCommentArray);
        clearCurrentCommentData();
        
        trackGAEvent(props.selectedOrganization.organization.name, 'Add Comment', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
        
        const comment_count = response.data.activity_feed.comment.comment_count;
        // to parent
        props.onCommentsUpdated(response.data.activity_feed, comment_count);
      })
      .catch((e) => {
        consoleToLog("addNewCommentApi err", e);
      });
  };

  const onCommentEditApiCall = () => {
    const organization_id = organizationID;
    const checklist_task_id = props.currentTask.id;
    const mention_users = mentions.map((mentionObj) => mentionObj.id);
    const comment_id = selectedCommentForEditing.id;

    editChecklistTaskCommentApi(checklist_task_id,  mention_users,  text, comment_id,  organization_id)
      .then((response) => {
        const res = response.data;
        consoleToLog("Response editChecklistTaskCommentApi: ", res);
        /* const updatedEditCommentArray = commentsArray.map((activityObj) => {
          return activityObj.id === res.activity_feed.id
            ? res.activity_feed
            : activityObj;
        }); */
        enqueueSnackbar("Comment Updated Successfully", {
          variant: "success",
        });
        //setCommentsArray(updatedEditCommentArray);
        clearCurrentCommentData();
        trackGAEvent(props.selectedOrganization.organization.name, 'Edit Comment', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
        // to parent
        props.onCommentsEdited(res.activity_feed);
      })
      .catch((e) => {
        consoleToLog("editChecklistTaskCommentApi error", e.response);
      });
  };

  let usersData = props.members.map((member) => ({
    id: member.user.id,
    display: `${member.user.firstname} ${member.user.lastname}`,
  }));

  const handleSaveComment = (e) => {
    e.preventDefault();

    const textValue = text.trim();

    if (!textValue) {
      enqueueSnackbar("Comment can not be empty!", {
        variant: "error",
      });
    } else {
      if (selectedCommentForEditing) {
        //edit note
        //if note is not changed do nothing
        if (selectedCommentForEditing === text) {
          consoleToLog("Comment is not changed!");
          return;
        } else {
          onCommentEditApiCall(text);
        }
      } else {
        //add comment
        addNewCommentApiCall(text);
      }
      setText("");
    }
  };

  const onEditClick = (e, activityObj) => {
    const commentToBeUpdated = activityObj.comment;
    setText(commentToBeUpdated.text);
    setSelectedCommentForEditing(commentToBeUpdated);
    setMentions([]);
    props.onScroll();
  };

  // for onCommentDeleteApiCall
  var organizationID = props.selectedOrganization.organization.id;

  const onCommentDeleteApiCall = (activity) => {
    const comment_id = activity.comment.id;
    const checklist_task_id = activity.task_id;
    const organization_id = organizationID;

    if (window.confirm("Are you sure to delete the comment??")) {
      deleteChecklistTaskCommentApi(comment_id, checklist_task_id, organization_id)
        .then((res) => {
          consoleToLog("deleteChecklistTaskCommentApi res", res);
          var updatedDeleteCommentArray = commentsArray.filter((activityObject) =>
              activityObject.id !== res.data.activity_feed.id
          );
          enqueueSnackbar("Comment Deleted Successfully", {
            variant: "success",
          });
          setCommentsArray(updatedDeleteCommentArray);
          props.onCommentsdeleted(updatedDeleteCommentArray, res.data.activity_feed.checklist);
        })
        .catch((err) => {
          consoleToLog("deleteChecklistTaskCommentApi err", err);
        });
    }
  };

  const getReadableNotesText = (activity) => {
    var readableActivity =  activity.activity_text;
    let removehtmltags =  readableActivity.replace(/<[^>]*>?/gm, '');
    let  numberPattern = /\d+/g;
    let user_id = removehtmltags.match(numberPattern);
    let num = user_id?.join()
    let mention_user_id = Number(num);
    
    readableActivity = readableActivity.replace(/\n\r?/g, "<br />");//add <br>
    readableActivity = readableActivity.split('@[').join(`<b style=color:${mention_user_id === currentAuthUser ? '#0B4C8C' :'black'};background:#E8F5FB;padding:4px;border-radius:4px>`);
    readableActivity = readableActivity.split(']').join("</b>");
    readableActivity = readableActivity.split(/\(.+?\)/).join('');
    return readableActivity;
}

  const displayActivity = () => {
    return(
      <div className = "commentsParent">
      {commentsArray && commentsArray.length > 0 ? (
        commentsArray.map((activity) => {
          const innerHtmlContent = getReadableNotesText(activity);
          const time = moment(activity.created_at).fromNow();

            return(
              <div className = "commentsChild" key={activity.id}> 
                <Grid
                  item
                  container
                  sm={12}
                  style={{ marginTop: "10px" }}
                  className="actvityItem"
                >
                  <Grid item direction="column">
                    <Fab className={classes.commentIconContainer}>
                      <ChatBubbleOutlineIcon className={classes.commentIcon} />
                    </Fab>
                    <Divider orientation="vertical" className="horizontalDivider" />
                  </Grid>
                  <Grid item direction="column" sm={11}>
                    <Grid
                      item
                      style={{ marginLeft: "10px" ,marginTop:"3px"}}
                      dangerouslySetInnerHTML={{ __html: innerHtmlContent }}
                    ></Grid>
                    <Grid
                      item
                      container
                      direction="row"
                      style={{ marginTop: "6px" , display:"inline-block"}}
                      sm={12}
                    >
                      {((activity.action_type === "comments") &&
                        (currentAuthUser === activity.user.id)) ? (
                        <div style={{ display: "inline-flex", marginLeft: "-18px" }}>
                          <Grid item direction="column">
                            <Button
                              size="small"
                              className="controlBtnEdit"
                              onClick={(e) => onEditClick(e, activity)}
                            >
                              Edit
                            </Button>
                          </Grid>
                          <Grid item direction="column">
                            <Button
                              style={{ width: "10px" }}
                              className="controlBtnDelete"
                              onClick={()=>onCommentDeleteApiCall(activity)}
                            >
                              Delete
                            </Button>
                          </Grid>
                        </div>
                      ) : (
                        <div></div>
                      )}
                      <Grid
                        item
                        container
                        direction="column"
                        alignItems="flex-end"
                        justifyContent="center"
                      >
                        <Grid
                          item
                          className={
                            activity.action_type === "comments"
                              ? "commentTiming"
                              : "activityTiming"
                          }
                        >
                          {time}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            )
        })) : (
          <Grid item container style={{ marginTop: "2rem" }}>
            <Grid item lg={12} style={{ textAlign: "center" }}>
              <Typography variant="body1">No Activities Found</Typography>
            </Grid>
          </Grid>
        )
      }
      </div>
    )
  }

  return (
    <>
      <Grid item sm={12}>
        <div className="mentionWrapper">
          <MentionsInput
            markup="@[__display__](__id__)"
            className="mentions"
            placeholder="Use @ to notify a team member"
            value={text}
            onChange={(e, newValue, newPlainTextValue, mentions) => {
              setText(e.target.value);
              setMentions(mentions);
            }}
          >
            <Mention className="mentions__mention" data={usersData} />
          </MentionsInput>
        </div>
        <Button
          variant="outlined"
          className={classes.checklistDetailBtn1}
          onClick={handleSaveComment}
          name="send"
        >
          Send
        </Button>
        <Button
          variant="outlined"
          className={classes.checklistDetailBtn2}
          onClick={() => {
            setText("");
          }}
        >
          Discard
        </Button>
      </Grid>
      <Grid item container>
        <Typography className={classes.checklistDetailAct}>
          Activities
        </Typography>
      </Grid>
      {displayActivity()}
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  selectedOrganization: state.organizationInfo.selectedOrganization,
  members: state.organizationInfo.members,
});

export default connect(mapStateToProps, null)(TaskActivities);
