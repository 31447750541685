import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {CircularProgress, Grid} from '@material-ui/core';
import {connect} from 'react-redux';
import { setSelectedDrawerState } from '../actions/selectedOrganization';
import {setOrgTemplates} from '../actions/selectedOrganization';
import BaseComponent from './Base/BaseComponent';
import { useIsMount } from './useIsMount';
import TemplateTasksAndWidget from './TemplateTasksAndWidget';
import CreateEditTemplate from './CreateEditTemplate';
import {getTemplateDetailsApi} from '../services/authService';
import {useParams} from 'react-router-dom';
import { consoleToLog } from '../util/AppUtil';
import { history } from '../routers/AppRouter';
import SnackbarComponent from "./SnackbarComponent";
import SendEmailDialog from './SendEmailDialog';

const useStyles = makeStyles((theme) => ({
    mainContent : {
        display:'flex',
        width: '100%',
        height:'100%',
        backgroundColor: theme.palette.primary.light, 
        overflow:'auto',
    },

}));

const CreateTemplatePage = (props) => {
    const {showDrawer} = props;
    const classes = useStyles(showDrawer);
    const isMount = useIsMount();
    let {slug} = useParams();
    consoleToLog('slug code from predefined template', slug);
    const fromPredefinedTemplate = history.location.state?.fromPredefinedTemplate;

    consoleToLog('is fromPredefinedTemplate', fromPredefinedTemplate);

    const [templateObj, setTemplateObj] = useState(undefined);
    const [editTemplate, setEditTemplate] = useState(undefined);
    const [predefinedTemplateObj, setPredefinedTemplateObj] = useState(undefined);
    const [editPredefinedTemplate, setEditPredefinedTemplate] = useState(undefined);
    const [loading, setLoading] = useState(false);

    const template_object = templateObj || predefinedTemplateObj;
    const edit_template = editTemplate || editPredefinedTemplate
    consoleToLog('predefined template object set', predefinedTemplateObj);

    const templateId = fromPredefinedTemplate ? predefinedTemplateObj?.predefined_template_id : templateObj?.template_id;
    const is_orgEmail_verified = props.selectedOrganization.organization?.is_email_verified;
    let filteredSupportEmail = props.orgEmailList && props.orgEmailList.length > 0 ? props.orgEmailList.filter(email => email.support) : [];
    const no_support_email = filteredSupportEmail && filteredSupportEmail.length === 0;
    const verifiedEmailArr = filteredSupportEmail && filteredSupportEmail.length > 0 ? filteredSupportEmail.filter((email) => !email.verified) : [];

    const [openSendEmailDialog, setOpenSendEmailDialog] = useState(false);

    useEffect(() => {
        document.title = 'Template'
    }, [])

    const setHeightOfComponent = () => {
        if(verifiedEmailArr && verifiedEmailArr.length > 0 && props.templateRuleErrorMessage !== null) {
            return 'calc(100vh - 132px)';
        }
        if(no_support_email) {
            return 'calc(100vh - 90px)';
        }
        if(verifiedEmailArr && verifiedEmailArr.length > 0) {
            return 'calc(100vh - 90px)';
        } 
        if(props.templateRuleErrorMessage) {
            return 'calc(100vh - 90px)';
        } 
    }

    const handleSendEmailDialogOpen = () => {
        setOpenSendEmailDialog(true);
    }

    const handleSendEmailDialogClose = () => {
        setOpenSendEmailDialog(false);
    }

    useEffect(() => {
        if(slug) {
            getTemplateDetails();
        }
    }, [slug]);


    const getTemplateDetails = () => {
        const organization_id = props.selectedOrganization.organization.id;
        const template_slug_code = slug;
        const templateString = fromPredefinedTemplate ? 'predefined_template' : 'template';

        setLoading(true);
        getTemplateDetailsApi(organization_id, templateString, template_slug_code)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response getTemplateDetailsApi', res);

                if(fromPredefinedTemplate) {
                    setEditPredefinedTemplate(res.predefined_template);
                } else {
                    setEditTemplate(res.template);
                }

                setLoading(false);
            })
            .catch((e) => {
                consoleToLog('Response getTemplateDetailsApi', e.response);
                setLoading(false);
                if (e.response.data && e.response.data.message) {
                    return false;
                }

            })

    }

    return (
        <BaseComponent isFromCreateTemplate={true} 
            handleSendEmailDialogOpen={handleSendEmailDialogOpen}>
            <div className={classes.mainContent} >
                { loading ? 
                    <CircularProgress size={35} style={{position:'relative', top:'25px', left: '50%'}}/>
                    :
                    <Grid item container flexDirection='column'>
                    {<SnackbarComponent  isFromCreateTemplate={true}
                        verifiedEmailArr={verifiedEmailArr && verifiedEmailArr.length > 0 ? verifiedEmailArr : []}
                        no_support_email={no_support_email}
                        isLoading={props.isLoading}/>}    
                        <Grid item xs={12} style={{height: setHeightOfComponent(),flexGrow: 1,
        //overflow:'hidden',
        overflow:'auto',
        overflowX:'hidden'}}>
                                {!template_object || edit_template ?
                                    <CreateEditTemplate setTemplateObj={setTemplateObj}
                                        editTemplate={editTemplate}
                                        setEditTemplate={setEditTemplate}
                                        loading={loading}
                                        fromPredefinedTemplate={fromPredefinedTemplate}
                                        setPredefinedTemplateObj={setPredefinedTemplateObj}
                                        editPredefinedTemplate={editPredefinedTemplate}
                                        setEditPredefinedTemplate={setEditPredefinedTemplate}
                                    />
                                    :
                                    <TemplateTasksAndWidget templateObj={templateObj}
                                        setEditTemplate={setEditTemplate}
                                        setTemplateObj={setTemplateObj}
                                        templateId={templateId}
                                        fromPredefinedTemplate={fromPredefinedTemplate}
                                        predefinedTemplateObj={predefinedTemplateObj}
                                        setPredefinedTemplateObj={setPredefinedTemplateObj}
                                        setEditPredefinedTemplate={setEditPredefinedTemplate}
                                        no_support_email={no_support_email}
                                        verifiedEmailArr={verifiedEmailArr && verifiedEmailArr.length > 0 ? verifiedEmailArr : []}
                                    />
                                }
                        </Grid>
                    </Grid>}       
            </div> 

            {openSendEmailDialog && <SendEmailDialog openSendEmailDialog={openSendEmailDialog}
                handleSendEmailDialogClose={handleSendEmailDialogClose}
            />}
        </BaseComponent>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    setDrawerState: state.organizationInfo.setDrawerState,
    templates: state.organizationInfo.templates,
    orgEmailList: state.organizationInfo.orgEmailList,
    isLoading: state.loading.isLoading,
    templateRuleErrorMessage: state.organizationInfo.templateRuleErrorMessage
});

const mapDispatchToProps = (dispatch) => ({
    setSelectedDrawerState: (drawerState) => dispatch(setSelectedDrawerState(drawerState)),
    setOrgTemplates: (templates) => dispatch(setOrgTemplates(templates))
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateTemplatePage);