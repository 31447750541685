import React from 'react';
import {Grid, Typography} from '@material-ui/core';
import {connect} from 'react-redux'; 
import {trackGAEvent} from '../../util/AppUtil';
import {addSelectedStatusToFilter, removeSelectedStatusFromFilter} from '../../actions/filters';

const ChecklistStatusFilters = (props) => {
    const statusNameList = ["Pending", "In Process", "Awaiting Reply", "Pending For Approval", "Completed"];
    const statusValueList = ["pending", "in_process", "awaiting_reply", "pending_approval", "completed"];
    const statusClassNames = ["pending__status", "in-process__status", "awaiting-reply__status", "pending-approval__status", "completed__status"];
    const statusClassNames1 = ["pending__background", "in-process__background", "awaiting-reply__background"," pending-approval__background", "completed__status__background"]
    
    const statusClassName = (index) => {
        const STATUS_CLASS = `status__filterList ${statusClassNames1[index]}`;
        return props.selectedStatusFilter.includes(statusValueList[index]) ? `${STATUS_CLASS} active` : STATUS_CLASS;
    }

    const onStatusClick = (selectedValue) => {
        const selectedStatus = props.selectedStatusFilter.filter((status) => status === selectedValue);
        trackGAEvent(props.selectedOrganization.organization.name, 'Status Selection Changed', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
        if(selectedStatus && selectedStatus.length > 0) {
            props.removeSelectedStatusFromFilter(selectedValue);
            props.isFromMobileComponent && props.pageReset();
        } else {
            props.addSelectedStatusToFilter(selectedValue);
            props.isFromMobileComponent && props.pageReset();
        }
    }

    return (
        <div style={{marginTop:'16px'}}>
            <span style={{fontSize:'14px'}}>
                Status            
            </span> 
            <Grid container lg={12} style={{marginTop:'8px'}} >
                {statusNameList.map((status, index) => {
                        return <Grid item
                                    className={statusClassName(index)}
                                    style={{display:'flex', alignItems:'center'}}
                                    key={status}
                                    onClick={() => onStatusClick(statusValueList[index])}
                                >
                            <span className={`circle ${statusClassNames[index]}`}></span> 
                            <Typography  style={{fontSize:'12px'}}>{status}</Typography>
                        </Grid>
                    })
                }
            </Grid> 
        </div>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedStatusFilter: state.filters.selectedStatusFilter,
    selectedOrganization: state.organizationInfo.selectedOrganization
});

const mapDispatchToProps = (dispatch) => ({
    addSelectedStatusToFilter: (item) => dispatch(addSelectedStatusToFilter(item)),
    removeSelectedStatusFromFilter: (item) => dispatch(removeSelectedStatusFromFilter(item))
});

export default connect(mapStateToProps, mapDispatchToProps)(ChecklistStatusFilters)