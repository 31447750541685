import {getAxios} from './axiosUtil';

//addInvoiceAccountApi
export const addInvoiceAccountApi = (access_token, organization_id, name, email, address, 
    state, country, tax_id, mobile, default_client_notes, default_payment_terms) => {
    let data = {
        tax_id,
        name,
        address,
        country,
        email,
        mobile,
        state,
        default_client_notes,
        default_payment_terms
    }

    getAxios().defaults.headers.common['Authorization'] = access_token;
    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/organization/${organization_id}/invoice_account`, data);
}

//editInvoiceAccountApi
export const editInvoiceAccountApi = (access_token, organization_id, name, email, 
    address, state, country, tax_id, mobile, id, default_client_notes, default_payment_terms) => {
    let data = {
        id,
        tax_id,
        name,
        address,
        country,
        email,
        mobile,
        state,
        default_client_notes,
        default_payment_terms
    }

    getAxios().defaults.headers.common['Authorization'] = access_token;
    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/organization/${organization_id}/invoice_account`, data);
}

export const deleteAccountApi = (access_token, organization_id, accId)=> {
    getAxios().defaults.headers.common['Authorization'] = access_token;
    return getAxios().delete(`${process.env.REACT_APP_BASE_URL}/organization/${organization_id}/invoice_account?id=${accId}`);
}

export const getInvoiceAccessorsApi= (access_token, organization_id)=> {
    getAxios().defaults.headers.common['Authorization'] = access_token;
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/organization/${organization_id}/invoice_accessors`);
}

//Add updateInvoiceAccessorsApi API
export const updateInvoiceAccessorsApi = (access_token, organization_id, accessors) => {
    let data = {accessors}
    getAxios().defaults.headers.common['Authorization'] = access_token;
    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/organization/${organization_id}/invoice_accessor`, data);

}

export const getInvoiceConfigApi= (organization_id, clientId)=> {
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/organization/${organization_id}/client/${clientId}/invoice_configs`);
}

// export const updateInvoiceAccountForClientApi=(access_token, organization_id, company_id, accId)=>{
//     getAxios().defaults.headers.common['Authorization'] = access_token;
//     return getAxios().put(`${process.env.REACT_APP_BASE_URL}/organization/${organization_id}/client/${company_id}/invoice_account/${accId}/update`);
// }

export const updateInvoiceAccountForClientApi=(client_id, old_invoice_acct_id, new_invoice_acct_id, client_organization_id)=>{
    const data = {
        old_invoice_acct_id,
        new_invoice_acct_id,
        client_organization_id };
    return getAxios().put(`${process.env.REACT_APP_CLIENTS_BASE_URL}/client/${client_id}/invoice_account/update`, data);
}

export const updateBilableForInvoiceConfigApi=(access_token, organization_id, company_id, accId, billabeStr)=>{
    getAxios().defaults.headers.common['Authorization'] = access_token;
    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/organization/${organization_id}/client/${company_id}/invoice_config/${accId}/${billabeStr}`);
}

export const updateCostAndPeriodicityForInvoiceConfigApi = (organization_id, company_id, id , cost, periodicity, billable, entity_id)=>{

    let data = {
        id,
        cost,
        periodicity,
        billable,
        entity_id
    }

    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/organization/${organization_id}/client/${company_id}/invoice_config`, data);
}

export const verifyInvoiceAccountEmailApi = (access_token, organization_id, invoice_account_id) => {
    getAxios().defaults.headers.common['Authorization'] = access_token;
    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/organization/${organization_id}/invoice_account/${invoice_account_id}/send_email_verification_link`);
}

export const updateAllAccountUnverifiedEmailStatusApi = () => {
    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/organization/update_all_account_unverified_email_status`);
}

export const getUnConnectInvoiceAccountsApi = (owner_ids_list) => {
    const param = {
        params: {
            owner_ids_list
        }
    }
    return getAxios().get(`${process.env.REACT_APP_INVOICE_APP_BASE_URL}/user/invoice_accounts/unconnected`, param);
}

export const connectedInvoiceAccountsApi = (organization_id, invoice_accounts) => {
    let data = {invoice_accounts}
    return getAxios().post(`${process.env.REACT_APP_INVOICE_APP_BASE_URL}/invoice_account/organization/${organization_id}/connect`, data);
}

export const getInvoiceAccountsEntityListApi = (invoice_account_id) => {
    return getAxios().get(`${process.env.REACT_APP_INVOICE_APP_BASE_URL}/invoice_account/${invoice_account_id}/entity_list`);
}

export const markAsDefaultApi = (invoice_account_id, entity_id) => {
    return getAxios().put(`${process.env.REACT_APP_INVOICE_APP_BASE_URL}/invoice_account/${invoice_account_id}/entity/${entity_id}/set_default`);
}

export const disconnectedInvoiceAccountsApi = (organization_id, invoice_accounts) => {
    let data = {invoice_accounts}
    return getAxios().post(`${process.env.REACT_APP_INVOICE_APP_BASE_URL}/invoice_account/organization/${organization_id}/disconnect`, data);
}