import React, {useState, useEffect, Fragment} from 'react';
import {TextField, Grid, Avatar, Chip, Tooltip} from '@material-ui/core'; 
import {connect} from 'react-redux';
import {consoleToLog, trackGAEvent} from '../../util/AppUtil';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ClearIcon from '@material-ui/icons/Clear';
import {addSelectedMemberToFilter, removeSelectedMemberFromFilter} from '../../actions/filters';
import {useIsMount} from '../useIsMount';
import {addActivityReportMemberToFilter, removeActivityReportMemberFromFilter} from '../../actions/activityReports';

const useStyles = makeStyles((theme) => ({
    avatar : {
        width: '15px !important',
        height: '15px !important',
        fontSize: '12px !important',
        background: 'none !important',
        color: 'rgba(0, 0, 0, 0.87) !important',
        border: '1px solid rgba(0, 0, 0, 0.26) !important'
        },

    chip : {
        borderRadius: '4px !important',
        backgroundColor: '#f9f9f9 !important',
        border: '1px solid #ececec !important',
        },

    avatarStyle: {
        width: 20,
        height: 20,
        fontSize: 12
        },
        autoStyle: {
        "&::-webkit-scrollbar": {
            display: "none"
        }
            
    }
    }
));

const ChecklistMembersFilter = (props) => {
    const classes = useStyles();
    const [searchMemberFilterLocal, setSearchMemberFilterLocal] = useState([]);
    const isMount = useIsMount();


    useEffect(() => {
        if(!isMount) {
            //consoleToLog("Autocomplete Membera- on member change: in useeffect", searchMemberFilterLocal);
            props.fromSendForReviewModal && props.setApprovalList(searchMemberFilterLocal);
        }
    }, [searchMemberFilterLocal]);

    useEffect(() => {
        if(!isMount) {
            consoleToLog("Autocomplete Members- On Clear click: in useeffect");
            props.clearFilter && setSearchMemberFilterLocal([]);
        }
    }, [props.clearFilter]);


    const onMemberClick = (option) => {
        trackGAEvent(props.selectedOrganization.organization.name, 'Members Added', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
        props.isFromActivityReport &&  props.addActivityReportMemberToFilter(option); 
        props.isChecklistFilter && props.addSelectedMemberToFilter(option);
    }

    const onDeleteMember = (option) => {
        trackGAEvent(props.selectedOrganization.organization.name, 'Members Removed', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
        setSearchMemberFilterLocal(searchMemberFilterLocal.filter(entry => entry !== option));
        props.isFromActivityReport &&  props.onUpdateClearFilterValue();
        props.isFromActivityReport && props.removeActivityReportMemberFromFilter(option);  
        props.isChecklistFilter && props.removeSelectedMemberFromFilter(option);
        props.isFromMobileComponent && props.pageReset();
    }

    const renderOptions = (option) => {
        return (
            <div style={{display: "flex", width: "100%"}} 
                className={classes.autoStyle} 
                onClick={() => {
                    onMemberClick(option);
                    props.isFromMobileComponent && props.pageReset();
                }}
            >
                <Avatar style={{marginRight: "6px", marginTop: "4px"}}
                    classes={{
                        root: classes.avatar
                    }}
                >
                    {option.user.firstname.charAt(0)}
                </Avatar>
                {option.user.firstname + ' ' + option.user.lastname}
            </div>
            );
    }
    
    const renderTags = (options) => {
        return (
            options.map(option =>
                <Fragment>
                <Tooltip title={option.user.firstname + ' ' + option.user.lastname} arrow>
                    <Chip
                        classes={{
                            root: classes.chip
                        }}
                        avatar={<Avatar classes={{
                            root: classes.avatar
                        }}>{option.user.firstname.charAt(0)}</Avatar>}
                        label={option.user.firstname + ' ' + option.user.lastname}
                        onDelete={() => onDeleteMember(option)}
                        variant="outlined"
                        style={{margin: "2px 2px", overflow:"hidden"}}
                        deleteIcon={<ClearIcon  style={{width: "18px"}}/>}
                    />
                </Tooltip>
                </Fragment>))
        }

    return (
        <div style={{marginTop: props.isChecklistFilter ? '-8px' : "6px"}}>
            <span style={{fontSize:'14px', color: props.showUnassignedChecklist? "#ccc": "black"}}>
                {(!props.isFromActivityReport || !props.fromSendForReviewModal && "Members")}  
            </span>
            <Grid item style={{marginTop:'8px'}}>
                <Autocomplete
                    multiple
                    style={{ width: '100%'}}
                    id="size-small-outlined-multi"
                    size="small"
                    options={props.members}
                    getOptionLabel={(option) => option.user.firstname + ' ' + option.user.lastname}
                    disableClearable
                    renderOption={(option) => renderOptions(option)}
                    value={props.isFromActivityReport && props.selectedActivityReportMembersFilter || props.isChecklistFilter && props.selectedMembersFilter || props.fromSendForReviewModal && searchMemberFilterLocal}
                    onChange={(e, newValue) => {
                            props.isFromActivityReport &&  props.onUpdateClearFilterValue();
                            setSearchMemberFilterLocal(newValue);
                    }}
                    renderTags={(options) => renderTags(options)}
                    renderInput={(params) => (
                        <TextField {...params} variant="outlined" placeholder={props.fromSendForReviewModal ? "Search Approver" :"Search Member"} />
                    )}
                    disabled={props.showUnassignedChecklist}
                />
            </Grid>    
        </div>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    members: state.organizationInfo.members,
    selectedMembersFilter: state.filters.selectedMembersFilter,
    selectedActivityReportMembersFilter: state.activityReports.selectedActivityReportMembersFilter
});

const mapDispatchToProps = (dispatch) => ({
    addSelectedMemberToFilter: (item) => dispatch(addSelectedMemberToFilter(item)),
    removeSelectedMemberFromFilter: (item) => dispatch(removeSelectedMemberFromFilter(item)),
    addActivityReportMemberToFilter: (item) => dispatch(addActivityReportMemberToFilter(item)),
    removeActivityReportMemberFromFilter: (item) => dispatch(removeActivityReportMemberFromFilter(item))
});

export default connect(mapStateToProps, mapDispatchToProps)(ChecklistMembersFilter);