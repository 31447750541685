import Header from "../Header";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    //apply full 100% width to page
    root: {
        display: "flex",
        flexFlow: "column",
        height: "100vh"
    },
    //stretch page to full width of screen below header
    child: {
        flex: "1 1 auto",
      }
  }));

const BaseComponent = ({children, drawerAction, isFromWaitingFromMyApproval, 
    isFromCreateTemplate, notFoundPage, verifyEmailPage, handleSendEmailDialogOpen,
    handleChecklistModalOpen, handleFolderModalOpen, toggleDrawer}) => {
    
    const classes = useStyles();
    return(
        <div className={classes.root}>
            <Header drawerAction={drawerAction} 
                isFromWaitingFromMyApproval={isFromWaitingFromMyApproval}
                isFromCreateTemplate={isFromCreateTemplate}
                notFoundPage={notFoundPage}
                verifyEmailPage={verifyEmailPage}
                handleSendEmailDialogOpen={handleSendEmailDialogOpen}
                handleChecklistModalOpen={handleChecklistModalOpen}
                handleFolderModalOpen={handleFolderModalOpen}
                toggleDrawer={toggleDrawer}
                />
            <div className={classes.child}>{children}</div>
        </div>
    )
}

export default BaseComponent;

