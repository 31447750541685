import React, {useState} from 'react';
import {Grid, Button, Popover} from '@material-ui/core';
import { DateRangePicker} from "materialui-daterange-picker";
import moment from 'moment';
import {connect} from 'react-redux';
import {setStartDateFilter, setEndDateFilter} from '../../actions/filters';
import { trackGAEvent , consoleToLog } from '../../util/AppUtil';

const DurationFilterComponent = (props) => {
    const [anchorEl, setAnchorEl] = useState(false);
    const [dateRange, setDateRange] = useState({});

    //Last Month
    var prevMonthFirstDay = new moment().subtract(1, 'months').date(1)
    
    //for next month last day
    var thisMonthLastDay = new moment().add(1, 'months').date(0);
    
    //for last day of previous month
    var lastdayLastMonth = moment(lastdayLastMonth).subtract(1,'months').endOf('month').format('YYYY-MM-DD');

    let currentFinancialYearStart;
    let currentFinancialYearEnd;

    currentFinancialYearStart = (moment().month() < 3) ? moment().subtract(1, 'year').month('April').startOf('month').format('YYYY-MM-DD')
    : moment().month('April').startOf('month').format('YYYY-MM-DD');

    currentFinancialYearEnd =  (moment().month() < 3) ? moment().month('March').endOf('month').format('YYYY-MM-DD')
    : moment().add(1, 'year').month('March').endOf('month').format('YYYY-MM-DD');
    
    let lastFinancialYearStart = moment(currentFinancialYearStart).subtract(1, 'year').format('YYYY-MM-DD');
    let lastFinancialYearEnd = moment(currentFinancialYearEnd).subtract(1, 'year').format('YYYY-MM-DD');

    let lastFinancialYearStart1 = moment(currentFinancialYearStart).subtract(12, 'year').format('YYYY-MM-DD');
    let lastFinancialYearEnd1 = moment(currentFinancialYearEnd).subtract(1, 'year').format('YYYY-MM-DD');

    //console.log("lastFinancialYearStart:", lastFinancialYearStart, moment(lastFinancialYearStart, 'YYYY-MM-DD').format('MMM-YYYY'));
    //console.log("lastFinancialYearEnd:", lastFinancialYearEnd, moment(lastFinancialYearEnd, 'YYYY-MM-DD').format('MMM-YYYY'));
    //console.log("currentFinancialYearStart:", currentFinancialYearStart, moment(currentFinancialYearStart, 'YYYY-MM-DD').format('MMM-YYYY'));
    //console.log("currentFinancialYearEnd:", currentFinancialYearEnd, moment(currentFinancialYearEnd, 'YYYY-MM-DD').format('MMM-YYYY'));
    

    const openDateRangePicker = (event) => {
        consoleToLog("123");
        setAnchorEl(event.currentTarget);
    };

    const open = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <span style={{fontSize:'14px'}}>
                Duration
            </span>
            <Grid item container lg={12} style={{marginTop:'3px'}}>
                <Grid item lg={8}>
                    <Grid item container alignItems='center'>
                        <Button variant='outlined' 
                            color='primary' 
                            style={{fontWeight:"300 !important", border: !props.startDateFilter && !props.endDateFilter && '2px solid #0076ff', borderTopRightRadius:'0px', borderBottomRightRadius:'0px'}}
                            onClick={() => {
                                props.setStartDateFilter(undefined);
                                props.setEndDateFilter(undefined);
                            }}
                            >
                            {/* {`${moment(props.startDateFilter).format('DD-MM-YYYY')} - 
                                    ${moment(props.endDateFilter).format('DD-MM-YYYY')}`} */}
                                All
                        </Button>
                        <Button variant='outlined' 
                            color='primary' 
                            className={(props.startDateFilter && props.endDateFilter) && 'border_style'}
                            style={{fontWeight:"300 !important", border: props.startDateFilter && props.endDateFilter && '2px solid #0076ff', borderTopLeftRadius:'0px', borderBottomLeftRadius:'0px'}}
                            onClick={openDateRangePicker}>
                            {(props.startDateFilter && props.startDateFilter ) ? `${moment(props.startDateFilter).format('DD-MM-YYYY')} - 
                                    ${moment(props.endDateFilter).format('DD-MM-YYYY')}` : 'Select Duration'}
                        </Button>
                        <Popover
                            id='daterange-popover'
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center"
                            }}
                            transformOrigin={{
                            vertical: "top",
                            horizontal: "center"
                            }}
                        >
                            <DateRangePicker
                                open={true}
                                toggle={handleClose}
                                onChange={(range) => {
                                consoleToLog("range", range, dateRange);
                                    setDateRange(range);
                                    props.setStartDateFilter(moment(range.startDate).format('YYYY-MM-DD'));
                                    props.setEndDateFilter(moment(range.endDate).format('YYYY-MM-DD'));
                                    trackGAEvent(props.selectedOrganization.organization.name, 'Date Range Changed', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
                                }}
                                style={{
                                width:'50%',
                                pointerEvents: dateRange?.startDate === lastFinancialYearStart1 && 'none'
                                }}
                                
                                initialDateRange={{
                                    startDate:  props.startDateFilter,
                                    endDate: props.endDateFilter
                                }}
                                definedRanges={[
                                {
                                    label: `${moment(lastFinancialYearStart, 'YYYY-MM-DD').format('MMM-YYYY')}-${moment(lastFinancialYearEnd, 'YYYY-MM-DD').format('MMM-YYYY')}`,
                                    startDate: lastFinancialYearStart,
                                    endDate: lastFinancialYearEnd
                                },
                                {
                                    label: `${moment(currentFinancialYearStart, 'YYYY-MM-DD').format('MMM-YYYY')}-${moment(currentFinancialYearEnd, 'YYYY-MM-DD').format('MMM-YYYY')}`,
                                    startDate: currentFinancialYearStart,
                                    endDate: currentFinancialYearEnd
                                },
                                {
                                    label: 'Last Month',
                                    startDate: prevMonthFirstDay,
                                    endDate: lastdayLastMonth
                                },
                                {
                                    label: 'This Month',
                                    startDate: new Date(),
                                    endDate: thisMonthLastDay
                                }
                                ]}
                            />
                        </Popover>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    startDateFilter: state.filters.startDateFilter,
    endDateFilter: state.filters.endDateFilter,
    selectedOrganization: state.organizationInfo.selectedOrganization,
});

const mapDispatchToProps = (dispatch) => ({
    setStartDateFilter: (start_date) => dispatch(setStartDateFilter(start_date)),
    setEndDateFilter: (end_date) => dispatch(setEndDateFilter(end_date))
})

export default connect(mapStateToProps, mapDispatchToProps)(DurationFilterComponent);