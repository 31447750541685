import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import List from "@material-ui/core/List";
import TaskListItem from "./TaskListItem";
//import TaskDetails from "./TaskDetails";
import { getWidgetsApi, getCommentsApi } from "../../services/authService";
import { connect } from "react-redux";
import { consoleToLog } from "../../util/AppUtil";
import { Typography } from "@material-ui/core";
//import { useIsMount } from "../useIsMount";
import {useSnackbar} from 'notistack';
import {activityActionTypes} from "../../util/AppUtil";
import UpdateTaskTitleDrawer from "./UpdateTaskTitleDrawer";
import { deleteChecklistTaskApi } from "../../services/ChecklistService";
import { useIsMount } from "../useIsMount";
import UpdateTaskWidgetsDrawer from './UpdateTaskWidgetsDrawer';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import {changeChecklistTaskOrderApi} from '../../services/ChecklistService';
import TaskApprovalModal from "./TaskApprovalModal";

const TasksContent = (props) => {
  const [selectedTask, setSelectedTask] = useState(undefined);
  const [taskList, setTaskList] = useState(props.taskList);
  const [widgetsLoading, setWidgetsLoading] = useState(false);
  const [taskObj, setTaskObj] = useState(undefined);
  const [taskId, setTaskId] = useState(undefined);
  const isMount = useIsMount();

  //const [openDetailFromParent, setOpenDetailFromParent] = useState(false);
  //const isMount = useIsMount();

  const [openTitleUpdateDrawer, setOpenTitleUpdateDrawer] = useState(false);
  const [openUpdateTaskWidgetDrawer, setOpenUpdateTaskWidgetDrawer] = useState(false);
  const [widgetObj, setWidgetObj] = useState(undefined);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [scrollToTask, setScrollTask] = useState(false);
  const [allowUpdateTask, setAllowUpdateTask] = useState(false);
  const [updateTask, setUpdateTask] = useState(false);
  const [openSendForReviewDialog, setOpenSendForReviewDialog] = useState(false);
  const [openApprovalsModal, setOpenApprovalsModal] = useState(false);
    
  const {enqueueSnackbar} = useSnackbar();

  useEffect(() => {
    if(!isMount) {
      if(props.updateTaskFromParent) {
        setTaskList(props.taskList);
        props.setUpdateTaskFromParent(false);
      }

    }
  }, [props.updateTaskFromParent]);


  useEffect(() => {
    if(props.activityObj) {
      performActivityRelatedTask();
    } else {
      // setFirstTaskAsSelectedTask();
    }
  }, []);

  useEffect(() => {
    if(!isMount) 
      if(props.addTaskClicked) {
        setOpenTitleUpdateDrawer(true);
      }
  }, [props.addTaskClicked]);

  useEffect(() => {
    if(!isMount) 
      if(allowUpdateTask) {
        setAllowUpdateTask(false);
        setUpdateTask(true);
      }
  }, [allowUpdateTask]);


  // const setFirstTaskAsSelectedTask = () => {
  //   var firstTask = props.taskList.find((task) => !task.heading);
  //   if(selectedTask === undefined && taskList[0]){
  //     if (!firstTask) {
  //       firstTask = taskList[0];
  //     }
  //    getWidgetsApiCall(firstTask.id);
  //   }
  // }

  const performActivityRelatedTask = () => {
    const actionType = props.activityObj.action_type;

    switch (actionType) {
        case activityActionTypes.CHECKLIST:
            // setFirstTaskAsSelectedTask();
            break;
        case activityActionTypes.TASK:
            setOtherTaskAsSelectedTask();
            //props.clearActivityObj();
            break;
        case activityActionTypes.COMMENTS:
            setOtherTaskAsSelectedTask();
            //activity object cleared in TaskDetails
            break;
        case activityActionTypes.ATTACHMENTS:
          const activity_type = props.activityObj.activity_type;
           if (activity_type === 'upload_widget_attachment') {
              setOtherTaskAsSelectedTask();
              //props.clearActivityObj();
          }
          break;    
        default:
            // setFirstTaskAsSelectedTask();
    }
  }  

  const setOtherTaskAsSelectedTask = () => {//when user came from activity click
    const task_id = props.activityObj.task_id;
    if (!task_id) {
        enqueueSnackbar('Task not found!', {
          variant:'warning'
        });
        // setFirstTaskAsSelectedTask();
    }
    else{
      const taskObj = props.checkList.checklist_task.find((task) => task.id === task_id);
      if (!taskObj) {
          enqueueSnackbar('Task not found!', {
            variant:'warning'
          });
          // setFirstTaskAsSelectedTask();
      }else{
        if(props.activityObj?.action_type === 'comments') {
          updateDetailsView(taskObj);
          //console.log('selectedTask', taskObj)
          onTaskItemClicked(taskObj);
        } else {
          setSelectedTask(taskObj);
          getWidgetsApiCall(taskObj, false);
        }
      }
   }
  }  

  const getWidgetsApiCall = (task11, fromtaskTitleDrawer) => {
    if(!fromtaskTitleDrawer) setWidgetsLoading(true);
    if(fromtaskTitleDrawer && task11.checklist_task_widget && task11.checklist_task_widget.length > 0) {
      setTaskObj(task11);
      setOpenTitleUpdateDrawer(true);
    } else {
 
    getWidgetsApi(props.checklistId, task11.id)
      .then((response) => {
        const res = response.data;
        consoleToLog("getWidgetApi response", res);
        /* var localTaskArray = taskList.map((task) => {
          if (task.id === task11.id) {
            task11.checklist_task_widget = res.checklist_task_widget;
            return task11;
          } else return task;
        }); */
        //const index = localTaskArray.findIndex((x) => x.id === task11.id);
        //setTaskList(localTaskArray);
        //setSelectedTask(localTaskArray[index]);
        if(fromtaskTitleDrawer && !task11.checklist_task_widget) {
          const obj = {
            ...task11,
            checklist_task_widget: res.checklist_task_widget
          }
          setTaskObj(obj);
          setOpenTitleUpdateDrawer(true);
        } else {
          getCommentsApiCall(task11, res.checklist_task_widget);
        }
        //setWidgetsLoading(false);
      })
      .catch((error) => {
        consoleToLog("getTaskList error", error);
        setWidgetsLoading(false);
      });
    }
  };

  const getCommentsApiCall = (task11, widgets) => {
    const organization_id = props.selectedOrganization.organization.id;
    const task_id = task11.id;

    getCommentsApi(task_id, organization_id)
      .then((response) => {
        const res = response.data;
        consoleToLog("getCommentsApi res ", res);
        var localTaskActivityArray = taskList.map((task) => {
          if (task.id === task11.id) {
            task11.checklist_task_widget = widgets;
            task11.activity_feed = res.activity_feed;
            return task11;
          } else return task;
        });
        //const index = localTaskActivityArray.findIndex((x) => x.id === task11.id);
        setTaskList(localTaskActivityArray);
        //setSelectedTask(localTaskActivityArray[index]);
        setWidgetsLoading(false);
      })
      .catch((e) => {
        consoleToLog("getCommentsApi error", e);
        setWidgetsLoading(false);
      });
  };

  // Checklist task widget
  const onWidgetUpdated = (updatedSelectedTask) => {
    consoleToLog("onWidgetUpdated in Main :updatedSelectedTask",updatedSelectedTask);
    var localTaskUploadArray = taskList.map((task) => {
      if (task.id === updatedSelectedTask.id) {
        task.checklist_task_widget = updatedSelectedTask.widgetResponse;
        return task;
      } else {
        return task;
      }
    });
    selectedTask.checklist_task_widget = updatedSelectedTask.widgetResponse;
    setSelectedTask(selectedTask);
    setTaskList(localTaskUploadArray);
  };

  const updateTaskAttachmentArray = (taskObj) => {
    var localTaskUploadArray = taskList.map((task) => {
      if (task.id === taskObj.id) {
        task.task_attachments = taskObj.task_attachments;
        task.task_attachments_count = taskObj.task_attachments_count;
        task.is_completable = taskObj.is_completable;
        task.status = taskObj.status;
        return task;
      } 
      return task;
    });
    setTaskList(localTaskUploadArray);
    props.onAttachmentUpdated(localTaskUploadArray);

  };

  const onCommentsEdited = (activity_feed, sel_task) => {
    if(sel_task.activity_feed && sel_task.activity_feed.length > 0){
      const updatedActivityFeed = sel_task.activity_feed.map((activityObj) => {
        return (activityObj.id === activity_feed.id) ? activity_feed : activityObj;
      });

      sel_task.activity_feed = updatedActivityFeed;
    }
    else{
      sel_task.activity_feed = [activity_feed];
    }

    var localTaskStatusArray = taskList.map((task) => {
      if (task.id === sel_task.id) {
        return sel_task;
      } else {
        return task;
      }
    });
      
    setSelectedTask(sel_task);
    setTaskList(localTaskStatusArray);
    props.onTaskCommentUpdated(activity_feed.checklist);
  };


  // Checklist comments updated
  const onCommentsUpdated = (activity_feed, comment_count, sel_task, checklistObj) => {

    sel_task.comment_count = comment_count;
    if(sel_task.activity_feed && sel_task.activity_feed.length > 0){
      sel_task.activity_feed = [activity_feed, ...sel_task.activity_feed];
    }
    else{
      sel_task.activity_feed = [activity_feed];
    }

    var localTaskStatusArray = taskList.map((task) => {
      if (task.id === sel_task.id) {
        return sel_task;
      } else {
        return task;
      }
    });
      
    setSelectedTask(sel_task);
    setTaskList(localTaskStatusArray);
    props.onNoteAdded(activity_feed?.checklist?.notes);
  };

  const onCommentsdeleted = (activityFeeds, checklistObj) => {
    selectedTask.activity_feed = activityFeeds;
    

    var localTaskStatusArray = taskList.map((task) => {
      if (task.id === selectedTask.id) {
        return selectedTask;
      } else {
        return task;
      }
    });
      
    setSelectedTask(selectedTask);
    setTaskList(localTaskStatusArray);
    props.onNoteDeleted(checklistObj?.notes);
  }

  // Checklist task Assignee
  const onTaskAssigneesChanged = (taskAssignee, checklist_task_id) => {
    var localTaskMemberArray = taskList.map((task) => {
      if (task.id === checklist_task_id) {
        task.checklist_task_members_list = taskAssignee;
        return task;
      } else {
        return task;
      }
    });
    setSelectedTask(selectedTask);
    setTaskList(localTaskMemberArray);
  };

  const onCheckListStatusCompleted = (status, toggle) => {
    props.onCheckListStatusCompleted(status, toggle);
  };

  const onTaskItemClicked = (task) => {
    if (task !== undefined && !task.checklist_task_widget) {
      getWidgetsApiCall(task, false);
      setSelectedTask(task);
    } else {
      setSelectedTask(task);
    }
      // if (task !== undefined && !task.activity_feed) {
    //   getCommentsApiCall(selectedId);
    // } else {
    //   setSelectedTask(task);
    // }
  };

  const onWidgetAttachmentAdded = (attachmentObj) => {
    // to parent 
    props.onWidgetAttachmentAdded(attachmentObj);
  }

  const onTaskStatusClick = (selectedTask) =>{
    setSelectedTask(selectedTask)
  }

  // Checklist task status
  const onTaskStatusChanged = (taskStatus,  activityObj, selTask) => {
    selTask.status = taskStatus;
    if(selTask.activity_feed && selTask.activity_feed.length > 0){
      selTask.activity_feed = [activityObj, ...selTask.activity_feed];
    }
    else{
      selTask.activity_feed = [activityObj];
    }

    var localTaskStatusArray = taskList.map((task) => {
      if (task.id === selTask.id) {
        return selTask;
      } else {
        return task;
      }
    });
    setSelectedTask(selTask);
    setTaskList(activityObj?.checklist?.checklist_task);

    // to parent
    props.onTaskStatusChanged(activityObj);
  };

  const updateDetailsView = (task11) =>{
    if (task11 !== undefined && !task11.checklist_task_widget) {
      getWidgetsApiCall(task11, false);
    }/*  else {
      setSelectedTask(task11);
    } */
    else{
      var localDetailsViewArray = taskList.map((task)=>{
        /* if (task.id !== selectedId) {
          task.detailsView = undefined;
          return task;
        } else {
          if(task.detailsView === undefined){
            task.detailsView = selectedId;
          }else{
            task.detailsView = undefined;
          }
          return task;
        } */
        if(task.id === task11.id) return task11;
        else{
          task.detailsView = undefined;
          return task;
        }
      })
      //setSelectedTask(selectedTask);
      setTaskList(localDetailsViewArray);
    }
  }

  const handleOpenTitleUpdateDrawer = (e, task) => {
    e.stopPropagation();
    getWidgetsApiCall(task, true);
  }

  const handleCloseTitleUpdateDrawer = () => {
    setOpenTitleUpdateDrawer(false);
    taskObj && setTaskObj(undefined);
  }

  const onDeleteTaskClick = (e, task) => {
    e.stopPropagation();
    setTaskId(task.id);

    if(window.confirm('Are you sure you want to delete this task?')) {

      setDeleteLoading(true);
      const organization_id = props.selectedOrganization.organization.id;
      const checklist_id = props.checklistId;
      const id = task.id;

      deleteChecklistTaskApi(organization_id, checklist_id, id)
        .then((response) => {
          const res = response.data;
          enqueueSnackbar(res.message, {variant:'success'});
          setDeleteLoading(false);
          setTaskId(undefined);

          consoleToLog('Response deleteChecklistTaskApi', res);
          updateChecklistTasks(res.checklist_tasks, true);
        })
        .catch((e) => {
          consoleToLog('Error deleteChecklistTaskApi', e);
          setDeleteLoading(false);
          setTaskId(undefined);
        })
    }

  }

  const updateChecklistTasks = (taskList, allowUpdateTask) => {
    setTaskList(taskList);
    props.checklistTaskUpdate(taskList);
    widgetObj && setWidgetObj(undefined);
    if(allowUpdateTask) setAllowUpdateTask(true);
  }

  const handleOpenUpdateTaskWidgetDrawer = (e, task, widget) => {
    e.stopPropagation();
    setOpenUpdateTaskWidgetDrawer(true);
    setWidgetObj(widget);
    setTaskObj(task);
  }

  const handleCloseUpdateTaskWidgetDrawer = () => {
    setOpenUpdateTaskWidgetDrawer(false);
  }

  const callChangeOrderApi = (orderNumber, drag_taskId) => {
    const organization_id = props.selectedOrganization.organization.id;
    const checklist_id = props.checkList?.id;
    const task_id = drag_taskId;
    const order_number = orderNumber + 1;

    changeChecklistTaskOrderApi(organization_id, checklist_id, task_id, order_number)
        .then((response) => {
            const res = response.data;
            consoleToLog("Response changeChecklistTaskOrderApi", res);

            enqueueSnackbar(res.message, ({
                variant: 'success'
            }));

            updateChecklistTasks(res.checklist_tasks, true);

        })
        .catch((e) => {
            consoleToLog('Error changeChecklistTaskOrderApi: ', e.response);
            if (e.response.data && e.response.data.message) {
                enqueueSnackbar(e.response.data.message, ({
                    variant: 'error'
                }));
                return false;
            }
        })

  }

  const reorder = (list, startIndex, endIndex) => {
    const result = list;
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
  };

const onEnd = (result) => {
    let orderNumber = result.destination.index;
    let task_id = result.draggableId;

    callChangeOrderApi(orderNumber, task_id);

    // dropped outside the list
    if (!result.destination) {
        return;
      }
  
      const items = reorder(
        taskList,
        result.source.index,
        result.destination.index
      );
  
      setTaskList(items);
    
}

const onEditDeleteWidget = (updatedSelectedTask) => {
  //consoleToLog("onWidgetUpdated in Main :updatedSelectedTask",updatedSelectedTask);
  var localTaskUploadArray = taskList.map((task) => {
    if (task.id === updatedSelectedTask.id) {
      task.checklist_task_widget = updatedSelectedTask.checklist_task_widget;
      return task;
    } 
    return task;
  });
  // selectedTask.checklist_task_widget = updatedSelectedTask.checklist_task_widget;
  // setSelectedTask(selectedTask);
  setTaskList(localTaskUploadArray);
};


  const updateSelectedTask = (obj) => {
    const updateTaskArray = taskList.map((task) => {
        if(task.id === obj.id) {
          task = obj;
        }
        return task;
    });
    setTaskList(updateTaskArray);
    props.checklistTaskUpdate(updateTaskArray);
  }

  const handleOpenApprovalsModal = (task) => {
    setOpenApprovalsModal(true);
    props.setTaskObj(task);
  }

  const handleCloseApprovalsModal = () => {
    setOpenApprovalsModal(false);
    props.setTaskObj(undefined);
  }

  return (
    <>
      <Grid item sm={12}>
        {taskList && taskList.length === 0 && (
          <Grid item container>
            <Grid item lg={12} style={{ textAlign: "center" }}>
              <Typography variant="body1">No Tasks Found</Typography>
            </Grid>
          </Grid>
        )}
        {/*Left container*/}
        <Grid item direction="column" sm={12} className="detailsTaskLeft">
          <Grid item direction="column" sm={12} className="listContainer">
              <DragDropContext onDragEnd={onEnd}>  
                <Droppable droppableId="droppable" direction="vertical">
                {(provided) => (
                  <div {...provided.droppableProps}
                    ref={provided.innerRef} style={{width:'100%'}}>
                    {taskList && taskList.length >0 && taskList.map((singleTask, index) => {
                      return (
                          <Draggable key={singleTask.id} 
                            draggableId={singleTask.id.toString()} 
                            index={index}> 
                              {(provided) => (
                                  <div  
                                    {...provided.draggableProps}
                                    // {...provided.dragHandleProps}
                                    ref={provided.innerRef}
                                    id={index+"_"+singleTask.title}>
                                      <TaskListItem
                                        key={singleTask.id}
                                        singleTask={singleTask}
                                        // onTaskItemClicked={(taskId) => {
                                        //   onTaskItemClicked(taskId, singleTask);
                                        // }}
                                        onTaskAssigneesChanged={onTaskAssigneesChanged}
                                        //selectedTask={selectedTask}
                                        onTaskStatusClick={onTaskStatusClick}
                                        onTaskStatusChanged={onTaskStatusChanged}
                                        widgetsLoading={widgetsLoading}
                                        onWidgetUpdated={onWidgetUpdated}
                                        onWidgetAttachmentAdded = {onWidgetAttachmentAdded}
                                        updateDetailsView={updateDetailsView}
                                        onCommentsUpdated={onCommentsUpdated}
                                        onCommentsEdited={onCommentsEdited}
                                        onCommentsdeleted={onCommentsdeleted}
                                        onCheckListStatusCompleted={onCheckListStatusCompleted}
                                        checklistId={props.checklistId}
                                        actionType={props.activityObj?.action_type}
                                        checklistItem={props.checkList}
                                        updateTaskAttachmentArray={updateTaskAttachmentArray}
                                        editChecklist={props.editChecklist}
                                        handleOpenTitleUpdateDrawer={handleOpenTitleUpdateDrawer}
                                        onDeleteTaskClick={onDeleteTaskClick}
                                        updateChecklistTasks={updateChecklistTasks}
                                        handleOpenUpdateTaskWidgetDrawer={handleOpenUpdateTaskWidgetDrawer}
                                        provided={provided}
                                        deleteLoading={deleteLoading}
                                        taskId={taskId}
                                        scrollToTask={scrollToTask}
                                        setScrollTask={setScrollTask}
                                        updateTask={updateTask}
                                        setUpdateTask={setUpdateTask}
                                        onEditDeleteWidget={onEditDeleteWidget}
                                        handleOpenSendForReviewDialog={props.handleOpenSendForReviewDialog}
                                        handleOpenApprovalsModal={handleOpenApprovalsModal}
                                        isDueDateOverDue={props.isDueDateOverDue}
                                        updateSelectedTask={updateSelectedTask}
                                        setTaskObj={props.setTaskObj}
                                        value={props.value}
                                      />
                                  </div>
                              )}
                          </Draggable>
                      );
                    })
                    }
                    {provided.placeholder}
                    </div>
                  )}
                  </Droppable>
              </DragDropContext>
          </Grid>
        </Grid>
        {/*Right container*/}
         {/* 
        <Grid item container sm={8} className="detailsTaskRight">
          {selectedTask !== undefined && (
            <TaskDetails
            selectTaskDetail={selectedTask}
            taskList={taskList}
            onWidgetUpdated={onWidgetUpdated}
            onTaskStatusChanged={onTaskStatusChanged}
            onCommentsUpdated={onCommentsUpdated}
            onCommentsEdited={onCommentsEdited}
            onCommentsdeleted={onCommentsdeleted}
            widgetsLoading={widgetsLoading}
            checklistId={props.checklistId}
            onCheckListStatusCompleted={onCheckListStatusCompleted}
            //onTaskStatusCompleted={onTaskStatusCompleted}
            activityObj={props.activityObj}
            // clearActivityObj={props.clearActivityObj}
            //onWidgetAttachmentAdded = {onWidgetAttachmentAdded}
            />
          )}
        </Grid>
        */} 

        
      </Grid>
      
      {props.editChecklist &&
      <UpdateTaskTitleDrawer fromChecklistDetails={true} 
        openTitleUpdateDrawer={openTitleUpdateDrawer}
        handleCloseTitleUpdateDrawer={handleCloseTitleUpdateDrawer}
        taskObj={taskObj}
        setAddTaskClicked={props.setAddTaskClicked}
        checklistId={props.checklistId}
        updateChecklistTasks={updateChecklistTasks}
        addTaskClicked={props.addTaskClicked}
        setScrollTask={setScrollTask}
        />}

      {props.editChecklist &&
      <UpdateTaskWidgetsDrawer fromChecklistDetails={true} 
        openUpdateTaskWidgetDrawer={openUpdateTaskWidgetDrawer}
        handleCloseUpdateTaskWidgetDrawer={handleCloseUpdateTaskWidgetDrawer}
        taskObj={taskObj}
        checklistId={props.checklistId}
        updateChecklistTasks={updateChecklistTasks}
        widgetObj={widgetObj}
        checklistItem={props.checkList}
        onEditDeleteWidget={onEditDeleteWidget}/>}

      <TaskApprovalModal openApprovalsModal={openApprovalsModal}
        handleCloseApprovalsModal={handleCloseApprovalsModal}
        taskObj={props.taskObj}
        updateChecklistFromReview={props.updateChecklistFromReview}
        checklistTaskUpdateFromApproval={props.checklistTaskUpdateFromApproval}
        fromChecklistDetailsDrawer={true}
        handleOpenSendForReviewDialog={props.handleOpenSendForReviewDialog}
        setApprovalObj={props.setApprovalObj}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  selectedOrganization: state.organizationInfo.selectedOrganization,
});

export default connect(mapStateToProps, null)(TasksContent);