import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles'; 
import {Grid, Checkbox, Container, Typography, TextField, InputAdornment, 
    Button, Tooltip, MenuItem, Popover, CircularProgress, Divider, Badge} from '@material-ui/core';
import ChecklistComponent from './ChecklistComponent';
import {connect} from 'react-redux';
import {shouldDisable, AccessLevel, consoleToLog, trackGAEvent, OverlayMessage, getStatusColor, getStatusNameByValue} from '../util/AppUtil';
import {setSearchChecklistFilter, setSortByFilter} from '../actions/filters';

import BulkAssignMembers from './bulk/BulkAssignMembers';
import BulkRemoveMembers from './bulk/BulkRemoveMembers';
import BulkApplyLabels from './bulk/BulkApplyLabels';
import BulkRemoveLabels from './bulk/BulkRemoveLabels';
import BulkMoveToFolder from './bulk/BulkMoveToFolder';
import {useIsMount} from './useIsMount';

import { useSnackbar } from 'notistack';
import { exportChecklistApi } from '../services/authService';
import { OverlayView, setOverlayToken, setOverlayView , setOvelayMessage} from '../actions/overlay';

import { v4 as uuidv4 } from 'uuid';

import Calender from './Calender';
import OrganizationError from './OrganizationError';
import { Autocomplete } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import {setSelectedTemplate} from '../actions/selectedOrganization';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { history } from '../routers/AppRouter';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import {Fab} from '@material-ui/core';
import ShoppingBasketOutlinedIcon from '@material-ui/icons/ShoppingBasketOutlined';
import GenerateInvoiceModal from './modals/GenerateInvoiceModal';


const useStyles = makeStyles((theme) => ({
    mainContent : {
        width: (showDrawer) => {
           return showDrawer ? "83%" : "100%"; 
        },
        backgroundColor: theme.palette.primary.light, 
        transition: ".5s", 
        flexGrow: 1,
        //overflow:'hidden',
        overflow:'auto',
        overflowX:'hidden',
        position:'relative'
    },
    container: {
        marginTop: "8px"
    },
    MuiFormControlroot: {
        '& .MuiInputBase-input' :{
            padding: '7px 4px 7px 0px',
            color:'grey',
            background:'#fff'
        },
        '& .MuiOutlinedInput-root' :{
            position: 'relative',
            borderRadius: '6px',
        },   
        '& .MuiOutlinedInput-adornedStart' : {
            background:'#fff',
            paddingLeft: '7px',
        }
    },
    icons : {
        color: "black",
        fontSize: "18px",
        minWidth:'30px',
        lineHeight: '10px',
    },
    checklistlabel: {
        fontSize:'20px', 
        fontWeight:500,
        color: '#202020',
    },
    searchicon: {
        color: 'grey', 
        transform:'rotate(90deg)'
    },
    stickyTopBar : {
        position:'sticky', 
        top:0, 
        zIndex:'999', 
        backgroundColor:theme.palette.primary.light,
        
    },
    itemOptionStyle: {
        width: "100%", 
        height:'100%',
        color:'#777f8e',
        margin:'5px 0px',
        display:'flex',
        alignItems:'center',
        justifyContent:'space-between'
    },
    MuiAutocompleteinputRoot : {
        '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"] .MuiAutocomplete-input': {
            padding: '4.5px 4px',
            maxWidth: '190px',
            [theme.breakpoints.down(1400)] : {
                maxWidth:'160px'
            }
        },
        '& .MuiOutlinedInput-root' : {
            borderRadius: '6px',
            background:'#fff',
        }
    },
    customTextField: {
        "& input::placeholder": {
            paddingLeft: "8px",
        },
    },
    checklistCountStyle : {
        color: theme.palette.primary.main,
        background: theme.palette.secondary.contrastText,
        width:'25px',
        height:'25px',
        borderRadius:'50%',
        display:'flex', 
        justifyContent:"center", 
        alignItems:'center',
        margin:'8px 0px 0px 4px',
        cursor:'pointer'
    },
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        padding: theme.spacing(1),
        boxShadow: "none",
        border: "1px solid rgba(0, 0, 0, 0.12)",
    },
    listmainstyle1 : {
        width: 'fit-content',
        height: 'auto',
        overflow: 'hidden',
        listStyle: 'none',
        padding:'0',
        margin: "0"
    },
    liststyle1: {
        display: 'block',
        color:'#202020',
        textAlign: 'left',
        fontSize: "14px",
        width:'180px',
        height: 'auto',
        padding: '8px 10px',
    },
    statuslist : {
        float:'right', 
        color:'#888888',
        marginTop:'-20px'
    },
    endAdornmentStyle: {
        fontSize:'13px', 
        color:'#747775', 
        cursor:'pointer', 
        display:'inline-block'
    },
    countTextStyles: {
        width:'25px',
        height:'25px',
        background:'#ededed',
        borderRadius:"50%",
        verticalAlign:'middle',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        marginTop:'1px',
        [theme.breakpoints.down(1400)] : {
            paddingTop:'1.5px'
        }
    },
    marginForFloat: {
        margin: theme.spacing(1),
        position:'fixed', 
        right:'32px',
        bottom:'24px',
        background:'#fff',
        color:'#222',
        width:'60px',
        height: '60px',
        fontSize:'21px',
        display:'flex',
        justifyContent:"center",
        alignItems:'center',
        '&:hover': {
            background:'#f7f7f7',
            color:'#222',
        }
    },
    colorSecondary : {
        color: '#ffffff !important',
        background: '#222222 !important',
    },
    badgeStyles: {
        position:'fixed',
        right:'40px',
        bottom:'72px',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        width:'25px', 
        height:"25px", 
        background:"#222", 
        fontSize:'13px',
        color:"#fff", 
        borderRadius:'100%',
        zIndex:100 
    } 
}));

const ContentComponent = (props) => {
    const {showDrawer} = props;
    const classes = useStyles(showDrawer);
    const { enqueueSnackbar } = useSnackbar();

    const isMount = useIsMount();
    
    const [checkAll, setCheckAll] = React.useState(false);
    
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorEl1, setAnchorEl1] = useState(null);
    const [anchorEl2, setAnchorEl2] = useState(null);
    
    const id = Boolean(anchorEl2) ? 'simple-popover' : undefined;

    //const [open, setOpen] = useState(false);

    const [openDrawer, setOpenDrawer] = useState(false);
    const [createFolder, setCreateFolder] = useState(false);
    const [createChecklist, setCreateChecklist] = useState(false);
    const [openFilters, setOpenFilters] = useState(false);
    const [editFolder, setEditFolder] = useState(undefined);
    
    const [addBulkMembers, setAddBulkMembers] = useState(undefined);
    const [removeBulkMembers, setRemoveBulkMembers] = useState(undefined);
    const [applyBulkLabels, setApplyBulkLabels] = useState(undefined);
    const [removeBulkLabels, setRemoveBulkLabels] = useState(undefined);
    const [bulkComplete, setBulkComplete] = useState(false);
    const [bulkDueDate, setBulkDueDate] = useState(undefined);
    const [bulkMoveToFolder, setBulkMoveToFolder] = useState(undefined);
    const [bulkLoading, setBulkLoading] = useState(false);
    const [checklistFolders, setChecklistFolders] = useState([]);
    const [checklistSearch,setChecklistSearch] = useState();
    const [refreshChecklist, setRefreshChecklist] = useState(false);
    const [spin, setSpin] = useState(false);
    const [selectedChecklistCount,setSelectedChecklistCount]=useState(0);
    const [checklistCount,setChecklistCount] = useState(0);
    const [checklist, setChecklist] = useState([]);
    const [addToCart, setAddToCart] = useState(false); 
    const [selectedChecklistArr, setSelectedChecklistArr] = useState([]);

    const { access_level } = props.selectedOrganization || {};
    const accessLevelManager = shouldDisable(AccessLevel.getUserAccessLevelValue(access_level), AccessLevel.MANAGER);
    const accessLevelGuest = (AccessLevel.getUserAccessLevelValue(access_level) === AccessLevel.GUEST) ? true : false;
    const isActive = (props.selectedOrganization.organization) ? (props.selectedOrganization.organization.deactivation_date) : null;

    //complete checklist loading
    const [completeChecklist, setCompleteChecklist] = useState(false);

    //template status popover
    const [anchorEl3, setAnchorEl3] = useState(null);

    const checklistDetails = window.location.toString().includes("checklist");

    const [openGenereateInvoiceDrawer, setOpenGenerateInvoiceDrawer] = useState(false);

    const userInvoiceAppScope = props.auth.user?.scope && props.auth.user?.scope.length > 0 ? props.auth.user?.scope.includes('invoices') : false;


    useEffect(() => {
        if(!checklistDetails){
            document.title = `Work - ${props.fullName}`;
        }
    }, []);

    useEffect(() => {
        if(!isMount) {
            if(!checklistDetails){
                document.title = `Work - ${props.fullName}`;
            }
        }
    }, [checklistDetails]);

    useEffect(() => {
        if(isMount) {

        } else {
            if(!checkAll) {
                setAddBulkMembers(undefined);
                setRemoveBulkMembers(undefined);
                setApplyBulkLabels(undefined);
                setRemoveBulkLabels(undefined);
            }
        }
    }, [checkAll]);

    useEffect(()=>{
        if (props.searchChecklistFilter !== undefined){
            setChecklistSearch(props.searchChecklistFilter);
        }
    },[]);

    useEffect(() => {
        if(isMount) {

        } else {
            setCheckAll(false);
        }
    }, [props.selectedTemplate?.template_id]);

    useEffect(() => {
        if(!isMount) {
            if(props.openChecklistModal) {
                onCreateChecklistClick();
            } 
        }
    }, [props.openChecklistModal]);

    useEffect(() => {
        if(!isMount) {
            if(props.openFolderModal) {
                onCreateFolderClick();
            } 
        } 
    }, [props.openFolderModal]);

    useEffect(() => {
        if(!isMount) {
            if(addToCart) {
                handleDrawerOpen();
            }

        }
    }, [addToCart])

    const getSelectedChecklistCount = (selectedChecklist) =>{
        let count = selectedChecklist.length;
        setSelectedChecklistCount(count);
        setSelectedChecklistArr(selectedChecklist);
        if(count > 50){
            setCheckAll(false);
            setAddBulkMembers(undefined);
            setRemoveBulkMembers(undefined);
            setApplyBulkLabels(undefined);
            setRemoveBulkLabels(undefined);
            enqueueSnackbar('Cannot select more than 50 checklists !', {
                variant: 'error'
            });
        }
    }
    const getChecklistCount = (checklistCount) =>{
        //consoleToLog("checklistCount",checklistCount);
        setChecklistCount(checklistCount);
    }

    const handleChange = (event) => {
        if(selectedChecklistCount > 1){
            setCheckAll(event.target.checked);
        }else{
            if(checklistCount < 50){
                setCheckAll(event.target.checked);
            }else{
                enqueueSnackbar('Cannot select more than 50 checklists !', {
                    variant: 'error'
                });
            }
        }
      };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event) => {
        //const value = event.currentTarget.dataset.myValue;
        //consoleToLog(event.currentTarget.dataset.myValue);
        setAnchorEl(null);
    };  

    /* const onFolderClick = () => {
        setOpen(true);
        setAnchorEl(null);
    } */

    const handleSortMenuClick = (event) => {
        setAnchorEl1(event.currentTarget);
    };

    const handleSortMenuClose = (event) => {
        setAnchorEl1(null);
      };

    const handleDueDatePopOverClick = (event) => {
        setAnchorEl2(event.currentTarget);
    }  

    const handleCloseDueDatePopOver = () => {
        setAnchorEl2(null);
    };
    
    const showTemplateLabelPopover = (event) => {
        setAnchorEl3(event.currentTarget);
    }

    const closeTemplateLabelStatusPopover = () => {
        setAnchorEl3(null);
    };

    const onCreateChecklistClick = () => {
        toggleDrawer();
        setCreateChecklist(!createChecklist);
    }

    const onCreateFolderClick = () => {
        toggleDrawer();
        setCreateFolder(!createFolder);
    }

    /**on export checklist icon click */
    const handleExportChecklistsClick = () => {
        //setIsActive(false);
        const template_id = props.selectedTemplate?.template_id;
        if (!template_id) {
            enqueueSnackbar('Please select a template to view', {
                variant:'error'
            });
            return;
        }
        
        let user_ids = props.selectedMembersFilter.map((member) => member.user.id).join();
        if(!user_ids) {
            user_ids = undefined;
        }

        let labels = props.selectedLabelsFilter.map((label) => label.id).join();
        if(!labels) {
            labels = undefined;
        }

        //let waiting_for_my_approval = false;
        // if(props.showWaitingForApprovalChecklist) {
        //     waiting_for_my_approval = true;
        // }

        const status = props.selectedStatusFilter.map(status => status).join();
        const search_text = props.searchChecklistFilter ? props.searchChecklistFilter : undefined;
        const organization_id = props.selectedOrganization.organization.id;
        const start_date = props.startDateFilter;
        const end_date = props.endDateFilter;
        const companiesFilter = props.companiesFilter;
        const sortBy =  props.sortByFilter;

        var randomtoken = uuidv4();
        consoleToLog("token for export checklist: ", randomtoken);
        props.setOvelayMessage(OverlayMessage.EXPORT_MSG);
        props.setOverlayView(OverlayView.SHOW_OVERLAY_LOADING)
        props.setOverlayToken(randomtoken);

        exportChecklistApi(organization_id, template_id, 1, start_date,
            end_date, user_ids, labels, status, search_text, companiesFilter, sortBy, randomtoken)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response exportChecklistApi: ", res);
                /* enqueueSnackbar(res.message, {
                    variant:'success'
                }); */
                trackGAEvent(props.selectedOrganization.organization.name, 'Checklist Export', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
            })
            .catch((e) => {
                consoleToLog("exportChecklistApi error", e.response);
                props.setOverlayView(OverlayView.NO_VIEW);
                props.setOverlayToken("");
                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant:'error'
                    });
                    return false;
                }
            });
        }


    const selectSortValue = (value) => {
        trackGAEvent(props.selectedOrganization.organization.name, 'Checklist Sort', `${props.auth.user.firstname} ${props.auth.user.lastname} `);
        props.setSortByFilter(value);
        setAnchorEl1(null);
    }  

    const setDrawerOpen = () => {
        setOpenDrawer(false)
        setCreateFolder(false);
        setCreateChecklist(false);
        setOpenFilters(false); 
        setEditFolder(undefined);
        props.openChecklistModal && props.handleChecklistModalClose();
        props.openFolderModal && props.handleFolderModalClose();
    }

    const toggleDrawer = () => {
        setOpenDrawer(open => !open)
        setAnchorEl(null);
    }

    const onEditFolderClick = (folder) => {
        if(!editFolder) { 
            toggleDrawer();
        }
        setEditFolder(folder);
        consoleToLog('folder', folder);
    }  

      let count = 0;
      if(props.selectedMembersFilter.length > 0) {
          count = count + 1
      } 
      
      if (props.selectedLabelsFilter.length > 0) {
          count = count + 1
      }
  
      if (props.companiesFilter.length > 0) {
          count = count + 1
      }
  
      let status_filter = props.selectedStatusFilter.filter((status) => status === 'completed')
      if(status_filter && status_filter.length > 0) {
          count = count + 1
      }  

      if(props.showUnassignedChecklist === true){
        count = count + 1
      }

    const onSearchChecklistChange = (e) => {
        const search_text = e.target.value;
        setChecklistSearch(e.target.value);
        if (search_text.length % 3 === 0 || search_text.length > 3) {
            props.setSearchChecklistFilter(search_text);
        }
    }

    const onAddBulkMember = (member) => {
        if(props.overlay && props.overlay.showOverlayView === OverlayView.SHOW_OVERLAY_LOADING){
            enqueueSnackbar("Another export request is already in progress. Please be patient.", ({
                variant: 'error'
            }));
        }else{
            props.setOvelayMessage(OverlayMessage.BULK_ADD_MEMBERS);
            setAddBulkMembers(member);
            console.log("New message",OverlayMessage.BULK_ADD_MEMBERS)
        }
    }

    const onRemoveBulkMember = (member) => {
        if(props.overlay && props.overlay.showOverlayView === OverlayView.SHOW_OVERLAY_LOADING){
            enqueueSnackbar("Another export request is already in progress. Please be patient.", ({
                variant: 'error'
            }));
        }else{
            props.setOvelayMessage(OverlayMessage.BULK_REMOVE_MEMBERS);
            setRemoveBulkMembers(member);
        }
    }

    const onApplyBulkLabel = (label) => {
        if(props.overlay && props.overlay.showOverlayView === OverlayView.SHOW_OVERLAY_LOADING){
            enqueueSnackbar("Another export request is already in progress. Please be patient.", ({
                variant: 'error'
            }));
        }else{
            props.setOvelayMessage(OverlayMessage.BULK_ADD_LABEL);
            setApplyBulkLabels(label);
        }
    }

    const onRemoveBulkLabel = (label) => {
        if(props.overlay && props.overlay.showOverlayView === OverlayView.SHOW_OVERLAY_LOADING){
            enqueueSnackbar("Another export request is already in progress. Please be patient.", ({
                variant: 'error'
            }));
        }else{
            props.setOvelayMessage(OverlayMessage.BULK_REMOVE_LABEL);
            setRemoveBulkLabels(label);
        }
    }

    const onCompleteChecklistClick = () => {
        if(props.overlay && props.overlay.showOverlayView === OverlayView.SHOW_OVERLAY_LOADING){
            enqueueSnackbar("Another export request is already in progress. Please be patient.", ({
                variant: 'error'
            }));
        }else{
            props.setOvelayMessage(OverlayMessage.BULK_COMPLETE_CHECKLIST);
            setBulkComplete(true);
        }
    }

    const clickedDate = (str) => {
        if(props.overlay && props.overlay.showOverlayView === OverlayView.SHOW_OVERLAY_LOADING){
            enqueueSnackbar("Another export request is already in progress. Please be patient.", ({
                variant: 'error'
            }));
        }else{
              props.setOvelayMessage(OverlayMessage.BULK_SET_DUE_DATE);
              setBulkDueDate(str);
        }
    }    

    const handleDueDatePopOver = () => {
        return (<Popover
            id={id}
            open={Boolean(anchorEl2)}
            anchorEl={anchorEl2}
            onClose={handleCloseDueDatePopOver}
            anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
            }}
            transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
            }}
        >
            <Calender clickedDate={clickedDate} />
        </Popover>)
    }

    const onBulkMoveToFolder = (folder) => {
        if(props.overlay && props.overlay.showOverlayView === OverlayView.SHOW_OVERLAY_LOADING){
            enqueueSnackbar("Another export request is already in progress. Please be patient.", ({
                variant: 'error'
            }));
        }else{
            props.setOvelayMessage(OverlayMessage.BULK_MOVE_TO_FOLDER);
            setBulkMoveToFolder(folder);
        }
    }

    const getChecklistFolders = (checklistGroup) => {
        setChecklistFolders(checklistGroup)
    }

    const onRefreshClick = () => {
        setSpin(true);
        setRefreshChecklist(true);
    }

    const onItemClick = (option) => {
        if(option) {
            props.setSelectedTemplate(option);
            history.push('/dashboard');
        }
    }

    const returnChecklistCount = (checklist) => {
        setChecklist(checklist);
    }
    
    const renderOptions = (option) => {
        return (
            <div className={classes.itemOptionStyle}>
                <Typography variant='body2'
                    style={{width:'200px', overflow:'hidden', whiteSpace:'wrap', textOverflow:'ellipsis'}}>
                    {option.name}
                </Typography>

                <Typography variant='body2'>
                    {option.checklist_count}
                </Typography>

            </div>
            );
    }

    const labelStatus = () => {
        let label_count =  props.selectedTemplate.data;
        return (
            <ul className={classes.listmainstyle1}>
                { label_count && label_count.length > 0 ? label_count.map((label) => {
                            return <li className={classes.liststyle1} key={label.name}>
                                    <span> <Typography style={{whiteSpace: "nowrap",
                                                    maxWidth: "150px", 
                                                    overflow: "hidden", 
                                                    fontSize:'14px',
                                                    color:`${label.label_color}`,
                                                    textOverflow: "ellipsis"}}>{label.label_name}
                                            </Typography>
                                        <span className={classes.statuslist}>{label.label_count}</span> 
                                    </span>
                                </li>
                        }): <div></div>}
            </ul>
        )
    }

    const getTemplateChecklistStatus = (status_data) => {
        return (
            <ul className={classes.listmainstyle1}>
                { status_data && status_data.length > 0 ? status_data.map((status) => {
                            return <li className={classes.liststyle1} key={status.name}>
                                    <span> <Typography style={{whiteSpace: "nowrap",
                                                    maxWidth: "150px", 
                                                    overflow: "hidden", 
                                                    fontSize:'14px',
                                                    color:`${getStatusColor(status.status)}`,
                                                    textOverflow: "ellipsis"}}>{getStatusNameByValue(status.status)}
                                            </Typography>
                                        <span className={classes.statuslist}>{status.count}</span> 
                                    </span>
                                </li>
                        }): <div></div>}
            </ul>
        )
    }

    const showTemplateLabelStatus = () => {
        let overDueData=[], allStatusDataExceptOverdue=[];
        if(props.selectedTemplate.status_data && props.selectedTemplate.status_data.length>0){
            overDueData =  props.selectedTemplate.status_data.filter(x => x.status === "over_due");
            allStatusDataExceptOverdue =  props.selectedTemplate.status_data.filter(x => x.status !== "over_due");
        }
        return <Popover
                    id="mouse-over-popover"
                    className={classes.popover}
                    classes={{
                        paper: classes.paper,
                    }}
                    open={Boolean(anchorEl3)}
                    anchorEl={anchorEl3}
                    anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                    }}
                    transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                    }}
                    onClose={closeTemplateLabelStatusPopover}
                    >
                    {labelStatus()}
                    {props.selectedTemplate.data && props.selectedTemplate.data.length > 0 && <Divider style={{margin: "8px 0px"}}/>}
                    {getTemplateChecklistStatus(allStatusDataExceptOverdue)}
                    {overDueData && overDueData.length>0 && <Divider style={{margin: "8px 0px"}}/>}
                    {overDueData && overDueData.length>0 && getTemplateChecklistStatus(overDueData)}
                </Popover>
    }

    const addToEndAdornment = (endAdornment) => {
        const children = React.Children.toArray(endAdornment.props.children);
        children.unshift(<>
                                {props.selectedTemplate?.name !== 'All' &&
                                <div className={classes.endAdornmentStyle}
                                    onMouseEnter={showTemplateLabelPopover}
                                    onMouseLeave={closeTemplateLabelStatusPopover}
                                >
                                    <Typography variant='subtitle1' className={classes.countTextStyles}>
                                        {props.selectedTemplate?.checklist_count}
                                    </Typography>
                                </div>}
                                {props.selectedTemplate?.checklist_count > 0 && showTemplateLabelStatus()}
                        </>);
        return React.cloneElement(endAdornment, {}, children);
    }

    const onAddToCartClick = () => {
        setAddToCart(true);
    }

    const handleDrawerOpen = () => {
        setOpenGenerateInvoiceDrawer(true);
    }

    const handleDrawerClose = () => {
        setOpenGenerateInvoiceDrawer(false);
    }

    return (
        <div className={classes.mainContent}
            style={{height: props.height}}>
                <Grid container justifyContent="space-between" 
                    className={classes.stickyTopBar}>
                    <OrganizationError /> 
                    <Container maxWidth="xl"
                        style={{display:'flex', padding: "0px 12px 4px 12px", margin:'8px 0px 0px 4px'}}>   
                        <Grid item container spacing={1} alignItems="center" md={8} lg={10}>
                            {!accessLevelGuest &&
                            <Grid item style={{padding: "0px"}}> 
                                <Checkbox color="secondary" 
                                    disabled={props.templates.length === 0 || checklist.length === 0 || addToCart}
                                    onChange={handleChange} 
                                    checked={checkAll}/>
                            </Grid>}
                            { !checkAll ?
                                (<>
                                <Grid item style={{padding: "0px", marginLeft: accessLevelGuest ? '1rem' : '0px'}}> 
                                    <Tooltip title="Reload" arrow >
                                        <Button aria-label="delete"
                                            disableRipple={true} 
                                            onClick={onRefreshClick} 
                                        className={spin ? `spinner ${classes.icons}` : classes.icons}>
                                            {<i className="flaticon-reload" />}
                                        </Button>
                                    </Tooltip>
                                    {/* <RefreshIcon fontSize='medium'/> */}
                                </Grid>
                                {/* <Grid item style={{padding: "0px 0px 0px 6px", marginRight:'4px'}}> 
                                    <Typography className={classes.checklistlabel}>Templates</Typography>
                                </Grid> */}
                                {props.templates.length > 0 &&
                                <Grid item md={4} 
                                    style={{padding: "0px 0px 0px 12px", marginTop:'-4px', flexBasis:'28%', maxWidth:'28%'}}>
                                    <Autocomplete
                                        id="combo-box-demo"
                                        options={props.templates}
                                        classes={{
                                            root: classes.MuiAutocompleteinputRoot
                                        }}
                                        value={props.selectedTemplate}
                                        getOptionLabel={(option) => option.name}
                                        renderOption={(option) => renderOptions(option)}
                                        onChange={(e, newValue) => {
                                            //onTemplateNameChange(e, newValue);
                                            onItemClick(newValue)
                                        }}
                                        renderInput={params => (
                                            <TextField {...params} 
                                            variant='outlined'
                                            placeholder='Search items' 
                                            classes={{
                                                root: classes.customTextField,
                                            }}
                                            margin="dense"
                                            fullWidth
                                            InputLabelProps={{style: {fontSize: 14, color: "#ccc"}}}
                                            InputProps={{ ...params.InputProps, disableUnderline: true, 
                                                style: {padding:'3px 0px 3px 10px'},
                                                endAdornment: addToEndAdornment(params.InputProps.endAdornment),
                                            
                                            }}
                                                />
                                        )}
                                        
                                        closeIcon={<CloseIcon fontSize='small' style={{display:'none'}}/>}
                                    />
                                </Grid>}
                                {/* <Grid item style={{marginTop:'-2px', marginLeft:'8px'}} className={classes.checklistCountStyle}
                                    onMouseEnter={showTemplateLabelPopover}
                                    onMouseLeave={closeTemplateLabelStatusPopover}>
                                    <Typography variant='subtitle1'>
                                        {props.selectedTemplate?.checklist_count}
                                    </Typography>
                                    {props.selectedTemplate?.checklist_count > 0 && showTemplateLabelStatus()}
                                </Grid> */}
                                <Grid item lg={6} style={{marginTop:'-4px', marginLeft:'16px'}}> 
                                    <TextField fullWidth 
                                        value={checklistSearch}
                                        id="quicksearch checklist" 
                                        label="" 
                                        variant="outlined"
                                        type="text"
                                        margin="dense"
                                        placeholder="Quick Search Checklist"
                                        onChange={onSearchChecklistChange}
                                        classes={{
                                            root: classes.MuiFormControlroot
                                        }} 
                                        InputLabelProps={{style: {fontSize: 14, color: "#ccc"}}}
                                        InputProps={{
                                            startAdornment: (
                                            <InputAdornment position="start">
                                                {/* <SearchIcon className={classes.searchicon}/> */}
                                                <i className="flaticon-search"></i>
                                            </InputAdornment>
                                            ),
                                            style: {paddingTop:'0.5px', paddingBottom:'0.5px'}
                                        }}
                                    /> 
                                </Grid>
                                { completeChecklist ?
                                    <Grid item style={{marginLeft:'16px', marginTop:'4px'}}>
                                        <CircularProgress size={20} color='secondary' />
                                    </Grid> : <></>
                                }
                                </>) :
                                    (<div  style={{padding:'9.5px 0', display:'flex', position:'relative'}}>
                                    {!accessLevelManager &&
                                    <Grid item>
                                        <BulkAssignMembers  onAddBulkMembers={onAddBulkMember}
                                            bulkLoading={bulkLoading}
                                        />
                                    </Grid>}
                                    {!accessLevelManager &&
                                    <Grid item>
                                        <BulkRemoveMembers  onRemoveBulkMembers={onRemoveBulkMember}
                                            bulkLoading={bulkLoading}
                                        />
                                    </Grid>}
                                    <Grid item>
                                        <BulkApplyLabels onApplyBulkLabel={onApplyBulkLabel}
                                            bulkLoading={bulkLoading}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <BulkRemoveLabels onRemoveBulkLabel={onRemoveBulkLabel}
                                            bulkLoading={bulkLoading}/>
                                    </Grid>
                                    <Grid item>
                                        <Tooltip title="Complete Checklist" arrow >
                                            <Button aria-label="delete"
                                                onClick={(e) => {
                                                    if (window.confirm("You cannot undo the completed checklist. Would you like to continue to complete the checklist?")) {
                                                        onCompleteChecklistClick();
                                                        }} 
                                                    } 
                                                disabled={bulkLoading}
                                            className={classes.icons}>
                                                <i className="flaticon-check-1"></i>
                                            </Button>
                                        </Tooltip>
                                    </Grid>
                                    {!accessLevelManager &&
                                    <Grid item>
                                        <Tooltip title="Set Due Date" arrow >
                                            <Button aria-label="delete" 
                                                disabled={bulkLoading}
                                                onClick={handleDueDatePopOverClick}
                                            className={classes.icons}>
                                                <i className="flaticon-calendar-1"></i>
                                            </Button>
                                        </Tooltip>
                                        {handleDueDatePopOver()}
                                    </Grid>}
                                    {!accessLevelManager &&
                                    <Grid item>
                                        <BulkMoveToFolder onBulkMoveToFolder={onBulkMoveToFolder}
                                            checklistFolders={checklistFolders}
                                            bulkLoading={bulkLoading}
                                        />
                                    </Grid>}
                                    <Grid item>
                                    </Grid>
                                    <Grid item style={{color:"#0076ff",marginLeft:"3px", paddingTop:'3px'}}>
                                        {selectedChecklistCount} selected
                                    </Grid>
                                    {(bulkComplete || (bulkDueDate !== undefined)) && 
                                    <CircularProgress size={15}
                                    color="primary" 
                                    style={{marginLeft:"15px"}}/>   
                                        
                                    }
                                    </div>)
                            }  
                        </Grid>
                        {!checkAll &&
                            <Grid item container alignItems="center" md={4} lg={2} justifyContent="flex-end">
                            <div>
                            {props.accountList && props.accountList.length > 0 && !accessLevelManager && userInvoiceAppScope &&
                            <Tooltip title='Generate Invoice' arrow>
                                <Button 
                                    onClick={onAddToCartClick}
                                    className={classes.icons}>
                                    <img src='/images/receipt.png' width='21px' className={addToCart ? 'invoice_icon active' : 'invoice_icon'} />
                                    {   addToCart ?
                                        <div className='filter__dot'></div> : <div></div>
                                    }
                                </Button>
                            </Tooltip>}
                            <Tooltip title="Export Checklists" arrow>
                                <Button aria-label="ec" 
                                    onClick={()=>{
                                        if(props.overlay && props.overlay.showOverlayView === OverlayView.SHOW_OVERLAY_LOADING){
                                            enqueueSnackbar("Another export request is already in progress. Please be patient.", ({
                                                variant: 'error'
                                            }));
                                        }
                                        else handleExportChecklistsClick();
                                    }} 
                                    className={classes.icons}>
                                    <i className="flaticon-export"></i>
                                </Button>
                            </Tooltip>
                            <Tooltip title="Sort" arrow>
                                <Button aria-label="sort" 
                                    onClick={handleSortMenuClick} 
                                    className={classes.icons}>
                                    <i className="flaticon-sort-1"></i>
                                </Button>
                            </Tooltip>
                                <Popover
                                    id="sort-menu"
                                    anchorEl={anchorEl1}
                                    keepMounted
                                    open={Boolean(anchorEl1)}
                                    onClose={handleSortMenuClose}
                                    style={{marginTop:'20px'}}
                                >
                                    <MenuItem 
                                        selected={props.sortByFilter === ''}
                                        value={''}
                                        onClick={() => selectSortValue("")}>Default</MenuItem>
                                    <MenuItem 
                                        selected={props.sortByFilter === 'date_descending'}
                                        value="date_descending"
                                        onClick={() => selectSortValue("date_descending")}>Period - Latest to Oldest</MenuItem>
                                    <MenuItem 
                                        selected={props.sortByFilter === 'date_ascending'}
                                        value="date_ascending"
                                        onClick={() => selectSortValue("date_ascending")}>Period - Oldest to Latest</MenuItem>
                                    <MenuItem
                                        selected={props.sortByFilter === 'due_date_ascending'}
                                        value="due_date_ascending"
                                        onClick={() => selectSortValue("due_date_ascending")}>Due Date</MenuItem>
                                </Popover>
                            </div>
                            <Tooltip title="Filters" arrow>
                                <Button aria-label="delete"  
                                    className={classes.icons}
                                    onClick={() => {
                                        toggleDrawer();
                                        setOpenFilters(!openFilters);
                                    }}
                                    >
                                    <i className={(count && count > 0) ? `flaticon-filter active` : `flaticon-filter`}></i>
                                    {   count && count > 0 ?
                                        <div className='filter__dot'></div> : <div></div>
                                    }
                                </Button>
                            </Tooltip>
                            {/* {!accessLevelManager && !isActive && props.selectedTemplate?.published &&
                                <div><Tooltip title="Create" arrow>
                                    <Button aria-label="delete"
                                        className={classes.icons}
                                        onClick={handleClick}
                                    >
                                        <i className="flaticon-add-2"></i>
                                    </Button>
                                </Tooltip><Popover
                                    id="create-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                    style={{ marginTop: '32px' }}
                                >
                                        <MenuItem onClick={onCreateChecklistClick}>Create Checklist</MenuItem>
                                        <MenuItem onClick={onCreateFolderClick}>Create Folder</MenuItem>
                                    </Popover></div>} */}
                        </Grid>}
                    </Container>
                    </Grid>  
                {/* <ChecklistFoldersComponent /> */}
            <Container  maxWidth="xl" style={{padding: "0 12px"}}>
                <ChecklistComponent checkAll={checkAll} 
                    setCheckAll={setCheckAll} 
                    setDrawerOpen={setDrawerOpen} 
                    openDrawer={openDrawer}
                    createFolder={createFolder}
                    createChecklist={createChecklist}
                    openFilters={openFilters}
                    addBulkMembers={addBulkMembers}
                    removeBulkMembers={removeBulkMembers}
                    applyBulkLabels={applyBulkLabels}
                    removeBulkLabels={removeBulkLabels}
                    bulkComplete={bulkComplete}
                    setBulkComplete={setBulkComplete}
                    bulkDueDate={bulkDueDate}
                    setBulkDueDate={setBulkDueDate}
                    setAnchorEl2={setAnchorEl2}
                    bulkMoveToFolder={bulkMoveToFolder}
                    setBulkMoveToFolder={setBulkMoveToFolder}
                    getChecklistFolders={getChecklistFolders}
                    refreshChecklist={refreshChecklist}
                    setRefreshChecklist={setRefreshChecklist}
                    setSpin={setSpin}
                    setBulkLoading={setBulkLoading}
                    onEditFolderClick={(folder) => onEditFolderClick(folder)}
                    editFolder={editFolder}
                    getSelectedChecklistCount={getSelectedChecklistCount}
                    getChecklistCount = {getChecklistCount}
                    setCompleteChecklist = {setCompleteChecklist} 
                    handleSendEmailDialogOpen={props.handleSendEmailDialogOpen}
                    toggleDrawer={toggleDrawer}
                    setCreateChecklist={setCreateChecklist}
                    returnChecklistCount={returnChecklistCount}
                    setChecklistDetailsOpen={props.setChecklistDetailsOpen}
                    fetchChecklistFromChild={props.fetchChecklistFromChild}
                    checkListFromParent={props.checkList}
                    fullName={props.fullName}
                    selectedChecklistArr={selectedChecklistArr}
                    addToCart={addToCart}
                />
            </Container>
            <div style={{position:'relative'}}>
                {addToCart &&
                    <div style={{position:'relative'}}>
                        <div className={classes.badgeStyles}> 
                            <Typography variant='body2'> 
                                {selectedChecklistArr.length}
                            </Typography>
                        </div>
                        <Fab size="small"
                            onClick={handleDrawerOpen}
                            className={classes.marginForFloat}>
                                
                            <ShoppingBasketOutlinedIcon />
                        </Fab>
                    </div>
                    
                }
            </div>

                {addToCart &&
                    <GenerateInvoiceModal 
                        openDrawer={openGenereateInvoiceDrawer}
                        handleDrawerClose={handleDrawerClose}
                        selectedChecklistArr={selectedChecklistArr}
                        setSelectedChecklistArr={setSelectedChecklistArr}
                        setAddToCart={setAddToCart}
                    />
                }
        </div>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    selectedStatusFilter: state.filters.selectedStatusFilter,
    selectedMembersFilter: state.filters.selectedMembersFilter,
    selectedLabelsFilter: state.filters.selectedLabelsFilter,
    companiesFilter: state.filters.companiesFilter,
    selectedTemplate: state.organizationInfo.selectedTemplate,
    searchChecklistFilter: state.filters.searchChecklistFilter,
    startDateFilter: state.filters.startDateFilter,
    endDateFilter: state.filters.endDateFilter,
    sortByFilter: state.filters.sortByFilter,
    overlay: state.overlay,
    isLoading: state.loading.isLoading,
    showUnassignedChecklist: state.filters.showUnassignedChecklist,
    templates: state.organizationInfo.templates,
    accountList: state.organizationInfo.accountList
});

const mapDispatchToProps = (dispatch) => ({
    setSearchChecklistFilter: (search_text) => dispatch(setSearchChecklistFilter(search_text)),
    setSortByFilter: (sortBy) => dispatch(setSortByFilter(sortBy)),
    setOverlayToken: (randomToken) => dispatch(setOverlayToken(randomToken)),
    setOverlayView: (exportView) => dispatch(setOverlayView(exportView)),
    setOvelayMessage: (overlayMessage) => dispatch(setOvelayMessage(overlayMessage)),
    setSelectedTemplate: (template) => dispatch(setSelectedTemplate(template))
})

export default connect(mapStateToProps, mapDispatchToProps)(ContentComponent);