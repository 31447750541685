import React, { useState, useEffect, createRef} from "react";
import {
  Grid,
  Tooltip,
  Button,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  ClickAwayListener,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import useOuterClick from "./useOuterClick";
import { setSearchLabelFilter } from "../../actions/filters";
import { useIsMount } from "../useIsMount";
import { consoleToLog } from "../../util/AppUtil";
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';

const useStyles = makeStyles((theme) => ({
  topbtns: {
    color: "black",
    fontSize: "15px",
    minWidth: "20px",
    padding: "5px 6px",
  },
  list : {
    cursor:'pointer',
    '&:hover' : {
        background:'#d3d3d3'
    }
  },
}));

const SelectFromLabelList = (props) => {
  const classes = useStyles();
  const isMount = useIsMount();
  const [selectedLabelsList, setSelectedLabelsList] = useState((props.assignees && props.assignees.length > 0) ? props.assignees : []);
  const [isActiveAdd, setIsActiveAdd] = useState(false);
  const innerRef1 = createRef();   // logic to close modal escape keypressed

  useEffect(() => {
    props.setSearchLabelFilter("");
  }, []);

  // logic to close modal escape keypressed
  useEffect(() => {
    if(innerRef1.current !== null){
      innerRef1.current.addEventListener("keydown", function(e) {
        if (e.key === "Escape") {
           setIsActiveAdd(false);
        }
      });
    }
  }, [innerRef1]);


  useEffect(() => {
    if (!isMount) {
      setSelectedLabelsList(props.assignees);
    }

  }, [props.assignees]);


  const onClickAdd = (e) => {
    consoleToLog("onClickAdd")
    setIsActiveAdd(!isActiveAdd);
  };

  const isSelectedLabel = (checkLabel) => {
    const tempArr = selectedLabelsList.filter(
      (label) => label.id === checkLabel.id
    );
    return tempArr.length > 0 ? true : false;
  };

  const onLabelChecked = (e, checkedLabel) => {
    //create checklist page
    if (isSelectedLabel(checkedLabel)) {
      //remove
      const array = selectedLabelsList.filter(
        (label) => label.id !== checkedLabel.id
      );
      setSelectedLabelsList(array);
      onApplyChanges(array);
    } else {
      //add
      const array = selectedLabelsList.concat(checkedLabel);
      setSelectedLabelsList(array);
      onApplyChanges(array);
    }
  };

  const onApplyChanges = (list) => {
    props.setAssignedLabelList(list);
  };

  const innerRef = useOuterClick((ev) => {
    //consoleToLog("outside click11");
    setIsActiveAdd(false);
  });

  const handleTextFieldChange = (e) => {
    const name = e.target.value;
    props.setSearchLabelFilter(name);
  };

  return (
    <>
      {props.fromActionsDropdown ?
      <li className={classes.list} onClick={(e) =>  onClickAdd(e)}>
        <a>
          Labels
        </a>
      </li>
      :
      <Button
      className='more_actionButton'
      onClick={(e) => onClickAdd(e)}
      disabled={props.disabledAssignChecklistLabels}
    >
      {/* <i
        style={{marginRight:'8px', display:'flex', alignItems:'center'}} 
        className={
          props.disabledAssignChecklistLabels
            ? "flaticon-price-tag flaticonPriceTagDisabled "
            : "flaticon-price-tag"
        }
      ></i> */}
      <LocalOfferOutlinedIcon className={classes.moreActionsIcons}
          style={{fontSize:'15px', verticalAlign:'baseline', marginRight:'4px', color:'#ff7f50'}}/>
      Labels
    </Button>}
    <Grid item>
      <Grid item style={{ position: "relative", marginRight: "1px"}}>
        {isActiveAdd && (
          <ClickAwayListener onClickAway={() => setIsActiveAdd(false)}>
          <Grid
            item
            className={`menu1 ${isActiveAdd ? "active" : "inactive"}`}
            style={{ padding: "4px 20px", width: "250px" }}
          >
            <TextField
              id="label_input"
              fullWidth
              label="Search Labels"
              style={{ marginBottom: "8px" }}
              //value={textFieldValue}
              onChange={handleTextFieldChange}
            />

            <Typography variant="button" gutterBottom>
              Labels
            </Typography>
            {props.labels &&
              props.labels.length > 0 &&
              props.labels.map((label, index) => {
                return (
                  <Tooltip
                    title={label.name}
                    arrow
                    key={label.id + "_" + index}
                  >
                    <FormControlLabel
                      key={label.id + "_" + index}
                      control={
                        <Checkbox
                          checked={isSelectedLabel(label) || false}
                          onChange={(e) => onLabelChecked(e, label)}
                          name="checkedB"
                          color="primary"
                        />
                      }
                      style={{
                        display: "block",
                        whiteSpace: "nowrap",
                        maxWidth: "auto",
                        overflow: "hidden",
                        verticalAlign: "middle",
                        marginRight: "0px",
                        color: `${label.color}`,
                        textOverflow: "ellipsis",
                      }}
                      label={label.name}
                    />
                  </Tooltip>
                );
              })}
          </Grid>
          </ClickAwayListener>
        )}
      </Grid>
    </Grid>
    </>

  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  labels: state.organizationInfo.labels.filter((label) => {
    return label.name
      .toLowerCase()
      .includes(state.filters.searchLabelFilter.toLowerCase());
  }),
});

const mapDispatchToProps = (dispatch) => ({
  setSearchLabelFilter: (name) => dispatch(setSearchLabelFilter(name)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectFromLabelList);
