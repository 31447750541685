import React, {useState} from 'react';
import {Grid, Typography, Button, Popover, MenuItem} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ImportTemplateModal from '../modals/ImportTemplateModal';
import {connect} from 'react-redux';
import { history } from '../../routers/AppRouter';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { archiveTemplateApi, templatePublishUnpublishApi } from '../../services/authService';
import { consoleToLog, trackGAEvent } from '../../util/AppUtil';
import { useSnackbar } from 'notistack';
import {fetchTemplateList} from '../../actions/selectedOrganization'; 

const useStyles = makeStyles((theme) => ({
    connectIcons: {
        width:'20px',
        marginLeft:'auto'
    },
    tempContainer : {
        marginTop: '30px',
        marginBottom:'30px'
    },
    tempList : {
        padding:'6px 0px',
        background: '#f7f7f7',
        marginBottom:'8px',
        borderRadius:'4px',
    },
    templateContainerStyle: {
        height:'200px', 
        overflowY:'auto', 
        overflowX:'hidden',
        "&::-webkit-scrollbar": {
            display: "none"
        }
    }
}));

const OnboadingTemplates = (props) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();

    const [openDrawer, setDrawerOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [templateId, setTemplateId] = useState(false);

    const handleDrawerOpen = () => {
        setDrawerOpen(true);
    }

    const handleDrawerClose = () => {
        setDrawerOpen(false);  
    }

    const onNewTemplateClick = () => {
        trackGAEvent(props.selectedOrganization.organization.name, 'Clicked create template from header', `${props.auth.user.firstname} ${props.auth.user.lastname} `);
        window.open('/template/create', '_blank');
    }

    const handlePopoverOpen = (event, template) => {
        setAnchorEl(event.currentTarget);
        setTemplateId(template.template_id);
    }

    const handlePopoverClose = () => {
        setAnchorEl(null);
        setTemplateId(undefined);
    }

    const callTemplateListApi = (organization_id) => {
        props.fetchTemplateList(organization_id, props.startDateFilter, props.endDateFilter, 
            props.companiesFilter, props.selectedMembersFilter, props.selectedTemplate)
    }

    const onDeleteTemplateClick = (e, template) => {
        e.stopPropagation();
        if(window.confirm('Are you sure you want to delete this template?')) {
            const organization_id = props.selectedOrganization.organization.id;
            const template_id = template.template_id;

            archiveTemplateApi(template_id)
                .then((response) => {
                    const res = response.data;
                    consoleToLog("Response archiveTemplateApi", res);

                    callTemplateListApi(organization_id);
                    trackGAEvent(props.selectedOrganization.organization.name, 'Template Deleted', `${props.auth.user.firstname} ${props.auth.user.lastname}`);

                })
                .catch((e) => {
                    consoleToLog('Error archiveTemplateApi', e.response);
                    if(e.response.data && e.response.data.message){
                        enqueueSnackbar(e.response.data.message, {variant:'error'});
                        return;
                    }
                })
        }

    }

    const showMoreActionsPopover = (template) => {
        return <Popover
                    id="more-option-popover"
                    keepMounted
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    className={classes.templatePopover}
                    onClose={handlePopoverClose}
                    style={{marginRight:"8px"}}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    >
                    {template.published ?
                    <MenuItem onClick={(e) => {
                        e.stopPropagation();
                        if(window.confirm('Are you sure you want to unpublish this template?')) {
                            onTemplatePublishUnPublishClick(template, 'unpublish');
                        }
                    }}>
                        Unpublish 
                    </MenuItem>
                    :
                    <MenuItem onClick={(e) => {
                        e.stopPropagation();
                        if(window.confirm('Are you sure you want to unpublish this template?')) {
                            onTemplatePublishUnPublishClick(template, 'publish');
                        }
                    }}>
                        Publish 
                    </MenuItem>
                    
                    }
                    <MenuItem onClick={() => {
                                history.push({
                                pathname: `/template/edit/${template.slug}`,
                            })
                        }}>
                        Edit 
                    </MenuItem>
                    <MenuItem onClick={(e) => onDeleteTemplateClick(e, template)}>
                        Delete
                    </MenuItem>
                </Popover>
    }

    const showOrgnizationTemplates = () => {
        return (
            <Grid item container className={classes.tempContainer}>
                {
                    props.templates && props.templates.length > 0 ? 
                    props.templates.map((template) => {
                        return <Grid item container alignItems='center' className={classes.tempList}
                                    key={template.template_id}>
                                    <Grid item md={10} lg={10}
                                        style={{borderRadius:'4px',
                                                padding:'5px' }}
                                    >
                                        <Typography variant="body1" 
                                            style={{padding: '0 8px'}}
                                        >
                                            {template.name}
                                        </Typography>

                                    </Grid>

                                    <Grid item md={1} style={{marginLeft:'24px'}}>
                                        <Button style={{minWidth:'20px'}} onClick={(e) => handlePopoverOpen(e, template)}>
                                            <MoreHorizIcon fontSize='small'/>
                                        </Button>
                                        {template.template_id === templateId && showMoreActionsPopover(template)}
                                    </Grid>
                                </Grid>

                    }) : <Grid item container justifyContent='center' className={classes.tempList}>
                            <Typography variant='body1'> No Templates Found </Typography>
                        </Grid>  

                }
        </Grid>
        )
    }

    const onTemplatePublishUnPublishClick = (template, pubString) => {
        const organization_id = props.selectedOrganization.organization.id;
        const template_id = template.template_id;
        const templateString = pubString === 'publish' ? 'Published' : 'Unpublished'

        templatePublishUnpublishApi(organization_id, 'template' ,template_id, pubString)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response templatePublishUnpublishApi ', res);

                enqueueSnackbar(res.message, {
                    variant:'success'
                })

                trackGAEvent(props.selectedOrganization.organization.name, `Template ${templateString}`, `${props.auth.user.firstname} ${props.auth.user.lastname}`);
                callTemplateListApi(organization_id);
                
            })
            .catch((e) => {
                consoleToLog("Erro templatePublishUnpublishApi ", e);
                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant:'error'
                    })
                    return false;
                }
            });

    }

    return (
        <Grid item md={12}>
            <Grid item md={12} className={classes.templateContainerStyle}>
                {showOrgnizationTemplates()}
            </Grid>
            <Grid item container justifyContent='center' alignItems='center' spacing={1} 
                style={{marginTop:"16px"}}>
                <Grid item md={5}>
                    <Button variant='outlined' color='primary' fullWidth
                        onClick={onNewTemplateClick}>
                        Add Template
                        <AddCircleOutlineIcon className={classes.connectIcons} />
                    </Button>
                </Grid>

                <Grid item md={5}>
                    <Button variant='outlined' color='primary' fullWidth
                        onClick={handleDrawerOpen}>
                        Import Template
                        <img src='/images/export_icon.svg' className={classes.connectIcons}/>
                    </Button>
                </Grid>
            </Grid>
            
            <ImportTemplateModal openDrawer={openDrawer} 
                handleDrawerClose={handleDrawerClose}
                fromOnboardingComponent={true}/>
        </Grid>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    templates: state.organizationInfo.templates,
    startDateFilter: state.filters.startDateFilter,
    endDateFilter: state.filters.endDateFilter,
    selectedMembersFilter: state.filters.selectedMembersFilter,
    companiesFilter: state.filters.companiesFilter,
    selectedTemplate: state.organizationInfo.selectedTemplate,
});

const mapDispatchToProps = (dispatch) => ({
    fetchTemplateList: (organization_id, start_date, end_date, companiesFilter, selectedMembersFilter, selectedTemplate) => 
        dispatch(fetchTemplateList(organization_id, start_date, end_date, companiesFilter, selectedMembersFilter, selectedTemplate)),
});


export default connect(mapStateToProps, mapDispatchToProps)(OnboadingTemplates);