import {getAxios} from './axiosUtil';

export const addCustomFieldApi = (organization_id, name, default_value, type) => {
    const data = {
        name,
        default_value,
        type
    } 
    return getAxios().post(`${process.env.REACT_APP_CLIENTS_BASE_URL}/organization/${organization_id}/custom_field`, data);
}

export const editCustomFieldApi= (id, name, default_value, type) => {
    const data = {
        name,
        default_value,
        type
    } 
    return getAxios().put(`${process.env.REACT_APP_CLIENTS_BASE_URL}/organization/custom_field/${id}/edit`, data);
}

export const deleteCustomFieldApi = (id) => {
    return getAxios().delete(`${process.env.REACT_APP_CLIENTS_BASE_URL}/organization/custom_field/${id}/delete`);
}

export const addClientApi = (organization_id, name, phone_number, address, state, country, tax_id, user_defined_field, relationship_manager_ids) => {
    // var bodyFormData = new FormData();
    // for(var i = 0; i < relationship_manager_ids.length; i++){
    //     bodyFormData.append('relationship_manager_id[]', relationship_manager_ids[i]);
    // }

    var data = new FormData();
    data.set('organization_id', organization_id);
    data.set('name', name);
    data.set('phone_number',phone_number);
    data.set('address', address);
    data.set('state', state);
    data.set('country', country);
    data.set('tax_id', tax_id);
    data.append('user_defined_field', JSON.stringify(user_defined_field));
    for(var i = 0; i < relationship_manager_ids.length; i++){
        data.append('relationship_manager_id[]', relationship_manager_ids[i]);
    }
    
    const header = {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Content-Type': 'multipart/form-data'
        }
    };

    return getAxios().post(`${process.env.REACT_APP_CLIENTS_BASE_URL}/client`, data, header);
}

export const getClientsListApi = (organization_id, page, text) => {
    const param = {
        params : {
            page,
            text
        }
    }
    return getAxios().get(`${process.env.REACT_APP_CLIENTS_BASE_URL}/organization/${organization_id}/clients`, param);
}

export const editClientApi= (client_id, name, phone_number, address, state, country, tax_id,  user_defined_field, relationship_manager_ids) => {
    var data = new FormData();
    data.set('name', name);
    data.set('address', address);
    data.set('state', state);
    data.set('country', country);
    data.set('phone_number', phone_number);
    // data.set('email', email);
    data.set('tax_id', tax_id);
    data.append('user_defined_field', JSON.stringify(user_defined_field));
    for(var i = 0; i < relationship_manager_ids.length; i++){
        data.append('relationship_manager_id[]', relationship_manager_ids[i]);
    }
    
    const header = {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Content-Type': 'multipart/form-data'
        }
    };

    return getAxios().put(`${process.env.REACT_APP_CLIENTS_BASE_URL}/${client_id}`, data, header);
}

export const deleteClientApi = (id) => {
    return getAxios().delete(`${process.env.REACT_APP_CLIENTS_BASE_URL}/${id}`);
}

export const getUploadURLApi = (filename, filetype) => {
    let data = {filename, filetype}
    return getAxios().post(`${process.env.REACT_APP_CLIENTS_BASE_URL}/signs3`, data);
}

export const getDownloadURLForClientAttachmentApi = (filepath) => {
    let data = {filepath}
    return getAxios().post(`${process.env.REACT_APP_CLIENTS_BASE_URL}/getsigns3url`, data);
}

export const getInvoiceAccountsListApi = (organization_id) => {
    return getAxios().get(`${process.env.REACT_APP_CLIENTS_BASE_URL}/organization/${organization_id}/invoice_accounts/connected`);
}

export const markAsDefaultApi = (access_token, organization_id, invoice_account_id) => {
    getAxios().defaults.headers.common['Authorization'] = access_token;
    return getAxios().put(`${process.env.REACT_APP_CLIENTS_BASE_URL}/organization/${organization_id}/invoice_account/${invoice_account_id}/mark_default`);
}

export const connectInvoiceAccountToOrgApi = (organization_id, invoice_accounts) => {
        
        var bodyFormData = new FormData();
        for(var i = 0; i < invoice_accounts.length; i++){
            bodyFormData.append('invoice_accounts[]', invoice_accounts[i]);
        }
        
        const header = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'Content-Type': 'multipart/form-data'
            }
        };

    return getAxios().post(`${process.env.REACT_APP_CLIENTS_BASE_URL}/organization/${organization_id}/invoice_accounts/connect`, bodyFormData, header);
}

export const addContactApi = (organization_id, first_name, last_name, title, email, phone_number, clients) => {
    const data = {
        organization_id,
        first_name,
        last_name,
        title,
        email,
        phone_number,
        clients 
    } 
    return getAxios().post(`${process.env.REACT_APP_CLIENTS_BASE_URL}/contact`, data);
}

export const getContactListApi = (organization_id) => {

    return getAxios().get(`${process.env.REACT_APP_CLIENTS_BASE_URL}/organization/${organization_id}/contacts`);
}

export const editContactApi= (organization_id, id, first_name, last_name, title, email, phone_number, clients) => {
    const data = {
        organization_id,
        first_name,
        last_name,
        title,
        email,
        phone_number,
        clients
    } 
    return getAxios().put(`${process.env.REACT_APP_CLIENTS_BASE_URL}/contact/${id}/update`, data);
}

export const deleteContactApi = (id, client_id, invoice_account_id) => {
    const data={
        client_id
    }

    return getAxios().delete(`${process.env.REACT_APP_CLIENTS_BASE_URL}/contact/${id}/delete`, {
        data: {
            client_id,
            invoice_account_id
        }
    });
}

export const addMultipleContactsApi = (contacts) => {
    let data = {contacts}
    return getAxios().post(`${process.env.REACT_APP_CLIENTS_BASE_URL}/contacts/multiple`, data);
}

export const fullTextSearchApi = (organization_id, text) => {
    const param = {
        params: {
            text
        }
    }

    return getAxios().get(`${process.env.REACT_APP_CLIENTS_BASE_URL}/organization/${organization_id}/contact/fulltext`, param);
}


export const getClientListForContactApi = (id) => {
    return getAxios().get(`${process.env.REACT_APP_CLIENTS_BASE_URL}/contacts/${id}/client_list`);
}

export const directDeleteContactApi = (id) => {
    return getAxios().delete(`${process.env.REACT_APP_CLIENTS_BASE_URL}/contact/${id}/direct_delete`);
}

export const autoCompleteClientApi = (organization_id, text) => {

    const param = {
        params : {
            text
        }
    }

    return getAxios().get(`${process.env.REACT_APP_CLIENTS_BASE_URL}/autocomplete_search/organization/${organization_id}/clients/`, param);
}

export const getClientDetailsObjectApi = (client_id) => {

    return getAxios().get(`${process.env.REACT_APP_CLIENTS_BASE_URL}/client/${client_id}/client_details`);
}

export const disconnectInvoiceAccountApi = (organization_id, invoice_account_id) => {

    return getAxios().post(`${process.env.REACT_APP_CLIENTS_BASE_URL}/organization/${organization_id}/invoice_account/${invoice_account_id}/disconnect`);
}