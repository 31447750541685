import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {Grid, Typography, Avatar} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';
import ArrowDropUpOutlinedIcon from '@material-ui/icons/ArrowDropUpOutlined';
import { useIsMount } from '../../components/useIsMount';
import {setSelectedDrawerState, SelectedDrawerState} from '../../actions/selectedOrganization';
import { setSelectedTemplate } from '../../actions/selectedOrganization';

const useStyles = makeStyles((theme) => ({
    leftMenuTopBar: {
        padding:'16px 12px',
        background:'#555555'
    },
    avatarStyle: {
        width:"38px !important",
        height:"38px !important",
        fontSize:'16px !important',
        fontWeight:'600 !important',
        background: '#fff !important',
        border: '1px solid #fff !important'
    },
    memberText: {
        color:'#fff',
    },
    orgNameStyle: {
        fontWeight:'500', 
        display:'flex', 
        alignItems:'center',
        marginTop:'-1px'
    }
}))

const MobileLeftComponentTopBar = (props) => {
    const classes = useStyles();
    const isMount = useIsMount();
    let fullName = props.auth?.user.firstname + ' ' + props.auth?.user.lastname;
    const { name } = props.selectedOrganization.organization ? props.selectedOrganization.organization : { name: '' };
    
    const onProfileClick = () => {
        props.setSelectedTemplate('');
        props.setSelectedDrawerState(SelectedDrawerState.SHOW_MY_PROFILE);
    }

    return (
        <div className={classes.leftMenuTopBar}>
                <Grid item xs={12} onClick={onProfileClick}>
                    <Grid item container alignItems="center">
                        <Grid item>
                            <Avatar className={classes.avatarStyle}>
                            {props.auth?.user.firstname.charAt(0).toUpperCase() +
                                props.auth?.user.lastname.charAt(0).toUpperCase()}    
                            </Avatar>
                        </Grid>
                        <Grid item className={classes.memberText}>
                            <div style={{marginLeft: "12px"}}>
                                <Typography variant="body1">
                                    {fullName}
                                </Typography>
                                <Typography variant="subtitle1" style={{fontSize:'12px', marginTop:'-4px'}}>
                                    {props.auth?.user.email}
                                </Typography>
                            </div>
                        </Grid>
                    </Grid> 
                </Grid>

                <Grid item  xs={12} style={{marginTop:'24px', color:'#fff'}}>
                    <Typography variant="subtitle1">
                        Organization
                    </Typography>

                    <Typography variant='body1' 
                        onClick={() => props.setShowOrgList(!props.showOrgList)}
                        className={classes.orgNameStyle}>
                        {name} 
                        {props.showOrgList ? 
                            <ArrowDropUpOutlinedIcon style={{marginLeft:'auto'}}/>
                            :
                            <ArrowDropDownOutlinedIcon style={{marginLeft:'auto'}}/>
                        }
                    </Typography>
                </Grid>
        </div>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    selectedTemplate: state.organizationInfo.selectedTemplate,
    startDateFilter: state.filters.startDateFilter,
    endDateFilter: state.filters.endDateFilter,
    selectedMembersFilter: state.filters.selectedMembersFilter,
    companiesFilter: state.filters.companiesFilter,
    setDrawerState: state.organizationInfo.setDrawerState,
});

const mapDispatchToProps = (dispatch) => ({
    setSelectedTemplate: (template) => dispatch(setSelectedTemplate(template)),
    setSelectedDrawerState: (drawerState) => dispatch(setSelectedDrawerState(drawerState))
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileLeftComponentTopBar);