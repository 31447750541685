import {getAxios} from './axiosUtil';

// export const signInApi = (email, password) => {
//     let data = {
//         email,
//         password
//     }
//     return getAxios().post(`${process.env.REACT_APP_BASE_URL}/login`, data)
// }

export const signInApi = (email, password, service) => {
    let data = {
        email,
        password,
        service
    }
    return getAxios().post(`${process.env.REACT_APP_AUTH_APP_BASE_URL}/user/login`, data)
}

export const signUpApi = (firstname, lastname, email, password) => {
    let data = {
        firstname,
        lastname,
        email,
        password
    }
    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/signup`, data)
}

export const forgetPasswordApi = (email) => {
    let data = {
        email
    }
    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/forget_password`, data)
}

export const updatePasswordApi = (password, jwt) => {
    let data = {
        password
    }

    getAxios().defaults.headers.common['Authorization'] = jwt;
    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/update_password`, data)
}

//get Organization List API
export const organizationListApi = () => {
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/organizations`);
}

//organization details (member + labels)
export const organizationDetailsApi = (organization_id) => {
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/organization/${organization_id}/details`);
}

//organizationtemplates
export const organizationTemplateListApi = (organization_id, start_date, end_date, 
        companiesFilter, selectedMembersFilter) => {
        
        let company_ids = '';
        let user_ids = '';
    
        if (companiesFilter && companiesFilter.length > 0) {
            company_ids = companiesFilter.map((company) => company.id).join() || '';
        }
    
        if (selectedMembersFilter && selectedMembersFilter.length > 0) {
            user_ids = selectedMembersFilter.map((member) => member.user.id).join() || '';
        }

    const param = {
        params: {
            start_date,
            end_date,
            company_ids,
            user_ids
        }
    }
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/organization/${organization_id}/templates`, param);
}

//get checklist
export const getChecklistApi = (organization_id, template_id, page, start_date, end_date, user_ids, 
    labels, status, search_text, companiesFilter, sort_by, group_slug_code, billable_status, overdue, upcoming, apiStr) => {

    let company_ids = "";
    if (companiesFilter && companiesFilter.length > 0) {
        company_ids = companiesFilter.map((company) => company.id).join() || '';
    }
    
    const param = {
        params: {
            page,
            start_date,
            end_date,
            user_ids,
            labels,
            status,
            search_text,
            company_ids,
            sort_by,
            group_slug_code,
            billable_status,
            overdue, 
            upcoming
        }
    }
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/organization/${organization_id}${apiStr}checklists`, param);
}

//Edit Checklist Group
export const editFolderApi = ( id, group_name, organization_id, template_id) => {
    let data = {
        id,
        group_name
    }

    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/checklist/grouping/${organization_id}/${template_id}`, data)
} 

//delete Folder Api
export const deleteFolderApi = ( group_id, organization_id, template_id) => {
    const param = {
            params: {
                group_id
            } 
    }
    return getAxios().delete(`${process.env.REACT_APP_BASE_URL}/checklist/grouping/${organization_id}/${template_id}`, param);
}

//create Folder Api
export const createFolderApi = (organization_id, template_id, group_name, parent_group_slug_code) => {
    const data = {
            group_name,
            parent_group_slug_code
    }
    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/checklist/grouping/${organization_id}/${template_id}`, data);

}

//export Checklist Api
export const exportChecklistApi = (organization_id, template_id, page, start_date,
    end_date, user_ids, labels, status, search_text, companiesFilter, sort_by, randomtoken) => {

    let company_ids = "";
    if (companiesFilter && companiesFilter.length > 0) {
        company_ids = companiesFilter.map((company) => company.id).join() || '';
    }
    const param = {
        params: {
            page,
            start_date,
            end_date,
            user_ids,
            labels,
            status,
            search_text,
            company_ids,
            sort_by
        }
    }
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/checklist/export_to_excel/${organization_id}/${template_id}/${randomtoken}`, param);
}

//export Checklist task
export const exportChecklistTaskApi = (checklist_id, randomtoken) => {
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/checklist_task/export_to_excel/${checklist_id}/${randomtoken}`);
}

//archive checklist api
export const archiveChecklistApi = (checklist_id) => {
    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/checklist/${checklist_id}/archive`);
}

//move to fodler api
export const moveChecklistToFolderApi = (checklist_id, group_id, start_date, end_date) => {
    /* const param = {
        params : {
            start_date,
            end_date
        }
    } */
    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/checklist/move_to_group/${checklist_id}/${group_id}${start_date ? `?start_date=${start_date}` : ''}${end_date ? `&end_date=${end_date}` : ''}`);
}

//Get Company List API
export const orgCompaniesListApi = (page, text, organization_id, access_token) => {
    const param = {
        params: {
            page,
            text
        }
    }
    getAxios().defaults.headers.common['Authorization'] = access_token;
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/company/${organization_id}`, param);
}

//Modify Members from checklist API
export const modifyMembersFromChecklistApi = (checklist_id, assignees) => {
    let data = {
        assignees
    }
    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/checklist/${checklist_id}/member`, data);
}

export const modifyApproversFromChecklistApi = (checklist_id, approvers) => {
    let data = {
        approvers
    }
    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/checklist/${checklist_id}/approver`, data);
}

export const getChecklistFoldersApi = (organization_id, template_id,) => {
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/checklist/grouping/${organization_id}/${template_id}`);
}

//Create Checklist API
export const createChecklistApi = (assignees, company_ids, approvers, schedule, organization_id, template_id, 
    start_date, schedule_type, schedule_creation_type, subtitle, billable, cost, periodicity, due_date) => {
    let data = {
        assignees,
        company_ids,
        approvers,
        schedule,
        start_date,
        schedule_type,
        schedule_creation_type,
        subtitle,
        billable, 
        cost, 
        periodicity,
        due_date
    }
    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/checklist/${organization_id}/${template_id}`, data);
}

//Update checklist due date api
export const updateCheckListDueDateApi = (checklist_id, due_date) => {
    let data = {
        due_date
    }
    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/checklist/${checklist_id}/due_date`, data);
}


//modify or update checklist label list
export const modifyLabelsFromChecklistApi = (checklist_id, labels) => {
    let data = {
        labels
    }
    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/checklist/${checklist_id}/label`, data);
}

//Complete Checklist API
export const completeChecklistApi = (checklist_id, start_date, end_date, user_ids, company_ids) => {
    //${company_ids === undefined ? '' : `company_ids`}=${company_ids
    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/checklist/${checklist_id}/complete?${start_date ? `start_date=${start_date}` : ''}${end_date ? `&end_date=${end_date}` : ''}${user_ids ? `&user_ids=${user_ids}` : ''}${company_ids ? `&company_ids=${company_ids}` : ''}`);
    //return getAxios().post(`${process.env.REACT_APP_BASE_URL}/checklist/${checklist_id}/complete`, param);
}

//waiting for my approval page
export const waitingForApprovalChecklistApi = (access_token, organization_id, page, search_text, approved_checklist) => {

    const param = {
        params: {
            page,
            search_text,
            approved_checklist
        }
    }
    getAxios().defaults.headers.common['Authorization'] = access_token;
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/checklist/waiting_for_approval/${organization_id}`, param);
}
 
export const checkBackgroundTaskComplted = (randomToken) => {
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/bg_job_request/${randomToken}/check`);
}

//send checklist to approver
export const sendChecklistToApproversApi = (access_token, checklist_id) => {
    getAxios().defaults.headers.common['Authorization'] = access_token;
    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/checklist/${checklist_id}/approver/send`);
}

//approve or reject api
export const approveRejectChecklistApi = (access_token, checklist_id, aprStr) => {
    getAxios().defaults.headers.common['Authorization'] = access_token;
    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/checklist/${checklist_id}/approver/${aprStr}`);
}

export const getTaskList=(checklistItem_slug)=>{
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/checklist_task/${checklistItem_slug}`)
}

export const bulkAssignRemoveMembersApi = (organization_id, checklists, assignees, bulkStr, randomtoken) => {
    let data = {
        checklists,
        assignees
    }
    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/organization/${organization_id}/${bulkStr}/${randomtoken}`, data)
}

// getWidgetApi
export const getWidgetsApi = (checklist_id, checklist_task_id) => {
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/checklist/${checklist_id}/task/${checklist_task_id}/widgets`);
}


//Add checklistTaskMember API
export const updateTaskMemberApi = (checklist_task_id, assignees) => {
    let data = {assignees}
    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/checklist_task/${checklist_task_id}/member`, data);

}

export const bulkApplyRemoveLabelsApi = (organization_id, checklists, labels, start_date, end_date, bulkStr, randomtoken) => {
    let data = {
        checklists,
        labels
    }
    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/organization/${organization_id}/${bulkStr}/${randomtoken}${start_date ? `?start_date=${start_date}` : ''}${end_date ? `&end_date=${end_date}` : ''}`, data)
}

export const bulkCompleteChecklistApi = (organization_id, checklists, start_date, end_date, randomtoken, user_ids, company_ids) => {
    let data = {
        checklists,
    }
    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/organization/${organization_id}/bulk_complete_checklist/${randomtoken}?${start_date ? `start_date=${start_date}` : ''}${end_date ? `&end_date=${end_date}` : ''}${user_ids ? `&user_ids=${user_ids}` : ''}${company_ids ? `&company_ids=${company_ids}` : ''}`, data);
}

export const downloadExportedFile = (randomToken) => {
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/download/${randomToken}`);
}

export const bulkSetChecklistDueDateApi = (organization_id, checklists, due_date, randomtoken) => {
    let data = {
        checklists,
        due_date
    }

    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/organization/${organization_id}/bulk_checklist_set_duedate/${randomtoken}`, data);
 
}


//Update url in "checklist task" wiget API (upload widget)
export const updateFileUploadUrlApi = (task_id, widget_id, path) => {
    let data = {
        path
    }
    return getAxios().put(` ${process.env.REACT_APP_BASE_URL}/checklist_task/${task_id}/widget/${widget_id}/upload`, data);
}

export const bulkMoveToFolderApi = (organization_id, checklists, start_date, end_date, group_id, randomtoken) => {
    let data = {
        checklists,
        group_id
    }
    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/organization/${organization_id}/bulk_checklist_move_to_group/${randomtoken}${start_date ? `?start_date=${start_date}` : ''}${end_date ? `&end_date=${end_date}` : ''}`, data);
}


// api/v1/activity_feed/<organization_id>/task/<task_id>

//get comment list 
export const getCommentsApi = (task_id, organization_id) => {
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/activity_feed/${organization_id}/task/${task_id}`);
}

 
//add Checklist task comment api
export const addNewCommentApi = (checklist_task_id, mention_users, text, organization_id) => {
    let data = {
        mention_users,
        text
    };

    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/comments/${organization_id}/checklist_task/${checklist_task_id}`, data);
}


//delete comment from list
export const deleteChecklistTaskCommentApi = (comment_id, checklist_task_id, organization_id) => {
    const param = {
        params: {
            comment_id
        }
    }
    return getAxios().delete(`${process.env.REACT_APP_BASE_URL}/comments/${organization_id}/checklist_task/${checklist_task_id}`, param);
}

//update comment 
export const editChecklistTaskCommentApi = (checklist_task_id, mention_users, text, comment_id, organization_id) => {
    let data = {
        mention_users,
        text,
        comment_id
    };

    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/comments/${organization_id}/checklist_task/${checklist_task_id}`, data);
}

export const addMemberToOrgApi = (service, email, role, entity_id, entity_name) => {
    let data = {
        service,
        email,
        role,
        entity_id, 
        entity_name
    }
    return getAxios().post(`${process.env.REACT_APP_AUTH_APP_BASE_URL}/user/invite`, data);
}

//Organization Member List API
export const organizationMemberListApi = (organization_id) => {
    const param = {
        params: {
            organization_id: organization_id
        }
    }

    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/organization/member`, param);
}

//Organization Edit Member API
export const editOrgMemberApi = (organization_member_id, access_level) => {
    let data = {
        organization_member_id,
        access_level
    }
    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/organization/member`, data);
}

//Remove Organization Member API
export const removeOrgMemberApi = (organization_member_id) => {
    const param = {
        params: {
            organization_member_id
        }
    }
    return getAxios().delete(`${process.env.REACT_APP_BASE_URL}/organization/member`, param);
}

//get pending invitation members list api
export const getPendingInvitationsListApi = (organization_id) => {
    
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/organization/${organization_id}/pending_invitations`);
}

//resend invitation to member to join org api 
export const resendInvitationApi = (service, email, role, entity_id, entity_name, entity_email) => {
    let data = {
        service,
        email,
        role,
        entity_id, 
        entity_name,
        entity_email
    }
    return getAxios().put(`${process.env.REACT_APP_AUTH_APP_BASE_URL}/user/resend_invite`, data);
}

//Add Label API
export const addLabelToOrgApi = (color, name, organization_id) => {
    let data = {
        color,
        name
    };

    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/label/${organization_id}`, data);
}

//Edit Label API
export const editOrgLabelApi = (id, color, name, organization_id) => {
    let data = {
        id,
        color,
        name
    }
    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/label/${organization_id}`, data);
}

//Delete Label API
export const deleteOrgLabelApi = (label_id, organization_id) => {
    const param = {
        params: {
            id: label_id
        }
    }
    return getAxios().delete(`${process.env.REACT_APP_BASE_URL}/label/${organization_id}`, param);
}

//Simiar Company Names Api
export const getSimilarNameCompaniesApi = (text, organization_id) => {
    const param = {
        params: {
            text
        }
    }
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/company/similar_name/${organization_id}`, param);
}

//Add Company to Organization API
export const addCompanyToOrgApi = (
    name, address, organization_id, user_defined_field, checklist_list, country, state, tax_id, id
) => {
    let data = {
        name,
        address,
        user_defined_field,
        checklist_list,
        country,
        state,
        tax_id,
        id
    }

    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/company/${organization_id}`, data);
}

export const editCompanyApi = (id, name, address, organization_id,
    access_token, email, user_defined_field, deleted_files, phone_number) => {
    //convert array to comma separated string
    var email1 = email.join();

    let data = {
        id,
        name,
        //pan,
        //gstin,
        address,
        email1,
        user_defined_field,
        deleted_files,
        phone_number
    }
    getAxios().defaults.headers.common['Authorization'] = access_token;
    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/company/${organization_id}`, data);
}

//Download documents for company user_define_field API
export const getDownloadUrlOfCompanyUDF = (access_token, organization_id, company_id, value, name) => {
    let data = {
        value,
        name
    };
    getAxios().defaults.headers.common['Authorization'] = access_token;
    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/organization/${organization_id}/company/${company_id}/download`, data);
}

//Decryption API
export const decryptionApi = (access_token, organization_id, private_key, cipher_text) => {
    let data = {
        private_key,
        cipher_text
    }

    getAxios().defaults.headers.common['Authorization'] = access_token;
    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/decryption/${organization_id}`, data);
}

//Archive Company API
export const deleteCompanyApi = (id, organization_id, access_token) => {
    let data = {
        id
    }
    getAxios().defaults.headers.common['Authorization'] = access_token;
    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/company/${organization_id}/archive`, data);
}

//import companyList api
export const importCompanyListApi = (file, organization_id,randomtoken) => {
    var data = new FormData();
    data.set('file', file);
    data.set('organization_id', organization_id);
   //data.set('setup_type', setup_type);
    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/companies/import/${randomtoken}`, data);
}

export const onImportInvoiceApi  = (file, organization_id,randomtoken) => {
    var data = new FormData();
    data.set('file', file);
    //data.set('organization_id', organization_id);
   //data.set('setup_type', setup_type);
    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/organization/${organization_id}/import_invoice_config/${randomtoken}`, data);
}



//verify org email 
export const verifyOrgEmailApi = (organization_id) => {
    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/organization/${organization_id}/send_email_verification_link`);
}

export const onRequestApiCall = (organization_id, sms_credit) => {

    let data = {
        sms_credit
    }
    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/organization/${organization_id}/send_sms_call_credit_request`, data);
}

//Organization Update API
export const organizationUpdateApi = (id, name, image, selectedcountry, financial_year_start_month) => {
    var data = new FormData();
    data.set('id', id);
    data.set('name', name);
    if (image) {
        data.set('image', image);
    }
    data.set('country', selectedcountry);
    data.set('financial_year_start_month', financial_year_start_month);

    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/organization_update`, data);
}

//Resend Verification Mail API
export const resendVerificationMailApi = () => {
    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/resend_mail`);
}

//update user profile api
export const updateProfileApi = (firstname, lastname, image) => {
    var data = new FormData();
    data.set('firstname', firstname);
    data.set('lastname', lastname);
    if (image) {
        data.set('image', image);
    }

    return getAxios().put(`${process.env.REACT_APP_AUTH_APP_BASE_URL}/user/update`, data);
}

//called from profile page
export const changePasswordApi = (old_password, password) => {
    let data = {
        old_password,
        password
    }
    return getAxios().post(`${process.env.REACT_APP_AUTH_APP_BASE_URL}/user/change_password`, data);
}

//Create New Organization API
export const createNewOrganizationApi = (name) => {
    let data = {
        name,
    }
    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/organization`, data);
}

//export companies
export const exportCompaniesApi = (org_id, randomtoken) => {
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/company/export_to_excel/${org_id}/${randomtoken}`);
}

//Activity Reports Api
export const getActivityReportApi = (organization_id, page, user_ids, start_date, end_date, selected) => {
    const param = {
        params: {
            user_ids, 
        }
    }
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/activity_feed/${organization_id}?view_type=${selected}&page=${page}&start_date=${start_date}&end_date=${end_date}`, param);
}


export const downloadTemplateApi = (organization_id, randomtoken) => {
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/company/export_setup_excel/${organization_id}/${randomtoken}`);
}

export const downloadInvoiceTemplateApi = (organization_id, randomtoken) => {
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/organization/${organization_id}/export_invoice_config_excel/${randomtoken}`);
}

//get notifications list
export const getNotificationListApi = (access_token, organization_id, page) => {
    const param = {
        params: {
            page
        }
    }
    getAxios().defaults.headers.common['Authorization'] = access_token;
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/notifications/${organization_id}`, param);
}

//get predefined template list api
export const getPredefinedTemplateListApi = () => {
    var client_utc_time = new Date().toISOString();
    client_utc_time = client_utc_time.substring(0, client_utc_time.length - 1);
    //consoleToLog("time1: ", client_utc_time);
    const param = {
        params: {
            client_utc_time
        }
    }

    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/predefined_template`, param);
}

export const importPredefinedTemplateApi = (organization_id, slug, template_name) => {
    let data = {
        slug,
        template_name
    };

    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/template/${organization_id}`, data);
}

export const createTemplateApi = (organization_id, name, schedule, schedule_type, schedule_creation_type, templateString, due_date_days) => {
    let data = {
        name,
        schedule,
        schedule_type,
        schedule_creation_type,
        due_date_days
    };
    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/organization/${organization_id}/${templateString}`, data);

}

export const editTemplateApi = (organization_id, templateString, id, name, schedule, schedule_type, schedule_creation_type, due_date_days) => {
    let data = {
        id,
        name,
        schedule,
        schedule_type,
        schedule_creation_type,
        due_date_days
    };
    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/organization/${organization_id}/${templateString}`, data);

}

export const createTemplateTaskApi = (organization_id, templateString, template_id, title, heading, default_status, widget_json) => {
    let data = {
        title,
        heading,
        default_status,
        widget_json
    };
    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/organization/${organization_id}/${templateString}/${template_id}/task`, data);
}

export const editTemplateTaskApi = (organization_id, templateString, template_id, id, title, heading, widget_json) => {
    let data = {
        id,
        title,
        heading,
        widget_json
    };
    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/organization/${organization_id}/${templateString}/${template_id}/task`, data);
}

export const deleteTemplateTaskApi = (organization_id, templateString, template_id, id) => {
    return getAxios().delete(`${process.env.REACT_APP_BASE_URL}/organization/${organization_id}/${templateString}/${template_id}/task?id=${id}`);
}

export const changeTemplateTaskOrderApi = (organization_id, templateString, template_id, id, order_number) => {
    const data = {
        order_number
    }

    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/organization/${organization_id}/${templateString}/${template_id}/task/${id}/change_order`, data);
}

export const getTemplateDetailsApi = (organization_id, templateString, template_slug_code) => {
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/organization/${organization_id}/${templateString}/${template_slug_code}`);
}

export const addTaskWidgetApi = (access_token, organization_id, templateString, template_id, task_id, type) => {
    const data = {
        type
    }
    getAxios().defaults.headers.common['Authorization'] = access_token;
    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/organization/${organization_id}/${templateString}/${template_id}/task/${task_id}/widget`, data);
}

export const editTaskWidgetApi = (access_token, organization_id, templateString, template_id, task_id, id, title, file_path) => {
    const data = {
        id,
        title,
        file_path
    }
    getAxios().defaults.headers.common['Authorization'] = access_token;
    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/organization/${organization_id}/${templateString}/${template_id}/task/${task_id}/widget`, data);
}

export const changeTaskWidgetOrderApi = (access_token, organization_id, templateString, template_id, task_id, widget_id, order_number) => {
    const data = {
        order_number
    }

    getAxios().defaults.headers.common['Authorization'] = access_token;
    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/organization/${organization_id}/${templateString}/${template_id}/task/${task_id}/widget/${widget_id}/change_order`, data);
}

export const deleteTaskWidgetApi = (access_token, organization_id, templateString, template_id, task_id, id) => {
    getAxios().defaults.headers.common['Authorization'] = access_token;
    return getAxios().delete(`${process.env.REACT_APP_BASE_URL}/organization/${organization_id}/${templateString}/${template_id}/task/${task_id}/widget?id=${id}`);
}

export const templatePublishUnpublishApi = (organization_id, tempString, template_id, pubString) => {
    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/organization/${organization_id}/${tempString}/${template_id}/${pubString}`);
}

export const createTemplateSchedulerApi = (access_token, organization_id, templateString, template_id, title, scheduler_type, emailId,
    email_body, email_subject, max_count, interval_days, attachment) => {
    var email_id = emailId.join();
    let data = {
        title,
        scheduler_type,
        body_data: {
            email_id,
            email_body,
            email_subject,
            attachment
        },
        max_count,
        interval_days,
    }
    getAxios().defaults.headers.common['Authorization'] = access_token;
    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/organization/${organization_id}/${templateString}/${template_id}/scheduler`, data);
}

export const editTemplateSchedulerApi = (access_token, organization_id, templateString, template_id, scheduler_id, title, scheduler_type, emailId,
    email_body, email_subject, max_count, interval_days, attachment) => {
    var email_id = emailId.join();
    let data = {
        title,
        scheduler_type,
        body_data: {
            email_id,
            email_body,
            email_subject,
            attachment
        },
        max_count,
        interval_days,
    }
    getAxios().defaults.headers.common['Authorization'] = access_token;
    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/organization/${organization_id}/${templateString}/${template_id}/scheduler/${scheduler_id}`, data);
}

export const deleteTemplateSchedulerApi = (access_token, organization_id, templateString, template_id, scheduler_id) => {
    getAxios().defaults.headers.common['Authorization'] = access_token;
    return getAxios().delete(`${process.env.REACT_APP_BASE_URL}/organization/${organization_id}/${templateString}/${template_id}/scheduler/${scheduler_id}`);
}

export const getpredefinedTemplateListApi = (organization_id) => {
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/organization/${organization_id}/predefined_templates`);
}

export const getPredefinedTemplateRulesApi = (organization_id, template_id) => {
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/organization/${organization_id}/predefined_template/${template_id}/rules`);
}

export const addPredefinedTemplateRuleApi = (organization_id, template_id, rules_json) => {
    let data = { rules_json };
    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/organization/${organization_id}/predefined_template/${template_id}/rules`, data);
}

export const editPredefinedTemplateRuleApi = (organization_id, template_id, rules_json, id) => {
    let data = {id, rules_json };
    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/organization/${organization_id}/predefined_template/${template_id}/rules`, data);
}

export const deletepredefinedTemplateRuleApi = (organization_id, template_id, id) => {
    const param = {
        params: {
            id
        }
    }
    return getAxios().delete(`${process.env.REACT_APP_BASE_URL}/organization/${organization_id}/predefined_template/${template_id}/rules`, param);
}

export const editClientInfoApi = (id, name, address, organization_id,
    access_token, emailId, phone_number, country, state, tax_id) => {
    //convert array to comma separated string
    var email = emailId.join();

    let data = {
        id,
        name, 
        address,
        email, 
        phone_number,
        country, 
        state,
        tax_id
    }
    getAxios().defaults.headers.common['Authorization'] = access_token;
    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/company/${organization_id}`, data);
}

 

export const editCustomFieldsApi = (id, name, address, organization_id,
    access_token, email, user_defined_field, deleted_files, phone_number)=> {
    
    let data = {
        id,
        name, 
        address,
        email,
        user_defined_field,
        deleted_files,
        phone_number
    }
    getAxios().defaults.headers.common['Authorization'] = access_token;
    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/company/${organization_id}`, data);
}

export const getClientAttachmentListApi = (organization_id, company_id, page, text, apiStr) => {
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/organization/${organization_id}/company/${company_id}/${apiStr}?page=${page}&text=${text}`);
}

export const getClientChecklistApi = (company_id,organization_id ,page) =>{
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/organization/${organization_id}/company/${company_id}/checklists?page=${page}`);
}

export const downloadAllAttchmentsApi = (checklist_id) =>{
    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/checklist/${checklist_id}/attachment/zip`);
}
 
export const verifyEmailApi = (queryParams) => {
    getAxios().defaults.headers.common['Authorization'] = queryParams.jwt;
    return getAxios().post(`${process.env.REACT_APP_AUTH_APP_BASE_URL}/email_verify`);
}

//for backend use only
export const updateAllUnverifiedEmailStatusApi = () => {
    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/organization/update_all_unverified_email_status`);
}
 
export const downloadTemplateAttachmentUrlApi = (access_token, template_id, scheduler_id, attachment) => {
    const data = {attachment}

    getAxios().defaults.headers.common['Authorization'] = access_token;
    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/template/${template_id}/scheduler/${scheduler_id}/attachment`, data);
}

export const removeTemplateSchedulerAttachmentApi = (access_token, template_id, scheduler_id, attachment) => {
    const data = {
        attachment
    }
    getAxios().defaults.headers.common['Authorization'] = access_token;
    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/template/${template_id}/scheduler/${scheduler_id}/attachment/delete`, data);
}

export const getUserDetailsApi = (access_token) => {
    getAxios().defaults.headers.common['Authorization'] = access_token;
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/user`);
}

export const getNotConnectInvoiceAccountsApi = (access_token) => {
    getAxios().defaults.headers.common['Authorization'] = access_token;
    return getAxios().get(`${process.env.REACT_APP_AUTH_APP_BASE_URL}/user/invoice_accounts`);
}

export const connectInvoiceAccountToOrgApi = (organization_id, invoice_accounts) => {
    const data = {invoice_accounts}
    return getAxios().post(`${process.env.REACT_APP_AUTH_APP_BASE_URL}/organization/${organization_id}/invoice_accounts/connect`, data);
}

export const markAsDefaultApi = (organization_id, invoice_account_id) => {
    return getAxios().put(`${process.env.REACT_APP_AUTH_APP_BASE_URL}/organization/${organization_id}/invoice_account/${invoice_account_id}/mark_default`);
}

export const checkIfUserIsRegisteredApi = (email) => {
    const param = {
        params: {
            email
        }
    }

    return getAxios().get(`${process.env.REACT_APP_AUTH_APP_BASE_URL}/user/is_registered`, param);
}

export const logoutApi = () => {
    return getAxios().post(`${process.env.REACT_APP_AUTH_APP_BASE_URL}/logout`);
}

export const pendingInvitationExistApi = (organization_id, email) => {
    const param = {
        params : {
            email
        }
    }

    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/organization/${organization_id}/pending_invitation_exist`, param);
}

export const createPendingInvitationApi = (organization_id, role, email) => {
    const data = {
        organization_id,
        role,
        email
    }

    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/user/invitation/create`, data);
}

export const removeScopeApi = (service, user_id) => {
    let data = {service, user_id}
    return getAxios().put(`${process.env.REACT_APP_AUTH_APP_BASE_URL}/user/remove_scope`, data);
}

export const createFirebaseTokenApi = () => {
    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/user/firebase_token/create`);
}

export const getUploadURLApi = (client_id, filename, type) => {
    let data = {filename, type}
    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/client/${client_id}/attachment/upload_url`, data);
}

export const getTemplateAttachmentUploadURLApi = (template_id, filename, type, tempString, organization_id) => {
    let data = {filename, type};

    let request_url = tempString === 'predefined_template' ? `organization/${organization_id}/${tempString}/${template_id}/attachment/upload_url` : `${tempString}/${template_id}/attachment/upload_url`; 

    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/${request_url}`, data);
}

export const getUploadUrlForSchedulerAttachmentApi = (access_token, filename, type, apiString) => {
    let data = {filename, type};

    getAxios().defaults.headers.common['Authorization'] = access_token;
    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/${apiString}/attachment/upload_url`, data);
}

export const downloadAttachmentApi = (organization_id, path, bucket) => {
    let data = {path, bucket};

    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/organization/${organization_id}/attachment/download_success`, data);
}

export const deleteCustomFieldAttachmentApi = (client_id, path) => {
    let data = {path};

    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/client/${client_id}/attachment/delete`, data);
}

export const uploadCustomFieldSuccessApi = (client_id, path) => {
    let data = {path};

    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/client/${client_id}/attachment/upload_success`, data);
}

export const getClientEmailListAPi = (access_token, organization_id, query) => {
        let param  = {
            params : {
                query
            }};

    getAxios().defaults.headers.common['Authorization'] = access_token;
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/organization/${organization_id}/client_emails`, param);
}

export const getClientsPendingTasksApi = (organization_id, clients, search_query_text) => {
    let param  = {
        params : {
            clients,
            search_query_text
        }};

return getAxios().get(`${process.env.REACT_APP_BASE_URL}/organization/${organization_id}/clients_checklists`, param);
}

export const archiveTemplateApi = (template_id) => {

    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/template/${template_id}/archive`);
}

export const createEmailFollowupApi = (organization_id, subject, body, attachments, from_email, to_emails, cc_emails, bcc_emails, checklist_ids, max_count, interval, email_draft_id  ) => {

    let data = {
        subject,
        body,
        attachments,
        from_email,
        to_emails,
        cc_emails,
        bcc_emails,
        checklist_ids,
        max_count,
        interval,
        email_draft_id
    }

    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/organization/${organization_id}/email_followup`, data);
}

export const getEmailFollowupApi = (checklist_id) => {
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/checklist/${checklist_id}/email_followups`);
}

export const disableEmailFollowupApi = (organization_id, followup_id) => {
    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/organization/${organization_id}/email_followup/${followup_id}/inactive`);
}

export const checkTemplateRulesApi = (organization_id) => {
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/organization/${organization_id}/check_template_rules`);
}

export const checkEmailDraftRuleApi = (organization_id, email_draft_id) => {
    const param = {
        params : {
            email_draft_id
        }
    }
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/organization/${organization_id}/check_email_draft`, param);
}

export const decryptEmailPathApi = (organization_id, raw_email_path) => {

    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/organization/${organization_id}/email/raw/decrypt?raw_email_path=${raw_email_path}`);
}

export const orgOnboardingUpdateApi = (organization_id, onboardingValue) => {

    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/organization/${organization_id}/onboarding/${onboardingValue}`);
}

export const updateUserLoginApi = () => {
    return getAxios().put(`${process.env.REACT_APP_AUTH_APP_BASE_URL}/user/update_user_login`);
}

export const checklistNoteReadApi = (organization_id, checklist_id) => {

    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/organization/${organization_id}/checklist/${checklist_id}/update_note_read_users`);
}

export const undoCompleteApi = (apiStr) => {

    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/${apiStr}/undo_complete`);
}

export const assignMemberToRecurringChecklist = (organization_id, assignValue) => {

    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/organization/${organization_id}/assign_member/${assignValue}`);
}

export const generateInvoiceApi = (organization_id, client_id, invoice_description, items, entity_id) => {

    let data = {
        client_id,
        invoice_description,
        items,
        entity_id
    }

    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/organization/${organization_id}/create_invoice`, data);
}

export const getCostForTemplateApi = (template_id, client_id, service_name) => {

    const param = {
        params: {
            service_name
        }
    }

    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/template/${template_id}/client/${client_id}/invoice_config_details`, param);
}


//download all clients documents
export const allAttachmentsZipApi = (organization_id, randomtoken, start_date, end_date) => {

    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/organization/all_attachment_zip/${organization_id}/${randomtoken}?start_date=${start_date}&end_date=${end_date}`);
}

export const setDefaultEntityApi = (organization_id, invoice_account_id, entity_id) => {

    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/organization/${organization_id}/invoice_account/${invoice_account_id}/set_entity/${entity_id}`);
}

export const unsetEntityApi = (organization_id) => {

    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/organization/${organization_id}/invoice_config/unset_entity`);
}

export const authenticateApi = (service) => {
    const param = {
        params: {
            service
        }
    }
    return getAxios().get(`${process.env.REACT_APP_AUTH_APP_BASE_URL}/authenticate`, param)
}
