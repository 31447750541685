import React, {useState, useEffect} from 'react';
import {Grid, Tooltip, Button, TextField, Typography, FormControlLabel, Checkbox, CircularProgress} from '@material-ui/core';
import {connect} from 'react-redux';
import { setSearchLabelFilter} from '../../actions/filters';
import {useIsMount} from '../useIsMount';
import useOuterClick from '../checklist/useOuterClick';

const BulkApplyLabels = (props) => {
    const [selectedLabel, setSelectedLabel] = useState(undefined);
    const [isActiveAdd, setIsActiveAdd] = useState(false);

    const isMount = useIsMount();

    useEffect(() => {
        props.setSearchLabelFilter('');
    }, []);

    useEffect(() => {
        if(isMount) {

        } else {
            if(!props.bulkLoading) {
                setSelectedLabel(undefined);
            }
        }

    }, [isActiveAdd]);

    const onClickAdd = (e) => {
        setIsActiveAdd(!isActiveAdd);
    }

    const onLabelChecked = (e, checkedLabel) => {
        setSelectedLabel(checkedLabel);
        props.onApplyBulkLabel(checkedLabel);
            
    }

    const innerRef = useOuterClick(ev => {  
        setIsActiveAdd(false);
    });

    const handleTextFieldChange = (e) => {
        const name = e.target.value;
        props.setSearchLabelFilter(name);
    }

    return (
        <Grid item container lg={2}
            ref={innerRef}
        >
            <Grid item style={{position:"relative", marginRight: "1px"}}>
                {(!props.disabledAssignChecklistLabels) && <Tooltip title="Add Label" arrow >
                    <Button aria-label="delete"
                        disabled={props.bulkLoading && selectedLabel === undefined}
                        className="checklistBulk__icons"
                        onClick={(e) => onClickAdd(e)}
                    >
                        <i className="flaticon-add-1"></i>
                    </Button>
                </Tooltip>}  
                {
                    isActiveAdd &&
                    <Grid item
                        className={`menu2 ${isActiveAdd ? "active bulkmenuStyles" : "inactive"}`}
                        //style={{padding: "4px 20px", width:'220px', overflow:'auto', height:'500px', scrollbarWidth:'none'}}
                    >
                        <TextField id="label_input" 
                            fullWidth
                            label="Search Labels" 
                            style={{ marginBottom: "8px"}}
                            //value={textFieldValue} 
                            onChange={handleTextFieldChange}
                        />
                        
                        <Typography variant="button" gutterBottom >
                            Labels {props.bulkLoading && <CircularProgress size={15} 
                                                                color="primary" 
                                                                style={{marginLeft:'10px'}}/> }
                        </Typography>
                        {props.labels && props.labels.length > 0 && props.labels.map((label, index)=>{
                            return(
                                <Tooltip title={label.name} arrow  key={label.id+"_"+index}>
                                    <FormControlLabel
                                        key={label.id+"_"+index}
                                        control={
                                        <Checkbox
                                            disabled={props.bulkLoading && selectedLabel !== label.id}
                                            checked={selectedLabel === label.id}
                                            onChange={(e) => onLabelChecked(e, label.id)}
                                            name="checkedB"
                                            color="primary"
                                        />
                                        }
                                        style={{ display:"block",
                                            whiteSpace: "nowrap", 
                                            maxWidth: "auto", 
                                            overflow: "hidden",
                                            verticalAlign:'middle',
                                            marginRight:'0px',
                                            color:`${label.color}`,
                                            textOverflow: "ellipsis" }}
                                        label={label.name}
                                />
                              </Tooltip>
                            )
                        })}
                    </Grid>    
                } 
            </Grid>
            
        </Grid>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    labels: state.organizationInfo.labels.filter((label) => {
        return label.name.toLowerCase().includes(
            state.filters.searchLabelFilter.toLowerCase()
        )
    }),
});

const mapDispatchToProps = (dispatch) => ({
    setSearchLabelFilter: (name) => dispatch(setSearchLabelFilter(name)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BulkApplyLabels);