import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { getReadableActivityInHTML } from "../util/AppUtil";

const useStyles = makeStyles((theme) => ({
  ruleText: {
    // padding: "4px",
    fontSize: "14px",
  },
}));



const RuleItem = (props) => {
  const classes = useStyles();
  const innerHtmlContent = getReadableActivityInHTML(
    props.rule.rule_plain_string
  );

  const handleRule = (ruleId) => {
    if(props.fromChecklistDetails) {
      props.handleChecklistDeleteRule(ruleId);
    } else {
      if(props.fromPredefinedTemplate) {
        props.handlepredefinedDeleteRule(ruleId)
      } else {
        props.handleDeleteRule(ruleId);
      }
    }
  }

  const handleEditRule = (rule) =>{
    props.editRule(rule);
  }
  return (
    <Grid className={props.fromChecklistDetails ? "ruleItemContainer1" : "ruleItemContainer"} sm={12}>
      <Grid item container direction="row" className="notesContainer" sm={12}>
        <Grid item direction="column" sm={11} className={classes.ruleText}>
        <p dangerouslySetInnerHTML={{ __html: innerHtmlContent }}></p>
        </Grid>
        <Grid
          item
          container
          direction="column"
          sm={1}
          alignItems="center"
          justifyContent="center"
          style={{ paddingLeft: "4rem" }}
        >
          <Grid item className="iconsContainer">
            <i
              className="flaticon-edit"
              style={{ fontSize: "18px", cursor: "pointer" }}
              title="Edit"
              onClick={()=>{handleEditRule(props.rule)}}
            ></i>
            <i
              className="flaticon-delete-1"
              style={{
                fontSize: "18px",
                margin: "0 15px",
                cursor: "pointer",
              }}
              title="Delete"
              onClick={() => { handleRule(props.rule.id) }}
            ></i>
          </Grid>
        </Grid>
      </Grid>
    </Grid>

  );
};

export default RuleItem;
