import React, {useState, useEffect} from 'react';
import  Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import {connect} from 'react-redux';
import { useIsMount } from './useIsMount';
import { makeStyles } from '@material-ui/core/styles';

const filter = createFilterOptions();

const useStyles = makeStyles((theme) => ({
    MuiAutocompleteinputRoot : {
        '& .MuiOutlinedInput-root' : {
        padding:'1px 1px 1px 4px',
        borderRadius: '5px',
        }
    },
    customTextField: {
        "& input::placeholder": {
          fontSize: "13px",
        }
      }
}))

const TemplateAutocomplete = (props) => {
    const [value, setValue] = useState(null);
    const isMount = useIsMount();
    const classes = useStyles();
    let templateList = props.templates.filter((template) =>  template.name !== 'All')

    useEffect(() => {
        if(isMount) { 

        } else {
            props.selectedTemplate(value);
        }
    }, [value])

    const handleChange = (event, newValue) => {
        if (typeof newValue === 'string') {
            setValue({
                title: newValue,
            });
            } else if (newValue && newValue.inputValue) {
            // Create a new value from the user input
            setValue({
                title: newValue.inputValue,
            });
            } else {
            setValue(newValue);
            }
    }

    const filterOptions = (options, params) => {
        const filtered = filter(options, params);
        // Suggest the creation of a new value
        if (params.inputValue !== '') {
        filtered.push({
            inputValue: params.inputValue,
            title: `Add "${params.inputValue}"`,
        });
        }

        return filtered;
    }

    const getOptionLabel = (option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
            return option;
            }
            // Add "xxx" option created dynamically
            if (option.inputValue) {
            return option.inputValue;
            }
            // Regular option
            return option.name;
    }

    return (
        <Autocomplete
            value={value}
            onChange={(event, newValue) => handleChange(event, newValue)}
            filterOptions={(options, params) => filterOptions(options, params)}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id="template-autocomplete-input"
            options={templateList}
            getOptionLabel={(option) => getOptionLabel(option)}
            renderOption={(option) => option.name}
            style={{ width: '100%', background: props.fromMobileComponent && '#fff' }}
            freeSolo
            classes={{
                root: classes.MuiAutocompleteinputRoot
            }}
            renderInput={(params) => (
                <TextField {...params} 
                    placeholder="Select Template" 
                    variant="outlined" 
                    classes={{
                        root: classes.customTextField
                    }}    
                />
            )}
        />
            );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    templates: state.organizationInfo.templates
})

export default connect(mapStateToProps)(TemplateAutocomplete);