import React, {useState, useEffect} from 'react';
import {Drawer, AppBar, Toolbar, Typography, IconButton, Grid, TextField, MenuItem, Button, CircularProgress, Tooltip} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { makeStyles } from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {consoleToLog, restructureChecklistArray, getUnCommonItems} from '../../util/AppUtil';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import { useSnackbar } from 'notistack';
import { generateInvoiceApi } from '../../services/authService';
import {updateInvoiceAccountForClientApi} from '../../services/invoiceService';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { useIsMount } from '../useIsMount';
import CheckIcon from '@material-ui/icons/Check';
import { getCostForTemplateApi } from '../../services/authService';

const useStyles = makeStyles((theme) => ({
    appBar: {
        background: "#F9F9F9",
        height: "45px",
        color: "#202020",
    },
    customMarginTop: {
        marginTop:'8px'
    },
    customMarginBottom: {
        marginBottom:'-8px',
        color:'grey',
        fontWeight:600
    },
    MuiFormControlroot: {
        "& .MuiOutlinedInput-input": {
            paddingLeft: "16px",
        },
        "& .MuiSelect-select.Mui-disabled": {
            cursor: "default !important",
            background: "#EEEEEE !important",
        },
        "& .MuiInputBase-input.Mui-disabled": {
            background: "#EEEEEE !important",
            color: "#555555 !important",
        },
    },
    lineContainer: {
        padding:'12px 4px 12px 12px',
        background:'#f7f7f7',
        borderRadius:'5px',
        marginBottom:'16px'
    },
    textEllipsis: {
        maxWidth: '270px',
        wordBreak: 'break-word'
    },
    customMarginTop1:{
        marginTop:"16px"
    },
    buttonWidth: {
        minWidth:'15px',
        padding:'8px'
    },
    textFieldBackground: {
        background:'#fff'
    },
    lineItemsLabel: {
        color:'grey',
        fontWeight:600
    },
    noItemsPlaceholderContainer: {
        padding: '0px 16px 0px 16px',
        height: 'calc(100vh - 180px)',
        overflow: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent:'center'
    },
    placeholderStyle: {
        fontSize:'18px',
        fontWeight: 600,
        textAlign:'center',
        marginTop:'24px'
    }
}))

const GenerateInvoiceModal = (props) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    const isMount = useIsMount();
    
    const [selectedEntityId, setSelectedEntityId] = useState('-1');
    const [lineItems, setLineItems] = useState([]);
    const [clientList, setClientList] = useState([]);
    const [selectedClientId, setSelectedClientId] = useState('-1');
    const [invoiceDescription, setInvoiceDescription] = useState('');
    const [loading, setLoading] = useState(false);
    const [unCommonItemsArr, setUncommonItemsArr] = useState([]);

    const entityList = props.accountList[0].entities;
    // useEffect(() => {
    //     if(props.selectedChecklistArr && props.selectedChecklistArr.length > 0) {
    //         let uniqueArray = removeDuplicateCompanies();
    //         setClientList(uniqueArray);
    //         if(uniqueArray && uniqueArray.length === 1) setSelectedClientId(uniqueArray[0]?.id);
    //         setLineItems(restructureChecklistArray(props.selectedChecklistArr));
    //         consoleToLog('originalArray****', props.selectedChecklistArr);
    //         consoleToLog('restructuredArray****', restructureChecklistArray(props.selectedChecklistArr));
    //     }
    // }, []);

    

    useEffect(() => {
        let uniqueArray = removeDuplicateCompanies();
        setClientList(uniqueArray);
        if(uniqueArray && uniqueArray.length === 1) 
            {
                setSelectedClientId(uniqueArray[0]?.id);
            } else {
                setSelectedClientId('-1');
            }
        setLineItems(restructureChecklistArray(props.selectedChecklistArr, lineItems));
        setUncommonItemsArr(getUnCommonItems(props.selectedChecklistArr, lineItems));

    }, [props.selectedChecklistArr]);

    useEffect(() => {
        if(!isMount) {
            if(unCommonItemsArr && unCommonItemsArr.length > 0) {
                getCostForTemplate(lineItems.length - 1);
            }
        }
    }, [unCommonItemsArr]);

    const removeDuplicateCompanies = () => {
        const clientArr = props.selectedChecklistArr.map((item) => item.company);
        let jsonObject = clientArr.map(JSON.stringify);
        let uniqueSet = new Set(jsonObject);
        let uniqueArray = Array.from(uniqueSet).map(JSON.parse);
        return uniqueArray
    }

    const onChangeAccountClick = (e) => {
        const value = e.target.value;
        setSelectedEntityId(value);
    } 
    
    const onChangeClientClick = (e) => {
        const value = e.target.value;
        setSelectedClientId(value);
    } 

    const onItemNameChange = (e, index) => {
        const updatedArray = lineItems;
        updatedArray[index].item_name = e.target.value;
        setLineItems([...updatedArray]);
    }

    const onItemDescriptionChange = (e, index) => {
        const updatedArray = lineItems;
        updatedArray[index].item_description = e.target.value;
        setLineItems([...updatedArray]);
    }

    const onItemCostChange = (e, index) => {
        const updatedArray = lineItems;
        updatedArray[index].cost = Number(e.target.value);
        setLineItems([...updatedArray]);
    }
    
    const onDeleteItemClick = (index) => {
        const updatedArray = lineItems.filter((item, i) => i !== index);
        if(updatedArray && updatedArray.length === 0) {
            setLineItems([]);
           // setTemplateArr([]);
            const unCheckSelectedChecklist = props.selectedChecklistArr.filter((item) => !lineItems[index]?.checklist_ids?.includes(item.id));
            props.setSelectedChecklistArr(unCheckSelectedChecklist);
        } else {
            setLineItems(updatedArray);
            const unCheckSelectedChecklist = props.selectedChecklistArr.filter((item) => !lineItems[index]?.checklist_ids?.includes(item.id));
            consoleToLog('unselected Checklist***', unCheckSelectedChecklist);
            props.setSelectedChecklistArr(unCheckSelectedChecklist);
        }
        
    }

    const onEditItemClick = (e, index) => {
        const updatedArray = lineItems;
        updatedArray[index].edit = true;
        setLineItems([...updatedArray]);
    }

    const onCloseEditClick = (e, index) => {
        const updatedArray = lineItems;
        updatedArray[index].edit = false;
        setLineItems([...updatedArray]);
    }

    const onGenerateInvoiceClick = () => {

        const invoice_description = invoiceDescription;
        if(invoice_description === '') {
            enqueueSnackbar('Please enter invoice description!!!', {variant:'error'});
            return;
        }

        const invoice_account_id = selectedEntityId;
        if(invoice_account_id === '-1') {
            enqueueSnackbar('Please select invoice account!!!', {variant:'error'});
            return;
        }

        const client_id = selectedClientId;
        if(client_id === '-1') {
            enqueueSnackbar('Please select company!!!', {variant:'error'});
            return;
        }

        const items = lineItems.map((item) => {
            delete item.template_id;
            delete item.edit;
            delete item.template_data;
            delete item.client_id;
            delete item.loading;
            return {
                ...item,
            }
        });

        const organization_id = props.selectedOrganization.organization.id;
        const old_invoice_acct_id = '';
        const new_invoice_acct_id = selectedEntityId;
        const client_organization_id = props.selectedOrganization?.organization.id

        setLoading(true);
        callGenerateInvoiceApi(client_organization_id, client_id, invoice_description, items)
    }

    const callGenerateInvoiceApi = (organization_id, client_id, invoice_description, items) => {
        generateInvoiceApi(organization_id, client_id, invoice_description, items, selectedEntityId)
            .then((response) => {
                const res =  response.data;
                consoleToLog("Response generateInvoiceApi: ", res);
                enqueueSnackbar(res.message, {variant:'success'});
                setLoading(false);
                setLineItems([]);
                props.handleDrawerClose();
                props.setSelectedChecklistArr([]);
                props.setAddToCart(false);
            })
            .catch((e) => {
                consoleToLog("Error generateInvoiceApi: ", e.response);
                setLoading(false);
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {variant:'error'});
                    return;
                }
            })
    }

    const onExitInvoiceModeClick = () => {
        setLineItems([]);
        props.handleDrawerClose();
        props.setSelectedChecklistArr([]);
        props.setAddToCart(false);
    }

    const getCostForTemplate = (index) => {
            const updatedArray = lineItems;
            const template_id = updatedArray[index]?.template_data?.template_id;
            const client_id = updatedArray[index]?.client_id;
            const service_name = updatedArray[index]?.template_data.scheduled ? '' : updatedArray[index].item_description;

            getCostForTemplateApi(template_id, client_id, service_name)
                .then((response) => {
                    const res = response.data;
                    consoleToLog('Response getCostForTemplateApi', res);

                    updatedArray[index].loading = false;
                    updatedArray[index].cost = res.invoice_config.cost;
                    setLineItems([...lineItems]);
                })
                .catch((e) => {
                    consoleToLog('Error getCostForTemplateApi: ', e);
                    if(e.response.data && e.response.data.message) {
                        return;
                    }
                })
    }

    return (
        <Drawer
            open={props.openDrawer}
            onClose={props.handleDrawerClose}
            anchor="right"
            className="generateInvoiceDrawer"
            variant='persistent'
        >
            <AppBar position="static" className={classes.appBar} elevation={0}>
                <Toolbar style={{ minHeight: "40px", padding: "0px 16px" }}>
                    <Typography
                    variant="body1"
                    style={{ fontWeight: "600", marginRight: "auto" }}
                    >
                        Generate Invoice
                    </Typography>
                    <IconButton
                        style={{ color: "#202020", marginRight: "-16px" }}
                        onClick={() => {
                            props.handleDrawerClose();
                        }}
                    >
                        <ClearIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>

            {lineItems && lineItems.length > 0 ?
                <div className='modal__main'>
                    <Grid item md={12} >
                        <Typography variant='subtitle1' className={classes.customMarginBottom}>
                            Invoice Description
                        </Typography>
                        <TextField
                            fullWidth
                            variant="outlined"
                            margin="dense"
                            onChange={(e) => setInvoiceDescription(e.target.value)}
                            value={invoiceDescription}
                            classes={{root: classes.MuiFormControlroot}}
                            placeholder='Enter Description'
                            >
                        </TextField>       

                    </Grid>

                    <Grid item md={12} className={classes.customMarginTop}>
                        <Typography variant='subtitle1' className={classes.customMarginBottom}>
                            Bill From:
                        </Typography>
                        <TextField
                            fullWidth
                            variant="outlined"
                            margin="dense"
                            onChange={onChangeAccountClick}
                            select
                            value={selectedEntityId}
                            classes={{root: classes.MuiFormControlroot,}}
                            >
                            <MenuItem value={"-1"} disabled={selectedEntityId !== "-1" && true}>
                                Select Entity
                            </MenuItem>
                            {entityList && entityList.length > 0 && entityList.map((entity) => {
                                return (
                                <MenuItem key={entity.id} value={entity.id}>
                                    {entity.name}
                                </MenuItem>
                                );
                            })}
                        </TextField>       

                    </Grid>

                    <Grid item md={12} className={classes.customMarginTop}>
                        <Typography variant='subtitle1' className={classes.customMarginBottom}>
                            Bill To:
                        </Typography>
                        <TextField
                            fullWidth
                            variant="outlined"
                            margin="dense"
                            onChange={onChangeClientClick}
                            select
                            value={selectedClientId}
                            classes={{root: classes.MuiFormControlroot}}
                            >
                            <MenuItem value={"-1"} disabled={selectedClientId !== "-1" && true}>
                                Change Company
                            </MenuItem>
                            {clientList && clientList.length > 0 && clientList.map((client) => {
                                return (
                                <MenuItem key={client.id} value={client.id}>
                                    {client.name}
                                </MenuItem>
                                );
                            })}
                        </TextField>       

                    </Grid>

                    <Grid item md={12} className={classes.customMarginTop1}>
                        <Typography variant='subtitle1' className={classes.lineItemsLabel}>
                            Line Items
                        </Typography>
                        {
                            lineItems && lineItems.length > 0 &&
                            lineItems.map((item, index) => {
                                return <Grid item  className={classes.lineContainer}>
                                            <Grid item container alignItems='center' spacing={2}>
                                                <Grid item md={7}>

                                                    <Grid item md={12}>
                                                        {item?.edit
                                                        ?
                                                        <TextField 
                                                            variant='outlined'
                                                            fullWidth
                                                            margin='dense'
                                                            value={item?.item_name}
                                                            onChange={(e) => onItemNameChange(e, index)}
                                                            className={classes.textFieldBackground}
                                                        />
                                                        :
                                                        <Typography variant='subtitle1'>
                                                            {item?.item_name}
                                                        </Typography>
                                
                                                        }
                                                    </Grid>

                                                    <Grid item md={12}>
                                                        {item?.edit
                                                            ?
                                                            <TextField 
                                                                variant='outlined'
                                                                fullWidth
                                                                margin='dense'
                                                                value={item?.item_description}
                                                                onChange={(e) => onItemDescriptionChange(e, index)}
                                                                className={classes.textFieldBackground}
                                                            />
                                                            :
                                                            <Typography variant='subtitle1' className={classes.textEllipsis}>
                                                                {item?.item_description}
                                                            </Typography>
                                                        }
                                                    </Grid>
                                                </Grid>

                                                <Grid item md={3}>
                                                    <Grid item md={12}>
                                                        {item?.edit
                                                            ?
                                                            <TextField 
                                                                variant='outlined'
                                                                fullWidth
                                                                margin='dense'
                                                                value={item?.cost}
                                                                onChange={(e) => onItemCostChange(e, index)}
                                                                className={classes.textFieldBackground}
                                                            />
                                                            :
                                                            <Typography variant='body2'>
                                                                {item.loading ? <CircularProgress size={18} color='primary'/> : item?.cost}
                                                            </Typography>
                                                        }
                                                    </Grid>
                                                </Grid>

                                                <Grid item md={2}>
                                                    <Grid item container>
                                                        {item?.edit 
                                                        ?
                                                        <Tooltip title='Save Changes' arrow>
                                                            <IconButton 
                                                                onClick={(e) => onCloseEditClick(e, index)}
                                                                className={classes.buttonWidth}>
                                                                <CheckIcon fontSize='small' style={{fontSize:'18px'}}/>
                                                            </IconButton>
                                                        </Tooltip>
                                                        :
                                                        <Tooltip title='Edit Item' arrow>
                                                            <IconButton 
                                                                onClick={(e) => onEditItemClick(e, index)}
                                                                className={classes.buttonWidth}>
                                                                <EditOutlinedIcon fontSize='small' style={{fontSize:'18px'}}/>
                                                            </IconButton>
                                                        </Tooltip>
                                                        }
                                                        
                                                        <Tooltip title='Delete Item' arrow>
                                                            <IconButton 
                                                                onClick={() => onDeleteItemClick(index)}
                                                                className={classes.buttonWidth}>
                                                                <DeleteOutlinedIcon fontSize='small' style={{fontSize:'18px'}}/>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Grid>
                                                    
                                                </Grid>
                                            </Grid>
                                        </Grid>
                            })
                        }

                    </Grid>
                    

                <div className="modal__footer1" >
                    <Button variant="contained" color="primary" 
                        className="modal__button"
                        onClick={onGenerateInvoiceClick}
                        >
                        {loading && <CircularProgress size={24} style={{marginRight:'8px'}}/>}
                        Generate
                    </Button>
                    <Button variant="outlined" 
                        onClick={onExitInvoiceModeClick}
                        style={{
                            minWidth: '130px',
                        }}>
                        Exit Invoicing Mode 
                    </Button>
                </div>
            </div>
            :
            <div className={classes.noItemsPlaceholderContainer}>
                <Grid item md={12}>
                    <img src={'/images/generate_invoice_placeholder.svg'} width='480px' height='280px'/>
                        <Typography variant='body1' className={classes.placeholderStyle}>
                            Please add items to generate invoice
                        </Typography>
                    <div style={{margin:'16px 0px', textAlign:'center', fontWeight:600}}>
                        <Typography variant='body1' style={{fontWeight:600}}>
                            OR                        
                        </Typography>
                    </div>
                    <div style={{display:"flex", justifyContent:"center"}}>
                        <Button variant='contained' color='primary' onClick={onExitInvoiceModeClick}>
                            Exit Invoicing Mode 
                        </Button>
                    </div>
                </Grid>
            </div>
            }
        </Drawer>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    accountList: state.organizationInfo.accountList
})

export default connect(mapStateToProps)(GenerateInvoiceModal);