import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Grid, Typography, Button, Avatar, IconButton, Popover, ListItem, Link, CircularProgress} from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';
import { useIsMount } from '../../../components/useIsMount';
import { useSnackbar } from 'notistack';
import { getExtension } from '../../../util/AppUtil';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { deleteChecklistAttachmentApi, getChecklistAttachmentUrlApi, getTaskAttachmentUrlApi} from "../../../services/ChecklistService";
import {connect} from 'react-redux';
import { consoleToLog } from '../../../util/AppUtil';
import MobileUploadAttachmentDrawer from './MobileUploadAttachmentDrawer';
import {downloadAttachmentApi} from '../../../services/authService';

const useStyles = makeStyles((theme) => ({
    MuiButtonoutlined : {
        borderColor:'#0071FF !important',
        color: '#0071FF !important'
    },
    attachmentItemContainer: {
        background:'#fff',
        borderRadius:'5px',
        padding:'10px',
        marginBottom:'16px'
    },
    attachmentTitle: {
        fontSize:'14px',
        fontWeight:'500',
        color:'#333333'
    },
    attachmentPathname: {
        fontSize:'12px',
        color:'#555555',
        maxWidth:'200px',
        overflow:'hidden',
        whiteSpace:'nowrap',
        textOverflow:'ellipsis'
    },
    actionsContainer: {
        marginLeft:'auto', 
        display:'flex', 
        flexDirection:"column", 
        alignItems:'center',
        marginTop:'-4px'
    }
}))

const MobileChecklistAttachment = (props) => {
    const classes = useStyles();
    const isMount = useIsMount();
    const {enqueueSnackbar} = useSnackbar();


    const [attachmentList, setAttachmentList] = useState(props.attachmentList);
    const [anchorEl, setAnchorEl] = useState(null);
    const [downloadableUrl, setDownloadableUrl] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);

    const handleDrawerOpen =  () => {
        setOpenDrawer(true);
    }

    const handleDrawerClose =  () => {
        setOpenDrawer(false);
    }

    useEffect(() => {
        if(!isMount) {
            if (downloadableUrl !== undefined) {
            document.getElementById("initiateDownloadAttachment").href = downloadableUrl;
            document.getElementById("initiateDownloadAttachment").click();
            }
        }
      }, [downloadableUrl]);

     //open popover
     const open = Boolean(anchorEl);
     const id = open ? 'simple-popover' : undefined;

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handlePopoverClose = () => {
        setAnchorEl(null);
    }

    const showActionsPopover = (attachmentItem) => {
        return (
            <Popover id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
                }}
                transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
                }}>
                
                <ListItem onClick={() => handleDownloadAttachment(attachmentItem)}>Download
                <Link
                    className="d-none"
                    id="initiateDownloadAttachment"
                    target="_blank"
                    onClick={(e) => e.stopPropagation()}
                    download
                    ></Link>    
                </ListItem>  

                <ListItem onClick={() => deleteChecklistAttachment(attachmentItem)}>
                {loading && <CircularProgress size={20} style={{marginRight:'6px'}}/>}    Delete
                </ListItem>    
            </Popover>
        )
    }

    const handleDownloadAttachment = (fileObj) => {
        const checklist_id = props.checklistId;
        const checklist_attachments_id = fileObj.id;
        const organization_id = props.selectedOrganization.organization.id;
        const path = fileObj.path;
    
        downloadAttachmentApi(organization_id, path)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response downloadAttachmentApi: ", res);
                const downloadableURL = res.document_signed_url;
                setDownloadableUrl(downloadableURL);
                handlePopoverClose();
                //trackGAEvent(props.selectedOrganization.organization.name, 'Checklist Download Attachment', `${props.auth.user.firstname} ${props.auth.user.lastname} downloaded attachment`);

            })
            .catch((e) => {
                consoleToLog(" downloadAttachmentApi error", e.response);
            });
    };

    // Widget Checklist Delete
    const deleteChecklistAttachment = (fileObj) => {
        const checklist_id = fileObj.checklist;
        const attachment_id = fileObj.id;

        setLoading(true)
    deleteChecklistAttachmentApi(checklist_id, attachment_id)
      .then((response) => {
        const res = response.data;
        consoleToLog("Response deleteChecklistAttachmentApi ", res);
        setLoading(false);
        enqueueSnackbar(res.message, {variant: "success"});

        //trackGAEvent(props.selectedOrganization.organization.name, 'Checklist Delete Attachment', `${props.auth.user.firstname} ${props.auth.user.lastname} deleted attachment`);
        
        const updatedDeleteAttachmentArray = attachmentList.filter(
            (attachment) => attachment.id !== attachment_id
        );
        
        setAttachmentList(updatedDeleteAttachmentArray);
        handlePopoverClose();
          // to parent
         props.onAttachmentDelete(updatedDeleteAttachmentArray);
      })
      .catch((e) => {
        consoleToLog("Response deleteChecklistAttachmentApi error", e.response);
      });
  };

    const onAttachmentAdded = (updatedAttachmentsArray) => {
        props.onAttachmentAdded(updatedAttachmentsArray);
    }
    return (
        <div style={{marginTop:'16px', padding:'0px 12px'}}>
            <Grid item xs={12}>
                <Button fullWidth variant='outlined' 
                    onClick={handleDrawerOpen}
                    classes={{
                        outlined : classes.MuiButtonoutlined
                    }}>
                    Upload Attachment
                    <PublishIcon fontSize='small' style={{marginLeft:'4px'}}/>
                </Button>
            </Grid>
            
             <Grid item xs={12} style={{marginTop:'16px'}}>
            {attachmentList && attachmentList.length > 0 ?
                attachmentList.map((attachmentItem) => {

                    var AttachmentName = attachmentItem.type ? attachmentItem.json_data.path.replace(/^.*[\\\/]/, "")
                    : attachmentItem.path.replace(/^.*[\\\/]/, "");
                    
                    var uploadedBy = attachmentItem.attachment_user.firstname.charAt(0).toUpperCase() +
                    attachmentItem.attachment_user.lastname.charAt(0).toUpperCase();
                    
                    var uploadedByFullName = attachmentItem.attachment_user.firstname +
                    attachmentItem.attachment_user.lastname;

                    return <Grid item key={attachmentItem.id} 
                                className={classes.attachmentItemContainer}>
                                    <Grid item container alignItems='center'>
                                        <Grid item>
                                            <i className={getExtension(AttachmentName)}
                                                style={{ fontSize: "32px" }}
                                            ></i>
                                        </Grid>

                                        <Grid item style={{marginLeft:'12px'}}> 
                                            <Typography className={classes.attachmentTitle}>
                                               {attachmentItem.title} 
                                            </Typography>

                                            <Typography className={classes.attachmentPathname}>
                                                {AttachmentName}
                                            </Typography>
                                        </Grid>

                                        <Grid item className={classes.actionsContainer}> 
                                            <IconButton onClick={handlePopoverOpen}
                                                style={{padding:'0px'}}>
                                                <MoreHorizIcon fontSize='small'/>
                                            </IconButton>
                                            <Avatar>{uploadedBy}</Avatar>
                                            {showActionsPopover(attachmentItem)}
                                        </Grid>
                                    </Grid>
                            </Grid>
                }) : <div></div>
            
            
            }
            </Grid> 
            <MobileUploadAttachmentDrawer openDrawer={openDrawer}
                handleDrawerClose={handleDrawerClose}
                checklistId={props.checklistId}
                attachmentList={attachmentList}
                setAttachmentList={setAttachmentList}
                onAttachmentAdded={onAttachmentAdded}
            />
        </div>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
})

export default connect(mapStateToProps)(MobileChecklistAttachment);