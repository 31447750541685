import React, {useState} from 'react';
import RightToLeftSideDrawer from './RightToLeftSideDrawer';
import { CircularProgress, Grid, Tooltip, Typography, Button, Avatar} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { consoleToLog, getStatusColor } from '../util/AppUtil';
import CheckListDetailPage from '../pages/modal/CheckListDetailPage';
import {history} from '../routers/AppRouter';

const useStyles = makeStyles((theme) => ({
    drawerContainer: {
        padding:'8px 16px',
        borderBottom: '1px solid rgba(0,0,0,0.1)',
        cursor:'pointer',
        '&:hover' : {
            background:'#f7f7f7'
        },
    },
    subtitleStyle: {
        color: '#888888',
        marginLeft:'6px'
    },
    templateNameStyle: {
        color:'#0071FF',
        display:'flex',
        direction:'row'
    },
    checklistTitle1: {
        maxWidth: '40ch', 
        overflow: 'hidden', 
        whiteSpace: 'nowrap', 
        textOverflow: 'ellipsis',
        [theme.breakpoints.down(1400)] : {
            maxWidth: '35ch', 
        },
        [theme.breakpoints.down(1300)] : {
            maxWidth: '32ch', 
        },
    },
    avatarStyle: {
        width:"38px !important",
        height:"38px !important",
        fontSize:'16px !important',
        fontWeight:'600 !important',
        background:'#fff',
        color:'#202020',
        border:'1px solid #4a5568',
        display:"flex",
        direction:"row"
    },
    statusStyle1:{
        display:'flex',
        alignItems:'center'
        
        
    }
  
}))

const ChecklistReportsDrawer = (props) => {
    const classes = useStyles();

    const [selectedChecklistItem, setSelectedChecklistItem] = useState(undefined);
    const [openDialog, setOpenDialog] = useState(false);
    const [currentchecklistItem, setCurrentchecklistItem] = useState(undefined);
    const [tab, setTab] = useState(0);

    var urlPath = window.location.pathname.split("/");
    var curr_slug = urlPath[urlPath.length-1];

    const handleDetailsDialogOpen=(current_slug)=>{
        setOpenDialog(true);
        history.push(`/checklist/${current_slug}`)
        consoleToLog("opening dialog box");
    }

    const handleDetailsDialogClose=()=>{
        setOpenDialog(false);
        history.push("/dashboard")
        consoleToLog("closing dialog box");
    }

    const getCurrentchecklistItem = (checklistItem) => {
        setCurrentchecklistItem(checklistItem);
        // consoleToLog("******curr checklist************",checklistItem);
    };

    const modalStatus =()=>{
        if(window.location.toString().includes("checklist_details")){
            setOpenDialog(true)
        }
        else{
            setOpenDialog(false)
        }
    }

    const loadMoreLogic = () => {
        return (
            <Grid item container>
                <Grid item lg={12}>
                {props.nextPage && !props.isMoreLoading &&
                    <div style={{textAlign: "center", margin:'16px 0px'}}>
                        <Button type="button"
                            color="secondary"
                            variant="contained"
                            onClick={() => {
                                props.setIsMoreLoading(!props.isMoreLoading);
                            }}>Load More
                        </Button>
                    </div>}

                { props.isMoreLoading &&
                    <CircularProgress size={35} style={{position:'relative', left:'50%', right:'50%', margin:'16px 0px'}}/>
                }
                </Grid>
            </Grid>
        )
    }

    return (
        <RightToLeftSideDrawer openDrawer={props.openChecklistDrawer} 
            closeDrawer={props.handleChecklistDrawerClose}
            title="Checklists"
            fromClientDetails={props.fromClientDetails}
            createChecklist={false}>
                    <div className='modal__main2'>
                        <Grid item container direction="column">
                            {
                                props.checklistLoading ?
                                <CircularProgress size={30} style={{position:'relative', top:'24px', left:'50%', right:'50%'}}/>
                                :
                                <Grid item md={12} lg={12}>
                                {
                                    props.checkList && props.checkList.length > 0 ?
                                    props.checkList.map((checklistItem) => {
                                        return <Grid item md={12} key={checklistItem.id} 
                                                    onClick={()=>{
                                                        handleDetailsDialogOpen(checklistItem.slug_code);
                                                        getCurrentchecklistItem(checklistItem);
                                                        setTab(0);
                                                    }}
                                                    className={classes.drawerContainer}>
                                                    <Grid item container alignItems='center' justifyContent='space-between'>
                                                        <Grid item md={10}>
                                                            <Grid item md={12}>
                                                            <Tooltip title={`${checklistItem.title} (${checklistItem.subtitle})`}>
                                                                <Typography className={classes.checklistTitle1}>
                                                                    {checklistItem.title} 
                                                                    <a className={classes.subtitleStyle}>
                                                                        {checklistItem.subtitle}
                                                                    </a>
                                                                </Typography>
                                                            </Tooltip> 
                                                            </Grid>

                                                            <Grid item md={12}>
                                                                <Typography variant='body2' className={classes.templateNameStyle}>
                                                                    {/* {props.tempObj?.template_name} */}
                                                                    {checklistItem.template?.name}<br/>
                                                                    {checklistItem.checklist_members_list.map((user,index)=>{
                                                                    return(
                                                                        <Grid item key={user.id}>
                                                                            <Tooltip
                                                                            title={user.user.firstname + " " + user.user.lastname}
                                                                            arrow
                                                                            >
                                                                            <Avatar style={{marginLeft:"5px"}}>
                                                                                {user.user.firstname.charAt(0).toUpperCase() +
                                                                                user.user.lastname.charAt(0).toUpperCase()}
                                                                            </Avatar>
                                                                            </Tooltip>
                                                                        </Grid>
                                                                    )})}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item md={2}>
                                                            <Tooltip title={checklistItem.status} arrow>
                                                                <Typography variant="subtitle1" className='statusStyle' style={{
                                                                        fontSize:'12px',
                                                                        position:'relative',
                                                                        top:'2px',
                                                                        color: `${getStatusColor(checklistItem.status)}`,
                                                                        border: `1px solid ${getStatusColor(checklistItem.status)}`,
                                                                    }}>{checklistItem.status}
                                                                </Typography>
                                                            </Tooltip>
                                                        </Grid>
                                                    </Grid>
                                                    
                                                </Grid>
                                    }) :
                                    <Grid item container justifyContent='center' alignItems='center'>
                                        No Checklist Found
                                    </Grid>
                                }
                                </Grid>
                            }
                            {loadMoreLogic()}
                        </Grid>

                        {openDialog && <CheckListDetailPage  openDialog={openDialog}
                            setOpenDialog={setOpenDialog}
                            slug={curr_slug}
                            currentchecklistItem={currentchecklistItem}
                            handleDetailsDialogOpen={handleDetailsDialogOpen} 
                            handleDetailsDialogClose={handleDetailsDialogClose}
                            tabValue={tab}
                            disableCallbacks={true}/>}
                    </div>
            
        </RightToLeftSideDrawer>
    );
}

export default ChecklistReportsDrawer;

