import { database } from '../firebase/firebase';
import { consoleToLog } from '../util/AppUtil';
//import { collection, doc, onSnapshot } from "firebase/firestore";

//set organization list with their counts
export const setOrganizationsWithCount = (organizations) => ({
    type: 'SET_ORGANIZATIONS_WITH_COUNT',
    organizations
});

//subscribe to firebase updates//on each callback: store in redux
export const checkFirebaseFieldsExist = (user_id) => {
    return (dispatch, getState) => {
        return database.collection("users").doc(`${user_id}`)
            .onSnapshot(function (doc) {
                let allData = doc.data();
                if(allData !== undefined){
                    var organizations = allData.organizations;
                    consoleToLog('New firebase data organizations: ', organizations);
                    dispatch(setOrganizationsWithCount(organizations))
                }
                 
            });
    };
};
