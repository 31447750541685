import React, {useEffect, useState} from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {Grid, Typography, Button, FormControlLabel, Checkbox, Tooltip, FormGroup, Paper } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import AutocompleteTemplateTask from './AutocompleteTemplateTask';
import {approvalRulesApi} from '../services/ChecklistService';
import {connect} from 'react-redux';
import { useIsMount } from './useIsMount';
import { consoleToLog } from '../util/AppUtil';
import { useSnackbar } from 'notistack';
import {updateChecklistBusinessLoginApi} from '../services/ChecklistService';

const useStyles = makeStyles((theme) => ({
    rulesContainer: {
        width:'100vw',
        padding:'16px',
    },
    headingLabel: {
        fontSize:'18px',
        fontWeight: 600
    },
    MuiFormControlLabellabel :{
        marginLeft:'-4px',
        fontSize:'15px'
    },
    customMarginTop:{
        marginTop:'16px'
    },
    customMarginLeft:{
        marginLeft:'16px'
    },
    iconsStyle: {
        fontSize:'20px',
        cursor: 'pointer'
    },
    tooltipTitle: {
        fontSize:'15px',
        fontWeight: 600
    },
    tooltipText: {
        fontSize:'14px',
    }
}));

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[4],
        fontSize: 11,
        marginLeft:'100px !important',
        padding: theme.spacing(2),
        width: 'max-content', 
        maxWidth: '550px'
    },
    }))(Tooltip);

const TemplateApprovalRules = (props) => {
    const classes = useStyles();
    const isMount = useIsMount();
    const {enqueueSnackbar} = useSnackbar();

    const businessLogic = props.fromChecklistDetails ? props.checklistDetailsObj?.business_logic : props.templateObj?.business_logic;
    const approvalTaskList = props.taskList.filter((task) => props.fromChecklistDetails ? businessLogic?.task_needs_approval?.includes(task.template_task) : businessLogic?.task_needs_approval?.includes(task.id));
    const attachmentTaskList = props.taskList.filter((task) => props.fromChecklistDetails ? businessLogic?.task_needs_attachment?.includes(task.template_task) : businessLogic?.task_needs_attachment?.includes(task.id));

    const [checklistNeedsApproval, setChecklistNeedsApproval] = useState(businessLogic?.checklist_needs_approval);
    const [checklistNeedsAttachment, setChecklistNeedsAttachment] = useState(businessLogic?.checklist_needs_attachment);
    const [taskCompletionInOrder, setTaskCompletionInOrder] = useState(businessLogic.task_needs_completed_in_order);
    const [allTaskNeedsApproval, setAllTaskNeedsApproval] = useState(businessLogic?.all_task_needs_approval);
    const [specificTaskNeedsApproval, setSpecificTaskNeedsApproval] = useState(businessLogic?.task_needs_approval && businessLogic?.task_needs_approval.length > 0 ? true : false);
    const [specificTaskNeedsAttachment, setSpecificTaskNeedsAttachment] = useState(businessLogic?.task_needs_attachment && businessLogic?.task_needs_attachment.length > 0 ? true : false);
    const [taskListForApproval, setTaskListForApproval] = useState(approvalTaskList);
    const [taskListForAttachment, setTaskListForAttachment] = useState(attachmentTaskList);

    // useEffect(() => {
    //     if(props.business_logic) {
    //         setChecklistNeedsApproval(businessLogic?.checklist_needs_approval);
    //         setChecklistNeedsAttachment(businessLogic?.checklist_needs_attachment);
    //         setTaskCompletionInOrder(businessLogic.task_needs_completed_in_order);
    //         setAllTaskNeedsApproval(businessLogic?.all_task_needs_approval);
    //         setSpecificTaskNeedsApproval(businessLogic?.task_needs_approval && businessLogic?.task_needs_approval.length > 0 ? true : false);
    //         setSpecificTaskNeedsAttachment(businessLogic?.task_needs_attachment && businessLogic?.task_needs_attachment.length > 0 ? true : false);
    //         setTaskListForApproval(businessLogic?.task_needs_approval);
    //         setTaskListForAttachment(businessLogic?.task_needs_attachment);
    //     }
    // }, [props.business_logic]);


    useEffect(() => {
        if(!isMount) {
            updateApprovalRules();
        }   
    }, [checklistNeedsApproval, checklistNeedsAttachment, taskCompletionInOrder, allTaskNeedsApproval, taskListForAttachment]);

    useEffect(() => {
        if(!isMount) {
            if(!allTaskNeedsApproval) {
                updateApprovalRules();
            }
        }   
    }, [taskListForApproval]);

    const returnTootlipText = (option) => {
        switch (option) {
            case 'one':
                return {
                    title: "Checklist needs approval", 
                    text: "The checklist can only be marked as complete once it receives approval from atleast one team member."
                };
                case 'two':
                return {
                    title: "Checklist needs attachment", 
                    text: "The checklist can only be marked as complete only if atleast one attachment in uploaded."
                };
                case 'three':
                return {
                    title: "Task completion in order", 
                    text: "Tasks must be completed sequentially, following a order."
                };
                case 'four':
                return {
                    title: "All Task needs approval", 
                    text: "All Tasks can be marked as complete only if it is approved by atleast one team member."
                };
                case 'five':
                return {
                    title: "Specific Task needs approval", 
                    text: "The specific tasks can only be completed once it has received approval from atleast one team member."
                };
                case 'six':
                return {
                    title: "Specific Task needs attachment", 
                    text: "The specific tasks can only be completed only if atleast one attachment in uploaded."
                };
                
        
            default:
                break;
        }
    }

    const showTooltip = (option) => {
        const val = returnTootlipText(option)
        return (
                <Paper elevation={0}>
                    <Typography className={classes.tooltipTitle}>
                        {val.title}
                    </Typography>
                    <Typography className={classes.tooltipText}>
                        {val.text}
                    </Typography>
                            
                </Paper>

        )
    }

    const updateApprovalRules = () => {
        const template_id = props.templateId;
        const apiStr = props.fromChecklistDetails ? `checklist/${props.checklistId}` : `template/${template_id}`;

        const business_logic_json = {
            all_task_needs_approval: allTaskNeedsApproval,
            all_task_needs_completion: false,
            checklist_needs_approval: checklistNeedsApproval,
            checklist_needs_attachment: checklistNeedsAttachment,
            task_needs_approval: props.fromChecklistDetails ? taskListForApproval.map((task) => task.template_task) : taskListForApproval.map((task) => task.id),
            task_needs_attachment: props.fromChecklistDetails ? taskListForAttachment.map((task) => task.template_task) : taskListForAttachment.map((task) => task.id),
            task_needs_completed_in_order: taskCompletionInOrder
        }

        approvalRulesApi(business_logic_json, apiStr)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response approvalRulesApi', res);

                enqueueSnackbar(res.message, {variant:'success'});

                setSpecificTaskNeedsApproval(taskListForApproval && taskListForApproval.length === 0 ? false : true);
                setSpecificTaskNeedsAttachment(taskListForAttachment && taskListForAttachment.length === 0 ? false : true);

                if(props.fromChecklistDetails) {
                    props.checklistItemNotesArrayRev(res.checklist, true);
                    props.updateChecklistStatusAndIsCompletable(res.checklist);

                } else {
                    let obj = {
                        ...props.templateObj,
                        business_logic: res.business_logic
                    }

                    props.setTemplateObj(obj);
                }
            })
            .catch((e) => {
                consoleToLog("Error approvalRulesApi", e);
                if(e.response.data && e.response.data.message){
                    enqueueSnackbar(e.response.data.message , {variant:'error'});
                    return;
                }
                
            });

    }

    const onUpdatePublishForAll = () => {
        const template_id = props.templateId;

        updateChecklistBusinessLoginApi(template_id)
        .then((response) => {
            const res = response.data;
            consoleToLog("updateChecklistBusinessLoginApi response: ", res);
            enqueueSnackbar(res.message, {
            variant: "success",
            });
        })
        .catch((e) => {
            consoleToLog("updateChecklistBusinessLoginApi error", e);
            if(e.response.data && e.response.data.message) {
            enqueueSnackbar(e.response.data.message, {
                variant: "error",
            });
            return;

            }
        });
    }

    return (
        <Grid item md={12} className={classes.rulesContainer}>
            {!props.fromChecklistDetails &&
            <Grid item container justifyContent='flex-end' alignItems='center'>
                <Grid item>
                    <Button color='primary' variant='contained' 
                        style={{marginRight:'80px'}}
                        onClick={onUpdatePublishForAll}>
                        Publish For All
                    </Button>
                </Grid>
            </Grid>}
            <div style={{marginLeft:'16px', marginTop:'8px'}}>
                <Grid item>
                    <Typography className={classes.headingLabel}>
                        Checklist Completion
                    </Typography>

                    <FormGroup className={classes.customMarginLeft}>
                        <Grid item container alignItems='center'>
                            <FormControlLabel
                                classes={{
                                    label: classes.MuiFormControlLabellabel
                                }}
                                control={
                                <Checkbox
                                    name="checkedB"
                                    color="primary"
                                    style={{fontSize:'8px'}}
                                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                                    checked={checklistNeedsApproval}
                                    onChange={() => setChecklistNeedsApproval(!checklistNeedsApproval)}
                                    onClick={(e) => e.stopPropagation()}
                                />
                                }
                                label="Checklist Needs Approval"
                            />
                            <LightTooltip title={showTooltip('one')}>
                                <HelpOutlineOutlinedIcon className={classes.iconsStyle}/>
                            </LightTooltip>
                        </Grid>
                        <Grid item container alignItems='center'>
                            <FormControlLabel
                                classes={{
                                    label: classes.MuiFormControlLabellabel
                                }}
                                control={
                                <Checkbox
                                    name="checkedB"
                                    color="primary"
                                    style={{fontSize:'8px'}}
                                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                                    checked={checklistNeedsAttachment}
                                    onChange={() => setChecklistNeedsAttachment(!checklistNeedsAttachment)}
                                    onClick={(e) => e.stopPropagation()}
                                />
                                }
                                label="Checklist Needs Attachment"
                            />
                            <LightTooltip title={showTooltip('two')}>
                                <HelpOutlineOutlinedIcon className={classes.iconsStyle}/>
                            </LightTooltip>
                        </Grid>
                    </FormGroup>
                </Grid>

                <Grid item className={classes.customMarginTop}>
                    <Typography className={classes.headingLabel}>
                        Task Completion
                    </Typography>

                    <FormGroup className={classes.customMarginLeft}>
                        {/* <Grid item container alignItems='center'>
                            <FormControlLabel
                                classes={{
                                    label: classes.MuiFormControlLabellabel
                                }}
                                control={
                                <Checkbox
                                    name="checkedB"
                                    color="primary"
                                    style={{fontSize:'8px'}}
                                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                                    checked={taskCompletionInOrder}
                                    onChange={() => setTaskCompletionInOrder(!taskCompletionInOrder)}
                                    onClick={(e) => e.stopPropagation()}
                                />
                                }
                                label="Task Completion In Order"
                            />
                            <LightTooltip title={showTooltip('three')}>
                                <HelpOutlineOutlinedIcon className={classes.iconsStyle}/>
                            </LightTooltip>
                        </Grid> */}
                        <Grid item container alignItems='center'>
                            <FormControlLabel
                                classes={{
                                    label: classes.MuiFormControlLabellabel
                                }}
                                control={
                                <Checkbox
                                    name="checkedB"
                                    color="primary"
                                    style={{fontSize:'8px'}}
                                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                                    checked={allTaskNeedsApproval}
                                    onChange={() => {
                                            setAllTaskNeedsApproval(!allTaskNeedsApproval);
                                            if(taskListForApproval && taskListForApproval.length > 0) {
                                                setSpecificTaskNeedsApproval(false);
                                                setTaskListForApproval([]);
                                            }
                                        }}
                                    onClick={(e) => e.stopPropagation()}
                                />
                                }
                                label="All Task Needs Approval"
                            />
                            <LightTooltip title={showTooltip('four')}>
                                <HelpOutlineOutlinedIcon className={classes.iconsStyle}/>
                            </LightTooltip>
                        </Grid>
                        <Grid item container alignItems='center'>
                            <FormControlLabel
                                classes={{
                                    label: classes.MuiFormControlLabellabel
                                }}
                                control={
                                <Checkbox
                                    name="checkedB"
                                    color="primary"
                                    style={{fontSize:'8px'}}
                                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                                    checked={specificTaskNeedsApproval}
                                    onChange={() => setSpecificTaskNeedsApproval(!specificTaskNeedsApproval)}
                                    onClick={(e) => e.stopPropagation()}
                                    disabled={allTaskNeedsApproval}
                                />
                                }
                                label="Specific Task Needs Approval"
                            />
                            <LightTooltip title={showTooltip('five')}>
                                <HelpOutlineOutlinedIcon className={classes.iconsStyle}/>
                            </LightTooltip>
                        </Grid>
                        <AutocompleteTemplateTask  taskList={props.taskList}
                            taskListForApproval={taskListForApproval}
                            setTaskListForApproval={setTaskListForApproval}
                            forApproval={true} 
                            allTaskNeedsApproval={allTaskNeedsApproval}
                            specificTaskNeedsApproval={specificTaskNeedsApproval}
                            fromChecklistDetails={props.fromChecklistDetails}/>
                    </FormGroup>
                </Grid>

                <Grid item style={{marginTop:'8px'}}>
                    <FormGroup className={classes.customMarginLeft}>
                        <Grid item container alignItems='center'>
                            <FormControlLabel
                                classes={{
                                    label: classes.MuiFormControlLabellabel
                                }}
                                control={
                                <Checkbox
                                    name="checkedB"
                                    color="primary"
                                    style={{fontSize:'8px'}}
                                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                                    checked={specificTaskNeedsAttachment}
                                    onChange={() => setSpecificTaskNeedsAttachment(!specificTaskNeedsAttachment)}
                                    onClick={(e) => e.stopPropagation()}
                                />
                                }
                                label="Specific Task Needs Attachment"
                            />
                            <LightTooltip title={showTooltip('six')}>
                                <HelpOutlineOutlinedIcon className={classes.iconsStyle}/>
                            </LightTooltip>
                        </Grid>
                        <AutocompleteTemplateTask  taskList={props.taskList}
                            taskListForAttachment={taskListForAttachment}
                            setTaskListForAttachment={setTaskListForAttachment}
                            forAttachment={true}
                            specificTaskNeedsAttachment={specificTaskNeedsAttachment}
                            fromChecklistDetails={props.fromChecklistDetails}
                            />
                    </FormGroup>
                </Grid>
            </div>
        </Grid>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedTemplate: state.organizationInfo.selectedTemplate
})

export default connect(mapStateToProps)(TemplateApprovalRules);