import React, {useState, useEffect} from 'react';
import { Grid, Typography, Button, Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {getStatusColor, shouldDisable, AccessLevel, consoleToLog} from '../../../util/AppUtil';
import MobileSelectFromMembersList from '../checklist/MobileSelectFromMembersList';
import {updateTaskMemberApi, getWidgetsApi, getCommentsApi} from '../../../services/authService';
import {connect} from 'react-redux';
import MobileTaskDetails from './MobileTaskDetails';

const useStyles = makeStyles((theme) => ({
    itemListContainer: {
        background:'white',
        borderRadius: "5px",
        padding:'6px',
        marginBottom:'8px',
    },
    checKlistItemSubtitleBtn: {
        marginLeft: "-8px",
        marginRight: "-10px",
        marginTop:'6px'
    },
    checKlistItemSubtitle: {
        marginLeft: "5px",
        marginTop: "-4px",
        color: "#888888",
    },
    checklistCompleted: {
        textDecoration:'line-through',
        marginLeft:'8px',
    },
    checklistTitle: {
        marginLeft:'8px',
    },
    avatarList: {
        display: "flex",
        marginTop: "5px",
        alignItems:'center',
        pointerEvents: "auto",
        paddingLeft: "16px",
    },
}))

const MobileTaskContent = (props) => {
    const classes = useStyles();
    const [taskList, setTaskList] = useState(props.taskList);
    const [selectedTask, setSelectedTask] = useState(undefined);
    const [showTaskDetails, setShowTaskDetails] = useState(false);
    const [widgetsLoading, setWidgetsLoading] = useState(false);

    const { access_level } = props.selectedOrganization || {};
    const accessLevelManager = shouldDisable(AccessLevel.getUserAccessLevelValue(access_level),AccessLevel.MANAGER);


    const setAssignedMemberList = (assignedMemberList, taskItem) => {
        const checklist_task_id = taskItem.id;
        var assignees = assignedMemberList.map((member) => member.user.id).join();
    
        //Update Members From Checklist Api
        updateTaskMemberApi(checklist_task_id, assignees)
          .then((response) => {
            const res = response.data;
            consoleToLog("updateTaskMemberApi res", res);
            //trackGAEvent(props.selectedOrganization.organization.name, "Add Member", `${props.auth.user.firstname} ${props.auth.user.lastname} added member ${assignees} to task`);
            //send to parent
            onTaskAssigneesChanged(res.checklist_task_members, checklist_task_id);
          })
          .catch((err) => {
            consoleToLog("updateTaskMemberApi error", err.res);
          });
    }

    // Checklist task Assignee
  const onTaskAssigneesChanged = (taskAssignee, checklist_task_id) => {
    var localTaskMemberArray = taskList.map((task) => {
      if (task.id === checklist_task_id) {
        task.checklist_task_members_list = taskAssignee;
        return task;
      } else {
        return task;
      }
    });
    setSelectedTask(selectedTask);
    setTaskList(localTaskMemberArray);
  };

  const onTaskStatusChanged = (taskStatus,  activityObj, selTask) => {
    selTask.status = taskStatus;
    if(selTask.activity_feed && selTask.activity_feed.length > 0){
      selTask.activity_feed = [activityObj, ...selTask.activity_feed];
    }
    else{
      selTask.activity_feed = [activityObj];
    }

    var localTaskStatusArray = taskList.map((task) => {
      if (task.id === selTask.id) {
        return selTask;
      } else {
        return task;
      }
    });
    setSelectedTask(selTask);
    setTaskList(localTaskStatusArray);

    // to parent
    props.onTaskStatusChanged(activityObj);
  };

    // Checklist task widget
    const onWidgetUpdated = (updatedSelectedTask) => {
      consoleToLog("onWidgetUpdated in Main :updatedSelectedTask",updatedSelectedTask);
      var localTaskUploadArray = taskList.map((task) => {
        if (task.id === updatedSelectedTask.id) {
          task.checklist_task_widget = updatedSelectedTask.widgetResponse;
          return task;
        } else {
          return task;
        }
      });
      selectedTask.checklist_task_widget = updatedSelectedTask.widgetResponse;
      setSelectedTask(selectedTask);
      setTaskList(localTaskUploadArray);
    };

    const onCommentsEdited = (activity_feed, sel_task) => {
      if(sel_task.activity_feed && sel_task.activity_feed.length > 0){
        const updatedActivityFeed = sel_task.activity_feed.map((activityObj) => {
          return (activityObj.id === activity_feed.id) ? activity_feed : activityObj;
        });

        sel_task.activity_feed = updatedActivityFeed;
      }
      else{
        sel_task.activity_feed = [activity_feed];
      }

      var localTaskStatusArray = taskList.map((task) => {
        if (task.id === sel_task.id) {
          return sel_task;
        } else {
          return task;
        }
      });
        
      setSelectedTask(sel_task);
      setTaskList(localTaskStatusArray);
    };


    // Checklist comments updated
    const onCommentsUpdated = (activity_feed, comment_count, sel_task) => {

      sel_task.comment_count = comment_count;
      if(sel_task.activity_feed && sel_task.activity_feed.length > 0){
        sel_task.activity_feed = [activity_feed, ...sel_task.activity_feed];
      }
      else{
        sel_task.activity_feed = [activity_feed];
      }

      var localTaskStatusArray = taskList.map((task) => {
        if (task.id === sel_task.id) {
          return sel_task;
        } else {
          return task;
        }
      });
        
      setSelectedTask(sel_task);
      setTaskList(localTaskStatusArray);
    };

    const onCommentsdeleted = (activityFeeds) => {
      selectedTask.activity_feed = activityFeeds;
      

      var localTaskStatusArray = taskList.map((task) => {
        if (task.id === selectedTask.id) {
          return selectedTask;
        } else {
          return task;
        }
      });
        
      setSelectedTask(selectedTask);
      setTaskList(localTaskStatusArray);
    }

    const onTaskItemClick = (taskItem) => {
        if (taskItem !== undefined && !taskItem.checklist_task_widget) {
            setSelectedTask(taskItem);
            setShowTaskDetails(true);
            getWidgetsApiCall(taskItem);
          } else {
            setSelectedTask(taskItem);
            setShowTaskDetails(true);
          }
        
    }

    const getWidgetsApiCall = (task11) => {
        setWidgetsLoading(true);
    
        getWidgetsApi(props.checklistId, task11.id)
          .then((response) => {
            const res = response.data;
            consoleToLog("getWidgetApi response", res);
            getCommentsApiCall(task11, res.checklist_task_widget);
          })
          .catch((error) => {
            consoleToLog("getTaskList error", error);
            setWidgetsLoading(false);
          });
      };
    
      const getCommentsApiCall = (task11, widgets) => {
        const organization_id = props.selectedOrganization.organization.id;
        const task_id = task11.id;
    
        getCommentsApi(task_id, organization_id)
          .then((response) => {
            const res = response.data;
            consoleToLog("getCommentsApi res ", res);
            var localTaskActivityArray = taskList.map((task) => {
              if (task.id === task11.id) {
                task11.checklist_task_widget = widgets;
                task11.activity_feed = res.activity_feed;
                return task11;
              } else return task;
            });
            setTaskList(localTaskActivityArray);
            setWidgetsLoading(false);
          })
          .catch((e) => {
            consoleToLog("getCommentsApi error", e);
            setWidgetsLoading(false);
          });
      };

    
    return (
        <div>
            {taskList && taskList.length === 0 && (
                <Grid item container justifyContent='center'>
                    <Typography variant="body1">
                        No Tasks Found
                    </Typography>
                </Grid>
            )} 
            {
            <Grid item direction="column" sm={12} >
                {taskList && taskList.length > 0 && taskList.map((taskItem) => {
                    return (
                            <Grid item xs={12} key={taskItem.id}>
                                {taskItem.heading === true && !showTaskDetails  &&(
                                    <Grid container direction="row" xs={12}>
                                        <Grid item container direction="row" xs={12}
                                            style={{ borderRadius: "10px" }}
                                        >
                                            <Typography className={classes.taskTitle}>
                                                {taskItem.title}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )}
                                {(taskItem.heading !== true || !taskItem.heading) && !showTaskDetails ?
                                    <Grid item
                                        xs={12} sm={12}
                                        style={{borderLeft: `5px solid  ${getStatusColor(taskItem.status)}`}}
                                        //onChange={() => handleAccordClick(task)}
                                        className={taskItem.status === "Not Applicable" ? `${classes.itemListContainer} dimmed` : `${classes.itemListContainer}`}
                                        onClick={() => onTaskItemClick(taskItem)}
                                        //ref={myDivToFocus}
                                    >
                                        <Grid item xs={12}>
                                            <Grid container direction="column">
                                                <Typography variant='body1'
                                                    className={taskItem.status === "Completed" ? classes.checklistCompleted : classes.checklistTitle}>
                                                        {taskItem.title}
                                                </Typography>
                                                <Grid container item direction="row" alignItems='center' style={{ marginTop: "2px" }}>
                                                    <Grid item xs={1}>
                                                        <Button className={classes.checKlistItemSubtitleBtn}>
                                                            <i className="flaticon-comment" style={{ lineHeight: "5px" }}></i>
                                                            <Typography variant="subtitle1"
                                                                className={classes.checKlistItemSubtitle}>
                                                                {taskItem.comment_count ? taskItem.comment_count : 0}
                                                            </Typography>
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={11} className={classes.avatarList}>
                                                        {taskItem && (
                                                            <Grid item xs={10} style={{ maxWidth: "23rem", width: "auto" }} onClick={(e) => e.stopPropagation()}>
                                                                    <MobileSelectFromMembersList
                                                                        onClick={(e) => { e.stopPropagation();}}
                                                                        isShowOnly={true}
                                                                        setAssignedMemberList={(selectedAssignees) =>
                                                                            setAssignedMemberList(selectedAssignees, taskItem)
                                                                        }
                                                                        fromChecklistDetails={true}
                                                                        assignees={taskItem.checklist_task_members_list}
                                                                        disabledAssignChecklistMembers={accessLevelManager}
                                                                        setSelectedTask={setSelectedTask}
                                                                        setShowTaskDetails={setShowTaskDetails}
                                                                    />
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    :
                                    taskItem.id === selectedTask?.id &&
                                    <Grid item direction="row" sm={12} className={classes.details}>
                                        <MobileTaskDetails 
                                            selectedTask={selectedTask}
                                            setSelectedTask={setSelectedTask}
                                            setShowTaskDetails={setShowTaskDetails}
                                            onTaskStatusChanged={onTaskStatusChanged}
                                            widgetsLoading={widgetsLoading}
                                            checklistId={props.checklistId}
                                            onWidgetUpdated={onWidgetUpdated}
                                            onCommentsUpdated={onCommentsUpdated}
                                            onCommentsEdited={onCommentsEdited}
                                            onCommentsdeleted={onCommentsdeleted}
                                        />
                                        {/* <DetailPage
                                            selectedTask={task}
                                            widgetsLoading={props.widgetsLoading}
                                            onWidgetUpdated={onWidgetUpdated}
                                            onCommentsUpdated={onCommentsUpdated}
                                            onCommentsEdited={onCommentsEdited}
                                            onCommentsdeleted={onCommentsdeleted}
                                            actionType={props.actionType}
                                        /> */}
                                    </Grid>
                                }

                            </Grid>
                            )
                        })}
                </Grid>
                }
            
        </div>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
  });

export default connect(mapStateToProps)(MobileTaskContent);