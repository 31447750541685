import React, {useState, useEffect, createRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Grid, TextField, Button, FormControlLabel, ClickAwayListener, Checkbox, Tooltip} from '@material-ui/core';
import {connect} from 'react-redux';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import {createTemplateTaskApi, editTemplateTaskApi, deleteTemplateTaskApi, changeTemplateTaskOrderApi} from '../services/authService';
import { useIsMount } from './useIsMount';
import { consoleToLog } from '../util/AppUtil';
import { useSnackbar } from 'notistack';
import OpenWithOutlinedIcon from '@material-ui/icons/OpenWithOutlined';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';

const useStyles = makeStyles((theme) => ({
    mainTaskStyles : {
        borderRight:'1px solid rgba(0,0,0,0.1)',
        height:'70vh',
        overflow:'auto',
        scrollbarWidth: 'none',
        maxHeight:'100%',
        "&::-webkit-scrollbar": {
            display: "none"
        }
    },
    taskStyles : {
        margin:'8px auto 16px auto',
        padding:'0px 8px',
    },
    taskList : {
        width:'98%',
        zIndex:'100',
        wordWrap: 'break-word',
        cursor:'pointer',
        marginBottom:'8px',
        //boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
        '& .taskIcons' : {
            visibility: 'hidden'
        },
        '&:hover .taskIcons' : {
            visibility: 'visible',
            cursor:'pointer'
        }
    },
    editableTextField : {
        marginTop: '-2px'
    },
    MuiFormControlLabellabel :{
        marginLeft:'-4px',
        fontSize:'13px'
    },
    noBorder: {
        border: "none",
      },
    taskIconStyles: {
        display:'flex', 
        alignItems:'center',
        marginTop:'4px',
        marginLeft:'-8px'
    },
    hoverIcons : {
        marginTop:'1px',
        color:'#ccc', 
        fontSize:'16px',
        padding:'0px 8px'
    }

}))

const TemplateTasks = (props) => {
    const classes = useStyles();
    const isMount = useIsMount();
    const {enqueueSnackbar} = useSnackbar();

    const myDivToFocus = createRef(null);

    const [taskName, setTaskName] = useState("Normal Task");
    const [templateTask, setTemplateTask] = useState(props.templateTask);
    const [isHeadingTask, setIsHeadingTask] = useState(false);
    const [taskId, setTaskId] = useState(undefined);
    const [taskObj, setTaskObj] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [dragTask, setDragTask] = useState(false);
    const [dragTaskId, setDragTaskId] = useState(undefined);
    const [canScrollToNewlyCreatedTask, setCanScrollToNewlyCreatedTask] = useState(false);
    const [taskItemSelected, setTaskItemSelected] = useState(undefined);

    //const templateId = props.fromPredefinedTemplate ? props.predefinedTemplateObj?.predefined_template_id : props.templateObj?.template_id;
    const templateString = props.fromPredefinedTemplate ? 'predefined_template' : 'template'
    
    //consoleToLog('props.fromPredefinedTemplate', props.fromPredefinedTemplate, props.predefinedTemplateObj)

    useEffect(() => {
        setTemplateTask(props.templateTask);
    }, [props.templateTask]);

    useEffect(() => {
        if(!isMount) {
            if(taskObj?.id === taskId) {
                setIsHeadingTask(taskObj?.heading);
                if(taskObj?.title !== 'Normal Task') {
                    setTaskName(taskObj?.title);
                } else {
                    setTaskName('Normal Task');
                }
            }
        }
    }, [taskObj]);

    useEffect(() => {
        if(isMount) {
            if(props.taskObj) {
                setTaskItemSelected(props.taskObj?.id)
            } else {
                if(templateTask[0]?.heading) {
                    setTaskItemSelected(templateTask[1]?.id)
                    props.setTaskObj(templateTask[1]);
                } else {
                    setTaskItemSelected(templateTask[0]?.id)
                    props.setTaskObj(templateTask[0]);
                }
                
            }
        }
    }, [props.taskObj]);

   // consoleToLog('123456', taskItemSelected, templateTask[0]);

    useEffect(() => {
            if(!isMount) {
                if (myDivToFocus && myDivToFocus.current && canScrollToNewlyCreatedTask) {
                    onScrollToNewlyCreatedTask();
                }   
            }  
    }, [myDivToFocus]);

    const onScrollToNewlyCreatedTask = () => {
        setCanScrollToNewlyCreatedTask(false);
            myDivToFocus.current.scrollIntoView({
                behavior: 'smooth', 
            })
    }

    const onCreateTemplateTaskClick = () => {
        setLoading(true);
        const organization_id = props.selectedOrganization.organization.id;
        const template_id = props.templateId;
        const title = 'Normal Task';
        const heading = false;
        const default_status = 'pending';
        const widget_json = [];

        createTemplateTaskApi(organization_id, templateString, template_id, title, heading, default_status, widget_json)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response createTemplateTaskApi', res);
                setLoading(false);

                enqueueSnackbar(res.message, {
                    variant:'success'
                });

                let updatedTask = props.fromPredefinedTemplate ? res.predefined_template_tasks : res.template_tasks;
                setTemplateTask(updatedTask);
                props.setTemplateTask(updatedTask);
                setCanScrollToNewlyCreatedTask(true);
                
            })
            .catch((e) => {
                consoleToLog('Error createTemplateTaskApi: ', e);
                setLoading(false);
                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, ({
                        variant: 'error'
                    }));
                    return false;
                }
            })
        }

        const editTemplateTaskApiCall = (task_id) => {
            const organization_id = props.selectedOrganization.organization.id;
            const template_id = props.templateId;
            const title = taskName;
            const heading = isHeadingTask;
            const widget_json = props.taskObj?.widget_json
            
            editTemplateTaskApi(organization_id, templateString, template_id, task_id, title, heading, widget_json)
                .then((response) => {
                    const res = response.data;
                    consoleToLog('Response editTemplateApiCall', res);
    
                    enqueueSnackbar(res.message, {
                        variant:'success'
                    });
    
                    let updatedTask = props.fromPredefinedTemplate ? res.predefined_template_tasks : res.template_tasks;
                    setTemplateTask(updatedTask);
                    props.setTemplateTask(updatedTask);
                    setTaskId(undefined);

                    // const templateTaskArr = props.templateTask.map((task) => {
                    //     res.template_tasks.map((newTask) => {
                    //         if(task.id === newTask.id) {
                    //             task = newTask
                    //         }
                    //         return task;
                    //     });
                    //     return task;
                    // });
                    // props.setTemplateTask(templateTaskArr);
                })
                .catch((e) => {
                    consoleToLog('Error editTemplateApiCall: ', e.response);
                    setTaskId(undefined);
                    if (e.response.data && e.response.data.message) {
                        enqueueSnackbar(e.response.data.message, ({
                            variant: 'error'
                        }));
                        return false;
                    }
                })
        }    

    const callChangeOrderApi = (orderNumber, drag_taskId) => {
            const organization_id = props.selectedOrganization.organization.id;
            const template_id = props.templateId;
            const task_id = drag_taskId;
            const order_number = orderNumber + 1;

            changeTemplateTaskOrderApi(organization_id, templateString, template_id, task_id, order_number)
                .then((response) => {
                    const res = response.data;
                    consoleToLog("Response changeTemplateTaskOrderApi", res);

                    enqueueSnackbar(res.message, ({
                        variant: 'success'
                    }));

                    let updatedTask = props.fromPredefinedTemplate ? res.predefined_template_tasks : res.template_tasks;
                    setTemplateTask(updatedTask);

                })
                .catch((e) => {
                    consoleToLog('Error changeTemplateTaskOrderApi: ', e.response);
                    if (e.response.data && e.response.data.message) {
                        enqueueSnackbar(e.response.data.message, ({
                            variant: 'error'
                        }));
                        return false;
                    }
                })

    }
        
    const handleAwayClick = (task) => {
        if(task.id === taskId) {
            editTemplateTaskApiCall(task.id);
        }   
    }

    const showAppropriatebackground = (task) => {
        if(task.id === taskId || (task.id === taskItemSelected && !task.heading)) {
            return "#E5EFFF";
        } else if(task.heading) {
            return "#fff";
        } else {
            return "#f9f9f9";
        }
    }

    const showAppropriateMargin = (task) => {
        if(task.id === taskId || !task.heading) {
            return "4px 0px"
        } else if(task.heading)  {
            return "-2px 0px -22px -18px"
        } 
    }

    const onDeleteTemplateTaskClick = (e, task) => {
        if(window.confirm("Are you sure you want to delete this task?")) {
            setCanScrollToNewlyCreatedTask(false);

            const organization_id = props.selectedOrganization.organization.id;
            const template_id = props.templateId;
            const id = task.id;

            deleteTemplateTaskApi(organization_id, templateString, template_id, id)
                .then((response) => {
                    const res = response.data;
                    consoleToLog('Response deleteTemplateTaskApi', res);

                    enqueueSnackbar(res.message, {
                        variant:'success'
                    });

                    let updatedTask = props.fromPredefinedTemplate ? res.predefined_template_tasks : res.template_tasks;    
                    setTemplateTask(updatedTask);
                    props.setTemplateTask(updatedTask);
                    if(props.taskObj) {
                        props.setTaskObj(undefined);
                    }
                })
                .catch((e) => {
                    consoleToLog('Error deleteTemplateTaskApi: ', e.response);
                    if (e.response.data && e.response.data.message) {
                        enqueueSnackbar(e.response.data.message, ({
                            variant: 'error'
                        }));
                        return false;
                    }
                })
        }    
    }

    const reorder = (list, startIndex, endIndex) => {
        const result = list;
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
      
        return result;
      };
    
    const onEnd = (result) => {
        let orderNumber = result.destination.index;
        let task_id = result.draggableId;

        callChangeOrderApi(orderNumber, task_id);

        // dropped outside the list
        if (!result.destination) {
            return;
          }
      
          const items = reorder(
            templateTask,
            result.source.index,
            result.destination.index
          );
      
          setTemplateTask(items);
        
    }

    return (
            <Grid item md={3} lg={3} className={classes.mainTaskStyles} 
                style={{height: !props.is_orgEmail_verified ? 'calc(70vh - 40px)' : '70vh'}}>
                <Grid item container direction="column"
                    alignItems="center"
                    className={classes.taskStyles}>
                        <Grid item style={{marginLeft:'auto'}}>
                            <Button variant="contained" color="primary" 
                                style={{ minWidth:'10px', marginBottom:'8px'}} 
                                onClick={onCreateTemplateTaskClick}>
                                Add Task
                            </Button>
                        </Grid>  
                        <DragDropContext onDragEnd={onEnd}>  
                        <Droppable droppableId="droppable" direction="vertical">
                        {(provided) => (
                            <div {...provided.droppableProps}
                            ref={provided.innerRef} style={{width:'100%', marginBottom:'8px'}}>
                                {templateTask && templateTask.length > 0 ? 
                                templateTask.map((task, index) => {
                                    return <Draggable key={task.id} 
                                             draggableId={task.id.toString()} 
                                             index={index}> 
                                            {(provided) => (
                                            <div  
                                                {...provided.draggableProps}
                                                ref={provided.innerRef}
                                                id={index+"_"+task.title}
                                                className={classes.taskList}
                                                onClick={(e) => {
                                                    //e.stopPropagation();
                                                    setTaskItemSelected(task.id);
                                                    props.setTaskObj(task);
                                                }}
                                                // onClick={(e) => {
                                                //     setTaskItemSelected(task.id);
                                                //     props.setTaskObj(task);
                                                //     //consoleToLog('taskSelectted');
                                                //     }}
                                                >
                                                <div ref={myDivToFocus} style={{
                                                        width: task.heading ? '98%' : '100%', 
                                                        cursor:'pointer',
                                                }}>    
                                                <ClickAwayListener onClickAway={() => handleAwayClick(task)} >
                                                    <Grid  container spacing={1} alignItems="center"
                                                        style={{
                                                            //padding: (taskId === task.id) ? '0px 8px' :'8px 2px',
                                                            fontWeight: (task.heading) ? '700' : 'none',
                                                            background: showAppropriatebackground(task),
                                                            borderRadius: '8px',
                                                            margin: showAppropriateMargin(task),
                                                        }}> 
                                                            <Grid item md={9} lg={9}>
                                                                {(taskId === task.id) ? (
                                                                    <TextField
                                                                    //id={task.id}
                                                                    fullWidth
                                                                    autoFocus
                                                                    margin="dense"
                                                                    //disabled={taskId !== task.id}
                                                                    value={taskName}
                                                                    multiline={true}
                                                                    variant="outlined"
                                                                    type="text"
                                                                    InputProps={{
                                                                        classes:{notchedOutline: (taskId !== task.id) && classes.noBorder}
                                                                        }}
                                                                    onChange={event => setTaskName(event.target.value)}
                                                                    inputProps={{ style: {padding:'0px 4px' } }}
                                                                    style={{background:'#fff'}}
                                                                    inputRef={(input) => {
                                                                        if (input !== null) {
                                                                        input.focus();
                                                                        }
                                                                    }}
                                                                    onKeyPress={(ev) => {
                                                                        //consoleToLog(`Pressed keyCode ${ev.key}`);
                                                                        if (ev.key === 'Enter') {
                                                                            //setIsTextFieldFocused(false);
                                                                            editTemplateTaskApiCall(task.id)
                                                                            ev.preventDefault();
                                                                        }
                                                                    }}
                                                                    />
                                                                    ) : (
                                                                    <TextField
                                                                        fullWidth
                                                                        autoFocus
                                                                        margin="dense"
                                                                        variant="outlined"
                                                                        disabled={taskId !== task.id}
                                                                        multiline={true}
                                                                        defaultValue={task.title}
                                                                        InputProps={{
                                                                            classes:{notchedOutline: classes.noBorder}
                                                                            }}
                                                                        inputProps={{ style: { 
                                                                            fontWeight: task.heading ? "bold" : "none", 
                                                                            cursor:'pointer',
                                                                            color:'#212121',
                                                                            lineHeight:'18px', 
                                                                            padding:'0px 4px' } }}    
                                                                        // onClick={(e) => {
                                                                        //     e.stopPropagation();
                                                                        //     setTaskItemSelected(task.id);
                                                                        //     props.setTaskObj(task);
                                                                        // }}
                                                                        />
                                                                    )} 
                                                            </Grid>
                                                                    
                                                            <Grid item md={2} lg={2} >
                                                                {(taskId === task.id) ?
                                                                    <FormControlLabel
                                                                        classes={{
                                                                            label: classes.MuiFormControlLabellabel
                                                                        }}
                                                                        control={
                                                                        <Checkbox
                                                                            name="checkedB"
                                                                            color="primary"
                                                                            style={{fontSize:'8px'}}
                                                                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                                            checked={isHeadingTask}
                                                                            onChange={() => setIsHeadingTask(!isHeadingTask)}
                                                                            onClick={(e) => e.stopPropagation()}
                                                                        />
                                                                        }
                                                                        label="Heading"
                                                                    /> 
                                                                    :
                                                                (taskId !== task.id) &&   
                                                                <div className={classes.taskIconStyles}>
                                                                    <Grid item className="taskIcons">
                                                                        <Tooltip title="Edit" arrow>
                                                                            <i className={`flaticon-edit ${classes.hoverIcons}`}
                                                                                style={{padding:0, color:'grey'}}
                                                                                onClick={(e) => {
                                                                                    e.stopPropagation();
                                                                                    setTaskId(task.id);
                                                                                    setTaskObj(task);
                                                                                    setTaskItemSelected(undefined);
                                                                                }}
                                                                            />
                                                                        </Tooltip>    
                                                                    </Grid>

                                                                    <Grid item className="taskIcons" {...provided.dragHandleProps}>
                                                                        <Tooltip title="Move" arrow>
                                                                            <OpenWithOutlinedIcon
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                            }}
                                                                            onMouseDown={(e) => {
                                                                                setTaskItemSelected(undefined);
                                                                                setDragTask(true);
                                                                                setDragTaskId(task.id);
                                                                            }}
                                                                            className={classes.hoverIcons}      
                                                                            />
                                                                        </Tooltip>
                                                                    </Grid>

                                                                    <Grid item className="taskIcons">
                                                                        <Tooltip title="Delete" arrow>
                                                                            <i className="flaticon-delete-1" 
                                                                                onClick={(e) => {
                                                                                    e.stopPropagation();
                                                                                    setTaskItemSelected(undefined);
                                                                                    onDeleteTemplateTaskClick(e, task);
                                                                                }}
                                                                                style={{fontSize:'14px'}}/>
                                                                        </Tooltip>        
                                                                    </Grid>

                                                                    <Grid item className="taskIcons">
                                                                            <ChevronRightIcon
                                                                                onClick={(e) => {
                                                                                    e.stopPropagation();
                                                                                    setTaskItemSelected(task.id);
                                                                                    props.setTaskObj(task);
                                                                                    //consoleToLog('taskSelectted');
                                                                                }}
                                                                                style={{fontSize:'22px', marginTop:'3px', padding:'0px 4px'}}
                                                                                className={classes.hoverIcons}/>
                                                                    </Grid>
                                                                </div> }
                                                            </Grid> 
                                                    </Grid>
                                                </ClickAwayListener>
                                                </div>
                                        </div>

                                            )

                                            } 
                                        </Draggable>
                                        }) : <Grid item> </Grid>
                                }
                                {provided.placeholder}
                            </div>

                                        )
                                        }
                        </Droppable>
                    </DragDropContext>
                </Grid>  
           </Grid> 

    )
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization
});

export default connect(mapStateToProps)(TemplateTasks);