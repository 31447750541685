import React, { useEffect, useState } from "react";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import { Button, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab"; 
import ClientInfo from "../client_details/ClientInfo";
import CustomFields from "../client_details/CustomFields";
import Checklist from "../client_details/Checklist";
import { consoleToLog , capitalizeFirstLetterOfEachWord, trackGAEvent} from "../../util/AppUtil";
import {getClientChecklistApi, getClientAttachmentListApi, getUploadURLApi, uploadCustomFieldSuccessApi} from "./../../services/authService";
import {connect} from 'react-redux';
import { useSnackbar } from 'notistack';
import Documents from "../client_details/Documents";
import { useIsMount } from '../useIsMount';
import ClientInvoiceSettings from "../client_details/ClientInvoiceSettings";
import { getInvoiceConfigApi } from "../../services/invoiceService";
import { udfTypes } from "../../util/userDefinedFieldsUtil";
import { editClientApi } from "../../services/clientService";
import moment from "moment";
import axios from "axios";
import {getClientAuditTrailListApi} from "../../services/genieService";
import EmailConversations from "../checklist_details/EmailConversations";
import ContactComponent from "../client_details/ContactComponent";
import ContactDrawer from "../client_details/ContactDrawer";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ClientWorkReport from "../ClientWorkReport";
import PublishOutlinedIcon from '@material-ui/icons/PublishOutlined';
import InputAdornment from '@material-ui/core/InputAdornment';


const useStyles = makeStyles((theme) => ({
  backIcon: {
    color: "#202020",
    border: " 1px solid #202020",
    padding: "4px 0px",
    fontSize: "18px",
    minWidth: "40px",
    lineHeight: "18px",
    marginTop: "15px",
    marginBottom: "10px",
    marginLeft: "15px",
  },
  detailsIconBtns: {
    color: "black",
    fontSize: "15px",
    minWidth: "20px",
    padding: "5px 6px",
    marginTop: "15px",
  },
  detailsMain: {
    paddingTop: "10px",
    paddingBottom: "10px",
    backgroundColor: "#f2f6ff",
  },
  detailBadge: {
    backgroundColor: "#cccccc",
    fontSize: "12px",
    borderRadius: "3.5px",
    padding: "0px 6px",
    fontWeight: 500,
    color: "black",
    marginBottom: "3px",
    marginLeft: "5px",
  },
  tabsMain: {
    minWidth: "100px",
    color: "#5e6265",
  },
  itemList: {
    // backgroundColor:"#e5efff",
    backgroundColor: "#f9f9f9",
    marginBottom: "10px",
    borderRadius: "5px",
    // marginRight:"20px"
  },
  checklistYear: {
    // lineHeight: "18px",
    color: "#888888",
    fontSize: "14px",
    marginLeft: "10px",
    marginTop: "3px",
  },
  checklistDateBtn: {
    // marginTop: "-5px",
    marginTop: "-7px",
    marginLeft: "-6px",
    marginRight: "-6px",
  },
  checklistDate: {
    marginLeft: "4px",
    color: "#888888",
    fontSize: "12.6px",
    // marginTop: "0.6px",
    marginTop: "3px",
  },
  checklistStatus: {
    backgroundColor: "#72BB53",
    marginTop: "-15.7px",
    marginBottom: "10px",
    padding: "2px",
    fontWeight: "600",
    color: "white",
  },
  checkListStatusComponent: {
    borderRadius: "5px",
    padding: "2px 10px 2px 10px",
    color: "#dd584d",
    border: `1px solid #dd584d`,
  },
  tabsAppbar: {
    boxShadow: "none",
    backgroundColor: "white",
    borderBottom: "1px solid #c5b9b9",
  },
  checklistLabel: {
    marginLeft: "3px",
    marginRight: "3px",
  },
  topMessage: {
    color: "gray",
    fontSize: "13px",
    marginTop: "2px",
    marginBottom: "-22px",
  },
  addButton: {
    color: "white",
    backgroundColor: "#3d3d3d",
    marginLeft: "auto",
    height: "30px",
    marginTop: "9px",
    "&:hover": {
        //you want this to be the same as the backgroundColor above
        backgroundColor: "#3d3d3d"
    },
    marginRight: "8px"
  },
  buttonPlusIcon: {
    //marginLeft:'8px', 
    marginRight: "8px",
    fontSize:'1.2rem', 
    alignItems:'center'
},   
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ClientDetailsTopBar = (props) => {
  const classes = useStyles();
  return (
    <Grid container className={classes.detailsMain}>
      <Grid item container direction="row" sm={6}>
        <Grid container wrap="nowrap" spacing={2}>
          <Grid item>
            <Button
              variant="outlined"
              className={classes.backIcon}
              onClick={props.handleCloseDetailsModal}
            >
              <ArrowBackIcon />
            </Button>
          </Grid>
          <Grid
            item
            container
            xs
            zeroMinWidth
            style={{ paddingLeft: "0px", paddingTop: "6px" }}
          >
            <Grid item container direction="row">
              <Grid
                item
                container
                direction="row"
                style={{ paddingTop: "21.5px" }}
              >
                <Grid item varient="body1" direction="column">
                  {props.companyObj.name}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const ClientDetailsModal = (props) => {
  const classes = useStyles();
  const {enqueueSnackbar} = useSnackbar();
  const [value, setValue] = useState('0');
  const [companyObj, setCompanyObj] = useState(props.showClientDetails);

  const [checklists, setChecklists] = useState([]);
  const [checklistLoading,setChecklistLoading]=useState(false);
  const [apiRunning, setApiRunning] = useState(false);
  const isMount = useIsMount();

  // pagnation logic for checklist
  const [page, setPage] = useState(1);
  const [nextPage, setNextPage] = useState(false);
  const [isMoreLoading, setIsMoreLoading] = useState(false);

  // pagnation logic for  attachments
  const [pageD, setPageD] = useState(1);
  const [nextPageD, setNextPageD] = useState(false);
  const [isMoreLoadingD, setIsMoreLoadingD] = useState(false);
  const [attachmentList, setAttachmentList] = useState(undefined);
  const [documentsLoading,setDocumentsLoading]=useState(false);
  const [invoiceConfigs, setInvoiceConfigs] = useState([]);
  const [selectedAccountId, setSelectedAccountId]=useState(props.showClientDetails?.invoice_account_id);
  const [invoiceSettingLoading, setInvoiceSettingLoading]=useState(false);

  //pagination for client audit trail
  const [emailLoading, setEmailLoading] = useState(false);
  const [emailsList, setEmailsList] = useState([]);
  const [pageEmail, setPageEmail] = useState(0);
  const [nextPageEmail, setNextPageEmail] = useState(false);
  const [isMoreLoadingEmail, setIsMoreLoadingEmail] = useState(false);
  const [openContactDrawer, setOpenContactDrawer]=useState(false);
  const [contactObj ,setContactObj]=useState(false);
  const [addCustomField, setAddCustomField] = useState(false);

  const [open, setOpen] = useState(false);
  const [clientDocumentSearchText, setClientDocumentSearchText] = useState('');

  const userInvoiceAppScope = props.auth.user.scope && props.auth.user.scope.length > 0 ? props.auth.user.scope.includes('invoices') : false;
  
  useEffect(() => {
    if (!isMount && isMoreLoading) {
      getClientChecklistApiCall();
    }
  }, [isMoreLoading]);

  useEffect(() => {
    if (!isMount && isMoreLoadingD) {
      getClientAttachmentListApiCall(clientDocumentSearchText);
    }
  }, [isMoreLoadingD]);

  useEffect(() => {
    if (!isMount && isMoreLoadingEmail) {
        getClientAuditTrailListCall();
    }
  }, [isMoreLoadingEmail]);


  useEffect(()=>{
    if (!isMount) {
      setPageD(1);
      setPage(1);
      setPageEmail(0);
      
      if (value === '2' || value === '3') {
        getClientAttachmentListApiCall(clientDocumentSearchText);
      }
      if(value === '4'){
        invoiceTabClicked();
      }
      if(value === '5'){
        getClientAuditTrailListCall();
      }
    }
  },[value]);

  useEffect(() => {
    if(!isMount) {
      if(value === '2' || value === '3') {
        getClientAttachmentListApiCall(clientDocumentSearchText);
      }
    }
}, [clientDocumentSearchText]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleMoreLoading = () =>{
    setIsMoreLoading(!isMoreLoading);
  }

  const handleMoreLoadingD = () =>{
    setIsMoreLoadingD(!isMoreLoadingD);
  }

  const handleMoreLoadingEmail = () =>{
    setIsMoreLoadingEmail(!isMoreLoadingEmail);
  }

  const invoiceTabClicked = () => {
    trackGAEvent(props.selectedOrganization.organization.name, 'Invoice Settings', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
    getInvoiceSettings(); 
  }

  const onAttachmentDelete =(updatedDeleteAttachmentArray)=>{
     setAttachmentList(updatedDeleteAttachmentArray);
  }

  const handleContactDrawerOpen = () => {
    setOpenContactDrawer(true);
  }

  const handleContactDrawerClose = () => {
    setOpenContactDrawer(false);
  }

  const onAddCustomFieldClick = () => {
      setAddCustomField(true);
  }


  const editClientInfo = (companyName,phone_number, compAddress, country, state, taxId, selectedManager) =>{
    const id = companyObj.id;
    const organization_id = props.selectedOrganization.organization.id
    // const email = emails && emails.length > 0 ? emails.join() : "";
    const user_defined_field = companyObj.user_defined_field;
    const relationship_manager_id = selectedManager !== 'none' ? [selectedManager] : [];

    editClientApi(id, companyName,phone_number, compAddress, state, country, taxId, user_defined_field, relationship_manager_id)
    .then((response) => {
        const res = response.data;
        consoleToLog('Response editClientApi: ', res);
        enqueueSnackbar(res.message, {variant:'success'});
        setCompanyObj(res.client);
        
        trackGAEvent(props.selectedOrganization.organization.name, 'Client Info Updated', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
        props.companyUpdated(res.client);

    })
    .catch((e) => {
        consoleToLog('Error editClientApi: ', e.response);
        if(e.response.data && e.response.data.message) {
          enqueueSnackbar(e.response.data.message, {variant:'error'});
          return;
        }
    })

  }

  const callNextFileUploadOrEditApi = (index, userDefinedFields) => {
    setApiRunning(true);
    if (userDefinedFields && userDefinedFields.length > 0) {
        for (var i = index; i < userDefinedFields.length; i++) {
            if (userDefinedFields[i].type === udfTypes.ImageOrDocument.value && typeof(userDefinedFields[i].value) === 'object') {
                uploadFileIfExists(i, userDefinedFields);
                break;
            } else if (i === userDefinedFields.length - 1) {//call company api directly as this is last iteration
              editCustomFields(userDefinedFields);
            }
        }
    } else {
        editCustomFields(userDefinedFields);
    }
  }
 
  const uploadFileIfExists = (i, userDefinedFields) => {
    const udfObj = userDefinedFields[i];
    //upload file logic
    const file_name = udfObj.value?.name;
    const file_type = udfObj.value?.type; 
    const type = 'custom'

    getUploadURLApi(companyObj.id, file_name, type)
      .then((response) => {
        const res = response.data;
        consoleToLog('Response getUploadURLApi', res);
        
        var signedRequest = res.upload_signed_url;
        var url = res.url;

        // Put the fileType in the headers for the upload
          const options = {
            headers: {
              "Content-Type": file_type,
            }
          };
          axios.defaults.headers.common = {};
          axios.put(signedRequest, udfObj.value, options)
          .then(result => {
            consoleToLog('signed Request s3', result);
            setApiRunning(false);

            callUploadSuccessApi(i, userDefinedFields, udfObj, url)
          })
          .catch(error => {
            consoleToLog('error: ', error.response);
            setApiRunning(false);
          })
      })
      .catch((e) => {
        consoleToLog('Error getUploadURLApi', e);
        setApiRunning(false);

      })
}

const callUploadSuccessApi = (i, userDefinedFields, udfObj, url) => {
  const client_id = companyObj?.id;

  uploadCustomFieldSuccessApi(client_id, url)
      .then((response) => {
          const res = response.data;
          consoleToLog('Response uploadCustomFieldSuccessApi: ', res);

          //update state obj with new path
          const newUDFObj = {
            ...udfObj,
            value: url
        }

          let tempArr = userDefinedFields.filter((obj, index) => index !== i);
          consoleToLog('tempArr', tempArr);
          tempArr.splice(i,0, newUDFObj);
          callapi (i, userDefinedFields ,tempArr);
      })
      .catch((e) => {
          consoleToLog("Error uploadCustomFieldSuccessApi: ", e);
      })
}

const callapi = (i, userDefinedFields, tempArr) => {
    let useDefinedArr = userDefinedFields.map(a => {
        const exists = tempArr.find(b => a.name == b.name);
        return exists ? (a.value = exists.value, a) : a;
      });

    if (i === userDefinedFields.length - 1) {//call company api
        editCustomFields(useDefinedArr);
    } else {//recursive call if file exists
        callNextFileUploadOrEditApi(i + 1, useDefinedArr);
    }
}


  const editCustomFields = (userDefinedFields) => {
    const id = companyObj.id;
    const user_defined_field = userDefinedFields; 
    const deleted_files = companyObj.deletedFiles;
    const edit_company_name = companyObj.name;
    const edit_address = companyObj.address;
    // const edit_companyEmail = companyObj.email;
    const edit_phone_number = companyObj.phone_number;
    const edit_state = companyObj.state !== 'none' ? companyObj.state : '';
    const edit_country = companyObj.country !== 'none' ? companyObj.country : '';
    const edit_taxId = companyObj.taxId ? companyObj.taxId : '';
    const edit_relationship_manager_id = companyObj.relationship_manager_id ? companyObj.relationship_manager_id : []; 

    editClientApi(id, edit_company_name, edit_phone_number, edit_address, edit_state, edit_country, edit_taxId, 
      user_defined_field, edit_relationship_manager_id)
    .then((response) => {
        const res = response.data;
        consoleToLog('Response editClientApi: ', res);
        enqueueSnackbar('Custom Fields edited successully!', {variant:'success'});
        setCompanyObj(res.client);
        setApiRunning(false);
        props.companyUpdated(res.client);
        trackGAEvent(props.selectedOrganization.organization.name, 'Custom Field Updated', `${props.auth.user.firstname} ${props.auth.user.lastname}`);


    })
    .catch((e) => {
        consoleToLog('Error editClientApi: ', e.response);
        setApiRunning(false);
        if(e.response.data && e.response.data.message) {
          enqueueSnackbar(e.response.data.message, {variant:'error'});
          return;
        }
    })
  } 
    

const getClientChecklistApiCall = () => {
  const company_id = companyObj.id;
  const organization_id = props.selectedOrganization.organization.id;
 
  if(!isMoreLoading) setChecklistLoading(true);
    getClientChecklistApi(company_id, organization_id,page)
    .then((response) => {
      const res = response.data;
      consoleToLog("Response getClientChecklistApi: ", res);
      setChecklistLoading(false);
      if(isMoreLoading) {
          const newItems = checklists.concat(res.checklists);
          setChecklists(newItems);
          setPage(page + 1);
          setNextPage(res.next_page);
          setIsMoreLoading(!isMoreLoading);
      } else {
          setNextPage(res.next_page);
          if(res.next_page) setPage(page + 1);
          setChecklists(res.checklists);
          // setTotal(res.total);
          }
      })
    .catch((err) => {
      consoleToLog("getClientChecklistApi err", err);
      setChecklistLoading(false);
    });
};

const getInvoiceSettings = () => {
  const organization_id = props.selectedOrganization.organization.id;
  const company_id = companyObj.id;

  setInvoiceSettingLoading(true);
  getInvoiceConfigApi(organization_id, company_id)
  .then((response) => {
      const res = response.data;
      consoleToLog("Response getInvoiceConfigApi: ", res);
      setInvoiceConfigs(res.invoice_configs);
      //setSelectedAccountId(res.invoice_account);
      setInvoiceSettingLoading(false);
    })
    .catch((error) => {
      consoleToLog("getInvoiceConfigApi res", error);
      setInvoiceSettingLoading(false);
    });
}

const getClientAttachmentListApiCall = (query) => {
  const company_id = companyObj.id;
  const organization_id = props.selectedOrganization.organization.id;
  const apiStr = value === '2' ? 'attachments' : 'master_documents';

  if(!isMoreLoadingD) setDocumentsLoading(true);
  getClientAttachmentListApi(organization_id, company_id, pageD, query, apiStr)
  .then((response) => {
    const res = response.data;
    consoleToLog("Response getClientAttachmentListApi: ", res);
    setDocumentsLoading(false);
    if(isMoreLoadingD) {
        const newAttachments = attachmentList.concat(value === '2' ? res.company_attachments : res.master_documents);
        setAttachmentList(newAttachments);
        setPageD(pageD + 1);
        setNextPageD(res.next_page);
        setIsMoreLoadingD(!isMoreLoadingD);
    } else {
        setNextPageD(res.next_page);
        if(res.next_page) setPageD(pageD + 1);
        setAttachmentList(value === '2' ? res.company_attachments : res.master_documents);
        // setTotal(res.total);
        }
    
    })
    .catch((error) => {
      consoleToLog("getClientAttachmentListApi res", error);
    });
};

const getClientAuditTrailListCall = () => {
  const company_id = companyObj.id;

  if(!isMoreLoadingEmail) setEmailLoading(true);
    getClientAuditTrailListApi(company_id, pageEmail)
      .then((response) => {
        const res = response.data;
        consoleToLog("Response getClientAuditTrailListApi: ", res);
        setEmailLoading(false);
        if(isMoreLoadingEmail) {
            const newItems = emailsList.concat(res.emails);
            setEmailsList(newItems);
            setPageEmail(pageEmail + 1);
            setNextPageEmail(res.next_page);
            setIsMoreLoadingEmail(!isMoreLoadingEmail);
        } else {
            setNextPageEmail(res.next_page);
            if(res.next_page) setPageEmail(pageEmail + 1);
            setEmailsList(res.emails);
            // setTotal(res.total);
            }
        })
        .catch((err) => {
          consoleToLog("getClientAuditTrailListApi err", err);
          setEmailLoading(false);
        });
};

const OnShowPassword = (updatedCompanyObj) =>{
  setCompanyObj({...companyObj,user_defined_field:updatedCompanyObj});
}

const ButtonInTabs = ({ className, onClick, children, disabled }) => {
  return <Button 
          className={className} 
          onClick={onClick} 
          children={children}
          disabled={disabled}
          style={{color:'white'}}
          />;
};

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const onSearch = (e) => {
    const query = e.target.value;
    if (query === '') {
        setPageD(1);
        setNextPageD(false);
        setClientDocumentSearchText('');
    } else {
        //dont allow spaces before string, allow in between
        const regex = /^[^-\s][a-zA-Z0-9_\s-]+$/;
        if (query && query.length > 0) {
            setPageD(1);
            setNextPageD(false);
            setClientDocumentSearchText(query);
        }
    }
}

  return (
    <div>
      <Dialog
        fullScreen
        open={props.openDialog}
        onClose={props.handleCloseDetailsModal}
        TransitionComponent={Transition}
      >
        <ClientDetailsTopBar
          handleCloseDetailsModal={props.handleCloseDetailsModal}
          companyObj={companyObj}
        />
        <div className={classes.root}>
          <AppBar
            position="static"
            color="default"
            className={classes.tabsAppbar}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="secondary"
              textColor="secondary"
              style={{ paddingLeft: "15px" }}
              aria-label="full width tabs example"
            >
              <Tab
                label="Client Info"
                value={'0'}
                className={classes.tabsMain}
              />
              <Tab
                label="Custom fields"
                value={'1'}
                className={classes.tabsMain}
              />
              <Tab
                label="Documents"
                value={'2'}
                className={classes.tabsMain}
              />
              <Tab
                label="Master Documents"
                value={'3'}
                className={classes.tabsMain}
              />
              {/* <Tab
                label="Checklists"
                value={'3'}
                className={classes.tabsMain}
              /> */}
              {props.accountList && props.accountList.length > 0 && userInvoiceAppScope && <Tab
                label="Invoice Settings"
                value={'4'}
                className={classes.tabsMain}
              />}
              <Tab
                label="Audit Trail"
                value={'5'}
                className={classes.tabsMain}
              />
              <Tab
                label="Contact"
                value={'6'}
                className={classes.tabsMain}
              />
              <Tab
                label="Work Report"
                value={'7'}
                className={classes.tabsMain}
              />

                {
                  value === '1' &&
                    <ButtonInTabs
                        onClick={onAddCustomFieldClick}  
                        className={classes.addButton}
                      
                    >
                      Add Custom Field
                    <AddCircleOutlineIcon fontSize='small' 
                      style={{fontSize:'15px', marginLeft:'4px'}}
                      className={classes.buttonPlusIcon}/>
                    </ButtonInTabs>
                }
              {
                  // value === '6' &&
                  //   <ButtonInTabs
                  //       onClick={handleContactDrawerOpen}   
                  //       className={classes.addButton}
                  //     >
                  //     Add Contact
                  //     <AddCircleOutlineIcon fontSize='small' 
                  //       style={{fontSize:'15px', marginLeft:'4px'}}
                  //       className={classes.buttonPlusIcon}/>
                  //   </ButtonInTabs>
                }
                {
                  value === '3' &&
                    <ButtonInTabs
                        onClick={handleDrawerOpen}  
                        className={classes.addButton}
                      
                    >
                      <PublishOutlinedIcon fontSize='small' 
                      style={{fontSize:'15px', marginLeft:'4px'}}
                      className={classes.buttonPlusIcon}/>
                      Upload
                    </ButtonInTabs>
                }

            </Tabs>
          </AppBar>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <span className={classes.topMessage}>*Press esc to exit</span>
          </Grid>
          <div style={{ padding: "14px" }}>
            <div style={{ display: value === '0' ? "block" : "none" }}>
              <ClientInfo companyObj={companyObj}
                editClientInfo={editClientInfo}/>
            </div>
            <div style={{ display: value === '1' ? "block" : "none" }}>

              <CustomFields companyObj={companyObj} 
                  OnShowPassword={OnShowPassword}
                  editCustomFields={editCustomFields}
                  editClientInfo={editClientInfo}
                  callNextFileUploadOrEditApi={callNextFileUploadOrEditApi}
                  apiRunning={apiRunning}
                  addCustomField={addCustomField}
                  setAddCustomField={setAddCustomField}
              />
            </div>
            <div style={{ display: value === '2' || value === '3' ? "block" : "none" }}>
              <Grid item container justifyContent="flex-end">
                <Grid item md={4}>
                    <TextField variant="outlined"
                      margin="dense"
                      fullWidth
                      onChange={onSearch}
                      placeholder='Search Documents'
                      InputLabelProps={{style: {fontSize: 14, color: "#ccc"}}}
                      InputProps={{
                        startAdornment: (
                        <InputAdornment position="start">
                            {/* <SearchIcon className={classes.searchicon}/> */}
                            <i className="flaticon-search"></i>
                        </InputAdornment>
                        )
                    }}
                    />
                </Grid>

              </Grid>
              <Documents companyObj={companyObj}
                  onAttachmentDelete={onAttachmentDelete}
                  attachmentList={attachmentList}
                  handleMoreLoadingD={handleMoreLoadingD}
                  documentsLoading={documentsLoading}
                  isMoreLoadingD={isMoreLoadingD}
                  nextPageD={nextPageD}
                  pageD={pageD}
                  tabValue={value} 
                  setAttachmentList={setAttachmentList}
                  open={open}
                  handleDrawerOpen={handleDrawerOpen}
                  handleDrawerClose={handleDrawerClose}
                  />
            </div>
            {/* <div style={{ display: value === '3' ? "block" : "none" }}>
              <Checklist companyObj={companyObj} 
                  checklists={checklists} 
                  checklistLoading={checklistLoading}
                  handleMoreLoading={handleMoreLoading}
                  isMoreLoading={isMoreLoading}
                  nextPage={nextPage}
                  tabValue={value}
                  setChecklists={setChecklists}
                  handleSendEmailDialogOpen={props.handleSendEmailDialogOpen}/>
            </div> */}
            {props.accountList && props.accountList.length > 0 &&
            <div style={{ display: value === '4' ? "block" : "none" }}>
              <ClientInvoiceSettings clientId={companyObj.id}
                  invoiceConfigs={invoiceConfigs}
                  selectedAccountId={selectedAccountId}
                  invoiceSettingLoading={invoiceSettingLoading}
                  companyUpdated={props.companyUpdated}
                  companyObj={companyObj}
                />
            </div>}
            <div style={{ display: value === '5' ? "block" : "none" }}>
              <EmailConversations companyObj={companyObj} 
                  emailsList={emailsList} 
                  emailLoading={emailLoading}
                  handleMoreLoadingEmail={handleMoreLoadingEmail}
                  isMoreLoadingEmail={isMoreLoadingEmail}
                  nextPageEmail={nextPageEmail}
                  tabValue={value}
                  fromClientDetails={true}
                  />
            </div>
            <div style={{ display: value === '6' ? "block" : "none" }}>
              <ContactComponent
                  fromClientDetails={true}
                  companyObj={companyObj} 
                  companyUpdated={props.companyUpdated}
                  setCompanyObj={setCompanyObj}
                />
            </div>
            <div style={{ display: value === '7' ? "block" : "none" }}>
            <ClientWorkReport
              fromClientDetails={true}
              clientId={companyObj.id}
            />
            </div>
            <ContactDrawer 
                  openContactDrawer={openContactDrawer}
                  contactObj={contactObj}
                  handleContactDrawerClose={handleContactDrawerClose}
                  fromClientDetails={true}
                  companyObj={companyObj}
                  setCompanyObj={setCompanyObj}
                  companyUpdated={props.companyUpdated}
              />
            
          </div>
        </div>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  selectedOrganization: state.organizationInfo.selectedOrganization,
  cd: state.organizationInfo.cd,
  accountList: state.organizationInfo.accountList
});

export default connect(mapStateToProps, null)(ClientDetailsModal);