import React from 'react';
import MuiAlert from '@material-ui/lab/Alert';
import {makeStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {consoleToLog, trackGAEvent, AccessLevel, shouldDisable} from '../util/AppUtil';
import {useSnackbar} from 'notistack';
import ErrorIcon from '@material-ui/icons/Error';
import { setSelectedDrawerState, SelectedDrawerState, setSelectedOrgEmailObj } from '../actions/selectedOrganization';
import { history } from '../routers/AppRouter';

const useStyles = makeStyles((theme) => ({
    mainContent : {
        width: (showDrawer) => {
           return showDrawer ? "83%" : "100%"; 
        },
        backgroundColor: theme.palette.primary.light, 
        transition: ".5s", 
        flexGrow: 1,
        },
    
    MuiAlertroot: {
        borderRadius:'0px',
        paddingTop:'2px',
        paddingBottom:'2px',
        fontSize:'13px'
    },
    MuiAlertfilledError: {
        color: '#c75877',
        fontWeight: 500,
        backgroundColor: '#fdd4cd',
    },
    linkStyles: {
        color: '#20a8e0',
        cursor: 'pointer',
        fontSize:'13px',
        margin:"0px 1px 0px 8px",
    },
    errorIconStyle: {
        marginRight:'-6px', 
        fontSize:'18px', 
        marginTop:'1px'
    }
    }
));

function Alert(props) {
    return <MuiAlert elevation={0} variant="filled" {...props}  />;
}

const SnackbarComponent = (props) => {
    const {showDrawer} = props;
    const classes = useStyles(showDrawer); 
    const {enqueueSnackbar} = useSnackbar();

    const namesList = props.verifiedEmailArr?.map((email) => email.email).join(', ');

    const { access_level } = props.selectedOrganization || {};
    const accessLeveOwner = shouldDisable(AccessLevel.getUserAccessLevelValue(access_level), AccessLevel.OWNER);


    const onAddNowClick = () => {
        if(props.isFromCreateTemplate) history.push('/dashboard');
        props.setSelectedDrawerState(SelectedDrawerState.SHOW_ORG_SETTINGS);
        const emailObj = {
            email_type: 'gmail'
        }
        props.setSelectedOrgEmailObj(emailObj);
    }

    return (
        <>
        {   
            props.no_support_email &&
            <div className={classes.mainContent} style={{width: (props.isFromCreateTemplate || props.isFromWaitingFromMyApproval) && '100%'}}>
                <Alert severity="error" icon={<ErrorIcon fontSize='small' className={classes.errorIconStyle}/>} classes={{
                        root: classes.MuiAlertroot,
                        filledError: classes.MuiAlertfilledError
                    }}>No support email connected. Please connect support email to start sending and receiving email. 
                    {!accessLeveOwner && <a onClick={onAddNowClick} className={classes.linkStyles}> Add Now </a> }
                </Alert>
            </div>
        }

        {
            props.verifiedEmailArr && props.verifiedEmailArr.length > 0 &&
            <div className={classes.mainContent} 
                style={{width: (props.isFromCreateTemplate || props.isFromWaitingFromMyApproval) && '100%',
                        marginBottom: props.isFromCreateTemplate && '1px'}}>
                <Alert severity="error" icon={<ErrorIcon fontSize='small' className={classes.errorIconStyle}/>} classes={{
                        root: classes.MuiAlertroot,
                        filledError: classes.MuiAlertfilledError
                    }}>Your support email's {namesList} {props.verifiedEmailArr.length === 1 ?  'is' : 'are'} not verified. 
                    {!accessLeveOwner && <a onClick={onAddNowClick} className={classes.linkStyles}> Verify </a> }
                </Alert>
            </div>
        }

        {
            !props.no_support_email && props.templateRuleErrorMessage && 
            <div className={classes.mainContent} 
                style={{width: (props.isFromCreateTemplate || props.isFromWaitingFromMyApproval) && '100%',
                        marginBottom: props.isFromCreateTemplate && '1px'}}>
                <Alert severity="error" icon={<ErrorIcon fontSize='small' className={classes.errorIconStyle}/>} classes={{
                        root: classes.MuiAlertroot,
                        filledError: classes.MuiAlertfilledError
                    }}>{props.templateRuleErrorMessage}
                </Alert>
            </div>
        }
        </>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    templateRuleErrorMessage: state.organizationInfo.templateRuleErrorMessage
})

const mapDispatchToProps = (dispatch) => ({
    setSelectedDrawerState: (drawerState) => dispatch(setSelectedDrawerState(drawerState)),
    setSelectedOrgEmailObj: (emailObj) => dispatch(setSelectedOrgEmailObj(emailObj))
})

export default connect(mapStateToProps, mapDispatchToProps)(SnackbarComponent);