import React, {useState, useEffect} from 'react';
import {TextField, Grid, Button, Typography, Divider, Tooltip} from '@material-ui/core'; 
import {makeStyles} from '@material-ui/core/styles';
import RightToLeftSideDrawer from '../RightToLeftSideDrawer';
import {getSimilarNameCompaniesApi} from '../../services/authService';
import {consoleToLog} from '../../util/AppUtil';
import {useSnackbar} from 'notistack';
import {connect} from 'react-redux';

const useStyles = makeStyles((theme) => ({
        createNewCompany: {
            margin:'20px 16px 0px 16px',
        },
        list: {
            display: 'flex',
            justifyContent: 'space-between',
            cursor: 'pointer',
            '&:hover' : {
                background:'#F9F9F9'
            }
        },
        MuiFormControlroot: {
            '& .MuiOutlinedInput-input' :{
                paddingLeft: '16px',
            },   
        },
        listContainer : {
            '&:last-child .dropdownDivider' : {
                display: 'none'
            }
        },
        dividerStyle : {
            background:'#000', 
            opacity:'0.1'
        }
})); 

const AddNewCompanyModal = (props) => {
    const {enqueueSnackbar} = useSnackbar();
    const classes = useStyles();
    
    const [companyList, setCompanyList] = useState([]);
    const [searchText, setSearchText] = useState('');

    useEffect(() => {
        setCompanyList([]);

        return () => {
            setCompanyList([]);
            setSearchText('');
        }
    }, [props.openAddCompanyDrawer]);

    const onCreateCompanyClick = () => {
        setCompanyList([]);
        props.handleOpenCreateCompanyDrawer();
        props.setOpenAddCompanyDrawer(false);
    }

    const fetchCompanyList = (text) => {
        const organization_id = props.selectedOrganization.organization.id;

        getSimilarNameCompaniesApi(text, organization_id) 
            .then((response) => {
                const res = response.data;
                consoleToLog('Response getSimilarNameCompaniesApi ', res);
                setCompanyList(res.company);
            })
            .catch((e) => {
                consoleToLog('Response getSimilarNameCompaniesApi ', e);
                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant:'error'
                    });
                    return false;
                }
            })
    }

    const boldString = (company_name) => {
        let boldStringText = new RegExp(searchText, 'igm');
        let result =  company_name.replace(boldStringText, '<strong>' + searchText + '</strong>');
        return result;
    }

    return (
        <RightToLeftSideDrawer title="Add Client"
            openDrawer={props.openAddCompanyDrawer}
            closeDrawer={props.handleDrawerClose}
            fromOnboardingComponent={props.fromOnboardingComponent}
        >
            <div className="modal__main">
                <Grid container md={12} lg={12}>
                    <Grid item lg={12}>
                        <Typography variant="subtitle1" style={{marginBottom:'-8px'}}>
                            Client Name
                        </Typography>

                        <Grid item style={{position:'relative'}}>
                            <TextField fullWidth 
                                id="company_name" 
                                variant="outlined"
                                type="text"
                                margin="dense"
                                value={searchText}
                                autoComplete="off"
                                placeholder="Enter Client Name"
                                InputLabelProps={{style: {fontSize: 13, color: "#ccc"}}}
                                classes={{
                                    root: classes.MuiFormControlroot
                                }}
                                onChange={(e) => {
                                    setSearchText(e.target.value);
                                    fetchCompanyList(e.target.value);
                                    props.setCompanyName(e.target.value);
                                }}
                            />
                            {(companyList) &&   
                                    <Grid item 
                                        style={{minHeight:'10px !important'}}
                                        className={`orgcomp__menu ${(searchText !== '')  ? "active" : "inactive"}`}
                                    >
                                        <div className={classes.createNewCompany}>
                                            <Button  variant="contained" color="primary" 
                                                disableElevation
                                                onClick={onCreateCompanyClick}
                                            >
                                                Create New Client
                                            </Button>
                                            <Typography style={{
                                                fontSize:'14px',
                                                color:'#555',
                                                margin: '15px 0px 10px 0px'
                                            }}>
                                                Other Matching Clients
                                            </Typography>
                                        </div>
                                        <Divider className={classes.dividerStyle} />

                                        {companyList.length > 0 ?
                                            companyList.map((company) => {
                                                let company_name = company.name
                                                return <ul key={company.id} className={classes.listContainer}>
                                                             <li className={classes.list} 
                                                                onClick={() => {
                                                                    !props.fromOnboardingComponent &&
                                                                    props.handleOpenDetailsModal(company);
                                                                }}
                                                             >
                                                                <span dangerouslySetInnerHTML={{__html: boldString(company_name)}}></span>
                                                                {!props.fromOnboardingComponent &&
                                                                <Tooltip title="Edit" arrow>
                                                                    <span style={{marginLeft:'auto'}}>
                                                                        <i className="flaticon-edit"></i>
                                                                    </span>
                                                                </Tooltip>}
                                                            </li>
                                                            <Divider className={`dropdownDivider ${classes.dividerStyle}`}/>
                                                       </ul>
                                            }) : <ul className={classes.listContainer} style={{textAlign:'right'}}>
                                                    <li className={classes.list}>
                                                        <span style={{fontSize:'14px', color:'#555'}}>
                                                            No Clients Found
                                                        </span>
                                                    </li>
                                                 </ul> 
                                        }
                                    </Grid>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </div>    
        </RightToLeftSideDrawer>
    );
}

const mapStateToProps = (state) =>({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    templates: state.organizationInfo.templates 
})

export default connect(mapStateToProps)(AddNewCompanyModal);