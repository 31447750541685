import React, {useState, useEffect} from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {Grid, Container, Typography, Button, CircularProgress, LinearProgress, Popover, ListItem } from '@material-ui/core';
import {connect} from 'react-redux';
import { getChecklistApi, modifyMembersFromChecklistApi, updateCheckListDueDateApi } from '../../services/authService';
import moment from 'moment';
import {fetchTemplateList, SelectedDrawerState, setSelectedTemplate, setSelectedDrawerState} from '../../actions/selectedOrganization';
import { useParams } from 'react-router-dom';
import { consoleToLog, getAssigneesFromChecklistItem , shouldDisable, AccessLevel, trackGAEvent} from '../../util/AppUtil';
import MobileChecklistGroupComponent from './MobileChecklistGroupComponent';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import MobileSelectFromMembersList from './checklist/MobileSelectFromMembersList';
import { useIsMount } from '../../components/useIsMount';
import { useSnackbar } from 'notistack';
import {history} from '../routers/MobileAppRouter';
import SortIcon from '@material-ui/icons/Sort';
import AddIcon from '@material-ui/icons/Add';
import MobileRightToLeftSideDrawer from './MobileRightToLeftSideDrawer';
import MobileChecklistFilter from './MobileChecklistFilter';
import MobileCreateFolder from './MobileCreateFolder';
import MobileCreateChecklist from './MobileCreateChecklist';
import Calender from '../../components/Calender';
import MobileChecklistDetailsModal from '../pages/modal/MobileChecklistDetailsModal';
import { useLocation } from 'react-router-dom';
import MobileSetDueDateDrawer from './MobileSetDueDateDrawer';
import {showEmailIcons} from '../../util/AppUtil';
import {showChecklistStatusColor, checklistLabelList} from '../util/MobileAppUtil';

const useStyles = makeStyles((theme) => ({
    mainContent : {
        width: '100%',
        backgroundColor: theme.palette.primary.light,
        height:'calc(100vh - 100px)',
        transition: ".5s",
        flexGrow: 1,
        //overflow:'hidden',
        overflow:'auto',
        },
    container: {
        marginTop: "16px",
    },
    loading: {
        position:'relative',
        left:'50%',
        right:'50%',
        top:'24px'
    },
    checklistStyles: {
        background:'#fff',
        padding:'8px 14px'
    },
    companyNameStyle: {
        color:"#333333",
        maxWidth:'280px',
        whiteSpace:'nowrap',
        overflow:'hidden',
        textOverflow:'ellipsis',
        [theme.breakpoints.down(385)] : {
            maxWidth:'255px',
        }
    },
    subtitleStyle: {
        fontSize:'13px',
        color:'#888888',
        marginLeft:'8px'
    },
    sidemenubtns: {
        color: "#808080",
        fontSize: "15px",
        minWidth:'20px',
        padding: '0px 5px'
    },
    icontext : {
        color:'#808080',
        padding:'0 0px 0 5px',
    },
    labels : {
        display:'flex',
        marginTop:'3px',
        flexGrow:'1',
        '& .labellist:last-child' : {
            '& .bullet' : {
            display: 'none'
            }
        }
    },
    filterAndCreateIcon: {
        display:'flex',
        position:'fixed',
        right:'24px',
        bottom:'32px'
    },
    emailIcons: {
        filter: 'invert(46%) sepia(96%) saturate(5%) hue-rotate(346deg) brightness(96%) contrast(96%)'
    }
}))

const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 4,
      borderRadius: 0,
    },
    colorPrimary: {
      backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
      borderRadius: 5,
      backgroundColor: '#525252',
    },
  }))(LinearProgress);

const MobileChecklistComponent = (props) => {
    const classes = useStyles();
    const isMount = useIsMount();
    const {enqueueSnackbar} = useSnackbar();
    let {slug} = useParams();
    const location = useLocation();

    const [checkList, setCheckList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [nextPage, setNextPage] = useState(false);
    const [isMoreLoading, setIsMoreLoading] = useState(false);
    const [checklistGroup, setChecklistGroup] = useState([]);
    const [total, setTotal] = useState(0);
    const [selectedChecklistItem, setSelectedChecklistItem] = useState(undefined);

    //create checklist, filter, folder
    const [openDrawer, setOpenDrawer] = useState(false);
    const [createFolder, setCreateFolder] = useState(false);
    const [createChecklist, setCreateChecklist] = useState(false);
    const [openFilters, setOpenFilters] = useState(false);

    //create folder checklist popover
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorEl1, setAnchorEl1] = useState(null);

    //checklist details
    const [openDialog, setOpenDialog] = useState(false);
    const [currentChecklistItem, setCurrentChecklistItem] = useState(undefined);
    const [tabValue, setTabValue] = useState(0); 

    const [openDueDateDrawer, setOpenDueDateDrawer] = useState(false);

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const open1 = Boolean(anchorEl1);
    const id1 = open1 ? 'due_date-popover' : undefined;

    /**Access Levels */
    const { access_level } = props.selectedOrganization || {};
    const accessLevelManager = shouldDisable(AccessLevel.getUserAccessLevelValue(access_level), AccessLevel.MANAGER);

    useEffect(() => {
        modalStatus()
        const template_id = props.selectedTemplate ? props.selectedTemplate.template_id : undefined;
        if(template_id) {
            fetchChecklist();
        }
    }, []);

    useEffect(() => {
        if (!isMount) {
            if (isMoreLoading) {
                consoleToLog('mobile isMoreLoading api called');
                fetchChecklist();
            }
        }
    }, [isMoreLoading]);

    useEffect(() => {
        if(!isMount) {
             consoleToLog('template id changed')
             pageReset();
         }
     }, [props.selectedTemplate?.template_id]);

    useEffect(() => {
        if(!isMount) {
            consoleToLog('mobile filters changed');
                if(page === 1) {
                    fetchChecklist();
                }
        }
    }, [props.selectedTemplate?.template_id, slug, props.startDateFilter, props.endDateFilter,
        props.selectedStatusFilter, props.showUnassignedChecklist, props.selectedMembersFilter, 
        props.selectedLabelsFilter, props.companiesFilter, 
    ]);

    useEffect(() => {
        if(!isMount) {
            if(page === 1) {
                consoleToLog('mobile filter changed and page reset');
                fetchChecklist();
            }
        }
    }, [page]);

    useEffect(() => {
        if(!isMount) {
            stateChangeFromParent();
        }
    }, [props.searchChecklistFilter]);

    useEffect(() => {
        if(!isMount) {
            callTemplateApi();
        }
    }, [props.startDateFilter, props.endDateFilter, props.companiesFilter, props.selectedMembersFilter]);

    var urlPath = window.location.pathname.split("/");
    var curr_slug = urlPath[urlPath.length-1];

    useEffect(() => {
        if(!isMount) {
            if(curr_slug) {
                modalStatus();
            }
        }

    }, [curr_slug]);

    const handleDueDateDrawerOpen = (checklistItem) => {
        setOpenDueDateDrawer(true);
        setCurrentChecklistItem(checklistItem);
    }
    
    const handleDueDateDrawerClose = () => {
        setOpenDueDateDrawer(false);
        setCurrentChecklistItem(undefined);
    }

    const stateChangeFromParent = () => {
        setPage(props.page);
        setNextPage(props.nextPage);
        if(page === 1) {
            fetchChecklist();
        }
    }

    const pageReset =  () => {
        setPage(1);
        setNextPage(false);
    }

    const drawerClose = () => {
        setOpenDrawer(false);
        setCreateFolder(false);
        setCreateChecklist(false);
        setOpenFilters(false); 
    }

    const drawerOpen = () => {
        setOpenDrawer(true);
    }

    const createPopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const createPopoverClose = () => {
        setAnchorEl(null);
    }

    const modalStatus = ()=> {
        if(window.location.toString().includes("checklist")){
            setOpenDialog(true)
        }
        else{
          setOpenDialog(false)
        }
    }

    const showPopover = () => (
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={createPopoverClose}
            anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
            }}
            transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
            }}
        >
        <ListItem onClick={() => {
            drawerOpen();
            setCreateChecklist(!createChecklist);
            createPopoverClose();
        }}>Create Checklist</ListItem>
        <ListItem onClick={() => {
            drawerOpen();
            setCreateFolder(!createFolder);
            createPopoverClose();
        }}>Create Folder</ListItem>
      </Popover>
    )

    /**Right menu names */
    const CREATE_FOLDER = 'Create Folder';
    const CREATE_CHECKLIST = 'Create Checklist';
    const FILTERS = 'Filter Checklist';

    const rightToLeftSideDrawerTitle = () => {
        if(createFolder) {
            return CREATE_FOLDER;
        }
        if(createChecklist) {
            return CREATE_CHECKLIST;
        }
        if(openFilters) {
            return FILTERS;
        }
    }

    const callTemplateApi = () => {
        const organization_id = props.selectedOrganization.organization.id;
        
        props.fetchTemplateList(organization_id, props.startDateFilter, props.endDateFilter, 
            props.companiesFilter, props.selectedMembersFilter, props.selectedTemplate)
    }

    const fetchChecklist = () => {
        let template_id = props.selectedTemplate?.template_id;
        // if(!template_id) {
        //     toast.error('Please select a template to view checklist');
        //     return;
        // }

        var user_ids;
        /* if(!user_ids) {
            user_ids = undefined;
        } */

        if(props.showUnassignedChecklist){
            user_ids = ',0'; //no need of user id'
        }
        else{
            user_ids = props.selectedMembersFilter.map((member) => member.user.id).join();
        }

        if (!user_ids) {
            user_ids = undefined;
        }

        let labels = props.selectedLabelsFilter.map((label) => label.id).join();
        if(!labels) {
            labels = undefined;
        }

        //let waiting_for_my_approval = false;
        // if(props.showWaitingForApprovalChecklist) {
        //     waiting_for_my_approval = true;
        // }

        const status = props.selectedStatusFilter.map(status => status).join();
        const search_text = props.searchChecklistFilter ? props.searchChecklistFilter : undefined;
        const organization_id = props.selectedOrganization.organization.id;
        const start_date = props.startDateFilter;
        const end_date = props.endDateFilter;
        const companiesFilter = props.companiesFilter;
        const sortBy = props.sortByFilter;
        const group_slug_code = slug;
        const billable_status = props.selectedBillStatusFilter?.map(status => status).join();

        if(!isMoreLoading)  setLoading(true);
        getChecklistApi(organization_id, template_id, page, start_date, end_date,
            user_ids, labels, status, search_text, 
            companiesFilter, sortBy, group_slug_code, billable_status)
                    .then((response) => {
                        const res = response.data;
                        consoleToLog("Response getChecklistApi: ", res);
                        setLoading(false);
                        setChecklistGroup(res.checklist_group);
    
                        if(isMoreLoading) {
                            const newItems = checkList.concat(res.checklist);
                            setCheckList(newItems);
                            setPage(page + 1);
                            setNextPage(res.next_page);
                            setIsMoreLoading(!isMoreLoading);
                             // to parent
                        } else {
                            setNextPage(res.next_page);
                            if(res.next_page) setPage(page + 1);
                            setCheckList(res.checklist);
                            setTotal(res.total);
                            // to parent
                            }
                        })
                        .catch((e) => {
                            consoleToLog(" getChecklistApi error", e);
                            setLoading(false);
                            if (e.response.data && e.response.data.message) {
                                return false;
                            }
                        });
    }

    const loadMoreLogic = () => {
        return (
            <Grid item container justifyContent='center'>
                <Grid item lg={12}>
                {nextPage && !isMoreLoading &&
                    <div style={{textAlign: "center", margin:'16px 0px'}}>
                        <Button type="button"
                            color="secondary"
                            variant="contained"
                            onClick={() => {
                                setIsMoreLoading(!isMoreLoading);
                            }}>Load More
                        </Button>
                    </div>}

                { isMoreLoading &&
                    <CircularProgress size={25} style={{position:'relative', left:'50%', right:'50%', margin:'16px 0px'}}/>
                }
                </Grid>
            </Grid>
        )
    }

    const onFolderClick = (slug_code) => {
        consoleToLog('Folder Slug Code: ', slug_code);
        history.push(`/group/${slug_code}`);
        pageReset();
    }

    const addFolderToList = (newFolder) => {
        drawerClose();
        setChecklistGroup(oldFolders => [newFolder, ...oldFolders]);
    }

    const onChecklistCreation = (newChecklistArr) => {
        const newItems = newChecklistArr.concat(checkList);
         setCheckList(newItems);
    }

    const handleClickNew = (event, checklistItem) => {
        setAnchorEl1(event.currentTarget);
        setSelectedChecklistItem(checklistItem);
      };

    const dueDatePopOverClose = () => {
        setAnchorEl1(null)
    }

    const handleDueDatePopOver = () => {
        return (<Popover
            id={id1}
            open={open1}
            anchorEl={anchorEl1}
            onClose={dueDatePopOverClose}
            anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
            }}
            transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
            }}
        >
            <Calender clickedDate={clickedDate} />
        </Popover>)
    }

    const clickedDate = (str) => {
        
        const checklist_id = selectedChecklistItem.id;
        const checklistDueDate = !str ? str : moment(str).format("YYYY-MM-DD");
        
        updateCheckListDueDateApi(checklist_id, checklistDueDate)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response updateCheckListDueDateApi: ", res);
                const updatedChecklist = checkList.map((checklistItem) => {
                    if (checklistItem.id === selectedChecklistItem.id) {
                        checklistItem.due_date = checklistDueDate ;
                        
                    }
                    return checklistItem;
                });

                //trackGAEvent(props.selectedOrganization.organization.name, 'Checklist Set DueDate', `${props.auth.user.firstname} ${props.auth.user.lastname} set DueDate`);

                setCheckList(updatedChecklist);
                setSelectedChecklistItem(undefined);
            })
            .catch((e) => {
                consoleToLog("updateCheckListDueDateApi error", e.response);
                if (e.response.data && e.response.data.message) {
                    //toast.error(e.response.data.message);
                    return false;
                }
            });

        dueDatePopOverClose();
    }

    
    const isDueDateOverDue = (checklistItem) => {
        var dueDate, currentDate;
        //currentDate
        currentDate = moment(new Date()).format('YYYY-MM-DD');


        //Due Date
        if (checklistItem.due_date)
            dueDate = moment(checklistItem.due_date, "YYYY-MM-DD").format("YYYY-MM-DD")

        //consoleToLog("Now: ", currentDate, "  formatedDueDate: ", dueDate);

        //if Due date available then compare else return false
        if (dueDate) {
            //consoleToLog("is same: ", moment(currentDate).isSame(dueDate));
            //If dates are same its not overdue
            if (moment(currentDate).isSame(dueDate)) return false;

            //check current date is greater/after than provided date if greater return true
            if (moment(currentDate).isAfter(dueDate)) return true;
            else return false;
        }
        else return false;
    }

    const setAssignedMemberList = (assignedMemberList, checklist_id) => {
        var assignees = assignedMemberList.map((member) => member.user.id).join();
        var member_names = assignedMemberList.map((member) => member.user.firstname + ' ' + member.user.lastname).join();

        //Modify Members From Checklist Api
        modifyMembersFromChecklistApi(checklist_id, assignees)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response modifyMembersFromChecklistApi: ", res);

                //trackGAEvent(props.selectedOrganization.organization.name, 'Checklist Add Member', `${props.auth.user.firstname} ${props.auth.user.lastname} added member ${member_names}`);

                //change local state
                const newCheckListArr = checkList.map((checklistItem) => {
                    if (checklistItem.id === checklist_id) {
                        checklistItem.checklist_members_list = res.members;
                        return checklistItem;
                    }
                    return checklistItem;
                });
                setCheckList(newCheckListArr);
            })
            .catch((e) => {
                consoleToLog("modifyMembersFromChecklistApi error", e.response);

                //if failed, we still display last added member in SelectFromMemberList,
                //to remove that member just refresh state,
                //so that original checklist data passed to SelectFromMemberList, and members gets updated.
                setCheckList(checkList);

                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant:'error'
                    });
                    return false;
                }
            });
    }

    const handleDetailsDialogOpen = (current_slug, checklistItem) => {
        setTabValue(0);
        setOpenDialog(true);
        history.push(`/checklist/${current_slug}`);
        setCurrentChecklistItem(checklistItem);
    }

    const handleDetailsDialogClose = () => {
        if (checklistGroup.length > 0 && (checklistGroup[0] && checklistGroup[0].slug_code !== undefined)){
            history.goBack();
        }else{
            history.push("/dashboard");
        }

        if(location.activityObj) {
            props.setSelectedTemplate('');
            props.setSelectedDrawerState(SelectedDrawerState.SHOW_ACTIVITY_REPORT);
            history.push('/dashboard',
                {activityArr: location.activityList,
                isMoreLoading: location.isMoreLoading,
                nextPage: location.nextPage,
                currPage: location.page,
                scrollTop: location.scrollTop
            })
        }

    }

        // get current checklistitem to pass in CheckListDetailsPage
        const getCurrentchecklistItem = (checklistItem) => {
            setCurrentChecklistItem(checklistItem);
        };

    const handleTabOpen = (current_slug, tabName) => {
        if (tabName === "Attachments") {
            setTabValue(1);
        }
        if (tabName === "Notes") {
            setTabValue(3);
        }
        if (tabName === "Audit Trail") {
            setTabValue(4);
        }

        // consoleToLog("******* tabName",tabName);
        setOpenDialog(true);
        history.push(`/checklist/${current_slug}`);
    };

    const showMobileChecklistItems = () => {
        return (
            <Grid item xs={12} style={{marginTop:'16px'}}>
                {
                    checkList && checkList.length > 0 ?
                    checkList.map(checklistItem => {
                        return <Grid item key={checklistItem.id} style={{marginBottom:'16px'}}>
                                    <Grid item className={classes.checklistStyles}
                                        style={{borderLeft: showChecklistStatusColor(checklistItem)}}>  
                                        <Grid item>
                                            <Typography variant="subtitle1" style={{display:'flex'}}>
                                                <span className={classes.companyNameStyle} 
                                                    onClick={(e)=>{
                                                        //trackGAEvent(props.selectedOrganization.organization.name, 'Checklist Opened', `${props.auth.user.firstname} ${props.auth.user.lastname} opened checklist`);
                                                        handleDetailsDialogOpen(checklistItem.slug_code,checklistItem);
                                                    }}
                                                >
                                                    {checklistItem.title}
                                                </span>

                                                <span className={classes.subtitleStyle}>
                                                    {checklistItem.subtitle}
                                                </span>
                                            </Typography>
                                        </Grid>

                                        <Grid item container alignItems='center' style={{marginTop:'3px'}}>
                                            <Grid item>
                                                <Button aria-label="delete"
                                                    className={classes.sidemenubtns}
                                                    onClick={(e)=>{
                                                        trackGAEvent(props.selectedOrganization.organization.name, 'Checklist Notes Icon', `${props.auth.user.firstname} ${props.auth.user.lastname} clicked notes icon`);
                                                        getCurrentchecklistItem(checklistItem);
                                                        handleTabOpen(checklistItem.slug_code, "Notes");
                                                    }}
                                                >
                                                    <i className="flaticon-comment" style={{lineHeight:'5px'}}></i>
                                                    <Typography variant="subtitle1" className={classes.icontext}>
                                                    {checklistItem.notes_count ? checklistItem.notes_count : '0'}
                                                    </Typography>
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button aria-label="delete"
                                                    className={classes.sidemenubtns}
                                                    onClick={(e)=>{
                                                        trackGAEvent(props.selectedOrganization.organization.name, 'Checklist Attachment Icon', `${props.auth.user.firstname} ${props.auth.user.lastname} clicked attachment icon`);
                                                        getCurrentchecklistItem(checklistItem);
                                                        handleTabOpen(checklistItem.slug_code, "Attachments");
                                                    }}
                                                >
                                                        <i className="flaticon-attachment-1" style={{lineHeight:'5px'}}></i>
                                                        <Typography variant="subtitle1" className={classes.icontext}>
                                                            {checklistItem.attachment_count ? checklistItem.attachment_count : '0'}
                                                        </Typography>
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button aria-label="delete"
                                                className={classes.sidemenubtns}
                                                onClick={(e)=>{
                                                    trackGAEvent(props.selectedOrganization.organization.name, 'Sent Email Icon Clicked', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
                                                    getCurrentchecklistItem(checklistItem);
                                                    handleTabOpen(checklistItem.slug_code, "Audit Trail");}}
                                                >
                                                    <img src={showEmailIcons('out_going')} className={classes.emailIcons}/>
                                                    <Typography variant="subtitle1" className={classes.icontext}>
                                                    {checklistItem.out_email_count ? checklistItem.out_email_count : '0'}
                                                    </Typography>
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button aria-label="delete"
                                                className={classes.sidemenubtns}
                                                onClick={(e)=>{
                                                    trackGAEvent(props.selectedOrganization.organization.name, 'Received Email Icon Clicked', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
                                                    getCurrentchecklistItem(checklistItem);
                                                    handleTabOpen(checklistItem.slug_code, "Audit Trail");}}
                                                >
                                                    <img src={showEmailIcons('in_coming')} className={classes.emailIcons}/>
                                                    <Typography variant="subtitle1" className={classes.icontext}>
                                                    {checklistItem.in_email_count ? checklistItem.in_email_count : '0'}
                                                    </Typography>
                                                </Button>
                                            </Grid>
                                            {checklistItem.due_date &&
                                            <Grid item >
                                                <Button aria-label="delete"
                                                    onClick={(e) =>{!accessLevelManager && handleDueDateDrawerOpen(checklistItem)}}
                                                    className={classes.sidemenubtns}
                                                    style={{color: checklistItem.billable_status !== 'paid' && isDueDateOverDue(checklistItem) &&'red'}}
                                                    >
                                                    <i className="flaticon-calendar-1" style={{lineHeight:'5px'}}></i>
                                                    <Typography variant="subtitle1"
                                                    style={{color:checklistItem.billable_status !== 'paid' && isDueDateOverDue(checklistItem) &&'red'}}
                                                    className={classes.icontext}>{`${moment(checklistItem.due_date).format('Do MMM')}`}</Typography>
                                                </Button>
                                                {/* {!accessLevelManager && handleDueDateDrawerOpen(checklistItem)} */}
                                            </Grid> }
                                        </Grid>   

                                        <Grid item container alignItems='center'
                                            justifyContent='space-between' 
                                            style={{marginTop:'3px'}}>
                                            <Grid item style={{marginBottom:'6px'}}>
                                                {(checklistItem.checklist_labels_list && checklistItem.checklist_labels_list.length > 0) &&
                                                    <div style={{display:'flex'}}>
                                                        <div className={classes.labels}>
                                                            {checklistItem.checklist_labels_list.slice(0,1).map((label) => {
                                                                return <div key={label.id} style={{display:'flex', alignItems:'flex-start'}} className='labellist'>
                                                                            <Typography
                                                                                //onClick={() => onLabelsClick(label)}
                                                                                style={{color: `${label.color}`,
                                                                                paddingLeft:'3px',
                                                                                cursor:'pointer',
                                                                                paddingTop:'0',
                                                                                fontSize:'12px'}}>{label.name}</Typography>
                                                                            <FiberManualRecordIcon fontSize="small"
                                                                                className="bullet"
                                                                                style={{width:'6px', height:'6px', color:"#ccc", paddingLeft:'3px', paddingTop:'0', marginTop:'7px'}}/>
                                                                        </div>
                                                                    })
                                                            }
                                                        </div>
                                                        <span>
                                                        {(checklistItem.checklist_labels_list && checklistItem.checklist_labels_list.length > 1) &&
                                                            checklistLabelList(checklistItem) }
                                                        </span>
                                                    </div>
                                                }
                                            </Grid>

                                            <Grid item>
                                                <MobileSelectFromMembersList isFromChecklistPage={true}  
                                                    assignees={getAssigneesFromChecklistItem(checklistItem)}
                                                    setAssignedMemberList={(selectedAssignees) => setAssignedMemberList(selectedAssignees, checklistItem.id)}
                                                    disabledAssignChecklistMembers={accessLevelManager}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                <BorderLinearProgress variant="determinate" 
                                    value={checklistItem.total_task > 0 ? Math.round((checklistItem.completed_task / checklistItem.total_task) * 100) : 0} />        
                               </Grid>
                    }) : <Grid item style={{
                            display:'flex', 
                            justifyContent:'center', 
                            background:'#fff',
                            marginTop:'32px', 
                            padding:'8px'}}>
                            
                            No Checklist Found
                         </Grid>
                }
            </Grid>
        )
    }

    // onTaskStatusChanged from details
    const onTaskStatusChanged = (selectedChecklistItemId, updatedChecklist) => {
        const newCheckListStatus = checkList.map((checklistItem) => {
            if (checklistItem.id === selectedChecklistItemId) {
                return updatedChecklist;
            }
            else return checklistItem;
        })
        // consoleToLog("newCheckListStatus in Checklist Component ",newCheckListStatus)
        setCheckList(newCheckListStatus);
    }

    const onAttachmentDelete = (updatedDeleteAttachmentArray) =>{
        const newCheckListArr = checkList.map((checklistItem) => {
            if (checklistItem.id === currentChecklistItem.id) {
                checklistItem.attachment_count = updatedDeleteAttachmentArray.length;
                return checklistItem;
            }else{
                return checklistItem;
            }
        });
        setCheckList(newCheckListArr);
    }

    const onAttachmentAdded = (updatedAttachmentsArray) => {
        const newCheckListArr = checkList.map((checklistItem) => {
            if (checklistItem.id === currentChecklistItem.id) {
                checklistItem.attachment_count = updatedAttachmentsArray.length + 1;
                return checklistItem;
            }else{
                return checklistItem;
            }
        });
        setCheckList(newCheckListArr);
    }

    const onNoteAdded = (newArray,checklistId) =>{
        const newNotesArray = checkList.map((checklistItem) => {
            if (checklistItem.id === checklistId) {
                checklistItem.notes_count = newArray.length;
                return checklistItem;
            }else{
                return checklistItem;
            }
        });
        // consoleToLog("onNoteDeleted",newNotesArray);
        setCheckList(newNotesArray);
    }

    const onNoteDeleted = (newArray,checklistId) =>{
        const newNotesArray = checkList.map((checklistItem) => {
            if (checklistItem.id === checklistId) {
                checklistItem.notes_count = newArray.length;
                return checklistItem;
            }else{
                return checklistItem;
            }
        });
        setCheckList(newNotesArray);
    }

    const onUpdateSchedulerChecklistItem = (checklist_id, checklistObj) => {
        // consoleToLog("onUpdateSchedulerChecklistItem in ChecklistComponent",checklistObj.status);
        const newChecklistArray = checkList.map((checklistItem) => {
            if (checklistItem.id === checklist_id) {
                return {
                    ...checklistItem,
                    active_scheduler_count: checklistObj.active_scheduler_count,
                    total_scheduler_count: checklistObj.total_scheduler_count
                }
            } else {
                return checklistItem;
            }
        });
        setCheckList(newChecklistArray)
    }

    const onUpdateSchedulerChecklistItemStatus =(checklist_id,checklistObj)=>{
        const newChecklistArray = checkList.map((checklistItem) => {
            if (checklistItem.id === checklist_id) {
                    return checklistObj;
            } else {
                    return checklistItem;
            }
        })
        setCheckList(newChecklistArray)
    }

    const showFilterAndCreateButtons = () => {
        return (
            <div className={classes.filterAndCreateIcon}>
                    <Button variant="contained" color="secondary" 
                        onClick={() => {
                            drawerOpen();
                            setOpenFilters(!openFilters);
                        }}
                        style={{
                            borderTopLeftRadius:'50px',
                            borderBottomLeftRadius:'50px',
                            borderTopRightRadius:'0px',
                            borderBottomRightRadius:'0px',
                            paddingRight:'-8px !important',
                        }}>
                        <SortIcon />
                    </Button>

                    <Button variant="contained" color="secondary"
                        onClick={createPopoverOpen}
                        style={{
                            borderTopLeftRadius:'0px',
                            borderBottomLeftRadius:'0px',
                            borderTopRightRadius:'50px',
                            borderBottomRightRadius:'50px',
                            paddingLeft:'-8px !important',
                        }}>
                        <AddIcon />
                    </Button>
                    {showPopover()}
            </div>
        )
    }   

    return (
        <div className={classes.mainContent} style={{position:'relative'}}>
            <div className={classes.container}>
                {
                    !loading &&
                    <MobileChecklistGroupComponent checklistGroup={checklistGroup}
                        onFolderClick={onFolderClick}
                        slug={slug}
                        pageReset={pageReset}
                        loading={loading}
                    />
                }
                {
                    loading ? 
                    <CircularProgress size={25} className={classes.loading}/>
                    :
                    <div style={{position:'relative'}}>
                        {showMobileChecklistItems()}
                        {loadMoreLogic()}
                    </div>
                }
            </div>
            {showFilterAndCreateButtons()}
            <MobileRightToLeftSideDrawer openDrawer={openDrawer}
                drawerClose={drawerClose}
                title={rightToLeftSideDrawerTitle()}>
                    {
                        openFilters &&
                        <MobileChecklistFilter pageReset={pageReset}/>
                    }

                    {
                        createFolder &&
                        <MobileCreateFolder addFolderToList={addFolderToList} 
                            drawerClose={drawerClose}/>
                    }
                    {   
                        createChecklist &&
                        <MobileCreateChecklist drawerClose={drawerClose}
                            checklistGroup={checklistGroup}
                            onChecklistCreation={onChecklistCreation}
                        />
                    }

            </MobileRightToLeftSideDrawer>

            {
                openDialog && 
                <MobileChecklistDetailsModal openDialog={openDialog}
                    currentChecklistItem={currentChecklistItem}
                    checkList={checkList}
                    setCheckList={setCheckList}
                    accessLevelManager={accessLevelManager}
                    tabValue={tabValue}
                    isDueDateOverDue={isDueDateOverDue}
                    modalStatus={modalStatus}
                    slug = {curr_slug}
                    handleDetailsDialogClose={handleDetailsDialogClose}
                    onTaskStatusChanged={onTaskStatusChanged}
                    onAttachmentDelete={onAttachmentDelete}
                    onAttachmentAdded={onAttachmentAdded}
                    onUpdateSchedulerChecklistItem={onUpdateSchedulerChecklistItem}
                    onNoteAdded={onNoteAdded}
                    onNoteDeleted={onNoteDeleted}
                    />
            }

            <MobileSetDueDateDrawer openDueDateDrawer={openDueDateDrawer}
                handleDueDateDrawerClose={handleDueDateDrawerClose}
                checkList={checkList}
                checklistDetails={currentChecklistItem}
                setCheckList={setCheckList}
                />
        </div>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedTemplate: state.organizationInfo.selectedTemplate,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    startDateFilter: state.filters.startDateFilter,
    endDateFilter: state.filters.endDateFilter,
    selectedStatusFilter: state.filters.selectedStatusFilter,
    selectedMembersFilter: state.filters.selectedMembersFilter,
    selectedLabelsFilter: state.filters.selectedLabelsFilter,
    companiesFilter: state.filters.companiesFilter,

    searchChecklistFilter: state.filters.searchChecklistFilter,
    templates: state.organizationInfo.templates,
    showUnassignedChecklist: state.filters.showUnassignedChecklist,
    selectedBillStatusFilter: state.filters.selectedBillStatusFilter,
    sortByFilter: state.filters.sortByFilter,

    isLoading: state.loading.isLoading,
});



const mapDispatchToProps = (dispatch) => ({
    fetchTemplateList: (organization_id, start_date, end_date, companiesFilter, selectedMembersFilter, selectedTemplate) => 
        dispatch(fetchTemplateList(organization_id, start_date, end_date, companiesFilter, selectedMembersFilter, selectedTemplate)),
    setSelectedTemplate: (template) => dispatch(setSelectedTemplate(template)),
    setSelectedDrawerState: (drawerState) => dispatch(setSelectedDrawerState(drawerState))
    })

export default connect(mapStateToProps, mapDispatchToProps)(MobileChecklistComponent);