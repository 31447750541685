import React, {useState, Fragment} from 'react';
import {TextField, Grid, Avatar, Chip} from '@material-ui/core'; 
import {connect} from 'react-redux';
import {trackGAEvent} from '../../util/AppUtil';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ClearIcon from '@material-ui/icons/Clear';
import {addSelectedLabelToFilter, removeSelectedLabelFromFilter} from '../../actions/filters';

const useStyles = makeStyles((theme) => ({
    avatar : {
        width: '15px !important',
        height: '15px !important',
        fontSize: '12px !important',
        background: 'none !important',
        color: 'rgba(0, 0, 0, 0.87) !important',
        border: '1px solid rgba(0, 0, 0, 0.26) !important'
        },
    
    chip : {
        borderRadius: '4px !important',
        backgroundColor: '#f9f9f9 !important',
        border: '1px solid #ececec !important',
        },
        
    avatarStyle: {
        width: 20,
        height: 20,
        fontSize: 12
    },
       
    autoStyle: {
        "&::-webkit-scrollbar": {
            display: "none"
        }
            
    }

    }
));

const ChecklistLabelsFilter = (props) => {
    const classes = useStyles();
    const[searchLabelFilterLocal, setSearchLabelFilterLocal] = useState([]);

    const onAddLabel = (option) => {
        trackGAEvent(props.selectedOrganization.organization.name, 'Labels Added', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
        props.addSelectedLabelToFilter(option);
    }

    const onDeleteLabel = (option) => {
        trackGAEvent(props.selectedOrganization.organization.name, 'Labels Removed', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
        setSearchLabelFilterLocal(searchLabelFilterLocal.filter(entry => entry !== option));
        props.removeSelectedLabelFromFilter(option);
        props.isFromMobileComponent && props.pageReset();
    }

    const renderOptions = (option) => {
        return (
            <div style={{display: "flex", width:'100%'}} 
                className={classes.autoStyle}
                onClick={() => {
                    onAddLabel(option)
                    props.isFromMobileComponent && props.pageReset();
                }} 
            >
                <Avatar style={{marginRight: "6px", marginTop: "4px"}}  
                classes={{
                    root: classes.avatar
                }}
                >
                    {option.name.charAt(0)}
                </Avatar>
                {option.name}
            </div>
        );
    }
    
    const renderTags = (options) => {
        return (
            options.map(option =>
                <Fragment>
                <Chip
                    classes={{
                        root: classes.chip
                    }}
                    avatar={<Avatar classes={{
                        root: classes.avatar
                    }}>{option.name.charAt(0)}</Avatar>}
                    label={option.name}
                    onDelete={() => onDeleteLabel(option)}
                    variant="outlined"
                    style={{margin: "2px 2px"}}
                    deleteIcon={<ClearIcon  style={{width: "18px"}}/>}
                />
                </Fragment>))
        }

    return (
        <div style={{marginTop:'16px'}}>
           <span style={{fontSize:'14px'}}>
                Labels            
            </span>
            <Grid item style={{marginTop:'8px'}}>
                <Autocomplete
                    multiple
                    style={{width: "100%"}}
                    id="size-small-outlined-multi"
                    size="small"
                    options={props.labels}
                    getOptionLabel={(option) => option.name}
                    disableClearable
                    renderOption={(option) => renderOptions(option)}
                    value={props.selectedLabelsFilter}
                    onChange={(e, newValue) => setSearchLabelFilterLocal(newValue)}
                    renderTags={(options) => renderTags(options)}
                    renderInput={(params) => (
                        <TextField {...params} variant="outlined" placeholder="Apply Label" />
                    )}
                />
            </Grid>    
        </div>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    labels: state.organizationInfo.labels,
    selectedLabelsFilter: state.filters.selectedLabelsFilter
});

const mapDispatchToProps = (dispatch) => ({
    addSelectedLabelToFilter: (item) => dispatch(addSelectedLabelToFilter(item)),
    removeSelectedLabelFromFilter: (item) => dispatch(removeSelectedLabelFromFilter(item))
});

export default connect(mapStateToProps, mapDispatchToProps)(ChecklistLabelsFilter);