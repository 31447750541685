import React, {useEffect, useState} from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles'; 
import { Grid, Container, Typography,  Button, Popover, Tooltip ,CircularProgress} from '@material-ui/core';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import { addOrgMember } from '../actions/selectedOrganization';
import { DateRangePicker } from "materialui-daterange-picker";
import moment from 'moment';
import { useIsMount } from './useIsMount';
import { consoleToLog, trackGAEvent, getStatusColor1, EmailFollowupStatus} from '../util/AppUtil';
import { getEmailFollowupReportApi } from '../services/reportService';
import Chart from 'react-apexcharts';
import {BarChartFollowupData} from "../data/ChartsData.json";
import RightToLeftSideDrawer from './RightToLeftSideDrawer';
import ChecklistCompaniesFilter from './filters/ChecklistCompaniesFilter';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import { disableEmailFollowupApi } from '../services/authService';
import EmailFollowupDetailsModal from './modals/EmailFollowupDetailsModal';


const useStyles = makeStyles((theme) => ({
    mainContent : {
        width: (showDrawer) => {
           return showDrawer ? "83%" : "100%"; 
        },
        backgroundColor: theme.palette.primary.light, 
        transition: ".5s", 
        flexGrow: 1,
        //overflow:'hidden',
        overflow:'auto',
        overflowX:'hidden'
        },
    
    container: {
        marginTop: "8px"
    },
    graphMain: {
        padding: "1rem !important",
        fontSize: "11px !important",
        marginTop: "1rem !important",
        boxShadow: "0 .5rem 1rem rgba(0,0,0,.15) !important",
        width: "32rem !important",
        height: "21rem !important",
        marginLeft: "40px !important",
        marginBottom: "50px"
    },

    icons : {
        color: "black",
        fontSize: "18px",
        minWidth:'30px',
        marginLeft: "8px",
        marginTop:'-3px'
    } ,
    notAttachmentsImage: {
        height: "13rem",
        width: "15rem",
        marginTop: "3rem",
    },
    notAttachmentsText: {
        fontSize: "20px",
        fontWeight: "600",
    },
    notAttachmentsButton: {
        fontSize: "15px",
        width: "19rem",
        marginTop: "20px",
    },
    listContainer: {
        '& .stopFollowupButton': {
            visibility:'hidden',
            width:'140px',
            background:'white',
            border:'1px solid #ccc',
            borderRadius:'5px',
            fontSize:'13px',
            dispatch:'flex',
            alignItems:'center',
            outline:'none',
        },
        '&:hover .stopFollowupButton': {
            visibility:'visible',
           
        }

       
    },
    stopFollowupButton: {
        width:'140px',
        background:'white',
        border:'1px solid #ccc',
        borderRadius:'5px',
        fontSize:'13px',
        padding:'1px 0px',
        dispatch:'flex',
        alignItems:'center',
        visibility:'hidden',
        '&:hover': {
            visibility:'visible'
        },
      
    },
    moreActionsIcons: {
        marginRight: '4px', 
        color: '#555'
    },
    followupHeadingStyle: {
        fontWeight: 600,
    }
    })
    
)

const FollowupReports = (props) => {
    const {showDrawer} = props;
    const classes = useStyles(showDrawer);
    const { enqueueSnackbar } = useSnackbar();
    const isMount = useIsMount();


    const [anchorEl, setAnchorEl] = useState(false);
    const [startDateFilter, setStartDateFilter] = useState("");
    const [endDateFilter, setEndDateFilter] = useState("");
    const [clearFilter, setClearFilter] = useState(false);
    const [selectedCompanies, setSelectedCompanies] = useState([]);
    const [emailFollowupData, setEmailFollowupData] = useState([]);
    const [page, setPage] = useState(1);
    const [nextPage, setNextPage] = useState(false);
    const [isMoreLoading, setIsMoreLoading] = useState(false);
    const [followupStatus, setFollowupStatus] = useState(['ACTIVE']);
    const [openFollowupDetailsDrawer, setOpenFollowupDetailsDrawer] = useState(false);
    const [followupObj, setFollowUpObj] = useState(undefined);
    
    const [openDrawer, setOpenDrawer] = useState(false);
    const [openFilters, setOpenFilters] = useState(false);
    const [followUpsReportsLoading,setFollowUpsReportsLoading] = useState(false);

    const statusNameList = ["Active", "Inactive", "Max Limit Reached"];
    const statusValueList = ["ACTIVE", "INACTIVE", "MAX_LIMIT_REACHED"];
    const statusClassNames = ["active_status", "in-active_status", "max-limit-reached_status"];
    const statusClassNames1 = ["active_background", "in-active_background", "max-limit-reached_background"];

    const statusClassName = (index) => {
        const STATUS_CLASS = `status__filterList ${statusClassNames1[index]}`;
        return followupStatus.includes(statusValueList[index]) ? `${STATUS_CLASS} active` : STATUS_CLASS;
    }

    useEffect(() => {
        document.title = `Followup Reports - ${props.fullName}`;
        getFollowupReport();
    }, []);

    const open = Boolean(anchorEl);

    const onUpdateClearFilterValue = () => {
        setClearFilter(false);
    }

    const onSelectedCompanies = (sts) => {
        setSelectedCompanies(sts);
    }

    const handleFollowupDetailsDrawerOpen = (followup) => {
        setFollowUpObj(followup);
        setOpenFollowupDetailsDrawer(true);
    }

    const handleFollowupDetailsDrawerClose = () => {
        setOpenFollowupDetailsDrawer(false);
        setFollowUpObj(undefined);
    }

    //api
    const getFollowupReport = () => {

        const organization_id = props.selectedOrganization.organization.id;
        const status =  followupStatus && followupStatus.length > 0 ? followupStatus.map((status) => status).join(',') : undefined;

        const ids = selectedCompanies && selectedCompanies.length > 0 ? selectedCompanies.map((l) => l.id).join(',') : undefined;
        if(!isMoreLoading) setFollowUpsReportsLoading(true);

        getEmailFollowupReportApi(organization_id, page, status, ids)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response getEmailFollowupReportApi: ", res);
                setFollowUpsReportsLoading(false);

                if(isMoreLoading) {
                    const newItems = emailFollowupData.concat(res.email_followups);
                    setEmailFollowupData(newItems);
                    setNextPage(res.next_page);
                    setPage(page + 1);
                    setIsMoreLoading(!isMoreLoading);
                } else {
                    trackGAEvent(props.selectedOrganization.organization.name, 'Filter Followup Report', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
                    setNextPage(res.next_page)
                    if (res.next_page) setPage(page + 1);
                    setEmailFollowupData(res.email_followups);
                }

            })
            .catch((e) => {
                consoleToLog("getEmailFollowupReportApi error", e);
                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant:'error'
                    });
                    return false;
                }
                setFollowUpsReportsLoading(false);
            });
    }

    useEffect(() => {
        if(!isMount) {
            pageReset();
        }
    }, [followupStatus, selectedCompanies]);

    useEffect(() => {
        if(!isMount) {
            if (page === 1) {
                getFollowupReport();
            }
        }    
    }, [page]);

    useEffect(() => {
        //consoleToLog("hi", isMoreLoading);
        if(!isMount) {
            if (isMoreLoading) {
                getFollowupReport();
            }
        }
    }, [isMoreLoading]);

    const pageReset = () => {
        //on page change useeffect will be called and getEmail api called
        setPage(1);
        setNextPage(false);
        if(page===1) getFollowupReport();
    }

    const toggleDrawer = () => {
        setOpenDrawer(open => !open)
        setAnchorEl(null);
    }

    const setDrawerOpen = () => {
        setOpenDrawer(false);
        setOpenFilters(false);
    }

const ReportsNotFound = () => {
    return (
        <div>
        {/* Not Found*/}
        <Grid container direction="row">
            <Grid
            item
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            sm={12}
            >
            <img
                alt="No Reports Found"
                src="/images/no_attachment_found.svg"
                className={classes.notAttachmentsImage}
            />
            </Grid>
            <Grid
            item
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            >
            <Typography className={classes.notAttachmentsText}>
                No Reports Found, You need to select all filters
            </Typography>
            </Grid>
            <Grid
            item
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            >
            <Button
                variant="contained"
                color="primary"
                //endIcon={<PublishIcon />}
                size="large"
                className={classes.notAttachmentsButton}
                onClick={()=>{
                toggleDrawer();
                setOpenFilters(!openFilters);
                }}
            >
                Add Filters
            </Button>
            </Grid>
        </Grid>
        {/* Not Found*/}
        </div>
    );
    };

    const StyledTableCell = withStyles((theme) => ({
        head: {
            backgroundColor: "#f5f5f5",
            color: theme.palette.common.black,
        },
        body: {
            fontSize: 14,
        },
        }))(TableCell);
    
        const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(even)': {
            backgroundColor: theme.palette.action.hover,
            },
        },
    }))(TableRow);

    const capitalize = (s) => {
        if (typeof s !== 'string') return '';
        return s.charAt(0).toUpperCase() + s.slice(1).toLocaleLowerCase();
    }

    const onStopFollowupClick = (e,followup) => {
        e.stopPropagation();
        const organization_id = props.selectedOrganization.organization.id;
        const followup_id = followup.id;

        disableEmailFollowupApi(organization_id, followup_id)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response disableEmailFollowupApi', res);
                enqueueSnackbar(res.message, {variant:'success'});
                const obj = {
                    ...followup,
                    status: EmailFollowupStatus.INACTIVE
                }
                const updatedArray = emailFollowupData.map((followup) => {
                    if(followup.id === obj.id) {
                        followup = obj
                    }
                    return followup;
                });
                setEmailFollowupData(updatedArray);
            })
            .catch((e) => {
                consoleToLog('Error disableEmailFollowupApi', e.response);
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant:'error'
                    })
                    return;
                }
            })
    }

    const getTableData = () => {
        return(
        <Grid container>
            <TableContainer component={Paper}>
                <Table className={classes.table}
                    aria-label="customized table">
                    <TableHead>
                        <TableRow style={{borderTop: "1px solid rgba(224, 224, 224, 1"}}>
                        <StyledTableCell style={{width: "200px"}}>
                            <Typography variant='subtitle1' className={classes.followupHeadingStyle}>
                                Emails
                            </Typography>
                        </StyledTableCell>
                        <StyledTableCell align="left" style={{width: "250px"}}>
                            <Typography variant='subtitle1' className={classes.followupHeadingStyle}>
                                Email Subject
                            </Typography>
                        </StyledTableCell>
                        <StyledTableCell align="left">
                            <Typography variant='subtitle1' className={classes.followupHeadingStyle}> 
                                No. of emails sent
                            </Typography>
                        </StyledTableCell>
                        <StyledTableCell align="left">
                            <Typography variant='subtitle1' className={classes.followupHeadingStyle}>
                                Max count
                            </Typography>
                        </StyledTableCell>
                        <StyledTableCell align="left">
                            <Typography variant='subtitle1' className={classes.followupHeadingStyle}>
                                Last email sent
                            </Typography>
                        </StyledTableCell>
                        <StyledTableCell align="left">
                            <Typography variant='subtitle1' className={classes.followupHeadingStyle}>
                                Followup Status
                            </Typography>
                        </StyledTableCell>
                        <StyledTableCell align="left"></StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {emailFollowupData.map((row) => (
                        <StyledTableRow hover key={row.name} 
                            onClick={() => handleFollowupDetailsDrawerOpen(row)} 
                            className={classes.listContainer}
                            style={{cursor:'pointer'}}>
                            <StyledTableCell align="left" style={{width: "200px"}}>{row.to_emails.map((email) => email).join(', ')}</StyledTableCell>
                            <StyledTableCell align="left" style={{width: "250px"}}>{row.subject}</StyledTableCell>
                            <StyledTableCell align="left">
                                <Typography variant='body2' style={{marginLeft:'48px'}}>
                                    {row.count}
                                </Typography>
                            </StyledTableCell>
                            <StyledTableCell align="left">
                                <Typography variant='body2' style={{marginLeft:'24px'}}>
                                    {row.max_count}
                                </Typography>
                                </StyledTableCell>
                            <StyledTableCell align="left">
                                <Typography variant='body2'>
                                    {row.last_email_sent ? moment(row.last_email_sent).fromNow() : ''}
                                </Typography>
                            </StyledTableCell>
                            <StyledTableCell align="left">
                                <Typography className={row.status === EmailFollowupStatus.MAX_LIMIT_REACHED ? 'statusStyle2' : 'statusStyle1'} variant='body2' 
                                    style={{
                                        textAlign:'center',
                                        color: `${getStatusColor1(row.status)}`,
                                        border: `1px solid ${getStatusColor1(row.status)}`,
                                    }}>
                                    {capitalize(row.status)}
                                </Typography>
                            </StyledTableCell>
                            <StyledTableCell align="left">
                                {row.status === EmailFollowupStatus.ACTIVE &&
                                <Button item className='stopFollowupButton'
                                    style={{padding:'1px 0px'}} onClick={(e) => onStopFollowupClick(e,row)}
                                > 
                                        <HighlightOffOutlinedIcon 
                                        className={classes.moreActionsIcons}
                                        style={{fontSize:'15px', verticalAlign:'baseline', color:'#dc143c'}}   
                                        />
                                    <span> Stop Followup </span>
                                    
                                </Button>}
                            </StyledTableCell>
                        </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
        )
    }

    const loadMoreLogic = () => {
        return (
            <Grid item container>
                <Grid item lg={12}>
                {nextPage && !isMoreLoading &&
                    <div style={{textAlign: "center", margin:'16px 0px 32px 0px'}}>
                        <Button type="button"
                            color="secondary"
                            variant="contained"
                            onClick={() => {
                                setIsMoreLoading(!isMoreLoading);
                            }}>Load More
                        </Button>
                    </div>}

                { isMoreLoading &&
                    <CircularProgress size={35} style={{position:'relative', left:'50%', right:'50%', margin:'16px 0px'}}/>
                }
                </Grid>
            </Grid>
        )
    }

    const isSelectedStatus = (selectedStatus) => {
        const tempArr = followupStatus.filter(
            (status) => status === selectedStatus
        );
        return tempArr.length > 0 ? true : false;
    };

    const onStatusClick = (selectedValue) => {
        if(isSelectedStatus(selectedValue)) {
            const selectedStatus = followupStatus.filter((status) => status !== selectedValue);
            setFollowupStatus(selectedStatus);
        } else {
            const array = followupStatus.concat(selectedValue); 
            setFollowupStatus(array);
        }
    }

    return (
        <div className={classes.mainContent} 
            style={{height: props.height}}>
            <Container className={classes.container} maxWidth="xl">
                <div style={{display: "flex"}}>
                    <Typography style={{fontSize:'20px', fontWeight:'600', marginBottom: "10px"}} color="primary">
                        Followup Report
                    </Typography>
                    <Tooltip title="Filters" arrow>
                        <Button aria-label="delete"  
                            className={classes.icons}
                            //style={{marginLeft: "auto"}}
                            onClick={() => {
                                toggleDrawer();
                                setOpenFilters(!openFilters);
                            }}
                            >
                            <i className={(selectedCompanies.length > 0 || followupStatus.length > 1 || followupStatus.length === 0) ? `flaticon-filter active` : `flaticon-filter`}></i>
                            {   (selectedCompanies.length > 0 || followupStatus.length > 1 || followupStatus.length === 0) ?
                                <div className='filter__dot'></div> : <div></div>
                            }
                        </Button>
                    </Tooltip>
                </div>
                { followUpsReportsLoading ?
                    <Grid item container>
                        <Grid item lg={12}>
                            <CircularProgress size={35} style={{position:'relative', left:'50%', right:'50%', margin:'16px 0px'}}/>
                        </Grid>
                    </Grid>
                    :
                    (emailFollowupData && emailFollowupData.length > 0) ? 
                        <div style={{paddingBottom: "35px"}}>
                            {getTableData()}
                            {loadMoreLogic()}   
                        </div>
                    : <ReportsNotFound /> 
                    
                }
            </Container>    
            <RightToLeftSideDrawer openDrawer={openDrawer} 
                closeDrawer={setDrawerOpen}
                title="Report Filters"
                createChecklist={false}
            >
            <div style={{padding:'0px 16px 16px 16px'}}>
                <Grid item md={12}>
                    <Typography
                        variant="body1"
                        style={{lineHeight:'42px', color: '#888888'}}
                    >
                        Status
                    </Typography>
                </Grid>    
                <Grid item md={12}>
                    <Grid item container alignItems='center'>
                        {statusNameList.map((status, index) => {
                                return <Grid item
                                            className={statusClassName(index)}
                                            style={{display:'flex', alignItems:'center'}}
                                            key={status}
                                            onClick={() => onStatusClick(statusValueList[index])}
                                        >
                                    <span className={`circle ${statusClassNames[index]}`}></span> 
                                    <Typography  style={{fontSize:'12px'}}>{status}</Typography>
                                </Grid>
                            })
                        }
                    </Grid>
                </Grid>
                <Grid item md={12} style={{marginTop:'4px'}}>
                    <Grid item lg={8}>
                        <ChecklistCompaniesFilter isFromReport = {true}
                            clearFilter={clearFilter}
                            onUpdateClearFilterValue={onUpdateClearFilterValue} 
                            onSelectedCompanies={onSelectedCompanies}
                            selectedCompanies={selectedCompanies}
                            />
                    </Grid>
                </Grid>
                <Grid item style={{marginTop: "24px"}}>
                    <Button variant="contained" 
                        color="primary" 
                        style={{fontWeight:"300 !important"}}
                        className="modal__button"
                        onClick={()=>{
                            setClearFilter(true);
                            setFollowupStatus(['ACTIVE'])
                            setSelectedCompanies([]);
                            //pageReset();
                        }}>
                        Clear Filters
                    </Button>
                </Grid>
            </div>
        </RightToLeftSideDrawer>

        {<EmailFollowupDetailsModal openFollowupDetailsDrawer={openFollowupDetailsDrawer}
            handleFollowupDetailsDrawerClose={handleFollowupDetailsDrawerClose}
            followupObj={followupObj}
            setFollowUpObj={setFollowUpObj}
            emailFollowupData={emailFollowupData}
            setEmailFollowupData={setEmailFollowupData}
            />}
    </div>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    members: state.organizationInfo.members
});

const mapDispatchToProps = (dispatch) => ({
    addOrgMember: (member) => dispatch(addOrgMember(member))
})

export default connect(mapStateToProps, mapDispatchToProps)(FollowupReports);