import React, {useState, createRef, useEffect} from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button, Typography, Fab, Divider, CircularProgress } from "@material-ui/core";
import { MentionsInput, Mention } from "react-mentions";
import moment from "moment";
import {connect} from 'react-redux';
import { useIsMount } from '../useIsMount';
import {addCommentToEmailApi} from '../../services/genieService';
import { consoleToLog } from '../../util/AppUtil';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';

const useStyles = makeStyles((theme) => ({
    menuListItems: {
        paddingRight: "26px",
    },
    commentSection: {
        padding: "5px 5px",
        fontSize: "12px",
        marginLeft: "10px",
        marginTop: "10px",
        marginBottom: "10px",
        boxShadow: "0px 0px 6px 0px #140d0d26",
        minWidth: "50rem",
        maxWidth: "57rem",
        width: "auto",
    },
    checklistDetailHeader: {
        fontWeight: 500,
        fontSize: "20px",
    },
    checklistDetailStatus: {
        borderRadius: "5px",
        padding: "2px 10px 2px 10px",
        color: "#656565",
        border: `1px solid #656565`,
        maxWidth: "12rem",
        width: "auto",
    },
    checklistDetailStatusIcon: {
        marginLeft: "2rem",
    },
    checklistDetailContent: {
        fontSize: "15px",
        padding: "18px 0px",
        color: "black",
        lineHeight: "25px",
    },
    checklistDetailBtn1: {
        borderRadius: "5px",
        padding: "2px 10px 2px 10px",
        color: "#656565",
        border: `1px solid #656565`,
    },
    checklistDetailBtn2: {
        borderRadius: "5px",
        padding: "2px 10px 2px 10px",
        color: "#dd584d",
        border: `1px solid #dd584d`,
    },
    checklistDetailAct: {
        fontWeight: "500",
        fontSize: "20px",
        marginTop: "10px",
    // marginBottom: "10px",
    },
    commentIconContainer: {
        backgroundColor: "#555555",
        minHeight: "30px",
        height: "30px",
        width: "30px",
        boxShadow: "none",
    },
    commentIcon: {
        height: "15px",
        width: "15px",
        color: "white",
        transform: "scaleX(-1)",
        fontSize: "1rem",
    },
    commentedBy: {
        fontSize: "14px",
        marginLeft: "10px",
        color: "black",
    },
    commentedOn: {
        fontSize: "13.5px",
        color: "#5e6265",
        margin: "0px 5px",
    },
    commentTime: {
        fontSize: "14px",
        marginLeft: "10px",
        color: "#000",
    },
  }));

const EmailDetailsNotesComponent = (props) => {
    const classes = useStyles();
    const isMount = useIsMount();

    const [notes,setNotes] = useState('');
    const [mentions, setMentions] = useState();
    const [selectedNoteForEditing, setSelectedNoteForEditing] = useState(undefined);
    const [text, setText] = useState("");
    const [loading, setLoading] = useState(false);

    const notesList = props.selectedEmailItem?.comments
    const currentAuthUser = props.selectedOrganization.user;

    const mentionRef = createRef();

    let usersData = props.members.map((member) => ({
        id: member.user.id,
        display: `${member.user.firstname} ${member.user.lastname}`,
    }));

    const NotesItem = () =>{
    
        const getReadableNotesText = (note) => {
            var readableNoteText = note.text;
            let  numberPattern = /\d+/g;
            let num = readableNoteText.match(numberPattern);
            num?.join();
            let user_id = Number(num)
            
            readableNoteText = readableNoteText.replace(/\n\r?/g, "<br />");//add <br>
            readableNoteText = readableNoteText.split('@[').join(`<b style=color:${user_id === currentAuthUser ? '#0B4C8C' :'black'};background:#E8F5FB;padding:4px;border-radius:4px>`);
            readableNoteText = readableNoteText.split(']').join("</b>");
            readableNoteText = readableNoteText.split(/\(.+?\)/).join('');
            return readableNoteText;
            
        }
    
        return (
            <div className="notesMain">
                {notesList && notesList.map((note)=>{
                    return (
                        <Grid item container key={note.id}
                            style={{ marginTop: "0px", padding: "10px 0px" }}
                            className="notesParent">

                            <Grid item>
                                <Fab className={classes.commentIconContainer}>
                                    <ChatBubbleOutlineIcon className={classes.commentIcon} />
                                </Fab>
                                <Divider orientation="vertical" className="horizontalDivider" />
                            </Grid>
                    
                            <Grid item sm={11}>
                                <Grid item container direction="column">
                                    <Grid item className="noteFullName">
                                        {note.user!==null ? note.user.firstname + " " + note.user.lastname : note.name}
                                    </Grid>
                                
                                    <Grid item className="noteTime">
                                        {moment(note.createdAt).format('Do - MMMM, h:mm a')}
                                    </Grid>
                                </Grid>
                                <Grid item container direction="row" className="notesContainer">
                                    <Grid item sm={11}
                                        className="noteText"
                                        dangerouslySetInnerHTML={{ __html: getReadableNotesText(note) }}>
                                    </Grid>
                                    {(note.user !== null && currentAuthUser === note.user.id) &&
                                    <Grid item sm={1} className="notesIcons">
                                        <Grid item container
                                            alignItems="center"
                                            justifyContent="flex-end"
                                        >
                                            <Grid item>
                                                <i
                                                    className="flaticon-edit"
                                                    style={{ fontSize: "18px",  cursor: "pointer"}}
                                                ></i>
                                            </Grid>
                                            <Grid item>
                                                <i
                                                    className="flaticon-delete-1"
                                                    style={{ fontSize: "18px", margin: "0 15px", cursor: "pointer"}}
                                                    title="Delete Note"
                                                ></i>
                                            </Grid>
                                        </Grid>
                                    </Grid>}
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                })}
            </div>
        );
    }

    const onSendClick = () => {
        const mentioned_users = mentions.map(mentionObj => mentionObj.id);
        const email_uuid = props.selectedEmailItem?.id;
        setLoading(true);

        addCommentToEmailApi(email_uuid, text, mentioned_users)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response addCommentToEmailApi: ', res);
                setText('');
                setMentions([]);

                props.updateEmailArr(res.triage);

                setLoading(false);
            })
            .catch((e) => {
                consoleToLog('Error addCommentToEmailApi: ', e.response);
                setLoading(false);
                if(e.response.data && e.response.data.message) {
                    return;
                }
            })
    }

    return (
        <div>
            <Grid item sm={12} 
                //style={{marginTop: selectedNoteForEditing ? '2rem' : ''}}
                >
                <div className="mentionWrapper">
                    <MentionsInput  markup="@[__display__]"
                        className="mentions"
                        placeholder="Use @ to notify a team member"
                        value={text}
                        onChange={(e, newValue, newPlainTextValue, mentions) => {
                        setText(e.target.value);
                        setMentions(mentions);
                        }}
                        inputRef={props.mentionRef}
                    >
                        <Mention className="mentions__mention" data={usersData}/>
                    </MentionsInput>
                </div>
                <Grid item container 
                    alignItems='center' 
                    spacing={1}
                    style={{marginTop:'16px'}}>
                    <Grid item>
                        <Button  variant="outlined" className={classes.checklistDetailBtn1}
                            onClick={onSendClick}
                        >
                            Send 
                        {loading && <CircularProgress
                        style={{ color: "black",height:"15px",width:"15px",marginLeft:"10px"}}
                        size={10}
                        />}
                        </Button>
                    </Grid>
                    
                    <Grid item>
                        <Button variant="outlined" className={classes.checklistDetailBtn2}
                            //onClick={clearCurrentCommentData}
                        >
                            Discard
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            {notesList && notesList.length > 0 && 
            <Grid item container direction='column'>
                <Typography className={classes.checklistDetailAct}>Notes</Typography>
                {NotesItem()}
            </Grid>}
        </div>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    members: state.organizationInfo.members
});

export default connect(mapStateToProps)(EmailDetailsNotesComponent);