import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Container, Button, Typography, Tooltip, CircularProgress, Tabs, Tab, Paper} from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import {connect} from 'react-redux';
import { history } from '../routers/AppRouter';
import {getpredefinedTemplateListApi} from '../services/authService';
import { useSnackbar } from 'notistack';
import { consoleToLog, trackGAEvent } from '../util/AppUtil';
import PredefinedEmailDrafts from './PredefinedEmailDrafts';

const useStyles = makeStyles((theme) => ({
    mainContent : {
        width: (showDrawer) => {
           return showDrawer ? "83%" : "100%"; 
        },
        backgroundColor: theme.palette.primary.light, 
        transition: ".5s", 
        flexGrow: 1,
        //overflow:'hidden',
        height:'calc(100vh - 48px)',
        overflow:'auto',
        overflowX:'hidden'
        },
        
    container: {
        marginTop: "8px"
        },

    MuiButtonlabel : {
        fontSize: "12px"
    },

    buttonPlusIcon: {
        marginLeft:'8px', 
        fontSize:'1.2rem', 
        alignItems:'center'
    },

    tempContainer : {
       marginTop: '30px',
       marginBottom:'30px'
    },

    tempList : {
        background: '#f7f7f7',
        marginBottom:'8px',
        borderRadius:'4px',
        '& .onlabel__hover' : {
            visibility: 'hidden'
        },
        '&:hover .onlabel__hover' : {
            visibility: 'visible'
        },
    },
    actionIconContainer : {
        display: 'flex', 
        justifyContent: 'flex-end',
    },

    actionIcons : {
        minWidth:'20px',
        justifyContent:'flex-end !important',
        verticalAlign: 'end !important'
    },

    flatIcons : {
        fontSize:'1.2rem', 
        height:'28px'
    },
    customColumn : {
        flexBasis:'89%',
        maxWidth:'89%'
    },
    loading: {
        position:'relative',
        left:'50%',
        right:'50%',
        top:'25%'
    },
    tab: {
        flexGrow: 1,
        borderRadius:0,
        borderBottom: "1px solid #c5b9b9",
    },
    settingsContainer: {
        width:'100%',
        background:'#fff',
        borderRadius:'4px',
        padding:'0px 24px',
        marginBottom:'32px'
    },
    addButton: {
        color: "white",
        backgroundColor: "#3d3d3d",
        marginLeft: "auto",
        height: "30px",
        marginTop: "9px",
        "&:hover": {
            //you want this to be the same as the backgroundColor above
            backgroundColor: "#3d3d3d"
        },
        marginRight: "8px"
    },
    buttonPlusIcon: {
        marginLeft:'8px', 
        fontSize:'1.2rem', 
        alignItems:'center'
    },

    }
)); 

const PredefinedTemplates = (props) => {
    const {showDrawer} = props;
    const classes = useStyles(showDrawer);
    const {enqueueSnackbar} = useSnackbar();

    const [predefinedTemplateList, setPredefinedTemplateList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState(0);

    const [openDrawer, setOpenDrawer] = useState(false);

    const handleDrawerOpen = () => {
        setOpenDrawer(true);
    }

    const handleDrawerClose = () => {
        setOpenDrawer(false);
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        getPredefinedTemplateList();
    }, [])

    useEffect(() => {
        document.title = 'Predefined Templates'
    }, []);

    const getPredefinedTemplateList = () => {
        const organization_id = props.selectedOrganization.organization.id;

        setLoading(true);
        getpredefinedTemplateListApi(organization_id)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response getpredefinedTemplateListApi: ', res);
                setLoading(false);
                setPredefinedTemplateList(res.predefined_templates)
            })
            .catch((e) => {
                consoleToLog("Error getpredefinedTemplateListApi ", e);
                setLoading(false);
                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant:'error'
                    })
                    return false;
                }
            });
    }

    const ButtonInTabs = ({ className, onClick, children }) => {
        return <Button 
                className={className} 
                onClick={onClick} 
                children={children}
                />;
    };

    const showPredefinedTemplateList = () => {
        return (
            <Grid item sm={12} className={classes.settingsContainer}>
            {loading ? 
            <CircularProgress size={35} className={classes.loading}/>
                    :
            <Grid item container className={classes.tempContainer}>
                    {
                        props.auth && predefinedTemplateList && predefinedTemplateList.length > 0 ? 
                        predefinedTemplateList.map((template) => {
                            return <Grid item container className={classes.tempList}
                                        key={template.predefined_template_id}
                                        justifyContent="space-between"
                                        alignItems="center">
                                        <Grid item md={11} lg={11}
                                            className={classes.customColumn}
                                            style={{borderRadius:'4px',
                                                    padding:'5px' }}
                                        >
                                            <Typography variant="body1" 
                                                style={{padding: '0 8px'}}
                                            >
                                                {template.name}
                                            </Typography>

                                        </Grid>  

                                            <Grid item md={1} lg={1} className={classes.actionIconContainer}>
                                                <Tooltip title="Edit" arrow>
                                                    <Button className={`${classes.actionIcons} onlabel__hover`}
                                                        onClick={() =>{ 
                                                        history.push({
                                                            pathname: `/predefined-template/edit/${template.slug}`,
                                                            state: {fromPredefinedTemplate: true}
                                                        })
                                                        trackGAEvent(props.selectedOrganization.organization.name, 'Edit Predefined Template Clicked', `${props.auth.user.firstname} ${props.auth.user.lastname}`);

                                                    }}
                                                    > 
                                                            <i className={`flaticon-edit ${classes.flatIcons}`}></i>
                                                    </Button>
                                                </Tooltip>    

                                                <Tooltip title="Delete" arrow>        
                                                    <Button className={`${classes.actionIcons} onlabel__hover`}> 
                                                        <i className={`flaticon-delete-1 ${classes.flatIcons}`}></i>
                                                    </Button> 
                                                </Tooltip>
                                            </Grid> 
                                    </Grid>

                        }) : <Grid item container className={classes.tempList}>
                                <Grid item> No Templates Found </Grid>
                            </Grid>  

                    }
            </Grid>}
        </Grid>
        )
    }

    return (
        <div className={classes.mainContent} 
            style={{height: props.height}}>
            <Container className={classes.container} maxWidth="xl">
                <Grid item container alignItems="center">
                    <Grid item>
                        <Typography style={{fontSize:'20px', fontWeight:'600'}} color="primary">
                            Predefined Templates
                        </Typography>
                    </Grid>
                    {/* <Grid item style={{marginLeft:'18px'}}>
                        <Button variant="contained" color="primary" 
                            onClick={() => history.push({
                                pathname: `/predefined-template/create`,
                                state: {fromPredefinedTemplate: true}
                            })}
                            classes={{
                                label: classes.MuiButtonlabel
                            }}
                            style={{justifyContent:'center', padding:'8px'}}>
                            Add Predefined Template
                            <AddCircleOutlineIcon className={classes.buttonPlusIcon}/>
                        </Button>
                    </Grid> */}

                    <Grid item container direction="column" style={{marginTop:'3px'}}>
                        <Grid item container>
                            <Paper className={classes.tab} elevation={1} style={{marginTop:"10px"}}>
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                >
                                    <Tab label="Predefined Templates" />
                                    <Tab label="Predefined Template Drafts" />
                                    {
                                        value === 0 &&
                                        <ButtonInTabs
                                            className={classes.addButton}
                                            onClick={() => 
                                            {
                                                history.push({pathname: `/predefined-template/create`, state: {fromPredefinedTemplate: true}});
                                                trackGAEvent(props.selectedOrganization.organization.name, 'Add Predefined Template Clicked', `${props.auth.user.firstname} ${props.auth.user.lastname}`);

                                            }
                                        
                                        }
                                        >
                                            Add Predefined Template
                                            <AddCircleOutlineIcon fontSize='small' 
                                                    style={{fontSize:'15px', marginLeft:'4px'}}
                                                    className={classes.buttonPlusIcon}/>
                                        </ButtonInTabs>
                                    }

                                    {
                                        value === 1 &&
                                        <ButtonInTabs
                                            className={classes.addButton}
                                            onClick={handleDrawerOpen}
                                        >
                                            Add Predefined Template Drafts
                                            <AddCircleOutlineIcon fontSize='small' 
                                                    style={{fontSize:'15px', marginLeft:'4px'}}
                                                    className={classes.buttonPlusIcon}/>
                                        </ButtonInTabs>
                                    }
                                            
                                </Tabs>
                            </Paper>
                        </Grid>
                    </Grid>

                    <Grid item md={12} style={{backgroundColor:"white",marginBottom:"1rem"}}>
                        {value === 0 && showPredefinedTemplateList()}
                        {value === 1 && <PredefinedEmailDrafts openDrawer={openDrawer}
                                            setOpenDrawer={setOpenDrawer}
                                            handleDrawerClose={handleDrawerClose}/>}
                    </Grid>
                </Grid>

                {/* {
                    loading ? 
                    <CircularProgress size={35} className={classes.loading}/>
                    :
                    showPredefinedTemplateList()
                } */}
            </Container> 
        </div>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
});

export default connect(mapStateToProps)(PredefinedTemplates);