import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Grid, Typography, Button, IconButton, Tooltip, Dialog, TextField, CircularProgress, Popover, MenuItem} from '@material-ui/core';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import ClearIcon from '@material-ui/icons/Clear';
import {updateOrgSupportEmailApi, deleteSupportEmailApi, sendSupportEmailVerificationApi, getLoginRedirectUrlApi} from '../services/genieService';
import {connect} from 'react-redux';
import { consoleToLog, AccessLevel, shouldDisable, trackGAEvent } from '../util/AppUtil';
import { useSnackbar } from 'notistack';
import { emailValidator } from '../util/validator';
import SelectFromMemberList from './checklist/SelectFromMemberList';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import SettingsEthernetOutlinedIcon from '@material-ui/icons/SettingsEthernetOutlined';
import AddEmailDialogModal from './modals/AddEmailDialogModal';
import {setSelectedOrgEmailObj} from '../actions/selectedOrganization';
import {deleteOrgEmail, updateOrgEmail, checkTemplateRules} from '../actions/selectedOrganization';
import ForwardingInfoDrawer from './modals/ForwardingInfoDrawer';
import InfoIcon from '@material-ui/icons/Info';
import FilterNoneOutlinedIcon from '@material-ui/icons/FilterNoneOutlined';

const useStyles = makeStyles((theme) => ({
    settingsContainer: {
        width:'100%',
        background:'#fff',
        borderRadius:'4px',
    },
    heading: {
        fontSize:'20px',
        fontWeight:'600',
        padding:'0px 24px',
        marginBottom:'16px'
    },
    emailListContainer: {
        padding:'0px 24px',
        borderBottom:'1px solid rgba(0,0,0,0.1)',
        '&:last-child' : {
            border:'none',
        },
        '&:first-child' : {
            borderBottom:'1px solid rgba(0,0,0,0.1)',
        },
        '& .onEmailListHover' : {
            visibility: 'hidden'
        },
        '&:hover .onEmailListHover': {
            visibility: 'visible'
        },
        '& .more_actionButton' : {
            visibility:'hidden',
            width:'120px',
            borderRadius:'5px',
            fontSize:'14px',
            padding:'1px 0px',
            background:'white',
            border:'1px solid #ccc',
        },
        '&:hover .more_actionButton' : {
            visibility:'visible'
        },

    },
    emailTextStyle: {
        fontSize:'18px',
        fontWeight:600,
        display:'flex',
        alignItems:'center'
    },
    checkIcon: {
        color:"green",
        fontSize:'20px',
        marginLeft:'8px'
    },
    dialogTitle : {
        padding:'16px 24px', 
        display:'flex',  
        justifyContent:'start', 
        alignItems:'center',
        fontSize:'18px',
        fontWeight:'600'
    },
    MuiDialogpaper: {
        scrollbarWidth: 'none',
        overflow:'visible',
        paddingBottom:'5px',
        "&::-webkit-scrollbar": {
            display: "none"
        }   
    },
    iconStyles: {
        fontSize:'16px', 
        color:'#555', 
        marginRight:'2px', 
    },
    connectIcons: {
        width:'20px',
        marginRight:'8px'
    },
    emailForwardStyle: {
        color:'grey',
        fontSize:'12px',
        width:'auto',
        overflow:'hidden',
        whiteSpace:'nowrap',
        textOverflow:'ellipsis',
        cursor:'pointer',
        [theme.breakpoints.down(1400)] : {
            maxWidth: '500px', 
        },
        [theme.breakpoints.down(1250)] : {
            maxWidth: '400px', 
        },
        
    },
    infoButtonStyle: {
        minWidth:'10px'
    },
    infoStyleIcon: {
        color:'#007bff',
        fontSize:'21px'
    },
    copyIcon: {
        color:'#555555',
        fontSize:'18px'
    },
    customColumn1: {
        flexBasis:'62%', 
        maxWidth:'62%',
        [theme.breakpoints.down(1320)] : {
            flexBasis:'64%', 
            maxWidth:'64%',
        }
    },
    customColumn2: {
        flexBasis:'22%',
        maxWidth:'22%',
        [theme.breakpoints.down(1320)] : {
            flexBasis:'20%', 
            maxWidth:'20%',
        }
    },
    customColumn3: {
        flexBasis:'16%', 
        maxWidth:'16%',
        [theme.breakpoints.down(1320)] : {
            flexBasis:'14%', 
            maxWidth:'14%',
        }
    }
}))

const SupportEmails = (props) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();

    const [loading, setLoading] = useState(false);
    const [anchorEl, setAnchorEl] = useState(false);
    const [emailId, setEmailId] = useState(undefined);
    const [openDrawer, setOpenDrawer] = useState(false);

    const organization_id = props.selectedOrganization.organization.id;

    const { access_level } = props.selectedOrganization || {};
    const accessLevelOwner = shouldDisable(AccessLevel.getUserAccessLevelValue(access_level), AccessLevel.OWNER);

    useEffect(() => {
        props.setSelectedOrgEmailObj();
    }, []);

    const showVerifyEmailText = (email) => {
        return (
            <Tooltip title={"Verify Email"} arrow>
                <IconButton disableRipple
                    onClick={(e) => sendVerificationEmail(e, email)}
                    style={{color: 'red', padding:'0', background:"none", marginLeft:'6px', marginTop:'3px'}}>
                        <>
                            <ClearIcon fontSize="small" style={{marginRight:'2px', fontWeight:'600'}}/>
                            <Typography style={{color:"#0071FF"}}>Verify </Typography>
                        </>
                </IconButton>
            </Tooltip>
        )
    }

    const setAssignedMemberList = (assignedMemberList, support_email_id) => {
        const access_users = assignedMemberList && assignedMemberList.length > 0 ? assignedMemberList : null
        //Update Members From Checklist Api
        updateOrgSupportEmailApi(support_email_id, access_users)
            .then((response) => {
                const res = response.data;
                consoleToLog("updateOrgSupportEmailApi res", res);
                // const updatedSupportEmailsArr = props.supportEmails.map((email) => {
                //     if(email.id === res.orgEmail.id) {
                //         email = res.orgEmail
                //     }
                //     return email
                // });
                // props.setSupportEmails(updatedSupportEmailsArr)
                props.updateOrgEmail(res.orgEmail);

            })
            .catch((e) => {
                consoleToLog("updateOrgSupportEmailApi error", e.response);
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant:'error'
                    })
                    return;
                }
            });
    }

    const sendVerificationEmail = (e, email) => {

        sendSupportEmailVerificationApi(organization_id, email.email)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response sendSupportEmailVerificationApi", res);

                enqueueSnackbar(res.message, {variant:'success'});
            })
            .catch((e) => {
                consoleToLog('Error sendSupportEmailVerificationApi:', e.response);
                if(e.response.data && e.response.data.message){
                    enqueueSnackbar(e.response.data.message, {variant:'error'});
                    return;
                }
            })
    }

    const deleteSupportEmail = (e, email) => {
        const support_email_id = email.id;
        if(window.confirm('Are you sure you want to delete this email?')) {
            deleteSupportEmailApi(support_email_id) 
                .then((response) => {
                    const res = response.data;
                    consoleToLog('Response deleteSupportEmailApi ', res);
                    enqueueSnackbar(res.message, {
                        variant:'success'
                    })

                    // const updatedSupportEmailArr = props.supportEmails.filter((email) => email.id !== support_email_id);
                    // props.setSupportEmails(updatedSupportEmailArr);
                    props.deleteOrgEmail(support_email_id);
                    props.checkTemplateRules(organization_id);
                    trackGAEvent(props.selectedOrganization.organization.name, 'Deleted Support Email', `${props.auth.user.firstname} ${props.auth.user.lastname}`);

                })
                .catch((err) => {
                    consoleToLog("updateOrgSupportEmailApi error", e.response);
                    if(e.response.data && e.response.data.message) {
                        enqueueSnackbar(e.response.data.message, {
                            variant:'error'
                        })
                        return;
                    }
                });
        }
    }

    const getFilteredMemberArr = (emailObj) => {
        return emailObj.access_users && emailObj.access_users.length > 0 ? 
        props.members.filter((member) => emailObj.access_users.includes(member.user.id)) : [];
    }

    const handlePopoverOpen = (event, emailObj) => {
        setAnchorEl(event.currentTarget);
        setEmailId(emailObj.id)
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
        setEmailId(undefined)
    };

    const getLoginRedirectUrl=()=>{
        const redirect_uri = process.env.REACT_APP_GMAIL_REDIRECT_URL;
        getLoginRedirectUrlApi(redirect_uri)
        .then((response) => {
            const res = response.data;
            consoleToLog("frontend: ", res);
            window.open(res.url, "_self");
        })
        .catch((e) => {
            consoleToLog("error", e);
        });
    }

    const loginWithoutLook = () => {
        window.open(`https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${process.env.REACT_APP_OUTLOOK_CLIENT_ID}&scope=offline_access%20https://graph.microsoft.com/mail.send%20https://graph.microsoft.com/User.Read%20https://graph.microsoft.com/Mail.Read%20https://graph.microsoft.com/Mail.ReadWrite%20https://graph.microsoft.com/Mail.ReadBasic&response_type=code&redirect_uri=${process.env.REACT_APP_OUTLOOK_REDIRECT_URL}`, '_self');
    }



    const onConnectEmailClick = (e, email, emailType) => {
        const emailObj = {
            support: email.support,
            access_users: email.access_users,
            organization_email_id: email.id,
            email_type: emailType,
            email: email.email
        }
        props.setSelectedOrgEmailObj(emailObj);

        if(emailType === 'gmail') {
            trackGAEvent(props.selectedOrganization.organization.name, 'Connect Gmail Clicked', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
            getLoginRedirectUrl();
        } else {
            trackGAEvent(props.selectedOrganization.organization.name, 'Connect Outlook Clicked', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
            loginWithoutLook();
        }
        handlePopoverClose();
    } 

    const showTemplatePopover = (email) => {
        return <Popover
                    id="connect-option-popover"
                    keepMounted
                    open={Boolean(anchorEl) && emailId === email.id}
                    anchorEl={anchorEl}
                    onClose={handlePopoverClose}
                    >
                    <MenuItem onClick={(e) => onConnectEmailClick(e, email, 'gmail')}>
                        <img src='images/gmail_icon.png' className={classes.connectIcons}/>
                        Gmail
                    </MenuItem>
                    <MenuItem onClick={(e) => onConnectEmailClick(e, email, 'outlook')}>
                        <img src='images/outlook_icon.png' className={classes.connectIcons} />
                        Outlook
                    </MenuItem>
                </Popover>
    }

    const handleDrawerOpen = () => {
        setOpenDrawer(true);
    }

    const handleDrawerClose = () => {
        setOpenDrawer(false);
    }

    return (
        <Grid item >
            <Grid item sm={12} className={classes.settingsContainer}>
                {loading ?
                <CircularProgress size={28} style={{position:'relative', left:'50%', right:'50%', margin:'16px 0px'}}/>
                :
                <Grid item>
                    {
                        props.supportEmails && props.supportEmails.length > 0 ?
                        props.supportEmails.map((email) => {
                            return email.support &&
                                <Grid item container
                                    alignItems='center'
                                    key={email.id}
                                    className={classes.emailListContainer}
                                    style={{height: '70px'}}
                                    justifyContent='space-between'>
                                        <Grid item sm={7} className={classes.customColumn1}>
                                            <Typography className={classes.emailTextStyle}>
                                                {email.email}
                                                {email.verified ? 
                                                    <CheckCircleRoundedIcon className={classes.checkIcon}/>
                                                :
                                                showVerifyEmailText(email)  }
                                            </Typography>
                                            <Grid item md={12}>
                                                <Grid item container alignItems='center'>
                                                    <Grid item>
                                                        <Tooltip title={`Start receiving email by adding forwarding address to ${email.alias_email}@${process.env.REACT_APP_STG_EPROCESSIFY_MAIL}`} arrow>
                                                        <Typography variant='subtitle1' className={classes.emailForwardStyle}>
                                                            {`Start receiving email by adding forwarding address to ${email.alias_email}@${process.env.REACT_APP_STG_EPROCESSIFY_MAIL}`}
                                                        </Typography>
                                                        </Tooltip>
                                                    </Grid>

                                                    <Grid item>
                                                        <Tooltip title='Copy Alias Email' arrow>
                                                            <Button style={{marginTop:'-5px', marginLeft:'8px'}} 
                                                                className={classes.infoButtonStyle}
                                                                onClick={() => {
                                                                        navigator.clipboard.writeText(`${email.alias_email}@${process.env.REACT_APP_STG_EPROCESSIFY_MAIL}`);
                                                                        trackGAEvent(props.selectedOrganization.organization.name, 'Copied Support email', `${props.auth.user.firstname} ${props.auth.user.lastname}`);

                                                                        enqueueSnackbar('Alias email copied successfully!!!', {
                                                                            variant:'success',
                                                                            anchorOrigin: {
                                                                                vertical:'top',
                                                                                horizontal:'center'
                                                                            }
                                                                        })
                                                                    }
                                                                }>
                                                                <FilterNoneOutlinedIcon className={classes.copyIcon}/>
                                                            </Button>
                                                        </Tooltip>
                                                    </Grid>

                                                    <Grid item>
                                                        <Tooltip title='Info' arrow>
                                                            <Button style={{marginTop:'-5px'}} 
                                                                className={classes.infoButtonStyle}
                                                                onClick={handleDrawerOpen}>
                                                                <InfoIcon className={classes.infoStyleIcon}/>
                                                            </Button>
                                                        </Tooltip>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item sm={3} className={classes.customColumn2}>
                                            <Typography
                                                variant="body1"
                                                style={{marginTop:'-8px', marginBottom:'4px', marginLeft:'2px', color:'#202020'}}
                                                className="onEmailListHover"
                                            >
                                                Accessors
                                            </Typography>
                                            <SelectFromMemberList assignees={getFilteredMemberArr(email)} 
                                                setAssignedMemberList={(selectedAssignees) =>
                                                    setAssignedMemberList(selectedAssignees, email.id)
                                                }
                                                fromSupportEmailComponent={true}
                                                isShowOnly={true}
                                            />
                                        </Grid>

                                        <Grid item sm={2} className={classes.customColumn3}>
                                        <div style={{display:'flex', alignItems:'center'}}>
                                            { email.verified && !email.connected &&
                                            <Button className="more_actionButton" 
                                                    onClick={(e)=> handlePopoverOpen(e, email)}
                                                    style={{paddingLeft:'4px', paddingRight:'4px'}}
                                                    >
                                                    <SettingsEthernetOutlinedIcon 
                                                        style={{marginRight:'4px', marginTop:'-2px'}}
                                                        className={classes.iconStyles}
                                                        fontSize='small'/>
                                                    <span> Connect </span>
                                            </Button>}
                                            {showTemplatePopover(email)}

                                            <Button className="more_actionButton"
                                                    onClick={(e) => deleteSupportEmail(e, email)}
                                                    > 
                                                <DeleteOutlinedIcon 
                                                    style={{marginTop:'-2.8px'}}
                                                    className={classes.iconStyles}
                                                    fontSize='small'/>
                                                <span> Delete </span>
                                                
                                            </Button>
                                            
                                        </div>
                                        </Grid>
                                    </Grid>
                        }) : 
                        <Grid item container justifyContent='center'>
                            <Typography  variant='body' 
                                style={{padding:'16px', fontSize:'16px'}}>
                                No Support Email Found 
                            </Typography>
                        </Grid>
                    }

                </Grid>}
            </Grid>
            {props.openDialog1 && 
                <AddEmailDialogModal openDialog={props.openDialog1}
                    handleDialogClose={props.handleDialogClose}
                    supportEmails={props.supportEmails}
                    setSupportEmails={props.setSupportEmails}
                    fromSupportEmail={true}
                    />}

            <ForwardingInfoDrawer openDrawer={openDrawer} 
                handleDrawerClose={handleDrawerClose}/>
        </Grid>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    members: state.organizationInfo.members
});

const mapDispatchToProps = (dispatch) => ({
    setSelectedOrgEmailObj: (emailObj) => dispatch(setSelectedOrgEmailObj(emailObj)),
    deleteOrgEmail: (email_id) => dispatch(deleteOrgEmail(email_id)),
    updateOrgEmail: (email) => dispatch(updateOrgEmail(email)),
    checkTemplateRules: (organization_id) => dispatch(checkTemplateRules(organization_id))
})

export default connect(mapStateToProps, mapDispatchToProps)(SupportEmails);