import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Typography, Button, CircularProgress, Avatar, Tooltip, TextField, InputAdornment, ClickAwayListener} from '@material-ui/core';
import {connect} from 'react-redux';
import { consoleToLog,trackGAEvent ,shouldDisable,AccessLevel} from '../../util/AppUtil';
import { useIsMount } from '../useIsMount';
import { useSnackbar } from 'notistack';
import { getContactListApi } from '../../services/clientService';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import ClientDetailsModal from '../modals/ClientDetailsModal';
import { deleteContactApi } from '../../services/clientService';
import ContactDrawer from './ContactDrawer';
import {fullTextSearchApi} from "../../services/clientService";
import ClientListModal from '../modals/ClientListModal';

const useStyles = makeStyles((theme) => ({
    loading: {
        position:'relative',
        left:'50%',
        right:'50%',
        marginTop:'1%'
    },
    headings: {
        fontWeight:'500',
        color:'#202020',
        [theme.breakpoints.down(1400)]: {
            fontSize:'14px'
        },
    },
    itemTitleList: {
        background:'#f9fafb',
        borderBottom: '1px solid #edf2f7',
    },
    itemListContainer : {
        background:'white', 
        minWidth:"100%", 
        padding:'10px 16px', 
        color:'#202020',
        position:'relative',
        borderBottom: '1px solid #edf2f7',
        justifyContent:'flex-start',
        cursor:'pointer',
        '& .action__icon' : {
            visibility:'hidden',
        },
        '&:hover':{
          backgroundColor:'#f7fafc',
          '& .action__icon': {
            visibility:'visible',
          }
        },
        '& .more_actionButton1' : {
            visibility:'hidden',
            width:'100px !important',
            background:'#fff',
            border:'1px solid #ccc',
            borderRadius:'5px',
            padding:'1px 0px',
            margin:'6px 0px',
            fontSize:'13px',
        },
        '&:hover .more_actionButton1' : {
            visibility:'visible'
        },
    },
    avatarStyle: {
        width:"38px !important",
        height:"38px !important",
        fontSize:'16px !important',
        fontWeight:'600 !important',
        background:'#fff',
        color:'#202020',
        border:'1px solid #4a5568'
    },
   
   
    actionsIcons: {
        color: '#b5bbc2',
        fontSize: '18px',
    },
    avatarStyle1: {
        width:"28px !important",
        height:"28px !important",
        fontSize:'13px !important',
        background:'#fff',
        color:'#202020',
        border:'1px solid #4a5568'
    },
    flatIcons : {
        fontSize:'15px', 
        marginRight:'6px',
        display:'flex',
        alignItems:'center'
    },
    addContactBtn: {
        fontSize: "15px",
        width: "10rem",
       
      },
    
    actionsIcons: {
        color: '#b5bbc2',
        fontSize: '18px',
        marginRight:'4px',
        marginTop:'1px'
    },
    actionIconContainer : {
        marginRight:'auto',
        display:'flex',
        flexFlow:'row',
        flexGrow:1,
        marginLeft:'0px',
        [theme.breakpoints.down(1500)] :{
            marginLeft:'-16px',
        },
        [theme.breakpoints.down(1450)] :{
            marginLeft:'-32px',
        },
        [theme.breakpoints.down(1400)] :{
            marginLeft:'-48px',
        },
        [theme.breakpoints.down(1320)] :{
            marginLeft:'-56px',
        },
        [theme.breakpoints.down(1290)] :{
            marginLeft:'-68px',
        },
    },
    nameEllipse : {
        maxWidth:'170px',
        overflow:'hidden',
        whiteSpace:'nowrap',
        textOverflow:'ellipsis',
        [theme.breakpoints.down(1400)] : {
            maxWidth:'140px',
        },
        [theme.breakpoints.down(1300)] : {
            maxWidth:'100px',
        },
    },
    emailEllipse : {
        maxWidth:'200px',
        overflow:'hidden',
        whiteSpace:'nowrap',
        textOverflow:'ellipsis',
        [theme.breakpoints.down(1400)] : {
            maxWidth:'170px',
        },
        [theme.breakpoints.down(1300)] : {
            maxWidth:'130px',
        },
    },
    customColumn1 : {
        flexBasis: '21%',
        maxWidth: '21%'
    },
    customColumn2 : {
        flexBasis: '11%',
        maxWidth: '11%'
    },
    customColumn3 : {
        flexBasis: '420px',
        maxWidth: '420px',
        [theme.breakpoints.down(1400)] : {
            maxWidth:'370px',
        },
        [theme.breakpoints.down(1340)] : {
            maxWidth:'320px',
        }
    },
    clientNameStyle: {
        borderRadius: '4px !important',
        backgroundColor: '#f9f9f9 !important',
        border: '1px solid #ececec !important',
        maxWidth:'170px',
        overflow:'hidden',
        whiteSpace:'nowrap',
        textOverflow:'ellipsis',
        padding:'2px 8px'
    }
}))

const ContactComponent = (props) => {
    const classes = useStyles();
    const isMount = useIsMount();
    const [isActive, setIsActive] = useState(false);
    const [contactList, setContactList] = useState([]);
    const [contactId, setContactId] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [showContactDetails, setShowContactDetails] = useState();
    const [openContactDrawer,setOpenContactDrawer]=useState(false);
    const [contactObj, setContactObj] = useState({});
    const {enqueueSnackbar} = useSnackbar();
    const [loading, setLoading] = useState(false);
    const [isMoreLoading, setIsMoreLoading] = useState(false);
    const [searchText, setSearchText] = useState('');

    const closeMoreOptionsMenu = () => {
        setIsActive(false);
        setContactId('');
    }

    const onEditContactClick = (e, contact) => {
        e.stopPropagation();
        setContactObj(contact);
        props.handleContactDrawerOpen();
    }

    useEffect(() => {
        if(props.fromClientDetails)
        setContactList(props.companyObj?.contacts)
        else
        fetchContactList();
    }, [props.companyObj]);

    useEffect(() => {
        if(!isMount) {
            if(props.fromClientDetails)
            setContactList(props.companyObj?.contacts);
        }
    }, [props.companyObj]);

    const fetchContactList = () => {
        const organization_id = props.selectedOrganization.organization.id;

        if(!isMoreLoading)  setLoading(true);

        getContactListApi(organization_id)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response getContactApi: ', res);
                setLoading(false);
        
                setContactList(res.contacts);
                    
            })
            .catch((e) => {
                setLoading(false);
                consoleToLog("Error getContactListApi: ", e.response);
                if(e.response.data && e.response.data.message) {
                    return;
                }
            })
    }

    const callDeleteContactApiFromClientDetails = (id, clientObj) => {
        const client_id = clientObj.id;
        const deleted_invoice_account_id = clientObj?.invoice_account_id;

        deleteContactApi(id, client_id, deleted_invoice_account_id)
        .then((response) => {
            const res = response.data;
            consoleToLog('Response deleteContactApi', res);
            const updatedArray = contactList.map((contact) => {
                if(contact.id === contactObj.id) {
                    contact.clients = contactObj.clients?.filter((client) => client.id !== clientObj.id);
                }
                return contact
            });
            setContactList(updatedArray);
            //props.setContactDeletedFromOutside(true);
            
            enqueueSnackbar(res.message, {variant: 'success'});
        })
        .catch((e) => {
            consoleToLog('Error deleteContactApi: ', e.response);
            if (e.response.data && e.response.data.message) {
                enqueueSnackbar(e.response.data.message, {
                    variant:'error'
                });
                return false;
            }
        })
    }


    const handleClickDialogOpen = () => {
      
        setOpenDialog(true);
    };

    const handleClickDialogClose = () => {
        setOpenDialog(false);
    };


    const onDeleteContactClick = (e,contact) => {
        e.stopPropagation();
        closeMoreOptionsMenu();
        setContactId(contact.id);
        handleClickDialogOpen();
    }

    

    const handleContactDrawerOpen = () => {
        
        trackGAEvent(props.selectedOrganization.organization.name, 'Add Contact Clicked', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
        setOpenContactDrawer(true);
    }
    
    const handleContactDrawerClose=()=>{
        props.handleContactDrawerClose();
        Object.keys(contactObj).length > 0 && setContactObj({});
    }

    const getClientContactList = (query) => {
        const organization_id = props.selectedOrganization.organization?.id;

        if(!query) {
            fetchContactList();
        } else {
        
            fullTextSearchApi(organization_id, query)
                .then((response) => {
                    const res = response.data;
                    consoleToLog('Response fullTextSearchApi', res);
                    setContactList(res.contacts);
                })
                .catch((e) => {
                    consoleToLog('Error fullTextSearchApi', e.response);
                    if(e.response.data && e.response.data.message) {
                        return;
                    }
                })
        }
    }

    const contactListForClientDetails = () => {
        return <>
                <Grid item sm={12} className={classes.itemTitleList}>        
                        <Grid item container style={{padding:'5px 16px'}}>
                            <Grid item sm={2} >
                                <Typography variant="body1" className={classes.headings}>
                                    Firstname
                                </Typography>
                            </Grid>

                            <Grid item sm={2} >
                                <Typography variant="body1" className={classes.headings}>
                                    Lastname
                                </Typography>
                            </Grid>

                            <Grid item sm={1}>
                                <Typography variant="body1" className={classes.headings} 
                                    style={{marginLeft:'-2px'}}    >
                                    Title
                                </Typography>
                            </Grid>

                            <Grid item sm={3}>
                                <Typography variant="body1" className={classes.headings}>
                                    Email
                                </Typography>
                            </Grid>

                            <Grid item sm={4}>
                                <Typography variant="body1" className={classes.headings}>
                                    Mobile
                                </Typography>
                            </Grid>

                        </Grid>        
                    </Grid>

                    {loading ?
                        <CircularProgress size={28} className={classes.loading}/> 
                        :
                        <Grid item xs={12}>
                        {
                            contactList && contactList.length > 0  ?
                            contactList.filter(
                                item => {
                                return  (
                                            item.first_name?.toLowerCase().includes(searchText?.toLowerCase()) ||
                                            item.last_name?.toLowerCase().includes(searchText?.toLowerCase()) ||
                                            item.email?.toLowerCase().includes(searchText?.toLowerCase())
                                    )}
                                ).map((contact) => {
                                return  <Grid item container 
                                                key={contact.id}
                                                justifyContent="flex-start"
                                                className={classes.itemListContainer}
                                                alignItems="center"                      
                                            >                               
                                                <Grid item sm={2} >
                                                    <Tooltip title={contact.first_name} arrow>
                                                        <Typography variant='body1'>
                                                            {contact.first_name}
                                                        </Typography>
                                                    </Tooltip>
                                                </Grid>        
                                                <Grid item sm={2} >
                                                    <Tooltip title={contact.last_name} arrow>
                                                        <Typography variant='body1'>
                                                            {contact.last_name}
                                                        </Typography>
                                                    </Tooltip>
                                                </Grid>        
                                                <Grid item sm={1} >
                                                    <Tooltip title={contact.title} arrow>
                                                        <Typography variant='body1'>
                                                            {contact.title}
                                                        </Typography>
                                                    </Tooltip>
                                                </Grid>        
                                                <Grid item sm={3} >
                                                    <Tooltip title={contact.email} arrow>
                                                        <Typography variant='body1'>
                                                            {contact.email}
                                                        </Typography>
                                                    </Tooltip>
                                                </Grid>        
                                                <Grid item sm={2} >
                                                    <Tooltip title={contact.phone_number} arrow>
                                                        <Typography variant='body1'>
                                                            {contact.phone_number}
                                                        </Typography>
                                                    </Tooltip>
                                                </Grid>    
                                            </Grid>  
                                        })
                                        :
                                        <Grid item container justifyContent='center' alignItems='center'
                                            style={{padding:'16px 0px', background:'#fff'}}>
                                            <Typography variant='body1'>
                                                No Contacts Found
                                            </Typography>
                                        </Grid>
                                    }    
                                                        
                                </Grid>    
                        }  
                </>
    }

    const contactListForClientTab = () => {
        return <>
                <Grid item sm={12} className={classes.itemTitleList}>        
                        <Grid item container style={{padding:'5px 16px'}}>
                            <Grid item sm={2}>
                                <Typography variant="body1" className={classes.headings}>
                                    Name
                                </Typography>
                            </Grid>

                            <Grid item sm={3} className={classes.customColumn1}>
                                <Typography variant="body1" className={classes.headings}>
                                    Email
                                </Typography>
                            </Grid>

                            <Grid item sm={1} className={classes.customColumn2}>
                                <Typography variant="body1" className={classes.headings}>
                                    Mobile
                                </Typography>
                            </Grid>

                            <Grid item sm={5} className={classes.customColumn3}>
                                <Typography variant="body1" className={classes.headings}>
                                    Companies
                                </Typography>
                            </Grid>

                        </Grid>        
                    </Grid>

                    {loading ?
                        <CircularProgress size={28} className={classes.loading}/> 
                        :
                        <Grid item xs={12}>
                        {
                            contactList && contactList.length > 0  ?
                            contactList.filter(
                                item => {
                                return  (
                                            item.first_name?.toLowerCase().includes(searchText?.toLowerCase()) ||
                                            item.last_name?.toLowerCase().includes(searchText?.toLowerCase()) ||
                                            item.email?.toLowerCase().includes(searchText?.toLowerCase())
                                    )}
                                ).map((contact) => {
                                    const fullName = contact.first_name + ' ' + contact.last_name;
                                    const clientList = contact?.clients;
                                return  <Grid item container 
                                                key={contact.id}
                                                justifyContent="flex-start"
                                                className={classes.itemListContainer}
                                                alignItems="center"                      
                                            >                               
                                                <Grid item sm={2}>
                                                    <Tooltip title={fullName} arrow>
                                                        <Typography variant='body1' className={classes.nameEllipse}>
                                                            {contact.first_name ? fullName : ''}
                                                        </Typography>
                                                    </Tooltip>
                                                </Grid>        
                                                {/* <Grid item sm={2} >
                                                    <Tooltip title={contact.last_name} arrow>
                                                        <Typography variant='body1'>
                                                            {contact.last_name}
                                                        </Typography>
                                                    </Tooltip>
                                                </Grid>         */}
                                                {/* <Grid item sm={1} >
                                                    <Tooltip title={contact.title} arrow>
                                                        <Typography variant='body1'>
                                                            {contact.title}
                                                        </Typography>
                                                    </Tooltip>
                                                </Grid>         */}
                                                <Grid item sm={3} className={classes.customColumn1}>
                                                    <Tooltip title={contact.email} arrow>
                                                        <Typography variant='body1' className={classes.emailEllipse}>
                                                            {contact.email}
                                                        </Typography>
                                                    </Tooltip>
                                                </Grid>        
                                                <Grid item sm={1} className={classes.customColumn2}>
                                                    <Tooltip title={contact.phone_number} arrow>
                                                        <Typography variant='body1'>
                                                            {contact.phone_number}
                                                        </Typography>
                                                    </Tooltip>
                                                </Grid> 
                                                <Grid item sm={5} className={classes.customColumn3}>
                                                    <Grid item container alignItems='center' spacing={1}>
                                                        {
                                                            clientList && clientList.length > 0 &&
                                                            clientList.map((client) => {
                                                            return <Grid item key={client.id}>
                                                                        <Tooltip title={client.name} arrow>
                                                                            <Typography variant='subtitle1' className={classes.clientNameStyle}>
                                                                                {client.name}
                                                                            </Typography>
                                                                        </Tooltip>
                                                                    </Grid>
                                                            })
                                                        }
                                                    </Grid>
                                                </Grid>


                                                <Grid item md={1} lg={1} style={{display:'flex', alignItems:'center', justifyContent:'flex-end', position:"absolute", right:'8px'}}>
                                                    <div className={classes.actionIconContainer}>
                                                        <Button 
                                                            onClick={(e) => onEditContactClick(e, contact)}
                                                            className='more_actionButton1 action__icon'>
                                                            <i className={`flaticon-edit ${classes.flatIcons}`}></i>
                                                            Edit
                                                        </Button>
                                                        <Button 
                                                            onClick={(e) => onDeleteContactClick(e, contact)}
                                                            className='more_actionButton1 action__icon'>
                                                            <i className={`flaticon-delete-1 ${classes.flatIcons}`}></i>
                                                            Delete
                                                        </Button>
                                                    </div>
                                                </Grid>  
                                            </Grid>  
                                        })
                                        :
                                        <Grid item container justifyContent='center' alignItems='center'
                                            style={{padding:'16px 0px', background:'#fff'}}>
                                            <Typography variant='body1'>
                                                No Contacts Found
                                            </Typography>
                                        </Grid>
                                    }    
                                                        
                                </Grid>    
                        }  
                </>
    }

    return (
            <div style={{width:'100%'}}>
                <Grid item md={12} lg={12} style={{marginTop:'10px'}}></Grid>
                    <Grid item md={12} lg={12} style={{marginTop:'10px'}}>

                    <Grid item sm={4} style={{margin:'16px 0px 16px auto', paddingRight:"16px"}}>
                        <TextField 
                            fullWidth
                            variant='outlined'
                            margin='dense'
                            placeholder='Search Contact'
                            InputLabelProps={{style: {fontSize: 14, color: "#ccc"}}}
                            onChange={(e) => {
                                if(props.fromClientDetails) {
                                    setSearchText(e.target.value);
                                } else {
                                    getClientContactList(e.target.value)
                                }
                            }}
                            InputProps={{
                                startAdornment: (
                                <InputAdornment position="start">
                                    {/* <SearchIcon className={classes.searchicon}/> */}
                                    <i className="flaticon-search"></i>
                                </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                                    
                    {
                        props.fromClientDetails ? 
                        contactListForClientDetails()
                        :
                        contactListForClientTab()
                    }
                                                            
                </Grid>

                <ContactDrawer 
                    //openContactDrawer={props.fromOutsideClientDetails ? props.openContactDrawer : openContactDrawer} 
                    //handleContactDrawerClose={props.fromOutsideClientDetails ? props.handleContactDrawerClose : handleContactDrawerClose}
                    openContactDrawer={props.openContactDrawer}
                    handleContactDrawerClose={handleContactDrawerClose}
                    contactObj={contactObj}
                    companyObj={props.fromClientDetails && props.companyObj}
                    setCompanyObj={props.fromClientDetails && props.setCompanyObj}
                    companyUpdated={props.fromClientDetails && props.companyUpdated}
                    contactList={contactList}
                    setContactList={setContactList}
                    setContactObj={setContactObj}
                    setContactDeletedFromOutside={props.setContactDeletedFromOutside}
                    callDeleteContactApiFromClientDetails={callDeleteContactApiFromClientDetails}
                />

                {
                    openDialog && <ClientListModal openDialog={openDialog}
                                        handleClickDialogClose={handleClickDialogClose}
                                        contactList={contactList}
                                        setContactList={setContactList}
                                        contactId={contactId}
                                        setContactId={setContactId}
                                        setContactDeletedFromOutside={props.setContactDeletedFromOutside}
                                    />
                }
            </div>
    )  
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
});

export default connect(mapStateToProps)(ContactComponent);