import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Typography } from "@material-ui/core";
import { useIsMount } from "../useIsMount";
import { Grid } from "@material-ui/core";
import { connect } from "react-redux";
import { consoleToLog } from "../../util/AppUtil";
import { downloadAttachmentApi } from "../../services/authService";

const useStyles = makeStyles((theme) => ({
  customFileUploadTypo: {
    fontWeight: 600,
    fontSize: "1rem",
  },
  customFileUploadMain: {
    marginTop: "10px",
    marginBottom: "10px",
  },
  downloadLink: {
    color: "#20a8e0",
    fontSize: "15px",
    fontWeight: "600",
    marginLeft: "15px",
    marginTop: "-10px",
    cursor: "pointer",
  },
  // customFileUploadTypo: {
  //   fontWeight: 600,
  //   fontSize: "1rem",
  //   marginBottom: "1rem",
  // },
  buttonStyle : {
    marginTop: "12px", 
    textDecoration: "none",
    '&:hover' : {
      color: theme.palette.primary.main
    }
  },
  downloadContainer: {
    padding:'16px',
    '&:hover': {
      backgroundColor: '#e2e4e9',
      borderRadius:'4px'
    },
    '& .actionButtons': {
      visibility:"hidden"
    },
    '&:hover .actionButtons': {
      visibility:"visible"
    }
  },
  flatIcons : {
    fontSize:'18px', 
    display:'flex',
    alignItems:'center'
  },
  customColumn1: {
    flexBasis: "92%",
    maxWidth: "92%"
  },
  customColumn2: {
    flexBasis: "8%",
    maxWidth: "8%"
  },
  buttonClass: {
    minWidth:"23px"
  }
}));

const DownloaderWidget = (props) => {
  const classes = useStyles();
  const isMount = useIsMount();

  const attachment_path = props.widget.json_data.file_path.path ? props.widget.json_data.file_path.path : props.widget.json_data.file_path;
  const [widget, setWidget] = useState(props.widget);
  const [filePath, setFilePath] = useState(attachment_path);

  // useEffect(() => {
  //   if (filePath?.path?.indexOf("http") === 0 || filePath?.path?.indexOf("https") === 0) {
  //     consoleToLog("The link has http or https.");
  //   } else {
  //     // consoleToLog("The link has NO http or https.");
  //     getSignedUrlApiCall();
  //   }
  // }, []);

  useEffect(() => {
    if (!isMount) {
      setWidget(props.widget)
    }
  }, [props.widget]);

  const getSignedUrlApiCall = () => {
    const task_id = props.selectedTask.id;
    const widget_id = props.widget.id;
    const path = filePath;
    const organization_id = props.selectedOrganization.organization.id;

    if (attachment_path?.path?.indexOf("http") === 0 || attachment_path?.path?.indexOf("https") === 0) {
      consoleToLog("The link has http or https.");
    } else {
      // consoleToLog("The link has NO http or https.");
      downloadAttachmentApi(organization_id, path)
        .then((response) => {
          const res = response.data;
          consoleToLog("*******Response downloadAttachmentApi: ", res);
          const downloadableURL = res.document_signed_url;

          //initiate download
          document.getElementById("widget_download").href = downloadableURL;
          document.getElementById("widget_download").click();
        })
        .catch((e) => {
          consoleToLog("******downloadAttachmentApi error", e.response);
        });
    }

    
  }

  return (
    <>
      <Grid item container alignItems="center" 
        className={`${classes.customFileUploadMain} ${props.editChecklist && classes.downloadContainer}`} 
        style={{ paddingTop: "10px" }}>
        
        <Grid item md={props.editChecklist ? 10 : 12} className={props.editChecklist && classes.customColumn1}>
            <Grid item container>
              <Typography className={classes.customFileUploadTypo}>
                {widget.json_data.desc}
              </Typography>
            </Grid>
            {
              widget.json_data.file_path &&
              <a
                style={{ textDecoration: "none" }}
                id="widget_download"
                title="Download"
                onClick={(e) => e.stopPropagation()}
                download
                target="_blank"
              >
                <Button
                  onClick={getSignedUrlApiCall}
                  variant="contained" color="primary" className={classes.buttonStyle}>
                  {widget.json_data.file_path.path ? widget.json_data.file_path.path.replace(/^.*[\\\/]/, "") 
                    :  widget.json_data.file_path.replace(/^.*[\\\/]/, "")}
                </Button>
              </a>
            }
        </Grid>
        {props.editChecklist &&
        <Grid item md={2} className={classes.customColumn2}>
          <Grid item container alignItems="center" className={props.editChecklist && 'actionButtons'}>
              <Grid item>
                  <Button className={classes.buttonClass}
                  onClick={(e) => props.handleOpenUpdateTaskWidgetDrawer(e, props.selectedTask, props.widget)}>
                    <i className={`flaticon-edit ${classes.flatIcons}`}></i>
                  </Button>
              </Grid>

              <Grid item>
                  <Button className={classes.buttonClass}
                    onClick={() => props.deleteWidgetClick(props.selectedTask, props.widget)}>
                    <i className={`flaticon-delete-1 ${classes.flatIcons}`}></i>
                  </Button>
              </Grid>

          </Grid>
        </Grid>}
      </Grid>
    </>
  );
};


const mapStateToProps = (state) => ({
  auth: state.auth,
  selectedOrganization: state.organizationInfo.selectedOrganization,
});

export default connect(mapStateToProps, null)(DownloaderWidget);
