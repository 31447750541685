import moment from 'moment';

const defaultState = {
    
    selectedActivityReportMembersFilter: [],
    //filter activity reports by date (dashboard)
    startDateFilter: getDefaultStartDate(),
    endDateFilter: getDefaultEndDate(),
}

function getDefaultStartDate() {
    return (moment().month() < 3) ? 
        moment().subtract(1, 'year').month('April').startOf('month').format('YYYY-MM-DD')
        : 
        moment().month('April').startOf('month').format('YYYY-MM-DD'); 
}

function getDefaultEndDate() {
    return (moment().month() < 3) ? 
        moment().month('March').endOf('month').format('YYYY-MM-DD')
        : 
        moment().add(1, 'year').month('March').endOf('month').format('YYYY-MM-DD');
}        

//set selected organization
export default (state = defaultState, action) => {
    switch (action.type) {
        
        case 'ADD_ACTIVITY_REPORT_MEMBER_TO_FILTER':
            return {
                ...state,
                selectedActivityReportMembersFilter: [
                    ...state.selectedActivityReportMembersFilter,
                    action.member
                ]
            }
        case 'REMOVE_ACTIVITY_REPORT_MEMBER_FROM_FILTER':
            return {
                ...state,
                selectedActivityReportMembersFilter: state.selectedActivityReportMembersFilter.filter((m) => m.id !== action.member.id)
            }

        // case 'CLEAR_ACTVITY_REPORT_MEMBERS_FILTER':
        //     return {
        //         ...state,
        //         selectedActivityReportMembersFilter: []
        //     }

        /**
         * Activity report date filter
         */
        case 'SET_ACTIVITY_REPORT_START_DATE_FILTER':
            return {
                ...state,
                startDateFilter: action.start_date
            }
        case 'SET_ACTIVITY_REPORT_END_DATE_FILTER':
            return {
                ...state,
                endDateFilter: action.end_date
            }

        case 'CLEAR_ACTIVITY_REPORT_FILTER':
            return defaultState;

        default:
            return state;
    }
};