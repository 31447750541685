import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Grid, Typography, Button, Avatar, Tooltip, Link, IconButton, TextField, MenuItem, CircularProgress, 
    ClickAwayListener, InputAdornment, } from '@material-ui/core';
import {connect} from 'react-redux';
import { useIsMount } from './useIsMount';
import { consoleToLog, trackGAEvent } from '../util/AppUtil';
import { useSnackbar } from 'notistack';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import CustomFieldDrawer from './CustomFieldDrawer';
import {deleteCustomFieldApi} from '../services/clientService';
import {deleteOrgCustomField, } from '../actions/selectedOrganization';
import { udfTypes } from '../util/userDefinedFieldsUtil';

const useStyles = makeStyles((theme) => ({
    loading: {
        position:'relative',
        left:'50%',
        right:'50%',
        marginTop:'1%'
    },
    headings: {
        fontWeight:'500',
        color:'#202020',
        [theme.breakpoints.down(1400)]: {
            fontSize:'14px'
        },
    },
    itemTitleList: {
        background:'#f9fafb',
        borderBottom: '1px solid #edf2f7',
    },
    itemListContainer : {
        background:'white', 
        minWidth:"100%", 
        padding:'10px 16px', 
        color:'#202020',
        position:'relative',
        borderBottom: '1px solid #edf2f7',
        cursor:'pointer',
        '& .action__icon' : {
            padding:'2px 4px 0px 4px',
            border:'1px solid white',
            borderRadius:'4px',
            cursor:'pointer'
        },
        '&:hover .action__icon' : {
           border:'1px solid #ccc'
        },
        '&:hover':{
          backgroundColor:'#f7fafc',
        },
        '& .more_actionButton' : {
            visibility:'hidden',
            position:'absolute',
            right:'90px',
            width:'160px',
            background:'white',
            border:'1px solid #ccc',
            borderRadius:'5px',
            display:'flex',
            justifyContent:'center',
            aligItems:'center',
            fontSize:'14px',
            padding:'4px 0px 4px 0px',
            [theme.breakpoints.down(1500)]: {
                right:'80px',
                padding:'3px 0px 3px 0px',
            },

        },
        '&:hover .more_actionButton' : {
            visibility:'visible',
        },
    },
    avatarStyle: {
        width:"38px !important",
        height:"38px !important",
        fontSize:'16px !important',
        fontWeight:'600 !important',
        background:'#fff',
        color:'#202020',
        border:'1px solid #4a5568'
    },
    customFieldName: {
        fontSize:'14px', 
        whiteSpace: "nowrap", 
        maxWidth: "320px", 
        overflow: "hidden", 
        textOverflow: "ellipsis",
    },
    emailEllipsis : {
        whiteSpace: "nowrap", 
        maxWidth: "83% !important", 
        overflow: "hidden", 
        textOverflow: "ellipsis",
        cursor: 'pointer'
    },
    addressHeading: {
        display:'flex', 
        justifyContent: 'center', 
        marginLeft:'-72px'
    },
    addressText : {
        display:'flex', 
        justifyContent: 'center'
    },
    customColumn1 : {
        flexWidth: '40%',
        maxWidth: '40%'
    },
    customColumn2 : {
        flexWidth: '18%',
        maxWidth: '18%'
    },
    actionsIcons: {
        color: '#b5bbc2',
        fontSize: '18px',
        marginRight:'4px',
        marginTop:'1px'
    },
}))

const CustomFieldsComponent = (props) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    const isMount = useIsMount();

    const [isActive, setIsActive] = useState(false);
    const [customFieldId, setCustomFieldId] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [selectedCustomField, setSelectedCustomField] = useState(undefined);
    const [customFieldList, setCustomFieldList] = useState(props.customFieldList);
    const [searchCustomField, setSearchCustomField] = useState('');

    const closeMoreOptionsMenu = () => {
        setIsActive(false);
        setCustomFieldId('');
    }

    const onEditCustomFieldClick = (e, customField) => {
        e.stopPropagation();
        props.setOpenCustomFieldDrawer(true);
        setSelectedCustomField(customField);
        closeMoreOptionsMenu();
    }

    useEffect(() => {
        if(isMount) {
            //consoleToLog('first Render');
        } else {
            if(searchCustomField === '') {
                setCustomFieldList(props.customFieldList)
            }    
        }
    }, [searchCustomField]);

    useEffect(() => {
        if(isMount) {
            //consoleToLog('first Render');
        } else {
                setCustomFieldList(props.customFieldList)
        }
    }, [props.customFieldList])

    const onDeleteCustomField =(e, customField) => {
        
        if(window.confirm("Do you really want to delete this custom field?")){
            deleteCustomFieldApi(customField.id)
                .then((response) => {
                    const res = response.data;
                    consoleToLog('Response deleteCustomFieldApi: ', res);
                    enqueueSnackbar(res.message, {
                        variant:'success'
                    });

                    props.deleteOrgCustomField(customField.id);
                    trackGAEvent(props.selectedOrganization.organization.name, 'Custom Field Deleted', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
                    setIsActive(false);
                    setCustomFieldId('');
                })
                .catch((e) => {
                    setLoading(false);
                    consoleToLog('Error deleteCustomFieldApi: ', e.response);
                    if(e.response.status && e.response.status.message) {
                        enqueueSnackbar(e.response.status.message, {variant:'error'});
                        return;
                    }
                });
        }
    }

    const onSearchCustomField = (e) => {
        const name = e.target.value.toLowerCase();
        setSearchCustomField(name);
        let searchList = customFieldList.filter(cf => cf.name.toLowerCase().includes(name));
        //console.log(test)
        setCustomFieldList(searchList);
    }

    return (
        <div style={{width:'100%'}}>
            <Grid item sm={4} style={{margin:'16px 0px 0px auto', paddingRight:"16px"}}>
                <TextField 
                    fullWidth
                    variant='outlined'
                    margin='dense'
                    placeholder='Search custom field'
                    InputLabelProps={{style: {fontSize: 14, color: "#ccc"}}}
                    onChange={onSearchCustomField}
                    InputProps={{
                        startAdornment: (
                        <InputAdornment position="start">
                            {/* <SearchIcon className={classes.searchicon}/> */}
                            <i className="flaticon-search"></i>
                        </InputAdornment>
                        )
                    }}
                />
            </Grid>
            <Grid item xs={12} style={{marginTop:'24px'}}>
                    <Grid item sm={12}>
                        <Grid item sm={12} className={classes.itemTitleList}>
                            <Grid item container style={{padding:'10px 16px'}}>
                                <Grid item sm={2}  className={classes.customColumn1}>
                                    <Typography variant="body1" className={classes.headings}>
                                        #
                                    </Typography>
                                </Grid>

                                <Grid item sm={9}  className={classes.customColumn2}>
                                    <Typography variant="body1" className={classes.headings}>
                                        Field Name
                                    </Typography>
                                </Grid>

                            </Grid>
                        </Grid>

                    {loading ?
                    <CircularProgress size={30} className={classes.loading}/> 
                    :
                    <Grid item xs={12}>
                    {
                        customFieldList && customFieldList.length > 0  &&
                        customFieldList.map((cf, index) => {
                            return  <Grid item container 
                                            key={cf.id}
                                            justifyContent="flex-start"
                                            className={classes.itemListContainer}
                                            alignItems="center">
                                        
                                        <Grid item md={2} lg={2} className={classes.customColumn1}>
                                            {index+1}
                                        </Grid>

                                        <Grid item md={9} lg={9} /* className={classes.customColumn2} */>
                                            <Grid item container alignItems='center'>
                                                <Grid item >
                                                    <Tooltip title={cf.name} arrow>
                                                        <Typography variant='body1' className={classes.customFieldName}>
                                                            {cf.name}
                                                        </Typography>
                                                    </Tooltip>
                                                </Grid>        
                                        </Grid>
                                        </Grid>
                                        
                                        <Grid item md={1} lg={1}
                                            style={{display:'flex', aligItems:'center', justifyContent:"end", flexBasis:'6%', maxWidth:'6%'}}>
                                            <Tooltip title="More Actions" arrow>  
                                                <i className="flaticon-more action__icon"  
                                                onClick={(e) => {
                                                        e.stopPropagation();
                                                        setIsActive(!isActive);
                                                        setCustomFieldId(cf.id);
                                                    }}
                                                style={{fontSize:'1.1rem'}}></i>
                                            </Tooltip>
                                            
                                        </Grid> 
                                        <>    
                                            {cf.id === customFieldId &&
                                            <ClickAwayListener onClickAway={() => {
                                                    setIsActive(false);
                                                    setCustomFieldId('');
                                            }}>
                                            <Grid item
                                                style={{marginRight:'3.5%', marginTop:'14px'}}
                                                className={`menu ${isActive ? `active ${classes.marginDropdown}` : "inactive"}`}>
                                                <ul>
                                                    
                                                    <li onClick={(e) => onEditCustomFieldClick(e, cf)}>
                                                        <a style={{display:'flex', alignItems:'center'}}>
                                                            <EditOutlinedIcon fontSize='small'  
                                                            className={`more_actionsIcons ${classes.actionsIcons}`}/>
                                                            Edit
                                                        </a>
                                                    </li>

                                                    <li onClick={(e) => onDeleteCustomField(e, cf)}>
                                                        <a href="#" style={{display:'flex', alignItems:'center'}}>
                                                            <DeleteOutlinedIcon fontSize='small' 
                                                            className={`more_actionsIcons ${classes.actionsIcons}`}/>
                                                            Delete
                                                        </a>
                                                    </li>
                                                </ul>
                                            </Grid>
                                            </ClickAwayListener>
                                            }
                                        </>
                                    </Grid>

                    })}
                        {customFieldList && customFieldList.length === 0 &&
                            <Grid tem container
                                className={classes.invoiceListContainer}
                                alignItems="center" justifyContent="center">
                                <Grid item>
                                    <Typography variant="body1" style={{padding:"16px"}}>
                                            No custom fields found.
                                    </Typography>
                                </Grid>     
                            </Grid>
                        }    
                        </Grid>
                    }
                    </Grid>
                </Grid>

                <CustomFieldDrawer openCustomFieldDrawer={props.openCustomFieldDrawer} 
                    setOpenCustomFieldDrawer={props.setOpenCustomFieldDrawer}
                    selectedCustomField={selectedCustomField}
                    setSelectedCustomField={setSelectedCustomField}
                />
                
            </div>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    customFieldList: state.organizationInfo.customFieldList
});

const mapDispatchToProps = (dispatch) => ({
    deleteOrgCustomField: (custom_field_id) => dispatch(deleteOrgCustomField(custom_field_id))
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomFieldsComponent);