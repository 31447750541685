import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar } from "@material-ui/core";
import moment from "moment";
import {
  deleteChecklistAttachmentApi,
  getChecklistAttachmentUrlApi,
  getTaskAttachmentUrlApi,
} from "../../services/ChecklistService";
import { updateFileUploadUrlApi } from "../../services/authService";
import { connect } from "react-redux";
import { consoleToLog, trackGAEvent } from "../../util/AppUtil";
import { useSnackbar } from "notistack";
import { Link } from "react-router-dom";
import { getExtension } from "../../util/AppUtil";
import {Tooltip} from '@material-ui/core';
import {CircularProgress} from '@material-ui/core';
import {downloadAttachmentApi} from '../../services/authService';

const useStyles = makeStyles((theme) => ({
  attachmentEndIcons: {
    marginRight: "15px",
    cursor:"pointer"
  },
  attachmentEndIcons1: {
    marginRight: "15px",
    cursor:"pointer"
  },
  attachmentsTitle: {
    fontSize: "15px",
    fontWeight: 500,
    marginLeft: "10px",
    marginBottom: "-4px",
  },
  attachmentsSubTitle: {
    fontSize: "12.5px",
    fontWeight: "400",
    color: "gray",
    marginLeft: "10px",
    paddingTop:"4px"
  },
  attachmentsUploadedby: {
    fontSize: "15px",
    marginLeft: "5px",
    color: "black",
  },
  attachmentsSize: {
    fontSize: "13.5px",
    color: "black",
  },
  attachmentsTime: {
    fontSize: "13.5px",
    color: "black",
  },
  attachmentsIcon: {
    padding: "6px 4px",
    // marginLeft:"15px",
    // paddingRight:"0px"
    paddingLeft: "15px",
  },
}));

const AttachmentItem = (props) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [downloadableUrl, setDownloadableUrl] = useState(undefined);
  const [widgetDownloadableURL, setWidgetDownloadableURL] = useState(undefined);
  const [loading,setLoading]=useState(false);

  useEffect(() => {
    if (downloadableUrl !== undefined) {
      document.getElementById("initiateDownloadAttachment").href = downloadableUrl;
      document.getElementById("initiateDownloadAttachment").click();
    }
  }, [downloadableUrl]);

  useEffect(() => {
    if (widgetDownloadableURL !== undefined) {
      document.getElementById("initiateDownloadAttachment").href = widgetDownloadableURL;
      document.getElementById("initiateDownloadAttachment").click();
    }
  }, [widgetDownloadableURL]);

  // // Widget Attachment Delete
  // const deleteWidgetAttachment = (fileObj) => {
  //   const path = ""; //empty for deletion
  //   const checklist_task_widget_id = fileObj.id;

  //   updateFileUploadUrlApi(checklist_task_widget_id, path)
  //     .then((response) => {
  //       const res = response.data;
  //       consoleToLog("Response deletion-updateFileUploadUrlApi: ", res);
  //       enqueueSnackbar("Checklist Attachment Deleted", {
  //         variant: "success",
  //       });
  //       var widgetId = res.checklist_task_widget.id;
  //       // consoleToLog("widgetId",widgetId);
  //       props.onAttachmentDelete(widgetId);
  //       trackGAEvent(props.selectedOrganization.organization.name, 'Checklist Delete widget Attachment', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
  //     })
  //     .catch((e) => {
  //       consoleToLog(" deletion-updateFileUploadUrlApi error", e.response);
  //     });
  // };

  // Widget Checklist Delete
  const deleteChecklistAttachment = (fileObj) => {
    const organization_id = props.selectedOrganization.organization.id;
    const attachment_id = fileObj.id;
    setLoading(true);

    deleteChecklistAttachmentApi(organization_id, attachment_id)
      .then((response) => {
        const res = response.data;
        consoleToLog("Response deleteChecklistAttachmentApi ", res);
        setLoading(false);
        enqueueSnackbar(res.message, {
          variant: "success",
        });
        consoleToLog("attachment_id",attachment_id);
        trackGAEvent(props.selectedOrganization.organization.name, 'Checklist Delete Attachment', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
        // to parent
        props.onAttachmentDelete(attachment_id);
      })
      .catch((e) => {
        setLoading(false);
        consoleToLog("Response deleteChecklistAttachmentApi error", e.response);
      });
  };

  // Download Checklist Attachment
  const handleDownloadAttachment = (fileObj) => {
    const organization_id = props.selectedOrganization.organization.id;
    const path = fileObj.path;
    consoleToLog('fileObj*****', fileObj);

    downloadAttachmentApi(organization_id, path)
    .then((response) => {
        const res = response.data;
        consoleToLog("Response downloadAttachmentApi: ", res);
        const downloadableURL = res.document_signed_url;
        setDownloadableUrl(downloadableURL);
        trackGAEvent(props.selectedOrganization.organization.name, 'Checklist Download Attachment', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
    })
    .catch((e) => {
      consoleToLog(" downloadAttachmentApi error", e.response);
    });
  };

  // Download Widget Attachment
  const onDownloadWidgetAttachment = (fileObj) => {
    const checklist_task_widget_id = fileObj.id;
    const path = fileObj.path;

    getTaskAttachmentUrlApi(checklist_task_widget_id, path)
    .then((response) => {
        const res = response.data;
        consoleToLog("Response getTaskAttachmentUrlApi: ", res);

        const widgetDownloadableURL = res.document_signed_url;
        setWidgetDownloadableURL(widgetDownloadableURL);
        trackGAEvent(props.selectedOrganization.organization.name, 'Checklist Download Widget Attachment', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
    })
    .catch((e) => {
      consoleToLog(" getTaskAttachmentUrlApi error", e.response);
    });
  };

  var AttachmentName = props.singleattachment.path.replace(/^.*[\\\/]/, "");

  var uploadedBy = props.singleattachment.attachment_user.firstname.charAt(0).toUpperCase() +
    props.singleattachment.attachment_user.lastname.charAt(0).toUpperCase();
 
  var uploadedByFullName = props.singleattachment.attachment_user.firstname +
  props.singleattachment.attachment_user.lastname;
  
  return (
    <>
    <div className="attachmentParent">
      <Grid
        item
        container
        direction="row"
        className="attachmentsListItem"
        alignItems="center"
        sm={12}
      >
        <Grid
          item
          container
          direction="column"
          sm={1}
          className={classes.attachmentsIcon}
        >
          <i className={getExtension(AttachmentName)}
            style={{ fontSize: "22px" }}
          ></i>
        </Grid>
        <Grid item container direction="column" sm={3}>
          <Grid item direction="row" className={classes.attachmentsTitle}>
            {props.singleattachment.title ? props.singleattachment.title : props.singleattachment.desc}
          </Grid>
          <Grid item direction="row" className={classes.attachmentsSubTitle}>
            {AttachmentName}
          </Grid>
        </Grid>
        <Grid item container direction="column" sm={3}>
          <Tooltip title={uploadedByFullName} arrow>
             <Avatar>{uploadedBy}</Avatar>
          </Tooltip>    
        </Grid>
        <Grid
          item
          container
          direction="column"
          sm={3}
          className={classes.attachmentsTime}
        >
          {moment(props.singleattachment.created_at).fromNow()}
        </Grid>
        {!loading &&
        <Grid
          item
          container
          direction="row"
          sm={2}
          alignItems="center"
          justifyContent="flex-end"
          className = "attachmentsIcons"
        >
            <Grid
            item
            direction="column"
            className={classes.attachmentEndIcons1}
            title="Download Attachment"
            onClick={(e) => {
              props.singleattachment.attachment_type === "attachment"   
                ? handleDownloadAttachment(props.singleattachment)
                : onDownloadWidgetAttachment(props.singleattachment);
            }}
          >
            <i className="flaticon-download" style={{ fontSize: "18px" }}></i>
            <Link
              className="d-none"
              id="initiateDownloadAttachment"
              target="_blank"
              onClick={(e) => e.stopPropagation()}
              download
            ></Link>
          </Grid>
          <Grid
            item
            direction="column"
            title="Delete Attachment"
            className={classes.attachmentEndIcons}
            onClick={(e) => deleteChecklistAttachment(props.singleattachment)}>
            <i className="flaticon-delete-1" style={{ fontSize: "18px" }}></i>
          </Grid>
        </Grid>
        }
        {loading &&
           <Grid
             item
             container
             direction="row"
             sm={2}
             alignItems="center"
             justifyContent="flex-end"
        >
          <Grid
            item
            direction="column"
            title="Delete Attachment"
            className={classes.attachmentEndIcons}>
              <CircularProgress size={18} style={{ fontSize: "18px",marginRight:"10px" }}/>
          </Grid>
          </Grid>
        }
      </Grid>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  selectedOrganization: state.organizationInfo.selectedOrganization,
});

export default connect(mapStateToProps, null)(AttachmentItem);
