import React, {useEffect, useState} from 'react';
import {Grid, Typography, Button} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    contactContainer: {
        background:'#fff',
        padding:'0px 12px',
        marginBottom:'10px'
    },
    contactTextStyle: {
        fontWeight:'600'
    }
}))

const MobileClientContacts = (props) => {
    const classes = useStyles();

    const [contactList, setContactList] = useState(props.clientObj?.contacts);

    return (
        <div>
            <Grid item xs={12} style={{marginTop:'16px'}}>
                {
                    contactList && contactList.length > 0 &&
                    contactList.map((contactObj) => {
                        return <Grid item xs={12} key={contactObj.id}
                                    className={classes.contactContainer}>
                                    <Grid item container spacing={1} alignItems='center'>
                                        <Grid item>
                                            <Typography variant='body2'>
                                                Firstname:
                                            </Typography>
                                        </Grid>

                                        <Grid item>
                                            <Typography variant='body2' className={classes.contactTextStyle}>
                                                {contactObj.first_name}
                                            </Typography>
                                        </Grid>

                                    </Grid>

                                    <Grid item container spacing={1} alignItems='center'>
                                        <Grid item>
                                            <Typography variant='body2'>
                                                Lastname:
                                            </Typography>
                                        </Grid>

                                        <Grid item>
                                            <Typography variant='body2' className={classes.contactTextStyle}>
                                                {contactObj.last_name}
                                            </Typography>
                                        </Grid>

                                    </Grid>
                                    <Grid item container spacing={1} alignItems='center'>
                                        <Grid item>
                                            <Typography variant='body2'>
                                                Email:
                                            </Typography>
                                        </Grid>

                                        <Grid item>
                                            <Typography variant='body2' className={classes.contactTextStyle}>
                                                {contactObj.email}
                                            </Typography>
                                        </Grid>

                                    </Grid>
                                    <Grid item container spacing={1} alignItems='center'>
                                        <Grid item>
                                            <Typography variant='body2'>
                                                Mobile:
                                            </Typography>
                                        </Grid>

                                        <Grid item>
                                            <Typography variant='body2' className={classes.contactTextStyle}>
                                                {contactObj.phone_number}
                                            </Typography>
                                        </Grid>

                                    </Grid>
                                    <Grid item container spacing={1} alignItems='center'>
                                        <Grid item>
                                            <Typography variant='body2'>
                                                Title:
                                            </Typography>
                                        </Grid>

                                        <Grid item>
                                            <Typography variant='body2' className={classes.contactTextStyle}>
                                                {contactObj.title}
                                            </Typography>
                                        </Grid>

                                    </Grid>
                                </Grid>
                    })
                }
            </Grid>
        </div>
    );
}

export default MobileClientContacts;