import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {Grid, CircularProgress} from '@material-ui/core';
import {getGmailOutlookRefeshTokenApi} from '../services/genieService';
import { consoleToLog, trackGAEvent } from '../util/AppUtil';
import { useSnackbar } from 'notistack';
import {updateOrgEmail, setSelectedOrgEmailObj, setCurrentEmailState, checkTemplateRules, setIsEmailSendError} from '../actions/selectedOrganization';
import {history} from '../routers/AppRouter';

const VerifySupportEmailPage = (props) => {
    const {enqueueSnackbar} = useSnackbar();

    useEffect(()=>{
        let queryParams = getQueryParams(document.location.search);
        console.log("hi", queryParams);
        //console.log(queryParams.code);
        const code = queryParams.code;
        console.log("display: ", code);
        if(code) callRefreshToken(code);
        else consoleToLog("No auth code found")
    },[]);

    const callRefreshToken = (code) => {
        consoleToLog("hi, code: ", code);
        const apiStringPath = props.selectedOrgEmailObj?.email_type === 'gmail' ? 'verify' : 'getOutlookToken';
        const organization_email_id = props.selectedOrgEmailObj?.organization_email_id ? props.selectedOrgEmailObj.organization_email_id : undefined;
        const redirect_uri = props.selectedOrgEmailObj?.email_type === 'gmail' ? process.env.REACT_APP_GMAIL_REDIRECT_URL : process.env.REACT_APP_OUTLOOK_REDIRECT_URL;
        getGmailOutlookRefeshTokenApi(code, apiStringPath , props.selectedOrganization.organization.id, organization_email_id, redirect_uri)
            .then((response) => {
                const res = response.data;
                
                trackGAEvent(props.selectedOrganization.organization.name, `${props.selectedOrgEmailObj?.email} connected`, `${props.auth.user.firstname} ${props.auth.user.lastname}`);

                console.log("Response get Org email Obj", res);
                if(organization_email_id){
                    enqueueSnackbar('Successfully reconnected!', {variant:'success'});
                }
                else{
                    enqueueSnackbar('Email added successfully and verification email sent. Click link on verification email to verify support email', {variant:'success'});
                }
                props.selectedOrgEmailObj?.fromOnboardingComponent ? 
                history.push('/onboarding')
                :
                history.push('/dashboard');
                props.isEmailSendError && props.setSelectedOrgEmailObj(undefined);
                if(props.currentEmailState) {
                    props.currentEmailState.selectedEmailId = res.orgEmail;
                }
                props.currentEmailState && props.setCurrentEmailState(props.currentEmailState);
                props.checkTemplateRules(props.selectedOrganization.organization.id);
                
            })
            .catch((e) => {
                consoleToLog("error", e);
                enqueueSnackbar(e.response.data.message, { 
                    variant: 'error',
                    });
                    props.selectedOrgEmailObj?.fromOnboardingComponent ? 
                    history.push('/onboarding')
                    :
                    history.push('/dashboard');
            });
    }

    const getQueryParams = (qs) => {
        qs = qs.split('+').join(' ');
    
        var params = {},
            tokens,
            re = /[?&]?([^=]+)=([^&]*)/g;
    
        while (tokens = re.exec(qs)) {
            params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
        }
    
        return params;
    }

    return (
        <div>
            <Grid item container 
                style={{marginTop:'18px'}}
                justifyContent='center' 
                alignItems='center'>
                <CircularProgress size={25}/>
            </Grid>
            
        </div>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    orgEmailList: state.organizationInfo.orgEmailList,
    selectedOrgEmailObj: state.organizationInfo.selectedOrgEmailObj,
    currentEmailState: state.organizationInfo.currentEmailState,
    isEmailSendError: state.organizationInfo.isEmailSendError
});

const mapDispatchToProps = (dispatch) => ({
    updateOrgEmail: (email) => dispatch(updateOrgEmail(email)),
    setSelectedOrgEmailObj: () => dispatch(setSelectedOrgEmailObj()),
    setCurrentEmailState: (emailState) => dispatch(setCurrentEmailState(emailState)),
    checkTemplateRules: (organization_id) => dispatch(checkTemplateRules(organization_id)),
    setIsEmailSendError: (emailSendError) => dispatch(setIsEmailSendError(emailSendError))

})

export default connect(mapStateToProps, mapDispatchToProps)(VerifySupportEmailPage);