import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import { Drawer, Grid, Typography, Button } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles((theme) => ({
    drawerPaper: {
        backgroundColor: theme.palette.primary.light,
        width: '100%', 
        height: 'calc(100vh - 51px)',
        flexGrow:1,
        overflowY:'auto',
        overflowX:'hidden',
    },
    backIcon: {
        color: "#202020",
        border: " 1px solid #202020",
        padding: "4px 0px",
        fontSize: "18px",
        minWidth: "40px",
        lineHeight: "18px",
        marginBottom: "10px",
        marginRight: "16px",
    },
    mobileHeading: {
        fontSize: '18px',
        fontWeight: 500,
        marginTop:'-8px'
    }
}))

const MobileRightToLeftSideDrawer = (props) => {
    const classes = useStyles();

    return (
        <Drawer variant="persistent"
            anchor="right"
            open={props.openDrawer}
            onClose={props.drawerClose}
            classes={{
                paper: classes.drawerPaper
            }}>
                <Grid item xs={12} style={{padding: !props.fromChecklistReports && '16px'}}>
                    <Grid item container alignItems='center' style={{padding: props.fromChecklistReports && '16px'}}>
                        <Grid item>
                            <Button
                                variant="outlined"
                                className={classes.backIcon}
                                onClick={props.drawerClose}
                            > 
                                <ArrowBackIcon /> 
                            </Button>
                        </Grid>

                        <Grid item>
                            <Typography variant="body1" 
                                className={classes.mobileHeading}>
                                {props.title}
                            </Typography>
                        </Grid>
                    </Grid>
                
                    <div>
                        {props.children}
                    </div>
                </Grid>
        </Drawer>
    );
}

export default MobileRightToLeftSideDrawer;