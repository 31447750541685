import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Button, FormControlLabel, Checkbox} from '@material-ui/core';
import MobileDurationFilter from './MobileDurationFilter';
import ChecklistStatusFilters from '../../components/filters/ChecklistStatusFilters';
import {connect} from 'react-redux';
import {clearSelectedFilters, clearSelectedMembersFilter, setShowUnassignedChecklist} from '../../actions/filters';
import ChecklistMembersFilter from '../../components/filters/ChecklistMembersFilter';
import ChecklistLabelsFilter from '../../components/filters/ChecklistLabelsFilter';
import ChecklistCompaniesFilter from '../../components/filters/ChecklistCompaniesFilter';

const useStyles = makeStyles((theme) => ({
    mainStyles: {
        width:'100%',
        marginTop: '16px',
        padding:'8px 0px 8px 0px',
        overflow: "auto !important", 
        scrollbarWidth: 'none',
        position:'relative',
        "&::-webkit-scrollbar": {
            display: "none"
            }
    },
    filterButtonStyle: {
        display: 'flex', 
        position:'fixed',
        bottom: 0,
        left: 0,
        right:0,
        padding: '12px 0px 12px 12px',
        zIndex: '15',
        background: '#fff',
        borderTop: '1px solid #ccc'
    }
    
}))

const MobileChecklistFilter = (props) => {
    const classes = useStyles();

    const handleChange = (e) => {
        console.log("vikas");
        const value = !props.showUnassignedChecklist;
        if (value) props.clearSelectedMembersFilter();//clear member filter
        props.setShowUnassignedChecklist(value);//store filter
        props.pageReset();
    }

    return (
        <div className={classes.mainStyles}>
            <MobileDurationFilter pageReset={props.pageReset}/>

            <ChecklistStatusFilters isFromMobileComponent={true} 
                pageReset={props.pageReset}/>
            
            <FormControlLabel
                style={{marginTop:'8px'}} 
                control={
                <Checkbox checked={props.showUnassignedChecklist}
                    onChange={handleChange}/>
                } 
                label="Unassigned Checklist"/>
            
            <ChecklistMembersFilter isFromMobileComponent={true} 
                pageReset={props.pageReset}/>
            
            <ChecklistLabelsFilter isFromMobileComponent={true}
                pageReset={props.pageReset}/>
            
            <ChecklistCompaniesFilter isFromMobileComponent={true}
                pageReset={props.pageReset}/>

            <div className={classes.filterButtonStyle}>
                <Button variant="contained" color="primary" 
                    fullWidth
                    className="modal__button"
                    onClick={() =>{
                        //trackGAEvent(props.selectedOrganization.organization.name, 'Filter Reset', `${props.auth.user.firstname} ${props.auth.user.lastname} reset filter`);
                        props.clearSelectedFilters();
                        props.pageReset();
                        }
                    }>
                    Reset Filters
                </Button>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    showUnassignedChecklist: state.filters.showUnassignedChecklist,
    isInvoiceAccessor: state.organizationInfo.isInvoiceAccessor,
    selectedStatusFilter: state.filters.selectedStatusFilter
});

const mapDispatchToProps = (dispatch) => ({
    clearSelectedFilters: () => dispatch(clearSelectedFilters()),
    clearSelectedMembersFilter: () => dispatch(clearSelectedMembersFilter()),
    setShowUnassignedChecklist: (showUnassignedChecklist) => dispatch(setShowUnassignedChecklist(showUnassignedChecklist)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileChecklistFilter);