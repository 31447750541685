import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles'; 
import { Grid, Container, Typography,  Button, Popover, Tooltip, AppBar, Tabs, Tab, Box ,CircularProgress} from '@material-ui/core';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import { addOrgMember } from '../actions/selectedOrganization';
import { DateRangePicker } from "materialui-daterange-picker";
import moment from 'moment';
import AutocompleteTemplate from './AutocompleteTemplate';
import { useIsMount } from './useIsMount';
import { consoleToLog, trackGAEvent } from '../util/AppUtil';
import { getStatusWorkLoadReport, getPendingWorkLoadReport, getUserPendingChecklistApi } from '../services/reportService';
import Chart from 'react-apexcharts';
import {PieChartData, StackedBarData} from "../data/ChartsData.json";
import RightToLeftSideDrawer from './RightToLeftSideDrawer';
import SwipeableViews from 'react-swipeable-views';
import ChecklistReportsDrawer from './ChecklistReportsDrawer';

const useStyles = makeStyles((theme) => ({
    mainContent : {
        width: (showDrawer) => {
            return showDrawer ? "83%" : "100%"; 
        },
        backgroundColor: theme.palette.primary.light, 
        transition: ".5s", 
        flexGrow: 1,
        //overflow:'hidden',
        overflow:'auto',
        overflowX:'hidden'
        },
    
    container: {
        marginTop: "8px"
    },
    graphMain: {
        padding: "1rem !important",
        fontSize: "11px !important",
        marginTop: "1rem !important",
        boxShadow: "0 .5rem 1rem rgba(0,0,0,.15) !important",
        width: "32rem !important",
        height: "21rem !important",
        marginLeft: "40px !important",
        marginBottom: "50px"
    },

    icons : {
        color: "black",
        fontSize: "18px",
        minWidth:'30px',
        marginLeft: "8px",
        marginTop:'-3px'
    } ,
    notAttachmentsImage: {
        height: "13rem",
        width: "15rem",
        marginTop: "3rem",
      },
      notAttachmentsText: {
        fontSize: "20px",
        fontWeight: "600",
      },
      notAttachmentsButton: {
        fontSize: "15px",
        width: "19rem",
        marginTop: "20px",
      },
      tabsAppbar: {
        boxShadow: "none",
        backgroundColor: "white",
        borderBottom: "1px solid #c5b9b9",
      }   
    })
    
)

const WorkLoadReports = (props) => {
    const {showDrawer} = props;
    const classes = useStyles(showDrawer);
    const { enqueueSnackbar } = useSnackbar();
    const [anchorEl, setAnchorEl] = useState(false);
    const [dateRange, setDateRange] = useState({});
    const [startDateFilter, setStartDateFilter] = useState("");
    const [endDateFilter, setEndDateFilter] = useState("");
    const [clearFilter, setClearFilter] = useState(false);
    const [selectedTemplates, setSelectedTemplates] = useState();
    const [pendingData, setPendingData] = useState([]);
    const [statusData, setStatusData] = useState([]);
    
    const [openDrawer, setOpenDrawer] = useState(false);
    const [openFilters, setOpenFilters] = useState(false);
    const [workloadReportsLoading,setWorkloadReportsLoading] = useState(false);
    const [value, setValue] = useState(0);

    const [openChecklistDrawer, setOpenChecklistDrawer] = useState(false);
    const [checkList, setCheckList] = useState([]);
    const [checklistLoading, setChecklistLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [isMoreLoading, setIsMoreLoading] = useState(false);
    const [nextPage, setNextPage] = useState(false);
    const [tempObj, setTempObj] = useState(undefined);
    const [userName, setUserName] = useState(undefined);


    const isMount = useIsMount(); 

    const checklistDetails = window.location.toString().includes("checklist");

    useEffect(() => {
        document.title = `Workload Reports - ${props.fullName}`;
    }, []);

    useEffect(() => {
        if(!isMount) {
            if(!checklistDetails) {
                document.title = `Workload Reports - ${props.fullName}`;
            }
        }
    }, [checklistDetails]);

    //Last Month
    var prevMonthFirstDay = new moment().subtract(1, 'months').date(1)
        
    //for next month last day
    var thisMonthLastDay = new moment().add(1, 'months').date(0);

    //for last day of previous month
    var lastdayLastMonth = moment(lastdayLastMonth).subtract(1,'months').endOf('month').format('YYYY-MM-DD');

    let currentFinancialYearStart;
    let currentFinancialYearEnd;

    currentFinancialYearStart = (moment().month() < 3) ? moment().subtract(1, 'year').month('April').startOf('month').format('YYYY-MM-DD')
    : moment().month('April').startOf('month').format('YYYY-MM-DD');

    currentFinancialYearEnd =  (moment().month() < 3) ? moment().month('March').endOf('month').format('YYYY-MM-DD')
    : moment().add(1, 'year').month('March').endOf('month').format('YYYY-MM-DD');

    let lastFinancialYearStart = moment(currentFinancialYearStart).subtract(1, 'year').format('YYYY-MM-DD');
    let lastFinancialYearEnd = moment(currentFinancialYearEnd).subtract(1, 'year').format('YYYY-MM-DD');



    const openDateRangePicker = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const open = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onUpdateClearFilterValue = () => {
        setClearFilter(false);
    }

    const onSelectedTemplates = (sts) => {
        setSelectedTemplates(sts);
    }

    //api
    const getWorkLoadReport = (newValue) => {
        consoleToLog("Index", newValue);
        consoleToLog("Api: SelectedTemplate", selectedTemplates);
        consoleToLog("Api: startDateFilter", startDateFilter);
        consoleToLog("Api: endDateFilter", endDateFilter);

        const organization_id = props.selectedOrganization.organization.id;
        if(!selectedTemplates){
            setPendingData([]);
            setStatusData([]) 
            return;
        }
        if(!startDateFilter || startDateFilter.length === 0) {
            setPendingData([]); 
            setStatusData([])
            return;
        };
        if(!endDateFilter || endDateFilter.length === 0){
            setPendingData([]); 
            setStatusData([])
            return;
        };

        //const ids = selectedTemplates.map((l) => l.template_id).join(',');
        
        if(newValue === 1){
        setWorkloadReportsLoading(true);
        getStatusWorkLoadReport(selectedTemplates.template_id, startDateFilter, endDateFilter, organization_id)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response getStatusWorkLoadReport: ", res);
                /* enqueueSnackbar(res.message, {
                    variant:'success'
                }); */
                trackGAEvent(props.selectedOrganization.organization.name, 'Filter StatusWorkLoad Report', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
                setStatusData(res);
                setWorkloadReportsLoading(false);
            })
            .catch((e) => {
                consoleToLog("getStatusWorkLoadReport error", e.response);
                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant:'error'
                    });
                    return false;
                }
                setWorkloadReportsLoading(false);
            });
        }
        else{
            setWorkloadReportsLoading(true);
            getPendingWorkLoadReport(selectedTemplates.template_id, startDateFilter, endDateFilter, organization_id)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response getPendingWorkLoadReport: ", res);
                /* enqueueSnackbar(res.message, {
                    variant:'success'
                }); */
                trackGAEvent(props.selectedOrganization.organization.name, 'Filter PendingWorkLoad Report', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
                setPendingData(res);
                setWorkloadReportsLoading(false);
            })
            .catch((e) => {
                consoleToLog("getPendingWorkLoadReport error", e.response);
                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant:'error'
                    });
                    return false;
                }
                setWorkloadReportsLoading(false);
            });   
        }
    }

    useEffect(() => {
        if(!isMount) {
            consoleToLog("WorkLoadReports page on Templates change- in useeffect");
            getWorkLoadReport(value);
        }
    }, [selectedTemplates, startDateFilter, endDateFilter]);

    useEffect(() => {
        if(!isMount) {
            if(page === 1 && tempObj) {
                callGetUserPendingChecklistApi();
            }
        }
    }, [userName, tempObj, page]);

    useEffect(() => {
        if(!isMount) {
            if(isMoreLoading) {
                callGetUserPendingChecklistApi();
            }
        }
    }, [isMoreLoading]);

    const toggleDrawer = () => {
        setOpenDrawer(open => !open)
        setAnchorEl(null);
    }

    const setDrawerOpen = () => {
        setOpenDrawer(false);
        setOpenFilters(false);
    }

    const pageReset = () => {
        setPage(1);
        setNextPage(false);
    }

    const ReportsNotFound = () => {
        return (
          <div>
            {/* Not Found*/}
            <Grid container direction="row">
              <Grid
                item
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
                sm={12}
              >
                <img
                  alt="No Reports Found"
                  src="/images/no_attachment_found.svg"
                  className={classes.notAttachmentsImage}
                />
              </Grid>
              <Grid
                item
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <Typography className={classes.notAttachmentsText}>
                  No Reports Found, You need to select all filters
                </Typography>
              </Grid>
              <Grid
                item
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                <Button
                  variant="contained"
                  color="primary"
                  //endIcon={<PublishIcon />}
                  size="large"
                  className={classes.notAttachmentsButton}
                  onClick={()=>{
                    toggleDrawer();
                    setOpenFilters(!openFilters);
                  }}
                >
                  Add Filters
                </Button>
              </Grid>
            </Grid>
            {/* Not Found*/}
          </div>
        );
      };

      const TabPanel = (props) => {
        const { children, value, index, ...rest } = props;
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...rest}
          >
            {value === index && (
              <Box p={2}>
                <Typography>{children}</Typography>
              </Box>
            )}
          </div>
        );
      };
    
      const a11yProps = (index) => {
        return {
          id: `full-width-tab-${index}`,
          "aria-controls": `full-width-tabpanel-${index}`,
        };
      };
    
    const handleChange = (event, newValue) => {
        setValue(newValue);
        //consoleToLog("index", newValue);
        getWorkLoadReport(newValue);
    };
    
    const handleChangeIndex = (index) => {
        setValue(index);
    };

    const callGetUserPendingChecklistApi = () => {
        const organization_id = props.selectedOrganization.organization.id;
        const start_date = startDateFilter ? startDateFilter : '';
        const end_date = endDateFilter ? endDateFilter : ''; 
        const userObjArr = tempObj?.data.filter((td) =>  td.name === userName);
        const user_id = userObjArr[0].user_id; 
        const template_id = tempObj ? tempObj.template_id !== -1 ? tempObj.template_id : undefined :  ''; 

        if(!isMoreLoading) setChecklistLoading(true);
        getUserPendingChecklistApi(organization_id, page, start_date, end_date, user_id, template_id)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response getUserPendingChecklistApi: ', res);
                setChecklistLoading(false);

                if(isMoreLoading) {
                    const newItems = checkList.concat(res.checklist);
                    setCheckList(newItems);
                    setPage(page + 1);
                    setNextPage(res.next_page);
                    setIsMoreLoading(!isMoreLoading);
                } else {
                    setNextPage(res.next_page);
                    if(res.next_page) setPage(page + 1);
                    setCheckList(res.checklist);
                }

            })
            .catch((e) => {
                consoleToLog("Error getUserPendingChecklistApi: ", e.response);
                setChecklistLoading(false);
                if(e.response.data && e.response.data.message) {
                    return;
                }
            })
    }

    const handleChecklistDrawerOpen = () => {
        setOpenChecklistDrawer(true);
    }

    const handleChecklistDrawerClose = () => {
        setOpenChecklistDrawer(false);
    }

    const PendingComponent = () => {
        return(
            <>
            {(pendingData && pendingData.length > 0) ? 
                pendingData.map((templateData, index)=>{
                    var seriesArr = templateData && templateData.data.length>0 
                                && templateData.data.map((td) => {return td.pending_count});

                    var nameArr = templateData && templateData.data.length>0 
                    && templateData.data.map((td) => {return td.name});

                    var options1 = {...PieChartData.options, labels: nameArr}

                    return (
                        templateData && templateData.data.length > 0 ? 
                        <div key={templateData.template_id+"_"+index}
                            style={{padding: "0px 0", marginTop: "0px", fontWeight: "bold", background: "white", marginBottom: "50px"}}>
                            {/* <Typography variant="body" style={{marginLeft: "20px"}}>
                                {templateData.template_name}
                            </Typography> */}
                            <Grid container justifyContent="center">
                                <Grid key={index} item lg={4}>
                                    <Chart options={{
                                                series:[3,5],
                                                chart:{
                                                    width:300,
                                                    type: "pie"
                                                },
                                                colors:[
                                                    "#00cf95",
                                                    "#0291ff",
                                                    "#FF5733",
                                                    "#1B4F72",
                                                    "#DC7633",
                                                    "#F7DC6F",
                                                    "#52BE80",
                                                    "#A93226",
                                                    "#273746",
                                                    "#B7950B"
                                                ],
                                                plotOptions:{
                                                    pie:{
                                                        customScale:0.7
                                                    }
                                                },
                                                legend:{
                                                    show:false
                                                },
                                                labels: nameArr,
                                                responsive:[
                                                {
                                                    breakpoint:480,
                                                    options:{
                                                    chart:{
                                                        width:200
                                                    },
                                                    legend:{
                                                        position:"bottom"
                                                    }
                                                    }
                                                }
                                                ],
                                                chart: {
                                                    events: {
                                                        dataPointSelection: (event, chartContext, config) => {
                                                                let user = config.w.config.labels[config.dataPointIndex];
                                                                setUserName(user);
                                                                setTempObj(templateData);
                                                                pageReset();
                                                                handleChecklistDrawerOpen();
                                                            },
                                                        
                                                        }
                                                    }
                                                }} 
                                        series={seriesArr} 
                                        type="pie" 
                                        height={400} />
                                    <Typography variant="subtitle1" style={{fontWeight: "bold", textAlign: "center", marginTop: "-25px"}}>
                                        {templateData.template_name}
                                    </Typography> 
                                </Grid>
                            
                            </Grid>
                    </div>
                    :
                    <ReportsNotFound /> 
                
                    )
                })  : <ReportsNotFound /> 
                }
            </>
        )
      }

      const getStatusSeriesArray = (statusData, status) => {
        var statusArray = [];
        var name = ""
        if(status === "pending") name = "Pending";
        else if(status === "in_process") name = "In Process";
        else if(status === "awaiting_reply") name = "Awaiting Reply";
        else if(status === "completed") name = "Completed";
        else if(status === "pending_approval") name = "Pending Approval";

        //consoleToLog("statusData", statusData);

        for(var i=0; i< statusData.length ; i++){
            for(var j=0; j< statusData[i].data.length ; j++){
                if(statusData[i].data[j].status === status){
                    statusArray.push(statusData[i].data[j].count)
                    break;
                }
            }
        }
        return {name: name, data: statusArray};
      }

      const StatusComponent = ()=> {
          const pending=getStatusSeriesArray(statusData, "pending");
          const in_process=getStatusSeriesArray(statusData, "in_process");
          const awaiting_reply=getStatusSeriesArray(statusData, "awaiting_reply");
          const pending_approval=getStatusSeriesArray(statusData, "pending_approval");
          const completed=getStatusSeriesArray(statusData, "completed");
           
          const categories = statusData.map((data)=> data.name);
          const xaxis = {categories: categories}
          const options2={...StackedBarData.options, xaxis: xaxis, dataLabels: {
            formatter: function(val, opt) {
                return val !== 0 ? val : ''
            }
        }} 
          
          /* consoleToLog("pending",pending)
          consoleToLog("in_process",in_process)
          consoleToLog("awaiting_reply",awaiting_reply)
          consoleToLog("pending_approval",pending_approval)
          consoleToLog("completed",completed) */
        return(
            <>
            {
                    <div
                        style={{padding: "0px 0", marginTop: "0px", fontWeight: "bold", background: "white", marginBottom: "50px"}}>
                        {/* <Typography variant="body" style={{marginLeft: "20px"}}>
                            {templateData.template_name}
                        </Typography> */}
                        <Grid container justifyContent="left">
                            <Grid item lg={4}>
                                <Chart options={options2}
                                    series={[pending, in_process, awaiting_reply, pending_approval, completed]} 
                                    type="bar" 
                                    width={500} />
                                    {/* <Typography variant="subtitle1" style={{fontWeight: "bold", textAlign: "center", marginTop: "-35px"}}>
                                    {templateData.template_name}
                                </Typography>  */}
                            </Grid>
                            
                        </Grid>
                </div>
                }
            </>
        )
    }

    return (
        <div className={classes.mainContent}
            style={{height: props.height}}>
            <Container className={classes.container} maxWidth="xl">
                <div style={{display: "flex"}}>
                    <Typography style={{fontSize:'20px', fontWeight:'600', marginBottom: "10px"}} color="primary">
                        Workload Report
                    </Typography>
                    <Tooltip title="Filters" arrow>
                        <Button aria-label="delete"  
                            className={classes.icons}
                            //style={{marginLeft: "auto"}}
                            onClick={() => {
                                toggleDrawer();
                                setOpenFilters(!openFilters);
                            }}
                            >
                            <i className={(selectedTemplates && startDateFilter.length > 0 && endDateFilter.length > 0) ? `flaticon-filter active` : `flaticon-filter`}></i>
                            {   (selectedTemplates && startDateFilter.length > 0 && endDateFilter.length > 0) ?
                                <div className='filter__dot'></div> : <div></div>
                            }
                        </Button>
                    </Tooltip>
                </div>
                    <div style={{padding: "12px 0", marginTop: "10px", fontWeight: "bold", background: "white", marginBottom: "50px"}}>
                        <AppBar
                            position="static"
                            color="default"
                            className={classes.tabsAppbar}
                        >
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                indicatorColor="secondary"
                                textColor="secondary"
                                style={{ paddingLeft: "15px" }}
                                aria-label="full width tabs example"
                                >
                                <Tab
                                    label="Pie Chart"
                                    className={classes.tabsMain}
                                    {...a11yProps(1)}
                                />
                                <Tab
                                    label="Bar Chart"
                                    className={classes.tabsMain}
                                    {...a11yProps(1)}
                                />
                            </Tabs>
                        </AppBar>
                        { workloadReportsLoading ?
                            <Grid item container>
                                <Grid item lg={12}>
                                    <CircularProgress size={35} style={{position:'relative', left:'50%', right:'50%', margin:'16px 0px'}}/>
                                </Grid>
                            </Grid>
                        :
                            <SwipeableViews index={value} onChangeIndex={handleChangeIndex}>
                                <TabPanel value={value} index={0}>
                                    <PendingComponent />
                                </TabPanel>
                                <TabPanel value={value} index={1}>
                                    {statusData && statusData.length>0 ?  <StatusComponent /> : <ReportsNotFound />}
                                </TabPanel>
                            </SwipeableViews>
                    }
                    </div>
            </Container>    
            <RightToLeftSideDrawer openDrawer={openDrawer} 
                closeDrawer={setDrawerOpen}
                title="Report Filters"
                createChecklist={false}
            >
            <div>
                <Grid item style={{marginLeft: "12px"}}>
                    <Grid item container>
                        <Grid item >
                            <Typography
                                variant="body1"
                                style={{lineHeight:'42px', color: '#888888'}}
                            >
                            Filter By Date
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item container style={{marginTop:'-6px'}}>
                        <Grid item>
                        <Button variant='outlined' 
                                color='primary' 
                                style={{fontWeight:"300 !important"}}
                                onClick={openDateRangePicker}>
                                {(startDateFilter.length>0 && endDateFilter.length>0) ? 
                                    `${moment(startDateFilter).format('DD-MM-YYYY')} - 
                                    ${moment(endDateFilter).format('DD-MM-YYYY')}` : "Start Date - End Date"}
                        </Button>
                        <Popover
                            id='daterange-popover'
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center"
                            }}
                            transformOrigin={{
                            vertical: "top",
                            horizontal: "center"
                            }}
                            >
                                <DateRangePicker
                                    open={true}
                                    toggle={handleClose}
                                    onChange={(range) => {
                                        setDateRange(range);
                                        setStartDateFilter(moment(range.startDate).format('YYYY-MM-DD'))
                                        setEndDateFilter(moment(range.endDate).format('YYYY-MM-DD'))
                                        setClearFilter(false);
                                    }}
                                    style={{
                                    width:'50%'
                                    }}
                                    initialDateRange={{
                                        startDate:  startDateFilter,
                                        endDate: endDateFilter
                                    }}
                                    definedRanges={[
                                    {
                                        label: `${moment(lastFinancialYearStart, 'YYYY-MM-DD').format('MMM-YYYY')}-${moment(lastFinancialYearEnd, 'YYYY-MM-DD').format('MMM-YYYY')}`,
                                        startDate: lastFinancialYearStart,
                                        endDate: lastFinancialYearEnd
                                    },
                                    {
                                        label: `${moment(currentFinancialYearStart, 'YYYY-MM-DD').format('MMM-YYYY')}-${moment(currentFinancialYearEnd, 'YYYY-MM-DD').format('MMM-YYYY')}`,
                                        startDate: currentFinancialYearStart,
                                        endDate: currentFinancialYearEnd
                                    },
                                    {
                                        label: 'Last Month',
                                        startDate: prevMonthFirstDay,
                                        endDate: lastdayLastMonth
                                    },
                                    {
                                        label: 'This Month',
                                        startDate: new Date(),
                                        endDate: thisMonthLastDay
                                    }
                                    ]}
                                />
                            </Popover>
                        </Grid>
                    </Grid>   
                </Grid>    
                <Grid item style={{marginLeft: "12px",marginTop:'6px'}}>
                    <Grid item container>
                        <Grid item >
                            <Typography
                                variant="body1"
                                style={{lineHeight:'42px', color: '#888888'}}
                            >
                            Select Templates
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item container style={{marginTop:'-16px'}}>
                        <Grid item md={5} style={{flexBasis:'45%', maxWidth:'45%'}}>
                        <AutocompleteTemplate 
                            clearFilter={clearFilter}
                            onUpdateClearFilterValue={onUpdateClearFilterValue} 
                            onSelectedTemplates={onSelectedTemplates} 
                            singleTemplateSelect={true}
                            selectedTemplates={selectedTemplates}
                        />
                        </Grid>
                    </Grid>   
                </Grid>
                <Grid item style={{marginLeft: "12px", marginTop: "18px"}}>
                <Button variant="contained" 
                    color="primary" 
                    style={{fontWeight:"300 !important"}}
                    className="modal__button"
                    onClick={()=>{
                        setClearFilter(true);
                        setDateRange();
                        setStartDateFilter("");
                        setEndDateFilter("");
                        setStatusData([]); 
                        setPendingData([]);
                        setSelectedTemplates();
                    }}>
                    Clear Filters
                </Button>
                </Grid>
            </div>
        </RightToLeftSideDrawer>

        <ChecklistReportsDrawer openChecklistDrawer={openChecklistDrawer}
            handleChecklistDrawerClose={handleChecklistDrawerClose}
            checklistLoading={checklistLoading}
            checkList={checkList}
            tempObj={tempObj}
            nextPage={nextPage}
            isMoreLoading={isMoreLoading}
            setIsMoreLoading={setIsMoreLoading}
            handleSendEmailDialogOpen={props.handleSendEmailDialogOpen}/>
    </div>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    members: state.organizationInfo.members
});

const mapDispatchToProps = (dispatch) => ({
    addOrgMember: (member) => dispatch(addOrgMember(member))
})

export default connect(mapStateToProps, mapDispatchToProps)(WorkLoadReports);