import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Grid, Button, Typography, Divider, CircularProgress, Link, Dialog, DialogContent, Tooltip} from '@material-ui/core';
import {ApprovalStatus, consoleToLog, ApprovalTypes, trackGAEvent, approvalStatus} from '../../util/AppUtil';
import moment from 'moment';
import {connect} from 'react-redux';
import {approveRejectCancelApi} from '../../services/ChecklistService';
import { downloadAttachmentApi } from '../../services/authService';
import {undoApproveRejectApi} from '../../services/ChecklistService';
import { useSnackbar } from 'notistack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { useIsMount } from '../useIsMount';

const useStyles = makeStyles((theme) => ({
    approvalListContainer: {
        background:'#F9F9F9',
        marginBottom:'8px',
        color:'#555555'
    },
    taskStyle: {
        fontSize:'16px',
        color:"#0071FF",
        textDecoration:"underline",
        marginBottom:'8px'
    },
    userApproverStyle :{
        display:'flex',
        alignItems: 'center'
    },
    userApproverNameStyle: {
        fontSize:"16px",
        fontWeight:600
    },
    forwardArrowStyle: {
        fontSize:'24px',
        margin:"0px 8px",
        position:"relative",
    },
    customColumn1: {
        flexBasis:"78%",
        maxWidth:"78%"
    },
    customColumn2: {
        flexBasis:"21%",
        maxWidth:"21%"
    },
    approverBtnStyle: {
        background:"#72bb53",
        color:"#fff",
        width:'100px',
        '&:hover': {
            background:'#539433'
        }
    },
    rejectBtnStyle: {
        color:"#DB4C3F",
        width:'100px',
        marginTop:'8px',
        border:'1px solid #DB4C3F',
        '&:hover': {
            background:'rgba(0,0,0,0.1)'
        }
    },
    undoBtnStyle: {
        color:"#333",
        width:'120px',
        border:'1px solid #333'
    },
    customMarginTop: {
        marginTop:'8px'
    },
    linkStyle: {
        color:'#0071FF',
        fontSize:'13px',
        cursor:"pointer",
        marginLeft:'16px',
        border:'1px solid #0071FF',
        padding:'1px 10px',
        borderRadius:'4px',
        display:'flex',
        alignItems:'center'
    },
    customMarginLeft: {
        position:'relative',
        marginLeft:'16px',
    },
    cancelButton: {
        color:"#DB4C3F",
        width:'120px',
        border: '1px solid #DB4C3F'
    },
    MuiDialogpaper: {
        scrollbarWidth: 'none !important',
    },
    dialogActionImg: {
        width:'60px',
        marginBottom:'8px',
        display:'flex',
        justifyContent:'center'
    },
    dailogHeading: {
        fontSize:'21px',
        fontWeight:'600',
        marginBottom:'8px',
        textAlign:'center'
    },
    yesButtonStyle: {
        borderRadius: '5px',
        background: '#888',
        color:'#fff',
        width:'110px',
        '&:hover': {
            background:'rgba(0,0,0,0.3)'
        }
    },
    noButtonStyle: {
        borderRadius: '5px',
        background: '#3B961B',
        color:'#fff',
        width:'110px',
        '&:hover': {
            background:'#318415'
        }
    },
    customColumn3: {
        flexBasis:'6%', 
        maxWidth:'6%'
    },
    customColumn4: {
        flexBasis:'75%', 
        maxWidth:'75%'
    },
    customColumn5: {
        flexBasis:'7%', 
        maxWidth:'7%'
    },
    customColumn6: {
        flexBasis:'74%', 
        maxWidth:'74%'
    },
    textEllipsis: {
        maxWidth:'260px',
        whiteSpace:'nowrap',
        overflow:'hidden',
        textOverflow:'ellipsis',
        cursor:'pointer',
        [theme.breakpoints.down(1450)] : {
            maxWidth:'220px',
        },
        [theme.breakpoints.down(1300)] : {
            maxWidth:'190px',
        }
    }
}))

const ApprovalItem = (props) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    const isMount = useIsMount();

    const [openActionDialog, setOpenActionDialog] = useState(false);
    const [approvalObj, setApprovalObj] = useState(undefined);
    const [actionText, setActionText] = useState('');
    const [allowApiCall, setAllowApiCall] = useState(false);
    const [approvalObj1, setApprovalObj1] = useState(undefined);

    useEffect(() => {
        if(!isMount) {
            if(allowApiCall) {
                if(approvalObj) {
                    callApproveRejectCancelApi();
                } else {
                    onUndoApproveRejectClick();
                }
            }
        }
    }, [allowApiCall])

    let item = props.item;
    let statusObj = approvalStatus(item.approval_status);
    const assignerUserName = item.assigner_user?.firstname + ' ' + item.assigner_user?.lastname;
    const approverUserName = item.approver_user?.firstname + ' ' + item?.approver_user?.lastname;
    const approverOrMember = item.approver_user?.id === props.auth.user.id;
    const cancelledUser = item.cancellor_user?.firstname + ' ' + item.cancellor_user?.lastname;

    const showDateDifference = (appObj) => {
        const approvalCreationDate = appObj.sent_timestamp;
        const approveRejectDate = appObj.approve_reject_timestamp
        const cancelledDate = appObj.cancelled_timestamp;
        const todaysDate = moment().format('DD-MM-YYYY');

        switch (appObj.approval_status) {
            case ApprovalStatus.PENDING:
                return `(${moment.utc(approvalCreationDate).fromNow()})`
            case ApprovalStatus.APPROVED:
                return `(within ${moment.utc(approveRejectDate).fromNow(approvalCreationDate)})`
            case ApprovalStatus.REJECTED:
                return `(within ${moment.utc(approveRejectDate).fromNow(approvalCreationDate)})`
            case ApprovalStatus.CANCELLED:
                return `(within ${moment.utc(cancelledDate).fromNow(approvalCreationDate)})`
        }
    }

    const returnVal = () => {
        if(actionText === 'approved') return 'approve';
        if(actionText === 'rejected') return 'reject';
        if(actionText === 'cancelled') return 'cancel';
    }

    const returnHeadingVal = () => {
        if(actionText === 'approved') return 'Approval';
        if(actionText === 'rejected') return 'Rejection';
        if(actionText === 'cancelled') return 'Cancellation';
    }

    const returnHeadingVal1 = () => {
        if(actionText === 'approve') return 'Undo Approve';
        if(actionText === 'reject') return 'Undo Reject';
    }

    const approveRejectCancelMessage = () => {

        if(returnVal() === 'cancel') {
            return `Are you sure you want to cancel this approval?`
        } else {

            if(approvalObj.target_type === ApprovalTypes.CHECKLIST){
                return `Are you sure you want to ${returnVal()} this checklist?`
            }
            
            if(approvalObj.target_type === ApprovalTypes.TASK){
                return `Are you sure you want to ${returnVal()} this task?`
            }
        }
    }

    const approveRejectCancelHeading = () => {
        if(returnHeadingVal() === 'Cancellation') {
            return `Cancellation`;
        } else {
            let val = approvalObj.target_type === ApprovalTypes.CHECKLIST ? 'Checklist' : 'Task'
            return `${val} ${returnHeadingVal()} Confirmation`;
        }
    }

    const undoApproveRejectHeading = () => {
        let val = approvalObj1.target_type === ApprovalTypes.CHECKLIST ? 'Checklist' : 'Task'
        return `${val} ${returnHeadingVal1()} Confirmation`
    }

    const undoApproveRejectMessage = () => {

        if(approvalObj1.target_type === ApprovalTypes.CHECKLIST){
            return `Are you sure you want to undo ${actionText} this checklist?`
        }
        
        if(approvalObj1.target_type === ApprovalTypes.TASK){
            return `Are you sure you want to undo ${actionText} this task?`
        }
    }

    const callApproveRejectCancelApi = () => {
        const organization_id = props.selectedOrganization.organization.id;
        const apiStr = approvalObj?.target_type === ApprovalTypes.CHECKLIST ? `checklist/${approvalObj?.target_id}/${approvalObj?.id}/${actionText}`
        : `task/${approvalObj?.target_id}/${approvalObj?.id}/${actionText}`;
        const val = approvalObj?.target_type === ApprovalTypes.CHECKLIST ? 'Checklist' : 'Checklist Task'


        approveRejectCancelApi(organization_id, apiStr)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response approveRejectCancelApi: ', res);
                enqueueSnackbar(`${val} ${actionText} successfully`, {variant: 'success'});

                props.checklistTaskUpdateFromApproval(res?.checklist_task, res?.checklist, props.fromChecklistDetailsDrawer ? true : false);
                const updatedArray = props.approvalList.map((approval) => {
                    if(approval.id === res.approval.id) {
                        approval = res.approval;
                    }
                    return approval;
                });
                props.setApprovalList(updatedArray);

                setApprovalObj(undefined);
                setActionText('');
                setAllowApiCall(false);
                handleCloseActionModal();

            })
            .catch((e) => {
                consoleToLog("Error approveRejectCancelApi: ", e.response);
                if(e.response.data && e.response.data.message) {
                    return;
                }
            });
    }

    const onAttachmentClick = (item) => {
        const organization_id = props.selectedOrganization.organization.id;
        const path = item.approval_attachments[0]?.path;

        downloadAttachmentApi(organization_id, path)
        .then((response) => {
            const res = response.data;
            consoleToLog("Response downloadAttachmentApi: ", res);
            const downloadableURL = res.document_signed_url;
            document.getElementById("attachment_download").href = downloadableURL;
            document.getElementById("attachment_download").click();
            trackGAEvent(props.selectedOrganization.organization.name, 'Approval Download Attachment', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
        })
        .catch((e) => {
        consoleToLog(" downloadAttachmentApi error", e.response);
        });
    }

    const onUndoApproveRejectClick = () => {
        const organization_id = props.selectedOrganization.organization.id;

        undoApproveRejectApi(organization_id, approvalObj1.id)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response undoApproveRejectApi: ', res);

                props.checklistTaskUpdateFromApproval(res?.checklist_task, res?.checklist, props.fromChecklistDetailsDrawer ? true : false);
                const updatedArray = props.approvalList.map((approval) => {
                    if(approval.id === res.approval.id) {
                        approval = res.approval;
                    }
                    return approval
                });
                props.setApprovalList(updatedArray);

                setApprovalObj1(undefined);
                setActionText('');
                setAllowApiCall(false);
                handleCloseActionModal();
            })
            .catch((e) => {
                consoleToLog('Error undoApproveRejectApi: ', e.response);
                if(e.response.data && e.response.data.message) {
                    return;
                }
            })
    }

    const handleOpenActionModal = (item, approveRejectCancel) => {
        if(approveRejectCancel) {
            setOpenActionDialog(true);
            setApprovalObj(item);
        } else {
            setOpenActionDialog(true);
            setApprovalObj1(item);
        }
    }

    const handleCloseActionModal = () => {
        setOpenActionDialog(false);
        setActionText('');
        approvalObj && setApprovalObj(undefined);
        approvalObj1 && setApprovalObj1(undefined);
    }

    const showApprovalImages = (approval_status) => {
        switch (approval_status) {
            case ApprovalStatus.PENDING:
                return '/images/pending_icon.svg';
            
            case ApprovalStatus.APPROVED:
                return '/images/approved_icon.svg';
            
            case ApprovalStatus.REJECTED:
                return '/images/rejected_icon.svg';
            
            case ApprovalStatus.CANCELLED:
                return '/images/cancelled_icon.svg';
            
            default:
                break;
        }
    }


    const showConfirmationImages = () => {
        if(actionText === 'approved' || actionText === 'approve') {
            return '/images/approved_icon.svg';
        }
        if(actionText === 'rejected' || actionText === 'reject') {
            return '/images/rejected_icon.svg';
        }
        if(actionText === 'cancelled' || actionText === 'cancel') {
            return '/images/cancelled_icon.svg';
        }
    }

    return (<>
            <div key={props.key}
                className={classes.approvalListContainer}
                style={{
                    display:"flex", 
                    paddingRight:'16px', paddingLeft:'0px',
                    paddingTop: '12px',
                    paddingBottom:'12px',
                    alignItems:'center'
                }}>
                
                <Grid item md={1} className={props.fromChecklistDetailsDrawer ? classes.customColumn5 : classes.customColumn3}>
                    <Grid item container justifyContent='center'>
                        <img src={showApprovalImages(item.approval_status)} style={{filter: 'invert(57%) sepia(0%) saturate(6%) hue-rotate(200deg) brightness(89%) contrast(71%)'}}/>
                    </Grid>
                </Grid>
                
                <Grid item md={9} className={props.fromChecklistDetailsDrawer ? classes.customColumn6 : classes.customColumn4}>
                    {item.target_type === ApprovalTypes.TASK &&
                        <>
                            <Grid item md={12}>
                                <Typography className={classes.taskStyle}>
                                {item.task_title}
                                </Typography>
                            </Grid>
                        </>
                    }
                    <Grid item md={12}>
                        <Grid item container alignItems='center'>
                            <Grid item>
                                <Typography className={classes.userApproverStyle}>
                                    <span className={classes.userApproverNameStyle}>
                                        {assignerUserName}
                                    </span>
                                    <ArrowForwardIcon className={classes.forwardArrowStyle}/>
                                    <span className={classes.userApproverNameStyle}>
                                        {approverUserName}
                                    </span>
                                </Typography>
                            </Grid>

                            <Grid item>
                                <Typography variant='subtitle1'
                                    style={{
                                        borderRadius:'20px',
                                        padding:'0px 14px', 
                                        background:statusObj.color, 
                                        width:"fit-content",
                                        color:'#fff',
                                        marginLeft:'16px',
                                        position:'relative',
                                        }}>
                                    {statusObj.name}
                                </Typography>
                            </Grid>

                                {
                                    (item.approval_status === ApprovalStatus.APPROVED || item.approval_status === ApprovalStatus.REJECTED) &&
                                    <Grid item className={classes.customMarginLeft}>
                                        <Typography variant='body2'>
                                            on {moment.utc(item.approve_reject_timestamp).format('DD MMM YYYY')}
                                        </Typography>
                                    </Grid>
                                }
                                {item.approval_status === ApprovalStatus.CANCELLED &&
                                <Grid item className={classes.customMarginLeft}>
                                    <Typography variant='body2'>
                                            {`by ${cancelledUser} on ${moment.utc(item.cancelled_timestamp).format('DD MMM YYYY')}`}
                                    </Typography>
                                </Grid>
                                }
                                <Grid item >
                                    <Grid item container alignItems='center' 
                                        style={{position:'relative', marginLeft:'6px'}}>

                                        <Typography variant='body2'>
                                            {showDateDifference(item)}
                                        </Typography>
                                        {item.approval_status === ApprovalStatus.REJECTED && item.assigner_user.id === props.auth.user.id &&
                                        <Button className={classes.linkStyle}
                                            onClick={() => {
                                                props.handleOpenSendForReviewDialog(props.taskObj, props.fromChecklistDetailsDrawer ? true : false);
                                                props.setApprovalObj(item);
                                                
                                            }}>
                                            Resend for review
                                        </Button>}

                                    </Grid>
                                </Grid>
                        </Grid>
                        
                    </Grid>

                    <Grid item md={12}>
                        <Grid item container alignItems='center' className={classes.customMarginTop}>
                            <Grid item md={9} className={classes.customColumn1}>
                                <Grid item container alignItems='center'>
                                    {item.text &&
                                    <Grid item>
                                        <span style={{display:'flex', alignItems:'center'}}>
                                            <img src='/images/chat_bubble.svg' style={{marginRight:'4px'}}/>
                                            <Tooltip title={item.text} arrow>
                                                <Typography variant='body2' className={classes.textEllipsis}>
                                                    {item.text}
                                                </Typography>
                                            </Tooltip>
                                        </span>
                                    </Grid>}
                                    {item.approval_attachments.length > 0 && 
                                    <Grid item>
                                        <Typography variant='body2' 
                                            onClick={() => onAttachmentClick(item)}
                                            style={{display:'flex', alignItems:'center', cursor:'pointer'}}>
                                            <img src='/images/attach_file.svg' style={{margin:'0px 4px 0px 16px'}}/>
                                            <span style={{color:"#007bfb"}}>  
                                                {item.approval_attachments[0].path.replace(/^.*[\\\/]/, "")}
                                            </span>
                                        </Typography>
                                        <Link
                                            style={{ display: "none" }}
                                            id="attachment_download"
                                            target="_blank"
                                            onClick={(e) => e.stopPropagation()}
                                            download
                                            ></Link>
                                    </Grid>}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={2}>
                    <Grid item container alignItems='center' justifyContent='center' spacing={1}>
                        {item.approval_status === ApprovalStatus.PENDING && approverOrMember ?  
                        <Grid item>
                            <Grid item container direction='column' alignItems='center'>
                                <Grid item>
                                    <Button 
                                        onClick={() => {
                                            handleOpenActionModal(item, true);
                                            setActionText('approved');
                                        }}
                                        className={classes.approverBtnStyle}>
                                        Accept
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button 
                                        onClick={() => {
                                            handleOpenActionModal(item, true)
                                            setActionText('rejected');
                                        }}
                                        className={classes.rejectBtnStyle}>
                                        Reject
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        :
                        item.approval_status === ApprovalStatus.PENDING &&
                        <Grid item>
                            <Grid item container alignItems='center'>
                                <Button 
                                    style={{position:'relative', top:item.target_type === ApprovalTypes.TASK ? '16px' : '6px'}}
                                    onClick={() => {
                                        handleOpenActionModal(item, true);
                                        setActionText('cancelled');
                                    }}
                                    className={classes.cancelButton}>
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>
                        }
                        {item.approval_status === ApprovalStatus.APPROVED && approverOrMember &&  
                        <Grid item>
                            <Grid item container alignItems='center'>
                                <Button 
                                    onClick={() => {
                                        handleOpenActionModal(item, false);
                                        setActionText('approve');
                                    }}
                                    className={classes.undoBtnStyle}>
                                    Undo Approve
                                </Button>
                            </Grid>
                        </Grid>}
                        {item.approval_status === ApprovalStatus.REJECTED && approverOrMember &&  
                        <Grid item>
                            <Grid item container alignItems='center'>
                                <Grid item>
                                    <Button 
                                        onClick={() => {
                                                handleOpenActionModal(item, false);
                                                setActionText('reject');
                                            }}
                                        className={classes.undoBtnStyle}>
                                        Undo Reject
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>}
                    </Grid>
                </Grid>
            </div>
            {
                openActionDialog && (approvalObj?.id === props.item.id || approvalObj1?.id === props.item.id) &&
                <Dialog fullScreen={false}
                open={openActionDialog}
                onClose={handleCloseActionModal}
                scroll="paper"
                classes={{
                    paper: classes.MuiDialogpaper}}
                maxWidth="sm"
                fullWidth>
                    {
                        <DialogContent style={{padding:'16px'}}>
                            <Grid item container 
                                direction='column'
                                alignItems='center'
                                justifyContent='center'>
                                <Grid item className={classes.dialogActionImg}>
                                    <img src={showConfirmationImages()}  />
                                </Grid>
                                <Grid item>
                                    <Typography className={classes.dailogHeading}>
                                            {approvalObj ? approveRejectCancelHeading() : undoApproveRejectHeading()}
                                    </Typography>
                                    <Typography className={classes.errorTextStyles}>
                                        {approvalObj ? approveRejectCancelMessage() : undoApproveRejectMessage()}
                                        </Typography>
                                </Grid>
                                <Grid item style={{marginTop:'16px'}}>
                                    <Grid item container spacing={1}>
                                        <Grid item>
                                            <Button 
                                                onClick={() => setAllowApiCall(true)}
                                                className={classes.yesButtonStyle}>
                                                Yes
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button 
                                                onClick={handleCloseActionModal}
                                                className={classes.noButtonStyle}>
                                                No
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </DialogContent>
                    }
                </Dialog>
            }
            </>

        );
    }

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization
})

export default connect(mapStateToProps)(ApprovalItem);