import React, {useState, useEffect} from 'react';
import MobileRightToLeftSideDrawer from './MobileRightToLeftSideDrawer';
import { consoleToLog } from '../../util/AppUtil';
import {getClientDetailsObjectApi} from '../../services/clientService';
import {connect} from 'react-redux';
import { Typography, Grid, CircularProgress, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    headings: {
        fontWeight: 600
    },
    customMarginTop: {
        marginTop:"8px"
    },
    contactListContainer: {
        marginBottom:'12px',
        background:"#fff",
        padding:'8px'
    },
    customMarginLeft: {
        marginLeft:'6px'
    },
    valuesStyle: {
        fontWeight:600
    }

}))

const MobileChecklistClientDetails = (props) => {
    const classes = useStyles();

    const [clientObj, setClientObj] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getClientObj();
    }, [])

    const getClientObj = () => {
        const client_id = props.checklistDetails.company.id;

        setLoading(true);
        getClientDetailsObjectApi(client_id)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response getClientDetailsObjectApi', res);
                setLoading(false);
                setClientObj(res.client);
            })
            .catch((e) => {
                consoleToLog("Error getClientDetailsObjectApi: ", e.response);
                setLoading(false);
                if(e.response.data && e.response.data.message) {
                    return;
                }
            })
    }   

    return (
        <MobileRightToLeftSideDrawer openDrawer={props.openClientDetailsDrawer}
            drawerClose={props.handleClientDetailsDrawerClose}
            title={'Client Details'}>
                
                {loading ? 
                <Grid item container justifyContent='center' style={{marginTop:'8px'}}>
                    <CircularProgress size={25} />
                </Grid>
                :
                <Grid item xs={12} style={{padding:"16px"}}>

                    <Grid item container direction='column'>
                        <Typography className={classes.headings}>
                            Client Info
                        </Typography>

                        <Grid item xs={12} className={classes.customMarginTop}>
                            <Typography variant='body2'>
                                Client Name
                            </Typography>
                            <Typography variant='body1' className={classes.valuesStyle}>
                                {clientObj?.name}
                            </Typography>
                        </Grid>

                        {clientObj?.tax_id &&
                        <Grid item xs={12} className={classes.customMarginTop}>
                            <Typography variant='body2'>
                                Tax Id
                            </Typography>
                            <Typography variant='body1' className={classes.valuesStyle}>
                                {clientObj?.tax_id}
                            </Typography>
                        </Grid>}

                        {clientObj?.address &&
                        <Grid item xs={12} className={classes.customMarginTop}>
                            <Typography variant='body2'>
                                Address
                            </Typography>
                            <Typography variant='body1' className={classes.valuesStyle}>
                                {clientObj?.address}
                            </Typography>
                        </Grid>}

                        {clientObj?.phone_number &&
                        <Grid item xs={12} className={classes.customMarginTop}>
                            <Typography variant='body2'>
                                Phone Number
                            </Typography>
                            <Typography variant='body1' className={classes.valuesStyle}>
                                {clientObj?.phone_number}
                            </Typography>
                        </Grid>}

                        <Grid item xs={12} className={classes.customMarginTop}>
                            <Typography variant='body2'>
                                Country
                            </Typography>
                            <Typography variant='body1' className={classes.valuesStyle}>
                                {clientObj?.country}
                            </Typography>
                        </Grid>

                        <Grid item xs={12} className={classes.customMarginTop}>
                            <Typography variant='body2'>
                                State
                            </Typography>
                            <Typography variant='body1' className={classes.valuesStyle}>
                                {clientObj?.state}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Divider light style={{marginTop:'16px'}}/>

                    <Grid item container direction='column' style={{marginTop:'16px'}}>
                        <Typography className={classes.headings}>
                            Contacts
                        </Typography>

                        {
                            clientObj.contacts && clientObj.contacts.length > 0
                            && clientObj.contacts.map((contactObj) => {
                                return <Grid item xs={12} key={contactObj.id} className={classes.contactListContainer}>
                                            <Grid item container>
                                                <Grid item>
                                                    <Typography variant='body2'>
                                                        First Name: 
                                                    </Typography>
                                                </Grid>
                                                <Grid item className={classes.customMarginLeft}>
                                                    <Typography variant='body2' className={classes.valuesStyle}>
                                                        {contactObj.first_name}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid item container>
                                                <Grid item>
                                                    <Typography variant='body2'>
                                                        Last Name: 
                                                    </Typography>
                                                </Grid>
                                                <Grid item className={classes.customMarginLeft}>
                                                    <Typography variant='body2' className={classes.valuesStyle}>
                                                        {contactObj.last_name}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid item container>
                                                <Grid item>
                                                    <Typography variant='body2'>
                                                        Email:
                                                    </Typography>
                                                </Grid>
                                                <Grid item className={classes.customMarginLeft}>
                                                    <Typography variant='body2' className={classes.valuesStyle}>
                                                        {contactObj.email}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid item container>
                                                <Grid item>
                                                    <Typography variant='body2'>
                                                        Mobile: 
                                                    </Typography>
                                                </Grid>
                                                <Grid item className={classes.customMarginLeft}>
                                                    <Typography variant='body2' className={classes.valuesStyle}>
                                                        {contactObj.phone_number}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid item container>
                                                <Grid item >
                                                    <Typography variant='body2'>
                                                        Title:
                                                    </Typography>
                                                </Grid>
                                                <Grid item className={classes.customMarginLeft}>
                                                    <Typography variant='body2' className={classes.valuesStyle}>
                                                        {contactObj.title}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                            })
                        }
                    </Grid>
                </Grid>}
            
        </MobileRightToLeftSideDrawer>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth
})

export default connect(mapStateToProps)(MobileChecklistClientDetails);