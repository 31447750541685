import React, { useState, useEffect, createRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import RichTextWidget from "../widgets/RichTextWidget";
import DownloaderWidget from "../widgets/DownloaderWidget";
import UploaderWidget from "../widgets/UploaderWidget";
import { connect } from "react-redux";
import { useIsMount } from "../useIsMount";
import TaskActivities from "./TaskActivities";
import CircularProgress from "@material-ui/core/CircularProgress";
import { editChecklistTaskApi } from "../../services/ChecklistService";
import { consoleToLog } from "../../util/AppUtil";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  menuListItems: {
    paddingRight: "26px",
  },
  horizontalDivider: {
    width: "2px",
    height: "5rem",
    backgroundColor: "#cacaca",
    marginTop: "0px",
    border: "none",
    marginBottom: "0px",
  },
  commentSection: {
    padding: "5px 5px",
    fontSize: "12px",
    marginLeft: "10px",
    marginTop: "10px",
    marginBottom: "10px",
    boxShadow: "0px 0px 6px 0px #140d0d26",
    minWidth: "50rem",
    maxWidth: "57rem",
    width: "auto",
  },
  checklistDetailHeader: {
    fontWeight: 500,
    fontSize: "20px",
  },
  checklistDetailContent: {
    fontSize: "15px",
    padding: "18px 0px",
    color: "black",
    lineHeight: "25px",
  },
  checklistDetailBtn1: {
    borderRadius: "5px",
    padding: "2px 10px 2px 10px",
    color: "#656565",
    border: `1px solid #656565`,
    margin: "10px 10px 10px 0px",
  },
  checklistDetailBtn2: {
    borderRadius: "5px",
    padding: "2px 10px 2px 10px",
    color: "#dd584d",
    border: `1px solid #dd584d`,
  },
  checklistDetailAct: {
    fontWeight: "500",
    fontSize: "20px",
    marginTop: "10px",
    marginBottom: "10px",
  },
  commentIconContainer: {
    backgroundColor: "#3d92ff",
    minHeight: "30px",
    height: "30px",
    width: "30px",
    boxShadow: "none",
  },
  commentIcon: {
    height: "15px",
    width: "15px",
    color: "white",
    transform: "scaleX(-1)",
    fontSize: "1rem",
  },
  commentedBy: {
    fontSize: "14px",
    marginLeft: "10px",
    color: "black",
  },
  commentedOn: {
    fontSize: "13.5px",
    color: "#5e6265",
    margin: "0px 5px",
  },
  commentTime: {
    fontSize: "14px",
    marginLeft: "10px",
    color: "#000",
  },
}));

const DetailPage = (props) => {
  const classes = useStyles();
  const isMount = useIsMount();
  const [task1, setTask1] = useState(props.selectedTask);
  const [loading, setLoading] = useState(false);
  //const { access_level } = props.selectedOrganization || {};

  /* const accessLevelGuest =
    AccessLevel.getUserAccessLevelValue(access_level) === AccessLevel.GUEST
      ? true
      : false; */

  const myDivToFocus = createRef();  //for comment scrolling
  const {enqueueSnackbar} = useSnackbar();

 
  useEffect(() => {
    setTask1(props.selectedTask);
  }, []);

  useEffect(() => {
    if (!isMount) {
      setTask1(props.selectedTask);
    }
  }, [props.selectedTask]);

  const onScrollToCommentSection = () => {
    if(myDivToFocus.current){
      myDivToFocus.current.scrollIntoView({
          behavior: "smooth"
      });
    }
  } 
  
  const onWidgetUpdated = (updatedWidget) => {
    props.onWidgetUpdated(updatedWidget);
  };

  const updateTaskAttachmentArray = (taskObj) => {
    props.updateTaskAttachmentArray(taskObj);
  };

  const onCommentsUpdated = (activity_feed, comment_count) => {
    props.onCommentsUpdated(activity_feed, comment_count);
  };

  const onCommentsdeleted = (activityFeeds, checklistObj) => {
    props.onCommentsdeleted(activityFeeds, checklistObj);
  };

  const onCommentsEdited = (activity_feed) => {
    props.onCommentsEdited(activity_feed);
  };

  const deleteWidgetClick = (task, widget) => {
    if(window.confirm("Are you sure you want to delete this widget?")) {

      const organization_id = props.selectedOrganization.organization.id;
      const checklist_id = props.checklistItem?.id;
      const task_id = task.id;
      const widget_id = widget.id;
      const title = task?.title;
      const heading = task?.heading;

      const updateArray = task?.checklist_task_widget;
      const arr =  updateArray.slice(widget.order_number);
      //console.log('arr**********', arr);

      const updateTaskWidgets = task?.checklist_task_widget?.map((widget) => {
            arr.map((widgetObj) => {
              if(widget.id === widgetObj.id) {
                  widget.order_number = widget.order_number - 1; 
              }
              return widget;
          })
          return widget;
      }).filter((widget) => widget.id !== widget_id);

      //console.log('updateTaskWidgets**********', updateTaskWidgets);
      const widget_json = updateTaskWidgets;

      editChecklistTaskApi(organization_id, checklist_id, task_id, title, heading, widget_json)
      .then((response) => {
          const res = response.data;
          consoleToLog("Response editTemplateTaskApi: ", res);

          enqueueSnackbar(res.message, {
              variant:'success'
          });

          let updateTaskObj = {...task, 
            checklist_task_widget: widget_json
          }
          props.onEditDeleteWidget(updateTaskObj);

      })
      .catch((e) => {
          consoleToLog('Error editTemplateTaskApi: ', e.response);
          setLoading(false);
          if (e.response.data && e.response.data.message) {
              enqueueSnackbar(e.response.data.message, ({
                  variant: 'error'
              }));
              return false;
          }
      })
  } 

  }

  return (
    <Grid direction="row" sm={12}>
      {props.widgetsLoading  ? (
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          style={{ height: "30vh" }}
        >
          <Grid
            item
            sm={12}
            container
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress color="secondary" sm={12} />
          </Grid>
      </Grid>
      ) : (
        <Grid item  sm={12} className={classes.checklistDetailContent}>
          {task1 && task1.checklist_task_widget ? (
            task1.checklist_task_widget.map((widget) => {
              return (
                <div key={widget.id} className={classes.taskListContainer}>
                  {widget.type === "rich_text" ? (
                    <RichTextWidget text={widget.json_data.desc}
                      editChecklist={props.editChecklist}
                      handleOpenUpdateTaskWidgetDrawer={props.handleOpenUpdateTaskWidgetDrawer}
                      task1={task1}
                      widget={widget}
                      deleteWidgetClick={deleteWidgetClick}/>
                  ) 
                  // : widget.type === "upload" ? (
                  //   <UploaderWidget
                  //     widget={widget}
                  //     onWidgetUpdated={(updatedWidget) => {
                  //       onWidgetUpdated(updatedWidget);
                  //     }}
                  //     //onWidgetAttachmentAdded = {onWidgetAttachmentAdded}
                  //     selectedTask={task1}
                  //     checklistItem={props.checklistItem}
                  //   />
                  // ) 
                  : widget.type === "download" ? (
                    <DownloaderWidget widget={widget} 
                      selectedTask={task1}
                      editChecklist={props.editChecklist}
                      handleOpenUpdateTaskWidgetDrawer={props.handleOpenUpdateTaskWidgetDrawer}
                      deleteWidgetClick={deleteWidgetClick}/>
                  ) : (
                    <div />
                  )}
                </div>
              );
            })
          ) : (
            <div></div>
          )}
          <UploaderWidget
                //onWidgetAttachmentAdded = {onWidgetAttachmentAdded}
                selectedTask={task1}
                checklistItem={props.checklistItem}
                updateTaskAttachmentArray={updateTaskAttachmentArray}
              />
          <div ref={myDivToFocus}>
                <TaskActivities
                  currentTask={task1}
                  onCommentsUpdated={onCommentsUpdated}
                  onCommentsEdited={onCommentsEdited}
                  onCommentsdeleted={onCommentsdeleted}
                  onScroll={onScrollToCommentSection}
                />
              </div>  
        </Grid>
      )}
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  selectedOrganization: state.organizationInfo.selectedOrganization,
  selectedMembersFilter: state.filters.selectedMembersFilter,
});

export default connect(mapStateToProps, null)(DetailPage);
