import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {Grid, Typography,  Avatar, Button, Paper} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {organizationListApi} from '../services/authService';
import {setOrganizations} from '../actions/organization';
import { consoleToLog} from '../util/AppUtil';

const useStyles = makeStyles((theme) => ({
    tabPanelContainer: {
        flexGrow: 1,
        padding:'15px',
        background: 'white',
        //height:'100vh',
        marginBottom:'25px',
    },      
    circularProgress: {
        marginLeft: 0,
        marginRight: theme.spacing(2),
        color: 'white'
      }, 
    companyListContainer : {
        background:'#F9F9F9', 
        minWidth:"100%", 
        borderRadius:'4px', 
        padding:'10px', 
        marginBottom:'6px',
        '& .action__icons' : {
            visibility: 'hidden',
        },
        '&:hover .action__icons' : {
            visibility: 'visible',
        },
        '&:last-child' : {
            marginBottom:'0px'
        }
    },
    avatarStyle: {
        width:"40px !important",
        height:"40px !important",
        fontSize:'16px !important',
        fontWeight:'600 !important'
    },    
}
))

const MyOrganizations = (props) => {
    const classes = useStyles();

    useEffect(() => {

        organizationListApi()
            .then((response) => {
                const res = response.data;
                consoleToLog("Response organizationListApi: ", res);

                //set redux organizations with new response
                props.setOrganizations(res);
            })
            .catch((e) => {
                consoleToLog("organizationListApi error", e.response);
                if (e.response.data && e.response.data.message) {
                    return false;
                }
            });

    }, []);

    return (
        <Grid item container>
            <Paper className={classes.tabPanelContainer} elevation={0}>
                <Grid container direction="column" spacing={2}  style={{marginTop:'8px'}}>
                    <Grid item>
                        <Button variant="contained" color="primary" 
                            onClick={() => props.handleDrawerOpen()}
                            style={{width:'185px'}}>
                            Create Organization
                        </Button>
                    </Grid> 

                    <Grid item md={12} lg={12}>
                        {
                            props.organizations && props.organizations.length > 0 ?
                            props.organizations.map((org) => {
                                const orgArr = org.organization.name.split(" ");
                                let orgStr1 = orgArr[0];
                                let orgStr2 = ((orgArr[1] && orgArr[1] !== '-') && orgArr[1]) || ((orgArr[1] && orgArr[1] === '-') && orgArr[2]) || '';
                                const finalStr = orgStr1.charAt(0).toUpperCase() + orgStr2.charAt(0).toUpperCase();
                                return <Grid item container key={org.id} 
                                            className={classes.companyListContainer}
                                            alignItems="center"
                                        >
                                            <Grid item md={1} lg={1}>
                                                <Avatar className={classes.avatarStyle}>
                                                    {finalStr}    
                                                </Avatar>
                                            </Grid>  

                                            <Grid item md={10} lg={10} style={{marginLeft: "-32px"}}>
                                                    <Typography variant="body1" color="primary" 
                                                        style={{fontWeight:'600'}}> 
                                                        {org.organization.name}
                                                    </Typography>

                                                    <Typography variant="subtitle1" 
                                                        style={{color:'#555', marginBottom:"0px"}}> 
                                                        {org.organization.email}
                                                    </Typography>
                                            </Grid> 

                                            {/* <Grid item md={1} lg={1} className="action__icons">
                                                <div style={{display:'flex', justifyContent:'flex-end'}}>
                                                    <Tooltip title="Archive Organization" arrow>    
                                                        <Button // onClick={() => onDeleteCompanyClick(company.id) }
                                                            style={{
                                                            minWidth:'10px'
                                                        }}>
                                                            <i className="flaticon-delete-1" style={{fontSize:'1.1rem'}}></i>
                                                        </Button>
                                                    </Tooltip>
                                                </div>
                                            </Grid>  */}
                                       </Grid>
                            }) : <Grid item container justifyContent="center">
                                    <Typography variant="body2" >
                                        No Organizations
                                    </Typography>
                                  </Grid>  
                        }
                    </Grid>      
                </Grid> 
            </Paper>    
        </Grid>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    organizations: state.organizations
});

const mapDispatchToProps = (dispatch) => ({
    setOrganizations: (organizations) => dispatch(setOrganizations(organizations)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyOrganizations);