import React, {useState, useEffect} from 'react';
import RightToLeftSideDrawer from '../RightToLeftSideDrawer';
import {Grid, Typography, Button, TextField, CircularProgress, FormControlLabel, Checkbox} from '@material-ui/core';
import { useIsMount } from '../useIsMount';
import { useSnackbar } from 'notistack';
import {connect} from 'react-redux';
import {editChecklistTaskApi, addChecklistTaskApi} from '../../services/ChecklistService';
import { consoleToLog } from '../../util/AppUtil';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import {makeStyles} from '@material-ui/core/styles';
import {generateRandomString} from '../../util/AppUtil';

const useStyles = makeStyles((theme) => ({
    MuiFormControlLabellabel :{
        marginLeft:'-4px',
        fontSize:'15px'
    },
}))

const UpdateTaskTitleDrawer = (props) => {
    const classes = useStyles();

    const [taskTitle, setTaskTitle] = useState('');
    const [loading, setLoading] = useState(false);
    const [isHeadingTask, setIsHeadingTask] = useState(false);
    const isMount = useIsMount();
    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => {
        if(props.addTaskClicked) {
            props.setAddTaskClicked(false);
        }
    }, [props.addTaskClicked])

    useEffect(() => {
        if(!isMount) {
            if(props.taskObj && props.openTitleUpdateDrawer) {
                setTaskTitle(props.taskObj?.title);
                setIsHeadingTask(props.taskObj?.heading)
            }
        }
    }, [props.taskObj]);

    const closeDrawer = () => {
        setTaskTitle('');
        setIsHeadingTask(false);
        props.handleCloseTitleUpdateDrawer();
        
    }

    const onSaveClick = () => {
        const title = taskTitle;
        if(title === '') {
            enqueueSnackbar('Title cannot be empty!', {variant:'error'});
        }
        const heading = isHeadingTask;

        const organization_id = props.selectedOrganization.organization.id;
        const checklist_id = props.checklistId;
        const task_id = props.taskObj?.id;
        const widget_json = props.taskObj?.checklist_task_widget ? props.taskObj?.checklist_task_widget : [];
        let obj = {id: generateRandomString(8, 'a#'), type:'rich_text', is_editable: false, order_number: 0, json_data:{desc: ''}};
        let widget_array = [obj]

        setLoading(true);

        if(props.taskObj) {
            editChecklistTaskApi(organization_id, checklist_id, task_id, title, heading, widget_json)
                .then((response) => {
                    const res = response.data;
                    consoleToLog("Response editChecklistTaskApi: ", res);
                    setLoading(false);

                    enqueueSnackbar(res.message, {
                        variant:'success'
                    });

                    props.updateChecklistTasks(res.checklist_tasks, true);
                    closeDrawer();
                })
                .catch((e) => {
                    consoleToLog('Error editChecklistTaskApi: ', e);
                    setLoading(false);
                    if (e.response.data && e.response.data.message) {
                        enqueueSnackbar(e.response.data.message, ({
                            variant: 'error'
                        }));
                        return false;
                    }
                })

        } else {

            addChecklistTaskApi(organization_id, checklist_id, title, heading, widget_array)
                .then((response) => {
                    const res = response.data;
                    consoleToLog("Response addChecklistTaskApi: ", res);
                    setLoading(false);

                    props.updateChecklistTasks(res.checklist_tasks, true);
                    props.setScrollTask(true);
                    closeDrawer();
                })
                .catch((e) => {
                    consoleToLog('Error addChecklistTaskApi: ', e);
                    setLoading(false);
                    if (e.response.data && e.response.data.message) {
                        enqueueSnackbar(e.response.data.message, ({
                            variant: 'error'
                        }));
                        return false;
                    }
                })
            
        }

    }   

    return (
        <RightToLeftSideDrawer title='Update Task Title'
            fromChecklistDetails={props.fromChecklistDetails}
            openDrawer={props.openTitleUpdateDrawer}
            closeDrawer={props.handleCloseTitleUpdateDrawer}
            >
            <div className="modal__main">
                <Grid item container md={12} lg={12} direction="column">
                    <Grid item lg={12} style={{margin:"0px 0px 8px 0px"}}>
                        <Typography variant="body2" style={{color:'#555555', marginBottom:'-7px'}}>
                            Title
                        </Typography>   
                        <TextField variant='outlined'
                            margin='dense'
                            fullWidth
                            value={taskTitle}
                            onChange={(e) => setTaskTitle(e.target.value)}
                            placeholder='Enter Title'
                            />
                    </Grid>

                    <Grid item lg={12} style={{margin:"0px 0px 8px 0px"}}>
                        <FormControlLabel
                            classes={{
                                label: classes.MuiFormControlLabellabel
                            }}
                            control={
                            <Checkbox
                                name="checkedB"
                                color="primary"
                                style={{fontSize:'8px'}}
                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                checked={isHeadingTask}
                                onChange={() => setIsHeadingTask(!isHeadingTask)}
                                onClick={(e) => e.stopPropagation()}
                            />
                            }
                            label="Heading"
                        />
                    </Grid>

                    <div className="modal__footer1" >
                        <Button variant="contained" color="primary" 
                            className="modal__button"
                            onClick={onSaveClick}
                            >
                            {loading && <CircularProgress size={24} style={{marginRight:'8px'}}/>}
                            Save
                        </Button>
                        <Button variant="outlined" 
                            onClick={props.handleCloseTitleUpdateDrawer}
                            style={{
                                minWidth: '130px',
                            }}>
                            Cancel
                        </Button>
                    </div>
                </Grid>
            </div>
        </RightToLeftSideDrawer>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization
})

export default connect(mapStateToProps)(UpdateTaskTitleDrawer);