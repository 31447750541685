import React, {useState, useEffect} from 'react';
import {TextField, Grid, Button} from '@material-ui/core'; 
import { connect } from 'react-redux';
import {consoleToLog, trackGAEvent} from '../../util/AppUtil';
import {createFolderApi} from '../../services/authService';
import {useParams} from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    modalFooter: {
        position: 'absolute',
        bottom: '16px',
        left: 0,
        right:0,
        zIndex:15,
        background: '#fff',
        borderTop: '1px solid #ccc',
        padding:'6px 8px 6px 8px',
    },
}))

const MobileCreateFolder = (props) => {
    const classes = useStyles();

    const {slug} = useParams();
    const [folderName, setFolderName] = useState('');
    const { enqueueSnackbar } = useSnackbar();

    const onCreateFolderClick = (e) => {
        if(!folderName) {
            enqueueSnackbar('Folder Name Cannot be empty', {
                variant:'error'
            });
            return;
        }
    
        const organization_id = props.selectedOrganization.organization.id;
        const template_id = props.selectedTemplate.template_id ? props.selectedTemplate.template_id : null;
        const parent_group_slug_code = slug ? slug : '' ;
        
        createFolderApi(organization_id, template_id, folderName, parent_group_slug_code)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response createFolderApi ', res);
                props.addFolderToList(res.checklist_grouping);
                //trackGAEvent(props.selectedOrganization.organization.name, 'Checklist Create Folder', `${props.auth.user.firstname} ${props.auth.user.lastname} created folder`);
            })
            .catch((e) => {
                consoleToLog('Error createFolderApi ', e);
                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant:'error'
                    });
                    return false;
                }
            });
    }

    return (
        <div style={{marginTop:'16px'}}>
            <Grid item lg={12}> 
                <span style={{fontSize:'14px'}}>
                    Folder Name
                </span>
                <TextField fullWidth 
                    id="folder_name" 
                    variant="outlined"
                    type="text"
                    margin="dense"
                    value={folderName}
                    style={{background:'#fff'}}
                    placeholder="Enter Folder Name"
                    InputLabelProps={{style: {fontSize: 13, color: "#ccc"}}}
                    onKeyPress={(ev) => {
                        //consoleToLog(`Pressed keyCode ${ev.key}`);
                        if (ev.key === 'Enter') {
                            onCreateFolderClick();
                            ev.preventDefault();
                        }
                        }}
                    onChange={e => { 
                        setFolderName(e.target.value);
                    }}
                />
                <Grid container spacing={2} className={classes.modalFooter}>
                    <Grid item xs={6} sm={6}>
                        <Button variant="contained" color="primary" 
                            fullWidth
                            onClick={onCreateFolderClick}>
                            Create
                        </Button>
                    </Grid>

                    <Grid item xs={6} sm={6}>
                        <Button variant="outlined" 
                            fullWidth
                            onClick={props.drawerClose}>
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    selectedTemplate: state.organizationInfo.selectedTemplate,
});

export default connect(mapStateToProps)(MobileCreateFolder);