import BaseComponent from "../components/Base/BaseComponent";
import { makeStyles, } from '@material-ui/core/styles';
import { useState, useEffect, useRef } from "react";
import LeftMenuComponent from '../components/LeftMenuComponent';
import ContentComponent from '../components/ContentComponent';
import { connect } from 'react-redux';
import { getOrganizationList,  setSelectedTemplate } from '../actions/selectedOrganization';
import { setLoading } from '../actions/loading';
import { CircularProgress, Grid } from "@material-ui/core";
import {useIsMount} from '../components/useIsMount';
import {history} from '../routers/AppRouter';
import {SelectedDrawerState, setSelectedDrawerState} from '../actions/selectedOrganization';
import OrganizationMembers from "../components/OrganizationMembers";
import OrganizationLabels from "../components/OrganizationLabels";
import CompletionReports from "../components/CompletionReports";
import OrganizationCompanies from "../components/OrganizationCompanies";
import WorkLoadReports from "../components/WorkLoadReports";
import FollowupReports from "../components/FollowupReports";
import OrganizationSettings from "../components/OrganizationSettings";
import MyProfile from "../components/MyProfile";
import ActivityReports from "../components/ActivityReports";
import EmailReports from "../components/EmailReports";
import PredefinedTemplates from "../components/PredefinedTemplates";
import SnackbarComponent from "../components/SnackbarComponent";
import SendEmailDialog from "../components/SendEmailDialog";
import RightToLeftSideDrawer from "../components/RightToLeftSideDrawer";
import CreateEditFolderModal from '../components/modals/CreateEditFolderModal';
import CreateChecklistModal from '../components/modals/CreateChecklistModal';
import {checkTemplateRules} from '../actions/selectedOrganization';
import OutsideOnboardingComponent from "../components/OutsideOnboardingComponent";
import ClientWorkReport from "../components/ClientWorkReport";
import { consoleToLog, getStatusNameByValue } from "../util/AppUtil";
import { fetchAccessToken } from '../util/AppUtil';

const useStyles = makeStyles((theme) => ({
    displayflex : {
        display: "flex", 
        height: "100%"
    },
    loading: {
        position:'absolute',
        left:'50%',
        right:'50%',
        top:'10%'
    }

}));

const DashboardPage = (props) => {    
    const classes = useStyles();
    const[showDrawer, setShowDrawer] = useState(true);
    const isMount = useIsMount();
    //const[mainContentWidth, setMainContentWidth] = useState("83%");

    //const no_support_email = props.orgEmailList && props.orgEmailList.length === 0;
    let filteredSupportEmail = props.orgEmailList && props.orgEmailList.length > 0 ? props.orgEmailList.filter(email => email.support) : [];
    const no_support_email = filteredSupportEmail && filteredSupportEmail.length === 0;
    const verifiedEmailArr = filteredSupportEmail && filteredSupportEmail.length > 0 ? filteredSupportEmail.filter((email) => !email.verified) : [];

    const [openSendEmailDialog, setOpenSendEmailDialog] = useState(false);
    const [openChecklistModal, setOpenChecklistModal] = useState(false);
    const [openFolderModal, setOpenFolderModal] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [checklistDetailsOpen, setChecklistDetailsOpen] = useState(false);
    const [checkList, setCheckList] = useState([]);
    const [accessToken, setAccessToken] = useState(undefined);
    const checklistArr = useRef([]);
    const templateIDRef = useRef('');

    const fullName = props.auth.user?.firstname + ' ' + props.auth.user?.lastname;

    const URL_WEB_SOCKET = `${process.env.REACT_APP_CONNECT_WEBSOCKET_URL}?authorization=${accessToken?.replace('Bearer ', '')}&type=workplace`;
    
    let webSocket;
    const connectToWebSocket = () => {
        webSocket = new WebSocket(URL_WEB_SOCKET);
    
        webSocket.onopen = (event) => {
            //console.log('selectedTemplateId', templateId);
            //consoleToLog('event opened', event);
        };

        webSocket.onerror = (event) => {
            //consoleToLog('event opened', event);
            webSocket.close();
        };

        webSocket.onclose = () => {
            //consoleToLog('ws closed');
            connectToWebSocket();
        };

        webSocket.onmessage = (evt) => {
            const message = JSON.parse(evt.data);
            addToCheckList(message)
            consoleToLog('received message', message);
            
        };

    }

    useEffect(() => {
        document.title="eProcessify";
        modalStatus();

        //props.setLoading(true);
        props.getOrganizationList(props.selectedOrganization, props.startDateFilter, props.endDateFilter, 
            props.companiesFilter, props.selectedMembersFilter, props.selectedTemplate, props.auth.user?.scope);
    }, []);

    useEffect(() => {
        const fetchToken = async () => {
            try {
            const data = await fetchAccessToken('workplace');
            if(data) {
                consoleToLog('Response authenticateApi', data);
                const {access_token} = data;
                if(access_token) {
                    setAccessToken(access_token)
                }  else {
                    window.open(`${process.env.REACT_APP_AUTH_APP_DOMAIN_NAME}/login?service=workplace`, '_self');
                }
            } 
            } catch (error) {
                consoleToLog("error fetchAccessToken", error);
            }
        };
    
        fetchToken();
    
        return () => {
            webSocket?.close();
        };
    }, []);

    useEffect(() => {
        if(!isMount) {
            if(accessToken) {
                connectToWebSocket();
                templateIDRef.current = props.selectedTemplate?.template_id;
            }
        }
    }, [accessToken])

    useEffect(() => {
        if(!isMount) {
            if(props.selectedTemplate) {
                templateIDRef.current = props.selectedTemplate?.template_id;
            }
        }
    }, [props.selectedTemplate?.template_id]);

    

    const fetchChecklistFromChild = (checkListFromChild) => {
        checklistArr.current = checkListFromChild;
        setCheckList(checklistArr.current);
    }

    const updateSocketChecklistArr = (socketArr) => {
        let filteredSocketArray = socketArr.filter((checklistItem) => !checklistItem.is_archived);
       // consoleToLog('filteredSocketArray', filteredSocketArray);
        //consoleToLog('templateIDRef.current', templateIDRef.current);
        return filteredSocketArray.filter(checklistItem => {
            return checklistItem.template === templateIDRef.current;
        });
    }

    const filterChecklistArrForCommonItems = (arr1, arr2) => {
        return  arr1.reduce((common, item1) => {
            const commonItem = arr2.find(item2 => item2.id === item1.id);
            if (commonItem) {
                common.push(commonItem);
            }
            return common;
        }, []);
    }

    const filterChecklistArrForUnCommonItems = (arr1, arr2) => {
        let set1 = new Set(arr1.map(item => item.id));
        let set2 = new Set(arr2.map(item => item.id));

        // Find uncommon items using reduce
        return arr1.reduce((result, item) => {
            if (!set2.has(item.id)) {
                result.push(item);
            }
            return result;
        }, []);
    }

    const addToCheckList = (message) => {
        let socketArr = message.publicMessage.checklists;
        let socketChecklistArr = updateSocketChecklistArr(socketArr);
        consoleToLog('socketChecklistArr', socketChecklistArr);
        if(socketChecklistArr && socketChecklistArr.length > 0) {
            if(message.updateToTop) {
                let unCommonArrayItems = filterChecklistArrForUnCommonItems(checklistArr.current, socketChecklistArr);
                //consoleToLog('unCommonArrayItems', unCommonArrayItems);
                //consoleToLog('array in items', [...socketChecklistArr, ...unCommonArrayItems]);
                checklistArr.current = [...socketChecklistArr, ...unCommonArrayItems];
                setCheckList([...socketChecklistArr, ...unCommonArrayItems]);
            } else {
                let updateItemAtCurrentPosition = checklistArr.current.map((checklistItem1) => {
                    socketChecklistArr.map((checklistItem2) => {
                        if(checklistItem1.id === checklistItem2.id) {
                            checklistItem1 = checklistItem2
                        }
                        return checklistItem1
                    })
                    return checklistItem1;
                });
                //consoleToLog('updateAtCurrentPosition', updateItemAtCurrentPosition);
                checklistArr.current = updateItemAtCurrentPosition;
                setCheckList(updateItemAtCurrentPosition);
            }
        }
    }

    const handleSendEmailDialogOpen = () => {
        setOpenSendEmailDialog(true);
    }

    const handleSendEmailDialogClose = () => {
        setOpenSendEmailDialog(false);
    }

    const handleChecklistModalOpen = () => {
        setOpenChecklistModal(true);
    }

    const handleChecklistModalClose = () => {
        setOpenChecklistModal(false);
    }

    const handleFolderModalOpen = () => {
        setOpenFolderModal(true);
    }

    const handleFolderModalClose = () => {
        setOpenFolderModal(false);
    }

    const toggleDrawer = () => {
        setOpenDrawer(open => !open)
    }


    const setHeightOfComponent = () => {
        if(verifiedEmailArr && verifiedEmailArr.length > 0 && props.templateRuleErrorMessage !== null) {
            return 'calc(100vh - 132px)';
        }
        else if(no_support_email) {
            return 'calc(100vh - 90px)';
        }
        else if(verifiedEmailArr && verifiedEmailArr.length > 0) {
            return 'calc(100vh - 90px)';
        } 
        else if(props.templateRuleErrorMessage) {
            return 'calc(100vh - 90px)';
        } else {
            return 'calc(100vh - 48px)';
        }
    }

    const modalStatus = ()=> {
        if(window.location.toString().includes("checklist")){
            props.setSelectedDrawerState(SelectedDrawerState.SHOW_ALL_CHECKLIST);
            props.setSelectedTemplate(props.templates[0]);
        }
    }

    useEffect(() => {
        if(props.selectedOrganization.organization?.id) {
            props.checkTemplateRules(props.selectedOrganization.organization?.id);
        }
    }, [])

    useEffect(() => {
        if(isMount) {
            //consoleToLog('Template Component first render useEffect')
        } else {
            //history.push(`/dashboard`);
            if(props.selectedOrganization.organization?.id) {
                props.checkTemplateRules(props.selectedOrganization.organization?.id);
            }
        }
    }, [props.selectedOrganization.organization?.id]);

    useEffect(() => {
        if(props.isEmailSendError) {
            handleSendEmailDialogOpen();
        }
    }, []);

    useEffect(() => {
            if(!checklistDetailsOpen) {
                document.addEventListener("keydown", handleKeydown, false);
            }
            
            return () => {
                document.removeEventListener("keydown", handleKeydown);
            };
    }, [checklistDetailsOpen])

    const handleKeydown = (e) => {
            if(e.ctrlKey && e.key === 'e') {
                e.preventDefault();
                handleSendEmailDialogOpen();
            }
    }

    const drawerAction = () => {
        //consoleToLog("*****drawerAction"); 
        setShowDrawer(!showDrawer);
    }

    const displaySelectedComponent = () => {
        if(props.setDrawerState === SelectedDrawerState.SHOW_ORG_MEMBERS) {
            return  <OrganizationMembers showDrawer={showDrawer} 
                        no_support_email={no_support_email}
                        height={setHeightOfComponent()}
                        fullName={fullName}/>
        }
        if(props.setDrawerState === SelectedDrawerState.SHOW_COMPLETION_REPORT) {
            return  <CompletionReports showDrawer={showDrawer}
                        no_support_email={no_support_email}
                        height={setHeightOfComponent()}
                        handleSendEmailDialogOpen={handleSendEmailDialogOpen}
                        fullName={fullName}/>
        }
        if(props.setDrawerState === SelectedDrawerState.SHOW_WORKLOAD_REPORT) {
            return  <WorkLoadReports showDrawer={showDrawer}
                        no_support_email={no_support_email}
                        height={setHeightOfComponent()}
                        handleSendEmailDialogOpen={handleSendEmailDialogOpen}
                        fullName={fullName}/>
        }
        if(props.setDrawerState === SelectedDrawerState.SHOW_FOLLOWUP_REPORT) {
            return  <FollowupReports showDrawer={showDrawer}
                        no_support_email={no_support_email}
                        height={setHeightOfComponent()}
                        fullName={fullName}/>
        } 
        if(props.setDrawerState === SelectedDrawerState.SHOW_ACTIVITY_REPORT) {
            return <ActivityReports showDrawer={showDrawer}
                        no_support_email={no_support_email}
                        height={setHeightOfComponent()}
                        fullName={fullName}/>
        }
        if(props.setDrawerState === SelectedDrawerState.SHOW_ORG_COMPANIES) {
            return <OrganizationCompanies showDrawer={showDrawer}
                        no_support_email={no_support_email}
                        height={setHeightOfComponent()}
                        handleSendEmailDialogOpen={handleSendEmailDialogOpen}
                        fullName={fullName}/>
        }
        if(props.setDrawerState === SelectedDrawerState.SHOW_ORG_SETTINGS) {
            return <OrganizationSettings showDrawer={showDrawer}
                        no_support_email={no_support_email}
                        height={setHeightOfComponent()}
                        fullName={fullName}/>
        }
        if(props.setDrawerState === SelectedDrawerState.SHOW_EMAIL_REPORT) {
            return  <EmailReports showDrawer={showDrawer}
                        no_support_email={no_support_email}
                        height={setHeightOfComponent()}
                        fullName={fullName}/>
        }
        if(props.setDrawerState === SelectedDrawerState.SHOW_MY_PROFILE || 
            props.setDrawerState === SelectedDrawerState.SHOW_MY_ORGS) {
            return <MyProfile showDrawer={showDrawer}
                        no_support_email={no_support_email}
                        height={setHeightOfComponent()}
                        fullName={fullName}/>
        }
        if(props.setDrawerState === SelectedDrawerState.CREATE_PREDEFINED_TEMPLATE) {
            return <PredefinedTemplates showDrawer={showDrawer}
                        no_support_email={no_support_email}
                        height={setHeightOfComponent()}
                        fullName={fullName}/>
        }
        if(props.setDrawerState === SelectedDrawerState.SHOW_OVERDUE_CHECKLIST || props.setDrawerState === SelectedDrawerState.SHOW_UPCOMING_CHECKLIST ||
            props.setDrawerState === SelectedDrawerState.SHOW_ALL_CHECKLIST) {
            return <ContentComponent showDrawer={showDrawer}
                        no_support_email={no_support_email}
                        handleSendEmailDialogOpen={handleSendEmailDialogOpen}
                        openChecklistModal={openChecklistModal}
                        openFolderModal={openFolderModal}
                        handleChecklistModalClose={handleChecklistModalClose}
                        handleFolderModalClose={handleFolderModalClose}
                        height={setHeightOfComponent()}
                        setChecklistDetailsOpen={setChecklistDetailsOpen}
                        fetchChecklistFromChild={fetchChecklistFromChild}
                        checkList={checkList}
                        fullName={fullName}
                        />
        }
        if(props.setDrawerState === SelectedDrawerState.SHOW_ONBOARDING) {
            return <OutsideOnboardingComponent showDrawer={showDrawer}
                no_support_email={no_support_email}
                height={setHeightOfComponent()}/>
        }
    }

    const rightToLeftSideDrawerTitle = () => {
        if(openChecklistModal) {
            return 'Create Checklist';
        }
        // if(openFolderModal) {
        //     return 'Create Folder';
        // }

    }

    const setDrawerOpen = () => {
        setOpenDrawer(false)
        openChecklistModal && handleChecklistModalClose();
        openFolderModal && handleFolderModalClose();
    }

    return (
        <BaseComponent drawerAction={drawerAction} 
            handleSendEmailDialogOpen={handleSendEmailDialogOpen}
            handleChecklistModalOpen={handleChecklistModalOpen}
            handleFolderModalOpen={handleFolderModalOpen}
            toggleDrawer={toggleDrawer}>
            {/* following 'flex' is used for horizontal div (we want 2 div in row) */}
            <div className={classes.displayflex}> 
                { props.isLoading 
                    ? <CircularProgress 
                        size={35}
                        className={classes.loading}
                    /> :
                    <>     
                        <LeftMenuComponent showDrawer={showDrawer} />
                        <Grid item container flexDirection="column" style={{overflow:"hidden"}}>
                        { 
                            <SnackbarComponent verifiedEmailArr={verifiedEmailArr && verifiedEmailArr.length > 0 ? verifiedEmailArr : []}
                                no_support_email={no_support_email}
                                isLoading={props.isLoading}
                                />
                        }
                            {displaySelectedComponent()}
                        </Grid>
                        

                    </>
                }
            {openSendEmailDialog && <SendEmailDialog openSendEmailDialog={openSendEmailDialog}
                handleSendEmailDialogClose={handleSendEmailDialogClose}
                handleSendEmailDialogOpen={handleSendEmailDialogOpen}
            />}

            <RightToLeftSideDrawer openDrawer={openDrawer}
                closeDrawer={setDrawerOpen}
                title={rightToLeftSideDrawerTitle()}
                createChecklist={openChecklistModal}
                >
                    {/* {openFolderModal &&
                        <CreateEditFolderModal setDrawerOpen={setDrawerOpen}
                            notFromChecklist={true}/>} */}

                    {openChecklistModal &&
                        <CreateChecklistModal setDrawerOpen={setDrawerOpen} 
                            notFromChecklist={true}/>}

            </RightToLeftSideDrawer>
            </div>
        </BaseComponent>
    )
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    organizations: state.organizations,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    isLoading: state.loading.isLoading,
    selectedTemplate: state.organizationInfo.selectedTemplate,
    startDateFilter: state.filters.startDateFilter,
    endDateFilter: state.filters.endDateFilter,
    selectedMembersFilter: state.filters.selectedMembersFilter,
    companiesFilter: state.filters.companiesFilter,
    setDrawerState: state.organizationInfo.setDrawerState,
    showCreateTemplate: state.organizationInfo.showCreateTemplate,
    templates: state.organizationInfo.templates,
    showUnassignedChecklist: state.filters.showUnassignedChecklist,
    selectedStatusFilter: state.filters.selectedStatusFilter,
    isEmailSendError: state.organizationInfo.isEmailSendError,
    orgEmailList: state.organizationInfo.orgEmailList,
    templateRuleErrorMessage: state.organizationInfo.templateRuleErrorMessage
});

const mapDispatchToProps = (dispatch) => ({
    getOrganizationList: (selectedOrganization, start_date, end_date, companiesFilter, selectedMembersFilter, selectedTemplate, scope) => 
        dispatch(getOrganizationList(selectedOrganization, start_date, end_date, companiesFilter, selectedMembersFilter, selectedTemplate, scope)),
    setLoading: (isLoading) => dispatch(setLoading(isLoading)),
    setSelectedTemplate: (template) => dispatch(setSelectedTemplate(template)),
    setSelectedDrawerState: (drawerState) => dispatch(setSelectedDrawerState(drawerState)),
    checkTemplateRules: (organization_id) => dispatch(checkTemplateRules(organization_id))
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);