import React from "react";
import { makeStyles } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import moment from "moment";
import { getReadableActivityInHTML,displayIcons } from "../../util/AppUtil";
import { Divider } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  activtyIconContainer: {
    backgroundColor: "#3d92ff",
    minHeight: "30px",
    height: "30px",
    width: "30px",
    boxShadow: "none",
  },
  activtycommentIcon: {
    height: "15px",
    width: "15px",
    color: "white",
    transform: "scaleX(-1)",
    fontSize: "1rem",
  },
  activitycommentedBy: {
    fontSize: "16px",
    marginLeft: "10px",
    color: "black",
    fontWeight: 500,
    marginTop:"0px"
  },
  activitycommentedOn: {
    fontSize: "14px",
    color: "#000",
    margin: "0px 5px",
    fontWeight: 400,
  },
  activitycommentTime: {
    fontSize: "14px",
    marginLeft: "12px",
    color: "#000000",
    fontWeight: "350",
    marginTop: "2px",
  },
  activtyIcons: {
    width: "15px",
    color: "white !important",
    marginLeft:'0px',
    marginTop:'-2px',
    fontSize: "14px",
    lineHeight:'0 !important',
  },
}));

 
const ActivityItem = ({ singleActivity }) => {
  const classes = useStyles();
  const time = moment(singleActivity.created_at).fromNow();
  const innerHtmlContent = getReadableActivityInHTML(singleActivity.activity_text);
 
  return (
    <>
      <Grid container direction="row" className="activityParent">
        <Grid item direction="column" style={{ marginTop: "-3px" }}>
          <Fab className={classes.activtyIconContainer}>
            <i 
              id="activityIcons"
              className={`${displayIcons(singleActivity)} ${classes.activtyIcons}`}
            ></i>
          </Fab>
          <Divider orientation="vertical" className="horizontalDivider" />
        </Grid>
        <Grid item direction="column" sm={10}>
          <Typography
            className={classes.activitycommentedBy}
            dangerouslySetInnerHTML={{ __html: innerHtmlContent }}
          ></Typography>
          <Grid item direction="row">
            <Typography className={classes.activitycommentTime}>
              {time}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ActivityItem;
