import React, {useState, useEffect } from 'react';
import {Drawer, Grid, Typography, Button, CircularProgress, Divider, Avatar} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {connect} from 'react-redux';
import {getNotificationListApi} from '../../services/authService';
import {consoleToLog, getReadableActivityInHTML} from '../../util/AppUtil';
import { useIsMount } from '../../components/useIsMount';
import {database} from '../../firebase/firebase';
import moment from 'moment';
import {setSelectedTemplate, setSelectedDrawerState, SelectedDrawerState} from '../../actions/selectedOrganization';
import { useSnackbar } from 'notistack';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    drawerPaper: {
        backgroundColor: theme.palette.primary.light,
        width: '100%', 
        height: '100vh',
        flexGrow:1,
        overflowY:'auto',
        overflowX:'hidden'
    },
    backIcon: {
        color: "#202020",
        border: " 1px solid #202020",
        padding: "4px 0px",
        fontSize: "18px",
        minWidth: "40px",
        lineHeight: "18px",
        marginBottom: "10px",
        marginRight: "16px",
    },
    mobileHeading: {
        fontSize: '18px',
        fontWeight: 500,
        marginTop:'-8px'
    },
    MuiAvatarroot : {
        marginTop:'2px',
        width:"38px !important",
        height:"38px !important",
        fontSize:'16px !important',
        fontWeight:'600 !important',
        boxSizing: 'border-box !important',
            '& .MuiAvatar-img' : {
                border:'none'
            }
    },
    newPostContainer : {
        position: '-webkit-fixed',
        position: 'fixed',
        top: '18%',
        ["@media (min-width:1400px)"]: {
            top: '15%',
        },
        zIndex: '999'
    },
    showNewPostsButton : {
        backgroundColor: '#20a8e0',
        color: 'white',
        fontSize: '11px',
        fontWeight: 500,
        border: 'none',
        borderRadius: '25px !important',
        //z-index: 9;
        margin: '0px auto !important',
        padding: '2px 16px',
        boxShadow: '0 0 0 1px lighten(#20a8e0, 50%)',
        transition: 'all 0.3s',
        left: '100% !important'
    },
    doubleArrowIcon: {
        transform: 'rotate(-90deg) !important',
        fontSize:'14px', 
        marginRight:'4px'
    },
    textContainer: {
        whiteSpace:'nowrap', 
        overflow:'hidden', 
        textOverflow:'ellipsis'
    },
    loading: {
        position:'relative',
        left:'50%',
        right:'50%',
        top:'30px'
    },
}))

const MobileNotificationDrawer = (props) => {
    const classes = useStyles();
    const isMount = useIsMount();
    const {enqueueSnackbar} = useSnackbar();

    const [page, setPage] = useState(1);
    const [nextPage, setNextPage] = useState(false);
    const [notificationList, setNotification] = useState([]);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [isMoreLoading, setIsMoreLoading] = useState(false);

    let notifiCount = (props.selectedOrganization &&
        props.selectedOrganization.organization &&
        props.selectedOrganization.organization.id &&
        props.notifications &&
        props.notifications[props.selectedOrganization.organization.id] &&
        props.notifications[props.selectedOrganization.organization.id] !== 0
    ) ? props.notifications[props.selectedOrganization.organization.id] :  0;

    useEffect(() => {
        getNotificationList();
    }, []);

    useEffect(() => {
        if(!isMount) {
            getNotificationList();
        }
    }, [props.selectedOrganization.organization?.id]);

    useEffect(() => {
        if(!isMount) {
            if(isMoreLoading) {
                consoleToLog('Notifications IsMoreLoading Clicked');
                getNotificationList();
            }
        }

    }, [isMoreLoading])

    useEffect(() => {
        if(!isMount) {
            if(page === 1) {
                getNotificationList();
            }
        }
    }, [page]);

    const getNotificationList = () => {
        const access_token = props.auth.access_token;
        const organization_id = props.selectedOrganization.organization?.id;

        if(!isMoreLoading) setLoading(true);
        getNotificationListApi(access_token, organization_id, page)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response getNotificationListApi: ", res);
                setLoading(false);
                //set State 
                if (isMoreLoading) { // next pages
                    const notificationItems = notificationList.concat(res.notifications)
                    setNotification(notificationItems);
                    setNextPage(res.next_page);
                    setPage(page + 1);
                    setIsMoreLoading(!isMoreLoading);
                } else { //first time 
                    setNextPage(res.next_page)
                    if (res.next_page) setPage(page + 1);
                    setNotification(res.notifications);
                    setTotal(res.total);
                    setTimeout(() => {resetFirebaseCount(organization_id)}, 400);
                    
                }
            })
            .catch((e) => {
                consoleToLog(" getNotificationListApi error", e.response);
                setLoading(false);
                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant:'error'
                    });
                    return false;
                }
            });
    }

    const resetFirebaseCount = async (organization_id) => {
        const user_id = props.auth.user.id;
        var docRef = database.collection("users").doc(`${user_id}`)

        docRef.get().then(function (doc) {
            if (doc.exists) {
                let allData = doc.data();
                var orgs = allData.organizations;
                orgs[`${organization_id}`] = 0;
                docRef.set({ organizations: orgs }).then(() => {
                    consoleToLog('Firebase count reset successfully');
                })
            } else {
                consoleToLog('To reset count: doc not exists');
            }
        }).catch(function (error) {
            consoleToLog("FirebaseService: Error getting document:", error);
        });
    }

    const showNotificationList = () => {
        return (<Grid item>
                    {
                        notificationList && notificationList.length > 0 ?
                        notificationList.map((notificationObj) => {
                        const time = moment(notificationObj.created_at).fromNow();
                        const innerHtmlContent = getReadableActivityInHTML(notificationObj.notification_text);
                        const image = notificationObj.source_user.image ? notificationObj.source_user.image : undefined;
                        const fullName = notificationObj.source_user.firstname.charAt(0).toUpperCase() + notificationObj.source_user.lastname.charAt(0).toUpperCase();
                        const templateObj = notificationObj.template;
                        var stripedHtml = notificationObj.notification_text.replace(/<[^>]+>/g, '');
                        let textInfo = notificationObj.template.name + ' ' + '-' + ' ' + notificationObj.checklist.title + ' ' + (notificationObj.checklist.subtitle);
                        
                        return <Grid item xs={12} sm={12} key={notificationObj.id} 
                                    style={{padding:'8px 0px', background:'#fff'}}
                                    id={notificationObj.id.toString()}> 
                                    <Link 
                                        style={{
                                            textDecoration: 'none',
                                            display:'inline-block', 
                                            marginBottom:'16px',
                                            padding:'0px 12px',
                                            cursor: `${notificationObj.notification_type === "setup_completed" ? 'default' : 'pointer'}`
                                        }}
                                        to={notificationObj.notification_type === "setup_completed" ?
                                            {} : {
                                                pathname: `/checklist/${notificationObj.checklist.slug_code}`,
                                                notificationObj: {
                                                    ...notificationObj,
                                                    user: notificationObj.source_user
                                                }
                                            }}
                                        //onClick={() => setFromChecklistItemPage(true)}
                                        onClick={(e) => {
                                                props.setSelectedDrawerState(SelectedDrawerState.NOT_SELECTED);
                                                props.setSelectedTemplate(templateObj);
                                                props.handleNotificationDrawerClose();
                                                //props.handleNotificationClose();
                                            }
                                            }>
                                        <div style={{display:'flex'}}>
                                            <Grid item xs={1} style={{flexBasis:'4%', maxWidth:'4%', 
                                                marginRight:  notificationObj.notification_type === "setup_completed" ? "38px" : '32px'}}>  
                                                <Avatar 
                                                    classes={{
                                                        root: classes.MuiAvatarroot
                                                    }}
                                                    size={40}
                                                    src={image}
                                                    >
                                                {fullName}       
                                                </Avatar>        
                                            </Grid>
                                            
                                            <Grid item sm={notificationObj.notification_type === "setup_completed" ? 9 : 10}  style={{marginLeft:'8px', color:'#212121'}}>
                                                <Grid item container 
                                                    akignItems={notificationObj.notification_type !== 'setup_completed' && 'center'}>
                                                    <Grid item style={{position:'relative', wordBreak:'break-word'}}>
                                                        <Typography variant='body1'
                                                            dangerouslySetInnerHTML={{ __html: innerHtmlContent }}
                                                        ></Typography>
                                                    </Grid>

                                                    <Grid item container direction="column" style={{marginTop:'5px'}}>
                                                    {notificationObj.notification_type !== 'setup_completed' &&
                                                        <>
                                                            <Grid item> 
                                                                <Typography style={{color:'#0071FF', fontSize:'14px'}}>
                                                                    {notificationObj.template.name}
                                                                </Typography>
                                                            </Grid>

                                                            <Grid item style={{marginTop:'4px'}}>
                                                                <Typography variant="subtitle1">
                                                                { `${notificationObj.checklist.title} ${notificationObj.checklist.subtitle ? 
                                                                    '(' + notificationObj.checklist.subtitle + ')' : ''}`}
                                                                </Typography>
                                                            </Grid>
                                                        </>}

                                                        <Grid item style={{marginTop: notificationObj.notification_type === "setup_completed" ? '-4px' : '4px'}}>
                                                            <Typography variant="subtitle1" style={{color:'#777'}}> 
                                                                {time} 
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Link>
                                    <Divider light style={{width:'100%'}}/> 
                                </Grid> 

                        }) :
                        <Grid item container justifyContent='center'>
                            <Typography variant='body1'>
                                No Notifications Found
                            </Typography>
                        </Grid>
                    }

                </Grid>
        )
    }

    const loadMoreLogic = () => {
        return (
            <Grid item container justifyContent='center'>
                <Grid item lg={12}>
                {nextPage && !isMoreLoading &&
                    <div style={{textAlign: "center", margin:'16px 0px 32px 0px'}}>
                        <Button type="button"
                            color="secondary"
                            variant="contained"
                            onClick={() => {
                                setIsMoreLoading(!isMoreLoading);
                            }}>Load More
                        </Button>
                    </div>}

                { isMoreLoading &&
                    <CircularProgress size={25} style={{position:'relative', left:'50%', right:'50%', margin:'16px 0px'}}/>
                }
                </Grid>
            </Grid>
        )
    }

    const onRefreshClick = () => {
        setPage(1);
        setNextPage(false)
    }

    const showNewPostsButton = () => {
        return (
            <Grid item className={classes.newPostContainer} style={{display:(notifiCount !== 0) ? 'block' : 'none'}}>
                { (notifiCount !== 0) ? 
                    <Button variant="contained" 
                        className={classes.showNewPostsButton} onClick={onRefreshClick}>
                        <DoubleArrowIcon className={classes.doubleArrowIcon}/>
                        New Notifications Arrived
                    </Button> : <Grid item></Grid>
                }
            </Grid>
        );
    }


    return (
        <Drawer open={props.openNotificationDrawer} 
            onClose={props.handleNotificationDrawerClose}
            variant='persistent'
            anchor='right'
            elevation={0}
            classes={{
                paper: classes.drawerPaper
            }}>
                <Grid item xs={12}>
                        <Grid item container alignItems='center'
                            style={{padding:'16px'}}>
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    className={classes.backIcon}
                                    onClick={props.handleNotificationDrawerClose}
                                > 
                                    <ArrowBackIcon /> 
                                </Button>
                            </Grid>

                            <Grid item>
                                <Typography variant="body1" 
                                    className={classes.mobileHeading}>
                                    Notifications
                                </Typography>
                            </Grid>
                        </Grid>

                        {
                            loading ? <CircularProgress size={25} className={classes.loading}/> 
                                    : <Grid item xs={12}>
                                        {showNewPostsButton()}
                                        {showNotificationList()}
                                        {loadMoreLogic()}
                                    </Grid>  
                        }

                </Grid>
            
        </Drawer>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    members: state.organizationInfo.members,
    notifications: state.notifications,
});

const mapDispatchToProps = (dispatch) => ({
    setSelectedTemplate: (template) => dispatch(setSelectedTemplate(template)),
    setSelectedDrawerState: (drawerState) => dispatch(setSelectedDrawerState(drawerState))
})

export default connect(mapStateToProps, mapDispatchToProps)(MobileNotificationDrawer);