import React , {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Grid, Typography, Button, CircularProgress} from '@material-ui/core';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import AddEditEmailDraftModal from './modals/AddEditEmailDraftModal';
import {connect} from 'react-redux';
import {deleteEmailDraftApi, getEmailDraftListApi} from '../services/genieService';
import { useSnackbar } from 'notistack';
import { consoleToLog, trackGAEvent } from '../util/AppUtil';
import {checkEmailDraftRuleApi} from '../services/authService';
import {deleteOrgEmailDraft} from '../actions/selectedOrganization';

const useStyles = makeStyles((theme) => ({
    settingsContainer: {
        width:'100%',
        background:'#fff',
        borderRadius:'4px',
        padding:'16px 24px',
        marginTop:'16px',
        marginBottom:'32px'
    },
    emailDraftListContainer : {
        position:'relative',
        background:'#F9F9F9',
        padding:'12px 16px',
        borderRadius:'8px',
        marginBottom:'12px',
        '& .hoverIcons' : {
            visibility:'hidden',
            minWidth:'20px',
            padding:'0 8px',
            fontSize:'18px'
        },
        '&:hover' : {
            background: 'rgba(0, 113, 255, 0.08)'
        },
        '&:hover .hoverIcons' : {
            visibility:'visible',
            minWidth:'20px',
            fontSize:'18px'
        },
    },
    mailIcon : {
        position:'absolute',
        marginRight:'16px',
        lineHeight:0
    },
    customClasses1 : {
        maxWidth:'13%',
        flexBasis:'13%',
    },
    emailSubject : {
        lineHeight:'18px'
    },
    emailBody : {
        lineHeight:'18px',
        color:'#6F7378',
        maxWidth:'auto !important',
        overflow:'hidden !important',
        whiteSpace:'nowrap !important',
        textOverflow:'ellipsis !important',
    },

}));

const EmailDrafts = (props) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => {
        getEmailDraftList()
    }, [])
    
    const [emailDraftObj, setEmailDraftObj] = useState(undefined);
    const [emailDraftList, setEmailDraftList] = useState([]);
    const [loading, setLoading] = useState(false);



    const onEditEmailDraftClick = (e, draft) => {
        setEmailDraftObj(draft);
        props.setOpenDrawer(true);
    }

    const getEmailDraftList = () => {
        const organinzation_id = props.selectedOrganization.organization.id;

        setLoading(true);
        getEmailDraftListApi(organinzation_id)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response getEmailDraftListApi: ', res);
                setLoading(false);

                setEmailDraftList(res.email_drafts);
            })
            .catch((e) => {
                consoleToLog("Error getEmailDraftListApi: ", e.response);
                setLoading(false);

            })
    }

    const onDeleteEmailDraftClick = (e, draft) => {
        if(window.confirm("Are you sure? You want to delete this draft?")) {
            const id = draft.id;

            checkEmailDraftRuleApi(props.selectedOrganization.organization.id, id)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response checkEmailDraftRuleApi: ', res);

                res.message && 
                enqueueSnackbar(res.message, {variant:'error'});

                    if(!res.message) {

                        deleteEmailDraftApi(id)
                        .then((response) => {
                            const res = response.data;
                            consoleToLog('Response deleteEmailDraftApi ', res);
                            enqueueSnackbar(res.message, {
                                variant:'success'
                            });

                            trackGAEvent(props.selectedOrganization.organization.name, 'Email draft Deleted', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
                            props.deleteOrgEmailDraft(id);
                            const updatedEmailDraftArr = emailDraftList.filter((draft) => draft.id !== id);
                            setEmailDraftList(updatedEmailDraftArr);

                        })
                        .catch((e) => {
                            consoleToLog("deleteEmailDraftApi error", e);
                            if (e.response.data && e.response.data.message) {
                                enqueueSnackbar(e.response.data.message, {
                                    variant:'error'
                                })
                                return false;
                            }
                        });
                    }
            })
            .catch((e) => {
                consoleToLog('Error checkEmailDraftRuleApi: ', e.response);
                if(e.response.data && e.response.data.message) {
                    return;
                }
            })


            
        }  

    }

    const EmailDraftListUI = () => {
        return (
            <Grid item md={12} lg={12}>
            {
            emailDraftList && emailDraftList.length > 0 ? 
            emailDraftList.map((draft) => {
                const emailBody = draft.body.replace(/<[^>]*>?/gm, '');
                return <Grid item container
                            key={draft.id}
                            className={classes.emailDraftListContainer}
                            alignItems='center'
                            justifyContent='space-between'>
                            <Grid item className={classes.mailIcon}>
                                <MailOutlineIcon />
                            </Grid>

                            <Grid item md={9} lg={9} style={{marginLeft:'40px'}}>
                                <Typography variant="body1" className='schedulerTitle'>
                                    {draft.title}  
                                </Typography>

                                <Typography variant="subtitle1" className={classes.emailSubject}>
                                    {draft.subject}
                                </Typography>
                                <Typography variant="subtitle1"  className={classes.emailBody}>
                                        {emailBody}
                                </Typography>
                            </Grid>

                            <Grid item md={2} className={classes.customClasses1}>
                                <Grid item container justifyContent="flex-end">
                                    <div style={{marginTop:'7px'}}>
                                        <Button className="hoverIcons" 
                                            onClick={(e) => onEditEmailDraftClick(e, draft)}>
                                            <i className="flaticon-edit"></i>
                                        </Button >
                                        <Button className="hoverIcons"
                                            onClick={(e) => onDeleteEmailDraftClick(e, draft)}>
                                            <i className="flaticon-delete-1"></i>
                                        </Button>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    }) :
                        <Grid item container className={classes.emailDraftListContainer} justifyContent='center'>
                                <Typography variant='body1'>
                                    No Email Drafts Found
                                </Typography>
                        </Grid>
                }
                </Grid>
        )
    }

    return (
        <Grid item md={12} className={classes.settingsContainer}>
            {loading ?
                <CircularProgress size={30} style={{position:'relative', left:'50%', right:'50%', margin:'16px 0px'}}/>
                :
                <EmailDraftListUI />
            }

            <AddEditEmailDraftModal openDrawer={props.openDrawer}
                handleDrawerClose={props.handleDrawerClose} 
                emailDraftObj={emailDraftObj}
                setEmailDraftObj={setEmailDraftObj}
                setEmailDraftList={setEmailDraftList}
                emailDraftList={emailDraftList}
                />
        </Grid>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization
});

const mapDispatchToProps = (dispatch) => ({
    deleteOrgEmailDraft : (email_draft_id) => dispatch(deleteOrgEmailDraft(email_draft_id))
})

export default connect(mapStateToProps, mapDispatchToProps)(EmailDrafts);