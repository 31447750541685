import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import PublishOutlinedIcon from "@material-ui/icons/PublishOutlined";
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';

import AttachmentItem from "./AttachmentItem";
import { connect } from "react-redux";
import { useIsMount } from "../useIsMount";
import CircularProgress from "@material-ui/core/CircularProgress";
import S3FileUpload from "react-s3";
import {
  consoleToLog,
} from "../../util/AppUtil";
import { useSnackbar } from "notistack";
import { downloadAllAttchmentsApi } from "../../services/authService";
import { Link } from "react-router-dom";
import { setLoading } from "../../actions/loading";
import UploadAttachmentModal from "../modals/UploadAttachmentModal";

const useStyles = makeStyles((theme) => ({
  notAttachmentsImage: {
    height: "13rem",
    width: "15rem",
    marginTop: "3rem",
  },
  notAttachmentsText: {
    fontSize: "20px",
    fontWeight: "600",
  },
  notAttachmentsButton: {
    fontSize: "15px",
    width: "19rem",
    marginTop: "20px",
  },
  uploadAttachmentLargeBtn: {
    fontSize: "15px",
    width: "12rem",
    marginLeft: "4px"
  },
  circularProgress: {
    marginLeft: 0,
    marginRight: theme.spacing(2),
    color: 'white'
},  
}));

const ChecklistAttachments = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const isMount = useIsMount();
  const { enqueueSnackbar } = useSnackbar();
  const [attachmentList, setAttachmentList] = useState(props.attachmentList);
  const [downloadableUrl, setDownloadableUrl] = useState(undefined);
  const [loading1, setLoading1] = useState(undefined);

  useEffect(() => {
    setAttachmentList(props.attachmentList);
  }, []);

  useEffect(() => {
    if(!isMount) {
      setAttachmentList(props.attachmentList);
    }
  }, [props.attachmentList]);

  useEffect(() => {
    if (downloadableUrl !== undefined) {
      document.getElementById("initiateDownloadAttachmentAll").href = downloadableUrl;
      document.getElementById("initiateDownloadAttachmentAll").click();
    }
  }, [downloadableUrl]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const onAttachmentDelete = (attachment_id) => {
    var updatedDeleteAttachmentArray = attachmentList.filter(
      (attachment) => attachment.id !== attachment_id
    );
    consoleToLog("updatedDeleteAttachmentArray in child",updatedDeleteAttachmentArray);
    setAttachmentList(updatedDeleteAttachmentArray);

    // to parent
    props.onAttachmentDelete(updatedDeleteAttachmentArray);
  };

  const downloadAllAttachments = () => {
    const checklist_id = props.checklistId;
    setLoading1(true);
    downloadAllAttchmentsApi(checklist_id)
    .then((response) => {
        const res = response.data;
        consoleToLog("Response downloadAllAttchmentsApi: ", res);
        const downloadableURL = res.document_signed_url;
        setDownloadableUrl(downloadableURL);
        setLoading1(false);
    })
    .catch((e) => {
      consoleToLog(" downloadAllAttchmentsApi error", e.response);
      setLoading1(false);
    });
  }

  // AttachmentsNotFound
  const AttachmentsNotFound = () => {
    return (
      <div>
        <Grid container direction="row">
          <Grid
            item
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            sm={12}
          >
            <img
              alt="No Attachments Found"
              src="/images/no_attachment_found.svg"
              className={classes.notAttachmentsImage}
            />
          </Grid>
          <Grid
            item
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Typography className={classes.notAttachmentsText}>
              No Attachments Found
            </Typography>
          </Grid>
          <Grid
            item
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <Button
              variant="contained"
              color="primary"
              endIcon={<PublishOutlinedIcon />}
              size="large"
              className={classes.notAttachmentsButton}
              onClick={handleDrawerOpen}
            >
              Upload Attachment
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  };

  const AttachmentList = () => {
    return (
      <Grid item container direction="row" sm={12} alignItems="center">
        <Grid
          item
          container
          direction="column"
          sm={1}
          className={classes.attachmentsHeader}
        ></Grid>
        <Grid
          item
          container
          direction="column"
          sm={3}
          className={classes.attachmentsHeader}
          style={{ paddingLeft: "10px" }}
        >
          File
        </Grid>
        <Grid
          item
          container
          direction="column"
          sm={3}
          className={classes.attachmentsHeader}
        >
          Uploaded By
        </Grid>
        <Grid
          item
          container
          direction="column"
          sm={2}
          className={classes.attachmentsHeader}
        >
          Uploaded
        </Grid>
        <Grid
          item
          container
          direction="column"
          sm={2}
          className={classes.attachmentsHeader}
        ></Grid>
      </Grid>
    );
  };

  const AttachmentFound = () => {
    return (
      <div>
        <Grid container style={{ marginBottom: "14px" }}>
          <Grid item container direction="row" justifyContent="flex-end">
            {attachmentList && attachmentList.length > 1 &&  <Button
              variant="contained"
              className={classes.uploadAttachmentLargeBtn}
              color="primary"
              endIcon={<GetAppOutlinedIcon />}
              size="medium"
              onClick={downloadAllAttachments}
            >
            {loading1 && <CircularProgress size={24} className={classes.circularProgress}/>}Download All
            </Button>}
            <Link
              className="d-none"
              id="initiateDownloadAttachmentAll"
              target="_blank"
              onClick={(e) => e.stopPropagation()}
              download
            ></Link>    
          <Button
              variant="contained"
              className={classes.uploadAttachmentLargeBtn}
              color="primary"
              endIcon={<PublishOutlinedIcon />}
              size="small"
              onClick={handleDrawerOpen}
            >
              Upload
            </Button>
          </Grid>
          <AttachmentList />
        </Grid>

        {attachmentList &&
          attachmentList.map((attachment) => {
            return (
              <AttachmentItem
                key={attachment.id}
                isFromChecklistPage={true}
                singleattachment={attachment}
                checklistId={props.checklistId}
                onAttachmentDelete={onAttachmentDelete}
              />
            );
          })}
      </div>
    );
  };

  return (
    <>
      {attachmentList && attachmentList.length > 0 ? (
        <AttachmentFound />
      ) : (
        <AttachmentsNotFound />
      )}

      <UploadAttachmentModal open={open}
          handleDrawerClose={handleDrawerClose}
          title='Upload Attachment'
          attachmentList={attachmentList}
          setAttachmentList={setAttachmentList}
          checkListItem={props.checkListItem}
          fromChecklistDetails={true}
          onAttachmentAdded={props.onAttachmentAdded}
        />
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  selectedOrganization: state.organizationInfo.selectedOrganization,
  selectedTemplate: state.organizationInfo.selectedTemplate
});

export default connect(mapStateToProps, null)(ChecklistAttachments);
