import React, { useState, useEffect } from 'react';
import { makeStyles, } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { getOrganizationList,  setSelectedTemplate } from '../../actions/selectedOrganization';
import { setLoading } from '../../actions/loading';
import { CircularProgress, Grid } from "@material-ui/core";
import {useIsMount} from '../../components/useIsMount';
import {history} from '../routers/MobileAppRouter';
import {SelectedDrawerState} from '../../actions/selectedOrganization';
import MobileHeader from '../components/MobileHeader';
import MobileLeftMenuComponent from '../components/MobileLeftMenuComponent';
import MobileChecklistComponent from '../components/MobileChecklistComponent';
import MobileEmailReports from '../components/MobileEmailReports';
import MobileOrganizationMembers from '../components/MobileOrganizationMembers';
import MobileOrganizationLabels from '../components/MobileOrganizationLabels';
import MobileOrganizationClients from '../components/MobileOrganizationClients';
import MobileOrganizationSettings from '../components/MobileOrganizationSettings';
import MobileMyProfileComponent from '../components/MobileMyProfileComponent';
import MobileNotificationDrawer from '../components/MobileNotificationDrawer';
import MobileCompletionReports from '../components/MobileCompletionReports';
import MobileWorkloadReports from '../components/MobileWorkloadReports';

const useStyles = makeStyles((theme) => ({
    displayFlex: {
        display:'flex',
        flexDirection:'column',
        height: "100%"
    },
    loading: {
        position:'absolute',
        left:'50%',
        right:'50%',
        top:'40%',
    }

}));

const MobileDashboardPage = (props) => {
    const classes = useStyles();
    const [showDrawer, setShowDrawer] = useState(false);
    const [page, setPage] = useState(1);
    const [nextPage, setNextPage] = useState(false);

    const [openNotificationDrawer, setOpenNotificationDrawer] = useState(false);

    const is_orgEmail_verified = props.selectedOrganization.organization?.is_email_verified;

    const onShowDrawerClick = () => {
        setShowDrawer(!showDrawer);
    }

    const closeDrawer = () => {
        setShowDrawer(false);
    }

    const pageReset =  () => {
        setPage(1);
        setNextPage(false);
    }

    const handleNotificationDrawerOpen = () => {
        setOpenNotificationDrawer(true);
    }

    const handleNotificationDrawerClose = () => {
        setOpenNotificationDrawer(false);
    }

    useEffect(() => {
        document.title="eProcessify";
        props.setLoading(true);
        props.getOrganizationList(props.selectedOrganization, props.startDateFilter, props.endDateFilter, 
            props.companiesFilter, props.selectedMembersFilter, props.selectedTemplate, props.auth.user?.scope);
    }, []);

    const isMount = useIsMount();

    useEffect(() => {
        if(isMount) {
            //consoleToLog('Template Component first render useEffect')
        } else {
            history.push(`/dashboard`)
        }
    }, [props.selectedOrganization.organization?.id]);

    const displaySelectedComponent = () => {
        if(props.setDrawerState === SelectedDrawerState.SHOW_EMAIL_REPORT) {
                return <MobileEmailReports is_orgEmail_verified={is_orgEmail_verified}/>
        }
        if(props.setDrawerState === SelectedDrawerState.SHOW_ORG_MEMBERS) {
            return <MobileOrganizationMembers is_orgEmail_verified={is_orgEmail_verified}/>
        }  
        if(props.setDrawerState === SelectedDrawerState.SHOW_ORG_LABELS) {
            return <MobileOrganizationLabels is_orgEmail_verified={is_orgEmail_verified}/>
        }
        if(props.setDrawerState === SelectedDrawerState.SHOW_ORG_COMPANIES) {
            return <MobileOrganizationClients is_orgEmail_verified={is_orgEmail_verified}/>
        }
        if(props.setDrawerState === SelectedDrawerState.SHOW_ORG_SETTINGS) {
            return <MobileOrganizationSettings is_orgEmail_verified={is_orgEmail_verified}/>
        }
        if(props.setDrawerState === SelectedDrawerState.SHOW_MY_PROFILE) {
            return <MobileMyProfileComponent is_orgEmail_verified={is_orgEmail_verified}/>
        }
        if(props.setDrawerState === SelectedDrawerState.SHOW_COMPLETION_REPORT) {
            return <MobileCompletionReports is_orgEmail_verified={is_orgEmail_verified}/>
        }
        if(props.setDrawerState === SelectedDrawerState.SHOW_WORKLOAD_REPORT) {
            return <MobileWorkloadReports is_orgEmail_verified={is_orgEmail_verified}/>
        }
        else if(props.selectedTemplate !== '') {
            return <MobileChecklistComponent page={page}
                is_orgEmail_verified={is_orgEmail_verified}
                nextPage={nextPage}/>
        } 
    }

    return (
        <div className={classes.displayFlex}>
            {props.isLoading 
                    ? 
                    <CircularProgress 
                        size={25}
                        className={classes.loading}
            /> :
            <>
            <MobileHeader onShowDrawerClick={onShowDrawerClick}
                pageReset={pageReset}
                handleNotificationDrawerOpen={handleNotificationDrawerOpen}/>
                {displaySelectedComponent()}
            </>
            }

            <MobileLeftMenuComponent showDrawer={showDrawer} 
                closeDrawer={closeDrawer}/>

            {props.selectedOrganization.organization?.id &&
            <MobileNotificationDrawer openNotificationDrawer={openNotificationDrawer}
                handleNotificationDrawerClose={handleNotificationDrawerClose}/>}
        </div>

    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    organizations: state.organizations,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    isLoading: state.loading.isLoading,
    selectedTemplate: state.organizationInfo.selectedTemplate,
    startDateFilter: state.filters.startDateFilter,
    endDateFilter: state.filters.endDateFilter,
    selectedMembersFilter: state.filters.selectedMembersFilter,
    companiesFilter: state.filters.companiesFilter,
    setDrawerState: state.organizationInfo.setDrawerState,
    showCreateTemplate: state.organizationInfo.showCreateTemplate,
    templates: state.organizationInfo.templates,
    showUnassignedChecklist: state.filters.showUnassignedChecklist,
    selectedStatusFilter: state.filters.selectedStatusFilter,
});

const mapDispatchToProps = (dispatch) => ({
    getOrganizationList: (selectedOrganization, start_date, end_date, companiesFilter, selectedMembersFilter, selectedTemplate, scope) => 
        dispatch(getOrganizationList(selectedOrganization, start_date, end_date, companiesFilter, selectedMembersFilter, selectedTemplate, scope)),
    setLoading: (isLoading) => dispatch(setLoading(isLoading)),
    setSelectedTemplate: (template) => dispatch(setSelectedTemplate(template))
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileDashboardPage);