
/**
 * 
 * activity Report filters
 */
 
//members
export const addActivityReportMemberToFilter = (member) => ({
    type: 'ADD_ACTIVITY_REPORT_MEMBER_TO_FILTER',
    member
})

export const removeActivityReportMemberFromFilter = (member) => ({
    type: 'REMOVE_ACTIVITY_REPORT_MEMBER_FROM_FILTER',
    member
})

// export const clearActivityReportMembersFilter = () => ({
//     type: 'CLEAR_ACTVITY_REPORT_MEMBERS_FILTER'
// })


/**
 * Activity Report date filter (start/end)
 * 
 */
export const setActivityReportStartDateFilter = (start_date) => ({
    type: 'SET_ACTIVITY_REPORT_START_DATE_FILTER',
    start_date
})

export const setActivityReportEndDateFilter = (end_date) => ({
    type: 'SET_ACTIVITY_REPORT_END_DATE_FILTER',
    end_date
})

export const clearActivityReportFilter = () => ({
    type: 'CLEAR_ACTIVITY_REPORT_FILTER'
})
