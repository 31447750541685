import React, {useState, useEffect} from 'react';
import {Grid, Typography, Button, FormControlLabel, Checkbox} from '@material-ui/core';
import {connect} from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import { consoleToLog } from '../util/AppUtil';
import {orgOnboardingUpdateApi} from '../services/authService';
import { updateSelectedOrg } from '../actions/selectedOrganization';
import { updateOrganization } from '../actions/organization';
import {assignMemberToRecurringChecklist} from '../services/authService';

const useStyles = makeStyles((theme) => ({
    settingsContainer: {
        width:'100%',
        background:'#fff',
        borderRadius:'4px',
        padding:'16px 24px',
        marginTop:'16px',
        marginBottom:'32px'
    },
}))

const PreferencesSettings = (props) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();

    const onboarding = props.selectedOrganization.organization?.onboarding;
    const assign_member_to_checklist = props.selectedOrganization.organization?.assign_checklist_on_recurring;

    const onboardingCheckboxClick = (e) => {
        const organization_id = props.selectedOrganization.organization.id;
        const onboardingValue = e.target.checked;

        orgOnboardingUpdateApi(organization_id, onboardingValue)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response orgOnboardingUpdateApi: ", res);
                enqueueSnackbar(res.message, {variant:'success'});

                props.updateOrganization(res.organization);//update organization list in redux
                props.updateSelectedOrg(res.organization);//update selected_org in redux 
            })
            .catch((e) => {
                consoleToLog("orgOnboardingUpdateApi error", e.response);
                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant:'error'
                    });
                    return false;
                }
            });

    }

    const onAssignChecklistClick = (e) => {
        const organization_id = props.selectedOrganization.organization.id;
        const assignValue = e.target.checked;

        assignMemberToRecurringChecklist(organization_id, assignValue)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response assignMemberToRecurringChecklist: ", res);
                enqueueSnackbar(res.message, {variant:'success'});

                props.updateOrganization(res.organization);//update organization list in redux
                props.updateSelectedOrg(res.organization);//update selected_org in redux 
            })
            .catch((e) => {
                consoleToLog("assignMemberToRecurringChecklist error", e.response);
                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant:'error'
                    });
                    return false;
                }
            });

    }

    return (
        <div className={classes.settingsContainer}>
            <Grid item md={12}>
                <FormControlLabel control={
                    <Checkbox  
                        onChange = {onboardingCheckboxClick}
                        checked={onboarding}
                    />
                    } 
                    label="Show Onboarding" />
            </Grid>
            <Grid item md={12}>
                <FormControlLabel control={
                    <Checkbox  
                        onChange = {onAssignChecklistClick}
                        checked={assign_member_to_checklist}
                    />
                    } 
                    label="Assign Checklist on Recurring Basis" />
            </Grid>
        </div>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization
});

const mapDispatchToProps = (dispatch) => ({
    updateOrganization: (organization) => dispatch(updateOrganization(organization)),
    updateSelectedOrg: (organization) => dispatch(updateSelectedOrg(organization))
});

export default connect(mapStateToProps, mapDispatchToProps)(PreferencesSettings);