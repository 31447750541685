import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Typography, Button, CircularProgress, Avatar, Tooltip, TextField, InputAdornment, ClickAwayListener} from '@material-ui/core';
import {connect} from 'react-redux';
import {orgCompaniesListApi, deleteCompanyApi} from '../services/authService';
import { consoleToLog, trackGAEvent, shouldDisable, AccessLevel } from '../util/AppUtil';
import { useIsMount } from './useIsMount';
import ClientDetailsModal from "../components/modals/ClientDetailsModal";
import { useSnackbar } from 'notistack';
import {getClientsListApi} from '../services/clientService';
import {deleteClientApi} from '../services/clientService';

const useStyles = makeStyles((theme) => ({
    loading: {
        position:'relative',
        left:'50%',
        right:'50%',
        marginTop:'1%'
    },
    headings: {
        fontWeight:'500',
        color:'#202020',
        [theme.breakpoints.down(1400)]: {
            fontSize:'14px'
        },
    },
    itemTitleList: {
        background:'#f9fafb',
        borderBottom: '1px solid #edf2f7',
    },
    itemListContainer : {
        background:'white', 
        minWidth:"100%", 
        padding:'10px 16px', 
        color:'#202020',
        position:'relative',
        borderBottom: '1px solid #edf2f7',
        cursor:'pointer',
        '& .action__icon' : {
            visibility:'hidden',
        },
        '&:hover':{
          backgroundColor:'#f7fafc',
          '& .action__icon': {
            visibility:'visible',
          }
        },
        '& .more_actionButton1' : {
            visibility:'hidden',
            width:'120px !important',
            background:'#fff',
            border:'1px solid #ccc',
            borderRadius:'5px',
            padding:'1px 0px',
            margin:'6px 0px',
            fontSize:'13px',
            [theme.breakpoints.down(1400)] : {
                position:'relative',
                width:'180px !important',
            }
        },
        '&:hover .more_actionButton1' : {
            visibility:'visible'
        },
    },
    avatarStyle: {
        width:"38px !important",
        height:"38px !important",
        fontSize:'16px !important',
        fontWeight:'600 !important',
        background:'#fff',
        color:'#202020',
        border:'1px solid #4a5568'
    },
    clientName: {
        fontSize:'14px', 
        maxWidth: "280px", 
        whiteSpace: "nowrap", 
        overflow: "hidden", 
        textOverflow: "ellipsis",
        [theme.breakpoints.down(1450)] : {
            maxWidth: "230px",
        }
    },
    emailEllipsis : {
        whiteSpace: "nowrap", 
        maxWidth: "140px", 
        overflow: "hidden", 
        textOverflow: "ellipsis",
        cursor: 'pointer'
    },
    addressHeading: {
        display:'flex', 
        fontWeight:'500',
        color:'#202020',
        [theme.breakpoints.down(1400)]: {
            fontSize:'14px',
            marginLeft:'8px'
        },
    },
    addressText : {
        display:'flex', 
        wordWrap: 'break-word', 
        width:"200px", 
        color:'#6b7280',
        [theme.breakpoints.down(1400)] :{ 
            width:"170px", 
            marginLeft:'8px'
        }
    },
    actionsIcons: {
        color: '#b5bbc2',
        fontSize: '18px',
    },
    customColumn1 : {
        flexWidth: '41%',
        maxWidth: '41%'
    },
    customColumn2 : {
        flexWidth: '18%',
        maxWidth: '18%'
    },
    customColumn3 : {
        flexWidth: '8%',
        maxWidth: '8%',
        [theme.breakpoints.down(1400)] :{ 
            flexWidth: '21%',
            maxWidth: '21%',
        }
    },
    customColumn4 : {
        flexWidth: '22%',
        maxWidth: '22%',
        [theme.breakpoints.down(1400)] :{ 
            flexWidth: '18%',
            maxWidth: '18%',
        }
    },
    customColumn5 : {
        flexWidth: '7%',
        maxWidth: '7%',
    },
    avatarStyle1: {
        width:"28px !important",
        height:"28px !important",
        fontSize:'13px !important',
        background:'#fff',
        color:'#202020',
        border:'1px solid #4a5568'
    },
    flatIcons : {
        fontSize:'15px', 
        marginRight:'6px',
        display:'flex',
        alignItems:'center'
    },
    customColumn6: {
        maxWidth:'39%',
        flexBasis:'39%'
    },
    customColumn7: {
        maxWidth:'19%',
        flexBasis:'19%'
    },
    emailEllipsis: {
        maxWidth:'170px',
        overflow:'hidden',
        whiteSpace:'nowrap',
        textOverflow:'ellipsis',
        [theme.breakpoints.down(1300)] : {
            maxWidth:'140px',
        }
    }
}))

const ClientListComponent = (props) => {
    const classes = useStyles();
    const isMount = useIsMount();
    const {enqueueSnackbar} = useSnackbar();

    const [openDialog, setOpenDialog] = useState(false);
    const [showClientDetails, setShowClientDetails] = useState();

    const { access_level } = props.selectedOrganization || {};
    const accessLevelManager = shouldDisable(AccessLevel.getUserAccessLevelValue(access_level), AccessLevel.MANAGER);

    useEffect(() => {
        if(isMount) {

        } else {
            if(props.openDetailsModal) {
                handleOpenDetailsModal(props.clientObj)
            }
        }    
    }, [props.openDetailsModal]);

    const handleOpenDetailsModal = (client) =>{
        setShowClientDetails(client)
        setOpenDialog(true);
        if(props.openAddCompanyDrawer) props.handleDrawerClose();
    }

    const handleCloseDetailsModal = () =>{
        setOpenDialog(false);
        props.setOpenDetailsModal(false);
        props.setClientObj(undefined);
    }

    const companyUpdated = (client) => {
        const new_clients_array = props.clientList.map((comp) => {
            if (comp.id === client.id) {
                return {
                    ...comp,//old
                    ...client//new
                }
            } else {
                return comp;
            }
        })

        props.setClientList(new_clients_array);
    }

    const onDeleteCompanyClick = (client_id) => {
        if (window.confirm('Are you sure you want to archive client?')) {

            //archive api call
            deleteClientApi(client_id)
                .then((response) => {
                    const res = response.data;
                    consoleToLog('Response deleteClientApi', res);
                    enqueueSnackbar(res.message, {variant: 'success'});

                    trackGAEvent(props.selectedOrganization.organization.name, 'Client Deleted', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
                    let updatedClientsArr = props.clientList.filter((client) => client.id !== client_id);
                    props.setClientList(updatedClientsArr)

                })
                .catch((e) => {
                    consoleToLog('Error deleteClientApi: ', e.response);
                    if (e.response.data && e.response.data.message) {
                        enqueueSnackbar(e.response.data.message, {
                            variant:'success'
                        });
                        return false;
                    }

                })
        }
    }

    const loadMoreLogic = () => {
        return (
            <Grid item container>
                <Grid item lg={12}>
                {props.nextPage && !props.isMoreLoading &&
                    <div style={{textAlign: "center", margin:'16px 0px'}}>
                        <Button type="button"
                            color="secondary"
                            variant="contained"
                            onClick={() => {
                                props.setIsMoreLoading(!props.isMoreLoading);
                            }}>Load More
                        </Button>
                    </div>}

                { props.isMoreLoading &&
                    <CircularProgress size={35} style={{position:'relative', left:'50%', right:'50%', margin:'16px 0px'}}/>
                }
                </Grid>
            </Grid>
        )
    }

    return (
        <div style={{width:'100%'}}>
            <Grid item sm={4} style={{margin:'16px 0px 0px auto', paddingRight:"16px"}}>
                <TextField 
                    fullWidth
                    variant='outlined'
                    margin='dense'
                    placeholder='Search Clients'
                    InputLabelProps={{style: {fontSize: 14, color: "#ccc"}}}
                    onChange={(e) => props.onSearch(e)}
                    InputProps={{
                        startAdornment: (
                        <InputAdornment position="start">
                            {/* <SearchIcon className={classes.searchicon}/> */}
                            <i className="flaticon-search"></i>
                        </InputAdornment>
                        )
                    }}
                />
            </Grid>
            <Grid item md={12} lg={12} style={{marginTop:'24px'}}>
                    <Grid item sm={12}>
                        <Grid item sm={12} className={classes.itemTitleList}>
                            <Grid item container style={{padding:'10px 16px'}}>
                                <Grid item sm={4} className={classes.customColumn1}>
                                    <Typography variant="body1" className={classes.headings}>
                                        Clients
                                    </Typography>
                                </Grid>

                                <Grid item sm={5} className={classes.customColumn6}>
                                    <Typography variant="body1"
                                        className={classes.headings}>
                                        Contacts
                                    </Typography>
                                </Grid>

                                <Grid item sm={2}  className={classes.customColumn7}>
                                    <Typography variant="body1" 
                                    className={classes.addressHeading}>
                                        Address
                                    </Typography>
                                </Grid>

                                <Grid item md={1} lg={1}
                                    style={{display:'flex', justifyContent:"center", flexGrow:1}}>
                                </Grid>

                            </Grid>
                        </Grid>

                    {props.loading ?
                    <CircularProgress size={30} className={classes.loading}/> 
                    :
                    <Grid item md={12} lg={12}>
                    {
                        props.clientList && props.clientList.length > 0  &&
                        props.clientList.map((client) => {
                            const compArr = client.name.split(" ");
                            let compStr1 = compArr[0];
                            let compStr2 = compArr[1] ? compArr[1] : '';
                            const finalStr = compStr1?.charAt(0).toUpperCase() + compStr2?.charAt(0).toUpperCase();
                            const contactList = client.contacts;
                            //const clientEmails = client.email && client.email.length > 0 ? client.email.split(',') : [];

                            return  <Grid item container 
                                            key={client.id}
                                            justifyContent="flex-start"
                                            className={classes.itemListContainer}
                                            onClick={() => handleOpenDetailsModal(client)}
                                            alignItems="center">
                                        
                                        <Grid item md={4} lg={4} className={classes.customColumn1}>
                                            <Grid item container alignItems='center'>
                                                <Grid item>
                                                    <Avatar className={classes.avatarStyle}>
                                                        {finalStr}    
                                                    </Avatar>
                                                </Grid>
                                                <Grid item style={{marginLeft: "12px"}}>
                                                    <Tooltip title={client.name} arrow>
                                                        <Typography variant='body1' className={classes.clientName}>
                                                            {client.name}
                                                        </Typography>
                                                    </Tooltip>
                                                </Grid>        
                                            </Grid>   
                                        </Grid>

                                        {/* <Grid item sm={2}>
                                            {client.relationship_managers && client.relationship_managers.length > 0 &&
                                            client.relationship_managers.map((manager) => {
                                                const firstname = manager.firstname ? manager.firstname.charAt(0).toUpperCase() : '';
                                                const lastname = manager.lastname ? manager.lastname.charAt(0).toUpperCase() : '';
                                                const fullName = firstname + lastname;
                                                return  <Grid item key={manager.id}>
                                                            <Tooltip title={manager.firstname + ' ' +  manager.lastname} arrow>
                                                                <Avatar>
                                                                    {fullName}    
                                                                </Avatar>
                                                            </Tooltip>
                                                        </Grid>
                                            })
                                            }
                                        </Grid> */}

                                        <Grid item md={5} lg={5} className={classes.customColumn6}>
                                            {
                                                contactList && contactList.length > 0 &&
                                                contactList.map((contact) => {
                                                    const clientFullName = contact.first_name + ' ' + contact.last_name;
                                                    return  <Grid item container alignItems='center' key={contact.id}>
                                                                {contact.first_name &&
                                                                <Grid item>
                                                                    <Typography variant='subtitle1'>
                                                                        {clientFullName}
                                                                    </Typography>
                                                                </Grid>}

                                                                {contact.first_name && 
                                                                <Grid item>
                                                                    <Typography variant='body2' style={{margin:'0px 6px'}}>
                                                                        -
                                                                    </Typography>
                                                                </Grid>}

                                                                <Grid item>
                                                                    <Tooltip title={contact.email} arrow>
                                                                        <Typography variant='subtitle1' 
                                                                            className={classes.emailEllipsis}>
                                                                            {contact.email}
                                                                        </Typography>
                                                                    </Tooltip>
                                                                </Grid>

                                                                {contact.phone_number && 
                                                                <Grid item>
                                                                    <Typography variant='body2' style={{margin:'0px 6px'}}>
                                                                        -
                                                                    </Typography>
                                                                </Grid>}

                                                                <Grid item>
                                                                    <Typography variant='subtitle1'>
                                                                        {contact.phone_number}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                })
                                            }
                                        </Grid>

                                        <Grid item md={2} lg={2} className={classes.customColumn7}>
                                            <Typography variant='subtitle1' 
                                                className={classes.addressText}>
                                                    {client.address}
                                            </Typography>
                                        </Grid>

                                        {!accessLevelManager &&
                                        <Grid item md={1} lg={1}
                                            style={{display:'flex', justifyContent:"center", flexGrow:1}}
                                            onClick={(e)=>{e.stopPropagation()}}>
                                                <Button onClick={() => onDeleteCompanyClick(client.id) }
                                                    className='more_actionButton1 action__icon'>
                                                    <i className={`flaticon-delete-1 ${classes.flatIcons}`}></i>
                                                    Delete
                                                </Button>
                                        </Grid> }     
                                    </Grid>

                    })}
                        {props.clientList && props.clientList.length === 0 &&
                            <Grid tem container
                                alignItems="center" justifyContent="center">
                                <Grid item>
                                    <Typography variant="body1" style={{padding:"16px"}}>
                                            No Clients Found
                                    </Typography>
                                </Grid>     
                            </Grid>
                        }    
                        {loadMoreLogic()}
                        </Grid>}
                    </Grid>
                </Grid>
                {
                    openDialog  && 
                        <ClientDetailsModal 
                            openDialog = {openDialog}
                            setOpenDialog = {setOpenDialog}
                            handleOpenDetailsModal = {handleOpenDetailsModal}
                            handleCloseDetailsModal = {handleCloseDetailsModal} 
                            showClientDetails={showClientDetails}
                            companyUpdated={companyUpdated}
                            handleSendEmailDialogOpen={props.handleSendEmailDialogOpen}
                        />
                }     
                
        </div>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    overlay: state.overlay
});

export default connect(mapStateToProps)(ClientListComponent);