import React, {useState, useEffect} from 'react';
import {Drawer, AppBar, Toolbar, Grid, Button, Typography, IconButton, Paper, TextField, CircularProgress, FormLabel} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from "@material-ui/icons/Clear";
import { FileUploader } from "react-drag-drop-files";
import { useSnackbar } from 'notistack';
import { isStringSpaces } from '../../util/validator';
import { consoleToLog, trackGAEvent, capitalizeFirstLetterOfEachWord } from '../../util/AppUtil';
import axios from 'axios';
import { uploadChecklistAttachmentApi, getUploadUrlForChecklistAttachmentApi } from "../../services/ChecklistService";
import {connect} from 'react-redux';


const useStyles = makeStyles((theme) => ({
    dialogboxFooter: {
        display: "flex",
        position: "absolute",
        bottom: "0px",
        left: "0",
        right: "0",
        padding: "12px 0px 12px 12px",
        zIndex: "15",
        background: "#fff",
        borderTop: "1px solid #ccc",
    },
    dragcontainerFooter: {
        fontSize: "12px",
        color: "gray",
        marginLeft: "18px",
        marginTop: "-14px",
    },
    dragContainerButtons: {
        marginLeft: "10px",
        width: "7rem",
    },
    uploadAttachmentLargeBtn: {
        fontSize: "15px",
        width: "12rem",
        marginLeft: "4px"
    },
    uploadAttachmentLabel: {
        fontSize: "13px",
        marginTop: "10px",
        marginBottom: "10px",
        color: "black",
    },
    closeButton: {
        paddingLeft: "22.5rem",
        paddingTop: "5px",
    },
    fileUploaderComponent: {
        width: "auto",
        backgroundColor: "red",
        border: "none",
        outline: "none",
    },
    appBar: {
        background: "#F9F9F9",
        height: "45px",
        color: "#202020",
    },
    fileUploaderText: {
        fontSize: "13px",
        color: "black",
        fontWeight: "400",
    },
}))

const UploadAttachmentModal = (props) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();

    const [file, setFile] = useState(null);
    const [newTitle, setNewTitle] = useState(null);
    const [uploadLoading, setUploadLoading] = useState(false);

    const handleTitle = (e) => {
        const newTitle = e.target.value;
        setNewTitle(newTitle);
    };

    const handleFileChange = (file) => {
        setFile(file);
    };

    const onTypeError = (err) => {
        // consoleToLog("Error", err);
    };
    

    const onSubmit = (e) => {
        if (!newTitle) {
            enqueueSnackbar("Title can not be empty!", {
                variant: "error",
            });
            return;
        }
    
        const isTitleValid = !isStringSpaces(newTitle);
        if (!isTitleValid) {
            enqueueSnackbar("Title can not be empty!", {
            variant: "error",
            });
            return;
        }
        if (!file) {
            enqueueSnackbar("Attachment can not be empty!", {
                variant: "error",
            });
            return;
        }

        const target_id = props.fromChecklistDetails ? props.checkListItem?.id : props.selectedOrganization.organization.id;
        if (!target_id) {
          // consoleToLog("UploadFileModal: Checklist_id not found!");
            return;
        }
    
        const organization_id = props.selectedOrganization.organization.id;
        const auto_generated_id = props.fromChecklistDetails ? props.checkListItem?.company.auto_generated_id : props.companyObj.auto_generated_id;
        const template_slug = props.fromChecklistDetails ? props.selectedTemplate?.slug : '';
        const subtitle = props.fromChecklistDetails ? props.checkListItem?.subtitle : '';
        const organization_slug = props.selectedOrganization.organization?.slug;
    
        const type = props.fromChecklistDetails ? 'checklist' : 'client_master';
        const apiPath = 'upload_success'
        
        setUploadLoading(true);
        getUploadUrlForChecklistAttachmentApi(organization_id, file.name, type, auto_generated_id, template_slug, subtitle, organization_slug, '')
            .then((response) => {
                const res = response.data;
                consoleToLog('Response getUploadUrlForChecklistAttachmentApi: ', res);
        
                // Put the fileType in the headers for the upload
                const options = {
                headers: {
                    "Content-Type": file.type,
                }
                };
                axios.defaults.headers.common = {};
                axios.put(res.upload_signed_url, file, options)
                .then(result => {
                    consoleToLog('signed Request s3', result);
                    //console.log("Response from s3");
                    updateCheckListFileUploadUrl(organization_id, type, newTitle, res.url, target_id);
                })
                .catch(error => {
                    setUploadLoading(false);
                    consoleToLog('error: ', error.response);
                })
    
        })
        .catch((err) => {
            setUploadLoading(false);
            consoleToLog("File Upload Error: ", err);
        });
    };
    
    const updateCheckListFileUploadUrl = (organization_id, type, title, path, target_id) => {
        const user_id = props.auth.user.id;
        const client_id = props.fromChecklistDetails ? props.checkListItem?.company.id : props.companyObj?.id;
    
        uploadChecklistAttachmentApi(organization_id, type, capitalizeFirstLetterOfEachWord(title), path, target_id, user_id, client_id)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response uploadChecklistAttachmentApi: ", res);
                setUploadLoading(false);
                var updatedAttachmentsArray = [
                    res.attachment,
                    ...props.attachmentList,
                ];

                // updated array locally
                props.setAttachmentList(updatedAttachmentsArray);
                // if (!isMount) {
                //     props.setAttachmentList(updatedAttachmentsArray);
                // }
                enqueueSnackbar("Attachment Updated Successfully", {
                    variant: "success",
                });
                
                trackGAEvent(props.selectedOrganization.organization.name, props.fromChecklistDetails ? 'Checklist Upload Attachment' : 'Client Master Document', `${props.auth.user.firstname} ${props.auth.user.lastname}`);

                // to parent
                props.fromChecklistDetails && props.onAttachmentAdded(updatedAttachmentsArray, res.checklist);
                onDrawerClose();
            })
            .catch((e) => {
                setUploadLoading(false);
                consoleToLog("uploadChecklistAttachmentApi error", e);
            });
    };
    
    const onDrawerClose = () => {
        setNewTitle('');
        setFile(null);
        props.handleDrawerClose();
    }
    

    return (
        <Drawer
            open={props.open}
            onClose={props.handleDrawerClose}
            anchor="right"
            className="drawerContainer"
    >
        <AppBar position="static" className={classes.appBar} elevation={0}>
        <Toolbar style={{ minHeight: "40px", padding: "0px 16px" }}>
            <Typography
            variant="body1"
            style={{ fontWeight: "600", marginRight: "auto" }}
            >
            {props.title}
            </Typography>
            <IconButton
            style={{ color: "#202020", marginRight: "-16px" }}
            onClick={onDrawerClose}
            >
            <ClearIcon />
            </IconButton>
        </Toolbar>
        </AppBar>
        <Grid container>
        <Grid
            item
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            style={{ marginLeft: "1rem", marginRight: "1rem" }}
        >
            <Grid item container direction="row">
            <FormLabel className={classes.uploadAttachmentLabel}>
                Title
            </FormLabel>
            </Grid>
            <Grid item container direction="row">
            <TextField
                variant="outlined"
                size="small"
                onChange={handleTitle}
                placeholder="Enter Attachment Title"
                fullWidth
            />
            </Grid>
        </Grid>
        <Grid item>
            <Paper elevation={0} className="dragcontainer">
            <FileUploader
                handleChange={handleFileChange}
                onDrop={handleFileChange}
                onSelect={handleFileChange}
                onTypeError={onTypeError}
                name="file"
                className={classes.fileUploaderComponent}
            >
                <Grid container>
                <Grid
                    item
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    style={{ marginTop: "2rem" }}
                >
                    <Button variant="outlined" color="secondary">
                    Browse File
                    </Button>
                </Grid>
                <Grid
                    item
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    style={{ marginBottom: "10px", marginTop: "5px" }}
                >
                    {file ? (
                    <Typography
                        className={classes.fileUploaderText}
                        style={{ color: " #888888" }}
                    >
                        {file.name}
                    </Typography>
                    ) : (
                    <Typography className={classes.fileUploaderText}>
                        Drag and Drop File
                    </Typography>
                    )}
                </Grid>
                </Grid>
            </FileUploader>
            <span className={classes.dragcontainerFooter}>
                jpg, png, pdf, docx ,xls files allowed only
            </span>
            </Paper>
        </Grid>
        <Grid item container direction="column">
            <Grid className={classes.dialogboxFooter}>
            <Grid
                item
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-end"
            >
                <Grid item direction="column" justifyContent="space-around">
                <Button
                    variant="contained"
                    size="large"
                    color="secondary"
                    className={classes.dragContainerButtons}
                    onClick={onSubmit}
                >
                    <span style={{ paddingRight: "6px" }}>
                    {uploadLoading && (
                        <CircularProgress
                        style={{ color: "white" }}
                        size={18}
                        />
                    )}
                    </span>
                    Upload
                </Button>
                </Grid>
                <Button
                variant="outlined"
                size="large"
                color="primary"
                className={classes.dragContainerButtons}
                onClick={onDrawerClose}
                >
                Cancel
                </Button>
            </Grid>
            </Grid>
        </Grid>
        </Grid>
    </Drawer>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    selectedTemplate: state.organizationInfo.selectedTemplate
})

export default connect(mapStateToProps)(UploadAttachmentModal);