import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import { Typography, Drawer, AppBar, Toolbar,IconButton } from '@material-ui/core';

import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles((theme) => ({
    drawer: {
    //    width: drawerWidth,
        flexShrink: 0
    },
    drawerPaper: props => ({
        width: props.createChecklist ? '550px' : '36%',
        //height: 'calc(100vh - 51px)',
        flexGrow:1,
        top: (props.fromClientDetails || props.fromOnboardingComponent || props.fromChecklistDetails) ? '0px' : '51px',
       
    }),
    appBar: {
        background:'#F9F9F9',
        height:'45px',
        color:'#202020'
    }

}));

const RightToLeftSideDrawer = (props) => {
    let {createChecklist, fromClientDetails, fromOnboardingComponent, fromChecklistDetails} = props;
    const classes = useStyles({createChecklist, fromClientDetails, fromOnboardingComponent, fromChecklistDetails});
    //const [openDrawer, setOpenDrawer] = useState(props.openDrawer);

    return(
        <Drawer variant="temporary"
            anchor="right"
            open={props.openDrawer}
            onClose={props.closeDrawer}
            classes={{
                paper: classes.drawerPaper
                }}
            >
            <AppBar position="static" className={classes.appBar} elevation={0}>
                <Toolbar style={{minHeight:'40px', padding:'0px 16px', }}>
                    <Typography variant="body1" 
                        style={{fontWeight:'600', marginRight:'auto'}}>
                        {props.title}
                    </Typography>
                    <IconButton style={{color:'#202020', marginRight:'-16px'}} 
                        onClick={props.closeDrawer}
                    >
                        <ClearIcon/>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <div>
                {props.children}
            </div>
            </Drawer>
        );
}

export default RightToLeftSideDrawer;