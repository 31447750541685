import React, { useState, useEffect, useRef } from "react";
import ListItem from "@material-ui/core/ListItem";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress, Grid, Tooltip, Avatar, Popover } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { ApprovalStatus, consoleToLog, trackGAEvent } from "../../util/AppUtil";
import { connect } from "react-redux";
import SelectFromMemberList from "../checklist/SelectFromMemberList";
import { updateTaskMemberApi } from "../../services/authService";
import {
  getStatusColor,
  shouldDisable,
  AccessLevel,
} from "./../../util/AppUtil";
import { useIsMount } from "../useIsMount";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useSnackbar } from "notistack";
import {
  updateTaskStatusApi,
  completeChecklistApi,
} from "../../services/ChecklistService";
import DetailPage from "./DetailPage";
import OpenWithOutlinedIcon from '@material-ui/icons/OpenWithOutlined';
import SelectFromApproverList from "../checklist/SelectFromApproverList";
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import {TaskStatusName} from '../../util/AppUtil';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ScheduleIcon from '@material-ui/icons/Schedule';
import {updateChecklistTaskDueDateApi} from '../../services/ChecklistService';
import moment from "moment";
import Calender from "../Calender";
import {undoCompleteApi} from '../../services/authService';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';

const useStyles = makeStyles((theme) => ({
  checklisthover: {
    "& .onChecklist__hover": {
      visibility: "hidden",
    },
    "&:hover .onChecklist__hover": {
      visibility: "visible",
    },
  },
  avatarList: {
    display: "flex",
    marginTop: "5px",
    pointerEvents: "auto",
    paddingLeft: "10px",
  },
  checKlistItemSubtitleBtn: {
    minWidth:"25px"
  },
  checKlistItemSubtitle: {
    marginLeft: "4px",
    marginTop: "-4px",
    color: "#888888",
  },
  checKlistItemSubtitle1: {
    marginLeft: "5px",
    color: "#888888",
  },
  checKlistItemIcon: {
    fontSize: "22px",
    paddingTop: "2px",
  },
  taskTitle: {
    fontSize: "15.5px",
    fontWeight: 600,
    marginBottom: "-5px",
    marginLeft: "-13px",
  },
  taskDetails: {
    fontSize: "15.5px",
    fontWeight: 450,
    paddingBottom: "5px",
    marginRight: "-10px",
  },
  details: {
    // border: "1px solid #d4c7c7",
    // marginTop: "-10px",
    borderRadius: "0px 0px 10px 10px",
    marginBottom: "10px",
    borderTop: "none",
    marginRight: "128px",
    marginLeft: "1px",
  },
  detailsIcon:{
    marginTop:"-3px"
  },
  detailsIcon2:{
    marginTop:"4px"
  },
  customColumn: {
    flexBasis:'5.3%',
    maxWidth:'5.3%'
  },
  flatIcons : {
    fontSize:'18px', 
    display:'flex',
    alignItems:'center'
  },
  itemListContainer: {
    '& .actionButtons' : {
      visibility:'hidden'
    },
    '&:hover .actionButtons': {
      visibility:'visible'
    },
    '& .statusButtons' : {
      display:"none"
    },
    '&:hover .statusButtons': {
      display:"flex",
      justifyContent:'flex-end',
      alignItems:'center',
      position:'absolute',
      width:'400px',
      right:'0px',
      top:'-16px'
    },
    '& .taskStatus' : {
      display:"flex",
      justifyContent:'center',
      alignItems:'center'
    },
    '&:hover .taskStatus': {
      display:"none"
    },
    '& .memberButton' : {
      visibility: "hidden"
    },
    '&:hover .memberButton': {
      visibility: "visible"
    },
    '& .statusButtons1' : {
      display:"none"
    },
  },
  hoverIcons : {
    marginTop:'1px',
    color:'#ccc', 
    fontSize:'20px',
    padding:'0px 8px',
    cursor:'pointer'
  },
  sendForReviewButton: {
    background:'#fff',
    border:'1px solid #ccc',
    color:'#202020',
    paddingTop:"2px",
    paddingBottom:"2px",
  },
  iconStyles: {
    display:'flex', 
    alignItems:'center', 
    fontSize:'17px',
    justifyContent:'center',
    cursor:'pointer'
  },
  MuiAccordionSummaryroot: {
    marginBottom:'-3px !important',
    padding:'0px !important',
    minHeight:'0px !important'
  },
  icontext : {
    color:'#808080',
    padding:'0 0px 0 5px',
  },
  materialIconStyle: {
    fontSize:'16px',
    color:'grey'
  }

}));

const TaskListItem = (props) => {
  const classes = useStyles();
  const isMount = useIsMount();
  const [open, setOpen] = useState(false);
  // const [currentDetails, setCurrentDetails] = useState(false);
  //const [selectedId, setSelectedId] = useState("");
  const [task,setTask]=useState(props.singleTask);
  const { enqueueSnackbar } = useSnackbar();
  const { access_level } = props.selectedOrganization || {};
  const [statusNameList, setStatusNameList] = useState([
    "Pending",
    "In Process",
    "Awaiting Reply",
    "Completed",
    "Pending Approval",
    "Not Applicable",
  ]);
  const [statusValueList, setStatusValueList] = useState([
    "pending",
    "in_process",
    "awaiting_reply",
    "completed",
    "pending_approval",
    "not_applicable",
  ]);

  const [taskStatus, setTaskStatus] = useState([
    {name: "Pending", value: "pending"},
    {name: "Awaiting Reply", value: "awaiting_reply"},
    {name: "Completed", value:"completed"}
  ]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [dueDateTaskObj, setDueDateTaskObj] = useState(undefined);

  const accessLevelManager = shouldDisable(
    AccessLevel.getUserAccessLevelValue(access_level),
    AccessLevel.MANAGER
  );
  /* const accessLevelMember = shouldDisable(
    AccessLevel.getUserAccessLevelValue(access_level),
    AccessLevel.MEMBER
  ); */
  const accessLevelGuest =
    AccessLevel.getUserAccessLevelValue(access_level) === AccessLevel.GUEST
      ? true
      : false;

    const myDivToFocus = useRef(); 
    const scrollToDiv = useRef(); 
    
    useEffect(()=>{
      if(!isMount){
       setTask(props.singleTask);
      }
   },[props.singleTask]);

    useEffect(()=>{
      if(!isMount){
        if(props.updateTask) {
          const statusIndex = statusValueList.indexOf(props.singleTask.status);
          const statusName = statusNameList[statusIndex];
          // task.status = statusName;
          setTask({...task, status:statusName});
          props.setUpdateTask(false);
        }
      }
  },[props.updateTask]);

    useEffect(() => {
          if(props.actionType === 'comments') {
            if(myDivToFocus.current) {
              myDivToFocus.current.scrollIntoView({
                behavior: 'smooth', 
              })
            }
        }
      }, []); 
      
    useEffect(() => {
      if(!isMount) {
        if(props.scrollToTask) {
            props.setScrollTask(false);
          if(scrollToDiv.current) {
            scrollToDiv.current.scrollIntoView({
              behavior: 'smooth', 
            });
          }
        }
      }
    }, [props.scrollToTask]); 

  const onTaskStatusChanged = (val, selectedTask) => {
    var statusValue = val;
    // if (statusValue === -1 || statusValue === "-1") {
    //   return;
    // }
    const statusIndex = statusValueList.indexOf(statusValue);
    const statusName = statusNameList[statusIndex];
    const checklist_task_id = selectedTask.id;
    // task.status = statusName;
    setTask({...task, status:statusName});

    updateTaskStatusApi(checklist_task_id, statusValue)
      .then((response) => {
        const res = response.data;
        consoleToLog("updateTaskStatusApi res", res);
        //const completed_task = res.activity_feed.checklist.completed_task;

        // when all task are completed then alert is shown and user is asked to complete checklist or continue with checklist updation
        trackGAEvent(props.selectedOrganization.organization.name,"Changed Status", `${props.auth.user.firstname} ${props.auth.user.lastname}`);

        if (res.checklist_complete === true) {
          completeChecklistApiCall(res);
        } else {
          enqueueSnackbar("Checklist Task Status Updated Successfully", {
            variant: "success",
          });
        }

        //send to parent
        props.onTaskStatusChanged(statusName,  res.activity_feed,  task);
      })
      .catch((e) => {
        consoleToLog("updateTaskStatusApi err", e);
        if (e.response && e.response.data && e.response.data.message) {
          enqueueSnackbar(e.response.data.message, {variant: "error"});
          return false;
        }
      });
  };

  const completeChecklistApiCall = () => {
    if (window.confirm("Checklist will be completed as all tasks are completed. You cannot undo the completed checklist. Would you like to continue to complete the checklist?" )) {
      const checklist_id = props.checklistId;

      completeChecklistApi(checklist_id)
        .then((response) => {
          const res = response.data;
          consoleToLog("Response TaskDetails completeChecklistApi: ", res);
          enqueueSnackbar(res.message, { variant: "success"});

          trackGAEvent(props.selectedOrganization.organization.name, 'Checklist', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
          //send data to parent
          props.onCheckListStatusCompleted("Completed", false);
        })
        .catch((e) => {
          consoleToLog("TaskDetails completeChecklistApi error", e.response);
          if (e.response.data && e.response.data.message) {
            // toast.error(e.response.data.message);
            enqueueSnackbar(e.response.data.message, { variant: "error",});
            return false;
          }
        });
    } 
  };

  const setAssignedMemberList = (assignedMemberList) => {
    const checklist_task_id = task.id;
    var assignees = assignedMemberList.map((member) => member.user.id).join();

    //Update Members From Checklist Api
    updateTaskMemberApi(checklist_task_id, assignees)
      .then((response) => {
        const res = response.data;
        consoleToLog("updateTaskMemberApi res", res);
        trackGAEvent(props.selectedOrganization.organization.name, "Add Member", `${props.auth.user.firstname} ${props.auth.user.lastname}`);
        //send to parent
        props.onTaskAssigneesChanged(res.checklist_task_members, checklist_task_id);
      })
      .catch((err) => {
        consoleToLog("updateTaskMemberApi error", err.res);
      });
  }

  const onWidgetUpdated = (updatedWidget) => {
    const updatedWidgetArr = task.checklist_task_widget.map((widget) => {
        if (widget.id === updatedWidget.id) {
          return updatedWidget;
        } else {
          return widget;
        }
      }
    );
    const updatedSelectedTask = {
      ...task,
      widgetResponse: updatedWidgetArr,
    };
  props.onWidgetUpdated(updatedSelectedTask);
  };

  const updateTaskAttachmentArray = (taskObj) => {

    props.updateTaskAttachmentArray(taskObj);
  };

  const onCommentsEdited = (activity_feed) => {
    props.onCommentsEdited(activity_feed, task);
  };

  const onCommentsUpdated = (activity_feed, comment_count) => {
    props.onCommentsUpdated(activity_feed, comment_count, task);
  };

  const onCommentsdeleted= (activityFeeds, checklistObj) => {
    props.onCommentsdeleted(activityFeeds, checklistObj);
  };
  

  const handleChildClick = (event) => {
    // event.stopPropagation();
    // consoleToLog("handleChildClick");
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const getStatusValueByName = (statusName) => {
    const statusIndex = statusNameList.indexOf(statusName);
    return statusValueList[statusIndex];
  };
  

  const handleAccordClick = (task) => {
    // update detailsView in parent
    if(task.detailsView === undefined){
        task.detailsView = task.id;
    }
    else task.detailsView = undefined;

    props.updateDetailsView(task);
  };

  const checklistApprovedOrRejected = (approvalList, member_id, initial) => {
    const approvers = approvalList;
  
    const checklist_approved = approvers.filter((approver) => approver.approval_status === ApprovalStatus.APPROVED && approver.approver_user.id === member_id);
    const checklist_rejected = approvers.filter((approver) => approver.approval_status === ApprovalStatus.REJECTED && approver.approver_user.id === member_id);

    if (checklist_approved && checklist_approved.length > 0) {
        return <DoneIcon className={`flaticon-check ${classes.iconStyles}`} />
    } else if (checklist_rejected && checklist_rejected.length > 0) {
        return <ClearIcon className={`flaticon-cancel-1 ${classes.iconStyles}`} />
    } else {
        return initial
    }     
  }

  const onUndoCompleteChecklistTaskClick = (task_id) => {
    const apiStr = `checklist_task/${task_id}`;

    undoCompleteApi(apiStr)
        .then((response) => {
            const res = response.data;
            consoleToLog("Response undoCompleteApi: ", res);

            enqueueSnackbar(res.message, {variant:"success"});

            props.updateSelectedTask(res.checklist_task);
            
            trackGAEvent(props.selectedOrganization.organization.name, 'Checklist Task Undo Complete', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
        })
        .catch((e) => {
            consoleToLog("completeChecklistApi error", e.response);
            if (e.response.data && e.response.data.message) {
                enqueueSnackbar(e.response.data.message, {
                    variant:'error'
                });
                return false;
            }
        });
}

  const returnStatusButton = (taskObj) => {
    if(taskObj.status === TaskStatusName.PENDING_APPROVAL) {
      return '';
    }

    if(taskObj.status === TaskStatusName.AWAITING_REPLY) {
      return <Grid item container alignItems="center" className={props.editChecklist ? "statusButtons1" : "statusButtons"}>
              <Grid item>
                <Button 
                  onClick={() => onTaskStatusChanged('pending', taskObj)}
                  style={{
                      color:getStatusColor('pending'),
                      border: `1px solid #ccc`,
                      width:'100px',
                      background:'#fff', paddingTop:'2px', paddingBottom:'2px'}}>
                    Resolved
                </Button>
              </Grid>
              {taskObj.is_completable &&
              <Grid item>
                <Button 
                  onClick={() => onTaskStatusChanged('completed', taskObj)}
                  style={{
                      color:getStatusColor('completed'),
                      border: `1px solid #ccc`,
                      width:'100px',
                      background:'#fff', paddingTop:'2px', paddingBottom:'2px'}}>
                    Complete
                </Button>
              </Grid>}
              <Grid item>
                <Button 
                  onClick={() => props.handleOpenSendForReviewDialog(task, props.value !== 5 ? true : false)}
                  className={classes.sendForReviewButton}>
                  Send for Review
                </Button>
              </Grid>
            </Grid>
    }

    if(taskObj.status === TaskStatusName.PENDING) {
      return <Grid item container alignItems="center" className={props.editChecklist ? "statusButtons1" : "statusButtons"}>
              <Grid item>
                <Button 
                  onClick={() => onTaskStatusChanged('awaiting_reply', taskObj)}
                  style={{
                      color:getStatusColor('awaiting_reply'),
                      border: `1px solid #ccc`,
                      width:'100px',
                      background:'#fff', paddingTop:'2px', paddingBottom:'2px'}}>
                    Awaiting
                </Button>
              </Grid>
              {taskObj.is_completable &&
              <Grid item>
                <Button 
                  onClick={() => onTaskStatusChanged('completed', taskObj)}
                  style={{
                      color:getStatusColor('completed'),
                      border: `1px solid #ccc`,
                      width:'100px',
                      background:'#fff', paddingTop:'2px', paddingBottom:'2px'}}>
                    Complete
                </Button>
              </Grid>}
              <Grid item>
                <Button 
                  onClick={() => props.handleOpenSendForReviewDialog(task, props.value !== 5 ? true : false)}
                  className={classes.sendForReviewButton}>
                  Send for Review
                </Button>
              </Grid>
            </Grid>
    }

    if(taskObj.status === TaskStatusName.COMPLETED)
      {
      return <Grid item className={props.editChecklist ? "statusButtons1" : "statusButtons"}>
                <Button 
                  onClick={() => onUndoCompleteChecklistTaskClick(taskObj.id)}
                  style={{
                      color:'#333',
                      border: `1px solid rgba(0,0,0,0.1)`,
                      background:'#fff', paddingTop:'4px', paddingBottom:'4px'}}>
                    Undo Complete
                </Button>
              </Grid>
      }
  }

  const handleCloseDueDatePopover = () => {
    setAnchorEl(null);
    setDueDateTaskObj(undefined);
  };

  const clickedDate = (str) => {
    const checklistTaskDueDate = !str ? str : moment(str).format("YYYY-MM-DD");
    const task_id = dueDateTaskObj.id;

    setTask({...task, due_date: checklistTaskDueDate});

    updateChecklistTaskDueDateApi(task_id, checklistTaskDueDate)
      .then((response) => {
        const res = response.data;
        consoleToLog("Response updateChecklistTaskDueDateApi: ", res);
        // const newCheckListArray = (itemDetails.due_date = checklistDueDate);
        enqueueSnackbar(res.message, {variant:"success"});
        trackGAEvent(props.selectedOrganization.organization.name, 'Checklist Task DueDate Set', `${props.auth.user.firstname} ${props.auth.user.lastname}`);

        // to parent
        props.onTaskStatusChanged(dueDateTaskObj.status, res.activity_feed,  task);
        handleCloseDueDatePopover();
      })
      .catch((e) => {
        consoleToLog("updateCheckListDueDateApi error", e.response);
        if (e.response.data && e.response.data.message) {
          enqueueSnackbar(e.response.data.message, {variant: "error"});
          return false;
        }
      });
  };

  const handleClickNew = (event, task) => {
    setAnchorEl(event.currentTarget);
    setDueDateTaskObj(task);
  };

  const handleDueDatePopOver = () => {
    return (<Popover
              id='task-due-date-popover'
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={handleCloseDueDatePopover}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal:'right'
                }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
                }}
            >
              <Calender clickedDate={clickedDate} />
            </Popover>
    );
  };

  return (
    <div ref={scrollToDiv}>
      {task.heading === true && (
        <Grid item sm={12}>
          <Grid
            item
            container
            style={{ borderRadius: "10px" }}
            alignItems="center"
            className={classes.itemListContainer}
          >
            <Grid item>
              <ListItem
                className="itemHeading"
              >
                <Typography className={classes.taskTitle}>
                  {task.title}
                </Typography>
              </ListItem>
            </Grid>
            {props.editChecklist && 
              <Grid item>
                {(props.deleteLoading && props.taskId === task.id)
                ?
                <Grid item container justifyContent="center">
                  <CircularProgress size={18} style={{position:"relative", top:"4px"}}/>
                </Grid>
                :
                <Grid item container alignItems="center" className="actionButtons"
                  style={{position:'relative', top:'4px'}}>
                  <Grid item {...props.provided.dragHandleProps}>
                      <Tooltip title="Move" arrow>
                          <OpenWithOutlinedIcon
                            className={classes.hoverIcons}      
                          />
                      </Tooltip>
                    </Grid>
                    <Grid item>
                        <Tooltip title='Edit Task Title' arrow>
                          <Button style={{minWidth:'20px'}}
                            onClick={(e) => props.handleOpenTitleUpdateDrawer(e, task)}>
                            <i className={`flaticon-edit ${classes.flatIcons}`}></i>
                          </Button>
                        </Tooltip>
                    </Grid>
                    <Grid item>
                      <Tooltip title='Delete Task' arrow>  
                        <Button style={{minWidth:'20px'}}
                          onClick={(e) => props.onDeleteTaskClick(e, task)}
                        >
                          <i className={`flaticon-delete-1 ${classes.flatIcons}`}></i>
                        </Button>
                      </Tooltip>
                    </Grid>
                </Grid>}
              </Grid>}
          </Grid>
        </Grid>
      )}
      {(task.heading !== true || !task.heading) && (
        <div
          item
          className={task.detailsView !== undefined ? "selectedMain" : "main"}
          ref={myDivToFocus}
        >
          <Accordion
            square={false}
            style={{
              borderRadius: "0px",
              borderColor: "none",
              backgroundColor: "none",
              boxShadow: "none",
            }}
            expanded={task.detailsView !== undefined}
            onChange={() => handleAccordClick(task)}
            key={task.id}
            className={
              task.status === "Not Applicable"
                ? "dimmed"
                : ""}

          >
            <ListItem
              className={`${classes.itemListContainer} itemList`}
              style={task.detailsView !== undefined
                ? {borderLeft: `6px solid  ${getStatusColor(task.status)}`, borderRadius: "5px 5px 5px 0px"}
                : {borderLeft: `6px solid  ${getStatusColor(task.status)}`}
              }
            >
              <Grid item sm={6}>
                <Grid container direction="column">
                  <Grid item direction="row">
                    <AccordionSummary
                        aria-controls="panel1d-content"
                        id={`panel1d-header${task.id}`}
                        classes={{
                          root: classes.MuiAccordionSummaryroot
                        }}
                      >
                        <Typography
                          className={task.status === "Completed"
                              ? "titleLineThrough"
                              : "checKlistItemTitle"
                          }
                        >
                          {task.title}
                        </Typography>
                    </AccordionSummary>
                  </Grid>
                  {!props.editChecklist &&
                  <Grid
                    container
                    item
                    alignItems="center"
                    direction="row"
                    style={{ marginTop: "2px" }}
                    sm={12}
                  >
                    <Grid item>
                      <AccordionSummary
                          aria-controls="panel1d-content"
                          id={`panel1d-header${task.id}`}
                          classes={{
                            root: classes.MuiAccordionSummaryroot
                          }}
                      >
                        <Tooltip title='Comment Count' arrow>
                          <Button className={classes.checKlistItemSubtitleBtn}
                            style={{marginLeft:'-7px'}}>
                            <ChatBubbleOutlineIcon fontSize="small" className={classes.materialIconStyle}/>
                            <Typography
                              variant="subtitle1"
                              className={classes.checKlistItemSubtitle}
                            >
                              {task.comment_count ? task.comment_count : 0}
                            </Typography>
                          </Button>
                        </Tooltip>
                      </AccordionSummary>
                    </Grid>
                    <Grid item>
                      <AccordionSummary
                          aria-controls="panel1d-content"
                          id={`panel1d-header${task.id}`}
                          classes={{
                            root: classes.MuiAccordionSummaryroot
                          }}
                      >
                        <Tooltip title='Attachment Count' arrow>
                          <Button className={classes.checKlistItemSubtitleBtn}>
                            <AttachFileIcon fontSize="small" className={classes.materialIconStyle}/>
                            <Typography
                              variant="subtitle1"
                              className={classes.checKlistItemSubtitle}
                            >
                              {task.task_attachments_count ? task.task_attachments_count : 0}
                            </Typography>
                          </Button>
                        </Tooltip>
                      </AccordionSummary>
                    </Grid>
                    <Grid item>
                      <Tooltip title='Approved Count' arrow>
                        <Button className={classes.checKlistItemSubtitleBtn}
                          onClick={() =>  props.handleOpenApprovalsModal(task)}
                          >
                          <CheckCircleOutlineIcon fontSize="small" style={{fontSize:'16px', color:'#72BB53'}}/>
                          <Typography
                            variant="subtitle1"
                            className={classes.checKlistItemSubtitle1}
                          >
                            {task.task_approved_approval_count ? task.task_approved_approval_count : 0}
                          </Typography>
                        </Button>
                      </Tooltip>
                    </Grid>
                    <Grid item>
                      <Tooltip title='Rejected Count' arrow>
                        <Button className={classes.checKlistItemSubtitleBtn}
                          onClick={() => props.handleOpenApprovalsModal(task)}
                          >
                          <HighlightOffIcon fontSize="small" style={{fontSize:'16px', color:'red'}}/>
                          <Typography
                            variant="subtitle1"
                            className={classes.checKlistItemSubtitle1}
                          >
                            {task.task_rejected_approval_count ? task.task_rejected_approval_count : 0}
                          </Typography>
                        </Button>
                      </Tooltip>
                    </Grid>
                    <Grid item>
                      <Tooltip title='Pending Count' arrow>
                        <Button className={classes.checKlistItemSubtitleBtn}
                          onClick={() => props.handleOpenApprovalsModal(task)}
                          >
                          <ScheduleIcon fontSize="small" style={{fontSize:'16px', color:'grey'}}/>
                          <Typography
                            variant="subtitle1"
                            className={classes.checKlistItemSubtitle1}
                          >
                            {task.task_pending_approval_count ? task.task_pending_approval_count : 0}
                          </Typography>
                        </Button>
                      </Tooltip>
                    </Grid>
                    <Grid item>
                      <Tooltip title="Due Date" arrow >
                          <Button aria-label="delete"
                          onClick={(e) =>handleClickNew(e, task)}
                          className={classes.sidemenubtns}
                          style={{color: props.isDueDateOverDue && props.isDueDateOverDue(task) && 'red', marginLeft:'4px'}}
                          >
                              <CalendarTodayOutlinedIcon fontSize="small" className={classes.materialIconStyle}/>
                              <Typography variant="subtitle1"
                              style={{color: props.isDueDateOverDue && props.isDueDateOverDue(task) && 'red'}}
                              className={classes.icontext}>{
                              task.due_date ? `${moment(task.due_date).format('Do MMM')}` : 'Set Due Date'}</Typography>
                          </Button>
                      </Tooltip>
                      {dueDateTaskObj?.id === task.id && handleDueDatePopOver()}
                    </Grid>
                    <Grid item className={classes.avatarList}>
                      <Grid item sm={12}>
                        {task && (
                          <Grid
                            item
                            sm={10}
                            style={{ maxWidth: "23rem", width: "auto", marginLeft:'14px' }}
                            className="memberButton"
                          >
                            <SelectFromMemberList
                              handlePropagation={handleChildClick}
                              style={{ pointerEvents: "auto" }}
                              onClick={(e) => { e.stopPropagation();}}
                              isShowOnly={true}
                              setAssignedMemberList={(selectedAssignees) =>
                                setAssignedMemberList(selectedAssignees)
                              }
                              assignees={task.checklist_task_members_list}
                              disabledAssignChecklistMembers={accessLevelManager}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </Grid>

                  </Grid>}
                </Grid>
              </Grid>
              {props.editChecklist && 
              <Grid item sm={4}>
                {(props.deleteLoading && props.taskId === task.id)
                ?
                <Grid item container justifyContent="center">
                  <CircularProgress size={25} />
                </Grid>
                :
                <Grid item container alignItems="center" className="actionButtons">
                    <Grid item {...props.provided.dragHandleProps}>
                      <Tooltip title="Move" arrow>
                          <OpenWithOutlinedIcon
                            className={classes.hoverIcons}      
                          />
                      </Tooltip>
                    </Grid>
                    <Grid item>
                        <Tooltip title='Edit Task Title' arrow>
                          <Button style={{minWidth:'20px'}}
                            onClick={(e) => props.handleOpenTitleUpdateDrawer(e, task)}>
                            <i className={`flaticon-edit ${classes.flatIcons}`}></i>
                          </Button>
                        </Tooltip>
                    </Grid>
                    <Grid item>
                      <Tooltip title='Delete Task' arrow>  
                        <Button style={{minWidth:'20px'}}
                          onClick={(e) => props.onDeleteTaskClick(e, task)}
                        >
                          <i className={`flaticon-delete-1 ${classes.flatIcons}`}></i>
                        </Button>
                      </Tooltip>
                    </Grid>
                </Grid>}
              </Grid>}
              {/* Task Status */}
              {!props.editChecklist &&
              <Grid item sm={4}>
                <Grid item container alignItems="center" spacing={1}>
                  
                </Grid>
              </Grid>}
              <Grid item md={2} style={{position:'relative'}}>
                <Grid item>
                  <Typography variant="subtitle1" className={props.editChecklist ? 'statusStyle' : 'statusStyle taskStatus'} style={{
                          color: `${getStatusColor(task.status)}`,
                          border: `1px solid ${getStatusColor(task.status)}`,
                      }}>{task.status}
                  </Typography>
                </Grid>
                <Grid item>
                    {returnStatusButton(task)}
                </Grid>
              </Grid>
            </ListItem>
            <Grid item direction="row" sm={12} className={classes.details}>
              <AccordionDetails >
                  <DetailPage
                    selectedTask={task}
                    widgetsLoading={props.widgetsLoading}
                    onWidgetUpdated={onWidgetUpdated}
                    onCommentsUpdated={onCommentsUpdated}
                    onCommentsEdited={onCommentsEdited}
                    onCommentsdeleted={onCommentsdeleted}
                    actionType={props.actionType}
                    checklistItem={props.checklistItem}
                    updateTaskAttachmentArray={updateTaskAttachmentArray}
                    editChecklist={props.editChecklist}
                    handleOpenUpdateTaskWidgetDrawer={props.handleOpenUpdateTaskWidgetDrawer}
                    updateChecklistTasks={props.updateChecklistTasks}
                    updateDetailsView={props.updateDetailsView}
                    onEditDeleteWidget={props.onEditDeleteWidget}
                  />
              </AccordionDetails>
            </Grid>
          </Accordion>
        </div>
      )}
      </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  selectedOrganization: state.organizationInfo.selectedOrganization,
});

export default connect(mapStateToProps, null)(TaskListItem);
