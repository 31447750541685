import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {Grid, Typography, TextField, Select, MenuItem} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {countries} from "../../data/country.json";
import { AccessLevel, shouldDisable, trackGAEvent, consoleToLog, capitalizeFirstLetterOfEachWord } from '../../util/AppUtil';
import {organizationUpdateApi} from '../../services/authService';
import { useSnackbar } from 'notistack';
import { updateSelectedOrg } from '../../actions/selectedOrganization';
import { updateOrganization } from '../../actions/organization';
import { orgNameValidator} from '../../util/validator';
import { useIsMount } from '../useIsMount';

const useStyles = makeStyles((theme) => ({
    innerbg: {
        //border: "1px solid #dbdbdb",
        borderRadius: "10px",
        padding: "32px 48px",
        background: "#ffffff",
        // [theme.breakpoints.down("md")]: {
        //     padding: "45px 20px",
        //     }
    },
    templateHeading: {
        fontSize : "24px",
        fontWeight: "600",
        margin:'0px auto',
        textAlign:'center'
    },
    labelStyles: {
        lineHeight:1, 
        color:'#555555'
    },  
    MuiFormControlLabellabel :{
        marginLeft:'-4px'
    },
    circularProgress: {
        marginLeft: 0,
        marginRight: theme.spacing(2),
        color: 'white'
    },   
    selectCountry:{
        width:"100%",
        height:"2.5rem",
        padding:"2px",
    },
    selectCountry2:{
        width:"100%",
        height:"2.5rem",
        padding:"2px",
        color:"grey"
    }
}))

const OnboardingOrganization = (props) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    const isMount = useIsMount();

    let org_name = props.selectedOrganization.organization.name ? props.selectedOrganization.organization.name : '';
    let org_country = props.selectedOrganization.organization.country ? props.selectedOrganization.organization.country : 'none';
    let start_month = props.selectedOrganization.organization?.financial_year_start_month;
    let org_email = props.selectedOrganization.organization.email ? props.selectedOrganization.organization.email : '';

    const { access_level } = props.selectedOrganization || {};
    const disabled = shouldDisable(AccessLevel.getUserAccessLevelValue(access_level), AccessLevel.OWNER);

    const [organizationName, setOrganizationName] = useState(org_name);
    const [selectedcountry, setSelectedCountry] = useState(org_country);
    const [monthValue, setMonthValue] = useState(start_month);
    const [organizationEmail, setOrganizationEmail] = useState(org_email);
    const [loading, setLoading] = useState(false);

    const startMonthOptions = [{name: "January", value:1}, {name: "February", value: 2}, {name: "March", value:3}, 
            {name: "April", value: 4}, {name: "May", value: 5}, {name: "June", value: 6},
            {name: "July", value: 7}, {name: "August", value:8}, {name: "September", value: '9'}, 
            {name: "October", value: 10}, {name: "November", value: 11}, {name: "December", value: 12}
        ];

    useEffect(() => {
        if(!isMount) {
            if(props.callApi) {
                onUpdateClick();
            }
        }

    }, [props.callApi])

    const handleCountryChange = (event) => {
        //console.log("Country: ", event.target.value);
        setSelectedCountry(event.target.value);
    };

    const handleMonthChange = (e) => {
        setMonthValue(e.target.value);
    }

    const onUpdateClick = () => {

        var orgName = organizationName;
        orgName = orgName.trim();//remove spaces from both side

        if (!orgName) {//check empty
            enqueueSnackbar('Organization name can not be empty!', {
                variant:'error'
            });
            return;
        }

        const isOrgNameValid = orgNameValidator(orgName);

        if (!isOrgNameValid) {
            enqueueSnackbar('Organization name is invalid. It should contain atleast one character.', {
                variant:'error'
            });
            return;
        }

        var orgEmail = organizationEmail;
        orgEmail = orgEmail.trim();//remove spaces from both side

        if(selectedcountry===undefined){
            enqueueSnackbar('Country field can not be entry!', {
                variant:'error'
            });
            return;
        }

        const financial_year_start_month = monthValue;
        if(financial_year_start_month === -1) {
            enqueueSnackbar('Please select ffinancial year start month', {variant:'error'});
            return;
        }

        const id = props.selectedOrganization.organization.id;
        const image = undefined;

        setLoading(true);
        //update organization api call
        organizationUpdateApi(id, capitalizeFirstLetterOfEachWord(orgName), image, selectedcountry, financial_year_start_month)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response organizationUpdateApi: ", res);
                setLoading(false);
                enqueueSnackbar(res.message, {
                    variant:'success'
                });

                //redux updation
                props.updateOrganization(res.organization);//update organization list in redux
                props.updateSelectedOrg(res.organization);//update selected_org in redux 
                props.setCallApi(false);
                props.setTabValue(tabValue => tabValue + 1);

                trackGAEvent(props.selectedOrganization.organization.name, 'Onboarding Save Organization', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
            })
            .catch((e) => {
                consoleToLog("organizationUpdateApi error", e.response);
                setLoading(false);
                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant:'error'
                    });
                    return false;
                }
            });
    }

    return (
        <Grid item sm={12} className={classes.settingsContainer}>
            <Grid item container direction="column" spacing={2} style={{marginTop:'2px'}}>
                <Grid item md={12} lg={12}>
                    <Typography variant="subtitle1" className='customMarginBottom'>
                        Organization Name
                    </Typography>

                    <TextField 
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        value={organizationName}
                        placeholder="Enter Organization Name"
                        InputLabelProps={{style: {fontSize: 13, marginTop:'3px'}}}
                        disabled={disabled}
                        onChange={(e) => setOrganizationName(e.target.value)}
                    />
                </Grid> 


                <Grid item md={12} lg={12}>
                    <Typography variant="subtitle1" style={{marginBottom:'2px'}}>
                        Country  
                    </Typography>
                        <Select 
                            className={selectedcountry !== undefined ? classes.selectCountry : classes.selectCountry2}
                            value={selectedcountry}
                            onChange={handleCountryChange}
                            variant="outlined"
                            placeholder='Select Country'
                            autoWidth
                            disabled={disabled}
                        >
                        <MenuItem disabled value="none">Select Country</MenuItem>
                        {countries.map((country)=>{
                            return(
                                <MenuItem value={country.code}>{country.name}</MenuItem>
                            )
                        })}
                        </Select>

                </Grid>

                <Grid item  md={12} lg={12}>
                    <Typography variant='subtitle1' style={{marginBottom:'2px'}}>
                        Financial Year Start Month
                    </Typography>
                    <Select 
                        placeholder='Select Financial Year Start Month'
                        variant='outlined'
                        margin='dense'
                        value={monthValue}
                        onChange={handleMonthChange}
                        select
                        fullWidth
                        disabled={disabled}
                    >
                        {
                            <MenuItem value={-1}>Please Select Start Month</MenuItem>
                        }
                        {
                            startMonthOptions.map((val) => {
                                return <MenuItem key={val.name} value={val.value}>
                                            {val.name}
                                        </MenuItem> 
                            })
                        }
                        
                    </Select>
                </Grid>

            </Grid> 
        
        </Grid>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization
});

const mapDispatchToProps = (dispatch) => ({
    updateOrganization: (organization) => dispatch(updateOrganization(organization)),
    updateSelectedOrg: (organization) => dispatch(updateSelectedOrg(organization))
});

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingOrganization);