import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Button, Menu, Tooltip, Popover, Badge, Fab, Typography, ClickAwayListener, Grid, Link  } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import MenuItem from '@material-ui/core/MenuItem';

import { useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {logout} from '../actions/auth';
import {history} from '../routers/AppRouter';
import {logoutOrgs} from '../actions/organization';
import {setSelectedOrg, logoutSelectedOrg, fetchOrgDetails, setSelectedTemplate, SelectedDrawerState, 
    setCDSalt, setSelectedDrawerState, setCurrentEmailState, setIsEmailSendError} from '../actions/selectedOrganization';
import {consoleToLog, trackGAEvent} from '../util/AppUtil';
import { setLoading } from '../actions/loading';
import {logoutFilters} from '../actions/filters';
import {clearSelectedFilters} from '../actions/filters';
import Notifications from './Notifications';
import { firebase } from '../firebase/firebase';
//import { getAuth, signOut } from "firebase/auth";
import {clearActivityReportFilter} from '../actions/activityReports';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import {logoutApi} from '../services/authService';
import AddIcon from '@material-ui/icons/Add';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import FolderOutlinedIcon from '@material-ui/icons/FolderOutlined';
import NoteOutlinedIcon from '@material-ui/icons/NoteOutlined';
import {AccessLevel, shouldDisable} from '../util/AppUtil';
import {setEmailSentFrom} from '../actions/selectedOrganization';
import AppsIcon from '@material-ui/icons/Apps';
import ReceiptIcon from '@material-ui/icons/Receipt';

const useStyles = makeStyles((theme) => ({
    MuiPopoverroot : {
        '& .MuiPopover-paper' : {
        width:'550px !important',
        borderRadius: '0px !important',
        height:'calc(100vh - 51px)',
        marginTop:"16px",
        flexGrow: 1,
        scrollbarWidth: 'none',
        marginLeft:"16px",
        overflow:'auto',
        "&::-webkit-scrollbar": {
            display: "none"
        } 
        }
    },
    logo: {
        maxWidth: 120,
        marginLeft: "15px",
        cursor:'pointer'
    },
    login: {
        marginLeft: 'auto',
    },
      selectEmpty: {
        color: "white",
        padding: "0 14px",
        backgroundColor: "transparent"
      },
      icons : {
        backgroundColor: "transparent",
        color: "white",
        fontSize: "18px",
        marginTop: "8px",
        minWidth: "40px"
      },
      colorSecondary : {
        color: theme.palette.primary.main,
        background: theme.palette.secondary.contrastText
    },
    actionIconStyle: {
        width:'20px',
        marginRight: '6px',
        filter:"invert(30%)"
    },
    approvalIconStyle: {
        width:'25px', 
        marginTop:'-4px',
        [theme.breakpoints.down(1450)] : {
            width:'24px', 
            marginTop:'-3px',
        }
    },
    profileIcon: {
        [theme.breakpoints.down(1450)] : {
            position:'relative',
            top:'1px'
        }
    },
    addIconStyle: {
        [theme.breakpoints.down(1450)] : {
            position:'relative',
            top:'2px'
        }
    },
    dotStyle: {
        width: '8px',
        height: '8px',
        background: '#FF6600',
        borderRadius: '50%',
        position: 'absolute',
        top: '4px',
        left: '26px',
        [theme.breakpoints.down(1450)] : {
            top: '5px',
            left: '23.5px',
        }
    },
    avatar : {
        minHeight: "28px",
        height: "28px",
        width: "28px",
        fontSize: '13px !important',
        color: '#fff !important',
        border: '1px solid #fff !important',
        background:'#3d3d3d !important',
        display:'flex',
        alignItems:'center'
    },  
    list : {
        cursor:'pointer',
        '&:hover' : {
            background:'#f5f5f5'
        }
    },
    otherAppStyle: {
        display:'flex',
        alignItems:'center',
        cursor:'pointer'
    },
    displayOtherAppItem: {
        display: 'block',
        position: 'absolute',
        top:'-1px',
        left:'-160px',
        backgroundColor: '#ffffff',
        border: '1px solid #ccc',
        borderRadius: '4px',
        width: '180px',
    },
    folderList: {
        display: 'flex',
        alignItems: 'center',
        padding:'7px 10px 7px 16px',
        color:'#333',
        textDecoration:'none !important',
        cursor:'pointer',
    },
  }));

const Header = (props) => {
    const classes = useStyles(props);
    const [anchorEl, setAnchorEl] = useState(null);
    const [profileEl, setProfileEl] = useState(null);
    const { name } = props.selectedOrganization.organization ? props.selectedOrganization.organization : { name: '' };
    const [selectedText, setSelectedText] = useState(name);
    const [orgChanged, setOrgChanged] = useState(null);

    const [anchorEl2, setAnchorEl2] = useState(null);
    const [anchorEl3, setAnchorEl3] = useState(null);
    const [isActive, setIsAcitve] = useState(false);
    const [showOtherAppItem, setShowOtherAppItem] = useState(false);

    let count = props.waiting_for_my_approval ? props.waiting_for_my_approval : 0;
    const open = Boolean(anchorEl2);
    const id = open ? 'notification-popover' : undefined;

    let notifiCount = (props.selectedOrganization &&
        props.selectedOrganization.organization &&
        props.selectedOrganization.organization.id &&
        props.notifications &&
        props.notifications[props.selectedOrganization.organization.id] &&
        props.notifications[props.selectedOrganization.organization.id] !== 0
    ) ? props.notifications[props.selectedOrganization.organization.id] :  0;

    const { access_level } = props.selectedOrganization || {};
    const accessLevelManager = shouldDisable(AccessLevel.getUserAccessLevelValue(access_level), AccessLevel.MANAGER);
    const accessLevelMember = shouldDisable(AccessLevel.getUserAccessLevelValue(access_level), AccessLevel.MEMBER);

    const workTabOpen = props.setDrawerState === SelectedDrawerState.SHOW_OVERDUE_CHECKLIST || props.setDrawerState === SelectedDrawerState.SHOW_UPCOMING_CHECKLIST ||
    props.setDrawerState === SelectedDrawerState.SHOW_ALL_CHECKLIST;

    useEffect(() => {
        if(orgChanged) {
            const organization_id = props.selectedOrganization.organization.id;
            props.fetchOrgDetails(organization_id, props.startDateFilter, props.endDateFilter, 
                props.companiesFilter, props.selectedMembersFilter, props.selectedTemplate, props.auth.user?.scope);
            setOrgChanged(false)
        }
    }, [orgChanged])

    const handleClick = (event) => {
     setAnchorEl(event.currentTarget);
    };

    const handleClose = (event) => {
      const value = event.currentTarget.dataset.myValue;
      consoleToLog(event.currentTarget.dataset.myValue);
      setSelectedText(value ? event.currentTarget.dataset.myValue : selectedText);
      setAnchorEl(null);
    };

    const handleProfileMenuClick = (event) => {
        //setProfileEl(event.currentTarget);
        setIsAcitve(true)
    };

    const handleProfileMenuClose = () => {
        //setProfileEl(null);
        setIsAcitve(false);
        setShowOtherAppItem(false);
    };

    const handleNotificationClick = (event) => {
        setAnchorEl2(event.currentTarget);
    };

    const handleNotificationClose = () => {
        setAnchorEl2(null)
    }

    const handleActionsMenuOpen = (event) => {
        setAnchorEl3(event.currentTarget);
    };

    const handleActionsMenuClose = () => {
        setAnchorEl3(null)
    }

    const logoutFirebase = () => {
        firebase.auth().signOut();
    }

    const onLogoutClick = () => {
        logoutApi()
            .then((response) => {
                const res = response.data;
                props.logout();
                props.logoutOrgs();
                props.logoutSelectedOrg();
                props.logoutFilters();
                logoutFirebase();
                window.open(`${process.env.REACT_APP_AUTH_APP_DOMAIN_NAME}/login?service=workplace`, '_self')
            })
            .catch((e) => {
                consoleToLog('Error logoutApi: ', e.response);
                props.logout();
                props.logoutOrgs();
                props.logoutSelectedOrg();
                props.logoutFilters();
                logoutFirebase();
                window.open(`${process.env.REACT_APP_AUTH_APP_DOMAIN_NAME}/login?service=workplace`, '_self')
                if(e.response.data && e.response.data.message) {
                    return;
                }
        })
    };

    const onMyProfileClick = () => {
        trackGAEvent(props.selectedOrganization.organization.name, 'Clicked My Profile', `${props.auth.user.firstname} ${props.auth.user.lastname} `);
        props.isFromWaitingFromMyApproval && history.push('/dashboard');
        props.setSelectedTemplate('');
        props.setSelectedDrawerState(SelectedDrawerState.SHOW_MY_PROFILE);
        //setProfileEl(null);
        setIsAcitve(false);
    }

    const onMyOrgClick = () => {
        trackGAEvent(props.selectedOrganization.organization.name, 'Clicked My Organization', `${props.auth.user.firstname} ${props.auth.user.lastname} `);
        props.isFromWaitingFromMyApproval && history.push('/dashboard');
        props.setSelectedTemplate('');
        props.setSelectedDrawerState(SelectedDrawerState.SHOW_MY_ORGS);
        //setProfileEl(null);
        setIsAcitve(false);
    }

    const onChangeOrgClick = (org) => {
        history.push('/dashboard');
        if (props.auth && props.auth.user) {
            if (org.organization.id !== props.selectedOrganization.organization.id) {
                trackGAEvent(org.name, `Changed Organization to ${org.name}`, `${props.auth.user.firstname} ${props.auth.user.lastname} `);
                //Set selected org
                if(org.organization.onboarding) {
                    props.setSelectedDrawerState(SelectedDrawerState.SHOW_ONBOARDING);
                } 
                if(!org.organization.onboarding && props.setDrawerState === SelectedDrawerState.SHOW_ONBOARDING) {
                    props.setSelectedDrawerState(SelectedDrawerState.SHOW_ALL_CHECKLIST);
                }
                props.setSelectedOrg(org);
                props.clearSelectedFilters();
                props.setCDSalt('');
                props.setCurrentEmailState(undefined);
                props.setIsEmailSendError(false);
                setOrgChanged(true);
                props.setLoading(true);
                setAnchorEl(null);
            }
        }      
    }

    // const showNotificationsCount = () => {
    //     let count = 0;
    //     return (
    //         <div>
    //             {
    //                 (props.selectedOrganization &&
    //                     props.selectedOrganization.organization &&
    //                     props.selectedOrganization.organization.id &&
    //                     props.notifications &&
    //                     props.notifications[props.selectedOrganization.organization.id] &&
    //                     props.notifications[props.selectedOrganization.organization.id] !== 0
    //                 ) ?
    //                     props.notifications[props.selectedOrganization.organization.id] <= 9 ?
    //                     count = (props.notifications[props.selectedOrganization.organization.id]) 
    //                     : count = '9+'
                        
    //                     : count = 0
    //             }
    //         </div>
    //     );
    // }
    const goToHomePage = () => {
        if (history.location.pathname !== 'dashboard') {
                history.push('/dashboard');
        }
    }

    const onSendEmailClick = () => {
        trackGAEvent(props.selectedOrganization.organization.name, 'Workplace: New email from header clicked', `${props.auth.user.firstname} ${props.auth.user.lastname} `);
        props.handleSendEmailDialogOpen();
        handleActionsMenuClose();
        props.setEmailSentFrom('Header');
    }

    const onNewChecklistClick = () => {
        trackGAEvent(props.selectedOrganization.organization.name, 'Clicked create new checklist from header', `${props.auth.user.firstname} ${props.auth.user.lastname} `);
        if(workTabOpen && props.selectedTemplate) {
            props.handleChecklistModalOpen();
            handleActionsMenuClose();
        } else {
            props.toggleDrawer();
            props.handleChecklistModalOpen();
            handleActionsMenuClose();
        }
        
    }

    const onNewFolderClick = () => {
        trackGAEvent(props.selectedOrganization.organization.name, 'Clicked create new folder from header', `${props.auth.user.firstname} ${props.auth.user.lastname} `);
        if(workTabOpen && props.selectedTemplate) {
            props.handleFolderModalOpen();
            handleActionsMenuClose();
        } else {
            props.toggleDrawer();
            props.handleFolderModalOpen();
            handleActionsMenuClose();
        }
    }

    const onNewTemplateClick = () => {
        trackGAEvent(props.selectedOrganization.organization.name, 'Clicked create template from header', `${props.auth.user.firstname} ${props.auth.user.lastname} `);
        history.push('/template/create');
        handleActionsMenuClose();
    }

    const onTriageClick = () => {
        trackGAEvent(props.selectedOrganization.organization.name, 'Clicked Inbox/Triage', `${props.auth.user.firstname} ${props.auth.user.lastname} `);
        window.open(`${process.env.REACT_APP_GENIE_APP_DOMAIN}/?organization_id=${props.selectedOrganization.organization.id}&firstname=${props.auth.user.firstname}&lastname=${props.auth.user.lastname}`)
    }

    const onInvoicesAppClick = () => {
        window.open(`${process.env.REACT_APP_INVOICE_APP_DOMAIN_NAME}`, '_blank');
    }

    return (
        <AppBar position="static" elevation={0}>
            <Toolbar variant="dense">
                {(!props.isFromWaitingFromMyApproval && !props.isFromCreateTemplate && !props.notFoundPage && !props.verifyEmailPage) &&
                    <Tooltip title="Drawer Open/Close" arrow>
                    <Button
                        onClick={props.drawerAction}
                        className={classes.icons}
                        >
                        <i className="flaticon-menu"></i>
                    </Button>
                </Tooltip>}
                {props.isFromWaitingFromMyApproval &&
                <Tooltip title="Home" arrow>
                    <Button
                        onClick={goToHomePage}
                        className={classes.icons}
                        >
                        <HomeOutlinedIcon fontSize='small' style={{marginTop:'-5px', fontSize:'28px', fontWeight:400}}/>
                    </Button>
                </Tooltip>
                }
                <img src="/images/eprocessify-logo-header.svg"
                    onClick={goToHomePage}
                    alt="logo" 
                    className={classes.logo}/>
                {!props.verifyEmailPage && !props.notFoundPage &&
                <div className={classes.login}>
                    {/* <Tooltip title="Send Email" arrow>
                        <Button aria-label="delete"
                            style={{marginTop:'-1px'}}
                            onClick={props.handleSendEmailDialogOpen}
                            className={classes.icons}>
                            <AddIcon/>
                        </Button>
                    </Tooltip> */}
                    <Tooltip title="Organizations" arrow>
                        <Button aria-controls="simple-menu" 
                            aria-haspopup="true"
                            onClick={handleClick}
                            endIcon={<ArrowDropDownIcon />}
                            style={{
                                color: "white",
                                border: '1px solid #ffffff',
                                fontWeight: "400",
                                fontSize: "12px",
                                marginRight:"10px"
                            }}
                            variant="outlined"
                            >
                            {name}
                        </Button>
                    </Tooltip>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        >
                            {props.organizations &&
                                    props.organizations.length !== 0 ? (
                                        props.organizations.map((org) => {
                                            return <MenuItem key={org.id} data-my-value={org.organization.name} 
                                                            onClick={() => onChangeOrgClick(org)}
                                                            han
                                                    >
                                                    {org.organization.name}
                                                    </MenuItem>
                                        })
                                    ) : <MenuItem>No Organizations Found</MenuItem>
                            } {/* <MenuItem onClick={handleClose} data-my-value="CodeToArt Technology">CodeToArt Technology</MenuItem>
                        <MenuItem onClick={handleClose} data-my-value="CA Rushab JAin Org">CA Rushab JAin Org</MenuItem>
                        <MenuItem onClick={handleClose} data-my-value="Girish Associates">Girish Associates</MenuItem>
                        */}
                    </Menu>
                 {!accessLevelManager ?
                    <>
                        <Tooltip title="Actions" arrow>
                                <Button
                                    onClick={handleActionsMenuOpen}
                                    className={classes.icons}>
                                    <i className={`flaticon-add-2 ${classes.addIconStyle}`}></i>
                                </Button>
                        </Tooltip>
                        <Menu
                            id="action-menu"
                            anchorEl={anchorEl3}
                            keepMounted
                            open={Boolean(anchorEl3)}
                            onClose={handleActionsMenuClose}
                            style={{marginTop:'24px'}}
                        >
                            <MenuItem onClick={onSendEmailClick}>
                                <img src="/images/new-mail.png" className={classes.actionIconStyle} alt="new email"/>
                                {/* <EmailOutlinedIcon fontSize='small'  className={classes.actionIconStyle}/> */}
                                New Email (Ctrl + E)
                            </MenuItem>
                            <MenuItem onClick={onNewChecklistClick}>
                                <img src="/images/new-checklist.png" className={classes.actionIconStyle} alt="new checklist"/>
                                {/* <AssignmentTurnedInOutlinedIcon fontSize='small'  className={classes.actionIconStyle}/> */}
                                New Checklist
                            </MenuItem>
                            {workTabOpen && props.selectedTemplate && 
                            <MenuItem onClick={onNewFolderClick}>
                                <img src="/images/new-folder.png" className={classes.actionIconStyle} alt="create folder"/>
                                {/* <FolderOutlinedIcon fontSize='small'  className={classes.actionIconStyle}/> */}
                                New Folder
                            </MenuItem>}
                            
                            <MenuItem onClick={onNewTemplateClick}>
                                <img src="/images/new-template.png" className={classes.actionIconStyle} alt="create template"/>
                                {/* <NoteOutlinedIcon fontSize='small'  className={classes.actionIconStyle}/> */}
                                New Template
                            </MenuItem>
 
                        </Menu>
                    </> :
                    !accessLevelMember && 
                    <>                    
                         <Tooltip title="Actions" arrow>
                                <Button
                                    onClick={handleActionsMenuOpen}
                                    className={classes.icons}>
                                    <i className={`flaticon-add-2 ${classes.addIconStyle}`}></i>
                                </Button>
                        </Tooltip>
                        <Menu
                            id="action-menu"
                            anchorEl={anchorEl3}
                            keepMounted
                            open={Boolean(anchorEl3)}
                            onClose={handleActionsMenuClose}
                            style={{marginTop:'24px'}}
                        >
                            <MenuItem onClick={onSendEmailClick}>
                                <img src="/images/new-mail.png" className={classes.actionIconStyle} alt="new email"/>
                                {/* <EmailOutlinedIcon fontSize='small'  className={classes.actionIconStyle}/> */}
                                New Email (Ctrl + E)
                            </MenuItem>
                        </Menu>
                        </>
                    }

                    {/* <Tooltip title="Waiting for my Approval" arrow>
                        <Button
                            onClick={() => {
                                trackGAEvent(props.selectedOrganization.organization.name, 'Open Approval', `${props.auth.user.firstname} ${props.auth.user.lastname} `);
                                history.push('/waiting-for-my-approval')
                            }}
                            className={classes.icons}>
                            <img src="/images/waiting-approval.png" className={classes.approvalIconStyle}/>
                            {   count && count > 0 ?
                                    <div className={classes.dotStyle}></div> : <div></div>
                            }
                        </Button>
                    </Tooltip> */}
                    <Tooltip title="Inbox" arrow>
                        <Button
                            onClick={onTriageClick}
                            className={classes.icons}>
                            <img src="/images/inbox-icon.png" style={{width:'30px', marginTop:'-4px'}}/>
                        </Button>
                    </Tooltip>
                    {/* <Tooltip title="Activity Feed" arrow>
                        <Button
                            style={{
                                backgroundColor: "transparent",
                                color: "white",
                                fontSize: "18px",
                                alignItem: "right",
                                marginTop: "8px",
                                minWidth: "40px",
                                marginLeft: "15px"
                            }}
                            >
                            <i className="flaticon-chat-1"></i>
                        </Button>
                    </Tooltip> */}
                    {/* <Tooltip title="Waiting for my Approval" arrow>
                        <Button
                            className={classes.icons}
                            onClick={() => {
                                trackGAEvent(props.selectedOrganization.organization.name, 'Open Approval', `${props.auth.user.firstname} ${props.auth.user.lastname} `);
                                history.push('/waiting-for-my-approval')
                            }}
                            >
                            <i className="flaticon-checked"></i>
                            {   count && count > 0 ?
                                    <div style={{
                                        width: '8px',
                                        height: '8px',
                                        background: '#FF6600',
                                        borderRadius: '50%',
                                        position: 'absolute',
                                        top: '8px',
                                        left: '26px'
                                    }}></div> : <div></div>
                            }
                        </Button>
                    </Tooltip>
                    <>
                            <Tooltip title="Notifications" arrow>
                                    <Button
                                        onClick={handleNotificationClick}
                                        className={classes.icons}>
                                        <Badge badgeContent={notifiCount}
                                                classes={{
                                                    colorSecondary: classes.colorSecondary
                                                }}
                                                max={9}
                                                color='secondary'
                                                invisible={(notifiCount === 0)}>
                                            <i className="flaticon-ring"></i>
                                        </Badge> 
                                    </Button>
                            </Tooltip>
                        
                        <Popover
                            classes={{
                                root: classes.MuiPopoverroot
                            }}
                            elevation={4}
                            id={id}
                            open={open}
                            anchorEl={anchorEl2}
                            onClose={handleNotificationClose}
                            anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                            }}
                            transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                            }}>
                                <Notifications handleNotificationClose={handleNotificationClose}/>
                        </Popover>
                    </> */}
                    <Tooltip title={props.auth.user?.firstname + ' ' + props.auth.user?.lastname} arrow>
                        <Button aria-label="delete"  
                            style={{position:'relative', top:'-2.5px'}}
                            onClick={handleProfileMenuClick}
                            className={classes.icons}>
                            {/* <i className={`flaticon-user ${classes.profileIcon}`}></i> */}
                            <Fab className={classes.avatar}>
                                {props.auth.user?.firstname?.charAt(0) + props.auth.user?.lastname?.charAt(0)}
                            </Fab>
                        </Button>
                    </Tooltip>
                    {isActive &&
                    <ClickAwayListener onClickAway={handleProfileMenuClose}>
                    <Grid item className={`profile_menu ${isActive ? "active" : "inactive"}`}>
                        <ul>
                            
                            <li className={classes.list} onClick={onMyProfileClick}>
                                <a>
                                    <img src="/images/my-profile.png" className={classes.actionIconStyle}/>
                                    My Profile
                                </a>
                            </li>

                            <li className={classes.list} onClick={onMyOrgClick}>
                                <a>
                                    <img src="/images/my-organization.png" className={classes.actionIconStyle}/>
                                    My Organization 
                                </a>
                            </li>

                            <li style={{position:'relative'}} onClick={() => setShowOtherAppItem(!showOtherAppItem)}>
                                <a className={classes.otherAppStyle}> 
                                    <AppsIcon style={{marginRight:'6px', color:'#555', marginLeft:'-2px'}}/>
                                    Other Apps
                                    <i className="flaticon-right-arrow iconStyle"></i>
                                </a>
                                {showOtherAppItem &&
                                <div className={classes.displayOtherAppItem} onClick={onInvoicesAppClick}>
                                    <Typography  variant="body2"  className={classes.folderList}>   
                                        <ReceiptIcon style={{marginRight:'6px', color:'#555', marginLeft:'-2px'}}/>
                                        Invoices
                                        <i className="flaticon-share iconStyle1"></i>
                                    </Typography>
                                </div>}
                            </li>

                            <li className={classes.list} onClick={onLogoutClick}>
                                <a>
                                    <img src="/images/logout.png" className={classes.actionIconStyle}/>
                                    Logout
                                </a>
                            </li>
                        </ul>
                    </Grid>
                    </ClickAwayListener>
                }
                </div>  
                }  
                {/* <Button color="inherit" className={classes.login}>Login</Button> */}
            </Toolbar>
        </AppBar>
    )
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    organizations: state.organizations,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    selectedTemplate: state.organizationInfo.selectedTemplate,
    startDateFilter: state.filters.startDateFilter,
    endDateFilter: state.filters.endDateFilter,
    selectedMembersFilter: state.filters.selectedMembersFilter,
    companiesFilter: state.filters.companiesFilter,
    waiting_for_my_approval: state.organizationInfo.waiting_for_my_approval_checklist_count,
    setDrawerState: state.organizationInfo.setDrawerState,
    notifications: state.notifications,
    templates: state.organizationInfo.templates
});

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(logout()),
    logoutOrgs: () => dispatch(logoutOrgs()),
    logoutSelectedOrg: () => dispatch(logoutSelectedOrg()),
    setLoading: (isLoading) => dispatch(setLoading(isLoading)),
    logoutFilters: () => dispatch(logoutFilters()),

    setSelectedOrg: (organization) => dispatch(setSelectedOrg(organization)),
    fetchOrgDetails: (organization_id, start_date, end_date, companiesFilter, selectedMembersFilter, selectedTemplate, scope) => 
    dispatch(fetchOrgDetails(organization_id, start_date, end_date, companiesFilter, selectedMembersFilter, selectedTemplate, scope)),
    setSelectedTemplate: (template) => dispatch(setSelectedTemplate(template)),
    clearSelectedFilters: () => dispatch(clearSelectedFilters()),
    setCDSalt: (salt) => dispatch(setCDSalt(salt)),
    clearActivityReportFilter: () => dispatch(clearActivityReportFilter()),

    setSelectedDrawerState: (drawerState) => dispatch(setSelectedDrawerState(drawerState)),

    setCurrentEmailState: (emailState) => dispatch(setCurrentEmailState(emailState)),
    setIsEmailSendError: (emailSendError) => dispatch(setIsEmailSendError(emailSendError)),
    setEmailSentFrom: (str) => dispatch(setEmailSentFrom(str))
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);