import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, CircularProgress, Typography} from '@material-ui/core';
import BaseComponent from '../components/Base/BaseComponent';
import { useSnackbar } from 'notistack';
import { consoleToLog } from '../util/AppUtil';
import { history } from '../routers/AppRouter';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import CancelPresentationSharpIcon from '@material-ui/icons/CancelPresentationSharp';
import {connect} from 'react-redux';
import { logout } from '../actions/auth';
import {updateAllUnverifiedEmailStatusApi} from '../services/genieService';

const useStyles = makeStyles((theme) => ({
    mainContent : {
        width: '100%', 
        backgroundColor: theme.palette.primary.light, 
        transition: ".5s", 
        flexGrow: 1,
        //overflow:'hidden',
        height:'calc(100vh - 48px)',
        overflow:'auto',
        overflowX:'hidden'
    },
    verifyEmailContainer : {
        background:'#FFFFFF',
        borderRadius:'4px',
        padding:'8px',
        height:'250px',
        position:'relative'
    },
    tickIcon : {
        fontSize: '50px',
        color:"green"
    },
    loginStyle : {
        color:"#20a8e0",
        fontWeight:'600',
        marginLeft:'4px',
        cursor:'pointer'
    },
    itemsContainer : {
        position:'relative', 
        top:'100%', 
        bottom:'100%',
        transform:'translateY(-160%)'
    },
    loading: {
        position:'relative',
        left:'50%',
        right:'50%',
    },
    verificationMessage : {
        color:'#6c757d', 
        fontSize:'28px', 
        marginTop:'4px', 
        marginBottom:'4px',
        fontWeight:'500'
    },
    clearIcon : {
        fontSize: '50px',
        color:"red"
    }

    }
));    


const OrgEmailVerificationSuccess = (props) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();

    const [loading, setLoading] = useState(false);
    let pathname = history.location.pathname;

    useEffect(() => {
            if(pathname === '/organization-email-verification-success/') {
                setLoading(true);
                organizationEmailVerified();
            }    
    }, []);

    const organizationEmailVerified = () => {
        const access_token = props.auth.access_token;
        updateAllUnverifiedEmailStatusApi(access_token)
                .then((response) => {
                    const res = response.data;
                    consoleToLog("Response updateAllUnverifiedEmailStatusApi: ", res);
                    enqueueSnackbar('Email Verified', {
                        variant:'success'
                    })
                    setLoading(false);
                })
                .catch((e) => {
                    consoleToLog("updateAllUnverifiedEmailStatusApi error", e.response);
                    setLoading(false);
                    if (e.response.data && e.response.data.msg) {
                        return false;
                    }
                });
    }       
    console.log('pathname', pathname)

    const onLoginClick = () => {
        window.open(`${process.env.REACT_APP_AUTH_APP_DOMAIN_NAME}/login?service=workplace`, '_self');
    }

    return (
        <BaseComponent verifyEmailPage={true}>
            <div className={classes.mainContent}>
                <Grid item container justifyContent='center'
                    style={{marginTop:'32px'}}
                    alignItems='center'>
                    <Grid item sm={7} className={classes.verifyEmailContainer}>
                    {loading ? <CircularProgress className={classes.loading}/>
                     :  
                        (pathname === '/organization-email-verification-success/') ? 
                        <Grid item container direction="column" 
                            alignItems='center'
                            className={classes.itemsContainer}>
                                <CheckBoxOutlinedIcon className={classes.tickIcon}/>
                                <h2 className={classes.verificationMessage}>
                                    Your organization email is verified.</h2>
                                {/* <Typography style={{fontSize:'1.1rem'}}>
                                   Please
                                    <span className={classes.loginStyle}
                                        onClick={() => history.push('/login')}
                                    >
                                        Login
                                    </span> here to continue
                                </Typography> */}
                        </Grid>
                        :
                        (pathname === '/organization-email-verification-failure/') &&
                        <Grid item container direction="column" 
                            alignItems='center'
                            className={classes.itemsContainer}>
                                <CancelPresentationSharpIcon className={classes.clearIcon}/>
                                <h2 className={classes.verificationMessage}>
                                Your organization email is not verified.</h2>
                                <Typography style={{fontSize:'1.1rem'}}>
                                   Please
                                    <span className={classes.loginStyle}
                                        onClick={onLoginClick}
                                    >
                                        Login
                                    </span> here to continue and try sending verification email again.
                                </Typography>
                        </Grid>
                    }

                    </Grid>

                </Grid>
            </div>
        </BaseComponent>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth
});

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(logout())
});

export default connect(mapStateToProps, mapDispatchToProps)(OrgEmailVerificationSuccess);