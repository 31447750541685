import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Grid, Typography, Link, TextField } from '@material-ui/core';
import { useState, useEffect } from 'react';
import { emailValidator } from '../util/validator';
import ForgotPassword from '../components/modals/ForgotPassword';
import { useSnackbar } from 'notistack';
import {signInApi} from '../services/authService';
import {login} from '../actions/auth';
import {connect} from 'react-redux'; 
import { consoleToLog, trackGAEvent, trackGAPageView } from '../util/AppUtil';
import CircularProgress from '@material-ui/core/CircularProgress';
import { firebase } from '../firebase/firebase';
//import { getAuth, signInWithCustomToken } from "firebase/auth";

const useStyles = makeStyles((theme) => ({
    innerbg: {
        border: "1px solid #dbdbdb",
        borderRadius: "10px",
        padding: "45px 100px",
        background: "#ffffff",
        [theme.breakpoints.down("md")]: {
            padding: "45px 20px",
          }
    },
    mainbg: {
        background: theme.palette.primary.light
    },
    logo: {
        maxWidth: "200px",
        marginBottom: "10px",
        [theme.breakpoints.down("md")]: {
            maxWidth: "180px",
          }
    },
    title: {
        marginTop: "20px",
        fontSize: "1.1rem"
    },
    circularProgress: {
        marginLeft: 0,
        marginRight: theme.spacing(2),
        color: 'white'
      },
  }));

const LoginPage = (props) => {
    //consoleToLog("Theme vikas: ", props);
    const { enqueueSnackbar } = useSnackbar();

      const classes = useStyles(props);
      const [email, setEmail] = useState('');
      const [password, setPassword] = useState('');
      const [emailInValid, setEmailInValid] = useState(false);
      const [passwordInValid, setPasswordInValid] = useState(false);
      const [loading, setLoading] = useState(false);
      
      const [open, setOpen] = useState(false);

      const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };

      useEffect(()=> {
        trackGAPageView('/login');
      }, [])

    const loginToFirebase = (token) => {
        firebase.auth().signInWithCustomToken(token)
            .catch(function (error) {
                consoleToLog("Firebase login api error: ", error);
            });
        }

      const onSubmitClick = () => {
        if (!email) {
            setEmailInValid(true);
            return;
        }
        const isEmailValid = emailValidator(email);
        if (!isEmailValid) {
            setEmailInValid(true);
            return;
        }

        if (!password) {
            setPasswordInValid(true);
            return;
        }

        setLoading(true)
        signInApi(email, password)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response signInApi: ", res);
                setLoading(false)
                enqueueSnackbar('Login Successful', { 
                    variant: 'success',
                });

                //firebase login
                const token = res.firebase_access_token;
                if (!token) {
                    consoleToLog('Firebase token not found');
                } else {
                    loginToFirebase(token);
                }


                props.login(res);
            })
            .catch((e) => {
                consoleToLog("Response signInApi: ", e.response);
                setLoading(false)
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, { 
                        variant: 'error',
                    });
                    return false;
                }
            });
      }

    return (
            <div className={classes.mainbg}>
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    minHeight="100vh"
                    >
                    <Grid container item direction="column" xs={10} md={6} lg={5} 
                        className={classes.innerbg}
                    >
                        <img src="/images/eprocessify-logo-svg.svg" 
                            alt="logo" 
                            className={classes.logo} />
                        <Typography variant="body1"
                            className={classes.title}>Log in to eProcessify</Typography>
                        <Typography variant="subtitle1">Enter your credentials to access your account</Typography>

                        <Grid container item xs={12} style={{marginTop: "15px"}}>
                            <TextField
                                fullWidth
                                id="email"
                                label="Email"
                                type="text"
                                variant="outlined"
                                margin="dense"
                                error={emailInValid}
                                helperText={emailInValid ? "* Email is invalid!" : ''}
                                onChange={e => { 
                                    setEmail(e.target.value);
                                    setEmailInValid(false);
                                }}
                                InputLabelProps={{style: {fontSize: 14}}}
                            />
                        </Grid>
                        <Grid container item xs={12} style={{marginTop: "15px"}}>
                            <TextField
                                fullWidth
                                id="standard-password-input"
                                label="Password"
                                type="password"
                                margin="dense"
                                variant="outlined"
                                onKeyPress={(ev) => {
                                    //consoleToLog(`Pressed keyCode ${ev.key}`);
                                    if (ev.key === 'Enter') {
                                        onSubmitClick();
                                      ev.preventDefault();
                                    }
                                  }}
                                onChange={e => { 
                                    setPassword(e.target.value);
                                    setPasswordInValid(false);
                                }}
                                error={passwordInValid}
                                helperText={passwordInValid ? "* Password is required!" : ''}
                                InputLabelProps={{style: {fontSize: 14}}}
                            />
                        </Grid>
                        <Link style={{marginTop: "12px", fontSize: "14px"}} 
                            color="secondary"
                            onClick={handleClickOpen}
                            >Forgot your password?</Link>
                        <Button variant="contained" 
                            color="primary" 
                            style={{marginTop: "25px"}}
                            onClick={()=>onSubmitClick()}>
                            {loading && <CircularProgress size={24} className={classes.circularProgress}/>}    
                            Login
                        </Button>
                    </Grid>
                </Box>
                <ForgotPassword isOpen={open} handleClose={handleClose}/>
            </div>
    )
}

const mapStateToProps = (state) => ({
    auth: state.auth
});

const mapDispatchToProps = (dispatch) => ({
    login: (auth) => dispatch(login(auth))
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);