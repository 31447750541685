import React, {useState, useEffect} from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { connect } from "react-redux";
import { useIsMount } from "../useIsMount";
import { useSnackbar } from "notistack";
import DocumentItem from "./DocumentItem";
import { CircularProgress } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { Button } from "@material-ui/core";
import PublishOutlinedIcon from "@material-ui/icons/PublishOutlined";
import UploadAttachmentModal from "../modals/UploadAttachmentModal";

const useStyles = makeStyles((theme) => ({
  dialogboxFooter: {
    display: "flex",
    position: "absolute",
    bottom: "0px",
    left: "0",
    right: "0",
    padding: "12px 0px 12px 12px",
    zIndex: "15",
    background: "#fff",
    borderTop: "1px solid #ccc",
  },
  notAttachmentsImage: {
    height: "13rem",
    width: "15rem",
    marginTop: "3rem",
  },
  notAttachmentsText: {
    fontSize: "20px",
    fontWeight: "600",
  },
  notAttachmentsButton: {
    fontSize: "15px",
    width: "19rem",
    marginTop: "20px",
  },
  uploadAttachmentTypo: {
    fontSize: "15px",
    fontWeight: "600",
    margin: "5px 10px",
  },
  uploadAttachmentLabel: {
    fontSize: "13px",
    marginTop: "10px",
    marginBottom: "10px",
    color: "black",
  },
  dragcontainerFooter: {
    fontSize: "12px",
    color: "gray",
    marginLeft: "18px",
    marginTop: "-14px",
  },
  dragContainerButtons: {
    marginLeft: "10px",
    width: "7rem",
  },
  uploadAttachmentLargeBtn: {
    fontSize: "15px",
    width: "13rem",
  },
  closeButton: {
    paddingLeft: "22.5rem",
    paddingTop: "5px",
  },
  fileUploaderComponent: {
    width: "auto",
    backgroundColor: "red",
    border: "none",
    outline: "none",
  },
  appBar: {
    background: "#F9F9F9",
    height: "45px",
    color: "#202020",
  },
  fileUploaderText: {
    fontSize: "13px",
    color: "black",
    fontWeight: "400",
  },
}));

const Documents = (props) => {
  const classes = useStyles();

  const onAttachmentDelete = (attachmentId) => {
    var updatedDeleteAttachmentArray = props.attachmentList.filter((attachment) => attachment.id !== attachmentId);
    // to parent
    props.setAttachmentList(updatedDeleteAttachmentArray)
    // setAttachmentList(updatedDeleteAttachmentArray);
  };


  const AttachmentList = () => {
    return (
      <Grid item container direction="row" sm={12} alignItems="center">
        <Grid
          item
          container
          direction="column"
          sm={1}
          className={classes.attachmentsHeader}
        ></Grid>
        <Grid
          item
          container
          direction="column"
          sm={3}
          className={classes.attachmentsHeader}
          style={{ paddingLeft: "10px" }}
        >
          File
        </Grid>
        <Grid
          item
          container
          direction="column"
          sm={3}
          className={classes.attachmentsHeader}
        >
          Uploaded By
        </Grid>
        <Grid
          item
          container
          direction="column"
          sm={2}
          className={classes.attachmentsHeader}
        >
          Uploaded
        </Grid>
        <Grid
          item
          container
          direction="column"
          sm={2}
          className={classes.attachmentsHeader}
        ></Grid>
      </Grid>
    );
  };

  const AttachmentFound = () => {
    return (
      <div>
        <Grid container style={{ marginBottom: "14px" }}>
          <AttachmentList />
        </Grid>
        {props.attachmentList &&
          props.attachmentList.length > 0 &&
          props.attachmentList.map((attachment) => {
            return (
              <DocumentItem
                attachment={attachment}
                onAttachmentDelete={onAttachmentDelete}
              />
            );
          })}
      </div>
    );
  };

  const AttachmentsNotFound = () => {
    return (
      <Grid item container style={{ marginTop: "2rem" }}>
        <Grid item lg={12} style={{ textAlign: "center" }}>
          <Typography variant="body1">No Attachments Found</Typography>
        </Grid>
      </Grid>
    );
  };

  const loadMoreLogic = () => {
    return (
        <Grid item container>
            <Grid item lg={12}>   
            {props.nextPageD  && (props.documentsLoading === false && props.isMoreLoadingD === false) &&
                <div style={{textAlign: "center", margin:'16px 0px'}}>
                    <Button type="button"
                        color="secondary"
                        variant="contained"
                        onClick={() => {props.handleMoreLoadingD()}}
                        >
                        Load More
                    </Button>
                </div>}

                {props.isMoreLoadingD && 
                  <CircularProgress size={35} style={{position:'relative', left:'50%', right:'50%', margin:'16px 0px'}}/>
                }
            </Grid>
        </Grid>
    )
}

// AttachmentsNotFound
const MasterAttachmentsNotFound = () => {
  return (
    <div>
      <Grid container direction="row">
        <Grid
          item
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          sm={12}
        >
          <img
            alt="No Attachments Found"
            src="/images/no_attachment_found.svg"
            className={classes.notAttachmentsImage}
          />
        </Grid>
        <Grid
          item
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Typography className={classes.notAttachmentsText}>
            No Master Attachments Found
          </Typography>
        </Grid>
        <Grid
          item
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Button
            variant="contained"
            color="primary"
            endIcon={<PublishOutlinedIcon />}
            size="large"
            className={classes.notAttachmentsButton}
            onClick={() => props.handleDrawerOpen()}
          >
            Upload Master Documents
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

  return (
    <>
      {props.documentsLoading ? (
        <Grid
          container
          sm={12}
          alignItems="center"
          justifyContent="center"
          style={{ marginTop: "2rem", overflow: "hidden" }}
        >
          <CircularProgress color="secondary" size={40} />
        </Grid>
      ) : props.attachmentList && props.attachmentList.length > 0 ? (
        <AttachmentFound />
      ) : (
        props.tabValue === '2' ? <AttachmentsNotFound /> : <MasterAttachmentsNotFound />
      )}

      {loadMoreLogic()}

      <UploadAttachmentModal open={props.open}
          handleDrawerClose={props.handleDrawerClose}
          title='Upload Attachment'
          attachmentList={props.attachmentList}
          setAttachmentList={props.setAttachmentList}
          companyObj={props.companyObj}
          fromClientDetails={true}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  selectedOrganization: state.organizationInfo.selectedOrganization,
  cd: state.organizationInfo.cd,
});

export default connect(mapStateToProps, null)(Documents);
