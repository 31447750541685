import React, {useState, useEffect, createRef} from 'react';
import {connect} from 'react-redux';
import {Grid, Container, Typography, Button, CircularProgress, Tooltip, Popover, Divider,Checkbox} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import { consoleToLog, getReadableActivityInHTML, displayIcons, trackGAEvent } from '../util/AppUtil';
import { DateRangePicker } from "materialui-daterange-picker";
import moment from 'moment';
import {getActivityReportApi} from '../services/authService';
import { useIsMount } from './useIsMount';
import { useSnackbar } from 'notistack';
import { setSelectedTemplate, setSelectedDrawerState, SelectedDrawerState } from '../actions/selectedOrganization';
import Fab from "@material-ui/core/Fab";
import {Link} from 'react-router-dom';
import ChecklistMembersFilter from './filters/ChecklistMembersFilter';
import RightToLeftSideDrawer from './RightToLeftSideDrawer';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import {useLocation} from 'react-router-dom';
import {setActivityReportStartDateFilter, setActivityReportEndDateFilter, clearActivityReportFilter} from '../actions/activityReports';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles((theme) => ({
    mainContent : {
        width: (showDrawer) => {
           return showDrawer ? "83%" : "100%";
        },
        backgroundColor: theme.palette.primary.light,
        transition: ".5s",
        flexGrow: 1,
        //overflow:'hidden',
        overflow:'auto',
        overflowX:'hidden'
        },

    container: {
        marginTop: "8px"
    },
    icons : {
        color: "black",
        fontSize: "18px",
        minWidth: "30px",
        marginLeft: "8px",
        marginTop:'-3px'
    },
    icons2 : {
        color: "black",
        fontSize: "18px",
        minWidth: "30px",
        lineHeight: "10px"
    },
    activtyIconContainer: {
        backgroundColor: '#3d92ff',
        minHeight: "30px",
        height: "30px",
        width: "30px",
        boxShadow: "none",
      },
    activtycommentIcon: {
        width: "15px",
        color: "white !important",
        marginLeft:'-3px',
        marginTop:'-2px',
        fontSize: "1.1rem",
        lineHeight:'0 !important',
        // height: "15px",
        // width: "15px",
        // color: "white",
        // transform: "scaleX(-1)",
        // fontSize: "1rem",

      },
    activtyhorizontalDivider: {
        display:'flex',
        width: "2px",
        //height: "3.8rem !important",
        backgroundColor: "#cacaca",
        marginTop: "0px",
        marginLeft:'15px',
        marginBottom: "0px",
        border:'none',
        '& last-child' : {
            height: "0",
        }
      },
    customColumn : {
        flexBasis:'69%',
        maxWidth:'69%'
    },
    loading: {
        position:'relative',
        left:'50%',
        right:'50%',
        top:'30px'
    },
    notAttachmentsImage: {
        height: "13rem",
        width: "15rem",
        marginTop: "3rem",
      },
    notAttachmentsText: {
        fontSize: "20px",
        fontWeight: "600",
        },
    notAttachmentsButton: {
        fontSize: "15px",
        width: "19rem",
        marginTop: "20px",
        },
    customMargin :{
        marginTop:'3px',
        marginRight:'1px'
    },
    activityText : {
        width: '1000px',
        ["@media (min-width:1400px)"]: {
            width: '1100px',
          },
         maxWidth:'90%' 
    },
    mainBg : {
        marginTop:'16px',
        background:'white',
        padding:'16px',
        borderRadius:'4px'
    },
    activityTime : {
        color:'#555555', 
        fontSize:'13px',
        marginTop:'-2px',
    },
    bulletIcons: {
        width: '9px',
        height: '9px', 
        color: '#ccc', 
        paddingLeft: '3px', 
        paddingTop: '0', 
        margin: '-3px 8px 0px 6px'
      }

}));

const ActivityReports = (props) => {
    const {showDrawer} = props;
    const classes = useStyles(showDrawer);
    const {enqueueSnackbar} = useSnackbar();
    const isMount = useIsMount();

    const [page, setPage] = useState(1);
    const [nextPage, setNextPage] = useState(false);
    const [activityList, setActivityList] = useState([]);
    const [dateRange, setDateRange] = useState({});
    const [loadingActivity, setLoadingActivity] = useState(false);
    const [isMoreLoading, setIsMoreLoading] = useState(false);
    const [scrollTop, setScrollTop] = useState(0);
    const [canScroll, setCanScroll] = useState(false);
    const [activityId, setActivityId] = useState(undefined);
    const [spin, setSpin] = useState(false);
    const [selected, setSelected] = useState("selected");

    const location = useLocation();
    //consoleToLog('location obj after coming from checklist modal', location);

    const myDivToFocus = createRef();

    const [anchorEl, setAnchorEl] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [openFilters, setOpenFilters] = useState(false);
    const [clearFilter, setClearFilter] = useState(false);

    const activity_list = location.state?.activityArr;

    useEffect(() => {
        if(location.state?.activityArr && location.state?.activityArr.length > 0) {
            setActivityList(activity_list);
            setIsMoreLoading(location.state?.isMoreLoading);
            setNextPage(location.state?.nextPage);
            setPage(location.state?.currPage);
            setScrollTop(location.state?.scrollTop)
            setCanScroll(true);
        } else { 
            getActivityReport();
        }
    }, []);

    useEffect(() => {
        if(isMount) {

        } else {
            if (isMoreLoading) {
                getActivityReport();
            }
        }
    }, [isMoreLoading]);

    useEffect(() => {
        if(!isMount) {
            consoleToLog("page, ", page);
            if (page === 1) {
                getActivityReport();
            }
        }
    }, [page, selected]);
    

    useEffect(() => {
        if(!isMount) {
            //consoleToLog("Followup page on on company or dates change- in useeffect");
            pageReset();
        }
    }, [props.selectedActivityReportMembersFilter]);

    useEffect(() => {
        if(!isMount) {
            //consoleToLog("Followup page on on company or dates change- in useeffect");
            pageReset();
        }
    }, [props.startDateFilter, props.endDateFilter]);

    useEffect(() => {
        if(myDivToFocus && myDivToFocus.current && canScroll) {
            onScrollToNewPage();
            }
    }, [myDivToFocus]);

    const onScrollToNewPage = () => {
        setCanScroll(false);
        myDivToFocus.current.scrollIntoView({
            block: 'nearest'
        })
    }

    const pageReset = () => {
        setPage(1);
        setNextPage(false);
        if(page === 1) {
            getActivityReport();
        }
    }

    //consoleToLog('Scroll position', location.state?.scrollTop);

    const onScroll = (e) => {
        const position = e.target.scrollTop;
        setScrollTop(position);
    };

    const onRefresh = () =>{
        setSpin(true);
        console.log("Print nextPage: "+nextPage+" page: "+page);
        if(nextPage === false && page === 1){
            getActivityReport();
        }
        else{
            setPage(1);
        }
    }

    const handleChange = (event) => {
        console.log("hi", selected)
        if (selected === "selected"){
            setSelected("all");
        }else{
            setSelected("selected");
        }
        setPage(1);
        setNextPage(false);
      };


    const getActivityReport = () => {
        const organization_id = props.selectedOrganization.organization.id;
        let user_ids = undefined;
        consoleToLog("ddd", props.selectedActivityReportMembersFilter);
        if(props.selectedActivityReportMembersFilter && props.selectedActivityReportMembersFilter.length > 0) {
            user_ids = props.selectedActivityReportMembersFilter.map((member) => member.user.id).join();
            consoleToLog("ddd1", user_ids);
        }
        const start_date = props.startDateFilter;
        const end_date = props.endDateFilter;
        if(!isMoreLoading) setLoadingActivity(true);

        getActivityReportApi(organization_id, page, user_ids, start_date, end_date,selected)
            .then((response) => {
                const res = response.data;
                setLoadingActivity(false);
                consoleToLog("Response getActivityReportApi: ", res);
                if(isMoreLoading) {
                    const activity = activityList.concat(res.activity_feed)
                    setActivityList(activity);
                    setNextPage(res.next_page);
                    setPage(page + 1);
                    setIsMoreLoading(!isMoreLoading);
                } else {
                    setNextPage(res.next_page)
                    if (res.next_page) setPage(page + 1);
                    setActivityList(res.activity_feed);
                }
                setSpin(false);
            })
            .catch((e) => {
                consoleToLog("getActivityReportApi error", e);
                setLoadingActivity(false);
                if (e && e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant:'error'
                    });
                    return false;
                }
            });
    }

    //Last Month
    var prevMonthFirstDay = new moment().subtract(1, 'months').date(1)

    //for next month last day
    var thisMonthLastDay = new moment().add(1, 'months').date(0);

    //for last day of previous month
    var lastdayLastMonth = moment(lastdayLastMonth).subtract(1,'months').endOf('month').format('YYYY-MM-DD');

    var currentFinancialYearStart;
    var currentFinancialYearEnd;

    currentFinancialYearStart = (moment().month() < 3) ? moment().subtract(1, 'year').month('April').startOf('month').format('YYYY-MM-DD')
    : moment().month('April').startOf('month').format('YYYY-MM-DD');

    currentFinancialYearEnd =  (moment().month() < 3) ? moment().month('March').endOf('month').format('YYYY-MM-DD')
    : moment().add(1, 'year').month('March').endOf('month').format('YYYY-MM-DD');

    let lastFinancialYearStart = moment(currentFinancialYearStart).subtract(1, 'year').format('YYYY-MM-DD');
    let lastFinancialYearEnd = moment(currentFinancialYearEnd).subtract(1, 'year').format('YYYY-MM-DD');

    const openDateRangePicker = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const open = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onUpdateClearFilterValue = () => {
        setClearFilter(false);
    }

    const toggleDrawer = () => {
        setOpenDrawer(open => !open)
        setAnchorEl(null);
    }

    const setDrawerOpen = () => {
        setOpenDrawer(false);
        setOpenFilters(false);
    }
    // const onSelectedMembers = (members) => {
    //     setSelectedMemberFilter(members);
    // }
    
    const showActivityList = () => {
        return (
        <Grid item className={classes.mainBg}>
            {
            activityList && activityList.length > 0 ?
            activityList.map((activityObj, index) => {
                const time = moment(activityObj.created_at).fromNow();
                const innerHtmlContent = getReadableActivityInHTML(activityObj.activity_text);
                const image = activityObj.user.image ? activityObj.user.image : undefined;
                const fullName = activityObj.user.firstname + ' ' + activityObj.user.lastname;
                const notesType = activityObj.activity_type === "notes_on_checklist";
                const commentOnTask = activityObj.activity_type === "comment_on_task";
                const templateObj = activityObj.template;
                       return<Grid item key={activityObj.id} md={12}
                            ref={myDivToFocus}
                            id={activityObj.id.toString()}>
                         <Link
                            style={{textDecoration: 'none', display:'inline-block', fontSize:'14px', marginBottom:'32px'}}
                            to={(activityObj.activity_type === 'move_checklist' && activityObj.action_type === 'checklist_grouping') ? {
                                pathname:activityObj.group_slug_code ? `/group/${activityObj.group_slug_code}` : `/dashboard`,
                                activityObj
                                } : {
                                    pathname: `/checklist/${activityObj.checklist.slug_code}`,
                                    activityObj,
                                    activityList,
                                    isMoreLoading,
                                    nextPage,
                                    page,
                                    scrollTop
                                } 
                            }
                            //onClick={() => setFromChecklistItemPage(true)}
                            onClick={(e) => {
                                    trackGAEvent(props.selectedOrganization.organization.name, 'Clicked Activity', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
                                    props.setSelectedDrawerState(SelectedDrawerState.NOT_SELECTED);
                                    props.setSelectedTemplate(templateObj);
                                    setActivityId(activityObj.id);
                                }
                            }
                        > 
                        <Grid item container direction="row">
                            <Grid item style={{ marginTop: "-3px"}}>
                                <Fab className={classes.activtyIconContainer}>
                                    { <i className={`${displayIcons(activityObj)} ${classes.activtycommentIcon}`}></i> }
                                    {/* <PublishIcon className={classes.activtycommentIcon} />  */}
                                </Fab>
                                <Divider orientation="vertical" className={classes.activtyhorizontalDivider} />
                            </Grid>

                            <Grid item md={10}  style={{marginLeft:'16px', color:'#212121'}}>
                                <Grid item container>
                                    <Grid item style={{display:'flex', width:'100%', position:'relative'}}>
                                        <Typography variant='body1'
                                            dangerouslySetInnerHTML={{ __html: innerHtmlContent }}
                                        ></Typography>
                                    </Grid>

                                <Grid item container alignItems="center" style={{marginTop:'5px'}}>
                                    <Grid item> 
                                        <Typography variant="body1" style={{color:'#0071FF'}}>
                                            {activityObj.template.name}
                                        </Typography>
                                    </Grid>

                                    <Grid item style={{padding:'0px 8px'}}>
                                        <Typography variant="body2"> - </Typography>
                                    </Grid>

                                    <Grid item style={{paddingTop:'1px'}}>
                                        <Typography variant="subtitle1">
                                       { `${activityObj.checklist.title} ${activityObj.checklist.subtitle ? '(' + activityObj.checklist.subtitle + ')' : ''}`}
                                        </Typography>
                                    </Grid>

                                    <Grid item>
                                        <FiberManualRecordIcon fontSize="small"  className={classes.bulletIcons} />
                                    </Grid>

                                    <Grid item>
                                        <div className={classes.activityTime}
                                        > {time} </div>
                                    </Grid>
                                </Grid>

                                </Grid>
                            </Grid>
                        </Grid>
                         </Link> 
                    </Grid>
            }) : <ReportsNotFound />
        }
        </Grid>

        )
    }

    const loadMoreLogic = () => {
        return (
            <Grid item container>
                <Grid item lg={12}>
                {nextPage && !isMoreLoading &&
                    <div style={{textAlign: "center", margin:'16px 0px 32px 0px'}}>
                        <Button type="button"
                            color="secondary"
                            variant="contained"
                            onClick={() => {
                                setIsMoreLoading(!isMoreLoading);
                            }}>Load More
                        </Button>
                    </div>}

                { isMoreLoading &&
                    <CircularProgress size={35} style={{position:'relative', left:'50%', right:'50%', margin:'16px 0px'}}/>
                }
                </Grid>
            </Grid>
        )
    }

    const ReportsNotFound = () => {
        return (
          <div>
            {/* Not Found*/}
            <Grid container direction="row">
              <Grid
                item
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
                sm={12}
              >
                <img
                  alt="No Activity Reports Found"
                  src="/images/no_attachment_found.svg"
                  className={classes.notAttachmentsImage}
                />
              </Grid>
              <Grid
                item
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <Typography className={classes.notAttachmentsText}>
                  No Activity Reports Found, Please Change the filter
                </Typography>
              </Grid>
              <Grid
                item
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                <Button
                  variant="contained"
                  color="primary"
                  //endIcon={<PublishIcon />}
                  size="large"
                  className={classes.notAttachmentsButton}
                  onClick={()=>{
                    toggleDrawer();
                    setOpenFilters(!openFilters);
                  }}
                >
                  Change Filters
                </Button>
              </Grid>
            </Grid>
            {/* Not Found*/}
          </div>
        );
      };

    return (
        <div className={classes.mainContent} onScroll={onScroll} 
            style={{height: props.height}}>
            <Container className={classes.container} maxWidth="xl">
                <div style={{display: "flex"}}>
                    <Typography style={{fontSize:'20px', fontWeight:'600', marginRight: "6px"}} color="primary">
                        Activity  
                    </Typography>
                    <Grid item style={{padding: "0px"}}> 
                        <Tooltip title="Reload" arrow>
                            <Button aria-label="delete"
                                className={spin ? `spinner ${classes.icons2}` : classes.icons2} 
                                disableRipple={true} 
                                onClick={onRefresh} >
                            {<i className="flaticon-reload" />}
                            </Button>
                        </Tooltip>
                    </Grid>
                    <Tooltip title="Filters" arrow>
                        <Button aria-label="delete"
                            className={classes.icons}
                            onClick={() => {
                                toggleDrawer();
                                setOpenFilters(!openFilters);
                            }}
                            style={{marginLeft:"-3px"}}
                            >
                            <i className={((props.startDateFilter !== currentFinancialYearStart && 
                                            props.endDateFilter !== currentFinancialYearEnd)
                                || props.selectedActivityReportMembersFilter.length > 0 ) ? `flaticon-filter active` : `flaticon-filter`}></i>
                            {  ((props.startDateFilter !== currentFinancialYearStart && props.endDateFilter !== currentFinancialYearEnd)
                                || props.selectedActivityReportMembersFilter.length > 0 ) ?
                               <div className='filter__dot'></div> : <div></div>
                            }
                        </Button>
                    </Tooltip>
                    <Grid item style={{ marginTop:"-6px",marginLeft:"4px"}}> 
                        <FormControlLabel
                         style={{marginTop:"2px"}}
                        control={
                          <Checkbox
                            onChange={handleChange}
                            name="checkedB"
                            color="primary"
                            style={{marginRight:"-6px"}}
                          />
                        }
                        label="Show All"
                      />
                    </Grid> 
                </div>
                {loadingActivity ? <CircularProgress size={35} className={classes.loading}/>
                    :
                    <div>
                        {showActivityList()}
                        {loadMoreLogic()}
                    </div>
                }

            </Container>

            <RightToLeftSideDrawer openDrawer={openDrawer}
                closeDrawer={setDrawerOpen}
                title="Activity Report Filters"
                createChecklist={false}>
                    <div>
                        <Grid item style={{marginLeft: "12px"}}>
                            <Grid item container>
                                <Grid item >
                                    <Typography
                                        variant="body1"
                                        style={{lineHeight:'42px', color: '#888888'}}
                                        >
                                            Filter By Date
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item container style={{marginTop:'-6px'}}>
                                <Grid item>
                                    <Button variant='outlined'
                                        color='primary'
                                        style={{fontWeight:"300 !important"}}
                                        onClick={openDateRangePicker}>
                                        {`${moment(props.startDateFilter).format('DD-MM-YYYY')} -
                                        ${moment(props.endDateFilter).format('DD-MM-YYYY')}` }
                                    </Button>
                                    <Popover
                                        id='daterange-popover'
                                        open={open}
                                        anchorEl={anchorEl}
                                        onClose={handleClose}
                                        anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "center"
                                        }}
                                        transformOrigin={{
                                        vertical: "top",
                                        horizontal: "center"
                                        }}
                                    >
                                        <DateRangePicker
                                            open={true}
                                            toggle={handleClose}
                                            onChange={(range) => {
                                                //consoleToLog("range", range, dateRange);
                                                setDateRange(range);
                                                props.setActivityReportStartDateFilter(moment(range.startDate).format('YYYY-MM-DD'));
                                                props.setActivityReportEndDateFilter(moment(range.endDate).format('YYYY-MM-DD'))
                                            }}
                                            style={{
                                            width:'50%'
                                            }}
                                            initialDateRange={{
                                                startDate:  props.startDateFilter,
                                                endDate: props.endDateFilter
                                            }}
                                            definedRanges={[
                                            {
                                                label: `${moment(lastFinancialYearStart, 'YYYY-MM-DD').format('MMM-YYYY')}-${moment(lastFinancialYearEnd, 'YYYY-MM-DD').format('MMM-YYYY')}`,
                                                startDate: lastFinancialYearStart,
                                                endDate: lastFinancialYearEnd
                                            },
                                            {
                                                label: `${moment(currentFinancialYearStart, 'YYYY-MM-DD').format('MMM-YYYY')}-${moment(currentFinancialYearEnd, 'YYYY-MM-DD').format('MMM-YYYY')}`,
                                                startDate: currentFinancialYearStart,
                                                endDate: currentFinancialYearEnd
                                            },
                                            {
                                                label: 'Last Month',
                                                startDate: prevMonthFirstDay,
                                                endDate: lastdayLastMonth
                                            },
                                            {
                                                label: 'This Month',
                                                startDate: new Date(),
                                                endDate: thisMonthLastDay
                                            }
                                            ]}
                                        />
                                    </Popover>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item style={{marginLeft: "12px"}}>
                            <Grid item container>
                                <Grid item >
                                    <Typography
                                        variant="body1"
                                        style={{lineHeight:'42px', color: '#888888'}}
                                    >
                                    Select Members
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item container style={{width: "500px", marginTop: "-15px"}}>
                                <Grid item lg={6}>
                                    <ChecklistMembersFilter isFromActivityReport = {true}
                                        clearFilter={clearFilter}
                                        onUpdateClearFilterValue={onUpdateClearFilterValue}
                                        //onSelectedMembers={onSelectedMembers}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item style={{marginLeft: "12px", marginTop: "18px"}}>
                            <Button variant="contained"
                                color="primary"
                                style={{fontWeight:"300 !important"}}
                                className="modal__button"
                                onClick={()=>{
                                    setClearFilter(true);
                                    props.clearActivityReportFilter();
                                }}>
                                Clear Filters
                            </Button>
                        </Grid>
                    </div>
            </RightToLeftSideDrawer>
        </div>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    startDateFilter: state.activityReports.startDateFilter,
    endDateFilter: state.activityReports.endDateFilter,
    selectedActivityReportMembersFilter: state.activityReports.selectedActivityReportMembersFilter
});

const mapDispatchToProps = (dispatch) => ({
    setSelectedTemplate: (template) => dispatch(setSelectedTemplate(template)),
    setSelectedDrawerState: (drawerState) => dispatch(setSelectedDrawerState(drawerState)),
    setActivityReportStartDateFilter: (start_date) => dispatch(setActivityReportStartDateFilter(start_date)),
    setActivityReportEndDateFilter: (end_date) => dispatch(setActivityReportEndDateFilter(end_date)),
    clearActivityReportFilter: () => dispatch(clearActivityReportFilter()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ActivityReports);