import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles'; 
import { Grid, Container, Typography,  Button, Popover, Tooltip ,AppBar ,Tabs, Tab, Box ,CircularProgress} from '@material-ui/core';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import { addOrgMember } from '../actions/selectedOrganization';
import { useIsMount } from './useIsMount';
import { consoleToLog, trackGAEvent } from '../util/AppUtil';
import Chart from 'react-apexcharts';
//import {PieChartData} from "../data/ChartsData.json";
import RightToLeftSideDrawer from './RightToLeftSideDrawer';
import ChecklistReportsDrawer from './ChecklistReportsDrawer';
import ClientWorkReport from './ClientWorkReport';
import SwipeableViews from 'react-swipeable-views';
import ReportByTemplate from './ReportByTemplate';

const useStyles = makeStyles((theme) => ({
    mainContent : {
        width: (showDrawer) => {
            return showDrawer ? "83%" : "100%"; 
        },
        backgroundColor: theme.palette.primary.light, 
        transition: ".5s", 
        flexGrow: 1,
        //overflow:'hidden',
        overflow:'auto',
        overflowX:'hidden'
        },
    
    container: {
        marginTop: "8px"
    },
    graphMain: {
        padding: "1rem !important",
        fontSize: "11px !important",
        marginTop: "1rem !important",
        boxShadow: "0 .5rem 1rem rgba(0,0,0,.15) !important",
        width: "32rem !important",
        height: "21rem !important",
        marginLeft: "40px !important",
        marginBottom: "50px"
    },

    icons : {
        color: "black",
        fontSize: "18px",
        minWidth:'30px',
        marginLeft: "8px",
        marginTop:'-3px'
    } ,
    notAttachmentsImage: {
        height: "13rem",
        width: "15rem",
        marginTop: "3rem",
      },
      notAttachmentsText: {
        fontSize: "20px",
        fontWeight: "600",
      },
      notAttachmentsButton: {
        fontSize: "15px",
        width: "19rem",
        marginTop: "20px",
      } ,
      tabsAppbar: {
        boxShadow: "none",
        backgroundColor: "white",
        borderBottom: "1px solid #c5b9b9",
      }     
    })
    
)

const CompletionReports = (props) => {
    const {showDrawer} = props;
    const classes = useStyles(showDrawer);
    const { enqueueSnackbar } = useSnackbar();
    const [anchorEl, setAnchorEl] = useState(false);
  
    const [startDateFilter, setStartDateFilter] = useState("");
    const [endDateFilter, setEndDateFilter] = useState("");
    const [clearFilter, setClearFilter] = useState(false);
    const [selectedTemplates, setSelectedTemplates] = useState([]);
 
    const [openDrawer, setOpenDrawer] = useState(false);
    const [openFilters, setOpenFilters] = useState(false);
    const [completionReportsLoading,setCompletionReportsLoading]= useState(false);
    const [checkList, setCheckList] = useState([]);
    const [checklistLoading, setChecklistLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [isMoreLoading, setIsMoreLoading] = useState(false);
    const [tempObj, setTempObj] = useState(undefined);
    const [nextPage, setNextPage] = useState(false);
    const [statusValue, setStatusValue] = useState(undefined);
    const [tempObj1, setTempObj1] = useState(undefined);
    const [value, setValue] = useState(0);
    const [templateTab, setTemplateTab] = useState(false);
    const [clientTab, setClientTab] = useState(false);
    const [filterCountForTemp, setFilterCountForTemp]=useState(0);
    const [filterCountForClient, setFilterCountForClient]=useState(0);

    const isMount = useIsMount(); 

    const checklistDetails = window.location.toString().includes("checklist");

    useEffect(() => {
        document.title = `Completion Reports - ${props.fullName}`
    }, []);

    useEffect(() => {
        if(!isMount) {
            if(!checklistDetails) {
                document.title = `Completion Reports - ${props.fullName}`
            }
        }
    }, [checklistDetails]);

    const open = Boolean(anchorEl);

    // const TabPanel = (props) => {
    //     const { children, value, index, ...rest } = props;
    //     return (
    //       <div
    //         role="tabpanel"
    //         hidden={value !== index}
    //         id={`full-width-tabpanel-${index}`}
    //         aria-labelledby={`full-width-tab-${index}`}
    //         {...rest}
    //       >
    //         {value === index && (
    //           <Box p={2}>
    //             <Typography>{children}</Typography>
    //           </Box>
    //         )}
    //       </div>
    //     );
    //   };
    
      const a11yProps = (index) => {
        return {
          id: `full-width-tab-${index}`,
          "aria-controls": `full-width-tabpanel-${index}`,
        };
      };
    
    const handleChange = (event, newValue) => {
        setValue(newValue);
        setFilterCountForTemp(0);
        setFilterCountForClient(0);
    };
    
    // const handleChangeIndex = (index) => {
    //     setValue(index);
    // };

    return (
        <div className={classes.mainContent}
            style={{height: props.height}}>
            <Container className={classes.container} maxWidth="xl">
                <div style={{display: "flex"}}>
                    <Typography style={{fontSize:'20px', fontWeight:'600', marginBottom: "10px"}} color="primary">
                        Completion Report
                    </Typography>
                    <Tooltip title="Filters" arrow>
                        <Button aria-label="delete"  
                            className={classes.icons}
                                onClick={() => {
                                    if(value === 0) 
                                        setTemplateTab(true);
                                    if(value === 1) 
                                        setClientTab(true);
                                }}>
                            <i className={(filterCountForTemp || filterCountForClient) ? `flaticon-filter active` : `flaticon-filter`}></i>
                            {  filterCountForTemp ?
                                <div className='filter__dot'></div> : <div></div>
                            } 
                            {  filterCountForClient ?
                                <div className='filter__dot'></div> : <div></div>
                            } 

                        </Button>
                    </Tooltip>
                </div> 
                <div style={{padding: "12px 0px 24px 0px", fontWeight: "bold", background: "white", margin: "10px auto 50px auto"}}>
                        <AppBar
                            position="static"
                            color="default"
                            className={classes.tabsAppbar}
                        >
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                indicatorColor="secondary"
                                textColor="secondary"
                                style={{ paddingLeft: "15px" }}
                                aria-label="full width tabs example"
                                >
                                <Tab
                                    label="By Template"
                                    className={classes.tabsMain}
                                    {...a11yProps(0)}
                                />
                                <Tab
                                    label="By Client"
                                    className={classes.tabsMain}
                                    {...a11yProps(1)}
                                />
                            </Tabs>
                       </AppBar>
                  
            {value === 0 && <ReportByTemplate templateTab={templateTab} setTemplateTab={setTemplateTab}
             filterCountForTemp={filterCountForTemp} setFilterCountForTemp={setFilterCountForTemp}/>}
            {value === 1 && <ClientWorkReport clientTab={clientTab} setClientTab={setClientTab}
             filterCountForClient={filterCountForClient} setFilterCountForClient={setFilterCountForClient}/>}
            {/* <SwipeableViews index={value} onChangeIndex={handleChangeIndex}>
                <TabPanel value={value} index={0}>
                    <ReportByTemplate templateTab={templateTab} setTemplateTab={setTemplateTab}/>
                </TabPanel>
                <TabPanel value={value} index={1}>
                        <ClientWorkReport clientTab={clientTab} setClientTab={setClientTab}/> 
                </TabPanel>
            </SwipeableViews> */}
            </div>
         </Container>
        </div>
    )
}            

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    members: state.organizationInfo.members
});

const mapDispatchToProps = (dispatch) => ({
    addOrgMember: (member) => dispatch(addOrgMember(member))
})

export default connect(mapStateToProps, mapDispatchToProps)(CompletionReports);