import React, {useState, createRef} from 'react';
import {connect} from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {Grid, Typography, Button, Drawer, MenuItem, CircularProgress} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {updateTaskStatusApi} from '../../../services/ChecklistService';
import { consoleToLog, trackGAEvent } from '../../../util/AppUtil';
import {useSnackbar} from 'notistack';
import RichTextWidget from "../../../components/widgets/RichTextWidget";
import DownloaderWidget from "../../../components/widgets/DownloaderWidget";
import UploaderWidget from "../../../components/widgets/UploaderWidget";
import MobileTaskActivities from './MobileTaskActivities';

const useStyles = makeStyles((theme) => ({
    taskArrowBackIcon: {
        marginRight:'3px', 
        fontSize:'14px',
    },
    taskItemTitle: {
        fontSize:'16px',
        fontWeight:'500',
        marginBottom:'8px'
    },
    taskItemStatus: {
        padding:'4px 8px',
        display:'flex',
        alignItems:'center'
    },
    drawerPaper: {
        backgroundColor: theme.palette.primary.light,
        width: '100%', 
        height: '250px',
        flexGrow:1,
        overflowY:'auto',
        overflowX:'hidden',
    },
    Muiselected: {
        backgroundColor: 'rgba(0, 113, 255, 0.13) !important'
    },
    checklistDetailContent: {
      marginTop:'16px'
    }
}))

const MobileTaskDetails = (props) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();

    const [statusNameList, setStatusNameList] = useState([
        "Pending",
        "In Process",
        "Awaiting Reply",
        "Completed",
        "Not Applicable",
      ]);
      const [statusValueList, setStatusValueList] = useState([
        "pending",
        "in_process",
        "awaiting_reply",
        "completed",
        "not_applicable",
      ]);
      const [openDrawer, setDrawerOpen] = useState(false);
      const myDivToFocus = createRef();

      const onScrollToCommentSection = () => {
        if(myDivToFocus.current){
          myDivToFocus.current.scrollIntoView({
              behavior: "smooth"
          });
        }
      }

      const handleDrawerOpen = () => {
        setDrawerOpen(true);
      }

      const handleDrawerClose = () => {
        setDrawerOpen(false);
      }

    const onAllTaskClick = () => {
        props.setSelectedTask(undefined);
        props.setShowTaskDetails(false);
    }

    const onTaskStatusChanged = (value, selectedTask) => {
        var statusValue = value;
        console.log('status value', statusValue);
        if (statusValue === -1 || statusValue === "-1") {
          return;
        }
        const statusIndex = statusValueList.indexOf(statusValue);
        const statusName = statusNameList[statusIndex];
        const checklist_task_id = selectedTask.id;
        // task.status = statusName;
        props.setSelectedTask({...props.selectedTask, status:statusName});
    
        updateTaskStatusApi(checklist_task_id, statusValue)
          .then((response) => {
            const res = response.data;
            consoleToLog("updateTaskStatusApi res", res);
            //const completed_task = res.activity_feed.checklist.completed_task;
    
            // when all task are completed then alert is shown and user is asked to complete checklist or continue with checklist updation
            trackGAEvent(props.selectedOrganization.organization.name,"Change Status", `${props.auth.user.firstname} ${props.auth.user.lastname} changed status to ${statusValue}`);
    
            if (res.checklist_complete === true) {
              //completeChecklistApiCall(res);
            } else {
              enqueueSnackbar("Checklist Task Status Updated Successfully", {
                variant: "success",
              });
            }
    
            //send to parent
            props.onTaskStatusChanged(statusName,  res.activity_feed,  props.selectedTask);
          })
          .catch((e) => {
            consoleToLog("updateTaskStatusApi err", e);
            if (e.response && e.response.data && e.response.data.message) {
              enqueueSnackbar(e.response.data.message, {variant: "error"});
              return false;
            }
          });
      };

      const onWidgetUpdated = (updatedWidget) => {
        const updatedWidgetArr = props.selectedTask.checklist_task_widget.map((widget) => {
            if (widget.id === updatedWidget.id) {
              return updatedWidget;
            } else {
              return widget;
            }
          }
        );
        const updatedSelectedTask = {
          ...props.selectedTask,
          widgetResponse: updatedWidgetArr,
        };
      props.onWidgetUpdated(updatedSelectedTask);
      };

      const onCommentsEdited = (activity_feed) => {
        props.onCommentsEdited(activity_feed, props.selectedTask);
      };
    
      const onCommentsUpdated = (activity_feed, comment_count) => {
        props.onCommentsUpdated(activity_feed, comment_count, props.selectedTask);
      };
    
      const onCommentsdeleted= (activityFeeds) => {
        props.onCommentsdeleted(activityFeeds);
      };

    return (
        <Grid item xs={12}>
                <Grid item>
                    <Typography variant='body1' onClick={onAllTaskClick} style={{display:'flex', alignItems:'center'}}> 
                        <ArrowBackIosIcon fontSize='small' className={classes.taskArrowBackIcon}/> 
                        All Task
                    </Typography>
                </Grid>
                <Grid item container alignItems='center' justifyContent='space-between' style={{marginTop:'8px'}}>
                    <Grid item>
                        <Typography variant='body1' className={classes.taskItemTitle}> 
                           {props.selectedTask?.title}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Button variant='outlined' 
                            onClick={handleDrawerOpen} 
                            className={classes.taskItemStatus}> 
                            {props.selectedTask.status}
                            <i className='flaticon-down-arrow' style={{marginLeft:'6px', marginTop:'4px'}}></i>
                        </Button>
                        <Drawer open={openDrawer}
                            onClose={handleDrawerClose}
                            anchor="bottom"
                            variant="temporary"
                            classes={{
                                paper: classes.drawerPaper
                            }}
                        >   
                            {statusNameList.map((status, index) => {
                                return (
                                    <MenuItem
                                        classes={{
                                            selected:classes.Muiselected
                                        }}
                                        value={statusValueList[index]}
                                        selected={status ===  props.selectedTask.status}
                                        key={status}
                                        onClick={(e) => onTaskStatusChanged(statusValueList[index], props.selectedTask)}
                                    >
                                    {status} 
                                    </MenuItem>
                                );
                                })}
                        </Drawer>
                    </Grid>
                </Grid>
                {props.widgetsLoading  ? (
                    <Grid item container alignItems="center" justifyContent="center" style={{marginTop:'16px'}}>
                        <CircularProgress color="secondary" size={25}  />
                    </Grid>
                ) : (
                  <Grid item direction="row" className={classes.checklistDetailContent}>
                    {props.selectedTask && props.selectedTask.checklist_task_widget ? (
                      props.selectedTask.checklist_task_widget.map((widget) => {
                        return (
                          <div key={widget.id} style={{marginBottom:'24px'}}>
                            {widget.type === "rich_text" ? (
                              <RichTextWidget text={widget.json_data.desc} />
                            ) : widget.type === "upload" ? (
                              <UploaderWidget
                                widget={widget}
                                onWidgetUpdated={(updatedWidget) => {
                                  onWidgetUpdated(updatedWidget);
                                }}
                                //onWidgetAttachmentAdded = {onWidgetAttachmentAdded}
                                selectedTask={props.selectedTask}
                                fromMobileComponent={true}
                              />
                            ) : widget.type === "download" ? (
                              <DownloaderWidget widget={widget} 
                                fromMobileComponent={true}
                                selectedTask={props.selectedTask}
                                />
                            ) : (
                              <div />
                            )}
                          </div>
                        );
                      })
                    ) : (
                      <div></div>
                    )}
                    <div ref={myDivToFocus}>
                      <MobileTaskActivities 
                        selectedTask={props.selectedTask}
                        onCommentsUpdated={onCommentsUpdated}
                        onCommentsEdited={onCommentsEdited}
                        onCommentsdeleted={onCommentsdeleted}
                        onScroll={onScrollToCommentSection}
                      />
                    </div>  
                  </Grid>
                )}
        </Grid>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
  });

export default connect(mapStateToProps)(MobileTaskDetails);