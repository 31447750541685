import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import { Button } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import ActivityItem from "./ActivityItem";
import { consoleToLog } from "../../util/AppUtil";
import {getChecklistActivityApi} from "../../services/ChecklistService";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Link } from "react-router-dom";
import { useIsMount } from "../useIsMount";

const useStyles = makeStyles((theme) => ({
  noActivityImage: {
    height: "13rem",
    width: "15rem",
    marginTop: "3rem",
  },
  noActivityText: {
    fontSize: "20px",
    fontWeight: "600",
  },
  noActivityButton: {
    fontSize: "15px",
    width: "19rem",
    marginTop: "20px",
  },
  activityContainer: {
    marginTop: "1rem",
  },
  refreshBtn: {
    // marginTop: "-2rem",
  },
}));

const ActivityList = (props) => {
  const classes = useStyles();
  useEffect(() => {
    // consoleToLog("ActivityList useEffect");
  }, []);

  return (
    <div className={classes.activityContainer}>
      <Grid container direction="row">
        <Grid item container direction="column" sm={11}>
          {props.activity.map((singleActivity) => {
            return (
              <Link
                style={{ textDecoration: "none" }}
                className="activityItemParent"
                to = {(singleActivity.activity_type === 'move_checklist' && singleActivity.action_type === 'checklist_grouping') 
                ? {
                    pathname:singleActivity.group_slug_code ? `/group/${singleActivity.group_slug_code}` : `/dashboard`,
                    singleActivity
                  } 
                : {
                    pathname: `/checklist/${singleActivity.checklist.slug_code}`,
                    singleActivity
                  } 
                }
                title="Open Checklist"
                onClick={(e)=>{props.onChecklistActivityClick(e, singleActivity)}}
              >
                <ActivityItem
                  key={singleActivity.id}
                  singleActivity={singleActivity}
                />
              </Link>
            );
          })}
        </Grid>
        <Grid
          item
          direction="column"
          sm={1}
          justifyContent="center"
          alignItems="flex-end"
        >
          <Button
            className={classes.refreshBtn}
            variant="contained"
            color="primary"
            endIcon={<RefreshIcon />}
            onClick={props.onRefreshClick}
          >
            Refresh
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

const ActivityNotFound = (props) => {
  const classes = useStyles();
  return (
    <div>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <Grid
          item
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          sm={6}
        >
          <img
            alt="No Attachments Found"
            src="/images/no-activity-found.svg"
            className={classes.noActivityImage}
          />
        </Grid>
        <Grid
          item
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Typography className={classes.noActivityText}>
            Not Activities Found
          </Typography>
        </Grid>
        <Grid
          item
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Button
            variant="contained"
            color="primary"
            endIcon={<RefreshIcon />}
            size="large"
            className={classes.noActivityButton}
            onClick = {props.onRefreshClick}
          >
            Refresh
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

const ActivityPage = (props) => {
  const [activity, setActivity] = useState();
  const [isAPICallInProgress, setIsAPICallInProgress] = useState(false);
  // const [next_page,setNext_page] = useState(false);
  const isMount = useIsMount();

  useEffect(() => {
    getActivity();
  }, []);

  useEffect(() => {
    if(!isMount){ 
      getActivity();
    }
  }, [props.activity]);

  
  const onRefreshClick = () => {
    getActivity();
  };

  const onChecklistActivityClick = (e, activityObj) => {
    props.onChecklistActivityClick(activityObj);
   }

  const getActivity = () => {
    const organizationId = props.selectedOrganization.organization.id;
    const checklist_slug = props.checklistSlug;
    const page = 1; 
    setIsAPICallInProgress(true);

      getChecklistActivityApi(organizationId, page, checklist_slug)
      .then((response) => {
        consoleToLog("getChecklistActivityApi response", response.data);
        const res = response.data;
        setActivity(res.activity_feed);
        setIsAPICallInProgress(false);
      })
      .catch((e) => {
        consoleToLog("Activity api error(checklist activity)", e);
        setIsAPICallInProgress(false);
      });
  };

  return (
    <>
      <div  style={{height:"79vh"}} className="detailsTaskRight">
        {isAPICallInProgress ? (
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            style={{ height: "30vh" }}
          >
            <Grid
              item
              sm={12}
              container
              alignItems="center"
              justifyContent="center"
            >
              <CircularProgress color="secondary" sm={12} />
            </Grid>
          </Grid>
        ) : (
          <>
            {activity && activity.length ? (
              <ActivityList activity={activity}
                onRefreshClick={onRefreshClick} 
                onChecklistActivityClick={onChecklistActivityClick} />
            ) : (
              <ActivityNotFound onRefreshClick={onRefreshClick} />
            )}
          </>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  selectedOrganization: state.organizationInfo.selectedOrganization,
});
 
export default connect(mapStateToProps, null)(ActivityPage);
