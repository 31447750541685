import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import { Grid, Typography, Button} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {connect} from 'react-redux';
import { history } from '../routers/MobileAppRouter';

const useStyles = makeStyles((theme) => ({
    folderStyles : {
        display: 'flex', 
        alignItems: 'center', 
        marginRight:'16px',
        width:'auto',
        '&:last-child' : {
            marginRight:'0px'
        }
    },
    foldericon : {
        fontSize: "18px",
        lineHeight: "18px",
        color: '#202020',
        marginTop:'-2px'
    },
    folderName : {
        marginLeft: '4px',
    }, 
    flexContainer: {
        maxWidth:'100%',
        whiteSpace:'nowrap',
        display:'flex',
        alignItems:'center',
        flex:'none',
        overflowX:'scroll'
    }
    })
);

const MobileChecklistGroupComponent = (props) => {
    const classes = useStyles();
    let folderList = props.checklistGroup;

    const onBackIconClick = () => {
        props.pageReset();
        history.goBack();
    }

    return (
        <div id="checklist_group" style={{padding:'0px 16px'}}>
            <Grid item container direction="row" spacing={1}>
               {props.slug &&
                <Grid item>
                    <Button variant="outlined" className={classes.foldericon} onClick={onBackIconClick}>
                        <ArrowBackIcon style={{padding:'1px 0'}}/> 
                    </Button>
                </Grid>
                }
                {!props.loading &&
                <div className={classes.flexContainer}>
                    {folderList && folderList.length > 0 && folderList.map((group, index) => {  
                        return <Grid item  className={classes.folderStyles}
                                    key={group.slug_code}
                                    id={index+"_"+group.group_name}
                                    onClick={() => {
                                        //trackGAEvent(props.selectedOrganization.organization.name, 'Checklist Folder Clicked', `${props.auth.user.firstname} ${props.auth.user.lastname} clicked folder`);
                                        props.onFolderClick(group.slug_code);
                                    }}
                                    >
                                        <i className={`flaticon-folder-1 ${classes.foldericon}`} style={{color: "#202020", marginRight:'4px'}}></i>
                                        <Typography variant="subtitle1" 
                                            className={classes.folderName}
                                            >
                                            {group.group_name}
                                        </Typography>
                                </Grid>
                        })
                    }    
                </div>}  
            </Grid>
        </div>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    selectedTemplate: state.organizationInfo.selectedTemplate
});

export default connect(mapStateToProps)(MobileChecklistGroupComponent);