import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Container, Button, Typography, Tooltip, CircularProgress, Tabs, Tab, Paper} from '@material-ui/core';
import {connect} from 'react-redux';
import { history } from '../routers/AppRouter';
import { useSnackbar } from 'notistack';
import { consoleToLog, trackGAEvent } from '../util/AppUtil';
import {templatePublishUnpublishApi, archiveTemplateApi} from '../services/authService';
import {fetchTemplateList} from '../actions/selectedOrganization';


const useStyles = makeStyles((theme) => ({
    settingsContainer: {
        width:'100%',
        background:'#fff',
        borderRadius:'4px',
        marginBottom:'32px'
    },
    MuiButtonlabel : {
        fontSize: "12px"
    },

    buttonPlusIcon: {
        marginLeft:'8px', 
        fontSize:'1.2rem', 
        alignItems:'center'
    },

    tempContainer : {
        marginTop: '30px',
        marginBottom:'30px'
    },

    tempList : {
        padding:'6px 0px',
        background: '#f7f7f7',
        marginBottom:'8px',
        borderRadius:'4px',
        '& .onlabel__hover' : {
            visibility: 'hidden'
        },
        '&:hover .onlabel__hover' : {
            visibility: 'visible'
        },
        '& .more_actionButton1' : {
            visibility:'hidden',
            width:'100px !important',
            background:'white',
            border:'1px solid #ccc',
            borderRadius:'5px',
            fontSize:'13px',
            padding:'1px 0px',
            margin:'6px 0px'
        },
        '& .more_actionButton2' : {
            visibility:'hidden',
            width:'135px',
            background:'white',
            border:'1px solid #ccc',
            borderRadius:'5px',
            fontSize:'13px',
            padding:'1px 0px',
            margin:'6px 0px'
        },
        '&:hover .more_actionButton1' : {
            visibility:'visible'
        },
        '&:hover .more_actionButton2' : {
            visibility:'visible'
        },
    },
    actionIconContainer : {
        marginRight:'auto',
        display:'flex',
        flexFlow:'row-reverse',
        flexGrow:1,
        marginLeft:'-8px',
        [theme.breakpoints.down(1500)] :{
            marginLeft:'-16px',
        },
        [theme.breakpoints.down(1450)] :{
            marginLeft:'-32px',
        },
        [theme.breakpoints.down(1400)] :{
            marginLeft:'-48px',
        },
        [theme.breakpoints.down(1320)] :{
            marginLeft:'-56px',
        },
        [theme.breakpoints.down(1290)] :{
            marginLeft:'-68px',
        },
    },

    actionIcons : {
        minWidth:'20px',
        justifyContent:'flex-end !important',
        verticalAlign: 'end !important'
    },

    flatIcons : {
        fontSize:'15px', 
        marginRight:'6px',
        display:'flex',
        alignItems:'center'
    },
    customColumn : {
        flexBasis:'71%',
        maxWidth:'71%'
    },
    loading: {
        position:'relative',
        left:'50%',
        right:'50%',
        top:'25%'
    },
    tab: {
        flexGrow: 1,
        borderRadius:0,
        borderBottom: "1px solid #c5b9b9",
    },
    settingsContainer: {
        width:'100%',
        background:'#fff',
        borderRadius:'4px',
        padding:'0px 24px',
        marginBottom:'32px'
    },
    addButton: {
        color: "white",
        backgroundColor: "#3d3d3d",
        marginLeft: "auto",
        height: "30px",
        marginTop: "9px",
        "&:hover": {
            //you want this to be the same as the backgroundColor above
            backgroundColor: "#3d3d3d"
        },
        marginRight: "8px"
    },
    buttonPlusIcon: {
        marginLeft:'8px', 
        fontSize:'1.2rem', 
        alignItems:'center'
    },
    publishUnpublishIconStyle : {
        width:"20px",
        color:"#222222",
        marginRight:'6px'
    }
    }
)); 

const OrganizationTemplates = (props) => {

    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();

    const callTemplateListApi = (organization_id) => {
        props.fetchTemplateList(organization_id, props.startDateFilter, props.endDateFilter, 
            props.companiesFilter, props.selectedMembersFilter, props.selectedTemplate)
    }

    const onDeleteTemplateClick = (e, template) => {
        e.stopPropagation();
        if(window.confirm('Are you sure you want to delete this template?')) {
            const organization_id = props.selectedOrganization.organization.id;
            const template_id = template.template_id;

            archiveTemplateApi(template_id)
                .then((response) => {
                    const res = response.data;
                    consoleToLog("Response archiveTemplateApi", res);

                    callTemplateListApi(organization_id);
                    trackGAEvent(props.selectedOrganization.organization.name, 'Template Deleted', `${props.auth.user.firstname} ${props.auth.user.lastname}`);

                })
                .catch((e) => {
                    consoleToLog('Error archiveTemplateApi', e.response);
                    if(e.response.data && e.response.data.message){
                        enqueueSnackbar(e.response.data.message, {variant:'error'});
                        return;
                    }
                })
        }

    }

    const onTemplatePublishUnPublishClick = (template, pubString) => {
        const organization_id = props.selectedOrganization.organization.id;
        const template_id = template.template_id;
        const templateString = pubString === 'publish' ? 'Published' : 'Unpublished'

        templatePublishUnpublishApi(organization_id, 'template' ,template_id, pubString)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response templatePublishUnpublishApi ', res);

                enqueueSnackbar(res.message, {
                    variant:'success'
                })

                trackGAEvent(props.selectedOrganization.organization.name, `Template ${templateString}`, `${props.auth.user.firstname} ${props.auth.user.lastname}`);
                callTemplateListApi(organization_id);
                
            })
            .catch((e) => {
                consoleToLog("Erro templatePublishUnpublishApi ", e);
                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant:'error'
                    })
                    return false;
                }
            });

    }

    const showOrgnizationTemplates = () => {
        return (
            <Grid item container className={classes.tempContainer}>
                {
                    props.templates && props.templates.length > 0 ? 
                    props.templates.map((template) => {
                        return  template.name !== 'All' &&
                                <Grid item container alignItems='center' className={classes.tempList}
                                    key={template.template_id}>
                                    <Grid item md={9} lg={9}
                                        className={classes.customColumn}
                                        style={{borderRadius:'4px',
                                                padding:'5px' }}
                                    >
                                        <Typography variant="body1" 
                                            style={{padding: '0 8px'}}
                                        >
                                            {template.name}
                                        </Typography>

                                    </Grid>  

                                    <Grid item md={3} lg={3} style={{display:'flex', alignItems:'center', justifyContent:'flex-start'}}>
                                        <div className={classes.actionIconContainer}>

                                            <Button onClick={(e) => onDeleteTemplateClick(e, template)} 
                                                className="more_actionButton1"> 
                                                <i className={`flaticon-delete-1 ${classes.flatIcons}`}></i>
                                                Delete
                                            </Button> 

                                            <Button className="more_actionButton1"
                                                onClick={() => {
                                                    history.push({
                                                    pathname: `/template/edit/${template.slug}`,
                                                })
                                                props.setCreateAndEditTemplate(true);
                                            }
                                            
                                            }
                                            > 
                                                    <i className={`flaticon-edit ${classes.flatIcons}`}></i>
                                                    Edit
                                            </Button>

                                            {
                                                template.published ?
                                                    <Button className="more_actionButton2"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            if(window.confirm('Are you sure you want to unpublish this template?')) {
                                                                onTemplatePublishUnPublishClick(template, 'unpublish');
                                                            }
                                                        }}
                                                    > 
                                                            <img src="/images/unpublish.png" className={classes.publishUnpublishIconStyle}/>
                                                            Unpublish
                                                    </Button>
                                            :
                                                    <Button className="more_actionButton2"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            onTemplatePublishUnPublishClick(template, 'publish');
                                                        }}
                                                    > 
                                                            <img src="/images/publish.png" className={classes.publishUnpublishIconStyle}/>
                                                            Publish
                                                    </Button>
                                            }
                                        </div>
                                    </Grid> 
                                </Grid>

                    }) : <Grid item container justifyContent='center' className={classes.tempList}>
                            <Typography variant='body1'> No Templates Found </Typography>
                        </Grid>  

                }
        </Grid>
        )
    }

    return (
        <Grid item sm={12}  className={classes.settingsContainer}>
                {showOrgnizationTemplates()}
        </Grid>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    templates: state.organizationInfo.templates,
    startDateFilter: state.filters.startDateFilter,
    endDateFilter: state.filters.endDateFilter,
    selectedMembersFilter: state.filters.selectedMembersFilter,
    companiesFilter: state.filters.companiesFilter,
    selectedTemplate: state.organizationInfo.selectedTemplate,
});

const mapDispatchToProps = (dispatch) => ({
    fetchTemplateList: (organization_id, start_date, end_date, companiesFilter, selectedMembersFilter, selectedTemplate) => 
        dispatch(fetchTemplateList(organization_id, start_date, end_date, companiesFilter, selectedMembersFilter, selectedTemplate)),
})

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationTemplates);