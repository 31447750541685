import React, {useState} from 'react';
import {Grid, Button, Popover} from '@material-ui/core';
import { DateRangePicker} from "materialui-daterange-picker";
import moment from 'moment';
import {connect} from 'react-redux';
import {setStartDateFilter, setEndDateFilter} from '../../actions/filters';
import { consoleToLog } from '../../util/AppUtil';
import { makeStyles } from '@material-ui/core/styles';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';

const useStyles =  makeStyles((theme) => ({
    MuiButtonlabel: {
        width: '100%',
        display: 'flex',
        alignItems: "center",
        justifyContent: 'space-between'
    }
})) 


const MobileDurationFilter = (props) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(false);
    const [dateRange, setDateRange] = useState({});

    //Last Month
    var prevMonthFirstDay = new moment().subtract(1, 'months').date(1)
    
    //for next month last day
    var thisMonthLastDay = new moment().add(1, 'months').date(0);
    
    //for last day of previous month
    var lastdayLastMonth = moment(lastdayLastMonth).subtract(1,'months').endOf('month').format('YYYY-MM-DD');

    let currentFinancialYearStart;
    let currentFinancialYearEnd;

    currentFinancialYearStart = (moment().month() < 3) ? moment().subtract(1, 'year').month('April').startOf('month').format('YYYY-MM-DD')
    : moment().month('April').startOf('month').format('YYYY-MM-DD');

    currentFinancialYearEnd =  (moment().month() < 3) ? moment().month('March').endOf('month').format('YYYY-MM-DD')
    : moment().add(1, 'year').month('March').endOf('month').format('YYYY-MM-DD');
    
    let lastFinancialYearStart = moment(currentFinancialYearStart).subtract(1, 'year').format('YYYY-MM-DD');
    let lastFinancialYearEnd = moment(currentFinancialYearEnd).subtract(1, 'year').format('YYYY-MM-DD');

    //console.log("lastFinancialYearStart:", lastFinancialYearStart, moment(lastFinancialYearStart, 'YYYY-MM-DD').format('MMM-YYYY'));
    //console.log("lastFinancialYearEnd:", lastFinancialYearEnd, moment(lastFinancialYearEnd, 'YYYY-MM-DD').format('MMM-YYYY'));
    //console.log("currentFinancialYearStart:", currentFinancialYearStart, moment(currentFinancialYearStart, 'YYYY-MM-DD').format('MMM-YYYY'));
    //console.log("currentFinancialYearEnd:", currentFinancialYearEnd, moment(currentFinancialYearEnd, 'YYYY-MM-DD').format('MMM-YYYY'));
    
    const toggle = () => setAnchorEl(!anchorEl);

    const handleClose = () => {
        setAnchorEl(false);
    };

    return (
        <div>
            <span style={{fontSize:'14px'}}>
                Duration
            </span>
            <Grid item container lg={12} style={{marginTop:'3px'}}>
                <Grid item xs={12}>
                   <Button variant='outlined' 
                        fullWidth
                        color='primary' 
                        style={{fontWeight:"300 !important"}}
                        classes={{
                            label: classes.MuiButtonlabel
                        }}
                        onClick={toggle}>
                        <span>    
                            {`${moment(props.startDateFilter).format('DD-MM-YYYY')} - 
                                ${moment(props.endDateFilter).format('DD-MM-YYYY')}`}
                        </span>

                        <CalendarTodayOutlinedIcon style={{color:'#BEBEBE'}}/>
                   </Button>
                        <DateRangePicker
                            open={anchorEl}
                            toggle={toggle}
                            onChange={(range) => {
                            consoleToLog("range", range, dateRange);
                                setDateRange(range);
                                props.setStartDateFilter(moment(range.startDate).format('YYYY-MM-DD'));
                                props.setEndDateFilter(moment(range.endDate).format('YYYY-MM-DD'));
                                handleClose();
                                props.pageReset();
                            }}
                            style={{
                            width:'50%'
                            }}
                            initialDateRange={{
                                startDate:  props.startDateFilter,
                                endDate: props.endDateFilter
                            }}
                            definedRanges={[
                            {
                                label: `${moment(lastFinancialYearStart, 'YYYY-MM-DD').format('MMM-YYYY')}-${moment(lastFinancialYearEnd, 'YYYY-MM-DD').format('MMM-YYYY')}`,
                                startDate: lastFinancialYearStart,
                                endDate: lastFinancialYearEnd
                            },
                            {
                                label: `${moment(currentFinancialYearStart, 'YYYY-MM-DD').format('MMM-YYYY')}-${moment(currentFinancialYearEnd, 'YYYY-MM-DD').format('MMM-YYYY')}`,
                                startDate: currentFinancialYearStart,
                                endDate: currentFinancialYearEnd
                            },
                            {
                                label: 'Last Month',
                                startDate: prevMonthFirstDay,
                                endDate: lastdayLastMonth
                            },
                            {
                                label: 'This Month',
                                startDate: new Date(),
                                endDate: thisMonthLastDay
                            }
                            ]}
                        />
                </Grid>
            </Grid>
        </div>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    startDateFilter: state.filters.startDateFilter,
    endDateFilter: state.filters.endDateFilter,
    selectedOrganization: state.organizationInfo.selectedOrganization,
});

const mapDispatchToProps = (dispatch) => ({
    setStartDateFilter: (start_date) => dispatch(setStartDateFilter(start_date)),
    setEndDateFilter: (end_date) => dispatch(setEndDateFilter(end_date))
})

export default connect(mapStateToProps, mapDispatchToProps)(MobileDurationFilter);