import './App.css';
import AppRouter from './routers/AppRouter';
import { SnackbarProvider } from 'notistack';
import Slide from '@material-ui/core/Slide';
import './css/flaticon.css';
import "react-datepicker/dist/react-datepicker.css";
import './styles/styles.scss';
import OverlayComponent from './components/OverlayComponent';
import { consoleToLog } from "../src/util/AppUtil";
import MobileAppRouter from './mobile/routers/MobileAppRouter';
import MobileOverlayComponent from './mobile/components/MobileOverlayComponent';

const App = () => {
  consoleToLog("Debug: ",process.env.REACT_APP_DEBUG);

  return (
    <SnackbarProvider maxSnack={3}
      autoHideDuration={2000}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
    }}
    TransitionComponent={Slide}>
      { window.innerWidth < 786 ? <MobileAppRouter/> : <AppRouter />}
      {window.innerWidth < 786 ? <MobileOverlayComponent /> : <OverlayComponent />}
      {/* <RightToLeftSideDrawer openDrawer={true}
          title="Title Will go from here">
          Content Should Be here
          
        </RightToLeftSideDrawer> */}
    </SnackbarProvider>
  );
}

export default App;
