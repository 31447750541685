import React, {useState, useEffect, createRef} from 'react';
import {TextField, Grid, Button, Typography, CircularProgress, Tooltip, Popover, MenuItem, Select, Paper, Link} from '@material-ui/core'; 
import {makeStyles} from '@material-ui/core/styles';
import RightToLeftSideDrawer from '../RightToLeftSideDrawer';
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import 'react-multi-email/style.css';
import { useSnackbar } from 'notistack';
import { useIsMount } from '../useIsMount';
import ClearIcon from '@material-ui/icons/Clear';
import {connect} from 'react-redux';
import {companyNameValidator, mobileNoValidator, emailValidator} from '../../util/validator';
import { removeSpace, consoleToLog, capitalizeFirstLetterOfEachWord, trackGAEvent, phoneNumberValidate } from '../../util/AppUtil';
import {addCompanyToOrgApi} from '../../services/authService';
import { showAllCountries, showAllStates, showAllUsers } from '../../util/AppUtil';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CloseIcon from '@material-ui/icons/Close';
import {addClientApi} from "../../services/clientService";
import { Drawer, AppBar, Toolbar, IconButton } from '@material-ui/core';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutline';
import {addMultipleContactsApi} from '../../services/clientService';


const useStyles = makeStyles((theme) => ({
    drawer: {
    //    width: drawerWidth,
        flexShrink: 0
    },
    drawerPaper: {
        width: '36%',
        //height: 'calc(100vh - 51px)',
        flexGrow:1,
        top:'0px'
    },
    appBar: {
        background:'#F9F9F9',
        height:'45px',
        color:'#202020'
    },
    MuiFormControlroot: {
        '& .MuiOutlinedInput-input' :{
            paddingLeft: '16px',
        },   
    },
    listContainer : {
        '&:last-child .dropdownDivider' : {
            display: 'none'
        }
    },
    dividerStyle : {
        background:'#000', 
        opacity:'0.1'
    },
    customMarginTop : {
        margin:'16px 0px 0px 0px'
    },
    customMarginBottom : {
        marginBottom:'-8px'
    },
    circularProgress: {
        marginLeft: 0,
        marginRight: theme.spacing(2),
        color: 'white'
    },
    enableStyle:{
        width:"100%",
        height:"2.5rem",
        padding:"2px",
    },
    disableStyle:{
        width:"100%",
        height:"2.5rem",
        padding:"2px",
        color:"#bababa"
    },
    MuiAutocompleteinputRoot : {
        '& .MuiOutlinedInput-root' : {
        padding:'0.5px 1px 0.5px 1px',
        borderRadius: '4px',
        background:'#fff',
        marginTop:'4px'
        }
    },
    customTextField: {
        "& input::placeholder": {
            paddingLeft: "8px",
        }
    },  
    addItemIcon: {
        margin:'0px 6px 0px 0px', 
        fontSize:'16px'
    },
    contactArrContainer: {
        marginBottom:'16px', 
        borderBottom:'1px solid rgba(0,0,0,0.2)',
        padding:'0px 0px 16px 0px'
    },
    addLinkButton: {
        color: "#20a8e0",
        fontSize: "15px",
        fontWeight: "600",
        marginLeft: "2px",
        cursor: "pointer",
    }

}));

const OnboardingCreateNewCompany = (props) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    const isMount = useIsMount();

    const [anchorEl, setAnchorEl] = useState(null);

    const [companyName, setCompanyName] = useState('');
    const [emails, setEmails] = useState([]);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [compAddress, setCompAddress] = useState('');
    const [selectedCountry, setSelectedCountry] = useState('IN');
    const [selectedState, setSelectedState] = useState('none');
    const [taxId, setTaxId] = useState('');
    const [openDrawer, setOpenDrawer] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(undefined);
    const [selectedManager, setSelectedManager] = useState('none');
    const [contactArr, setContactArr] = useState([]);

    const [loading, setLoading] = useState(false);
    const [switchFocus, setSwitchFocus] = useState(false);

    const comp_name = props.companyName ? props.companyName : undefined;

    let obj = {
        organization_id: props.selectedOrganization.organization.id, 
        email: '', 
        phone_number: '', 
        first_name: '', 
        last_name: '', 
        title: '', 
        //client_id: client_id, 
        //client_name:  client_name
    }

    const addInitialObj = () => {
        setContactArr([obj]);
    }

    useEffect(() => {
        setCompanyName(comp_name);
    }, [comp_name]);

    useEffect(() => {
        if(props.clearCompanyDrawer) {

            props.setClearCompanyDrawer(true);
        }
    }, [props.clearCompanyDrawer]);

    const closeCompanyDrawer = () => {
        setCompanyName('');
        setEmails([]);
        setPhoneNumber('');
        setCompAddress('');
        setSelectedState('none');
        setContactArr([]);
        props.handleCloseCreateCompanyDrawer();
    }

    const onAddButtonClick = () => {
        setContactArr([...contactArr, obj]);
    }

    const onDeleteButtonClick = (contactObj, index) => {
        const updatedArray = contactArr.filter((contact) => contact.email !== contactObj.email);
        setContactArr([...updatedArray]);
    }

    const onFirstNameChange = (e, index) => {
        const updatedArray = contactArr;
        updatedArray[index].first_name = capitalizeFirstLetterOfEachWord(e.target.value);
        setContactArr([...updatedArray]);
    }

    const onLastNameChange = (e, index) => {
        const updatedArray = contactArr;
        updatedArray[index].last_name = capitalizeFirstLetterOfEachWord(e.target.value);
        setContactArr([...updatedArray]);
    }


    const onPhoneNumberChange = (e, index) => {
        const updatedArray = contactArr;
        updatedArray[index].phone_number = e.target.value;
        setContactArr([...updatedArray]);
    }

    const onEmailChange = (e, index) => {
        const updatedArray = contactArr;
        updatedArray[index].email = e.target.value;
        setContactArr([...updatedArray]);
    }

    const onTitleChange = (e, index) => {
        const updatedArray = contactArr;
        updatedArray[index].title = e.target.value;
        setContactArr([...updatedArray]);
    }

    const contactFieldValidation = (contact_array) => {
        for (var i = 0; i < contact_array.length; i++) {
            //name validations
            if (!contact_array[i].first_name) {//empty
                return `Firstname cannot be empty at row :${i + 1}`;
            }
            if (!contact_array[i].last_name) {//empty
                return `Lastname cannot be empty at row :${i + 1}`;
            }
            if (!contact_array[i].email) {//empty
                return `Email cannot be empty at row :${i + 1}`;
            }
            const isEmailValid = emailValidator(contact_array[i].email);
            if(!isEmailValid) {
                return `Email is not valid at row :${i + 1}`;
            }
            for (var j = i + 1; j < contact_array.length; j++) {//repeat
                if (contact_array[i].email === contact_array[j].email) {
                    return `Email can not be repeated: ${contact_array[i].email}`;
                }
            }
        }
    }

    const onCreateCompanyModal = () => {
        const company_name = companyName;
        
        //const companyEmail = emails;
        
        let mobile = phoneNumber;
        
        //var pan_no = document.getElementById('pan_no').value;
        //var GSTIN = document.getElementById('GSTIN').value;
        
        const address = compAddress;

        //company name validation
        if (!company_name) {
            enqueueSnackbar('Client name can not be empty!', {
                variant: 'error'
            });
            return;
        }


        const isCompanyNameValid = companyNameValidator(company_name);
        if (!isCompanyNameValid) {
            enqueueSnackbar('Enter valid client name', {
                variant: 'error'
            });
            return;
        }

        mobile = removeSpace(mobile);
        //user defined fields validations

        if(selectedCountry === 'none' || selectedCountry === '' || selectedCountry === undefined) {
            enqueueSnackbar('Please Select the Country', {
                variant: 'error'
            });
            return;
        } 
  
        if(selectedCountry !== 'IN') {
            enqueueSnackbar('Please Select India as Country', {
                variant: 'error'
            });
            return;
        }
    
        if(selectedState === 'none' || selectedState === '' || selectedState === undefined) {
            enqueueSnackbar('Please Select the State', {
                variant: 'error'
            });
            return;
        }

        if(contactArr.length > 0) {
            const errors = contactFieldValidation(contactArr);
            if (errors) {
                enqueueSnackbar(errors, {
                    variant:'error'
                });
                return;
            }
        } 

        const tax_id = taxId;
        
        const relationship_manager_id = selectedManager !== 'none' ? [selectedManager] : [];

        setLoading(true);
        const companyObj = {company_name, mobile, address, selectedCountry, selectedState, tax_id, relationship_manager_id }
        callCompanyApi(companyObj);
        
    }


    const callCompanyApi = ({company_name, mobile, address, selectedCountry, selectedState, tax_id, relationship_manager_id }) => {
        
            const organization_id = props.selectedOrganization.organization.id;
            const user_defined_field = [];
            //create company api call
            addClientApi(organization_id, capitalizeFirstLetterOfEachWord(company_name),mobile, address, selectedState,
                selectedCountry, tax_id, user_defined_field, relationship_manager_id)
                .then((response) => {
                    const res = response.data;
                    consoleToLog("Response addClientApi: ", res);
                    setLoading(false);
                    enqueueSnackbar('Client created!', {
                        variant:'success'
                    });

                    //update local state companies

                    if(contactArr && contactArr.length > 0) {
                        const updatedArray = contactArr.map((contact) => {
                            return {
                                ...contact,
                                client_id: res.id, 
                                client_name:  res.name
                            }
                        });
                        setContactArr(updatedArray);
                        callAddMultipleContactsApi(updatedArray, res);
                    } else {
                        props.addCompany(res);
                        createCompanyWithoutChecklist(res);
                        setLoading(false);
                    }
                    trackGAEvent(props.selectedOrganization.organization.name, 'Add Company', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
                })
                .catch((e) => {
                    consoleToLog("addClientApi error", e);
                    setLoading(false);
                    if (e.response.data && e.response.data.message) {
                        enqueueSnackbar(e.response.data.message, {
                            variant : 'error'
                        });
                        return false;
                    }
                });
    }

    const callAddMultipleContactsApi = (contactArr, clientObj) => {
        addMultipleContactsApi(contactArr)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response addMultipleContactsApi: ', res);
                props.addCompany(clientObj);
                setLoading(false);
                createCompanyWithoutChecklist(clientObj);

            })
            .catch((e) => {
                consoleToLog('Error addMultipleContactsApi: ', e.response);
                if(e.reponse.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {variant:'error'});
                    return;
                }
            })
    }

    const createCompanyWithoutChecklist = (clientObj) => {
        const organization_id = props.selectedOrganization.organization.id;


        const user_defined_field = [];
        const { name, address, country, state, tax_id, id } = clientObj;
        //const emailArr = email && email !== '' ? email.split(',') : [];
        addCompanyToOrgApi(capitalizeFirstLetterOfEachWord(name), address, 
        organization_id, user_defined_field, [], country, state, tax_id, id)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response addCompanyToOrgApi: ", res);

                closeCompanyDrawer();
            })
            .catch((e) => {
                consoleToLog("addCompanyToOrgApi error", e.response);
                setLoading(false);
                closeCompanyDrawer();
                if (e.response.data && e.response.data.message) {
                    // enqueueSnackbar(e.response.data.message, {
                    //     variant:'error'
                    // });
                    return;
                }
            });
    }

    return (
        <Drawer variant="temporary"
            anchor="right"
            open={props.openCreateCompanyDrawer}
            onClose={closeCompanyDrawer}
            classes={{
                paper: classes.drawerPaper
                }}
            >
            <AppBar position="static" className={classes.appBar} elevation={0}>
                <Toolbar style={{minHeight:'40px', padding:'0px 16px', }}>
                    <Typography variant="body1" 
                        style={{fontWeight:'600', marginRight:'auto'}}>
                        Add Client 
                    </Typography>
                    <IconButton style={{color:'#202020', marginRight:'-16px'}} 
                        onClick={closeCompanyDrawer}
                    >
                        <ClearIcon/>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <div className={"modal__main"} style={{overflow:'auto'}}>
                <Grid item container>
                    <Grid item md={12} lg={12}>
                        <Typography variant="subtitle1" 
                            className={classes.customMarginBottom}>
                            Client Name
                        </Typography>

                        <TextField fullWidth 
                            id="company_name" 
                            variant="outlined"
                            type="text"
                            margin="dense"
                            value={companyName}
                            autoComplete="off"
                            placeholder="Enter Client Name"
                            InputLabelProps={{style: {fontSize: 13, color: "#ccc"}}}
                            onChange={(e) => setCompanyName(e.target.value)}
                        />
                    </Grid>    

                    <Grid item md={12} lg={12} 
                        className={classes.customMarginTop}>
                        <Typography variant="subtitle1" 
                            className={classes.customMarginBottom}>
                            Mobile Number (Optional)
                        </Typography>   

                        <TextField fullWidth 
                            id="mobile_number" 
                            variant="outlined"
                            type="tel"
                            margin="dense"
                            autoComplete="off"
                            value={phoneNumber}
                            placeholder="Mobile Number"
                            InputLabelProps={{style: {fontSize: 13, color: "#ccc"}}}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            onKeyPress={(e) => phoneNumberValidate(e)}
                        />
                    </Grid>  

                    <Grid item md={12} lg={12} 
                        className={classes.customMarginTop}>
                        <Typography variant="subtitle1" 
                            className={classes.customMarginBottom}>
                            Tax Id (Optional)
                        </Typography>   

                        <TextField fullWidth 
                            id="tax_id" 
                            variant="outlined"
                            type="text"
                            margin="dense"
                            autoComplete="off"
                            placeholder="Tax Id"
                            value={taxId}
                            InputLabelProps={{style: {fontSize: 13, color: "#ccc"}}}
                            onChange={(e) => setTaxId(e.target.value)}
                        />
                    </Grid>

                    <Grid item md={12} lg={12} 
                        className={classes.customMarginTop}>
                        <Typography variant="subtitle1" 
                            className={classes.customMarginBottom}>
                            Address (Optional)
                        </Typography>   

                        <TextField fullWidth 
                            id="address" 
                            variant="outlined"
                            type="text"
                            margin="dense"
                            autoComplete="off"
                            placeholder="Address"
                            value={compAddress}
                            InputLabelProps={{style: {fontSize: 13, color: "#ccc"}}}
                            onChange={(e) => setCompAddress(e.target.value)}
                        />
                    </Grid>

                    <Grid item md={12} lg={12} 
                        className={classes.customMarginTop}>
                        <Typography variant="subtitle1">
                            Country
                        </Typography>   

                        <Select 
                            className={selectedCountry === 'none' ? classes.disableStyle : classes.enableStyle}
                            value={selectedCountry}
                            onChange={(e) => setSelectedCountry(e.target.value)}
                            variant="outlined"
                            placeholder='Select Country'
                            autoWidth
                            //disabled={disabled}
                            >
                            {
                                selectedCountry === 'none' && <MenuItem value='none'>Select Country</MenuItem>
                            } 
                            {showAllCountries()}
                        </Select>
                    </Grid>

                    <Grid item md={12} lg={12} 
                        className={classes.customMarginTop}>
                        <Typography variant="subtitle1">
                            State
                        </Typography>   

                        <Select 
                            className={selectedState === 'none' ? classes.disableStyle : classes.enableStyle}
                            value={selectedState}
                            onChange={(e) => setSelectedState(e.target.value)}
                            variant="outlined"
                            placeholder='Select State'
                            autoWidth
                            disabled={selectedCountry === 'none' || selectedCountry !== 'IN'}
                        >
                        {
                            selectedState === 'none' && <MenuItem value='none'>Select State</MenuItem>
                        } 
                        {showAllStates()}
                        </Select>
                    </Grid>

                    <Grid item md={12} lg={12} 
                        className={classes.customMarginTop}>
                        <Typography variant="subtitle1">
                            Relationship Manager (Optional)
                        </Typography>   

                        <Select 
                            className={selectedManager === 'none' ? classes.disableStyle : classes.enableStyle}
                            value={selectedManager}
                            onChange={(e) => setSelectedManager(e.target.value)}
                            variant="outlined"
                            placeholder='Select Relationship Manager'
                            autoWidth
                        >
                        {
                            selectedManager === 'none' && <MenuItem value='none'>Select Relationship Manager</MenuItem>
                        } 
                        {props.members && props.members.length > 0 && showAllUsers(props.members)}
                        </Select>
                    </Grid>

                    <Grid item md={12} lg={12} style={{marginTop:'16px'}}>
                        {contactArr && contactArr.length > 0 &&
                        <Typography variant='body1' style={{fontWeight:600}}>
                            Contacts
                        </Typography>}
                    {
                        contactArr && contactArr.length > 0 && 
                        contactArr.map((contact, index) => {
                            return <Grid item container key={index} className={classes.contactArrContainer} style={{}}>
                                        <Grid item md={11}>
                                            <Grid item container spacing={1}>
                                                <Grid item md={6}>
                                                    <TextField
                                                        fullWidth
                                                        margin='dense'
                                                        variant='outlined'
                                                        value={contact.first_name}
                                                        placeholder='Enter First Name'
                                                        onChange={(e) => onFirstNameChange(e, index)}
                                                    />
                                                </Grid>

                                                <Grid item md={6}>
                                                    <TextField
                                                        fullWidth
                                                        margin='dense'
                                                        variant='outlined'
                                                        value={contact.last_name}
                                                        placeholder='Enter Last Name'
                                                        onChange={(e) => onLastNameChange(e, index)}
                                                    />
                                                </Grid>

                                                <Grid item md={6}>
                                                    <TextField
                                                        fullWidth
                                                        margin='dense'
                                                        variant='outlined'
                                                        value={contact.phone_number}
                                                        onKeyPress={(e)=>phoneNumberValidate(e)} 
                                                        placeholder='Enter Mobile No'
                                                        onChange={(e) => onPhoneNumberChange(e, index)}
                                                    />
                                                </Grid>

                                                <Grid item md={6}>
                                                    <TextField
                                                        fullWidth
                                                        margin='dense'
                                                        variant='outlined'
                                                        value={contact.email}
                                                        placeholder='Enter Email'
                                                        onChange={(e) => onEmailChange(e, index)}
                                                    />
                                                </Grid>

                                                <Grid item md={6}>
                                                    <TextField
                                                        fullWidth
                                                        margin='dense'
                                                        variant='outlined'
                                                        value={contact.title}
                                                        placeholder='Enter Title'
                                                        onChange={(e) => onTitleChange(e, index)}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item md={1}>
                                            <Button onClick={() => onDeleteButtonClick(contact, index)} 
                                                style={{minWidth:'20px', marginLeft:'8px'}}>
                                                <DeleteOutlinedIcon />
                                            </Button>
                                        </Grid>

                                    </Grid>
                        })
                    }
                
                {
                    <Grid item container justifyContent='center' alignItems='center' style={{marginTop:'16px'}}>
                        <Grid item>
                            <Link  className={classes.addLinkButton}
                                onClick={onAddButtonClick}>
                                Add Contact
                            </Link>
                        </Grid>

                    </Grid>
                }

                </Grid>

                    <div className="modal__footer1">
                        <Button variant="contained" color="primary" 
                            onClick={onCreateCompanyModal}
                            className="modal__button">
                            {loading && <CircularProgress size={24} className={classes.circularProgress}/>}
                            Create    
                        </Button>
                        <Button variant="outlined" style={{
                            minWidth: '130px',
                        }} onClick={props.handleCloseCreateCompanyDrawer}>
                            Cancel
                        </Button>
                    </div> 
                </Grid>
            </div>
            </Drawer>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    templates: state.organizationInfo.templates,
    customFieldList: state.organizationInfo.customFieldList,
    members: state.organizationInfo.members,
    orgEmailList: state.organizationInfo.orgEmailList
});

export default connect(mapStateToProps)(OnboardingCreateNewCompany);