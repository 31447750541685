import React, {useState, useEffect} from 'react';
import RightToLeftSideDrawer from '../RightToLeftSideDrawer';
import {Grid, Typography, Button, TextField, CircularProgress} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {connect} from 'react-redux';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import { capitalizeFirstLetterOfEachWord, consoleToLog } from '../../util/AppUtil';
import {addCompanyToOrgApi} from '../../services/authService';
import {addMultipleContactsApi} from '../../services/clientService';
import { useSnackbar } from 'notistack';
import { useIsMount } from '../useIsMount';
import { phoneNumberValidate } from '../../util/AppUtil';
import { emailValidator } from '../../util/validator';


const useStyles = makeStyles((theme) => ({
    modalContainer: {
        marginTop: '16px',
        height: 'calc(100vh - 180px)',
        overflow: 'auto !important'
    },
    modalContainer1: {
        display: 'grid',
        justifyContent: 'center',
        alignContent: 'center',
        flexDirection: 'column',
        height: 'calc(100vh - 180px)',
        overflow: 'auto !important'
    },
    contactArrContainer: {
        marginBottom:'16px', 
        borderBottom:'1px solid rgba(0,0,0,0.2)',
        padding:'0px 16px 16px 16px'
    },
    circularProgress: {
        marginLeft: 0,
        marginRight: theme.spacing(2),
        color: 'white'
    },
}))

const AddClientContactModal = (props) => {
    const classes = useStyles();
    const isMount = useIsMount();
    const {enqueueSnackbar} = useSnackbar();

    const [contactArr, setContactArr] = useState([]);
    const [loading, setLoading] = useState(false);
    const [clientObj,setClientObj]=useState(undefined);
    const [showCreateChecklistMessage, setShowCreateChecklistMessage] = useState(false);

    const client_id = props.tempCompanyData && props.tempCompanyData.id;
    const client_name = props.tempCompanyData && props.tempCompanyData.name;

    let obj = {
        organization_id: props.selectedOrganization.organization.id, 
        email: '', 
        phone_number: '', 
        first_name: '', 
        last_name: '', 
        title: '', 
        client_id: client_id, 
        client_name:  client_name,
        invoice_account_id: props.tempCompanyData?.invoice_account_id
    }

    const addInitialObj = () => {
        setContactArr([obj]);
    }

    useEffect(() => {
            addInitialObj();
    }, []);

    useEffect(() => {
        if(!isMount) {
            if(props.tempCompanyData) {
                addInitialObj();
            }
        }
    }, [props.tempCompanyData]);

    const onAddButtonClick = () => {
        setContactArr([...contactArr, obj]);
    }


    const onDeleteButtonClick = (contactObj, index) => {
        const updatedArray = contactArr.filter((contact) => contact.email !== contactObj.email);
        setContactArr([...updatedArray]);
    }

    const onFirstNameChange = (e, index) => {
        const updatedArray = contactArr;
        updatedArray[index].first_name = capitalizeFirstLetterOfEachWord(e.target.value);
        setContactArr([...updatedArray]);
    }

    const onLastNameChange = (e, index) => {
        const updatedArray = contactArr;
        updatedArray[index].last_name = capitalizeFirstLetterOfEachWord(e.target.value);
        setContactArr([...updatedArray]);
    }


    const onPhoneNumberChange = (e, index) => {
        const updatedArray = contactArr;
        updatedArray[index].phone_number = e.target.value;
        setContactArr([...updatedArray]);
    }

    const onEmailChange = (e, index) => {
        const updatedArray = contactArr;
        updatedArray[index].email = e.target.value;
        setContactArr([...updatedArray]);
    }

    const onTitleChange = (e, index) => {
        const updatedArray = contactArr;
        updatedArray[index].title = e.target.value;
        setContactArr([...updatedArray]);
    }

    const onYesButtonClick = () => {
        addContactsToClient();
        props.closeDrawer();
        props.handleOpenCreateChecklistDrawer();
    }

    const onCancelClick = () => {
        setShowCreateChecklistMessage(true);
    }

    const onNoButtonClick = () => {
        createCompanyWithoutChecklist();
    }

    const addContactsToClient = () => {
        // const newObj = {
        //     ...props.tempCompanyData,
        //     contacts: contactArr
        // }
        let obj = clientObj ? clientObj : props.tempCompanyData;
        const updatedArray = props.clientList.map((client) => {
            if(client.id === obj.id) {
                client = obj
            }
            return client;
        });
        props.setClientList(updatedArray);
        setShowCreateChecklistMessage(false);
        setClientObj(undefined);
    }

    const createCompanyWithoutChecklist = () => {
        const organization_id = props.selectedOrganization.organization.id;
        
        const user_defined_field = props.tempCompanyData?.user_defined_field;
        const { name, address, country, state, tax_id, id } = props.tempCompanyData;
        addCompanyToOrgApi(capitalizeFirstLetterOfEachWord(name), address, 
        organization_id, user_defined_field, [], country, state, tax_id, id)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response addCompanyToOrgApi: ", res);

                addContactsToClient();
                setShowCreateChecklistMessage(false);
                props.setClearCompanyDrawer(true);
                props.handleCloseCreateCompanyDrawer();
                props.closeDrawer();
                props.storeTempCompanyInfo(undefined);
            })
            .catch((e) => {
                consoleToLog("addCompanyToOrgApi error", e);
                setLoading(false);
                if (e.response.data && e.response.data.message) {
                    // enqueueSnackbar(e.response.data.message, {
                    //     variant:'error'
                    // });
                    return;
                }
            });
    } 

    const contactFieldValidation = (contact_array) => {
        for (var i = 0; i < contact_array.length; i++) {
            //name validations
            if (!contact_array[i].first_name) {//empty
                return `Firstname cannot be empty at row :${i + 1}`;
            }
            if (!contact_array[i].last_name) {//empty
                return `Lastname cannot be empty at row :${i + 1}`;
            }
            if (!contact_array[i].email) {//empty
                return `Email cannot be empty at row :${i + 1}`;
            }
            const isEmailValid =emailValidator(contact_array[i].email);
            if(!isEmailValid) {
                return `Email is not valid at row :${i + 1}`;
            }
            for (var j = i + 1; j < contact_array.length; j++) {//repeat
                if (contact_array[i].email === contact_array[j].email) {
                    return `Email can not be repeated: ${contact_array[i].email}`;
                }
            }
        }
    }
    


    const onSaveContactsClick = () => {
        if(contactArr.length > 0) {
            const errors = contactFieldValidation(contactArr);
            if (errors) {
                enqueueSnackbar(errors, {
                    variant:'error'
                });
                return;
            }
        } 
        if(contactArr.length === 0) {
            enqueueSnackbar('Please add atleast one contact', {variant:'error'});
        }   

        setLoading(true);
        addMultipleContactsApi(contactArr)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response addMultipleContactsApi: ', res);
                
                setLoading(false);
                setClientObj(res.client);

                enqueueSnackbar('Contacts added successfully', {variant:'success'});

                setShowCreateChecklistMessage(true);

            })
            .catch((e) => {
                consoleToLog('Error addMultipleContactsApi: ', e.response);
                setLoading(false);
                if(e.reponse.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {variant:'error'});
                    return;
                }
            })
    }


    return (
        <RightToLeftSideDrawer title="Add Client Contacts"
            openDrawer={props.openDrawer}
            closeDrawer={props.closeDrawer}>

            <div className={showCreateChecklistMessage ? classes.modalContainer1 : classes.modalContainer}>
                {showCreateChecklistMessage ?
                    <Grid item sm={12}>
                            <Typography variant='body1'>
                                Do you want to create checklist for {props.tempCompanyData?.name} ?
                            </Typography>

                            <Grid item container  style={{marginTop:'8px'}}
                                justifyContent='center'
                                spacing={1}>

                                    <Grid item>
                                        <Button variant='contained' 
                                            onClick={onYesButtonClick}
                                            color='primary'>
                                            Yes
                                        </Button>
                                    </Grid>

                                    <Grid item>
                                        <Button variant='outlined' 
                                            onClick={onNoButtonClick}
                                            color='primary'>
                                            No
                                        </Button>
                                    </Grid>

                            </Grid>
                    </Grid>
                :
                <Grid item md={12} lg={12}>
                    {
                        contactArr && contactArr.length > 0 && 
                        contactArr.map((contact, index) => {
                            return <Grid item container key={index} className={classes.contactArrContainer} style={{}}>
                                        <Grid item md={11}>
                                            <Grid item container spacing={1}>
                                                <Grid item md={6}>
                                                    <TextField
                                                        fullWidth
                                                        margin='dense'
                                                        variant='outlined'
                                                        value={contact.first_name}
                                                        placeholder='Enter First Name'
                                                        onChange={(e) => onFirstNameChange(e, index)}
                                                    />
                                                </Grid>

                                                <Grid item md={6}>
                                                    <TextField
                                                        fullWidth
                                                        margin='dense'
                                                        variant='outlined'
                                                        value={contact.last_name}
                                                        placeholder='Enter Last Name'
                                                        onChange={(e) => onLastNameChange(e, index)}
                                                    />
                                                </Grid>

                                                <Grid item md={6}>
                                                    <TextField
                                                        fullWidth
                                                        margin='dense'
                                                        variant='outlined'
                                                        value={contact.phone_number}
                                                        onKeyPress={(e)=>phoneNumberValidate(e)} 
                                                        placeholder='Enter Mobile No'
                                                        onChange={(e) => onPhoneNumberChange(e, index)}
                                                    />
                                                </Grid>

                                                <Grid item md={6}>
                                                    <TextField
                                                        fullWidth
                                                        margin='dense'
                                                        variant='outlined'
                                                        value={contact.email}
                                                        placeholder='Enter Email'
                                                        onChange={(e) => onEmailChange(e, index)}
                                                    />
                                                </Grid>

                                                <Grid item md={6}>
                                                    <TextField
                                                        fullWidth
                                                        margin='dense'
                                                        variant='outlined'
                                                        value={contact.title}
                                                        placeholder='Enter Title'
                                                        onChange={(e) => onTitleChange(e, index)}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item md={1}>
                                            <Button onClick={() => onDeleteButtonClick(contact, index)} 
                                                style={{minWidth:'20px', marginLeft:'8px'}}>
                                                <DeleteOutlinedIcon />
                                            </Button>
                                        </Grid>

                                    </Grid>
                        })
                    }
                
                {
                    <Grid item container justifyContent='center' alignItems='center' style={{marginTop:'16px'}}>
                        <Grid item md={6}>
                            <Button fullWidth 
                                onClick={onAddButtonClick}
                                color='primary' variant='contained'>
                                Add Contact
                            </Button>
                        </Grid>

                    </Grid>
                }

                    <div className="modal__footer">
                        <Button variant="contained" color="primary" 
                            onClick={onSaveContactsClick}
                            className="modal__button">
                            {loading && <CircularProgress size={24} className={classes.circularProgress}/>}    
                            Save
                        </Button>
                        <Button variant="outlined" 
                            onClick={onCancelClick}
                            style={{
                            minWidth: '130px',
                            }}
                        >
                            Cancel
                        </Button>
                    </div>
                </Grid>}
            </div>
        </RightToLeftSideDrawer>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization
});

export default connect(mapStateToProps)(AddClientContactModal);