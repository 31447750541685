import React, {useState, useEffect} from 'react';
import {Grid, Typography, Button, Dialog, CircularProgress} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {connect} from 'react-redux';
import ClearIcon from '@material-ui/icons/Clear';
import {getClientListForContactApi, directDeleteContactApi} from '../../services/clientService';
import { consoleToLog } from '../../util/AppUtil';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
    MuiDialogpaper: {
        scrollbarWidth: 'none',
        "&::-webkit-scrollbar": {
            display: "none"
        }   
    },
    dialogTitle : {
        borderBottom:'1px solid #ccc', 
        padding:'14px 16px', 
        fontSize:'16px',
        fontWeight:'600',
    },
    clientListContainer: {
        backgroundColor: "#F9F9F9",
        padding:"10px",
        borderRadius: '4px',
        marginBottom:'8px',
        '&:last-child' : {
            marginBottom:'0px',
        }
    },
    listItemsStyle: {
        color:'#555555', 
        display:'flex', 
        alignItems:'center'
    }
}))

const ClientListModal = (props) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();

    const [clientList, setClientList] = useState([]);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        getClientListForContact();
    }, [])

    const getClientListForContact = () => {

        setLoading(true);
        getClientListForContactApi(props.contactId)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response getClientListForContactApi: ', res);
                setLoading(false);

                setClientList(res.clients);
            })
            .catch((e) => {
                consoleToLog('Error getClientListForContactApi: ', e.response);
                setLoading(false);
                if(e.response.data && e.response.data.message) {
                    return;
                }
            })

    }

    const callDirectContactDeleteApi = () => {
        directDeleteContactApi(props.contactId)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response directDeleteContactApi: ', res);

                enqueueSnackbar('Client Deleted Successfully', {variant:'success'});
                const updatedArray = props.contactList.filter((contact) => contact.id !== props.contactId);
                props.setContactList(updatedArray);
                props.setContactId(undefined);
                //props.setContactDeletedFromOutside(true);
                onCancelClick();
            })
            .catch((e) => {
                consoleToLog('Error directDeleteContactApi: ', e.response);
                if(e.response.data && e.response.data.message) {
                    return;
                }
            })
    }   

    const onCancelClick = () => {
        props.handleClickDialogClose();
        props.setContactId(undefined);
    }   

    return (
        <Dialog
                open={props.openDialog}
                //TransitionComponent={Transition}
                scroll="paper"
                classes={{
                    paper: classes.MuiDialogpaper}}
                maxWidth="md"
                fullWidth
                style={{scrollbarWidth:'none !important'}}
                onClose={props.handleClickDialogClose}>

                    <Grid item container   
                        justifyContent='space-between'
                        alignItems='center'className={classes.dialogTitle}>

                        <Grid item>        
                            <Typography style={{fontWeight:600}}>
                                <span style={{marginLeft:'9px'}}>Client List</span>
                            </Typography>
                        </Grid>

                        <Grid item>
                            <Grid item>
                                <ClearIcon fontSize='small' onClick={props.handleClickDialogClose}
                                style={{cursor:'pointer', verticalAlign:'inherit'}}/>
                            </Grid>
                        </Grid>

                    </Grid>

                    {loading ?
                        <Grid item container justifyContent='center'>
                            <CircularProgress size={28} style={{marginTop:'16px'}}/>
                        </Grid>
                    :
                    <Grid item sm={12} style={{padding:'32px 16px'}}>
                        {clientList && clientList.length > 0 ?
                        clientList.map((client) => {
                            return <Grid item container direction="row"
                                        key={client.contact_id}
                                        className={classes.clientListContainer}
                                        >
                                        <Grid item container direction="column">
                                            <Grid item md={12}>
                                                <Typography variant='body1'>
                                                    {client.name}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                        }) 

                        : 
                        <Grid item container justifyContent='center'>
                            <Typography variant='body1'>
                                No Client List Found
                            </Typography>
                        </Grid>
                        }
                    </Grid>
                    
                    
                    }
                {(clientList && clientList.length > 0) ?

                    <Grid item md={12} style={{marginBottom: '16px'}}>
                        <Grid item container justifyContent='center' alignItems='center'>
                            <Typography variant='body1'>
                                Are you sure want to delete this contact for above clients? 
                            </Typography>
                        </Grid>

                    </Grid>
                   :                   
                    <Grid item md={12} style={{marginBottom: '16px'}}>
                        <Grid item container justifyContent='center' alignItems='center'>
                                <Typography variant='body1'>
                                   Are you sure want to delete this contact? 
                                </Typography>
                        </Grid>
                    </Grid>
                    
                  }
                    {
                        
                        <Grid item container justifyContent='center' spacing={1} style={{marginBottom:'32px'}}>
                            <Grid item>
                                <Button variant='contained' color='primary' 
                                    onClick={callDirectContactDeleteApi}
                                >
                                    Yes
                                </Button>
                            </Grid>

                            <Grid item>
                                <Button variant='outlined' color='primary' 
                                    onClick={onCancelClick}
                                >
                                    No
                                </Button>
                            </Grid>
                        </Grid>
                    }
                
            </Dialog>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
});

export default connect(mapStateToProps)(ClientListModal);