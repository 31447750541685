import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles'; 
import {Grid, Container, Typography, TextField, Button, MenuItem, CircularProgress,Paper, Tabs, Tab, Avatar} from '@material-ui/core';
import { connect } from 'react-redux';
import {capitalize, consoleToLog, trackGAEvent, shouldDisable, AccessLevel} from '../util/AppUtil';
import {emailValidator} from '../util/validator';
import { useSnackbar } from 'notistack';
import {addMemberToOrgApi, organizationMemberListApi, editOrgMemberApi, removeOrgMemberApi, 
    getPendingInvitationsListApi, resendInvitationApi, checkIfUserIsRegisteredApi,
    pendingInvitationExistApi, createPendingInvitationApi, removeScopeApi} from '../services/authService';
import { addOrgMember, setOrgMembers, updateMemberRole, removeOrgMember } from '../actions/selectedOrganization';
import {useIsMount} from './useIsMount';


const useStyles = makeStyles((theme) => ({
    mainContent : {
        width: (showDrawer) => {
           return showDrawer ? "83%" : "100%"; 
        },
        backgroundColor: theme.palette.primary.light, 
        transition: ".5s", 
        flexGrow: 1,
        //overflow:'hidden',
        overflow:'auto',
        overflowX:'hidden'
        },
    
    container: {
        marginTop: "8px"
        },

    circularProgress: {
        marginLeft: 0,
        marginRight: theme.spacing(2),
        color: 'white'
        },    

    tab: {
        flexGrow: 1,
        borderRadius:0,
        borderBottom: "1px solid #c5b9b9",
        },
        
    memberContainer: {
        flexGrow: 1,
        background: 'white',
        padding:'15px',
        //height:'100vh',
        marginBottom:'25px'
    },   
        
    memberColumn : {
        background: '#F9F9F9',
        borderRadius: '5px',
        marginBottom: '8px',
        padding:'8px 8px',
        '&:last-child' : {
            marginBottom: '0px'
        },
        "& .deleteIcon" : {
            visibility: 'hidden'
        },
        "&:hover .deleteIcon" : {
            visibility: 'visible'
        },
        '& .more_actionButton1' : {
            visibility:'hidden',
            width:'90px !important',
            background:'#fff',
            border:'1px solid #ccc',
            borderRadius:'5px',
            padding:'1px 0px',
            margin:'6px 0px',
            fontSize:'13px',
        },
        '& .more_actionButton2' : {
            visibility:'hidden',
            width:'180px !important',
            background:'#fff',
            border:'1px solid #ccc',
            borderRadius:'5px',
            padding:'1px 0px',
            margin:'6px 0px',
            fontSize:'13px',
        },
        '&:hover .more_actionButton1' : {
            visibility:'visible'
        },
        '&:hover .more_actionButton2' : {
            visibility:'visible'
        },
    },
    
    avatarStyle: {
        width:"38px !important",
        height:"38px !important",
        fontSize:'16px !important',
        fontWeight:'600 !important',
        transform: 'translateY(3px)'
    },

    memberText: {
        transform: 'translateY(1.5px)'
    },

    pendingLoading: {
        position:'relative',
        left:'50%',
        right:'50%',
        top:'5%'
    },

    actionIconContainer : {
        display:'flex',
        flexGrow:1,
        justifyContent:'flex-end',
        alignItems:'center'
    },

    flatIcons : {
        fontSize:'15px', 
        marginRight:'6px',
        display:'flex',
        alignItems:'center'
    },

    pendingMemberColumn : {
        background: '#F9F9F9',
        borderRadius: '5px',
        marginBottom: '8px',
        padding:'8px',
        '&:last-child' : {
            marginBottom: '0px'
        },
        "& .refreshIcon" : {
            visibility: 'hidden'
        },
        "&:hover .refreshIcon" : {
            visibility: 'visible'
        }

    }
    })
)

const OrganizationMembers = (props) => {
    const {showDrawer} = props;
    const classes = useStyles(showDrawer);
    const { enqueueSnackbar } = useSnackbar();
    const isMount = useIsMount();

    const [selectedRowId, setSelectedRowId] = useState(undefined);
    const [email, setEmail] = useState('');
    const [buttonLoading, setButtonLoading] = useState(false);

    const [pendingMembersList, setPendingMembersList] = useState([]);
    const [pendingMembersLoading, setPendingMembersLoading] = useState(false);
    const [tabValue, setTabValue] = useState(0);

    const roles = ["owner", "manager", "member", "guest"];
    const { access_level } = props.selectedOrganization || {};
    const accessLevelManager = shouldDisable(AccessLevel.getUserAccessLevelValue(access_level), AccessLevel.MANAGER);
    const loggedInUserAccessLevel = AccessLevel.getUserAccessLevelValue(access_level);

    const verifiedEmailArr = props.orgEmailList && props.orgEmailList.length > 0 ? props.orgEmailList.filter((email) => email.verified) : [];

    useEffect(() => {
        getOrganizationMemberList();
        document.title = `Members - ${props.fullName}`;
    }, []);

    useEffect(() => {
        if(isMount) {

        } else {
            if(tabValue) {
                getPendingInvitations();
            }
        }

    }, [tabValue])

    const getOrganizationMemberList = () => {
        const organization_id = props.selectedOrganization.organization.id;
        //get members list api on first render
        organizationMemberListApi(organization_id)
        .then((response) => {
            const res = response.data;
            consoleToLog("Response Org-MembersPage: organizationMemberListApi: ", res);

            //set members of selected oraganization in redux
            props.setOrgMembers(res.member_list);
        })
        .catch((e) => {
            consoleToLog("Response Org-MembersPage: organizationMemberListApi: ", e.response);
            if (e.response.data && e.response.data.message) {
                enqueueSnackbar(e.response.data.message, {
                    variant:'error'
                });
                return false;
            }
        });
    }

    const onChangeSelectedRoleType = (e) => {
        const value = e.target.value;
        consoleToLog('********value', value);
        setSelectedRowId(value);
    }

    // const checkIfUserRegisteredOnAnyApp = (email, role_id, organization_id) => {
    //     setButtonLoading(true);

    //     checkIfUserIsRegisteredApi(email)
    //     .then((response) => {
    //         const res = response.data;
    //         consoleToLog('Response checkIfUserIsRegisteredApi: ', res);

    //         if(res.email === email) {
    //             addMemberToOrg(email, role_id, organization_id, res);
    //         }

    //     })
    //     .catch((e) => { 
    //         consoleToLog('Error checkIfUserIsRegisteredApi: ', e.response);
    //         if(e.response.data && e.response.data.message) {
    //             //enqueueSnackbar(e.response.data.message, {variant:'error'});
    //             if(e.response.data.message === 'User not found' && e.response.status === 400) {
    //                 addMemberToOrg(email, role_id, organization_id, {});
    //             } 
    //         }
    //     })
    // }

    const onInviteMemberClick = () => {
        if (!email) {
            enqueueSnackbar('Email cannot be empty', {
                variant: 'error'
            });
            return;
        }
        const isEmailValid = emailValidator(email);
            if (!isEmailValid) {
            enqueueSnackbar('Email is not valid', {
                variant: 'error'
            });
                return;
        }

        const role_id = selectedRowId;
        consoleToLog("*************role_id", role_id);
        if(!role_id) {
            enqueueSnackbar('Please Select a Type', {
                variant: 'error'
            });
            return;
        }

        // if(props.orgEmailList && props.orgEmailList.length === 0) {
        //     enqueueSnackbar('No support email added. Please add support email to continue.', {variant:'error'});
        //     return;
        // } 

        // if(verifiedEmailArr && verifiedEmailArr.length === 0) {
        //     enqueueSnackbar('No support email verified. Please verify support emails to continue.', {variant:'error'});
        //     return;
        // } 

        const service = 'workplace';
        const organization_id = props.selectedOrganization.organization.id;
        const entity_name = props.selectedOrganization.organization.name;


        setButtonLoading(true);
        pendingInvitationExistApi(organization_id, email)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response pendingInvitationExistApi: ', res);

                if(res.message === 'No record in pending inviation') {
                    addMemberToOrg(service, email, role_id, organization_id, entity_name)
                }
            })
            .catch((e) => {
                consoleToLog("pendingInvitationExistApi error", e.response);
                setButtonLoading(false);
                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant:'error'
                    });
                    return false;
                }
            });
    }
    
    const addMemberToOrg = (service, email, role_id, organization_id, entity_name) => {


        addMemberToOrgApi(service, email, role_id, organization_id, entity_name)
        .then((response) => {
            const res = response.data;
            consoleToLog('Response addMemberToOrgApi: ', res);
            setButtonLoading(false);
            enqueueSnackbar(res.message, {
                variant: 'success'
            });

            //const is_shadow = res.member.user.is_shadow;
            trackGAEvent(props.selectedOrganization.organization.name, 'Invite Member', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
            
            // if(is_shadow) {//pending member
            //     let newPendingMember = pendingMembersList.concat(res.member);
            //         setPendingMembersList(newPendingMember);
            // } else {
            //     props.addOrgMember(res.member);
            // }
            setEmail('');
            setSelectedRowId(undefined);
            createPendingInvitation(organization_id, role_id, email)
        })
        .catch((e) => {
            consoleToLog("addMemberToOrgApi error", e);
            setButtonLoading(false);
            if (e.response.data && e.response.data.message) {
                enqueueSnackbar(e.response.data.message, {
                    variant:'error'
                });
                return false;
            }
        });
    }

    const createPendingInvitation = (organization_id, role_id, email) => {
            
        createPendingInvitationApi(organization_id, role_id, email)
            .then((response) => {
                    const res = response.data;
                    consoleToLog('Response createPendingInvitationApi', res);

            })
            .catch((e) => {
                consoleToLog("createPendingInvitationApi error", e.response);
                setButtonLoading(false);
                if (e.response.data && e.response.data.message) {
                    return false;
                }
            });
}

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    }

    const checkDisabled = (isCurrentlyLoggedIn) => {
        if(isCurrentlyLoggedIn) {
            return true;
        } else {
            return false;
        }

    }

    const onMemberTypeChanged = (e, organization_member_id) => {
        const changeTypeToRoleId = e.target.value;

        editOrgMemberApi(organization_member_id, changeTypeToRoleId)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response editOrgMemberApi: ", res);
                enqueueSnackbar(res.message, {
                    variant: 'success'
                });

                props.updateMemberRole(organization_member_id, changeTypeToRoleId);
                trackGAEvent(props.selectedOrganization.organization.name, 'Change Role', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
            })
            .catch((e) => {
                consoleToLog("Response editOrgMemberApi: ", e.response);
                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant:'error'
                    });
                    return false;
                }
            });
    }

    const deleteMemberFromOrg = (organization_member_id, member_user_id, fullName) => {
        //dont allow if owner deleting itself
        if (props.auth.user.id === member_user_id) {
            enqueueSnackbar('You can not delete yourself!', {
                variant:'error'
            });
            return;
        }

        if(window.confirm('Are you sure you want to remove member?')) {
            
            removeOrgMemberApi(organization_member_id) 
                .then((response) => {
                    const res = response.data;
                    consoleToLog("Response removeOrgMemberApi: ", res);
                    enqueueSnackbar(res.message, {
                        variant: 'success'
                    });
                            
                    trackGAEvent(props.selectedOrganization.organization.name, 'Delete Member', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
                    //remove from members-redux 
                    props.removeOrgMember(organization_member_id);
                        if(res.orphaned) {
                            removeRegisterScope(member_user_id)
                        }
                })
                .catch((e) => {
                    consoleToLog("Response removeOrgMemberApi: ", e.response);
                    if (e.response.data && e.response.data.message) {
                        enqueueSnackbar(e.response.data.message, {
                            variant:'error'
                        });
                        return false;
                    }
                });    
            }

    }

    const removeRegisterScope = (member_user_id) => {
        const service = 'workplace';
        const user_id = member_user_id

        removeScopeApi(service, user_id)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response removeScopeApi: ', res);
            })
            .catch((e) => {
                consoleToLog("Response removeScopeApi: ", e.response);
                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant:'error'
                    });
                    return false;
                }
            });   
    }

    const showMembersList = () => {
        return (
                <Grid item container>
                    <Paper className={classes.memberContainer}  elevation={0}>
                        <Grid item container direction="column">
                            {props.members.map((member) => {
                                let fullName = member.user.firstname + " " + member.user.lastname;
                                const isDisabled = checkDisabled(props.auth.user.id === member.user.id);
                                const memberAccessLevel = AccessLevel.getUserAccessLevelValue(member.access_level);
                                return <Grid item container 
                                            key={member.user.id} 
                                            className={classes.memberColumn}>

                                            <Grid item md={8} lg={8}>
                                                <Grid item container alignItems="center">
                                                    <Grid item>
                                                        <Avatar className={classes.avatarStyle}>
                                                        {member.user.firstname.charAt(0).toUpperCase() +
                                                            member.user.lastname.charAt(0).toUpperCase()}    
                                                        </Avatar>
                                                    </Grid>
                                                    <Grid item className={classes.memberText}>
                                                        <div style={{marginLeft: "16px"}}>
                                                            <Typography style={{fontSize:'16px', fontWeight:'600'}}>
                                                                {fullName}
                                                            </Typography>
                                                            <Typography variant="subtitle1" style={{color:'#555555'}}>
                                                                {member.user.email}
                                                            </Typography>
                                                        </div>
                                                    </Grid>
                                                </Grid>                                                
                                            </Grid>

                                            <Grid item lg={2}>
                                                <TextField fullWidth
                                                    margin="dense"
                                                    style={{transform:'translateY(-1.5px)'}}
                                                    variant="outlined"
                                                    select
                                                    disabled={isDisabled || accessLevelManager || (loggedInUserAccessLevel < memberAccessLevel)}
                                                    value={member.access_level}
                                                    onChange={(e) => onMemberTypeChanged(e, member.id)}
                                                >
                                                    {
                                                    roles && roles.map((role) => {
                                                    return  <MenuItem key={role} value={role}>
                                                                {capitalize(role)}
                                                            </MenuItem>
                                                    })
                                                    }
                                                </TextField>               
                                            </Grid> 

                                            {!accessLevelManager && !(loggedInUserAccessLevel < memberAccessLevel) &&
                                                <Grid item md={2} lg={2} style={{display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
                                                    <div className={classes.actionIconContainer}>
                                                        <Button className='more_actionButton1'  
                                                            onClick={() => deleteMemberFromOrg(member.id, member.user.id, fullName)}>
                                                            <i className={`flaticon-delete-1 ${classes.flatIcons}`}></i>
                                                            Delete
                                                        </Button> 
                                                    </div>
                                                </Grid>
                                            }

                                    </Grid>
                                })
                            }   
                        </Grid>
                    </Paper>          
                </Grid>
        )
    }

    const getPendingInvitations = () => {
        const organization_id = props.selectedOrganization.organization.id;
        setPendingMembersLoading(true);

        getPendingInvitationsListApi(organization_id)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response getPendingInvitationsListApi: ", res);
                setPendingMembersLoading(false);
                setPendingMembersList(res.pending_invitation);
            })
            .catch((e) => {
                setPendingMembersLoading(false);
                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant:'error'
                    });
                    return false;
                }
            });
    }

    const resendInvitation = (member) => {
        const service = 'workplace';
        const email = member.email;
        const role = member.role;
        const entity_id = props.selectedOrganization.organization.id;
        const entity_name = props.selectedOrganization.organization.name;
        const entity_email = verifiedEmailArr && verifiedEmailArr.length > 0 && verifiedEmailArr[0]?.email;
        

        resendInvitationApi(service, email, role, entity_id, entity_name, entity_email)
            .then((response) => {
                const res = response.data;
                enqueueSnackbar(res.message, {
                    variant: 'success'
                });
                consoleToLog("Response resendInvitationApi: ", res);
            })
            .catch((e) => {
                consoleToLog("Response resendInvitationApi error", e.response);
                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant:'error'
                    });
                    return false;
                }
            });

    }

    const showPendingMemberList = () => {
        return (
                <Grid item container >
                    <Paper className={classes.memberContainer}  elevation={0}>
                       { pendingMembersLoading ? <CircularProgress size={35} className={classes.pendingLoading}/> : 
                        <Grid item container direction="column">
                        {pendingMembersList && pendingMembersList.length > 0 ? pendingMembersList.map((member) => {
                                return <Grid item container 
                                            alignItems='center'
                                            key={member.id} 
                                            className={classes.memberColumn}>

                                           <Grid item md={8} lg={8}>
                                               <Grid item container alignItems="center">
                                                   <Grid item>
                                                        <Avatar className={classes.avatarStyle} style={{transform:'translateY(0px)'}}>
                                                        {member.email.charAt(0).toUpperCase()}    
                                                        </Avatar>
                                                    </Grid>
                                                    <Grid item> 
                                                        <div style={{marginLeft: "16px", marginTop:"-4px"}}>
                                                            <Typography style={{fontSize:"15px", fontWeight:"600"}}>
                                                                {member.email}
                                                            </Typography>
                                                        </div>
                                                    </Grid>
                                               </Grid>                                                
                                            </Grid>

                                            <Grid item lg={2}>
                                                 <TextField fullWidth
                                                    style={{transform:'translateY(-1.5px)'}}
                                                    margin="dense"
                                                    variant="outlined"
                                                    select
                                                    disabled={true}
                                                    value={member.role}
                                                    onChange={(e) => onMemberTypeChanged(e, member.id)}>
                                                     {
                                                        roles && roles.map((role) => {
                                                        return  <MenuItem key={role} value={role}>
                                                                    {capitalize(role)}
                                                                </MenuItem>
                                                        })
                                                    }
                                                </TextField>               
                                            </Grid> 
                                            
                                            <Grid item lg={2} style={{display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
                                                <div className={classes.actionIconContainer}>
                                                        <Button className='more_actionButton2'  
                                                            onClick={() => resendInvitation(member)}>
                                                            <i className={`flaticon-reload ${classes.flatIcons}`}></i>
                                                            Resend Invitation
                                                        </Button> 
                                                </div> 
                                            </Grid>      

                                    </Grid>
                                }) : <Grid item container justifyContent="center">
                                        <Typography variant="body1">No Pending Invitations</Typography>
                                    </Grid>
                            }   
                        </Grid>}
                    </Paper>          
                </Grid>
        )
    }

    return (
        <div className={classes.mainContent} 
            style={{height: props.height}}>
            <Container className={classes.container} maxWidth="xl">
                {!accessLevelManager &&
                <>
                <Typography style={{fontSize:'20px', fontWeight:'600'}} color="primary">
                    Invite Members
                </Typography>
                <Grid item container spacing={1} alignItems="center">
                    <Grid item lg={4}>
                        <TextField fullWidth
                            margin="dense"
                            variant="outlined"
                            value={email}
                            onFocus={email}
                            label="Enter Email Address"
                            type="text"
                            style={{background: '#fff'}}
                            InputLabelProps={{style: {fontSize: 14, paddingRight:'31px !important'}}}
                            onChange={e => setEmail(e.target.value)}
                        />
                    </Grid>
                    <Grid item lg={3}>
                        <TextField fullWidth
                            margin="dense"
                            variant="outlined"
                            select
                            label= "Select Type"
                            value={selectedRowId ? selectedRowId : ''}
                            style={{background: '#fff'}}
                            InputLabelProps={{style: {fontSize: 14, paddingRight:'31px !important'}}}
                            onChange={onChangeSelectedRoleType}
                        >
                                {
                                    roles && roles.map((role) => {
                                    return  <MenuItem key={role} value={role}>
                                                {capitalize(role)}
                                            </MenuItem>
                                    })
                                }
                        </TextField>    
                    </Grid>
                    <Grid item lg={1}>
                        <Button  variant="contained" 
                            style={{background: "#383838",
                                    width: "107px",
                                    padding: "7px 0",
                                    marginTop: "4px",
                                    color: "white"
                                }}
                            onClick={onInviteMemberClick}       
                        >
                            {buttonLoading && <CircularProgress size={24} className={classes.circularProgress}/>}
                            Invite
                        </Button>
                    </Grid>          
                </Grid>
                </>}
               

                <Grid item container direction="column" style={{marginTop:'30px'}}>
                    <Grid item container>
                        <Paper className={classes.tab} elevation={0}>
                            <Tabs
                                value={tabValue}
                                onChange={handleTabChange}
                                indicatorColor="primary"
                                textColor="primary"
                            >
                                <Tab label="Members" />
                                {!accessLevelManager &&
                                    <Tab label="Pending Invitation" />
                                }
                            </Tabs>
                        </Paper>
                    </Grid>

                    {!tabValue ? showMembersList() : showPendingMemberList()}
                 </Grid>


            </Container>    
        </div>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    members: state.organizationInfo.members,
    orgEmailList: state.organizationInfo.orgEmailList,
});

const mapDispatchToProps = (dispatch) => ({
    addOrgMember: (member) => dispatch(addOrgMember(member)),
    setOrgMembers: (members) => dispatch(setOrgMembers(members)),
    updateMemberRole: (id, access_level) => dispatch(updateMemberRole(id, access_level)),
    removeOrgMember: (id) => dispatch(removeOrgMember(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationMembers);