import moment from 'moment';



const defaultState = {
    startDateFilter: undefined,
    endDateFilter: undefined,

    selectedStatusFilter: ["pending", "in_process", "awaiting_reply", "pending_approval"],
    selectedMembersFilter: [],
    selectedLabelsFilter: [],
    companiesFilter: [],

    searchMemberFilter: '',
    searchLabelFilter: '',
    sortByFilter: '',
    searchChecklistFilter: undefined,

    showApprovedChecklist: false,
    showUnassignedChecklist: false,
    selectedBillStatusFilter: [],
}

function getDefaultStartDate() {
    return (moment().month() < 3) ? 
        moment().subtract(1, 'year').month('April').startOf('month').format('YYYY-MM-DD')
        : 
        moment().month('April').startOf('month').format('YYYY-MM-DD'); 
}

function getDefaultEndDate() {
   return (moment().month() < 3) ? 
        moment().month('March').endOf('month').format('YYYY-MM-DD')
        : 
        moment().add(1, 'year').month('March').endOf('month').format('YYYY-MM-DD');
}


export default (state = defaultState, action) => {
    switch(action.type) {
        
        case 'LOGOUT_FILTERS':
            return defaultState;

        case 'SET_START_DATE_FILTER' :
            return {
                ...state,
                startDateFilter : action.start_date
            }
        
        case 'SET_END_DATE_FILTER' :
            return {
                ...state,
                endDateFilter : action.end_date
            }  
            
        //status filters
        case 'ADD_SELECTED_STATUS_TO_FILTER':
            return {
                ...state,
                selectedStatusFilter: [
                    ...state.selectedStatusFilter,
                    action.status
                ]
            }
        
        case 'REMOVE_SELECTED_STATUS_FROM_FILTER':
            return {
                ...state,
                selectedStatusFilter: state.selectedStatusFilter.filter((s) => s !== action.status)
            }   

        //members filter
        case 'ADD_SELECTED_MEMBER_TO_FILTER':
            return {
                ...state,
                selectedMembersFilter: [
                    ...state.selectedMembersFilter,
                    action.member
                ]
            }

        case 'REMOVE_SELECTED_MEMBER_FROM_FILTER':
            return {
                ...state,
                selectedMembersFilter: state.selectedMembersFilter.filter((m) => m.id !== action.member.id)
        }    

        //labels filter
        case 'ADD_SELECTED_LABEL_TO_FILTER':
            return {
                ...state,
                selectedLabelsFilter: [
                    ...state.selectedLabelsFilter,
                    action.label
                ]
            }

        case 'REMOVE_SELECTED_LABEL_FROM_FILTER':
            return {
                ...state,
                selectedLabelsFilter: state.selectedLabelsFilter.filter((l) => l.id !== action.label.id)
            }

        //company filter
        case 'ADD_SELECTED_COMPANY_FILTER':
            return {
                ...state,
                companiesFilter: [
                    ...state.companiesFilter,
                    action.company
                ]
            }

        case 'DELETE_SELECTED_COMPANY_FILTER':
            return {
                ...state,
                companiesFilter: state.companiesFilter.filter((company) => company.id !== action.company.id)
            }    

        //clear filters
        case 'CLEAR_SELECTED_FILTERS' :
            return defaultState     

        //clear selected member
        case 'CLEAR_SELECTED_MEMBERS':
            return {
                ...state,
                selectedMembersFilter: []
            }

        /*** setShowUnassignedChecklist */
        case 'SET_SHOW_UNASSIGNED_CHECKLIST':
            return {
                ...state,
                showUnassignedChecklist: action.showUnassignedChecklist
            }

        //search member    
        case 'SET_SEARCH_MEMBER_FILTER':
            return {
                ...state,
                searchMemberFilter: action.name
            }

        //search label   
        case 'SET_SEARCH_LABEL_FILTER':
            return {
                ...state,
                searchLabelFilter: action.name
            } 

        case 'SET_SHOW_APPROVED_CHECKLIST':
            return {
                ...state,
                showApprovedChecklist: action.showApprovedChecklist
            }

        //search checklist
        case 'SET_SEARCH_CHECKLIST_FILTER':
            return {
                ...state,
                searchChecklistFilter: action.search_text
            }    

        case 'SET_SORTBY':
            return {
                ...state,
                sortByFilter: action.sortBy
            }    

            //billable status filters
            case 'ADD_SELECTED_BILLABLE_STATUS_TO_FILTER':
                return {
                    ...state,
                    selectedBillStatusFilter: [
                        ...state.selectedBillStatusFilter,
                        action.status
                    ]
                }

            case 'REMOVE_SELECTED_BILLABLE_STATUS_FROM_FILTER':
                return {
                    ...state,
                    selectedBillStatusFilter: state.selectedBillStatusFilter.filter((s) => s !== action.status)
                }   

            case 'CLEAR_BILLABLE_STATUS_FILTER' : 
                return {
                    ...state,
                    selectedBillStatusFilter: []
                }

        default:
            return state;
    }
}