import React, {useState, useEffect} from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {Dialog, Slide, Grid, Button, Typography, AppBar, Tabs, Tab, Badge, Popover, CircularProgress, ListItem} from "@material-ui/core";
import { connect } from "react-redux";
import { consoleToLog, trackGAEvent, getStatusColor, getLabelsFromChecklistItem, checkAllAppoverApprovedChecklist,
    shouldDisable, AccessLevel, getAssigneesOfApprover} from "../../../util/AppUtil";
import moment from "moment";
import SwipeableViews from "react-swipeable-views";
import { history } from "../../routers/MobileAppRouter";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MobileSelectFromApproversList from '../../components/checklist/MobileSelectFromApproversList';
import {modifyApproversFromChecklistApi, getTaskList, modifyLabelsFromChecklistApi, completeChecklistApi} from '../../../services/authService';
import { useSnackbar } from 'notistack';
import { useIsMount } from '../../../components/useIsMount';
import MobileTaskContent from '../../components/checklist_details/MobileTaskContent';
import {useLocation} from 'react-router-dom';
import MobileChecklistAttachment from '../../components/checklist_details/MobileChecklistAttachment';
import MobileSelectFromLabelList from '../../components/checklist/MobileSelectFromLabelList';
import MobileChecklistActivities from '../../components/checklist_details/MobileChecklistActivities';
import MobileChecklistNotes from '../../components/checklist_details/MobileChecklistNotes';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import MobileSetDueDateDrawer from '../../components/MobileSetDueDateDrawer';
import Confetti from "react-confetti";
import MobileAuditTrailComponent from '../../components/checklist_details/MobileAuditTrailComponent';
import MobileChecklistClientDetails from '../../components/MobileChecklistClientDetails';

const useStyles = makeStyles((theme) => ({
    detailsBackground: {
        background: theme.palette.primary.light,
        overflowY:'auto',
        overflowX:'hidden'
    },
    detailsTopBarStatus: {
        padding:'4px 4px',
        textAlign: 'center',
        fontSize:'13px'
    },
    backIcon: {
        color: "#202020",
        border: " 1px solid #202020",
        padding: "4px 0px",
        fontSize: "18px",
        minWidth: "40px",
        lineHeight: "18px",
        marginTop: "15px",
        marginBottom: "10px",
        marginLeft: "15px",
    },
    detailsTopBarTitle: {
        marginTop:'10px',
        fontSize:'16px',
        fontWeight:'500'
    },
    detailsTopBarOptionIcon: {
        marginTop:'6px'
    },
    detailsTopBarDueDate: {
        marginLeft: "4px",
        color: "#888888",
        fontSize: "12.6px",
        // marginTop: "0.6px",
    },
    detailsMain: {
        paddingTop: "10px",
        paddingBottom: "10px",
        backgroundColor: "#f2f6ff",
    },
    detailBadge: {
        backgroundColor: "#cccccc",
        fontSize: "12px",
        borderRadius: "3.5px",
        padding: "0px 6px",
        fontWeight: 500,
        color: "black",
        marginBottom: "3px",
        marginLeft: "5px",
    },
    tabsAppbar: {
        boxShadow: "none",
        backgroundColor: "transparent",
        marginTop:'8px',
        //marginLeft:'16px'
    },
    tabsMain: {
        minWidth: "100px",
        color: "#5e6265",
    },
    listContainer: {
        display:'flex',
        alignItems:'center'
    },
    popoverIcons: {
        marginRight:'7px',
        marginTop:'1px'
    },
    labels : {
        display:'flex',
        marginTop:'3px',
        '& .labellist:last-child' : {
            '& .bullet' : {
            display: 'none'
            }
        }
    },
    checklistStatus: {
        backgroundColor: "#72BB53",
        marginTop: "-15.7px",
        marginBottom: "10px",
        padding: "2px",
        fontWeight: "600",
        color: "white",
    },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const MobileChecklistDetailsModal = (props) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    const isMount = useIsMount();
    const location  = useLocation();

    const [value, setValue] = useState(props.tabValue);
    const [itemDetails, setItemDetails] = useState([]);
    const checklistItem = props?.currentChecklistItem;

    const [companyData, setCompanyData] = useState(props.currentchecklistItem ? props.currentchecklistItem : itemDetails);
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentActivity,setCurrentActivity] = useState(undefined);
    const [confetti, setConfetti] = useState({ run: false, recycle: false });
    const [openClientDetailsDrawer, setOpenClientDetailsDrawer] = useState(false);

    const activeSchedulerCount = itemDetails?.active_scheduler_count;
    const totalSchedulerCount = itemDetails?.total_scheduler_count;
    const activityObj = location.activityObj ? location.activityObj : location.notificationObj;

    const handleOpenPopover = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const [openDrawer, setOpenDrawer] = useState(false);
    const [openDueDateDrawer, setOpenDueDateDrawer] = useState(false);

    const handleDrawerOpen = () => {
        setOpenDrawer(true);
        handleClosePopover();
    }
    
    const handleDrawerClose = () => {
        setOpenDrawer(false);
    }

    const handleDueDateDrawerOpen = () => {
        setOpenDueDateDrawer(true);
        handleClosePopover();
    }
    
    const handleDueDateDrawerClose = () => {
        setOpenDueDateDrawer(false);
    }

    const handleClientDetailsDrawerOpen = () => {
        setOpenClientDetailsDrawer(true);
        handleClosePopover();
    }

    const handleClientDetailsDrawerClose = () => {
        setOpenClientDetailsDrawer(false);
    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined; 

    const handleClosePopover = () => {
        setAnchorEl(null);
    }

    useEffect(() => {
        callGetTaskList();
    }, []);

    useEffect(() => {
        if(!isMount) {
            callGetTaskList(itemDetails);
        }
    }, [props.checkList]);

    useEffect(() => {
        if(!isMount) {
            setCompanyData(itemDetails);
        }
    }, [itemDetails]);

    useEffect(() => {
        // to handle activity click from activity tab
        if(currentActivity) {
            switch(currentActivity.activity_type) {
                case 'upload_attachment':
                setValue(1);
                break;

                case 'notes_on_checklist':
                setValue(4);
                break;
                
                default:
                setValue(0);
        }
        }
    }, [currentActivity]);

    useEffect(() => {
        if(activityObj) {
            if(activityObj.activity_type === 'upload_attachment') {
                setValue(1);
            }

            if(activityObj.activity_type === 'scheduler_enabled' || 
                activityObj.activity_type === 'scheduler_disabled' ||
                activityObj.activity_type === "scheduler_disabled_by_rule" || 
                activityObj.activity_type === "scheduler_enabled_by_rule") {
                setValue(3);
            }

            if(activityObj.activity_type === 'notes_on_checklist' || activityObj.action_type === 'notes') {
                setValue(4);
            }
    }

    }, [activityObj]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    const TabsWithBadage = (props) => {
        return (
            <span>
            {props.labelname}
            <Badge color="primary" className={classes.detailBadge}>
                {props.count}
            </Badge>
            </span>
        );
    };

    const a11yProps = (index) => {
        return {
            id: `full-width-tab-${index}`,
            "aria-controls": `full-width-tabpanel-${index}`,
        };
    };

    const onChecklistActivityClick = (currentActivity) =>{
        // to handle activity click from activity tab
        setCurrentActivity(currentActivity);
    }

    const callGetTaskList = () => {
        const checklistItemSlug = props.slug;

        getTaskList(checklistItemSlug)
            .then((response) => {
                const res = response.data;
                consoleToLog("getTaskList res", res);
                setItemDetails(res);
            // setGetWigets(true);
            })
            .catch((error) => {
                consoleToLog("getTaskList error", error);
        });
    }

    const setAssignedAproverList = (assignedApproverList, checklist_id) => {
        var approvers = assignedApproverList.map((member) => member.user.id).join();
        var approver_names = assignedApproverList.map((member) => member.user.firstname + ' ' + member.user.lastname).join();

        //Modify Approvers From Checklist Api
        modifyApproversFromChecklistApi(checklist_id, approvers)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response modifyApprovers FromChecklistApi: ", res);

                //trackGAEvent(props.selectedOrganization.organization.name, 'Checklist Add Approver', `${props.auth.user.firstname} ${props.auth.user.lastname} added approver ${approver_names}`);
                //change local state
                const newCheckListArr = props.checkList.map((checklistItem) => {
                    if (checklistItem.id === checklist_id) {
                        checklistItem.checklist_approvers_list = res.approvers;
                        return checklistItem;
                    }
                    return checklistItem;
                });
                props.setCheckList(newCheckListArr);
            })
            .catch((e) => {
                consoleToLog("modifyApproversFromChecklistApi error", e.response);
                props.setCheckList(props.checkList);

                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant:'error'
                    });
                    return false;
                }
            });
    }

    /**Modify Lables */
    const setAssignedLabelList = (assignedLabelList, checklist_id) => {
        const labels = assignedLabelList.map((label) => label.id).join();
        const labels_names = assignedLabelList.map((label) => label.name).join();

        //Modify Labels From Checklist Api
        modifyLabelsFromChecklistApi(checklist_id, labels)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response modifyLabelsFromChecklistApi: ", res);
                enqueueSnackbar(res.message, {variant:'success'})
                //trackGAEvent(props.selectedOrganization.organization.name, 'Checklist Add Label', `${props.auth.user.firstname} ${props.auth.user.lastname} added label ${labels_names}`);

                const newCheckListArr = props.checkList.map((checklistItem) => {
                    if (checklistItem.id === checklist_id) {
                        checklistItem.checklist_labels_list = res.labels;
                        return checklistItem;
                    }
                    return checklistItem;
                });
                props.setCheckList(newCheckListArr);
            })
            .catch((e) => {
                consoleToLog(" modifyLabelsFromChecklistApi error", e.response);

                props.setCheckList(props.checkList)
                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant:'error'
                    });
                    return false;
                }
            });
    }

    const MoreActionsPopover = () => {
        const approvers_list = companyData?.checklist_approvers_list;
        return (
            <Popover 
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClosePopover}
                anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
                }}
                transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
                }}
                style={{
                    marginTop:'48px'
                }}
                >
                {!props.disabledAssignChecklistLabels && (
                    <ListItem className={classes.listContainer} 
                        onClick={handleDrawerOpen}>
                        <i className={`flaticon-price-tag ${classes.popoverIcons}`}></i>
                        Add Label
                    </ListItem>
                )}

                <ListItem className={classes.listContainer} 
                    onClick={handleDueDateDrawerOpen}>
                    <i className={`flaticon-calendar-1 ${classes.popoverIcons}`}></i>
                    <span>Set Due Date</span>
                </ListItem>

                {checkAllAppoverApprovedChecklist(approvers_list && approvers_list.length > 0 ? approvers_list : []) 
                && companyData?.status !== 'Completed' && 
                <ListItem className={classes.listContainer}
                    onClick={callCompleteChecklistApi}>  
                    <i className={`flaticon-check ${classes.popoverIcons}`}></i>
                    <span>Complete Checklist</span>
                </ListItem>}

                <ListItem className={classes.listContainer}
                    onClick={handleClientDetailsDrawerOpen}>
                    <i className={`flaticon-folder ${classes.popoverIcons}`}></i>    
                    <span>Client Details</span>
                </ListItem>
            </Popover>
        )
    }

    const checklistLabelList = (checklistItem) => {
        console.log('checklistItem', checklistItem);
        const labels = checklistItem.checklist_labels_list.slice(2);
        const titles = labels.map((l) => l.name).join(', ');
        return <Typography variant='subtitle1'  style={{
                    marginLeft:'12px',
                    color:'#333',
                    textDecoration:'underline',
                    marginTop:'-1px',
                    cursor:'pointer'
                    }}>+{labels.length} more
                </Typography>
    }

    const checkListDetailsTopBar = () => {
        //  var companyData = currentItem ? currentItem : itemDetails;
        return (
            <>
                <Grid item className={classes.detailsTopBarStatus} 
                    style={{background:getStatusColor(companyData.status)}}>
                    {companyData.status}
                </Grid>

                <Grid item container alignItems='flex-start'>
                    <Grid item xs={1} style={{flexBasis:'14%', maxWidth:'14%'}}>
                        <Button
                            variant="outlined"
                            className={classes.backIcon}
                            onClick={props.handleDetailsDialogClose}> 
                            <ArrowBackIcon /> 
                        </Button>
                    </Grid>

                    <Grid item xs={8} style={{marginLeft:'16px'}}>
                        <Typography variant='body1' className={classes.detailsTopBarTitle}>
                            {companyData.title}
                        </Typography>

                        <Grid item container alignItems='center'>
                            <Typography variant='subtitle1' style={{color:'#555555'}}>
                                {companyData.subtitle} 
                            </Typography>

                            {
                                companyData.due_date && companyData.due_date.length > 0 &&
                                <Button
                                    className={classes.checklistDateBtn}
                                    onClick={handleDueDateDrawerOpen}
                                    style={{color: props.isDueDateOverDue && props.isDueDateOverDue(companyData) && "red"}}>
                                        <i className="flaticon-calendar-1"
                                            style={{ lineHeight: "20px", height: "19px", marginLeft:'4px' }}></i>
                                        
                                        <Typography
                                            variant="subtitle1"
                                            className={classes.detailsTopBarDueDate}
                                            style={{color: props.isDueDateOverDue && props.isDueDateOverDue(companyData) && "red"}}
                                        >
                                            {`${moment(companyData.due_date).format(
                                            "Do MMM"
                                            )}`}
                                    </Typography>
                                </Button>
                            }
                        </Grid>

                        <Grid item style={{marginBottom:'8px'}}>
                        {(companyData.checklist_labels_list && companyData.checklist_labels_list.length > 0) &&
                            <div style={{display:'flex'}}>
                                <div className={classes.labels}>
                                    {companyData.checklist_labels_list.slice(0,2).map((label) => {
                                        return <div key={label.id} style={{display:'flex', alignItems:'flex-start'}} className='labellist'>
                                                    <Typography
                                                        //onClick={() => onLabelsClick(label)}
                                                        style={{color: `${label.color}`,
                                                        paddingLeft:'3px',
                                                        cursor:'pointer',
                                                        paddingTop:'0',
                                                        maxWidth:'180px',
                                                        overflow:'hidden',
                                                        textOverflow:'ellipsis',
                                                        whiteSpace:'nowrap',
                                                        fontSize:'12px'}}>{label.name}</Typography>
                                                    <FiberManualRecordIcon fontSize="small"
                                                        className="bullet"
                                                        style={{width:'6px', height:'6px', color:"#ccc", paddingLeft:'3px', paddingTop:'0', marginTop:'7px'}}/>
                                                </div>
                                            })
                                    }
                                </div>
                                <div>
                                {(companyData.checklist_labels_list && companyData.checklist_labels_list.length > 2) &&
                                    checklistLabelList(companyData) }
                                </div>
                            </div>
                        }
                        </Grid>

                        <Grid item>
                            <Typography variant="body1" style={{fontWeight:'500'}}>
                                Approvers
                            </Typography>
                            <MobileSelectFromApproversList assignees={getAssigneesOfApprover(companyData)} 
                                setAssignedApproverList={(selectedAssignees) => setAssignedAproverList(selectedAssignees, companyData.id)}
                                disabledAssignChecklistApprovers={props.accessLevelManager}/>
                        </Grid>
                    </Grid>

                    <Grid item xs={1} className={classes.detailsTopBarOptionIcon}>
                        <Button onClick={handleOpenPopover}>
                            <MoreVertIcon /> 
                        </Button>
                        {MoreActionsPopover()}
                    </Grid>
                </Grid>
            </>

        );
    };

    const onTaskStatusChanged = (activityObj ) => {
        setItemDetails(activityObj.checklist);
    
        // to parent
        props.onTaskStatusChanged && props.onTaskStatusChanged(itemDetails.id,activityObj.checklist)
    };

    const onAttachmentDelete = (updatedDeleteAttachmentArray) =>{
        setItemDetails({ ...itemDetails, attachments: updatedDeleteAttachmentArray });
        // to parent
        props.onAttachmentDelete && props.onAttachmentDelete(updatedDeleteAttachmentArray);
    }

    const onAttachmentAdded = (updatedAttachmentsArray) => {
        setItemDetails({ ...itemDetails, attachments: updatedAttachmentsArray });
         // to parent
        props.onAttachmentAdded && props.onAttachmentAdded(updatedAttachmentsArray);
    }

    const updateSchedulerCounts = (checklistItem) => {
        setItemDetails({ ...itemDetails, 
            active_scheduler_count: checklistItem.active_scheduler_count, 
            total_scheduler_count: checklistItem.total_scheduler_count
        });
    }

    const onUpdateSchedulerChecklistItemStatus = (checklist_id,checklistObj) =>{
        // var updatedChecklist = {...itemDetails, status: checklistObj.status}
        setItemDetails(checklistObj);
    
        // to parent
        if(!props.disableCallbacks) { props.onUpdateSchedulerChecklistItemStatus(checklist_id,checklistObj);}
    
    }

    const onNoteAdded = (newarray) =>{
        setItemDetails({ ...itemDetails, notes: newarray });
        // to parent
        props.onNoteAdded && props.onNoteAdded(newarray,itemDetails.id);
    
    }

    const onNoteDeleted = (newarray) =>{
        setItemDetails({ ...itemDetails, notes: newarray });
        // to parent
        props.onNoteDeleted && props.onNoteDeleted(newarray,itemDetails.id);
    }

    const callCompleteChecklistApi = () => {
        handleClosePopover();
        if (window.confirm("Checklist will be completed as all tasks are completed. You cannot undo the completed checklist. Would you like to continue to complete the checklist?")) {
        const checklist_id = itemDetails.id;
        const start_date = props.startDateFilter;
        const end_date = props.endDateFilter;
        const user_ids = props.selectedMembersFilter && props.selectedMembersFilter.length > 0 ? props.selectedMembersFilter.map((member) => member.user.id).join() : '';
        const company_ids = props.companiesFilter && props.companiesFilter.length > 0 ? props.companiesFilter.map((company) => company.id).join() : '';

        completeChecklistApi(checklist_id, start_date, end_date, user_ids, company_ids)
        .then((response) => {
            const res = response.data;
            enqueueSnackbar(res.message, {variant: "success"});

            setConfetti({ run: true, confetti: true });
            setItemDetails({ ...itemDetails, status: "Completed" });

            //to parent
                trackGAEvent(props.selectedOrganization.organization.name, 'Checklist Complete from Detail', `${props.auth.user.firstname} ${props.auth.user.lastname} completed Checklist from Detail`);
                setTimeout(() => {
                setConfetti({ run: false, confetti: false });
            }, 3000);
        })
        .catch((e) => {
            consoleToLog("completeChecklistApi error");
            if(e.response.data && e.response.data.message) {
                enqueueSnackbar(e.response.data.message, {variant: "error"});
            }
        });
    }
    };
        
    // Checking the length of itemDetails object
    const itemDetailsObjectLength = Object.keys(itemDetails).length;
    // Assigning a avr to props.currentchecklistItem
    var currentItem = props.currentchecklistItem;

    return (
            <Dialog fullScreen
                open={props.openDialog}
                onClose={props.handleDetailsDialogClose}
                TransitionComponent={Transition}
                classes={{
                    paper: classes.detailsBackground
                }}
            >
                {currentItem || itemDetails ? (
                    <Grid item>
                        {checkListDetailsTopBar()}
                    </Grid>
                    ):(
                    <Grid container alignItems="center" justifyContent="center">
                        <Grid
                        item
                        sm={12}
                        container
                        alignItems="center"
                        justifyContent="center"
                        >
                        <CircularProgress siz={25} color="secondary" xs={12} sm={12} />
                        </Grid>
                    </Grid>
                    )
                }

                
                {itemDetailsObjectLength === 0 ? (
                    <Grid container alignItems="center" justifyContent="center">
                        <Grid
                        item
                        sm={12}
                        container
                        alignItems="center"
                        justifyContent="center"
                        >
                        <CircularProgress size={25} color="secondary" xs={12} sm={12} />
                        </Grid>
                    </Grid>
                    ) : (
                        <div>
                            <AppBar
                            position="static"
                            color="default"
                            className={classes.tabsAppbar}
                            >
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                indicatorColor="secondary"
                                textColor="secondary"
                                aria-label="full width tabs example"
                                variant="scrollable"
                            >
                                <Tab
                                label="Tasks"
                                className={classes.tabsMain}
                                {...a11yProps(1)}
                                />
                                <Tab
                                label={
                                    <TabsWithBadage
                                    count={itemDetails ? itemDetails.attachments.length: ""}
                                    labelname="Attachments"
                                    />
                                }                    
                                className={classes.tabsMain}
                                {...a11yProps(1)}
                                />
                                <Tab
                                label="Activity"
                                className={classes.tabsMain}
                                {...a11yProps(2)}
                                />
                                <Tab
                                label={<TabsWithBadage 
                                count={itemDetails ? itemDetails.notes.length: ""}
                                labelname="Notes" />}
                                className={classes.tabsMain}
                                {...a11yProps(3)}
                                />
                                <Tab
                                label={
                                    <TabsWithBadage labelname="Audit Trail" />
                                }
                                className={classes.tabsMain}
                                {...a11yProps(4)}
                                />
                            </Tabs>
                            </AppBar>
                            <div style={{ display: value === 0? 'block': 'none', marginTop:'16px', padding: "0px 16px 0px 16px"}}>
                            {confetti.run && (
                                    <div>
                                        <Grid item container className={classes.checklistStatus}>
                                            <Grid item lg={12} style={{ textAlign: "center" }}>
                                                <Typography variant="body1">Completed</Typography>
                                            </Grid>
                                        </Grid>
                                        <Confetti
                                            width={window.innerWidth}
                                            height={580}
                                            run={confetti.run}
                                            recycle={confetti.recycle}
                                            gravity={0.3}
                                            numberOfPieces={400}
                                        />
                                    </div>
                                    )
                            }
                                {itemDetails.checklist_task 
                                && 
                                <MobileTaskContent
                                    taskList={itemDetails.checklist_task}
                                    checklistId={itemDetails.id}
                                    checkList={itemDetails}
                                    onTaskStatusChanged={onTaskStatusChanged}
                                    activityObj={activityObj}
                                    // getWidgets={getWidgets}
                                    // getActivities={getActivities}
                                    //selectedTaskMain={selectedTaskMain}
                                    //onWidgetAttachmentAdded = {onWidgetAttachmentAdded}
                                />}
                            </div>
                            <div style={{ display: value === 1? 'block': 'none'}}>
                                <MobileChecklistAttachment
                                    attachmentList = {itemDetails.attachments}
                                    checklistId = {itemDetails.id}
                                    checkListItem = {itemDetails}
                                    onAttachmentDelete={onAttachmentDelete}
                                    onAttachmentAdded={onAttachmentAdded}
                                />
                            </div>

                            <div style={{display: value === 2 ? 'block' : 'none'}}>
                                <MobileChecklistActivities checklistSlug = {props.slug} 
                                    isFromCheckListItemPage = {true} 
                                    onChecklistActivityClick = {onChecklistActivityClick}/>

                            </div>

                            <div style={{display: value === 3 ? 'block' : 'none'}}>
                                <MobileChecklistNotes checkListItem = {itemDetails}
                                    onNoteAdded = {onNoteAdded}
                                    onNoteDeleted = {onNoteDeleted}/>
                            </div>

                            <div style={{display: value === 4 ? 'block' : 'none'}}>
                                <MobileAuditTrailComponent checkListItem = {itemDetails}/>
                            </div>
                        </div>
                    )
                }

                    <MobileSelectFromLabelList assignees={getLabelsFromChecklistItem(companyData)} 
                        disabledAssignChecklistLabels={props.accessLevelMember}
                        setAssignedLabelList = {
                            (selectedLabels) => setAssignedLabelList(selectedLabels, companyData.id)
                        }
                        handleClosePopover={handleClosePopover}
                        handleDrawerClose={handleDrawerClose}
                        openDrawer={openDrawer}
                    />

                    <MobileSetDueDateDrawer openDueDateDrawer={openDueDateDrawer}
                        handleDueDateDrawerClose={handleDueDateDrawerClose}
                        checkList={props.checkList}
                        checklistDetails={itemDetails}
                        setCheckList={props.setCheckList}
                        />

                    {openClientDetailsDrawer && 
                    <MobileChecklistClientDetails checklistDetails={itemDetails} 
                        openClientDetailsDrawer={openClientDetailsDrawer}
                        handleClientDetailsDrawerClose={handleClientDetailsDrawerClose}/>}
            </Dialog>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    startDateFilter: state.filters.startDateFilter,
    endDateFilter: state.filters.endDateFilter,
    overlay: state.overlay,
    selectedMembersFilter: state.filters.selectedMembersFilter,
    companiesFilter: state.filters.companiesFilter
});

export default connect(mapStateToProps, null)(MobileChecklistDetailsModal);