import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Container, Typography} from '@material-ui/core';
import BaseComponent from './Base/BaseComponent';

const useStyles = makeStyles((theme) => ({
    mainContent : {
        width: '100%', 
        backgroundColor: theme.palette.primary.light, 
        transition: ".5s", 
        flexGrow: 1,
        //overflow:'hidden',
        height:'calc(100vh - 48px)',
        overflow:'auto',
        overflowX:'hidden'
        },

    }
));    


const NotFoundPage = (props) => {
    const classes = useStyles()
    return (
        <BaseComponent notFoundPage={true}>
            <div className={classes.mainContent}>
                <Grid item container 
                    justifyContent='center'
                    style={{height:'100%'}}
                    alignItems='center'>
                        <img src="/images/404-not-found.png" />
                </Grid> 
            </div>
        </BaseComponent>
    );
}

export default NotFoundPage;