import {getAxios} from './axiosUtil';

export const getCompletionReportApi = (ids, startDateFilter, endDateFilter, organization_id) => {
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/${organization_id}/completion_report?start_date=${startDateFilter}&end_date=${endDateFilter}&template_ids=${ids}`);
}

export const getStatusWorkLoadReport = (ids, startDateFilter, endDateFilter, organization_id) => {
    const str = (ids === -1) ? "" : `template_ids=${ids}`;

    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/${organization_id}/status_workload_report?start_date=${startDateFilter}&end_date=${endDateFilter}&${str}`);
}

export const getPendingWorkLoadReport = (ids, startDateFilter, endDateFilter, organization_id) => {
    const str = (ids === -1) ? "" : `template_ids=${ids}`;
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/${organization_id}/pending_workload_report?start_date=${startDateFilter}&end_date=${endDateFilter}&${str}`);
}

export const getEmailReportApi = (organization_id, nextPage, start_date, end_date, company_ids) => {
    if (company_ids !== undefined) company_ids = `&company_ids=${company_ids}`
    else company_ids="";
    
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/email_tracking/organization/${organization_id}?page=${nextPage}&start_date=${start_date}&end_date=${end_date}${company_ids}`);
}

export const getCompleteIncompleteChecklistApi = (organization_id, page, start_date, end_date, status, template_id, schedule_type) => {
    const param = {
        params: {
            page, 
            start_date, 
            end_date, 
            status, 
            template_id, 
            schedule_type
        }
    }

    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/${organization_id}/completion_chart_report_checklists`, param);

}

export const getUserPendingChecklistApi = (organization_id, page, start_date, end_date, user_id, template_id) => {
    const param = {
        params: {
            page, 
            start_date, 
            end_date, 
            user_id, 
            template_id, 
        }
    }

    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/${organization_id}/user_chart_report_checklists`, param);

}   

export const getEmailFollowupReportApi = (organization_id, page, status, clients) => {
    const param = {
        params: {
            page,
            status,
            clients
        }
    }
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/organization/${organization_id}/email_followup`, param);
}

export const getClientWorkReportApi = (organization_id, client_ids) => {
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/${organization_id}/client_work_report?client_ids=${client_ids}`);
}

export const getChecklistForClientWorkReport=(organization_id,client_ids,page,status,template_id)=>{
    const param = {
        params: {
            page,
            status,
            template_id
        }
    }
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/${organization_id}/${client_ids}/client_work_report_checklists`, param);
}
