import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Container, Button, Typography, Tooltip} from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import {connect} from 'react-redux';
import CreateEditLabelModal from './modals/CreateEditLabelModal';
import {deleteOrgLabelApi} from '../services/authService';
import {deleteOrgLabel} from '../actions/selectedOrganization';
import { consoleToLog, trackGAEvent } from '../util/AppUtil';
import { useSnackbar } from 'notistack';
import { useIsMount } from './useIsMount';

const useStyles = makeStyles((theme) => ({
    mainContent : {
        width: '100%',
        backgroundColor: '#fff', 
        transition: ".5s", 
        flexGrow: 1,
        //overflow:'hidden',
        overflow:'auto',
        overflowX:'hidden'
        },
        
    container: {
        marginTop: "8px"
        },

    MuiButtonlabel : {
        fontSize: "12px"
    },

    buttonPlusIcon: {
        marginLeft:'8px', 
        fontSize:'1.2rem', 
        alignItems:'center'
    },

    labelContainer : {
        marginTop: '30px',
        marginBottom:'32px',
    },

    labelList : {
        background: '#f7f7f7',
        padding:'4px 10px',
        marginBottom:'8px',
        borderRadius:'4px',
        '& .onlabel__hover' : {
            visibility: 'hidden'
        },
        '&:hover .onlabel__hover' : {
            visibility: 'visible'
        },
        '& .more_actionButton1' : {
            visibility:'hidden',
            width:'90px !important',
            background:'#f7f7f7',
            border:'1px solid #ccc',
            borderRadius:'5px',
            fontSize:'13px',
            padding:'1px 0px',
            margin:'6px 0px'
        },
        '&:hover .more_actionButton1' : {
            visibility:'visible'
        },
    },

    actionIconContainer : {
        display:'flex',
        flexGrow:1,
        flexFlow:'row-reverse',
        justifyContent:'flex-start',
    },

    actionIcons : {
        minWidth:'20px',
        justifyContent:'flex-end !important',
        verticalAlign: 'end !important'
    },

    flatIcons : {
        fontSize:'15px', 
        marginRight:'6px',
        display:'flex',
        alignItems:'center'
    },
    customColumn : {
        flexBasis:'83%',
        maxWidth:'83%'
    }

    }
));    


const OrganizationLabels = (props) => {
    const {showDrawer} = props;
    const classes = useStyles(showDrawer);
    const {enqueueSnackbar} = useSnackbar();
    const {isMount} = useIsMount();

    const [openDrawer, setOpenDrawer] = useState(false);
    const [editLabel, setEditLabel] = useState(undefined);

    useEffect(() => {
        if(!isMount) {
            if(props.openLabelDrawer) {
                handleDrawerOpen();
            }
        } 
    }, [props.openLabelDrawer])

    const handleDrawerOpen = () => {
        setOpenDrawer(true);
    }

    const handleDrawerClose = () => {
        setOpenDrawer(false);
        setEditLabel(undefined);
        props.openLabelDrawer && props.setOpenLabelDrawer(false);
    }

    const onEditLabelClicked = (label) => {
        setEditLabel(label);
    }

    const onDeleteLabelClick = (e, labelId, labelName) => {
        //e.preventDefault();
        const organization_id = props.selectedOrganization.organization.id;
        const label_id = labelId;
        if(window.confirm("Are you sure you want to delete this label?")) {
            deleteOrgLabelApi(label_id, organization_id)
                .then((response) => {
                    const res = response.data;
                    consoleToLog('Response deleteOrgLabelApi', res);
                    enqueueSnackbar(res.message, {
                        variant: 'success'
                    });
                    props.deleteOrgLabel(label_id);

                    trackGAEvent(props.selectedOrganization.organization.name, 'Delete Label', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
                })
                .catch((e) => {
                    consoleToLog('Error deleteOrgLabelApi', e.response);
                    if (e.response.data && e.response.data.message) {
                        enqueueSnackbar(e.response.data.message, {
                            variant: 'error'
                        });
                        return false;
                    }
                });
            }   
    }

    return (
        <div className={classes.mainContent}
            style={{height: props.height}}>
            <Container className={classes.container} maxWidth="xl">
                {/* <Grid item container alignItems="center">
                    <Grid item>
                        <Typography style={{fontSize:'20px', fontWeight:'600'}} color="primary">
                            Labels
                        </Typography>
                    </Grid>
                    <Grid item style={{marginLeft:'18px'}}>
                        <Button variant="contained" color="primary" 
                            // onClick={() => {
                            //     toggleDrawer();
                            //     setCreateLabel(!createLabel);
                            // }}
                            onClick={handleDrawerOpen}
                            classes={{
                                label: classes.MuiButtonlabel
                            }}
                            style={{justifyContent:'center', padding:'8px'}}>
                            Add Label
                            <AddCircleOutlineIcon className={classes.buttonPlusIcon}/>
                        </Button>
                    </Grid>
                </Grid> */}

                <Grid item container className={classes.labelContainer}>
                    {
                        props.auth && props.labels && props.labels.length > 0 ? 
                        props.labels.map((label) => {
                            return <Grid item container className={classes.labelList}
                                        key={label.id}
                                        justifyContent="space-between"
                                        alignItems="center">
                                        <Grid item md={10} lg={10}
                                            className={classes.customColumn}
                                            style={{background: `${label.color}`,
                                                    borderRadius:'4px',
                                                    padding:'5px' }}
                                        >
                                            <Typography variant="body2" 
                                                style={{color: 'white',
                                                        position:'relative',
                                                        top:'1px',
                                                        padding: '0 10px'}}
                                            >
                                                {label.name}
                                            </Typography>

                                        </Grid>  

                                            <Grid item md={2} lg={2} style={{display:'flex', alignItems:'center', justifyContent:'flex-start'}}>
                                                <div className={classes.actionIconContainer}> 
                                                    <Button  className="more_actionButton1"
                                                            onClick = {(e) => onDeleteLabelClick(e, label.id, label.name)}
                                                        > 
                                                        {/* <DeleteOutlinedIcon /> */}
                                                        <i className={`flaticon-delete-1 ${classes.flatIcons}`}></i>
                                                            Delete
                                                    </Button> 

                                                    <Button  className="more_actionButton1"
                                                        onClick={() => {
                                                            onEditLabelClicked(label);
                                                            handleDrawerOpen();
                                                        }}
                                                        > 
                                                            {/* <EditOutlinedIcon /> */}
                                                            <i className={`flaticon-edit ${classes.flatIcons}`}></i>
                                                            Edit
                                                    </Button>
                                                </div>
                                            </Grid> 
                                    </Grid>

                        }) : <Grid item container className={classes.labelList}>
                                <Grid item> No Labels Found </Grid>
                             </Grid>  

                    }
                </Grid>
            </Container>  
            {<CreateEditLabelModal openDrawer={openDrawer}
                    editLabel={editLabel}
                    setDrawerOpen={handleDrawerClose}/>
                } 
        </div>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    labels: state.organizationInfo.labels
});

const mapDispatchToProps = (dispatch) => ({
    deleteOrgLabel: (label_id) => dispatch(deleteOrgLabel(label_id))
});

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationLabels);