import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Typography, TextField, Button, CircularProgress, Paper, Avatar, InputAdornment, Tooltip, IconButton, Link} from '@material-ui/core';
import {connect} from 'react-redux';
import {updateUser} from '../../actions/auth';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import CheckSharpIcon from '@material-ui/icons/CheckSharp';
import ClearIcon from '@material-ui/icons/Clear';
import {resendVerificationMailApi, updateProfileApi} from '../../services/authService';
import { nameValidator, emailValidator } from '../../util/validator';
import {consoleToLog, capitalizeFirstLetterOfEachWord, trackGAEvent} from '../../util/AppUtil';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        marginTop:'16px',
        overFlowY: 'auto',
        overflowX:'hidden',
    },
    tabPanelContainer: {
        flexGrow: 1,
        padding:'15px',
        //height:'100vh',
        marginBottom:'25px',
    },      
    imgContainer :{
        marginTop:"8px",
        padding:'10px',
        borderRadius:'50%',
        border: '1px solid rgba(0, 113, 255, 0.19)',
        boxSizing:'border-box',
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        width:'120px',
        height:'120px',
        marginBottom:'16px'
    },
    imgContainer1 :{
        borderRadius:'50%',
        display: 'block',
        width:'100%',
    },
    uploadImg: {
        position:'absolute',
        left:'80px',
        top:'95px',
        minWidth:'25px', 
        height:'25px', 
        borderRadius:'50%',
        background:'#3d3d3d', 
        color:'white',
        cursor:'pointer'
    },
    uploadImg1: {
        position:'absolute',
        top:'80px',
        left:'75px',
        minWidth:'25px', 
        height:'25px', 
        borderRadius:'50%',
        background:'#3d3d3d', 
        color:'white',
        cursor:'pointer',
    }, 
    avatarStyle: {
        marginTop: '24px',
        margin:'0px auto',
        width: '110px !important',
        height: '110px !important',
        fontSize: '32px !important',
        fontWeight: '600 !important',
        background: 'rgba(51, 51, 51, 0.1) !important',
        border: '1px solid rgba(0, 113, 255, 0.19) !important',
        boxSizing:'border-box',
        overflow:'visible'
    },
    circularProgress: {
        marginLeft: 0,
        marginRight: theme.spacing(2),
        color: 'white'
    }, 
    footer: {
        position:'absolute',
        width:'100%',
        bottom:'0px',
        padding:'16px',
        background:'white',
        borderTop:'1px solid rgba(0,0,0,0.1)'
    }

}))

const MobileBasicInfo = (props) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();

    let first_name = props.auth.user.firstname ? props.auth.user.firstname : '';
    let last_name = props.auth.user.lastname ? props.auth.user.lastname : '';
    let user_email = props.auth.user.email ? props.auth.user.email : '';

    const [attachment, setAttachment] = useState(undefined);
    const [userFirstName, setUserFirstName] = useState(first_name);
    const [userLastName, setUserLastName] = useState(last_name);
    const [userEmail, setUserEmail] = useState(user_email)
    const [loading, setLoading] = useState(false);

    const isEmailVerified = props.auth.user.is_email_verified;
    const {image}  = props.auth.user || {};

    const setImageSrc = () => {
        if(attachment){
            return URL.createObjectURL(attachment);
        }
        else if(image){
            return image;
        }
        else return undefined;
        }
    
    const onProfilePhotoSelected = (e) => {
            const { target: { files } } = e;
            //var fileName = files[0].name;
            setAttachment(e.target.files[0]);
            var oFReader = new FileReader();
            oFReader.readAsDataURL(e.target.files[0]);
            oFReader.onload = function (oFREvent) {
                document.getElementById("input_profilePhoto").src = oFREvent.target.result;
        };
    }  

    const endAdornmentIcon = () => {
        return (
            <InputAdornment position="end">
                <Tooltip title={isEmailVerified ? "Email Verified" : "Verify Email"} arrow>
                    <IconButton disableRipple style={{color: isEmailVerified ? 'green' : 'red', padding:'0', background:"none"}}>
                        {isEmailVerified ? 
                            <CheckSharpIcon fontSize="medium" /> 
                            : 
                            <ClearIcon fontSize="small" style={{marginRight:'4px', fontWeight:'bold'}}/>
                        }
                    </IconButton>
                </Tooltip>
                
            </InputAdornment>
        )
    } 

    const onUpdateClick = (e) => {
        e.preventDefault();

        const firstName = userFirstName;
        const lastName = userLastName;
        const email = userEmail;

        if (!firstName) {//check empty
            enqueueSnackbar('First name can not be empty!', {
                variant: 'error'
            });
            return;
        }
        const isFirstNameValid = nameValidator(firstName);
        if (!isFirstNameValid) {
            enqueueSnackbar('First name can not be empty!', {
                variant: 'error'
            });
            return;
        }

        const isLastNameValid = nameValidator(lastName);
        if (!lastName) {
            enqueueSnackbar('First name can not be empty!', {
                variant: 'error'
            });
            return;
        }
        if (!isLastNameValid) {
            enqueueSnackbar('Last name is not valid! Please enter only Alphabets', {
                variant: 'error'
            });
            return;
        }

        const isEmailValid = emailValidator(email);
        if (!isEmailValid) {
            return;
        }
        if (!email) {
            enqueueSnackbar('Email can not be empty!', {
                variant: 'error'
            });
            return;
        }


        //get image
        const image = attachment

        setLoading(true);
        //update profile api call
        updateProfileApi(capitalizeFirstLetterOfEachWord(firstName), 
            capitalizeFirstLetterOfEachWord(lastName), image)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response updateProfileApi: ", res);
                setAttachment(undefined);
                setLoading(false);
                enqueueSnackbar('User profile updated successfully!!!', {
                    variant: 'success'
                });
                props.updateUser(res);//update user details to redux store

                trackGAEvent(props.selectedOrganization.organization.name, 'Update Profile', `${props.auth.user.firstname} ${props.auth.user.lastname} updated profile`);
            })
            .catch((e) => {
                consoleToLog("updateProfileApi error", e.response);
                setLoading(false);
                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant: 'error'
                    });
                    return false;
                }
            });
    }

    const onResetClick = () => {
        //set firstname,lastname from redux to fields
        setUserFirstName(first_name);
        setUserLastName(last_name);
        setUserEmail(user_email);
    }

    return (
        <div className={classes.mainContainer}>
            <Grid item container direction="column" style={{padding:'0px 12px', overflow:'hidden'}}>
                <Grid item xs={12} sm={12}>

                    {image || attachment ?
                        <div className={classes.imgContainer} 
                            style={{display:'flex', justifyContent:'center', position:'relative'}}>
                            <img className={classes.imgContainer1}
                                src={image ? image : "/images/eprocessify-icon.png"}
                                id="input_profilePhoto"
                                alt="userProfilePic"
                            />
                            <label className={classes.uploadImg}>
                                <Typography  style={{marginLeft:'4px', marginTop:'4px'}}>
                                    <EditRoundedIcon fontSize="small" style={{fontSize:'18px'}}/>
                                </Typography>
                                    <input style={{display:'none'}}
                                        type="file"
                                        id="input_profilePhoto"
                                        accept="image/x-png,image/gif,image/jpeg"
                                        onChange={onProfilePhotoSelected}
                                        //disabled={disabled}
                                    />
                            </label>
                        </div>
                        :
                        <Avatar className={classes.avatarStyle}
                            id="profilePhoto"
                            alt="Profile Photo"
                            src={setImageSrc()}
                        >
                        {props.auth.user.firstname.charAt(0).toUpperCase() +
                        props.auth.user.lastname.charAt(0).toUpperCase()}
                            <label className={classes.uploadImg1}>
                                <Typography  style={{marginLeft:'4px', marginTop:'4px'}}>
                                    <EditRoundedIcon fontSize="small" style={{fontSize:'18px'}}/>
                                </Typography>
                                    <input style={{display:'none'}}
                                        type="file"
                                        id="input_profilePhoto"
                                        accept="image/x-png,image/gif,image/jpeg"
                                        onChange={onProfilePhotoSelected}
                                        //disabled={disabled}
                                    />
                            </label>
                    </Avatar>
                    } 
                </Grid>  

                <Grid item container direction="column" spacing={2} style={{marginTop:'4px'}}>
                    <Grid item xs={12} sm={12}>
                        <Typography component={'span'} variant="body2">
                            First Name
                        </Typography>

                        <TextField 
                            fullWidth
                            variant="outlined"
                            margin="dense"
                            value={userFirstName}
                            placeholder="Enter First Name"
                            InputLabelProps={{style: {fontSize: 13, marginTop:'3px'}}}
                            onChange={(e) => setUserFirstName(e.target.value)}
                        />
                    </Grid> 

                    <Grid item xs={12} sm={12}>
                        <Typography component={'span'} variant="body2">
                            Last Name
                        </Typography>

                        <TextField 
                            fullWidth
                            variant="outlined"
                            margin="dense"
                            value={userLastName}
                            placeholder="Enter Last Name"
                            InputLabelProps={{style: {fontSize: 13, marginTop:'3px'}}}
                            onChange={(e) => setUserLastName(e.target.value)}
                        />
                    </Grid>  

                    <Grid item xs={12} sm={12}>
                        <Typography component={'span'} variant="body2">
                            Email
                        </Typography>
                        <TextField 
                            fullWidth
                            variant="outlined"
                            margin="dense"
                            value={userEmail}
                            disabled
                            placeholder="Enter Email Address"
                            InputLabelProps={{style: {fontSize: 13, marginTop:'3px'}}}
                            onChange={(e) => setUserEmail(e.target.value)}
                            InputProps={{
                                endAdornment: endAdornmentIcon()
                            }}
                        />
                    </Grid>  
                </Grid>    
            </Grid>
            <Grid item xs={12} sm={12} className={classes.footer}>
                <Grid item container spacing={1}>
                    <Grid item xs={6} sm={6}>
                        <Button variant="contained" 
                            color="primary"
                            fullWidth 
                            style={{padding:'8px'}}
                            onClick={onUpdateClick}
                            >
                            {loading && <CircularProgress size={24} className={classes.circularProgress}/>} 
                            Save
                        </Button>
                    </Grid>

                    <Grid item xs={6} sm={6}>
                        <Button variant="outlined" 
                            onClick={onResetClick}
                            fullWidth
                            style={{padding:'8px'}}
                            color="primary"> 
                            Reset
                        </Button>
                    </Grid>
                </Grid>   
            </Grid>
        </div>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
});

const mapDispatchToProps = (dispatch) => ({
    updateUser: (user) => dispatch(updateUser(user))
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileBasicInfo);