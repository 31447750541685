import React,{useState, useEffect} from 'react';
import {Grid, Typography, Button, CircularProgress, Container, Popover} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MobileRightToLeftSideDrawer from './MobileRightToLeftSideDrawer';
import moment from 'moment';
import AutocompleteTemplate from '../../components/AutocompleteTemplate';
import { consoleToLog, trackGAEvent } from '../../util/AppUtil';
import Chart from 'react-apexcharts';
import { useIsMount } from '../../components/useIsMount';
import { getCompletionReportApi, getCompleteIncompleteChecklistApi } from '../../services/reportService';
import { useSnackbar } from 'notistack';
import { DateRangePicker } from "materialui-daterange-picker";
import {connect} from 'react-redux';
import MobileChecklistReportsDrawer from './MobileChecklistReportsDrawer';
import SortIcon from '@material-ui/icons/Sort';

const useStyles = makeStyles((theme) => ({
    mainContent : {
        width: '100%',
        height:'calc(100vh - 100px)',
        backgroundColor: theme.palette.primary.light,
        transition: ".5s",
        flexGrow: 1,
        //overflow:'hidden',
        overflow:'auto',
        overflowX:'hidden'
    },
    container: {
        marginTop: "8px",
    },
    graphMain: {
        padding: "1rem !important",
        fontSize: "11px !important",
        marginTop: "1rem !important",
        boxShadow: "0 .5rem 1rem rgba(0,0,0,.15) !important",
        width: "32rem !important",
        height: "21rem !important",
        marginLeft: "40px !important",
        marginBottom: "50px"
    },

    icons : {
        color: "black",
        fontSize: "18px",
        minWidth:'30px',
        marginLeft: "auto",
        marginTop:'-3px'
    } ,
    notAttachmentsImage: {
        height: "11rem",
        width: "13rem",
        marginTop: "3rem",
    },
    notAttachmentsText: {
        fontSize: "20px",
        fontWeight: "600",
        [theme.breakpoints.down(460)] : {
            fontSize: "18px",
            fontWeight: "600",
        },
        [theme.breakpoints.down(420)] : {
            fontSize: "16px",
            fontWeight: "600",
        },
        [theme.breakpoints.down(380)] : {
            fontSize: "15px",
            fontWeight: "600",
        },

    },
    notAttachmentsButton: {
        fontSize: "15px",
        width: "19rem",
        marginTop: "20px",
    },
    filterIcon: {
        position:'fixed',
        right:'24px',
        bottom:'16px'
    }
}))

const MobileCompletionReports = (props) => {
    const classes = useStyles();
    const isMount = useIsMount();
    const {enqueueSnackbar} = useSnackbar();

    const [anchorEl, setAnchorEl] = useState(false);
    const [dateRange, setDateRange] = useState({});
    const [startDateFilter, setStartDateFilter] = useState("");
    const [endDateFilter, setEndDateFilter] = useState("");
    const [clearFilter, setClearFilter] = useState(false);
    const [selectedTemplates, setSelectedTemplates] = useState([]);
    const [completionData, setCompletionData] = useState([]);
    const [openChecklistDrawer, setOpenChecklistDrawer] = useState(false);
    
    const [openDrawer, setOpenDrawer] = useState(false);
    const [openFilters, setOpenFilters] = useState(false);
    const [completionReportsLoading,setCompletionReportsLoading]= useState(false);
    const [checkList, setCheckList] = useState([]);
    const [checklistLoading, setChecklistLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [isMoreLoading, setIsMoreLoading] = useState(false);
    const [tempObj, setTempObj] = useState(undefined);
    const [nextPage, setNextPage] = useState(false);
    const [statusValue, setStatusValue] = useState(undefined);
    const [tempObj1, setTempObj1] = useState(undefined);

    const checklistDetails = window.location.toString().includes("checklist");

    useEffect(() => {
        document.title = 'Completion Reports'
    }, []);

    useEffect(() => {
        if(!isMount) {
            if(!checklistDetails) {
                document.title = 'Completion Reports'
            }
        }
    }, [checklistDetails]);

    //Last Month
    var prevMonthFirstDay = new moment().subtract(1, 'months').date(1)
        
    //for next month last day
    var thisMonthLastDay = new moment().add(1, 'months').date(0);

    //for last day of previous month
    var lastdayLastMonth = moment(lastdayLastMonth).subtract(1,'months').endOf('month').format('YYYY-MM-DD');

    let currentFinancialYearStart;
    let currentFinancialYearEnd;

    currentFinancialYearStart = (moment().month() < 3) ? moment().subtract(1, 'year').month('April').startOf('month').format('YYYY-MM-DD')
    : moment().month('April').startOf('month').format('YYYY-MM-DD');

    currentFinancialYearEnd =  (moment().month() < 3) ? moment().month('March').endOf('month').format('YYYY-MM-DD')
    : moment().add(1, 'year').month('March').endOf('month').format('YYYY-MM-DD');

    let lastFinancialYearStart = moment(currentFinancialYearStart).subtract(1, 'year').format('YYYY-MM-DD');
    let lastFinancialYearEnd = moment(currentFinancialYearEnd).subtract(1, 'year').format('YYYY-MM-DD');



    const openDateRangePicker = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const open = Boolean(anchorEl);

    const toggle = () => setAnchorEl(!anchorEl);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onUpdateClearFilterValue = () => {
        setClearFilter(false);
    }

    const onSelectedTemplates = (sts) => {
        setSelectedTemplates(sts);
    }

    //api
    const getCompletionReport = () => {
        consoleToLog("Api: SelectedTemplates", selectedTemplates);
        consoleToLog("Api: startDateFilter", startDateFilter);
        consoleToLog("Api: endDateFilter", endDateFilter);

        const access_token = props.auth.access_token;
        const organization_id = props.selectedOrganization.organization.id;
        if(!selectedTemplates || selectedTemplates.length === 0){
            setCompletionData([]); 
            return;
        }
        if(!startDateFilter || startDateFilter.length === 0) {
            setCompletionData([]); 
            return;
        };
        if(!endDateFilter || endDateFilter.length === 0){
            setCompletionData([]); 
            return;
        };

        const ids = selectedTemplates.map((l) => l.template_id).join(',');
        setCompletionReportsLoading(true);

        getCompletionReportApi(access_token, ids, startDateFilter, endDateFilter, organization_id)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response getCompletionReportApi: ", res);
                /* enqueueSnackbar(res.message, {
                    variant:'success'
                }); */
                trackGAEvent(props.selectedOrganization.organization.name, 'Filter Completion Report', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
                setCompletionData(res);
                setCompletionReportsLoading(false);
            })
            .catch((e) => {
                consoleToLog("getCompletionReportApi error", e.response);
                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant:'error'
                    });
                    return false;
                }
                setCompletionReportsLoading(false);
            });
    }

    const callGetCompleteIncompleteChecklistApi = () => {
        const organization_id = props.selectedOrganization.organization.id;
        const start_date = tempObj1 ? tempObj1.start_date : '';
        const end_date = tempObj1 ? tempObj1.end_date : ''; 
        const status = statusValue.toLowerCase(); 
        const template_id = tempObj ? tempObj.template_id : ''; 
        const schedule_type = tempObj1 ? tempObj1.schedule_type : '';

        if(!isMoreLoading) setChecklistLoading(true);
        getCompleteIncompleteChecklistApi(organization_id, page, start_date, end_date, status, template_id, schedule_type)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response getCompleteIncompleteChecklistApi: ', res);
                setChecklistLoading(false);

                if(isMoreLoading) {
                    const newItems = checkList.concat(res.checklist);
                    setCheckList(newItems);
                    setPage(page + 1);
                    setNextPage(res.next_page);
                    setIsMoreLoading(!isMoreLoading);
                } else {
                    setNextPage(res.next_page);
                    if(res.next_page) setPage(page + 1);
                    setCheckList(res.checklist);
                }

            })
            .catch((e) => {
                consoleToLog("Error getCompleteIncompleteChecklistApi: ", e.response);
                setChecklistLoading(false);
                if(e.response.data && e.response.data.message) {
                    return;
                }
            })
    }

    useEffect(() => {
        if(!isMount) {
            consoleToLog("CompletionReports page on Templates change- in useeffect");
            getCompletionReport();
        }
    }, [selectedTemplates, startDateFilter, endDateFilter]);

    useEffect(() => {
        if(!isMount) {
            if(page === 1 && tempObj1) {
                callGetCompleteIncompleteChecklistApi();
            }
        }
    }, [tempObj1, statusValue, page]);

    useEffect(() => {
        if(!isMount) {
            if(isMoreLoading) {
                callGetCompleteIncompleteChecklistApi();
            }
        }
    }, [isMoreLoading]);

    const pageReset = () => {
        setPage(1);
        setNextPage(false);
    }

    const toggleDrawer = () => {
        setOpenDrawer(open => !open)
        setAnchorEl(null);
    }

    const setDrawerOpen = () => {
        setOpenDrawer(false);
        setOpenFilters(false);
    }

    const handleChecklistDrawerOpen = () => {
        setOpenChecklistDrawer(true);
    }

    const handleChecklistDrawerClose = () => {
        setOpenChecklistDrawer(false);
    }


    const ReportsNotFound = () => {
        return (
            <div>
                {/* Not Found*/}
                <Grid container direction="row">
                <Grid
                    item
                    container
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    sm={12}
                >
                    <img
                    alt="No Reports Found"
                    src="/images/no_attachment_found.svg"
                    className={classes.notAttachmentsImage}
                    />
                </Grid>
                <Grid
                    item
                    container
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Typography className={classes.notAttachmentsText}>
                    No Reports Found, You need to select all filters
                    </Typography>
                </Grid>
                <Grid
                    item
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Button
                    variant="contained"
                    color="primary"
                    //endIcon={<PublishIcon />}
                    size="large"
                    className={classes.notAttachmentsButton}
                    onClick={()=>{
                        toggleDrawer();
                        setOpenFilters(!openFilters);
                    }}
                    >
                    Add Filters
                    </Button>
                </Grid>
                </Grid>
                {/* Not Found*/}
            </div>
            );
    };

    const filterStyle = () => {
        return (
            <Button variant='contained' color='secondary' 
                onClick={() => {
                    toggleDrawer();
                    setOpenFilters(!openFilters);
                }}
                className={classes.filterIcon}>
                <SortIcon style={{color:'#fff'}}/>
            </Button>
        )
    }

    return (
        <div className={classes.mainContent}>
            <Container className={classes.container} maxWidth="xl">
                { completionReportsLoading ?
                    <Grid item container justifyContent='center' alignItems='center'>
                        <Grid item lg={12}>
                            <CircularProgress size={35} style={{position:'relative', left:'50%', right:'50%', margin:'16px 0px'}}/>
                        </Grid>
                    </Grid>
                :
                    (completionData && completionData.length > 0) ? 
                    completionData.map((templateData, index)=>{
                        return(
                            <div key={templateData.template_id+"_"+index}
                                style={{padding: "12px 0", marginTop: "10px", fontWeight: "bold", marginBottom: "50px"}}>
                                <Typography variant="body" style={{marginLeft: "20px"}}>
                                {templateData.template_name}  
                                </Typography>
                                <Grid container justifyContent='center'>
                                    {templateData && templateData.data.length>0 && templateData.data.map((td, index) => {
                                    const seriesArr=[td.completed_count, td.incomplete_count];
                                    //consoleToLog("series: ", seriesArr);
                                        return(
                                            <Grid key={index} item lg={4}>
                                                <Chart options={{
                                                        series:[3,5],
                                                        chart:{
                                                            width:300,
                                                            type: "pie"
                                                        },
                                                        colors:[
                                                            "#00cf95",
                                                            "#0291ff",
                                                            "#FF5733",
                                                            "#1B4F72",
                                                            "#DC7633",
                                                            "#F7DC6F",
                                                            "#52BE80",
                                                            "#A93226",
                                                            "#273746",
                                                            "#B7950B"
                                                        ],
                                                        plotOptions:{
                                                            pie:{
                                                                customScale:0.7
                                                            }
                                                        },
                                                        legend:{
                                                            show:false
                                                        },
                                                        labels:["Complete", "Incomplete" ],
                                                        responsive:[
                                                        {
                                                            breakpoint:480,
                                                            options:{
                                                            chart:{
                                                                width:300
                                                            },
                                                            legend:{
                                                                position:"bottom"
                                                            }
                                                            }
                                                        }
                                                        ],
                                                        chart: {
                                                            events: {
                                                                    dataPointSelection: (event, chartContext, config) => {
                                                                        setTempObj(templateData);
                                                                        setTempObj1(td);
                                                                        let status = config.w.config.labels[config.dataPointIndex];
                                                                        setStatusValue(status);
                                                                        pageReset();
                                                                        handleChecklistDrawerOpen();
                                                                    },
                                                                }
                                                            }
                                                        }} 
                                                    series={seriesArr} 
                                                    type="pie" 
                                                    height={300}
                                                    />
                                                <Typography variant="subtitle1" style={{fontWeight: "bold", textAlign: "center", marginTop: "-35px"}}>
                                                    {td.subtitle}
                                                </Typography>
                                            </Grid>
                                            )
                                })}
                                </Grid>
                        </div>
                        )
                    })  : <ReportsNotFound /> 
                }
            </Container>    
            <MobileRightToLeftSideDrawer openDrawer={openDrawer} 
                drawerClose={setDrawerOpen}
                title="Report Filters"
                createChecklist={false}
            >
            <div >
                <Grid item>
                    <Grid item container>
                        <Grid item >
                            <Typography
                                variant="body1"
                                style={{lineHeight:'42px', color: '#888888'}}
                            >
                            Filter By Date
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item container style={{marginTop:'-6px'}}>
                        <Grid item xs={12}>
                        <Button variant='outlined'
                            fullWidth
                            color='primary'
                            style={{fontWeight:"300 !important"}}
                            onClick={toggle}>
                            {(startDateFilter.length > 0 && endDateFilter.length > 0) ? 
                            `${moment(startDateFilter).format('DD-MM-YYYY')} - 
                            ${moment(endDateFilter).format('DD-MM-YYYY')}` : "Start Date - End Date"}
                        </Button>
                        <DateRangePicker
                            open={anchorEl}
                            toggle={toggle}
                            closeOnClickOutside={handleClose}
                            onChange={(range) => {
                                //consoleToLog("range", range, dateRange);
                                setDateRange(range);
                                setStartDateFilter(moment(range.startDate).format('YYYY-MM-DD'))
                                setEndDateFilter(moment(range.endDate).format('YYYY-MM-DD'))
                                setClearFilter(false);
                            }}
                            style={{
                            width:'50%'
                            }}
                            initialDateRange={{
                                startDate:  startDateFilter,
                                endDate: endDateFilter
                            }}
                            definedRanges={[
                            {
                                label: `${moment(lastFinancialYearStart, 'YYYY-MM-DD').format('MMM-YYYY')}-${moment(lastFinancialYearEnd, 'YYYY-MM-DD').format('MMM-YYYY')}`,
                                startDate: lastFinancialYearStart,
                                endDate: lastFinancialYearEnd
                            },
                            {
                                label: `${moment(currentFinancialYearStart, 'YYYY-MM-DD').format('MMM-YYYY')}-${moment(currentFinancialYearEnd, 'YYYY-MM-DD').format('MMM-YYYY')}`,
                                startDate: currentFinancialYearStart,
                                endDate: currentFinancialYearEnd
                            },
                            {
                                label: 'Last Month',
                                startDate: prevMonthFirstDay,
                                endDate: lastdayLastMonth
                            },
                            {
                                label: 'This Month',
                                startDate: new Date(),
                                endDate: thisMonthLastDay
                            }
                            ]}
                        />
                        </Grid>
                    </Grid>   
                </Grid>    
                <Grid item>
                    <Grid item container>
                        <Grid item style={{marginTop:'8px'}}>
                            <Typography
                                variant="body1"
                                style={{lineHeight:'42px', color: '#888888'}}
                            >
                            Select Templates
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item container style={{marginTop:'-16px'}}>
                        <Grid item xs={12}>
                        <AutocompleteTemplate 
                            clearFilter={clearFilter}
                            onUpdateClearFilterValue={onUpdateClearFilterValue} 
                            onSelectedTemplates={onSelectedTemplates} 
                            selectedTemplates={selectedTemplates}
                            fromMobileComponent={true}
                        />
                        </Grid>
                    </Grid>   
                </Grid>
                <Grid item style={{marginTop: "18px"}}>
                <Button variant="contained" 
                    color="primary" 
                    style={{fontWeight:"300 !important"}}
                    className="modal__button"
                    onClick={()=>{
                        setClearFilter(true);
                        setDateRange();
                        setStartDateFilter("");
                        setEndDateFilter("");
                        setCompletionData([]); 
                    }}>
                    Clear Filters
                </Button>
                </Grid>
            </div>
        </MobileRightToLeftSideDrawer>

        <MobileChecklistReportsDrawer 
            openChecklistDrawer={openChecklistDrawer}
            handleChecklistDrawerClose={handleChecklistDrawerClose}
            checklistLoading={checklistLoading}
            checkList={checkList}
            tempObj={tempObj}
            nextPage={nextPage}
            isMoreLoading={isMoreLoading}
            setIsMoreLoading={setIsMoreLoading}
        />
        {completionData && completionData.length > 0 && filterStyle()}
        </div>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    members: state.organizationInfo.members
});

export default connect(mapStateToProps)(MobileCompletionReports);