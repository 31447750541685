//mentions used in:
//email scheduler widget
//email widget


//subject
export const getMentionOptionsForSubject = (emailSchedulerMentionData) => {
    var { company_name, start_date, end_date, subtitle, pan, gstin, next_month, start_month, end_month } = emailSchedulerMentionData ? emailSchedulerMentionData : {};

    var mentionOptionsForSubject = [
        { id: `{{company.name}}`, display: `{{company.name}} ${company_name ? company_name : ''}` },
        { id: `{{checklist.start_date}}`, display: `{{checklist.start_date}} ${start_date ? start_date : ''}` },
        { id: `{{checklist.end_date}}`, display: `{{checklist.end_date}} ${end_date ? end_date : ''}` },
        // { id: `{{company.pan}}`, display: `{{company.pan}} ${pan ? pan : ''}` },
        // { id: `{{company.gstin}}`, display: `{{company.gstin}} ${gstin ? gstin : ''}` },
        //{ id: `{{checklist.subtitle}}`, display: `{{checklist.subtitle}} ${subtitle ? subtitle : ''}` },
        //period is subtitle
        { id: `{{checklist.period}}`, display: `{{checklist.period}} ${subtitle ? subtitle : ''}` },
        { id: `{{checklist.next_month}}`, display: `{{checklist.next_month}} ${next_month ? next_month : ''}` },
        { id: `{{checklist.start_month}}`, display: `{{checklist.start_month}} ${start_month ? start_month : ''}` },
        { id: `{{checklist.end_month}}`, display: `{{checklist.end_month}} ${end_month ? end_month : ''}` }
    ];
    return mentionOptionsForSubject;
}


//body
export const getMentionOptionsForBody = (emailSchedulerMentionData) => {
    var { company_name, start_date, end_date, subtitle, pan, gstin, next_month, start_month, end_month } = emailSchedulerMentionData ? emailSchedulerMentionData : {};

    var mentionOptionsForBody = [
        {
            text: `{{company.name}} ${company_name ? company_name : ''}`,
            value: `{{company.name}} ${company_name ? company_name : ''}`,
            url: `{{company.name}}`
        },
        {
            text: `{{checklist.start_date}} ${start_date ? start_date : ''}`,
            value: `{{checklist.start_date}} ${start_date ? start_date : ''}`,
            url: `{{checklist.start_date}}`
        },
        {
            text: `{{checklist.end_date}} ${end_date ? end_date : ''}`,
            value: `{{checklist.end_date}} ${end_date ? end_date : ''}`,
            url: `{{checklist.end_date}}`,
        },
        // {
        //     text: `{{company.pan}} ${pan ? pan : ''}`,
        //     value: `{{company.pan}} ${pan ? pan : ''}`,
        //     url: `{{company.pan}}`,
        // },
        // {
        //     text: `{{company.gstin}} ${gstin ? gstin : ''}`,
        //     value: `{{company.gstin}} ${gstin ? gstin : ''}`,
        //     url: `{{company.gstin}}`,
        // },
        /*{
            text: `{{checklist.subtitle}} ${subtitle ? subtitle : ''}`,
            value: `{{checklist.subtitle}} ${subtitle ? subtitle : ''}`,
            url: `{{checklist.subtitle}}`,
        },*/
        {//period is subtitle
            text: `{{checklist.period}} ${subtitle ? subtitle : ''}`,
            value: `{{checklist.period}} ${subtitle ? subtitle : ''}`,
            url: `{{checklist.period}}`,
        },
        {
            text: `{{checklist.next_month}} ${next_month ? next_month : ''}`,
            value: `{{checklist.next_month}} ${next_month ? next_month : ''}`,
            url: `{{checklist.next_month}}`,
        },
        {
            text: `{{checklist.start_month}} ${start_month ? start_month : ''}`,
            value: `{{checklist.start_month}} ${start_month ? start_month : ''}`,
            url: `{{checklist.start_month}}`,
        },
        {
            text: `{{checklist.end_month}} ${end_month ? end_month : ''}`,
            value: `{{checklist.end_month}} ${end_month ? end_month : ''}`,
            url: `{{checklist.end_month}}`,
        },
    ];

    return mentionOptionsForBody;
}