import { Typography } from "@material-ui/core";

export const showChecklistStatusColor = (checklistItem) => {
    if(checklistItem.status === 'Pending') {
        return '4px solid #929292'
    } else if(checklistItem.status === 'In Process') {
        return '4px solid #FFA834'
    } else if(checklistItem.status === 'Awaiting Reply') {
        return '4px solid #DC4C18'
    } else if(checklistItem.status === 'Pending Approval') {
        return '4px solid #000000'
    } else {
        return '4px solid #72BB53'
    }
}

export const checklistLabelList = (checklistItem) => {
    const labels = checklistItem.checklist_labels_list.slice(1);
    const titles = labels.map((l) => l.name).join(', ');
    return <Typography variant='subtitle1'  style={{
                marginLeft:'12px',
                color:'#333',
                textDecoration:'underline',
                marginTop:'-1px',
                cursor:'pointer'
                }}>+{labels.length} more
            </Typography>
}