import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Typography, Button, CircularProgress, Avatar, TextField, InputAdornment,} from '@material-ui/core';
import {connect} from 'react-redux';
import {getClientsListApi} from '../../services/clientService';
import { consoleToLog, trackGAEvent, OverlayMessage, shouldDisable, AccessLevel } from '../../util/AppUtil';
import { useSnackbar } from 'notistack';
import { useIsMount } from '../../components/useIsMount';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import MobileClientInfoDrawer from './MobileClientInfoDrawer';
import {deleteClientApi} from '../../services/clientService';
import {exportCompaniesApi} from '../../services/authService';
import { OverlayView, setOverlayToken, setOverlayView, setOvelayMessage} from '../../actions/overlay';
import { v4 as uuidv4 } from 'uuid';
import ClearIcon from '@material-ui/icons/Clear';
import MobileClientDetailsModal from './MobileClientDetailsModal'; 

const useStyles = makeStyles((theme) => ({
    mainContent : {
        width: '100%',
        height:'calc(100vh - 48px)',
        backgroundColor: theme.palette.primary.light,
        transition: ".5s",
        flexGrow: 1,
        //overflow:'hidden',
        overflowY:'auto',
        overflowX:'hidden'
        },
    container: {
        marginTop: "8px",
        marginBottom:'16px'
    },
    buttonIconStyles: {
        marginLeft:'6px'
    },
    clientListStyle : {
        background: '#FFFFFF',
        padding:'8px 8px',
        margin:'0px 0px 8px 0px',
        borderTop:'1px solid rgba(0,0,0,0.1)',
        borderBottom:'1px solid rgba(0,0,0,0.1)',
        '&:last-child' : {
            marginBottom: '0px'
        },
    },
    avatarStyle: {
        width:"38px !important",
        height:"38px !important",
        fontSize:'16px !important',
        fontWeight:'600 !important',
    },
    emailEllipsis : {
        color:'#555555'
    },
    clientName: {
        fontSize:'16px',
        fontWeight:'600',
        maxWidth:'250px',
        overflow:'hidden',
        whiteSpace:'nowrap',
        textOverflow:'ellipsis'
    },
    actionIconContainer : {
        display: 'flex', 
        justifyContent: 'end',
        alignItems:'center',
        marginLeft:'auto'
    },
    actionIcons : {
        minWidth:'10px',
        justifyContent:'flex-end !important',
        verticalAlign: 'end !important',
        color:'#555555'
    },
    MuiTextFieldroot : {
        border:'none !important',
        '& .MuiOutlinedInput-root' : {
        borderRadius: '0px',
        border:'none !important'
        }
    }
}))

const MobileOrganizationClients = (props) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    const isMount = useIsMount();

    const [clientList, setClientList] = useState([]);
    const [page, setPage] = useState(0);
    const [nextPage, setNextPage] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isMoreLoading, setIsMoreLoading] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [clientInfo, setClientInfo] = useState(undefined);
    const [openSetupDrawer, setOpenSetupDrawer] = useState(false);
    const [clientSearch, setClientSearch] = useState('');
    const [openDialog, setOpenDialog] = useState(false);

    const isActive = (props.selectedOrganization.organization) ? (props.selectedOrganization.organization.deactivation_date) : null;
    const { access_level } = props.selectedOrganization || {};
    const accessLevelManager = shouldDisable(AccessLevel.getUserAccessLevelValue(access_level), AccessLevel.MANAGER);

    useEffect(() => {
        fetchClientList('');
        //   return  () => {
        //        setCompanies([])
        //    }
    }, []);

    useEffect(() => {
        if(isMount) {

        } else {
            if (isMoreLoading) {
                fetchClientList('');
            }
        }    
    }, [isMoreLoading]);

    const pageReset = (query) => {
        setClientSearch(query);
        setPage(0);
        setNextPage(false);
        if(page === 0) {
            fetchClientList(query);
        }
    }

    const handleDrawerOpen = (client) => {
        setOpenDrawer(true);
        setClientInfo(client);
    }

    const handleDrawerClose = () => {
        setOpenDrawer(false);
        setClientInfo(undefined);
    }

    const handleSetupDrawerOpen = () => {
        setOpenSetupDrawer(true);
    }

    const handleSetupDrawerClose = () => {
        setOpenSetupDrawer(false);
    }

    const companyUpdated = (client) => {
        const new_clients_array = props.clientList.map((comp) => {
            if (comp.id === client.id) {
                return {
                    ...comp,//old
                    ...client//new
                }
            } else {
                return comp;
            }
        })

        setClientList(new_clients_array);
    }

    const fetchClientList = (query) => {
        const organization_id = props.selectedOrganization.organization.id;

        if(!isMoreLoading)  setLoading(true);
        getClientsListApi(organization_id, page, query)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response getClientsListApi: ', res);
                setLoading(false);

                if(isMoreLoading) {
                    const newItems = clientList.concat(res.companies);
                    setClientList(newItems);
                    setPage(page + 1);
                    setNextPage(res.next_page);
                    setIsMoreLoading(!isMoreLoading);
                } else {
                    setNextPage(res.next_page);
                    if (res.next_page) setPage(page + 1);
                    setClientList(res.companies);
                }
            })
            .catch((e) => {
                setLoading(false);
                consoleToLog('Error getClientsListApi: ', e.response);
                //show401ErrorAndClearRedux(props, e, enqueueSnackbar);
            })
    }

    const onDeleteCompanyClick = (e, client_id) => {
        e.stopPropagation();
        if (window.confirm('Are you sure you want to archive client?')) {
            const organization_id = props.selectedOrganization.organization.id

            //archive api call
            deleteClientApi(client_id)
                .then((response) => {
                    const res = response.data;
                    consoleToLog('Response deleteClientApi', res);
                    enqueueSnackbar(res.message, {variant: 'success'});

                    let updatedClientsArr = clientList.filter((client) => client.id !== client_id);
                    setClientList(updatedClientsArr)

                })
                .catch((e) => {
                    consoleToLog('Error deleteClientApi: ', e.response);
                    if (e.response.data && e.response.data.message) {
                        enqueueSnackbar(e.response.data.message, {
                            variant:'success'
                        });
                        return false;
                    }

                })
        }
    }

    const exportCompanies  = () => {
        const organization_id = props.selectedOrganization.organization.id;

        var randomtoken = uuidv4();
        consoleToLog("token for export: ", randomtoken);

        props.setOverlayView(OverlayView.SHOW_OVERLAY_LOADING);
        props.setOverlayToken(randomtoken);
        props.setOvelayMessage(OverlayMessage.EXPORT_MSG);
        
        exportCompaniesApi(organization_id, randomtoken)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response exportCompaniesApi: ", res);
                trackGAEvent(props.selectedOrganization.organization.name, 'Export Companies', `${props.auth.user.firstname} ${props.auth.user.lastname} exported companies`);
            })
            .catch((e) => {
                consoleToLog("exportCompaniesApi error", e.response);

                props.setOverlayView(OverlayView.NO_VIEW);
                props.setOverlayToken("");

                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant:'error'
                    });
                    return false;
                }
            });
    }

    const displayClients = () => {
        return (
            <Grid item container style={{marginTop:'16px'}}>
                <Grid item container direction="column">
                    {clientList && clientList.length > 0 ? 
                        clientList.map((client) => {
                        
                        const compArr = client.name.split(" ");
                        let compStr1 = compArr[0];
                        let compStr2 = compArr[1] ? compArr[1] : '';
                        const finalStr = compStr1.charAt(0).toUpperCase() + compStr2.charAt(0).toUpperCase();
                        const clientEmails = client.email && client.email.length > 0 ? client.email.split(',') : [];
                        return <Grid item container 
                                    key={client.id} 
                                    onClick={() => handleDetailsDialogOpen(client)}
                                    className={classes.clientListStyle}>

                                    <Grid item md={10} lg={10}>
                                        <Grid item container>
                                            <Grid item>
                                                <Avatar className={classes.avatarStyle}>
                                                {finalStr}    
                                                </Avatar>
                                            </Grid>
                                            <Grid item className={classes.memberText}>
                                                <Grid item container direction='column' 
                                                    style={{marginLeft: "16px", marginTop:'-6px'}}>
                                                    <Grid item>
                                                        <Typography className={classes.clientName}>
                                                            {client.name}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item>
                                                    {
                                                        clientEmails && clientEmails.length > 0 &&
                                                        clientEmails.map((email) => {
                                                            return <Typography key={email} variant='subtitle1' 
                                                                        className={classes.emailEllipsis}>
                                                                            {email}
                                                                    </Typography>
                                                            })
                                                    }
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography variant="subtitle1" style={{color:'#555555'}}>
                                                            {client.phone_number}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                        </Grid>                                                
                                    </Grid>

                                    <Grid item xs={2} sm={2} 
                                        className={classes.actionIconContainer}>
                                        {/* <Button className={`${classes.actionIcons}`}
                                            onClick={() => handleDrawerOpen(client)}> 
                                                <VisibilityOutlinedIcon fontSize='small' 
                                                    style={{marginTop:'-5px'}}/>
                                        </Button> */}

                                        <Button className={`${classes.actionIcons}`}
                                            onClick={(e) => onDeleteCompanyClick(e, client.id) }
                                        > 
                                            <i className={`flaticon-delete-1 ${classes.flatIcons}`}></i>
                                        </Button> 
                                    </Grid>
                                </Grid>
                        }) : 
                        <Grid item container justifyContent='center' alignItems='center'>
                            <Typography>
                                No Clients Found
                            </Typography>
                        </Grid>

                    }   
                </Grid>
            </Grid>
        )
    }

    const loadMoreLogic = () => {
        return (
            <Grid item container justifyContent='center'>
                <Grid item lg={12}>
                {nextPage && !isMoreLoading &&
                    <div style={{textAlign: "center", margin:'16px 0px'}}>
                        <Button type="button"
                            color="secondary"
                            variant="contained"
                            onClick={() => {
                                setIsMoreLoading(!isMoreLoading);
                            }}>Load More
                        </Button>
                    </div>}

                { isMoreLoading &&
                    <CircularProgress size={35} style={{position:'relative', left:'50%', right:'50%', margin:'16px 0px'}}/>
                }
                </Grid>
            </Grid>
        )
    }

    const onSearchClientChange = (e) => {
        const query = e.target.value;
        pageReset(query);
    }

    const onHideQuickSearch = () => {
        pageReset('');
    }

    const handleDetailsDialogOpen = (client) => {
        setClientInfo(client);
        setOpenDialog(true);
    }

    const handleDetailsDialogClose = () => {
        setOpenDialog(false);
    }

    return (
                <div className={classes.mainContent}>
            <Grid item container direction='column'
                className={classes.container}>
                <Grid item xs={12} style={{marginTop:'8px', padding:'0 12px'}}>
                    <Grid item container alignItems='center'>
                        <Grid item xs={12}>
                            <TextField  fullWidth
                                type='text'
                                variant='outlined'
                                margin='dense'
                                placeholder='Search Client' 
                                value={clientSearch}
                                InputProps={{
                                    startAdornment: (
                                    <InputAdornment position="start">
                                        <i className="flaticon-search"></i>
                                    </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                                <ClearIcon onClick={onHideQuickSearch} 
                                                    fontSize='small' style={{color:'#ccc'}}/>
                                        </InputAdornment>
                                    )
                                }}
                                style={{background:'#fff'}}
                                onChange={onSearchClientChange}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} style={{marginTop:'8px'}}>
                    {displayClients()}
                    {loadMoreLogic()}
                </Grid>
            </Grid>

            {/* <MobileClientInfoDrawer openDrawer={openDrawer}
                handleDrawerClose={handleDrawerClose}
                clientInfo={clientInfo}
            /> */}

            {openDialog &&
            <MobileClientDetailsModal  openDialog={openDialog}
                handleDetailsDialogClose={handleDetailsDialogClose}
                clientInfo={clientInfo}
                companyUpdated={companyUpdated}
            />}

        </div>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    overlay: state.overlay,
    templates: state.organizationInfo.templates,
});

const mapDispatchToProps = (dispatch) => ({
    setOverlayView: (exportView) => dispatch(setOverlayView(exportView)),
    setOverlayToken: (randomToken) => dispatch(setOverlayToken(randomToken)),
    setOvelayMessage: (overlayMessage) => dispatch(setOvelayMessage(overlayMessage)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MobileOrganizationClients);