import React, {useState, useEffect} from 'react';
import {TextField, Grid, Button, Typography, CircularProgress, 
    MenuItem, FormControlLabel, Checkbox, Breadcrumbs, Link, InputAdornment} from '@material-ui/core'; 
import {makeStyles} from '@material-ui/core/styles';
import RightToLeftSideDrawer from '../RightToLeftSideDrawer';
import {connect} from 'react-redux';
import { consoleToLog, capitalizeFirstLetterOfEachWord, getInvoicePeriodicityPlaceholder, trackGAEvent} from '../../util/AppUtil';
import {
    getStartYearOptions, getRangeOptionsFromTemplate, getRangeValuesFromTemplate,
    getCreateAtOptionsFromTemplate, getCreateAtValuesFromTemplate, 
    getOptionsAndValuesForMonthlyInvoicePeriod, 
    getOptionsAndValuesForQuartelyInvoicePeriod, 
    getOptionsAndValuesForHalfYearlyInvoicePeriod, 
    getOptionsAndValuesForYearlyInvoicePeriod
} from '../../util/checklistUtil';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useIsMount } from '../useIsMount';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import {addCompanyToOrgApi} from '../../services/authService';


const useStyles = makeStyles((theme) => ({
    templateListContainer : {
        background: '#F9F9F9',
        borderRadius: '5px',
        marginBottom: '8px',
        padding:'8px 16px 8px 16px'
    },
    textFieldBackground: {
        background: '#fff'
    },
    MuiTextFieldroot: {
        '& .MuiSelect-select.Mui-disabled' :{
            cursor: 'default',
            background:'#EEEEEE'
        },
        '& .MuiSelect-outlined.MuiSelect-outlined': {
            border: '1px solid #DBDBDB',
            paddingRight:'25px',
            paddingLeft: '8px'
        }
    },
    circularProgress: {
        marginLeft: 0,
        marginRight: theme.spacing(2),
        color: 'white'
      },
}))

const CreateCompanyChecklistModal = (props) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    const isMount = useIsMount();
    let temp_data = props.tempCompanyData ? props.tempCompanyData : undefined

    const [tempCompanyData, settTempCompanyData] = useState(undefined);
    const [checkList, setCheckList] = useState([]);
    const [startYearOptions, setStartYearOptions] = useState([]);
    const [loading, setLoading] = useState(false);

    const startMonthOptions = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    useEffect(() => {
        const yearOptions = getStartYearOptions();
        setStartYearOptions(yearOptions);
        settTempCompanyData(temp_data);
    },[]);

    useEffect(() => {
        settTempCompanyData(temp_data);
    }, [temp_data])

    useEffect(() => {
        if(isMount) {

        } else {
            if(tempCompanyData) {
                loadTemplatesForChecklistCreation();
            }
        }
    }, [tempCompanyData]);

    const loadTemplatesForChecklistCreation = () => {
        const checklist_list = (props.templates && props.templates.length > 0) ?
            props.templates.map((templateObj) => {

                //Periodicity / range options
                const rangeOptionsFromTemplate = getRangeOptionsFromTemplate(templateObj);

                //Periodicity / range values
                const rangeValuesFromTemplate = getRangeValuesFromTemplate(templateObj);

                //createAtOptions
                const createAtOptionsFromTemplate = getCreateAtOptionsFromTemplate(templateObj);

                //createAtValues
                const createAtValuesFromTemplate = getCreateAtValuesFromTemplate(templateObj);

                //subtitle
                const subtitle = !templateObj.isScheduleActive ? moment().format('DD/MM/YYYY') : '';

                //default selected if one option
                var selectedRange = (rangeOptionsFromTemplate.length === 1) ?
                    rangeValuesFromTemplate[0] : '';

                var selectedCreateAt = (createAtOptionsFromTemplate.length === 1) ?
                    createAtValuesFromTemplate[0] : '';

                return {
                    ...templateObj,
                    checked: false,
                    isScheduleActive: templateObj.schedule,
                    rangeOptions: rangeOptionsFromTemplate,
                    rangeValues: rangeValuesFromTemplate,
                    createAtOptions: createAtOptionsFromTemplate,
                    createAtValues: createAtValuesFromTemplate,
                    scheduleValues: {
                        selectedMonth: -1,
                        selectedYear: -1,
                        selectedRange,
                        selectedCreateAt
                    },
                    subtitle
                }
            })
            : [];
        setCheckList([...checklist_list])
    }

    const onChecklistMarked = (e, template, index) => {
        var newList = checkList;
        newList[index].checked = !newList[index].checked;
        setCheckList([...newList]);
    }

    const onBillableClicked= (e, index, template) => {
        
        var newList = checkList;
        newList[index].isBillableChecked = !newList[index].isBillableChecked;

        newList[index].isBillableChecked &&
        trackGAEvent(props.selectedOrganization.organization.name, 'Billable Clicked', `${props.auth.user.firstname} ${props.auth.user.lastname}`);

        setCheckList([...newList]);
    }

    const onStartMonthChanged = (e, index) => {
        const value = e.target.value;
        if (value !== -1 || value !== '-1') {
            var newList = checkList;
            newList[index].scheduleValues.selectedMonth = value
            setCheckList([...newList]);
        }
    }

    const onStartYearChanged = (e, index) => {
        const value = e.target.value;
        if (value !== -1 || value !== '-1') {
            var newList = checkList;
            newList[index].scheduleValues.selectedYear = value;
            setCheckList([...newList]);
        }
    }

    const onRangeChanged = (e, index) => {
        const value = e.target.value;
        if (value !== -1 || value !== '-1') {
            var newList = checkList;
            newList[index].scheduleValues.selectedRange = value;
            setCheckList([...newList]);
        }
    }

    const onInvoicePeriodicityChanged= (e, index) => {
        const value = e.target.value;
        console.log(value);
        if (value !== -1 || value !== '-1') {
            var newList = checkList;
            newList[index].invoicePeriodicty = value;
            setCheckList([...newList]);
        }
    }

    const onInvoiceCostChanged = (e, index) => {
        const value = e.target.value;
        console.log(value);
        if (value !== -1 || value !== '-1') {
            var newList = checkList;
            newList[index].invoiceCost = value;
            setCheckList([...newList]);
        }
    }

    const onCreateAtChanged = (e, index) => {
        const value = e.target.value;
        if (value !== -1 || value !== '-1') {
            var newList = checkList;
            newList[index].scheduleValues.selectedCreateAt = value;
            setCheckList([...newList]);
        }
    }

    const onSubtitleChanged = (e, index) => {
        const value = e.target.value ? e.target.value : '';
        var newList = checkList;
        newList[index].subtitle = value;
        setCheckList([...newList]);
    }

    const scheduleChecklist = (template, index) => {
        return (
                <Grid item style={{marginTop:"-8px", marginLeft:"28px"}}> 
                    <Grid item container spacing={1}>
                        <Grid item md={3} lg={3}>{/**Month */}
                            <TextField
                                fullWidth
                                select
                                variant="outlined"
                                margin="dense"
                                id={"startMonthSelector" + template.template_id}
                                onChange={(e) => onStartMonthChanged(e, index)}
                                disabled={!template.isScheduleActive || !template.checked}
                                InputLabelProps={{style: {fontSize: 14}}}
                                label="Month"
                                value={template.scheduleValues.selectedMonth ? template.scheduleValues.selectedMonth : -1}
                                classes={{
                                    root: classes.MuiTextFieldroot
                                }}
                            >
                                <MenuItem value={-1}>Month</MenuItem>
                                {
                                    startMonthOptions && startMonthOptions.map((op, index) => {
                                        return  <MenuItem key={op} value={index + 1}>
                                                    {op}
                                                </MenuItem>
                                        })
                                }
                            </TextField>
                        </Grid>

                        <Grid item md={2} lg={2}>
                            <TextField
                                fullWidth
                                select
                                variant="outlined"
                                margin="dense"
                                d={"startYearSelector" + template.template_id}
                                onChange={(e) => onStartYearChanged(e, index)}
                                disabled={!template.isScheduleActive || !template.checked}
                                InputLabelProps={{style: {fontSize: 14}}}
                                label="Year"
                                value={template.scheduleValues.selectedYear ? template.scheduleValues.selectedYear : -1}
                                classes={{
                                    root: classes.MuiTextFieldroot
                                }}
                            >
                                <MenuItem value={-1}>Year</MenuItem>
                                {
                                    startYearOptions && startYearOptions.map((op) => {
                                        return <MenuItem key={op} value={op}>
                                                    {op}
                                                </MenuItem>
                                    }) 
                                }
                            </TextField>

                        </Grid>

                        <Grid item md={3} lg={3}>
                            <TextField
                                style={{ width: "100%"}} 
                                id="rangeSelector"
                                margin="dense"
                                select
                                label="Periodicity"
                                variant="outlined"
                                onChange={(e) => onRangeChanged(e, index)}
                                InputLabelProps={{style: {fontSize: 14}}}
                                disabled={!template.isScheduleActive || !template.checked || template.rangeOptions.length === 1}
                                value={template.scheduleValues.selectedRange ? template.scheduleValues.selectedRange : ''}
                                classes={{
                                    root: classes.MuiTextFieldroot
                                }}
                            >
                                <MenuItem value={-1}>Periodicity</MenuItem>
                                {
                                    template.rangeOptions && template.rangeOptions.map((op, index) => {
                                        return <MenuItem key={op} 
                                                    value={template.rangeValues[index]}>
                                                        {op}
                                            </MenuItem>
                                    })
                                }
                            </TextField>
                        </Grid>

                        <Grid item md={4} lg={4}>
                            <TextField
                                style={{ width: "100%"}} 
                                id="createAtSelector"
                                margin="dense"
                                select
                                label="Create At"
                                variant="outlined"
                                onChange={(e) => onCreateAtChanged(e, index)}
                                InputLabelProps={{style: {fontSize: 14}}}
                                disabled={!template.isScheduleActive || !template.checked || template.createAtOptions.length === 1}
                                value={template.scheduleValues.selectedCreateAt ?  template.scheduleValues.selectedCreateAt : ''}
                                classes={{
                                    root: classes.MuiTextFieldroot
                                }}
                            >
                                <MenuItem value={-1}>Create At</MenuItem>
                                {
                                    template.createAtOptions && template.createAtOptions.map((op, index) => {
                                        return <MenuItem key={op} 
                                                    value={template.createAtValues[index]}>
                                                        {op}
                                            </MenuItem>
                                    })
                                }
                            </TextField>
                        </Grid>   
                    </Grid>    
                </Grid>
        )
    }    

    const handleBreadCrumb = () => {
        props.handleCloseCreateChecklistDrawer()
    }

    const breadCrumb = () => {
       return (
                <Breadcrumbs separator={<NavigateNextIcon style={{marginLeft:'-8px', padding:0}} fontSize="medium" />} 
                    aria-label="breadcrumb">
                    <Link  onClick={handleBreadCrumb}
                        style={{color:'#888',
                            cursor:'pointer', 
                            fontWeight:'600'}}
                    >
                        Add Company
                    </Link>
                    <Typography  style={{fontWeight:'600', marginLeft: '-8px'}} 
                        color="primary">
                            Create Checklist
                    </Typography>
                </Breadcrumbs>
            ); 
    }


    const onCreateCompanyChecklistModal = ( ) => {

        const organization_id = props.selectedOrganization.organization.id;

        //get selected checklists data
        let selectedChecklists = checkList.filter((checklist) => checklist.checked);
        let checklist_list = [];

        //checklist validations and store required details for API for each
        if (selectedChecklists.length > 0) {
            for (var i = 0; i < selectedChecklists.length; i++) {
                //get start date
                let start_date = moment().format('YYYY-MM-DD');//start_date//default=today

                let month = undefined;
                let year = undefined;
                if (selectedChecklists[i].isScheduleActive) {
                    //month
                    if (
                        selectedChecklists[i].scheduleValues.selectedMonth &&
                        selectedChecklists[i].scheduleValues.selectedMonth !== -1 &&
                        selectedChecklists[i].scheduleValues.selectedMonth !== '-1'
                    ) {
                        month = selectedChecklists[i].scheduleValues.selectedMonth;
                    } else {
                        enqueueSnackbar('Month can not be empty!', {
                            variant:'error'
                        });
                        return;
                    }

                    //year
                    if (
                        selectedChecklists[i].scheduleValues.selectedYear &&
                        selectedChecklists[i].scheduleValues.selectedYear !== -1 &&
                        selectedChecklists[i].scheduleValues.selectedYear !== '-1'
                    ) {
                        year = selectedChecklists[i].scheduleValues.selectedYear
                    } else {
                        enqueueSnackbar('Year can not be empty!', {
                            variant:'error'
                        });
                        return;
                    }

                    //date
                    start_date = moment(`${year}-${month}-01`, "YYYY-MM-DD").format('YYYY-MM-DD');
                }

                //get schedule value
                let schedule = selectedChecklists[i].isScheduleActive ? 1 : undefined;

                //get range
                let schedule_type = '';//'one_time';//range // default value
                if (selectedChecklists[i].isScheduleActive) {
                    if (selectedChecklists[i].scheduleValues.selectedRange &&
                        selectedChecklists[i].scheduleValues.selectedRange !== -1 &&
                        selectedChecklists[i].scheduleValues.selectedRange !== '-1'
                    ) {
                        schedule_type = selectedChecklists[i].scheduleValues.selectedRange;
                    } else {
                        enqueueSnackbar('Periodicity can not be empty!', {
                            variant:'error'
                        });
                        return;
                    }
                }

                //get create_at
                var schedule_creation_type = 'start';//create_at//default value
                if (selectedChecklists[i].isScheduleActive) {
                    if (selectedChecklists[i].scheduleValues.selectedCreateAt &&
                        selectedChecklists[i].scheduleValues.selectedCreateAt !== -1 &&
                        selectedChecklists[i].scheduleValues.selectedCreateAt !== '-1'
                    ) {
                        schedule_creation_type = selectedChecklists[i].scheduleValues.selectedCreateAt;
                    } else {
                        enqueueSnackbar('Create At can not be empty!', {
                            variant:'error'
                        });
                        return;
                    }
                }

                //get template_id
                const template_id = selectedChecklists[i].template_id;

                //subtitle: if not schedule
                var subtitle = !selectedChecklists[i].isScheduleActive ? document.getElementById('subtitle' + selectedChecklists[i].template_id).value : '';
                
                var billable =selectedChecklists[i].isBillableChecked ? selectedChecklists[i].isBillableChecked : false; 
                var cost = selectedChecklists[i].invoiceCost? selectedChecklists[i].invoiceCost : "";
                var periodicity=(selectedChecklists[i].invoicePeriodicty!=="-1" && selectedChecklists[i].invoicePeriodicty !== -1)?selectedChecklists[i].invoicePeriodicty : "";

                //save all details
                var checklistObj = {
                    start_date,
                    schedule,
                    schedule_type,
                    schedule_creation_type,
                    organization_id,
                    template_id,
                    subtitle,
                    billable,
                    cost,
                    periodicity
                }
                checklist_list.push(checklistObj);
            }
        }

        //get required details of company modal
        const user_defined_field = tempCompanyData.user_defined_field;
        const { name, address, email, phone_number, country, state, tax_id, id } = tempCompanyData;
        //consoleToLog('subtitle', user_defined_field);
        const emailArr = email && email !== '' ? email.split(',') : [];
        //setLoading(true);
        consoleToLog('emails', emailArr);

        //create company api call
        addCompanyToOrgApi(capitalizeFirstLetterOfEachWord(name), address, 
        organization_id, user_defined_field, checklist_list, country, state, tax_id, id)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response addCompanyToOrgApi: ", res);
                enqueueSnackbar('Client created!', {
                    variant:'success'
                });

                //update local state companies
                //props.addCompany(res);

                //clear data
                loadTemplatesForChecklistCreation();
                setLoading(false);
                settTempCompanyData(undefined);
                props.setTempCompanyData(undefined);

                // ReactGA.event({
                //     category: 'Organization',
                //     action: 'Company created'
                // });

                //hide modal now
                props.handleCloseCreateChecklistDrawer();
                props.setClearCompanyDrawer(true);
                props.handleCloseCreateCompanyDrawer();
            })
            .catch((e) => {
                consoleToLog("addCompanyToOrgApi error", e.response);
                props.handleCloseCreateChecklistDrawer();
                props.setClearCompanyDrawer(true);
                props.handleCloseCreateCompanyDrawer();
                props.handleCloseClientContactDrawer();
                setLoading(false);
                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant:'error'
                    });
                    //return false;
                    if(e.response.data.template_list && e.response.data.template_list.length>0){ 
                        //this.setState({template_list: e.response.data.template_list});
                        var list = checkList.map((templateObj) => {
                            const isError = e.response.data.template_list.includes(templateObj.template_id);
                            //consoleToLog(isError);
                            return {...templateObj, isError};
                        });
                        //consoleToLog(list);

                        setCheckList([...list]);
                    }
                }
            });

    }

    return (
        <RightToLeftSideDrawer title={breadCrumb()}
            openDrawer={props.openCreateChecklistDrawer}
            closeDrawer={props.handleCloseCreateChecklistDrawer}
            createCheckList={props.openCreateChecklistDrawer}
        >
            <div className="modal__main" style={{overflow:'auto'}}>
                <Grid item container md={12} lg={12} direction="column">
                    <Grid item md={12} lg={12}>

                        {
                            checkList && checkList.length > 0 ?
                            checkList.map((template, index) => {
                                //console.log("Periodcity checklist: ", template)
                                var invoicePeriodRange = [];
                                if(template.isScheduleActive){
                                    if(template.scheduleValues.selectedRange!== '' && template.scheduleValues.selectedRange === "monthly"){
                                        invoicePeriodRange= getOptionsAndValuesForMonthlyInvoicePeriod();
                                    }
                                    else if(template.scheduleValues.selectedRange!== '' && template.scheduleValues.selectedRange === "quarterly"){
                                        invoicePeriodRange= getOptionsAndValuesForQuartelyInvoicePeriod();
                                    }
                                    else if(template.scheduleValues.selectedRange!== '' && template.scheduleValues.selectedRange === "half_yearly"){
                                        invoicePeriodRange= getOptionsAndValuesForHalfYearlyInvoicePeriod();
                                    }
                                    else if(template.scheduleValues.selectedRange!== '' && template.scheduleValues.selectedRange === "yearly"){
                                        invoicePeriodRange= getOptionsAndValuesForYearlyInvoicePeriod();
                                    }
                                }

                                return  template.name !== 'All' &&
                                        <Grid item container key={template.template_id}
                                            direction="column"
                                            className={classes.templateListContainer}
                                        >
                                            <Grid item>
                                                <FormControlLabel
                                                    key={template.id + "_" + index}
                                                    control={
                                                        <Checkbox
                                                        onChange={(e) => onChecklistMarked(e, template, index)}
                                                        checked={template.checked}
                                                        name="checkedB"
                                                        color="primary"
                                                        />
                                                    }
                                                    style={{
                                                        display: "block",
                                                        fontSize:'14px',
                                                    }}
                                                    label={template.name} 
                                                />
                                            </Grid>
                                            {
                                                (template.isScheduleActive && template.checked) ? scheduleChecklist(template, index)
                                                :
                                                <Grid item md={12} lg={12} style={{marginLeft:"28px"}}>
                                                    {!template.isScheduleActive && template.checked && <TextField
                                                        id={"subtitle" + template.template_id}
                                                        fullWidth
                                                        margin="dense"
                                                        variant="outlined"
                                                        value={template.subtitle}
                                                        onChange={(e) => onSubtitleChanged(e, index)}
                                                        placeholder="Enter Checklist Subtitle"
                                                        disabled={!template.checked}
                                                        InputLabelProps={{style: {fontSize: 13, marginTop:'3px'}}}
                                                    ></TextField>}
                                                </Grid>   
                                            }
                                            {template.checked && props.accountList && props.accountList.length > 0 &&
                                            <div>
                                                <Grid item style={{marginLeft: "28px"}}>
                                                    <FormControlLabel
                                                        key={template.id + "_" + index}
                                                        control={
                                                            <Checkbox
                                                                onChange={(e) => onBillableClicked(e, index, template)}
                                                                checked={template.isBillableChecked}
                                                                name="invoiceCheck"
                                                                color="primary"
                                                            />
                                                        }
                                                        style={{
                                                            display: "block",
                                                            fontSize:'14px',
                                                        }}
                                                        label="Billable" 
                                                    />
                                                </Grid>
                                                {template.isBillableChecked && <Grid item style={{marginTop:"-8px", marginLeft: "28px"}}> 
                                                    <Grid item container spacing={1}>
                                                        <Grid item md={6} lg={6}>
                                                            <TextField
                                                                fullWidth
                                                                margin="dense"
                                                                variant="outlined"
                                                                type="number"
                                                                value={template.invoiceCost}
                                                                onChange={(e) => onInvoiceCostChanged(e, index)}
                                                                placeholder='Cost'
                                                                //disabled={!template.checked}
                                                                InputProps={{
                                                                    endAdornment: <InputAdornment position="end">{`${getInvoicePeriodicityPlaceholder(checkList[index].invoicePeriodicty)}`}</InputAdornment>,
                                                                  }}
                                                                InputLabelProps={{style: {fontSize: 13, marginTop:'3px'}}}
                                                            ></TextField>
                                                        </Grid>
                                                        { template.isScheduleActive &&  <Grid item md={6} lg={6}>
                                                            <TextField
                                                                style={{ width: "100%"}} 
                                                                id="invoicerangeSelector"
                                                                margin="dense"
                                                                select
                                                                label="Invoice Periodicity"
                                                                variant="outlined"
                                                                onChange={(e) => onInvoicePeriodicityChanged(e, index)}
                                                                InputLabelProps={{style: {fontSize: 14}}}
                                                                //disabled={!template.isScheduleActive || !template.checked || template.rangeOptions.length === 1}
                                                                // value={template.scheduleValues.selectedRange ? template.scheduleValues.selectedRange : ''}
                                                                classes={{
                                                                    root: classes.MuiTextFieldroot
                                                                }}
                                                            >
                                                                <MenuItem value={-1}>Invoice Periodicity</MenuItem>
                                                                {
                                                                    invoicePeriodRange && invoicePeriodRange.map((op, index) => {
                                                                        return <MenuItem key={op+index} 
                                                                                    value={op.value}>
                                                                                        {op.display_name}
                                                                            </MenuItem>
                                                                    })
                                                                }
                                                            </TextField>
                                                    </Grid>}


                                                    </Grid>                                                                            
                                                </Grid>}
                                            </div>}
                                       </Grid>

                            }) : <div></div> 
                        }

                    </Grid>    

                    <div className="modal__footer">
                        <Button variant="contained" color="primary" 
                            onClick={onCreateCompanyChecklistModal}
                            className="modal__button">
                            {loading && <CircularProgress size={24} className={classes.circularProgress}/>}
                            Create
                        </Button>
                        <Button variant="outlined"
                            onClick={props.handleCloseCreateChecklistDrawer} 
                            style={{
                            minWidth: '130px',
                        }}>
                            Back
                        </Button>
                    </div>
                </Grid>
            </div>
        </RightToLeftSideDrawer>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    templates: state.organizationInfo.templates,
    accountList: state.organizationInfo.accountList
});


export default connect(mapStateToProps)(CreateCompanyChecklistModal);