//year dropdown
export const getStartYearOptions = () => {
    var year = new Date().getFullYear();
    var years = [];
    for (var i = year; i > year - 11; i--) {
        years.push(i);
    }
    return years;
}

//Periodicity / range options
export const getRangeOptionsFromTemplate = (templateObj) => {
    return (templateObj.schedule_type && templateObj.schedule_type.length > 0) ?
        templateObj.schedule_type.map((obj) => obj.display_name) :
        ["Monthly", "Quarterly", "Half Yearly", "Yearly"];
}

//Periodicity / range values
export const getRangeValuesFromTemplate = (templateObj) => {
    return (templateObj.schedule_type && templateObj.schedule_type.length > 0) ?
        templateObj.schedule_type.map((obj) => obj.value) :
        ["monthly", "quarterly", "half_yearly", "yearly"];
}

//createAtOptions
export const getCreateAtOptionsFromTemplate = (templateObj) => {
    return (templateObj.schedule_creation_type && templateObj.schedule_creation_type.length > 0) ?
        templateObj.schedule_creation_type.map((obj) => obj.display_name) :
        ["Start of period", "End of period"];
}

//createAtValues
export const getCreateAtValuesFromTemplate = (templateObj) => {
    return (templateObj.schedule_creation_type && templateObj.schedule_creation_type.length > 0) ?
        templateObj.schedule_creation_type.map((obj) => obj.value) :
        ["start", "end"];
}


//Monthly Invoice Periodicity / range values
export const getOptionsAndValuesForMonthlyInvoicePeriod = () => {
    return [
        {display_name: "Monthly", value: "monthly"}, 
        {display_name: "Quarterly", value: "quarterly"},
        {display_name: "Half Yearly", value: "half_yearly"},
        {display_name: "Yearly", value: "yearly"}
    ];
}

//Qurtely Invoice Periodicity / range values
export const getOptionsAndValuesForQuartelyInvoicePeriod = () => {
    return [ 
        {display_name: "Quarterly", value: "quarterly"},
        {display_name: "Half Yearly", value: "half_yearly"},
        {display_name: "Yearly", value: "yearly"}
    ];
}

//Half Yearly Invoice Periodicity / range values
export const getOptionsAndValuesForHalfYearlyInvoicePeriod = () => {
    return [ 
        {display_name: "Half Yearly", value: "half_yearly"},
        {display_name: "Yearly", value: "yearly"}
    ];
}

//Yearly Invoice Periodicity / range values
export const getOptionsAndValuesForYearlyInvoicePeriod = () => {
    return [ 
        {display_name: "Yearly", value: "yearly"}
    ];
}