import React, {useState, useEffect} from 'react';
import { Accordion, AccordionSummary, AccordionDetails , Typography,
    List, ListItem, Button, Link, Drawer, Grid, Tooltip, CircularProgress } from "@material-ui/core";

import { makeStyles } from '@material-ui/core/styles'; 
import {connect} from 'react-redux';  
import {setSelectedTemplate, setSelectedDrawerState, SelectedDrawerState, setOrgTemplates,
    setSelectedOrg, logoutSelectedOrg, fetchOrgDetails, setCDSalt} from '../../actions/selectedOrganization';
import {shouldDisable, AccessLevel, trackGAEvent, consoleToLog} from '../../util/AppUtil';
import {history} from '../routers/MobileAppRouter';
import {useSnackbar} from 'notistack';
import { useParams } from 'react-router-dom';
import { useIsMount } from '../../components/useIsMount';
import MobileLeftComponentTopBar from './MobileLeftComponentTopBar';
import { setLoading } from '../../actions/loading';
import {logoutFilters} from '../../actions/filters';
import {clearSelectedFilters} from '../../actions/filters';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import {logout} from '../../actions/auth';
import {logoutOrgs} from '../../actions/organization';
import {clearActivityReportFilter} from '../../actions/activityReports';
import { firebase } from '../../firebase/firebase';
import {logoutApi} from '../../services/authService';

const useStyles = makeStyles((theme) => ({
    drawer: {
    //    width: drawerWidth,
        flexShrink: 0
    },
    drawerPaper: {
        width: '75%', 
        height: '100%',
        flexGrow:1,
        overflowY:'auto',
        overflowX:'hidden'
    },
    activityLabelStyle : {
        padding:'10px 16px',
        marginTop: '5px',
    },
    MuiAccordionroot: {
        "&.MuiAccordion-root:before": {
          backgroundColor: "white",
          margin:'-8px 0px'
        }
    },
    MuiAccordionSummaryroot : {
        '&.Mui-expanded' : {
            marginBottom: '-3px'
        }
    },
    MuiAccordionSummaryexpandIcon : {
        transform: 'rotate(-90deg)',
        '&.Mui-expanded' : {
        transform: 'rotate(0deg)'
        }
    },
    rightdownicon : {
        marginRight: '7px',
        marginTop:'8px',
        width: '1.5em',
        height: '1.5em',
        fontSize: '1rem',
        transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        flexShrink: 0,
        userSelect: 'none',
    },
    icons : {
        marginRight:'8px',
        marginLeft: '2px', 
        fontSize: '16px',
        lineHeight: 0
    },
    listStyleContainer: {
        padding:'0', 
        listStyle:'none', 
        width:'100%',
        marginTop:'-10px'
    },
    listStyle: {
        marginLeft:'auto !important',
        color: '#ccc',
        padding:'0 8px 0px 0px',
    },
    templateAndReportList : {
        //paddingRight: '0px',
        display:'flex',
        padding:'4px 0px 4px 6px',
        marginBottom:'4px',
        '&:last-child': {
            marginBottom:'0px'
        }
    },
    selectedTemplateStyle:{
        background:'rgba(0, 0, 0, 0.08) !important'
    }, 
    templateName : {
        width:'160px',
        wordWrap:'break-word',
    },
    invoicesLinkStyle: {
        display:'flex !important', 
        alignItems:'center !important',
        width:'100% !important', 
        textDecoration:'none !important'
    },
    externalImg: {
        marginLeft:'8px !important',
        filter: "invert(28%) sepia(0%) saturate(18%) hue-rotate(259deg) brightness(96%) contrast(83%)",
    },
    text: {
        fontWeight:600,
    },
    listitem : {
        fontSize: '15px',
        fontWeight: 400,
        color:'#202020',
        '&:first-child' : {
            marginTop:'-8px'
        },
        '&:selected' : {
            background:'transparent'
        }    
    },
}));

const MobileLeftMenuComponent = (props) => {
    const classes = useStyles();
    const isMount = useIsMount();
    
    const [showOrgList, setShowOrgList] = useState(false);
    const [orgChanged, setOrgChanged] = useState(null);

    const { access_level } = props.selectedOrganization || {};
    const accessLevelManager = shouldDisable(AccessLevel.getUserAccessLevelValue(access_level), AccessLevel.MANAGER);
    const accessLevelGuest = (AccessLevel.getUserAccessLevelValue(access_level) === AccessLevel.GUEST) ? true : false;

    useEffect(() => {
        if(!isMount) {
            if(orgChanged) {
                const organization_id = props.selectedOrganization.organization.id;
                props.fetchOrgDetails(organization_id, props.startDateFilter, props.endDateFilter, 
                    props.companiesFilter, props.selectedMembersFilter, props.selectedTemplate, props.auth.user?.scope);
                setOrgChanged(false)
            }
        }
    }, [orgChanged]);

    const onTemplateClick = (template) => {
        //trackGAEvent(props.selectedOrganization.organization.name, 'Open Template', `${props.auth.user.firstname} ${props.auth.user.lastname} opened template ${template.name}`);
        props.setSelectedDrawerState(SelectedDrawerState.NOT_SELECTED);
        props.setSelectedTemplate(template);
        history.push('/dashboard')
    }

    const onOrganizationMemberClick = () => {
        trackGAEvent(props.selectedOrganization.organization.name, 'Open Member', `${props.auth.user.firstname} ${props.auth.user.lastname} opened Members`);
        props.setSelectedTemplate('');
        props.setSelectedDrawerState(SelectedDrawerState.SHOW_ORG_MEMBERS);
    }

    const onCompletionReportClick = () => {
        trackGAEvent(props.selectedOrganization.organization.name, 'Completion Report', `${props.auth.user.firstname} ${props.auth.user.lastname} clicked completion report`);
        props.setSelectedTemplate('');
        props.setSelectedDrawerState(SelectedDrawerState.SHOW_COMPLETION_REPORT);
    }

    const onWorkLoadReportClick = () => {
        trackGAEvent(props.selectedOrganization.organization.name, 'Workload Report', `${props.auth.user.firstname} ${props.auth.user.lastname} clicked workload report`);
        props.setSelectedTemplate('');
        props.setSelectedDrawerState(SelectedDrawerState.SHOW_WORKLOAD_REPORT);
    }

    const onOrganizationLabelsClick = () => {
        trackGAEvent(props.selectedOrganization.organization.name, 'Open Labels', `${props.auth.user.firstname} ${props.auth.user.lastname} opened Labels`);
        props.setSelectedTemplate('');
        props.setSelectedDrawerState(SelectedDrawerState.SHOW_ORG_LABELS);
    }

    const onOrganizationCompaniesClick = () => {
        trackGAEvent(props.selectedOrganization.organization.name, 'Open Companies', `${props.auth.user.firstname} ${props.auth.user.lastname} opened Companies`);
        props.setSelectedTemplate('');
        props.setSelectedDrawerState(SelectedDrawerState.SHOW_ORG_COMPANIES);
    }

    const onOrganizationSettingsClick = () => {
        trackGAEvent(props.selectedOrganization.organization.name, 'Open Settings', `${props.auth.user.firstname} ${props.auth.user.lastname} opened Settings`);
        props.setSelectedTemplate('');
        props.setSelectedDrawerState(SelectedDrawerState.SHOW_ORG_SETTINGS);
    }

    const onActivityReportClick = () => {
        trackGAEvent(props.selectedOrganization.organization.name, 'Open Activity', `${props.auth.user.firstname} ${props.auth.user.lastname} opened activity`);
        props.setSelectedTemplate('');
        props.setSelectedDrawerState(SelectedDrawerState.SHOW_ACTIVITY_REPORT);
    }

    const onEmailReportClick = () => {
        trackGAEvent(props.selectedOrganization.organization.name, 'Email Report', `${props.auth.user.firstname} ${props.auth.user.lastname} clicked email report`);
        props.setSelectedTemplate('');
        props.setSelectedDrawerState(SelectedDrawerState.SHOW_EMAIL_REPORT);
    }

    const onChangeOrgClick = (e, org) => {
        history.push('/dashboard');
        if (org.organization.id !== props.selectedOrganization.organization.id) {
            consoleToLog('Change Organization Clicked');
            //Set selected org
            props.setSelectedOrg(org);
            props.clearSelectedFilters();
            if(props.setDrawerState !== SelectedDrawerState.NOT_SELECTED) {
                props.setSelectedTemplate('');
            } else {
                props.setSelectedTemplate();
            }
            setOrgChanged(true);
            props.setLoading(true);
        }
    }

    const logoutFirebase = () => {
        firebase.auth().signOut();
    }

    const onLogoutClick = () => {
        logoutApi(props.auth.access_token)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response logoutApi: ', res);

                props.logout();
                props.logoutOrgs();
                props.logoutSelectedOrg();
                props.logoutFilters();
                history.push('/login');
                logoutFirebase();
            })
            .catch((e) => {
                consoleToLog('Error logoutApi: ', e.response);
                if(e.response.data && e.response.data.message) {
                    return;
                }
            })
        
    };

    const showOrganisationList  = () => {
        return (
            <List disablePadding>
                {
                    props.organizations && props.organizations.length > 0 &&
                    props.organizations.map((org) => {
                        return <ListItem key={org.id} 
                                    selected={props.selectedOrganization.organization.id === org.organization.id}
                                    onClick={(e) => onChangeOrgClick(e, org)}>
                                    {org.organization.name}
                                </ListItem>
                    })
                }
            
            </List>
        )

    }

    return (
        <Drawer open={props.showDrawer} 
            onClose={props.closeDrawer}
            variant='temporary'
            anchor='left'
            elevation={0}
            classes={{
                paper: classes.drawerPaper
            }}
            >
                <Grid item container direction='column'>
                    <MobileLeftComponentTopBar  setShowOrgList={setShowOrgList}
                        showOrgList={showOrgList}
                        history={history}/>

                {showOrgList ?
                <Grid item  style={{marginTop:'16px'}}>
                    {showOrganisationList()}
                </Grid>
                :
                <Grid item style={{marginTop:'16px'}}>

                    {props.isLoading ? 
                    <Grid item container justifyContent='center' style={{marginTop:'16px'}}>
                        <CircularProgress size={28}/>
                    </Grid>
                    :
                    <Accordion elevation={0} defaultExpanded="true" 
                        className={classes.templatehover}
                        classes={{
                            root: classes.MuiAccordionroot,
                        }}
                    >
                        <div>
                            <AccordionSummary
                                style={{flexDirection: 'row-reverse', display: "inline-flex", padding: 0,  minHeight: "0"}}
                                //className={classes.summary}
                                //expandIcon={<ExpandMoreIcon className={classes.rightdownicon}/>}
                                expandIcon={<i className={`flaticon-down-arrow ${classes.rightdownicon}`}></i>}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                classes = {{
                                    root: classes.MuiAccordionSummaryroot,
                                    expandIcon: classes.MuiAccordionSummaryexpandIcon
                                }}
                            >
                                <Typography className={classes.text} style={{marginLeft:'-1px'}} 
                                    variant="body1">Templates</Typography>
                            </AccordionSummary>
                            {/* <IconButton aria-label="delete" style={{float: "right"}}>
                                <i className="flaticon-more ellipsisIcon" style={{fontSize: "1.3rem", color: "#202020"}}></i>
                            </IconButton> */}
                        </div>
                        <AccordionDetails style={{paddingBottom:'0px', marginLeft:'20px'}}>
                            <ul className={classes.listStyleContainer}>
                                {
                                    (props.templates && props.templates.length > 0) ?
                                    props.templates.map((template) => {
                                        let template_id = props.selectedTemplate ? props.selectedTemplate.template_id : undefined
                                        return <li key={template.template_id} 
                                                    onClick={() => onTemplateClick(template)}
                                                    className={props.setDrawerState === SelectedDrawerState.NOT_SELECTED && template_id === template.template_id ?
                                                        `${classes.templateAndReportList} ${classes.selectedTemplateStyle}` : classes.templateAndReportList}>
                                                        
                                                        <Tooltip title={template.name} arrow>
                                                            <Typography variant='body1' className={classes.templateName}>
                                                                {template.name}  
                                                            </Typography>
                                                        </Tooltip>
                                                        <div style={{marginLeft:'auto'}}>
                                                            <span className={classes.listStyle}>
                                                                {template.checklist_count}
                                                            </span>
                                                        </div>
                                                </li>
                                    }) :
                                    <ListItem button className={classes.listitem} >No Templates Found</ListItem>
                                }
                            </ul>
                        </AccordionDetails>
                    </Accordion>}
                    {props.superAdminUser &&
                    <ListItem button className={classes.activityLabelStyle}
                        selected={props.setDrawerState === SelectedDrawerState.CREATE_PREDEFINED_TEMPLATE} 
                        //onClick={onPredefinedTemplateClick}
                        >
                        <i className={`flaticon-clipboards-2 ${classes.icons}`}></i>
                        <Typography variant="body1" className={classes.text}>Predefined Templates</Typography>
                    </ListItem>}

                    {!accessLevelGuest && <Accordion elevation={0} defaultExpanded="true" 
                         classes={{
                            root: classes.MuiAccordionroot
                        }}
                    >
                        <div>
                            <AccordionSummary
                                style={{flexDirection: 'row-reverse', display: "inline-flex", padding: 0,  minHeight: "0"}}
                                //className={classes.summary}
                                expandIcon={<i className={`flaticon-down-arrow ${classes.rightdownicon}`}></i>}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                classes = {{
                                    root: classes.MuiAccordionSummaryroot,
                                    expandIcon: classes.MuiAccordionSummaryexpandIcon
                                }}
                            >
                                <Typography className={classes.text} variant="body1">Reports</Typography>
                            </AccordionSummary>
                        </div>
                        <AccordionDetails  className={classes.detail}>
                            <List component="div" disablePadding>
                                <ListItem button className={classes.listitem}
                                    selected={props.setDrawerState === SelectedDrawerState.SHOW_COMPLETION_REPORT}   
                                    onClick={onCompletionReportClick}>
                                    Completion Report
                                </ListItem>
                                <ListItem button className={classes.listitem}
                                    selected={props.setDrawerState === SelectedDrawerState.SHOW_WORKLOAD_REPORT}   
                                    onClick={onWorkLoadReportClick}>
                                    Workload Report
                                </ListItem>
                                {/* <ListItem button className={classes.listitem}
                                    selected={props.setDrawerState === SelectedDrawerState.SHOW_FOLLOWUP_REPORT}   
                                    onClick={onFollowupReportClick}>
                                    Follow Up Report
                                </ListItem> */}
                                <ListItem button className={classes.listitem}
                                    selected={props.setDrawerState === SelectedDrawerState.SHOW_EMAIL_REPORT}   
                                    onClick={onEmailReportClick}>
                                    Email Report
                                </ListItem>
                                {/* <ListItem button className={classes.listitem}>
                                    Activity Report
                                </ListItem> */}
                            </List>
                        </AccordionDetails>
                    </Accordion>}
                    {!accessLevelGuest && <List component="div" disablePadding>
                        <ListItem button className={classes.labels}
                                selected={props.setDrawerState === SelectedDrawerState.SHOW_ORG_MEMBERS}   
                                onClick={onOrganizationMemberClick}>
                            <i className={`flaticon-group ${classes.icons}`}></i>
                            <Typography variant="body1" className={classes.text}>Members</Typography>
                        </ListItem>
                        <ListItem button className={classes.labels}
                                selected={props.setDrawerState === SelectedDrawerState.SHOW_ORG_LABELS}   
                                onClick={onOrganizationLabelsClick}
                                >
                            <i className={`flaticon-price-tag ${classes.icons}`}></i>
                            <Typography variant="body1" className={classes.text}>Labels</Typography>
                        </ListItem>
                        <ListItem button className={classes.labels}
                                selected={props.setDrawerState === SelectedDrawerState.SHOW_ORG_COMPANIES}
                                onClick={onOrganizationCompaniesClick}
                        >
                        <i className={`flaticon-business-and-trade ${classes.icons}`}></i>
                            <Typography variant="body1" className={classes.text}>Clients</Typography>  
                        </ListItem>
                        
                        {props.isInvoiceAccessor &&
                        <ListItem button className={classes.labels}
                                //selected={props.setDrawerState === SelectedDrawerState.SHOW_ORG_COMPANIES}
                                //onClick={onInvoiceTabClick}
                        >
                        <Link href={`${process.env.REACT_APP_INVOICE_APP_DOMAIN_NAME}?access_token=${window.btoa(props.auth.access_token)}`} 
                                target="_blank"
                                rel="noreferrer"
                                className={classes.invoicesLinkStyle}>   
                            <i className={`flaticon-file ${classes.icons}`}></i>
                            <Typography variant="body1" className={classes.text}>Invoices</Typography>
                            <img src="/images/external_link.svg" className={classes.externalImg} width="20px" height="18px"/>  
                            </Link>
                        </ListItem>}
                        
                        <ListItem button className={classes.labels}
                                selected={props.setDrawerState === SelectedDrawerState.SHOW_ORG_SETTINGS}
                                onClick={onOrganizationSettingsClick}
                        >
                            <i className={`flaticon-settings-1 ${classes.icons}`}></i>
                            <Typography variant="body1" className={classes.text}>Settings</Typography>
                        </ListItem>

                        <ListItem button className={classes.labels}
                                onClick={onLogoutClick}
                        >
                            <ExitToAppIcon className={classes.icons}/>
                            <Typography variant="body1" className={classes.text}>Logout</Typography>
                        </ListItem>
                    </List>
                    
                    }
                </Grid>}

                </Grid>
        </Drawer>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    organizations: state.organizations,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    templates: state.organizationInfo.templates,
    setDrawerState: state.organizationInfo.setDrawerState,
    selectedTemplate: state.organizationInfo.selectedTemplate,
    isInvoiceAccessor: state.organizationInfo.isInvoiceAccessor,
    isLoading: state.loading.isLoading,
    startDateFilter: state.filters.startDateFilter,
    endDateFilter: state.filters.endDateFilter,
    selectedMembersFilter: state.filters.selectedMembersFilter,
    companiesFilter: state.filters.companiesFilter,
});

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(logout()),
    logoutOrgs: () => dispatch(logoutOrgs()),
    logoutSelectedOrg: () => dispatch(logoutSelectedOrg()),
    setLoading: (isLoading) => dispatch(setLoading(isLoading)),
    logoutFilters: () => dispatch(logoutFilters()),
    clearSelectedFilters: () => dispatch(clearSelectedFilters()),
    clearActivityReportFilter: () => dispatch(clearActivityReportFilter()),

    setSelectedOrg: (organization) => dispatch(setSelectedOrg(organization)),
    fetchOrgDetails: (organization_id, start_date, end_date, companiesFilter, selectedMembersFilter, selectedTemplate, scope) => 
    dispatch(fetchOrgDetails(organization_id, start_date, end_date, companiesFilter, selectedMembersFilter, selectedTemplate, scope)),
    setCDSalt: (salt) => dispatch(setCDSalt(salt)),

    setSelectedTemplate: (template) => dispatch(setSelectedTemplate(template)),
    setSelectedDrawerState: (drawerState) => dispatch(setSelectedDrawerState(drawerState)),
    setOrgTemplates: (templates) => dispatch(setOrgTemplates(templates))
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileLeftMenuComponent);