import React, {useState, useEffect} from 'react';
import {Drawer, Grid, Typography, Button, AppBar, Toolbar, IconButton, CircularProgress, Avatar, Tooltip, List, ListItem, ListItemText, Divider} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import { getClientDetailsObjectApi } from '../../services/clientService';
import { connect } from 'react-redux';
import {setCurrentEmailState, setEmailSentFrom, setFromChecklistDetailsPage} from '../../actions/selectedOrganization';
import { setEmailObj } from '../../util/AppUtil';
import { consoleToLog } from '../../util/AppUtil';

const useStyles = makeStyles((theme) => ({
    drawerPaper: {
        width: '36%',
        height: '100vh',
        flexGrow: 1,
    },
    appBar: {
        background:'#F9F9F9',
        height:'45px',
        color:'#202020'
    },
    headings: {
        fontWeight: 600,
        color:'#202020',
    },
    emailColor: {
        color: "#0071ff",
        padding:"0px 4px"
    }
}))

const ChecklistClientDetailsModal = (props) => {
    const classes = useStyles();
    
    const [clientObj, setClientObj] = useState(undefined);
    const [contactArr,setContactArr] = useState([]);
    const [loading, setLoading] = useState(false);
     
    useEffect(() => {
        getClientListForCheckList();
      },[])

    const getClientListForCheckList = () => {

        const client_id = props.checkListItem.company?.id;
            
        setLoading(true);

        getClientDetailsObjectApi(client_id)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response getClientDetailsObjectApi: ', res);
                setLoading(false);
                setClientObj(res.client);
                setContactArr(res.client.contacts);
                
            })
            .catch((e) => {
                consoleToLog('Response getClientDetailsObjectApi: ', e.response);
                setLoading(false);
                if(e.response.data && e.response.data.message) {
                    return;
                }
            })
        

    }


    const onEmailClick = (contact) =>{
        let filterContactArr = contactArr.filter((contactObj) => contactObj.id === contact.id);
        let company = {
            ...props.checkListItem.company,
            contacts: filterContactArr
        }
        let updatedObj = {
            ...props.checkListItem,
            company
        }
        const data = setEmailObj(updatedObj, props.selectedTemplate);
        props.setCurrentEmailState(data);
        props.handleSendEmailDialogOpen();
        props.setFromChecklistDetailsPage(true);
        props.setEmailSentFrom('Checklist Details');
    }
    const getContactString = (contact) => {
        if(!contact.first_name && !contact.last_name && !contact.phone_number)
        {
            return <Button style={{padding: 0}} onClick={() => onEmailClick(props.checkListItem)}>{contact.email}</Button>
        }
        else if(!contact.first_name && !contact.last_name)
        {
            return <Grid item container alignItems='center'>
                        <Button className={classes.emailColor} onClick={() => onEmailClick(contact)}>
                            {contact.email}
                        </Button>{` - ${contact.phone_number}`}
                    </Grid>
        }
        else if(!contact.phone_number)
        {
            return <Grid item container alignItems='center'>
                    {`${contact.first_name} ${contact.last_name}  -`}
                    <Button className={classes.emailColor} onClick={() => onEmailClick(contact)}>
                        {contact.email}
                    </Button>
                    </Grid>
        }
        else
        {
            return <>{`${contact.first_name} ${contact.last_name}  -`}<Button style={{padding:"0px 4px"}} onClick={() => onEmailClick(props.checkListItem)}>{contact.email}</Button>{`  - ${contact.phone_number}`}</>
        }
    }

    return (
        <Drawer variant="temporary"
            anchor="right"
            open={props.openDrawer}
            onClose={props.handleDrawerClose}
            classes={{
                paper: classes.drawerPaper
            }}>
            <AppBar position="static" className={classes.appBar} elevation={0}>
                <Toolbar style={{minHeight:'40px', padding:'0px 16px', }}>
                    <Typography variant="body1" 
                        style={{fontWeight:'600', fontSize:'16px', marginRight:'auto'}}>
                        Client Details
                    </Typography>
                    <IconButton style={{color:'#202020', marginRight:'-16px'}} 
                        onClick={props.handleDrawerClose}
                    >
                        <ClearIcon/>
                    </IconButton>
                </Toolbar>
            </AppBar>
            
        <div>
            <Grid item md={12} style={{padding:'16px'}}>
            {
                    <Grid item container spacing={1}>
                        <Grid item md={6}>
                            <Typography variant="body1" className={classes.headings}>
                                Client Name 
                            </Typography>
                            <Typography variant="subtitle1" >
                                {clientObj?.name}
                            </Typography>
                        </Grid>

                        <Grid item md={6}>
                            <Typography variant="body1" className={classes.headings}>
                                Tax Id 
                            </Typography>
                            <Typography variant="subtitle1">
                                {clientObj?.tax_id ? clientObj?.tax_id : '-'}
                            </Typography>                  
                        </Grid>

                        <Grid item md={6}>
                            <Typography variant="body1" className={classes.headings}>
                                Mobile Number 
                            </Typography>
                            <Typography variant="subtitle1" >
                                {clientObj?.phone_number ? clientObj?.phone_number : '-'}
                            </Typography>                  
                        </Grid>

                        <Grid item md={6}>
                            <Typography variant="body1" className={classes.headings}>
                                Address 
                            </Typography>
                            <Typography variant="subtitle1">
                                {clientObj?.address ? clientObj?.address : '-'}
                            </Typography>                  
                        </Grid>

                        <Grid item md={6}>
                            <Typography variant="body1" className={classes.headings}>
                                Country
                            </Typography>
                            <Typography variant="subtitle1">
                                {clientObj?.country ? clientObj?.country : '-'}
                            </Typography>                  
                        </Grid>

                        <Grid item md={6}>
                            <Typography variant="body1" className={classes.headings}>
                                State
                            </Typography>
                            <Typography variant="subtitle1">
                                {clientObj?.state ? clientObj?.state : '-'}
                            </Typography>                  
                        </Grid>

                    </Grid>   
                }
            </Grid>
            
            <Divider light/>
            
            {contactArr && contactArr.length > 0 &&
            <Grid item md={12} style={{padding:'16px'}}>
                <Grid item container>
                    <Grid item md={12}>
                        <Typography variant='h6' className={classes.headings}>
                            Contacts
                        </Typography>
                    </Grid>
                </Grid>
            {loading ? <CircularProgress/>
                :
            <Grid>
            {
                contactArr && contactArr.length > 0 && 
                contactArr.map((contact, index) => {
                    return <Typography variant="subtitle2" style={{marginTop:"5px"}}>
                                {getContactString(contact)}
                            </Typography> 
                })
            }
            </Grid>
            }
            </Grid>}
        </div>
    </Drawer>
);
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    selectedTemplate: state.organizationInfo.selectedTemplate
});

const mapDispatchToProps = (dispatch) => ({
    setCurrentEmailState: (emailState) => dispatch(setCurrentEmailState(emailState)),
    setFromChecklistDetailsPage: (fromChecklistDetails) => dispatch(setFromChecklistDetailsPage(fromChecklistDetails)),
    setEmailSentFrom: (str) => dispatch(setEmailSentFrom(str))
  })

export default connect(mapStateToProps, mapDispatchToProps)(ChecklistClientDetailsModal)