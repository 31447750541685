import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Grid, Typography, Button, Box, Tabs, Tab, Paper, CircularProgress} from '@material-ui/core';
import { connect } from 'react-redux';
import OnboardingOrganization from './OnboardingOrganization';
import OnboardingSupportEmails from './OnboardingSupportEmails';
import { setSelectedOrgEmailObj, setSelectedDrawerState, SelectedDrawerState } from '../../actions/selectedOrganization';
import OnboardingMembers from './OnboardingMembers';
import OnboadingTemplates from './OnboadingTemplates';
import OnboardingClients from './OnboardingClients';
import { history } from '../../routers/AppRouter';
import {getOrgSupportEmailsApi} from '../../services/genieService';
import {setOrgEmails} from '../../actions/selectedOrganization';
import { consoleToLog, shouldDisable, AccessLevel } from '../../util/AppUtil';
import {updateUserLoginApi} from '../../services/authService';
import { login } from '../../actions/auth';
import { getOrganizationList,  setSelectedTemplate } from '../../actions/selectedOrganization';
import { useIsMount } from '../useIsMount';
import { setLoading } from '../../actions/loading';

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        width:'100vw',
        height:'100vh',
        overflow:'auto'
    },
    onBoardingDesignStyle : {
        background: '#3D3D3D',
        height:'100vh',
        padding:'40px 48px'
    },
    logoStyle: {
        width: '220px',
        height: '49.18px',
        [theme.breakpoints.down(1400)] : {
            width: '190px',
            height: '40px',
        }
    },
    headingContainer: {
        marginTop:'70px'
    },
    headingTextStyle: {
        fontSize:'25px',
        fontWeight: '600',
        color:'#fff',
        lineHeight:'31px',
        [theme.breakpoints.down(1400)] : {
            fontSize:'21px'
        }
    },
    paragraphTextStyle: {
        marginTop:'16px',
        color:'#fff',
        fontSize:'13px',
    },
    imageContainer : {
        marginTop:'72px',
        [theme.breakpoints.down(1400)] : {
            marginTop:'52px'
        }
    },
    rightPanelStyle: {
        background:  theme.palette.primary.light,
        height:'100vh',
        display:'flex',
        justifyContent:'center',
        alignItems:'center'
    },
    greetingHeadingStyle: {
        fontSize:'20px',
        fontWeight:'600'
    },
    greetingTextStyle: {
        fontSize:'16px',
        marginTop:'16px',
        color:'#333'
    },
    innerbg: {
        border: "1px solid #dbdbdb",
        borderRadius: "10px",
        padding: "32px 48px",
        background: "#ffffff",
        height:'auto',
        maxHeight:'75vh',
        overflow:'auto'
        // [theme.breakpoints.down("md")]: {
        //     padding: "45px 20px",
        //     }
    },
    onboardHeading: {
        fontSize : "19px",
        fontWeight: "600",
        margin:'0px auto 8px auto',
    },
    tab: {
        minWidth:'15px',
        height: '8px !important',
        marginLeft:'4px',
        background:'#cbcbcb',
        borderRadius:"10px",
        cursor:'pointer',
        '&:first-child': {
            marginLeft:'0px',
        },
    },
    tabSelected: {
        width:'50px',
        backgroundColor: '#555555 !important',
    },
    imageStyle: {
        marginLeft:'16px',
        [theme.breakpoints.down(1400)] : {
            width:'75%'
        }
    }
}))

const OnboardingComponent = (props) => {
    const classes = useStyles();
    const isMount = useIsMount();

    const [tabValue, setTabValue] = useState(0);
    const [callApi, setCallApi] = useState(false);
    const [loading, setLoading] = useState(true);
    const [orgMembers, setOrgMembers] = useState([...props.members]);

    const { access_level } = props.selectedOrganization || {};
    const accessLevelOwner = shouldDisable(AccessLevel.getUserAccessLevelValue(access_level), AccessLevel.OWNER);

    useEffect(() => {
            props.setLoading(true);
            props.getOrganizationList(props.selectedOrganization, props.startDateFilter, props.endDateFilter, 
                props.companiesFilter, props.selectedMembersFilter, props.selectedTemplate, props.auth.user?.scope);
            if(props.auth.user.first_login) {
                updateUserLogin();
            }
    }, []);

    useEffect(() => {
        if(!isMount) {
            if(!props.isLoading) {
                if(!accessLevelOwner && props.selectedOrganization.organization.onboarding) {
                    setLoading(false);
                    if(props.selectedOrgEmailObj?.fromOnboardingComponent) {
                        setTabValue(1);
                        props.setSelectedOrgEmailObj();
                    }
                } else {
                    history.push('/dashboard');
                }
            }
        }
    }, [props.isLoading]);

    const showHeadingTitle = () => {
        switch (tabValue) {
            case 0:
                return 'Add Organization Info'

            case 1:
                return 'Add Support Email'

            case 2:
                return 'Invite Members'

            case 3:
                return 'Import Template Or Create Template'

            case 4:
                return 'Add Client'
                
        
            default:
                break;
        }
    }

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    }

    const onNextClick = () => {
        if(tabValue < 4) {
            if(tabValue === 0) {
                setCallApi(true);
            } else {
                setTabValue(tabValue => tabValue + 1);
            }
        } else {
            props.setSelectedDrawerState(SelectedDrawerState.SHOW_ONBOARDING);
            history.push('/dashboard');
        }
    }

    const updateUserLogin = () => {

        updateUserLoginApi()
            .then((response) => {
                const res = response.data;
                consoleToLog('Response updateUserLoginApi: ', res);

                const obj = {
                    ...props.auth,
                    user: res
                }
                props.login(obj);
            })
            .catch((e) => {
                consoleToLog('Error updateUserLoginApi: ', e.reponse);
                if(e.response.data && e.response.data.message) {
                    return;
                }
            })
    }

    return (
        <div className={classes.mainContainer}>
            {loading ?
            <Grid item container justifyContent='center' alignItems='center' style={{marginTop:'18px'}}>
                <CircularProgress size={28}/>
            </Grid>
            :
            <Grid item container>
                <Grid item md={4} className={classes.onBoardingDesignStyle}>
                        <Grid item container direction='column'>
                            <Grid item md={12}>
                                <img src='/images/eProcessify_logo_name_white.png' className={classes.logoStyle}/>
                            </Grid>

                            <Grid item md={12} className={classes.headingContainer}>
                                <Typography className={classes.headingTextStyle}>
                                    eProcessify is a CA office automation tool for Chartered Accountants and Accounting firms
                                </Typography>

                                <Typography className={classes.paragraphTextStyle}>
                                    Automate Recurring Task creation, Automate client followups, Automate Invoice generation and make your practice run smoother
                                </Typography>
                            </Grid>

                            <Grid item md={12} className={classes.imageContainer}>
                                <img src='/images/on_borboarding_image.svg' className={classes.imageStyle}/>
                            </Grid>
                        </Grid>
                </Grid>

                <Grid item md={8} className={classes.rightPanelStyle}>
                    <Grid item md={7}>
                        <Grid item md={12}>
                            <Typography className={classes.greetingHeadingStyle}>
                                Greetings {props.auth.user.firstname}
                            </Typography>

                            <Typography className={classes.greetingTextStyle}>
                                We appreciate your decision to be part of <span style={{fontWeight:500, color:'#555'}}>eProcessify.</span> To initiate you journey, we suggest you complete these steps.
                            </Typography>
                        </Grid>

                        <Grid item md={12} style={{marginTop:'32px'}}>
                        
                            <Grid item md={12} className={classes.innerbg}>
                                <Typography className={classes.onboardHeading}>
                                    {showHeadingTitle()}
                                </Typography>

                                {tabValue === 0 && <OnboardingOrganization callApi={callApi}
                                                        setCallApi={setCallApi}
                                                        setTabValue={setTabValue}
                                                        />}
                                {tabValue === 1 && <OnboardingSupportEmails />}
                                {tabValue === 2 && <OnboardingMembers orgMembers={orgMembers}
                                                        setOrgMembers={setOrgMembers}/>}
                                {tabValue === 3 && <OnboadingTemplates />}
                                {tabValue === 4 && <OnboardingClients setTabValue={setTabValue}/>}

                                <Grid item container direction='column' >
                                    <Grid item md={12} style={{margin: '24px 0px 16px 0px'}}>  
                                        <Button variant="contained" color="primary" 
                                            className="modal__button"
                                            fullWidth
                                            onClick={onNextClick}
                                            >
                                            {tabValue === 4 ? 'Finish' : 'Next'}
                                        </Button>
                                    </Grid>

                                    <Grid item  md={12}>    
                                        <Button 
                                            className="modal__button"
                                            fullWidth
                                            disabled={tabValue === 4}
                                            style={{background:'#fff'}}
                                            onClick={() => setTabValue(tabValue => tabValue + 1)}
                                            >
                                            Skip
                                        </Button>
                                    </Grid>
                            
                                </Grid>
                            </Grid>

                            <Grid item container style={{marginTop:"10px"}}>
                                <div tabIndex={0} 
                                    onClick={(e) => {
                                        setTabValue(0);
                                    }}
                                    className={tabValue === 0 ? `${classes.tab} ${classes.tabSelected}` :  classes.tab}/>
                                <div tabIndex={1} 
                                    onClick={(e) => {
                                        setTabValue(1);
                                    }}
                                    className={tabValue === 1 ? `${classes.tab} ${classes.tabSelected}` :  classes.tab}/>
                                <div tabIndex={2} 
                                    onClick={(e) => {
                                        setTabValue(2);
                                    }}
                                    className={tabValue === 2 ? `${classes.tab} ${classes.tabSelected}` :  classes.tab}/>
                                <div tabIndex={3} 
                                    onClick={(e) => {
                                        setTabValue(3);
                                    }}
                                    className={tabValue === 3 ? `${classes.tab} ${classes.tabSelected}` :  classes.tab}/>
                                <div tabIndex={4} 
                                    onClick={(e) => {
                                        setTabValue(4);
                                    }}
                                    className={tabValue === 4 ? `${classes.tab} ${classes.tabSelected}` :  classes.tab}/>
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>
            </Grid>}
        </div>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    selectedOrgEmailObj: state.organizationInfo.selectedOrgEmailObj,
    isLoading: state.loading.isLoading,
    selectedTemplate: state.organizationInfo.selectedTemplate,
    startDateFilter: state.filters.startDateFilter,
    endDateFilter: state.filters.endDateFilter,
    selectedMembersFilter: state.filters.selectedMembersFilter,
    companiesFilter: state.filters.companiesFilter,
    setDrawerState: state.organizationInfo.setDrawerState,
    templates: state.organizationInfo.templates,
    selectedStatusFilter: state.filters.selectedStatusFilter,
    orgEmailList: state.organizationInfo.orgEmailList,
    members: state.organizationInfo.members
})

const mapDispatchToProps = (dispatch) => ({
    setSelectedOrgEmailObj: (emailObj) => dispatch(setSelectedOrgEmailObj(emailObj)),
    setOrgEmails: (emails) => dispatch(setOrgEmails(emails)),
    setSelectedDrawerState: (drawerState) => dispatch(setSelectedDrawerState(drawerState)),
    login: (auth) => dispatch(login(auth)),
    getOrganizationList: (selectedOrganization, start_date, end_date, companiesFilter, selectedMembersFilter, selectedTemplate, scope) => 
        dispatch(getOrganizationList(selectedOrganization, start_date, end_date, companiesFilter, selectedMembersFilter, selectedTemplate, scope)),
    setLoading: (isLoading) => dispatch(setLoading(isLoading))
})

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingComponent);