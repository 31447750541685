import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {Grid, Typography, Button, Tooltip, Avatar, Popover, MenuItem, TextField} from '@material-ui/core';
import {capitalize, consoleToLog, trackGAEvent, shouldDisable, AccessLevel} from '../../util/AppUtil';
import { removeOrgMember } from '../../actions/selectedOrganization';
import { useSnackbar } from 'notistack';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { addMemberToOrgApi, createPendingInvitationApi } from '../../services/authService';
import { useIsMount } from '../useIsMount';
import { emailValidator } from '../../util/validator';

const useStyles = makeStyles((theme) => ({
    memberContainer: {
        flexGrow: 1,
        background: 'white',
        padding:'15px',
        //height:'100vh',
        marginBottom:'25px'
    },   
    memberColumn : {
        background: '#F9F9F9',
        borderRadius: '5px',
        marginBottom: '8px',
        padding:'8px 16px 8px 8px',
        '&:last-child' : {
            marginBottom: '0px'
        },
        "& .deleteIcon" : {
            visibility: 'hidden'
        },
        "&:hover .deleteIcon" : {
            visibility: 'visible'
        },
        '& .more_actionButton1' : {
            visibility:'hidden',
            width:'90px !important',
            background:'#fff',
            border:'1px solid #ccc',
            borderRadius:'5px',
            padding:'1px 0px',
            margin:'6px 0px',
            fontSize:'13px',
        },
        '& .more_actionButton2' : {
            visibility:'hidden',
            width:'180px !important',
            background:'#fff',
            border:'1px solid #ccc',
            borderRadius:'5px',
            padding:'1px 0px',
            margin:'6px 0px',
            fontSize:'13px',
        },
        '&:hover .more_actionButton1' : {
            visibility:'visible'
        },
        '&:hover .more_actionButton2' : {
            visibility:'visible'
        },
    },
    avatarStyle: {
        width:"38px !important",
        height:"38px !important",
        fontSize:'16px !important',
        fontWeight:'600 !important',
        transform: 'translateY(3px)'
    },
    memberText: {
        transform: 'translateY(1.5px)'
    },
    accessLevelStyle: {
        fontSize: "12px",
        color: "#fff",
        background: "#555555",
        padding: "2px 4px",
        borderRadius: "4px",
        fontWeight: 400,
        marginLeft:'8px',
        position:'relative',
        top:'-2px'
    },
    memberContainerStyle: {
        height:'170px', 
        overflowY:'auto', 
        overflowX:'hidden',
        "&::-webkit-scrollbar": {
            display: "none"
        },
        [theme.breakpoints.down(1430)] : {
            height:'70px', 
        }
    }
}))

const OnboardingMembers = (props) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    const isMount = useIsMount();

    const [selectedRowId, setSelectedRowId] = useState(undefined);
    const [email, setEmail] = useState('');
    const [buttonLoading, setButtonLoading] = useState(false);
    
    const roles = ["owner", "manager", "member", "guest"];
    const { access_level } = props.selectedOrganization || {};
    const accessLevelManager = shouldDisable(AccessLevel.getUserAccessLevelValue(access_level), AccessLevel.MANAGER);
    const loggedInUserAccessLevel = AccessLevel.getUserAccessLevelValue(access_level);

    const checkDisabled = (isCurrentlyLoggedIn) => {
        if(isCurrentlyLoggedIn) {
            return true;
        } else {
            return false;
        }

    }

    const verifiedEmailArr = props.orgEmailList && props.orgEmailList.length > 0 ? props.orgEmailList.filter((email) => email.verified) : [];

    const onChangeSelectedRoleType = (e) => {
        const value = e.target.value;
        consoleToLog('********value', value);
        setSelectedRowId(value);
    }

    const addMemberToOrg = () => {

        
            if (!email) {
                enqueueSnackbar('Email cannot be empty', {
                    variant: 'error'
                });
                return;
            }
            const isEmailValid = emailValidator(email);
                if (!isEmailValid) {
                enqueueSnackbar('Email is not valid', {
                    variant: 'error'
                });
                    return;
            }
    
            const role_id = selectedRowId;
            consoleToLog("*************role_id", role_id);
            if(!role_id) {
                enqueueSnackbar('Please Select a Type', {
                    variant: 'error'
                });
                return;
            }
    
            // if(props.orgEmailList && props.orgEmailList.length === 0) {
            //     enqueueSnackbar('No support email added. Please add support email to continue.', {variant:'error'});
            //     return;
            // } 
    
            // if(verifiedEmailArr && verifiedEmailArr.length === 0) {
            //     enqueueSnackbar('No support email verified. Please verify support emails to continue.', {variant:'error'});
            //     return;
            // }

            const service = 'workplace';
            const organization_id = props.selectedOrganization.organization.id;
            const entity_name = props.selectedOrganization.organization.name;
            const entity_email = verifiedEmailArr && verifiedEmailArr.length > 0 && verifiedEmailArr[0]?.email;

            addMemberToOrgApi(service, email, selectedRowId, organization_id, entity_name, entity_email)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response addMemberToOrgApi: ', res);
                setButtonLoading(false);
                enqueueSnackbar(res.message, {
                    variant: 'success'
                });

                trackGAEvent(props.selectedOrganization.organization.name, 'Invite Member', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
                let user = {
                    email: email,
                };

                let obj = {
                    access_level: selectedRowId,
                    user: user
                }

                props.setOrgMembers([...props.members, obj]);

                setEmail('');
                setSelectedRowId(undefined);
                createPendingInvitation(organization_id, selectedRowId, email)
            })
            .catch((e) => {
                consoleToLog("addMemberToOrgApi error", e);
                setButtonLoading(false);
                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant:'error'
                    });
                    return false;
                }
            });
    }

    const createPendingInvitation = (organization_id, role_id, email) => {
            
        createPendingInvitationApi(organization_id, role_id, email)
            .then((response) => {
                    const res = response.data;
                    consoleToLog('Response createPendingInvitationApi', res);
            })
            .catch((e) => {
                consoleToLog("createPendingInvitationApi error", e.response);
                setButtonLoading(false);
                if (e.response.data && e.response.data.message) {
                    return false;
                }
            });
}

    const showMembersList = () => {
        return (
                <Grid item container className={classes.memberContainerStyle}>
                    <Grid item container direction="column">
                        {props.members.map((member) => {
                            let fullName = member.user?.firstname + " " + member.user?.lastname;
                            const isDisabled = checkDisabled(props.auth.user.id === member.user.id);
                            const memberAccessLevel = AccessLevel.getUserAccessLevelValue(member.access_level);
                            return <Grid item container 
                                        key={member.user.id} 
                                        justifyContent='space-between'
                                        className={classes.memberColumn}>

                                        {member.user.firstname ?
                                        <Grid item md={12} lg={12}>
                                            <Grid item container alignItems="center">
                                                <Grid item>
                                                    <Avatar className={classes.avatarStyle}>
                                                    {member.user.firstname.charAt(0).toUpperCase() +
                                                        member.user.lastname.charAt(0).toUpperCase()}    
                                                    </Avatar>
                                                </Grid>
                                                <Grid item className={classes.memberText}>
                                                    <div style={{marginLeft: "16px"}}>
                                                        <Typography style={{fontSize:'16px', fontWeight:'600'}}>
                                                            {fullName} 
                                                            <span className={classes.accessLevelStyle}>
                                                                {capitalize(member.access_level)}
                                                            </span>
                                                        </Typography>
                                                        <Typography variant="subtitle1" style={{color:'#555555'}}>
                                                            {member.user.email}
                                                        </Typography>
                                                    </div>
                                                </Grid>
                                            </Grid>                                                
                                        </Grid>
                                        :
                                        <Grid item md={12} lg={12}>
                                            <Grid item container alignItems="center">
                                                <Grid item>
                                                    <Avatar className={classes.avatarStyle}>
                                                    {member.user.email.charAt(0).toUpperCase()}    
                                                    </Avatar>
                                                </Grid>
                                                <Grid item className={classes.memberText}>
                                                    <div style={{marginLeft: "16px"}}>
                                                        <Typography style={{fontSize:'16px', fontWeight:'600'}}>
                                                            {member.user.email} 
                                                            <span className={classes.accessLevelStyle}>
                                                                {capitalize(member.access_level)}
                                                            </span>
                                                        </Typography>
                                                        <Typography variant="subtitle1" style={{color:'#555555'}}>
                                                            Invited
                                                        </Typography>
                                                    </div>
                                                </Grid>
                                            </Grid>                                                
                                        </Grid>
                                        }
                                    </Grid>
                            })
                        }   
                    </Grid>
                </Grid>
        )
    }

    return (
        <Grid item md={12}>
            {showMembersList()}
            <Grid item md={12} style={{marginTop:props.members && props.members.length > 0 && '16px', overflowX:'hidden'}}>
                <div style={{border:'1px solid #ccc', padding:'16px', borderRadius:'4px'}}>
                <Grid item lg={12}>
                    <Typography variant='subtitle1' className='customMarginBottom'>
                        Enter Email
                    </Typography>
                    <TextField fullWidth
                        margin="dense"
                        variant="outlined"
                        value={email}
                        onFocus={email}
                        placeholder="Enter Email Address"
                        type="text"
                        style={{background: '#fff'}}
                        InputLabelProps={{style: {fontSize: 14, paddingRight:'31px !important'}}}
                        onChange={e => setEmail(e.target.value)}
                    />
                </Grid>
                <Grid item lg={12}>
                    <Typography variant='subtitle1' style={{marginTop:'8px'}} className='customMarginBottom'>
                        Member Type
                    </Typography>
                    <TextField fullWidth
                        margin="dense"
                        variant="outlined"
                        select
                        label= "Select Type"
                        value={selectedRowId ? selectedRowId : ''}
                        style={{background: '#fff'}}
                        InputLabelProps={{style: {fontSize: 14, paddingRight:'31px !important'}}}
                        onChange={onChangeSelectedRoleType}
                    >
                            {
                                roles && roles.map((role) => {
                                return  <MenuItem key={role} value={role}>
                                            {capitalize(role)}
                                        </MenuItem>
                                })
                            }
                    </TextField>    
                </Grid>
                <Grid item lg={12} style={{marginTop:'16px'}}>
                    <Button fullWidth onClick={addMemberToOrg}
                        variant='contained'
                        color='primary'>
                        Invite
                    </Button>   
                </Grid>
                </div>
            </Grid>
        </Grid>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    members: state.organizationInfo.members,
    orgEmailList: state.organizationInfo.orgEmailList
});

const mapDispatchToProps = (dispatch) => ({
    removeOrgMember: (id) => dispatch(removeOrgMember(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingMembers);