import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Provider } from 'react-redux';
import { loadState, saveState } from './localStorage'
import configureStore from './store/configureStore';
import App from './App';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from "./theme";
import { firebase } from './firebase/firebase';
import { checkFirebaseFieldsExist } from './actions/notifications';
import { consoleToLog, isDebug } from './util/AppUtil';
//import { getAuth, onAuthStateChanged } from "firebase/auth";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import ReactGA from "react-ga4";

const persistedState = loadState();
const store = configureStore(persistedState);

//Things you want to be persisted all time
store.subscribe(() => {
  saveState({
      auth: store.getState().auth,
      organizations: store.getState().organizations,
      organizationInfo: store.getState().organizationInfo,
      filters: store.getState().filters,
      overlay: store.getState().overlay,
      notifications: store.getState().notifications,
      activityReports: store.getState().activityReports
  });
});

//google analytics
const initGA4 = () => {
  consoleToLog("***init GA4")
  ReactGA.initialize(process.env.REACT_APP_GA4_API_KEY);
}

if (!isDebug()) {
  initGA4();
} 

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store} >
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Provider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// ReactDOM.render(
//   <Provider store={store} >
//     <ThemeProvider theme={theme}>
//       <App />
//     </ThemeProvider>
//   </Provider>,
//   document.getElementById('root')
// );

//firebase:
firebase.auth().onAuthStateChanged((user) => {
  if (user) {
      consoleToLog('user obj from firebase: ', user);

      const user_id = store.getState().auth.user?.id;
      if (!user_id) return;

      consoleToLog("Logged in with firebase", user_id);
      store.dispatch(checkFirebaseFieldsExist(user_id));
  }
  else {
      consoleToLog("Logged out with firebase");
  }
});
