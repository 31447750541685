import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles'; 
import { Grid, Container, Typography,  Button, Popover, Tooltip, withStyles, CircularProgress } from '@material-ui/core';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import { addOrgMember } from '../actions/selectedOrganization';
import { DateRangePicker } from "materialui-daterange-picker";
import moment from 'moment';
import { useIsMount } from './useIsMount';
import { consoleToLog, trackGAEvent } from '../util/AppUtil';
import { getEmailReportApi } from '../services/reportService';
import {BarChartFollowupData} from "../data/ChartsData.json";
import RightToLeftSideDrawer from './RightToLeftSideDrawer';
import ChecklistCompaniesFilter from './filters/ChecklistCompaniesFilter';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';



const useStyles = makeStyles((theme) => ({
    mainContent : {
        width: (showDrawer) => {
           return showDrawer ? "83%" : "100%"; 
        },
        backgroundColor: theme.palette.primary.light, 
        transition: ".5s", 
        flexGrow: 1,
        //overflow:'hidden',
        overflow:'auto',
        overflowX:'hidden'
        },
    
    container: {
        marginTop: "8px"
    },
    graphMain: {
        padding: "1rem !important",
        fontSize: "11px !important",
        marginTop: "1rem !important",
        boxShadow: "0 .5rem 1rem rgba(0,0,0,.15) !important",
        width: "32rem !important",
        height: "21rem !important",
        marginLeft: "40px !important",
        marginBottom: "50px"
    },

    icons : {
        color: "black",
        fontSize: "18px",
        minWidth:'30px',
        marginLeft: "8px",
        marginTop:'-3px'
    } ,
    notAttachmentsImage: {
        height: "13rem",
        width: "15rem",
        marginTop: "3rem",
      },
      notAttachmentsText: {
        fontSize: "20px",
        fontWeight: "600",
      },
      notAttachmentsButton: {
        fontSize: "15px",
        width: "19rem",
        marginTop: "20px",
      }   
    })
    
)

const EmailReports = (props) => {
    const {showDrawer} = props;
    const classes = useStyles(showDrawer);
    const { enqueueSnackbar } = useSnackbar();
    const [anchorEl, setAnchorEl] = useState(false);
    const [dateRange, setDateRange] = useState({});
    const [startDateFilter, setStartDateFilter] = useState("");
    const [endDateFilter, setEndDateFilter] = useState("");
    const [clearFilter, setClearFilter] = useState(false);
    const [selectedCompanies, setSelectedCompanies] = useState([]);
    const [followupData, setFollowupData] = useState([]);
    
    const [openDrawer, setOpenDrawer] = useState(false);
    const [openFilters, setOpenFilters] = useState(false);

    const [page, setPage] = useState(1);
    const [nextPage, setNextPage] = useState(false);
    const [isMoreLoading, setMoreLoading] = useState(false);
    const [emailReportsLoading,setEmailsReportsLoading] = useState(false);
    const isMount = useIsMount(); 

    useEffect(() => {
        document.title = `Email Reports - ${props.fullName}`;
    }, []);

    //Last Month
    var prevMonthFirstDay = new moment().subtract(1, 'months').date(1)
        
    //for next month last day
    var thisMonthLastDay = new moment().add(1, 'months').date(0);

    //for last day of previous month
    var lastdayLastMonth = moment(lastdayLastMonth).subtract(1,'months').endOf('month').format('YYYY-MM-DD');

    let currentFinancialYearStart;
    let currentFinancialYearEnd;

    currentFinancialYearStart = (moment().month() < 3) ? moment().subtract(1, 'year').month('April').startOf('month').format('YYYY-MM-DD')
    : moment().month('April').startOf('month').format('YYYY-MM-DD');

    currentFinancialYearEnd =  (moment().month() < 3) ? moment().month('March').endOf('month').format('YYYY-MM-DD')
    : moment().add(1, 'year').month('March').endOf('month').format('YYYY-MM-DD');

    let lastFinancialYearStart = moment(currentFinancialYearStart).subtract(1, 'year').format('YYYY-MM-DD');
    let lastFinancialYearEnd = moment(currentFinancialYearEnd).subtract(1, 'year').format('YYYY-MM-DD');



    const openDateRangePicker = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const open = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onUpdateClearFilterValue = () => {
        setClearFilter(false);
    }

    const onSelectedCompanies = (sts) => {
        setSelectedCompanies(sts);
    }

    //api
    const getFollowupReport = () => {
        consoleToLog("Api: selectedCompanies", selectedCompanies);
        consoleToLog("Api: startDateFilter", startDateFilter);
        consoleToLog("Api: endDateFilter", endDateFilter);

        const organization_id = props.selectedOrganization.organization.id;
        /* if(!selectedCompanies || selectedCompanies.length === 0){
            setFollowupData([]); 
            return;
        } */
        if(!startDateFilter || startDateFilter.length === 0) {
            setFollowupData([]); 
            return;
        };
        if(!endDateFilter || endDateFilter.length === 0){
            setFollowupData([]); 
            return;
        };

        const ids = selectedCompanies.id;
        if(!isMoreLoading) setEmailsReportsLoading(true);

        getEmailReportApi(organization_id, page, startDateFilter, endDateFilter,  ids )
            .then((response) => {
                const res = response.data;
                consoleToLog("Response getEmailReportApi: ", res);
                /* enqueueSnackbar(res.message, {
                    variant:'success'
                }); */
                setFollowupData(res.email_list);

                if (isMoreLoading) {
                    const newItems = followupData.concat(res.email_list);
                    setFollowupData(newItems);
                    setNextPage(res.next_page);
                    setPage(page + 1);
                    setMoreLoading(!isMoreLoading);

                } else {
                    trackGAEvent(props.selectedOrganization.organization.name, 'Filter Email Report', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
                    setNextPage(res.next_page)
                    if (res.next_page) setPage(page + 1);
                    setFollowupData(res.email_list);
                }
                setEmailsReportsLoading(false);
            })
            .catch((e) => {
                consoleToLog("getEmailReportApi error", e.response);
                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant:'error'
                    });
                    return false;
                }
                setEmailsReportsLoading(false);
            });
    }

    useEffect(() => {
        if(!isMount) {
            consoleToLog("***On filter change  in useeffect");
            pageReset();
        }
    }, [selectedCompanies, startDateFilter, endDateFilter])
    
    useEffect(() => {
        if(!isMount) {
            if (page === 1) {
                consoleToLog("***On page change  in useeffect");
                getFollowupReport();
            }
        }    
    }, [page]);

    useEffect(() => {
        //consoleToLog("hi", isMoreLoading);
        if(!isMount) {
            if (isMoreLoading) {
                consoleToLog("***On more loading  in useeffect");
                getFollowupReport();
            }
        }
    }, [isMoreLoading])

    const toggleDrawer = () => {
        setOpenDrawer(open => !open)
        setAnchorEl(null);
    }

    const setDrawerOpen = () => {
        setOpenDrawer(false);
        setOpenFilters(false);
    }


    const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#f5f5f5",
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
    }))(TableCell);

    const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(even)': {
        backgroundColor: theme.palette.action.hover,
        },
    },
    }))(TableRow);

    const ReportsNotFound = () => {
        return (
          <div>
            {/* Not Found*/}
            <Grid container direction="row">
              <Grid
                item
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
                sm={12}
              >
                <img
                  alt="No Reports Found"
                  src="/images/no_attachment_found.svg"
                  className={classes.notAttachmentsImage}
                />
              </Grid>
              <Grid
                item
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <Typography className={classes.notAttachmentsText}>
                  No Reports Found, You need to select all filters
                </Typography>
              </Grid>
              <Grid
                item
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                <Button
                  variant="contained"
                  color="primary"
                  //endIcon={<PublishIcon />}
                  size="large"
                  className={classes.notAttachmentsButton}
                  onClick={()=>{
                    toggleDrawer();
                    setOpenFilters(!openFilters);
                  }}
                >
                  Add Filters
                </Button>
              </Grid>
            </Grid>
            {/* Not Found*/}
          </div>
        );
      };

      const getTableData = () => {
          return(
            <Grid container>
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="customized table">
                        <TableHead>
                            <TableRow style={{borderTop: "1px solid rgba(224, 224, 224, 1"}}>
                            <StyledTableCell style={{width: "200px"}}>Company</StyledTableCell>
                            <StyledTableCell align="left" style={{width: "250px"}}>Email Subject</StyledTableCell>
                            <StyledTableCell align="left">Template Name</StyledTableCell>
                            <StyledTableCell align="left">Subtitle</StyledTableCell>
                            <StyledTableCell align="left">Open Count</StyledTableCell>
                            <StyledTableCell align="left">Email Sent</StyledTableCell>
                            <StyledTableCell align="left">Last Opened</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {followupData.map((row) => (
                            <StyledTableRow key={row.name}>
                                <StyledTableCell align="left" style={{width: "200px"}}>{row.checklist_company_name}</StyledTableCell>
                                <StyledTableCell align="left" style={{width: "250px"}}>{row.email_subject}</StyledTableCell>
                                <StyledTableCell align="left">{row.checklist_template_name}</StyledTableCell>
                                <StyledTableCell align="left">{row.checklist_subtitle}</StyledTableCell>
                                <StyledTableCell align="center">{row.open_count}</StyledTableCell>
                                <StyledTableCell align="left">{moment(row.created_at).fromNow()}</StyledTableCell>
                                <StyledTableCell align="left">{row.open_timestamp ? moment(row.open_timestamp).fromNow() : "Not Opened"}</StyledTableCell>
                            </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
          )
      }

      const loadMoreLogic = () => {
        return (
            <Grid item container>
                <Grid item lg={12}>
                {nextPage && !isMoreLoading &&
                    <div style={{textAlign: "center", margin:'16px 0px 32px 0px'}}>
                        <Button type="button"
                            color="secondary"
                            variant="contained"
                            onClick={() => {
                                setMoreLoading(!isMoreLoading);
                            }}>Load More
                        </Button>
                    </div>}

                { isMoreLoading &&
                    <CircularProgress size={35} style={{position:'relative', left:'50%', right:'50%', margin:'16px 0px'}}/>
                }
                </Grid>
            </Grid>
        )
    }

    const pageReset = () => {
        //on page change useeffect will be called and getEmail api called
        setPage(1);
        setNextPage(false);
        if(page===1) getFollowupReport();
    }

    return (
        <div className={classes.mainContent}
            style={{height: props.height}}>
            <Container className={classes.container} maxWidth="xl">
                <div style={{display: "flex", alignItems:'center'}}>
                    <Typography style={{fontSize:'20px', fontWeight:'600', marginBottom: "10px"}} color="primary">
                        Email Report
                    </Typography>
                    <Tooltip title="Filters" arrow>
                        <Button aria-label="delete"  
                            className={classes.icons}
                            onClick={() => {
                                toggleDrawer();
                                setOpenFilters(!openFilters);
                            }}
                            >
                            <i className={(/* selectedCompanies.length &&  */startDateFilter.length > 0 && endDateFilter.length > 0) ? `flaticon-filter active` : `flaticon-filter`}></i>
                            {   (/* selectedCompanies.length &&  */startDateFilter.length > 0 && endDateFilter.length > 0) ?
                                <div className='filter__dot'></div> : <div></div>
                            }
                        </Button>
                    </Tooltip>
                </div>
                { emailReportsLoading ?
                    <Grid item container>
                        <Grid item lg={12}>
                            <CircularProgress size={35} style={{position:'relative', left:'50%', right:'50%', margin:'16px 0px'}}/>
                        </Grid>
                    </Grid>
                    :
                    (followupData && followupData.length > 0) ? 
                        <div style={{paddingBottom: "35px"}}>
                            {getTableData()}
                            {loadMoreLogic()}   
                        </div>
                    : <ReportsNotFound /> 
                    
                }
            </Container>    
            <RightToLeftSideDrawer openDrawer={openDrawer} 
                closeDrawer={setDrawerOpen}
                title="Report Filters"
                createChecklist={false}
            >
            <div >
                <Grid item style={{marginLeft: "12px"}}>
                    <Grid item container>
                        <Grid item >
                            <Typography
                                variant="body1"
                                style={{lineHeight:'42px', color: '#888888'}}
                            >
                            Filter By Date
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item container style={{marginTop:'-6px'}}>
                        <Grid item>
                        <Button variant='outlined' 
                                color='primary' 
                                style={{fontWeight:"300 !important"}}
                                onClick={openDateRangePicker}>
                                {(startDateFilter.length>0 && endDateFilter.length>0) ? 
                                    `${moment(startDateFilter).format('DD-MM-YYYY')} - 
                                    ${moment(endDateFilter).format('DD-MM-YYYY')}` : "Start Date - End Date"}
                        </Button>
                        <Popover
                            id='daterange-popover'
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center"
                            }}
                            transformOrigin={{
                            vertical: "top",
                            horizontal: "center"
                            }}
                            >
                                <DateRangePicker
                                    open={true}
                                    toggle={handleClose}
                                    onChange={(range) => {
                                        //consoleToLog("range", range, dateRange);
                                        setDateRange(range);
                                        setStartDateFilter(moment(range.startDate).format('YYYY-MM-DD'))
                                        setEndDateFilter(moment(range.endDate).format('YYYY-MM-DD'))
                                        setClearFilter(false);
                                    }}
                                    style={{
                                    width:'50%'
                                    }}
                                    initialDateRange={{
                                        startDate:  startDateFilter,
                                        endDate: endDateFilter
                                    }}
                                    definedRanges={[
                                    {
                                        label: `${moment(lastFinancialYearStart, 'YYYY-MM-DD').format('MMM-YYYY')}-${moment(lastFinancialYearEnd, 'YYYY-MM-DD').format('MMM-YYYY')}`,
                                        startDate: lastFinancialYearStart,
                                        endDate: lastFinancialYearEnd
                                    },
                                    {
                                        label: `${moment(currentFinancialYearStart, 'YYYY-MM-DD').format('MMM-YYYY')}-${moment(currentFinancialYearEnd, 'YYYY-MM-DD').format('MMM-YYYY')}`,
                                        startDate: currentFinancialYearStart,
                                        endDate: currentFinancialYearEnd
                                    },
                                    {
                                        label: 'Last Month',
                                        startDate: prevMonthFirstDay,
                                        endDate: lastdayLastMonth
                                    },
                                    {
                                        label: 'This Month',
                                        startDate: new Date(),
                                        endDate: thisMonthLastDay
                                    }
                                    ]}
                                />
                            </Popover>
                        </Grid>
                    </Grid>   
                </Grid>    
                <Grid item style={{marginLeft: "12px"}}>
                    <Grid item container>
                        <Grid item >
                            <Typography
                                variant="body1"
                                style={{lineHeight:'42px', color: '#888888'}}
                            >
                            Select Companies
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item container style={{marginTop:'-16px', width: "500px"}}>
                        <Grid item lg={6}>
                            <ChecklistCompaniesFilter isFromReport = {true}
                                isSingleSelect={true}
                                clearFilter={clearFilter}
                                onUpdateClearFilterValue={onUpdateClearFilterValue} 
                                onSelectedCompanies={onSelectedCompanies}
                                selectedCompanies={selectedCompanies}
                                />
                        </Grid>
                    </Grid>   
                </Grid>
                <Grid item style={{marginLeft: "12px", marginTop: "18px"}}>
                <Button variant="contained" 
                    color="primary" 
                    style={{fontWeight:"300 !important"}}
                    className="modal__button"
                    onClick={()=>{
                        setClearFilter(true);
                        setDateRange();
                        setStartDateFilter("");
                        setEndDateFilter("");
                        setSelectedCompanies([]);
                        setFollowupData([]); 
                        //pageReset();
                    }}>
                    Clear Filters
                </Button>
                </Grid>
            </div>
        </RightToLeftSideDrawer>
    </div>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    members: state.organizationInfo.members
});

const mapDispatchToProps = (dispatch) => ({
    addOrgMember: (member) => dispatch(addOrgMember(member))
})

export default connect(mapStateToProps, mapDispatchToProps)(EmailReports);