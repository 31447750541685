import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Grid, Typography, Link, TextField, CircularProgress, InputAdornment } from '@material-ui/core';
import {connect} from 'react-redux';
import { firebase } from '../../firebase/firebase';
import {login} from '../../actions/auth';
import { emailValidator } from '../../util/validator';
import { consoleToLog, trackGAEvent, trackGAPageView } from '../../util/AppUtil';
import { signInApi, createFirebaseTokenApi } from '../../services/authService';
import { useSnackbar } from 'notistack';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import ForgotPassword from '../../components/modals/ForgotPassword';

const useStyles = makeStyles((theme) => ({
    innerbg: {
        borderTopRightRadius: "40px",
        borderTopLeftRadius: "40px",
        background: "#ffffff",
        padding: "45px 30px",
    },
    mainbg: {
        width:"100%",
        height:"100vh",
        background: theme.palette.primary.light,
        overflow:'auto'
    },
    logo: {
        maxWidth: "200px",
        marginBottom: "10px",
        [theme.breakpoints.down("md")]: {
            maxWidth: "190px",
          }
    },
    title: {
        marginTop: "20px",
        fontSize: "24px",
        fontWeight:'500',
        textAlign: 'center',
        marginBottom:'4px'
    },
    circularProgress: {
        marginLeft: 0,
        marginRight: theme.spacing(2),
        color: 'white'
      },
    logoContainer: {
        display: 'flex',
        justifyContent:'center',
        marginTop:'64px'
    },
    formTitle: {
        fontSize:'18px',
        fontWeight:'500',
        marginBottom:'-6px !important'
    },
    forgotPasswordLabel: {
        marginTop: "12px", 
        display:'block', 
        fontSize: "14px", 
        textAlign:'right'
    }
  }));

const MobileLoginPage = (props) => {
      const classes = useStyles(props);
      const {enqueueSnackbar} = useSnackbar();

      const [email, setEmail] = useState('');
      const [password, setPassword] = useState('');
      const [emailInValid, setEmailInValid] = useState(false);
      const [passwordInValid, setPasswordInValid] = useState(false);
      const [loading, setLoading] = useState(false);
      
      const [open, setOpen] = useState(false);
      const [showPaswordText, setShowPasswordText] = useState(false);

      const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };

      const onShowPasswordIconClick = () => {
        setShowPasswordText(!showPaswordText);
      }

      useEffect(()=> {
        trackGAPageView('/login');
      }, []);

      const loginToFirebase = (token) => {
        firebase.auth().signInWithCustomToken(token)
            .catch(function (error) {
                consoleToLog("Firebase login api error: ", error);
            });
        }

    const onSubmitClick = () => {
        if (!email) {
            setEmailInValid(true);
            return;
        }
        const isEmailValid = emailValidator(email);
        if (!isEmailValid) {
            setEmailInValid(true);
            return;
        }

        if (!password) {
            setPasswordInValid(true);
            return;
        }

        const service = 'workplace';

        setLoading(true)
        signInApi(email, password, service)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response signInApi: ", res);
                setLoading(false)
                enqueueSnackbar('Login Successful', { 
                    variant: 'success',
                });

                trackGAEvent('Mobile App Login', 'Login clicked', `${res.user.firstname} ${res.user.lastname}`);
                callFirebaseTokenApi(res.access_token);
            })
            .catch((e) => {
                consoleToLog("Response signInApi: ", e.response);
                setLoading(false)
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, { 
                        variant: 'error',
                    });
                    return false;
                }
            });
      }

      const showPasswordIcon = () => {
        return (
                        <InputAdornment  position="end" 
                                onClick={onShowPasswordIconClick}
                                className={classes.daysLabel}>
                           {showPaswordText ?
                                <VisibilityIcon style={{color:'#BDBDBD', marginTop:'-1px'}}/>
                                : 
                                <VisibilityOffIcon style={{color:'#BDBDBD', marginTop:'-1px'}}/>
                            }
                          </InputAdornment>
        )
     }

     const callFirebaseTokenApi = (access_token) => {
        createFirebaseTokenApi(access_token)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response createFirebaseTokenApi: ', res);

                loginToFirebase(res.firebase_access_token);
                const obj = {
                    access_token: access_token,
                    firebase_access_token: res.firebase_access_token,
                    service_name: 'workplace',
                    user: res.user
                }
                props.login(obj);
                
            })
            .catch((e) => {
                consoleToLog('Error createFirebaseTokenApi: ', e.reponse);
                if(e.response.data && e.reponse.data.message) {
                    return;
                }
            })
     }

    return (
        <div className={classes.mainbg}>

            <Grid item xs={12} sm={12} className={classes.logoContainer}>
                <img src="/images/eprocessify-logo-svg.svg" alt="logo" className={classes.logo} />
            </Grid>

            <Grid item container direction="column" style={{marginTop:'120px'}}>
                <Grid item xs={12} sm={12}>
                    <Typography variant="body1" className={classes.title}>
                        Log in to eProcessify
                    </Typography>

                    <Typography variant="body2" style={{textAlign:'center'}}>
                        Enter your credentials to access your account
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={12} style={{marginTop:'32px'}}>
                    <Box minHeight="70vh" className={classes.innerbg}>
                        <Typography variant="body1" className={classes.formTitle}>
                            Login
                        </Typography>

                        <Grid item xs={12} style={{marginTop: "8px"}}>
                            <TextField
                                fullWidth
                                id="email"
                                label="Username/Email"
                                type="text"
                                margin="dense"
                                error={emailInValid}
                                helperText={emailInValid ? "* Email is invalid!" : ''}
                                onChange={e => { 
                                    setEmail(e.target.value);
                                    setEmailInValid(false);
                                }}
                                InputLabelProps={{style: {fontSize: 14}}}
                            />
                        </Grid>

                        <Grid item xs={12} style={{marginTop: "15px"}}>
                            <TextField
                                fullWidth
                                id="standard-password-input"
                                label="Password"
                                type={showPaswordText ? "text" : "password"}
                                margin="dense"
                                // onKeyPress={(ev) => {
                                //     //consoleToLog(`Pressed keyCode ${ev.key}`);
                                //     if (ev.key === 'Enter') {
                                //         onSubmitClick();
                                //         ev.preventDefault();
                                //     }
                                //     }}
                                onChange={e => { 
                                    setPassword(e.target.value);
                                    setPasswordInValid(false);
                                }}
                                error={passwordInValid}
                                helperText={passwordInValid ? "* Password is required!" : ''}
                                InputLabelProps={{style: {fontSize: 14}}}
                                InputProps={{endAdornment: showPasswordIcon()}}
                            />
                        </Grid>

                        <Link className={classes.forgotPasswordLabel} 
                            color="secondary"
                            onClick={handleClickOpen}
                            >Forgot your password?
                        </Link>

                        <Button variant="contained" 
                            color="primary" 
                            fullWidth
                            style={{marginTop: "40px"}}
                            onClick={()=>onSubmitClick()}
                            >
                            {loading && <CircularProgress size={24} className={classes.circularProgress}/>}
                            Login
                        </Button>

                    </Box>
                </Grid>

            </Grid>
            <ForgotPassword isOpen={open} handleClose={handleClose} fromMobile={true}/>      
        </div>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth
});

const mapDispatchToProps = (dispatch) => ({
    login: (auth) => dispatch(login(auth))
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileLoginPage);