import React, { useEffect, useState } from "react";
import {TextField, Grid, Button, Typography, Select, MenuItem} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ReactMultiEmail, isEmail } from "react-multi-email";
import "react-multi-email/style.css";
import {companyNameValidator, mobileNoValidator} from '../../util/validator';
import { useSnackbar } from 'notistack';
import {connect} from 'react-redux';
import { showAllCountries, showAllStates, showAllUsers } from "../../util/AppUtil";
import {phoneNumberValidate} from "../../util/AppUtil";

const useStyles = makeStyles((theme) => ({
  MuiFormControlroot: {
    "& .MuiOutlinedInput-input": {
      paddingLeft: "16px",
    },
    "& .MuiSelect-select.Mui-disabled": {
      cursor: "default !important",
      background: "#EEEEEE !important",
    },
    "& .MuiInputBase-input.Mui-disabled": {
      background: "#EEEEEE !important",
      color: "#555555 !important",
    },
  },
  listContainer: {
    "&:last-child .dropdownDivider": {
      display: "none",
    },
  },
  dividerStyle: {
    background: "#000",
    opacity: "0.1",
  },
  customMarginTop: {
    margin: "16px 0px 0px 0px",
  },
  customMarginBottom: {
    marginBottom: "-8px",
  },
  udfButton: {
    position: "relative",
    width: "27px",
    height: "27px",
    minWidth: "0",
    margin: "-5px 0px 0px 10px",
    fontSize: "14px",
    color: "#555555",
    borderRadius: "50%",
    border: "1px solid #555555",
    paddingTop: "5px",
    //display: props.isFromWaitingForMyApproval ? "none" : "flex",
  },
  customColumn: {
    maxWidth: "31.5%",
    flexBasis: "31.5%",
  },
  customColumn2: {
    maxWidth: "5.5%",
    flexBasis: "5.5%",
  },
  circularProgress: {
    marginLeft: 0,
    marginRight: theme.spacing(2),
    color: "white",
  },
  downloadLink: {
    color: "#20a8e0",
    fontSize: "15px",
    fontWeight: "600",
    marginLeft: "2px",
    cursor: "pointer",
  },
  enableStyle:{
    width:"100%",
    height:"2.5rem",
    padding:"2px",
},
  disableStyle:{
    width:"100%",
    height:"2.5rem",
    padding:"2px",
    color:"#bababa"
},
increaseDenseMargin : {
  '& .MuiOutlinedInput-inputMarginDense': {
    paddingTop: '15px',
    paddingBottom: '15px'
  }
},
increaseDenseMargin1 : {
  '& .MuiOutlinedInput-inputMarginDense': {
    paddingTop: '11.5px',
    paddingBottom: '11.5px'
  }
},

customColumn: {
  flexBasis:'49.5%',
  maxWidth:'49.5%'
}
}));

const ClientInfo = (props) => {
  const classes = useStyles();
  const {enqueueSnackbar} = useSnackbar();
  const [companyName, setCompanyName] = useState(props.companyObj.name);
  // const [emails, setEmails] = useState(props.companyObj.email ? props.companyObj.email.split(',') : []);
  const [phoneNumber, setPhoneNumber] = useState(props.companyObj.phone_number);
  const [compAddress, setCompAddress] = useState(props.companyObj.address);
  const [selectedCountry, setSelectedCountry] = useState("none");
  const [selectedState, setSelectedState] = useState("none");
  const [taxId, setTaxId] = useState(props.companyObj.tax_id);
  const selected_manager = props.companyObj.relationship_manager_id && props.companyObj.relationship_manager_id.length > 0
                            ? props.companyObj.relationship_manager_id.join('') : 'none'
  const [selectedManager, setSelectedManager] = useState(selected_manager);

  useEffect(()=>{
    if(props.companyObj.country !== null){
      setSelectedCountry(props.companyObj.country === 'India' ? 'IN' : props.companyObj.country)
     }
     else setSelectedCountry("none");

     if(props.companyObj.state !== null){
      setSelectedState(props.companyObj.state === 'Maharashtra' ? 'MH' : props.companyObj.state)
     }
     else setSelectedState("none");

  }, [])

  const handleReset = () => {
    //  set to initial value
    setCompanyName(props.companyObj.name);
    // setEmails(props.companyObj.email ? props.companyObj.email.split(',') : []);
    setPhoneNumber(props.companyObj.phone_number);
    setCompAddress(props.companyObj.address);
    setTaxId(props.companyObj.tax_id);
    setSelectedCountry(props.companyObj.country);
    setSelectedState(props.companyObj.state);
    setSelectedManager(selected_manager);
  };

  const handleSave = () => {
    // validations
    if (!companyName) {
      enqueueSnackbar('Client name can not be empty!', {
          variant: 'error'
      });
      return;
    }
    const isCompanyNameValid = companyNameValidator(companyName);
    if (!isCompanyNameValid) {
        enqueueSnackbar('Enter valid client name', {
            variant: 'error'
        });
        return;
    }

    if(selectedCountry === 'none' || selectedCountry === '' || selectedCountry === undefined) {
          enqueueSnackbar('Please Select the Country', {
              variant: 'error'
          });
          return;
    }

    // if(selectedCountry !== 'IN') {
    //   enqueueSnackbar('Please Select India as Country', {
    //       variant: 'error'
    //   });
    //   return;
    // }

    if(selectedCountry === 'IN' &&  selectedState === 'none') {
      enqueueSnackbar('Please Select the State', {
          variant: 'error'
      });
      return;
    }

    const state = selectedCountry === 'IN' ? selectedState : undefined;

    const tax_id = taxId;

    // to parent
    props.editClientInfo(companyName, phoneNumber, compAddress, selectedCountry, state, tax_id, selectedManager)
  };

  // const validateEmail = (email) => {
  //   if(!isEmail(email)) {
  //       enqueueSnackbar('Please enter a valid email.', {variant:'error'});
  //       return false;
  //   } else {
  //       return true;
  //   }
  // }

  return (
    <Grid item container md={8} lg={8}>
      <Grid item md={12} lg={12}>
        <Typography variant="subtitle1" className={classes.customMarginBottom}>
          Client Name
        </Typography>

        <TextField
          fullWidth
          id="company_name"
          variant="outlined"
          type="text"
          margin="dense"
          autoComplete="off"
          placeholder="Enter Company Name"
          InputLabelProps={{ style: { fontSize: 13, color: "#ccc" } }}
          classes={{
            root: classes.MuiFormControlroot,
          }}
          value={companyName}
          onChange={(e) => setCompanyName(e.target.value)}
        />
      </Grid>

      <Grid item md={12} lg={12} className={classes.customMarginTop}>
        <Typography variant="subtitle1" className={classes.customMarginBottom}>
          Tax Id (Optional)
        </Typography>

        <TextField
          fullWidth
          id="tax_id"
          variant="outlined"
          type="tel"
          margin="dense"
          autoComplete="off"
          placeholder="Tax Id"
          InputLabelProps={{ style: { fontSize: 13, color: "#ccc" } }}
          classes={{
            root: classes.MuiFormControlroot,
          }}
          value={taxId}
          onChange={(e) => setTaxId(e.target.value)}
        />
      </Grid>

      <Grid item md={12} lg={12} className={classes.customMarginTop}>
          <Typography variant="subtitle1">
            Mobile Number (Optional)
          </Typography>

          <TextField
            fullWidth
            id="mobile_number"
            variant="outlined"
            type="text"
            margin="dense"
            autoComplete="off"
            placeholder="Mobile Number"
            InputLabelProps={{ style: { fontSize: 13, color: "#ccc" } }}
            classes={{
              root: classes.MuiFormControlroot,
            }}
            // className={emails && emails.length > 0 ? classes.increaseDenseMargin : classes.increaseDenseMargin1}
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            onKeyPress={(e) => phoneNumberValidate(e)}
          />
      </Grid>

      {/* <Grid item container alignItems='center' justifyContent="space-between"
        className={`${classes.customMarginTop} ${classes.customMarginBottom}`}>

        
        <Grid item md={6} lg={6} className={classes.customColumn}>
          <Typography variant="subtitle1">Email Addresses (Optional) </Typography>
          
          <ReactMultiEmail
            className={emails && emails.length > 0 ? "org__email" : ""}
            style={{
              borderRadius: "5px",
              borderStyle: "solid",
              borderWidth: "1px",
              borderColor: "#DBDBDB",
              fontSize: "14px",
            }}
            placeholder="Type Email Address And Press Enter"
            emails={emails}
            onChange={(emails) => setEmails(emails)}
            validateEmail={(email) => validateEmail(email)}
            getLabel={(email, index,
              removeEmail = (index) => {
                const arr = emails.splice(index, 1);
                setEmails(arr);
              }
            ) => {
              return (
                <div data-tag key={index} className="return__email">
                  {email}
                  <span data-tag-handle onClick={() => removeEmail(index)}>
                    x
                  </span>
                </div>
              );
            }}
          />
        </Grid>
          

      </Grid>  */}
      
      <Grid item md={12} lg={12} className={classes.customMarginTop}>
        <Typography variant="subtitle1" className={classes.customMarginBottom}>
          Address (Optional)
        </Typography>

        <TextField
          fullWidth
          id="address"
          variant="outlined"
          type="text"
          margin="dense"
          autoComplete="off"
          placeholder="Address"
          InputLabelProps={{ style: { fontSize: 13, color: "#ccc" } }}
          classes={{
            root: classes.MuiFormControlroot,
          }}
          value={compAddress}
          onChange={(e) => setCompAddress(e.target.value)}
        />
      </Grid>

      <Grid item md={12} lg={12} className={classes.customMarginTop}>
        <Typography variant="subtitle1">
          Country
        </Typography>

        <Select 
            className={selectedCountry === 'none' ? classes.disableStyle : classes.enableStyle}
            value={selectedCountry}
            onChange={(e) => setSelectedCountry(e.target.value)}
            variant="outlined"
            placeholder='Select Country'
            autoWidth
            //disabled={disabled}
        >
          {
            selectedCountry === 'none' && <MenuItem value='none'>Select Country</MenuItem>
          } 
        {showAllCountries()}
        </Select>
      </Grid>

      {selectedCountry === 'none' || selectedCountry === 'IN' &&
      <Grid item md={12} lg={12} className={classes.customMarginTop}>
        <Typography variant="subtitle1">
          State
        </Typography>

        <Select 
            className={selectedState === 'none' ? classes.disableStyle : classes.enableStyle}
            value={selectedState}
            onChange={(e) => setSelectedState(e.target.value)}
            variant="outlined"
            placeholder='Select State'
            autoWidth
            disabled={selectedCountry === 'none' || selectedCountry !== 'IN'}
        >
          {
            selectedState === 'none' && <MenuItem value='none'>Select State</MenuItem>
          } 
        {showAllStates()}
        </Select>
      </Grid>}

      <Grid item md={12} lg={12} className={classes.customMarginTop}>
        <Typography variant="subtitle1">
          Relationship Manager (Optional)
        </Typography>

        <Select 
            className={selectedManager === 'none' ? classes.disableStyle : classes.enableStyle}
            value={selectedManager}
            onChange={(e) => setSelectedManager(e.target.value)}
            variant="outlined"
            placeholder='Select State'
            autoWidth
        >
          {
            selectedManager === 'none' && <MenuItem value='none'>Select Relationship Manager</MenuItem>
          } 
          {props.members && props.members.length > 0 && showAllUsers(props.members)}
        </Select>
      </Grid>



      <Grid container sm={12} style={{ marginTop: "1rem" }}>
        <Button
          variant="contained"
          color="primary"
          className="modal__button"
          onClick={() => {handleSave()}}
        >
          Save
        </Button>
        <Button
          variant="outlined"
          style={{ minWidth: "130px" }}
          onClick={() => {handleReset()}}
        >
          Reset
        </Button>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  selectedOrganization: state.organizationInfo.selectedOrganization,
  cd: state.organizationInfo.cd,
  members: state.organizationInfo.members
});

export default connect(mapStateToProps, null)(ClientInfo);

