import React, {useState, useEffect} from 'react';
import {Drawer, Grid, Typography, Button, IconButton, TextField, CircularProgress, Link, Tooltip} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import RightToLeftSideDrawer from '../RightToLeftSideDrawer';
import { MentionsInput, Mention } from 'react-mentions';
import { getMentionOptionsForSubject, getMentionOptionsForBody } from '../../services/mentionService';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState,  RichUtils, Modifier } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { isStringSpaces } from '../../util/validator';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import ClearIcon from '@material-ui/icons/Clear';
import AddIcon from '@material-ui/icons/Add';
import {createEmailDraftApi, updateEmailDraftApi, downloadEmailDraftAttachmentApi, deleteEmailDraftAttachmentApi} from '../../services/genieService';
import { useSnackbar } from 'notistack';
import {connect} from 'react-redux';
import { consoleToLog, stringToSlug, trackGAEvent } from '../../util/AppUtil';
import axios from 'axios';
import {uploadAttachmentApi} from '../../services/uploadService';
import {addOrgEmailDraft, updateOrgEmailDraft} from '../../actions/selectedOrganization';
import {downloadAttachmentApi} from '../../services/authService'

const useStyles = makeStyles((theme) => ({
    dialogboxFooter: {
        display: "flex",
        position: "absolute",
        bottom: "0px",
        left: "0",
        right: "0",
        padding: "12px 0px 12px 12px",
        zIndex: "15",
        background: "#fff",
        borderTop: "1px solid #ccc",
    }, 
    customMarginTop : {
        margin:'8px 0px 0px 0px'
    },   
    customMarginBottom : {
        marginBottom:'-8px'
    },  
    MuiTextFieldroot : {
        '& .MuiOutlinedInput-adornedEnd': {
            paddingRight: '8px'
        }
    },
    MuiFormControlLabelroot : {
        '& .MuiFormControlLabel-label' : {
            fontSize: '14px',
            color: '#555555',
            marginLeft:'-4px'
        }
    },
    companyEmailLabelStyle : {
        color:'#0071ff', 
        cursor:'pointer'
    },
    circularProgress: {
        marginLeft: 0,
        marginRight: theme.spacing(2),
        color: 'white'
    },
    attachLink : {
        color:'#3eb6e7',
        fontWeight:'600',
        cursor:'pointer',
        textDecoration:"none !important",
        fontSize:'18px',
        display:'flex',
        alignItems:'flex-start',
        '&:hover': {
            color:'#20a8e0'
        }
    },
    downloadLink: {
        color: "#20a8e0",
        fontSize: "15px",
        fontWeight: "600",
        marginLeft: "2px",
        cursor: "pointer",
        maxWidth:'20ch',
        overflow:'hidden',
        whiteSpace:'nowrap',
        textOverflow:'ellipsis',
    },
    attachmentListStyle: {
        display:'flex',
        alignItems:'center', 
        padding:'8px', 
        backgroundColor:'#f9f9f9', 
        border:'1px solid #ececec', 
        borderRadius:'5px', 
        marginRight:'12px',
        marginBottom:'12px'
    },
    downloadStyle: {
        display:'flex',
        alignItems:'center',
        marginRight:'12px',
        marginBottom:'8px',
        backgroundColor:'#f9f9f9',
        border:'1px solid #ececec', 
        padding:'8px',
        borderRadius:'5px'
    },
    attachmentFilename: {
        marginTop:'-2px', 
        marginRight:'6px',
        maxWidth:'20ch',
        overflow:'hidden',
        whiteSpace:'nowrap',
        textOverflow:'ellipsis'
    }
}))

const AddEditEmailDraftModal = (props) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    
    const [draftTitle, setDraftTitle] = useState('');
    const [mentions, setMentions] = useState();
    const [mentionOptionsForSubject, setMentionOptionsForSubject] = useState(getMentionOptionsForSubject());
    const [mentionOptionsForBody, setMentionOptionsForBody] = useState(getMentionOptionsForBody());
    const [value, setValue] = useState("");
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [attachment, setAttachment] = useState([]);
    const [path, setPath] = useState([]);
    const [fileAttachment, setFileAttachment] = useState([]);
    const [loading, setLoading] = useState(false);

    const draftObj = props.fromPredefinedComponent ? props.predefinedEmailDraftObj : props.emailDraftObj;
    const draftListArr = props.fromPredefinedComponent ? props.predefinedEmailDraftList : props.emailDraftList;
    const setEmailDraftListArr = props.fromPredefinedComponent ? props.setPredefinedEmailDraftList : props.setEmailDraftList;

    useEffect(() => {
        if(draftObj) {
            preFillFormData();
        }
    }, [draftObj])

    let toolbarConfig = {
        inline: {
            options: ['bold', 'italic', 'underline', 'strikethrough']
        },
        /* blockType: {
            options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code']
        }, */
        list: {
            options: ['unordered', 'ordered']
        },
        textAlign: {
            options: ['left', 'center', 'right', 'justify']
        },
        link: { options: ['link'] },
        options: ['inline', 'link',/* 'blockType', */ 'fontFamily', 'fontSize', 'colorPicker', 'list', 'textAlign']
    };

    const preFillFormData = () => {
        const { title, subject, body, attachments } = draftObj;

        const blocksFromHtml = htmlToDraft(body);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const editorState = EditorState.createWithContent(contentState);

        setDraftTitle(title);
        setEditorState(editorState);
        setValue(subject);
        setPath(attachments && attachments.length > 0 ? attachments : []);

    }

    const onEditorStateChange = (editorState) => {
        setEditorState(editorState)
    }

    const handleReturn = (e) =>  {
        //console.log("eeeeeee:",e);
        if (e.keyCode===13) {
          ///this.setState({ editorState: insertSoftNewline(editorState) });
            setEditorState(RichUtils.insertSoftNewline(editorState));
            return 'handled';
        }
        return 'not-handled';
    }

    const handlePastedText = (text, html, editorState) => {
        var content = editorState.getCurrentContent();
        var selection = editorState.getSelection();

        var newContent = Modifier.insertText(
            content, 
            selection,
            text
        )

        var newEditorState = EditorState.push(editorState, newContent, "insert-characters")
        //Then send this to onChange()
        setEditorState(newEditorState);
        return 'handled'
    }

    const onAttachmentSelected = (e) => {
        const { target: { files } } = e;
        //consoleToLog('attachment array: ', Array.from(files));
        const fileArr = Array.from(files);
        fileArr && fileArr.length > 0 &&
        fileArr.map((fileObj) => setAttachment(oldAttachment => [...oldAttachment, fileObj]))
    }

    const showAttachment = (file, index) => {
        return (
            <div className={classes.attachmentListStyle} key={index}>
                    <i className="flaticon-attachment" style={{marginRight:'3px'}}></i>
                    <Tooltip title={file.name} arrow>
                        <Typography  variant="body1" className={classes.attachmentFilename} style={{marginTop:'-2px', marginRight:'3px'}}>
                            {file.name}
                        </Typography>
                    </Tooltip>
                    <ClearIcon fontSize="small"
                            onClick={(e) => onRemoveAttachment(e, file)} 
                            style={{cursor:'pointer', fontSize:'16px'}}/>
            </div>
            );
    }

    const onRemoveAttachment = (e, file) => {
        e.stopPropagation();
        let updateAttachment= attachment.filter((fileItem) => fileItem.name !== file.name);
        setAttachment(updateAttachment);
    }

    const calldownloadAttachmentApi = (filePath) => {
        const path = filePath.path ? filePath.path : filePath;
        const organinzation_id = props.selectedOrganization.organization.id;
        const bucket = process.env.REACT_APP_S3_EMAIL_DRAFT_BUCKET_NAME
        consoleToLog("Path: ", filePath);
        
        if (filePath?.path?.indexOf("http") === 0 || filePath?.path?.indexOf("https") === 0) {
            consoleToLog("The link has http or https.");
        } else {
                //get downloadable url from backend using url
                downloadAttachmentApi(organinzation_id, path, bucket)
                    .then((response) => {
                        const res = response.data;
                        consoleToLog("Response downloadEmailDraftAttachmentApi: ", res);

                        const downloadableURL = res.document_signed_url;

                        //initiate download
                        document.getElementById('draft-attachment').href = downloadableURL;
                        document.getElementById('draft-attachment').click();
                    })
                    .catch((e) => {
                        consoleToLog(" downloadEmailDraftAttachmentApi error", e.response);
                        if (e.response.data && e.response.data.message) {
                            return false;
                        }
                    });
                }
    }

    const onRemoveAttachmentFromServer = (e, filePath) => {
        e.stopPropagation();
        callRemoveEmailDraftAttachmentApi(filePath);

    }

    const callRemoveEmailDraftAttachmentApi = (filePath) => {
        const organinzation_id = props.selectedOrganization.organization.id;
        const draft_id = draftObj.id;
        const apiPath = props.fromPredefinedComponent ? `organization/${organinzation_id}/predefined_draft/${draft_id}` :
                        `draft/${draft_id}`;

        consoleToLog("Path: ", filePath);
        if(window.confirm('Are you sure you want to delete this attachment?')) {

        deleteEmailDraftAttachmentApi(filePath, apiPath)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response deleteEmailDraftAttachmentApi: ', res);

                setPath(res.attachments);

                draftObj.attachments = res.attachments;
                props.emailDraftObj && props.setEmailDraftObj(draftObj);
                props.predefinedEmailDraftObj && props.setPredefinedEmailDraftObj(draftObj);

                const updatedEmailDraftArr = draftListArr.map((draft) => {
                    if(draft.id === draftObj?.id) {
                        draft = draftObj
                    }
                    return draft
                });

                props.fromPredefinedComponent ? props.setPredefinedEmailDraftList(updatedEmailDraftArr) : 
                props.setEmailDraftList(updatedEmailDraftArr);


            })
            .catch((e) => {
                consoleToLog('Error deleteEmailDraftAttachmentApi: ', e.response);
                if(e.response.data && e.response.data.message) {
                    return;
                }
            })
        }
        
    }

    const showAttachmentFromServer = (filepath, index) => {
        //console.log('template attachment', templateAttachment);
        consoleToLog('filepath****', filepath);

        return (
                <Tooltip 
                    key={filepath.attachment_id}
                    title={filepath.path ? filepath.path.replace(/^.*[\\\/]/, '') : filepath.replace(/^.*[\\\/]/, '')}
                    arrow>
                    <div onClick={() => calldownloadAttachmentApi(filepath)} 
                        className={classes.downloadStyle}>    
                    <i className="flaticon-attachment" style={{marginRight:'3px'}}></i>
                        <Typography className={classes.downloadLink}>
                            {filepath.path ? filepath.path.replace(/^.*[\\\/]/, '') : filepath.replace(/^.*[\\\/]/, '')}
                        </Typography>
                        <Link
                            style={{ display: "none" }}
                            id="draft-attachment"
                            target="_blank"
                            onClick={(e) => e.stopPropagation()}
                            download
                        ></Link>
                        <ClearIcon fontSize="small"
                            onClick={(e) => onRemoveAttachmentFromServer(e, filepath)} 
                            style={{marginTop:'2px', marginLeft:'3px', cursor:'pointer', fontSize:'16px'}}/>
                    </div>
                </Tooltip>
        )
    }

    const onCreateEditEmailDraft = () => {
        const title = draftTitle;
        if(!title) {
            enqueueSnackbar('Title can not be empty!', {
                variant:'error'
            });
            return;
        }

        const email_subject = value;//document.getElementById('subject').value;
        if (!email_subject) {
            enqueueSnackbar('Subject can not be empty!', {
                variant:'error'
            });
            return;
        }
        consoleToLog('subject: ', email_subject);//check

        const isSubjectValid = !isStringSpaces(email_subject);
        if (!isSubjectValid) {
            return;
        }
        if (!editorState.getCurrentContent().hasText()) {
            enqueueSnackbar('Email body can not be empty!', {
                variant:'error'
            });
            return;
        }
        let email_body = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        consoleToLog('body: ', email_body);

        if (!email_body) {
            return;
        }

        const organization_id = props.selectedOrganization.organization.id;
        const draft_id = draftObj?.id

        if(attachment && attachment.length > 0) {
                callUploadUrlApi(organization_id, title, email_subject, email_body, draft_id);
        } else {
            if(draftObj) {
                callUpdateEmaiDraftApi(draft_id, title, email_subject, email_body, path && path.length > 0 && path);
            } else {
                callCreateEmaiDraftApi(organization_id, title, email_subject, email_body);
            }
        }
        //setLoading(true);
        
        
    }

    const setPredefinedUploadPath = (file_name) => {
        const slug = stringToSlug(draftTitle);

        return `predefined_email/${slug}/attachments/${file_name}` ;
        
    }

    const callUploadUrlApi = (organization_id, title, email_subject, email_body, draft_id) => {
        for(let i = 0; i < attachment.length; i++) {
            const file_name = attachment[i].name;
            const file_type = attachment[i]?.type;
            const file = attachment[i];
            const slug = props.selectedOrganization.organization.slug;
            const bucket = process.env.REACT_APP_S3_EMAIL_DRAFT_BUCKET_NAME;
            
            const file_path = props.fromPredefinedComponent ? setPredefinedUploadPath(file_name) : 
                `organizations/${slug}/email-draft/attachments/${file_name}`;

            setLoading(true);
            uploadAttachmentApi(file_path, file_type, bucket, file, props.fromPredefinedComponent && organization_id)
            .then((data) => {
                consoleToLog('upload success', data);
                let obj = {attachment_id: '', path: file_path, name: file_name, bucket: bucket, type: file_type};
                fileAttachment.push(obj);

                if(fileAttachment.length === attachment.length) {
                    const newItemArray = path && path.length > 0 ? fileAttachment.concat(path) : fileAttachment;
                    if(draftObj) {
                        callUpdateEmaiDraftApi(draft_id, title, email_subject, email_body, newItemArray);
                    } else {
                        callCreateEmaiDraftApi(organization_id, title, email_subject, email_body, newItemArray);
                    }
                        
                }
            })
            .catch((e) => {
                consoleToLog('upload error', e);
                setLoading(false);
            })
        
        }
    }

    const callCreateEmaiDraftApi = (organization_id, title, email_subject, email_body, fileAttachment) => {
        const apiPath = props.fromPredefinedComponent ? `organization/${organization_id}/create_predefined_draft` : 'create_email_draft'
        
        createEmailDraftApi(props.fromPredefinedComponent ? undefined : organization_id, 
            title, email_subject, email_body, fileAttachment, apiPath)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response createEmailDraftApi: ', res);
                enqueueSnackbar('Email Draft added successfully', {variant: 'success'});
                setLoading(false);

                !props.fromPredefinedComponent && props.addOrgEmailDraft(res);

                trackGAEvent(props.selectedOrganization.organization.name, 'Email draft created', `${props.auth.user.firstname} ${props.auth.user.lastname}`);

                props.fromPredefinedComponent ? props.setPredefinedEmailDraftList([res, ...draftListArr]) : 
                props.setEmailDraftList([res, ...draftListArr]);

                closeDrawer();
            })
            .catch((e) => {
                consoleToLog('Error createEmailDraftApi: ', e);
                setLoading(false);
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        vairant:'error'
                    });
                    return;
                }
            })
    }

    

    const callUpdateEmaiDraftApi = (draft_id, title, email_subject, email_body, fileAttachment) => {
        const organization_id = props.selectedOrganization.organization.id;
        const apiPath = props.fromPredefinedComponent ? `organization/${organization_id}/predefined_draft/${draft_id}/edit` : draft_id

        updateEmailDraftApi(title, email_subject, email_body, fileAttachment, apiPath)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response updateEmailDraftApi: ', res);
                enqueueSnackbar('Email Draft updated successfully', {variant: 'success'});
                setLoading(false);

                const updatedEmailDraftArr = draftListArr.map((draft) => {
                    if(draft.id === draft_id) {
                        draft = res;
                    }
                    return draft
                });

                trackGAEvent(props.selectedOrganization.organization.name, 'Email draft updated', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
                
                !props.fromPredefinedComponent && props.updateOrgEmailDraft(res);
                props.fromPredefinedComponent ? props.setPredefinedEmailDraftList(updatedEmailDraftArr) : 
                props.setEmailDraftList(updatedEmailDraftArr);
                closeDrawer();
            })
            .catch((e) => {
                consoleToLog('Error updateEmailDraftApi: ', e);
                setLoading(false);
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        vairant:'error'
                    });
                    return;
                }
            })
    }

    const closeDrawer = () => {
        setDraftTitle('');
        setValue('');
        setEditorState(EditorState.createEmpty());
        setPath([]);
        setFileAttachment([]);
        setAttachment([]);
        props.handleDrawerClose();
        props.emailDraftObj && props.setEmailDraftObj(undefined);
        props.predefinedEmailDraftObj && props.setPredefinedEmailDraftObj(undefined);
    }

    return (
        <RightToLeftSideDrawer 
            title={draftObj ? 'Update Email Draft' : 'Add Email Draft'}
            openDrawer={props.openDrawer}
            closeDrawer={closeDrawer}>
                <div className="modal__main" style={{overflow:'auto'}}>
                    <Grid item container md={12} lg={12} direction="column">
                        
                        <Grid item lg={12}> 
                            <Typography variant="subtitle1" color="primary" className={classes.customMarginBottom}>
                                Title
                            </Typography> 
                            <TextField fullWidth 
                                id="title" 
                                variant="outlined"
                                type="text"
                                margin="dense"
                                value={draftTitle}
                                placeholder="Enter Title"
                                InputLabelProps={{style: {fontSize: 13, color: "#ccc"}}}
                                onChange={e => setDraftTitle(e.target.value)}
                            />
                        </Grid>

                        <Grid item md={12} lg={12} 
                            className={classes.customMarginTop}>
                            <Typography variant="subtitle1" color="primary">
                                Subject
                            </Typography> 

                            <div className='emailSchedulerMention'>        
                                <MentionsInput className="mentionWrapper"
                                    experimental__cutcopypaste="true"
                                    singleLine={true}
                                    //disabled={}
                                    value={value}
                                    placeholder='Press * to display options'
                                    allowSpaceInQuery={true}
                                    onChange={(e, newValue, newPlainTextValue, mentions) => {
                                        setValue(e.target.value);
                                        setMentions(mentions)
                                    }}>
                                        <Mention
                                            trigger="*"
                                            data={mentionOptionsForSubject}
                                            renderSuggestion={(suggestion, search, highlightedDisplay, index, focused) => (
                                                <div className={`user ${focused ? 'focused' : ''}`}>{/*`user ${focused ? 'focused' : ''} m-1 p-1`*/}
                                                    {highlightedDisplay}
                                                </div>
                                            )}
                                        />
                                </MentionsInput> 
                            </div> 
                        </Grid>

                        <Grid item md={12} lg={12} 
                            style={{marginTop:'24px'}}>
                            <Typography variant="subtitle1" color="primary">
                                Message Body
                            </Typography>

                            <Editor wrapperClassName="wysiwig-editor-wrapper"
                                editorClassName="form-control"
                                editorStyle={{ 
                                    border: '1px solid lightgray',
                                    minHeight: '200px',
                                    scrollbarWidth: 'none',
                                    lineHeight:1.3,
                                    position:'relative',
                                    padding: '12px',
                                    "&::-webkit-scrollbar": {
                                        display: "none"
                                    }
                                }}

                                mention={{
                                    separator: " ",
                                    trigger: "*",
                                    suggestions: mentionOptionsForBody
                                }}
                                editorState={editorState}
                                onEditorStateChange={onEditorStateChange}
                                toolbar={toolbarConfig}
                                placeholder='Press * to display options'
                                handlePastedText={handlePastedText}
                                //handleReturn={handleReturn}
                                //readOnly={}
                            />  
                        </Grid>

                    </Grid>

                    <Grid item md={12} lg={12} 
                        style={{marginTop:'16px'}}>
                            
                            <label >
                                <Typography className={classes.attachLink}>
                                    <AddIcon style={{marginRight:'4px'}}/> 
                                    Attach File
                                </Typography>
                                <input style={{display:'none'}}
                                    type="file"
                                    multiple
                                    id="emailSchedulerAttachment"
                                    onChange={onAttachmentSelected}
                            /> 
                            </label>  

                            <Grid item container alignItems='center' style={{marginTop:'8px'}}>
                            {
                                attachment && attachment.length > 0 &&
                                attachment.map((file, index) => {
                                    return showAttachment(file, index);
                                })
                            }
                            </Grid>

                            <Grid item container alignItems='center' style={{marginTop:'8px'}}>
                            {
                                path && path.length > 0 && path.map((filePath, index) => {
                                    return showAttachmentFromServer(filePath, index);
                                    })
                            }
                            </Grid>
                    </Grid> 

                    <div className="modal__footer">
                        <Button variant="contained" color="primary" 
                            onClick={onCreateEditEmailDraft}
                            className="modal__button">
                            {loading && <CircularProgress size={24} className={classes.circularProgress}/>}    
                            {draftObj ? "Update" : "Create"}
                        </Button>
                        <Button variant="outlined" style={{
                            minWidth: '130px',
                        }}
                        onClick={closeDrawer}
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
        </RightToLeftSideDrawer>
            
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
});

const mapDispatchToProps = (dispatch) => ({
    addOrgEmailDraft: (email_draft) => dispatch(addOrgEmailDraft(email_draft)),
    updateOrgEmailDraft: (email_draft) => dispatch(updateOrgEmailDraft(email_draft))
})

export default connect(mapStateToProps, mapDispatchToProps)(AddEditEmailDraftModal);