import { connect } from "react-redux";
import {Grid, Avatar, Tooltip, TextField, Typography, FormControlLabel, Checkbox,
  Button, Drawer} from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import { useIsMount } from "../../../components/useIsMount";
import { consoleToLog, trackGAEvent } from "../../../util/AppUtil";
import { setSearchMemberFilter } from "../../../actions/filters";
import useOuterClick from "../../../components/checklist/useOuterClick";
import {addSelectedMemberToFilter} from '../../../actions/filters';
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    backgroundColor: theme.palette.primary.light,
    width: '100%', 
    height: '100vh !important',
    flexGrow:1,
    overflowY:'auto',
    overflowX:'hidden',
  },
  backIcon: {
    color: "#202020",
    border: " 1px solid #202020",
    padding: "4px 0px",
    fontSize: "18px",
    minWidth: "40px",
    lineHeight: "18px",
    marginBottom: "10px",
    marginRight: "16px",
  },
  mobileHeading: {
    fontSize: '18px',
    fontWeight: 500,
    marginTop:'-8px'
  },
  customColumn2: {
    flexBasis: "13%",
    maxWidth: "13%",
    flexWrap: "nowrap !important",
  },

  avatarStyle1: {
    background:'#ffffaa !important',
    color:'#f7751e !important',
    border:'0.1px solid #f7751e !important'
  },

  avatarStyle2: {
    background:'#e1f5e7 !important',
    color:'#63b267 !important',
    border:'0.1px solid #63b267 !important'
  },

  avatarStyle3: {
    background:'#dff5ff !important',
    color:'#31a9ea !important',
    border:'0.1px solid #31a9ea !important'
  },

  avatarStyle4: {
    background:'#fff2d3 !important',
    color:'#d4ac4d !important',
    border:'0.1px solid #d4ac4d !important'
  },
  avatarStyle5: {
    background:'#dba8ff !important',
    color:'#4f1c96 !important',
    border:'0.1px solid #4f1c96 !important'
  }
}));

const MobileSelectFromMembersList = (props) => {
  const classes = useStyles();

  const [selectedMembersList, setSelectedMembersList] = useState(
    props.assignees && props.assignees.length > 0 ? props.assignees : []
  );
  const [thumbnailSelectedMembersList, setThumbnailSelectedMembersList] = useState([]);
  const [isActiveAdd, setIsActiveAdd] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const isMount = useIsMount();
  
  const handleDrawerOpen = (e) => {
    if(props.fromChecklistDetails){
      e.stopPropagation();
    }
    setOpenDrawer(true);
  }

  const handleDrawerClose = () => {
    setOpenDrawer(false);
    if(props.fromChecklistDetails){
      props.setSelectedTask(undefined);
      props.setShowTaskDetails(false);
    }
  }

  useEffect(() => {
    //consoleToLog("selectedMembersList: ", selectedMembersList);
    props.setSearchMemberFilter("");
    setSelectedMembersList(props.assignees);
    toggleThumbnailSelectedMembersList(props.assignees);
  }, []);

  useEffect(() => {
    if (!isMount) {
      setSelectedMembersList(props.assignees);
      toggleThumbnailSelectedMembersList(props.assignees);
    }
  }, [props.assignees]);

  const onApplyChanges = (list) => {
    //pass data to parent class
    props.setAssignedMemberList(list);
  };

  const toggleThumbnailSelectedMembersList = (list) => {
    if (!list) {
      return;
    }
    // const slicedArr = list.slice(0, 3);
    // setThumbnailSelectedMembersList(slicedArr);

    if (props.isShowOnly) {
      setThumbnailSelectedMembersList(list); 
    } else {
      const slicedArr = list.slice(0, 3);
      setThumbnailSelectedMembersList(slicedArr);
    }
  };

  const onClickAdd = (e /* , checklist_id */) => {
    setIsActiveAdd(!isActiveAdd);
    //setChecklistId(checklist_id);
  };

  const isSelectedMember = (checkMember) => {
    const tempArr = selectedMembersList.filter(
      (member) => member.user.id === checkMember.user.id
    );
    return tempArr.length > 0 ? true : false;
  };

  const onMemberChecked = (e, checkedMember) => {
    if (isSelectedMember(checkedMember)) {
      //remove
      props.fromInvoiceAccessor && 
      trackGAEvent(props.selectedOrganization.organization.name, 'Invoice Accessor', `${props.auth.user.firstname} ${props.auth.user.lastname} removed invoice accessor`);

      const arr = selectedMembersList.filter(
        (member) => member.user.id !== checkedMember.user.id
      );
      setSelectedMembersList(arr);
      toggleThumbnailSelectedMembersList(arr);
      onApplyChanges(arr);
      //after setting above list it will call useEffect on selectedMembersList
    } else {
      //add
      props.fromInvoiceAccessor && 
      trackGAEvent(props.selectedOrganization.organization.name, 'Invoice Accessor', `${props.auth.user.firstname} ${props.auth.user.lastname} added invoice accessor`);
      
      consoleToLog("Newly added: ", [...selectedMembersList, checkedMember]);
      const array = selectedMembersList.concat(checkedMember);
      setSelectedMembersList(array);
      toggleThumbnailSelectedMembersList(array);
      onApplyChanges(array);
      //after setting above list it will call useEffect on selectedMembersList
    }
  };

  const getMemberButtonTitle = () => {
    //if (this.state.isApplyActive) {//create checklist page || checklist item
    const number = selectedMembersList.length;
    //<i className="fa fa-plus" aria-hidden="true"></i>;
    if (number > 3 && number <= 6) {
      return `${number - 2}+`;
    } else if (number > 6) {
      return `4+`;
    }
  };


  const handleTextFieldChange = (e) => {
    const name = e.target.value;
    props.setSearchMemberFilter(name);
  };

  const onMemberClick = (selectedMember) => {
    const selected_member = props.selectedMembersFilter.filter((member) => member.id === selectedMember.id);
        consoleToLog('On member click filter list', selected_member);
        if(selected_member.length > 0) {
            return;
        } else {
          trackGAEvent(props.selectedOrganization.organization.name, 'Checklist Filtered By Members', `${props.auth.user.firstname} ${props.auth.user.lastname} clicked member`);
          props.addSelectedMemberToFilter(selectedMember);
        }
  }

  return (
    <Grid
      item
      style={{display:'flex', flexDirection:'row-reverse', justifyContent:'flex-end'}}
      className={classes.customColumn2}
    >
      <Grid item style={{ position: "relative", marginRight: "1px" }}>
        {!props.disabledAssignChecklistMembers   && (
            <Button
              className="onChecklist__hover"
              style={{
                position: "relative",
                width: "27px",
                height: "27px",
                minWidth: "0",
                marginTop: "-2px",
                fontSize: "14px",
                color: "#0071ff",
                borderRadius: "50%",
                border: "1px solid #0071ff",
                paddingTop: "5px",
                marginLeft:'2px',
                display: props.isFromWaitingForMyApproval ? "none" : "flex",
              }}
              onClick={handleDrawerOpen}
            >
              <i className="flaticon-add-2" style={{ paddingTop: "6px" }}></i>
            </Button>
        )}
          <Drawer open={openDrawer}
              variant="persistent"
              anchor="right"
              onClose={handleDrawerClose}
              classes={{
                  paper: classes.drawerPaper
              }}
          >
            <Grid item xs={12} style={{padding:'16px', marginTop:'4px'}}>
                  <Grid item container alignItems='center'>
                      <Grid item>
                          <Button
                              variant="outlined"
                              className={classes.backIcon}
                              onClick={handleDrawerClose}
                          > 
                              <ArrowBackIcon /> 
                          </Button>
                      </Grid>

                      <Grid item>
                          <Typography variant="body1" 
                              className={classes.mobileHeading}>
                              Members
                          </Typography>
                      </Grid>
                  </Grid>
                  <TextField
                    id="member_input"
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    label="Search Members"
                    style={{ marginBottom: "8px" }}
                    //value={textFieldValue}
                    onChange={handleTextFieldChange}
                  />
                  {props.members &&
                    props.members.length > 0 && 
                    props.members.map((member, index) => {
                      const fullName =
                        member.user.firstname + " " + member.user.lastname;
                      return (
                        <FormControlLabel
                          key={member.id + "_" + index}
                          control={
                            <Checkbox
                              checked={isSelectedMember(member) || false}
                              onChange={(e) => {
                                onMemberChecked(e, member);
                              }}
                              name="checkedB"
                              color="primary"
                            />
                          }
                          style={{
                            display: "block",
                            whiteSpace: "nowrap",
                            maxWidth: "auto",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                          label={fullName} 
                        />
                      );
                    })}
              </Grid>      
            </Drawer>
      </Grid>

      {
        /* display initial 2 members if any */
        selectedMembersList &&
          selectedMembersList.length > 0 &&
          thumbnailSelectedMembersList &&
          thumbnailSelectedMembersList.length > 0 && 
          thumbnailSelectedMembersList.map((member, index) => {
            return (
              <div style={{margin:'0 1px'}}>
                {(selectedMembersList.length > 3 && index === 2) && !props.isShowOnly ? (
                  <Grid item key={member.id}>
                      <Avatar>{getMemberButtonTitle()}</Avatar>
                  </Grid>
                ) : (

                  <Grid item key={member.id} 
                    // style={{cursor:'pointer'}} 
                    style={{ cursor: props.isFromClients ? "default" :"pointer"}}   
                    onClick={() =>{
                        if(props.isFromChecklistPage) {
                           onMemberClick(member);
                      }}
                      }
                    >
                      <Avatar>
                        {member.user.firstname.charAt(0).toUpperCase() +
                          member.user.lastname.charAt(0).toUpperCase()}
                      </Avatar>
                  </Grid>
                )}
              </div>
            );
          })
      }
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  selectedOrganization: state.organizationInfo.selectedOrganization,
  members: state.organizationInfo.members.filter((member) => {
    const fullName = member.user.firstname + " " + member.user.lastname;
    return fullName
      .toLowerCase()
      .includes(state.filters.searchMemberFilter.toLowerCase());
  }),
  selectedMembersFilter: state.filters.selectedMembersFilter
});

const mapDispatchToProps = (dispatch) => ({
  setSearchMemberFilter: (name) => dispatch(setSearchMemberFilter(name)),
  addSelectedMemberToFilter: (item) => dispatch(addSelectedMemberToFilter(item))
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileSelectFromMembersList);
