import { connect } from "react-redux";
import {
  Grid,
  Avatar,
  makeStyles,
  Tooltip,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
} from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import { useIsMount } from "../useIsMount";
import { consoleToLog, trackGAEvent } from "../../util/AppUtil";
import { setSearchMemberFilter } from "../../actions/filters";
import useOuterClick from "./useOuterClick";
import {addSelectedMemberToFilter} from '../../actions/filters';

const useStyles = makeStyles((theme) => ({
  customColumn2: {
    flexBasis: "13%",
    maxWidth: "13%",
    flexWrap: "nowrap !important",
  },

  avatarStyle1: {
    background:'#ffffaa !important',
    color:'#f7751e !important',
    border:'0.1px solid #f7751e !important'
  },

  avatarStyle2: {
    background:'#e1f5e7 !important',
    color:'#63b267 !important',
    border:'0.1px solid #63b267 !important'
  },

  avatarStyle3: {
    background:'#dff5ff !important',
    color:'#31a9ea !important',
    border:'0.1px solid #31a9ea !important'
  },

  avatarStyle4: {
    background:'#fff2d3 !important',
    color:'#d4ac4d !important',
    border:'0.1px solid #d4ac4d !important'
  },
  avatarStyle5: {
    background:'#dba8ff !important',
    color:'#4f1c96 !important',
    border:'0.1px solid #4f1c96 !important'
  }
}));

const SelectFromMemberList = (props) => {
  const classes = useStyles();

  const [selectedMembersList, setSelectedMembersList] = useState(
    props.assignees && props.assignees.length > 0 ? props.assignees : []
  );
  const [thumbnailSelectedMembersList, setThumbnailSelectedMembersList] = useState([]);
  const [isActiveAdd, setIsActiveAdd] = useState(false);
  const isMount = useIsMount();

  useEffect(() => {
    //consoleToLog("selectedMembersList: ", selectedMembersList);
    props.setSearchMemberFilter("");
    setSelectedMembersList(props.assignees);
    toggleThumbnailSelectedMembersList(props.assignees);
  }, []);

  useEffect(() => {
    if (!isMount) {
      setSelectedMembersList(props.assignees);
      toggleThumbnailSelectedMembersList(props.assignees);
    }
  }, [props.assignees]);

  const onApplyChanges = (list) => {
    //pass data to parent class
    props.setAssignedMemberList(list);
  };

  const toggleThumbnailSelectedMembersList = (list) => {
    if (!list) {
      return;
    }
    // const slicedArr = list.slice(0, 3);
    // setThumbnailSelectedMembersList(slicedArr);

    if (props.isShowOnly) {
      setThumbnailSelectedMembersList(list); 
    } else {
      const slicedArr = list.slice(0, 3);
      setThumbnailSelectedMembersList(slicedArr);
    }
  };

  const onClickAdd = (e /* , checklist_id */) => {
    setIsActiveAdd(!isActiveAdd);
    //setChecklistId(checklist_id);
  };

  const isSelectedMember = (checkMember) => {
    const tempArr = selectedMembersList.filter(
      (member) => member.user.id === checkMember.user.id
    );
    return tempArr.length > 0 ? true : false;
  };

  const onMemberChecked = (e, checkedMember) => {
    if (isSelectedMember(checkedMember)) {
      //remove
      props.fromInvoiceAccessor && 
      trackGAEvent(props.selectedOrganization.organization.name, 'Invoice Accessor', `${props.auth.user.firstname} ${props.auth.user.lastname}`);

      props.fromSupportEmailComponent &&
      trackGAEvent(props.selectedOrganization.organization.name, 'Email Accessor removed', `${props.auth.user.firstname} ${props.auth.user.lastname}`);

      const arr = selectedMembersList.filter(
        (member) => member.user.id !== checkedMember.user.id
      );
      setSelectedMembersList(arr);
      toggleThumbnailSelectedMembersList(arr);
      onApplyChanges(arr);
      //after setting above list it will call useEffect on selectedMembersList
    } else {
      //add
      props.fromInvoiceAccessor && 
      trackGAEvent(props.selectedOrganization.organization.name, 'Invoice Accessor', `${props.auth.user.firstname} ${props.auth.user.lastname}`);

      props.fromSupportEmailComponent &&
      trackGAEvent(props.selectedOrganization.organization.name, 'Email Accessor added', `${props.auth.user.firstname} ${props.auth.user.lastname}`);

      consoleToLog("Newly added: ", [...selectedMembersList, checkedMember]);
      const array = selectedMembersList.concat(checkedMember);
      setSelectedMembersList(array);
      toggleThumbnailSelectedMembersList(array);
      onApplyChanges(array);
      //after setting above list it will call useEffect on selectedMembersList
    }
  };

  const getMemberButtonTitle = () => {
    //if (this.state.isApplyActive) {//create checklist page || checklist item
    const number = selectedMembersList.length;
    //<i className="fa fa-plus" aria-hidden="true"></i>;
    if (number > 3 && number <= 6) {
      return `${number - 2}+`;
    } else if (number > 6) {
      return `4+`;
    }
  };

  const getMemberButtonToolTipTitle = () => {
    const title = selectedMembersList
      .slice(2)
      .map((m) => m.user.firstname + " " + m.user.lastname)
      .join(", ");
    return title;
  };

  const handleTextFieldChange = (e) => {
    const name = e.target.value;
    props.setSearchMemberFilter(name);
  };

  const innerRef = useOuterClick((ev) => {
    //consoleToLog("outside click11");
    setIsActiveAdd(false);
  });


  const onMemberClick = (selectedMember) => {
    const selected_member = props.selectedMembersFilter.filter((member) => member.id === selectedMember.id);
        consoleToLog('On member click filter list', selected_member);
        if(selected_member.length > 0) {
            return;
        } else {
          trackGAEvent(props.selectedOrganization.organization.name, 'Checklist Filtered By Members', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
          props.addSelectedMemberToFilter(selectedMember);
        }
  }

  return (
    <Grid
      item
      container
      lg={2}
      className={classes.customColumn2}
      ref={innerRef}
      //style={{marginTop: "-3px"}}
      onClick={props.handlePropagation}
    >
      <Grid item style={{ position: "relative", marginRight: "1px" }}>
        {!props.disabledAssignChecklistMembers   && (
          <Tooltip title="Add members" arrow>
            <Button
              className="onChecklist__hover"
              style={{
                position: "relative",
                width: "27px",
                height: "27px",
                minWidth: "0",
                marginTop: "-2px",
                fontSize: "14px",
                color: "#0071ff",
                borderRadius: "50%",
                border: "1px solid #0071ff",
                paddingTop: "5px",
                display: props.isFromWaitingForMyApproval ? "none" : "flex",
              }}
              onClick={(e) => onClickAdd(e)}
            >
              <i className="flaticon-add-2" style={{ paddingTop: "6px" }}></i>
            </Button>
          </Tooltip>
        )}
        {
          isActiveAdd && (
            <Grid
              item
              className={props.isShowOnly?`menu2 ${isActiveAdd ? "active" : "inactive"}`:`menu1 ${isActiveAdd ? "active" : "inactive"}`}
              style={{ padding: "4px 10px" }}
            >
              <TextField
                id="member_input"
                label="Search Members"
                style={{ marginBottom: "8px" }}
                //value={textFieldValue}
                onChange={handleTextFieldChange}
              />
              <Typography variant="button" gutterBottom>
                Members
              </Typography>
              {props.members &&
                props.members.length > 0 && 
                props.members.map((member, index) => {
                  const fullName =
                    member.user.firstname + " " + member.user.lastname;
                  return (
                    <FormControlLabel
                      key={member.id + "_" + index}
                      control={
                        <Checkbox
                          checked={isSelectedMember(member) || false}
                          onChange={(e) => onMemberChecked(e, member)}
                          name="checkedB"
                          color="primary"
                        />
                      }
                      style={{
                        display: "block",
                        whiteSpace: "nowrap",
                        maxWidth: "auto",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                      label={fullName} 
                    />
                  );
                })}
            </Grid>
          )
        }
      </Grid>

      {
        /* display initial 2 members if any */
        selectedMembersList &&
          selectedMembersList.length > 0 &&
          thumbnailSelectedMembersList &&
          thumbnailSelectedMembersList.length > 0 && 
          thumbnailSelectedMembersList.map((member, index) => {
            return (
              <div>
                {(selectedMembersList.length > 3 && index === 2) && !props.isShowOnly ? (
                  <Grid item key={member.id}>
                    <Tooltip title={getMemberButtonToolTipTitle()} arrow>
                      <Avatar>{getMemberButtonTitle()}</Avatar>
                    </Tooltip>
                  </Grid>
                ) : (

                  <Grid item key={member.id} 
                    // style={{cursor:'pointer'}} 
                    style={{ cursor: props.isFromClients ? "default" :"pointer"}}   
                    onClick={() =>{
                        if(props.isFromChecklistPage) {
                           onMemberClick(member);
                      }}
                      }
                    >
                    <Tooltip
                      title={member.user.firstname + " " + member.user.lastname}
                      arrow
                    >
                      <Avatar>
                        {member.user.firstname.charAt(0).toUpperCase() +
                          member.user.lastname.charAt(0).toUpperCase()}
                      </Avatar>
                    </Tooltip>
                  </Grid>
                )}
              </div>
            );
          })
      }
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  selectedOrganization: state.organizationInfo.selectedOrganization,
  members: state.organizationInfo.members.filter((member) => {
    const fullName = member.user.firstname + " " + member.user.lastname;
    return fullName
      .toLowerCase()
      .includes(state.filters.searchMemberFilter.toLowerCase());
  }),
  selectedMembersFilter: state.filters.selectedMembersFilter
});

const mapDispatchToProps = (dispatch) => ({
  setSearchMemberFilter: (name) => dispatch(setSearchMemberFilter(name)),
  addSelectedMemberToFilter: (item) => dispatch(addSelectedMemberToFilter(item))
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectFromMemberList);
