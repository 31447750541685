import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Drawer, Grid, Typography, Button, TextField, MenuItem, CircularProgress} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import HomeWorkOutlinedIcon from '@material-ui/icons/HomeWorkOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import LocalPhoneOutlinedIcon from '@material-ui/icons/LocalPhoneOutlined';
import MapOutlinedIcon from '@material-ui/icons/MapOutlined';
import FolderOutlinedIcon from '@material-ui/icons/FolderOutlined';

const useStyles = makeStyles((theme) => ({
    drawerPaper: {
        backgroundColor: theme.palette.primary.light,
        width: '100%', 
        height: '100vh',
        flexGrow:1,
        overflowY:'auto',
        overflowX:'hidden'
    },
    backIcon: {
        color: "#202020",
        border: " 1px solid #202020",
        padding: "4px 0px",
        fontSize: "18px",
        minWidth: "40px",
        lineHeight: "18px",
        marginBottom: "10px",
        marginRight: "16px",
    },
    mobileHeading: {
        fontSize: '16px',
        fontWeight: 600,
        marginTop:'-8px',
    },
    modalFooter: {
        position: 'absolute',
        bottom: '16px',
        left: 0,
        right:0,
        zIndex:15,
        background: '#fff',
        borderTop: '1px solid #ccc',
        padding:'6px 8px 6px 8px',
    },
    circularProgress: {
        marginLeft: 0,
        marginRight: theme.spacing(2),
        color: 'white'
    },
    displayFlex:{
        display: 'flex'
    },
    iconStyles: {
        fontSize:'25px',
        color:'#555'
    },
    customMarginTop: {
        marginTop:'14px',
        display: 'flex'
    },
    customMarginLeft: {
        marginLeft:'16px'
    },
    textStyle: {
        fontWeight: 600,
        maxWidth:'98%', 
        wordBreak:'break-word'
    }
}))

const MobileClientInfoDrawer = (props) => {
    const classes = useStyles();

    const clientEmails = props.clientInfo?.email && props.clientInfo?.email.length > 0 ? props.clientInfo?.email.split(',') : [];
    const customFields = props.clientInfo?.user_defined_field && props.clientInfo?.user_defined_field.length > 0 ? 
                        props.clientInfo?.user_defined_field : [];

    return (
        <Drawer open={props.openDrawer} 
            onClose={props.handleDrawerClose}
            variant='persistent'
            anchor='right'
            elevation={0}
            classes={{
                paper: classes.drawerPaper
            }}>
                <Grid item xs={12} style={{padding:'16px'}}>
                        <Grid item container alignItems='center'>
                            <Grid item xs={2}>
                                <Button
                                    variant="outlined"
                                    className={classes.backIcon}
                                    onClick={props.handleDrawerClose}
                                > 
                                    <ArrowBackIcon /> 
                                </Button>
                            </Grid>

                            <Grid item xs={10}>
                                <Typography variant="body1" 
                                    className={classes.mobileHeading}>
                                        {props.clientInfo?.name}
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} style={{marginTop:'16px'}}>
                            <Grid item className={classes.displayFlex}>
                                <Grid item xs={1}>
                                    <HomeWorkOutlinedIcon className={classes.iconStyles}/>
                                </Grid>

                                <Grid item xs={11} 
                                    className={classes.customMarginLeft}>
                                    <Typography variant='body2'>
                                        Client Name
                                    </Typography>
                                    <Typography variant='body1' 
                                        className={classes.textStyle}>
                                        {props.clientInfo?.name}
                                    </Typography>
                                </Grid>
                            </Grid>

                            {clientEmails && clientEmails.length > 0 &&
                            <Grid item className={classes.customMarginTop}>
                                <Grid item xs={1}>
                                    <EmailOutlinedIcon className={classes.iconStyles}/>
                                </Grid>

                                <Grid item xs={11}
                                    className={classes.customMarginLeft}>
                                    <Typography variant='body2'>
                                        Email Adresses
                                    </Typography>
                                {
                                    clientEmails && clientEmails.length > 0 &&
                                    clientEmails.map((email) => {
                                        return <Typography key={email} variant='subtitle1' 
                                                    className={classes.textStyle}>
                                                        {email}
                                                </Typography>
                                        })
                                }
                                </Grid>
                            </Grid>}

                            {props.clientInfo?.phone_number && 
                            <Grid item className={classes.customMarginTop}>
                                <Grid item xs={1}>
                                    <LocalPhoneOutlinedIcon className={classes.iconStyles}/>
                                </Grid>

                                <Grid item xs={11}
                                    className={classes.customMarginLeft}>
                                    <Typography variant='body2'>
                                        Mobile Number
                                    </Typography>
                                    <Typography variant='body1' 
                                        className={classes.textStyle}>
                                        {props.clientInfo?.phone_number}
                                    </Typography>
                                </Grid>
                            </Grid>}

                            {props.clientInfo?.address &&
                            <Grid item className={classes.customMarginTop}>
                                <Grid item xs={1}>
                                    <MapOutlinedIcon className={classes.iconStyles}/>
                                </Grid>

                                <Grid item xs={11}
                                    className={classes.customMarginLeft}>
                                    <Typography variant='body2'>
                                        Address
                                    </Typography>
                                    <Typography variant='body1' 
                                        className={classes.textStyle}>
                                        {props.clientInfo?.address}
                                    </Typography>
                                </Grid>
                            </Grid>}

                            {customFields && customFields.length > 0 &&
                            <Grid item className={classes.customMarginTop}>
                                <Grid item xs={1}>
                                    <FolderOutlinedIcon className={classes.iconStyles}/>
                                </Grid>

                                <Grid item xs={11}
                                    className={classes.customMarginLeft}>
                                    <Typography variant='body2'>
                                        Custom Fields
                                    </Typography>
                                    {
                                        customFields && customFields.length > 0 &&
                                        customFields.map((cf) => {
                                            return   (cf.type !== 'password' && cf.type !== 'file') &&
                                                    <Typography key={cf.value} variant='subtitle1'>
                                                            <span>
                                                                {cf.name}
                                                            </span>

                                                            <span style={{margin:'0 8px'}}>
                                                                -
                                                            </span>

                                                            <span className={classes.textStyle}>{cf.value}</span>
                                                    </Typography>
                                            })
                                    }
                                </Grid>
                            </Grid>
                            }
                        </Grid>
                </Grid>
            
        </Drawer>
    );
}

export default MobileClientInfoDrawer;