import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import { createBrowserHistory } from "history";
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import DashboardPage from '../pages/DashboardPage';
import LoginPage from '../pages/LoginPage';
import SignupPage from '../pages/SignupPage';
import UpdatePasswordPage from '../pages/UpdatePasswordPage';
import CreateTemplatePage from '../components/CreateTemplatePage';
import NotFoundPage from '../components/NotFoundPage';
import VerifyEmailPage from '../pages/VerifyEmailPage';
import OrgEmailVerificationSuccess from '../components/OrgEmailVerificationSuccess';
import GmailAddOnLogin from '../pages/GmailAddOnLogin';
import InvoiceAccountVerification from '../components/InvoiceAccountVerification';
import RootPage from '../pages/RootPage';
import VerifySupportEmailPage from '../components/VerifySupportEmailPage';
import OnboardingComponent from '../components/onboarding/OnboardingComponent';

export const history = createBrowserHistory();

const AppRouter = () => (
    <Router history={history}>
        <div>
            <Switch>
                <Route path="/" component={RootPage} exact={true} />
                <Route path="/dashboard" component={DashboardPage} exact/>
                <Route path="/group/:slug?" component={DashboardPage} exact/>
                <Route path="/checklist" component={DashboardPage}/>               
                <Route path="/template/create" component={CreateTemplatePage} />
                <Route path="/template/edit/:slug?" component={CreateTemplatePage} exact={true}/>
                <Route path="/predefined-template/create" component={CreateTemplatePage} exact={true}/>
                <Route path="/predefined-template/edit/:slug?"  component={CreateTemplatePage} exact={true}/>
                <Route path="/update-password/:slug?" component={UpdatePasswordPage} />
                <Route path="/gmail-addon/login" component={GmailAddOnLogin} />
                <Route path="/verify-email/:slug?" component={VerifyEmailPage} />
                <Route path="/organization-email-verification-success" component={OrgEmailVerificationSuccess} />
                <Route path="/organization-email-verification-failure" component={OrgEmailVerificationSuccess} />
                <Route path="/account-email-verification-success" component={InvoiceAccountVerification} />
                <Route path="/account-email-verification-failure" component={InvoiceAccountVerification} />
                <Route path="/verify_gmail" component={VerifySupportEmailPage} />
                <Route path="/verify_outlook" component={VerifySupportEmailPage} />
                <Route path="/onboarding" component={OnboardingComponent} exact/>
                <Route component={NotFoundPage} />
            </Switch>
        </div>
    </Router>
);

export default AppRouter;