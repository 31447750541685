import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Drawer, Grid, Typography, Button, Paper} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Calender from '../../components/Calender';
import {updateCheckListDueDateApi} from '../../services/authService';
import { consoleToLog, trackGAEvent} from '../../util/AppUtil';
import {connect} from 'react-redux';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    drawerPaper: {
        backgroundColor: theme.palette.primary.light,
        width: '100%', 
        height: '100vh',
        flexGrow:1,
        overflowY:'auto',
        overflowX:'hidden'
    },
    mobileHeading: {
        fontSize: '18px',
        fontWeight: 500,
        marginTop:'-8px'
    },
    backIcon: {
        color: "#202020",
        border: " 1px solid #202020",
        padding: "4px 0px",
        fontSize: "18px",
        minWidth: "40px",
        lineHeight: "18px",
        marginBottom: "10px",
        marginRight: "16px",
    },
    
}))

const MobileSetDueDateDrawer = (props) => {
    const classes = useStyles();

    const clickedDate = (str) => {
        
        const checklist_id = props.checklistDetails?.id;
        const checklistDueDate = !str ? str : moment(str).format("YYYY-MM-DD");
        
        updateCheckListDueDateApi(checklist_id, checklistDueDate)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response updateCheckListDueDateApi: ", res);
                const updatedChecklist = props.checkList.map((checklistItem) => {
                    if (checklistItem.id === checklist_id) {
                        checklistItem.due_date = checklistDueDate ;
                        
                    }
                    return checklistItem;
                });

                trackGAEvent(props.selectedOrganization.organization.name, 'Checklist Set DueDate', `${props.auth.user.firstname} ${props.auth.user.lastname} set DueDate`);

                props.setCheckList(updatedChecklist);
            })
            .catch((e) => {
                consoleToLog("updateCheckListDueDateApi error", e.response);
                if (e.response.data && e.response.data.message) {
                    //toast.error(e.response.data.message);
                    return false;
                }
            });

    }

    return (
        <Drawer open={props.openDueDateDrawer} 
            onClose={props.handleDueDateDrawerClose}
            variant='persistent'
            anchor='right'
            elevation={0}
            classes={{
                paper: classes.drawerPaper
            }}>
                <Grid item xs={12} style={{padding:'16px'}}>
                    <Grid item container alignItems='center'>
                        <Grid item>
                            <Button
                                variant="outlined"
                                className={classes.backIcon}
                                onClick={props.handleDueDateDrawerClose}
                            > 
                                <ArrowBackIcon /> 
                            </Button>
                        </Grid>

                        <Grid item>
                            <Typography variant="body1" 
                                className={classes.mobileHeading}>
                                Set Due Date
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item container justifyContent='center'>
                        <Grid item xs={8} style={{flexBasis:'69%', maxWidth:'69%'}}>
                            <Paper elevation={0}
                                style={{marginTop:'16px', border:'2px solid rgba(0,0,0,0.1)'}}>
                                <Calender clickedDate={clickedDate}/>
                            </Paper>
                        </Grid>
                    </Grid>

                </Grid>
            
        </Drawer>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
});

export default connect(mapStateToProps)(MobileSetDueDateDrawer);