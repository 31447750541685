import React, {useState, useEffect} from 'react';
import {Drawer, Grid, Typography, IconButton, AppBar, Toolbar, CircularProgress, Button, FormControlLabel, 
    Checkbox, TextField, InputAdornment, MenuItem} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { useIsMount } from '../useIsMount';
import {getInvoicePeriodicityPlaceholder, consoleToLog, trackGAEvent} from '../../util/AppUtil';
import { useSnackbar } from 'notistack';
import {updateCostAndPeriodicityForInvoiceConfigApi} from '../../services/invoiceService';
import {connect} from 'react-redux'

const useStyles = makeStyles((theme) => ({
    appBar: {
        background: "#F9F9F9",
        height: "45px",
        color: "#202020",
    },
    circularProgress: {
        marginLeft: 0,
        marginRight: theme.spacing(2),
        color: 'white'
    },
    MuiFormControlLabelroot : {
        '& .MuiFormControlLabel-label' : {
            fontSize: '14px',
            color: '#555555',
            marginLeft:'-4px'
        }
    },
    MuiTextFieldroot : {
        '& .MuiOutlinedInput-adornedEnd': {
            paddingRight: '8px'
        }
    },
    inputLabel: {
        marginBottom:'-7px'
    },
    customMarginBottom: {
        marginBottom: "-8px",
    },
    MuiFormControlroot: {
        "& .MuiOutlinedInput-input": {
            paddingLeft: "16px",
        },
        "& .MuiSelect-select.Mui-disabled": {
            cursor: "default !important",
            background: "#EEEEEE !important",
        },
        "& .MuiInputBase-input.Mui-disabled": {
            background: "#EEEEEE !important",
            color: "#555555 !important",
        },
    }
}))

const UpdatePriceConfigDrawer = (props) => {
    const classes = useStyles();
    const isMount = useIsMount();
    const {enqueueSnackbar} = useSnackbar();

    const [loading, setLoading] = useState(false);
    const [billableStatus, setBillableStatus] = useState(false);
    const [invoiceCost, setInvoiceCost] = useState(0);
    const [invoicePeriodicity, setInvoicePeriodicity] = useState(-1);
    const [selectedEntityId, setSelectedEntityId]=useState(-1);

    const entityList = props.accountList.map((account) => account.entities).flat();

    useEffect(() => {
        if(props.invoiceObj) {
            setBillableStatus(props.invoiceObj?.billable);
            setInvoiceCost(props.invoiceObj?.cost);
            setInvoicePeriodicity(props.invoiceObj?.periodicity);
            setSelectedEntityId(props.invoiceObj?.entity_id ? props.invoiceObj?.entity_id : -1)
        }
    }, [props.invoiceObj]);

    useEffect(() => {
        if(!isMount) {
            if(!props.openDrawer) {
                setBillableStatus(false);
                setInvoiceCost(0);
                setInvoicePeriodicity(-1);
                setSelectedEntityId(-1);
            }
        }

    }, [props.openDrawer])

    const onUpdatePriceConfigClick = () => {
        const organization_id = props.selectedOrganization.organization.id;
        const company_id = props.clientId;
        const id = props.invoiceObj.id;
        const billable = billableStatus;
        const cost = invoiceCost;
        const entity_id = selectedEntityId

        if(selectedEntityId === -1) {
            enqueueSnackbar('Please select account', {variant: 'error'});
            return;
        }

        if(billableStatus && (!cost || cost == '' || cost == 0)) {
            enqueueSnackbar('Invoice cost cannot be empty', {variant: 'error'});
            return;
        }

        const periodicity = invoicePeriodicity;
        if(billableStatus && props.invoiceObj?.template.schedule && (!periodicity || periodicity == -1 || periodicity == '-1')) {
            enqueueSnackbar('Please select invoice periodicity', {variant: 'error'});
            return;
        }

        setLoading(true);
        updateCostAndPeriodicityForInvoiceConfigApi(organization_id, company_id, id, cost, periodicity, billable, entity_id)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response updateCostAndPeriodicityForInvoiceConfigApi: ", res);
                setLoading(false);
                enqueueSnackbar(res.message, {variant:'success'});
                trackGAEvent(props.selectedOrganization.organization.name, 'Client Invoice Settings', `${props.auth.user.firstname} ${props.auth.user.lastname}`);

                const updateInvoiceConfigArr = props.invoiceConfigList.map((invoice, index) => {
                    if(invoice.id === id) {
                        invoice = res.invoice_config;
                    }
                    return invoice;
                });
                
                props.setInvoiceConfigList(updateInvoiceConfigArr);
                props.handleCloseDrawer();
            })
            .catch((e) => {
                consoleToLog("updateCostAndPeriodicityForInvoiceConfigApi res", e);
                setLoading(false);
                if (e.response.data && e.response.data.message) {
                enqueueSnackbar(e.response.data.message, {
                    variant:'error'
                });
                }
            }); 
    }

    const onInvoicePeriodicityChanged = (e) => {
        setInvoicePeriodicity(e.target.value);
    }

    const onChangeAcc = (e) => {
        const value = e.target.value;
        setSelectedEntityId(value);
    }

    return (
        <Drawer open={props.openDrawer}
            onClose={props.handleCloseDrawer}
            anchor="right"
            style={{maxWidth:'18% !important'}}
            className="drawerContainer">
            <AppBar position="static" className={classes.appBar} elevation={0}>
                <Toolbar style={{ minHeight: "40px", padding: "0px 16px" }}>
                    <Typography
                        variant="body1"
                        style={{ fontWeight: "600", marginRight: "auto" }}
                        >
                        {props.invoiceObj?.service_name}
                    </Typography>
                    <IconButton
                        style={{ color: "#202020", marginRight: "-16px" }}
                        onClick={props.handleCloseDrawer}>
                    <ClearIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>

            <div item className="modal__main" style={{overflow:'auto'}}>
                <Grid item sm={12}>
                    <FormControlLabel
                        classes={{
                            root: classes.MuiFormControlLabelroot
                        }}
                        control={
                        <Checkbox
                            name="checkedB"
                            color="primary"
                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                            checked={billableStatus}
                            onChange={(event) => setBillableStatus(event.currentTarget.checked)}
                            //onClick={(e) => e.stopPropagation()}
                        />
                        }
                        label="Billable"
                    />
                </Grid>

                {billableStatus &&
                    <>
                    <Grid item sm={12} style={{marginTop: "8px"}}>
                        <Typography variant="subtitle1" 
                            className={classes.customMarginBottom}>
                            Select entity from which invoice to be generated:
                        </Typography>

                        <TextField
                            fullWidth
                            variant="outlined"
                            margin="dense"
                            onChange={onChangeAcc}
                            select
                            value={selectedEntityId}
                            classes={{root: classes.MuiFormControlroot}}
                        >
                        <MenuItem value={-1} disabled={selectedEntityId !== -1 && true}>
                            Select Entity
                        </MenuItem>
                        {entityList && entityList.length > 0 && entityList.map((entity) => {
                            return (
                            <MenuItem key={entity.id} value={entity.id}>
                                {entity.name}
                            </MenuItem>
                            );
                        })}
                        </TextField>
                    </Grid>

                    <Grid item sm={12} style={{marginTop:'8px'}}>
                        <Typography variant='subtitle1' className={classes.inputLabel}>
                            Cost    
                        </Typography>
                        <TextField
                            fullWidth
                            margin="dense"
                            variant="outlined"
                            value={invoiceCost === 0 ? '' : invoiceCost}
                            onChange={(e) => setInvoiceCost(e.target.value)}
                            placeholder='Cost'
                            InputProps={{
                            endAdornment: <InputAdornment position="end">
                                            {`${getInvoicePeriodicityPlaceholder(invoicePeriodicity)}`}
                                        </InputAdornment>,
                            }}
                            disabled={!billableStatus}
                            InputLabelProps={{style: {fontSize: 13, marginTop:'3px'}}}
                            style={{paddingRight:"10px"}}
                        />
                    </Grid>

                    {props.invoiceObj?.template.schedule &&
                    <Grid item sm={12} style={{marginTop:'8px'}}>
                        <Typography variant='subtitle1' className={classes.inputLabel}>
                            Periodicity    
                        </Typography>
                        <TextField
                            style={{ width: "100%"}} 
                            id="invoicerangeSelector"
                            margin="dense"
                            select
                            placeholder="Invoice Periodicity"
                            variant="outlined"
                            onChange={(e) => onInvoicePeriodicityChanged(e)}
                            InputLabelProps={{style: {fontSize: 14}}}
                            value={invoicePeriodicity ? invoicePeriodicity : -1}
                            classes={{
                                root: classes.MuiTextFieldroot
                            }}
                            disabled={!billableStatus}
                        >
                            <MenuItem value={-1}>Invoice Periodicity</MenuItem>
                            {   props.invoicePeriodRangeArr && props.invoicePeriodRangeArr.length > 0 
                                && props.invoicePeriodRangeArr.map((op, index) => {
                                    return <MenuItem key={op} 
                                                value={op.value}>
                                                {op.display_name}
                                        </MenuItem>
                                })
                            }
                        </TextField>
                    </Grid>}
                    </>
                }
            </div>


            <div className='modal__schdulerFooter'>
                <Button variant="contained" color="primary"
                    onClick={onUpdatePriceConfigClick}
                    className="modal__button">
                    {loading && <CircularProgress size={24} className={classes.circularProgress}/>}
                        Update
                </Button>
                <Button variant="outlined" style={{
                    minWidth: '130px',
                }} onClick={props.handleCloseDrawer}>
                    Cancel
                </Button>
            </div>
        </Drawer>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    accountList: state.organizationInfo.accountList
  });

export default connect(mapStateToProps)(UpdatePriceConfigDrawer);