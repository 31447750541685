import React,{useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import { Grid, Container, Typography,  Button, Popover, Tooltip, withStyles, CircularProgress, Drawer } from '@material-ui/core';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { useIsMount } from '../../components/useIsMount';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { DateRangePicker } from "materialui-daterange-picker";
import ChecklistCompaniesFilter from '../../components/filters/ChecklistCompaniesFilter';
import { getEmailReportApi } from '../../services/reportService';
import { consoleToLog, trackGAEvent } from '../../util/AppUtil';
import SortIcon from '@material-ui/icons/Sort';

const useStyles = makeStyles((theme) => ({
    mainContent : {
        width: '100%',
        height:'calc(100vh - 48px)',
        backgroundColor: theme.palette.primary.light,
        transition: ".5s",
        flexGrow: 1,
        //overflow:'hidden',
        overflow:'auto',
        overflowX:'hidden'
        },
    container: {
        marginTop: "8px",
    },
    notAttachmentsImage: {
        height: "13rem",
        width: "15rem",
        marginTop: "3rem",
        [theme.breakpoints.down(420)] : {
            height: "11rem",
            width: "13rem",
        },
        },
    notAttachmentsText: {
        fontSize: "20px",
        fontWeight: "600",
        [theme.breakpoints.down(460)] : {
            fontSize: "18px",
            fontWeight: "600",
        },
        [theme.breakpoints.down(420)] : {
            fontSize: "16px",
            fontWeight: "600",
        },
        [theme.breakpoints.down(380)] : {
            fontSize: "15px",
            fontWeight: "600",
        },

    },
    notAttachmentsButton: {
        fontSize: "15px",
        width: "19rem",
        marginTop: "20px",
    },
    drawerPaper: {
        backgroundColor: theme.palette.primary.light,
        width: '100%', 
        height: '100vh',
        flexGrow:1,
        overflow:'auto'
    },
    backIcon: {
        color: "#202020",
        border: " 1px solid #202020",
        padding: "4px 0px",
        fontSize: "18px",
        minWidth: "40px",
        lineHeight: "18px",
        marginBottom: "10px",
        marginRight: "16px",
    },
    mobileHeading: {
        fontSize: '18px',
        fontWeight: 500,
        marginTop:'-8px'
    },
    reportListContainer: {
        padding:'8px',
        background:'#fff',
        marginBottom:'8px'
    },
    customMarginTop: {
        marginTop:'8px'
    },
    companyName: {
        fontSize:'16px',
        fontWeight:'600'
    },
    emailSubject: {
        fontSize:'14px'
    },
    dateStyle: {
        padding:'4px 10px',
        borderRadius:'4px',
        background:'#0071FF',
        color:'white',
        fontSize:'13px'
    },
    notOpened: {
        padding:'4px 10px',
        borderRadius:'4px',
        background:'#CCCCCC',
        color:'#555555',
        fontSize:'13px'
    },
    lastOpened: {
        padding:'4px 10px',
        borderRadius:'4px',
        background:'#3B961B',
        color:'white',
        fontSize:'13px'
    },
    filterIcon: {
        position:'fixed',
        right:'24px',
        bottom:'16px'
    }

}))

const MobileEmailReports = (props) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const isMount = useIsMount();

    const [dateRange, setDateRange] = useState({});
    const [startDateFilter, setStartDateFilter] = useState("");
    const [endDateFilter, setEndDateFilter] = useState("");
    const [selectedCompanies, setSelectedCompanies] = useState([]);
    const [emailReportData, setEmailReportData] = useState([]);
    
    const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
    const [page, setPage] = useState(1);
    const [nextPage, setNextPage] = useState(false);
    const [isMoreLoading, setMoreLoading] = useState(false);

    const [emailReportsLoading, setEmailsReportsLoading] = useState(false);
    const [anchorEl, setAnchorEl] = useState(false);
    const [clearFilter, setClearFilter] = useState(false);

    const toggle = () => setAnchorEl(!anchorEl);

    const handleOpenFilterDrawer = () => {
        setOpenFilterDrawer(true);
    }

    const handleCloseFilterDrawer = () => {
        setOpenFilterDrawer(false);
    }

    const onUpdateClearFilterValue = () => {
        setClearFilter(false);
    }

    const onSelectedCompanies = (sts) => {
        setSelectedCompanies(sts);
    }

    useEffect(() => {
        if(!isMount) {
            consoleToLog("***On filter change  in useeffect");
            pageReset();
        }
    }, [selectedCompanies, startDateFilter, endDateFilter])
    
    useEffect(() => {
        if(!isMount) {
            if (page === 1) {
                consoleToLog("***On page change  in useeffect");
                getEmailReport();
            }
        }    
    }, [page]);

    useEffect(() => {
        //consoleToLog("hi", isMoreLoading);
        if(!isMount) {
            if (isMoreLoading) {
                consoleToLog("***On more loading  in useeffect");
                getEmailReport();
            }
        }
    }, [isMoreLoading])

    const pageReset = () => {
        //on page change useeffect will be called and getEmail api called
        setPage(1);
        setNextPage(false);
        if(page===1) getEmailReport();
    }
    
    //Last Month
    var prevMonthFirstDay = new moment().subtract(1, 'months').date(1);
        
    //for next month last day
    var thisMonthLastDay = new moment().add(1, 'months').date(0);

    //for last day of previous month
    var lastdayLastMonth = moment(lastdayLastMonth).subtract(1,'months').endOf('month').format('YYYY-MM-DD');

    let currentFinancialYearStart;
    let currentFinancialYearEnd;

    currentFinancialYearStart = (moment().month() < 3) ? moment().subtract(1, 'year').month('April').startOf('month').format('YYYY-MM-DD')
    : moment().month('April').startOf('month').format('YYYY-MM-DD');

    currentFinancialYearEnd =  (moment().month() < 3) ? moment().month('March').endOf('month').format('YYYY-MM-DD')
    : moment().add(1, 'year').month('March').endOf('month').format('YYYY-MM-DD');

    let lastFinancialYearStart = moment(currentFinancialYearStart).subtract(1, 'year').format('YYYY-MM-DD');
    let lastFinancialYearEnd = moment(currentFinancialYearEnd).subtract(1, 'year').format('YYYY-MM-DD');

    //api
    const getEmailReport = () => {
        consoleToLog("Api: selectedCompanies", selectedCompanies);
        consoleToLog("Api: startDateFilter", startDateFilter);
        consoleToLog("Api: endDateFilter", endDateFilter);

        const organization_id = props.selectedOrganization.organization.id;
        /* if(!selectedCompanies || selectedCompanies.length === 0){
            setFollowupData([]); 
            return;
        } */
        if(!startDateFilter || startDateFilter.length === 0) {
            setEmailReportData([]); 
            return;
        };
        if(!endDateFilter || endDateFilter.length === 0){
            setEmailReportData([]); 
            return;
        };

        const ids = selectedCompanies.id;
        if(!isMoreLoading) setEmailsReportsLoading(true);

        getEmailReportApi(organization_id, page, startDateFilter, endDateFilter,  ids )
            .then((response) => {
                const res = response.data;
                consoleToLog("Response getEmailReportApi: ", res);
                /* enqueueSnackbar(res.message, {
                    variant:'success'
                }); */
                setEmailReportData(res.email_list);

                if (isMoreLoading) {
                    const newItems = emailReportData.concat(res.email_list);
                    setEmailReportData(newItems);
                    setNextPage(res.next_page);
                    setPage(page + 1);
                    setMoreLoading(!isMoreLoading);

                } else {
                    trackGAEvent(props.selectedOrganization.organization.name, 'Filter Email Report', `${props.auth.user.firstname} ${props.auth.user.lastname} filtered email report`);
                    setNextPage(res.next_page)
                    if (res.next_page) setPage(page + 1);
                    setEmailReportData(res.email_list);
                }
                setEmailsReportsLoading(false);
            })
            .catch((e) => {
                consoleToLog("getEmailReportApi error", e.response);
                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant:'error'
                    });
                    return false;
                }
                setEmailsReportsLoading(false);
            });
    }

    const ReportsNotFound = () => {
        return (
            <div style={{display:'flex', alignItems:'center', height:'calc(100vh - 48px)', marginTop:'-100px'}}>
            {/* Not Found*/}
            <Grid container direction="row">
                <Grid item container direction="column"
                    alignItems="center"
                    justifyContent="center"
                    sm={12}>
                    <img
                        alt="No Reports Found"
                        src="/images/no_attachment_found.svg"
                        className={classes.notAttachmentsImage}
                    />
                </Grid>
                <Grid item container direction="column"
                    alignItems="center"
                    justifyContent="center">
                    <Typography className={classes.notAttachmentsText}>
                        No Reports Found, You need to select all filters
                    </Typography>
                </Grid>
                <Grid item container direction="row"
                    alignItems="center"
                    justifyContent="center">

                    <Button variant="contained"
                        color="primary"
                        //endIcon={<PublishIcon />}
                        size="large"
                        className={classes.notAttachmentsButton}
                        onClick={handleOpenFilterDrawer}
                    >
                        Add Filters
                    </Button>

                </Grid>
            </Grid>
            {/* Not Found*/}
            </div>
        );
    };

    const loadMoreLogic = () => {
        return (
            <Grid item container justifyContent='center'>
                <Grid item lg={12}>
                {nextPage && !isMoreLoading &&
                    <div style={{margin:'16px auto 32px auto'}}>
                        <Button type="button"
                            color="secondary"
                            variant="contained"
                            onClick={() => {
                                setMoreLoading(!isMoreLoading);
                            }}>Load More
                        </Button>
                    </div>}

                { isMoreLoading &&
                    <CircularProgress size={35} style={{position:'relative', left:'50%', right:'50%', margin:'16px 0px'}}/>
                }
                </Grid>
            </Grid>
        )
    }

    const filterStyle = () => {
        return (
            <Button variant='contained' color='secondary' 
                onClick={handleOpenFilterDrawer}
                className={classes.filterIcon}>
                <SortIcon style={{color:'#fff'}}/>
            </Button>
        )
    }

    const emailReportDataUI = () => {
        return (
                <Grid item xs={12} sm={12}> 
                    {
                        emailReportData.map((report) => {
                            return <Grid item container direction='column' 
                                        key={report.id}
                                        className={classes.reportListContainer}>
                                        <Grid item>
                                            <Typography className={classes.companyName}>
                                                {report.checklist_company_name}
                                            </Typography>
                                        </Grid>

                                        <Grid item className={classes.customMarginTop}>
                                            <Typography className={classes.emailSubject}>
                                                {report.email_subject}
                                            </Typography>
                                        </Grid>

                                        <Grid item className={classes.customMarginTop}>
                                            <Typography className={classes.emailSubject}
                                                style={{fontWeight:'600'}}>
                                                Template Name
                                            </Typography>
                                            <Typography className={classes.emailSubject}>
                                                {report.checklist_template_name}
                                            </Typography>
                                        </Grid>

                                        <Grid item className={classes.customMarginTop}>
                                            <Typography className={classes.emailSubject}
                                                style={{fontWeight:'600'}}>
                                                Subtitle
                                            </Typography>
                                            <Typography className={classes.emailSubject}>
                                                {report.checklist_subtitle}
                                            </Typography>
                                        </Grid>

                                        <Grid item className={classes.customMarginTop}>
                                            <Typography className={classes.emailSubject}
                                                style={{fontWeight:'600'}}>
                                                Open Count
                                            </Typography>
                                            <Typography className={classes.emailSubject}>
                                                {report.open_count}
                                            </Typography>
                                        </Grid>

                                        <Grid item container alignItems='center'
                                            spacing={1}
                                            className={classes.customMarginTop}>
                                            <Grid item>
                                                <Typography className={classes.dateStyle}>
                                                    {moment(report.created_at).fromNow()}
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography className={report.open_timestamp ? classes.lastOpened : classes.notOpened}>
                                                    {report.open_timestamp ? 'Last Opened ' + moment(report.open_timestamp).format("DD-MM-YYYY hh:mm A") : "Not Opened"}
                                                </Typography>
                                            </Grid>
                                            
                                            
                                        </Grid>
                                    </Grid>
                        })
                    }
                </Grid>
        )
    }

    return (
        <div className={classes.mainContent}>
            <div className={classes.container}>
                { emailReportsLoading ?
                    <Grid item container>
                        <Grid item lg={12}>
                            <CircularProgress size={35} style={{position:'relative', left:'50%', right:'50%', margin:'16px 0px'}}/>
                        </Grid>
                    </Grid>
                    :
                    (emailReportData && emailReportData.length > 0) ? 
                        <div style={{paddingBottom: "35px"}}>
                            {emailReportDataUI()}
                            {loadMoreLogic()}
                        </div>
                    : ReportsNotFound() 
                    
                }
            </div>
            <Drawer open={openFilterDrawer} 
                onClose={handleCloseFilterDrawer}
                variant='persistent'
                anchor='right'
                elevation={0}
                classes={{
                    paper: classes.drawerPaper
                }}
            >

                <Grid item xs={12} style={{padding:'16px'}}>
                    <Grid item container alignItems='center'>
                        <Grid item>
                            <Button
                                variant="outlined"
                                className={classes.backIcon}
                                onClick={handleCloseFilterDrawer}
                            > 
                                <ArrowBackIcon /> 
                            </Button>
                        </Grid>

                        <Grid item>
                            <Typography variant="body1" 
                                className={classes.mobileHeading}>
                                Filter Activity
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <Grid item>
                            <Grid item container>
                                <Grid item >
                                    <Typography
                                        variant="body1"
                                        style={{lineHeight:'42px', color: '#888888'}}
                                        >
                                            Filter By Date
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item container style={{marginTop:'-6px'}}>
                                <Grid item xs={12}>
                                    <Button variant='outlined'
                                        fullWidth
                                        color='primary'
                                        style={{fontWeight:"300 !important"}}
                                        onClick={toggle}>
                                        {(startDateFilter.length > 0 && endDateFilter.length > 0) ? 
                                        `${moment(startDateFilter).format('DD-MM-YYYY')} - 
                                        ${moment(endDateFilter).format('DD-MM-YYYY')}` : "Start Date - End Date"}
                                    </Button>
                                        <DateRangePicker
                                            open={anchorEl}
                                            
                                            toggle={toggle}
                                            onChange={(range) => {
                                                //consoleToLog("range", range, dateRange);
                                                setDateRange(range);
                                                setStartDateFilter(moment(range.startDate).format('YYYY-MM-DD'))
                                                setEndDateFilter(moment(range.endDate).format('YYYY-MM-DD'))
                                                setClearFilter(false);
                                            }}
                                            initialDateRange={{
                                                startDate:  props.startDateFilter,
                                                endDate: props.endDateFilter
                                            }}
                                            definedRanges={[
                                            {
                                                label: `${moment(lastFinancialYearStart, 'YYYY-MM-DD').format('MMM-YYYY')}-${moment(lastFinancialYearEnd, 'YYYY-MM-DD').format('MMM-YYYY')}`,
                                                startDate: lastFinancialYearStart,
                                                endDate: lastFinancialYearEnd
                                            },
                                            {
                                                label: `${moment(currentFinancialYearStart, 'YYYY-MM-DD').format('MMM-YYYY')}-${moment(currentFinancialYearEnd, 'YYYY-MM-DD').format('MMM-YYYY')}`,
                                                startDate: currentFinancialYearStart,
                                                endDate: currentFinancialYearEnd
                                            },
                                            {
                                                label: 'Last Month',
                                                startDate: prevMonthFirstDay,
                                                endDate: lastdayLastMonth
                                            },
                                            {
                                                label: 'This Month',
                                                startDate: new Date(),
                                                endDate: thisMonthLastDay
                                            }
                                            ]}
                                        /> 
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{marginTop:'16px'}}>
                            <Grid item>
                                <Typography
                                    variant="body1"
                                    style={{color: '#888888', marginBottom:'-8px'}}
                                >
                                Select Company
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <ChecklistCompaniesFilter isFromReport = {true}
                                    clearFilter={clearFilter}
                                    isSingleSelect={true}
                                    onUpdateClearFilterValue={onUpdateClearFilterValue} 
                                    onSelectedCompanies={onSelectedCompanies}
                                />
                            </Grid>
                        </Grid>
                        <Grid item style={{marginTop: "24px"}}>
                            <Button variant="contained"
                                color="primary"
                                style={{fontWeight:"300 !important"}}
                                className="modal__button"
                                onClick={() => {
                                    setClearFilter(true)
                                    setDateRange();
                                    setStartDateFilter("");
                                    setEndDateFilter("");
                                    setSelectedCompanies([]);
                                    setEmailReportData([]); 
                                }}
                                >
                                Clear Filters
                            </Button>
                        </Grid>
                    </Grid>

                </Grid>
            
            </Drawer>
            {emailReportData && emailReportData.length > 0 && filterStyle()}
        </div>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    startDateFilter: state.activityReports.startDateFilter,
    endDateFilter: state.activityReports.endDateFilter,
});

export default connect(mapStateToProps)(MobileEmailReports);