import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles'; 
import { Grid, Container, Typography,  Button, Popover, Tooltip, withStyles, CircularProgress } from '@material-ui/core';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import { addOrgMember } from '../actions/selectedOrganization';
import { DateRangePicker } from "materialui-daterange-picker";
import moment from 'moment';
import { useIsMount } from './useIsMount';
import { consoleToLog, trackGAEvent } from '../util/AppUtil';
import RightToLeftSideDrawer from './RightToLeftSideDrawer';
import ChecklistCompaniesFilter from './filters/ChecklistCompaniesFilter';
import Chart from 'react-apexcharts';
import AutocompleteTemplate from './AutocompleteTemplate';
import Paper from '@material-ui/core/Paper';
import { getCompletionReportApi, getCompleteIncompleteChecklistApi } from '../services/reportService';
import ChecklistReportsDrawer from './ChecklistReportsDrawer';


const useStyles = makeStyles((theme) => ({
    mainContent : {
        width: '100%',
        // backgroundColor: theme.palette.primary.light, 
        transition: ".5s", 
        flexGrow: 1,
        //overflow:'hidden',
        overflow:'auto',
        overflowX:'hidden'
        },
    
    container: {
        marginTop: "8px"
    },
    graphMain: {
        padding: "1rem !important",
        fontSize: "11px !important",
        marginTop: "1rem !important",
        boxShadow: "0 .5rem 1rem rgba(0,0,0,.15) !important",
        width: "32rem !important",
        height: "21rem !important",
        marginLeft: "40px !important",
        marginBottom: "50px"
    },

    icons : {
        color: "black",
        fontSize: "18px",
        minWidth:'30px',
        marginLeft: "8px",
        marginTop:'-3px'
    } ,
    notAttachmentsImage: {
        height: "13rem",
        width: "15rem",
        marginTop: "3rem",
      },
      notAttachmentsText: {
        fontSize: "20px",
        fontWeight: "600",
      },
      notAttachmentsButton: {
        fontSize: "15px",
        width: "19rem",
        marginTop: "20px",
      }   
    })
    
)

const ReportByTemplate = (props) => {
    const {showDrawer} = props;
    const classes = useStyles(showDrawer);
    const { enqueueSnackbar } = useSnackbar();
    const [anchorEl, setAnchorEl] = useState(false);
 
    const [clearFilter, setClearFilter] = useState(false);
    const [selectedCompanies, setSelectedCompanies] = useState([]);
    const [completionDataForClient, setCompletionDataForClient] = useState([]);
    const [selectedTemplates, setSelectedTemplates] = useState([]);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [openFilters, setOpenFilters] = useState(false);
    const [startDateFilter, setStartDateFilter] = useState("");
    const [endDateFilter, setEndDateFilter] = useState("");
    const [page, setPage] = useState(1);
    const [nextPage, setNextPage] = useState(false);
    const [isMoreLoading, setIsMoreLoading] = useState(false);
    const [clientDataLoading,setClientDataLoading] = useState(false);
    const isMount = useIsMount(); 
    const [tempObj, setTempObj] = useState(undefined);
    const [tempObj1, setTempObj1] = useState(undefined);
    const [statusValue, setStatusValue] = useState(undefined);
    const [openChecklistDrawer, setOpenChecklistDrawer] = useState(false);
    const [checklistLoading, setChecklistLoading] = useState(false);
    const [checkList, setCheckList] = useState([]);
    const [completionData, setCompletionData] = useState([]);
    const [completionReportsLoading,setCompletionReportsLoading]= useState(false);
    const [dateRange, setDateRange] = useState({});
    const [callDrawer, setCallDrawer] = useState(props.isFromTemplate);

    const checklistDetails = window.location.toString().includes("checklist");

    useEffect(() => {
        document.title = 'Completion Reports'
    }, []);

    useEffect(() => {
        if(!isMount) {
            if(!checklistDetails) {
                document.title = 'Completion Reports'
            }
        }
    }, [checklistDetails]);

    useEffect(() => {
        if(!isMount) {
            if(props.templateTab) {
                toggleDrawer();
                setOpenFilters(!openFilters);
        }
        }
    }, [props.templateTab]);

    //Last Month
    var prevMonthFirstDay = new moment().subtract(1, 'months').date(1)
        
    //for next month last day
    var thisMonthLastDay = new moment().add(1, 'months').date(0);

    //for last day of previous month
    var lastdayLastMonth = moment(lastdayLastMonth).subtract(1,'months').endOf('month').format('YYYY-MM-DD');

    let currentFinancialYearStart;
    let currentFinancialYearEnd;

    currentFinancialYearStart = (moment().month() < 3) ? moment().subtract(1, 'year').month('April').startOf('month').format('YYYY-MM-DD')
    : moment().month('April').startOf('month').format('YYYY-MM-DD');

    currentFinancialYearEnd =  (moment().month() < 3) ? moment().month('March').endOf('month').format('YYYY-MM-DD')
    : moment().add(1, 'year').month('March').endOf('month').format('YYYY-MM-DD');

    let lastFinancialYearStart = moment(currentFinancialYearStart).subtract(1, 'year').format('YYYY-MM-DD');
    let lastFinancialYearEnd = moment(currentFinancialYearEnd).subtract(1, 'year').format('YYYY-MM-DD');



    const openDateRangePicker = (event) => {
        setAnchorEl(event.currentTarget);
    };


    const open = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onUpdateClearFilterValue = () => {
        setClearFilter(false);
        props.setFilterCountForTemp(0);
    }

    // const onSelectedCompanies = (sts) => {
    //     setSelectedCompanies(sts);
    // }

    const handleChecklistDrawerOpen = () => {
        setOpenChecklistDrawer(true);
    }

    const handleChecklistDrawerClose = () => {
        setOpenChecklistDrawer(false);
    }

    //api
    const getCompletionReport = () => {
        consoleToLog("Api: SelectedTemplates", selectedTemplates);
        consoleToLog("Api: startDateFilter", startDateFilter);
        consoleToLog("Api: endDateFilter", endDateFilter);

        const organization_id = props.selectedOrganization.organization.id;
        

        if(!selectedTemplates || selectedTemplates.length === 0){
            setCompletionData([]); 
            return;
        }
        if(!startDateFilter || startDateFilter.length === 0) {
            setCompletionData([]); 
            return;
        };
        if(!endDateFilter || endDateFilter.length === 0){
            setCompletionData([]); 
            return;
        };

        const ids = selectedTemplates.map((l) => l.template_id).join(',');  
        setCompletionReportsLoading(true);
        

        getCompletionReportApi(ids, startDateFilter, endDateFilter, organization_id)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response getCompletionReportApi: ", res);
                props.setFilterCountForTemp(1);
                
                /* enqueueSnackbar(res.message, {
                    variant:'success'
                }); */
                trackGAEvent(props.selectedOrganization.organization.name, 'Filter Completion Report', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
                setCompletionData(res);
                setCompletionReportsLoading(false);
            })
            .catch((e) => {
                consoleToLog("getCompletionReportApi error", e.response);
                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant:'error'
                    });
                    return false;
                }
                setCompletionReportsLoading(false);
            });
    }

    useEffect(() => {
        if(!isMount) {
            consoleToLog("CompletionReports page on Templates change- in useeffect");
            getCompletionReport();
            
        }
    }, [selectedTemplates, startDateFilter, endDateFilter]);

    useEffect(() => {
        if(!isMount) {
            if(page === 1 && tempObj1) {
                callGetCompleteIncompleteChecklistApi();
            }
        }
    }, [tempObj1, statusValue, page]);

    useEffect(() => {
        if(!isMount) {
            if(isMoreLoading) {
                callGetCompleteIncompleteChecklistApi();
            }
        }
    }, [isMoreLoading]);

    const toggleDrawer = () => {
        setOpenDrawer(open => !open)
        setAnchorEl(null);
    }

    const setDrawerOpen = () => {
        setOpenDrawer(false);
        setOpenFilters(false);
        props.setTemplateTab(false);
    }

    const onSelectedTemplates = (sts) => {
        setSelectedTemplates(sts);
    }

    
    const ReportsNotFound = () => {
        return (
        <div>
        {/* Not Found*/}
        <Grid container justifyContent='center' direction="row">
            <Grid
            item
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            >
            <img
                alt="No Reports Found"
                src="/images/no_attachment_found.svg"
                className={classes.notAttachmentsImage}
            />
            </Grid>
            <Grid
            item
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            >
            <Typography className={classes.notAttachmentsText}>
                No Reports Found By Templates, You need to select all filters
            </Typography>
            </Grid>
            <Grid
            item
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            >
            <Button
                variant="contained"
                color="primary"
                //endIcon={<PublishIcon />}
                size="large"
                className={classes.notAttachmentsButton}
                onClick={()=>{
                toggleDrawer();
                setOpenFilters(!openFilters);
                
                }}
            >
                Add Filters
            </Button>
            </Grid>
        </Grid>
        {/* Not Found*/}
        </div>
        );
    };

    
    const loadMoreLogic = () => {
        return (
            <Grid item container>
                <Grid item lg={12}>
                {nextPage && !isMoreLoading &&
                    <div style={{textAlign: "center", margin:'16px 0px 32px 0px'}}>
                        <Button type="button"
                            color="secondary"
                            variant="contained"
                            onClick={() => {
                                setIsMoreLoading(!isMoreLoading);
                            }}>Load More
                        </Button>
                    </div>}

                { isMoreLoading &&
                    <CircularProgress size={35} style={{position:'relative', left:'50%', right:'50%', margin:'16px 0px'}}/>
                }
                </Grid>
            </Grid>
        )
    }

    const pageReset = () => {
        //on page change useeffect will be called and getEmail api called
        setPage(1);
        setNextPage(false);
        if(page===1 && tempObj1) callGetCompleteIncompleteChecklistApi();
    }

    const callGetCompleteIncompleteChecklistApi = () => {
        const organization_id = props.selectedOrganization.organization.id;
        const start_date = (tempObj1 && tempObj1.start_date)? tempObj1.start_date :startDateFilter ;
        const end_date = (tempObj1 && tempObj1.end_date)? tempObj1.end_date : endDateFilter; 
        const status = statusValue.toLowerCase(); 
        const template_id = tempObj ? tempObj.template_id : ''; 
        const schedule_type = tempObj1 ? tempObj1.schedule_type : '';

        if(!isMoreLoading) setChecklistLoading(true);
        getCompleteIncompleteChecklistApi(organization_id, page, start_date, end_date, status, template_id, schedule_type)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response getCompleteIncompleteChecklistApi: ', res);
                setChecklistLoading(false);

                if(isMoreLoading) {
                    const newItems = checkList.concat(res.checklist);
                    setCheckList(newItems);
                    setPage(page + 1);
                    setNextPage(res.next_page);
                    setIsMoreLoading(!isMoreLoading);
                } else {
                    setNextPage(res.next_page);
                    if(res.next_page) setPage(page + 1);
                    setCheckList(res.checklist);
                }

            })
            .catch((e) => {
                consoleToLog("Error getCompleteIncompleteChecklistApi: ", e.response);
                setChecklistLoading(false);
                if(e.response.data && e.response.data.message) {
                    return;
                }
            })
    }

    return (
        <div className={classes.mainContent}
            style={{height: props.height}}>
            <Container className={classes.container} maxWidth="xl">
            
                { completionReportsLoading ?
                    <Grid item container>
                        <Grid item lg={12}>
                            <CircularProgress size={35} style={{position:'relative', left:'50%', right:'50%', margin:'16px 0px'}}/>
                        </Grid>
                    </Grid>
                    :
                       (completionData && completionData.length > 0) ? 
                        completionData.map((templateData, index)=>{
                            return(
                                <div key={templateData.template_id+"_"+index}
                                    style={{padding: "12px 0", marginTop: "10px", fontWeight: "bold", background: "white", marginBottom: "50px"}}>
                                    <Typography variant="body" style={{marginLeft: "20px"}}>
                                    {templateData.template_name}  
                                    </Typography>
                                    <Grid container>
                                        {templateData && templateData.data.length>0 && templateData.data.map((td, index) => {
                                        const seriesArr=[td.completed_count, td.incomplete_count];
                                        consoleToLog("templateData: ", templateData);
                                            return(
                                                <Grid key={index} item lg={4}>
                                                    <Chart options={{
                                                            series:[3,5],
                                                            chart:{
                                                                width:300,
                                                                type: "pie"
                                                            },
                                                            colors:[
                                                                "#00cf95",
                                                                "#0291ff",
                                                                "#FF5733",
                                                                "#1B4F72",
                                                                "#DC7633",
                                                                "#F7DC6F",
                                                                "#52BE80",
                                                                "#A93226",
                                                                "#273746",
                                                                "#B7950B"
                                                            ],
                                                            plotOptions:{
                                                                pie:{
                                                                    customScale:0.7
                                                                }
                                                            },
                                                            legend:{
                                                                show:false
                                                            },
                                                            labels:["Complete", "Incomplete" ],
                                                            responsive:[
                                                            {
                                                                breakpoint:480,
                                                                options:{
                                                                chart:{
                                                                    width:200
                                                                },
                                                                legend:{
                                                                    position:"bottom"
                                                                }
                                                                }
                                                            }
                                                            ],
                                                            chart: {
                                                                events: {
                                                                        dataPointSelection: (event, chartContext, config) => {
                                                                            setTempObj(templateData);
                                                                            setTempObj1(td);
                                                                            let status = config.w.config.labels[config.dataPointIndex];
                                                                            setStatusValue(status);
                                                                            pageReset();
                                                                            handleChecklistDrawerOpen();
                                                                        },
                                                                    }
                                                                }
                                                            }} 
                                                        series={seriesArr} 
                                                        type="pie" 
                                                        height={300}
                                                        />
                                                    <Typography variant="subtitle1" style={{fontWeight: "bold", textAlign: "center", marginTop: "-35px"}}>
                                                        {td.subtitle}
                                                    </Typography>
                                                </Grid>
                                                )
                                         })}
                                    </Grid>
                                </div>
                             )
                            })  : <ReportsNotFound /> 
                        }
                </Container>    
              
                <RightToLeftSideDrawer openDrawer={openDrawer} 
                    closeDrawer={setDrawerOpen}
                    title="Report Filters"
                    createChecklist={false}>
               
                    <Grid item style={{marginLeft: "12px"}}>
                        <Grid item container>
                            <Grid item >
                                <Typography
                                    variant="body1"
                                    style={{lineHeight:'42px', color: '#888888'}}
                                >
                                Filter By Date
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item container style={{marginTop:'-6px'}}>
                            <Grid item>
                            <Button variant='outlined' 
                                    color='primary' 
                                    style={{fontWeight:"300 !important"}}
                                    onClick={openDateRangePicker}>
                                    {(startDateFilter.length>0 && endDateFilter.length>0) ? 
                                        `${moment(startDateFilter).format('DD-MM-YYYY')} - 
                                        ${moment(endDateFilter).format('DD-MM-YYYY')}` : "Start Date - End Date"}
                            </Button>
                            <Popover
                                id='daterange-popover'
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "center"
                                }}
                                transformOrigin={{
                                vertical: "top",
                                horizontal: "center"
                                }}
                                >
                                    <DateRangePicker
                                        open={true}
                                        toggle={handleClose}
                                        onChange={(range) => {
                                            //consoleToLog("range", range, dateRange);
                                            setDateRange(range);
                                            setStartDateFilter(moment(range.startDate).format('YYYY-MM-DD'))
                                            setEndDateFilter(moment(range.endDate).format('YYYY-MM-DD'))
                                            setClearFilter(false);
                                        }}
                                        style={{
                                        width:'50%'
                                        }}
                                        initialDateRange={{
                                            startDate:  startDateFilter,
                                            endDate: endDateFilter
                                        }}
                                        definedRanges={[
                                        {
                                            label: `${moment(lastFinancialYearStart, 'YYYY-MM-DD').format('MMM-YYYY')}-${moment(lastFinancialYearEnd, 'YYYY-MM-DD').format('MMM-YYYY')}`,
                                            startDate: lastFinancialYearStart,
                                            endDate: lastFinancialYearEnd
                                        },
                                        {
                                            label: `${moment(currentFinancialYearStart, 'YYYY-MM-DD').format('MMM-YYYY')}-${moment(currentFinancialYearEnd, 'YYYY-MM-DD').format('MMM-YYYY')}`,
                                            startDate: currentFinancialYearStart,
                                            endDate: currentFinancialYearEnd
                                        },
                                        {
                                            label: 'Last Month',
                                            startDate: prevMonthFirstDay,
                                            endDate: lastdayLastMonth
                                        },
                                        {
                                            label: 'This Month',
                                            startDate: new Date(),
                                            endDate: thisMonthLastDay
                                        }
                                        ]}
                                    />
                                </Popover>
                            </Grid>
                        </Grid>   
                    </Grid>    
                    <Grid item style={{marginLeft: "12px"}}>
                        <Grid item container>
                            <Grid item style={{marginTop:'8px'}}>
                                <Typography
                                    variant="body1"
                                    style={{lineHeight:'42px', color: '#888888'}}
                                >
                                Select Templates
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item container style={{marginTop:'-16px'}}>
                            <Grid item md={6}>
                            <AutocompleteTemplate 
                                clearFilter={clearFilter}
                                onUpdateClearFilterValue={onUpdateClearFilterValue} 
                                onSelectedTemplates={onSelectedTemplates} 
                                selectedTemplates={selectedTemplates}
                            />
                            </Grid>
                        </Grid>   
                    </Grid>
                    <Grid item style={{marginLeft: "12px", marginTop: "18px"}}>
                    <Button variant="contained" 
                        color="primary" 
                        style={{fontWeight:"300 !important"}}
                        className="modal__button"
                        onClick={()=>{
                            setClearFilter(true);
                            setDateRange();
                            setStartDateFilter("");
                            setEndDateFilter("");
                            setCompletionData([]); 
                            props.setFilterCountForTemp(0);
                        }}>
                        Clear Filters
                    </Button>
                    </Grid>
              
                </RightToLeftSideDrawer>
           
                <ChecklistReportsDrawer 
                    openChecklistDrawer={openChecklistDrawer}
                    handleChecklistDrawerClose={handleChecklistDrawerClose}
                    checklistLoading={checklistLoading}
                    checkList={checkList}
                    tempObj={tempObj}
                    nextPage={nextPage}
                    isMoreLoading={isMoreLoading}
                    setIsMoreLoading={setIsMoreLoading}
                />
    </div>
     );
    }
const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    members: state.organizationInfo.members
});

const mapDispatchToProps = (dispatch) => ({
    addOrgMember: (member) => dispatch(addOrgMember(member))
})

export default connect(mapStateToProps, mapDispatchToProps)(ReportByTemplate);