import React from 'react';
import {Grid, Typography, Button, LinearProgress} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {showChecklistStatusColor, checklistLabelList} from '../../util/MobileAppUtil';
import { trackGAEvent, showEmailIcons } from '../../../util/AppUtil';
import  moment from 'moment';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import {connect} from "react-redux";

const useStyles = makeStyles((theme) => ({
    checklistStyles: {
        background:'#fff',
        padding:'8px 14px'
    },
    companyNameStyle: {
        color:"#333333",
        maxWidth:'280px',
        whiteSpace:'nowrap',
        overflow:'hidden',
        textOverflow:'ellipsis',
        [theme.breakpoints.down(385)] : {
            maxWidth:'255px',
        }
    },
    subtitleStyle: {
        fontSize:'13px',
        color:'#888888',
        marginLeft:'8px'
    },
    sidemenubtns: {
        color: "#808080",
        fontSize: "15px",
        minWidth:'20px',
        padding: '0px 5px'
    },
    icontext : {
        color:'#808080',
        padding:'0 0px 0 5px',
    },
    labels : {
        display:'flex',
        marginTop:'3px',
        flexGrow:'1',
        '& .labellist:last-child' : {
            '& .bullet' : {
            display: 'none'
            }
        }
    },
    emailIcons: {
        filter: 'invert(46%) sepia(96%) saturate(5%) hue-rotate(346deg) brightness(96%) contrast(96%)'
    }
}))

const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 4,
        borderRadius: 0,
    },
    colorPrimary: {
        backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
        borderRadius: 5,
        backgroundColor: '#525252',
    },
}))(LinearProgress);


const MobileClientChecklistItem = (props) => {
    const classes = useStyles();

    const showMobileChecklistItems = () => {
        return (
            <Grid item xs={12} style={{marginTop:'16px'}}>
                <Grid item className={classes.checklistStyles}
                    style={{borderLeft: showChecklistStatusColor(props.checklistItem)}}>  
                    <Grid item>
                        <Typography variant="subtitle1" style={{display:'flex'}}>
                            <span className={classes.companyNameStyle} 
                                onClick={(e)=>{
                                    trackGAEvent(props.selectedOrganization.organization.name, 'Mobile Checklist Opened Clicked', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
                                    props.handleDetailsDialogOpen(props.checklistItem.slug_code, props.checklistItem);
                                }}
                            >
                                {props.checklistItem.title}
                            </span>

                            <span className={classes.subtitleStyle}>
                                {props.checklistItem.subtitle}
                            </span>
                        </Typography>
                    </Grid>

                    <Grid item container alignItems='center' style={{marginTop:'3px'}}>
                        <Grid item>
                            <Button aria-label="delete"
                                className={classes.sidemenubtns}
                                onClick={(e)=>{
                                    trackGAEvent(props.selectedOrganization.organization.name, 'Mobile Checklist Notes Icon Cliked', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
                                    props.getCurrentchecklistItem(props.checklistItem);
                                    props.handleTabOpen(props.checklistItem.slug_code, "Notes");
                                }}
                            >
                                <i className="flaticon-comment" style={{lineHeight:'5px'}}></i>
                                <Typography variant="subtitle1" className={classes.icontext}>
                                {props.checklistItem.notes_count ? props.checklistItem.notes_count : '0'}
                                </Typography>
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button aria-label="delete"
                                className={classes.sidemenubtns}
                                onClick={(e)=>{
                                    trackGAEvent(props.selectedOrganization.organization.name, 'Mobile Checklist Attachment Icon Clicked', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
                                    props.getCurrentchecklistItem(props.checklistItem);
                                    props.handleTabOpen(props.checklistItem.slug_code, "Attachments");
                                }}
                            >
                                    <i className="flaticon-attachment-1" style={{lineHeight:'5px'}}></i>
                                    <Typography variant="subtitle1" className={classes.icontext}>
                                        {props.checklistItem.attachment_count ? props.checklistItem.attachment_count : '0'}
                                    </Typography>
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button aria-label="delete"
                            className={classes.sidemenubtns}
                            onClick={(e)=>{
                                trackGAEvent(props.selectedOrganization.organization.name, 'Mobile Sent Email Icon Clicked', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
                                props.getCurrentchecklistItem(props.checklistItem);
                                props.handleTabOpen(props.checklistItem.slug_code, "Audit Trail");
                            }}
                            >
                                <img src={showEmailIcons('out_going')} className={classes.emailIcons}/>
                                <Typography variant="subtitle1" className={classes.icontext}>
                                {props.checklistItem.out_email_count ? props.checklistItem.out_email_count : '0'}
                                </Typography>
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button aria-label="delete"
                            className={classes.sidemenubtns}
                            onClick={(e)=>{
                                trackGAEvent(props.selectedOrganization.organization.name, 'Mobile Received Email Icon Clicked', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
                                props.getCurrentchecklistItem(props.checklistItem);
                                props.handleTabOpen(props.checklistItem.slug_code, "Audit Trail");
                            }}
                            >
                                <img src={showEmailIcons('in_coming')} className={classes.emailIcons}/>
                                <Typography variant="subtitle1" className={classes.icontext}>
                                {props.checklistItem.in_email_count ? props.checklistItem.in_email_count : '0'}
                                </Typography>
                            </Button>
                        </Grid>
                        {props.checklistItem.due_date &&
                        <Grid item >
                            <Button aria-label="delete"
                                //onClick={(e) =>{!accessLevelManager && handleDueDateDrawerOpen(props.checklistItem)}}
                                className={classes.sidemenubtns}
                                //style={{color: props.checklistItem.billable_status !== 'paid' && isDueDateOverDue(props.checklistItem) &&'red'}}
                                >
                                <i className="flaticon-calendar-1" style={{lineHeight:'5px'}}></i>
                                <Typography variant="subtitle1"
                                // style={{color:props.checklistItem.billable_status !== 'paid' && isDueDateOverDue(props.checklistItem) &&'red'}}
                                className={classes.icontext}>{`${moment(props.checklistItem.due_date).format('Do MMM')}`}</Typography>
                            </Button>
                            {/* {!accessLevelManager && handleDueDateDrawerOpen(props.checklistItem)} */}
                        </Grid> }
                    </Grid>   

                    <Grid item container alignItems='center'
                        justifyContent='space-between' 
                        style={{marginTop:'3px'}}>
                        <Grid item style={{marginBottom:'6px'}}>
                            {(props.checklistItem.checklist_labels_list && props.checklistItem.checklist_labels_list.length > 0) &&
                                <div style={{display:'flex'}}>
                                    <div className={classes.labels}>
                                        {props.checklistItem.checklist_labels_list.slice(0,1).map((label) => {
                                            return <div key={label.id} style={{display:'flex', alignItems:'flex-start'}} className='labellist'>
                                                        <Typography
                                                            //onClick={() => onLabelsClick(label)}
                                                            style={{color: `${label.color}`,
                                                            paddingLeft:'3px',
                                                            cursor:'pointer',
                                                            paddingTop:'0',
                                                            fontSize:'12px'}}>{label.name}</Typography>
                                                        <FiberManualRecordIcon fontSize="small"
                                                            className="bullet"
                                                            style={{width:'6px', height:'6px', color:"#ccc", paddingLeft:'3px', paddingTop:'0', marginTop:'7px'}}/>
                                                    </div>
                                                })
                                        }
                                    </div>
                                    <span>
                                    {(props.checklistItem.checklist_labels_list && props.checklistItem.checklist_labels_list.length > 1) &&
                                        checklistLabelList(props.checklistItem) }
                                    </span>
                                </div>
                            }
                        </Grid>

                    </Grid>
                </Grid>
            <BorderLinearProgress variant="determinate" 
                value={props.checklistItem.total_task > 0 ? Math.round((props.checklistItem.completed_task / props.checklistItem.total_task) * 100) : 0} />        
    {/**/}
            </Grid>
        )
    }

    return (
        <div>
            {showMobileChecklistItems()}
        </div>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
});

export default connect(mapStateToProps)(MobileClientChecklistItem);