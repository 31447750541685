import { connect } from 'react-redux';
import { Grid, Avatar, makeStyles, Tooltip, TextField, Typography, FormControlLabel, Checkbox, Button  } from '@material-ui/core';
import { useEffect, useRef, useState } from 'react';
import {useIsMount} from '../useIsMount';
import { consoleToLog } from '../../util/AppUtil';
import { setSearchMemberFilter } from '../../actions/filters';
import useOuterClick from './useOuterClick';
import ClearIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';

const useStyles = makeStyles((theme) => ({
    customColumn2: {
        flexBasis:'13%', 
        maxWidth:'13%',
        flexWrap:'nowrap !important'
    },
    approvedStyle: {
        background:'#0FFF50 !important',
        color:'#fff !important',
        border:'#0FFF50 !important'
    },
    rejectedStyle: {
        background:'#EE4B2B !important',
        color:'#fff !important',
        border:'#EE4B2B !important'
    },

    avatarStyle1: {
        background:'#ffffaa !important',
        color:'#f7751e !important',
        border:'0.1px solid #f7751e !important'
      },
    
    avatarStyle2: {
        background:'#e1f5e7 !important',
        color:'#63b267 !important',
        border:'0.1px solid #63b267 !important'
    },

    avatarStyle3: {
        background:'#dff5ff !important',
        color:'#31a9ea !important',
        border:'0.1px solid #31a9ea !important'
    },

    avatarStyle4: {
        background:'#fff2d3 !important',
        color:'#d4ac4d !important',
        border:'0.1px solid #d4ac4d !important'
    },
    avatarStyle5: {
        background:'#dba8ff !important',
        color:'#4f1c96 !important',
        border:'0.1px solid #4f1c96 !important'
    },
    MuiFormControlLabelroot : {
        '& .MuiFormControlLabel-label' : {
            fontSize:'12px'
        },
    },
    iconStyles: {
        display:'flex', 
        alignItems:'center', 
        fontSize:'17px',
        justifyContent:'center',
        cursor:'pointer'
    },
    dropdownIconStyle: {
        fontSize:'18px', 
        cursor:'pointer'
    },
    sendForReviewClass : {
        left:'10px',
        zIndex:'999 !important',
        paddingBottom:'16px !important'
    }
}));

const SelectFromApproverList = (props) => {
    const classes = useStyles();
    
    const [selectedApproversList, setSelectedApproversList] = useState([]);
    const [thumbnailselectedApproversList, setThumbnailselectedApproversList] = useState([]);
    const [isActiveAdd, setIsActiveAdd] = useState(false);
    const isMount = useIsMount();

    useEffect(()=>{
        props.setSearchMemberFilter('');
        setSelectedApproversList((props.assignees && props.assignees.length > 0) ? props.assignees : []);
        toggleThumbnailselectedApproversList(props.assignees);
    }, []);

    useEffect(()=>{
        if(!isMount) {
            setSelectedApproversList(props.assignees);
            toggleThumbnailselectedApproversList(props.assignees);
        }
    }, [props.assignees]);

    const onApplyChanges = (list) => {
        //pass data to parent class
        !props.fromSendForReviewModal && props.setAssignedApproverList(list);
        props.fromSendForReviewModal && props.setApprovalList(list);
    }

    const toggleThumbnailselectedApproversList = (list) => {
        if (!list) {
            return;
        }
        if(props.isShowOnly) {
            setThumbnailselectedApproversList(list);
        } else {
            const slicedArr = list.slice(0, 3);
            setThumbnailselectedApproversList(slicedArr);
        }
    }

    const onClickAdd = (e/* , checklist_id */) => {
        setIsActiveAdd(!isActiveAdd);
        //setChecklistId(checklist_id);
    }  

    const isSelectedApprover = (checkedApprover) => {
        const tempArr = selectedApproversList.filter((member) => member.user.id === checkedApprover.user.id);
        return tempArr.length > 0 ? true : false;
    }

    const onApproverChecked = (e, checkedApprover) => {
        e.stopPropagation();
        if (isSelectedApprover(checkedApprover)) {
            //remove
            const array = selectedApproversList.filter((member) => member.user.id !== checkedApprover.user.id);
            setSelectedApproversList(array);
            toggleThumbnailselectedApproversList(array);
            onApplyChanges(array);
            //after setting above list it will call useEffect on selectedMembersList
        } else {
            //add
            //consoleToLog("Newly added: ", [...selectedApproversList, checkedApprover]);
            const array = selectedApproversList.concat(checkedApprover);
            setSelectedApproversList(array);
            toggleThumbnailselectedApproversList(array);
            onApplyChanges(array);
            //after setting above list it will call useEffect on selectedMembersList
        }
    }

    const getApproverButtonTitle = () => {
        //if (this.state.isApplyActive) {//create checklist page || checklist item
            const number = selectedApproversList.length;
            //<i className="fa fa-plus" aria-hidden="true"></i>;
            if (number > 3 && number <= 6) {
                return `${number - 2}+`;
            } else if (number > 6) {
                return `4+`;
            }
    }

    const getApproverButtonToolTipTitle = () => {
            const title = selectedApproversList.slice(2).map((m) => m.user.firstname + ' ' + m.user.lastname).join(', ');
            return title;
    }

    const handleTextFieldChange = (e) => {
        const name = e.target.value;
        props.setSearchMemberFilter(name);
    }

    const innerRef = useOuterClick(ev => {  
        //consoleToLog("outside click11"); 
        setIsActiveAdd(false);
    });

    function findLatestItemByApproverId(approvalList, approvers) {
        const latestItems = {};

        const arr = approvalList?.filter((approval) => {
            return approvers?.find((approver) => approval.approver_user.id === approver.user.id);
        });
        
        if(arr && arr.length > 0) {
            for (const item of arr) {
                const approverId = item.approver_user.id;
                const timestamp = new Date(item.sent_timestamp);

                if (!latestItems[approverId] || timestamp > new Date(latestItems[approverId].sent_timestamp)) {
                    latestItems[approverId] = item;
                }
            }
        }
        return Object.values(latestItems);
    }

    const checklistApprovedOrRejected = (member_id, initial) => {
        const approvers = selectedApproversList;
        const approvalList = props.approvalList;
        
        const finalArray = findLatestItemByApproverId(approvalList, approvers);

        const checklist_approved = finalArray?.filter((approver) => approver?.approval_status === 'APPROVED' && approver?.approver_user.id === member_id);
        const checklist_rejected = finalArray?.filter((approver) => approver?.approval_status === 'REJECTED' && approver?.approver_user.id === member_id);

        if (checklist_approved && checklist_approved.length > 0) {
            return <DoneIcon className={`flaticon-check ${classes.iconStyles}`} />
        } else if (checklist_rejected && checklist_rejected.length > 0) {
            return <ClearIcon className={`flaticon-cancel-1 ${classes.iconStyles}`} />
        } else {
            return initial
        }     
    }

    const checklistApprovedOrRejected1 = (member_id) => {
        const approvers = selectedApproversList;
        const approvalList = props.approvalList;

        const finalArray = findLatestItemByApproverId(approvalList, approvers);

        const checklist_approved = finalArray?.filter((approver) => approver?.approval_status === 'APPROVED' && approver?.approver_user.id === member_id);
        const checklist_rejected = finalArray?.filter((approver) => approver?.approval_status === 'REJECTED' && approver?.approver_user.id === member_id);

        if (checklist_approved && checklist_approved.length > 0) {
            return  <Tooltip title="Approved" arrow>
                        <DoneIcon className={`flaticon-check ${classes.dropdownIconStyle}`} />
                    </Tooltip>
        } else if (checklist_rejected && checklist_rejected.length > 0) {
            return <Tooltip title="Rejected" arrow> 
                        <ClearIcon className={`flaticon-cancel-1 ${classes.dropdownIconStyle}`} />
                    </Tooltip>
        }   
        
        
    }

    return(
        <Grid item container lg={2} 
            className={classes.customColumn2} 
            ref={innerRef}
            //style={{marginTop: "-3px"}}
            >
             <Grid item style={{position:"relative", marginRight: "1px"}}>
            {(!props.disabledAssignChecklistApprovers) && <Tooltip title="Add Approver" arrow >
                    <Button  className="onChecklist__hover"
                        style={{
                            position:'relative', 
                            width: "27px", 
                            height: "27px", 
                            minWidth: "0", 
                            marginTop: "-2px", 
                            fontSize: "14px", 
                            color: "#0071ff", 
                            borderRadius: "50%", 
                            border: "1px solid #0071ff", 
                            paddingTop: "5px",
                            display: props.isFromWaitingForMyApproval ? 'none' : 'flex'}}
                            onClick={(e) => onClickAdd(e)} 
                       >
                            <i className="flaticon-add-2" style={{paddingTop: "6px"}}></i>
                        </Button>
                </Tooltip>} 
                { isActiveAdd &&
                    <Grid item  
                        className={`menu1 ${isActiveAdd ? `active ${props.fromSendForReviewModal && classes.sendForReviewClass}` : "inactive"}`}
                        style={{padding: "4px 10px", width:'220px', marginBottom:'116px'}}
                    >
                        <TextField id="member_input" 
                            fullWidth
                            label="Search Members" 
                            style={{ marginBottom: "8px"}}
                            //value={textFieldValue} 
                            onChange={handleTextFieldChange}
                            />
                        <Typography variant="button" gutterBottom >
                            Members
                        </Typography>
                        {props.members && props.members.length > 0 && props.members.map((member, index)=>{
                            const fullName = member.user.firstname + ' ' + member.user.lastname;
                            return(<div style={{display: 'flex'}}>
                                        <FormControlLabel
                                            key={member.id+"_"+index}
                                            control={
                                            <Checkbox
                                                checked={isSelectedApprover(member) || false}
                                                onChange={(e) => onApproverChecked(e, member)}
                                                name="checkedB"
                                                color="primary"
                                            />
                                            }
                                            style={{ display:"block", whiteSpace: "nowrap", maxWidth: "auto", overflow: "hidden", textOverflow: "ellipsis" }}
                                            label={fullName}
                                        />
                                        <span style={{color:'black', marginTop:'9px'}}>
                                            {!props.fromSendForReviewModal && checklistApprovedOrRejected1(member.user.id)}
                                        </span>
                                    </div>  
                                    )
                                })}
                    </Grid>
                }   
            </Grid>   

            {/* display initial 2 members if any */
                selectedApproversList && selectedApproversList.length > 0 && 
                thumbnailselectedApproversList && thumbnailselectedApproversList.length>0 &&
                thumbnailselectedApproversList.map((member, index)=>{
                    let initial = member.user.firstname.charAt(0).toUpperCase()+member.user.lastname.charAt(0).toUpperCase()
                    return(
                        <div>
                        {(selectedApproversList.length>3 && index === 2) && !props.isShowOnly ? 
                        <Grid item key={member.id}>
                            <Tooltip title={getApproverButtonToolTipTitle()} arrow
                             style={{ cursor: props.isFromClients ? "default" :"pointer"}}>
                                <Avatar>{getApproverButtonTitle()}</Avatar>
                            </Tooltip>    
                        </Grid>
                        :
                        <Grid item key={member.id}>
                            <Tooltip title={member.user.firstname+" "+member.user.lastname} arrow
                             style={{ cursor: props.isFromClients ? "default" :"pointer"}}>
                                <Avatar >
                                    {!props.fromSendForReviewModal ? checklistApprovedOrRejected(member.user.id, initial) : initial}
                                </Avatar>
                            </Tooltip>    
                        </Grid>}
                        </div>
                    )
                })
            }
            
        </Grid>
    )
}


const mapStateToProps = (state) => ({
    auth: state.auth,
    members: state.organizationInfo.members.filter((member) => {
        const fullName = member.user.firstname + ' ' + member.user.lastname;
        return fullName.toLowerCase().includes(
            state.filters.searchMemberFilter.toLowerCase()
        )
    }),
});

const mapDispatchToProps = (dispatch) => ({
   setSearchMemberFilter: (name) => dispatch(setSearchMemberFilter(name))
})

export default connect(mapStateToProps, mapDispatchToProps)(SelectFromApproverList)