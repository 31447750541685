import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Grid, Button, Typography, Divider, CircularProgress, Link} from '@material-ui/core';
import {ApprovalStatus, consoleToLog, ApprovalTypes, trackGAEvent} from '../../util/AppUtil';
import ForwardOutlinedIcon from '@material-ui/icons/ForwardOutlined';
import moment from 'moment';
import {getApprovalListApi} from '../../services/ChecklistService';
import {connect} from 'react-redux';
import { useIsMount } from '../useIsMount';
import {approveRejectCancelApi} from '../../services/ChecklistService';
import { downloadAttachmentApi } from '../../services/authService';
import {undoApproveRejectApi} from '../../services/ChecklistService';
import { useSnackbar } from 'notistack';
import ApprovalItem from './ApprovalItem';

const useStyles = makeStyles((theme) => ({
    approvalListContainer: {
        background:'#f7f6f6',
        marginBottom:'8px'
    },
    taskStyle: {
        fontSize:'14px',
        color:"#007bfb",
        textDecoration:"underline",
        paddingTop:'8px'
    },
    userApproverStyle :{
        display:'flex',
        alignItems: 'center'
    },
    userApproverNameStyle: {
        fontSize:"16px",
        fontWeight:600
    },
    forwardArrowStyle: {
        fontSize:'36px',
        margin:"0px 8px",
        position:"relative",
        top:'-4px'
    },
    customColumn1: {
        flexBasis:"78%",
        maxWidth:"78%"
    },
    customColumn2: {
        flexBasis:"21%",
        maxWidth:"21%"
    },
    approverBtnStyle: {
        background:"#72bb53",
        color:"#fff",
        width:'100px'
    },
    rejectBtnStyle: {
        background:"#e61610",
        color:"#fff",
        width:'100px',
        marginLeft:'8px'
    },
    undoBtnStyle: {
        background:"#d6d6d6",
        color:"#fff",
        width:'120px'
    },
    negativeMarginBottom: {
        marginBottom:'-8px'
    },
    customMarginTop: {
        marginTop:'16px'
    }
}))

const Approvals = (props) => {
    const classes = useStyles();
    const isMount = useIsMount();
    const {enqueueSnackbar} = useSnackbar();

    const checklistId = props.checkListItem.id;

    const [approvalList, setApprovalList] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getApporvalList();
    }, []);

    useEffect(() => {
        if(!isMount) {
            if(props.updateApporvalList) {
                setApprovalList([props.updateApporvalList, ...approvalList]);
                props.setUpdateApprovalList(undefined);
            }
        }
    }, [props.updateApporvalList]);

    useEffect(() => {
        if(!isMount) {
                if(props.callGetApi) {
                    getApporvalList();
                    props.setCallGetApi(false);
                }
        }
    }, [props.taskList, props.callGetApi]);

    const getApporvalList = () => {

        setLoading(true);
        getApprovalListApi(checklistId)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response getApprovalListApi ', res);
                setApprovalList(res.approvals);
                setLoading(false);
            })
            .catch((e) => {
                consoleToLog("Error getApprovalListApi: ", e.response);
                setLoading(false);
                if(e.response.data && e.response.data.message) {
                    return;
                }
            })
    }

    return (
        loading ?
        <Grid item container justifyContent='center'>
            <CircularProgress size={28} style={{marginTop:'24px'}} />
        </Grid>
        :
        <div style={{marginTop:"16px"}}>
            {approvalList && approvalList.length > 0 ?
            approvalList.map((item) => {
                return <ApprovalItem key={item.id}
                            item={item}
                            approvalList={approvalList}
                            setApprovalList={setApprovalList}
                            updateChecklistFromReview={props.updateChecklistFromReview}
                            checklistTaskUpdateFromApproval={props.checklistTaskUpdateFromApproval}
                            setApprovalObj={props.setApprovalObj}
                            handleOpenSendForReviewDialog={props.handleOpenSendForReviewDialog}
                            updateApporvalList={props.updateApporvalList}
                            setUpdateApprovalList={props.setUpdateApprovalList}
                        />            
            })
            :
            <Grid item container justifyContent='center' alignItems='center'>
                <Typography>
                    No Approvals Found
                </Typography>
            </Grid>
            }
        </div>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization
})

export default connect(mapStateToProps)(Approvals);