import React, {useState, useEffect} from 'react';
import { Grid, Tooltip, TextField, Typography, FormControlLabel, Checkbox, Button, CircularProgress  } from '@material-ui/core';
import {connect} from 'react-redux';
import useOuterClick from '../checklist/useOuterClick';
import {useIsMount} from '../useIsMount';
import { useParams } from 'react-router-dom';

const BulkMoveToFolder = (props) => {
    const isMount = useIsMount();
    const {slug} = useParams();

    const [folderList, setFolderList] = useState(props.checklistFolders ? props.checklistFolders : []);
    const [selectedFolder, setSelectedFolder] = useState(undefined);
    const [isActiveAdd, setIsActiveAdd] = useState(false);
    const [searchFolder, setSearchFolder] = useState('');

    useEffect(() => {
        if(isMount) {

        } else {
            if(searchFolder === '') {
                setFolderList(props.checklistFolders);
            }
        }
    }, [searchFolder]);

    const handleTextFieldChange = (e) => {
        const name = e.target.value.toLowerCase();
        setSearchFolder(name);
        const search_folder = folderList.filter((folder) => folder.group_name.toLowerCase().includes(name));
        setFolderList(search_folder);
    }


    const onFolderChecked = (e, checkedFolder) => {
        setSelectedFolder(checkedFolder);
        props.onBulkMoveToFolder(checkedFolder);
    }

    const onClickAdd = (e) => {
        setIsActiveAdd(!isActiveAdd);
    }

    const innerRef = useOuterClick(ev => {  
        //consoleToLog("outside click11"); 
        setIsActiveAdd(false);
    });

    return (
        <Grid item container lg={2}
            ref={innerRef}
        >
            <Grid item style={{position:"relative", marginRight: "1px"}}>
                {<Tooltip title="Move to Folder" arrow >
                    <Button aria-label="delete"
                        disabled={props.bulkLoading && selectedFolder === undefined}
                        className="checklistBulk__icons"
                        onClick={(e) => onClickAdd(e)}
                    >
                        <i className="flaticon-folder-1"></i>
                    </Button>
                </Tooltip>}  
                {isActiveAdd &&
                    <Grid item  
                        className={`menu2 ${isActiveAdd ? "active" : "inactive"}`}
                        style={{padding: "4px 10px", width:'220px'}}
                    >
                        <TextField id="member_input" 
                            fullWidth
                            label="Search Folders" 
                            style={{ marginBottom: "8px"}}
                            //value={textFieldValue} 
                            onChange={handleTextFieldChange}
                            />
                        <Typography variant="button" gutterBottom >
                            Folders {props.bulkLoading && <CircularProgress size={15} 
                                                                color="primary" 
                                                                style={{marginLeft:'10px'}}/> }
                        </Typography>
                        {
                             slug &&
                             <FormControlLabel
                                    control={
                                    <Checkbox
                                        disabled={props.bulkLoading && selectedFolder !== 0}
                                        checked={selectedFolder === 0}
                                        onChange={(e) => onFolderChecked(e, 0)}
                                        name="checkedB"
                                        color="primary"
                                    />
                                    }
                                    style={{paddingBottom:'0', display:'block' }}
                                    label="Move To Root"
                              />
                        }
                        {props.auth && folderList && folderList.length > 0 && folderList.map((folder, index)=>{
                            return(
                                <FormControlLabel
                                    key={folder.id+"_"+index}
                                    control={
                                    <Checkbox
                                        disabled={props.bulkLoading && selectedFolder !== folder.id}
                                        checked={selectedFolder === folder.id}
                                        onChange={(e) => onFolderChecked(e,folder.id)}
                                        name="checkedB"
                                        color="primary"
                                    />
                                    }
                                    style={{ display:"block", whiteSpace: "nowrap", maxWidth: "auto", overflow: "hidden", textOverflow: "ellipsis" }}
                                    label={folder.group_name}
                              />
                            )
                        })}
                    </Grid>
                }   
            </Grid>
            
        </Grid>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedOrganization: state.organizationInfo.selectedOrganization,
    selectedTemplate: state.organizationInfo.selectedTemplate
});

export default connect(mapStateToProps)(BulkMoveToFolder);